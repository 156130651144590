<template>
  <div>
    <button class="btn btn-success font-weight-bold btn-sm"
            v-b-modal="`test-input-create-modal`"
            @click="create"
    >
      Tạo lịch test
    </button>
    <b-modal
        @ok="handleOk"
        centered id="test-input-create-modal"
        size="lg"
        title="Tạo tác vụ"
        ok-title="Lưu"
        cancel-title="Hủy"
        :ok-disabled="okDisable"
        hide-footer
        @hidden="clearData"
    >
      <ValidationObserver v-show="!isShowLoading" v-slot="{ handleSubmit }" ref="form">
        <form class="form" autocomplete="off">
          <div class="container">
            <div class="row">
              <div class="col-md-12 mb-2">
                <h5>Thông tin tác vụ</h5>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Loại tác vụ <span class="text-danger">*</span></label>
                  <ValidationProvider name="type_task" rules="required|max:30" v-slot="{ errors,classes }"
                                      vid="type_task">
                    <el-select disabled v-model="schedule.type_task"
                               placeholder="Loại tác vụ"
                               clearable
                    >
                      <el-option
                          v-for="item in type_task"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Trung tâm test<span class="text-danger">*</span></label>
                  <ValidationProvider name="Trung tâm test" rules="required" v-slot="{ errors,classes }"
                                      vid="center_id">
                    <el-select v-model="schedule.center_id"
                               @input="handleCenter"
                               placeholder="Trung tâm test"
                               clearable
                               filterable
                    >
                      <el-option
                          v-for="item in centers"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6" v-if="showOptionBranch">
                <div class="form-group">
                  <label>Cơ sở test<span class="text-danger">*</span></label>
                  <ValidationProvider name="Cơ sở test" rules="required" v-slot="{ errors,classes }"
                                      vid="branch_id">
                    <el-select v-model="schedule.branch_id"
                               filterable
                               placeholder="Cơ sở test"
                               clearable
                               @input="getTestersFrees"
                               :loading="loadingBranch"
                    >
                      <el-option
                          v-for="item in branches"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Hình thức test<span class="text-danger">*</span></label>
                  <ValidationProvider name="Hình thức test" rules="required" v-slot="{ errors,classes }"
                                      vid="type">
                    <el-select v-model="schedule.type"
                               placeholder="Cơ sở test"
                               :disabled="disableType"
                               @input="handleType"
                    >
                      <el-option
                          v-for="item in WORKING_FORM_TASK"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Ngày làm<span class="text-danger">*</span></label>
                  <ValidationProvider name="Ngày làm" rules="required" v-slot="{ errors,classes }"
                                      vid="schedule">
                    <date-picker
                        v-model="schedule.schedule"
                        type="date"
                        placeholder="Ngày làm"
                        :disabled-date="disabledBeforeToday"
                        value-type="YYYY-MM-DD"
                        format="DD-MM-YYYY"
                        @input="handleSchedule"
                        :clearable="false"
                    >
                      <template v-slot:footer>
                        <button class="mx-btn mx-btn-text" @click="toggleTimePanel">
                          {{ showTimePanel ? 'select date' : 'select time' }}
                        </button>
                      </template>
                    </date-picker>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <label>Thời gian bắt đầu<span class="text-danger">*</span></label>
                <ValidationProvider name="Thời gian bắt đầu" rules="required" v-slot="{ errors,classes }"
                                    vid="startTime">
                  <el-select filterable class="w-100" v-model="schedule.start_time"
                             placeholder="Thời gian bắt đầu"
                             :disabled="disableStartTime"
                             @change="handleChangeStartDate">
                    <el-option-group
                        v-for="group in testHourList"
                        :key="group.label"
                        :label="group.label"
                    >
                      <el-option
                          v-for="item in group.options"
                          :key="item.value"
                          :label="item.label"
                          :disabled="disableOptionTimeStart(item.label)"
                          :value="item">
                        <span style="float: left">{{ item.label }}</span>
                        <span v-if="isHourTooBad(item.label)"
                              style="float: right; color: #c5a862; font-size: 13px">Nhiều KH lên test ca này!</span>
                      </el-option>
                    </el-option-group>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-md-6" v-if="showTesterAssign">
                <label>Người phụ trách<span class="text-danger">*</span></label>
                <el-input disabled placeholder="Người phụ trách" v-model="testerFreeTime"></el-input>
                <div v-if="showOptionAssign">
                  <p v-if="!checkAssignToMe"
                     class="text-primary cursor-pointer mt-2"
                     @click="assignToMe">Tôi nhận
                    phụ trách tác vụ này</p>
                  <p v-else class="text-primary cursor-pointer mt-2" @click="unAssignToMe"><i
                      class="el-icon-refresh-left text-primary"></i>Hoàn tác</p>
                </div>
              </div>
              <div class="col-md-12 mt-8 mb-2">
                <h5>Thông tin khách hàng</h5>
              </div>
              <div class="col-md-6">
                <label>Họ và tên<span class="text-danger">*</span></label>
                <ValidationProvider name="Họ và tên" rules="required" v-slot="{ errors,classes }"
                                    vid="name">
                  <el-input placeholder="Họ và tên" v-model="schedule.name"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <label>Số điện thoại<span class="text-danger">*</span></label>
                <ValidationProvider name="Số điện thoại"
                                    :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                    v-slot="{ errors,classes }"
                                    vid="phone">
                  <el-input placeholder="Số điện thoại" v-model="schedule.phone"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="mt-4 col-md-12 d-flex flex-row-reverse">
                <button type="button" class="btn btn-primary font-weight-bold btn-sm " @click="handleSubmit(handleOk)">
                  <i class="el-icon-loading" v-if="okDisable"></i>Xác nhận
                </button>
                <button type="button" class="btn btn-secondary font-weight-bold btn-sm mr-3"
                        @click="closeModal">
                  Hủy
                </button>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <modal-handle-loading
          v-show="isShowLoading"
          :status-prop="statusSpeaking"
          :id="idSpeaking"
          :prop-seconds="seconds"
          :isShowLoading="isShowLoading"
          @close-modal="closeModal"
          @loading-success="loadingSuccess"
      ></modal-handle-loading>
    </b-modal>
  </div>
</template>

<script>
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  CHECK_ONLY_BRANCH_TESTER,
  CHECK_SLOT_BY_DAY,
  GET_TESTER_BY_USER_LOGIN,
  GET_TESTER_FREE_TIME_PERSONAL,
  TEST_INPUT_GET_TESTER_BY_USER_ID,
  TEST_INPUT_STORE_TASK_PERSONAL
} from "@/core/services/store/test/testInput.module";
import {
  ALL,
  OFFLINE,
  ONLINE,
  TIME_PICKER_OPTIONS, TIME_SELECT_OPTION, TIME_SELECT_OPTION_ONLINE,
  TYPE_EXAMS,
  TYPE_TASK,
  TYPE_TASK_TEST_INPUT, WORKING_FORM_TASK
} from "@/core/option/testInputOption";
import {mapGetters} from "vuex";
import moment from "moment-timezone";
import {GET_CENTERS} from "@/core/services/store/center/centers.module";
import {GET_BRANCHES} from "@/core/services/store/center/branch.module";
import {Cambridge_Mentor_ID, ECOACH_ID} from "@/core/option/typeCenterBill";
import {CHECK_MY_TASK_PENDING} from "../../../../core/services/store/test/testInput.module";
import ModalHandleLoading from "./ModalHandleLoading";

export default {
  name: "TestInputCreateModal",
  components: {ModalHandleLoading, DatePicker},
  props: {},
  data() {
    return {
      //setup
      showTimePanel: false,
      okDisable: false,
      search_loading: false,
      fileList: [],
      type_task: TYPE_TASK,
      WORKING_FORM_TASK: WORKING_FORM_TASK,
      TYPE_EXAMS: TYPE_EXAMS,
      testers: [],
      testerLogin: null,
      users: [],
      centers: [],
      branches: [],
      schedule: {
        type_task: TYPE_TASK_TEST_INPUT,
        tester_id: '',
        schedule: moment().format('YYYY-MM-DD'),
        end_schedule: '',
        start_time: '',
        user_id: '',
        type: 2,
        type_exam_id: 0, // Mặc định ielts
        name: '',
        phone: '',
        center_id: '',
        branch_id: '',
        assign_to_me: false,
      },
      time_picker_options: TIME_PICKER_OPTIONS,
      open: false,
      allSlots: [],
      ECOACH_ID: ECOACH_ID,
      TYPE_ALL: ALL,
      TYPE_OFFLINE: OFFLINE,
      optionSelectTime: TIME_SELECT_OPTION,
      optionSelectTimeOnline: TIME_SELECT_OPTION_ONLINE,

      //Loading
      isShowLoading: false,
      statusSpeaking: 0,
      idSpeaking: 0,
      seconds: 60,
      loadingBranch: false
      //End Loading
    }
  },
  mounted() {
    this.getUser();
    this.getAllCenterIsEdutalk();
    this.getBranches();
    this.checkOnlyBranchTester();
    this.getTesterByUserId();
  },
  computed: {
    showTesterAssign() {
      if (!this.schedule.branch_id || !this.schedule.type || !this.schedule.schedule || !this.schedule.start_time) {
        this.schedule.tester_id = '';
        return false;
      }
      return true;
    },
    ...mapGetters(["currentUser"]),
    disableType() {
      return this.schedule.center_id == ECOACH_ID || this.schedule.center_id === Cambridge_Mentor_ID
    },
    disableStartTime() {
      if (this.schedule.branch_id != '') {
        this.checkSlotByDay()
      }
      return this.schedule.branch_id == '' || this.schedule.schedule == '' || this.schedule.type == ''
    },
    testerFreeTime() {
      if (this.schedule.assign_to_me) {
        this.schedule.tester_id = this.testerLogin.id;
        return this.testerLogin.name + ' - ' + this.testerLogin.phone;
      }

      if (this.testers.length === 0) {
        return null;
      }
      let tester = this.testers[0];
      this.schedule.tester_id = tester.id;
      return tester.name + ' - ' + tester.phone;
    },
    checkAssignToMe() {
      if (this.schedule.assign_to_me) {
        return true;
      }
      return false;
    },
    /**
     * Nếu là offline & test đầu vào chọn cùng chi nhánh với tester đang login thì mới hiển thị option (Tôi nhận
     *                    phụ trách tác vụ này)
     * @returns {boolean}
     */
    showOptionAssign() {
      if ([this.TYPE_ALL, this.TYPE_OFFLINE].includes(this.schedule.type) && this.testerLogin.branches.includes(this.schedule.branch_id)) {
        return true;
      }
      return false;
    },
    showOptionBranch() {
      if (this.schedule.center_id) {
        return true;
      }
      return false;
    },
    testHourList(){

      return  this.optionSelectTimeOnline;
    }

  },
  methods: {
    create() {
      this.checkMyTaskPending();
    },
    checkMyTaskPending() {
      this.$store.dispatch(CHECK_MY_TASK_PENDING).then((data) => {
        let item = data.data;
        if (item == null) {
          this.isShowLoading = false;
          return;
        }
        this.idSpeaking = item.id;
        this.isShowLoading = true;
        this.statusSpeaking = item.status;
        this.handleSeconds(item.created_at);
      })
    },
    handleSeconds(created_at) {
      let seconds = this.getSeconds(created_at);
      let minusSeconds = 60 - seconds;
      if (minusSeconds < 0) {
        this.seconds = 0;
      }
      if (minusSeconds > 60) {
        this.seconds = 60;
      }
    },
    getSeconds(created_at) {
      let create = new Date(created_at);
      let currentTime = new Date();
      return Math.floor((currentTime - create) / 1000);
    },
    handleType() {
      this.getTestersFrees();
    },
    getTesterByUserId() {
      this.$store.dispatch(GET_TESTER_BY_USER_LOGIN).then((data) => {
        this.testerLogin = data.data;
      }).catch((error) => {
        console.log(error)
      })
    },
    getTestersFrees() {
      if (!this.schedule.branch_id || !this.schedule.type || !this.schedule.schedule || !this.schedule.start_time) {
        return false;
      }

      this.testers = [];
      let time = moment(this.schedule.start_time.value, "HH:mm:ss");
      let startDate = moment(this.schedule.schedule).set({
        'hour': time.hour(),
        'minute': time.minute(),
        'second': time.second()
      });
      let payload = {
        start_date: startDate.clone().format("YYYY-MM-DD HH:mm:ss"),
        end_date: startDate.add(30, 'minutes').format("YYYY-MM-DD HH:mm:ss"),
        type_task: this.schedule.type_task,
        branch_id: this.schedule.branch_id,
        type: this.schedule.type
      }
      this.search_loading = true;
      this.$store.dispatch(GET_TESTER_FREE_TIME_PERSONAL, payload).then((data) => {
        this.search_loading = false;
        this.testers = data.data;
      })
    },
    assignToMe() {
      this.schedule.assign_to_me = true;
    },
    unAssignToMe() {
      this.schedule.assign_to_me = false;
    },
    disableOptionTimeStart(hour) {
      if (!this.allSlots || Object.keys(this.allSlots).length == 0) {
        return true;
      }
      let stringHour = `${this.schedule.schedule} ${hour}`
      return new Date(stringHour).getTime() < new Date().getTime() || (this.allSlots[hour] == 0) || ((this.allSlots[hour] == 2) && this.schedule.type == ONLINE)
    },
    checkSlotByDay() {
      let payload = {
        branch_id: this.schedule.branch_id,
        day: moment(this.schedule.schedule).format('YYYY-MM-DD'),
        type: this.schedule.type
      }
      this.$store.dispatch(CHECK_SLOT_BY_DAY, payload).then((data) => {
        let Hour = {...data.data}
        Object.keys(data.data).forEach((key, index) => {
          Hour[key] = Hour[key]?.type ?? 0
        })
        this.allSlots = Hour
        let valueHour = Object.values(Hour)
        if (valueHour.filter((e) => e == 0).length == valueHour.length) {
          this.allSlots = {}
          this.messageError()
        }
      }).catch((res) => {
        this.messageError()
        this.allSlots = {}
      })
    },
    messageError() {
      // this.$alert(`${this.schedule.type==OFFLINE?'Không có admin test trực tại điểm.':'Ngày chọn đã hết ca trống. Vui lòng chọn ngày khác hoặc liên hệ hotline 0363915764 để đề xuất thêm ca test.'}`, 'Thông báo', {
      //   confirmButtonText: 'Đóng',
      // });
    },
    checkOnlyBranchTester() {
      this.$store.dispatch(CHECK_ONLY_BRANCH_TESTER).then((data) => {
        this.schedule.center_id = data.data.center_id;
        if (this.schedule.center_id == ECOACH_ID) {
          this.schedule.type = ONLINE
        }
        this.schedule.branch_id = data.data.branch_id;
        this.getBranches();
      })
    },
    handleCenter() {
      this.getTestersFrees();
      if (this.schedule.center_id == ECOACH_ID) {
        this.schedule.type = ONLINE
      } else {
        this.schedule.type = OFFLINE
      }
      this.schedule.branch_id = '';
      this.schedule.start_time = null
      this.getBranches();
    },
    handleChangeStartDate(value, type) {
      this.getTestersFrees();
      if (type === 'second') {
        this.open = false;
      }
    },
    getAllCenterIsEdutalk() {
      this.$store.dispatch(GET_CENTERS, {
        is_edutalk: 1, status: 1
      }).then((data) => {
        this.centers = data.data.data;
      })
    },
    getBranches() {
      this.loadingBranch = true;
      this.$store.dispatch(GET_BRANCHES, {
        center_id: this.schedule.center_id,
        status: 1,
        limit: 100000
      }).then((data) => {
        this.branches = data.data.data;
      }).finally(()=>{
        this.loadingBranch = false;
      });
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    handleSchedule() {
      let end_date = moment(this.schedule.schedule).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss');
      this.schedule.end_schedule = end_date;
      this.schedule.start_time = null
    },
    handleOk(bvModalEvent) {
      this.store();
    },
    disabledBeforeToday(date) {
      let tempDate = new Date();
      let currentDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0, 0);
      let nextWeek = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate() + (+7 - (tempDate.getDay() == 0 ?7:+tempDate.getDay())));
      return date < currentDate | date > nextWeek;
    },
    afterCreateSuccess(speaking) {
      if (!speaking) {
        return;
      }
      this.isShowLoading = true;
      this.statusSpeaking = speaking.status;
      this.idSpeaking = speaking.id;
      this.seconds = 60;
    },
    loadingSuccess() {
      this.$emit('create-success');
    },
    store() {
      if (this.okDisable) {
        return;
      }
      this.okDisable = true;
      this.$store.dispatch(TEST_INPUT_STORE_TASK_PERSONAL, {
        ...this.schedule,
        start_time: this.schedule.start_time.label + ":00"
      }).then((data) => {
        this.afterCreateSuccess(data.data);
        this.$emit('create-success');
      }).catch((e) => {
        // if (e.response.data.data.message_validate_form) {
        // 	this.$refs.form.setErrors(e.response.data.data.message_validate_form);
        // }
        this.$message.error(`${e.response.data.message}`);
        this.schedule.start_time = null;
        this.schedule.schedule = null;
        this.testerFreeTime = null;
      }).finally(() => {
        setTimeout(() => {
          this.okDisable = false;
        }, 1000);
      });
    },
    // Set phòng ban xử lý

    closeModal() {
      this.isShowLoading = false;
      this.idSpeaking = 0;
      this.statusSpeaking = 0;
      this.$bvModal.hide('test-input-create-modal');
    },
    getUser() {
      this.$store.dispatch(TEST_INPUT_GET_TESTER_BY_USER_ID, {
        keyword: this.currentUser.user.id
      }).then((data) => {
        let tester = data.data.length ? data.data[0] : null;
        if (tester && tester.user) {
          this.users.push(tester.user);
          this.schedule.tester_id = tester.id;
        }
        this.schedule.user_id = this.currentUser.user.id;
      })
    },
    isHourTooBad(hour) {
      let stringHour = `${this.schedule.schedule} ${hour}`
      return new Date(stringHour).getTime() > new Date().getTime() && (this.allSlots[hour] == 2) && (this.schedule.type == OFFLINE)
    },
    clearData() {
      this.checkOnlyBranchTester();
      this.schedule = {
        type_task: TYPE_TASK_TEST_INPUT,
        tester_id: '',
        schedule: moment().format('YYYY-MM-DD'),
        end_schedule: '',
        start_time: '',
        user_id: '',
        type: 2,
        type_exam_id: 0, // Mặc định ielts
        name: '',
        phone: '',
        center_id: '',
        branch_id: '',
        assign_to_me: false,
      };
    }
  }
}
</script>

<style scoped>
.el-select {
  width: 100%;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}
</style>