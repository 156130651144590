<template>
    <div class="card ">
        <div class="card-body">
            <div class="example mb-10">
                <div class="input-group row">
                    <div class="col-md-2">
                        <div class="form-group" data-app>
                            <label>Chọn tháng :
                                <span class="text-danger"></span>
                            </label>
                            <div>
                                <el-date-picker
                                    v-model="show_date"
                                    type="month"
                                    placeholder="Chọn tháng"
                                    @change="saveRange()">
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>Chọn khu vực : <span class="text-danger"></span></label>
                            <el-select v-model="cache_area" filterable @change="selectArea($event)" clearable placeholder="Chọn khu vực">
                                <el-option
                                    v-for="item in areas"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>Chọn chi nhánh : <span class="text-danger"></span></label>
                            <el-select v-model="cache_branch" filterable @change="selectBranch()" clearable placeholder="Chọn chi nhánh">
                                <el-option
                                    v-for="item in branches"
                                    :key="item.id"
                                    :label="item.name_alias ? item.name_alias : item.name "
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label>Nguồn khách hàng :<span class="text-danger"></span></label>
                        <div class="form-group">
                            <el-select v-model="query.nguon" filterable @change="selectSource()" clearable placeholder="Chọn nguồn">
                                <el-option
                                    v-for="item in sources"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>Đội KD :<span class="text-danger"></span></label>
                            <treeselect
                                placeholder="Chọn..." v-model="tree"
                                :multiple="false"
                                :options="sales"
                                :show-count="true"
                                :normalizer="normalizer"
                                @input="selectSale">
                                <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                                <v-avatar size="20" style="border: 1px solid;">
                                    <v-img :src="node.raw.user_id ? node.raw.account_type.image : node.raw.profile.account_type.image"></v-img>
                                </v-avatar>
                                    {{ node.raw.name }}
                                <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                                </label>
                            </treeselect>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label>Xem với tư cách :
                            <span class="text-danger"></span></label>
                        <div class="form-group">
                            <el-select v-model="cache_view_as" filterable @change="selectType()" clearable placeholder="Tư cách">
                                <el-option
                                    v-for="item in typeViews"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <button class="btn btn-primary" @click="searchDataDSKH">Tìm kiếm</button>
                        </div>
                    </div>
                </div>
                <div class="b-table-sticky-column table-responsive">
                    <table role="table" :aria-busy="isBusy" aria-colcount="0"
                           class="table table-hover table-bordered b-table">
                        <tr role="row" class="b-table-busy-slot" v-show="isBusy">
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </tr>
                        <thead>
                        <tr role="row">
                            <th class="table-primary b-table-sticky-column" style="border-bottom: none">Ngày trong
                                tháng
                            </th>
                            <th class="font-size-10" style="min-width: 100px; vertical-align:middle">KQ tháng</th>
                            <th v-show="!isBusy" class="font-size-10" v-for="(day,index) in days" :key="index">{{ day }}
                            </th>
                        </tr>
                        </thead>
                        <tbody v-show="!isBusy">
                        <tr role="row">
                            <td class="table-primary b-table-sticky-column">Số lượng ca đăng kí đi làm</td>
                            <td class="font-size-10"><b>{{ totalStaffOnWork }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                <a href="javascript:" @click="showDetailByDate(day,'dk_lam')">
                                    {{ renderStaffOnWork(day) }}
                                </a>
                            </td>
                        </tr>
                        <tr role="row">
                            <td class="table-primary b-table-sticky-column">Số lượng công thực tế đi làm</td>
                            <td class="font-size-10"><b>{{ totalRealStaffOnWork }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                <a href="javascript:" @click="showDetailByDate(day,'thuc_te_lam')">
                                    {{ renderRealStaffOnWork(day) }}
                                </a>
                            </td>
                        </tr>
                        <tr role="row">
                            <td class="table-primary b-table-sticky-column">Số ca Lead/ngày</td>
                            <td class="font-size-10"><b>{{ totalLeaderOnWork }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                <a href="javascript:" @click="showDetailByDate(day,'so_ca_lead')">
                                    {{ renderLeaderOnWork(day) }}
                                </a>
                            </td>
                        </tr>
                        <tr role="row">
                            <td class="table-primary b-table-sticky-column">Số lịch hẹn</td>
                            <td class="font-size-10"><b>{{ totalScheduledAppointment }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                <a href="javascript:"  @click="showDetailByDate(day,'lich_hen')">
                                    {{renderSchedule(day) }}
                                </a>
                            </td>
                        </tr>
                        <tr role="row">
                            <td class="table-primary b-table-sticky-column">Số lịch hẹn bị hủy</td>
                            <td class="font-size-10"><b>{{ totalScheduledCancel }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                <a href="javascript:" @click="showDetailByDate(day,'lich_hen_huy')">{{ renderScheduleCancel(day) }}</a>
                            </td>
                        </tr>
                        <tr role="row">
                            <td class="table-primary b-table-sticky-column">Số lượng KH lên test</td>
                            <td class="font-size-10"><b>{{ totalCustomerOnTest }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                 @click="showDetailByDate(day,'kh_test')">{{ renderCustomerOnTest(day) }}</a>
                            </td>
                        </tr>
                        <tr role="row">
                            <td title="" class="table-primary b-table-sticky-column">Số quyền lợi <a href="javascript:"
                                                                                                     id="so-quyen-loi"><i
                                class="fas fa-info-circle"></i></a>
                                <b-tooltip target="so-quyen-loi" title="Lần đầu đóng dưới 1200k"
                                           variant="primary"></b-tooltip>
                            </td>
                            <td class="font-size-10"><b>{{ totalBenefit }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                 @click="showDetailByDate(day ,'quyen_loi')">{{ renderBenefit(day, 'so_quyen_loi') }}</a>
                            </td>
                        </tr>
                        <tr role="row">
                            <td title="" class="table-primary b-table-sticky-column">Quyền lợi <a href="javascript:"
                                                                                                  id="quyen-loi"><i
                                class="fas fa-info-circle"></i></a>
                                <b-tooltip target="quyen-loi" title="Doanh số lần đầu đóng dưới 1200k"
                                           variant="primary"></b-tooltip>
                            </td>
                            <td class="font-size-10"><b>{{ totalBenefitAmount | formatVND }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                <a href="javascript:" @click="showDetailByDate(day ,'quyen_loi')">
                                    {{ renderBenefit(day, 'quyen_loi') | formatVND }}
                                </a>
                            </td>
                        </tr>
                        <tr role="row">
                            <td title="" class="table-primary b-table-sticky-column">Số lượng hợp đồng nợ <a
                                href="javascript:" id="hop-dong-no"><i class="fas fa-info-circle"></i></a>
                                <b-tooltip target="hop-dong-no" title="Hợp đồng công nợ" variant="primary"></b-tooltip>
                            </td>
                            <td class="font-size-10"><b>{{ totalDebtContract }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                 @click="showDetailByDate(day ,'hop_dong_no')">{{ renderDebtContract(day, 'so_hop_dong_no') }}</a>
                            </td>
                        </tr>
                        <tr role="row">
                            <td title="" class="table-primary b-table-sticky-column">Doanh số nợ <a href="javascript:"
                                                                                                    id="doanh-so-hop-dong-no"><i
                                class="fas fa-info-circle"></i></a>
                                <b-tooltip target="doanh-so-hop-dong-no" title="Doanh số hợp đồng công nợ"
                                           variant="primary"></b-tooltip>
                            </td>
                            <td class="font-size-10"><b>{{ totalDebtContractAmount | formatVND }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                {{ renderDebtContract(day, 'doanh_so_no') | formatVND }}
                            </td>
                        </tr>
                        <tr role="row">
                            <td title="" class="table-primary b-table-sticky-column">Số lượng hợp đồng mới <a
                                href="javascript:" id="hop-dong-moi"><i class="fas fa-info-circle"></i></a>
                                <b-tooltip target="hop-dong-moi" title="Lần đầu đóng từ 1200k trở lên"
                                           variant="primary"></b-tooltip>
                            </td>
                            <td class="font-size-10"><b>{{ totalNewContract }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                 @click="showDetailByDate(day ,'hop_dong_moi')">{{ renderNewContract(day, 'so_hop_dong_moi') }}</a>
                            </td>
                        </tr>
                        <tr role="row">
                            <td title="" class="table-primary b-table-sticky-column">Doanh số Khách hàng mới <a
                                href="javascript:" id="doanh-so-KH-moi"><i class="fas fa-info-circle"></i></a>
                                <b-tooltip target="doanh-so-KH-moi"
                                           title="Doanh số khách hàng mới đóng lần thứ 2 trở đi trong tháng"
                                           variant="primary"></b-tooltip>
                            </td>
                            <td class="font-size-10"><b>{{ totalNewContractAmount | formatVND }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                 @click="showDetailByDate(day ,'doanh_so_moi')">{{ renderNewContract(day, 'ds_so_hop_dong_moi') | formatVND }}</a>
                            </td>
                        </tr>
                        <tr role="row">
                            <td title="" class="table-primary b-table-sticky-column">Tỷ lệ chốt <a href="javascript:"
                                                                                                   id="ty-le-chot"><i
                                class="fas fa-info-circle"></i></a>
                                <b-tooltip target="ty-le-chot" title="(Số HĐ mới + Số HĐ nội bộ) / Tổng Số KH lên test"
                                           variant="primary"></b-tooltip>
                            </td>
                            <td class="font-size-10"><b>{{ Math.round(contractClosing) }}%</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                {{ renderContractClosing(day) }}%
                            </td>
                        </tr>
                        <tr role="row">
                            <td title="" class="table-primary b-table-sticky-column">Số lượng hợp đồng nội bộ <a
                                href="javascript:" id="hd-noi-bo"><i class="fas fa-info-circle"></i></a>
                                <b-tooltip target="hd-noi-bo" title="Số lượng HĐ có [Mã giới thiệu] = [Mã nhân sự]"
                                           variant="primary"></b-tooltip>
                            </td>
                            <td class="font-size-10"><b>{{ totalContractInternal }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                 @click="showDetailByDate(day ,'hop_dong_noi_bo')">{{ renderContractInternal(day, 'so_hop_dong_noi_bo') ? renderContractInternal(day, 'so_hop_dong_noi_bo') : 0 }}</a>
                            </td>
                        </tr>
                        <tr role="row">
                            <td title="" class="table-primary b-table-sticky-column">Doanh số nội bộ <a
                                href="javascript:"
                                id="ds-noi-bo"><i
                                class="fas fa-info-circle"></i></a>
                                <b-tooltip target="ds-noi-bo"
                                           title="Doanh số hợp đồng có [Mã giới thiệu] = [Mã nhân sự]"
                                           variant="primary"></b-tooltip>
                            </td>
                            <td class="font-size-10"><b>{{ totalContractInternalAmount | formatVND }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                {{ renderContractInternal(day, 'ds_so_hop_dong_noi_bo') | formatVND }}
                            </td>
                        </tr>


                        <tr role="row">
                            <td title="" class="table-primary b-table-sticky-column">Doanh số đội<a href="javascript:"
                                                                                                      id="tong-ds"><i
                                class="fas fa-info-circle"></i></a>
                                <b-tooltip target="tong-ds"
                                           title="Quyền lợi + Doanh số KH mới + Doanh số nợ + Doanh số nội bộ - Rút tiền Đội - Hoàn tiền Đội"
                                           variant="primary"></b-tooltip>
                            </td>
                            <td class="font-size-10"><b>{{ tongDsTeam | formatVND }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                {{ renderDoanhSo(day) | formatVND }}
                            </td>
                        </tr>

                        <tr role="row">
                            <td title="" class="table-primary b-table-sticky-column">Doanh số Digital<a href="javascript:"
                                                                                                      id="tong-ds-digital"><i
                                class="fas fa-info-circle"></i></a>
                                <b-tooltip target="tong-ds-digital"
                                           title="Doanh số Digital = Nạp- Rút - Hoàn Digital"
                                           variant="primary"></b-tooltip>
                            </td>
                            <td class="font-size-10"><b>{{ tongDsTeamDigital | formatVND }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                {{ renderDigital(day) | formatVND }}
                            </td>
                        </tr>

                        <tr role="row">
                            <td title="" class="table-primary b-table-sticky-column">Tổng doanh số<a href="javascript:"
                                                                                                      id="tong-ds"><i
                                class="fas fa-info-circle"></i></a>
                                           <b-tooltip target="tong-ds"
                                           title="Tổng doanh số = Doanh số đội + Doanh số Digital - Rút - Hoàn"
                                           variant="primary"></b-tooltip>
                            </td>
                            <td class="font-size-10"><b>{{ tongDoanhSo | formatVND }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                {{ rendertongDsTeam(day) | formatVND }}
                            </td>
                        </tr>

                        <tr role="row">
                            <td class="table-primary b-table-sticky-column">Số tiền rút</td>
                            <td class="font-size-10"><b>{{ Math.abs(totalWithDraw) | formatVND }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                 @click="showDetailByDate(day ,'rut_tien')">{{ Math.abs(renderRefundMoreThan(day, 'withdraw')) | formatVND }}</a>
                            </td>
                        </tr>
                        <tr role="row">
                            <td class="table-primary b-table-sticky-column">Số tiền hoàn</td>
                            <td class="font-size-10"><b>{{ Math.abs(totalRefund) | formatVND }}</b></td>
                            <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                 @click="showDetailByDate(day ,'hoan_tien')">{{ Math.abs(renderRefundMoreThan(day, 'refund')) | formatVND }}</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <register-on-staff :showModal="showOnStaff" :dataDetail="dataDetail" :type-on-staff="typeOnStaff"
                           @clicked="clickOnStaff"></register-on-staff>
        <schedule-by-date :showModal="showScheduleDetail" :scheduleData="dataDetail" :type-action="typeAction"
                          :titleType="title" @clicked="clickOnSchedule"></schedule-by-date>
        <contract-detail-table :show-modal="showContractDetail" :contract-data="dataDetail" :type-action="typeAction"
                               @clicked="clickOnContractDetail"></contract-detail-table>
    </div>
</template>

<script>
import moment from 'moment';
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import {GET_LIST_AREAS} from "../../../core/services/store/center/area.module";
import {
    DSKH_DOI,
    DSKH_DOI_V2,
    GET_BRANCH_SALES,
    GET_BRANCH_SALES_V2,
    SHOW_DETAIL_BY_DATE, SHOW_DETAIL_BY_DATE_V2
} from "../../../core/services/store/report/report.module";
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import '@/assets/sass/custom/dskh-doi/dskh_doi.scss';

import RegisterOnStaff from "./RegisterOnStaff";
import ScheduleByDate from "./ScheduleByDate";
import ContractDetailTable from "./ContractDetailTable";

const _ = require('lodash');

export default {
    name: "DSKH-Team",
    data() {
        return {
            /* query */
            query: {
                start_date: '',
                branch_id: '',
                area_id: '',
                nguon: '',
                view_as: '',
                sale: '',
                center_id: '',
            },
            /* date-picker */
            dates: [],
            menu: false,
            isBusy: false,
            show_date: '',
            /* options */
            branchesByCenter: [],
            branches: [],
            areas: [],
            sales: [],
            typeViews: [{name: 'Đội KD', value: 1}, {name: 'Cá nhân', value: 2}],
            sources: ["Online Thân", "Online Gần", "Online Xa", "Truyền thông", "Tái đăng ký", "Nhân trước đăng ký", "Nhân sau đăng ký",],
            days: [],
            positions: [{name: 'Sale Expert', value: 5}, {name: 'Sale Member', value: 6}, {
                name: 'Sale Intern',
                value: 7
            }],
            /*Cache */
            cache_status: '',
            cache_current_account_type: '',
            cache_branch: '',
            cache_area: '',
            cache_source: '',
            cache_view_as: '',
            /*Loader */
            isLoading: false,
            fullPage: true,
            tree: null,
            /*payload */
            payload: {
                limit: 1000000,
                status: 1,
                is_with: 0,
                area_id: ''
            },
            /* data response*/
            eventRegisters: [],
            dataDetail: [],
            title: '',
            leadersOnWork: [],
            scheduledAppointments: [],
            scheduledCancels: [],
            customersOnTest: [],
            benefit: [],
            debtContract: [],
            newContract: [],
            contractInternal: [],
            refundMoreThan: [],
            digital: [],
            refund_digital_marketing: [],
            idCreated: [],
            /* is show variable */
            showOnStaff: false,
            typeOnStaff: false,
            showScheduleDetail: false,
            showContractDetail: false,
            typeAction: '',
            /* total variable */
            totalStaffOnWork: 0,
            totalRealStaffOnWork: 0,
            totalLeaderOnWork: 0,
            totalScheduledAppointment: 0,
            totalScheduledCancel: 0,
            totalCustomerOnTest: 0,
            totalBenefit: 0,
            totalBenefitAmount: 0,
            totalDebtContract: 0,
            totalDebtContractAmount: 0,
            totalNewContract: 0,
            totalNewContractAmount: 0,
            totalContractInternal: 0,
            totalContractInternalAmount: 0,
            totalWithDraw: 0,
            totalDigital: 0,
            totalRefund: 0,
            totaDigitalRefunWithdraw: 0,
            totaDigitalRefun: 0,
            contractClosing: 0,
            tongDsTeam: 0,
            tongDsTeamDigital: 0,
            tongDoanhSo: 0,
        };
    },
    components: {
        RegisterOnStaff, ScheduleByDate, ContractDetailTable
    },
    computed: {},
    created() {
        this.isBusy = true;
        this.setParams();
        this.getBranches();
        this.getAreas();
        this.getSalesBranch();
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý tài chính"},
            {title: "Báo cáo DSKH đội"}
        ]);
    },
    methods: {
        /* all get data function */
        getBranches: function () {
            this.$store.dispatch(GET_BRANCHES, this.payload).then((res) => {
                this.branches = res.data.data;
                this.branchesByCenter = res.data.data;
                let branch_id = this.$route.query.branch_id ? this.$route.query.branch_id : '';
                this.cache_branch = _.find(this.branches, function (branch) {
                    return branch.id === parseInt(branch_id);
                });
            });
        },
        getAreas: function () {
            this.$store.dispatch(GET_LIST_AREAS).then((res) => {
                this.areas = res.data;
                let area_id = this.$route.query.area_id ? this.$route.query.area_id : '';
                this.cache_area = _.find(this.areas, function (area) {
                    return area.id === parseInt(area_id);
                });
            });
        },
        searchDataDSKH: function () {
            this.isBusy = true;
            this.getQuery();
        },
        getSalesBranch: async function () {
            this.query.idCreated = [];
            if (this.query.branch_id) {
                await this.$store.dispatch(GET_BRANCH_SALES_V2, this.query).then((res) => {
                    this.sales = res;
                });
            }
        },
        getQuery: async function () {
            this.query.idCreated = [];
            this.query.action = undefined;
            this.query.date = undefined;
            await this.$store.dispatch(DSKH_DOI_V2, this.query).then((res) => {
                this.days = res.data.dataDays;
                this.idCreated = res.data.idCreated;
                this.newContract = res.data.newContract;
                this.eventRegisters = res.data.eventRegister;
                this.leadersOnWork = res.data.leaderRegister;
                this.scheduledAppointments = res.data.scheduledAppointment;
                this.customersOnTest = res.data.customersOnTest;
                this.scheduledCancels = res.data.scheduledCancel;
                this.digital = res.data.DS_digital;
                this.refund_digital_marketing = res.data.refund_digital;
                this.benefit = res.data.benefit;
                this.debtContract = res.data.debtContract;
                this.contractInternal = res.data.contractInternal;
                this.refundMoreThan = res.data.refund;
                this.isBusy = false;
                this.countTotalStaffOnWork();
            });
        },
        /* all select function */
        selectBranch: function () {
            this.query.branch_id = this.cache_branch;
            this.getSalesBranch();
            this.pushParams();
        },
        selectArea: function () {
            console.log(this.cache_area);
            this.payload.area_id = this.cache_area;
            this.query.area_id = this.cache_area;
            this.$store.dispatch(GET_BRANCHES, this.payload).then((res) => {
                this.branches = res.data.data;
                this.branchesByCenter = res.data.data;
            });
            this.cache_branch = '';
            this.query.branch_id = '';
            this.pushParams();
        },
        selectSource: function () {
            this.pushParams();
        },
        selectType: function ($event) {
            this.query.view_as = this.cache_view_as
            this.pushParams();
        },
        selectSale: function ($event) {
            this.query.sale = $event ? $event : '';
            this.pushParams();
        },
        saveRange: function () {
            let start = moment(this.show_date).format('YYYY-MM-DD');
            this.query.start_date = start;
            this.pushParams();
        },
        setParams: async function () {
            this.query.branch_id = this.$route.query.branch_id ? this.$route.query.branch_id : '';
            this.query.area_id = this.$route.query.area_id ? this.$route.query.area_id : '';
            let view_as = this.$route.query.view_as ? this.$route.query.view_as : '';
            this.cache_view_as = _.find(this.typeViews, function (obj) {
                return obj.value === parseInt(view_as);
            })
            this.query.sale = this.$route.query.sale ? this.$route.query.sale : '';
            this.show_date = this.query.start_date = this.$route.query.start_date ? this.$route.query.start_date : '';
            this.query.nguon = this.$route.query.nguon ? this.$route.query.nguon : '';
            this.getQuery();
        },
        pushParams: function () {
            this.query.idCreated = [];
            this.$router.push({path: '/reports/dskh-doi', query: this.query}).catch(()=> {});
        },
        onCancel: function () {
            console.log('User cancelled the loader.')
        },
        normalizer: function (node) {
            return {
                id: node.id,
                label: node.name,
            }
        },
        getFullDate: function (date) {
            let year  = moment(this.show_date).format('YYYY');
            date += '/' + year;
            return date;
        },
        /* all click function */
        showDetailByDate: async function (date, action) {
            this.query.date = this.getFullDate(date);
            this.query.idCreated = this.idCreated;
            this.query.action = action;
            await this.$store.dispatch(SHOW_DETAIL_BY_DATE_V2, this.query).then((res) => {
                this.dataDetail = res.data;
            });
            switch (action) {
                case 'dk_lam' :
                    this.typeOnStaff = 'dk_lam';
                    this.showOnStaff = true;
                    break;
                case 'thuc_te_lam' :
                    this.typeOnStaff = 'thuc_te_lam';
                    this.showOnStaff = true;
                    break;
                case 'so_ca_lead' :
                    this.typeOnStaff = 'so_ca_lead';
                    this.showOnStaff = true;
                    break;
                case 'lich_hen' :
                    this.title = 'Danh sách lịch hẹn trong ngày';
                    this.typeAction = 'lich_hen';
                    this.showScheduleDetail = true;
                    break;
                case 'lich_hen_huy' :
                    this.title = 'Danh sách lịch hẹn hủy';
                    this.typeAction = 'lich_hen_huy';
                    this.showScheduleDetail = true;
                    break;
                case 'kh_test' :
                    this.title = 'Danh sách khách hàng lên test';
                    this.typeAction = 'kh_test';
                    this.showScheduleDetail = true;
                    break;
                case 'quyen_loi' :
                    this.typeAction = 'quyen_loi';
                    this.showContractDetail = true;
                    break;
                case 'hop_dong_no' :
                    this.typeAction = 'hop_dong_no';
                    this.showContractDetail = true;
                    break;
                case 'hop_dong_moi' :
                    this.typeAction = 'hop_dong_moi';
                    this.showContractDetail = true;
                    break;
                case 'doanh_so_moi' :
                    this.typeAction = 'doanh_so_moi';
                    this.showContractDetail = true;
                    break;
                case 'hop_dong_noi_bo' :
                    this.typeAction = 'hop_dong_noi_bo';
                    this.showContractDetail = true;
                    break;
                case 'hoan_tien' :
                    this.typeAction = 'hoan_tien';
                    this.showContractDetail = true;
                    break;
                case 'rut_tien' :
                    this.typeAction = 'rut_tien';
                    this.showContractDetail = true;
                    break;
            }
        },
        clickOnStaff: function () {
            this.showOnStaff = false;
        },
        clickOnSchedule: function () {
            this.showScheduleDetail = false;
        },
        clickOnContractDetail: function () {
            this.showContractDetail = false;
        },
        /* all render function */
        renderData: function (day, data, type = '') {
            let value = _.find(data, function (item) {
                return item.day === day;
            });
            if (type === 'so_quyen_loi' || type === 'so_hop_dong_no' || type === 'so_hop_dong_moi' || type === 'so_hop_dong_noi_bo') {
                return value ? value.total : 0;
            } else if (type === 'quyen_loi' || type === 'doanh_so_no' || type === 'ds_so_hop_dong_moi' || type === 'ds_so_hop_dong_noi_bo') {
                return value ? value.amount : 0;
            } else if (type === 'withdraw') {
                return value && value.withdraw ? value.withdraw : 0;
            } else if (type === 'refund') {
                return value && value.refund ? value.refund : 0;
            } else {
                return value ? value.number : 0;
            }
        },
        renderStaffOnWork: function (day) {
            return this.renderData(day, this.eventRegisters);
        },
        renderRealStaffOnWork: function (day) {
            let register = _.find(this.eventRegisters, function (item) {
                return item.day === day;
            });
            return register ? register.attendance : 0;
        },
        renderLeaderOnWork: function (day) {
            return this.renderData(day, this.leadersOnWork);
        },
        renderSchedule: function (day) {
            return this.renderData(day, this.scheduledAppointments);
        },
        renderScheduleCancel: function (day) {
            return this.renderData(day, this.scheduledCancels);
        },
        renderCustomerOnTest: function (day) {
            return this.renderData(day, this.customersOnTest);
        },

        renderBenefit: function (day, type) {
            return this.renderData(day, this.benefit, type);
        },

        renderDigital: function (day) {
            return this.renderData(day, this.digital);
        },

        renderDebtContract: function (day, type) {
            return this.renderData(day, this.debtContract, type);
        },

        renderNewContract: function (day, type) {
            return this.renderData(day, this.newContract, type);
        },
        renderContractInternal: function (day, type) {
            return this.renderData(day, this.contractInternal, type);
        },
        renderContractClosing: function (day) {
            return this.renderCustomerOnTest(day) !== 0 ? Math.round((this.renderNewContract(day, 'so_hop_dong_moi') + this.renderContractInternal(day, 'so_hop_dong_noi_bo')) / this.renderCustomerOnTest(day) * 100) : 0;
        },
        renderRefundMoreThan: function (day, type) {
            return this.renderData(day, this.refundMoreThan, type);
        },

        renderDoanhSo: function (day) {
            // Quyền lợi + Doanh số KH mới + Doanh số nợ + Doanh số nội bộ - Rút tiền - Hoàn tiền
            return this.renderBenefit(day, 'quyen_loi') + this.renderNewContract(day, 'ds_so_hop_dong_moi')
                + this.renderDebtContract(day, 'doanh_so_no') + this.renderContractInternal(day, 'ds_so_hop_dong_noi_bo')
                - this.renderRefundMoreThan(day, 'withdraw') - this.renderRefundMoreThan(day, 'refund');
        },
        
        tongDoanhSo: function (day) {
            return this.renderBenefit(day, 'quyen_loi') + this.renderNewContract(day, 'ds_so_hop_dong_moi') + this.renderDigital(day, 'digital')
                + this.renderDebtContract(day, 'doanh_so_no') + this.renderContractInternal(day, 'ds_so_hop_dong_noi_bo')
                - this.renderRefundMoreThan(day, 'withdraw') - this.renderRefundMoreThan(day, 'refund');
        },

        rendertongDsTeam: function (day) {
            return this.renderBenefit(day, 'quyen_loi') + this.renderNewContract(day, 'ds_so_hop_dong_moi') + this.renderDigital(day, 'digital')
                + this.renderDebtContract(day, 'doanh_so_no') + this.renderContractInternal(day, 'ds_so_hop_dong_noi_bo')
                - this.renderRefundMoreThan(day, 'withdraw') - this.renderRefundMoreThan(day, 'refund');
        },


        /* all sum total function */
        countTotalStaffOnWork: function () {
            this.totalStaffOnWork = _.reduce(this.eventRegisters, function (result, item, key) {
                result += item.number;
                return result;
            }, 0);
            this.totalRealStaffOnWork = _.reduce(this.eventRegisters, function (result, item, key) {
                result += item.attendance;
                return result;
            }, 0);
            this.totalLeaderOnWork = _.reduce(this.leadersOnWork, function (result, item, key) {
                result += item.number;
                return result;
            }, 0);
            this.totalScheduledAppointment = _.reduce(this.scheduledAppointments, function (result, item, key) {
                result += item.number;
                return result;
            }, 0);
            this.totalScheduledCancel = _.reduce(this.scheduledCancels, function (result, item, key) {
                result += item.number;
                return result;
            }, 0);
            this.totalCustomerOnTest = _.reduce(this.customersOnTest, function (result, item, key) {
                result += item.number;
                return result;
            }, 0);
            this.totalBenefit = _.reduce(this.benefit, function (result, item, key) {
                result += item.total;
                return result;
            }, 0);
            this.totalBenefitAmount = _.reduce(this.benefit, function (result, item, key) {
                result += item.amount;
                return result;
            }, 0);

            this.tongDsTeamDigital = _.reduce(this.digital, function (result, item, key) {
                result += item.number;
                return result;
            }, 0);
            this.totalNewContract = _.reduce(this.newContract, function (result, item, key) {
                result += item.total;
                return result;
            }, 0);
            this.totalNewContractAmount = _.reduce(this.newContract, function (result, item, key) {
                result += item.amount;
                return result;
            }, 0);
            this.totalDebtContract = _.reduce(this.debtContract, function (result, item, key) {
                result += item.total;
                return result;
            }, 0);
            this.totalDebtContractAmount = _.reduce(this.debtContract, function (result, item, key) {
                result += item.amount;
                return result;
            }, 0);
            this.totalContractInternal = _.reduce(this.contractInternal, function (result, item, key) {
                let total = item.total ? item.total : 0;
                result += total;
                return result;
            }, 0);
            this.totalContractInternalAmount = _.reduce(this.contractInternal, function (result, item, key) {
                result += item.amount;
                return result;
            }, 0);
            this.totalRefund = _.reduce(this.refundMoreThan, function (result, item, key) {
                if (item.refund) {
                    result += item.refund;
                }
                return result;
            }, 0);
            this.totalWithDraw = _.reduce(this.refundMoreThan, function (result, item, key) {
                if (item.withdraw) {
                    result += item.withdraw;
                }
                return result;
            }, 0);

            this.totaDigitalRefunWithdraw = _.reduce(this.refund_digital_marketing, function (result, item, key) {
                if (item.withdraw) {
                    result += item.withdraw;
                }
                return result;
            }, 0);

            this.totaDigitalRefun = _.reduce(this.refund_digital_marketing, function (result, item, key) {
                if (item.refund) {
                    result += item.refund;
                }
                return result;
            }, 0);
            
            this.contractClosing = this.totalCustomerOnTest > 0 ? (this.totalNewContract + this.totalContractInternal) / this.totalCustomerOnTest * 100 : 0;

         

            //Quyền lợi + Doanh số KH mới + Doanh số nợ + Doanh số nội bộ - Rút tiền - Hoàn tiền
            this.tongDsTeam = this.totalBenefitAmount + this.totalNewContractAmount + this.totalDebtContractAmount + this.totalContractInternalAmount - this.totalWithDraw - this.totalRefund;

            this.tongDsTeamDigital = this.tongDsTeamDigital;

               // tổng ds digital + tổng ds team
               this.tongDoanhSo = this.tongDsTeam + this.tongDsTeamDigital;
        }
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
