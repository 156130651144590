<template>
  <div>
    <div class="container header_detail d-flex justify-content-between py-5">
      <h1>Chi tiết tác vụ</h1>
      <div class="d-flex">
        <button v-if="informationTask.status == STATUS_TO_DO && ((informationTask.testFormID==ONLINE && isTester)|| !isTester) && informationTask.taskItem == TYPE_TASK_ITEM_SPEAKING"  :disabled="disableCancelSchedule" type="button" class="btn btn-danger mr-2 btn-sm" v-b-modal="`cancel-schedule`">Hủy tác vụ</button>
<!--        <button v-if="informationTask.testForm === 'Offline' && informationTask.taskItem == TYPE_TASK_ITEM_SPEAKING " :disabled="informationTask.come_check_date != ''"-->
<!--                type="button" class="btn btn-success mr-2 btn-sm" @click="testSpeaking()">Test nói-->
<!--        </button>-->
        <button
            v-if="informationTask.status == STATUS_TO_DO && informationTask.taskItem==TYPE_TASK_ITEM_SPEAKING && informationTask.testFormID==ONLINE"
            type="button" class="btn btn-success btn-sm" style="margin-right: 10px" @click="openTest()"><i class="el-icon-video-camera"></i> Vào test
        </button>
        <button
            v-if="[STATUS_TO_DO].includes(informationTask.status) && !(typeItemId === undefined)"
            type="button" class="btn btn-primary btn-sm"
            @click="markTheExam()"><i class="el-icon-edit"></i> Chấm bài
        </button>
        <button         v-if=" informationTask.taskItem!=TYPE_TASK_ITEM_SPEAKING"  type="button" class="btn btn-success ml-2 btn-sm" v-b-modal="`watch-writing`">Xem lại bài general</button>
        <button v-if="statusTest && !isTester" type="button" class="btn btn-info ml-2 btn-sm" v-b-modal="`redo-general`" ><i class="el-icon-refresh-left"></i> Làm lại General</button>
        <button v-if="informationTask?.status === STATUS_CANCEL && !isTester && informationTask?.testFormID===ONLINE"  type="button" class="btn btn-primary ml-2 btn-sm" v-b-modal="`open-speaking-test`" ><i class="el-icon-switch-button"></i> Mở lại Speaking</button>
      </div>
    </div>
    <hr>
    <ModalUpdateTaskInformation :informationTask="informationTask"></ModalUpdateTaskInformation>
    <ModalWatchWriting :informationTask="informationTask"></ModalWatchWriting>
    <ModalCancelScheduleTester v-if="isTester" @cancelSchedule="cancelSchedule"></ModalCancelScheduleTester>
    <ModalCancelSchedule v-else :customerName="customerName" @cancelSchedule="cancelSchedule"></ModalCancelSchedule>
    <ModalWarningNotInGrade :gradedUserName="gradedUserName" :informationTask="informationTask"></ModalWarningNotInGrade>
    <ModalRedoGeneral :informationTask="informationTask" :isIelts="isIelts" ></ModalRedoGeneral>
    <ModalOpenSpeakingTest @open_re_speaking="open_re_speaking()"></ModalOpenSpeakingTest>
  </div>
</template>

<script>
import router from "@/router";
import {
  MARK_SPEAKING, OFFLINE,
  ONLINE, STATUS_CANCEL,
  STATUS_SUCCESS,
  STATUS_TO_DO, TYPE_TASK_ITEM_GENERAL,
  TYPE_TASK_ITEM_SPEAKING
} from "@/core/option/testInputOption";
import {CHECK_TEST_IS_GRADED, COME_CHECK_GENERAL} from "@/core/services/store/test/testInput.module";
import ModalCancelSchedule from "@/view/pages/test-input/components/ModalCancelSchedule.vue";
import ModalUpdateTaskInformation
  from "@/view/pages/test-input/components/ComponentsTaskDetail/ModalUpdateTaskInformation.vue";
import {Cambridge_Mentor_ID} from "@/core/option/typeCenterBill";
import ModalCancelScheduleTester from "@/view/pages/test-input/components/ModalCancelScheduleTester.vue";
import ModalWatchWriting from "@/view/pages/test-input/components/ModalWatchWriting.vue";
import ModalWarningNotInGrade from "@/view/pages/test-input/components/ModalWarningNotInGrade";
import ModalRedoGeneral from "@/view/pages/test-input/components/ModalRedoGeneral.vue";
import ModalOpenSpeakingTest from "@/view/pages/test-input/components/ModalOpenSpeakingTest.vue";
export default {
  name: "HeaderTaskDetail",
  components: {
    ModalOpenSpeakingTest,
    ModalRedoGeneral,
		ModalWarningNotInGrade,
		ModalUpdateTaskInformation, ModalCancelSchedule,ModalCancelScheduleTester,ModalWatchWriting},
  data() {
    return {
      MARK_SPEAKING: MARK_SPEAKING,
      data: {},
      STATUS_SUCCESS: STATUS_SUCCESS,
      STATUS_TO_DO: STATUS_TO_DO,
			STATUS_CANCEL: STATUS_CANCEL,
      ONLINE: ONLINE,
      OFFLINE: OFFLINE,
			TYPE_TASK_ITEM_SPEAKING : TYPE_TASK_ITEM_SPEAKING,
      isTestGraded: false,
      gradedUserName:'',
      SCORED: 1003
    }
  },
  props: {
    typeItemId: {
      type: Number,
      default() {
        return undefined
      }
    },
    customerName: {
      type: String,
      default() {
        return " "

      }
    },
    customerPhone: {
      type: String,
      default() {
        return ""
      }
    },
    linkTestOnline: {
      type: String,
      default() {
        return ""
      }
    },
    informationTask: {
      type: Object,
      default() {
        return {}
      }
    },
    statusTest: {
      type: Boolean,
      default() {
        return false
      }
    },
    isTester:{
      type: Boolean,
      default(){
        return false
      }
    },
    isIelts:{
      type: Boolean,
      default(){
        return false
      }
    }
  },
  methods: {
    testSpeaking() {
      this.$confirm('Bạn có chắc chắn bắt đầu ca test nói với khách hàng không?', 'Vào test nói offline', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy bỏ',
        type: ''
      }).then(() => {
        this.$store.dispatch(COME_CHECK_GENERAL, {
          id: this.informationTask.taskCode
        }).then((data) => {
          this.$emit('comeCheckSpeakingSuccess');
          this.$message.success("Bắt đầu test nói thành công");
        });
      }).catch(() => {
      });
    },
    markTheExam() {
       if(this.informationTask.testFormID !== ONLINE && +this.informationTask.taskItem === TYPE_TASK_ITEM_SPEAKING ){
       this.$bvModal.show('my-modal')
       return;
     }
      if(+this.informationTask.taskItem === TYPE_TASK_ITEM_GENERAL){
        this.checkTestIsGraded();
        return;
      }
      this.navigateGradePage()
    },
    comeCheck() {
      this.$store.dispatch(COME_CHECK_GENERAL, {
        id: this.informationTask.taskCode
      }).then((res) => {
        this.$emit('comeCheck')
      });
    },
    openTest() {
      this.comeCheck();
      window.open(`${this.linkTestOnline}`)
    },
    checkTimeInTest(time) {
      let current_date = new Date().getTime();
      let timeLimitToTest = Date.parse(this.informationTask.fullTimeStart) + (10 * 60000)
      let timeToTest = Date.parse(this.informationTask.fullTimeStart) - (5 * 60000)
      return (current_date >= timeToTest && current_date <= timeLimitToTest)
    },
    cancelSchedule(){
      this.$emit('cancelSchedule')
    },
    checkTestIsGraded(){
    this.$store.dispatch(CHECK_TEST_IS_GRADED,this.$route.params.id).then((res)=>{
      if(!res.error){
       this.navigateGradePage()
      }
    }).catch((e)=>{
      if(e?.data?.message === 'Bạn không có quyền chấm điểm'){
        if(+e?.data?.error_code === this.SCORED){
          location.reload();
          return
        }
        this.gradedUserName = e?.data?.data?.current_scorer_name
        this.$bvModal.show('warning-graded')
      }
    })
    },
    navigateGradePage(){
      let test_input_schedule_id = this.$route.params.id;
      router.push({
        name: 'mark-the-exam',
        params: {id: test_input_schedule_id},
        query: {typeMark: this.typeItemId, name: this.customerName, phone: this.customerPhone}
      })
    },
    open_re_speaking(){
      this.$emit('open_re_speaking')
    }

  },
  mounted() {
    this.data = this.informationTask

  },
  computed: {
    disableCancelSchedule(){
      let current_date = new Date().getTime();
      let start_time = Date.parse(this.informationTask.fullTimeStart)
      let timeLimitToTest = Date.parse(this.informationTask.fullTimeStart) + (10 * 60000)
      if(this.isTester){
        return (current_date < timeLimitToTest)
      }
     return (current_date > start_time)

    }
  }
}
</script>

<style scoped>

</style>