<template>
    <div>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(handleCreatePromotion)">
          <div style="width: 100%; height: 1px; background: gray; margin-top: -20px; margin-bottom: 20px"></div>
          <div style="display: grid; grid-template-columns: 6fr 6fr; gap: 40px">
            <ValidationProvider name="Tên chương trình" rules="required|max:50" v-slot="{ errors,classes }" vid="name">
              <div style="display: flex; flex-direction: column; gap: 10px">
                <span class="page-filter__title">Tên chương trình <span style="color: red">*</span></span>
                <el-input size="medium" v-model="query.name" placeholder="Nhập tên chương trình"/>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider name="Thời gian" rules="required" v-slot="{ errors, classes }" vid="date">
              <div style="display: flex; flex-direction: column; gap: 10px">
                <span class="page-filter__title">Thời gian <span style="color: red">*</span></span>
                  <el-date-picker
                    v-model="query.date_range"
                    type="datetimerange"
                    start-placeholder="Thời gian bắt đầu"
                    end-placeholder="Thời gian kết thúc"
                    format="dd-MM-yyyy HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00']">
                  </el-date-picker>
                <div class="fv-plugins-message-container">
                  <div data-field="date" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div style="display: flex; flex-direction: column; gap: 10px; margin-top: 15px">
            <span class="page-filter__title">Mô tả</span>
            <el-input size="small" type="textarea" v-model="query.description" placeholder="Nhập mô tả"/>
          </div>
          <div style="display: flex; justify-content: end; margin-top: 30px; gap: 10px">
            <el-button style="background: #CACACA" @click="closeDialog()">Hủy bỏ</el-button>
            <button
                :disabled="loading"
                type="submit"
                style="background: #5D04B0; padding: 0 10px; border-radius: 5px; color: white; position:relative;"
            >
              <i v-if="loading" class="el-icon-loading text-white float-righ"></i>
              Xác nhận
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </template>
  
  <script>
  import { CREATE_PROMOTION_PROGRAM_GENZ } from '../../../../core/services/store/rewards-programs/rewardsPrograms.module';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  export default {
    components: {
      DatePicker
    },
    data() {
      return {
        query: {
          name: '',
          date_range: '',
          description: '',
        },
        loading: false,
      }
    },

    props: {
      is_show: {
        type: Boolean,
        default: false,
      },
    },

    watch: {
      is_show: {
      handler(newVal) {
          if(!newVal) {
            this.clearFrom();
          }
          
      },
    
    },
  },

    methods: {
      closeDialog() {
        this.loading = false;
        this.$emit('closeDialog', 'create')
      },
      handleCreatePromotion() {
        this.loading = true
        const payload = {
          name: this.query.name,
          start_date:this.query.date_range[0],
          end_date:this.query.date_range[1],
          description: this.query.description,
          program_type: 3,
          center_id: 531,
          is_genz: 1
        };

        this.$store.dispatch(CREATE_PROMOTION_PROGRAM_GENZ, payload).then((data) => {
            if(!data.error) {
              this.$notify({
              title: 'Thành công!',
              message: 'Thêm chương trình khuyến mại thành công',
              type: 'success'
            });
            }else {
              this.$notify({
              title: 'Thất bại',
              message: 'Đã tồn tại chương trình nằm trong khoảng thời gian này. Vui lòng chọn lại ',
              type: 'error'
            });
            }
            this.closeDialog();
          }).catch((e) => {
            this.$notify({
              title: 'Thất bại',
              message: 'Thêm chương trình khuyến mại bại ',
              type: 'error'
            });
          })
          this.loading = false;
          this.$emit('closeDialog', 'create')
        },

      clearFrom() {
          this.query.name = '';
          this.query.date_range = '';
          this.query.description = '';
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>