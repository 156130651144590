import ApiService from "@/core/services/api.service";
import {DELETE_CONTRACT, UPDATE_LIST_CONTRACT_WHEN_DELETE} from "@/core/services/store/contract/contracts.module";

//action types
export const LIST_CONTRACT_PROGRAMS = "contract/list-refund-program";
export const CREATE_CONTRACT_PROGRAMS = "contract/create-refund-program";

//mutations types
export const SET_CONTRACT_PROGRAMS = "setContractPrograms";
export const ADD_CONTRACT_PROGRAMS = "addContractProgram";
export const CREATE_REFUND_PROGRAM = "refund-program";
export const UPDATE_REFUND_PROGRAM = "refund-program-update";
export const LIST_CENTER = "refund-program/list-center";
export const LIST_REFUND_PROGRAM = "list-refund-program";
export const DELETE_REFUND_PROGRAM = "delete-refund";
export const GET_CONTRACT_STUDENT_REFUND = "get-contract-student-refund";
export const LIST_REFUND_PROGRAM_BY_BRANCH = "get-contract-student-refund-by-branch";

export const REWARD_PROGRAMS = "spin/rewardPrograms";
export const CREATE_REWARD_PROGRAMS = "spin/create-rewardPrograms";
export const UPDATE_REWARD_PROGRAMS = "spin/update-rewardPrograms";
export const DELETE_REWARD_PROGRAMS = "spin/delete-rewardPrograms";


const state = {
    errors: null,
    contractPrograms: [],
};

const getters = {
    getAllPrograms(state) {
        return state.contractPrograms;
    },
};

const actions = {
    [LIST_CONTRACT_PROGRAMS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${LIST_CONTRACT_PROGRAMS}`, payload.id).then(({data}) => {
                context.commit(SET_CONTRACT_PROGRAMS, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [REWARD_PROGRAMS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${REWARD_PROGRAMS}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [CREATE_REWARD_PROGRAMS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${REWARD_PROGRAMS}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_REWARD_PROGRAMS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${REWARD_PROGRAMS}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_REWARD_PROGRAMS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${REWARD_PROGRAMS}`, payload.id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [CREATE_REFUND_PROGRAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${CREATE_REFUND_PROGRAM}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_REFUND_PROGRAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(`${CREATE_REFUND_PROGRAM}`,payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [DELETE_REFUND_PROGRAM](context, id) {
        return new Promise(resolve => {
            ApiService.delete(CREATE_REFUND_PROGRAM, id).then(({data}) => {
                context.commit(UPDATE_LIST_CONTRACT_WHEN_DELETE, id);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [LIST_CENTER](context) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${LIST_CENTER}`).then(({data}) => {
                context.commit(SET_CONTRACT_PROGRAMS, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_CONTRACT_STUDENT_REFUND](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_CONTRACT_STUDENT_REFUND}`,payload).then(({data}) => {
                context.commit(SET_CONTRACT_PROGRAMS, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [LIST_REFUND_PROGRAM](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${LIST_REFUND_PROGRAM}`,payload).then(({data}) => {
                context.commit(SET_CONTRACT_PROGRAMS, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [LIST_REFUND_PROGRAM_BY_BRANCH](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${LIST_REFUND_PROGRAM_BY_BRANCH}`,payload).then(({data}) => {
                context.commit(SET_CONTRACT_PROGRAMS, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [CREATE_CONTRACT_PROGRAMS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${CREATE_CONTRACT_PROGRAMS}`, payload).then(({data}) => {
                context.commit(ADD_CONTRACT_PROGRAMS, data.data)
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

};


const mutations = {
    [SET_CONTRACT_PROGRAMS](state, data) {
        state.contractPrograms = data;
        state.errors = {};
    },
    [ADD_CONTRACT_PROGRAMS](state, data) {
        state.contractPrograms.push(data);
        state.errors = {};
    },

};

export default {
    state,
    actions,
    mutations,
    getters
};
