<template>
   <div>

   </div>
</template>

<script>
    export default {
        name: "CrmTicketCreate"
    }
</script>

<style scoped>

</style>