<template>
  <div class="entranceTestSchedule ">
    <div class="card card-custom gutter-b ">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          Lịch trực test đầu vào
        </div>
      </div>
      <div class="card-body">
        <div>
          <div class="row">
            <div class="form-group col-xl-3 col-lg-12 ">
              <label class="d-block">Tuần làm việc</label>
              <el-date-picker
                  @change="getTestingSchedule()"
                  class="w-100"
                  v-model="query.date_week"
                  clearable
                  type="week"
                  :format="dateRange"
                  placeholder="Chọn ngày"
                  size="small"
                  :first-day-of-week="1"
              />
            </div>
            <div class="form-group col-xl-3 col-lg-12 ">
              <label class="d-block">Hình thức trực test</label>
              <el-select v-model="typeTest" size="small" class="w-100"
                         @change="getTestingSchedule()"
              >
                <el-option
                    v-for="(item,index) in typeTests"
                    :key="index"
                    :label="item.name"
                    :value="item.id"

                />
              </el-select>
              <h5 v-if="typeTest == null" class="mt-2 text-danger">Vui lòng hình thức trực test!</h5>
            </div>
            <div v-if="typeTest==OFFLINE" class="form-group col-xl-3 col-lg-12 ">
              <label class="d-block">Cơ sở</label>
              <el-select v-model="branch" size="small" class="w-100"
                         filterable
                         @change="getTestingSchedule()"
              >
                <el-option
                    v-for="(item,index) in allBranches"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
              <h5 v-if="branch == ``" class="mt-2 text-danger">Vui lòng chọn cơ sở để xem!</h5>
            </div>
          </div>
          <div class="on-call-schedule">
            <EntranceTestScheduleItem :days="days" :testingSchedule="testingSchedule" :typeTest="typeTest" :branch="branch"></EntranceTestScheduleItem>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EntranceTestScheduleItem from "@/view/pages/test-input/components/EntranceTestScheduleItem";
import {GET_ALL_BRANCHES, GET_BRANCHES} from "@/core/services/store/center/branch.module";
import moment from "moment-timezone";
import {daysOfWeek} from "@/core/option/dayOption";
import {GET_LIST_TESTER_ON_CALL_SCHEDULE} from "@/core/services/store/test/testInput.module";
import {OFFLINE} from "@/core/option/testInputOption";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_CENTERS} from "@/core/services/store/center/centers.module";


export default {
  name: "EntranceTestSchedule",
  components: {EntranceTestScheduleItem},
  data() {
    return {
      allBranches: [],
      testingSchedule: {},
      branch: "",
      query: {
        date_week: moment().startOf('week').add(1,'days'),
      },
      daysOfWeek: daysOfWeek,
      listTester: [],
      typeTests : [
        {
          id: 1,
          name: "ONLINE"
        },
        {
          id: 2,
          name: "OFFLINE"
        },],
      typeTest : null,
      OFFLINE:OFFLINE

    }
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý admin"},
      {title: "Lịch trực test đầu vào"}
    ]);
  },
  watch:{
    typeTest(){
      this.branch = ""
    }
  },
  async mounted() {
    await this.getAllCenterIsEdutalk()
  },
  methods: {
    getAllCenterIsEdutalk() {
      this.$store.dispatch(GET_CENTERS, {
        is_edutalk: 1
      }).then((data) => {
        let ids = data.data.data.map((branch)=>branch.id);
        this.getAllBranches(ids);
      })
    },
    getAllBranches(ids) {
      this.$store.dispatch(GET_BRANCHES, {
        center_ids: ids ,
				status : 1,
				limit : 1000
      }).then((data) => {
        if (!data.error) {
          this.allBranches = data.data.data;
        }
      })
    },
    async getTestingSchedule() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let startWeek = moment(this.query.date_week);
      let payload = {
        date: startWeek.format('YYYY-MM-DD'),
        branch_id: this.branch,
        type:this.typeTest
      }
      this.$store.dispatch(GET_LIST_TESTER_ON_CALL_SCHEDULE, payload).then((data) => {
        if (!data.error) {
          this.testingSchedule = data.data
          return
        }
        return {}
      }).finally(()=>{
        loading.close();
      })
    },
    nameIdTester(id, name) {
      return `${id} - ${name}`
    },


  },
  computed: {
    dateRange() {
      console.log(this.query.date_week);
      let startWeek = moment(this.query.date_week);
      let endWeek = startWeek.clone().endOf('week').add(1,'day');
      return startWeek.format('DD/MM/YYYY') + ' - ' + endWeek.format('DD/MM/YYYY')
    },
    days() {
      let result = [];
      if (this.query.date_week == null) {
        this.query.date_week = moment().startOf('week')
        this.getTestingSchedule()
      }
      let startWeek = moment(this.query.date_week);
      for (let i = 0; i < 7; i++) {
        let tomorrow = startWeek.clone().add(i, 'days');

        result.push({
          day: tomorrow.clone().format('DD-MM-YYYY'),
          dayOfWeek: this.daysOfWeek[tomorrow.clone().day()]
        })
      }
      return result;
    },
    testers() {
      if (Object.keys(this.testingSchedule).length === 0) {
        return null
      }
      let data = Object.values(this.testingSchedule)?.map((element) => Object.values(element))?.reduce((a, b) => a.concat(b))?.reduce((a, b) => a.concat(b));
      let obj = {};
      return data.filter((value, index, self) => index === self.findIndex((t) => (t.user_id === value.user_id )))
    },


  }
}
</script>

<style scoped>
h5 {
  font-size: 13px;
}

hr {
  border: 1px solid grey;
  margin: 0 0 20px 0px;
}

.branch {
  max-width: max-content;
}
</style>