<template>
  <div style="width: 100%">
    <div class="example mb-10">
      <div class="example-preview table-responsive">
        <table class="table table-vertical-center">
          <thead>
          <tr>
            <th class="text-center">Ca làm việc</th>
            <th>Tất cả</th>
            <th class="calendar text-center" v-for="(item, index) in days" :key="index">{{ item.dayOfWeek }}<br> <span
                class="text-secondary" style="font-size: 10px">({{ item.day }})</span></th>
          </tr>
          <tr v-for="(item, index) in newData" :key="index">
            <td>
              <span>{{ CA_LAM_VIEC[item[0].name] }}</span>
            </td>
            <td class="text-center" v-for="(v, i) in item" :key="i">
              <div v-if="v==null">

              </div>
              <el-checkbox
                  v-else-if="v.type =='title'"
                  v-model="checkedAll[v.name]"
                  @input="handleCheckAll(index,item[0].name)"
              >
              </el-checkbox>

              <el-checkbox
                  v-else
                  v-model="v.value"
                  :disabled="v.is_disable"
                  :title="v.is_disable ? 'Không thể thay đổi lịch những ca đã qua hoặc đã được gán tác vụ.' : ''"
                  @input="checkIsSelectedAll(index,item[0].name)"
              >
              </el-checkbox>
            </td>
          </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>

import {CA_LAM_VIEC, LOCATION_TESTER_CALENDAR} from "@/core/option/testInputOption";
import {TEST_INPUT_SAVE_CALENDAR} from "@/core/services/store/test/testInput.module";
import moment from "moment-timezone";

export default {
  name: "CalendarItemOnly",
  props: {
    item_prop: {
      type: Object,
      default() {
        return null
      }
    },
    days: {
      type: Array,
      default() {
        return []
      }
    },

  },
  watch: {
    item_prop: {
      handler() {
        this.data = this.item_prop;
        if (!this.data) {
          return;
        }
        this.data.data = this.data?.data?.sort((a, b) => {
          const titleTypeA = a.find(d => d.type === "title")?.name;
          const titleTypeB = b.find(d => d.type === "title")?.name;
          const firstLocation = titleTypeA ? LOCATION_TESTER_CALENDAR.get(titleTypeA) : 0;
          const secondLocation = titleTypeB ? LOCATION_TESTER_CALENDAR.get(titleTypeB) : 0;
          return firstLocation - secondLocation;
        });
        this.handleMissDataTwoEveningShift()
        this.changeData()
        for (let i = 0; i < 6; i++) {
          this.checkIsSelectedAll(i,this.data.data[i][0].name)
        }
      }
    }
  },
  data() {
    return {
      checked: false,
      is_load_save: false,
      data: [],
      CA_LAM_VIEC: CA_LAM_VIEC,
      checkedAll: {1: false, 2: false, 3: false, 4: false, 5: false, 6: false},
      newData: [],
      indexEveningShift2: 3
    }
  },
  methods: {
    save() {
      this.is_load_save = true;
      this.$store.dispatch(TEST_INPUT_SAVE_CALENDAR, {
        calendars: this.data.data,
        user_id: this.data.tester.user_id
      }).then((data) => {
        this.$emit('saveCalendarSuccess');
        this.$message.success(data.message);
      }).catch((error) => {
        this.$message.error(error.data.message);
      }).finally(() => {
        this.is_load_save = false;
      })
    },
    handleCheckAll(index,name) {
      console.log(index,this.data?.data[index])
      this.data?.data[index].forEach((item) => {
        if (!item?.is_disable && item?.type != 'title') {
          item.value = this.checkedAll[name]
          return
        }
        return;
      })
    },
    checkIsSelectedAll(index,name) {
      this.checkedAll[name] = !this.data?.data[index]?.map((item) => {
        if (item?.type == 'title' || item?.is_disable) {
          return true
        }
        return item?.value
      }).includes(false)
    },
    changeData() {
      if (this.data?.data[0]?.length < 8) {
        const dayWeek = ["title", ...this.days.map((d) => d.day)]
        this.newData = this.data?.data.map((shift) => dayWeek.map((d, index) => {
          if (index == 0) {
            return shift[0]
          }
          const newShift = shift.slice(1);
          const data = newShift.find((day) => moment(day.day).format('DD-MM-YYYY') == d)
          return data ?? undefined
        }));
        return
      }
      this.newData = this.data?.data
    },
    handleMissDataTwoEveningShift() {
      const twoEveningShiftData = this.data?.data[this.indexEveningShift2]
      if (twoEveningShiftData && twoEveningShiftData.length < 8) {
        let dataAfterHandle = this.days.map((day) => {
          return twoEveningShiftData.find((data) => data.day === moment(day.day, 'DD-MM-YYYY').format('YYYY-MM-DD'));
        });
        this.data.data[this.indexEveningShift2] = [twoEveningShiftData[0], ...dataAfterHandle]
      }
    }
  },

}
</script>

<style scoped>

</style>