<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="event_edit" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 v-if="checkEdit" class="card-label">Sửa sự kiện nội bộ</h3>
            <h3 v-else class="card-label">Tạo sự kiện nội bộ</h3>
          </div>
          <div class="card-toolbar">
            <router-link class="font-weight-bold font-size-3 btn btn-secondary" :to="{ name: 'company-event-list' }"><i
                class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Tên sự kiện <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Tên sự kiện" rules="required" v-slot="{errors, classes}">
                  <el-input
                      type="text"
                      placeholder="Tên sự kiện"
                      v-model="companyEvent.name"
                      maxlength="50"
                      show-word-limit
                      :class="classes"
                  >
                  </el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="title" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Ảnh<span class="text-danger">*</span></label>
<!--                <ValidationProvider vid="name" name="Tên sự kiện" rules="required" v-slot="{errors, classes}">-->
                  <div class="image-input image-input-outline" id="kt_profile_avatar">
                    <div
                        class="image-input-wrapper"
                        :style="{ backgroundImage: `url(${photo})` }"
                    ></div>
                    <label
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Change avatar"
                    >
                      <i class="fa fa-pen icon-sm text-muted"></i>
                      <input
                          type="file"
                          name="profile_avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="onFileChange($event)"
                      />
                      <input type="hidden" name="profile_avatar_remove"/>
                    </label>
                    <span
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="cancel"
                        data-toggle="tooltip"
                        title="Cancel avatar"
                    >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                    <span
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title="Remove avatar"
                        @click="current_photo = null"
                    >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                  </div>
                  <div class="fv-plugins-message-container">
<!--                    <div data-field="title" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>-->
                  </div>
<!--                </ValidationProvider>-->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Mô tả ngắn<span class="text-danger">*</span></label>
                <ValidationProvider vid="short_description" name="Mô tả" rules="required" v-slot="{errors, classes}">
                  <el-input
                      type="textarea"
                      placeholder="Nhập mô tả"
                      v-model="companyEvent.short_description"
                      maxlength="300"
                      show-word-limit
                      :class="classes"
                  >
                  </el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="title" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Mô tả <span class="text-danger">*</span></label>
                <ValidationProvider vid="description" name="Mô tả" rules="required" v-slot="{errors, classes}">
                  <el-input
                      type="textarea"
                      placeholder="Nhập mô tả"
                      v-model="companyEvent.description"
                      maxlength="300"
                      show-word-limit
                      :class="classes"
                  >
                  </el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="title" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Người phụ trách <span class="text-danger">*</span></label>
                <ValidationProvider vid="users" name="Người phụ trách,sđt"  rules="required" v-slot="{ errors,classes }">
                  <el-select
                      ref="users"
                      v-model="companyEvent.users"
                      multiple
                      filterable
                      remote
                      reserve-keyword
                      class="w-100"
                      name="users"
                      placeholder="Nhập mã nhân sự"
                      :remote-method="remoteMethod"
                      :loading="loading"
                      :class="classes"
                  >
                    <el-option
                        v-for="item in userSearch"
                        :key="item.id"
                        :label="item.id + 100000+  ' - ' + item.name + ' - ' + item.phone"
                        :value="item.id">
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="title" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Ngày bắt đầu <span class="text-danger">*</span></label>
                <ValidationProvider vid="start_date" name="Ngày bắt đầu" rules="required" v-slot="{ errors,classes }">
                  <el-date-picker class="w-100" ref="start_date" name="start_date"
                                  placeholder="Chọn ngày bắt đầu diên ra sự kiện" v-model="companyEvent.start_date"
                                  format="yyyy-MM-dd"
                                  value-format="yyyy-MM-dd"
                                  type="date"
                                  :class="classes"
                                  :disabled="companyEvent.checked_success"
                  ></el-date-picker>
                  <span class="form-text text-muted" v-if="companyEvent.checked_success">Không được sửa khi đã có người check-in.</span>
                  <div class="fv-plugins-message-container">
                    <div data-field="start_date" data-validator="notEmpty"
                         class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Thời gian check-in <span class="text-danger">*</span></label>
                <ValidationProvider vid="time_checkin" name="Thời gian check-in" rules="required"
                                    v-slot="{ errors,classes }">
                  <el-time-select ref="time_checkin" class="w-100" name="time_checkin"
                                  v-model="companyEvent.time_checkin"
                                  :picker-options="{ start: '06:30', step: '00:15', end: '23:30'}"
                                  :class="classes"
                                  placeholder="Chọn thời gian check-in"
                                  :disabled="companyEvent.checked_success"
                  >

                  </el-time-select>
                  <span class="form-text text-muted" v-if="companyEvent.checked_success">Không được sửa khi đã có người check-in.</span>
                  <div class="fv-plugins-message-container">
                    <div data-field="end_date" data-validator="notEmpty"
                         class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Trạng thái sự kiện</label>
                <div class="w-100" style="display: flex; align-items: center; height: 40px;">
                  <el-tooltip :content="companyEvent.status ? 'Mở sự kiện' : 'Đóng sự kiện'" placement="top">
                      <span class="switch switch-outline switch-icon switch-success">
                        <label>
                         <input type="checkbox"
                                v-model="companyEvent.status"
                                :checked="companyEvent.status" name="select"/>
                         <span></span>
                        </label>
                     </span>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button type="submit" class="btn btn-success mr-2">
              Cập nhật
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";

const _ = require('lodash');
import {
  COMPANY_EVENT_CREATE,
  COMPANY_EVENT_GET_BY_ID,
  COMPANY_EVENT_UPDATE
} from "../../../core/services/store/company-event/company-event.module";
import {SEARCH_CUSTOMER_BY_OR_ID} from "../../../core/services/store/user/users.module";
import {mapGetters} from "vuex";

export default {
  components: {},
  name: "CompanyEventForm",
  data() {
    return {
      // Check show
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      // Cache
      cacheCenter: [],
      cacheLevelIn: [],
      cacheLevelOut: [],
      cacheCourseType: [],
      companyEvent: {
        id: '',
        name: '',
        description: '',
        status: true,
        start_date: '',
        time_checkin: '',
        users: [],
        short_description: '',
        avatar: {}
      },
      userSearch: [],
      checkCreate: false,
      checkEdit: false,
      showArea: false,
      showCenter: false,
      showCourse: false,
      loading: false,
      default_photo: "media/users/blank.png",
      current_photo: null,
      image_name: null,
      image_type: null,
    }
  },
  computed: {
    photo() {
      return this.current_photo == null
          ? this.default_photo
          : this.current_photo;
    }
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.checkEdit = true;
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Quản lý sự kiện nội bộ", route: "company-event-list"},
        {title: "Sửa sự kiện", icon: 'fas fa-users'}
      ]);
      this.getEventById();
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Quản lý sự kiện nội bộ", route: 'company-event-list'},
        {title: "Tạo sự kiện"}
      ]);
    }
  },
  methods: {
    getEventById() {
      let id = this.$route.params.id;
      this.$store.dispatch(COMPANY_EVENT_GET_BY_ID, id).then((res) => {
        if (!res.error) {
          let arrayId = [];
          this.companyEvent = res.data
          this.userSearch = res.data.users
          _.each(this.userSearch, (res) => {
            arrayId.push(res.id)
          });
          this.companyEvent.users = arrayId
          this.current_photo = res.data.avatar;
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    async onSubmit() {
      let formData = new FormData();
      formData.append('id',this.companyEvent.id);
      formData.append('name',this.companyEvent.name);
      formData.append('description',this.companyEvent.description);
      formData.append('status',this.companyEvent.status);
      formData.append('start_date',this.companyEvent.start_date);
      formData.append('time_checkin',this.companyEvent.time_checkin);
      formData.append('users',this.companyEvent.users);
      formData.append('short_description',this.companyEvent.short_description);
      let avatar = this.photo;

      function urltoFile(url, filename, mimeType) {
        return (fetch(url)
                .then(function (res) {
                  return res.arrayBuffer();
                })
                .then(function (buf) {
                  return new File([buf], filename, {type: mimeType});
                }).catch((error) => {
                  return 1;
                })
        );
      }
      if (avatar.search('https') === -1) {
        let file = await urltoFile(avatar, this.image_name, this.image_type);
        if (file !== 1 && file.name !== 'null') {
          formData.append('avatar', file);
        }
      }
      if (this.checkEdit) {
        this.$store.dispatch(COMPANY_EVENT_UPDATE,{id: this.companyEvent.id,params: formData}).then((res) => {
          if (res.error) {
            this.$bvToast.toast(res.message, {
              title: 'Cập nhật',
              variant: 'danger',
              solid: true
            });
          } else {
            this.$router.push({name: "company-event-list"}).then(() => {
              this.$bvToast.toast(res.message, {
                title: 'Cập nhật',
                variant: 'success',
                solid: true
              });
            })
          }
        }).catch((e) => {
          if (e.data.data.message_validate_form) {
            this.$refs.form.setErrors(e.data.data.message_validate_form);
          }
        });
      } else {
        this.createEvent(formData);
      }

    },
    createEvent(formData) {
      this.$store.dispatch(COMPANY_EVENT_CREATE, formData).then((res) => {
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Tạo mới',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$router.push({name: "company-event-list"}).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Tạo mới',
              variant: 'success',
              solid: true
            });
          })
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      });
    },
    remoteMethod: function (query) {
      if (query !== '' && query.length >= 6 && query.length <= 10) {
        this.new_phone = query;
        const params = {
          search_customer_by_phoneOrId: query,
          limit: 10000,
          status: 1,
          is_staff: 1,
        }
        this.loading = true;
        let vm = this;
        setTimeout(function () {
          vm.getStudents(params);
        }, 300)
      } else {
        this.new_phone = '';
      }
    },
    getStudents(params) {
      this.$store.dispatch(SEARCH_CUSTOMER_BY_OR_ID, params).then((res) => {
        this.userSearch = res.data.data;
        this.loading = false;
      })
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
        this.image_name = file.name;
        this.image_type = file.type;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  }
}
</script>
