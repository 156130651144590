<template>
  <div class="card card-custom gutter-b disabled-css">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <a href="javascript:" @click="$router.go(-1) " class="mr-3 align-items-center">
          <i class="fas fa-arrow-circle-left"></i>
        </a>
        <h1 class="card-label ml-3">Chi tiết trả góp</h1>
      </div>
    </div>
    <div class="card-body" v-loading="loading">
      <div class="row">
        <div class="col-md-6">
          <div class="container-content">
            <div class="card-title" style="height: 33px">
              <h4 class="card-label">Thông tin trả góp</h4>
            </div>
              <el-divider></el-divider>
            <div class="card-title">
              <h4 class="card-label">Thông tin khách hàng</h4>
            </div>
            <div class="row">
              <div class="col-md-6 form-group">
                <label>Họ tên:</label>
                <div class="mt-3">
                  <router-link :to="{ name: 'customer-crm-detail', params: {id: installmentInformation.user_id }, query:{
                    best_customer_id: installmentInformation.best_customer_id, active_name: 'cskh' }
                   }">
                    {{ installmentInformation.customer_name }}
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label>Số điện thoại:</label>
                <el-input type="text" :value="installmentInformation.customer_phone" disabled />
              </div>
            </div>
              <el-divider></el-divider>
            <div class="card-title">
              <h4 class="card-label">Thông tin người làm trả góp</h4>
            </div>
            <div class="row">
              <div class="col-md-6 form-group">
                <label>Họ tên:</label>
                <el-input type="text" :value="installmentInformation.name" disabled/>
              </div>
              <div class="col-md-6 form-group">
                <label>Số điện thoại:</label>
                <el-input type="text" :value="installmentInformation.phone" disabled/>
              </div>
              <div class="col-md-6 form-group">
                <label>Địa điểm trả góp:</label>
                <el-input type="text" :value="installmentInformation.branch" disabled/>
              </div>
              <div class="col-md-6 form-group">
                <label>Địa chỉ hiện tại:</label>
                <el-input type="text" :value="installmentInformation.address" disabled/>
              </div>
              <div class="col-md-6 form-group">
                <label>Loại trả góp:</label>
                <el-input type="text" :value="installmentInformation.tuition_payment" disabled/>
              </div>
              <div class="col-md-6 form-group">
                <label>Loại Căn cước công dân:</label>
                <el-input type="text" :value="installmentInformation.type_id_card" disabled/>
              </div>
            </div>
            <!-- Ảnh media -->
            <div class="row">
              <div class="form-group col-md-6">
                <label>Ảnh 02 mặt căn cước công dân:</label>
                <div class="ticket-media d-flex">
                  <div class="media image-ticket mr-2" v-for="image in cccdMedia">
                    <div class="demo-image__preview">
                      <el-image
                          style="width: 100px; height: 100px"
                          :src="image"
                          :preview-src-list="cccdMedia">
                      </el-image>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6" v-if="installmentInformation.type_id_card_number === 4"> <!-- căn cước gắn chíp -->
                <label>Thông tin chứng minh thư cũ:</label>
                <el-input type="text" :value="installmentInformation.old_id_card" disabled/>
              </div>
              <div class="form-group col-md-6" v-else> <!-- căn cước còn lại -->
                <label>Ảnh sổ hộ khẩu:</label>
                <div class="ticket-media d-flex flex-wrap">
                  <div class="media image-ticket mr-2" v-for="image in idCardMedia">
                    <div class="demo-image__preview">
                      <el-image
                          style="width: 100px; height: 100px"
                          :src="image"
                          :preview-src-list="idCardMedia">
                      </el-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="container-content">
            <div class="card-title d-flex justify-content-between align-items-center">
              <div class="align-items-center">
                <h4 class="card-label">Trạng thái chăm sóc</h4>
              </div>
                <button class="btn btn-primary btn-sm"
                        @click="showModalUpdate" v-if="installmentInformation.installment_method_id !==7">
                  Cập nhật tiến trình
                </button>
            </div>
              <el-divider></el-divider>
            <div class="title d-flex">
              <p class="ticket-log-title">Trạng thái làm trả góp :</p>
              <p class="badge badge-success ml-3">{{ installmentInformation.installment_method }}</p>
            </div>
            <div class="ticket-log mt-4" v-for="(log, key) in installmentInformation.logs">
              <div class="ticket-log-content">
                <p class="ticket-log-title">Thời gian:</p> <p>{{log.created_at | formatDateTimeAsia }}</p>
              </div>
              <div class="ticket-log-content">
                <p class="ticket-log-title">Người xử lý:</p>
                <p v-if="log.user">{{ log.user ? log.user.id + 100000 : ''}} - {{ log.user && log.user.name }}</p>
                <p v-else></p>
              </div>
              <div class="ticket-log-content">
                <p class="ticket-log-title">Hoạt động:</p> <p v-html="log.action"></p>
              </div>
              <div class="ticket-log-content">
                <p class="ticket-log-title">Nội dung:</p> <p v-html="renderContent(log)"></p>
              </div>
              <div class="ticket-log-content">
                <p class="ticket-log-title">Ảnh/video:</p>
                <div class="row">
                  <div class="col-12" v-if="log.images.length > 0 || log.videos.length > 0">
                    <div class="ticket-media d-flex flex-wrap">
                      <div class="media image-ticket mr-2" v-for="image in log.images">
                        <div class="demo-image__preview">
                          <el-image
                              style="width: 100px; height: 100px"
                              :src="image"
                              :preview-src-list="log.images">
                            <span class="el-upload-list__item-preview"><i class="el-icon-zoom-in"></i></span>
                          </el-image>
                        </div>
                      </div>
                      <div class="media video-ticket" v-for="video in log.videos">
                        <i class="fa fa-play-circle" style="color: blue" aria-hidden="true" @click="showModalVideo(video)"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" v-else>
                    Không có thông tin
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal :centered="true" :scrollable="true" content-class="your-class" size="lg" body-class="modal-video"  hide-footer hide-header ref="modal-video-ticket">
        <video controls id="video1" style="width: 100%; height: 98%" ref="video">
          <source :src="targetVideo" type="video/mp4">
          Your browser doesn't support HTML5 video tag.
        </video>
      </b-modal>
    </div>
    <el-dialog title="Tiến trình công việc" :destroy-on-close="true" center :visible.sync="dialogUpdateProgress">
      <change-installment-method :installment="installmentInformation" :tuition-payments="tuitionPayments" @successUpdate="successUpdate"></change-installment-method>
    </el-dialog>
  </div>
</template>

<script>
import changeInstallmentMethod from "@/view/pages/installment/component/ChangeInstallmentMethod";
import Comment from "@/view/pages/feedback/Comment";
import '@/assets/sass/ticket/ticketDetail.scss';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import {
  GET_INSTALLMENT_METHODS,
  SHOW_INSTALLMENT_INFORMATION
} from "@/core/services/store/installment/installment.module";
export default {
  name: "InstallmentInformationDetail",
  components: { changeInstallmentMethod, Comment },
  data() {
    return {
      installmentInformation: {},
      media: [],
      targetVideo: '',
      cccdMedia: [],
      idCardMedia: [],
      tuitionPayments: [],
      dialogUpdateProgress: false,
      dialogComment: false,
      loading: false,
    }
  },
  created() {
    this.showInstallmentInformation();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Duyệt trả góp", route: 'installment-information-list'},
      {title: "Chi tiết trả góp"}
    ]);
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    showInstallmentInformation() {
      this.loading = true;
      this.$store.dispatch(SHOW_INSTALLMENT_INFORMATION, this.$route.params.id).then((res) => {
        this.installmentInformation = res.data;
        this.cccdMedia = this.installmentInformation.cccdMedia.map((item) => item.url);
        this.idCardMedia = this.installmentInformation.idCardMedia.map((item) => item.url);
        this.$store.dispatch(GET_INSTALLMENT_METHODS).then((res) => {
          this.tuitionPayments = res.data.filter((item) => {
            if (this.installmentInformation.tuition_payment_id === 2){ //trả góp ngân hàng
              return item.id !== 7;
            }else{                                                     //trả góp trung tâm
              return [1, 2, 5, 6, 8].includes(item.id);
            }
          })
          console.log(this.tuitionPayments);
        })
      }).catch((err) => {
        this.noticeMessage('error', 'Thất bại', err?.data?.message);
      }).finally(() => this.loading = false);
    },
    showModalVideo(video) {
      this.targetVideo = video;
      this.$refs['modal-video-ticket'].show();
      setTimeout( () => {
        let modalContent = document.querySelector('.your-class');
        modalContent.style.background = 'none';
        modalContent.style.boxShadow = 'none';
        this.$refs['video'].play();
      }, 200);
    },
    successUpdate() {
      this.dialogUpdateProgress = false;
      this.showInstallmentInformation();
    },
    showModalUpdate(){
      this.dialogUpdateProgress = true;
    },
    renderContent(log){
      return log.content.replace(/(\r\n|\n\r|\r|\n)/g, '<br>');
    }
  }
}
</script>

<style scoped>

</style>