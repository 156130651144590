<template>
  <div>
    <button class="btn btn-success font-weight-bold btn-sm" v-b-modal="`Modal_Add_New`">
      <i class="flaticon2-add-1"></i>
      Thêm tin
    </button>

    <b-modal id="Modal_Add_New" title="Thêm mới tin tức" centered hide-footer ref="Modal_Add_New" @hidden="hideModal"
             size="xl"
             @show="getCountNumberOrder">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" @submit.prevent autocomplete="off">
          <div class="row">
            <div class="col-lg-6 col-xs-12">
              <p>Tiêu đề <span class="text-danger">*</span></p>
              <ValidationProvider name="Tiêu đề" rules="required" v-slot="{ errors,classes }"
                                  vid="title">
                <el-input v-model="news.title" placeholder="Nhập tiêu đề"/>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-lg-6 col-xs-12">
              <p>Thứ tự hiển thị <span class="text-danger">*</span></p>
              <ValidationProvider name="Thứ tự hiển thị" rules="required" v-slot="{ errors,classes }"
                                  vid="stt">
                <el-input-number v-model="news.stt"/>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6 col-lg-6 col-xs-12">
              <p>Loại tin tức<span class="text-danger">*</span></p>
              <ValidationProvider name="Loại tin tức" rules="required" v-slot="{ errors,classes }"
                                  vid="typeNews">
                <el-select v-model="news.type" placeholder="Nhập loại tin tức" clearable>
                  <el-option
                      v-for="item in TYPE_NEWS"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-12 mt-5">
              <p>Ảnh <span class="text-danger">*</span></p>
              <input class="file-input__input" id="file-input-add-news" type="file" name="myImage"
                     accept="image/png, image/gif, image/jpeg" @change="" multiple="multiple" @input="addPhotoNews">
              <div v-if="urlImageNews==null">
                <label class="file-input__label " for="file-input-add-news"
                >
                  <span><i class="flaticon-upload mr-2 " style="color: white"></i>Tải lên</span></label
                >
                <p class="text-dark">Kích thước ảnh tiêu chuẩn là 340 x n pixels. Với n là chiều cao tùy theo mong muốn.</p>
                <p class="text-danger" v-if="checkNullImg">
                  Ảnh là bắt buộc
                </p>
              </div>
              <div class="d-flex" v-else>
                <div class="" @mouseover="upHereNews = true"
                     @mouseleave="upHereNews = false"
                     style="width: 100px ;height: 100px ;background-size: 100px 100px ; "
                     :style="{ 'background-image': 'url(' + urlImageNews + ')' }">
                  <div v-if="upHereNews" class=" w-100 h-100 d-flex justify-content-center align-items-center"
                       style="background-color: rgba(0,0,0,0.5)">
                    <i class="flaticon2-magnifier-tool mr-3" style=" cursor: pointer ;"
                       @click="openPhotoNews = true"></i>
                    <i class="flaticon-delete" @click=" removePhotoNews()"
                       style=" cursor: pointer ;"></i>
                  </div>
                  <el-dialog
                      :visible.sync="openPhotoNews"
                      width="30%"
                      center
                      z-index="10">
                    <img :src="urlImageNews" style="width: 100% ; height: auto">
                    <span slot="footer" class="dialog-footer">
                              <el-button @click="openPhotoNews = false">Đóng</el-button></span>
                  </el-dialog>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex d-flex flex-row-reverse">
            <button type="button" class="btn btn-success ml-4" @click="handleSubmit(confirmNews)" :disabled="loading">
              <div v-if="loading" class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div v-else>
                Xác nhận
              </div>
            </button>
            <button type="button" class=" btn btn-secondary" @click="cancel()">Hủy</button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import {GET_COUNT_NUMBER_ORDER, POST_NEWS} from "@/core/services/store/tournament/tournament.module";
import {TYPE_NEWS} from "@/core/option/tournamentOption";
export default {
  name: "ModalAddNew",
  data() {
    return {
      news: {
        title: null,
        stt: null,
        type: null,
      },
      image_news: null,
      urlImageNews: null,
      openPhotoNews: false,
      upHereNews: false,
      checkNullImg: false,
      loading: false,
      TYPE_NEWS: TYPE_NEWS,
    }
  },
  methods: {
    hideModal() {
      this.news.title = null
      this.news.stt = null
      this.news.type = null
      this.image_news = null
      this.urlImageNews = null
      this.openPhotoNews = false
      this.upHereNews = false
      this.checkNullImg = false
    },
    confirmNews() {
      if (this.image_news == null) {
        this.checkNullImg = true
        return
      }
      this.addNew()
    },
    getCountNumberOrder() {
      let payload = {
        tournament_id: this.$route.params.id
      }
      this.$store.dispatch(GET_COUNT_NUMBER_ORDER, payload).then((data) => {
        this.news.stt = data.data + 1
      })
    },
    addNew() {
      this.loading = true
      this.$store.dispatch(POST_NEWS, this.formDataNew).then((data) => {
        this.$refs['Modal_Add_New'].hide()
        this.$notify({
          title: 'Thành công',
          message: 'Thêm mới tin tức thành công',
          type: 'success'
        });
        this.$emit("getListNews")
      }).catch((error) => {
        let message = error.data.data.message_validate_form
        this.$notify({
          title: 'Lỗi',
          message: `${message?.stt ? message?.stt[0] : message?.img_new ? message?.img_new[0] : 'Đã có lỗi xẩy ra'}`,
          type: 'error'
        });
      }).finally(() => {
        this.loading = false
      })
    },
    cancel() {
      this.$refs['Modal_Add_New'].hide()
    },
    addPhotoNews(e) {
      if (e.target.files.length) {
        this.urlImageNews = URL.createObjectURL(e.target.files[0])
        this.image_news = e.target.files[0]
      }
    },
    removePhotoNews() {
      this.$confirm('Bạn có chắc chắn muốn xóa ảnh này không?', 'Xóa ảnh', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
        type: 'Delete'
      }).then(() => {
        this.urlImageNews = null
        this.image_news = null
        this.$notify({
          title: 'Thành công',
          message: 'Xóa ảnh thành công',
          type: 'success'
        });
      }).catch(() => {
      });
    },
  },
  computed: {
    formDataNew() {
      let formData = new FormData();
      formData.append('title', this.news.title);
      formData.append('stt', this.news.stt);
      formData.append('type', this.news.type);
      formData.append('tournament_id', this.$route.params.id);
      formData.append('img_new', this.image_news);
      return formData
    }
  }
}
</script>

<style scoped>
.file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.el-select {
  width: 100%;
}

.file-input__label {
  height: 34px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 7px 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  background-color: #667CA4;
}
</style>