export const LIST_SHEET = new Map ([
    ["Văn phòng Tổng", "vp_tong"],
    ["Giám đốc Văn phòng", "gdvp"],
    ["SL Trung Tâm", "sale_trung_tam"],
    ["SL Tỉnh", "sale_tinh"],
    ["Sale Lương cứng", "sale_luong_cung"],
    ["Sale Lương mềm", "sale_luong_mem"],
    ["Giám đốc Khu vực", "gdkv"],
    ["Giám đốc Khu vực chi tiết", "gdkv_chi_tiet"],
    ["Giám đốc Văn phòng chi tiết", "gdvp_chi_tiet"],
    ["Trung tâm chi tiết", "trung_tam_chi_tiet"],
    ["Tỉnh chi tiết", "tinh_chi_tiet"],
    ["Sale Lương cứng chi tiết", "sale_luong_cung_chi_tiet"],
    ["Sale Lương mềm chi tiết", "sale_luong_mem_chi_tiet"],
])