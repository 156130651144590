<template>
  <div class="bg-white mark_the_exam pb-5">
    <header-mark-the-exam :currentTest="currentTest" :isChildTest ="+centerID === Cambridge_Mentor_ID"></header-mark-the-exam>
    <div class="container  mt-10" v-if="+centerID === Cambridge_Mentor_ID ">
      <TestSpeakingChild></TestSpeakingChild>
    </div>
    <div class="container  mt-10" v-else>
      <test-writing v-if="currentTest==TEST_WRITING" :doneTest="doneTest" @change="currentTest=SUGGESTED_COURSE"></test-writing>
      <test-speaking v-if="currentTest==TEST_SPEAKING" :doneTest="doneTest" @change="currentTest=SUGGESTED_COURSE"></test-speaking>
    </div>
  </div>
</template>

<script>
import HeaderMarkTheExam from "@/view/pages/test-input/components/ComponentMarkTheExam/HeaderMarkTheExam";
import CustomerInformation from "@/view/pages/test-input/components/ComponentMarkTheExam/CustomerInformation";
import TestWriting from "@/view/pages/test-input/components/ComponentMarkTheExam/TestWriting";

import TestSpeaking from "@/view/pages/test-input/components/ComponentMarkTheExam/TestSpeaking";

import {SPEAKING, TEST_SPEAKING, TEST_WRITING, WRITING} from "@/core/option/testInputOption";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_RESULT_EXAM} from "@/core/services/store/test/testInput.module";
import SuggestedCourse from "@/view/pages/test-input/components/ComponentMarkTheExam/SuggestedCourse";
import {Cambridge_Mentor_ID} from "@/core/option/typeCenterBill";
import TestSpeakingChild from "@/view/pages/test-input/components/ComponentMarkTheExam/TestSpeakingChild.vue";


export default {
  name: "MarkTheExam",
  components: {
    TestSpeakingChild,
    SuggestedCourse,
    TestSpeaking,
    TestWriting,
    CustomerInformation,
    HeaderMarkTheExam
  },
  data() {
    return {
      currentTest: this.$route.query.typeMark,
      TEST_SPEAKING: 0,
      TEST_WRITING: 1,
      SUGGESTED_COURSE: 2,
      testIELTS: false,
      doneTest: false,
      idSchedule:null,
      centerID : this.$route.query.centerID,
      Cambridge_Mentor_ID: Cambridge_Mentor_ID
    }
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Chi tiết Tác vụ", route: 'task-details'},
      {title: "Chấm bài Test đầu vào"}
    ]);
  },
  mounted() {

  },
  computed: {},
  methods: {
    async getResultTests() {
      let test_input_schedule_id = this.$route.params.id
      let data = await this.$store.dispatch(GET_RESULT_EXAM, test_input_schedule_id ?? 0);
      if (!data.error) {
        this.idSchedule = data.data.test_input_schedule_id
        let keys = ["speaking", "writing", "listening", "vocabulary", "reading"];
        if (data.data?.vocabulary?.right_answers > 1) {
          keys = ["speaking", "writing", "listening", "reading"];
          this.testIELTS = true
        } else {
          keys = ["speaking", "vocabulary"];
        }
        let count = 0
        let res = keys.forEach((obj) => {
          if (!data?.data?.hasOwnProperty(obj) || data.data[obj] == null) {
            return
          }
          count++
        })
        this.doneTest = (this.testIELTS && (count == 3|| count ==4 ) ) || (!this.testIELTS && ((count == 1 && data?.data?.speaking == null  ) ||count == 2 ))
        this.currentTest = (!this.testIELTS && this.currentTest == WRITING && data.data[speaking] != null) ? 2 : this.$route.query.typeMark
        if((count == 4 && this.testIELTS) || (count == 2 && !this.testIELTS)){
          this.currentTest = 2
        }
      }
    }
  }
}
</script>

<style scoped>
.mark_the_exam {
  height: 100%;
}

.mark_the_exam {
  box-sizing: border-box;
}
</style>