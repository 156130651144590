<template>
  <div>
    <ValidationProvider name="Tác vụ" rules="required" v-slot="{ errors,classes }"
    >
      <el-select v-model="idTester" placeholder="Chọn nhân sự Admin"
                 @input="selectTester" clearable>
        <el-option
            v-for="(item,index) in task.dataEmployees"
            :key="index"
            :label="item.tester_name"
            :value="item.tester_id"
        />
      </el-select>
      <div class="fv-plugins-message-container">
        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  name: "taskAssign",
  data() {
    return {
      idTester: ''
    }
  },
  watch: {
    clearDataTester() {
      this.idTester = null
    }
  },
  props: {
    task: {
      type: Object,
      default() {
        return {}
      }
    },
    clearDataTester: {
      type: Boolean,
      default() {
        return false
      }
    },
    index: {
      default() {
        return 0
      }
    }
  },
  methods: {
    selectTester() {
      this.$emit('selectTester', this.idTester, this.index)
    }
  }
}
</script>

<style scoped>

</style>