import ApiService from "@/core/services/api.service";

const _ = require('lodash');
//action types
export const GET_PROVINCES = "list-province";

//mutations types
export const SET_PROVINCES = "setProvinces";
export const SET_PROVINCE_PAGINATE = "set_province_pagination";

const state = {
    errors: null,
    provinces: [],
};

const getters = {
    getAllProvinces(state) {
        return state.provinces;
    },

};

const actions = {
    [GET_PROVINCES](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/provinces", credentials)
                .then(({data}) => {
                    context.commit(SET_PROVINCES, data.data);
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },

};

const mutations = {
    [SET_PROVINCES](state, data) {
        state.provinces = data;
        state.errors = {};
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
