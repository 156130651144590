<template>
  <div>
    <b-modal :centered="true" :scrollable="true" size="xl" ref="modal-history-sent-information" hide-footer
             :id="'modal-history-sent-information'"
             :title="`Lịch sử gửi thông tin thu tiền hợp đồng ${titleModal}`"
             @show="getInformationHistory">
      <div class="example mb-10">
        <div class="example-preview table-responsive overflow-auto">
          <table class="table table-bordered  table-vertical-center ">
            <thead>
            <tr class="text-center">
              <th scope="col" class="min-w-100px">Danh mục</th>
              <th scope="col" class="min-w-100px">Hóa đơn</th>
              <th scope="col" class="min-w-200px">Trạng thái gửi</th>
              <th scope="col" class="min-w-100px">Người thực hiện</th>
              <th scope="col" class="min-w-100px">Thời gian</th>
              <th scope="col" class="min-w-100px">Ghi chú</th>
            </tr>
            </thead>
            <tbody v-if="listHistoryNotification.length==0">
            <tr >
              <td colspan="6" class="text-center">
                Không có dữ liệu
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr v-for="item in listHistoryNotification" :key="item.id" class="text-center">
              <td>
                Gửi {{ item.provider }}
              </td>
              <td>
                {{ item.amount }}
              </td>
              <td>
                <span  :class="{'badge badge-success':item.status==SUCCESS , 'badge badge-secondary':item.status!=SUCCESS}">{{item.status==SUCCESS?'Thành công':'Thất bại'}}</span>
              </td>
              <td>
                {{showUserAction(item?.action_user)}}
              </td>
              <td>
                {{item.created_at}}
              </td>
              <td>
                {{item.status==SUCCESS?'':item.error_text}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="float-right">
        <button type="button" class="btn btn-secondary mr-2" @click="hideModal()"> Đóng</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {GET_HISTORY_SENT_NOTIFICATION} from "@/core/services/store/notification/notification.module";
import {FAIL, formatDate, formatMoneyToVND, SUCCESS} from "@/core/option/edutalkPaymentOption";

export default {
  name: "ModalHistorySentInformation",
  data() {
    return {
      FAIL : FAIL,
      SUCCESS : SUCCESS
    }
  },
  props:{
    listHistoryNotification : {
      type: Array,
      default(){
        return []
      }
    },
    titleModal : {
      type: String,
      default() {
        return''
      }
    }
  },
  methods: {
    hideModal() {
      this.$refs['modal-history-sent-information'].hide();
    },
    getInformationHistory() {

    },
    showUserAction(actionUser){
      if(actionUser){
        return `${actionUser? (+actionUser.id + 100000):""} - ${actionUser?.name??""}`
      }
      return 'Auto'
    }
  }
}
</script>

<style scoped>

</style>