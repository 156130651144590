<template>
  <div v-loading="loading">
    <hr style="margin-top: -20px; margin-bottom: 20px"/>
    <div>
      <div style="display: flex; align-items: center; justify-content: space-between">
        <div>
          <h3>I. Kết quả KPI</h3>
          <h4 style="color: #0066FF">% KPI tổng: {{ totalPercentKpi ? `${totalPercentKpi}%` : 'Chưa có' }}</h4>
        </div>
        <div style="color: #666666; display: flex; flex-direction: column;">
          <span>Cập nhật bảng thành phẩm: {{timeUpdateResultKpi ? `${timeUpdateResultKpi} bởi ${userUpdateKpi?.name}` : ''}}</span>
          <span>Cập nhật kết quả KPI: {{timeUpdateKpi ? `${timeUpdateKpi} bởi ${userUpdateResultKpi}` : ''}}</span>
        </div>
      </div>
      <TableResultKpi :list-management-kpi="listDetailResultKpi" :is-edit="isEdit" @totalPercentKra="totalPercentKra"/>
      <div v-if="listWeeklyOfMonth?.length > 0">
        <div style="display: flex; align-items: center; justify-content: space-between">
          <div>
            <h3>II. Kết quả họp tuần</h3>
            <h4 style="color: #0066FF">Điểm tích họp tuần: {{ totalPoint ?? 'Chưa có' }}</h4>
          </div>
        </div>
        <TableGradingWeeklyMeeting :result-weekly-meeting="listWeeklyOfMonth" :is-edit="isEdit" @totalPointWeeklyMeeting="totalPointWeeklyMeeting"/>
      </div>
      <div style="margin-bottom: 5px" v-if="listWeeklyOfMonth?.length > 0">
        <span>Nhận xét</span>
        <el-input v-model="commentDetail" :disabled="!isEdit" placeholder="Nhập nhận xét họp tuần..."/>
      </div>
    </div>
    <div style="display: flex; justify-content: end; gap: 5px; align-items: center; margin: 4px 0">
      <el-button v-if="!isEdit" type="primary" style="color: white !important;" @click="isEdit = true">Sửa</el-button>
      <el-button @click="closeDialog">Đóng</el-button>
      <el-button v-if="isEdit" type="primary" style="color: white !important;" @click="handleCreateResult">Lưu</el-button>
    </div>
  </div>
</template>

<script>
import TableResultKpi from "@/view/pages/backoffice/managements/component/TableResultKpi.vue";
import TableGradingWeeklyMeeting from "@/view/pages/backoffice/managements/component/TableGradingWeeklyMeetings.vue";
import {GET_RESULT_KPI_BY_USER, MARK_KPI_MONTHLY} from "@/core/services/store/backoffice/mangementKpi";
import moment from 'moment-timezone';

export default {
  name: 'ResultKpiPersonal',
  components: {TableGradingWeeklyMeeting, TableResultKpi},
  data() {
    return {
      loading: true,
      listDetailResultKpi: [],
      listWeeklyOfMonth: [],
      totalPercentKpi: null,
      totalPoint: null,
      userUpdateKpi: null,
      timeUpdateKpi: null,
      userUpdateResultKpi: null,
      timeUpdateResultKpi: null,
      commentDetail: '',
      weekOfMonth: null,
      dataDetail: {},
      isEdit: false,
    }
  },
  props: {
    recordKpiByUser: {
      type: Object,
      default: null,
      required: true
    },
  },
  mounted() {
    this.getDetailKpiMonthly()
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    getDetailKpiMonthly() {
      const payload = {
        month: this.recordKpiByUser.date ? moment(this.recordKpiByUser.date, 'MM-YYYY').format('YYYY-MM') : '',
        user_id: this.recordKpiByUser.userId,
        department_id: this.recordKpiByUser?.departmentId,
      }
      this.$store.dispatch(GET_RESULT_KPI_BY_USER, payload).then(res => {
        if (!res.error) {
          this.dataDetail = res?.data
          this.totalPercentKpi = res?.data?.total_percent_kra
          this.totalPoint = res?.data?.total_score_meeting
          this.commentDetail = res?.data?.description
          this.userUpdateKpi = res?.data?.details[0]?.user
          this.userUpdateResultKpi = res?.data?.assessor_name
          this.listWeeklyOfMonth = res?.data?.week_meetings
          this.listDetailResultKpi = res?.data?.details?.filter(item => item.department_id === this.recordKpiByUser?.departmentId)
          this.timeUpdateKpi = res?.data?.update_kra_at && moment(res?.data?.update_kra_at).format('HH:mm:ss DD-MM-YYYY')
          this.timeUpdateResultKpi = res?.data?.update_finished_product_at && moment(res?.data?.update_finished_product_at).format('HH:mm:ss DD-MM-YYYY')
          this.loading = false
        }
      }).catch(e => {
        console.log(e, 'error')
      })
    },
    totalPointWeeklyMeeting(total) {
      this.totalPoint = total
    },
    handleCreateResult() {
      const payload = {
        user_id: this.dataDetail?.details[0]?.user_id,
        month: this.recordKpiByUser.date ? moment(this.recordKpiByUser.date, 'MM-YYYY').format('YYYY-MM') : '',
        details: this.dataDetail?.details,
        week_meetings: this.dataDetail?.week_meetings,
        description: this.commentDetail,
        department_id: this.recordKpiByUser?.departmentId,
      }
      this.$store.dispatch(MARK_KPI_MONTHLY, payload).then(res => {
        if (!res.error) {
          this.$emit('closeDialog');
          this.$message.success('Chấm điểm thành công')
          this.$emit('getListManagementKpi')
        }
      }).catch(e => {
        console.log(e, 'error mark kpi')
      })
    },
    totalPercentKra(total) {
      this.totalPercentKpi = total
    }
  }
}
</script>

<style lang="css">
.el-dialog__wrapper .el-dialog {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

</style>