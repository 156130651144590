import { render, staticRenderFns } from "./InfoLayering.vue?vue&type=template&id=4e4ec636&scoped=true"
import script from "./InfoLayering.vue?vue&type=script&lang=js"
export * from "./InfoLayering.vue?vue&type=script&lang=js"
import style0 from "./InfoLayering.vue?vue&type=style&index=0&id=4e4ec636&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e4ec636",
  null
  
)

export default component.exports