export class AnswerStudent{
    constructor(comment,comments,group_question,outcomes_id,overall_result_id,score,score_detail,status_mark,student_answer,test_id,type,user_id,typeAnswer) {
       this.comment = comment;
       this.comments = comments;
       this.group_question = group_question;
       this.outcomes_id = outcomes_id;
       this.overall_result_id = overall_result_id;
       this.score = score;
       this.score_detail = score_detail;
       this.status_mark = status_mark;
       this.student_answer = student_answer;
       this.test_id = test_id;
       this.type = type;
       this.user_id = user_id;
       this.Fluency_Coherence = {
          valueOption: null,
          comment: '',
       };
       this.Lexical_Resource={
          valueOption: null,
          comment: '',
       };
       this.Grammatical_Range_Accuracy= {
          valueOption: null,
          comment: '',
       };
       this.pronunciation= {
          valueOption: null,
          comment: '',
       };
       this.totalScore = null;
       this.typeAnswer = typeAnswer;
       this.commentToAnswer = [];
       this.highlightComment = null;
       this.commentAnswer = [];
       this.achievement = undefined;
       this.lexical= undefined;
       this.coherence= undefined;
       this.grammatical = undefined;

    }
}