<template>
  <!--begin::Card-->
  <ValidationObserver v-slot="{ handleSubmit }">
    <form class="form" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title ">
            <h3 class="card-label">
              Sửa giảng viên, trợ giảng
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{name: 'teacher-list'}"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Họ tên
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Họ tên" rules="required" v-slot="{ errors,classes }">
                  <input type="text" ref="name" name="name" class="form-control" :class="classes"
                         placeholder="Họ tên"
                         v-model=user.name>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Email
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors,classes }">
                  <input type="email" name="email" class="form-control" placeholder="Email"
                         :class="classes"
                         v-model=user.email>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Số điện thoại
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Số điện thoại"
                                    :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                    v-slot="{ errors,classes }">

                  <input type="number" v-model="user.phone" :class="classes" class="form-control">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row mx-auto mt-8">
              <label class="col-xl-4 col-lg-3 col-form-label text-right">Ảnh đại diện</label>
              <div class="col-lg-9 col-xl-6">
                <div class="image-input image-input-outline" id="kt_profile_avatar">
                  <div
                      class="image-input-wrapper"
                      :style="{ backgroundImage: `url(${photo})` }"
                  ></div>
                  <label
                      class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="change"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Change avatar"
                  >
                    <i class="fa fa-pen icon-sm text-muted"></i>
                    <input
                        type="file"
                        name="profile_avatar"
                        accept=".png, .jpg, .jpeg"
                        @change="onFileChange($event)"
                    />
                    <input type="hidden" name="profile_avatar_remove" />
                  </label>
                  <span
                      class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="cancel"
                      data-toggle="tooltip"
                      title="Cancel avatar"
                  >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                  <span
                      class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="remove"
                      data-toggle="tooltip"
                      title="Remove avatar"
                      @click="current_photo = null"
                  >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                </div>
                <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
              </div>
            </div>
            <div class="row">

            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Trung tâm
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Trung tâm" rules="required" v-slot="{ errors,classes }">
                  <multiselect v-model="cache_center" :options="centers"
                               placeholder="Chọn trung tâm"
                               label="name"
                               track-by="name"
                               :class="classes"
                               :multiple="true"
                               @input="selectCenter($event)"
                  ></multiselect>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Chức vụ
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Chức vụ" rules="required" v-slot="{ errors,classes }">
                  <multiselect v-model="cache_position" :options="positions"
                               placeholder="Chọn chức vụ"
                               label="name"
                               track-by="name"
                               :class="classes"
                               @input="selectPosition($event)"
                  ></multiselect>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
<!--              <div class="form-group">-->
<!--                <label>Chi nhánh-->
<!--                  <span class="text-danger">*</span></label>-->
<!--                <ValidationProvider name="Chi nhánh" rules="required" v-slot="{ errors,classes }">-->
<!--                  <multiselect v-model="cache_branch" :options="branches"-->
<!--                               placeholder="Chọn chi nhánh"-->
<!--                               label="name"-->
<!--                               track-by="name"-->
<!--                               @input="selectBranch(($event))"-->
<!--                               :class="classes"-->
<!--                  ></multiselect>-->
<!--                  <div class="fv-plugins-message-container">-->
<!--                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </ValidationProvider>-->
                <input type="hidden" name="account_type_id" :value="user.profile.account_type_id">
<!--              </div>-->
              <div class="form-group">
                <label>Trình độ
                  <span class="text-danger"></span></label>
                <input type="text" name="level" class="form-control" placeholder="Trình độ"
                  :v-model="user?.teacher_infor?.level ?? ''">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Nơi từng làm việc
                  <span class="text-danger"></span></label>
                <input type="text" name="work_place" class="form-control" placeholder="Nơi từng làm việc"
                      :v-model="user?.teacher_infor?.work_place ?? ''">
              </div>
              <!--end::Form-->
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Chọn giới tính
                  <span class="text-danger"></span></label>
                <multiselect v-model="user.profile.gender" :options="gender"
                             :searchable="true"
                             :close-on-select="true"
                             :show-labels="true"
                             placeholder="Chọn giới tính"
                ></multiselect>
              </div>
              <!--end::Form-->
            </div>

          </div>
          <div class="row">
            <div class="col-md-4 d-flex justify-content-center">
              <div class="col-form-label checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input type="checkbox" name="Checkboxes5" v-model="user.status">
                  <span></span>Kích hoạt</label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-1">
                <label>Địa chỉ
                  <span class="text-danger"></span></label>
                <textarea class="form-control" rows="2" v-model="user.profile.address"></textarea>
              </div>
              <!--end::Form-->
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Mô tả ngắn
                  <span class="text-danger"></span></label>
                <textarea :v-model="user?.teacher_infor?.excerpt ?? ''" class="form-control" rows="2"></textarea>
              </div>
              <!--end::Form-->
            </div>
          </div>

          <div class="row">
            <div class="form-group mb-1 col-md-12">
              <label>Giới thiệu
                <span class="text-danger"></span></label>
              <vue-editor id="editor" :editorOptions="editorSettings" useCustomImageHandler
                          @image-removed="removeImage"
                          @image-added="handleImageAdded" :v-model="user?.teacher_infor?.description ?? ''"></vue-editor>
            </div>
          </div>
          <div class="row">

          </div>
        </div>
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <button type="submit" class="btn btn-success mr-2"> Cập nhật </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
  <!--end::Card-->
</template>
<script>
import Multiselect from 'vue-multiselect'
import {Quill, VueEditor} from 'vue2-editor'
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import {GET_TEACHER_ACCOUNT_TYPES,UPDATE_TEACHER} from "../../../core/services/store/teacher/teacher.module";
import ApiService from "@/core/services/api.service";
import {ImageDrop} from "quill-image-drop-module";
import ImageResize from "quill-image-resize-vue";
import {GET_USER_BY_ID} from "../../../core/services/store/user/users.module";
import {mapGetters} from "vuex";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
  name: "Edit-Teacher",
  components: {
    Multiselect,
    VueEditor
  },
  data() {
    return {
      user: {
        name: '',
        phone: "",
        email: "",
        status: '',
        is_staff : '',
        profile: {
          ma_gioi_thieu: "",
          gioithieu_id: "",
          gender: 'Nam',
          address: "",
          chi_nhanh_id: "",
          trung_tam_id: "",
          account_type_id: '',
        },
        teacher_infor : {
          level : '',
          work_place : '',
          profile_id : '',
          excerpt : '',
          description : '',
        }
      },
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      /*Data default*/
      isLoadingBoss: false,
      status: {
        0: 'Không hoạt động',
        1: 'Đang hoạt động',
        2: "Nghỉ việc"
      },
      gender: [
        'Nam', 'Nữ', 'Khác'
      ],

      centers: [],
      branches : [],
      branchesByCenter : '',
      positions: [],
      /*photo*/
      default_photo: "media/users/blank.png",
      current_photo: null,
      image_name: null,
      image_type: null,
      /*Cache */
      cache_center: '',
      cache_branch: '',
      cache_position : '',
    };
  },
  computed: {
    ...mapGetters(['getTeacherById']),
    photo() {
      return this.current_photo == null
          ? this.default_photo
          : this.current_photo;
    },
  },
  async created() {
    await this.getCenters();
    await this.getBranches();
    await this.getPositions();
    let id = this.$route.params.id;
    let res = await this.$store.dispatch(GET_USER_BY_ID,id);
    this.user = res.data;
    this.cache_branch = _.find(this.branchesByCenter, function (obj){
      return obj.id === parseInt(res.data.profile.chi_nhanh_id);
    });
    this.cache_center = _.find(this.centers, function (obj){
      return obj.id === parseInt(res.data.profile.trung_tam_id);
    });
    let center_details = [];
    res.data.center_teacher.forEach((center) => {
      let center_each = {
        id: center.center.id,
        name: center.center.name,
      };
      center_details.push(center_each);
    });
    this.cache_center = center_details;
    this.cache_position = _.find(this.positions, function (obj){
      return obj.id === parseInt(res.data.profile.account_type_id);
    });
    this.current_photo = res.data.avatar;
  },
   mounted() {
     this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý nhân sự"},
      {title: "Sửa giảng viên, trợ giảng"}
    ]);
  },
  methods: {
    removeImage: function(delta, oldContents){
        ApiService.post('remove-image', {url:delta})
            .then(({data}) => {

            })
            .catch(err => {
              console.log(err);
            });
      },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData();
      formData.append("image", file);

      ApiService.post('upload-image',formData)
          .then(({data}) => {
            let url = data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch(err => {
            console.log(err);
          });
    },
    getCenters : async function (){
      await this.$store.dispatch(GET_CENTERS,{limit : 10000}).then((res) => {
        this.centers = res.data.data;
      });
    },
    async getBranches() {
      await this.$store.dispatch(GET_BRANCHES,{limit: 10000}).then((res)=>{
        this.branches = res.data.data;
        this.branchesByCenter = res.data.data;
      });
    },
    selectCenter : function ($event){
      let center_id = $event != null ? $event.id : '';
      this.user.profile.trung_tam_id = center_id;
      if (center_id !== ''){
        this.branches = _.reduce(this.branchesByCenter, function (result,value,key){
          if (center_id === value.center_id){
            result.push(value);
          }
          return result;
        },[]);
      }else{
        this.branches = this.branchesByCenter;
      }
      this.cache_branch = '';
    },
    selectBranch: function ($event){
      this.user.profile.chi_nhanh_id = $event != null ? $event.id : '';
    },
    selectPosition : function ($event){
      this.user.profile.account_type_id = $event != null ? $event.id : '';
      this.user.is_staff = $event != null ? $event.is_staff : '';
    },
    async getPositions() {
      await this.$store.dispatch(GET_TEACHER_ACCOUNT_TYPES, {}).then((res) => {
        if (!res.error) {
          this.positions = res.data;
        }
      });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
        this.image_name = file.name;
        this.image_type = file.type;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    async onSubmit() {
      let formData = new FormData();
      let idArray = this.cache_center.map(center => center.id);
      formData.append('name',this.user.name);
      formData.append('phone',this.user.phone);
      formData.append('email',this.user.email);
      formData.append('status',this.user.status == true ? 1 : 0);
      formData.append('is_staff',this.user.is_staff);
      this.user.profile.address ? formData.append('address',this.user.profile.address) : ''
      this.user.profile.gender ? formData.append('gender',this.user.profile.gender) : ''
      idArray.forEach(id => {
        formData.append('center_ids[]', id);
      });
      this.user.profile.chi_nhanh_id ? formData.append('chi_nhanh_id',this.user.profile.chi_nhanh_id) : ''
      formData.append('account_type_id',this.user.profile.account_type_id);
      this.user?.teacher_infor?.level ? formData.append('level',this.user?.teacher_infor?.level) : ''
      this.user?.teacher_infor?.work_place ? formData.append('work_place',this.user?.teacher_infor?.work_place) : ''
      this.user?.teacher_infor?.excerpt ? formData.append('excerpt',this.user?.teacher_infor?.excerpt) : ''
      this.user?.teacher_infor?.description ? formData.append('description',this.user?.teacher_infor?.description) : ''
      let avatar = this.photo;
      function urltoFile(url, filename, mimeType) {
        return (fetch(url)
                .then(function (res) {
                  return res.arrayBuffer();
                })
                .then(function (buf) {
                  return new File([buf], filename, {type: mimeType});
                }).catch((error) => {
                  return 1;
                })
        );
      }
      if (avatar.search('https') === -1) {
        let file = await urltoFile(avatar, this.image_name, this.image_type);
        if (file !== 1 && file.name !== 'null') {
          formData.append('avatar', file);
        }
      }
      if (avatar === this.default_photo){
        formData.append('clear_avatar', 1);
      }
      this.$store.dispatch(UPDATE_TEACHER, {
        id: this.$route.params.id,
        params : formData
      }).then((res) => {
        if (!res.error) {
          this.$router.push({name: "teacher-list"}).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Tạo giảng viên, trợ giảng',
              variant: 'success',
              solid: true
            });
          })
        } else {
          this.$bvToast.toast(res.message, {
            title: 'Tạo giảng viên, trợ giảng',
            variant: 'danger',
            solid: true
          });
        }
      }).catch((e) => {
        this.$bvToast.toast('Có lỗi xảy ra', {
          title: 'Tạo giảng viên, trợ giảng',
          variant: 'danger',
          solid: true
        });
      });
    },
  },
};
</script>
<style scoped>
.mx-datepicker {
  width: 100% !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

