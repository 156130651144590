<template id="component-extend-candidate">
  <b-modal id="handle-extend-candidate" @change="showDialog" title="Gia hạn đào tạo giảng viên" size="lg" ok-only centered hide-footer>
    <ValidationObserver v-slot="{ handleSubmit  }" ref="form">
        <form class="form" autocomplete="off" @submit.prevent="handleSubmit(handleOk)">
          <label class="fs-1" style="font-size: 14px">Vui lòng chọn thời hạn deadline mới để gia hạn đào tạo cho  giảng viên {{ teacher.name }}?</label>
          <div class="row css-add-candidate">
            <div class="calendar-candidate col-md-12 mt-2">
              <label class="fs-1" style="color:rgba(69, 70, 116, 1); font-size: 14px">Thời hạn deadline mới<span class="text-danger"> *</span></label>
              <ValidationProvider vid="time_extend" name="Thời hạn" rules="required" v-slot="{ errors, classes }">
                <div>
                <el-date-picker :class="classes" type="time" class="w-100" v-model="form.time_extend"
                                :picker-options="pickerOptions" placeholder="Chọn thời hạn deadline">
                </el-date-picker>
              </div>
                <div class="fv-plugins-message-container">
                  <div data-field="time_extend" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="mt-3">
            <el-button
              :disabled="loading_extend"
              size="small"
              class="float-right text-white rounded"
              style="background-color: rgba(93, 4, 176, 1);"
              native-type="submit">
              <i v-bind:class="[loading_extend ? 'el-icon-loading' : '']"></i>
              Xác nhận
            </el-button>
          </div>
          <div>
            <el-button
              style="background-color: rgba(202, 202, 202, 1);"
              size="small"
              class="float-right mr-3 rounded"
              @click="closeModal"
            >
            <i></i>
              Huỷ bỏ
            </el-button>
          </div>
        </form>
    </ValidationObserver>
  </b-modal>
</template>
  
  <script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import { EXTEND_CANDIDATE_TEACHER } from '../../../../core/services/store/course/classes.module';
  import moment from 'moment-timezone';
  
  export default {
    name: "ExtendCandidate",
    components: {DatePicker},
    props: {
      teacher: Object,
    },
    mounted() {
    },
    data() {
      const that = this;
      return {
        loading_extend: false,
        teacher_id: '',
        form: {
          time_extend: null 
        },
        pickerOptions: {
          disabledDate(time) {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return time.getTime() < yesterday.getTime();
          },
        },
      }
    },
    watch: {
      teacher: function(value) {
        if(this.teacher_id !== value.id) {
            this.form.time_extend = '';
        }
      }
    },
    computed: {
      teacherDeadline() {
        return moment(this.teacher.deadline_format).toDate().getTime();
      },
    },
    mounted() {
    },
    methods: {
      handleOk() {
        this.callApi();
      },
  
      callApi() {
        this.loading_extend = true;
        this.$store.dispatch(EXTEND_CANDIDATE_TEACHER, {
          id_teacher: this.teacher.id,
          time_extend: moment(this.form.time_extend).format('YYYY-MM-DD HH:mm:ss'),
          status_candidate : this.teacher.status_candidate
        }).then((res) => {
          this.$notify({
            title: 'Thành công',
            message: 'Gia hạn đào tạo thành công',
            type: 'success'
          });
          this.handle_save_loading = false;
          this.form.time_extend = '';
          this.loading_extend = false;
          this.closeModal();
        }).catch((e) => {
          this.$notify({
              title: "Lỗi",
              message: "Đã xảy ra lỗi khi gia hạn giảng viên.",
              type: "error",
            });
        }).finally(() => {
          this.loading_extend = false;
        })
      },
      closeModal() {
        this.$bvModal.hide('handle-extend-candidate');
        this.$emit("reload");
      },

      showDialog() {
        this.teacher_id =  this.teacher.id
      }
    },
  }
  </script>
  
  <style>
     #handle-extend-candidate  {
        top: -100px;
    }
    .el-picker-panel__footer {
      display: none !important;
    }
</style>