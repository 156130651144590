<template>
  <fragment>
    <li class="menu-section">
      <h4 class="menu-text">Quản lý kinh doanh</h4>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-if="currentUser.user.permission.includes(permission.QUAN_LY_KENH_CTV)"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-users"></i>
        <span class="menu-text">Quản lý kênh CTV</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <!-- <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Biểu đồ</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
              <ul class="menu-subnav">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
          ]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Kinh doanh đội</span>
                  </a>
                </li>

                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
          ]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Kinh doanh tại điểm</span>
                  </a>
                </li>
              </ul>
            </div>
          </li> -->
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Quản lý</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
              <ul class="menu-subnav">
                <!-- <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Cơ cấu tổ chức</span>
                  </a>
                </li> -->
                <router-link :to="{ name: 'business-coefficient-index'}"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                      v-if="currentUser.user.permission.includes(permission.QUAN_LY_HE_SO_KINH_DOANH)"
                  >
                    <a href="#" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Hệ số KD</span>
                    </a>
                  </li>
                </router-link>
                <!-- <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Chi tiêu</span>
                  </a>
                </li> -->
              </ul>
            </div>
          </li>
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              v-if="currentUser.user.permission.includes(permission.BAO_CAO_KENH_CTV)"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Báo cáo</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
              <ul class="menu-subnav">
                <router-link :to="{ name : 'dskh-doi'}"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      v-if="currentUser.user.permission.includes(permission.BAO_CAO_DOANH_THU_DOI)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Doanh thu đội</span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{ name : 'dskh-branch'}"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                      v-if="currentUser.user.permission.includes(permission.BAO_CAO_DOANH_THU_TAI_DIEM)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Doanh thu tại điểm</span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{ name : 'report-tvv'}"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                      v-if="currentUser.user.permission.includes(permission.BAO_CAO_TVV)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Báo cáo tư vấn viên</span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{ name : 'report-digital'}"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                      v-if="currentUser.user.permission.includes(permission.BAO_CAO_DIGITAL)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Báo cáo digital</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-if="currentUser.user.permission.includes(permission.QUAN_LY_KENH_WEBSITE)"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-solid fa-blog"></i>
        <span class="menu-text">Quản lý kênh website</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              v-if="currentUser.user.permission.includes(permission.QUAN_LY_GIAO_DIEN)"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Quản lý giao diện</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
              <ul class="menu-subnav">
                <router-link :to="{ name : 'news-index'}"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                      v-if="currentUser.user.permission.includes(permission.DANH_SACH_TIN_TUC)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Danh sách tin tức</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
              <ul class="menu-subnav">
                <router-link :to="{ name : 'event-web-index'}"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                      v-if="currentUser.user.permission.includes(permission.DANH_SACH_SU_KIEN)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Danh sách sự kiện</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
            <!--            <div class="menu-submenu menu-submenu-classic menu-submenu-right">-->
            <!--              <ul class="menu-subnav">-->
            <!--                <router-link :to="{ name : 'page-index'}"-->
            <!--                             v-slot="{ href, navigate, isActive, isExactActive }">-->
            <!--                  <li-->
            <!--                      aria-haspopup="true"-->
            <!--                      data-menu-toggle="hover"-->
            <!--                      class="menu-item"-->
            <!--                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"-->
            <!--                      v-if="currentUser.user.permission.includes(permission.DANH_SACH_PAGE)"-->
            <!--                  >-->
            <!--                    <a :href="href" class="menu-link" @click="navigate">-->
            <!--                      <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
            <!--                      <span class="menu-text">Danh sách page</span>-->
            <!--                    </a>-->
            <!--                  </li>-->
            <!--                </router-link>-->
            <!--              </ul>-->
            <!--            </div>-->
          </li>
          <!--          <li-->
          <!--              aria-haspopup="true"-->
          <!--              data-menu-toggle="hover"-->
          <!--              class="menu-item"-->
          <!--          >-->
          <!--            <a href="#" class="menu-link menu-toggle">-->
          <!--              <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
          <!--              <span class="menu-text">Quản lý content</span>-->
          <!--              <i class="menu-arrow"></i>-->
          <!--            </a>-->
          <!--            <div class="menu-submenu menu-submenu-classic menu-submenu-right">-->
          <!--              <ul class="menu-subnav">-->
          <!--                <router-link :to="{ name : 'promotion-index'}"-->
          <!--                             v-slot="{ href, navigate, isActive, isExactActive }">-->
          <!--                  <li-->
          <!--                      aria-haspopup="true"-->
          <!--                      data-menu-toggle="hover"-->
          <!--                      class="menu-item"-->
          <!--                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"-->
          <!--                  >-->
          <!--                    <a :href="href" class="menu-link" @click="navigate">-->
          <!--                      <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
          <!--                      <span class="menu-text">Danh sách combo</span>-->
          <!--                    </a>-->
          <!--                  </li>-->


          <!--                </router-link>-->
          <!--                <router-link-->
          <!--                    :to="{ name : 'center-list'}"-->
          <!--                    v-slot="{ href, navigate, isActive, isExactActive }"-->
          <!--                >-->
          <!--                  <li-->
          <!--                      aria-haspopup="true"-->
          <!--                      data-menu-toggle="hover"-->
          <!--                      class="menu-item"-->
          <!--                      :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"-->
          <!--                  >-->
          <!--                    <a :href="href" class="menu-link" @click="navigate">-->
          <!--                      <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
          <!--                      <span class="menu-text">Danh sách trung tâm</span>-->
          <!--                    </a>-->
          <!--                  </li>-->
          <!--                </router-link>-->
          <!--              </ul>-->
          <!--            </div>-->
          <!--          </li>-->
          <!--          <router-link :to="{ name : 'lead-index'}" class="menu-link menu-toggle"-->
          <!--                       v-slot="{ href, navigate, isActive, isExactActive }">-->
          <!--            <li-->
          <!--                aria-haspopup="true"-->
          <!--                data-menu-toggle="hover"-->
          <!--                class="menu-item"-->
          <!--                v-if="currentUser.user.permission.includes(permission.QUAN_LY_LEAD)"-->
          <!--            >-->
          <!--              <a :href="href" class="menu-link menu-toggle">-->
          <!--                <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
          <!--                <span class="menu-text">Quản lý lead</span>-->
          <!--              </a>-->
          <!--            </li>-->
          <!--          </router-link>-->
        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"

        v-if="currentUser.user.permission.includes(permission.DIGITAL_MARKETING)"
    >
      <a href="#" class="menu-link">
        <i class="menu-icon fab fa-digital-ocean"></i>
        <span class="menu-text">Digital marketing</span>
      </a>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-solid fa-newspaper"><span></span></i>
        <span class="menu-text">Cấu hình trang</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link :to="{ name : 'setting-home-page'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Trang chủ</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'setting-ecosystem-page'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Hệ sinh thái</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'setting-peopleEdutalk-page'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Người edutalk</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
        v-if="currentUser.user.permission.includes(permission.CHUONG_TRINH_QUAY_THUONG)"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item">
      <router-link :to="{ name : 'list-program'}"
                   v-slot="{ href, navigate }">
        <a :href="href" class="menu-link menu-toggle" @click="navigate">
          <i class="menu-icon fas fa-solid fa-newspaper"><span></span></i>
          <span class="menu-text">Chương trình quay thưởng</span>
        </a>
      </router-link>
    </li>
    <li
        v-if="currentUser.user.permission.includes(permission.QUAN_LY_KENH_LK_TRUONG)"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item">
      <router-link :to="{ name : 'listSchool'}"
                   v-slot="{ href, navigate }">
        <a :href="href" class="menu-link menu-toggle" @click="navigate">
          <i class="menu-icon fas fa-solid fa-school"><span></span></i>
          <span class="menu-text">Quản lý kênh LK Trường</span>
        </a>
      </router-link>
    </li>
  </fragment>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "QuanLyKinhDoanh",
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
