<template>
  <div>
    <button v-if="isEdit" type="button" class="btn   mr-2 btn-outline-primary"  style="font-size: 10px" v-b-modal="`Modal_Complete_Request`"><i class="flaticon2-pen" style="font-size: 10px" ></i> Chỉnh sửa</button>
    <button v-else type="button" class="btn" style="background-color: #1BC5BD;color: white"
            v-b-modal="`Modal_Complete_Request`">Hoàn thành
    </button>

    <b-modal id="Modal_Complete_Request" :title="isEdit?'Cập nhật thông tin hoàn thành xử lý yêu cầu':'Hoàn thành xử lý yêu cầu'" @show="getDetailTicket()" centered hide-footer
             ref="Modal_Complete_Request"
             size="lg" @hidden="resetData()">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" @submit.prevent autocomplete="off">
          <div class="mb-4">
            <el-checkbox v-model="haveCost">Tốn chi phí xử lý</el-checkbox>
            <Money v-if="haveCost" placeholder="Nhập chi phí" v-model="processingCosts" v-bind="money"
                   class="form-control"></Money>
          </div>
          <div>
            <p>Hình ảnh mô tả (chụp thành phẩm, hóa đơn,...) <span class="text-danger"></span></p>
            <ValidationProvider name="type_task" rules="required|max:30" v-slot="{ errors,classes }"
                                vid="type_task">
              <input
                  ref="fileExam"
                  type="file"
                  name="file-input-exam"
                  id="file-input-exam"
                  class="file-input__input"
                  @change="addPhoto"
                  :disabled="disableImport"
                  accept="image/png, image/gif, image/jpeg"
              />
            </ValidationProvider>
            <label  class="file-input__label " for="file-input-exam"
                   :class="{'disable-import':!disableImport, 'on-disable-import':disableImport}">
              <span style="color: white"><i class="flaticon-upload mr-2" style="color: white"></i>Tải lên</span></label>
            <div v-if="urlFiles" class="d-flex flex-row flex-wrap">
              <div v-for="(urlFile,index) in urlFiles" :key="index">
                <ImageModal   class="mr-4 mt-3" :urlProps="urlFile" :haveDeleteImage="true" @deleteImage="deleteImage" :indexImage="index"></ImageModal>
              </div>
            </div>
            </div>

          <div class="mt-4">
            <p>Ghi chú <span class="text-danger"></span></p>
            <el-input
                type="textarea"
                :rows="5"
                placeholder="Nhập ghi chú (nếu có)"
                v-model="note"
                maxlength = "50"
            >
            </el-input>
            <div v-if="note">
              <p class="text-right mb-0" >{{note.length}}/50</p>
            </div>
          </div>
          <div class="d-flex d-flex flex-row-reverse mt-5">
            <button type="button" class="btn btn-success ml-4" v-loading="loading" :disabled="loading" @click="confirmCompleteRequest()">
              <div>
                {{isEdit?'Cập nhật':'Xác nhận'}}
              </div>
            </button>
            <button type="button" class="btn btn-secondary" @click="cancel()">Hủy</button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import {Money} from 'v-money'
import {COMPLETE_REQUEST} from "@/core/services/store/csvc/csvc.module";
import ImageModal from "@/view/pages/infrastructure/component/ImageModal";

export default {
  name: "CompleteRequest",
  components: {ImageModal, Money},
  data() {
    return {
      haveCost: false,
      processingCosts: 0,
      files: [],
      note: '',
      urlFiles: [],
      money: {
        decimal: '.',
        thousands: '.',
        prefix: '',
        suffix: ' VNĐ',
        precision: 0,
        masked: false,
      },
      loading : false
    }
  },
  props:{
    isEdit:{
      type: Boolean,
      default(){
        return false
      }
    },
    detailTicket: {
      type: Object,
      default() {
        return null
      }
    }
  },
  methods: {
    confirmCompleteRequest() {
      this.loading = true
      let formData = new FormData();
      let payload = {
        id: this.$route.params.id,
        body: formData
      }
      if(!this.haveCost){
        this.processingCosts = 0
      }
      formData.append("price", this.processingCosts);
      formData.append("description success", this.note);
      this.files.forEach(async (file)=>{
        await formData.append("files[]", file)
      })
      this.$store.dispatch(COMPLETE_REQUEST, payload).then((data) => {
        this.cancel()
        this.$emit('doneCompleteRequest')
      }).finally(()=>{
        this.loading = false
      })
    },
    addPhoto(e) {
      let size = e.target.files[0].size;
      if(size > 10000000) {
        this.noticeMessage('error', 'Thất bại', 'Dung lượng hình ảnh không được vượt quá 10MB');
        return;
      }
      if(this.files.length === 5){
        return
      }
      if (e.target.files.length) {
        let dataFile = e.target.files[0]
        this.files = [...this.files,dataFile]
        this.urlFiles = [...this.urlFiles,URL.createObjectURL(dataFile)]
      }
    },
    deleteImage(index) {
      this.files.splice(index,1)
      this.urlFiles.splice(index,1)
    },
    cancel() {
      this.$refs['Modal_Complete_Request'].hide()
    },
    resetData() {
      this.processingCosts = null
      this.files = []
      this.note = null
      this.urlFiles = []
    },
    async getDetailTicket() {
      if(this.isEdit){
        this.haveCost = this.detailTicket?.price > 0
        this.processingCosts = this.detailTicket?.price
        this.note = this.detailTicket?.description_success??""
        this.urlFiles = this.detailTicket?.image_success?.map((image)=>image?.url)
        this.files = await  Promise.all(this.detailTicket?.image_success?.map( async (image)=> {
          let file =  await this.convertUrlToFile(image)
          return file
        }));
      }
      console.log(this.files)
    },
    convertUrlToFile(image){
      return  fetch(`${image.url}`)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File(
                [blob],
                `${image.file_name}`,
                {
                  type: `${image.mime_type}`,
                }
            );
            return  file;
          });
    }
  },
  computed:{
    disableImport(){
      return this.files.length > 4
    }
  }
}
</script>

<style scoped>
.file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  height: 34px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 7px 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);

}

.disable-import {
  background-color: #667CA4;
}

.on-disable-import {
  background-color: #a6a6a6;
}
</style>
