<template>
  <div>
    <div class="mb-4">
      <label for="">Bước 1: Tải file mẫu</label>
      <div>
        <button class="btn btn-primary" @click="exportFileMau">
          <i class="el-icon-download"></i>
          Tải file
        </button>
      </div>
    </div>
    <div>
      <label for="">Bước 2: Upload file excel lên hệ thống</label>
      <el-upload
          ref="upload"
          accept=".xlsx"
          :limit="1"
          list-type="text"
          action=""
          :file-list="fileList"
          :on-change="handleChange"
          :on-remove="handleRemove"
          :auto-upload="false"
          :multiple="false"
      >
        <el-button size="small" type="warning" class="text-white" :disabled="disable">
          <i class="el-icon-upload2 text-white"></i>
          Tải lên
        </el-button>
      </el-upload>
    </div>
    <div class="mt-3 d-flex justify-content-end">
      <el-button @click="closeModalImport" class="btn">Hủy</el-button>
      <el-button :loading="loading" size="small" type="success" class="btn btn-success" @click="submitUpload">Xác nhận</el-button>
    </div>
  </div>
</template>

<script>
import {
  EXPORT_EXAM_CLASS,
  PREVIEW_IMPORT,
  GET_DATA_IMPORT
} from "@/core/services/store/rewards-programs/rewardsPrograms.module";
import { read, utils } from "xlsx";

export default {
  name: "ModalImportClass",
  props: {
    loadingPreview : {
      type : Boolean,
      default()  {
          return true;
      }
    },
  },
  data() {
    return {
      fileList: [],
      disable: false,
      loading: false,
      listDataImport: []
    }
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    async submitUpload() {
        await this.fileToJson(this.fileList).then(dataToImport => {
        this.loading = true;
        this.listDataImport = dataToImport;
        this.validateData(dataToImport);
      }).catch(e => {
          this.$message({
            type: 'error',
            message: 'Có lỗi xảy ra',
            showClose: true
          });
        });
    },
    validateData(dataToImport) {
      if (!this.listDataImport.length) {
        this.listDataImport = this.listDataImport.map((data) => {
          return {
            ...data,
            error: [
              data?.id_combo ? null : "Mã combo không được để trống.",
              data?.id_khoa_hoc ? null : "Mã khoá học không được để trống.",
              data?.id_qua_tang ? null : "Mã quà tăng không được để trống.",
              data?.id_voucher ? null : "Mã voucher không được để trống.",
              data?.so_luong_khoa ? null : "Số lượng khoá không được để trống.",
            ],
            success: 1
          }
        });
        if (!this.listDataImport.length) {
          this.$notify({
            title: 'Thất bại',
            message: 'File không có dữ liệu',
            type: 'error'
          });
          return;
        }
        this.$emit("importSuccess", this.listDataImport);
        return;
      }
      this.$store.dispatch(GET_DATA_IMPORT, {id: this.$route.params.id , data: dataToImport}).then((response) => {
        console.log(response, 'data')
        this.listDataImport = response.map((data) => {
          var errorData = response.find(d => d.so_luong_khoa == data.so_luong_khoa
            && d.id_khoa_hoc == data.id_khoa_hoc
            && d.id_combo == data.id_comb
            && d.id_voucher == data.id_voucher
            && d.id_qua_tang == data.id_qua_tang);
          return {
            ...data,
            error: [
              data?.class_error ? data?.class_error : null,
            ],
            success: (data.class_error && data?.class_error.length > 0) > 0
          }
        });

        this.$emit("importSuccess", this.listDataImport);
        this.loading = false;
      }).catch((res) => {
        console.log(res, "err GET_DATA_IMPORT");
      });
    },
    handleRemove() {
      this.disable = false;
      this.fileList = []
    },
    handleChange(file, fileList){
      this.disable = true;
      this.fileList = fileList
    },
    exportFileMau() {
      this.$store.dispatch(EXPORT_EXAM_CLASS).then((res) => {
        this.$notify({
          title: 'Thành công',
          message: res.message,
          type: 'success'
        })
      }).catch((error) => {
        this.$notify({
          title: 'Thất bại',
          message: error.data.message,
          type: 'error'
        })
      })
    },
    fileToJson(file) {
        return new Promise((res, rej) => {
            /* Boilerplate to set up FileReader */
            const reader = new FileReader()
            reader.onload = (e) => {
                /* Parse data */
                const bstr = e.target.result
                const wb = read(bstr, {type: 'binary'})
                /* Get first worksheet */
                const wsname = wb.SheetNames[0]
                const ws = wb.Sheets[wsname]
                /* Convert array of arrays */
                const data = utils.sheet_to_json(ws)

                res(data);
            }
            reader.onerror = (e) => {
                rej(e);
            };
            reader.readAsBinaryString(file[0].raw)
        });
    },
    closeModalImport() {
      this.fileList = [];
      this.$emit("closeModalImport", false);
    },
    arrUnique(arr) {
      var cleaned = [];
      arr.forEach(function(itm) {
        var unique = true;
        cleaned.forEach(function(itm2) {
          if (_.isEqual(itm, itm2)) unique = false;
        });
        if (unique)  cleaned.push(itm);
      });
      return cleaned;
    }
  }
}
</script>

<style scoped>

</style>