import ApiService from "@/core/services/api.service";

const _ = require('lodash');
//action types
export const GET_FOLDERS_DRIVERS = "list-folders-driver";

//mutations types
export const SET_FOLDERS = "setfolders";

const state = {
    errors: null,
    folders: [],
};

const getters = {
    getAllFolders(state) {
        return state.folders;
    },

};

const actions = {
    [GET_FOLDERS_DRIVERS](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("getFoldersDriver", credentials)
                .then(({data}) => {
                    context.commit(SET_FOLDERS, data.data);
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },

};

const mutations = {
    [SET_FOLDERS](state, data) {
        state.folders = data;
        state.errors = {};
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
