<template>
    <div>
        <b-modal
            id="modal-create-prize-draw"
            ref="modal"
            title="Thêm mới chương trình"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            size="lg"
            ok-title="Xác nhận"
            cancel-title="Hủy"
            >
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="col-12 row">
                    <div class="col-6 form-group">
                        <label for="">Tên chương trình <span class="text-danger">*</span></label>
                            <ValidationProvider name="Tên chương trình" rules="required" v-slot="{ errors }" vid="name">
                                <el-input type="text" v-model="name" placeholder="Nhập tên chương trình"></el-input>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                                </div>
                            </ValidationProvider>
                    </div>
                    <div class="col-6 form-group">
                        <label for="">Trung tâm <span class="text-danger">*</span></label>
                            <ValidationProvider name="Trung tâm" rules="required" v-slot="{ errors }" vid="center_id">
                                <el-select filterable class="w-100" placeholder="Chọn trung tâm"
                                    clearable v-model="centerId">
                                    <el-option v-for="(item, index) in listCenter" :key="index" :value="item.id" :label="item.name">
                                    </el-option>
                                </el-select>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                                </div>
                            </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-6 form-group">
                        <label for="">Thời gian bắt đầu <span class="text-danger">*</span></label>
                            <ValidationProvider name="Thời gian bắt đầu" rules="required" v-slot="{ errors }"
                            vid="start_date">
                                <div>
                                    <date-picker
                                        type="datetime"
                                        placeholder="Thời gian bắt đầu"
                                        format="YYYY-MM-DD HH:mm:ss"
                                        value-type="format"
                                        v-model="timeStart"
                                        :disabled-date="disabledBeforeStartDate"
                                        :disabled-time="disableHourStart"
                                        @input="changeDate"
                                    >
                                    </date-picker>
                                </div>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                                </div>
                            </ValidationProvider>
                    </div>
                    <div class="col-6 form-group">
                        <label for="">Thời gian kết thúc <span class="text-danger">*</span></label>
                            <ValidationProvider name="Thời gian kết thúc" rules="required" v-slot="{ errors }"
                            vid="end_date">
                                <div>
                                    <date-picker
                                        type="datetime"
                                        placeholder="Thời gian kết thúc"
                                        format="YYYY-MM-DD HH:mm:ss"
                                        value-type="format"
                                        v-model="timeEnd"
                                        :disabled="checkPickDate"
                                        :disabled-date="disabledBeforeEndDate"
                                        :disabled-time="disableHourEnd"
                                    >
                                    </date-picker>
                                </div>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                                </div>
                            </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 row">
                  <div class="form-group col-6">
                    <label for="">Loại chương trình <span class="text-danger">*</span></label>
                    <ValidationProvider name="Loại chương trình" rules="required" v-slot="{ errors }" vid="type">
                      <el-select class="w-100" placeholder="Chọn loại quay thưởng"
                                 clearable v-model="type">
                        <el-option v-for="item in typeProgramOption" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
            </form>
        </ValidationObserver>
        </b-modal>
    </div>
</template>
<script>
import {
  CREATE_REWARD_PROGRAMS, UPDATE_REWARD_PROGRAMS
} from "@/core/services/store/contract/contract-program.module";
import DatePicker from "vue2-datepicker";
import moment from "moment-timezone";
import "vue2-datepicker/index.css";
import {typeProgramOption} from "@/view/pages/rewards-program/detail-program/model/InformationProgramModel";

export default {
    name: "ModalCreatePrizeDraw",
    components: {DatePicker},
    props: {
        checkUpdate: {
            type: Boolean,
            default() {
                return false;
            }
        }, 
        listCenter: {
            type: Array,
            default() {
                return [];
            }
        }, 
    },
    data() {
        return {
            name: null,
            centerId: null,
            timeStart:null,
            timeEnd: null,
            type: null,
            submittedNames: [],
            pickerOptions: {
                disabledDate(time) {
                  return time.getTime() < Date.now();
                },
            },
            checkPickDate: true,
            loading: false,
            typeProgramOption: typeProgramOption,
        }
    },
  methods: {
        changeDate() {
            if(!this.timeStart) {
                this.checkPickDate = true;
                return;
            }
            this.checkPickDate = false;
        },
        resetModal(bvModalEvent) {
            this.name = ''
            this.centerId = null
            this.timeStart = null
            this.timeEnd = null
            this.type = null
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {
            if(this.checkUpdate) {
                this.updatePrize();
                return;
            }
            this.createPrize();
        },
        createPrize() {
            const payload = {
              name: this.name,
              center_id: this.centerId,
              start_date: this.timeStart,
              end_date: this.timeEnd,
              program_type: this.type,
            }
            this.$store.dispatch(CREATE_REWARD_PROGRAMS, payload).then(res => {
                if (!res.error) {
                    this.resetModal();
                    this.$notify({
                        title: 'Thành công',
                        message: 'Thêm mới chương trình thành công',
                        type: 'success'
                    });
                    this.$emit("commit", true);
                    this.closeModal();
                } else {
                  this.$notify({
                    title: 'Không thành công',
                    message: 'Đã tồn tại chương trình nằm trong khoảng thời gian này. Vui lòng chọn lại.',
                    type: 'error'
                  });
                }
            }).catch((e) => {
                if (e.data.data.message_validate_form) {
                    this.$refs.form.setErrors(e.data.data.message_validate_form);
                    this.timeEnd = payload.timeEnd;
                    this.timeStart = payload.timeStart;
                }
            });
        },
        closeModal() {
            this.$bvModal.hide('modal-create-prize-draw');
        },
        updatePrize() {
          const payload = {
            name: this.name,
            center_id: this.centerId,
            start_date: this.timeStart,
            end_date: this.timeEnd,
            program_type: this.type,
          }
          this.$store.dispatch(UPDATE_REWARD_PROGRAMS, payload).then(res => {
                    this.$notify({
                        title: 'Thành công',
                        message: 'Cập nhật thành công',
                        type: 'success'
                    })
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                });
        },
        disabledBeforeStartDate(date) {
            const today = moment().set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            });
            return moment(date) < today;
        },
        disableHourStart(hour) {
            let today = new Date();
            return today >= hour;
        },
        disabledBeforeEndDate(date) {
            return (
                moment(date).endOf("days") <= moment(this.timeEnd)
            );
        },
        disableHourEnd(hour) {
            return this.timeEnd >= hour;
        }
    }
}
</script>