<template>
  <div>
      <div class="border-0">
          <div class="border-0" :disabled="!item.children || item.children.length === 0">
                  <div class="col-md-12 d-flex hover align-items-center rounded" >
                      <div class=" d-flex flex-fill"  @click="changeVisible" role="button" style="font-weight: bold;">
                          <div v-if="item.active" class="disabledbutton">
                              <i class="el-icon-caret-right text-violet pr-2 size-icon" style="padding-top: 3px" v-if="item.children && item.children.length > 0 && !visible"></i>
                              <i v-else-if="item.children && item.children.length > 0 && visible" class="el-icon-caret-bottom text-violet pr-2 size-icon"></i>
                              <i v-else class="el-icon-s-home text-violet pr-2" style="padding-top: 3px"></i>
                          </div>
                          <div v-else>
                              <i class="el-icon-caret-right text-violet pr-2 size-icon" style="padding-top: 3px" v-if="item.children && item.children.length > 0 && !visible"></i>
                              <i v-else-if="item.children && item.children.length > 0 && visible" class="el-icon-caret-bottom text-violet pr-2 size-icon"></i>
                              <i v-else class="el-icon-s-home text-violet pr-2" style="padding-top: 3px"></i>
                          </div>

                          <span v-if="item.active" class="disabledbutton">
                              {{item.name}}
                          </span>
                          <span v-else style="font-size: 14px;">{{item.name}}</span>
                      </div>
                      <div>
                        {{item.id}}
                      </div>
                      <div class="d-flex justify-content-end align-items-center" style="width: 200px;font-size: 14px;">
                          <span class="text-start"> {{item.type === 1 ? 'Khối' : (item.type === 2 ? 'Phòng ban' : (item.type === 3 ? 'Bộ phận' : (item.type === -1 ? 'Tổng công ty' : '')))}}</span>
                      </div>
                      <div class="d-flex justify-content-end p-0" style="width: 200px;">
                        <span class="mr-13" v-if="item.active">
                            <el-button
                                title="Kích hoạt"
                                type="text"
                                size="mini"
                                @click="() => active(item)">
                                <i class="el-icon-s-tools text-luc p-1 rounded" style="border: 1px solid"></i>
                            </el-button>
                        </span>
                          <span class="mr-2" v-else>
                            <el-button
                                title="Sửa"
                                type="text"
                                size="mini"
                                @click="() => showUpdate(item)">
                                <i class="fas fa-pen-nib text-blue p-1 rounded" style="border: 1px solid"></i>
                            </el-button>
                            <el-button
                                title="Ẩn"
                                type="text"
                                size="mini"
                                @click="() => hideDepartment(item)">
                                <i class="el-icon-remove text-violet p-1 rounded" style="border: 1px solid"></i>
                            </el-button>
                            <el-button
                                title="Xoá"
                                type="text"
                                size="mini"
                                @click="() => remove(item)">
                                <i class="fas fa-trash text-red p-1 rounded" style="border: 1px solid"></i>
                            </el-button>
                        </span>
                      </div>
                  </div>
              <div class="child border-child" v-if="visible" v-for="(child, index) in item.children" :key="index" >
                  <ItemView :item="child"></ItemView>
              </div>
          </div>
      </div>
      <div>
          <el-dialog :title="titleConfirm" :visible.sync="confirm">
              <div v-if="hide">
                  <div class="d-flex justify-content-start">
                      Việc ẩn đơn vị sẽ ảnh hưởng tới các chức năng của hệ thống.
                  </div>
                  <div class="d-flex justify-content-start">
                      Bạn có chắc chắn muốn ẩn đơn vị này không?
                  </div>
              </div>
              <div v-else>
                  <div class="d-flex justify-content-start">
                      Bạn có chắc chắn muốn kích hoạt lại đơn vị này không?
                  </div>
              </div>
              <div slot="footer" class="dialog-footer">
                  <el-button @click="confirm = false">Huỷ bỏ</el-button>
                  <el-button class="text-white" type="primary" @click="action()">Xác nhận</el-button>
              </div>
          </el-dialog>
      </div>


      <div>
          <el-dialog :title="title" :visible.sync="outerVisible">
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                  <form class="form" @submit.prevent="handleSubmit(onSubmit)">
                      <div class="col-md-12 d-flex">
                          <div class="col-md-4">
                              <ValidationProvider vid="name" name="Tên đơn vị" v-slot="{ errors, classes }"
                              >
                                  <label for="">Tên đơn vị <span class="text-danger">*</span></label>
                                  <input type="text" class="form-control" v-model="form.name" placeholder="Tên đơn vị" tri>
                                  <div class="fv-plugins-message-container">
                                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                              errors[0]
                                          }}
                                      </div>
                                  </div>
                              </ValidationProvider>
                          </div>
                          <div class="col-md-4">
                              <ValidationProvider vid="type" name="Loại đơn vị" v-slot="{ errors, classes }">
                                  <label for="">Loại đơn vị <span class="text-danger">*</span></label>
                                  <el-select v-model="form.type" @input="changeAllDepartment" class="w-100">
                                      <el-option v-for="(item, index) in typeRole"
                                                 :key="index"
                                                 :label="item.name"
                                                 :value="item.value" :disabled="item.value == -1">

                                      </el-option>
                                  </el-select>
                                  <div class="fv-plugins-message-container">
                                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                              errors[0]
                                          }}
                                      </div>
                                  </div>
                              </ValidationProvider>
                          </div>
                          <div class="col-md-4">
                              <ValidationProvider vid="parent_id" name="Đơn vị cha" v-slot="{ errors, classes }">
                                  <label for="">Đơn vị cha</label>
                                  <el-select v-model="form.parent_id" clearable class="w-100">
                                      <el-option v-for="(item, index) in allRoleDepartment"
                                                 :key="index"
                                                 :label="item.name" :value="item.id">

                                      </el-option>
                                  </el-select>
                                  <div class="fv-plugins-message-container">
                                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                              errors[0]
                                          }}
                                      </div>
                                  </div>
                              </ValidationProvider>
                          </div>
                      </div>
                      <div slot="footer" class="dialog-footer d-flex justify-content-center mt-4">
                          <button type="submit" v-if="is_update" class="el-button el-button--default bg-primary text-white">Sửa</button>
                          <button type="submit" v-else @click="innerVisible = true" class="el-button el-button--default bg-primary text-white">Thêm mới</button>
                      </div>
                  </form>
              </ValidationObserver>
          </el-dialog>
      </div>
  </div>


</template>

<script>
import ItemView from "./ItemView";
import {
    ACTION_ROLE_DEPARTMENT,
    ALL_ROLE_DEPARTMENT,
    BLOCK,
    DEPARTMENT, GET_LIST_ROLE_DEPARTMENT,
    PART, REMOVE_ROLE_DEPARTMENT,
    STORE_ROLE_DEPARTMENT,
    TYPE_ROLE_DEPARTMENT,
    UPDATE_ROLE_DEPARTMENT
} from "../../../../core/services/store/department/department.module";
import Swal from "sweetalert2";
import {UPDATE_TICKET_FORM} from "../../../../core/services/store/user/ticket.module";

export default {
  name: 'ItemView',
  props: {
      item: {}
  },
    data() {
        return {
            activeName: '',
            visible: false,
            outerVisible: false,
            form: {
                id: '',
                name: '',
                type: '',
                parent_id: ''
            },
            dataConfirm: {},
            confirm: false,
            hide: false,
            titleConfirm: '',
            is_update: false,
            allRoleDepartment: {},
            typeRole: TYPE_ROLE_DEPARTMENT,
            title: '',
            keyword: '',
            data: []
        }
    },
  components: {
    ItemView
  },
    watch: {
        item: function checkedValue(val) {
            this.data = val
        }
    },
  methods: {
    changeType(type) {
      let text = '';
      switch (type) {
        case BLOCK:
          text = 'Khối';
          break;
        case DEPARTMENT:
          text = 'Phòng ban';
          break;
        case PART:
          text = 'Bộ phận';
          break;
      }
      return text;
    },
    changeVisible() {
      this.visible = !this.visible;
    },
    hideDepartment(data) {
      this.dataConfirm = data;
      this.confirm = true;
      this.hide = true;
      this.titleConfirm = 'Ẩn bộ phận ' + data.name;
    },
    active(data) {
      this.dataConfirm = data;
      this.confirm = true;
      this.hide = false;
      this.titleConfirm = 'Kích hoạt Bộ phận ' + data.name;
    },
    remove(data) {
        Swal.fire({
            title: "Bạn có chắc chắn muốn xoá đi đơn vị này !",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Xác nhận",
            cancelButtonText: "Hủy bỏ",
        }).then((result) => {
            if (result.isConfirmed) {
                this.$store.dispatch(REMOVE_ROLE_DEPARTMENT, data.id).then(res => {
                    if (!res.data) {
                        this.$bvToast.toast(res.message, {
                            title: 'Xoá đơn vị',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.$bvToast.toast(res.message, {
                            title: 'Xoá đơn vị',
                            variant: 'success',
                            solid: true
                        });
                        this.getData();
                    }
                })
            }
        });
    },
    showUpdate(data) {
      this.form = {...data};
      this.outerVisible = true;
      this.is_update = true;
      this.title = 'Cập nhật đơn vị';
      this.getAllDepartment();
    },
      changeAllDepartment() {
          this.form.parent_id = '';
          this.$store.dispatch(ALL_ROLE_DEPARTMENT, {type: this.form.type}).then(res => {
              if (res.data) {
                  this.allRoleDepartment = res.data;
              }
          })
      },
      getAllDepartment() {
          this.$store.dispatch(ALL_ROLE_DEPARTMENT, {type: this.form.type}).then(res => {
              if (res.data) {
                  this.allRoleDepartment = res.data;
              }
          })
      },
      onSubmit() {
          let formData = {...this.form}
          formData.name = this.form.name.trim();

          if (this.is_update) {
              this.$store.dispatch(UPDATE_ROLE_DEPARTMENT, formData).then(res => {
                  if (res.error) {
                      this.$bvToast.toast(res.message, {
                          title: 'Cập nhật',
                          variant: 'danger',
                          solid: true
                      });
                  }else {
                      this.$bvToast.toast(res.message, {
                          title: 'Cập nhật',
                          variant: 'success',
                          solid: true
                      });
                      this.outerVisible = false;
                      this.getData();
                  }
              }).catch((e) => {
                  if (e.data.data.message_validate_form) {
                      this.$refs.form.setErrors(e.data.data.message_validate_form);
                  }
              })
          } else {
              this.$store.dispatch(STORE_ROLE_DEPARTMENT, this.form).then(res => {
                  if (res.error) {
                      this.$bvToast.toast(res.message, {
                          title: 'Cập nhật',
                          variant: 'danger',
                          solid: true
                      });
                  }else {
                      this.$bvToast.toast(res.message, {
                          title: 'Cập nhật',
                          variant: 'success',
                          solid: true
                      });
                      this.outerVisible = false;
                      this.getData();
                  }
              }).catch((e) => {
                  if (e.data.data.message_validate_form) {
                      this.$refs.form.setErrors(e.data.data.message_validate_form);
                  }
              })
          }
      },
      action() {
          this.$store.dispatch(ACTION_ROLE_DEPARTMENT, {id: this.dataConfirm.id, status: this.hide ? 1 : 0 } ).then(res => {
              this.confirm = false;
              if (this.hide) {
                  this.getData();
                  this.$bvToast.toast('Ẩn thành công', {
                      title: 'Cập nhật',
                      variant: 'success',
                      solid: true
                  });
              }else {
                  this.$bvToast.toast('Kích hoạt thành công', {
                      title: 'Cập nhật',
                      variant: 'success',
                      solid: true
                  });
                  this.getData();
              }
          })
      },
      getData() {
          this.$store.dispatch(GET_LIST_ROLE_DEPARTMENT, {keyword: this.keyword});
      },
  }
}
</script> 
<style scoped>
.child {
  margin-left: 20px;
  font-size: 15px;
}
.hover:hover { 
    background-color: #D8CAE6 !important;  
}
.text-red {
  color: red;
}
.text-blue {
  color: blue;
}
.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}
.text-luc {
  color: #002D55;
}
.el-dialog__header {
  display: flex!important;
}
.bg-violet {
  background: #500DA7 !important;
}

.text-violet {
  color: #500DA7 !important;
}
.border-child {
    border-left: 2px solid blueviolet;
    padding-left: 5px;
}
.size-icon {
    font-size: 20px;
}
</style>
