import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const CREATE_EVENT_WEBSITE = "create-event-website";
export const UPDATE_EVENT_WEBSITE = "update-event-website";
export const GET_EVENT_WEB = "event-web";
export const DELETE_EVENT_WEB = "delete-event-web";
export const GET_EVENT_WEB_BY_ID = "get-event-web-by-id";
export const CHECK_NUMBER_HIGHLIGHTS = "check_number_highlights";

// Setter
export const SET_TOTAL_HIGHLIGHTS = "set_total_highlights";

const state = {
    total_event_highlights: 0,
};
const getters = {
    getTotalEventHighlights(state) {
        return state.total_event_highlights;
    },
};
const actions = {
    // Get provinces
    [GET_EVENT_WEB](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_EVENT_WEB, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CHECK_NUMBER_HIGHLIGHTS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('event-web/check-total-highlights', payload).then(({data}) => {
                resolve(data);
                context.commit(SET_TOTAL_HIGHLIGHTS, data.data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_EVENT_WEB_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('event-web', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_EVENT_WEBSITE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('event-web', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_EVENT_WEBSITE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('event-web/' + payload.id + '?_method=PUT', payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_EVENT_WEB](context, id) {
        return new Promise(resolve => {
            ApiService.delete('event-web', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {
    [SET_TOTAL_HIGHLIGHTS](state, data) {
        state.total_event_highlights = data;
    },
};
export default {
    state,
    actions,
    mutations,
    getters
};
