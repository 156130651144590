<template>
    <div>
        <div class="card card-custom gutter-b">
            <div class="card-header d-flex justify-content-between">
                <div class="card-title">
                    <h3 class="card-label">Báo cáo Tư vấn viên</h3>
                </div>
            </div>
            <div class="card-body">
                <!--begin::Example-->
                <div class="example mb-10">
                    <div class="input-group row">
                        <div class="col-md-2">
                            <div class="form-group" data-app>
                                <b-form>
                                    <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :return-value.sync="dates"
                                            transition="scale-transition"
                                            offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <date-picker
                                                    v-model="query.start_date"
                                                    type="date"
                                                    range
                                                    placeholder="Chọn ngày, tháng"
                                                    format="DD-MM-YYYY"
                                                    value-type="YYYY-MM-DD"
                                                    @input="getUserByPosition"
                                            ></date-picker>
                                        </template>
                                        <v-date-picker
                                                v-model="dates"
                                                no-title
                                                scrollable
                                                range
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="saveRange">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </b-form>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <el-select v-model="query.area_id"
                                           placeholder="Chọn khu vực"
                                           clearable
                                           filterable
                                           @input="selectArea($event)"
                                >
                                    <el-option
                                            v-for="item in areas"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <el-select v-model="query.branch_id"
                                           placeholder="Chọn chi nhánh"
                                           clearable
                                           filterable
                                           @input="getUserByPosition"
                                >
                                    <el-option
                                            v-for="item in branches"
                                            :key="item.id"
                                            :label="item.name_alias ? item.name_alias : item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <el-select v-model="query.tvv_id"
                                           placeholder="Chọn tư vấn viên"
                                           clearable
                                           filterable
                                >
                                    <el-option
                                            v-for="item in tvv"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="d-flex justify-content-between">
                                <button class="btn btn-primary" @click="searchReportTvv">Tìm kiếm</button>
                            </div>
                        </div>
                    </div>
                    <div class="b-table-sticky-column table-responsive">
                        <table role="table" :aria-busy="isBusy" aria-colcount="0"
                               class="table table-hover table-bordered b-table" id="__BVID__822">
                            <tr role="row" class="b-table-busy-slot" v-show="isBusy">
                                <td colspan="3" role="cell" class="">
                                    <div class="text-center text-danger my-2">
                                        <span aria-hidden="true" class="align-middle spinner-border"></span>
                                        <strong>Loading...</strong>
                                    </div>
                                </td>
                            </tr>
                            <thead>
                            <tr role="row">
                                <th class="table-primary b-table-sticky-column" style="border-bottom: none">Ngày trong
                                    tháng
                                </th>
                                <th class="font-size-10" style="min-width: 100px; vertical-align:middle">KQ tháng</th>
                                <th v-show="!isBusy" class="font-size-10" v-for="(day,index) in days" :key="index">{{
                                    day }}
                                </th>
                            </tr>
                            </thead>
                            <tbody v-show="!isBusy">
                            <tr role="row">
                                <td class="table-primary b-table-sticky-column">Số lịch hẹn</td>
                                <td class="font-size-10"><b>{{ totalScheduledAppointment }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day,'lich_hen')">{{
                                    renderSchedule(day) }}</a>
                                </td>
                            </tr>
                            <tr role="row">
                                <td class="table-primary b-table-sticky-column">Số lịch hẹn bị hủy</td>
                                <td class="font-size-10"><b>{{ totalScheduledCancel }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day,'lich_hen_huy')">{{
                                    renderScheduleCancel(day) }}</a>
                                </td>
                            </tr>
                            <tr role="row">
                                <td class="table-primary b-table-sticky-column">Số lượng KH lên test</td>
                                <td class="font-size-10"><b>{{totalCustomerOnTest}}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day,'kh_test')">{{renderCustomerOnTest(day)}}</a>
                                </td>
                            </tr>
                            <tr role="row">
                                <td title="" class="table-primary b-table-sticky-column">Số quyền lợi <a
                                        href="javascript:"
                                        id="so-quyen-loi"><i
                                        class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="so-quyen-loi" title="Doanh số lần đầu đóng dưới 1200k"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ totalBenefit }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day ,'quyen_loi')">{{renderBenefit(day,'so_quyen_loi')}}</a>
                                </td>
                            </tr>
                            <tr role="row">
                                <td title="" class="table-primary b-table-sticky-column">Quyền lợi <a href="javascript:"
                                                                                                      id="quyen-loi"><i
                                        class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="quyen-loi" title="Doanh số lần đầu đóng dưới 1200k"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ totalBenefitAmount | formatVND }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day ,'quyen_loi')">{{
                                    renderBenefit(day,'quyen_loi') | formatVND }}</a>
                                </td>
                            </tr>
                            <tr role="row">
                                <td title="" class="table-primary b-table-sticky-column">Số lượng hợp đồng mới <a
                                        href="javascript:" id="hop-dong-moi"><i class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="hop-dong-moi" title="Lần đầu đóng từ 1200k trở lên"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ totalNewContract }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day ,'hop_dong_moi')">{{renderNewContract(day,'so_hop_dong_moi')}}</a>
                                </td>
                            </tr>
                            <tr role="row">
                                <td title="" class="table-primary b-table-sticky-column">Doanh số Khách hàng mới <a
                                        href="javascript:" id="doanh-so-KH-moi"><i class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="doanh-so-KH-moi"
                                               title="Doanh số khách hàng mới đóng lần thứ 2 trở đi trong tháng"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ totalNewContractAmount | formatVND}}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day ,'doanh_so_moi')">{{renderNewContract(day,'ds_so_hop_dong_moi')
                                    | formatVND}}</a>
                                </td>
                            </tr>
                            <tr role="row">
                                <td class="table-primary b-table-sticky-column">Số tiền rút</td>
                                <td class="font-size-10"><b>{{ Math.abs(totalWithDraw) | formatVND }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day ,'rut_tien')">{{
                                    Math.abs(renderRefundMoreThan(day,'withdraw')) | formatVND}}</a>
                                </td>
                            </tr>

                            <tr role="row">
                                <td title="" class="table-primary b-table-sticky-column">Tổng doanh số <a
                                        href="javascript:"
                                        id="tong-ds"><i
                                        class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="tong-ds"
                                               title="Doanh số KH mới + Quyền lợi - Doanh số rút(trong tháng)"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ tongDoanhSo | formatVND }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{ renderDoanhSo(day)
                                    | formatVND }}
                                </td>
                            </tr>
                            <tr role="row">
                                <td class="table-primary b-table-sticky-column">Giá trị hợp đồng
                                    <a href="javascript:"
                                       id="gia-tri-hop-dong"><i
                                            class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="gia-tri-hop-dong"
                                               title="Tổng giá học phí trong hợp đồng đã trừ mã khuyến mại"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ Math.abs(totalContractTvv) | formatVND }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                    {{ Math.abs(renderContractTvv(day,'gia_tri_hop_dong')) | formatVND}}
                                </td>
                            </tr>
                            <tr role="row">
                                <td title="" class="table-primary b-table-sticky-column">Tỷ lệ chốt <a
                                        href="javascript:"
                                        id="ty-le-chot"><i
                                        class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="ty-le-chot"
                                               title="Số HĐ mới/Tổng Số KH lên test"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ Math.round(contractClosing) }}%</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                    {{renderContractClosing(day)}}%
                                </td>
                            </tr>
                            <tr role="row">
                                <td title="" class="table-primary b-table-sticky-column">Doanh số trung bình hợp đồng <a
                                        href="javascript:"
                                        id="doanh_so_trung_binh_hop_dong"><i
                                        class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="doanh_so_trung_binh_hop_dong"
                                               title="Tổng doanh số mới/ Số hợp đồng mới"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ Math.round(averageContract) | formatVND }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                    {{renderAverageContract(day) | formatVND}}
                                </td>
                            </tr>
                            <tr role="row">
                                <td title="" class="table-primary b-table-sticky-column">Tỷ lệ doanh số mới <a
                                        href="javascript:"
                                        id="ds-noi-bo"><i
                                        class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="ds-noi-bo"
                                               title="Tổng doanh số mới/ Tổng giá trị hợp đồng"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{Math.round(percentNewSales) }}%</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                    {{Math.round(renderPercentNewSales(day))}}%
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <register-on-staff :showModal="showOnStaff" :dataDetail="dataDetail" :type-on-staff="typeOnStaff"
                               @clicked="clickOnStaff"></register-on-staff>
            <schedule-by-date-tvv :showModal="showScheduleDetail" :scheduleData="dataDetail" :type-action="typeAction"
                                  :titleType="title" @clicked="clickOnSchedule"></schedule-by-date-tvv>
            <contract-detail-tvv-table :show-modal="showContractDetail" :contract-data="dataDetail"
                                       :type-action="typeAction"
                                       @clicked="clickOnContractDetail"></contract-detail-tvv-table>
        </div>
    </div>
</template>

<script>
    import Swal from 'sweetalert2'
    import moment from 'moment';
    import Multiselect from 'vue-multiselect';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
    import {GET_AREAS} from "../../../core/services/store/center/area.module";
    import {
        REPORT_TVV,
        SHOW_DETAIL_BY_DATE_TVV
    } from "../../../core/services/store/report/report.module";
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';
    import '@/assets/sass/custom/dskh-doi/dskh_doi.scss';

    import RegisterOnStaff from "./RegisterOnStaff";
    import ScheduleByDate from "./ScheduleByDate";
    import ContractDetailTable from "./ContractDetailTable";
    import {GET_USER_BY_POSITION} from "../../../core/services/store/user/users.module";
    import ContractDetailTvvTable from "./tvv/ContractDetailTvvTable";
    import ScheduleByDateTvv from "./tvv/ScheduleByDateTvv";

    const _ = require('lodash');

    export default {
        name: "ReportTvv",
        data() {
            return {
                /* query */
                query: {
                    start_date: '',
                    branch_id: '',
                    area_id: '',
                    tvv_id: '',
                },
                /* date-picker */
                dates: [],
                menu: false,
                isBusy: false,
                show_date: '',
                /* options */
                branchesByCenter: [],
                branches: [],
                areas: [],
                tvv: [],
                days: [],
                positions: [{name: 'Sale Expert', value: 5}, {name: 'Sale Member', value: 6}, {
                    name: 'Sale Intern',
                    value: 7
                }],
                /*Cache */
                cache_status: '',
                cache_current_account_type: '',
                cache_area: '',
                /*Loader */
                isLoading: false,
                fullPage: true,
                tree: null,
                /*payload */
                payload: {
                    limit: 1000000,
                    status: 1,
                    is_with: 0,
                    area_id: ''
                },
                /* data response*/
                eventRegisters: [],
                dataDetail: [],
                title: '',
                leadersOnWork: [],
                scheduledAppointments: [],
                scheduledCancels: [],
                customersOnTest: [],
                benefit: [],
                debtContract: [],
                newContract: [],
                contractTvv: [],
                contractInternal: [],
                refundMoreThan: [],
                idCreated: [],
                /* is show variable */
                showOnStaff: false,
                typeOnStaff: false,
                showScheduleDetail: false,
                showContractDetail: false,
                typeAction: '',
                /* total variable */
                totalStaffOnWork: 0,
                totalRealStaffOnWork: 0,
                totalLeaderOnWork: 0,
                totalScheduledAppointment: 0,
                totalScheduledCancel: 0,
                totalCustomerOnTest: 0,
                totalBenefit: 0,
                totalBenefitAmount: 0,
                totalDebtContract: 0,
                totalDebtContractAmount: 0,
                totalNewContract: 0,
                totalContractTvv: 0, // Giá trị hợp đồng
                totalNewContractAmount: 0,
                totalContractInternal: 0,
                totalContractInternalAmount: 0,
                totalWithDraw: 0,
                totalRefund: 0,
                contractClosing: 0,
                tongDoanhSo: 0, // Tổng doanh số
                percentNewSales: 0, // tỉ lệ doanh số mới
                //
                totalContracts: 0,
                averageContract: 0,
                year: ''
            };
        },
        components: {
            ScheduleByDateTvv,
            ContractDetailTvvTable,
            Multiselect, DatePicker, RegisterOnStaff, ScheduleByDate, ContractDetailTable
        },
        computed: {},
        created() {
            this.isBusy = true;
            this.getBranches();
            this.getAreas();
            this.setParams();
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý tài chính"},
                {title: "Báo cáo Tư vấn viên"}
            ]);
            if (this.query.branch_id) {
                this.getUserByPosition();
            }
        },
        methods: {
            /* all get data function */
            getBranches: function () {
                this.$store.dispatch(GET_BRANCHES, this.payload).then((res) => {
                    this.branches = res.data.data;
                    this.branchesByCenter = res.data.data;
                    let branch_id = this.$route.query.branch_id ? this.$route.query.branch_id : '';
                });
            },
            getAreas: function () {
                this.$store.dispatch(GET_AREAS, this.payload).then((res) => {
                    this.areas = res.data.data;
                    let area_id = this.$route.query.area_id ? this.$route.query.area_id : '';
                    this.cache_area = _.find(this.areas, function (area) {
                        return area.id === parseInt(area_id);
                    });
                });
            },
            searchReportTvv: function () {
                this.isBusy = true;
                this.pushParams();
                this.getQuery();
            },
            getQuery: async function () {
                this.query.idCreated = [];
                this.query.action = undefined;
                this.query.date = undefined;
                await this.$store.dispatch(REPORT_TVV, this.query).then((res) => {
                    this.year = res.data.year;
                    this.days = res.data.days;
                    this.idCreated = res.data.idCreated;
                    this.newContract = res.data.newContract;
                    this.scheduledAppointments = res.data.scheduledAppointment;
                    this.customersOnTest = res.data.customersOnTest;
                    this.scheduledCancels = res.data.scheduledCancel;
                    this.benefit = res.data.benefit;
                    this.contractTvv = res.data.contractTvv;
                    this.refundMoreThan = res.data.refund;
                    this.isBusy = false;
                    this.countTotalStaffOnWork();
                }).catch((error) => {
                    if (error.data.error) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: error.data.message,
                            showConfirmButton: false,
                            timer: 2500
                        }).then(() => {
                            this.isBusy = false;
                        })
                    }
                });
            },
            /* all select function */
            selectArea: function ($event) {
                this.payload.area_id = this.query.area_id;
                this.$store.dispatch(GET_BRANCHES, this.payload).then((res) => {
                    this.branches = res.data.data;
                    this.branchesByCenter = res.data.data;
                });
                this.query.branch_id = '';
            },
            selectSource: function ($event) {
                this.query.nguon = $event != null ? $event : '';
            },
            selectSale: function ($event) {
                this.query.sale = $event ? $event : '';
            },
            saveRange: function () {
                this.$refs.menu.save(this.dates);
                let range = this.dates.sort(function (left, right) {
                    return moment(left) - moment(right);
                }).map(item => moment(item).format('DD/MM/YYYY'));
                this.show_date = range.join(' - ');
                this.query.start_date = this.show_date;
            },
            setParams: async function () {
                this.query.branch_id = this.$route.query.branch_id ? parseInt(this.$route.query.branch_id) : '';
                this.query.area_id = this.$route.query.area_id ? parseInt(this.$route.query.area_id) : '';
                this.query.start_date = this.$route.query.start_date ? this.$route.query.start_date : [];
                this.getQuery();
            },
            pushParams: function () {
                this.query.idCreated = [];
                this.$router.push({path: '/reports/tvv', query: this.query});
            },
            onCancel: function () {
                console.log('User cancelled the loader.')
            },
            normalizer: function (node) {
                return {
                    id: node.id,
                    label: node.name,
                }
            },
            getFullDate: function (date) {
                date += '/' + this.year;
                return date;
            },

            getUserByPosition() {
                if (!this.query.branch_id) {
                    return false;
                }

                this.tvv = [];
                this.query.tvv_id = '';
                this.$store.dispatch(GET_USER_BY_POSITION, {
                    position: 'TVV',
                    branch_id: this.query.branch_id,
                    start_date: this.query.start_date,
                }).then((data) => {
                    this.tvv = data.data;
                })
            },
            /* all click function */
            showDetailByDate: async function (date, action) {
                this.query.date = this.getFullDate(date);
                this.query.idCreated = this.idCreated;
                this.query.action = action;
                await this.$store.dispatch(SHOW_DETAIL_BY_DATE_TVV, this.query).then((res) => {
                    this.dataDetail = res.data;
                });
                switch (action) {
                    case 'dk_lam' :
                        this.typeOnStaff = 'dk_lam';
                        this.showOnStaff = true;
                        break;
                    case 'thuc_te_lam' :
                        this.typeOnStaff = 'thuc_te_lam';
                        this.showOnStaff = true;
                        break;
                    case 'so_ca_lead' :
                        this.typeOnStaff = 'so_ca_lead';
                        this.showOnStaff = true;
                        break;
                    case 'lich_hen' :
                        this.title = 'Danh sách lịch hẹn trong ngày';
                        this.typeAction = 'lich_hen';
                        this.showScheduleDetail = true;
                        break;
                    case 'lich_hen_huy' :
                        this.title = 'Danh sách lịch hẹn hủy';
                        this.typeAction = 'lich_hen_huy';
                        this.showScheduleDetail = true;
                        break;
                    case 'kh_test' :
                        this.title = 'Danh sách khách hàng lên test';
                        this.typeAction = 'kh_test';
                        this.showScheduleDetail = true;
                        break;
                    case 'quyen_loi' :
                        this.typeAction = 'quyen_loi';
                        this.showContractDetail = true;
                        break;
                    case 'hop_dong_no' :
                        this.typeAction = 'hop_dong_no';
                        this.showContractDetail = true;
                        break;
                    case 'hop_dong_moi' :
                        this.typeAction = 'hop_dong_moi';
                        this.showContractDetail = true;
                        break;
                    case 'doanh_so_moi' :
                        this.typeAction = 'doanh_so_moi';
                        this.showContractDetail = true;
                        break;
                    case 'hop_dong_noi_bo' :
                        this.typeAction = 'hop_dong_noi_bo';
                        this.showContractDetail = true;
                        break;
                    case 'hoan_tien' :
                        this.typeAction = 'hoan_tien';
                        this.showContractDetail = true;
                        break;
                    case 'rut_tien' :
                        this.typeAction = 'rut_tien';
                        this.showContractDetail = true;
                        break;
                }
            },
            clickOnStaff: function () {
                this.showOnStaff = false;
            },
            clickOnSchedule: function () {
                this.showScheduleDetail = false;
            },
            clickOnContractDetail: function () {
                this.showContractDetail = false;
            },
            /* all render function */
            renderData: function (day, data, type = '') {
                let value = _.find(data, function (item) {
                    return item.day === day;
                });
                if (type === 'so_quyen_loi' || type === 'gia_tri_hop_dong' || type === 'so_hop_dong_no' || type === 'so_hop_dong_moi' || type === 'so_hop_dong_noi_bo') {
                    return value ? value.total : 0;
                } else if (type === 'quyen_loi' || type === 'doanh_so_no' || type === 'ds_so_hop_dong_moi' || type === 'ds_so_hop_dong_noi_bo') {
                    return value ? value.amount : 0;
                } else if (type === 'withdraw') {
                    return value && value.withdraw ? value.withdraw : 0;
                } else if (type === 'refund') {
                    return value && value.refund ? value.refund : 0;
                } else {
                    return value ? value.number : 0;
                }
            },
            renderStaffOnWork: function (day) {
                return this.renderData(day, this.eventRegisters);
            },
            renderRealStaffOnWork: function (day) {
                let register = _.find(this.eventRegisters, function (item) {
                    return item.day === day;
                });
                return register ? register.attendance : 0;
            },
            renderLeaderOnWork: function (day) {
                return this.renderData(day, this.leadersOnWork);
            },
            renderSchedule: function (day) {
                return this.renderData(day, this.scheduledAppointments);
            },
            renderScheduleCancel: function (day) {
                return this.renderData(day, this.scheduledCancels);
            },
            renderCustomerOnTest: function (day) {
                return this.renderData(day, this.customersOnTest);
            },
            renderBenefit: function (day, type) {
                return this.renderData(day, this.benefit, type);
            },
            renderDebtContract: function (day, type) {
                return this.renderData(day, this.debtContract, type);
            },
            renderNewContract: function (day, type) {
                return this.renderData(day, this.newContract, type);
            },
            renderContractTvv: function (day, type) {
                return this.renderData(day, this.contractTvv, type);
            },
            renderContractClosing: function (day) {
                return this.renderCustomerOnTest(day) !== 0 ? Math.round(this.renderNewContract(day, 'so_hop_dong_moi') / this.renderCustomerOnTest(day) * 100) : 0;
            },
            renderAverageContract: function (day) {
                // let sales = this.renderBenefit(day, 'quyen_loi') + this.renderNewContract(day, 'ds_so_hop_dong_moi')
                //     + this.renderDebtContract(day, 'doanh_so_no')
                //     + this.renderRefundMoreThan(day, 'withdraw') + this.renderRefundMoreThan(day, 'refund');
                let sales = this.renderNewContract(day, 'ds_so_hop_dong_moi');
                let totalContractNew = Math.round(this.renderNewContract(day, 'so_hop_dong_moi'));
                return totalContractNew > 0 ? sales / totalContractNew : 0;
            },
            renderRefundMoreThan: function (day, type) {
                return this.renderData(day, this.refundMoreThan, type);
            },
            renderDoanhSo: function (day) {
                // Quyền lợi + Doanh số KH mới + Doanh số nợ + Doanh số nội bộ - Rút tiền - Hoàn tiền
                return this.renderBenefit(day, 'quyen_loi') + this.renderNewContract(day, 'ds_so_hop_dong_moi')
                    + this.renderDebtContract(day, 'doanh_so_no')
                    + this.renderRefundMoreThan(day, 'withdraw') + this.renderRefundMoreThan(day, 'refund');
            },

            renderPercentNewSales: function (day) {
                let tongDoanhso = Math.abs(this.renderDoanhSo(day, 'gia_tri_hop_dong'));
                let totalContractTvv = Math.abs(this.renderContractTvv(day, 'gia_tri_hop_dong'));
                return totalContractTvv > 0 ? (tongDoanhso / totalContractTvv) * 100 : 0;
            },
            /* all sum total function */
            countTotalStaffOnWork: function () {
                this.totalStaffOnWork = _.reduce(this.eventRegisters, function (result, item, key) {
                    result += item.number;
                    return result;
                }, 0);
                this.totalRealStaffOnWork = _.reduce(this.eventRegisters, function (result, item, key) {
                    result += item.attendance;
                    return result;
                }, 0);
                this.totalLeaderOnWork = _.reduce(this.leadersOnWork, function (result, item, key) {
                    result += item.number;
                    return result;
                }, 0);
                this.totalScheduledAppointment = _.reduce(this.scheduledAppointments, function (result, item, key) {
                    result += item.number;
                    return result;
                }, 0);
                this.totalScheduledCancel = _.reduce(this.scheduledCancels, function (result, item, key) {
                    result += item.number;
                    return result;
                }, 0);
                this.totalCustomerOnTest = _.reduce(this.customersOnTest, function (result, item, key) {
                    result += item.number;
                    return result;
                }, 0);
                this.totalBenefit = _.reduce(this.benefit, function (result, item, key) {
                    result += item.total;
                    return result;
                }, 0);
                this.totalBenefitAmount = _.reduce(this.benefit, function (result, item, key) {
                    result += item.amount;
                    return result;
                }, 0);
                this.totalNewContract = _.reduce(this.newContract, function (result, item, key) {
                    result += item.total;
                    return result;
                }, 0);
                this.totalNewContractAmount = _.reduce(this.newContract, function (result, item, key) {
                    if (item.amount_coefficient) {
                        result += item.amount_coefficient;
                    } else {
                        result += item.amount;
                    }
                    return result;
                }, 0);
                this.totalDebtContract = _.reduce(this.debtContract, function (result, item, key) {
                    result += item.total;
                    return result;
                }, 0);
                this.totalDebtContractAmount = _.reduce(this.debtContract, function (result, item, key) {
                    result += item.amount;
                    return result;
                }, 0);
                this.totalContractInternal = _.reduce(this.contractInternal, function (result, item, key) {
                    let total = item.total ? item.total : 0;
                    result += total;
                    return result;
                }, 0);
                this.totalContractInternalAmount = _.reduce(this.contractInternal, function (result, item, key) {
                    result += item.amount;
                    return result;
                }, 0);
                this.totalRefund = _.reduce(this.refundMoreThan, function (result, item, key) {
                    if (item.refund) {
                        result += item.refund;
                    }
                    return result;
                }, 0);
                this.totalWithDraw = _.reduce(this.refundMoreThan, function (result, item, key) {
                    if (item.withdraw) {
                        result += item.withdraw;
                    }
                    return result;
                }, 0);
                this.totalContractTvv = _.reduce(this.contractTvv, function (result, item, key) {
                    result += item.total;
                    return result;
                }, 0);
                this.contractClosing = this.totalCustomerOnTest > 0 ? this.totalNewContract / this.totalCustomerOnTest * 100 : 0;
                // Quyền lợi + Doanh số KH mới + Doanh số nợ + Doanh số nội bộ - Rút tiền - Hoàn tiền
                this.tongDoanhSo = this.totalBenefitAmount + this.totalNewContractAmount + this.totalDebtContractAmount + this.totalContractInternalAmount + this.totalWithDraw + this.totalRefund;
                this.averageContract = this.totalNewContract > 0 ? this.totalNewContractAmount / this.totalNewContract : 0;
                this.percentNewSales = this.totalContractTvv > 0 ? (this.tongDoanhSo / this.totalContractTvv) * 100 : 0;
            }
        },
    };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
