<template>
  <div>
    <b-modal id="Modal_Edit_News" :title="`Chi tiết nội dung Buổi ${item.lesson}`" centered hide-footer ref="Modal_Edit_News" size="xl" >
      <table class="table table-bordered table-hover table-vertical-center table-responsive">
        <thead class="thead-light">
        <tr class="text-center">
          <th class="min-w-110px">Material</th>
          <th>Vocab</th>
          <th>Grammar</th>
          <th>Reading</th>
          <th>Speaking</th>
          <th>Writing</th>
          <th>Listening</th>
        </tr>
        </thead>
        <tbody >
        <tr >
          <td>{{ item.material }}</td>
          <td>{{ item.vocabulary }}</td>
          <td>{{ item.grammar }}</td>
          <td>{{ item.reading }}</td>
          <td>{{ item.speaking }}</td>
          <td>{{ item.writing }}</td>
          <td>{{ item.listening }}</td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex  flex-row-reverse">
        <b-button variant="light" @click="cancel()">Đóng</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "modalDetail",
  watch :{
    item(newValue,oldValue){
      console.log(newValue)
    }
  },
  props:{
    item:{
      type : Object,
      default(){
        return {}
      }
    }
  },
  methods:{
    cancel(){
      this.$bvModal.hide('Modal_Edit_News')
    }
  }
}
</script>

<style scoped>

</style>