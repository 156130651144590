<template>
  <div>

    <b-modal
        @ok="handleOk"
        centered id="modal-create-ticket"
        size="lg"
        :title="title"
        ok-title="Lưu"
        cancel-title="Hủy"
        ref="my-modal"
    >
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" autocomplete="off" >
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Tên chương trình </label><span class="text-danger">*</span>
                  <ValidationProvider vid="name" name="Tên chương trình"
                                      :rules="{ required: true }"
                                      v-slot="{ errors,classes }">
                    <el-input placeholder="Tên chương trình"
                              :class="classes"
                              v-model="refund_program.name"
                              maxlength="50"
                              show-word-limit
                    ></el-input>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Thời gian áp dụng <span class="text-danger">*</span></label>
                  <ValidationProvider vid="time_start" name="Chọn khoảng thời gian áp dụng"
                                      :rules="{ required: true }"
                                      v-slot="{ errors,classes }">
                    <span class="text-danger"></span>
                    <date-picker
                        placeholder="Chọn thời gian"
                        format="DD-MM-YYYY"
                        range
                        valueType="YYYY-MM-DD" v-model="refund_program.date_range">
                    </date-picker>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>Trung tâm áp dụng <span class="text-danger">*</span></label>
                  <ValidationProvider vid="center_id" name="Trung tâm áp dụng"
                                      :rules="{  }"
                                      v-slot="{ errors,classes }">
                    <el-select filterable class="w-100" placeholder="Chọn trung tâm áp dụng"
                               clearable
                               multiple
                               v-model="refund_program.center_ids"
                               @input="changeCenter()"
                    >
                      <el-option
                          v-for="item in listCenter"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      >
                        <span style="float: left">{{ item.name}}</span>
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>

                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Chi nhánh áp dụng <span class="text-danger">*</span></label>
                  <ValidationProvider vid="branch_id" name="Chi nhánh áp dụng"
                                      :rules="{  }"
                                      v-slot="{ errors,classes }">
                    <el-select filterable class="w-100"
                               placeholder="Chọn chi nhánh áp dụng"
                               clearable
                               multiple
                               v-model="refund_program.branch_ids"
                               @input="checkBranchModal"
                    >
                      <el-option
                          v-for="item in listBranch"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      >
                        <span style="float: left">{{ item.name }}</span>
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <label class="col-3 col-form-label">Trạng thái</label>
                <div class="col-3">
                  <span class="switch switch-sm">
                    <label>
                     <input type="checkbox" checked="checked" name="select" v-model="status"/>
                     <span></span>
                    </label>
                   </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import {CREATE_REFUND_PROGRAM, UPDATE_REFUND_PROGRAM} from "@/core/services/store/contract/contract-program.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  GET_BRANCH_BY_LIST_CENTER_ID,
} from "@/core/services/store/center/branch.module";

export default {
  name: "ModalCreateTicket",
  components: {
    DatePicker
  },
  props: {
    program: {
      type: Object,
      default() {
        return {};
      }
    },
    listBranch_prop: {
      type: Array,
      default() {
        return [];
      }
    },
    listCenter_prop: {
      type: Array,
      default() {
        return [];
      }
    },
    date_filter_default_prop: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      refund_program: {
        center_ids: [],
        branch_ids: [],
        name: '',
        status: true,
        date_range: [],
        id: '',
        branches: [],
        centers: []
      },
      status: true,
      date_filter_default: [],
      listCenter: [{
        id: 0,
        name:'Tất cả trung tâm'
      }],
      listBranch: [{
        id: 0,
        name:'Tất cả chi nhánh'
      }],
      title: 'Thêm mới chương trình hoàn tiền'
    }
  },
  mounted() {
  },
  watch: {
    program: {
      handler(){
        this.refund_program = this.program;
        this.status =  true;
        if (this.program.id) {
          this.refund_program = {...this.program}
          this.status = this.program.status;
          this.refund_program.center_ids = this.program?.centers?.map(item => Number(item.id));
          this.refund_program.branch_ids = this.program?.branches?.map(item => Number(item.id)).length ? this.program?.branches?.map(item => Number(item.id)) : [0];
          if(this.refund_program?.center_ids?.length){
            this.checkCenter(this.refund_program.center_ids);
          }else {
            this.refund_program.center_ids = [0];
            this.checkCenter(this.refund_program.center_ids);
          }
          if(this.refund_program.id){
            this.title = 'Cập nhập chương trình hoàn tiền';
            this.refund_program.date_range = [this.program.time_start, this.program.time_end];
          }else {
            this.title = 'Thêm mới chương trình hoàn tiền';
          }
        }
      },
      immediate: true,
      deep: true
    },
    listCenter_prop() {
      if (this.listCenter_prop) {
        this.listCenter = this.listCenter.concat(this.listCenter_prop);
      }
    },
    date_filter_default(){
      if (!this.date_filter_default[0]){
        this.date_filter_default = [];
      }
    }
  },
  methods: {
    handleOk(e) {
      e.preventDefault();
      let name = this.refund_program.name;
      let time_start = this.refund_program.date_range;
      let center_id = this.refund_program.center_ids;
      let branch_id = this.refund_program.branch_ids;
      let status = this.status;
      let total_center = 0;
      let total_branch = 0;
      if (time_start){
        if (!time_start[0]){
          time_start = [];
        }
      }
      if(center_id  == 0){
        total_center = 1;
      }else {
        total_center = 0;
      }
      if(branch_id  == 0){
        total_branch = 1;
      }else {
        total_branch = 0;
      }

      if (this.program.id) {
        let id = this.program.id;
        this.$store.dispatch(UPDATE_REFUND_PROGRAM, {
          id: id, name: name, time_start: time_start, center_id: center_id, branch_id: branch_id, status: status , total_center:total_center , total_branch:total_branch
        }).then(res => {
          if (!res.error) {
            this.$emit("addParam");
            this.$bvToast.toast(res.message, {
              title: 'Cập nhật',
              variant: 'success',
              solid: true,

            });
            this.$refs['my-modal'].hide();
            this.refund_program = this.program;
          }
        }).catch((e) => {
          if (e.data.data.message_validate_form) {
            this.$refs.form.setErrors(e.data.data.message_validate_form);
          }
        })
      } else {
        this.$store.dispatch(CREATE_REFUND_PROGRAM, {
          name: name, time_start: time_start, center_id: center_id, branch_id: branch_id, status: status , total_center:total_center , total_branch:total_branch
        }).then(res => {
          if (!res.error) {
            this.$emit("addParam");
            this.$bvToast.toast(res.message, {
              title: 'Cập nhật',
              variant: 'success',
              solid: true
            });
            this.$refs['my-modal'].hide();
          }
        }).catch((e) => {
          if (e.data.data.message_validate_form) {
            this.$refs.form.setErrors(e.data.data.message_validate_form);
          }
        })
      }
    },
    changeCenter() {
      let center_id = this.refund_program.center_ids;
      if (center_id.includes(0)){
        if(center_id[0] == 0 && center_id.length != 1){
          let index = center_id.indexOf(0);
          if (index > -1) { // only splice array when item is found
            center_id.splice(index, 1); // 2nd parameter means remove one item only
          }
          this.checkCenter(center_id);
        }else {
          this.refund_program.center_ids = [0];
          this.$store.dispatch(GET_BRANCH_BY_LIST_CENTER_ID,{}).then(res => {
            if (!res.error) {
              let listBranch = [{
                id: 0,
                name:'Tất cả chi nhánh'
              }];
              this.listBranch = listBranch.concat(res.data);
            }
          })
        }
      }else {
        this.checkCenter(center_id);
      }
    },
    checkCenter(center_id){
      let listBranch = [{
        id: 0,
        name:'Tất cả chi nhánh'
      }];
      if(center_id[0]){
    
        this.$store.dispatch(GET_BRANCH_BY_LIST_CENTER_ID, {center_id: center_id}).then(res => {
          if (!res.error) {
            this.listBranch = listBranch.concat(res.data);
            this.removeNoNeedBranch();
          }
        })
      }else {
        this.$store.dispatch(GET_BRANCH_BY_LIST_CENTER_ID,{
          center_id: this.program.center_ids
        }).then(res => {
          let listBranch = [{
            id: 0,
            name:'Tất cả chi nhánh'
          }];
          this.listBranch = listBranch.concat(res.data);
          this.removeNoNeedBranch();
        })
      }


    },
    checkBranchModal(){
      let branch_id = this.refund_program.branch_ids;
      if (branch_id.includes(0)) {
        if (branch_id[0] == 0 && branch_id.length != 1) {
          let index = branch_id.indexOf(0);
          if (index > -1) { // only splice array when item is found
            branch_id.splice(index, 1); // 2nd parameter means remove one item only
          }
        }else if (branch_id[0] != 0 && branch_id.length != 1){
          this.refund_program.branch_ids = [0]
        }
      }
    },
    removeNoNeedBranch(){
      var updateLevelSelect = []
      if(this.refund_program.center_ids.length){
        _.forEach(this.refund_program.branch_ids, (value, key) => {
          let check = this.listBranch.find(x => x.id == value);
          if (check) {
            updateLevelSelect.push(value)
          }
        });
      }else {
        updateLevelSelect = [];
      }
      this.refund_program.branch_ids = updateLevelSelect;
    },
  }
}
</script>

<style scoped>
.el-select {
  width: 100%;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}
</style>