<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách slide</h3>
      </div>
      <div class="card-title">
        <div class="card-title">
          <b-button id="course_type_create" class="btn btn-success" @click="clickCreateNewSlide">Thêm mới</b-button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Tên slide
                <span class="text-danger"></span></label>
              <el-input type="text" v-model="query.name_slide" placeholder="Nhập tên slide"></el-input>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Trung tâm áp dụng</label>
              <el-select v-model="query.center_id" clearable filterable placeholder="Chọn trung tâm áp dụng"
                class="w-100">
                <el-option v-for="item in centers" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">Trạng thái</label>
              <el-select v-model="query.slide_status" clearable filterable placeholder="Chọn trạng thái" class="w-100">
                <el-option v-for="item in statusOfSlide" :key="item.id" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3 form-group d-flex flex-column justify-content-end">
            <div class="d-flex justify-content-between">
              <el-button size="small" :disabled="isBusy" class="btn btn-primary font-weight-bold mr-2"
                :class="isBusy ? 'spinner spinner-white spinner-left' : ''" @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
              </el-button>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive mt-4">
          <table :aria-busy="isBusy" class="table table-vertical-center table-bordered table-hover b-table">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" class="min-w-20px">STT</th>
                <th scope="col" class="min-w-120px">Tên slide</th>
                <th scope="col" class="min-w-120px">Trung tâm áp dụng</th>
                <th scope="col" class="min-w-110px">Trạng thái</th>
                <th scope="col" class="min-w-250px">Người upload</th>
                <th scope="col" class="min-w-150px">Thời gian upload</th>
                <th scope="col" class="action-btn">Hành động</th>
              </tr>
            </thead>
            <tbody v-if="isBusy">
              <tr role="row" class="b-table-busy-slot">
                <td colspan="16" role="cell" class="text-center">
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="list_slide.length">
              <tr v-for="(item, index) in list_slide" :key="index">
                <td>{{ (list_slide_pagination.current_page - 1) * list_slide_pagination.per_page + index + 1 }}</td>
                <td>{{ item.name_slide }}</td>
                <td>{{ item.center && item.center.name }}</td>
                <td>
                  <span class="badge" v-bind:class="item.slide_status ? 'badge-success' : 'badge-danger'">
                    {{ item.slide_status ? "Hiển thị" : 'Ẩn' }}
                  </span>
                </td>
                <td v-if="item.user">{{ item.user.id + 100000 }} - {{ item.user && item.user.name }}</td>
                <td v-else></td>
                <td>{{ item.created_at | formatDateTimeVietnam }}</td>
                <td>
                  <div class="d-flex justify-content-around">
                    <a title="Tải xuống slide"  :href="item.uri_slide"
                      class="btn btn-sm btn-icon btn-outline-success" download>
                      <i class="el-icon-download"></i></a>
                    <a title="Cập nhật" href="javascript:" class="btn btn-sm btn-icon btn-outline-primary "
                      @click="clickEditSlide(item)"><i class="fas fa-pen-nib"></i></a>


                    <a title="Xóa" @click="removeSlide(item.id)" href="javascript:"
                      class="btn btn-sm btn-icon btn-outline-danger"><i class="el-icon-delete-solid"></i>
                    </a>
                  </div>
                </td>

              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7" class="text-center font-italic">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center" v-if="list_slide">
      <paginate v-model="list_slide_pagination.current_page" :page-count="list_slide_pagination.total_pages"
        :page-range="3" :margin-pages="1" :click-handler="clickCallback" :prev-text="'Trước'" :next-text="'Sau'"
        :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'" :next-link-class="'next-link-item'"
        :prev-link-class="'prev-link-item'" :prev-class="'page-link'" :next-class="'page-link'"
        :page-class="'page-item'">
      </paginate>
    </div>
    <!-- Dialog create -->
    <el-dialog title="Thêm mới slide" :visible.sync="dialogUpload" @close="closeModal">
      <div class="container">
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <form autocomplete="off" @submit.prevent="handleSubmit(importData)">
            <div class="row">
              <div class="col-md-6 col-xs-12 col-sm-12">
                <div class="form-group">
                  <label for="name">Tên slide<span class="text-danger">*</span></label>
                  <ValidationProvider vid="name" name="Tên" rules="required|max:50" v-slot="{ errors, classes }">
                    <el-input id="name" type="text" ref="name" name="name" v-model="form.name_slide"
                      placeholder="Nhập tên slide" :class="classes" />
                    <error-message field="name" :errors="errors"></error-message>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-12">
                <div class="form-group">
                  <label>Trung tâm áp dụng<span class="text-danger">*</span></label>
                  <ValidationProvider vid="center" name="Trung tâm" rules="required" v-slot="{ errors, classes }">
                    <el-select v-model="form.center_id" clearable filterable placeholder="Chọn trung tâm áp dụng"
                      class="w-100">
                      <el-option v-for="item in centers" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                    <error-message field="center" :errors="errors"></error-message>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-md-6 col-xs-12 col-sm-12">
                <div class="form-group">
                  <label>Loại file<span class="text-danger">*</span></label>
                  <ValidationProvider vid="type" name="Chọn loại file" rules="required"
                                      v-slot="{ errors,classes }">
                    <el-select v-model="form.type_slide"
                              @change="changeTypeFile(form.type_slide)"
                               clearable filterable
                               placeholder="Chọn loại file" class="w-100">
                      <el-option v-for="item in nameTypeFile"
                                 :key="item.id"
                                 :label="item.name"
                                 :value="item.id">
                      </el-option>
                    </el-select>
                    <error-message field="type" :errors="errors"></error-message>
                  </ValidationProvider>
                </div>
              </div>

              <div class="col-md-6 col-xs-12 col-sm-12 d-flex align-items-center mb-0">
                <div>
                  <span>Kích hoạt: </span>
                  <el-switch v-model="form.slide_status" />
                </div>
              </div>

              <!-- Nếu không phải là file Google Slides -->
              <!-- <div v-if="form.type_sile === 'PowerPoint' && false" class="col-md-6 col-xs-12 col-sm-12">
              <div v-if="form.type_slide === 1" class="col-md-6 col-xs-12 col-sm-12">
                <div>
                  <div class="form-group">
                    <label>File tải lên<span class="text-danger">*</span></label>
                    <ValidationProvider vid="file" name="File tải lên" rules="required" v-slot="{ errors, classes }">
                      <el-upload class="upload-demo" action="/" accept=".pptx" :on-preview="handlePreview"
                        :on-remove="handleRemove" :auto-upload="false" multiple :limit="1" :on-change="handleChange"
                        :on-exceed="handleExceed" :file-list="files">
                        <el-button style="color: black;" type="warning" size="small" v-if="files.length === 0"><i
                            style="color: black;" class="el-icon-upload2 mr-1"></i>Chọn file</el-button>
                      </el-upload>
                      <input type="text" hidden name="file" ref="file" v-model="form.file">
                      <error-message field="file" :errors="errors"></error-message>
                      <div v-if="percentage > 0 && percentage_complete">
                        <el-progress :percentage="percentage"></el-progress>
                        Upload Progress: {{ percentage }}%
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div> -->

              <!-- Nếu file Google Slides -->
              <div class="w-100">
                <div>
                  <div class="form-group ml-4">
                    <label>Slide<span class="text-danger">*</span></label>
                    <ValidationProvider vid="file" name="File Slide" rules="required" v-slot="{ errors, classes }">
                      <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="Dán liên kết link web tại đây" v-model="form.uri_slide">
                      </el-input>
                      <error-message field="file" :errors="errors"></error-message>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div slot="footer" class="example-btn text-right">
              <el-button style="color: #fff" size="small" @click="dialogUpload = false" type="info">Hủy</el-button>
              <el-button size="small" class="btn btn-success" native-type="submit" :loading="loading">Thêm
                mới</el-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </el-dialog>

    <!-- Dialog update -->
    <el-dialog title="Cập nhật slide" :visible.sync="dialogEditUpload">
      <div class="container">
        <ValidationObserver v-slot="{ handleSubmit }" ref="update-form">
          <form autocomplete="off" @submit.prevent="handleSubmit(editSlide)">
            <div class="row">
              <div class="col-md-6 col-xs-12 col-sm-12">
                <div class="form-group">
                  <label for="name">Tên slide<span class="text-danger">*</span></label>
                  <ValidationProvider vid="name" name="Tên" rules="required|max:50" v-slot="{ errors, classes }">
                    <el-input id="name" type="text" ref="name" name="name" v-model="formEdit.name_slide"
                      placeholder="Nhập tên slide" :class="classes" />
                    <error-message field="name" :errors="errors"></error-message>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-12">
                <div class="form-group">
                  <label>Trung tâm áp dụng<span class="text-danger">*</span></label>
                  <ValidationProvider vid="center" name="Trung tâm" rules="required" v-slot="{ errors, classes }">
                    <el-select v-model="formEdit.center_id" clearable filterable placeholder="Chọn trung tâm áp dụng"
                      class="w-100">
                      <el-option v-for="item in centers" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                    <error-message field="center" :errors="errors"></error-message>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-12">
                <div class="form-group">
                  <label>Loại file<span class="text-danger">*</span></label>
                  <ValidationProvider vid="type" name="Chọn loại file" rules="required" v-slot="{ errors, classes }">
                    <el-select v-model="form.type_sile" @change="changeTypeFile(form.type_sile)" clearable filterable
                      placeholder="Chọn loại file" class="w-100">
                      <el-option v-for="item in nameTypeFile" :key="item.id" :label="item.name" :value="item.name">
                      </el-option>
                    </el-select>
                    <error-message field="type" :errors="errors"></error-message>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-12 d-flex align-items-center mb-0">
                <span>Kích hoạt: </span>
                <el-switch v-model="formEdit.slide_status" />
              </div>
              <!-- <div v-if="form.type_sile === 'PowerPoint'" class="col-md-6 col-xs-12 col-sm-12">
                <div class="">
                  <div class="form-group">
                    <label>File tải lên<span class="text-danger">*</span></label>
                    <ValidationProvider vid="file" name="File tải lên" rules="required" v-slot="{ errors, classes }">
                      <el-upload class="upload-demo" action="/" accept=".pptx" :on-preview="handlePreview"
                        :on-remove="handleRemoveUpdates" :auto-upload="false" multiple :limit="1"
                        :on-change="handleChangeUpdates" :on-exceed="handleExceed" :file-list="fileUpdates">
                        <el-button style="color: black;" type="warning" size="small" v-if="fileUpdates.length === 0"><i
                            style="color: black;" class="el-icon-upload2 mr-1"></i>Chọn file</el-button>
                      </el-upload>
                      <input type="text" hidden name="file" ref="file" v-model="formEdit.file">
                      <error-message field="file" :errors="errors"></error-message>
                      <div v-if="percentage > 0 && percentage_complete">
                        <el-progress :percentage="percentage"></el-progress>
                        Upload Progress: {{ percentage }}%
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div> -->
              <div class="col-md-6 col-xs-12 col-sm-12">
                <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="Dán liên kết link web tại đây" :file-list="fileUpdates.file_googleSlide"
                  v-model="formEdit.uri_slide">
                </el-input>
              </div>

            </div>
            <div slot="footer" class="example-btn text-right">
              <el-button style="color:#fff;" size="small" @click="dialogEditUpload = false" type="info">Hủy</el-button>
              <el-button size="small" class="btn btn-success" native-type="submit" :loading="loading">Cập
                nhật</el-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DatePicker from 'vue2-datepicker';
import { GET_CENTERS } from "@/core/services/store/center/centers.module";
import 'vue2-datepicker/index.css';
import { UPLOAD_SLIDE_SCHEDULE, UPLOAD_FILE_GOOGLE_SLIDE, MULTIPART_UPLOAD_SLIDE_SCHEDULE, MULTIPART_EDIT_SLIDE_SCHEDULE } from "@/core/services/store/course/classes.module";
import ErrorMessage from "../common/ErrorMessage";
import Swal from "sweetalert2";
import { EDIT_SLIDE, REMOVE_SLIDE_BY_ID, SHOW_ALL_LIST_SLIDE } from "../../../core/services/store/course/classes.module";
// import { CREATE_MULTIPART_UPLOAD } from "@/core/services/store/course/classes.module";
import moment from "moment";
import AWS from "aws-sdk";

const _ = require('lodash');

export default {
  components: {
    ErrorMessage,
    DatePicker,
  },

  name: "ScheduleSlide",
  data() {
    return {
      statusOfSlide: [{ label: 'Ẩn', value: 'false' }, { label: 'Hiển thị', value: 'true' }],
      dialogUpload: false,
      dialogEditUpload: false,
      sources: [],
      centers: [],
      files: [],
      fileUpdates: [],
      idEditSlide: 0,
      link_slide: '',
      file_googleSlide: '',
      // Paginate
      page: 1,
      is_disable_search: false,
      isBusy: false,
      current_schedule_id: this.$route.query.index,
      query: {
        index: this.$route.query.index,
        name_slide: '',
        slide_status: '',
        center_id: ''
      },
      list_slide: [],
      list_slide_pagination: {
        total: '',
        per_page: '',
        current_page: 1,
        total_pages: 1
      },
      form: {
        name_slide: '',
        slide_status: true,
        center_id: '',
        uri_slide: '',
        type_file: '',
        type_slide: '',
        slide_status: false,
      },
      formEdit: {
        id: '',
        center_id: '',
        name_slide: '',
        slide_status: '',
        uri_slide: '',
        model_id: '',
      },
      formEditGS: {
        name_slide: '',
        slide_status: true,
        center_id: '',
        file: ''
      },
      disable: false,
      loading: false,
      is_GS: false,
      percentage: 0,
      percentage_complete: false,
      url_google_slide: '',
      nameTypeFile:
      [
        {id: 1, name:'PowerPoint'},
        {id: 2, name:'Google Slides'},
        {id: 3, name:'Google Slides trình chiếu'}
      ]
    }
  },

  created() {
    this.current_schedule_id = this.$route.query.index;
    this.getList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý lộ trình", route: 'schedule-course-list' },
      { title: "Chi tiết lộ trình", route: 'schedule-course-detail' },
      { title: "Danh sách slide" }
    ]);
    this.getCenters();
  },
  computed: {},
  watch: {},
  methods: {
    changeTypeFile(value) {
      this.file_googleSlide = ''
      this.files = [];
      this.fileUpdates = [];
    },
    getCenters() {
      this.$store.dispatch(GET_CENTERS, {
        limit: 10000,
        status: 1
      }).then((res) => {
        if (!res.error) {
          this.centers = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },

    handleDataGoogleSlide() {
      var text = this.form.uri_slide;
      var urlRegex = /(https?:\/\/[^\s]+)/g;

      var result = '';
      text.replace(urlRegex, function(text) {
        result = text;
      });
      this.url_google_slide = result;
      if (!result) {
        return false;
      }
      return true;
    },

    closeModal() {
      this.loading = false;
      this.dialogUpload = false;
    },

    uploadGoogleSlide() {
      const payload = {
        url_google_slide: this.url_google_slide,
        index: this.query.index,
        name_slide: this.form.name_slide,
        center_id: this.form.center_id,
        type_slide: this.form.type_slide, 
        course_schedule_id: this.$route.params.id,
        slide_status: this.form.slide_status,
      }
      this.$store.dispatch(UPLOAD_FILE_GOOGLE_SLIDE, payload).then((res) => {
        this.dialogUpload = false
        this.$notify({
          title: 'Thành công',
          message: res.message,
          type: 'success'
        })
        this.callApiGetList();
      }).catch((err) => {
        this.noticeMessage('error', 'Thất bại', err.data?.message);
      }).finally(() => {
        this.loading = false
      })
    },

    clickCreateNewSlide() {
      this.dialogUpload = true;
      this.current_schedule_id = this.$route.query.index;
      this.form = {
        name_slide: '',
        slide_status: true,
        center_id: '',
        course_schedule_id: this.$route.params.id
      }
      this.getCenters();
    },

    clickEditSlide(slide) {
      switch (slide.type_slide) {
        case 1:
           this.form.type_sile = 'PowerPoint'
          break;

        case 2:
          this.form.type_sile = 'Google Slides';
        break;

        case 3:
        this.form.type_sile = 'Google Slides trình chiếu'
        break;
      
        default:
          break;
      }
     
      this.dialogEditUpload = true;
      this.getCenters();
      this.formEdit.id = slide.id;
      this.formEdit.center_id = slide.center.id;
      this.formEdit.name_slide = slide.name_slide;
      this.formEdit.slide_status = slide.slide_status > 0;
      this.formEdit.uri_slide = slide.uri_slide; 
      this.formEdit.model_id = slide.model_id
    },
    updateSuccess() {
      this.dialogUpdate = false;
      this.getList();
    },
    getList() {
      this.pullParamsUrl();
      this.callApiGetList()
    },
    pullParamsUrl() {
      this.page = this.$route.query.page ? Number(this.$route.query.page) : 1
      this.query.name_slide = (this.$route.query.name_slide) ? String(this.$route.query.name_slide) : '';
      this.query.center_id = Number(this.$route.query.center_id) ? Number(this.$route.query.center_id) : '';
      this.query.slide_status = Boolean(this.$route.query.slide_status) ? (this.$route.query.slide_status) : '';
    },
    handleRemove(file, fileList) {
      this.files = [];
      this.form.file = '';
    },
    handleRemoveUpdates(file, fileList) {
      this.fileUpdates = [];
      this.formEdit.file = '';
    },
    handleChangeUpdates(file, fileList) {
      this.fileUpdates = fileList;
      this.formEdit.file = fileList.length ? fileList[0] : '';
    },
    handlePreview(file) {
    },
    handleExceed(files, fileList) {

    },
    handleChange(file, fileList) {
      this.files = fileList;
      this.form.file = fileList.length ? fileList[0] : '';
    },
    pushInfoToUrl() {
      let query = {
        ...this.query,
        page: this.page,
      }
      this.pushParamsUrl(query);
    },
    clickCallback(pageNum) {
      this.page = pageNum
      this.pushParamsUrl(this.query)
      this.callApiGetList()
    },
    callApiGetList() {
      this.isBusy = true;
      let payload = {
        id: this.current_schedule_id,
        params: {
          ...this.query,
          page: this.page
        }
      }
      this.$store.dispatch(SHOW_ALL_LIST_SLIDE, payload).then((res) => {
        if (!res.error) {
            this.list_slide = res.data;
            console.log('this.list_slide', this.list_slide);

            this.list_slide_pagination.total = Number(res.pagination.total),
            this.list_slide_pagination.per_page = Number(res.pagination.per_page),
            this.list_slide_pagination.current_page = Number(res.pagination.current_page),
            this.list_slide_pagination.total_pages = Number(res.pagination.last_page)
        }
      }).catch((e) => {
        console.log(e, 'error')
      }).finally(() => this.isBusy = false);

    },
    async importData() {
      this.loading = true
      if (this.form.type_sile === 'Google Slides' || true) {
        let slide = this.handleDataGoogleSlide();
        if (!slide) {
          this.$notify({
            title: 'Thất bại',
            message: "Slide không hợp lệ, vui lòng hãy tải lại !",
            type: 'error'
          });
          this.loading = false
          return;
        }
        this.uploadGoogleSlide();
      } else {
        let formData = new FormData();
        for (const formKey in this.form) {
          await formData.append(formKey, this.form[formKey]);
        }
        formData.append('file', this.files[0].raw);
        let payload = {
          id: this.current_schedule_id,
          params: formData,
        }
        let file = this.files[0].size / 1024 / 1024;

        const maxSize = 300;
        const standardSize = 100;

        if (file > maxSize) {
          this.dialogUpload = false;
          Swal.fire({
            title: "File tải lên không quá 300Mb!",
            icon: "warning",
            confirmButtonColor: "#7453a6",
            cancelButtonText: "Đóng",
          })
          this.loading = false;
          return;
        }

        if (file < standardSize) {
          this.$store.dispatch(UPLOAD_SLIDE_SCHEDULE, payload).then((res) => {
            this.noticeMessage('success', 'Thành công', 'Thêm mới thành công');
            this.$refs.form.reset();
            this.files = [];
            this.dialogUpload = false;
            this.callApiGetList();
          }).catch((err) => {
            this.noticeMessage('error', 'Thất bại', err.data?.message);
          }).finally(() => {
            this.loading = false
          })
          return;
        }
        this.uploadBigData(false);
      }
    },

    async uploadBigData(is_edit) {
      AWS.config.update({
        accessKeyId: "DO00NWAFNG3FUBNJ38M8",
        secretAccessKey: "4XdIkd6oSj0XPOsLnrCVplPhwJlK6hYnB8cQ4Ylyv4o",
        endpoint: new AWS.Endpoint("https://sgp1.digitaloceanspaces.com"),
        s3ForcePathStyle: true,
      });
      const s3 = new AWS.S3();
      // Chia nhỏ file thành các phần (parts)
      let file = is_edit ? this.fileUpdates[0] : this.files[0];
      let number_part_big_file = file;
      this.number_part_big_file = number_part_big_file.size / 30;
      this.number_part_big_file = Math.floor(this.number_part_big_file / 1000000);
      const name = moment().format("YYYY_MM_DD-HH_mm_ss") + "-" + file.name;
      const Bucket = process.env.NODE_ENV == 'production' ? "edutalk-cdn/schedule" : "beta-edutalk-cdn/schedule";
      const params = {
        Bucket: Bucket,
        Key: name,
        ACL: "public-read",
      };

      const initiateResponse = await s3.createMultipartUpload(params).promise();
      const uploadId = initiateResponse.UploadId;
      const partSize = 30 * 1024 * 1024; // chia nhỏ thành nhiều fiel 30 MB
      const numParts = Math.ceil(file.size / partSize);
      let uploadPromises = [];
      for (let i = 0; i < numParts; i++) {
        const start = i * partSize;
        const end = Math.min(start + partSize, file.size);
        const partParams = {
          Bucket: Bucket,
          Key: name,
          PartNumber: i + 1,
          UploadId: uploadId,
          Body: file.raw.slice(start, end),
        };
        uploadPromises.push(
          s3
            .uploadPart(partParams)
            .on("httpUploadProgress", (progress) => {
              this.percentage_complete = true;
              const uploadedBytes = progress.loaded;
              const totalBytes = file.size;
              const currentProgress = (Math.floor((uploadedBytes / totalBytes) * 100 * (this.number_part_big_file)));
              this.percentage = currentProgress;
            })
            .promise()
            .then((res) => {
              this.percentage_complete = false;
            })
            .catch((error) => { })
        );
      }

      try {
        await Promise.all(uploadPromises);
        let parts = await s3
          .listParts({
            Bucket: Bucket,
            Key: name,
            UploadId: uploadId,
          })
          .promise();

        const completeParams = {
          Bucket: Bucket,
          Key: name,
          MultipartUpload: {
            Parts: parts.Parts.map((e) => ({
              ETag: e.ETag,
              PartNumber: e.PartNumber,
            })),
          },
          UploadId: uploadId,
        };
        const completeResponse = await s3.completeMultipartUpload(completeParams).promise();
        if (is_edit) {
          var payload = {
            id_slide: this.formEdit.id_slide,
            index: this.$route.query.index,
            name_slide: this.formEdit.name_slide,
            size: this.fileUpdates[0].size,
            fileName: completeResponse.Key,
            name: this.fileUpdates[0].name,
            center_id: this.formEdit.center_id,
          }
          await this.$store.dispatch(MULTIPART_EDIT_SLIDE_SCHEDULE, payload);
          this.dialogEditUpload = false;
        } else {
          var payload = {
            index: this.$route.query.index,
            name_slide: this.form.name_slide,
            size: this.files[0].size,
            fileName: completeResponse.Key,
            name: this.files[0].name,
            center_id: this.form.center_id,
          }
          await this.$store.dispatch(MULTIPART_UPLOAD_SLIDE_SCHEDULE, payload);
        }
        this.noticeMessage('success', 'Thành công', 'Thêm mới thành công');
        this.$refs.form.reset();
        this.files = [];
        this.dialogUpload = false;
        this.callApiGetList();
        // completeResponse
      } catch (err) {
        s3.abortMultipartUpload();
        const s3 = new AWS.S3({
          httpOptions: {
            timeout: 30000
          }
        });
        this.noticeMessage('error', 'Thất bại', err.data?.message || "Upload failed!");
        console.error("Complete upload error:", err);
      } finally {
        this.loading = false;
      }
    },

    editSlide() {
      this.$store.dispatch(EDIT_SLIDE, {
        id: this.formEdit.id,
        center_id: this.formEdit.center_id,
        name_slide: this.formEdit.name_slide,
        slide_status: this.formEdit.slide_status,
        uri_slide: this.formEdit.uri_slide,
        model_id: this.formEdit.model_id
      }).then((res) => {
        this.noticeMessage('success', 'Thành công', 'Cập nhật slide thành công');
        this.dialogEditUpload = false;
        this.callApiGetList();
      }).catch((err) => {
        this.noticeMessage('error', 'Thất bại', err.data?.message);
      }).finally(() => {
        this.loading = false;
      })
    },

    checkBigfile(file) {
      const standardSize = 100;
      if (file < standardSize) {
        return false;
      }
      return true;
    },

    removeSlide(id)
    {
      this.loading = true;
      Swal.fire({
        title: "Bạn chắc chắn muốn gỡ slide?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(REMOVE_SLIDE_BY_ID, id).then((res) => {
            this.$notify({
              title: 'Thành công',
              message: res.message,
              type: 'success'
            })
            this.loading = false;
            this.callApiGetList();
          }).catch((error) => {
            this.$notify({
              title: 'Thất bại',
              message: error.data.message,
              type: 'error'
            });
          })
        }
      });
    },
    searchData() {
      this.page = 1;
      this.pushInfoToUrl();
      this.callApiGetList();
    },
  }

}
</script>
<style>
.el-upload-list__item {
transition: none !important;
}
.mx-datepicker {
  width: 100% !important;
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 500px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #EBEDF3;
}

.table td {
  text-align: center;
  vertical-align: unset;
}

.col-form-label {
  display: none;
}

.b-table-sticky-header > .table.b-table > thead > tr > th {
  background: #F3F6F9;
}
.action-btn {
  width: 150px;
}

</style>
