<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách chuyển nhượng khóa học</h3>
      </div>
      <div class="card-title">
        <router-link
            class="font-weight-bold font-size-3  btn btn-success"
            :to="{ name: 'course-transfers-create' }"
        >Thêm mới
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="input-name">Tên người chuyển, hoặc nhận</label>
            <el-input id="input-name" placeholder="Nhập mã học viên, tên, SĐT, email..." v-model="query.keyword"></el-input>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="input-date">Thời gian chuyển nhượng</label>
            <el-date-picker
                v-model="query.selectDate"
                id="input-date"
                type="date"
                placeholder="Chọn ngày chuyển nhượng"
                class="w-100"
                align="center"
                :picker-options="pickerOptions"
                format="yyyy/MM/dd"
                value-format="yyyy/MM/dd"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="col-md-3 d-flex flex-column justify-content-end">
          <div class="form-group">
            <button class="btn btn-primary font-weight-bold"
                    :class="isBusy ? 'spinner spinner-white spinner-left' : ''"
                    :disabled="isBusy"
                    @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
            </button>
          </div>
        </div>
      </div>
      <div class="example mb-10 mt-5">
        <div class="example-preview table-responsive">
          <table class="table table-hover table-bordered table-vertical-center text-center">
            <thead>
              <tr>
                <th scope="col">STT</th>
                <th scope="col">Họ tên người chuyển</th>
                <th scope="col">SĐT người chuyển</th>
                <th scope="col">Họ tên người nhận</th>
                <th scope="col">SĐT người nhận</th>
                <th scope="col">Khóa học chuyển nhượng</th>
                <th scope="col">Thời gian chuyển nhượng</th>
              </tr>
            </thead>
            <tbody v-if="isBusy">
              <tr role="row" class="b-table-busy-slot" >
                <td colspan="7" role="cell" class="text-center">
                  <div class="text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="course_transfers.length > 0">
              <tr v-for="(item, index) in course_transfers" :key="index">
                <td>{{ ++index }}</td>
                <td>{{ item.from_user_name }}</td>
                <td>{{ item.from_user_phone }}</td>
                <td>{{ item.to_user_name }}</td>
                <td>{{ item.to_user_phone }}</td>
                <td class="max-w-250px">
                  <div class="text-break">
                  <span class="ml-1 badge badge-pill badge-success mt-1"
                        v-if="item.courses.length > 0" v-for="(course , k) in item.courses"
                        :key="k">
                     {{ course.name }}
                  </span>
                  </div>
                </td>
                <td>{{ item.created_at | formatDateTimeVietnam }}</td>
            </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7" class="text-center font-italic">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center" v-if="course_transfers.length > 0">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {LIST_COURSE_TRANSFER} from "@/core/services/store/course/courseTransfer.module";

const _ = require('lodash');

export default {
  components: {},
  name: "CourseTransferIndex",
  data() {
    return {
      is_disable_search: false,
      course_transfers: [],
      coefficients: [],
      // Paginate
      page: 1,
      last_page: 1,
      query: {
        keyword: '',
        selectDate: '',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: 'Hôm nay',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: 'Hôm qua',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: 'Một tuần trước',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      isBusy: false,
    }
  },
  created() {
    this.getList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Chuyển nhượng khóa học", route: 'course-transfers-list'},
      {title: "Danh sách chuyển nhượng"}
    ]);
  },
  methods: {
    searchData() {
      this.page = 1;
      this.pushParamsToUrl()
      this.callApiGetList()
    },
    pushParamsToUrl() {
      let query = {
      ...this.query,
        page: this.page
      }
      this.pushParamsUrl(query);
    },
    getList() {
      this.pullParamsUrl();
      this.callApiGetList();
    },
    pullParamsUrl() {
      this.pullParamsFromUrl();
      this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    },
    clickCallback(page) {
      this.page = page;
      this.pushParamsToUrl();
      this.callApiGetList();
    },
    callApiGetList() {
      this.isBusy = true;
      this.$store.dispatch(LIST_COURSE_TRANSFER, {
        page: this.page,
        ...this.query
      }).then((res) => {
        this.course_transfers = res.data;
        this.last_page = res.pagination.last_page;
      }).finally(() => this.isBusy = false);
    },
    disabledDate(date) {
      let endOfCurrentMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
      let endMonth = this.endOfMonth > endOfCurrentMonth ? endOfCurrentMonth : this.endOfMonth;
      if (this.minDate) {
        return this.startOfMonth > date || date > endMonth;
      }
      return date > endOfCurrentMonth;
    },
    onPick({maxDate, minDate}) {
      this.minDate = minDate;
      this.maxDate = maxDate;
      if (this.minDate) {
        let date = new Date(this.minDate);
        let year = date.getFullYear(), month = date.getMonth();
        this.startOfMonth = new Date(year, month, 1);
        this.endOfMonth = new Date(year, month + 1, 0);
      }
    },
    focus() {
      this.minDate = null;
      this.mountedDate();
    },
    mountedDate() {
      if (!this.form.selectDate) {
        let date = new Date();
        let year = date.getFullYear(), month = date.getMonth();
        let startOfMonth = new Date(year, month, 1);
        let endOfMonth = new Date(year, month + 1, 0);
        this.form.selectDate = [startOfMonth, endOfMonth];
      }
    },
  }
}
</script>
<style>
.checkbox-slide, .checkbox-switch {
  border-radius: 20px !important;
}
</style>

