<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Công nợ khách hàng</h3>
      </div>
      <div class="card-title" v-if="currentUser.user.permission.includes(permission.CONG_NO_KHACH_HANG)">
        <div>
          <div class="my-3">
            <!-- Our triggering (target) element -->
            <b-button id="popover-reactive-1" variant="primary" ref="button" class="mr-2">
              <i class="la la-file-excel"></i> Xuất google sheet
            </b-button>
          </div>
          <!-- Output from the popover interaction -->
          <b-popover
              target="popover-reactive-1"
              triggers="click"
              :show.sync="popoverShow"
              placement="auto"
              container="my-container"
              ref="popover"
              @show="onShow"
              @hidden="onHidden"
          >
            <template #title>
              <b-button @click="onClose" class="close" aria-label="Close">
                <span class="d-inline-block" aria-hidden="true">&times;</span>
              </b-button>
              Dánh sách thư mục
            </template>

            <div>
              <b-form-group
                  label-for="popover-input-1"
                  label-cols="3"
                  :state="validateState"
                  class="mb-1"
                  invalid-feedback="Không được để trống"
                  size="lg"
              >
                <b-form-select
                    id="popover-input-1"
                    v-model="folderData"
                    :state="validateState"
                    :options="options"
                    size="sm"
                    class="mb-3"
                ></b-form-select>
              </b-form-group>
              <b-button @click="onClose" size="sm" variant="danger">Cancel</b-button>
              <b-button @click="onOk" size="sm" variant="primary" class="ml-2">Ok</b-button>
            </div>
          </b-popover>
        </div>
        <div>
          <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcel">
            <i class="la la-file-excel"></i> Xuất excel
          </button>
          <button v-else disabled
                  class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
            Xuất excel
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label>Chọn khoảng thời gian
                <span class="text-danger"></span></label>
              <date-range-picker
                  v-model="dateRange"
                  :auto-apply="false"
                  :locale-data="localData"
                  @update="selectDate"
              ></date-range-picker>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Thông tin học viên
                <span class="text-danger"></span></label>
              <input type="text" v-model="query.keyword" class="form-control form-information"
                     v-on:input="searchByCustomer"
                     placeholder="Tên, SĐT, Mã học viên">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Mã hợp đồng
                <span class="text-danger"></span></label>
              <input type="text" v-model="query.contract_id" class="form-control form-information"
                     v-on:input="searchByContract"
                     placeholder="Mã hợp đồng">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Chọn chi nhánh
                <span class="text-danger"></span></label>
              <multiselect v-model="cache_branch" :options="branches"
                           placeholder="Chọn chi nhánh"
                           label="name"
                           track-by="name"
                           @input="selectBranch($event)"
              ></multiselect>
            </div>
          </div>
<!--          05/04/24: Huy bảo bỏ -->
<!--          <div class="col-md-2">-->
<!--            <div class="form-group">-->
<!--              <label>Loại học viên-->
<!--                <span class="text-danger"></span></label>-->
<!--              <multiselect v-model="cache_customer_type" :options="customerTypes"-->
<!--                           placeholder="Chọn loại học viên"-->
<!--                           label="name"-->
<!--                           track-by="name"-->
<!--                           @input="selectType($event)"-->
<!--              ></multiselect>-->
<!--            </div>-->
<!--          </div>-->
          <div class="col-md-2 mt-8">
            <div class="d-flex justify-content-between">
              <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                      @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
              </button>
              <button v-else type="button" disabled
                      class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            Tổng công nợ :
            <span class="badge badge-danger">{{formatPrice(total_debt , 'đ')}}</span>
          </div>
        </div>
        <div class="example-preview table-responsive overflow-auto">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th scope="col" class="min-w-100px">STT</th>
              <th scope="col" class="min-w-100px">Tên học viên</th>
              <th scope="col" class="min-w-100px">Mã học viên</th>
              <th scope="col" class="min-w-120px">Số điện thoại</th>
              <th scope="col" class="min-w-100px">Mã hợp đồng</th>
              <th scope="col" class="min-w-100px">Giá trị hợp đồng</th>
              <th scope="col" class="min-w-100px">Chi nhánh</th>
              <th scope="col" class="min-w-120px">Đã thanh toán</th>
              <th scope="col" class="min-w-100px">Công nợ</th>
              <th scope="col" class="min-w-100px">Ngày tạo hợp đồng</th>
              <th scope="col" class="min-w-100px">Loại học viên</th>
              <th scope="col" class="min-w-100px">Xem chi tiết hợp đồng</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in contracts.data" :key="index">
              <td>{{index + 1}}</td>
              <td>{{item.user != null ? item.user.name : ''}}</td>
              <td>{{item.user != null ? item.user.id + 100000 : ''}}</td>
              <td>{{item.user != null ? item.user.phone : ''}}</td>
              <td>{{item.id}}</td>
              <td>{{formatPrice(item.total_after_voucher, 'đ')}}</td>
              <td>{{item.branch? item.branch.name : ''}}</td>
              <td>{{formatPrice(item.total_paid, 'đ')}}</td>
              <td>{{formatPrice(item.total_debt, 'đ')}}</td>
              <td>{{item.created_at | formatDateTime}}</td>
              <td>{{ item.center_user_id ? 'Trung tâm' : 'Edutalk' }}</td>
              <td>
                <a href="javascript:" title="Xem chi tiết"
                   class="btn btn-sm btn-icon mr-2 btn-outline-success"
                   @click="viewContract(item.id)"><i class="far fa-eye"></i>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <contract-detail :showModal="showDetailContract" :contract="contract" :center_classes="center_classes"
                        :courses="courses" @clicked="onclickViewContractDetail"></contract-detail>
      </div>
    </div>

    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>

    <div class="vld-parent">
      <loading :active.sync="isLoading"
               :can-cancel="true"
               :on-cancel="onCancel"
               :is-full-page="fullPage"></loading>
    </div>
  </div>
</template>
<script>
    import {mapGetters} from "vuex";
import {GET_FOLDERS_DRIVERS} from "../../../../core/services/store/public/google.module";
const _ = require('lodash');

import {
  GET_CONTRACT_BY_ID,
  GET_CONTRACTS,
  EXPORT_EXCEL_CONTRACTS,
  EXPORT_CONTRACT_GOOGLE_SHEET, GET_TOTAL_DEBT
} from "../../../../core/services/store/contract/contracts.module";
import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";
import {GET_COURSES} from "../../../../core/services/store/course/course.module";
import {GET_CLASSES} from "../../../../core/services/store/course/classes.module";
import {GET_BRANCHES} from "../../../../core/services/store/center/branch.module";
import DateRangePicker from 'vue2-daterange-picker';
import Multiselect from 'vue-multiselect';
import ContractForm from "@/view/pages/business/ContractForm";
import ContractDetail from "@/view/pages/business/ContractDetail";
import Loading from 'vue-loading-overlay';
/* CSS */
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
    import {exportExcel} from "../../../../core/filters";


export default {
  components: {
    Multiselect,
    ContractForm,
    ContractDetail,
    Loading,
    DateRangePicker
  },
  name: "CongNoKhachHang",
  data() {
    return {
      /* date range picker */
      localData : {
        direction: 'ltr',
        format: 'dd-mm-yyyy',
        separator: ' ~ ',
        applyLabel: 'Đồng ý',
        cancelLabel: 'Hủy bỏ',
        weekLabel: 'Tuần',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
        monthNames: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
        firstDay: 0,
      },
      /*Loader */
      isLoading : false,
      fullPage : true,
      /* popover */
      path : '',
      showPop: false,
      folderData: '',
      validateState: null,
      options: [],
      popoverShow: false,
      /*Show modal edit*/
      center_classes: [],
      contract: {},
      cache_branch : '',
      cache_customer_type : '',
      showModalContract: false,
      showDetailContract: false,
      courses: [],
      branches: [],
      customerTypes: [{name : 'Edutalk', value : 'Edutalk'}, {name: 'Trung tâm', value: 'Trung tâm'}],
      center_id: '',
      /*End*/
      is_disable_search: false,
      is_export_excel: false,
      is_export_sheet: false,
      contracts: [],
      total_debt : 0,
      // Paginate
      page: 1,
      last_page: 1,
      query:
          {
            keyword: '',
            branch_id: '',
            contract_id: '',
            startDate : '',
            endDate : '',
            customer_type : '',
            is_debt: 1,
          }
      ,
      payload : {
        limit : 100000,
        status : 1,
        is_with : 0
      },
      /* v-model */
      dateRange : {},

    }
  },
  watch: {
    folderData(val) {
      if (val) {
        this.validateState = true;
        this.path = val;
      }
    },
  },
  async mounted() {
    this.setDateRangeFromUrl();
    this.getFolders();
    this.getBranches();
    this.getList()
    this.getCourses();
    this.getClasses();
    await this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý tài chính"},
      {title: "Công nợ khách hành"}
    ]);
  },
    computed: {
        ...mapGetters(["currentUser"]),
    },
  methods: {
    onCancel :function () {
      console.log('User cancelled the loader.')
    },
    onClose() {
      this.popoverShow = false
    },
    onOk() {
      if (!this.folderData) {
        this.validateState = false
      }
      if (this.folderData) {
        this.onClose()
        // Return our popover form results
        this.exportGoogleSheet();
      }
    },
    onShow() {
      this.folderData = ''
      this.validateState = null
    },
    onHidden() {
      this.focusRef(this.$refs.button)
    },
    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          ;(ref.$el || ref).focus()
        })
      })
    },
    getFolders: function () {
      this.$store.dispatch(GET_FOLDERS_DRIVERS).then((res) => {
        this.options = _.reduce(res.data, function (result,item,key) {
          result.push({
            text : item.name,
            value : item.path
          });
          return result;
        },[])
        this.options.unshift({ text: '- Chọn thư mục -', value: '' });
      });

    },
    /*Show modal edit contract*/
    getClasses:  function () {
       this.$store.dispatch(GET_CLASSES, {limit: 10000, status: 1}).then((res) => {
        this.center_classes = _.map(res.data.data, (item) => {
          item = {id: item.id, name: item.name, course_id: item.course_id};
          return item;
        })
      });
    },
    getBranches:  function () {
      this.$store.dispatch(GET_BRANCHES, this.payload).then((res) => {
        this.branches = res.data.data;
        this.branchesByCenter = res.data.data;
        let branch_id = this.$route.query.branch_id ? this.$route.query.branch_id : '';
        this.cache_branch = _.find(this.branches, function (branch) {
          return branch.id === parseInt(branch_id);
        });
      });
    },
    getCourses:  function () {
       this.$store.dispatch(GET_COURSES, {
        limit: 10000,
        status: 1,
        center_id: this.center_id
      }).then((res) => {
        this.courses = _.map(res.data.data, (item) => {
          item = {id: item.id, name: item.name, fee: item.fee, sale: item.sale,};
          return item;
        })
      });
    },
    selectBranch: function ($event) {
      this.query.branch_id = $event != null ? $event.id : '';
      this.pushParamsUrl();
    },
    selectType : function ($event){
      this.query.customer_type = $event != null ? $event.value : '';
      this.pushParamsUrl();
    },
    searchByCustomer : function (){
      this.pushParamsUrl();
    },
    searchByContract : function (){
      this.pushParamsUrl();
    },
    setDateParams : function (d){
      return d.getFullYear()  + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +("0" + d.getDate()).slice(-2);
    },
    selectDate: function () {
      this.query.startDate = this.setDateParams(this.dateRange.startDate);
      this.query.endDate = this.setDateParams(this.dateRange.endDate);
      this.pushParamsUrl();
    },
    setDateRangeFromUrl() {
      let date = new Date();
      let endDate = this.$route.query.end_date  ? new Date(this.$route.query.end_date) : new Date();
      let startDate = this.$route.query.start_date ?  new Date(this.$route.query.start_date) : new Date(date.getFullYear(), date.getMonth(), 1);
      this.query.startDate = this.$route.query.start_date ? this.$route.query.start_date : this.setDateParams(startDate);
      this.query.endDate = this.$route.query.end_date ? this.$route.query.end_date : this.setDateParams(endDate);
      endDate.setDate(endDate.getDate());
      this.dateRange = {startDate , endDate};
    },
    showDetail: async function (contract) {
      let contract_id = contract.id;
      await this.getContractDetail(contract_id);
      this.center_id = this.contract.center_id;
      await this.getCourses();
      this.showModalContract = true;
    },
    viewContract: async function (contract_id) {
      await this.getContractDetail(contract_id);
      this.showDetailContract = true;
    },
    getContractDetail: async function (contract_id) {
      await this.$store.dispatch(GET_CONTRACT_BY_ID, contract_id).then((res) => {
        this.contract = res.data;
      });
    },
    onclickContractDetail() {
      this.getList()
      this.showModalContract = false;
    },
    onclickViewContractDetail() {
      this.showDetailContract = false;
    },
    /*End*/
    getList() {
      this.pullParamsUrl()
      this.callApiGetList()
    },
    pullParamsUrl() {
      if (typeof this.$route.query.page == "string") {
        this.page = parseInt(this.$route.query.page)
      }
      this.query.keyword = this.$route.query.keyword;
      this.query.contract_id = this.$route.query.contract_id;
      this.query.branch_id = this.$route.query.branch_id;
      let customerType = this.query.customer_type = this.$route.query.customer_type;
      this.cache_customer_type = _.find(this.customerTypes,function (item){
        return item.value === customerType;
      });
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'cong-no-khach-hang',
        query: {
          page: this.page,
          keyword: this.query.keyword,
          contract_id: this.query.contract_id,
          branch_id: this.query.branch_id,
          start_date: this.query.startDate,
          end_date: this.query.endDate,
          customer_type: this.query.customer_type,
          is_debt: 1,
        }
      })
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({
        name: 'cong-no-khach-hang',
        query: {
          page: this.page,
          keyword: this.query.keyword,
          is_debt : 1,
          contract_id : this.query.contract_id,
          branch_id : this.query.branch_id,
          start_date : this.query.startDate,
          end_date: this.query.endDate,
          customer_type: this.query.customer_type,
        }
      })
      this.callApiGetList()
    },
    callApiGetList() {
      this.$store.dispatch(GET_CONTRACTS, {
        page: this.page,
        keyword: this.query.keyword,
        is_debt : 1,
        contract_id : this.query.contract_id,
        branch_id : this.query.branch_id,
        start_date : this.query.startDate,
        end_date : this.query.endDate,
        customer_type: this.query.customer_type,
      }).then((res) => {
        this.contracts = res.data
        this.is_disable_search = false
        this.last_page = res.data.last_page
      });
      this.$store.dispatch(GET_TOTAL_DEBT, {
        keyword: this.query.keyword,
        is_debt : 1,
        contract_id : this.query.contract_id,
        branch_id : this.query.branch_id,
        start_date : this.query.startDate,
        end_date : this.query.endDate,
        customer_type: this.query.customer_type,
      }).then((res) =>{
        this.total_debt = res.data ? res.data : 0;
      })
    },

    exportExcel() {
      this.is_export_excel = true
      const HEADER_ROW_EXPORT_CONG_NO_KH = [
        {
          key: 'id',
          value: 'Mã HĐ',
        },
        {
          key: 'created_at',
          value: 'Ngày tạo',
        },
        {
          key: 'name',
          value: 'Họ tên khách hàng',
        },
        {
          key: 'email',
          value: 'Email khách hàng',
        },
        {
          key: 'phone',
          value: 'Số điện thoại khách hàng',
        },
        {
          key: 'birthday',
          value: 'Ngày sinh',
        },
        {
          key: 'center_id',
          value: 'ID Trung tâm',
        },
        {
          key: 'center_name',
          value: 'Tên Trung tâm',
        },
        {
          key: 'branch_id',
          value: 'ID Chi nhánh',
        },
        {
          key: 'branch_name',
          value: 'Tên Chi nhánh',
        },
        {
          key: 'id_courses',
          value: 'ID khóa học',
        },
        {
          key: 'name_courses',
          value: 'Khóa học',
        },
        {
          key: 'promotion_name',
          value: 'Mã combo',
        },
        {
          key: 'promotion_value',
          value: 'Giảm giá combo',
        },
        {
          key: 'voucher_name',
          value: 'Danh sách vouchers',
        },
        {
          key: 'voucher_value',
          value: 'Giảm giá vouchers',
        },
        {
          key: 'total_fee_courses',
          value: 'Tổng giá khóa (1)',
        },
        {
          key: 'total_voucher',
          value: 'Tổng khuyến mại (2)',
        },
        {
          key: 'total_after_voucher',
          value: 'Tổng giá trị hợp đồng (3) = (1) + (2)',
        },
        {
          key: 'total_paid',
          value: 'Đã thanh toán (4): Tổng nạp - tổng rút',
        },
        {
          key: 'total_debt',
          value: 'Còn nợ (5) = (3) - (4)',
        },
        {
          key: 'tuition_payment',
          value: 'Hình thức hoàn thành học phí',
        },
        {
          key: 'status',
          value: 'Trạng thái',
        },
        {
          key: 'type',
          value: 'Loại hợp đồng',
        },
        {
          key: 'gift',
          value: 'Quà tặng',
        },
        {
          key: 'gift_detail',
          value: 'Chi tiết quà tặng',
        },
      ]
      this.$store.dispatch(EXPORT_EXCEL_CONTRACTS, {
        keyword: this.query.keyword,
        is_debt : 1,
        contract_id : this.query.contract_id,
        branch_id : this.query.branch_id,
        start_date : this.query.startDate,
        end_date : this.query.endDate,
        customer_type: this.query.customer_type,
      }).then((res) => {
        this.is_export_excel = false
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        }
        exportExcel(HEADER_ROW_EXPORT_CONG_NO_KH, res.data, "Danh sách công nợ.xlsx", false)
      });
    },
    exportGoogleSheet() {
      this.isLoading = true;
      this.$store.dispatch(EXPORT_CONTRACT_GOOGLE_SHEET, {
        keyword: this.query.keyword,
        path : this.path,
        is_debt : 1,
        contract_id : this.query.contract_id,
        branch_id : this.query.branch_id,
        start_date : this.query.startDate,
        end_date : this.query.endDate,
        customer_type: this.query.customer_type,
      }).then((res) => {
        this.isLoading = false
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Export google sheet',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$bvToast.toast(res.message, {
            title: 'Export google sheet',
            variant: 'success',
            solid: true
          });
        }
      }).catch((e) => {
        if (e.data.error) {
          this.$bvToast.toast(e.data.message, {
            title: 'Export google sheet',
            variant: 'danger',
            solid: true
          });
        }
      });
    },
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl()
      this.callApiGetList()
    },
  }
}
</script>
<style>
#btn-export-excel {
  position: relative;
}
.col-form-label{
  display: none;
}
.vue-daterange-picker {
  width: 100%;
}

.form-control {
  height: 43px !important;
}
.multiselect__tags {
  height: 43px !important;
}

.reportrange-text[data-v-79d7c92a]  {
  text-align: center;
  padding: 12px 10px;
}
.calendars {
  flex-wrap: unset !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
