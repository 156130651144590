<template>
  <div>
    <b-modal
        ref="waring-update-admin"
        size="xl"
        title="Cảnh báo"
        centered
        @hidden="resetModal"
        hide-footer
    >
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent>
          <div class="row">
            <div class="form-group col-xl-12 col-lg-12 ">
              <p>Việc thay đổi lịch làm việc cố định sẽ dẫn tới sự thay đổi lịch đi làm kể từ tuần sau.
                Lịch đi làm mới thay đổi đang ảnh hưởng tới tác vụ của nhân sự. Cần thay đổi người làm của các tác vụ
                dưới đây:</p>
            </div>
          </div>
          <div class="example-preview table-responsive">
            <table class="table table-bordered text-center table-vertical-center  " style="  height: 100%">
              <thead>
              <tr>
                <th>Mã tác vụ</th>
                <td>Tác vụ</td>
                <td>Thời gian bắt đầu</td>
                <td>Thời gian kết thúc</td>
                <td>Thay đổi người làm</td>
                <td></td>
              </tr>
              </thead>
              <tbody style="  height: 100%">
              <tr v-for="(task,index) in dataTasks">
                <td>{{ task.idTask }}</td>
                <td>
                  <p :class="TYPE_TASK_ITEM_WARNING_DEFINE_CSS[task.typeTask]" class="mt-4">{{ task.typeTaskName }}
                  </p>
                </td>
                <td>{{ task.timeStart }}</td>
                <td>{{ task.timeEnd }}</td>
                <td>
                  <el-input v-if="task.typeTask == TEST_DAU_VAO " disabled placeholder="Nhân sự được chọn tự động"/>
                  <div v-else>
                    <task-assign :task="task" @selectTester="selectTester" :index="index"
                                 :clearDataTester="clearDataTester"></task-assign>
                  </div>
                </td>
                <td>
                  <button v-if="task.typeTask == TEST_DAU_VAO " type="button" class="btn btn-success"
                          @click="confirmTheChange(task.idTask)">Thay đổi
                  </button>
                  <button v-else type="button" class="btn btn-success" @click="assignTask(task.idTask,index)">Thay đổi
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-end mt-10">
              <button type="button" class="btn btn-secondary" @click="cancel()">Đóng</button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>


import {TasksWarning, TaskWarningModel} from "@/view/pages/test/model/WarningUpdateAdmin";
import {
  COI_THI,
  GIA_SU,
  TAC_VU_KHAC,
  TEST_DAU_VAO,
  TYPE_TASK_ITEM_DEFINE_CSS,
  TYPE_TASK_ITEM_WARNING_DEFINE_CSS
} from "@/core/option/testInputOption";
import {
  ASSIGN_TASK,
  AUTO_ASSIGN_TEST_INPUT,
  CHECK_TASK_BY_DAY_LEAVE,
  UPDATE_TESTER
} from "@/core/services/store/test/test.module";
import TaskAssign from "@/view/pages/test/tester/taskAssign";

export default {
  name: "WarningUpdateAdmin",
  components: {TaskAssign},
  data() {
    return {
      dataTasks: [],
      TYPE_TASK_ITEM_WARNING_DEFINE_CSS: TYPE_TASK_ITEM_WARNING_DEFINE_CSS,
      idTester: [],
      test_input_schedule_item_ids: [],
      TEST_DAU_VAO: TEST_DAU_VAO,
      clearDataTester: false
    }
  },
  props: {
    isWaringModal: {
      type: Boolean,
      default() {
        return true
      }
    },
    dataWaringModal: {
      type: Object,
      default() {
        return {}
      }
    },
    tester: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  watch: {
    isWaringModal(newValue, oldValue) {
      if (newValue) {
        this.$refs['waring-update-admin'].show();
        let keys = [TEST_DAU_VAO, GIA_SU, COI_THI, TAC_VU_KHAC]
        let res = keys.map((obj) => {
          if (!this.dataWaringModal.hasOwnProperty(obj) || this.dataWaringModal[obj] == null) {
            return null;
          }
          if (obj == TEST_DAU_VAO) {
            return this.dataWaringModal[obj]?.map((task) => {
              this.test_input_schedule_item_ids.push(task.id)
              return new TaskWarningModel(task.id, task.type_task, task.schedule, task.end_schedule, [], task.type_item_id
              )
            })
          }
          return this.dataWaringModal[obj]?.map((task) => new TaskWarningModel(task.id, task.type_task, task.schedule, task.end_schedule, task.tester_free_time, task.type_item_id))
        }).filter((task) => task != null).reduce((a, b) => a.concat(b))
        this.dataTasks = res
      }
    },

  },
  methods: {
    selectTester(idTester, index) {
      this.idTester[index] = idTester
    },
    cancel() {
      this.$refs['waring-update-admin'].hide()
    },
    resetModal() {
      this.$emit("setIsWaringModal")
    },
    assignTask(idTask, index) {
      this.$confirm('Bạn có muốn thay đổi không', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //Khi nhấn oke sẽ thực thi lệnh này
        if (!this.idTester[index]) {
          this.$alert('Vui lòng chọn nhân sự', 'Error', {
            confirmButtonText: 'OK',
          });
        }
        let payload = {
          id: idTask,
          tester: {
            tester_id: this.idTester[index]
          }
        }
        this.$store.dispatch(ASSIGN_TASK, payload).then((data) => {
          this.dataTasks = this.dataTasks.filter((task) => task.idTask != idTask)
          this.idTester[index] = null
          this.clearDataTester = !this.clearDataTester
          this.updateTaskTester()
          if (this.dataTasks.length == 0) {
            this.updateTester()
          } else {
            this.$message({
              type: 'success',
              message: 'Thay đổi thành công'
            });
          }
        })
        //

      }).catch(() => {
        //Hủy không làm gì cả
      });
    },
    confirmTheChange(idTask) {
      this.$confirm('Bạn có chắc chắn thay đổi admin phụ trách không?', 'Cảnh báo', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(() => {
        if (this.test_input_schedule_item_ids.length == 0) {
          this.$refs['waring-update-admin'].hide()
          return
        }
        let payload = {
          test_input_schedule_item_id: idTask
        }
        this.$store.dispatch(AUTO_ASSIGN_TEST_INPUT, payload).then((data) => {
          this.dataTasks = this.dataTasks.filter((task) => task.idTask != idTask)
          this.idTester = null
          if (this.dataTasks.length == 0) {
            this.updateTester()
          } else {
            this.$message({
              type: 'success',
              message: 'Thay đổi thành công'
            });
          }
        }).catch((error) => {
          this.$message.error(error.data.message);
        })
      })
    },
    updateTester() {
      this.$store.dispatch(UPDATE_TESTER, this.tester).then((res) => {
        this.$message.success(res.message);
        this.$refs['waring-update-admin'].hide()
        this.$emit('updateSuccess')
      })
    },
    updateTaskTester() {
      this.$store.dispatch(CHECK_TASK_BY_DAY_LEAVE, this.tester).then((res) => {
      }).catch((error) => {
        if (error?.data) {
          this.dataWaringModal = error?.data?.data
          let keys = [TEST_DAU_VAO, GIA_SU, COI_THI, TAC_VU_KHAC]
          let res = keys.map((obj) => {
            if (!this.dataWaringModal.hasOwnProperty(obj) || this.dataWaringModal[obj] == null) {
              return null;
            }
            if (obj == TEST_DAU_VAO) {
              return this.dataWaringModal[obj]?.map((task) => {
                this.test_input_schedule_item_ids.push(task.id)
                return new TaskWarningModel(task.id, task.type_task, task.schedule, task.end_schedule, [], task.type_item_id
                )
              })
            }
            return this.dataWaringModal[obj]?.map((task) => new TaskWarningModel(task.id, task.type_task, task.schedule, task.end_schedule, task.tester_free_time, task.type_item_id))
          }).filter((task) => task != null).reduce((a, b) => a.concat(b))
          this.dataTasks = res
        }
      })
    },
  },
  computed: {}
}
</script>

<style scoped>

</style>