import { render, staticRenderFns } from "./arrange-sentences.vue?vue&type=template&id=d3bc5750&scoped=true"
import script from "./arrange-sentences.vue?vue&type=script&lang=js"
export * from "./arrange-sentences.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3bc5750",
  null
  
)

export default component.exports