<template>
  <div>
    <div class="row">
      <div class="schedule col-md-10  p-2 d-flex align-items-center shadow-1 overflow-auto">
        <div v-if="comingLesson" style="margin-left: 5px">
          <i v-if="comingLesson" class="fas fa-graduation-cap" style="margin: 1px;"></i>
        </div>
        <div v-else>
          <span style="margin: 1px"></span>
        </div>
      </div>
    </div>
    <div class="row">
          <div class="schedule col-md-10  p-2 align-items-center  "
               :class="comingLesson ? 'custom-padding' : ''"
               :style="isDisable ? 'background-color: #1bdbd2; color: #fff ; margin: 15px; border-radius: 10px' : 'border-radius: 10px;border: solid black 1px;margin: 15px;'"
          >
            <div class="row">
              <div class="col-md-3 ml-2 coming d-flex align-items-center text-center ">
                <div>
                  <b>Buổi {{ item.lesson }}</b>
                </div>
              </div>

              <div class="col-md-8 lesson-box  mt-4 w-50" :class="comingLesson ? 'ml-2' : ''">
                          <div v-if="item.type !== 'tutoring'" class="">
                            <ul>
                              <p class="date-time m-0">Ngày {{ item.date_only }}
                                <br>
                                {{ item.start_time }} - {{ item.end_time }}
                              </p>
                            </ul>



                          </div>
                          <div v-else>
                            <h4 class="lesson"><b> {{ item.name }} </b></h4>
                            <p class="date-time m-0">Ngày {{ item.date }}</p>
                          </div>
              </div>
            </div>

          <!--          <div v-if="item.type !== 'tutoring'" class="">-->
          <!--            <ul>-->
          <!--              <p class="date-time m-0">Ngày {{ item.date_only }}-->
          <!--                <br>-->
          <!--                {{ item.start_time }} - {{ item.end_time }}-->
          <!--              </p>-->
          <!--            </ul>-->
          <!--            &lt;!&ndash;            <ul>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <p class="date-time m-0">&ndash;&gt;-->
          <!--            &lt;!&ndash;                {{ item.start_time }} - {{ item.end_time }}&ndash;&gt;-->
          <!--            &lt;!&ndash;              </p>&ndash;&gt;-->
          <!--            &lt;!&ndash;            </ul>&ndash;&gt;-->


          <!--          </div>-->
          <!--          <div v-else>-->
          <!--            <h4 class="lesson"><b> {{ item.name }} </b></h4>-->
          <!--            <p class="date-time m-0">Ngày {{ item.date }}</p>-->
          <!--          </div>-->

          </div>

      </div>
    </div>
</template>

<script>
export default {
  name: "ClassScheduleCourseItem",
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    index: {
      type: [Number, String],
      default: () => {
        return ''
      }
    },
  },
  data() {
    return {
      comingLesson: false,
      isDisable: false,
    }
  },
  watch: {
    item: {
      handler() {
        this.isDisable = new Date(this.item.date) < new Date();
        if (this.item.type !== 'tutoring') {
          let matchLesson = this.items.findLast(item => new Date(item.date) < new Date());
          let matchIndex = this.items.findLastIndex(item => new Date(item.date) < new Date());
          let scroll = this.items.length - 1
          let arr = [0, 1, 2, 3, 4, this.items.length - 1];
          let check = arr.includes(matchIndex)
          if (check) {
            scroll = matchIndex
          } else {
            scroll = matchIndex - 5;
          }
          this.comingLesson = matchLesson && matchLesson.learning_schedule_id === this.item.learning_schedule_id && matchIndex !== this.items.length - 1;
          if (this.comingLesson) {
            this.$emit('indexItem', scroll)
          }
        }
      },
      immediate: true,
      deep: true
    },
  }
}
</script>

<style scoped>
i {
  font-size: 1.25rem;
  color: #1bc5bd;
}
</style>
