export class ImportKraModel {
    constructor(index, userId, role, department, kra, requestResult, hours, weight, errors) {
        this.index = index;
        this.userId = userId;
        this.role = role;
        this.department = department;
        this.kra = kra;
        this.requestResult = requestResult;
        this.hours = hours;
        this.weight = weight;
        this.errors = Object.values(errors)
    }
}

export class kraModel {
    constructor(id, name, result, time, weight, isEdit = false, departmentId, accountTypeId) {
        this.id = id;
        this.name = name;
        this.result = result;
        this.time = time;
        this.weight = weight;
        this.isEdit = isEdit;
        this.departmentId = departmentId;
        this.accountTypeId = accountTypeId;
    }
}