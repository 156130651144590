import ApiService from "@/core/services/api.service";
const _ = require('lodash');
//action types
export const GET_REVIEW_BY_ID = "get_review_by_id";
export const GET_REVIEWS = "list-review";
export const CREATE_REVIEW = "create_review";
export const UPDATE_REVIEW = "update_review";
export const DELETE_REVIEW = "delete_review";
export const GET_DEFAULT_AVATAR = "get_default_avatar";
export const GET_AVG_RATING = "get_abg_rating";

//mutations types
export const SET_REVIEWS = "setReviews";
export const SET_REVIEW_BY_ID = "set_Review_By_Id";
export const SET_REVIEW_PAGINATE = "set_review_paginate_value";
export const UPDATE_LIST_REVIEW_WHEN_DELETE = "set_reviews_when_delete_review";


const state = {
    errors: null,
    reviews: [],
    review : '',
    classesPaginate : [],
    reviewPaginatedData : [],
};

const getters = {
    getAllReviews(state) {
        return state.reviews;
    },
    getReviewById(state) {
        return state.review;
    },
    getReviewPaginate(state) {
        return state.reviewPaginatedData;
    },
};
const actions = {
    [CREATE_REVIEW](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('reviews', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [UPDATE_REVIEW](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.post("reviews/" + payload.id+'?_method=PUT', payload.params)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [GET_REVIEWS](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("reviews", payload).then(({data}) => {
                    context.commit(SET_REVIEWS, data.data.data);
                    let reviewPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when reviews clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_REVIEW_PAGINATE, reviewPagination)
                    resolve(data);
                }).catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },

    [GET_REVIEW_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('reviews', id).then(({data}) => {
                context.commit(SET_REVIEW_BY_ID, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [DELETE_REVIEW](context, id) {
        return new Promise(resolve => {
            ApiService.delete('reviews',id).then(({data}) => {
                context.commit(UPDATE_LIST_REVIEW_WHEN_DELETE, id);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_DEFAULT_AVATAR](context, payload) {
        return new Promise(resolve => {
            ApiService.query('getDefaultAvatar', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_AVG_RATING](context, payload) {
        return new Promise(resolve => {
            ApiService.query('reviews-avg-rating', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },


};


const mutations = {
    [SET_REVIEWS](state, data) {
        state.reviews = data;
        state.errors = {};
    },
    [SET_REVIEW_PAGINATE](state, data) {
        state.reviewPaginatedData = data;
    },
    [UPDATE_LIST_REVIEW_WHEN_DELETE](state, id){
        let indexReview = _.findIndex(state.reviews,function (object) {
            return object.id === id;
        });
        state.reviews.splice(indexReview,1);
    },
    [SET_REVIEW_BY_ID](state, data){
        state.review = data;
    },

};

export default {
    state,
    actions,
    mutations,
    getters
};
