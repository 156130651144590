import { render, staticRenderFns } from "./Modal-Bill.vue?vue&type=template&id=2d59035f&scoped=true"
import script from "./Modal-Bill.vue?vue&type=script&lang=js"
export * from "./Modal-Bill.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d59035f",
  null
  
)

export default component.exports