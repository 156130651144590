import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const GET_CATEGORIES = "get_categories";


const state = {};
const getters = {};
const actions = {
    /**
     * Get categories
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_CATEGORIES](context, payload) {
        return new Promise(resolve => {
            ApiService.query('categories', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
