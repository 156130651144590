<template>
  <div v-if="item.status && item.user_id">
    <div v-if="item.device_id">
      <div v-if="!show_input">
        <span>{{ item.device_id }}</span>
        <button class="ml-2 btn btn-icon btn-primary btn-xs" @click="update">
          <i class="flaticon-edit-1 icon-sm"></i>
        </button>
      </div>
      <div v-else class="update-device">
        <button v-if="!show_input" @click="show_input = !show_input"
                class="btn btn-icon btn-success btn-xs"><i
            class="ki ki-solid-plus icon-sm"></i>
        </button>
        <span v-else class="d-flex justify-content-between">
          <el-input placeholder="Nhập device Id" v-model="device" class="mr-2"></el-input>
          <button class="btn btn-icon btn-primary btn-xs" @click="save">
            <i class="ki ki-check icon-sm"></i>
          </button>
        </span>
      </div>
    </div>
    <span v-else class="update-device">
        <button v-if="!show_input" @click="show_input = !show_input"
                class="btn btn-icon btn-success btn-xs"><i
            class="ki ki-solid-plus icon-sm"></i>
        </button>
        <span v-else class="d-flex justify-content-between">
          <el-input placeholder="Nhập device Id" v-model="device" class="mr-2"></el-input>
          <button class="btn btn-icon btn-primary btn-xs" @click="save">
            <i class="ki ki-check icon-sm"></i>
          </button>
        </span>
      </span>
  </div>
</template>

<script>
import {ATTENDANCE_MAPPING_DEVICE} from "@/core/services/store/attendance/attendance.module";

export default {
  name: "AddDevice",
  props: {
    item: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    item() {
    }
  },
  data() {
    return {
      show_input: false,
      device: ''
    }
  },
  methods: {
    update() {
      this.show_input = true;
      this.device = this.item.device_id;
    },
    save(item) {
      this.$store.dispatch(ATTENDANCE_MAPPING_DEVICE, {
        device_id: this.device,
        user_id: this.item.user_id
      }).then((data) => {
        this.show_input = false;
        this.$message.success(data.message);
        this.$emit('deviceSuccess');
      }).catch((err) => {
        this.$message.error(err.data.message);
      });
    },
  }
}
</script>

<style scoped>

</style>