<template>
  <div v-loading="loading">
    <span>Tổng số người đăng ký: {{ totalUser }}</span>
    <div class="table-responsive hidden-scroll-bar my-3" style="height: 500px; overflow-y: auto">
      <table class="table" style="text-align: center;">
        <thead>
        <tr style="border: 1px solid #EBEDF3">
          <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: start">Họ tên</th>
          <th scope="col" class="min-w-100px" style="background: #F8F8F8; text-align: start">Mã nhân sự</th>
          <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: start">Phòng ban</th>
          <th scope="col" class="min-w-100px" style="background: #F8F8F8; text-align: start">Telegram ID</th>
          <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: start;">Thời gian đăng ký</th>
          <th scope="col" class="min-w-90px" style="background: #F8F8F8; text-align: start;">Trạng thái</th>
        </tr>
        </thead>
        <tbody v-if="listUser?.length > 0">
          <tr v-for="item of listUser" :key="item?.code" style="border: 1px solid #EBEDF3; border-top: 0">
            <td style="text-align: start;">
              <span class="line-clamp-1" :title="item?.name">{{item?.name}}</span>
            </td>
            <td style="text-align: start; text-transform: uppercase">{{ item?.userId + 100000 }}</td>
            <td style="text-align: start">
              <span class="line-clamp-1" :title="item?.department">{{item?.department}}</span>
            </td>
            <td style="text-align: start">{{item?.telegramId}}</td>
            <td>{{ item?.registerTime }}</td>
            <td>
              <el-switch v-model="item.status" @change="handleUpdateStatusUserByBot(item)"></el-switch>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
        <tr style="word-break: break-word; white-space: nowrap; position: relative">
          <label style="position: absolute; left: 50%; translate: -50%; top: 10px">Chưa có dữ liệu</label>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  GET_LIST_BOT_BY_USER,
  UPDATE_STATUS_USER_BY_BOT
} from "@/core/services/store/user/account-type.module";
import {UserByBotModel} from "@/view/pages/account-type/model/BotModel";

export default {
  name: 'DialogListBotByUser',
  data() {
    return {
      listUser: [],
      totalUser: 0,
      loading: true,
    }
  },
  mounted() {
    this.getListBotByUserId();
  },
  props: {
    botId: {
      type: Number || String,
      default: null,
      required: false,
    }
  },
  methods: {
    getListBotByUserId() {
      this.$store.dispatch(GET_LIST_BOT_BY_USER, this.botId).then(res => {
        this.loading = false
        if (!res.error) {
          this.totalUser = res?.data?.length ?? 0
          this.listUser = res?.data?.map(item => {
            return new UserByBotModel(
                item?.user?.name,
                item?.user_id,
                item?.department_name,
                item?.telegram_id,
                item?.created_at,
                item?.status,
            )
          }) ?? []
        }
      })
    },
    handleUpdateStatusUserByBot(user) {
      const payload = {
        status: user.status ? 1 : 0,
        user_id: user.userId,
        bot_id : this.botId
      }
      this.$store.dispatch(UPDATE_STATUS_USER_BY_BOT, payload).then(() => {
        if (user.status) {
          this.$message.success('Kích hoạt thành công');
        } else {
          this.$message.success('Ngừng kích hoạt thành công');
        }
        this.getListBotByUserId();
      }).catch(e => {
        console.log(e, 'error')
      })
    },
  }
}
</script>

<style lang="css" scoped>
  .hidden-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
</style>