<script>
export default {
  name: "ModalConfirmedLowScoring",
  props: {
    allCriteria:{
      type: Array,
      default() {
        return []
      }
    }
  },
  methods:{
    cancelModal(){
      this.allCriteria.forEach((criteria)=>{
        criteria.clearPointAndCommentAutoIs0Or1()
      })
      this.$bvModal.hide("Modal-Confirmed-Low-Scoring")
    },
    submit(){
      this.$emit('submit');
      this.$bvModal.hide("Modal-Confirmed-Low-Scoring");
    }
  }
}
</script>

<template>
  <div>
    <b-modal id="Modal-Confirmed-Low-Scoring" centered  title="Xác nhận chấm điểm thấp" hide-footer :no-close-on-backdrop="true" hide-header-close >
      <p class="">Khi chọn điểm 0 hoặc 1 của một tiêu chí, hệ thống sẽ tự động chấm tất cả các tiêu chí còn lại với số điểm tương tự và kết thúc tác vụ này.</p>
      <p style="font-weight: bold">Bạn có chắc chắn muốn tiếp tục?</p>
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-secondary mr-4" @click="cancelModal()">Hủy bỏ</button>
        <button type="button" class="btn btn-info" @click="submit()">Xác nhận</button>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>

</style>