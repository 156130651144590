<template>
    <div>
        <button v-b-modal="`modal-list-detail-${index}`" @click="getListDetail"
                class="btn btn-outline-info font-weight-bold mr-2 btn-sm" v-if="isShowButton">
            <i class="ki ki-bold-sort icon-sm">

            </i>Chi tiết
        </button>
        <b-modal :centered="true" :scrollable="true" size="xl" ref="modal-amount" hide-footer
                 :id="'modal-list-detail-' + index" title="Lịch sử chuyển" @show="getListDetail()">
            <div class="example mb-10">
                <div class="example-preview table-responsive overflow-auto">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col" class="min-w-100px">STT</th>
                            <th scope="col" class="min-w-100px">Mã hóa đơn</th>
                            <th scope="col" class="min-w-200px">Thời gian chuyển</th>
                            <th scope="col" class="min-w-100px">Số tiền</th>
                            <th scope="col" class="min-w-100px">Người chuyển</th>
                            <th scope="col" class="min-w-100px">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in list_detail" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.id }}</td>
                            <td>{{ item.dated_paid | formatDateTimeAsia }}</td>
                            <td>{{  formatPrice(item.amount, 'đ') }}</td>
                            <td>{{ item.create_user != null ? item.create_user.email : '' }}</td>
                            <td class="d-flex justify-content-between" style="width: max-content;">
                                <ModalEditCreatedAt #ModalListDetail
                                                    :item="item"
                                                    :index="index"
                                                    @update_success="updateSuccess"
                                >
                                </ModalEditCreatedAt>
                                <ModalWrongTransferComponent
                                    :item="item"
                                    :index="index"
                                    @update_success="updateSuccess"
                                >
                                </ModalWrongTransferComponent>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="float-right">
                <button type="button" class="btn btn-secondary mr-2" @click="hideModal()"> Đóng</button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import {
    GET_STUDENT_AMOUNT
} from "../../../core/services/store/contract/studentAmount.module";
import ModalEditCreatedAt from '../contracts/ModalEditCreatedAt';
import ModalWrongTransferComponent from '../contracts/WrongTransferComponent';

export default {
    components: {ModalEditCreatedAt, ModalWrongTransferComponent},
    props: {
        item: Object,
        index: '',
        isShowButton: {
            type: Boolean,
            default() {
                return true
            }
        }
    },
    data() {
        return {
            list_detail: [],
        }
    },
    name: "ModalListDetail",
    methods: {
        updateSuccess() {
            this.$emit('updateSuccess');
            this.getListDetail()
        },
        getListDetail() {
            console.log(this.item)
            this.$store.dispatch(GET_STUDENT_AMOUNT, {
                limit: 1000,
                type: 2,
                parent_log_id: this?.item?.id,
                // other_status: 4,
                is_get_log: true,
                is_get_log_children: true
            }).then((res) => {
                this.list_detail = res.data.data
            });
        },

        hideModal() {
            this.$refs['modal-amount'].hide();
        }
    }
}
</script>

<style scoped>
</style>
