<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách trang tĩnh</h3>
      </div>
      <div class="card-title">
        <router-link
            class="font-weight-bold font-size-3  btn btn-success"
            :to="{ name: 'page-create' }"
        >Thêm mới
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <!--begin::Example-->
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <el-input placeholder="Tiêu đề" v-model="query.keyword"></el-input>
          </div>
          <!--end::Form-->
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <el-select v-model="query.status" filterable class="w-100" placeholder="Trạng thái" clearable>
              <el-option
                  v-for="item in status"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                <span style="float: left">{{ item.label }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="d-flex justify-content-between">
            <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                    @click="searchData">
              <i class="fa fa-search"></i> Tìm kiếm
            </button>
            <button v-else type="button" disabled
                    class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
              Tìm kiếm
            </button>
          </div>
        </div>
      </div>

      <div class="example mb-10">
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th scope="col">STT</th>
              <th scope="col">Tiêu đề</th>
              <th scope="col">Slug</th>
              <th scope="col">Trạng thái</th>
              <th scope="col">Ngày đăng</th>
              <th scope="col">Hành động</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item, index) in pages" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.slug }}</td>
              <td>
                <button v-if="item.status" href="#" class="btn btn-success button-active">
                  <i class="fa fa-check-circle"></i> Hoạt động
                </button>
                <button v-else href="#" class="btn btn-success button-not-active">
                  <i class="fa fa-minus-circle"></i> Ẩn
                </button>
              </td>
              <td>{{ item.created_at | formatDateTime }}</td>
              <td>
                <router-link :to="{ name: 'page-edit', params: {id : item.id} }" title="Sửa"
                             class="btn btn-sm btn-icon mr-2 btn-outline-info"><i
                    class="fas fa-pen-nib"></i>
                </router-link>
                <a title="Xóa" @click="deletePage(item.id)" href="javascript:"
                   class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
// Date picker
import {DELETE_PAGE, GET_PAGE} from "../../../core/services/store/pages/page.module";

const _ = require('lodash');

export default {
  components: {},
  name: "PageIndex",
  data() {
    return {
      is_disable_search: false,
      pages: [],
      status: [
        {
          value: 0,
          label: 'Ẩn',
        },
        {
          value: 1,
          label: 'Hoạt động',
        },
      ],
      // Paginate
      page: 1,
      last_page: 1,
      query: {
        keyword: '',
        status: '',
      },
    }
  },
  mounted() {
    this.getList();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý trang tĩnh", route: 'page-index'},
      {title: "Danh sách trang tĩnh"}
    ]);
  },
  methods: {
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl()
      this.callApiGetList()
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'page-index',
        query: {
          page: this.page,
          keyword: this.query.keyword,
          status: this.query.status,
        }
      })
    },
    handleQueryCenter($event) {
      let id = $event.id
      this.query.center_id = id
    },
    getList() {
      this.pullParamsUrl()
      this.callApiGetList()
    },
    pullParamsUrl() {
      this.page = parseInt(this.$route.query.page);
      this.query.keyword = this.$route.query.keyword;
      this.query.status = this.$route.query.status != undefined && this.$route.query.status != '' ? parseInt(this.$route.query.status) : '';
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({path: '', query: {page: this.page}})
      this.callApiGetList()
    },
    callApiGetList() {
      this.$store.dispatch(GET_PAGE, {
        page: this.page,
        keyword: this.query.keyword,
        status: this.query.status,
      }).then((res) => {
        if (!res.data.error) {
          this.is_disable_search = false;
          this.pages = res.data.data;
          this.last_page = res.pagination.last_page;
        }
      });
    },
    deletePage(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_PAGE, id).then((res) => {
            if (res.error) {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'danger',
                solid: true
              });
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'success',
                solid: true
              });
              this.getList()
            }
          });
        }
      });
    },
  }
}
</script>
<style>

.button-active {
  position: static;
  background: #49d746 !important;
  border: #49d746 !important;
  border-radius: 5px !important;
}

.button-not-active {
  position: static;
  background: #F64E60 !important;
  border: #F64E60 !important;
  border-radius: 5px !important;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
