import moment from "moment/moment";

export class InformationProgramModel {
    constructor(id, name, centerId, centerName, type, timeStart, timeEnd) {
        this.id = id;
        this.name = name;
        this.centerId = centerId;
        this.centerName = centerName ?? this.setNameCenter(centerId);
        this.type = type;
        this.timeStart = timeStart;
        this.timeEnd = timeEnd;
        this.status = this.changeStatus(timeStart, timeEnd)
        this.typeToString = this.setTypeString(type)
    }
    changeStatus(start_date, end_date) {
        const start = moment(start_date);
        const now = moment();
        let text = '';
        if (now >= start) {
            text = 'Đã kết thúc';
        }
        if (now < start) {
            text = 'Chưa bắt đầu';
        }
        if(now.isBetween(start_date, end_date)) {
            text = 'Đang diễn ra';
        }
        return text;
    }
    setTypeString(type) {
        if (type === 1) {
            return 'Voucher';
        }
        return 'Quà Tặng'
    }
    setNameCenter(id) {
        switch (id) {
            case 295:
                return 'Ecoach'
            case 516:
                return 'Trung tâm Anh ngữ TOEIC Master'
            case 518:
                return 'Trung tâm Anh ngữ IELTS Mentor'
            case 530:
                return 'Dòng sản phẩm Cambridge Mentor'
        }
    }
}

export class GiftModel {
    constructor(id, name, nameImage, url, type) {
        this.id = id;
        this.name = name;
        this.nameImage = nameImage;
        this.url = url;
        this.type = type;
    }
}

export class ClassDetailModel {
    constructor(id, className, voucher, slot, voucherId) {
        this.id = id;
        this.className = className;
        this.voucher = voucher;
        this.slot = slot;
        this.voucherId = voucherId;
    }
}

export class ConditionModel {
    constructor(id, quantity, name, typeVoucher, gift, comboId, voucherIds = []) {
        this.id = id;
        this.quantity = quantity;
        this.name = name;
        this.type = typeVoucher;
        this.gift = typeof gift === 'array' ? gift.join(", ") : [];
        this.typeString = this.setTypeVoucher(typeVoucher)
        this.comboId = comboId;
        this.giftArr = gift;
        this.voucherIds = voucherIds;
    }
    setTypeVoucher(typeVoucher) {
        if (typeVoucher === 0) {
            return 'Giảm giá theo hợp đồng'
        }
        return 'Giảm giá theo khóa học'
    }
}

const typeProgramOption = [
    {
        label: 'Voucher',
        value: 1,
    },
    {
        label: 'Quà tặng',
        value: 2,
    },
]

const typeVoucher = [
    {
        id: 0,
        name: 'Giảm giá theo hợp đồng'
    },
    {
        id: 1,
        name: 'Giảm giá theo khóa học'
    }
]

export {
    typeProgramOption,
    typeVoucher,
}
