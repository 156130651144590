export const EDUTALK_ID = 491
export const KEYPASS_ID = 518
export const IELTS_MENTOR_ID = 516
export const ECOACH_ID = 295
export const SINH_TRAC_VAN_TAY_ID = 233
export const EDUTALK_TYPE = 1
export const KEYPASS_TYPE = 2
export const IELTS_MENTOR_TYPE = 3
export const ECOACH_TYPE = 4
export const SINH_TRAC_VAN_TAY_TYPE = 5
export const Cambridge_Mentor_ID = 530;



