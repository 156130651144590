<template>
  <div>
    <router-view></router-view>
    <div style="position:fixed; bottom:100px; right: 18px">
      <img src="/media/logo-zalo.png" alt="logo-zalo" @click="openZalo" class="bounce-animation" style="width: 40px; height: 40px;">
    </div>
  </div>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
@import '~@braid/vue-formulate/themes/snow/snow.scss';
@import "assets/sass/ckeditor/ck-editoir.scss";

// Main demo style scss
@import "assets/sass/style.vue";
.el-input__inner{
  text-align: left !important;
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

// Animation
@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.bounce-animation {
  cursor: pointer;
  animation: bounce 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import ID_TOKEN_KEY from "./core/services/jwt.service"

export default {
  name: "MetronicVue",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    window.addEventListener('storage', (e) => { 
      const userKey = 'user'; 
      if ([ID_TOKEN_KEY, userKey].includes(e.key)) {
        window.location.reload(); 
      }
    });
  },
  methods: {
    openZalo() {
      window.open("https://zalo.me/edutalkholdings", "_blank");
    }
  }
};
</script>
