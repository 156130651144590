<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Danh sách lớp học</h3>
            </div>
            <div class="card-title">
              <b-button id="popover-reactive-1" variant="primary" ref="button" class="mr-2" @click="exportExcel">
                <i class="la la-file-excel"></i>
                Xuất excel
              </b-button>
              <b-button id="popover-reactive-1" variant="primary" ref="button" class="mr-2" @click="exportExcelV2">
                <i class="la la-file-excel"></i>
                Xuất excel v2
              </b-button>
                <router-link
                        class="font-weight-bold font-size-3  btn btn-success"
                        :to="{ name: 'class-create' }"
                >Thêm mới
                </router-link>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Tên lớp</label>
                        <input type="text" class="form-control" placeholder="Tìm kiếm" v-model="query.keyword"
                               @input="searchKeyword"
                               aria-describedby="basic-addon2">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Loại khóa học</label>
                        <multiselect v-model="cacheCourseType" :options="courseTypes"
                                     placeholder="Loại khóa học"
                                     label="name"
                                     track-by="name"
                                     @input="handleQueryCourseType($event)"
                        ></multiselect>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Khóa học</label>
                        <multiselect v-model="cacheCourse" :options="courses"
                                     placeholder="Khóa học"
                                     label="name"
                                     track-by="name"
                                     @input="handleQueryCourse($event)"
                        ></multiselect>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Khai giảng từ ngày</label>
                        <date-picker v-model="query.start_date" valueType="format"
                                     @input="handleStartDate"></date-picker>
                    </div>
                    <!--end::Form-->
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Khai giảng kết thúc</label>
                        <date-picker v-model="query.end_date" valueType="format" @input="handleEndDate"></date-picker>
                    </div>
                </div>
            </div>

            <!--begin::Example-->
            <div class="example mb-10">
                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col">Tên lớp</th>
                            <th scope="col">Tên chi nhánh</th>
                            <th scope="col">Khóa học</th>
                            <th scope="col">Loại khóa học</th>
                            <th scope="col">Trạng thái</th>
                            <th scope="col">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="(item, index) in classes.data" :key="index">
                            <td>{{item.name}}</td>
                            <td>{{item.branch != null ? item.branch.name : ''}}</td>
                            <td>{{item.course != null ? item.course.name : ''}}
                            </td>
                            <td>{{item.course != null && item.course.course_type != null ? item.course.course_type.name
                                : ''}}
                            </td>
                            <td>
                                <span class="badge badge-pill badge-success" v-if="item.current_status == 'Đang học'"> {{ item.current_status }}</span>
                                <span class="badge badge-pill badge-light" v-else-if="item.current_status == 'Sắp khai giảng'"> {{ item.current_status }}</span>
                                <span class="badge badge-pill badge-light" v-else-if="item.current_status == 'Sắp diễn ra'"> {{ item.current_status }}</span>
                                <span class="badge badge-pill badge-danger" v-else>{{ item.current_status }}</span>
                            </td>
                            <td>
                                <router-link :to="{ name: 'class-edit', params: {id : item.id} }" title="Sửa"
                                             class="btn btn-sm btn-icon mr-2 btn-outline-info"><i
                                        class="fas fa-pen-nib"></i>
                                </router-link>
                                <a title="Xóa" @click="deleteClass(item.id)" href="javascript:"
                                   class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="edu-paginate mx-auto text-center">
            <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                    :page-class="'page-item'"
            >
            </paginate>
        </div>
    </div>
</template>
<script>
    import Swal from "sweetalert2";
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {
        DELETE_CLASS, EXPORT_CLASS, EXPORT_CLASS_V2,
        GET_CLASSES,
    } from "../../../core/services/store/course/classes.module";
    // Date picker
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    //Multiselect
    import Multiselect from 'vue-multiselect'
    import {GET_COURSES} from "../../../core/services/store/course/course.module";
    import {GET_COURSE_TYPES} from "../../../core/services/store/course/courseType.module";
    import {exportExcel} from "../../../core/filters";
    const _ = require('lodash');
    export default {
        components: {
            DatePicker,
            Multiselect
        },
        name: "BranchIndex",
        data() {
            return {
                classes: [],
                // Paginate
                page: 1,
                last_page: 1,
                query: {
                    keyword: '',
                    course_type: '',
                    course: '',
                    start_date: '',
                    end_date: ''
                },
                courseTypes: [],
                courses: [],
                // cache
                cacheCourseType: [],
                cacheCourse: [],
                allCourse: false
            }
        },
        async mounted() {
            await this.getCourseTypes()
            await this.getList()
            await this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý lớp học", route: 'profile-1'},
                {title: "Danh sách lớp học"}
            ]);
        },
        methods: {
            async getList() {
                await this.pullParamsUrl()
                await this.callApiGetList()
            },
            pullParamsUrl() {
                this.page = this.$route.query.page
                this.query.keyword = this.$route.query.keyword
                this.query.course = this.$route.query.course
                this.query.course_type = this.$route.query.course_type
                this.query.start_date = this.$route.query.start_date
                this.query.end_date = this.$route.query.end_date
            },
            clickCallback(obj) {
                this.page = obj
                this.$router.push({path: '', query: {page: this.page}})
                this.callApiGetList()
            },
            callApiGetList() {
                this.$store.dispatch(GET_CLASSES, {
                    page: this.page,
                    keyword: this.query.keyword,
                    course_id: this.query.course,
                    course_type_id: this.query.course_type,
                    start_date: this.query.start_date,
                    end_date: this.query.end_date,
                }).then((res) => {
                    this.classes = res.data
                    this.last_page = res.data.last_page
                });
            },
            deleteClass(id) {
                Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(DELETE_CLASS, id).then((res) => {
                            console.log(res)
                            if (res.error) {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'danger',
                                    solid: true
                                });
                            } else {
                                Swal.fire("Xóa", "", "success");
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'success',
                                    solid: true
                                });
                                this.getList()
                            }
                        }).catch((error) => {
                            console.log('error', error)
                        });
                    }
                });
            },
            getCourses() {
                this.$store.dispatch(GET_COURSES, {
                    limit: 10000,
                    course_type: this.query.course_type,
                    all: this.allCourse
                }).then((res) => {
                    if (!res.error) {
                        this.courses = res.data
                        this.courses.unshift({
                            name: 'Chọn khóa học'
                        })
                        this.setCacheTheLoad()
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getCourseTypes() {
                this.$store.dispatch(GET_COURSE_TYPES, {
                    limit: 10000,
                }).then((res) => {
                    if (!res.error) {
                        this.courseTypes = res.data.data
                        this.courseTypes.unshift({
                            name: 'Chọn loại khóa học'
                        })
                        this.setCacheTheLoad()
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            handleQueryCourseType($event) {
                this.allCourse = true;
                this.query.course_type = $event.id;
                if (this.$route.query.keyword != undefined) {
                    this.query.keyword = this.$route.query.keyword
                }
                if (this.$route.query.page != undefined) {
                    this.page = this.$route.query.page
                }
                if (this.$route.query.course != undefined) {
                    this.query.course = this.$route.query.course
                }
                if (this.$route.query.start_date != undefined) {
                    this.query.start_date = this.$route.query.start_date
                }
                if (this.$route.query.end_date != undefined) {
                    this.query.end_date = this.$route.query.end_date
                }
                this.pushParamsUrl()
                this.callApiGetList()
                this.getCourses()
            },
            handleQueryCourse($event) {
                this.query.course = $event.id
                if (this.$route.query.page != undefined) {
                    this.page = this.$route.query.page
                }
                if (this.$route.query.keyword != undefined) {
                    this.query.keyword = this.$route.query.keyword
                }
                if (this.$route.query.course_type != undefined) {
                    this.query.course_type = this.$route.query.course_type
                }
                if (this.$route.query.start_date != undefined) {
                    this.query.start_date = this.$route.query.start_date
                }
                if (this.$route.query.end_date != undefined) {
                    this.query.end_date = this.$route.query.end_date
                }
                this.pushParamsUrl()
                this.callApiGetList()
            },
            handleStartDate() {
                if (this.$route.query.page != undefined) {
                    this.page = this.$route.query.page
                }
                if (this.$route.query.keyword != undefined) {
                    this.query.keyword = this.$route.query.keyword
                }
                if (this.$route.query.course_type != undefined) {
                    this.query.course_type = this.$route.query.course_type
                }
                if (this.$route.query.course != undefined) {
                    this.query.course = this.$route.query.course
                }
                if (this.$route.query.end_date != undefined) {
                    this.query.end_date = this.$route.query.end_date
                }
                this.pushParamsUrl()
                this.callApiGetList()
            },
            handleEndDate() {
                if (this.$route.query.page != undefined) {
                    this.page = this.$route.query.page
                }
                if (this.$route.query.keyword != undefined) {
                    this.query.keyword = this.$route.query.keyword
                }
                if (this.$route.query.course_type != undefined) {
                    this.query.course_type = this.$route.query.course_type
                }
                if (this.$route.query.course != undefined) {
                    this.query.course = this.$route.query.course
                }
                if (this.$route.query.start_date != undefined) {
                    this.query.start_date = this.$route.query.start_date
                }
                this.pushParamsUrl()
                this.callApiGetList()
            },
            searchKeyword() {
                if (this.$route.query.page != undefined) {
                    this.page = this.$route.query.page
                }
                if (this.$route.query.course != undefined) {
                    this.query.course = this.$route.query.course
                }
                if (this.$route.query.course_type != undefined) {
                    this.query.course_type = this.$route.query.course_type
                }
                if (this.$route.query.start_date != undefined) {
                    this.query.start_date = this.$route.query.start_date
                }
                if (this.$route.query.end_date != undefined) {
                    this.query.end_date = this.$route.query.end_date
                }
                this.pushParamsUrl()
                this.callApiGetList()
            },
            setCacheTheLoad() {
                if (this.query.course != '') {
                    let oldParamCenter = this.courses.filter((value) => {
                        if (parseInt(this.query.course) == value.id) {
                            return value
                        }
                    })
                    this.cacheCourse = oldParamCenter
                }
                if (this.query.course_type != '') {
                    let oldParamDistrict = this.courseTypes.filter((value) => {
                        if (parseInt(this.query.course_type) == value.id) {
                            return value
                        }
                    })
                    this.cacheCourseType = oldParamDistrict
                }
            },
            pushParamsUrl() {
                this.$router.push({
                    name: 'class-index',
                    query: {
                        keyword: this.query.keyword,
                        course: this.query.course,
                        course_type: this.query.course_type,
                        start_date: this.query.start_date,
                        end_date: this.query.end_date,
                    }
                })
            },
            async exportExcel() {
                await this.pullParamsUrl()
                const HEADER_ROW_EXPORT_CLASSROOM_LIST = [
                    {
                        key: 'stt',
                        value: 'STT',
                    },
                    {
                        key: 'name',
                        value: 'Tên lớp',
                    },
                    {
                        key: 'type',
                        value: 'Loại lớp',
                    },
                    {
                        key: 'course_name',
                        value: 'Khóa học',
                    },
                    {
                        key: 'teacher_name',
                        value: 'Giảng viên',
                    },
                    {
                        key: 'status',
                        value: 'Tình trạng lớp',
                    },
                    {
                        key: 'start_date',
                        value: 'Ngày khai giảng',
                    },
                    {
                        key: 'end_date',
                        value: 'Ngày kết thúc dự kiến',
                    },
                    {
                        key: 'current_student',
                        value: 'Sĩ số',
                    },
                    {
                        key: 'current_lesson',
                        value: 'Tiến độ học',
                    },
                    {
                        key: 'status_code',
                        value: 'Trạng thái',
                    },
                    {
                        key: 'branch_name',
                        value: 'Chi nhánh',
                    },
                ]
                await this.$store.dispatch(EXPORT_CLASS, {
                    page: this.page,
                    keyword: this.query.keyword,
                    course_id: this.query.course,
                    course_type_id: this.query.course_type,
                    start_date: this.query.start_date,
                    end_date: this.query.end_date,
                }).then((res) => {
                    exportExcel(HEADER_ROW_EXPORT_CLASSROOM_LIST, res.data, "Danh sách lớp học.xlsx", true)
                });
            },
            async exportExcelV2() {
                await this.pullParamsUrl()
                const HEADER_ROW_EXPORT_CLASSROOM_LIST = [
                    {
                        key: 'stt',
                        value: 'STT',
                    },
                    {
                        key: 'name',
                        value: 'Tên lớp',
                    },
                    {
                        key: 'type',
                        value: 'Loại lớp',
                    },
                    {
                        key: 'course_name',
                        value: 'Khóa học',
                    },
                    {
                        key: 'teacher_name',
                        value: 'Giảng viên',
                    },
                    {
                        key: 'tinh_trang_lop',
                        value: 'Tình trạng lớp',
                    },
                    {
                        key: 'start_date',
                        value: 'Ngày khai giảng',
                    },
                    {
                        key: 'end_date',
                        value: 'Ngày kết thúc dự kiến',
                    },
                    {
                        key: 'current_student',
                        value: 'Sĩ số',
                    },
                    {
                        key: 'current_lesson',
                        value: 'Tiến độ học',
                    },
                    {
                        key: 'status',
                        value: 'Trạng thái',
                    },
                    {
                        key: 'branch_name',
                        value: 'Chi nhánh',
                    },
                ]
                await this.$store.dispatch(EXPORT_CLASS_V2, {
                    page: this.page,
                    keyword: this.query.keyword,
                    course_id: this.query.course,
                    course_type_id: this.query.course_type,
                    start_date: this.query.start_date,
                    end_date: this.query.end_date,
            }).then((res) => {
                exportExcel(HEADER_ROW_EXPORT_CLASSROOM_LIST, res.data, "Danh sách lớp học.xlsx", true)
            });
          }
        }
    }
</script>
<style scoped>
    .mx-datepicker {
        width: 100% !important;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
