<template>
    <el-form :model="model" :rules="rules" ref="form">
        <el-form-item v-if="testIncome.type_test !='daihoc'" label="Chấm điểm speaking" class="w-100"
                      prop="speaking_point">
            <el-select v-if="testIncome.type_test == 'giaotiep'" v-model="model.speaking_point" clearable filterable
                       placeholder="Chọn điểm" :disabled="isDisable">
                <el-option v-for="point in SPEAKING_POINT"
                           :disabled="isDisable"
                           :key="point.value"
                           :label="point.label"
                           :value="point.value">
                </el-option>
            </el-select>
            <el-select v-else v-model="model.speaking_point" clearable filterable placeholder="Chọn điểm"
                       :disabled="isDisable">
                <el-option v-for="point in POINT"
                           :disabled="isDisable"
                           :key="point.value"
                           :label="point.label"
                           :value="point.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item v-if="testIncome.type_test !='daihoc'" label="Nhận xét speaking" prop="speaking_comment">
            <el-input
                    type="textarea"
                    :rows="4"
                    :disabled="isDisable"
                    placeholder="Nhập nhận xét speaking"
                    v-model="model.speaking_comment">
            </el-input>
        </el-form-item>
        <div class="callout callout-info text-white p-3">
            <div v-if="marks && marks['reading']">
                <h4 class="text-left">Điểm chấm tự động bài test {{ testType }}</h4>
                <div v-if="testIncome.type_test != 'daihoc'">
                    <p class="text-left" v-if="marks && marks['reading']">READING:
                        {{ marks['reading'].exactly + '/' + marks['reading'].total }}</p>
                    <p class="text-left" v-if="marks && marks['listening']">LISTENING:
                        {{ marks['listening'].exactly + '/' + marks['listening'].total }}</p>
                    <p class="text-left" v-if="marks && marks['general test']">GENERAL TEST:
                        {{ marks['general test'].exactly + '/' + marks['general test'].total }}</p>
                </div>
                <div v-else>
                    <p class="text-left" v-if="marks && marks['reading']">Số câu đúng:
                        {{ marks['reading'].exactly + '/' + marks['reading'].total }}</p>
                    <p class="text-left" v-if="marks && marks['reading']">Số điểm:
                        {{ (marks['reading'].exactly * 0.2).toFixed(1) }}</p>
                </div>
                <a :href="urlEcoach" target="_blank" class="btn btn-light-danger font-weight-bold mr-2">Xem bài làm học
                    viên</a>
            </div>
            <div v-else>
                <h4>Học viên đang làm bài test</h4>
            </div>
        </div>
        <el-form-item label="Nhận xét reading" prop="reading_comment"
                      v-if="testIncome.type_test !='daihoc' && marks && marks['reading']">
            <el-input
                    type="textarea"
                    :rows="4"
                    :disabled="isDisable"
                    placeholder="Nhập nhận xét phần reading"
                    v-model="model.reading_comment">
            </el-input>
        </el-form-item>
        <el-form-item label="Nhận xét listening" prop="listening_comment"
                      v-if=" testIncome.type_test !='daihoc' && marks && marks['listening']">
            <el-input
                    type="textarea"
                    :rows="4"
                    :disabled="isDisable"
                    placeholder="Nhập nhận xét phần listening"
                    v-model="model.listening_comment">
            </el-input>
        </el-form-item>
        <el-form-item label="Nhận xét general" prop="general_comment" v-if="marks && marks['general test']">
            <el-input
                    type="textarea"
                    :rows="4"
                    :disabled="isDisable"
                    placeholder="Nhập nhận xét phần general"
                    v-model="model.general_comment">
            </el-input>
        </el-form-item>
        <div class="box box-primary box-solid" v-for="(writingQ, index) in writingQuestion" :key="index">
            <div class="box-header with-border text-white text-left p-3">
                <h3 class="box-title">{{ writingQ.section }}</h3>
                <p>{{ writingQ.content }}</p>
            </div>
            <!-- /.box-header -->
            <div class="box-body text-left">
                <h5 class="text-light-blue mt-2" style="margin-top: 0px; color: #3c8dbc">Nội dung bài viết:</h5>
                <p class="" v-if="hasAnswer(writingQ)">{{ hasAnswer(writingQ) }}</p>
                <p class='text-danger' v-else>Chưa có câu trả lời</p>
                <hr>
                <div class="form-group row align-items-center">
                    <label for="inputMark" class="col-sm-2 control-label" style="font-size: 14px !important;"><strong
                            class="">Chấm
                        điểm:</strong></label>
                    <div class="col-sm-10">
                        <el-select id="inputMark" v-model="model.writing[writingQ.id]" clearable filterable
                                   placeholder="Chọn điểm"
                                   :disabled="isDisable">
                            <el-option v-for="point in POINT"
                                       :key="point.value"
                                       :label="point.label"
                                       :value="point.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </div>
        <el-form-item label="Nhận xét writing" prop="writing_comment" v-if="writingQuestion.length > 0">
            <el-input
                    type="textarea"
                    :rows="4"
                    :disabled="isDisable"
                    placeholder="Nhập nhận xét phần writing"
                    v-model="model.writing_comment">
            </el-input>
        </el-form-item>
    </el-form>
</template>

<script>
    import {POINT, SPEAKING_POINT} from "../../../../core/option/testIncomeOption.js";

    export default {
        props: {
            marks: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            writingQuestion: {
                type: Array,
                default: () => {
                    return []
                }
            },
            answers: {
                type: Array,
                default: () => {
                    return []
                }
            },
            testIncome: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            isDisable: false
        },
        data() {
            return {
                urlEcoach: '',
                model: {
                    auto_point: 10,
                    writing: [],
                    writing_comment: '',
                    reading_comment: '',
                    listening_comment: '',
                    general_comment: '',
                    speaking_point: '',
                    speaking_comment: '',
                },
                writingMark: [],
                testType: '',
                POINT,
                SPEAKING_POINT,
                rules: {
                    speaking_point: [
                        {required: true, message: 'Điểm nói là bắt buộc', trigger: 'change'}
                    ],
                },
                CENTER_ECOACH :295,
                CENTER_IM :516,
            }
        },
        mounted() {
            this.checkUrlEweb();
        },
        watch: {
            testIncome: {
                handler() {
                    if (this.testIncome.writing_comment) {
                        this.model.writing_comment = this.testIncome.writing_comment;
                    }
                    if (this.testIncome.reading_comment) {
                        this.model.reading_comment = this.testIncome.reading_comment;
                    }
                    if (this.testIncome.speaking_comment) {
                        this.model.speaking_comment = this.testIncome.speaking_comment;
                    }
                    if (this.testIncome.listening_comment) {
                        this.model.listening_comment = this.testIncome.listening_comment;
                    }
                    if (this.testIncome.general_comment) {
                        this.model.general_comment = this.testIncome.general_comment;
                    }
                    this.testType = this.testIncome.testType;
                },
                deep: true,
                immediate: true,
            },
            marks: {
                handler() {
                  if(this.marks && this.marks.writing && this.marks.writing.writing_each) {
                    this.model.writing = this.marks.writing.writing_each;
                  }
                  if (this.marks && this.marks['speaking']) {
                    this.model.speaking_point = this.marks['speaking'].exactly;
                   }
                },
                deep: true,
                immediate: true,
            },

        },
        methods: {
            validate() {
                return new Promise((resolve, reject) => {
                    this.$refs.form.validate((valid) => {
                        this.$emit('on-validate', valid, this.model)
                        resolve(valid);
                    });
                })
            },
            hasAnswer(writingQ) {
                let wrtingPara = '';
                this.answers.forEach((answer) => {
                    if (answer.id === writingQ.id) {
                        wrtingPara = answer.answers;
                    }
                });
                return wrtingPara;
            },
            checkUrlEweb() {
                if (this.testIncome.center_id == this.CENTER_ECOACH) {
                    this.urlEcoach = process.env.VUE_APP_URL_ECOACH + '/exam?type=' + this.testIncome.type_test + '&user_id=' + this.testIncome.user_id;
                }
                if (this.testIncome.center_id == this.CENTER_IM) {
                    this.urlEcoach = process.env.VUE_APP_URL_IM + '/exam?type=' + this.testIncome.type_test + '&user_id=' + this.testIncome.user_id;
                }
            }
        }

    }
</script>

<style>
    .callout-info {
        background-color: #00c0ef !important;
        border-radius: 5px;
    }

    .box-header.with-border {
        background-color: #3c8dbc;
        border-radius: 5px;
    }
</style>
