<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Edutalk thu tiền</h3>
            </div>
            <div class="card-title" v-if="currentUser.user.permission.includes(permission.EDUTALK_THU_TIEN)">
                <button class="btn btn-success font-weight-bold mr-2" @click="isOpenExportExcel = true">
                    Xuất dữ liệu CKNH
                </button>
                <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcel">
                    <i class="la la-file-excel"></i> Xuất excel
                </button>
                <button v-else disabled class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
                  Xuất excel
                </button>
                <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcelV2">
                  <i class="la la-file-excel"></i> Xuất excel v2
                </button>
                <button v-else disabled class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
                  Xuất excel v2
                </button>
            </div>
        </div>
        <div class="card-body">
            <!--begin::Example-->
            <div class="example mb-10">
                <div class="row filter_form">
                    <div class="col-md-2">
                        <date-picker
                            placeholder="Chọn thời gian"
                            format="DD-MM-YYYY"
                            range
                            valueType="YYYY-MM-DD" v-model="query.date_range"></date-picker>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <el-select v-model="query.center_id" clearable filterable class="w-100" placeholder="Chọn trung tâm"
                                       @change="handleQueryCenter($event)">
                                <el-option
                                    v-for="(item, key) in centers"
                                    :label="item.name"
                                    :value="item.id"
                                    :key="key"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <el-select v-model="query.branch_id" clearable filterable class="w-100" placeholder="Chọn chi nhánh"
                                       @change="handleQueryBranch($event)">
                                <el-option
                                    v-for="(item, key) in branches"
                                    :label="item.name"
                                    :value="item.id"
                                    :key="key"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <input type="text" v-model="query.keyword" class="form-control"
                                   placeholder="Tên, SĐT, Mã học viên">
                        </div>
                        <!--end::Form-->
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <el-select v-model="query.status_history" clearable filterable class="w-100"
                                       placeholder="Trạng thái gửi thông tin thu tiền">
                                <el-option
                                    v-for="(item, key) in listStatusSent"
                                    :label="item.name"
                                    :value="item.type"
                                    :key="key"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="d-flex justify-content-between">
                            <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                                    @click="searchData">
                                <i class="fa fa-search"></i> Tìm kiếm
                            </button>
                            <button v-else type="button" disabled
                                    class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                                Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
                <div class="example-preview b-table-sticky-header">
                    <el-row v-loading="loadingTable">
                        <table class="table table-vertical-center b-table ">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col" class="min-w-100px">Mã hóa đơn</th>
                                <th scope="col" class="min-w-100px">Tên học viên</th>
                                <th scope="col" class="min-w-100px">Mã học viên</th>
                                <th scope="col" class="min-w-100px">Mã hợp đồng</th>
                                <th scope="col" class="min-w-100px">Số tiền nạp</th>
                                <th scope="col" class="min-w-150px">Chi nhánh</th>
                                <th scope="col" class="min-w-150px">Ngày nạp tiền</th>
                                <th scope="col" class="min-w-90px">Đã chuyển</th>
                                <th scope="col" class="min-w-140px">Đã gửi thông tin thu tiền</th>
                                <th scope="col" class="min-w-200px">Hành động</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in student_amounts" :key="index">
                                <td>{{ item.id }}</td>
                                <td>{{ item.user != null ? item.user.name : '' }}</td>
                                <td>{{ item.user_id + 100000 }}</td>
                                <td>{{ item.contract_id ??  '' }}</td>
                                <td>{{ formatPrice(item.amount) }}</td>
                                <td>{{ item.branch != null ? item.branch.name : '' }}</td>
                                <td>{{ item.dated_paid | formatDateTimeAsia }}</td>
                                <td>{{ formatPrice(item.total_amount_childes) }}
                                </td>
                                <td>
                                    <div v-if="item?.listSent.length == 0">
                                        <span class="badge badge-secondary">Chưa gửi</span>
                                    </div>
                                    <div v-else class="d-flex">
                    <span v-for="(id,index) in item?.listSent" :key="index" :class="checkColorStudentSent(id)"
                          class="ml-3">{{ checkNameStatusSent(id) }}</span>
                                    </div>
                                </td>
                                <td>
                                    <el-dropdown>
                    <span class="el-dropdown-link">
                         <button type="button" class="btn btn-info btn-sm"><i
                             class="fa-solid fa-gear"></i> Thao tác</button>
                    </span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item>
                                                <div @click="transferMoney(item,index)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"
                                                         style="margin-right: 10px;font-size: 16px">
                                                        <path
                                                            d="M10.75 5.87937H4.25M10.75 5.87937L8.3125 8.33696M10.75 5.87937L8.3125 3.42178M7.5 13.6618H10.75M7.5 13.6618H4.25M7.5 13.6618V10.385M7.5 10.385H1.8125C1.36377 10.385 1 10.0182 1 9.56576V2.19298C1 1.74055 1.36377 1.37378 1.8125 1.37378H13.1875C13.6362 1.37378 14 1.74055 14 2.19298V9.56576C14 10.0182 13.6362 10.385 13.1875 10.385H7.5Z"
                                                            stroke="#464646" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    Chuyển tiền Educenter
                                                </div>
                                            </el-dropdown-item>
                                            <el-dropdown-item v-if="item.center_id == EDUTALK_ID || item.center_id == KEYPASS_ID || item.center_id == IELTS_MENTOR_ID || item.center_id == ECOACH_ID ">
                                                <div @click="sentZalo(item)">
                                                    <img
                                                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Icon_of_Zalo.svg/1024px-Icon_of_Zalo.svg.png"
                                                        style="width: 20px; height: auto;margin-right: 10px">Gửi Zalo
                                                </div>
                                            </el-dropdown-item>
                                            <el-dropdown-item v-if="checkShowActionSms(item)">
                                                <div @click="sendSms(item.id)">
                                                    <img
                                                        src="../../../assets/img/logo_zalo.png"
                                                        style="width: 20px; height: auto;margin-right: 10px">Gửi SMS
                                                </div>
                                            </el-dropdown-item>
                                            <el-dropdown-item
                                                v-if="item.center_id == EDUTALK_ID || item.center_id == KEYPASS_ID || item.center_id == IELTS_MENTOR_ID || item.center_id == ECOACH_ID || item.center_id ==SINH_TRAC_VAN_TAY_ID || item.center_id == Cambridge_Mentor_ID">
                                                <div @click="openBill(item)">
                                                    <img
                                                        src="https://cdn.osxdaily.com/wp-content/uploads/2022/02/gmail-logo-610x458.jpg"
                                                        style="width: 20px; height: auto ;margin-right: 10px">
                                                    Gửi Email
                                                </div>
                                            </el-dropdown-item>
                                            <el-dropdown-item
                                                v-if="item.center_id == EDUTALK_ID || item.center_id == KEYPASS_ID || item.center_id == IELTS_MENTOR_ID || item.center_id == ECOACH_ID || item.center_id ==SINH_TRAC_VAN_TAY_ID || item.center_id == Cambridge_Mentor_ID">
                                                <div @click="openBill(item)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none"
                                                         style="margin-right: 10px;width: 18px; height: auto">
                                                        <path
                                                            d="M9.25 11.3687V9.32071H3.75V11.3687M9.25 11.3687V13.4167H3.75V11.3687M9.25 11.3687H12V5.22471H9.25M3.75 11.3687H1V5.22471H3.75M3.75 5.22471H9.25M3.75 5.22471V1.81139C3.75 1.43436 4.0578 1.12872 4.4375 1.12872H8.5625C8.9422 1.12872 9.25 1.43436 9.25 1.81139V5.22471"
                                                            stroke="#464646" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    In hóa đơn
                                                </div>
                                            </el-dropdown-item>
                                            <el-dropdown-item>
                                                <div v-if="item.total_amount_childes != 0 || item.total_amount_childes_wrong != 0"
                                                     @click="historyTransferMoney(item,index)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none"
                                                         style="margin-right: 10px;width: 15px; height: auto">
                                                        <path
                                                            d="M1.28341 8.66746C1.64365 9.69116 2.32645 10.5699 3.22891 11.1713C4.13138 11.7727 5.20463 12.0642 6.28695 12.0019C7.36927 11.9395 8.40203 11.5267 9.22962 10.8256C10.0572 10.1245 10.6348 9.17308 10.8753 8.11475C11.1159 7.05643 11.0064 5.94851 10.5633 4.95792C10.1203 3.96732 9.36763 3.14773 8.41884 2.62261C7.47005 2.0975 6.37651 1.89532 5.30297 2.04654C3.59712 2.28682 2.35337 3.54143 1 4.50081M1 4.50081V0.746155M1 4.50081H4.75"
                                                            stroke="#464646" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    Lịch sử chuyển tiền
                                                </div>
                                            </el-dropdown-item>
                                            <el-dropdown-item>
                                                <div @click="openModalHistorySent(item.histories_notification,item.contractAndUser)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none"
                                                         style="margin-right: 10px;width: 15px; height: auto">
                                                        <path
                                                            d="M1.28341 9.24858C1.64365 10.2723 2.32645 11.151 3.22891 11.7524C4.13138 12.3539 5.20463 12.6453 6.28695 12.583C7.36927 12.5206 8.40203 12.1078 9.22962 11.4067C10.0572 10.7056 10.6348 9.75419 10.8753 8.69587C11.1159 7.63755 11.0064 6.52963 10.5633 5.53903C10.1203 4.54844 9.36763 3.72884 8.41884 3.20373C7.47005 2.67862 6.37651 2.47644 5.30297 2.62765C3.59712 2.86794 2.35337 4.12255 1 5.08193M1 5.08193V1.32727M1 5.08193H4.75"
                                                            stroke="#464646" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    Lịch sử gửi thông tin thu tiền
                                                </div>
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </el-row>
                </div>
            </div>
        </div>
        <div class="edu-paginate mx-auto text-center">
            <paginate
                v-model="page"
                :page-count="last_page"
                :page-range="3"
                :margin-pages="1"
                :click-handler="clickCallback"
                :prev-text="'Trước'"
                :next-text="'Sau'"
                :container-class="'pagination b-pagination'"
                :pageLinkClass="'page-link'"
                :next-link-class="'next-link-item'"
                :prev-link-class="'prev-link-item'"
                :prev-class="'page-link'"
                :next-class="'page-link'"
                :page-class="'page-item'"
            >
            </paginate>
        </div>
        <ModalTransferMoney
            :item="item"
            :index="index"
            @move-success="moveSuccess"
            :isShowButton="false"
        >
        </ModalTransferMoney>
        <ModalListDetail
            #ModalListDetail
            :item="item"
            :index="index"
            @updateSuccess="updateSuccess"
            :isShowButton="false"
        >
        </ModalListDetail>
        <ModalHistorySentInformation :listHistoryNotification="listHistoryNotification" :titleModal="titleModal">
        </ModalHistorySentInformation>
        <el-dialog
            :visible.sync="isOpenExportExcel"
            width="30%"
            title="Xuất dữ liệu CKNH">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <form class="form" @submit.prevent autocomplete="off">
                    <ValidationProvider
                        name="Thời gian kết thúc"
                        rules="required"
                        v-slot="{ errors, classes }"
                        vid="end_schedule"
                    >
                        <el-date-picker
                            style="width: 100% !important;"
                            v-model="dateExportExcel"
                            type="datetimerange"
                            range-separator="To"
                            start-placeholder="Thời gian bắt đầu"
                            end-placeholder="Thời gian kết thúc">
                        </el-date-picker>
                        <div class="fv-plugins-message-container">
                            <div
                                data-field="name"
                                data-validator="notEmpty"
                                class="fv-help-block"
                            >
                                {{ errors[0] }}
                            </div>
                        </div>
                    </ValidationProvider>
                    <div  class="mt-7 d-flex justify-content-end">
                        <el-button @click="isOpenExportExcel = false">Đóng</el-button>
                        <el-button type="primary" @click="handleSubmit(handleExportExcel)">Xuất Excel</el-button>
                    </div>
                </form>
            </ValidationObserver>
        </el-dialog>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

const _ = require('lodash');
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import {
  GET_STUDENT_AMOUNT,
  EXPORT_EDUTALK_THU_TIEN_OR_HOP_DONG_UNG,
  CONTRACT_STUDENT_AMOUNT_SEND_SMS,
  EXPORT_DATA_CKNH,
  EXPORT_EDUTALK_THU_TIEN_V2
} from "../../../core/services/store/contract/studentAmount.module";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
import Multiselect from 'vue-multiselect'
import ModalTransferMoney from './ModalTransferMoney';
import ModalListDetail from './ModalListDetail';
// Datetime picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ModalBill from "./Modal-Bill";
import {Loading} from 'element-ui';
import {
    ECOACH_ID,
    IELTS_MENTOR_ID,
    KEYPASS_ID,
    EDUTALK_ID,
    ECOACH_TYPE,
    IELTS_MENTOR_TYPE,
    KEYPASS_TYPE,
    EDUTALK_TYPE, SINH_TRAC_VAN_TAY, SINH_TRAC_VAN_TAY_ID, SINH_TRAC_VAN_TAY_TYPE,
    Cambridge_Mentor_ID
} from "@/core/option/typeCenterBill";
import {
    COLOR_SENT, formatDate,
    formatMoneyToVND,
    NAME_SENT,
    SENT_EMAIL,
    SENT_SMS,
    SENT_ZALO,
    UNSENT
} from "@/core/option/edutalkPaymentOption";
import ModalHistorySentInformation from "@/view/pages/contracts/ModalHistorySentInformation";
import {NOTIFY_ZALO} from "@/core/services/store/notification/notification.module";
import moment from "moment-timezone";
import {exportExcel} from "@/core/filters";


export default {
    components: {
        ModalHistorySentInformation,
        ModalBill,
        Multiselect,
        DatePicker,
        ModalTransferMoney,
        ModalListDetail
    },
    name: "StudentAmount",
    data() {
        return {
            is_loading_send_sms: false,
            is_disable_search: false,
            is_export_excel: false,
            branches: [],
            centers: [],
            student_amounts: [],
            // Paginate
            page:
                1,
            last_page:
                1,
            query:
                {
                    center_id: '',
                    branch_id: '',
                    date_range: '',
                    keyword: '',
                    //Condition default
                    parent_log_id: 0,
                    ben_thu_tien: 1,
                    other_type: 3,
                    other_status: 4,
                    is_sum_amount_childs: true,
                    is_danh_sach_dong_tien: 1,
                    status_history: null
                }
            ,
            districtsByProvince: [],

            // cache
            cacheCenter: [],
            loadingTable: false,
            cacheBranch: [],
            ECOACH_ID,
            IELTS_MENTOR_ID,
            KEYPASS_ID,
            EDUTALK_ID,
            ECOACH_TYPE,
            IELTS_MENTOR_TYPE,
            KEYPASS_TYPE,
            EDUTALK_TYPE,
            SINH_TRAC_VAN_TAY_ID,
            SINH_TRAC_VAN_TAY_TYPE,
            Cambridge_Mentor_ID,
            SENT_ZALO: SENT_ZALO,
            SENT_EMAIL: SENT_EMAIL,
            SENT_SMS: SENT_SMS,
            listStatusSent: [
                {
                    type: SENT_ZALO,
                    name: 'Đã gửi Zalo'
                },
                {
                    type: SENT_EMAIL,
                    name: 'Đã gửi Email'
                },
                {
                    type: SENT_SMS,
                    name: 'Đã gửi SMS'
                },
                {
                    type: UNSENT,
                    name: 'Chưa gửi'
                }
            ],
            item: null,
            index: -1,
            listHistoryNotification: [],
            titleModal: '',
            isOpenExportExcel: false,
            dateExportExcel: null

        }
    },
    computed: {
        ...mapGetters(["currentUser"]),
    },
    mounted() {
        this.pullParamsUrl()
        this.getCenters()
        this.getBranches()
        this.getList()
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý hợp đồng", route: '#'},
            {title: "Danh sách edutalk thu tiền"}
        ]);
    },
    methods: {
        checkShowActionSms(item) {
            if (!item.center || item.center.is_edutalk !== 1) {
                return false;
            }
            if (item.is_send) {
                return false;
            }

            return true;
        },
        sendSms(id) {
            const loading = this.$loading({
                lock: true,
                text: 'Đang gửi',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$store.dispatch(CONTRACT_STUDENT_AMOUNT_SEND_SMS, {
                contract_student_amount_id: id
            }).then((res) => {
                this.getList();
                this.$message.success(res.message);
            }).catch((error) => {
                this.$message.error(error.data.message);
            }).finally(() => {
                loading.close();
            });
        },
        moveSuccess() {
            this.getList()
        },
        getList() {
            this.pullParamsUrl()
            this.setCacheTheLoad()
            this.callApiGetList()
        },
        updateSuccess() {
            this.getList()
        },
        setCacheTheLoad() {
            if (this.query.center_id != '') {
                let oldParamCenter = this.centers.filter((value) => {
                    if (parseInt(this.query.center_id) == value.id) {
                        return value
                    }
                })

                this.cacheCenter = oldParamCenter
            }

            if (this.query.branch_id != '') {
                let oldParamBranch = this.branches.filter((value) => {
                    if (parseInt(this.query.branch_id) == value.id) {
                        return value
                    }
                })
                this.cacheBranch = oldParamBranch
            }
        },

        pullParamsUrl() {
            this.page = this.$route.query.page
            this.query.keyword = this.$route.query.keyword
            this.query.center_id = this.$route.query.center_id ? parseInt(this.$route.query.center_id) : ''
            this.query.branch_id = this.$route.query.branch_id ? parseInt(this.$route.query.branch_id) : ''
            this.query.date_range = this.$route.query.date_range
        },
        pushParamsUrl() {
            this.$router.push({
                name: 'edutalk-payment',
                query: {
                    page: this.page,
                    keyword: this.query.keyword,
                    center_id: this.query.center_id,
                    branch_id: this.query.branch_id,
                    date_range: this.query.date_range
                }
            })
        },
        clickCallback(obj) {
            this.page = obj
            this.$router.push({
                path: '',
                query: {
                    page: this.page,
                    center_id: this.query.center_id,
                    branch_id: this.query.branch_id,
                    keyword: this.query.keyword,
                    date_range: this.query.date_range
                }
            })
            this.callApiGetList()
        },
        callApiGetList() {
            this.$store.dispatch(GET_STUDENT_AMOUNT, {
                ...this.query,
                page: this.page,
            }).then((res) => {
                this.is_disable_search = false
                this.student_amounts = res?.data?.data?.filter(item => item.is_refund !== 1);
                this.student_amounts = this.student_amounts.map((student) => {
                    if (!Array.isArray(student.histories_notification)) {
                        student.histories_notification = Object.values(student.histories_notification)
                    }
                    let listSent = student.histories_notification.filter((history) => history.status)?.map((history) => history.provider == 'zalo' ? 1 : history.provider == 'email' ? 2 : 3) ?? [];
                    listSent = Array.from(new Set(listSent))
                    let contractAndUser = `${student?.contract_id} - ${student?.user?.name}`
                    return {...student, listSent: listSent, contractAndUser: contractAndUser}
                })
                this.last_page = res.data.last_page
            });
        },

        exportExcel() {
            this.is_export_excel = true
            const HEADER_ROW_EXPORT_DSDT = [
                {
                    key: 'stt',
                    value: 'STT',
                },
                {
                    key: 'id',
                    value: 'Mã hóa đơn',
                },
                {
                    key: 'name',
                    value: 'Tên học viên',
                },
                {
                    key: 'user_id',
                    value: 'Mã học viên',
                },
                {
                    key: 'phone',
                    value: 'Số điện thoại',
                },
                {
                    key: 'contract_id',
                    value: 'Mã hợp đồng',
                },
                {
                    key: 'amount',
                    value: 'Số tiền nạp',
                },
                {
                    key: 'ben_thu_tien',
                    value: 'Bên thu tiền',
                },
                {
                    key: 'center_name',
                    value: 'Trung tâm',
                },
                {
                    key: 'branch_name',
                    value: 'Chi nhánh',
                },
                {
                    key: 'created_user',
                    value: 'Người tạo',
                },
                {
                    key: 'dated_paid',
                    value: 'Ngày nạp tiền',
                },
                {
                    key: 'total_amount_childes',
                    value: 'Đã chuyển',
                },
            ]
            this.$store.dispatch(EXPORT_EDUTALK_THU_TIEN_OR_HOP_DONG_UNG, {
                ...this.query,
            }).then((res) => {
                this.is_export_excel = false
                if (res.data.error) {
                    this.$bvToast.toast(res.data.message, {
                        title: 'Export excel',
                        variant: 'danger',
                        solid: true
                    });
                }
                exportExcel(HEADER_ROW_EXPORT_DSDT, res.data, 'Danh sách Edutalk thu tiền.xlsx', true)
            });
        },
        exportExcelV2() {
            this.is_export_excel = true
            const HEADER_ROW_EXPORT_DSDT = [
                {
                    key: 'stt',
                    value: 'STT',
                },
                {
                    key: 'bill_id',
                    value: 'Mã hóa đơn',
                },
                {
                    key: 'name',
                    value: 'Tên học viên',
                },
                {
                    key: 'user_id',
                    value: 'Mã học viên',
                },
                {
                    key: 'phone',
                    value: 'Số điện thoại',
                },
                {
                    key: 'contract_id',
                    value: 'Mã hợp đồng',
                },
                {
                    key: 'amount',
                    value: 'Số tiền nạp',
                },
                {
                    key: 'ben_thu_tien',
                    value: 'Bên thu tiền',
                },
                {
                    key: 'center_name',
                    value: 'Trung tâm',
                },
                {
                    key: 'chi_nhanh_nap_tien',
                    value: 'Chi nhánh',
                },
                {
                    key: 'created_user',
                    value: 'Người tạo',
                },
                {
                    key: 'dated_paid',
                    value: 'Ngày nạp tiền',
                },
                {
                    key: 'total_amount_childes',
                    value: 'Đã chuyển',
                },
            ]
            this.$store.dispatch(EXPORT_EDUTALK_THU_TIEN_V2, {
                ...this.query,
            }).then((res) => {
                this.is_export_excel = false
                if (res.data.error) {
                    this.$bvToast.toast(res.data.message, {
                        title: 'Export excel',
                        variant: 'danger',
                        solid: true
                    });
                }
                exportExcel(HEADER_ROW_EXPORT_DSDT, res.data, 'Danh sách Edutalk thu tiền.xlsx', true)
            });
        },
        getCenters() {
            this.$store.dispatch(GET_CENTERS, {
                limit: 10000,
            }).then((res) => {
                if (!res.error) {
                    this.centers = res.data.data
                    this.setCacheTheLoad()
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getBranches() {
            this.$store.dispatch(GET_BRANCHES, {
                limit: 10000,
                center_id: this.query.center_id
            }).then((res) => {
                if (!res.error) {
                    this.branches = res.data.data
                    this.setCacheTheLoad()
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        handleQueryCenter($event) {
            this.query.branch_id = ''
            this.branches = []
            this.query.center_id = $event
            this.getBranches()

        },
        handleQueryBranch($event) {
            this.query.branch_id = $event
        },
        searchData() {
            this.page = 1
            this.is_disable_search = true
            this.pushParamsUrl()
            this.callApiGetList()
        },
        addFirstSelectExample(obj, text) {
            obj.unshift({
                name: text
            })
        },
        openBill(bill) {
            let type = 0
            if (bill.center_id == EDUTALK_ID) {
                type = EDUTALK_TYPE
            } else if (bill.center_id == KEYPASS_ID) {
                type = KEYPASS_TYPE
            } else if (bill.center_id == IELTS_MENTOR_ID || bill.center_id == Cambridge_Mentor_ID) {
                type = IELTS_MENTOR_TYPE
            } else if (bill.center_id == ECOACH_ID) {
                type = ECOACH_TYPE
            } else if (bill.center_id == SINH_TRAC_VAN_TAY_ID) {
                type = SINH_TRAC_VAN_TAY_TYPE
            }
            let url = window.location.origin + '/bill?id=' + bill.id + '&type=' + type
            window.open(url, '_blank');
        },
        checkColorStudentSent(key) {
            return COLOR_SENT.get(key)
        },
        checkNameStatusSent(key) {
            return NAME_SENT.get(key)
        },
        async transferMoney(item, index) {
            this.item = await item
            this.index = await index
            setTimeout(() => {
                this.$bvModal.show('modal-transfer-' + index)
            }, 200)
        },
        async historyTransferMoney(item, index) {
            this.item = await item
            this.index = await index
            setTimeout(() => {
                this.$bvModal.show('modal-list-detail-' + index)
            }, 200)
        },
        async openModalHistorySent(data, titleModal) {
            data = data.sort((a, b) => a.created_at < b.created_at ? 1 : -1).map((e) => {
                return {...e, amount: formatMoneyToVND(e.amount), created_at: formatDate(e.created_at)}
            })
            this.listHistoryNotification = await data
            this.titleModal = await titleModal
            this.$bvModal.show('modal-history-sent-information')
        },
        sentZalo(item) {
            let payload = {contract_student_amount_id: item.id}
            this.$store.dispatch(NOTIFY_ZALO, payload).then((data) => {
                this.$notify({
                    title: 'Thành công',
                    message: `${data.message}`,
                    type: 'success'
                });
            }).catch(() => {
                this.$notify({
                    title: 'Thất bại',
                    message: `Gửi zalo thất bại`,
                    type: 'error'
                });
            })
        },
        handleExportExcel(){
            console.log(this.dateExportExcel)
            let payload = {
                start_date : moment(this.dateExportExcel[0]).format('YYYY-MM-DD HH:mm:ss'),
                end_date : moment(this.dateExportExcel[1]).format('YYYY-MM-DD HH:mm:ss')
            }
            this.$store.dispatch(EXPORT_DATA_CKNH,payload).then((data)=>{
                console.log(data)
            })
        }
    }
}
</script>
<style>
.mx-datepicker {
    width: 100% !important;
}

.multiselect__tags {
    padding: 3px 40px 0 9px !important;
    min-height: 38px !important;
}

.multiselect {
    min-height: 38px !important;
}

.mx-input {
    min-height: 38px !important;
}

.multiselect__single {
    margin-top: 5px !important;
}

</style>
