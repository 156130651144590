import { render, staticRenderFns } from "./ListCourseRefund.vue?vue&type=template&id=0bb40b62&scoped=true"
import script from "./ListCourseRefund.vue?vue&type=script&lang=js"
export * from "./ListCourseRefund.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bb40b62",
  null
  
)

export default component.exports