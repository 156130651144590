<template>
    <div class="card card-custom">
        <div class="card-header">
            <h3 class="card-title">
                Nạp rút tiền trước 19-7
            </h3>
            <div class="card-toolbar">
                <div class="example-tools justify-content-center">
                    <span class="example-toggle" data-toggle="tooltip" title="View code"></span>
                    <span class="example-copy" data-toggle="tooltip" title="Copy code"></span>
                </div>
            </div>
        </div>
        <!--begin::Form-->
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                    <form class="form" id="nap_rut_197" @submit.prevent="handleSubmit(amountIn,amountOut)">
                        <div class="card-body">
                            <div class="form-group">
                                <label>Tìm học viên</label>
                                <SelectCustomer minimumInputLength="6" placeholder="Nhập mã nv/SĐT"
                                                method="GET"
                                                :url="getUserUrl"
                                                :date1907="date197"
                                                @input="selectCustomer($event)"
                                                @napRut197="messageNotFound()"
                                >
                                </SelectCustomer>
                            </div>
                            <div v-if="show_detail">
                                <h3 class="card-title d-flex justify-content-center font-weight-bold">
                                    Kết quả tìm kiếm
                                </h3>
                                <div class="form-group row">
                                    <label class="col-3">Mã học viên:</label>
                                    <div class="col-9">
                                        <p class="font-weight-bold font-italic">{{user.id ? user.id + 100000 : ''}}</p>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-3">Họ và tên:</label>
                                    <div class="col-9">
                                        <p class="font-weight-bold font-italic">{{user.name ? user.name : ''}}</p>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-3">Số điện thoại:</label>
                                    <div class="col-9">
                                        <p class="font-weight-bold font-italic">{{user.phone ? user.phone :''}}</p>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-3">Số dư tài khoản:</label>
                                    <div class="col-9">
                                        <p class="font-weight-bold font-italic">{{user.cash ? formatPrice(user.cash,'VNĐ'): ''}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                  <div class="form-group col-md-6">
                                    <label>Số tiền nạp rút
                                      <span class="text-danger">*</span></label>
                                    <ValidationProvider mode="lazy" vid="amount" name="Tiền nạp"
                                                        rules="required|integer|min_value:0"
                                                        v-slot="{ errors,classes }">
                                      <money v-model="student_amount.amount" v-bind="money" class="form-control"
                                      ></money>
                                      <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                        </div>
                                      </div>
                                    </ValidationProvider>
                                  </div>
                                  <div class="col-md-6 d-lg-flex justify-content-center">
                                    <div class="col-form-label checkbox-inline">
                                      <label class="checkbox checkbox-success">
                                        <input type="checkbox" name="Checkboxes5" v-model="isDebt">
                                        <span></span> Khách hàng nợ?</label>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Hình thức thanh toán: <span class="text-danger">*</span></label>
                                    <ValidationProvider name="Hình thức thanh toán" rules="required" v-slot="{ errors,classes }" vid="refund_note">
                                      <el-select v-model="hinh_thuc_thanh_toan" name="refund_note" clearable filterable class="w-100 test-id" placeholder="Chọn hình thức thanh toán">
                                        <el-option
                                          v-for="item in hinh_thuc_thanh_toans"
                                          :key="item.name"
                                          :label="item.name"
                                          :value="item.name"
                                          :class="classes"
                                        >
                                          <span style="float: left">{{ item.name }}</span>
                                        </el-option>
                                      </el-select>
                                      <div class="fv-plugins-message-container">
                                        <div data-field="refund_note" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                                      </div>
                                    </ValidationProvider>
                                  </div>
                                </div>
                                </div>
                                <div class="form-group">
                                    <label>Chọn Trung tâm
                                        <span class="text-danger">*</span></label>
                                    <ValidationProvider vid="center_id" name="Trung tâm" rules="required"
                                                        v-slot="{ errors,classes }">
                                        <multiselect
                                                v-model="cacheCenter"
                                                :options="centers"
                                                placeholder="Chọn Trung tâm"
                                                label="name"
                                                track-by="name"
                                                @input="setStateCenter($event)"
                                                :class="classes"
                                        ></multiselect>
                                        <div class="fv-plugins-message-container">
                                            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group">
                                    <label>Chi nhánh học
                                        <span class="text-danger">*</span></label>
                                    <ValidationProvider vid="branch_id" name="Chi nhánh" rules="required"
                                                        v-slot="{ errors,classes }">
                                        <multiselect
                                                v-model="cacheBranch"
                                                :options="branches"
                                                placeholder="Chi nhánh học"
                                                label="name"
                                                track-by="name"
                                                @input="setStateBranch($event)"
                                                :class="classes"
                                        ></multiselect>
                                        <div class="fv-plugins-message-container">
                                            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <button type="button" class="btn btn-primary mr-2" @click="amountIn">Nạp tiền
                                    </button>
                                    <button type="button" class="btn btn-danger" @click="amountOut">Rút tiền</button>
                                </div>
                            </div>

                        </div>
                    </form>
                </ValidationObserver>

            </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import Multiselect from 'vue-multiselect';
    import SelectCustomer from "@/view/content/SelectCustomer";
    import ContractForm from "@/view/pages/business/ContractForm";
    import {GET_USER} from '@/core/services/ajaxUrl';
    import {GET_USER_BY_ID} from "../../../core/services/store/user/users.module";
    import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
    import {AMOUNT_IN_OUT} from "../../../core/services/store/contract/studentAmount.module";
    import {Money} from 'v-money'

    export default {
        name: "NapRut197",
        data() {
            return {
                date197: '2021-07-19',
                user: {},
                show_detail: false,
                isDebt: false,
                contract_id: '',
                showModalCourseLevel: false,
                cacheCenter: [],
                cacheBranch: [],
                centers: [],
                branches: [],
              hinh_thuc_thanh_toan: '',
              hinh_thuc_thanh_toans: [{
                name: 'Tiền mặt',
              }, {
                name: 'Chuyển khoản'
              }],
                student_amount: {
                    user_id: '',
                    center_id: '',
                    branch_id: '',
                    refund_id: '',
                    nguoi_quyet_toan: '',
                    center_user_id: '',
                    ben_thu_tien: '',
                    contract_id: '',
                    is_internal: '',
                    parent_log_id: '',
                    nguoi_gt_id: '',
                    chinhanh_gt_id: '',
                    trungtam_gt_id: '',
                    khuvuc_gt_id: '',
                    amount: '',
                    cash_before: '',
                    old_center: '',
                    levelup_coefficient: '',
                    business_coefficient: '',
                    note: '',
                    gift: '',
                    dated_paid: '',
                    ngay_hoan_quyen_loi: '',
                    error_correction_time: '',
                    create_user: '',
                    is_new: '',
                    status: '',
                    is_refund: '',
                    type_refund: '',
                    quyet_toan: '',
                    type: '',
                    old: '',
                    is_recharge: true
                },
                money: {
                    decimal: ',',
                    thousands: ',',
                    prefix: '',
                    suffix: ' VNĐ',
                    precision: 0,
                    masked: false,
                }
            };
        },
        components: {
            Multiselect, SelectCustomer, ContractForm, Money
        },
        computed: {
            getUserUrl: function () {
                return GET_USER
            },
            total_pages() {
                return 1;
            }
        },
        mounted() {
            this.getCenters();
            this.getBranches();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: 'Quản lý tài chính'},
                {title: 'Nạp rút tiền trước 19-7'},
            ])
        },
        methods: {
            amountIn() {
                this.student_amount.is_recharge = true;
                this.student_amount.type = this.isDebt ? 3 : 1;
                this.student_amount.hinh_thuc_thanh_toan = this.hinh_thuc_thanh_toan;
                this.$store
                    .dispatch(AMOUNT_IN_OUT, this.student_amount)
                    .then((res) => {
                        if (!res.error) {
                            this.user = res.data
                            this.$bvToast.toast(res.message, {
                                title: 'Nạp tiền',
                                variant: 'success',
                                solid: true
                            });
                        } else {
                            this.$bvToast.toast(res.message, {
                                title: 'Nạp tiền',
                                variant: 'danger',
                                solid: true
                            });
                        }
                    }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                });
            },
            amountOut() {
                if (this.isDebt){
                  this.$bvToast.toast('Rút tiền không tích nợ!', {
                    title: 'Rút tiền',
                    variant: 'warning',
                    solid: true
                  });
                  return;
                }
                this.student_amount.is_recharge = false;
                this.student_amount.type = 5;
                this.student_amount.hinh_thuc_thanh_toan = this.hinh_thuc_thanh_toan;
                this.$store
                    .dispatch(AMOUNT_IN_OUT, this.student_amount)
                    .then((res) => {
                        if (!res.error) {
                            this.user = res.data
                            this.$bvToast.toast(res.message, {
                                title: 'Rút tiền',
                                variant: 'success',
                                solid: true
                            });
                        } else {
                            this.$bvToast.toast(res.message, {
                                title: 'Rút tiền',
                                variant: 'danger',
                                solid: true
                            });
                        }
                    }).catch((e) => {
                    if (e.data.error) {
                        if (e.data.data.message_validate_form) {
                            this.$refs.form.setErrors(e.data.data.message_validate_form);
                        }
                    }
                });
            },
            selectCustomer: function ($event) {
                this.resetStudentAmount()
                this.getUserById($event)
            },
            messageNotFound() {
                this.$bvToast.toast('Học viên không tồn tại hoặc chỉ áp dụng cho học viên đã đóng tiền trước ngày 19/7/2021', {
                    title: 'Tìm học viên',
                    variant: 'danger',
                    solid: true
                });
            },
            getUserById(id) {
                this.$store.dispatch(GET_USER_BY_ID, id).then((res) => {
                    if (!res.error) {
                        this.user = res.data
                        if (this.user.id != undefined) {
                            this.student_amount.user_id = this.user.id
                        }
                        this.show_detail = true
                    }
                });
            },
            resetStudentAmount() {
                this.student_amount = {
                    user_id: '',
                    center_id: '',
                    branch_id: '',
                    refund_id: '',
                    nguoi_quyet_toan: '',
                    center_user_id: '',
                    ben_thu_tien: '',
                    contract_id: '',
                    is_internal: '',
                    parent_log_id: '',
                    nguoi_gt_id: '',
                    chinhanh_gt_id: '',
                    trungtam_gt_id: '',
                    khuvuc_gt_id: '',
                    amount: '',
                    cash_before: '',
                    old_center: '',
                    levelup_coefficient: '',
                    business_coefficient: '',
                    note: '',
                    gift: '',
                    dated_paid: '',
                    ngay_hoan_quyen_loi: '',
                    error_correction_time: '',
                    create_user: '',
                    is_new: '',
                    status: '',
                    is_refund: '',
                    type_refund: '',
                    quyet_toan: '',
                    type: '',
                    old: '',
                    is_recharge: true
                }
            },
            getCenters() {
                this.$store.dispatch(GET_CENTERS, {
                    limit: 10000,
                    status: 1
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getBranches() {
                this.$store.dispatch(GET_BRANCHES, {
                    limit: 10000,
                    center_id: this.student_amount.center_id
                }).then((res) => {
                    if (!res.error) {
                        this.branches = res.data.data
                    }
                    0
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            /*Set state*/
            setStateCenter($event) {
                let id = $event.id
                this.student_amount.center_id = id
                this.cacheBranch = []
                this.student_amount.branch_id = ''
                this.getBranches()
            },
            setStateBranch($event) {
                let id = $event.id
                this.student_amount.branch_id = id
            },
            showDetail: async function (id) {
                this.contract_id = id;
                this.showModalCourseLevel = true;
            },
        },
    }
</script>

<style>
    .input-select2 {
        width: 100% !important;
    }

    .mx-datepicker {
        width: 100% !important;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
