<template>
  <!--begin::Card-->
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title ">
            <h3 class="card-label">
              Tạo rating ảo
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{name: 'review-list'}"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <ValidationProvider name="Tên trung tâm" vid="center" rules="required" v-slot="{ errors,classes }">
                  <label>Chọn trung tâm <span class="text-danger">*</span></label>
                  <multiselect v-model="cache_center" :options="centers"
                               placeholder="Chọn trung tâm"
                               label="name"
                               track-by="name"
                               :class="classes"
                               @input="selectCenter($event)"
                  ></multiselect>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Chọn chi nhánh <span class="text-danger">*</span></label>
                <ValidationProvider name="Tên chi nhánh" vid="branch" rules="required" v-slot="{ errors,classes }">
                  <multiselect v-model="cache_branch" :options="branches"
                               placeholder="Chọn chi nhánh"
                               label="name"
                               track-by="name"
                               :class="classes"
                               @input="selectBranch($event)"
                  ></multiselect>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <ValidationProvider name="Tên khóa học" vid="course" rules="required" v-slot="{ errors,classes }">
                  <label>Chọn khóa học <span class="text-danger">*</span></label>
                  <multiselect v-model="cache_course" :options="courses"
                               placeholder="Chọn khóa học"
                               label="name"
                               track-by="name"
                               :class="classes"
                               @input="selectCourse($event)"
                  ></multiselect>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <ValidationProvider name="Tên lớp" vid="class" rules="required" v-slot="{ errors,classes }">
                  <label>Chọn lớp học <span class="text-danger">*</span></label>
                  <multiselect v-model="cache_class" :options="center_classes"
                               placeholder="Chọn lớp học"
                               label="name"
                               track-by="name"
                               :class="classes"
                               @input="selectClass($event)"
                  ></multiselect>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Chọn nhân sự cho rating ảo
                  <span class="text-danger"></span></label>
                <multiselect
                    v-model="cache_user"
                    placeholder="Nhập mã nhân sự hoặc email..."
                    id="ajax"
                    label="name"
                    :options="users"
                    :loading="isLoading"
                    :internal-search="false"
                    @search-change="searchUserByCode"
                    @input="selectUser"
                >
                </multiselect>
              </div>
              <div class="form-group">
                <label>Hoặc nhập tên học viên ảo
                  <span class="text-danger"></span></label>
                <ValidationProvider name="Tên học viên" rules="min:6" v-slot="{ errors,classes }">
                <input type="text" name="custom_user_name"
                       placeholder="Nhập tên"
                       class="form-control"
                       v-on:input="enterName"
                       v-model="review.custom_user_name"
                       :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group" v-if="is_show">
                <label>Chọn ảnh mặc định theo giới tính
                  <span class="text-danger"></span></label>
                <multiselect v-model="cache_gender" :options="gender"
                             label="name"
                             :searchable="true"
                             :close-on-select="true"
                             :show-labels="true"
                             placeholder="Chọn giới tính"
                             @input="selectGender($event)"
                ></multiselect>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row d-flex justify-content-center">
                <div class="">
                  <div class="image-input image-input-outline" id="kt_profile_avatar">
                    <div
                        class="image-input-wrapper"
                        :style="{ backgroundImage: `url(${photo})` }"
                    ></div>
                    <label
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Change avatar"
                        v-if="is_show"
                    >
                      <i class="fa fa-pen icon-sm text-muted" ></i>
                      <input
                          type="file"
                          name="profile_avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="onFileChange($event)"
                      />
                      <input type="hidden" name="profile_avatar_remove" />
                    </label>
                    <span
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="cancel"
                        data-toggle="tooltip"
                        title="Cancel avatar"
                    >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                    <span
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title="Remove avatar"
                        @click="current_photo = null"
                        v-if="is_show"
                    >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                  </div>
                  <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group d-flex justify-content-center">
                <v-rating
                    v-model="review.rating"
                    background-color="grey lighten-1"
                    color="warning"
                    large
                    class="create-rating"
                    @input="selectRating($event)"
                ></v-rating>
              </div>
              <!--end::Form-->
            </div>

          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Bình luận
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Bình luận" rules="required|min:10|max:1000" v-slot="{ errors,classes }">
                  <textarea class="form-control" rows="2" v-model="review.comment"></textarea>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <!--end::Form-->
            </div>
          </div>
          <div class="row mt-6">
            <div class="form-group col-md-12">
              <label>Ảnh cho bình luận(tối đa 4 ảnh)</label>
              <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="col-form-label checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input type="checkbox" name="Checkboxes5"  v-model="review.status">
                  <span></span>Hiển thị</label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <button type="submit" class="btn btn-success mr-2">
              Tạo mới
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
  <!--end::Card-->
</template>
<script>
import Multiselect from 'vue-multiselect'
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
import {DELETE_BRANCH, GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import {CREATE_REVIEW, GET_DEFAULT_AVATAR} from "../../../core/services/store/review/review.module";
import {GET_CLASSES} from "../../../core/services/store/course/classes.module";
import {GET_COURSES} from "../../../core/services/store/course/course.module";
import {GET_USERS, SEARCH_BY_MA_CAP_TREN} from "../../../core/services/store/user/users.module";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {GET_TEACHERS} from "../../../core/services/store/teacher/teacher.module";
import Swal from "sweetalert2";
const _ = require('lodash');

export default {
  name: "Create-Review",
  components: {
    Multiselect, vueDropzone: vue2Dropzone
  },
  data() {
    return {
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      is_show : false,
      review : {
        rating : 5,
        comment : '',
        custom_avatar : '',
        class_id : '',
        center_id : '',
        course_id : '',
        branch_id : '',
        teacher_id : '',
        status: '',
        is_fake: 1,
        custom_user_name : '',
        use_default_avatar : '',
      },
      user_name: '',
      isLoading : false,
      /*Data default*/
      status: {
        0: 'Không hoạt động',
        1: 'Đang hoạt động',
        2: "Nghỉ việc"
      },
      gender: [
        {
          name : 'Nam',
          avatar : ''
        },
        {
          name : 'Nữ',
          avatar : ''
        },
      ],
      /*options */
      users: [],
      centers: [],
      branches : [],
      courses : [],
      center_classes: [],
      default_avatars : [],
      teachers : [],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        // thumbnailWidth: 200,
        maxFilesize: 0.5,
        maxFiles : 4,
        thumbnailMethod: 'contain',
        addRemoveLinks: true,
        autoProcessQueue: false,
        headers: {"My-Awesome-Header": "header value"},
        init: function () {
          this.on("complete", function (file) {
            if (!file.accepted) {
              file.previewElement.remove();
              Swal.fire("File có dung lượng quá lớn", "", "error");
            }
          });
          this.on("maxfilesexceeded", function(file){
            file.previewElement.remove();
            Swal.fire("Vượt quá số lượng file quy định", "", "error");
          });
        }
      },
      /* custom variable */
      branchesByCenter : [],
      coursesByCenter : [],
      classesByCourse : [],
      /*photo*/
      default_photo: "media/users/blank.png",
      current_photo: null,
      image_name: null,
      image_type: null,
      /*Cache */
      cache_center: '',
      cache_branch: '',
      cache_course : '',
      cache_class : '',
      cache_gender : '',
      cache_user : '',
      cache_teacher : '',
      /*Payload */
      payload : {
        limit : 100000,
        status : 1,
        is_staff : 1
      }
    };
  },
  computed: {
    photo() {
      return this.current_photo == null
          ? this.default_photo
          : this.current_photo;
    },
  },
  mounted() {
    this.getCenters();
    this.getBranches();
    this.getCourses();
    this.getClasses();
    this.getUsers();
    this.getTeachers();
    this.getDefaultAvatar();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý nhân sự"},
      {title: "Tạo rating ảo"}
    ]);
  },
  methods: {
    getCenters : function (){
      this.$store.dispatch(GET_CENTERS,this.payload).then((res) => {
        this.centers = res.data.data;
      });
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES,{limit: 10000}).then((res)=>{
        this.branches = res.data.data;
        this.branchesByCenter = res.data.data;
      });
    },
    getCourses() {
      this.$store.dispatch(GET_COURSES,this.payload).then((res)=>{
        this.courses = res.data.data;
        this.coursesByCenter = res.data.data;
      });
    },
    getClasses() {
      this.$store.dispatch(GET_CLASSES,this.payload).then((res)=>{
        this.center_classes = res.data.data;
        this.classesByCourse = res.data.data;
      });
    },
    getUsers() {
      this.$store.dispatch(GET_USERS,this.payload).then((res)=>{
        this.users = res.data.data;
      });
    },
     getTeachers() {
       this.$store.dispatch(GET_TEACHERS,{limit: 10000, status: 1, is_staff: 2}).then((res)=>{
        this.teachers = res.data.data;
      });
    },
    getDefaultAvatar : function (){
      this.$store.dispatch(GET_DEFAULT_AVATAR).then((res) =>{
        this.gender = res.data;
      });
    },
    searchUserByCode : _.debounce (function (query){
      this.isLoading = true;
      this.$store.dispatch(SEARCH_BY_MA_CAP_TREN,{
        search_boss: query,
        limit: 100,
        status : 1,
        is_staff : 0
      }).then((res) => {
        this.isLoading = false;
        if (!res.error) {
          this.users = res.data.data
          this.isLoadingBoss = false;
        }
      })
    },300),
    selectUser : function (event){
      this.review.user_id = event != null ? event.id : 0;
      this.current_photo = event != null ? event.image : '';
      this.review.custom_user_name = '';
      this.review.custom_avatar =  event != null ? event.image : '';
      this.is_show = false;
    },
    selectGender : function ($event){
      this.current_photo = $event != null ? $event.avatar : '';
      this.review.custom_avatar = $event != null ? $event.avatar : '';
      this.review.use_default_avatar = 0;
    },
    selectCenter : function ($event){
      let center_id = $event != null ? $event.id : '';
      this.review.center_id = center_id;
      if (center_id !== ''){
        this.branches = _.reduce(this.branchesByCenter, function (result,value,key){
          if (center_id === value.center_id){
            result.push(value);
          }
          return result;
        },[]);
        this.courses = _.reduce(this.coursesByCenter,function (result,value,key){
          if (center_id === value.center_id){
            result.push(value);
          }
          return result;
        },[]);
      }else{
        this.branches = this.branchesByCenter;
        this.courses = this.coursesByCenter;
      }
      this.cache_branch = '';
      this.cache_course = '';
    },
    selectBranch: function ($event){
      this.review.branch_id = $event != null ? $event.id : '';
    },
    selectCourse : function ($event){
      let course_id = $event != null ? $event.id : '';
      this.review.course_id = course_id;
      if (course_id !== ''){
        this.center_classes = _.reduce(this.classesByCourse, function (result,value,key){
          if (course_id === value.course_id){
            result.push(value);
          }
          return result;
        },[]);
      }else{
        this.center_classes = this.classesByCourse;
      }
      this.cache_class = '';
    },
    selectClass : function ($event){
      this.review.class_id = $event != null ? $event.id : '';
    },
    selectTeacher : function ($event){
      this.review.teacher_id = $event != null ? $event.id : '';
    },
    selectRating : function ($event){
      this.review.rating = parseInt($event);
    },
    enterName : function (){
      this.is_show = true;
      this.cache_user = '';
      this.review.user_id = 0;
    },
    onFileChange(e) {
      this.review.use_default_avatar = 1;
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
        this.image_name = file.name;
        this.image_type = file.type;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    async onSubmit() {
      let formData = new FormData();
      formData.append('rating',this.review.rating);
      formData.append('comment',this.review.comment);
      formData.append('user_id',this.review.user_id);
      formData.append('branch_id',this.review.branch_id);
      formData.append('teacher_id',this.review.teacher_id);
      formData.append('center_id',this.review.center_id);
      formData.append('class_id',this.review.class_id);
      formData.append('course_id',this.review.course_id);
      formData.append('custom_avatar',this.review.custom_avatar);
      formData.append('custom_user_name',this.review.custom_user_name);
      formData.append('use_default_avatar',this.review.use_default_avatar);
      formData.append('status',this.review.status == true ? 1 : 0);
      formData.append('is_fake',this.review.is_fake);
      let avatar = this.photo;
      function urltoFile(url, filename, mimeType) {
        return (fetch(url)
                .then(function (res) {
                  return res.arrayBuffer();
                })
                .then(function (buf) {
                  return new File([buf], filename, {type: mimeType});
                }).catch((error) => {
                  return 1;
                })
        );
      }
      if (avatar.search('https') === -1) {
        let file = await urltoFile(avatar, this.image_name, this.image_type);
        if (file !== 1 && file.name !== 'null') {
          formData.append('avatar', file);
        }
      }
      //đăng multi ảnh
      let ins = this.$refs.myVueDropzone.dropzone.files.length;
      for (var x = 0; x < ins; x++) {
        formData.append("thumbnail[]", this.$refs.myVueDropzone.dropzone.files[x]);
      }
      //dispatch action
      this.$store.dispatch(CREATE_REVIEW, formData ).then((res) => {
        if (!res.error) {
          this.$router.push({name: "review-list"}).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Tạo rating ảo',
              variant: 'success',
              solid: true
            });
          })
        } else {
          this.$bvToast.toast(res.message, {
            title: 'Tạo rating ảo',
            variant: 'danger',
            solid: true
          });
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      });
    },
  },
};
</script>
<style>
.theme--light.v-icon {
  color: #ffa800 !important;
}
.dz-progress {
  /* progress bar covers file name */
  display: none !important;
}
.dz-remove {
  z-index: 999999 !important;
  top: 0 !important;
  right: 0 !important;
  height: 34px !important;
  background: #be2626 !important;
}
.create-rating .v-icon{
  font-size: 36px !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

