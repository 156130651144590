<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Hồ sơ</h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">Cập nhật thông tin của bạn</span>
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
          :class="{'spinner spinner-light spinner-right': check_spinner }"
        >
          Cập nhật
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="update_profile">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Thông tin chung</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Ảnh đại diện</label>
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div
                class="image-input-wrapper"
                :style="{ backgroundImage: `url(${photo})` }"
              ></div>
              <label
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
                @click="toggleShow"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
<!--                <input-->
<!--                  type="file"-->
<!--                  name="profile_avatar"-->
<!--                  accept=".png, .jpg, .jpeg"-->
<!--                  @change="onFileChange($event)"-->
<!--                />-->
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <my-upload field="img"
                         @crop-success="cropSuccess"
                         @crop-upload-success="cropUploadSuccess"
                         @crop-upload-fail="cropUploadFail"
                         @src-file-set="srcFileSet"
                         v-model="show"
                         :width="300"
                         :height="300"
                         langType="en"
                         :params="params"
                         :headers="headers"
                         img-format="png"></my-upload>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel"
                data-toggle="tooltip"
                title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
<!--              <span-->
<!--                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"-->
<!--                data-action="remove"-->
<!--                data-toggle="tooltip"-->
<!--                title="Remove avatar"-->
<!--                @click="current_photo = null"-->
<!--              >-->
<!--                <i class="ki ki-bold-close icon-xs text-muted"></i>-->
<!--              </span>-->
            </div>
<!--            <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>-->
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Họ và tên</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="name"
              name="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="user.name"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Ngày sinh</label>
          <div class="col-lg-9 col-xl-6">
            <date-picker format="DD-MM-YYYY" v-model="user.birthday" valueType="YYYY-MM-DD" class="custom_date" name="birthday"></date-picker>
          </div>
        </div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Thông tin liên hệ</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Số điện thoại</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input
                name="phone"
                ref="phone"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Phone"
                v-model="user.phone"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Email</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                name="email"
                ref="email"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                v-model="user.email"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Địa chỉ</label>
          <div class="col-lg-9 col-xl-6">
            <input
              name="address"
              ref="address"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="user.address"
            />
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import { USER_PROFILE, UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import myUpload from "vue-image-crop-upload/upload-2.vue";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import '@/assets/sass/custom/custom_profile.scss';

export default {
  name: "PersonalInformation",
  components: {
      DatePicker,'my-upload': myUpload
  },
    props: ['userProfile'],
  data() {
      return {
        show: false,
        params: {
          token: '123456798',
          name: 'avatar'
        },
        headers: {
          smail: '*_~'
        },
        imgDataUrl: '',
          default_photo: "media/users/blank.png",
          current_photo: null,
          image_name: null,
          image_type: null,
          check_spinner: false,
          user: {
              name: this.userProfile.data.name,
              email:this.userProfile.data.email,
              phone:this.userProfile.data.phone,
              birthday:this.userProfile.data.profile.birthday,
              address:this.userProfile.data.profile.address,
          }
      };
  },
  mounted() {
      const update_profile = KTUtil.getById("update_profile");
      this.fv = formValidation(update_profile, {
          fields: {
              name: {
                  validators: {
                      notEmpty: {
                          message: "Bắt buộc nhập họ tên"
                      },
                      blank: {
                          message: ""
                      }
                  }
              },
              phone: {
                  validators: {
                      stringLength: {
                          max: 10,
                          min: 10,
                          message: 'Số điện thoại phải có 10 chữ số'
                      },
                      blank: {
                          message: ""
                      }
                  }
              },
              email: {
                  validators: {
                      notEmpty: {
                          message: "Bắt buộc nhập email"
                      },
                      emailAddress: {
                          message: "Email không đúng"
                      },
                      blank: {
                          message: ""
                      }
                  }
              },
          },
          plugins: {
              trigger: new Trigger(),
              bootstrap: new Bootstrap(),
              submitButton: new SubmitButton()
          }
      });
  },
  methods: {
    srcFileSet(fileName, fileType, fileSize){
      this.image_name = fileName;
      this.image_type = fileType;
    },
    toggleShow() {
      this.show = !this.show;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field){
      this.current_photo = imgDataUrl;
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field){
      console.log('-------- upload success --------');
      console.log(jsonData);
      console.log('field: ' + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field){
      console.log('-------- upload fail --------');
      console.log(status);
      console.log('field: ' + field);
    },
       save() {
           this.fv.validate().then( async (status) => {
              if (status == 'Valid') {
                  var avatar = this.photo;
                  this.check_spinner = true;
                  function urltoFile(url, filename, mimeType) {
                      return (fetch(url)
                          .then(function (res) {
                              return res.arrayBuffer();
                          })
                          .then(function (buf) {
                              return new File([buf], filename, {type: mimeType});
                          }).catch((error) => {
                              return 1;
                          })
                      );
                  }
                  let formData = new FormData();
                  formData.append('phone', this.user.phone);
                  formData.append('email', this.user.email);
                  formData.append('name', this.user.name);
                  formData.append('birthday', this.user.birthday);
                  formData.append('address', this.user.address);

                  if (avatar.search('https') == -1) {
                    console.log(avatar);
                      let file = await urltoFile(avatar, this.image_name, this.image_type);
                      if (file != 1) {
                          formData.append('avatar', file);
                      }
                  }
                  this.$store.dispatch(UPDATE_PERSONAL_INFO, formData).then((rp) => {
                      this.check_spinner = false;
                      this.$router.push({name: "dashboard"}).then(() => {
                          this.$bvToast.toast(rp.message,{
                              title: 'Thành công',
                              variant: 'success',
                              solid: true
                          });
                      });
                  }).catch((rp) => {
                      this.check_spinner = false;
                  });
                  setTimeout(() => {
                      _.forEach(this.errors, (value, key) => {
                          this.fv.updateValidatorOption(key, 'blank', 'message', value.length == 1 ? value[0] : value)
                              .updateFieldStatus(key, 'Invalid', 'blank');
                      });
                      this.check_spinner = false;
                  },500);
              }
          });
      },
      cancel() {
          this.user.name = this.userProfile.data.name;
          this.user.phone = this.userProfile.data.phone;
          this.user.email = this.userProfile.data.email;
          this.user.address = this.userProfile.data.profile.address;
          this.user.birthday = this.userProfile.data.profile.birthday;
          this.current_photo = this.userProfile.data.avatar;
      },
      onFileChange(e) {
          const file = e.target.files[0];
          if (typeof FileReader === "function") {
              const reader = new FileReader();
              reader.onload = event => {
                this.current_photo = event.target.result;
              };
              reader.readAsDataURL(file);
              this.image_name = file.name;
              this.image_type = file.type;
          } else {
              alert("Sorry, FileReader API not supported");
          }
      }
  },
  computed: {
      ...mapState({
          errors: state => state.profile.errors
      }),
      photo() {
          return this.current_photo == null
          ? this.default_photo
          : this.current_photo;
      }
  },
  watch: {
    userProfile(newValue, oldValue) {
      if(oldValue!==newValue){
          this.current_photo = newValue.data.avatar;
          this.user.name = newValue.data.name;
          this.user.birthday = newValue.data.profile.birthday;
          this.user.email = newValue.data.email;
          this.user.phone = newValue.data.phone;
          this.user.address = newValue.data.profile.address;
      }
    },
  },
};
</script>
