export class HonorModel {
    constructor(id, name, date, description, status) {
        this.id = id;
        this.name = name;
        this.date = date;
        this.description = description;
        this.status = this.checkStatus(status);
    }
    checkStatus(status) {
        return status !== 0;
    }
}

export class HonorCategoryModel {
    constructor(id, category, typeCategory, description) {
        this.id = id;
        this.category = category;
        this.type = typeCategory;
        this.description = description;
        this.type_category_name = this.checkTypeCategory(typeCategory);
    }

    checkTypeCategory(category) {
        switch (category) {
            case 0:
                return 'Cá nhân';
            case 1:
                return 'OKR';
            case 2:
                return 'Phòng ban';
        }
    }
}

export class HonorDetailModel {
    constructor(id, name, nameCategory, like, share, comment, content, description,  apartment, title, attach_video_image_files, media_ids, category_honor) {
        this.id = id;
        this.name = name;
        this.category = nameCategory;
        this.like = like;
        this.share = share;
        this.comment = comment;
        this.content = content;
        this.description = description;
        this.content = content;
        this.apartment = apartment;
        this.title = title;
        this.attach_video_image_files = attach_video_image_files;
        this.media_ids = media_ids;
        this.category_honor = category_honor;
    }
}

const honorStatus = [
    {
        name: 'Hiển thị',
        value: 1,
    },
    {
        name: 'Không hiển thị',
        value: 0,
    },
]

const typeOptionCategory = [
    {
        name: 'Cá nhân',
        value: 0,
    },
    {
        name: 'OKR',
        value: 1,
    },
    {
        name: 'Phòng ban',
        value: 2,
    },
]

export {
    honorStatus,
    typeOptionCategory,
}
