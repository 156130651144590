<template>
  <b-modal @hide="onHide" size="xl" :centered="true" :scrollable="true" ref="modal-benefit-detail"
           hide-footer :title="title" class="modal-benefit-detail">
    <div class="d-block text-center">
      <div class="row">
        <div class="col-md-12">
          <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'bottom',
                dropdownAllowAll: true,
                setCurrentPage: 1,
                nextLabel: 'Sau',
                prevLabel: 'Trước',
                rowsPerPageLabel: 'Số bản ghi',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'Tất cả',
              }"
          />
        </div>
      </div>
    </div>
    <footer class="modal-footer">
      <button type="button" class="btn btn-secondary" @click="hideModal">Hủy bỏ</button>
    </footer>
  </b-modal>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';

const _ = require('lodash');
export default {
  name: "ContractDetailTable",
  props: {
    showModal: {default: false},
    isBaoCaoChiNhanh: {
      type: Boolean,
      default() {
        return false;
      }
    },
    typeAction: {default: ''},
    contractData: {
      type: [Array, Object],
      default() {
        return [];
      }
    },
  },
  data() {
    return {
      employeeRegisters: [],
      rows: [],
      title: '',
      query: {
        stt: '',
        user_id: '',
        user_name: '',
        morning: '',
        afternoon: '',
        evening: '',
        center_name: ''
      },
      columns: [
        {
          label: 'STT',
          field: 'stt',
          tdClass: 'text-center',
        },
        {
          label: 'Mã học viên',
          field: 'user_id',
          tdClass: 'text-center',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Tìm kiếm theo mã hv', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            trigger: '', //only trigger on enter not on keyup
          },
        },
        {
          label: 'Số tiền',
          field: 'amount_coefficient',
          html: true,
          tdClass: 'text-center',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Tìm kiếm theo số tiền', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            trigger: '', //only trigger on enter not on keyup
          },
        },
        {
          label: 'Người giới thiệu',
          field: 'sale_intro',
          html: true,
          tdClass: 'text-center',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Tìm kiếm theo NGT', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            trigger: '', //only trigger on enter not on keyup
          },
        },
        {
          label: 'Đội SL',
          field: 'leader',
          html: true,
          tdClass: 'text-center',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Tìm kiếm theo leader', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            trigger: '', //only trigger on enter not on keyup
          },
        },
        {
          label: 'Văn Phòng',
          field: 'branch_name',
          tdClass: 'text-center',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Tìm kiếm văn phòng', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            trigger: '', //only trigger on enter not on keyup
          },
        },
      ],
    }
  },
  components: {VueGoodTable},
  computed: {},
  methods: {
    hideModal: function () {
      this.$refs['modal-benefit-detail'].hide();
    },
    onHide: function (evt) {
      this.$emit("clicked", true);
    },
    toggleModal() {
      // when the modal has hidden
      this.$refs['modal-benefit-detail'].toggle('#toggle-btn');
    },
  },
  watch: {
    showModal() {

      if (this.showModal) {
        this.$refs['modal-benefit-detail'].show();
        // set các giá trị cho việc hiển thị
        switch (this.typeAction) {
          case  'quyen_loi' :
          this.title = 'Danh sách quyền lợi';
          break;
          case  'hop_dong_no' :
          this.title = 'Danh sách hợp đồng nợ';
          break;
          case  'hop_dong_moi' :
          this.title = 'Danh sách hợp đồng mới';
          break;
          case  'doanh_so_moi' :
          this.title = 'Doanh số Khách hàng mới';
          break;
          case  'hop_dong_noi_bo' :
          this.title = 'Danh sách hợp đồng nội bộ';
          break;
          case  'hoan_tien' :
          this.title = 'Danh sách hoàn tiền';
          break;
          case  'rut_tien' :
          this.title = 'Danh sách rút tiền';
          break;
        }
        let isBaoCaoChiNhanh = this.isBaoCaoChiNhanh;
        this.rows = _.reduce(this.contractData, function (result, item, key) {
          /* Nếu là báo cáo văn phòng thì tiền k nhân hệ số */
          let amount = isBaoCaoChiNhanh ? item.amount : item.amount_coefficient;
          let data = {
            stt: ++key,
            user_id: item.user_id + 100000,
            amount_coefficient: ( new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'VND' }).format(Math.abs(amount)) ),
            sale_intro: item.sale ? (item.sale.id + 100000) + ' - ' + item.sale.name : '',
            leader: item.leader,
            branch_name: item.intro_branch ? (item.intro_branch.name_alias ? item.intro_branch.name_alias : item.intro_branch.name) : '',
          }
          result.push(data);
          return result;
        }, []);
      }
    },
  }
}
</script>

<style scoped>

</style>
