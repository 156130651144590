<template>
  <div class="container">
        <el-form :model="formData" ref="rateForm" :rules="rules" label-position="top" label-width="100px">
          <el-form-item label="Trạng thái:">
            <div class="title">
              <p class="badge badge-info">{{ STATUS[ticket.status] }}</p>
            </div>
          </el-form-item>
            <el-form-item label="Nội dung:" prop="reason">
              <el-input type="textarea"
                        maxlength="3000"
                        show-word-limit
                        placeholder="Nhập nội dung tại đây..."
                        class="mt-2"
                        :rows="5"
                        v-model="formData.reason">

              </el-input>
            </el-form-item>
          <el-form-item label="Ảnh/video:">
            <upload-file @emitUpdateFile="uploadFileSuccess"></upload-file>
          </el-form-item>
          <div class="d-flex justify-content-center">
            <el-button :loading="loading" class="btn btn-primary" @click="onSubmit">Cập nhật</el-button>
          </div>
        </el-form>
  </div>
</template>

<script>
import UploadFile from "@/view/pages/feedback/UploadFile";
import ErrorMessage from "@/view/pages/common/ErrorMessage";
import {UPDATE_TICKET_FEEDBACK} from "@/core/services/store/ticket/ticket.module";
import {STATUS} from "@/core/option/ticketOption";
export default {
  name: "RateTicket",
  components: {ErrorMessage, UploadFile},
  props: {
    ticket: {
      type: Object,
      default: () => { return {} }
    }
  },
  data(){
    return{
      formData: {
        reason: '',
        files: []
      },
      rules: {
        reason: [
            { max: 3000, message: 'Nội dung không quá 3000 kí tự', trigger: 'blur' }
        ],
      },
      loading: false,
      STATUS,
    }
  },
  methods: {
    onSubmit(){
      this.$refs.rateForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let ticket_id = this.$route.params.id;
          let formData = new FormData();
          for (let formDataKey in this.formData) {
            if (formDataKey === 'files'){
              for (let i = 0; i < this.formData.files.length; i++) {
                formData.append('fileList[]', this.formData.files[i].raw);
              }
            }
            formData.append(formDataKey, this.formData[formDataKey]);
          }
          formData.append('ticket_id', ticket_id);
          formData.append('type_update', 'Phòng ban xử lý');
          this.$store.dispatch(UPDATE_TICKET_FEEDBACK, formData).then((res) => {
            this.$emit('successUpdate', res.data);
            this.$notify({
              title: 'Thành công',
              message: 'Nhập giải pháp thành công',
              type: "success"
            })
          }).catch((e) => {
            this.$notify({
              title: 'Thất bại',
              message: e.data.message,
              type: "error"
            })
          }).finally(() => {
            this.loading = false;
          })
        } else {
          return false;
        }
      });
    },
    uploadFileSuccess(fileList) {
        this.formData.files = fileList;
    }
  }
}
</script>

<style scoped>

</style>