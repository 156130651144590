<template>
    <div>
        <b-modal @change="changeModal()" id="add-error-teaching" title="Thêm lỗi dạy học" size="lg" ok-only centered
            hide-footer>
            <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                <form class="form" autocomplete="off" @submit.prevent="handleSubmit(addNewErrorTeaching)">
                    <div v-loading="loadingShowinfo" class="row">
                        <div class="col-md-12 mt-2">
                            <label class="fs-1 font">Loại lỗi<span class="text-danger"> *</span></label>
                            <ValidationProvider vid="type_id" name="Loại lỗi" rules="required"
                                v-slot="{ errors, classes }">
                                <el-select :class="classes" class=" w-100 shadow-sm" placeholder="Chọn loại lỗi"
                                    v-model="form.type_id" collapse-tags filterable clearable>
                                    <el-option v-for="item in type_errors" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0]
                                        }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-6 mt-2">
                            <div class="form-group">
                                <label class="fs-1 font">Lớp học<span class="text-danger"> *</span></label>
                                <ValidationProvider vid="class_id" name="Lớp học" rules="required"
                                    v-slot="{ errors, classes }">
                                    <el-select @change="changeClass(form.class_id)" :class="classes"
                                        class=" w-100 shadow-sm" placeholder="Lớp học" v-model="form.class_id"
                                        collapse-tags filterable clearable>
                                        <el-option v-for="item in class_list" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
            errors[0]
        }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6 mt-2">
                            <div class="form-group">
                                <label class="fs-1 font">Buổi học<span class="text-danger"> *</span></label>
                                <ValidationProvider vid="lesson" name="Buổi học" rules="required"
                                    v-slot="{ errors, classes }">
                                    <el-select :class="classes" class=" w-100 shadow-sm" placeholder="Buổi học"
                                        v-model="form.lesson" collapse-tags filterable clearable>
                                        <el-option v-for="item in lesson_list" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
            errors[0]
        }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <el-button :disabled="loading_create" size="small" class="float-right text-white rounded"
                            style="background-color: rgba(93, 4, 176, 1);" native-type="submit">
                            <i v-bind:class="[loading_create ? 'el-icon-loading' : '']"></i>
                            Xác nhận
                        </el-button>
                    </div>
                    <div>
                        <el-button style="background-color: rgba(202, 202, 202, 1)" size="small"
                            class="float-right mr-3 rounded" @click="closeModal">
                            <i></i>
                            Huỷ bỏ
                        </el-button>
                    </div>
                </form>
            </ValidationObserver>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ADD_NEW_ERROR, GET_LIST_TYPE_ERROR } from "@/core/services/store/report-error/reportError.module";
import {
    CLASS_FILTER,
    SCHEDULE_CLASS_LESSON
} from "@/core/services/store/course/classes.module";

export default {
    name: "FormErrorTeaching",
    components: {},
    props: {
        centers: Array,
        teacher: Object,
        trainers: Array,
    },
    data() {
        return {
            errors: '',
            loading_create: false,
            form: {
                type_id: '',
                class_id: '',
                lesson: ''
            },
            loadingShowinfo: false,
            type_errors: [],
            lesson_list: [],
            class_list: [],
        }
    },
    mounted() {
        this.loadingShowinfo = true;
        this.getListTypeError();
        this.getClasses();
        this.loadingShowinfo = false;
    },
    computed: {
        ...mapGetters(["currentUser"]),
    },
    methods: {
        changeModal() {
            this.clearForm();
            this.getClasses();
        },
        clearForm() {
            this.form = {
                type_id: '',
                class_id: '',
                lesson: ''
            }
            this.lesson_list = [];
        },
        getScheduleLesson(class_id) {
            this.$store
                .dispatch(SCHEDULE_CLASS_LESSON, {
                    limit: 10000,
                    classroom_id: class_id
                })
                .then((res) => {
                    if (!res.error) {
                        this.lesson_list = res.data;
                    }
                })
                .catch((e) => {
                    console.log(e, "error");
                });
        },
        changeClass(class_id) {
            this.getScheduleLesson(class_id);
        },
        getClasses() {
            this.$store.dispatch(CLASS_FILTER, {is_now : true}).then((res) => {
                if (!res.error) {
                    this.class_list = res.data;
                }
            });
        },
        getListTypeError() {
            this.$store.dispatch(GET_LIST_TYPE_ERROR).then(res => {
                if (!res.error) {
                    this.type_errors = res.data;
                }
            }).catch(res => {
                console.log(res);
            });
        },

        addNewErrorTeaching() {
            this.loading_create = true;
            this.$store.dispatch(ADD_NEW_ERROR, (this.form)).then((res) => {
                this.$notify({
                    title: "Thành công",
                    message: "Thêm lỗi giảng dạy thành công",
                    type: "success",
                });
                this.loading_create = false;
                this.reloadTableError()
                this.closeModal();
            }).catch((e) => {
                this.$notify({
                    title: "Lỗi",
                    message: e.data.message ?? "Đã xảy ra lỗi khi thêm mới lỗi giảng dạy.",
                    type: "error",
                });
                if (e.data.data.message_validate_form) {
                    this.$refs.form.setErrors(e.data.data.message_validate_form);
                }
                this.loading_create = false;
            }).finally(() => {
                this.loading_create = false;
            });
        },

        closeModal() {
            this.$bvModal.hide('add-error-teaching');
        },

        reloadTableError() {
            this.$emit('reloadTableError');
        }
        
    }
}
</script>

<style>
#handle-candidate-teacher {
    top: -100px;
}

#handle-candidate-teacher .font {
    font-size: 14px;
    font-weight: 600;
    color: rgba(69, 70, 116, 1);
}
</style>