export const TYPE_RECRUITMENT_JOB = [
    {value: 1, label: 'Tin hot'},
    {value: 0, label: 'Tin thường'},
];

export const STATUS_RECRUITMENT_JOB = [
    {value: 1, label: 'Hiển thị'},
    {value: 0, label: 'Ẩn'},
];


export const TYPE_WORK_TIME_RECRUITMENT_JOB = [
    {value: 'Full-time', label: 'Full-time'},
    {value: 'Part-time', label: 'Part-time'},
    {value: 'Free-time', label: 'Free-time'},
];

export const GENDER = [
    {value: 'Nam', label: 'Nam'},
    {value: 'Nữ', label: 'Nữ'},
    {value: 'Không yêu cầu', label: 'Không yêu cầu'},
];

export const RECRUITMENT_JOB_CV_STATUS = [
    {value: 1, label: 'Chưa có CV'},
    {value: 2, label: 'Có CV'},
];