import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./../../router";
import Swal from "sweetalert2";
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css'
import store from "@/core/services/store";

/**
 * Service to call HTTP request via Axios
 */

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    // Add a request interceptor
    Vue.axios.interceptors.request.use(
      function (config) {
        if((config.hasOwnProperty('params')&&config.params.hasOwnProperty('show_progress'))
            ||(config.hasOwnProperty('data')&&config.data.hasOwnProperty('show_progress'))
        ){
          Nprogress.start();
        }
        Vue.axios.defaults.withCredentials = true;
        Vue.axios.defaults.credentials = "include";
        // config.headers.Authorization = `Bearer ${JwtService.getToken()} `;
        Vue.axios.defaults.headers['Accept'] = 'application/json';
        Vue.axios.defaults.headers['Content-Type'] = 'application/json';
        return config;
      },
      function (error) {
        Nprogress.done();
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    Vue.axios.interceptors.response.use(
      function (response) {
        Nprogress.done();
        return response;
      },
      function (error) {
        const { status } = error.response;
        if (status === 503) {
          if (localStorage.getItem("maintain") === null || localStorage.getItem("maintain") !== error.response.data.data) {
            localStorage.setItem("maintain", error.response.data.data);
          }
          router.push({ name: "maintain" });
        } else {
          if (localStorage.getItem("maintain") !== null) {
            localStorage.removeItem("maintain");
          }
        }
        if (status === 401) {
          Swal.fire({
            title: 'Phiên đăng nhập của bạn đã hết hạn',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: `Đồng ý`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            localStorage.removeItem("user");
            store.commit('logOut')
            router.push({ name: "login" });

          })
        }
        Nprogress.done();
        return Promise.reject(error);
      }
    );
  },

  // /**
  //  * Set the default HTTP request headers
  //  */
  // setHeader() {
  //   Vue.axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Bearer ${JwtService.getToken()} `;
  // },

  query(resource, params) {
    return Vue.axios.get(resource, {
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json','Content-Type': 'application/json',
      },
      params : params});
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`,{
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json','Content-Type': 'application/json',
      },
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json','Content-Type': 'application/json',
      },
    });
  },
  /**
   * Set the Upload HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  upload(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      withCredentials: true,
      credentials: "include",
    });
  },
  /**
   *
   * @param resource
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  login(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json','Content-Type': 'application/json',
      },
    });
  },
  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params,{
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json','Content-Type': 'application/json',
      },
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params,{
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json','Content-Type': 'application/json',
      },
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  delete(resource, slug = "") {
    return Vue.axios.delete(`${resource}/${slug}`,{
      withCredentials: true,
      credentials: "include",
      headers: {
        Accept: 'application/json','Content-Type': 'application/json',
      },
    });
  },
};

export default ApiService;
