<template>
  <b-modal id="warning-graded" ref="warning-graded" centered hide-footer title="Xác nhận chấm bài" size="md">
    <div>
      <p class="mb-1">Tác vụ Chấm General (Mã {{idTask}}) này đã được admin {{gradedUserName}} phụ trách.</p>
      <p>Bạn sẽ không thể lưu kết quả chấm bài nếu bài general này được admin khác thao tác trước.</p>
      <p style="color: #5E00B6; font-weight: bold">Bạn có chắc chắn muốn tiếp tục?</p>
      <div class="d-flex justify-content-end mt-2">
        <button type="button" class="btn btn-secondary" @click="closeModal()">Đóng</button>
        <button type="button" class="btn btn-info ml-3" @click="gradTest()">Chấm bài</button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import router from "@/router";

export default {
  name: "ModalWarningNotInGrade",
  data(){
    return {
      idTask: this.$route.params.id
    }
  },
  props:{
    gradedUserName:{
      type: String,
      default(){
        return ''
      }
    },
    informationTask:{
      type: Object,
      default(){
        return {}
      }
    }
  },
  methods:{
    closeModal(){
      this.$refs['warning-graded'].hide()
    },
    gradTest(){
      let test_input_schedule_id = this.$route.params.id;
      router.push({
        name: 'mark-the-exam',
        params: {id: test_input_schedule_id},
        query: {typeMark: this.informationTask.taskItem, name: this.informationTask.customerName, phone: this.informationTask.customerPhone}
      })
    }
  }
}
</script>
<style scoped>

</style>