export const TYPE_MAY_CHAM_CONG = 0;
export const TYPE_APP = 1;
export const TYPE_LOCATION = 2;
export const TYPE_ADMIN = 3;

export const TYPES = [
    {label: 'Máy chấm công', value: TYPE_MAY_CHAM_CONG},
    {label: 'Wifi', value: TYPE_APP},
    {label: 'Định vị', value: TYPE_LOCATION},
    {label: 'Admin', value: TYPE_ADMIN},
]

