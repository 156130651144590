// category_chamsoc
export const NGHILIENTIEP = 1;
export const DIEMTHAP = 2;
export const KHONGLAMBAI = 3;
export const CHUADONGTIEN = 4;
export const DADONGTIEN = 5;
export const FEEDBACK_THAP = 6;
export const NGHI_MOT_BUOI = 7;
export const TANG_QUA = 12;


export const CHAM_SOC = 1;
export const ARRAY_CATE_CHAMSOC = {
    1: "Nghỉ liên tiếp",
    2: "Điểm thấp",
    3: "Không làm bài",
    4: "Chưa đóng tiền",
    5: "Đã đóng tiền",
    6: "Feedback thấp",
    7: "Nghỉ một buổi",
    11: "Không làm BTVN",
    12: "Tặng quà",
    13: 'Nhắc nhở bảo hành',
    14: 'Nghỉ 1 buổi không phép',
    15: 'Nghỉ buổi khai giảng',
    16: 'Chăm sóc học viên giữa khóa'
}

export const LEVEL_KID = {
    1 : "Newbie",
    2 : "Rookie",
    3 : "Intern",
    4 : "Regular",
    5 : "Hardened",
    6 : "Veteran",
    7 : "Superior",
    8 : "Commander",
    9 : "Specialist",
    10 : "Master",
    11 : "King",
    12 : "Grandmaster",
}
