import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const CREATE_INFORMATION = "create_information";
export const UPDATE_INFORMATION = "update_information";
export const GET_INFORMATIONS = "get_informations";
export const DELETE_INFORMATION = "delete_information";
export const GET_INFORMATION_BY_ID = "get_information_by_id";
export const COUNT_SPECIAL_INFORMATION = "count_special_information";
export const GET_BUSINESS_LEVELS = "get_business_levels";
const state = {};
const getters = {};
const actions = {
    [GET_INFORMATIONS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('informations', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_INFORMATION_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('informations', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_INFORMATION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('informations', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_INFORMATION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('informations/' + payload.id + '?_method=PUT', payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [COUNT_SPECIAL_INFORMATION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('informations/count-special', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_INFORMATION](context, id) {
        return new Promise(resolve => {
            ApiService.delete('informations', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_BUSINESS_LEVELS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('informations/get-business-levels', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
