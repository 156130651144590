<template xmlns="http://www.w3.org/1999/html">
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          Danh sách lịch trình
        </div>
    
      </div>
      <div class="card-body">
        <div class="example mb-10">
          <div class="row filter_form">
            <div class="col-xxl-2 col-md-4">
              <label for=""><b>Connection</b></label>
              <div class="form-group w-100">
                <el-input placeholder="Nhập connection..." v-model="query.connection" clearable></el-input>
              </div>
            </div>
            <div class="col-xxl-2 col-md-4">
              <label for=""><b>Trạng thái</b></label>
              <div class="form-group w-100">
                    <el-select v-model="query.status" class="w-100 shadow-sm" placeholder="Chọn trạng thái" collapse-tags clearable>
                    <el-option label="Chờ xử lý" :value="0"> </el-option>
                    <el-option label="Đã hoàn thành" :value="1"> </el-option>
                    <el-option label="Lỗi" :value="2"> </el-option>
                </el-select>
              </div>  
            </div>
            <div class="col-xxl-2 col-md-4">
              <label for=""><b>Code</b></label>
              <div class="form-group w-100">
                  <div class="form-group w-100">
                      <el-select v-model="query.code" class="w-100 shadow-sm" placeholder="Chọn code..." collapse-tags clearable>
                      <el-option label="15 Phút" :value="1"> </el-option>
                      <el-option label="30 Phút" :value="2"> </el-option>
                      <el-option label="12 Giờ" :value="3"> </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="col-xxl-2 col-md-4">
              <label for=""><b>Lịch gửi</b></label>
              <div class="form-group w-100 shadow-sm">
                <date-picker placeholder="Chọn Lịch gửi" range v-model="query.date_range" format="YYYY-MM-DD"
                  valueType="YYYY-MM-DD"></date-picker>
              </div>
            </div>
            <div class="col-xxl-2 col-md-4">
              <label for="">&ensp;</label>
              <div class="form-group" @click="srearchSchedule">
                <button class="btn btn-primary font-weight-bold mr-2">
                  <i class="fa fa-search"></i>Tìm kiếm</button>
              </div>
            </div>
          </div>
          <div class="example-preview table-responsive">
            <table class="table table-vertical-center table-bordered" v-loading="loading" element-loading-text="Loading...">
              <thead>
                <tr style="background-color: #F8F8F8;">
                  <th>STT</th>
                  <th>Gửi đến</th>
                  <th style="width: 7%;">Lịch gửi</th>
                  <th>Nội dung</th>
                  <th style="width: 10%;">Trạng thái</th>
                  <th>Code</th>
                  <th>Connection</th>
                  <th>Exception</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="listscheduleRun?.length > 0">
                <tr v-for="(item, index) in listscheduleRun">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.destination ? item.destination : '' }}</td>
                  <td>{{ item.schedule_time | formatDate  }}</td>
                  <td>{{ item.schedule_body ? item.schedule_body : '' }}</td>
                  <td>
                    <div v-if="item.schedule_status == 0">
                        <div class="circle_status d-inline-flex mr-2" style="background-color: #C0C0C0"></div>
                        <span>Chờ xử lý</span>
                    </div>
                    <div v-if="item.schedule_status == 1">
                        <div class="circle_status d-inline-flex mr-2" style="background-color: #009821;"></div>
                            <span>Đã hoàn thành</span>
                    </div>
                    <div v-if="item.schedule_status == 2">
                        <div class="circle_status d-inline-flex mr-2" style="background-color: red"></div>
                        <span>Lỗi</span>
                    </div>
                  </td>
                  <td>{{ item.schedule_code ? item.schedule_code : '' }}</td>
                  <td>{{ item.connection ? item.connection : '' }}</td>
                  <td>{{ item.schedule_exception ? item.schedule_exception : '' }}</td>
                  <td>
                    <div class="d-flex">
                        <div>
                            <button class="btn btn-xs btn-icon mr-2 btn-outline-primary" @click="connectSchedule(item.id)" title="Connection">
                                <i class="el-icon-connection"></i>
                            </button>
                        </div>
                        <div>
                            <button class="btn btn-xs btn-icon mr-2 btn-outline-danger" @click="deleteSchedule(item.id)" title="Connection">
                                <i class="el-icon-delete"></i>
                            </button>
                        </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{
            totalData }}</b></div>
        </div>
      </div>
      <div class="edu-paginate mx-auto text-center">
        <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
          :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
          :next-class="'page-link'" :page-class="'page-item'">
        </paginate>
      </div>
    </div>
  </template>
  
  <script>
  import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import { GET_ALL_SCHEDULE_JOB, DELETEE_SCHEDULE, CONNECT_SCHEDULE } from '../../../core/services/store/course/classes.module';
  export default {
    name: "schedule-runing",
    components: {
      DatePicker
    },
    data() {
      return {
        page: 1,
        last_page: 1,
        query: {
         status: '',
         connection: '',
         code: '',
         date_range: ''
        },
        loading: false,
        totalData: '',
        listscheduleRun: [],
        totalData: '',
      }
    },
  
    mounted() {
      this.getAllScheduleJob();
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Quản lý đơn vị sản xuất", route: 'schedule-runing' },
        { title: "Lịch trình" }
      ]);
    },
    methods: {
      srearchSchedule() {
        this.page = 1;
        this.getAllScheduleJob();
      },
  
      getAllScheduleJob() {
        let check_date = this.query.date_range;
         if(check_date[0] == null || check_date[1] == null) {
          this.query.date_range = '';
         }
          this.loading = true,
          this.$store.dispatch(GET_ALL_SCHEDULE_JOB, {
            page: this.page,
            status: this.query.status,
            connection: this.query.connection,
            code: this.query.code,
            date_range: this.query.date_range,
          }).then((res) => {
            this.listscheduleRun = res.data.data;
            this.totalData = res.data.total;
            this.last_page = res.data.last_page
          }).catch(() => {

          }).finally(() => {
            this.loading = false
        });;
      },

      connectSchedule(id) {
        this.$store.dispatch(CONNECT_SCHEDULE,{id:id}
        ).then((res) => {
          this.getAllScheduleJob();
          this.$notify({
            title: 'Success',
            message: 'Kết nối lịch trình thành công',
            type: 'success'
          }).catch(() => {
            this.$notify({
            title: 'Error',
            message: 'Kết nối lịch trình thất bại',
            type: 'error'
          })
          });
        })
      },

      deleteSchedule(id) {
        this.$store.dispatch(DELETEE_SCHEDULE,{id:id}
        ).then((res) => {
          this.getAllScheduleJob();
          this.$notify({
            title: 'Success',
            message: 'Xoá lịch trình thành công',
            type: 'success'
          }).catch(() => {
            this.$notify({
            title: 'Error',
            message: 'Xoá lịch trình thất bại',
            type: 'error'
          })
          });;
        })
      },
  
      searchData() {
        this.getAllScheduleJob()
      },
  
      clickCallback(obj) {
        this.page = obj
        this.getAllScheduleJob()
      },
    }
  }
  </script>
  
  <style scoped>
    table th,
        table td {
        padding: 0.7rem;
        text-align: center;
    }

    .circle_status{
        color: rgb(5, 5, 5);
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
  </style>
  
  
  