import { render, staticRenderFns } from "./Installment.vue?vue&type=template&id=31b6429e&scoped=true"
import script from "./Installment.vue?vue&type=script&lang=js"
export * from "./Installment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31b6429e",
  null
  
)

export default component.exports