<template>
  <fragment>
    <li class="menu-section">
      <h4 class="menu-text">CƠ SỞ VẬT CHẤT</h4>
    </li>
    <router-link
        :to="{ name : 'infrastructureList'}" class="menu-link menu-toggle"
        v-slot="{ href, navigate, isActive, isExactActive }">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
          v-if="currentUser.user.permission.includes(permission.XU_LY_YEU_CAU)"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-file-alt"></i>
          <span class="menu-text">Xử lý yêu cầu</span>
        </a>
      </li>
    </router-link>
  </fragment>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CSVC",
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
}
</script>

<style scoped>

</style>