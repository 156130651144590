<template>
  <div class="question">
    <homework-container :question="question"></homework-container>
    <div class="row">
      <div class="w-100" v-for="(item, index) in question.questions" :key="index">
        <div class="answer">
          <div class="answer-title mb-1 w-50">
            <div v-html="formatBrTagText(item.title)"></div>
          </div>
          <div class="row">
            <div v-if="item.answers.length > 0" class="col-7">
              <div class="answer-detail d-flex align-items-center" v-for="(answer, key) in item.answers" :key="key">
                <el-input type="textarea" placeholder="Your answer..." :value="question.student_answer" class="w-100"></el-input>
                <b class="ml-3">
                  Correct answer:<span class="text-success"> {{ answer.content }}</span>
                </b>
              </div>
            </div>
            <div v-else class="col-8">
              <div class="answer-detail d-flex align-items-center" >
                <!-- Xem kết quả -->
                <div v-if="typeView === 'view-result'" class="w-100 card p-4" style="overflow: auto; height: 488px">
                  <div v-if="listComment">
                    <div
                        v-for="(comment, index) in getHtmlStudentAnswer"
                        :style="{  'z-index': index }"
                        style="position: absolute; top: 0; left: 0; padding: 4px 14px;" :key="`span-${index}`">
                                <span v-for="(item, indexItem) in comment" ref="answerRef" :key="`${indexItem}-${index}`">
<!--                                  :style="{'background-color': indexItem == indexComment?'red':'#FBFF2180'}"-->
                                    <span v-if="item.type === 1"   style="white-space: pre-wrap;background-color:#FBFF2180">{{ item?.text }}</span>
                                    <span v-if="item.type === 0" style="white-space: pre-wrap">{{ item.text }}</span>
                                    <span v-if="item.type === 2" style="white-space: pre-wrap">{{ item.text
                                      }}</span>
                                </span>
                    </div>
                  </div>
                  <div v-else>
                    <el-input type="textarea" placeholder="Your answer..." :value="question.student_answer" class="w-100"></el-input>
                  </div>


                </div>
                <el-input type="textarea" placeholder="Your answer..." class="w-100" v-else></el-input>

              </div>
            </div>
            <!--            Nhận xét-->
            <div class="col-4 card p-4" v-if="listComment" >
              <p style="font-size: 13px" class="bold">Danh sách nhận xét</p>
              <div style="overflow: auto; height: 400px">
                <div class="card px-3 py-3 mb-2" v-for="(comment,index) in listComment" :key="index" @click="selectComment(index)" style="cursor: pointer">
                  <p class="mb-1">{{index+1}}.<span style="background-color:#FBFF2180;
">{{comment.text}}</span></p>
                  <p>{{comment.feedback}}</p>
                </div>
              </div>
            </div>
            <div v-else class="col-4 card p-4">
              <div class="card px-3 py-3 mb-2" >
                <p class="mb-1">{{1}}.<span style="background-color:#FBFF2180;
">Toàn bài</span></p>
                <p>{{question?.outComes?.comment }}</p>
              </div>
            </div>
          </div>
          <div v-if="typeView === 'view-result' && item.student_answer">
            <div>
              <div class="comment-essay">
                <div class="row">
                  <div class="col-md-6 col-infor  mt-3">
                    <div class="d-flex" v-if="scoreSkill?.scoreDetail ? scoreSkill.scoreDetail['task-achievement'] : false">
                      <div style="width: 30%" >
                        <p class="bold mb-1" style="height: 35px">Task Achievement</p>
                        <el-input :value="scoreSkill.scoreDetail['task-achievement']"  ></el-input>
                      </div>
                      <div style="width: 30%"   class="ml-4">
                        <p class="bold mb-1" style="height: 35px">Lexical Resource</p>
                        <el-input :value="scoreSkill.scoreDetail['lexical-resource']" ></el-input>
                      </div>
                      <div style="width: 30%" class="ml-4">
                        <p class="bold mb-1" style="height: 35px">Coherence and Cohesion</p>
                        <el-input :value="scoreSkill.scoreDetail['coherence-and-cohesion']" ></el-input>
                      </div>
                      <div style="width: 30%" class="ml-4">
                        <p class="bold mb-1" style="height: 35px">Grammatical Range and Accuracy</p>
                        <el-input :value="scoreSkill.scoreDetail['grammatical-range-and-accuracy']" ></el-input>
                      </div>
                      <div style="width: 20%" class="ml-4">
                        <p class="bold mb-1" style="height: 35px">Điểm Overall</p>
                        <el-input :value="scoreSkill?.total" ></el-input>
                      </div>
                    </div>
                    <div v-else>
                      <div style="width: 30%" class="ml-4">
                        <p class="bold mb-1" style="height: 35px">Điểm toàn bài</p>
                        <el-input :value="scoreSkill?.total" ></el-input>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/sass/homework/single-choice.scss';
import HomeworkContainer from "@/view/pages/classes/components/homework-type/homework-container.vue";

export default {
  name: "write-paragraph",
  components: {HomeworkContainer},
  props: {
    question: {
      type: Object,
      default: () => {
        return {}
      }
    },
    typeView: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      listComment: [],
      indexComment: null,
      scoreSkill: null,
    }
  },
  mounted() {
    this.listComment = this.question?.outComes?.comments;
    let outComes = this.question?.outComes;
    this.scoreSkill = {
      total: outComes?.score,
      scoreDetail: outComes?.score_detail
    }
  },
  computed: {
    getHtmlStudentAnswer() {
      let studentAnswer = this.question.questions[0].student_answer;
      return [...this.listComment?.map(comment => [
        {text: studentAnswer.slice(0, comment.index), type: 0},
        {text: comment.text, type: 1},
        {
          text: studentAnswer.slice(comment.index + comment.text.length),
          type: 0
        },
      ]),
        [{text: studentAnswer, type: 2}]]
    }
  },
  methods: {
    selectComment(index) {
      this.indexComment = index;
    },
    formatBrTagText(value) {
      return value ? value.replace(/(\r\n|\n\r|\r|\n)/g, '<br>') : '';
    },
  }
}
</script>

<style scoped>
</style>