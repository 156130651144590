<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách thông báo</h3>
      </div>
      <div class="card-title">
        <router-link
            class="font-weight-bold font-size-3  btn btn-success"
            :to="{ name: 'information-create' }"
        >Thêm mới
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <!--Filter-->
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Tiêu đề, nội dung</label>
            <input type="text" v-model="query.keyword" class="form-control"
                   placeholder="Tiêu đề, nội dung">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Phân loại</label>
            <el-select v-model="query.is_special" filterable class="w-100" placeholder="Phân loại"
                       clearable collapse-tags>
              <el-option
                  v-for="item in specials"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Thời gian tạo</label>
            <date-picker placeholder="Thời gian tạo" range v-model="query.created_at"
                         format="DD-MM-YYYY"
                         valueType="YYYY-MM-DD"></date-picker>
          </div>
        </div>
        <div class="col-md-3 mt-8">
          <div class="d-flex justify-content-between">
            <button class="btn btn-primary font-weight-bold mr-2"
                    @click="searchData">
              <i class="fa fa-search"></i> Tìm kiếm
            </button>
          </div>
        </div>
      </div>

      <div class="example mb-10">
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th scope="col" class="min-w-100px">Tiêu đề</th>
              <th scope="col" class="min-w-100px">Vị trí</th>
              <th scope="col" class="min-w-100px">Áp dụng với</th>
              <th scope="col" class="min-w-100px">Áp dụng từ</th>
              <th scope="col" class="min-w-100px">Người tạo</th>
              <th scope="col" class="min-w-160px">Người cập nhật</th>
              <th scope="col" class="min-w-100px">Phân loại</th>
              <th scope="col" class="min-w-150px">Ngày tạo</th>
              <th scope="col" class="min-w-100px">Hành động</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in informations.data" :key="index">
              <td>{{ item.category }}</td>
              <td>{{ item.stt }}</td>
              <td>
                <span v-if="item.is_all">Tất cả</span>
                <span v-else>{{ getAccountType(item) }}</span>
              </td>
              <td>{{ item.start_date | formatDate }}</td>
              <td>{{ item.created_user != null ? item.created_user.name : '' }}</td>
              <td>{{ item.updated_user != null ? item.updated_user.name : '' }}</td>
              <td>
                <span class="badge" :class="specialDefineCss[item.is_special]">{{
                    specialDefine[item.is_special]
                  }}</span>
              </td>
              <td>{{ item.created_at | formatDateTime }}</td>
              <td>
                <router-link :to="{ name: 'information-edit', params: {id : item.id} }" title="Sửa"
                             class="btn btn-sm btn-icon mr-2 btn-outline-info"><i
                    class="fas fa-pen-nib"></i>
                </router-link>
                <a title="Xóa" @click="deleteInformation(item.id)" href="javascript:"
                   class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_INFORMATIONS, DELETE_INFORMATION} from "@/core/services/store/system/information.module";
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const _ = require('lodash');

export default {
  components: {Multiselect, DatePicker},
  name: "InformationIndex",
  data() {
    return {
      is_disable_search: false,
      informations: [],
      // Paginate
      page: 1,
      last_page: 1,
      query: {
        keyword: '',
        is_special: '',
        created_at: [],
        type: 1
      },
      centers: [],
      cacheCenter: [],
      specials: [
        {
          id: 0,
          name: 'Không nổi bật'
        },
        {
          id: 1,
          name: 'Thông báo nổi bật'
        }
      ],
      specialDefine: {
        0: 'Không nổi bật',
        1: 'Nổi bật',
      }, specialDefineCss: {
        1: 'badge-info',
        0: 'badge-light',
      }
    }
  },
  mounted() {
    this.getList()
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý hệ thống thông tin", route: 'Thông báo'},
      {title: "Danh sách thông báo"}
    ]);
  },
  methods: {
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl()
      this.callApiGetList()
    },
    pushParamsUrl() {
      this.$router.push({
        name: '',
        query: {
          page: this.page,
          ...this.query
        }
      })
    },
    getList() {
      this.pullParamsUrl()
      this.callApiGetList()
    },
    pullParamsUrl() {
      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
      this.query.keyword = this.$route.query.keyword
      this.query.is_special = this.$route.query.is_special ? parseInt(this.$route.query.is_special) : ''
      this.query.created_at = this.$route.query.created_at ? this.$route.query.created_at : []
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({path: '', query: {page: this.page}})
      this.callApiGetList()
    },
    callApiGetList() {
      this.$store.dispatch(GET_INFORMATIONS, {
        page: this.page,
        ...this.query
      }).then((res) => {
        if (!res.data.error) {
          this.is_disable_search = false;
          this.informations = res.data;
          this.last_page = res.data.last_page;
        }
      });
    },
    getAccountType(item) {
      if (item.account_type != null) {
        let arrName = item.account_type.map(function (v) {
          return v.name
        })
        return arrName.join()
      }
    },
    deleteInformation(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_INFORMATION, id).then((res) => {
            if (res.error) {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'danger',
                solid: true
              });
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'success',
                solid: true
              });
              this.getList()
            }
          });
        }
      });
    },
  }
}
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}

.multiselect__tags {
  padding: 3px 40px 0 9px !important;
  min-height: 38px !important;
}

.multiselect {
  min-height: 38px !important;
}

.mx-input {
  min-height: 38px !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
