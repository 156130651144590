<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(handleUpdateCategory)">
        <div style="width: 100%; height: 1px; background: gray; margin-top: -20px; margin-bottom: 20px"></div>
        <div style="display: grid; grid-template-columns: 6fr 6fr; gap: 40px">
          <ValidationProvider name="Tên hạng mục" rules="required|max:50" v-slot="{ errors,classes }" vid="name">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Chương trình <span style="color: red">*</span></span>
              <el-input size="small" v-model="categoryClone.category" placeholder="Nhập tên chương trình, mô tả"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider name="Loại hạng mục" rules="required" v-slot="{ errors,classes }" vid="date">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Loại hạng mục <span style="color: red">*</span></span>
              <el-select v-model="categoryClone.type" placeholder="Chọn loại hạng mục" size="small">
                <el-option
                    v-for="item in typeOptionCategory"
                    :key="item.type"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="date" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div style="display: flex; flex-direction: column; gap: 10px; margin-top: 30px">
          <span class="page-filter__title">Mô tả</span>
          <el-input size="small" type="textarea" v-model="categoryClone.description" placeholder="Nhập tên chương trình, mô tả"/>
        </div>
        <div style="display: flex; justify-content: end; margin-top: 30px; gap: 10px">
          <el-button style="background: #CACACA" @click="closeDialog">Hủy bỏ</el-button>
          <button
              :disabled="loading"
              style="background: #5D04B0; padding: 0 10px; border-radius: 5px; color: white; position:relative;"
          >
            <i v-if="loading" class="el-icon-loading text-white float-righ"></i>
            Xác nhận
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {typeOptionCategory} from "@/view/pages/honors/Model/honorModel";
import {UPDATE_HONOR_CATEGORY} from "@/core/services/store/honors/honor.module";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    category: {
      category: '',
      type: '',
      description: '',
    },
    parentId: {
      type: Number,
      required: true,
      default: null,
    }
  },
  mounted() {
    this.categoryClone = {... this.category}
  },
  data() {
    return {
      loading: false,
      typeOptionCategory: typeOptionCategory,
      categoryClone: {}
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeEditCategory')
    },
    handleUpdateCategory() {
      this.loading = true
      const payload = {
        honors_program_id: this.parentId,
        category_name: this.categoryClone.category,
        honor_category_type: this.categoryClone.type,
        category_desc: this.categoryClone.description,
        id: this.categoryClone.id
      }
      this.$store.dispatch(UPDATE_HONOR_CATEGORY, payload).then(() => {
        this.$message.success('Sửa hạng mục thành công');
        this.$emit('getListCategory');
        this.closeDialog();
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>

</style>
