<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách người tham gia sự kiện: <b>{{ companyEvent.name }}</b></h3>
      </div>
      <div class="card-title align-content-end">
        <div class="dropdown dropdown-inline mr-2">
          <button :class="{ 'spinner spinner-white spinner-left': is_disable_export_example }"
            @click="exportCustomerExample" type="button" class="btn btn-info font-weight-bolder btn-sm">
            <span class="svg-icon svg-icon-md">
              <i class="fa fa-download"></i>
            </span>Download file mẫu
          </button>
        </div>
        <button @click="showModalCreate = true" type="button" class="btn btn-success font-weight-bolder mr-2 btn-sm">
          <span class="svg-icon svg-icon-md">
            <i class="fa fa-user-plus"></i>
          </span>Thêm mới
        </button>
        <div class="dropdown dropdown-inline mr-2">
          <button :class="{ 'spinner spinner-white spinner-left': is_disable_export }" @click="exportCustomer"
            type="button" class="btn btn-info font-weight-bolder btn-sm">
            <span class="svg-icon svg-icon-md">
              <i class="fa fa-cloud-download-alt"></i>
            </span>Xuất excel
          </button>
        </div>
        <div class="card-toolbar">
          <router-link class="font-weight-bold font-size-3 btn btn-secondary btn-sm"
            :to="{ name: 'company-event-list' }"><i class="fas fa-angle-double-left"></i> Quay lại
          </router-link>
        </div>
      </div>

    </div>
    <div class="card-body">
      <!--begin::Example-->
      <!--begin::Example-->
      <div class="example mb-10">
        <div class="example-btn d-flex justify-content-end">
          <div v-for="file in files" :key="file.id">
            <i class="fas fa-minus-circle mr-2" style="cursor: pointer;" @click="removeFile"></i>
            <span>{{ file.name }}</span> -
            <span>{{ file.size }}</span> -
            <span v-if="file.error">{{ file.error }}</span>
            <span v-else-if="file.success">success</span>
            <span v-else-if="file.active">active</span>
            <span v-else-if="!!file.error">{{ file.error }}</span>
            <span v-else></span>
          </div>
          <file-upload class="btn btn-text-dark-50 btn-icon-primary font-weight-bold btn-hover-bg-light mr-3"
            post-action="" extensions="xlsx,xls" accept=".xlsx,.xls" :size="1024 * 1024 * 10" v-model="files"
            @input-filter="inputFilter" @input-file="inputFile" ref="upload">
            <i class="flaticon-upload"></i>
            Chọn file
          </file-upload>
          <button type="button" @click="onSubmit" class="btn btn-primary mr-3 btn-sm"
            :class="{ 'spinner spinner-darker-success spinner-left': is_loading_import }">
            Upload file
          </button>
        </div>
      </div>
      <div class="example mb-10">
        <div class="mt-4  row filter_form">
          <div class="form-group col-md-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text line-height-0 py-0">
                  <span class="svg-icon">
                    <i class="fa fa-search"></i>
                  </span>
                </span>
              </div>
              <input type="text" class="form-control" placeholder="Nhập mã, tên, sđt, email người tham dự"
                v-model="query.keyword" @input="search" aria-describedby="basic-addon2">
            </div>
          </div>
          <div class="form-group col-md-3">
            <el-select v-model="query.status" clearable class="w-100" placeholder="Chọn trạng thái check-in">
              <el-option :value="1" label="Đã check-in"></el-option>
              <el-option :value="0" label="Chưa check-in"></el-option>
            </el-select>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <button class="btn btn-primary font-weight-bold mr-2"
                  :class="is_disable_search ? 'spinner spinner-white spinner-left' : ''" @click="searchData()">
                  <i class="fa fa-search" v-if="!is_disable_search"></i> Tìm kiếm
                </button>
              </div>
            </div>
          </div>
        </div>
        <DoubleScrollbar class="example-preview table-responsive overflow-auto">
          <table class="table table-vertical-center">
            <thead>
              <tr>
                <td>STT</td>
                <th class="" scope="col">Mã nhân sự</th>
                <th class="" scope="col">Họ tên</th>
                <th class="" scope="col">Số điện thoại</th>
                <th class="" scope="col">Trạng thái check-in</th>
                <th class="" scope="col">Thời gian check-in</th>
                <th class="" scope="col">Hành động</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in users" :key="index">
                <td>{{ paging.current_page == 1 ? index + 1 : (paging.current_page - 1) * 20 + index + 1 }}</td>
                <td>{{ item.staff_id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.phone }}</td>
                <td>
                  <span class="badge" v-bind:class="item.status === 1 ? classSpan.success : classSpan.danger">
                    {{ item.status === 1 ? "Đã check-in" : 'Chưa check-in' }}
                  </span>
                </td>
                <td>
                  <template v-if="item.status">
                    {{ item.time_checkin | formatTime }}
                  </template>
                  <template v-else></template>
                </td>
                <td>
                  <button v-b-modal.modal-show-qr-code title="QRCode" @click="getQRCode(item.staff_id, item.name)"
                    class="btn btn-icon mr-2 btn-outline-primary btn-xs">
                    <i class="fas fa-qrcode"></i>
                  </button>
                  <button v-b-modal.event-history title="Lịch sử" @click="user_id_current = item.id"
                    class="btn btn-icon mr-2 btn-outline-info btn-xs"><i class="fas fa-history fa-xs"></i>
                  </button>
                  <button title="Xóa" @click="deleteUser(item.id)"
                    v-if="currentUser.user.permission.includes(permission.XOA_NHAN_SU_CO_SO)"
                    class="btn btn-icon mr-2 btn-outline-danger btn-xs">
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </DoubleScrollbar>
      </div>
    </div>
    <Histories :user_id="user_id_current"></Histories>
    <div class="edu-paginate mx-auto text-center">
      <paginate v-model="paging.current_page" :page-count="paging.last_page" :page-range="3" :margin-pages="2"
        :click-handler="clickCallback" :prev-text="'Trước'" :next-text="'Sau'"
        :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'" :page-class="'page-item'"
        :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
        :next-class="'page-link'">
      </paginate>
    </div>
    <b-modal title="Thêm mới người tham dự" class="dialog-active" @hide="onHide" hide-footer
      :visible.sync="showModalCreate" centered>
      <el-row :gutter="10" class="pr-2 pl-2">
        <div class="form-row">
          <div class="col-md-12 mt-2">
            <h5 class="mb-3">Mã nhân sự</h5>
            <el-select v-model="form.user_id" clearable filterable remote reserve-keyword placeholder="Nhập mã nhân sự"
              ref="new_student" class="w-100" :remote-method="remoteMethod" :loading="loading" @change="getUserById">
              <template slot="empty" class="align-items-center justify-content-center">
                <div class="align-items-center justify-content-center el-select-dropdown__item hover cursor-pointer"
                  v-if="new_phone.length > 0">
                  Không tìm thấy nhân sự
                </div>
              </template>
              <el-option v-for="user in userSearch" :key="user.id"
                :label="user.id + 100000 + ' - ' + user.name + ' - ' + user.phone" :value="user.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-md-12 mt-5">
            <h5 class="mb-3">Email</h5>
            <input v-model="form.email" disabled type="text" class="form-control" id="address" placeholder="Email">
          </div>
          <div class="col-md-12 mt-5">
            <h5 class="mb-3">Số điện thoại</h5>
            <input v-model="form.phone" disabled type="text" class="form-control" placeholder="Số điện thoại">
          </div>
        </div>
        <button class=" btn float-right mt-5 mr-3 btn-success px-10" @click="addNewCompanyEventUser"> Lưu</button>
        <button class="swal2-cancel swal2-styled float-right mt-5 mr-3" @click="showModalCreate = false"> Hủy</button>
      </el-row>
    </b-modal>
    <ModalShowQrCode :QRCodeTitle=QRCodeTitle :qrCode=qrCode>

    </ModalShowQrCode>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import FileUpload from 'vue-upload-component';
import DoubleScrollbar from '@/view/content/DoubleScrollbar';
import Select2 from "@/view/content/Select2";
import Select2Ajax from "@/view/content/Select2Ajax";
import {
  COMPANY_EVENT_GET_BY_ID,
  COMPANY_EVENT_USER_CREATE,
  COMPANY_EVENT_USER_DELETE,
  COMPANY_EVENT_USER_EXPORT,
  COMPANY_EVENT_USER_EXPORT_EXAMPLE,
  COMPANY_EVENT_USER_GET_LIST,
  COMPANY_EVENT_USER_IMPORT,
  COMPANY_EVENT_USER_QR_CODE
} from "../../../core/services/store/company-event/company-event.module";
import { GET_USER_BY_ID, SEARCH_CUSTOMER_BY_OR_ID } from "../../../core/services/store/user/users.module";
import Histories from "@/view/pages/event-company/components/Histories";
import ModalShowQrCode from "@/view/pages/event-company/components/ModalShowQrCode";
import { exportExcel } from "@/core/filters";

const _ = require('lodash');

export default {
  components: {
    ModalShowQrCode,
    Histories,
    FileUpload,
    DoubleScrollbar,
    Select2,
    Select2Ajax
  },
  name: "EventCompanyList",
  data() {
    return {
      query: {
        keyword: '',
        status: ''
      },
      companyEvent: {
        name: ''
      },
      users: [],
      keyword: '',
      paging: {
        count: 0,
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0,
      },
      form: {
        user_id: '',
        company_event_id: '',
        email: '',
        phone: '',
      },
      new_phone: '',
      qrCode: '',
      userSearch: [],
      classSpan: {
        success: 'badge-success',
        danger: 'badge-danger',
        primary: 'badge-primary',
        secondary: 'badge-secondary'
      },
      loading: false,

      // Cache
      showModal: false,
      showModalCreate: false,
      showModalQR: false,
      QRCodeTitle: '',
      QRLoading: false,
      cachePosition: [],
      is_disable_search: false,
      is_disable_export: false,
      is_disable_export_example: false,
      dialog: {
        title: "",
        endDateLeave: null,
        reason_lay_off: null,
      },
      files: [],
      is_loading_import: false,
      user_id_current: 0
    }
  },
  created() {
    this.getList();
    this.setBreadcrumb();
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.form.company_event_id = this.$route.params.id;
      this.getEventById();
    } else {
      this.$router.push({ name: 'company-event-list' });
    }
  },
  methods: {
    setBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Quản lý sự kiện nội bộ", route: 'company-event-list' },
        { title: "Danh sách người tham gia sự kiện" }
      ]);
    },
    getEventById() {
      let id = this.$route.params.id;
      this.$store.dispatch(COMPANY_EVENT_GET_BY_ID, id).then((res) => {
        if (!res.error) {
          this.companyEvent = res.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getList() {
      this.page = parseInt(this.$route.query.page)
      this.pullParamsUrl();
      this.callApiGetList();
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'company-event-subscribers',
        query: {
          keyword: this.query.keyword,
          status: this.query.status,
          page: this.query.page
        },
        params: {
          id: this.$route.params.id
        }
      })
    },
    pullParamsUrl() {
      this.query.keyword = this.$route.query.keyword;
      this.query.status = this.$route.query.status ? parseInt(this.$route.query.status) : '';
      this.query.page = this.$route.query.page;
    },
    search: _.debounce(function () {
      this.query.status = this.$route.query.status
      this.pushParamsUrl()
      this.page = 1
      this.callApiGetList()
    }, 200),
    clickCallback(obj) {
      this.page = obj
      this.$router.push({
        name: 'company-event-subscribers',
        query: { ...this.query, page: this.page },
        params: { id: this.$route.params.id }
      });
      this.callApiGetList()
    },
    searchData() {
      this.pushParamsUrl();
      this.callApiGetList();
    },
    callApiGetList() {
      this.is_disable_search = true;
      this.$store.dispatch(COMPANY_EVENT_USER_GET_LIST, {
        keyword: this.query.keyword,
        page: this.page ? this.page : 1,
        status: this.query.status,
        company_event_id: this.$route.params.id
      }).then((res) => {
        this.users = res.data
        this.paging = res.pagination
      }).finally(() => {
        this.is_disable_search = false;
      });
    },
    deleteUser(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa khỏi danh sách tham gia?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(COMPANY_EVENT_USER_DELETE, id).then((res) => {
            if (res.error) {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'danger',
                solid: true
              });
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'success',
                solid: true
              });
              this.getList()
            }
          }).catch((e) => {
            this.$bvToast.toast(e.data.message, {
              title: 'Xóa mới',
              variant: 'danger',
              solid: true
            });
          });
          ;
        }
      })
    },
    getQRCode(id, name) {
      this.qrCode = '';
      this.showModalQR = true;
      this.QRloading = true;
      this.QRCodeTitle = name;
      this.$store.dispatch(COMPANY_EVENT_USER_QR_CODE, { user: id })
        .then((res) => {
          this.qrCode = res.data
          this.QRloading = false;
        });
    },
    onHide() {
      this.showModal = false;
      this.showModalCreate = false;
      this.showModalQR = false
    },
    onShowModal(payload) {
      this.showModal = true;
    },
    remoteMethod: function (query) {
      if (query !== '' && query.length >= 6 && query.length <= 7) {
        this.new_phone = query;
        const params = {
          search_customer_by_phoneOrId: query,
          limit: 10000,
          status: 1,
          is_staff: 1,
        }
        this.loading = true;
        let vm = this;
        setTimeout(function () {
          vm.getStudents(params);
        }, 300)
      } else {
        this.new_phone = '';
      }
    },
    getStudents(params) {
      this.$store.dispatch(SEARCH_CUSTOMER_BY_OR_ID, params).then((res) => {
        this.userSearch = res.data.data;
        this.loading = false;
        if (res.data.data.length === 0) {
          this.form.email = '';
          this.form.phone = '';
        }
      })
    },
    getUserById() {
      if (this.form.user_id) {
        this.$store.dispatch(GET_USER_BY_ID, this.form.user_id).then((res) => {
          if (!res.error) {
            this.form.email = res.data.email;
            this.form.phone = res.data.phone;
          }
        });
      } else {
        this.form.email = '';
        this.form.phone = '';
      }
    },
    addNewCompanyEventUser() {
      this.$store.dispatch(COMPANY_EVENT_USER_CREATE, this.form).then((res) => {
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Tạo mới',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$router.push({
            name: 'company-event-subscribers',
            query: { ...this.query, page: this.page },
            params: { id: this.$route.params.id }
          }).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Tạo mới',
              variant: 'success',
              solid: true
            });
          })
          this.showModalCreate = false;
          this.getList()
        }
      }).catch((e) => {
        this.form.user_id = '';
        this.form.email = '';
        this.form.phone = '';
        this.$bvToast.toast(e.data.message, {
          title: 'Tạo mới',
          variant: 'danger',
          solid: true
        });
      });
    },
    exportCustomer: function () {
      const headers = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'code',
          value: 'Mã nhân sự',
        },
        {
          key: 'name',
          value: 'Họ và tên',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'status_checkin',
          value: 'Trạng thái Check-In'
        },
        {
          key: 'time_checkin',
          value: 'Thời gian Check-In'
        },
      ];
      this.is_disable_export = true;
      this.$store.dispatch(COMPANY_EVENT_USER_EXPORT, {
        company_event_id: this.$route.params.id,
        ...this.query
      }).then((res) => {
        console.log("res.data",res.data);
        exportExcel(headers, res.data, "danh_sach_nguoi_tham_gia_su_kien" + new Date().getTime() / 1000 + ".xlsx", true)
        this.is_disable_export = false;
      });
    },
    exportCustomerExample: function () {
      this.is_disable_export_example = true;
      this.$store.dispatch(COMPANY_EVENT_USER_EXPORT_EXAMPLE, {}).then(() => {
        this.is_disable_export_example = false;
      });
    },
    onSubmit() {
      let formData = new FormData();
      formData.append('dataImport', this.dataImport);
      formData.append('event_id', this.$route.params.id);
      this.is_loading_import = true;
      this.show_process_loading = true;
      this.$store.dispatch(COMPANY_EVENT_USER_IMPORT, formData).then((res) => {
        this.is_loading_import = false
        if (!res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Import',
            variant: 'success',
            solid: true
          });
        } else {
          this.$router.push({
            name: 'company-event-subscribers',
            query: { ...this.query, page: this.page },
            params: { id: this.$route.params.id }
          }).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Tạo mới',
              variant: 'success',
              solid: true
            });
          })
          this.$bvToast.toast(res.message, {
            title: 'Import',
            variant: 'danger',
            solid: true
          });
          this.getList()
        }
      }).catch((error) => {
        if (error.data.error) {
          this.$bvToast.toast(error.data.message, {
            title: 'Import',
            variant: 'success',
            solid: true
          });
        }
        this.is_loading_import = false
      });
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.dataImport = newFile.file;
        this.isAble = false;
      }
      if (newFile && oldFile) {
        // update
      }
      if (!newFile && oldFile) {
        // remove
      }
    },
    removeFile: function () {
      this.files.pop();
      this.isAble = true;
    },
  }
}
</script>
