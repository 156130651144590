import ApiService from "@/core/services/api.service";
const _ = require('lodash');
//action types
export const GET_CENTER_BY_ID = "get_center_by_id";
export const GET_CENTERS = "list-center";
export const GET_LIST_CENTERS = "get-list";
export const GET_CENTERS_FOR_NAME = "centers/list";
export const CREATE_CENTER = "create_center";
export const UPDATE_CENTER = "update_center";
export const UPDATE_EDUCENTER_CENTER = "centers/update-educenter-center";
export const UPDATE_CENTER_STATUS = "centers/update-status";
export const DELETE_CENTER = "delete_center";
export const GET_EDUCENTER_INFORMATION = "centers/show-educenter-information";
export const GET_AREA = "get-list-area";

//mutations types
export const SET_CENTERS = "setCenters";
export const ADD_CENTERS = "addCenters";
export const SET_CENTERS_ERROR = "errorCenters";
export const SET_CENTER_BY_ID = "getCenterById";
export const SET_CENTER_PAGINATE = "set_center_paginate_value";
export const UPDATE_LIST_CENTER = "set_centers_when_active_center";
export const UPDATE_LIST_CENTER_WHEN_DELETE = "set_centers_when_delete_center";
export const UPDATE_LIST_CENTER_WHEN_UPDATE = "set_centers_when_update_center";

export const GET_CENTER_BRAND_E = "get-brand-e";

const state = {
    errors: null,
    centers: [],
    center : '',
    centerPaginatedData : [],
};

const getters = {
    getAllCenters(state) {
        return state.centers;
    },
    getCenterById(state) {
        return state.center;
    },
    getCenterPaginate(state) {
        return state.centerPaginatedData;
    },
};

const actions = {
    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [CREATE_CENTER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('centers', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [UPDATE_CENTER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('centers/'+ payload.id +'?_method=PUT', payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [UPDATE_CENTER_STATUS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('centers/update-status/'+ payload.id +'?_method=PUT', payload.params).then(({data}) => {
                context.commit(UPDATE_LIST_CENTER_WHEN_UPDATE, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [UPDATE_EDUCENTER_CENTER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_EDUCENTER_CENTER}/`+ payload.id +'?_method=PUT', payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_LIST_CENTERS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`centers/${GET_LIST_CENTERS}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    /**
     *
     * @param context
     * @param credentials
     * @returns {Promise<unknown>}
     */
    [GET_CENTERS](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("centers", credentials)
                .then(({data}) => {
                    // data.data.data -=> tất cả trung tâm
                    context.commit(SET_CENTERS, data.data.data);
                    const centerPagination = {
                        total: data.data.meta.pagination.total,  // total number of elements or items
                        per_page: data.data.meta.pagination.per_page, // items per page
                        current_page: data.data.meta.pagination.current_page, // current page (it will be automatically updated when centers clicks on some page number).
                        total_pages: data.data.meta.pagination.total_pages // total pages in record
                    }
                    // dữ liệu phân trang
                    context.commit(SET_CENTER_PAGINATE, centerPagination)
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },/**
     *
     * @param context
     * @param credentials
     * @returns {Promise<unknown>}
     */
    [GET_CENTERS_FOR_NAME](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query(GET_CENTERS_FOR_NAME, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },

    [GET_AREA](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("areas/get-list", credentials)
                .then(({data}) => {

                    resolve(data);
                })
                .catch(() => {

                });
        });
    },
    /**
     *
     * @param context
     * @param id
     * @returns {Promise<unknown>}
     */
    [GET_CENTER_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('centers', id).then(({data}) => {
                context.commit(SET_CENTER_BY_ID, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    /**
     *
     * @param context
     * @param id
     * @returns {Promise<unknown>}
     */
    [GET_EDUCENTER_INFORMATION](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${GET_EDUCENTER_INFORMATION}`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    /**
     *
     * @param context
     * @param id
     * @returns {Promise<unknown>}
     */
    [DELETE_CENTER](context, id) {
        return new Promise(resolve => {
            ApiService.delete('centers', id).then(({data}) => {
                context.commit(UPDATE_LIST_CENTER_WHEN_DELETE, id);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_CENTER_BRAND_E](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`centers/${GET_CENTER_BRAND_E}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
};


const mutations = {
    [SET_CENTERS](state, data) {
        state.centers = data;
        state.errors = {};
    },
    [ADD_CENTERS](state, data) {
        state.centers.push(data);
        state.errors = {};
    },
    [SET_CENTERS_ERROR](state, error) {
        state.errors = error;
    },
    [SET_CENTER_BY_ID](state, data) {
        state.center = data;
    },
    [SET_CENTER_PAGINATE](state, data) {
        state.centerPaginatedData = data;
    },
    [UPDATE_LIST_CENTER](state, center){
        let centers = state.centers;
        let oldCenter = _.find(centers,function (object) {
            return object.id === center.id;
        });
        oldCenter.status = center.status;
    },

    [UPDATE_LIST_CENTER_WHEN_DELETE](state, id){
        let centers = state.centers;
        let indexCenter = _.findIndex(centers,function (object) {
            return object.id === id;
        });
        centers.splice(indexCenter,1);
    },
    [UPDATE_LIST_CENTER_WHEN_UPDATE](state, center){
        let centers = state.centers;
        let indexCenter = _.findIndex(centers,function (object) {
            return object.id === center;
        });
        centers[indexCenter] = center;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
