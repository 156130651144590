<template>
  <div>
    <b-modal
        id="modal-change-contract"
        :title="'Sửa thông tin hợp đồng ' + id_prop"
        @ok="handleOk"
        class="centered-input"
        ok-only
        ok-title="Cập nhật"
        ref="my-modal"
    >
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form action="" @submit.prevent="handleSubmit(handleOk)">
          <div class="row">
            <div class="col-md-6">
              <label>Mã giới thiệu:</label>
              <ValidationProvider vid="created_user" name="Ma gioi thieu"
                                  :rules="{ required : false }"
                                  v-slot="{ errors,classes }">
                <el-input placeholder="Mã giới thiệu"
                          v-model="created_user"
                          :class="classes"
                ></el-input>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                    {{
                      errors[0]
                    }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <label>Mã tư vấn:</label>
              <ValidationProvider vid="nguoi_tu_van" name="Nguoi tu van"
                                  :rules="{  }"
                                  v-slot="{ errors,classes }">
                <el-input placeholder="Mã người tư vấn"
                          v-model="nguoi_tu_van"
                          :class="classes"
                ></el-input>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                    {{
                      errors[0]
                    }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <label>Lý do sửa:</label>
              <ValidationProvider vid="reason" name="Lý do sửa"
                                  :rules="{  }"
                                  v-slot="{ errors,classes }">
                <el-input
                    v-model="reason"
                    type="textarea"
                    :rows="2"
                    placeholder="Lý do sửa"
                >
                </el-input>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                    {{
                      errors[0]
                    }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import {UPDATE_CONTRACT_BY_USER_ID} from "@/core/services/store/contract/contracts.module";

export default {
  name: "ModalChangeContract",
  props: {
    created_user_prop: {
      type: Number,
      default() {
        return 0;
      }
    },
    nguoi_tu_van_prop: {
      type: Number,
      default() {
        return 0;
      }
    },
    id_prop: {
      type: Number,
      default() {
        return 0;
      }
    }
  },
  mounted() {
  },
  watch: {
    created_user_prop() {
      this.created_user = Number(this.created_user_prop) ? Number(this.created_user_prop) + 100000 : '';
    },
    nguoi_tu_van_prop() {
      this.nguoi_tu_van = Number(this.nguoi_tu_van_prop)? Number(this.nguoi_tu_van_prop) + 100000 : '';
    },
    id_prop() {
      this.id = this.id_prop;
    }
  },
  data() {
    return {
      reason: '',
      created_user: '',
      nguoi_tu_van: '',
      id: ''
    }
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$store.dispatch(UPDATE_CONTRACT_BY_USER_ID, {
        reason: this.reason,
        created_user: this.created_user ? Number(this.created_user) : '',
        nguoi_tu_van: this.nguoi_tu_van ? Number(this.nguoi_tu_van) : '',
        id: this.id
      }).then(res => {
        this.$emit('updateContractSuccess')
        this.$bvToast.toast(res.message, {
          title: 'Cập nhật',
          variant: 'success',
          solid: true
        });
        this.$refs['my-modal'].hide();
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      })
    }
  }
}
</script>

<style scoped>

</style>