<template>
    <div class="card card-custom gutter-b">
        <div class="card-header">
            <div class="card-title">
                Danh sách test đầu vào
            </div>
            <div class="card-title">
                <a href="#" class="font-weight-bold font-size-3 btn btn-success" @click="openModalCreate">Thêm mới</a>
            </div>
        </div>
        <div class="card-body">
            <div class="example mb-10">
                <div class="row">
                    <div class="form-group col-md-3">
                        <div class="form-group">
                            <label>
                                <span class="text-danger"></span></label>
                            <date-range-picker
                                    v-model="dateRange"
                                    :auto-apply="false"
                                    :locale-data="localData"
                                    @update="selectDate"
                                    class="text-center"
                            ></date-range-picker>
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <div class="form-group">
                            <el-input
                                    v-model="query.keyword"
                                    placeholder="Nhập số điện thoại hoặc tên học viên"
                                    clearable>
                            </el-input>
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <div class="form-group">
                            <el-select v-model="query.tester_id" clearable filterable class="w-100"
                                       placeholder="Người test">
                                <el-option
                                        v-for="item in getTesters"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <div class="form-group">
                            <el-select v-model="query.status" clearable filterable class="w-100"
                                       placeholder="Trạng thái">
                                <el-option
                                        v-for="item in statuses"
                                        :key="item.id"
                                        :label="item.value"
                                        :value="item.id"
                                >
                                    <span style="float: left">{{ item.value }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <div class="form-group">
                            <el-select v-model="query.center_id" clearable filterable class="w-100"
                                       placeholder="Trung tâm">
                                <el-option
                                        v-for="item in centers"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                >
                                    <span style="float: left">{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <div class="form-group">
                            <button class="btn btn-primary"
                                    :class="{'spinner spinner-white spinner-left' : is_loading_search}"
                                    @click="handleFormSearch"
                            >Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>

                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th>STT</th>
                            <th>Mã học viên</th>
                            <th>Họ tên</th>
                            <th>Số điện thoại</th>
                            <th>Người test</th>
                            <th>Lịch test</th>
                            <th>Trung tâm</th>
                            <th>Hình thức</th>
                            <th>Trạng thái</th>
                            <th style="min-width: 100px">Hành động</th>
                        </tr>
                        </thead>
                        <tbody v-if="incomes.length > 0">
                        <tr v-for="(item, key) in incomes" :key="key">
                            <td>{{ ++key }}</td>
                            <td>{{ item.user_id + 100000}}</td>
                            <td>{{ item.user_name }}</td>
                            <td>{{ item.user_phone }}</td>
                            <td>{{ item.tester ? item.tester.name: '' }}</td>
                            <td>{{ item.schedule }}</td>
                            <td>{{ getNameCenter(item.center_id) }}</td>
                            <td>
                                <span :class="defineTypeCss[item.type]">{{defineType[item.type]}}</span>
                            </td>
                            <td><span :class="defineStatusCss[item.status]">{{defineStatus[item.status]}}</span></td>
                            <td>
                                <a href="javascript:" title="Sửa" class="btn btn-xs btn-icon mr-2 btn-outline-info"
                                   @click="openModalEdit(item)">
                                    <i class="fas fa-pen-nib"></i>
                                </a>
                                <a class="btn btn-xs btn-outline-warning btn-icon mr-2" href="javascript:"
                                   v-if="item.status === 1 || item.status === 0"
                                   title="Chưa chấm"
                                   @click="openModalStep(item)"
                                >
                                    <i class="fas fa-user-plus"></i>
                                </a>
                                <a class="btn btn-xs btn-icon btn-outline-success mr-2" href="javascript:" v-else
                                   title="Đã chấm"
                                   @click="openModalStep(item)"
                                >
                                    <i class="fas fa-user-check"></i>
                                </a>
                                <a v-if="!item.test_income_exam.questions && item.status != 3"
                                   title="KH hủy hẹn"
                                   @click="cancelDating(item.id)"
                                   href="javascript:"
                                   class="btn btn-xs btn-icon btn-outline-danger mr-2"><i
                                        class="fas far fa-window-close"></i></a>
                                <a v-if="item.status == 1" title="Xóa" @click="deleteTestIncome(item.id)"
                                   href="javascript:"
                                   class="btn btn-xs btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr>
                            <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <test-income-form :showModal="showModalCreate" :income-data="incomeData" @clicked="closeModal"
                          @createSuccess="createIncomeSuccess"></test-income-form>
        <test-income-edit :showModal="showModalEdit" :income-data="incomeData" @clicked="closeEditModal"
                          @updateSuccess="updateIncomeSuccess"></test-income-edit>
        <test-mark-point :showModal="showModalMark" @clicked="closeModal" :income="income"
                         @errorGetDataExam="errorGetDataExam" @finishMarkPoint="finishMarkPoint"></test-mark-point>
        <div class="edu-paginate mx-auto text-center">
            <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                    :page-class="'page-item'"
            >
            </paginate>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import {GET_INCOMES, GET_TESTERS, DELETE_INCOME} from "@/core/services/store/test/test.module";
    import TestIncomeForm from "./TestIncomeForm";
    import TestIncomeEdit from "./TestIncomeEdit";
    import TestMarkPoint from "./TestMarkPoint";
    import Multiselect from 'vue-multiselect';
    import DateRangePicker from 'vue2-daterange-picker';
    import Swal from "sweetalert2";
    import DatePicker from "vue2-datepicker";
    import 'vue2-datepicker/index.css';
    import '@/assets/sass/custom/custom_event.scss';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
    import {CANCEL_INCOME} from "../../../core/services/store/test/test.module";

    export default {
        components: {
            DatePicker,
            Multiselect,
            DateRangePicker,
            TestIncomeForm,
            TestIncomeEdit,
            TestMarkPoint,
        },
        name: "TestIncomeIndex",
        data() {
            return {
                is_loading_search: false,
                /* date range picker */
                localData: {
                    direction: 'ltr',
                    format: 'dd-mm-yyyy',
                    separator: ' ~ ',
                    applyLabel: 'Đồng ý',
                    cancelLabel: 'Hủy bỏ',
                    weekLabel: 'Tuần',
                    customRangeLabel: 'Custom Range',
                    daysOfWeek: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
                    monthNames: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
                    firstDay: 0,
                },
                dateRange: {},
                statuses: [
                    {id: 0, value: 'Chưa làm bài'},
                    {id: 1, value: 'Cần chấm'},
                    {id: 2, value: 'Đã chấm'},
                    {id: 3, value: 'Hủy hẹn'}
                ],
                areas: [],
                testers: [],
                query: {
                    status: null,
                    tester_id: null,
                    startDate: null,
                    endDate: null,
                    center_id: null,
                    keyword: '',
                },
                incomes: [],
                page: 1,
                last_page: 1,
                /*modal variable*/
                showModalCreate: false,
                showModalMark: false,
                showModalEdit: false,
                incomeData: {
                    user_name: '',
                    user_phone: '',
                    tester_id: '',
                    zoom_id: '',
                    zoom_password: '',
                    schedule: '',
                },
                income: {},
                centers: [],
                status: [
                    {
                        id: 1,
                        name: 'Cần chấm'
                    },
                    {
                        id: 2,
                        name: 'Đã chấm'
                    },
                    {
                        id: 3,
                        name: 'Hủy hẹn'
                    }
                ],
                defineType: {
                    1: 'Online',
                    2: 'Offline'
                },
                defineTypeCss: {
                    1: 'badge badge-success',
                    2: 'badge badge-secondary'
                },
                defineStatus: {
                    0: 'Chưa làm bài',
                    1: 'Cần chấm',
                    2: 'Đã chấm',
                    3: 'Hủy hẹn'
                },
                defineStatusCss: {
                    0: 'badge badge-secondary',
                    1: 'badge badge-warning ',
                    2: 'badge badge-success',
                    3: 'badge badge-danger',
                }
            }
        },
        async created() {
            await this.getCenters();
            this.setDateRangeFromUrl();
            this.setParams();
            this.getListTester();
            this.getListIncomes();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý test đầu vào", route: 'incomes-list'},
                {title: "Danh sách test đầu vào"}
            ]);
        },
        computed: {
            ...mapGetters(['getTesters'])
        },

        methods: {
            getNameCenter(center_id) {
                for (let key in this.centers) {
                    if (this.centers[key].id == center_id) {
                        return this.centers[key].name;
                    }
                }
            },
            getCenters() {
                this.$store.dispatch(GET_CENTERS, {center_ids: [295, 516]}).then((res) => {
                    if (!res.error) {
                        for (let dataKey in res.data.data) {
                            this.centers.push(
                                {
                                    id: res.data.data[dataKey].id,
                                    name: res.data.data[dataKey].name,
                                })
                        }
                    }
                })
            },
            setDateRangeFromUrl() {
                let date = new Date();
                let endDate = this.$route.query.endDate ? new Date(this.$route.query.endDate) : new Date();
                let startDate = this.$route.query.startDate ? new Date(this.$route.query.startDate) : new Date(date.getFullYear(), date.getMonth(), 1);
                this.query.startDate = this.$route.query.startDate ? this.$route.query.startDate : this.setDateParams(startDate);
                this.query.endDate = this.$route.query.endDate ? this.$route.query.endDate : this.setDateParams(endDate);
                endDate.setDate(endDate.getDate());
                this.dateRange = {startDate, endDate};
            },

            setParams() {
                let status = this.$route.query.status;
                let tester_id = this.$route.query.tester_id;
                this.query.status = status ? parseInt(status) : '';
                this.query.tester_id = tester_id ? parseInt(tester_id) : '';
            },

            selectDate: function () {
                this.query.startDate = this.setDateParams(this.dateRange.startDate);
                this.query.endDate = this.setDateParams(this.dateRange.endDate);
                this.handleFormSearch();
            },

            setDateParams: function (d) {
                return d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
            },

            pushParamsUrl() {
                let page = {page: this.page};
                let query = {...this.query, ...page}
                this.$router.push({
                    name: 'incomes-list',
                    query: query
                })
            },

            handleFormSearch() {
                this.pushParamsUrl();
                this.getListIncomes()
            },

            deleteTestIncome(id) {
                Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(DELETE_INCOME, id).then((res) => {
                            this.$bvToast.toast(res.message, {
                                title: 'Xóa',
                                variant: 'success',
                                solid: true
                            });
                            this.getListIncomes();
                        }).catch((res) => {
                            this.$bvToast.toast(res.data.message, {
                                title: 'Xóa',
                                variant: 'danger',
                                solid: true
                            });
                        });
                    }
                });
            },

            cancelDating(id) {
                Swal.fire({
                    title: "Bạn chắc chắn muốn hủy hẹn?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(CANCEL_INCOME, id).then((res) => {
                            this.$bvToast.toast(res.message, {
                                title: 'Hủy lịch',
                                variant: 'success',
                                solid: true
                            });
                            this.getListIncomes();
                        }).catch((res) => {
                            this.$bvToast.toast(res.data.message, {
                                title: 'Hủy lịch',
                                variant: 'danger',
                                solid: true
                            });
                        });
                    }
                });
            },

            getListIncomes() {
                this.is_loading_search = true;
                let page = {page: this.page};
                let payload = {...this.query, ...page};
                this.$store.dispatch(GET_INCOMES, payload).then((res) => {
                    this.incomes = res.data.data;
                    this.last_page = res.data.last_page;
                    this.is_loading_search = false;
                });
            },

            getListTester() {
                this.$store.dispatch(GET_TESTERS);
            },

            clickCallback(obj) {
                this.page = obj;
                this.getListIncomes();
            },

            openModalCreate() {
                this.showModalCreate = true;
            },

            openModalEdit(income) {
                this.showModalEdit = true;
                this.incomeData = income;
            },

            closeModal() {
                this.showModalCreate = false;
                this.showModalMark = false;
            },

            openModalStep(income) {
                this.income = income;
                this.showModalMark = true;
            },

            errorGetDataExam() {
                this.showModalMark = false;
            },

            closeEditModal() {
                this.showModalEdit = false;
            },

            createIncomeSuccess() {
                this.getListIncomes();
            },

            updateIncomeSuccess() {
                this.getListIncomes();
            },

            finishMarkPoint() {
                this.getListIncomes();
            },

        }
    }
</script>

<style>
    .vue-daterange-picker {
        width: 100%;
    }

    .reportrange-text[data-v-79d7c92a] {
        text-align: center;
        padding: 12px 10px;
    }

    .calendars {
        flex-wrap: unset !important;
    }

    .form-control.reportrange-text {
        padding-top: 9px;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
