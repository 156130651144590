<template>
  <div>
    <button v-b-modal="`modal-attendance-${item.id}`" title="Điểm danh"
            class="btn btn-xs btn-icon mr-2 btn-outline-success">
      <i class="fa fas fa-house-user"></i>
    </button>

    <b-modal :id="'modal-attendance-' + item.id"
             hide-footer
             :centered="true" title="Điểm danh" size="lg">
      <el-row>
        <el-row class="table-responsive-lg">
          <table class="table table-bordered m-0">
            <thead>
            <tr>
              <th> Họ và tên</th>
              <th> Mã học viên</th>
              <th> Số điện thoại</th>
              <th class="text-center"> Có mặt</th>
              <th class="text-center"> Vắng</th>
              <th class="text-center"> Có phép</th>
            </tr>
            </thead>
            <tbody>
            <AttendanceItem v-for="(item, index) in attendances" :key="index"
                            :attendance="item"
                            @changeAttendance="changeAttendance"
            >
            </AttendanceItem>
						<p class="mt-3" style="text-align: center;max-width: 100%" v-if="isLoadingAttendance">Đang tải<i class="el-icon-loading ml-2"></i></p>
						<p class="text-center mt-3 text-danger" v-if="!isLoadingAttendance && attendances.length == 0">Không có lịch trình</p>
            </tbody>
          </table>
        </el-row>
        <span class="dialog-footer mt-5 d-flex align-items-center justify-content-end">
                    <button v-if="hideButtonAttendancePost" :disabled="disableAttendancePost" class="btn btn-primary ml-2" @click="attendancePost">Điểm danh</button>
        </span>
      </el-row>

    </b-modal>
  </div>
</template>

<script>
import {ACTION_ATTENDANCE, ATTENDANCE} from "../../../../core/services/store/course/classes.module";
import AttendanceItem from "./AttendanceItem";

export default {
  name: "Attendance",
  components: {AttendanceItem},
  props: {
    item: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      isLoadingAttendance: false,
      hideButtonAttendancePost: true,
      attendances: [],
      disableAttendancePost: true,
    }
  },
  async mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      let modal_by_id = 'modal-attendance-' + this.item.id;
      if (modalId == modal_by_id) {
        this.getAttendance();
      }
    })
  },
  methods: {
    getAttendance() {
			this.isLoadingAttendance = true;
      this.$store.dispatch(ATTENDANCE, {
        classroom_id: this.item.classroom_id,
        type: this.item.type,
        date_only: this.item.date_only
      }).then((data) => {
        this.attendances = data.data;
      }).finally(() => {
				this.isLoadingAttendance = false;
			})
    },
    changeAttendance(type, course_student_id) {
      this.attendances.map((value, key) => {
        if (value.id == parseInt(course_student_id)) {
          this.attendances[key].attendance = type;
        }
        if(value.status_class !== 0 ) {
            value.attendance = 3;
        }
      })
			this.disableAttendancePost = !this.checkedAll(this.attendances, 'attendance');
    },
		checkedAll(array, key) {
        return array.every(function(obj) { 
          return obj.hasOwnProperty(key) && obj[key] !== null;
      });
		},
    getPayloadAttendance() {
      let data = [];
      this.attendances.map((value => {
        data.push({
          course_student_id: value.id,
          val: value.attendance
        })
      }));
      return {
        classroom_id: this.item.classroom_id,
        lesson: this.item.lesson,
        date : this.item.date,
        data: data
      }
    },
    attendancePost() {
      this.$confirm('Chỉ có thể điểm danh một lần duy nhất. Bạn có chắc chắn muốn lưu kết quả điểm danh này?', 'Điểm danh buổi kiểm tra', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: ''
      }).then(() => {
        this.hideButtonAttendancePost = false;
        this.$store.dispatch(ACTION_ATTENDANCE, this.getPayloadAttendance()).then((res) => {
          if (!res.error) {
            this.$message({
              message: 'Điểm danh thành công',
              type: 'success'
            });
            this.hideModal();
          } else {
            this.$message.error('Oops, Điểm danh thất bại.');
          }
        }).catch((res) => {
          this.$message({
            message: res.data.message,
            type: 'error'
          });
        }).finally(() => {
          this.hideButtonAttendancePost = true;
        });
      });
    },
    hideModal() {
      this.$bvModal.hide(`modal-attendance-${this.item.id}`)
    }
  }
}
</script>

<style scoped>
.el-checkbox__inner {
  width: 30px !important;
  height: 30px !important;
}
</style>