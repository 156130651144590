import ApiService from "@/core/services/api.service";

const state = {};
const getters = {};
export const GET_REGION = "region";

const actions = {

    [GET_REGION](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_REGION, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};

const mutations = {

};
export default {
  state,
  actions,
  mutations,
  getters
};