<template>
  <div class="bg-white task_detail">
    <HeaderTaskDetail :typeItemId="informationTask?.taskItem"
                      :customerName="informationTask?.customerName"
                      :customerPhone="informationTask?.customerPhone"
                      :linkTestOnline="informationTask?.linkTestOnline??``"
                      :informationTask="informationTask"
                      @comeCheckSpeakingSuccess="getInformationTask"
                      :statusTest="statusTest"
                      @cancelSchedule="getInformationTask"
                      @comeCheck="getInformationTask"
                      :isTester="true"
                      :isIelts="isIelts"
    ></HeaderTaskDetail>
    <div v-if="dataInfoTask.adminComeCheck" class="my-6 d-flex justify-content-end">
      <span class="mr-4">{{ dataInfoTask.adminComeCheck.id + 100000 }} - {{ dataInfoTask.adminComeCheck.name }} đã vào test lúc {{ dataInfoTask.admin_come_check_date |formatTimeNew}}</span>
    </div>
    <div class="row container">
      <div class="col-lg-12 col-xl-5 container">
        <TaskInformation :informationTask="informationTask"></TaskInformation>
        <div>
          <h3 class="my-4">Trạng thái</h3>
          <div class="grading_status card" :class="STATUS_BG_ITEM_DEFINE_CSS[informationTask.status]" style="background-color: #FFE55E">  {{ STATUS_ITEM_DEFINE[informationTask.status] }}</div>
        </div>
        <div class="mt-2">
          <div class="card p-3">
            <h5 class="my-4" style="font-size: 13px">Gửi link hoặc quét mã QR cho khách hàng làm bài trước để giúp tiết kiệm thời gian cho buổi kiểm tra đầu vào.</h5>
            <p style="font-size: 13px">Link website:</p>
            <div>
              <el-input :value="linkTestInput" :disabled="true" style="width: 85%" class="mb-3"></el-input>
              <el-tooltip content="Sao chép đường dẫn" placement="top" class="ml-2">
                <button @click="copyUrl" class="btn btn-default ml-1" title="Sao chép đường dẫn"><i
                    class="el-icon-document-copy v-size--small"></i></button>
              </el-tooltip>
            </div>
            <div style="width: 110px ;height: 110px ; border: solid 1px "
                 class="d-flex justify-content-center align-items-center">
              <qr-code :text="linkTestInput" :size="100">
              </qr-code>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-xl-7" v-if="informationTask">
        <test-results-child v-if="+informationTask?.centerID === Cambridge_Mentor_ID"></test-results-child>
        <TestResults @doneTest="checkDoneTest" @isIelts="isIelts=true" v-else></TestResults>
      </div>
      <div>
      </div>
    </div>
    <ModalWarningAdmin :open="openModal"></ModalWarningAdmin>
    <div>

      <el-dialog
        title="Nhắc nhở vào test"
        :visible.sync="dialogremind"
        width="30%"
        >
        <span>Đã tới thời gian vào phòng test để chuẩn bị kiểm tra năng lực đầu vào cho học viên.</span>
        <p>Vui lòng truy cập sớm vào phòng test để có sự chuẩn bị tốt nhất.</p>
        <p class="d-flex justify-content-end">
          <el-button @click="dialogremind = false">Để sau</el-button>
          <el-button type="primary" @click="openTest" class="bg-violet text-white">Vào test ngay</el-button>
        </p>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import HeaderTaskDetail from "@/view/pages/test-input/components/ComponentsTaskDetail/HeaderTaskDetail";
import TaskInformation from "@/view/pages/test-input/components/ComponentsTaskDetail/TaskInformation";
import TestCaseInformation from "@/view/pages/test-input/components/ComponentsTaskDetail/TestCaseInformation";
import TestResults from "@/view/pages/test-input/components/ComponentsTaskDetail/TestResults";
import {CHECK_TASK_OF_ME, TEST_INPUT_GET_INFORMATION_TASK} from "@/core/services/store/test/testInput.module";
import {toModelTest} from "@/view/pages/test-input/models/task_information_model";
import {Cambridge_Mentor_ID, ECOACH_ID} from "@/core/option/typeCenterBill";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {STATUS_BG_ITEM_DEFINE_CSS, STATUS_ITEM_DEFINE, STATUS_ITEM_DEFINE_CSS} from "@/core/option/testInputOption";
import ModalWarningAdmin from "@/view/pages/test-input/components/ModalWarningAdmin.vue";
import TestResultsChild from "@/view/pages/test-input/components/ComponentsTaskDetail/TestResultsChild.vue";
import moment from "moment-timezone";
import {COME_CHECK_GENERAL} from "../../../core/services/store/test/testInput.module";
export default {
  name: "TaskDetails",
  components: {TestResultsChild, ModalWarningAdmin, TestResults, TestCaseInformation, TaskInformation, HeaderTaskDetail},
  data() {
    return {
      informationTask: {},
      STATUS_ITEM_DEFINE :STATUS_ITEM_DEFINE,
      STATUS_BG_ITEM_DEFINE_CSS:STATUS_BG_ITEM_DEFINE_CSS,
      statusTest : false,
      openModal: false,
      Cambridge_Mentor_ID:Cambridge_Mentor_ID,
      isIelts:false,
      dialogremind: false,
      dataInfoTask: {}
    }
  },
  async mounted() {
    this.checkTaskOfMe()
    await this.getInformationTask()
  },
  created() {
    let flag = this.$route.query.flag??false
    this.$store.dispatch(SET_BREADCRUMB, [
      !flag ? {title: "Quản lý tác vụ", route: 'task-manager'} :{title: "Danh sách tác vụ", route: 'task-manager-personal'} ,
      {title: "Chi tiết tác vụ"}
    ]);
  },
  computed: {
    isGradingStatus() {
      return this.informationTask?.testResults?.isGradingStatus
    },
    linkTestInput() {

      if (this.informationTask.centerID == ECOACH_ID){
        return process.env.VUE_APP_TEST_INPUT_ECOACH + `login?token=${this.informationTask.token}`
      }
      return process.env.VUE_APP_TEST_INPUT_IELTS + `login?token=${this.informationTask.token}`

    }
  },
  methods: {
    checkDoneTest(status){
        this.statusTest = status
    },
    async getInformationTask() {
      let test_input_schedule_id = this.$route.params.id
      let data = await this.$store.dispatch(TEST_INPUT_GET_INFORMATION_TASK, test_input_schedule_id);
      if (!data.error) {
        this.dataInfoTask = data.data;
        this.informationTask = toModelTest(data.data)
        this.remind();
        return ;
      }
      return  data
    },
    checkTaskOfMe(){
       this.$store.dispatch(CHECK_TASK_OF_ME, {test_input_schedule_item_id:this.$route.params.id} ).then((data)=>{
         this.openModal = !data?.data
       }).catch((e)=>{
         console.log(e)
       })
    },
    copyUrl() {
      navigator.clipboard.writeText(`${this.linkTestInput}`);
      this.$message.success("Sao chép đường dẫn thành công");
    },
    remind() {
      if (this.informationTask.status || this.dataInfoTask.admin_come_check_date || this.informationTask.testForm != 'Online') {
        this.dialogremind = false;
        return;
      }

      let time = moment(this.dataInfoTask.schedule, "YYYY-MM-DD HH:mm:ss");
      let now = moment();

      let tenMinutesBefore = time.clone().subtract(10, 'minutes');
      let tenMinutesAfter = time.clone().add(10, 'minutes');

      let isInRange = now.isBetween(tenMinutesBefore, tenMinutesAfter);

      setTimeout(() => {
        this.dialogremind = isInRange;
      }, 3000);
    },
    openTest()
    {
      this.comeCheck();
      window.open(`${this.informationTask?.linkTestOnline}`)
    },
    comeCheck() {
      this.$store.dispatch(COME_CHECK_GENERAL, {
        id: this.informationTask.taskCode
      }).then((data)=>{
        this.getInformationTask();
      });
    },
  },

}
</script>

<style scoped>
.task_detail {
  height: 100%;
}

.grading_status {

  width: max-content;
  padding: 2px 10px;
}
.bg-violet {
  background: #5E00B6;
}
</style>