<template>
    <div class="bg-white p-2">
        <div class="d-flex">
            <div class="col-md-6">
                <h4>Chương trình TVV định kỳ</h4>
            </div>
            <div class="col-md-6 d-flex  justify-content-end">
                <el-button class="bg-success text-white" @click="exportExcel"><i class="text-white el-icon-download"></i> Export</el-button>
            </div>
        </div>
        <div class="filter d-flex">
            <div class="col-md-3">
                <label for="">Tư vấn viên</label>
                <input type="text" value="" placeholder="Tìm tên, sđt, email của tư vấn viên" class="form-control" v-model="query.keyword">
            </div>
            <div class="col-md-3">
                <label>Khu vực</label>
                <el-select v-model="query.area" filterable class="w-100" placeholder="Chọn khu vực" @change="getAllOffice" multiple>
                    <el-option
                        v-for="item in areas"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                        <span style="float: left">{{ item.name }}</span>
                    </el-option>
                </el-select>
            </div>
            <div class="col-md-3">
                <label>Văn phòng</label>
                <el-select class=" w-100 shadow-sm" placeholder="Văn phòng" v-model="query.branch" filterable multiple collapse-tags clearable>
                      <el-option
                        v-for="item in branchs"
                        :key="item.id"
                        :label="item.name_alias ? item.name_alias : item.name"
                        :value="item.id"
                      >
                        <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                      </el-option>
                </el-select>
            </div>
            <div class="col-md-3">
                <label>Thời gian bắt đầu khóa học</label>
                <date-picker
                    placeholder="Thời gian bắt đầu khóa học"
                    range
                    v-model="query.date_range"
                    format="DD-MM-YYYY"
                    valueType="YYYY-MM-DD">
                </date-picker>
            </div>
        </div>
        <div class="filter d-flex mt-2">
            <div class="col-md-3">
                <label>Khóa học</label>
                <el-select class=" w-100 shadow-sm" placeholder="Khóa học" v-model="query.course" collapse-tags clearable multiple>
                    <el-option v-for="item in listCourse" :key="item.id" :label="item.title" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="col-md-3">
                <label>Trạng thái học</label>
                <el-select class=" w-100 shadow-sm" placeholder="Trạng thái học" v-model="query.status" collapse-tags clearable multiple>
                    <el-option v-for="item in listStatus" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="col-md-3 pt-8">
                <el-button @click="search"
                           type="primary"
                           class="btn btn-primary font-weight-bold mr-2"
                           :class="loading ? 'spinner spinner-white spinner-left' : ''">
                    <i class="fa fa-search" v-if="!loading"></i> Tìm kiếm</el-button>
            </div>

        </div>
        <div class="example-preview table-responsive mt-6">
            <table class="table table-vertical-center table-bordered" v-loading="loading" element-loading-text="Loading...">
                <thead>
                    <tr style="background-color: #F8F8F8;">
                        <th>Mã tư vấn viên</th>
                        <th>Tên tư vấn viên</th>
                        <th>Số điện thoại</th>
                        <th>Email</th>
                        <th>Khu vực</th>
                        <th>Văn phòng</th>
                        <th>Khoá học</th>
                        <th>Trạng thái học</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in dataTvv">
                        <td>{{ item.user ? item.user.id + 100000 : '' }}</td>
                        <td>{{ item.user ? item.user.name : '' }}</td>
                        <td>{{ item.user ? item.user.phone : '' }}</td>
                        <td>{{ item.user ? item.user.email : '' }}</td>
                        <td>{{ item.profiles && item.profiles.area ? item.profiles.area.name : '' }}</td>
                        <td>{{ item.profiles && item.profiles.branch ? (item.profiles.branch.name_alias ? item.profiles.branch.name_alias : item.profiles.branch.name ) : '' }}</td>
                        <td>{{ item.name_course }}</td>
                        <td>
                            <span class="rounded-circle px-2 mr-2 " :class="changeStatus(item.status_course).color"></span>
                            {{ changeStatus(item.status_course).text }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <div class="d-flex justify-content-center">
                <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
                          :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
                          :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
                          :next-class="'page-link'" :page-class="'page-item'">
                </paginate>
            </div>
        </div>
        <div class="d-flex justify-content-end">Tổng số bản ghi: {{ total }}</div>
    </div>
</template>

<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_AREA, GET_OFFICE} from "../../../core/services/store/center/branch.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {GET_ALL_TRAINING, GET_PROGRAM_TVV} from "../../../core/services/store/course/classes.module";
import {EXPORT_LIST_TVV_TRAINING} from "../../../core/services/store/export/export.module";
import moment from "moment-timezone";

export default {
    name: "TrainingManageCourse",
    components: {
        DatePicker
    },
    data() {
        return {
            query: {
                date_range: '',
                keyword: '',
                area: [],
                branch_id: '',
                status: [],
                course: []
            },
            branchs: [],
            areas: [],
            listStatus: [
                {
                    id: 0,
                    name: 'Chưa học'
                },
                {
                    id: 1,
                    name: 'Đang học'
                },
                {
                    id: 2,
                    name: 'Đã học'
                },
                {
                    id: 3,
                    name: 'Hoàn thành'
                },
            ],
            page: 1,
            last_page: 1,
            loading: false,
            loading_export: false,
            dataTvv: [],
            total: 0,
            listCourse: [],
            date_filter_default: [],
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Chương trình TVV định kỳ", route: 'training-manage' },
            { title: "Quản lý đào tạo" },
        ]);
        this.getAllArea();
        this.getDateFilterDefault();
        this.getAllOffice();
        this.getProgramTvv();
        this.search();
    },
    methods: {
        getDateFilterDefault() {
            const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
            const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
            this.date_filter_default.push(startOfMonth);
            this.date_filter_default.push(endOfMonth);
            this.query.date_range = this.date_filter_default;
        },
        pushParamsUrl() {
            this.$router.push({
                name: 'training-manage',
                query: {
                    page: this.page,
                    ...this.query
                }
            })
        },
        search() {
            this.page = 1;
            this.pushParamsUrl();
            this.getAllCandidate();
        },
        getAllArea() {
            this.$store.dispatch(GET_AREA, {}).then((res) => {
                if (!res.error) {
                    this.areas = res.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getAllOffice() {
            this.$store.dispatch(GET_OFFICE, {
                area_id: this.query.area
            }).then((res) => {
                if (!res.error) {
                    this.branchs = res.data
                    console.log(this.branchs,'al')
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getProgramTvv() {
            this.$store.dispatch(GET_PROGRAM_TVV, {}).then((res) => {
                if (!res.error) {
                    this.listCourse = res.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },


        clickCallback(obj) {
            this.page = obj
            this.getAllCandidate()
        },
        getAllCandidate() {
            this.loading = true;
            this.$store.dispatch(GET_ALL_TRAINING, {
              page: this.page,
              ...this.query
            }).then((res) => {
                if (!res.error) {
                    this.loading = false;
                    this.dataTvv = res.data.data
                    this.last_page = res.data.last_page;
                    this.total = res.data.total;
                }
            })
        },

        exportExcel() {
            this.$store.dispatch(EXPORT_LIST_TVV_TRAINING, {
                page: this.page,
                keyword: this.query.keyword,
                area: this.query.area,
                status: this.query.status,
                branch: this.query.branch,
                date_range: this.query.date_range,
                course: this.query.course,
                is_export_candidate: 1
            }).then((res) => {
                this.$notify({
                    title: 'Success',
                    message: 'Export danh sách tvv thành công',
                    type: 'success'
                });
            })
        },
        changeStatus(status) {
            let text;
            let color;
            switch (status) {
                case 0:
                    text = 'Chưa học';
                    color = 'bg-secondary';
                    break;
                case 1:
                    text = 'Đang học';
                    color = 'bg-orange';
                    break;
                case 2:
                    text = 'Đã học';
                    color = 'bg-blue';
                    break;
                case 3:
                    text = 'Hoàn thành';
                    color = 'bg-green';
                    break;
            }
            return { text: text, color: color };
        },
      getDefaultStartDate() {
        const currentDate = new Date();
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      },
      getDefaultStartDateFormatted() {
        const defaultStartDate = this.getDefaultStartDate();
        const formattedStartDate = `${defaultStartDate.getFullYear()}-${(defaultStartDate.getMonth() + 1).toString().padStart(2, '0')}-${defaultStartDate.getDate().toString().padStart(2, '0')}`;
        return formattedStartDate;
      }
    }
}
</script>

<style scoped>
.bg-pink {
    background: pink;
}
.bg-orange {
    background: orange;
}
.bg-blue {
    background: dodgerblue;
}
.bg-green {
    background: green;
}
</style>