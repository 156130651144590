<template>
    <div class="card card-custom gutter-b border-primary">
        <div class="card-header bg-primary">
            <div class="card-title">
                <h3 class="card-label text-white">Thông tin lớp học đã tham gia
                </h3>
            </div>
        </div>
        <div class="card-body">
            <div class="example-preview table-responsive">
                <table class="table table-vertical-center">
                    <thead>
                    <tr>
                        <th scope="col" class="min-w-30px">Tên khóa</th>
                        <th scope="col" class="min-w-100px">Tên lớp</th>
                        <th scope="col" class="min-w-100px">Tên trung tâm</th>
                        <th scope="col" class="min-w-100px">Tên chi nhánh</th>
                        <th scope="col" class="min-w-100px">Đã học số buổi/ tổng</th>
                        <th scope="col" class="min-w-100px">Thời gian bắt đầu</th>
                        <th scope="col" class="min-w-100px">Thời gian kết thúc</th>
                        <th scope="col" class="min-w-100px">Ca học</th>
                    </tr>
                    </thead>
                    <tbody v-if="classes.length > 0">
                      <tr v-for="(item, index) in classes" :key="index">
                          <td>{{item.course ? item.course.name : ''}}</td>
                          <td>{{item.name}}</td>
                          <td>{{item.branch && item.branch.center ? item.branch.center.name : ''}}</td>
                          <td>{{item.branch ? item.branch.name : ''}}</td>
                          <td></td>
                          <td>{{item.start_date}}</td>
                          <td>{{item.end_date}}</td>
                          <td>
                              {{ getSchedules(item)}}
                          </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="8" class="text-center">Chưa có dữ liệu</td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ClassInfo",
        props: {
            classes: {
              type: Array,
              default: () => { return [] }
            },
        },
        methods: {
            getSchedules(item) {
                let arr = item.schedules.map((item) => {
                    return item.day;
                })
                return arr.join(',')
            }
        }
    }
</script>

<style scoped>

</style>
