<template>
    <b-modal id="handle-add-guardian"
    title="Thêm mới người giám hộ"
    size="xl" ok-only centered
    hide-footer>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form class="form" autocomplete="off" @submit.prevent="handleSubmit(handleOk)">
        <div v-loading="loading" class="row">
          <div class="col-md-4 mt-2">
            <label class="fs-1 font">Họ và tên<span class="text-danger"> *</span></label>
            <ValidationProvider vid="name" name="Họ và tên" rules="required" v-slot="{ errors, classes }">
              <el-input :class="classes" type="text" placeholder="Nhập họ và tên" v-model="form.name" />
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <!-- <div class="col-md-4 mt-2">
            <div class="form-group">
              <label class="fs-1 font">Email<span class="text-danger"> *</span></label>
              <ValidationProvider vid="email" name="Email" rules="required|email" v-slot="{ errors, classes }">
                <el-input  maxlength="50" type="email" placeholder="Email" :class="classes" v-model="form.email" />
                <div class="fv-plugins-message-container">
                  <div data-field="Email đăng nhập" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div> -->
          <div class="col-md-4 mt-2">
            <div class="form-group">
              <label class="fs-1 font">Số điện thoại<span class="text-danger"> *</span></label>
              <ValidationProvider vid="phone" name="Số điện thoại"
                :rules="{ required: true, regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }" v-slot="{ errors, classes }">
                <el-input type="number" v-model="form.phone" :class="classes" placeholder="Số điện thoại" />
                <div class="fv-plugins-message-container">
                  <div data-field="Số điện thoại" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-md-4 mt-2">
            <div class="form-group w-100">
              <label class="fs-1 font">Mối quan hệ khách hàng<span class="text-danger"> *</span></label>
              <ValidationProvider vid="area" name="Mối quan hệ khách hàng" rules="required" v-slot="{ errors, classes }">
              <el-input :class="classes" collapse-tags clearable type="text" placeholder="Nhập mối quan hệ" v-model="form.value_other_type" />
                <div class="fv-plugins-message-container">
                  <div data-field="Mối quan hệ khách hàng" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-md-4 mt-2">
            <div class="form-group">
              <label class="fs-1 font">Số điện thoại khác</label>
              <ValidationProvider vid="phone_other" name="Số điện thoại khác"
                v-slot="{ errors, classes }">
                <el-input type="number" v-model="form.phone_other" :class="classes" placeholder="Số điện thoại khác" />
                <div class="fv-plugins-message-container">
                  <div data-field="phone_other" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <el-button :disabled="loading_create" size="small" class="float-right text-white rounded"
            style="background-color: rgba(93, 4, 176, 1);" native-type="submit">
            <i v-bind:class="[loading_create ? 'el-icon-loading' : '']"></i>
            Xác nhận
          </el-button>
        </div>
        <div>
          <el-button style="background-color: rgba(202, 202, 202, 1)" size="small" class="float-right mr-3 rounded"
            @click="closeModal">
            <i></i>
            Huỷ bỏ
          </el-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
   import { mapGetters } from "vuex";
import {ADD_USER_PARENT} from "../../../../core/services/store/customer/customer-crm.module"

export default {
  name: "FormCandidate",
  components: {},
  props: {
    centers: Array,
    teacher: Object,
    trainers: Array,
  },
  mounted() {
  },
  data() {
    return {
      loading: false,
      form: {
        name: '',
        phone: '',
        // email: '',
        value_other_type: '',
        phone_other: '',
        user_guardian: this.$route.params.id
      },
     
    }
  },
    methods: {
        handleOk(bvModalEvent) {
            this.callApi()
        },

        callApi(){
            this.laoding = true;

            this.$store.dispatch(ADD_USER_PARENT, (this.form)).then((res) => {
                this.$notify({
                title: "Thành công",
                message: "Thêm người giám hộ thành công",
                type: "success",
                });
                this.closeModal();
            }).catch((e) => {
                this.$notify({
                title: "Lỗi",
                message: "Đã xảy ra lỗi khi thêm người giám hộ.",
                type: "error",
                });
                if (e.data.data.message_validate_form) {
                this.$refs.form.setErrors(e.data.data.message_validate_form);
                }
                this.laoding = false;
            })
            .finally(() => {
            this.laoding = false;
            });
        },
        closeModal() {
            this.$bvModal.hide('handle-add-guardian');
            this.$emit("reload");
        }
    }
}
</script>

<style scoped>

</style>