<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form class="form" id="area_create" @submit.prevent="handleSubmit(handleAddPersonInCharge)">
        <span style="margin-top: 15px; margin-bottom: 20px;; display: block">Vui lòng chọn người phụ trách để chuyển người phụ trách cho {{ listTaskSelected.length }} tác vụ trên.</span>
        <ValidationProvider vid="name" name="Người phụ trách" rules="required"
                            v-slot="{ errors,classes }">
          <el-select v-model="personSelected" filterable clearable placeholder="Chọn người phụ trách">
            <el-option
                v-for="item in personInChargeOption"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                errors[0]
              }}
            </div>
          </div>
        </ValidationProvider>
        <div style="display: flex; justify-content: end; gap: 5px; margin-top: 10px">
          <el-button @click="closeDialog">Đóng</el-button>
          <button style="background: #5E00B6; color: white; padding-left: 15px; padding-right: 15px; border-radius: 4px" type="submit">Gán tác vụ</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  GET_LIST_PERSON_IN_CHARGE_ACTIVE,
  WORK_COORDINATION
} from "@/core/services/store/task-management/task-management.module";

export default {
  data() {
    return {
      personSelected: null,
      personInChargeOption: [],
      taskSelectedList: [],
    }
  },
  props: {
    listTaskSelected: {
      type: Array,
      required: true,
      default: []
    }
  },
  mounted() {
    this.getListPersonInChargeOption()
    this.getListIdTaskSelected()
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    getListPersonInChargeOption() {
      this.$store.dispatch(GET_LIST_PERSON_IN_CHARGE_ACTIVE).then(res => {
        if (!res.error) {
          this.personInChargeOption = res.data ?? []
        }
      })
    },
    getListIdTaskSelected() {
      this.taskSelectedList = this.listTaskSelected.map((item) => {
        return item.id
      })
    },
    handleAddPersonInCharge() {
      const payload = {
        person_in_charge_id: this.personSelected + 100000,
        ids: this.taskSelectedList
      }
      this.$store.dispatch(WORK_COORDINATION, payload).then(res => {
        if (!res.error) {
          this.$message.success('Điều phối công việc thành công')
          this.$emit('getTaskManagementList')
          this.$emit('workCoordinationSuccess')
          this.closeDialog()
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>

</style>