<template>
    <div class="">
        <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Danh sách chi văn phòng</h3>
        </div>
        <div class="card-title d-flex">
          <!-- <div>
            <button 
                    class="btn btn-success font-weight-bold mr-2 ">
                    Export vốn góp
            </button>
          </div> -->
          <button
              class="font-weight-bold font-size-3  btn btn-primary"
              @click="dialogImport = true"
          >Import
          </button>
        </div>
      </div>
      <div class="card-body bg-white">
        <div class="example mb-10">
          <div class="row">
            <div class="col-md-2">
                <label for="">Thời gian</label>
                <el-date-picker
                    class="w-100"
                    v-model="query.range_date"
                    type="month"
                    placeholder="Chọn tháng"
                >
                </el-date-picker>
            </div>
            <div class="col-md-2">
                <label for="">Khu vực</label>
              <el-select v-model="query.area_id" clearable filterable collapse-tags placeholder="Chọn khu vực" @change="changebranch">
                <el-option
                    v-for="item in areas"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-md-2">
                <label for="">Văn phòng</label>
              <el-select v-model="query.branch_id" clearable filterable collapse-tags placeholder="Nhập tên, ID văn phòng">
                <el-option
                    v-for="item in branches"
                    :key="item.id"
                    :label="item.name_alias ? item.name : ''"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="col-md-2">
                <label for="">Người đề xuất</label>
            <el-input placeholder="Nhập tên, mã nhân sự" v-model="query.keyword"></el-input>
            <!--end::Form-->
            </div>
            <div class="col-md-2 mt-8">
                <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                        @click="searchData">
                  <i class="fa fa-search"></i> Tìm kiếm
                </button>
                <button v-else type="button" disabled
                        class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                  Tìm kiếm
                </button>
            </div>
          </div>
          <div class="example-preview table-responsive overflow-auto mt-6">
            <table :aria-busy="isBusy" class="table table-hover table-bordered table-vertical-center b-table bg-white"
                   style="text-align: center">
                <thead>
                <tr>
                    <th scope="col" class="min-w-30px">ID văn phòng</th>
                    <th scope="col" class="min-w-100px">Tên văn phòng</th>
                    <th scope="col" class="min-w-100px">Khu vực</th>
                    <th scope="col" class="min-w-100px">Người đề xuất</th>
                    <th scope="col" class="min-w-100px">Nội dung chi</th>
                    <th scope="col" class="min-w-120px">Số tiền</th>
                    <th scope="col" class="min-w-120px">Thời gian chi</th>
                    <th scope="col" class="min-w-120px">Hành động</th>
                </tr>
                </thead>
                <tbody v-if="isBusy">
                    <tr role="row" class="b-table-busy-slot" >
                    <td colspan="16" role="cell" class="">
                        <div class="text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                        </div>
                    </td>
                    </tr>
                </tbody>
                <tbody v-else>
                <tr v-for="(item, index) in dataFinance" :key="index">
                    <td>{{ item.branch ? item.branch.id : '' }}</td>
                    <td>{{ item.branch ? item.branch.name_alias ?? item.branch.name : '' }}</td>
                    <td>{{ item.area ? item.area.name : '' }}</td>
                    <td>{{ item.user ? item.user.name : '' }}</td>
                    <td>{{ item.content }}</td>
                    <td>{{ item.money |formatPrice}}</td>
                    <td>{{ item.date |formatDate }}</td>
                    <td>
                      <a 
                        title="Xóa"
                        @click="deleteFinace(item.id)"
                        href="javascript:"
                        class="btn btn-xs btn-icon btn-outline-danger ml-2"><i class="fas fa-trash"></i></a>
                    </td>
                </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end bg-white pr-4">
        Tổng số bản ghi : {{ total }}
      </div>
      <div class="edu-paginate mx-auto d-flex text-center justify-content-center bg-white">
        <paginate
            v-model="page"
            :page-count="last_page"
            :page-range="3"
            :margin-pages="1"
            :click-handler="clickCallback"
            :prev-text="'Trước'"
            :next-text="'Sau'"
            :container-class="'pagination b-pagination'"
            :pageLinkClass="'page-link'"
            :next-link-class="'next-link-item'"
            :prev-link-class="'prev-link-item'"
            :prev-class="'page-link'"
            :next-class="'page-link'"
            :page-class="'page-item'"
        >
        </paginate>
      </div>
      <el-dialog title="Import chi văn phòng" :visible.sync="dialogImport">
            <div class="mb-2">
                <div>
                    <p for="">Bước 1: Tải xuống file mẫu</p>
                    <div>
                        <el-button class="btn btn-primary" @click="exportFileMau">
                            <i class="el-icon-download"></i>
                            Tải xuống
                        </el-button>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <p for="">Bước 2: Tải file excel lên hệ thống</p>
                    <div class="mb-2">
                        <el-upload
                            ref="upload"
                            accept=".xlsx"
                            :limit="1"
                            list-type="text"
                            action=""
                            :file-list="fileList"
                            :on-change="handleChange"
                            :on-remove="handleRemove"
                            :auto-upload="false"
                            :multiple="false"
                        >
                            <el-button size="small" type="warning">
                                <i class="el-icon-upload2"></i>
                                Tải lên
                            </el-button>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogImport = false">Cancel</el-button>
                <el-button :disabled="loadingImport" v-loading="loadingImport" type="success" class="btn btn-success"
                           @click="submitUpload">Xác nhận
                </el-button>
            </div>
        </el-dialog>
        <el-dialog title="Lỗi" :visible.sync="logImport" width="90%">
            <div>Hệ thống phát hiện có lỗi trong quá trình import dữ liệu:</div>
            <table class="table" v-loading="loadingImport">
                <thead>
                <tr>
                    <th style="width: 10%;">STT</th>
                    <th style="width: 10%;">ID văn phòng</th>
                    <th style="width: 10%;">Mã nhân sự</th>
                    <th style="width: 30%;">Nội dung chi</th>
                    <th style="width: 10%;">Số tiền chi</th>
                    <th style="width: 15%;">Thời gian chi</th>
                    <th style="width: 15%;">Lỗi</th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="(item, index) in listDataImport.dataError" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.id_van_phong }}</td>
                    <td>{{ item.ma_nhan_su_de_xuat }}</td>
                    <td>{{ item.noi_dung_chi }}</td>
                    <td>{{ item.so_tien_chi }}</td>
                    <td>{{ item.thoi_gian_chi }}</td>
                    <td>
                        <p v-for="(item_prop, index_prop) in item?.error" :key="index_prop">
                            <span class="text-dark">
                                {{ item_prop }}
                            </span>
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <div slot="footer" class="dialog-footer">
                <el-button v-loading="loadingImport" @click="dialogImport = true, logImport= false">Quay lại </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {   
    GET_DATA_FINACE_ACTUAL,
    DELETE_FINACE_ACTUAL,
    EXPORT_FILE_DEFAULT_FINACE_ACTUAL,
    IMPORT_FINACE_ACTUAL} from "../../../core/services/store/finance/financeTransaction.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from "moment-timezone";
import {GET_LIST_AREAS} from "../../../core/services/store/center/area.module";
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import Swal from "sweetalert2";
import {read, utils} from "xlsx";
import {formatDateTime, formatDateTimeVietnam, formatPrice, removeVietnameseTones} from "../../../core/filters";

export default {
    name: "FinanceActualContributeds",
    components: {
        DatePicker
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    data() {
        return {
            page: 1,
            areas: [],
            range_total_paid: [],
            branches: [],
            status_contract_cancelled: [],
            query: {
                keyword: '',
                range_total_paid: '',
                area_id: '',
                branch_id: '',
                range_date: null,
                status_contract_cancelled: [],
                type: 1,
                tuitionPayment: '',
                month: '',
                year: ''
            },
            is_disable_search: false,
            dataFinance: {},
            isBusy:false,
            month: '',
            year: '',
            last_page: 1,
            date: null,
            dialogImport: false,
            fileList: [],
            disable: false,
            loading: false,
            logImport: false,
            listDataImport: {},
            loadingImport: false,
            total: 0 
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý chi văn phòng", route: '#'},
            {title: "Danh sách chi văn phòng"}
        ]);
        this.getData();
        this.getAreas();
        let lastMonth = moment().format('YYYY-MM-DD');
        this.query.range_date = new Date(lastMonth);

    },
    methods: {
        fileToJson(file) {
            return new Promise((res, rej) => {
                /* Boilerplate to set up FileReader */
                const reader = new FileReader()
                reader.onload = (e) => {
                    /* Parse data */
                    const bstr = e.target.result
                    const wb = read(bstr, {type: 'binary'})
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0]
                    const ws = wb.Sheets[wsname]
                    /* Convert array of arrays */
                    const data = utils.sheet_to_json(ws)

                    res(data);
                }
                reader.onerror = (e) => {
                    rej(e);
                };
                reader.readAsBinaryString(file[0].raw)
            });
        },
        changebranch() {
          this.$store.dispatch(GET_BRANCHES, {area_id: this.query.area_id, all: true}).then((res) => {
            this.branches = res.data;
          });
        },
        getAreas() {
            this.$store.dispatch(GET_LIST_AREAS, {}).then(data => {
                this.areas = data.data;
            });
        },
        getData() {
            this.clickCallback()
            this.callApiGetList()
        },
        clickCallback(obj) {
          if (this.query.range_date) {
            this.month = this.query.range_date.getMonth() + 1;
            this.year = this.query.range_date.getFullYear();
          } else {
            let lastMonth = moment().format('YYYY-MM-DD');
            this.date = new Date(lastMonth);
            this.month = this.date.getMonth() + 1;
            this.year = this.date.getFullYear();
          }
          this.query.month = this.month;
          this.query.year = this.year;

          this.page = obj
          this.$router.push({
            name: 'finance-actual-contributeds',
            query: {
              page: this.page,
              ...this.query
            }
          })
          this.callApiGetList()
        },
        pushParamsUrl() {
            if (this.query.range_date) {
            this.month = this.query.range_date.getMonth() + 1;
            this.year = this.query.range_date.getFullYear();
          } else {
            let lastMonth = moment().format('YYYY-MM-DD');
            this.date = new Date(lastMonth);
            this.month = this.date.getMonth() + 1;
            this.year = this.date.getFullYear();
          }
          this.query.month = this.month;
          this.query.year = this.year;
          this.$router.push({
            name: 'finance-actual-contributeds',
            query: {
              page: this.page,
              ...this.query
            }
          })
        },

        callApiGetList() {
          let payload = this.query;
          payload.page = this.page;
            this.$store.dispatch(GET_DATA_FINACE_ACTUAL, payload).then((res) => {
              this.dataFinance = res.data.data;
              this.last_page = res.data.last_page;
              this.is_disable_search = false;
              this.total = res.data.total;
            });
        },
        searchData() {
          this.page = 1
          this.is_disable_search = true
          this.pushParamsUrl()
          this.callApiGetList()
        },
        deleteFinace(id) {
          Swal.fire({
            title: "Bạn chắc chắn muốn xóa?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Đồng ý",
            cancelButtonText: "Không",
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire("Xóa", "", "success");
                this.$store.dispatch(DELETE_FINACE_ACTUAL, id).then((res) => {
                  this.callApiGetList();
              });
            }
          });
        },
        handleChange(file, fileList) {
            this.fileList = fileList
        },
        handleRemove() {
            this.fileList = []
        },
        convertKey(key) {
            let myString = removeVietnameseTones(key);
            let trimmedString = myString.trim();
            let formattedString = trimmedString.replace(/\s+/g, '_');
            return formattedString.toLowerCase()
        },
        submitUpload() {
            if (!this.fileList.length) {
                this.$notify({
                    title: 'Thất bại',
                    message: 'Chưa có file nào được chọn',
                    type: 'error'
                })
            }
            this.fileToJson(this.fileList).then(dataToImport => {
                this.logImport = true;
                this.listDataImport = dataToImport;
                if (!dataToImport.length) {
                    this.$notify({
                          title: 'Thất bại',
                          message: 'File đã chọn chưa có thông tin.',
                          type: 'error'
                      });
                      this.logImport = false;
                      return;
                }
                this.validateData(dataToImport);
                this.loadingImport = true;
                this.$store.dispatch(IMPORT_FINACE_ACTUAL, this.listDataImport).then((res) => {
                  this.listDataImport = res.data;
                  if (res.data.check == false) {
                    this.$notify({
                      title: 'Không Thành công',
                      message: res.message,
                      type: 'error'
                    })
                    this.loadingImport = false;
                    return;
                  }
                  if(res.data) {
                        this.dialogImport = false;
                        this.logImport = false;
                        this.$notify({
                            title: 'Thành công',
                            message: res.message,
                            type: 'success'
                        })
                        this.fileList = [];
                    this.loadingImport = false;
                      this.callApiGetList();
                      return;
                    }
                })
            })
        },
        
        validateData(dataToImport) {
          dataToImport.map((data) => {
              return data.error = [
                  typeof data.so_tien_chi != 'number' && data.so_tien_chi != null ? 'Số tiền không hợp lệ.' : '',
                  data.id_van_phong == undefined ? 'Mã văn phòng không được để trống' : '',
                  data.ma_nhan_su_de_xuat == undefined ? 'Mã nhân sự không được để trống' : '',
                  data.noi_dung_chi == undefined ? 'Nội dung chi không được để trống' : '',
                  data.so_tien_chi == undefined ? 'Số tiền chi không được để trống' : '',
                  data.thoi_gian_tinh_chi_van_phong == undefined ? 'Thời gian chi không được để trống' : ''
              ];
          });
        },
        exportFileMau() {
            this.$store.dispatch(EXPORT_FILE_DEFAULT_FINACE_ACTUAL).then((res) => {
                this.$notify({
                    title: 'Thành công',
                    message: res.message,
                    type: 'success'
                })
            }).catch((error) => {
                this.$notify({
                    title: 'Thất bại',
                    message: error.data.message,
                    type: 'error'
                })
            })
        },
    },
}
</script>

<style scoped>
.text-dark {
  color: red !important;
}
</style>