<template>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" id="classes_create" @submit.prevent="handleSubmit(onSubmit)">
            <div class="card card-custom">
                <!--begin::Header-->
                <div class="card-header">
                    <div class="card-title">
                        <h3 class="card-label">
                            Sửa lớp học
                        </h3>
                    </div>
                    <div class="card-toolbar">
                        <router-link class="font-weight-bold font-size-3  btn btn-secondary"
                            :to="{ name: 'class-index' }"><i class="fas fa-angle-double-left"></i> Quay lại
                        </router-link>
                    </div>
                </div>
                <!--end::Header-->
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Tên lớp
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider vid="name" name="Tên lớp" rules="required" v-slot="{ errors, classes }">
                                    <input type="text" ref="name" name="name" class="form-control"
                                        v-model="classes_obj.name" placeholder="Tên lớp" :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Khóa học
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Khóa học" rules="required" v-slot="{ errors, classes }">
                                    <multiselect v-model="cacheCourse" :options="courses" placeholder="Khóa học"
                                        label="name" track-by="name" :class="classes" @input="setStateCourse($event)">
                                    </multiselect>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Sĩ số
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Sĩ số" rules="required|min_value:0" v-slot="{ errors, classes }">
                                    <input type="number" ref="name" name="name" class="form-control"
                                        v-model="classes_obj.number_student" placeholder="Tên lớp" :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Số bài học
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Số bài học" rules="required|min_value:0"
                                    v-slot="{ errors, classes }">
                                    <input type="number" ref="name" name="name" class="form-control"
                                        v-model="classes_obj.number_lesson" placeholder="Tên lớp" :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Số bài kiểm tra
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Số bài kiểm tra" rules="required|min_value:0"
                                    v-slot="{ errors, classes }">
                                    <input type="number" ref="name" name="name" class="form-control"
                                        v-model="classes_obj.number_test" placeholder="Tên lớp" :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Chi nhánh
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Chi nhánh" rules="required" v-slot="{ errors, classes }">
                                    <multiselect v-model="cacheBranch" :options="branches" placeholder="Chi nhánh"
                                        label="name" track-by="name" :class="classes" :hide-selected="true"
                                        @input="setStateBranch($event)"></multiselect>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Phòng học
                                    <span class="text-danger"></span></label>
                                <multiselect v-model="cacheRoom" :options="rooms" placeholder="Phòng học" label="name"
                                    track-by="name" :hide-selected="true" @input="setStateRoom($event)"></multiselect>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Khai giảng từ ngày
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Ngày bắt đầu" rules="required" v-slot="{ errors, classes }">
                                    <date-picker v-model="classes_obj.start_date" valueType="YYYY-MM-DD" format="DD-MM-YYYY"
                                        :class="classes"></date-picker>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Ngày kết thúc dự kiến
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Ngày kết thúc" rules="required" v-slot="{ errors, classes }">
                                    <date-picker v-model="classes_obj.end_date" valueType="YYYY-MM-DD" format="DD-MM-YYYY"
                                        :class="classes"></date-picker>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Giảng viên
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Giảng viên" rules="required" v-slot="{ errors, classes }">
                                    <multiselect v-model="cacheTeacher" :options="teachers" placeholder="Giảng viên"
                                        label="name" track-by="name" :class="classes" @input="setStateTeacher($event)">
                                    </multiselect>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Trợ giảng
                                    <span class="text-danger"></span></label>
                                <multiselect v-model="cacheTutors" :options="tutors" placeholder="Trợ giảng" label="name"
                                    track-by="name" @input="setStateTutors($event)"></multiselect>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>
                                    <span class="text-danger"></span></label>
                                <div class="col-9 col-form-label">
                                    <div class="checkbox-inline">
                                        <label class="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" v-model="classes_obj.status">
                                            <span></span>Hiển thị</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Lịch học
                                    <span class="text-danger">*</span></label>
                                <multiselect :close-on-select="false" v-model="cacheSchedule" :options="schedules"
                                    placeholder="Lịch học" label="name" track-by="name" multiple
                                    @input="setStateSchedule($event)"></multiselect>
                            </div>
                        </div>
                    </div>
                    <ScheduleItem v-for="(item, index) in classes_obj.schedules" :key="index" :item="item" :index="index">

                    </ScheduleItem>
                </div>
                <!--Thông tin cấp trên-->
                <div class="separator separator-dashed my-5"></div>
                <div class="card-header">
                    <div class="card-title align-items-start flex-column">
                        <button type="submit" class="btn btn-success mr-2">
                            Cập nhật
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>
<script>
import ScheduleItem from './ScheduleItem'
import Multiselect from 'vue-multiselect'
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import { GET_COURSES } from "../../../core/services/store/course/course.module";
import { Money } from 'v-money'
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
//editor
import ApiService from "@/core/services/api.service";
import { VueEditor, Quill } from 'vue2-editor'
import ImageResize from 'quill-image-resize-vue';
import { ImageDrop } from 'quill-image-drop-module';
import { GET_BRANCHES } from "../../../core/services/store/center/branch.module";
import {
    GET_TEACHERS_OR_TUTORS,
    GET_ClASS_BY_ID, UPDATE_CLASS, GET_ROOMS
} from "../../../core/services/store/course/classes.module";

const _ = require('lodash');

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
export default {
    components: {
        Multiselect,
        DatePicker,
        VueEditor,
        Money,
        ScheduleItem
    },
    name: "ClassCreate",
    data() {
        return {
            editorSettings: {
                modules: {
                    imageDrop: true,
                    imageResize: {}
                }
            },
            is_show_room: false,
            class: {
                name: ''
            },
            classes_obj: {
                name: '',
                course_id: '',
                number_student: '',
                number_lesson: '',
                number_test: '',
                room_id: '',
                branch_id: '',
                teacher_id: '',
                tutors_id: '',
                status: true,
                start_date: '',
                end_date: '',
                schedules: []
            },
            courses: [],
            branches: [],
            teachers: [],
            tutors: [],
            rooms: [],
            schedules: [
                {
                    id: 1,
                    name: 'Thứ 2'
                },
                {
                    id: 2,
                    name: 'Thứ 3'
                },
                {
                    id: 3,
                    name: 'Thứ 4'
                },
                {
                    id: 4,
                    name: 'Thứ 5'
                },
                {
                    id: 5,
                    name: 'Thứ 6'
                },
                {
                    id: 6,
                    name: 'Thứ 7'
                },
                {
                    id: 7,
                    name: 'Chủ nhật'
                },
            ],

            // Cache
            cacheCourse: [],
            cacheBranch: [],
            cacheTeacher: [],
            cacheTutors: [],
            cacheRoom: [],
            cacheSchedule: [],
        }
    },
    mounted() {
        this.getClassById()
        this.getCourses()
        this.getBranches()
        this.getTeachers()
        this.getTutors()
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Quản lý khóa học", route: 'profile-1' },
            { title: "Sửa lớp học" }
        ]);
    },
    computed: {},
    methods: {
        getClassById() {
            let id = this.$route.params.id;
            this.$store.dispatch(GET_ClASS_BY_ID, id).then((res) => {
                if (!res.error) {
                    this.classes_obj = res.data
                    this.updateCacheState(res.data)
                    this.getRooms()
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getRooms() {
            this.$store.dispatch(GET_ROOMS, {
                limit: 10000,
                is_teacher: 1,
                branch_id: this.classes_obj.branch_id
            }).then((res) => {
                if (!res.error) {
                    this.rooms = res.data.data
                    this.rooms.unshift({
                        name: '--Chọn--'
                    })
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getTeachers() {
            this.$store.dispatch(GET_TEACHERS_OR_TUTORS, {
                limit: 10000,
                is_teacher: 1
            }).then((res) => {
                if (!res.error) {
                    this.teachers = res.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getTutors() {
            this.$store.dispatch(GET_TEACHERS_OR_TUTORS, {
                limit: 10000,
                is_tutors: 1
            }).then((res) => {
                if (!res.error) {
                    this.tutors = res.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },

        getCourses() {
            this.$store.dispatch(GET_COURSES, {
                limit: 10000
            }).then((res) => {
                if (!res.error) {
                    this.courses = res.data.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getBranches() {
            this.$store.dispatch(GET_BRANCHES, {
                limit: 10000,
                status: 1
            }).then((res) => {
                if (!res.error) {
                    this.branches = res.data.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        /*Set state*/
        setStateCourse($event) {
            let id = $event.id
            this.classes_obj.course_id = id
        },
        setStateBranch($event) {
            let id = $event.id;
            this.classes_obj.branch_id = id
            // SET room
            if (id != undefined) {
                this.is_show_room = true
                this.cacheRoom = [];
                this.classes_obj.room_id = '';
                this.getRooms()
            }
        },
        setStateTeacher($event) {
            if ($event == null) {
                this.classes_obj.teacher_id = ''
            } else {
                let id = $event.id
                this.classes_obj.teacher_id = id
            }
        },
        setStateTutors($event) {
            if ($event == null) {
                this.classes_obj.tutors_id = ''
            } else {
                let id = $event.id
                this.classes_obj.tutors_id = id
            }
        },
        setStateRoom($event) {
            let id = $event.id
            this.classes_obj.room_id = id
        },
        setStateSchedule($event) {
            this.addSchedule($event)
            this.minusSchedule($event)
        },
        /*End*/
        addSchedule(event) {
            event.map((value) => {
                let schedules = this.classes_obj.schedules
                let findId = _.findIndex(schedules, (o) => {
                    return o.id == value.id;
                })
                if (findId == -1) {
                    this.classes_obj.schedules.push({
                        id: value.id,
                        day: value.name,
                        start_date: '',
                        end_date: ''
                    })
                }
            })
        },
        minusSchedule(event) {
            let schedules = this.classes_obj.schedules
            schedules.map((value, index) => {
                let findId = _.findIndex(event, (o) => {
                    return o.id == value.id;
                })
                if (findId == -1) {
                    schedules.splice(index, 1);
                }
            })
        },
        onSubmit() {
            this.$store.dispatch(UPDATE_CLASS, this.classes_obj).then((res) => {
                if (res.error) {
                    this.$bvToast.toast(res.message, {
                        title: 'Cập nhật',
                        variant: 'danger',
                        solid: true
                    });
                } else {
                    this.$router.push({ name: "class-index" }).then(() => {
                        this.$bvToast.toast(res.message, {
                            title: 'Cập nhật',
                            variant: 'success',
                            solid: true
                        });
                    })
                }
            }).catch((e) => {
                if (e.data.data.message_validate_form) {
                    this.$refs.form.setErrors(e.data.data.message_validate_form);
                }
            });
        },
        updateCacheState(data) {
            if (data.course != null) {
                this.cacheCourse = data.course
            }
            if (data.branch != null) {
                this.cacheBranch = data.branch
            }
            if (data.teacher != null) {
                this.cacheTeacher = data.teacher
            }
            if (data.tutors != null) {
                this.cacheTutors = data.tutors
            }
            if (data.room != null) {
                this.cacheRoom = data.room
            }
            if (data.schedules != null) {
                data.schedules.map((value, index, array) => {
                    return value.name = value.day
                })
                this.cacheSchedule = data.schedules
            }
        }
    }
}
</script>
<style scoped>
.mx-datepicker {
    width: 100% !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
