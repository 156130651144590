<template>
  <div>
    <!-- Chi tiết hợp đồng -->
    <b-modal @hide="onHide" size="xl" :centered="true" :scrollable="true" ref="modal-contract-detail" hide-footer
             :title="title" class="modal-contract-detail" @show="getListGift">
      <div class="d-block text-center">
        <ValidationObserver ref="form">
          <form class="form" @submit.prevent="saveContract">
            <div class="row">
              <div class="col-md-7 shadow">
                <user-detail
                    :user="contract_detail.user"
                    :customer="contract_detail.customer"
                    :is_disable_phone="disablePhone"
                    :center="contract_detail.center"
                    :branch="contract_detail.branch"
                    :editBranch="editBranch"
                    :editName="editName"
                    :editEmail="editEmail"
                    :editPhone="editPhone"
                    :editBirthDate="editBirthDate"
                    :total_paid="contract_detail.total_paid"
                    :confirm="contract_detail.confirm"
                    :is_edutalk="is_edutalk"
                    @changeEditBranch="changeEditBranch"
                    @changeEditStatus="changeEditStatus"
                ></user-detail>
                <div class="row" v-if="showTuitionPayment">
                  <div class="col-md-6">
                    <div class="form-group">
                      <el-select class="w-100" v-model="tuitionPayment" placeholder="Hình thức đóng học phí">
                        <el-option
                            v-for="item in tuitionPayments"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <button class="btn btn-success" @click="addCourse">Thêm khóa học</button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <course-class v-for="(course_infor,index) in contract_detail.student_register_courses" :key="index"
                                :course_infor="course_infor"
                                :index="index"
                                :center_classes="center_classes"
                                :courses="courses"
                                :branchs="branchs"
                                :condition_courses="condition_courses"
                                :contract="contract_detail"
                                :courses-refund="coursesRefund"
                                :is-transferred="is_transferred"
                                @deleteCourse="deleteCourse"
                                @selectCourse="selectCourse"
                                @selectClass="selectClass"
                                class="col-md-6"
                  >
                  </course-class>
                </div>
              </div>
              <div class="col-md-5">
                <payment-detail :contract="contract_detail"
                                :course-price="coursePrice"
                                :vouchers="vouchers"
                                :promotions="promotions"
                                :is-withdraw-with-course="isWithdrawWithCourse"
                                :vouchers-for-contract="vouchersForContract"
                                :promotion-for-contract="promotionForContract"
                                :total-destroy-fee="totalDestroyFee"
                                :total-discount="totalDiscount"
                                :total-course-refund-fee="totalCourseRefundFee"
                                :total-contract-amount="totalContractAmount"
                                :total-course-register-fee="totalCourseRegisterFee"
                                :courses-refund="coursesRefund"
                                :is-transferred="is_transferred"
                                :total-promotion-after-transferred="totalPromotionAfterTransferred"
                                :gifts="listGift"
                                @showPromotions="showGift"
                                @showVouchers="showVouchers"
                                @removeContractPromotion="removeContractPromotion"
                                @removeContractVoucher="removeContractVoucher"
                                @changeCoursePrice="changeCoursePrice"
                                :promotionTotal="promotionTotal"
                >
                </payment-detail>
                <!-- <div class="gift-detail" v-if="gift_select && gift_select.description"> -->
                  <!-- <p class="ml-3 font-weight-bold">Quà tặng:</p> -->
                  <!-- <p class="ml-3 font-weight-bold">{{ gift_select.description }}</p> -->
                <!-- </div> -->
                <!-- Quà tặng -->
                <div v-if="is_edutalk && gift_select && checkGiff" class="payment-detail pt-5 table-responsive">
                  <div>
                    <h4 class="mb-3" style="font-weight: 700">Quà tặng</h4>
                  </div>
                  <div class="d-flex align-items-center">
                    <i  class="fas fa-minus-circle mr-2" style="cursor: pointer;" @click="deleteGift()"></i>
                    <div class="w-100">
                      <b-button v-b-toggle.payment-gift-detail class="m-1 m-0  button_show_value">{{ gift_select.name }} <i class="fas fa-chevron-down"></i></b-button>
                      <b-collapse id="payment-gift-detail">
                        <p v-html="gift_select?.description" class="mt-3 pl-2 ml-3 font-weight-bold"></p>
                      </b-collapse>
                    </div>
                  </div>

                </div>
                <div class="payment-information">
                  <payment-information :subcontracts="contract_detail.subcontracts" :refund-data="refundData"
                                       :gift-data="giftData" :is_edutalk="is_edutalk"></payment-information>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <footer class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="hideModal">Hủy bỏ</button>
        <button type="button" :disabled="checkTotal" class="btn btn-success" @click="saveContract">Xác nhận</button>
      </footer>
      <b-button ref="show-gift-list" v-b-modal.list-promotions size="lg" hidden>Open Third Modal</b-button>
      <b-button ref="show-vouchers-list" v-b-modal.list-vouchers size="lg" hidden>Open Third Modal</b-button>
    </b-modal>
    <!-- Danh sách quà tặng -->
    <b-modal id="list-promotions"
             :centered="true"
             :scrollable="true"
             noFade size="lg"
             ref="modal-list-promotions"
             hide-footer
             title="Danh sách quà tặng">
      <list-gifts :gifts="listGift"
                  :id_promotion_disable="id_promotion_disable"
                  :vouchersForContract="vouchersForContract"
                  :gift-select="gift_select"
                  @showPromotionDetail="showPromotionDetail"
                  @setPromotionForContract="setPromotionForContract"
                  @pickGift="pickGift"
                  @setVoucherForContract="setVoucherForContract"
                  @cancelGift="cancelGift"
      ></list-gifts>
      <b-button ref="show-promotion-detail" v-b-modal.promotion-detail size="sm" hidden>Open Third Modal</b-button>
    </b-modal>

		<!-- Danh sách voucher -->
		<b-modal id="list-vouchers" :centered="true" :scrollable="true" size="lg" ref="modal-list-vouchers" hide-footer
				 title="Danh sách voucher">
<!--			<list-vouchers :vouchers="vouchers"-->
<!--						   :id_voucher_disable="id_voucher_disable"-->
<!--						   @showVoucherDetail="showVoucherDetail"-->
<!--						   @setVoucherForContract="setVoucherForContract"-->
<!--			>-->
<!--			</list-vouchers>-->
      <div v-if="allCode[FLASH_SALE]?.length">
        <h1 style="font-size: 14px;font-weight: bold;text-align: left">Chương trình Flash sale</h1>
        <list-vouchers :vouchers="allCode[FLASH_SALE]"
                       :id_voucher_disable="id_voucher_disable"
                       @showVoucherDetail="showVoucherDetail"
                       @setListVoucherForContract="setListVoucherForContract(FLASH_SALE)"
                       :colorCode="'#FF8C22'"
                       :allCode='allCode' :type="FLASH_SALE"
        >
        </list-vouchers>
      </div>
      <div class="mt-5" v-if="allCode[PERIODIC_PROMOTIONS]?.length">
        <h1 style="font-size: 14px;font-weight: bold;text-align: left">Chương trình định kỳ</h1>
        <list-vouchers :vouchers="allCode[PERIODIC_PROMOTIONS]"
                       :id_voucher_disable="id_voucher_disable"
                       @showVoucherDetail="showVoucherDetail"
                       @setListVoucherForContract="setListVoucherForContract(PERIODIC_PROMOTIONS)"
                       :colorCode="'#1BC5BD'"
                       :allCode='allCode' :type="PERIODIC_PROMOTIONS"
        >
        </list-vouchers>
      </div>
      <div class="mt-5" v-if="allCode[SCHOOL_LINK]?.length">
        <h1 style="font-size: 14px;font-weight: bold;text-align: left">Chương trình liên kết trường</h1>
        <list-vouchers :vouchers="allCode[SCHOOL_LINK]"
                       :id_voucher_disable="id_voucher_disable"
                       @showVoucherDetail="showVoucherDetail"
                       @setListVoucherForContract="setListVoucherForContract(SCHOOL_LINK)"
                       :colorCode="'#3343D0'"
                       :allCode='allCode' :type="SCHOOL_LINK"
        >
        </list-vouchers>
      </div>
      <div class="mt-5" v-if="allCode[OTHER_SCHOOL]?.length">
        <h1 style="font-size: 14px;font-weight: bold;text-align: left">Chương trình khác</h1>
        <list-vouchers :vouchers="allCode[OTHER_SCHOOL]"
                       :id_voucher_disable="id_voucher_disable"
                       @showVoucherDetail="showVoucherDetail"
                       @setListVoucherForContract="setListVoucherForContract(OTHER_SCHOOL)"
                       :colorCode="'#D40000'"
                       :allCode='allCode' :type="OTHER_SCHOOL"
        >
        </list-vouchers>
      </div>
      <div class="mt-5" v-if="allCode[DIGITAL]?.length && +contract?.get_ma_gioi_thieu?.profile?.chi_nhanh_id === digitalBranch">
        <h1 style="font-size: 14px;font-weight: bold;text-align: left">Chương trình Digital</h1>
        <list-vouchers :vouchers="allCode[DIGITAL]"
                       :id_voucher_disable="id_voucher_disable"
                       @showVoucherDetail="showVoucherDetail"
                       @setListVoucherForContract="setListVoucherForContract(DIGITAL)"
                       :colorCode="'#0094ff'"
                       :allCode='allCode' :type="DIGITAL"
        >
        </list-vouchers>
      </div>
			<b-button ref="show-voucher-detail" v-b-modal.voucher-detail size="sm" hidden>Open Third Modal</b-button>
		</b-modal>

    <!-- Chi tiết mã giảm giá -->
    <b-modal id="promotion-detail" :centered="true" :scrollable="true" size="lg" ref="modal-promotion-detail"
             hide-footer title="Chi tiết mã giảm giá">
      <promotion-detail
		  :promotion="promotion_detail"
		  :gift_select="gift_select"
	  ></promotion-detail>
    </b-modal>

		<!-- Chi tiết voucher -->
		<b-modal id="voucher-detail" :centered="true" :scrollable="true" size="lg" ref="modal-promotion-detail" hide-footer
				 title="Chi tiết voucher">
			<voucher-detail :voucher="voucher_detail"></voucher-detail>
		</b-modal>

    <!-- Nạp rút tặng quà -->
    <b-modal @hide="onHide" size="xl" :centered="true" :scrollable="true" ref="modal-nap-rut" hide-footer
             title="Nạp - rút - tặng quà" class="modal-nap-rut">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-7">
            <user-detail
                :user="contract_detail.user"
                :customer="contract_detail.customer"
                :is_disable_phone="disablePhone"
                :is_disable_mail="disableEmail"
                :center="contract_detail.center"
                :branch="contract_detail.branch"
                :detail="contract_detail"
                :totalPaid="contract_detail.total_paid"
                :is_edutalk="is_edutalk"
                :tuitionPayments="tuitionPayments"
            ></user-detail>
            <div v-if="is_edutalk" class="row col-md-6">
              <div class="form-group">
                <label>Hình thức đóng học phí<span class="text-danger"></span></label>
                <el-select disabled class="w-100" v-model="tuitionPayment" placeholder="Hình thức đóng học phí">
                  <el-option
                      v-for="item in tuitionPayments"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-12">
              <b-card active-nav-item-class="font-weight-bold text-uppercase text-danger"
                      active-tab-class="font-weight-bold text-success" class="nap_rut_all_form">
                <b-tabs pills card>
                  <!-- Tab Nạp tiền -->
                  <b-tab title="Nạp tiền" active>
                    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                      <form class="form" @submit.prevent="handleSubmit(depositMoney)">
                        <div class="row">
                          <div class="col-md-12 d-lg-flex">
                            <div class="col-form-label checkbox-inline">
                              <label class="checkbox checkbox-success">
                                <input type="checkbox" name="Checkboxes5" v-model="type">
                                <span></span>Khách hàng nợ</label>
                            </div>
                          </div>
                          <!-- <div class="col-md-6 d-lg-flex">
                            <div class="col-form-label checkbox-inline" v-if="showInputInstallment">
                              <label class="checkbox checkbox-success">
                                <input type="checkbox" name="is_installment" v-model="is_installment"
                                       @change="handleInstallment">
                                <span></span>Trả góp</label>
                            </div>
                          </div> -->
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Số tiền nạp: <span class="text-danger">*</span></label>
                              <ValidationProvider name="Số tiền nạp" rules="excluded:0|min_value:1" mode="lazy"
                                                  v-slot="{ errors,classes }" vid="deposit_money">
                                <money v-model="amount" v-bind="money" class="form-control" :class="classes"></money>
                                <div class="fv-plugins-message-container">
                                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                      errors[0]
                                    }}
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Hình thức thanh toán: <span class="text-danger">*</span></label>
                              <ValidationProvider name="Hình thức thanh toán" rules="required"
                                                  v-slot="{ errors,classes }" vid="refund_note">
                                <el-select v-model="hinh_thuc_thanh_toan" name="refund_note" clearable filterable
                                           class="w-100 test-id" placeholder="Chọn hình thức thanh toán">
                                  <el-option
                                      v-for="item in hinh_thuc_thanh_toans"
                                      :key="item.name"
                                      :label="item.name"
                                      :value="item.name"
                                      :class="classes"
                                  >
                                    <span style="float: left">{{ item.name }}</span>
                                  </el-option>
                                </el-select>
                                <div class="fv-plugins-message-container">
                                  <div data-field="refund_note" data-validator="notEmpty" class="fv-help-block">
                                    {{ errors[0] }}
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div v-if="showInputInstallment && hinh_thuc_thanh_toan == 'Quẹt thẻ'" class="col-md-6">
                            <div class="form-group">
                              <label>Mục đích quẹt thẻ : <span class="text-danger">*</span></label>
                              <ValidationProvider name="Mục đích quẹt thẻ" rules="required"
                                                  v-slot="{ errors,classes }" vid="type_card">
                                <el-select @change="handleInstallment" v-model="type_card" name="type_card" clearable filterable
                                           class="w-100 test-id" placeholder="Chọn mục đích quẹt thẻ">
                                  <el-option
                                      v-for="item in type_cards"
                                      :key="item.id"
                                      :label="item.name"
                                      :value="item.id"
                                      :class="classes"
                                  >
                                    <span style="float: left">{{ item.name }}</span>
                                  </el-option>
                                </el-select>
                                <div class="fv-plugins-message-container">
                                  <div data-field="type_card" data-validator="notEmpty" class="fv-help-block">
                                    {{ errors[0] }}
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2" v-if="!is_transferred">
                          <div class="col-md-12">
                            <a href="javascript:" class="promotion-list" @click="showVouchers">
                              <strong>Danh sách voucher</strong>
                              <span class="promotion-count">{{ vouchers.length }}</span>
                            </a>
                          </div>
                        </div>
                        <div class="row mt-8" v-if="!is_transferred">
                          <div class="col-12 col-md-12">
                            <div class="row" v-for="(voucher,index) in vouchersForContract" :key="index">
                              <div class="col-2">
                                <p>Voucher {{ ++index }}:</p>
                              </div>
                              <div class="col-7">
                                <span class="promotion-icon"><i class="fas fa-minus-circle mr-2"
																style="cursor: pointer;"
																@click="removeContractVoucher(index)"></i></span>
																<span class="promotion-list"><strong>{{ voucher.name }}</strong></span>
															</div>
															<div class="col-3">
																<strong class="text-danger"
																		v-if="voucher.unit === 1 ">{{ voucher.value | toThousandFilter }} ₫</strong>
																<strong class="text-danger" v-else>{{ voucher.value }} %</strong>
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12 mt-3 text-lg-right btn-witdh" style="text-align: center">
														<button :disabled="is_disable" type="submit" class="btn btn-success">Nạp tiền</button>
													</div>
												</div>
											</form>
										</ValidationObserver>
									</b-tab>
									<!-- Tab Rút tiền -->
									<b-tab title="Rút tiền">
										<ValidationObserver v-slot="{ handleSubmit }" ref="form">
											<form class="form" @submit.prevent="handleSubmit(withdrawMoney)">
												<div class="row" v-if="is_cancel_course">
													<div class="col-md-12">
														<div class="form-group">
															<label>Rút cho khóa học <span class="text-danger"> *</span></label>
															<ValidationProvider name="Khóa học" rules="required" v-slot="{ errors,classes }"
																				vid="withdraw_course">
																<multiselect v-model="withdraw_course" :options="condition_courses"
																			 :close-on-select="true"
																			 placeholder="Chọn Khóa Học"
																			 label="name"
																			 track-by="name"
																			 multiple
																			 :class="classes"
																></multiselect>
																<div class="fv-plugins-message-container">
																	<div data-field="withdraw_course" data-validator="notEmpty" class="fv-help-block">
																		{{ errors[0] }}
																	</div>
																</div>
															</ValidationProvider>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label>Số tiền rút: <span class="text-danger">*</span></label>
															<ValidationProvider name="Số tiền rút" rules="required|excluded:0|min_value:1" mode="lazy"
																				v-slot="{ errors,classes }" vid="withdraw_money">
																<money v-model="withdraw_money" name="value" v-bind="money" class="form-control"
																	   :class="classes"
																></money>
																<div class="fv-plugins-message-container">
																	<div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
																			errors[0]
																		}}
																	</div>
																</div>
															</ValidationProvider>
														</div>
													</div>
													<div class="col-md-6">
														<div class="form-group">
															<label>Phí hủy khóa:
																<span class="" v-if="is_cancel_course"
																	  v-tooltip="{ content: 'Phí rút tiền sẽ được cộng trực tiếp vào giá trị hợp đồng của khách hàng và được chuyển về công ty' }">
                                    <i class="fas fa-info-circle"></i></span>
															</label>
															<ValidationProvider name="Phí hủy khóa" rules="min_value:0" v-slot="{ errors,classes }"
																				vid="reason">
																<money v-model="destroy_fee" name="value" v-bind="money" class="form-control"
																	   :class="classes"></money>
																<div class="fv-plugins-message-container">
																	<div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
																			errors[0]
																		}}
																	</div>
																</div>
															</ValidationProvider>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label>Lý do rút: <span class="text-danger">*</span></label>
															<ValidationProvider name="Lý do rút" rules="required" v-slot="{ errors,classes }"
																				vid="reason">
                                <el-select v-model="reason" name="reason" clearable filterable class="w-100 test-id" placeholder="Chọn hình lý do rút" @change="changeReason">
                                  <el-option
                                      v-for="item in listReason"
                                      :key="item.id"
                                      :label="item.name"
                                      :value="item.id"
                                      :class="classes"
                                  >
                                    <span style="float: left">{{ item.name }}</span>
                                  </el-option>
                                </el-select>
                                <div class="fv-plugins-message-container">
                                  <div data-field="refund_note" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                                </div>
															</ValidationProvider>
														</div>
													</div>
                          <div class="col-md-6" v-if="checkReason">
                            <div class="form-group">

                              <label>Chi tiết lý do: <span class="text-danger">*</span></label>
                              <ValidationProvider name="Chi tiết lý do" rules="required" v-slot="{ errors,classes }" vid="reason">

                                <textarea  class="form-control w-100" rows="3" v-model="withdraw_reason" maxlength="50"></textarea>
                                <div class="fv-plugins-message-container">
                                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 text-right">
                            <button :disabled="is_disable" type="submit" class="btn btn-success text-right">Rút tiền
                            </button>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </b-tab>
                  <!-- Tab Tặng Quà -->
                  <b-tab v-if="!is_edutalk" title="Tặng quà">
                    <ValidationObserver v-slot="{ handleSubmit }" ref="gift-form">
                      <form class="form" id="giftForm" @submit.prevent="handleSubmit(submitGift)">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Quà tặng hiện vật: <span class="text-danger"></span></label>
                              <input type="text" name="name" class="form-control" placeholder="Nhập quà tặng"
                                     v-model=gift_name>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Tặng tiền mặt: <span class="text-danger"></span></label>
                              <!--                                  <ValidationProvider name="Số tiền tặng" rules="min_value:0" mode="lazy" v-slot="{ errors,classes }" vid="value">-->
                              <money v-model="gift_money" name="value" v-bind="money" class="form-control"></money>
                              <!--                                    <div class="fv-plugins-message-container">-->
                              <!--                                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>-->
                              <!--                                    </div>-->
                              <!--                                  </ValidationProvider>-->
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Lý do tặng quà: <span class="text-danger">*</span></label>
                              <ValidationProvider name="Lý do tặng quà" rules="required" v-slot="{ errors,classes }"
                                                  vid="reason">
                                <input type="text" name="name" class="form-control" placeholder="Nhập lý do tặng quà"
                                       :class="classes"
                                       v-model=gift_reason>
                                <div class="fv-plugins-message-container">
                                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                      errors[0]
                                    }}
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 text-right">
                            <button :disabled="is_disable" type="submit" class="btn btn-success">Tặng</button>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </b-tab>
                  <!-- Tab Hoàn tiền -->
                  <b-tab title="Hoàn tiền">
                    <ValidationObserver v-slot="{ handleSubmit }" ref="refund-form">
                      <form class="form" id="refundForm" @submit.prevent="handleSubmit(submitRefund)">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Nhập số tiền hoàn: <span class="text-danger">*</span></label>
                              <ValidationProvider name="Số tiền hoàn" rules="required|excluded:0|min_value:1"
                                                  mode="lazy" v-slot="{ errors,classes }" vid="value">
                                <money v-model="refund_money" name="value" v-bind="money" class="form-control"></money>
                                <div class="fv-plugins-message-container">
                                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                      errors[0]
                                    }}
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Chương trình: <span class="text-danger">*</span></label>
                              <ValidationProvider name="Chương trình" rules="required" v-slot="{ errors,classes }"
                                                  vid="refund_note">
                                <el-select v-model="refund_note" name="refund_note" clearable filterable
                                           class="w-100 test-id" placeholder="Chọn chương trình">
                                  <el-option
                                      v-for="item in listRefundByBranch"
                                      :key="item.id"
                                      :label="item.name"
                                      :value="item.name"
                                      :class="classes"
                                  >
                                    <span style="float: left">{{ item.name }}</span>
                                  </el-option>
                                </el-select>
                                <div class="fv-plugins-message-container">
                                  <div data-field="refund_note" data-validator="notEmpty" class="fv-help-block">
                                    {{ errors[0] }}
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 text-right">
                            <button :disabled="is_disable" type="submit" class="btn btn-success">Hoàn</button>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </b-tab>
                </b-tabs>
              </b-card>
            </div>
          </div>
					<div class="col-md-5 mt-lg-0 mt-3 shadow">
						<payment-detail-nap-rut-form :contract="contract_detail"
																				 :course-price="coursePrice"
																				 :vouchers="vouchers"
																				 :promotions="promotions"
																				 :is-withdraw-with-course="isWithdrawWithCourse"
																				 :vouchers-for-contract="vouchersForContract"
																				 :promotion-for-contract="promotionForContract"
																				 :total-destroy-fee="totalDestroyFee"
																				 :total-discount="totalDiscount"
																				 :total-course-refund-fee="totalCourseRefundFee"
																				 :total-contract-amount="totalContractAmount"
																				 :total-course-register-fee="totalCourseRegisterFee"
																				 :courses-refund="coursesRefund"
																				 :is-transferred="is_transferred"
																				 :total-promotion-after-transferred="totalPromotionAfterTransferred"
																				 :tong-khuyen-mai-sau-huy="tongKhuyenMaiSauHuy"
																				 @changeCoursePrice="changeCoursePrice"
                                         :is_edutalk="is_edutalk"
                                         :gift_select="gift_select"
						></payment-detail-nap-rut-form>
						<div class="payment-information">
							<payment-information :subcontracts="contract_detail.subcontracts" :refund-data="refundData" :gift-data="giftData" :is_edutalk="is_edutalk"></payment-information>
						</div>
					</div>
				</div>
      </div>
      <b-button ref="show-vouchers-list" v-b-modal.list-vouchers size="lg" hidden>Open Third Modal</b-button>
    </b-modal>
    <program-create :show-modal="showModalProgram" @clicked="closeModalProgram"
                    @programCreateSuccess="programCreateSuccess"></program-create>
    <!-- <div>
      <b-modal id="bv-modal-example" hide-footer>
        <template #modal-title>
          Thông báo
        </template>
        <div class="d-block text-center">
          <p>{{titleNoti}}</p>
        </div>
        <div class="d-block text-center">
          <p>Bạn vui lòng hướng dẫn khách hàng viết Đơn đề xuất học theo nguyện vọng.</p>
        </div>
        <div class="d-flex justify-content-center">
          <router-link
              v-if="checkTestInput"
              class="border border-violet p-2 rounded mr-2 text-dark"
              :to="{ name: 'customer-crm-detail', params: { id : dataUserCustomer.id }, query: {best_customer_id: dataUserCustomer.best_customer ? dataUserCustomer.best_customer.id : ''}}"
          >Xem kết quả test
          </router-link>
          <button class="bg-violet text-white px-6 py-2 rounded" block @click="$bvModal.hide('bv-modal-example'), understood = true">Đã hiểu</button>
        </div>
      </b-modal>
    </div> -->
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import CourseClass from "./CourseClass";
import ProgramCreate from "./modal-contract/ProgramCreate";
import vueCheckboxToggle from 'vue-checkbox-toggle';
import PaymentInformation from "./modal-contract/PaymentInformation";
import ListVouchers from "./modal-contract/ListVouchers";
import ListPromotions from "./modal-contract/ListPromotions";
import PromotionDetail from "./modal-contract/PromotionDetail";
import VoucherDetail from "./modal-contract/VoucherDetail";
import UserDetail from "./modal-contract/UserDetail";
import PaymentDetail from "./modal-contract/PaymentDetail";
import PaymentDetailNapRutForm from "./modal-contract/PaymentDetailNapRutForm";
import {
	GET_PROMOTIONS_BY_CONTRACT,
	GET_VOUCHERS_BY_CONTRACT, LIST_GIFT
} from "@/core/services/store/contract/promotions.module";
import {
  DEPOSIT_MONEY, LIST_COURSE_REFUND,
  UPDATE_CONTRACT_BEFORE_DEPOSIT,
  WITHDRAW_MONEY,
  GET_REASON_WITHDRAWAL,
  NOT_DONE,
  DONE,
  UNDUPLICATED
} from "@/core/services/store/contract/contracts.module";
import {GET_TUITION_PAYMENT} from "@/core/services/store/installment/installment.module";
import Swal from "sweetalert2";
import {Money} from 'v-money';
import {CREATE_REFUND} from "@/core/services/store/contract/studentAmount.module";
import '@/assets/sass/custom/tai-chinh/contractForm.scss';
import {GET_CLASSES} from "@/core/services/store/course/classes.module";
import {GET_COURSES} from "@/core/services/store/course/course.module";
import {mapGetters} from "vuex";
import {LIST_CONTRACT_PROGRAMS} from "@/core/services/store/contract/contract-program.module";
import "@/assets/sass/contract/contractForm.scss";
import ListGifts from "@/view/pages/business/modal-contract/ListGifts";
import {DIGITAL, FLASH_SALE, OTHER_SCHOOL, PERIODIC_PROMOTIONS, SCHOOL_LINK} from "@/core/option/contractOption";
import {CodeModel} from "@/view/pages/contracts/CodeModel/code";
import {GET_LIST_BRANCHES} from "../../../core/services/store/center/branch.module";

const _ = require('lodash');

export default {
  components: {
    ListGifts,
    ProgramCreate,
    Multiselect,
    CourseClass,
    Money,
    vueCheckboxToggle,
    PaymentInformation,
    ListPromotions,
    ListVouchers,
    PromotionDetail,
    VoucherDetail,
    UserDetail,
    PaymentDetail,
    PaymentDetailNapRutForm
  },
  props: {
    showModal: {default: false},
    contract: {
      type: Object,
      default() {
        return {}
      }
    },
    fromPage: {
      type: String,
      default: () => {
        return ''
      }
    },
    editBranch: {default: false},
    editName: {default: false},
    editEmail: {default: false},
    editPhone: {default: true},
    editBirthDate: {default: false},
    updateContract: {default: false},
    listRefundByBranch: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  name: "ContractForm",
  data() {
    return {
      is_show_promotions : false,
      is_disable : false,
      is_cancel_course : false,
      isWithdrawWithCourse : false,
      checkTotal : false,
      totalCourseRefundFee : 0,
      totalDestroyFee : 0,
      totalContractAmount : 0,
      totalWithdrawWithinCourse : 0,
      totalCourseRegisterFee : 0,
      tongKhuyenMaiSauHuy : 0,
      title: '',
      center_classes: [],
      coursesRefund: [],
      courses: [],
      coursePrice: [],
      center_id: '',
      /* recalculate when action */
      recalculate: 1,
      recalculateSubTotal: 1,
      check_general: false,
      disablePhone: false,
      disableEmail:true,
      hinh_thuc_thanh_toan: '',
      edit_branch: null,
      hinh_thuc_thanh_toans: [
        {
          name: 'Tiền mặt',
        },
        {
          name: 'Chuyển khoản'
        },
        {
          name: 'Quẹt thẻ'
        }
      ],
      type_cards: [
        {
          name: 'Trả góp',
          id: 1,
        },
        {
          name: 'Trả thẳng',
          id: 2,
        }
      ],
      /* detail variable */
      contract_detail: {},
      promotion_detail: {},
      voucher_detail: {},
      /* list variable */
      promotions: [],
      vouchers: [],
      /* list variable of contract */
      promotionForContract: '',
      vouchersForContract: [],
      /* condition variable */
      id_promotion_disable: '',
      id_voucher_disable: [],
      condition_courses: [],
      /* calculate variable  */
      totalDiscountByVoucher: 0,
      totalValueOfVoucher: 0,
      totalDiscount: 0,
      /* nạp rút tặng quà */
      amount: '',
      withdraw_course: [],
      withdraw_reason: '',
      withdraw_money: 0,
      khuyenMaiSauHuy: '',
      congNoSauRut: '',
      withdraw_type: '',
      destroy_fee: 0,
      gift_name: '',
      gift_money: '',
      gift_reason: '',
      type: '',
      refundData: [],
      giftData: [],
      subcontracts: [],
      /* refund variable */
      refund_money: '',
      refund_note: '',
      showModalProgram: false,
      showModalListCourses: false,
      /* v-money */
      money: {
        decimal: ',',
        thousands: ',',
        prefix: '',
        suffix: ' VNĐ',
        precision: 0,
        masked: false,
      },
      is_installment: '',
      tuitionPayment: '',
      tuitionPayments: [],
      showTuitionPayment: false,
      showInputInstallment: false,
      is_transferred: false,
      totalPromotionAfterTransferred: 0, // tổng khuyến mại sau chuyển nhượng
      listGift: [],
      gift_select: null,
      is_edutalk : false,
      listReason: [],
      reason: '',
      checkReason: false,
      contentReason: '',
      titleNoti: '',
      checkTestInput: true,
      dataUserCustomer: {},
      understood: false,
      SCHOOL_LINK:SCHOOL_LINK,
      FLASH_SALE:FLASH_SALE,
      PERIODIC_PROMOTIONS:PERIODIC_PROMOTIONS,
      OTHER_SCHOOL:OTHER_SCHOOL,
      allCode:{},
      checkGiff: true,
      DIGITAL: DIGITAL,
      digitalBranch: 182,
      type_card : '',
      branchs: []
    }
  },
  mounted() {
    this.clearForm();
  },
  computed: {
    ...mapGetters(['getAllPrograms']),
    promotionTotal(){
      if(this.contract?.get_ma_gioi_thieu?.profile?.chi_nhanh_id === this.digitalBranch){
        return this.vouchers.length + this.listGift.length
      }
      return  (this.vouchers.length + this.listGift.length) - (this.allCode[DIGITAL]?.length??0)
    }
  },
  watch: {
    showModal() {
      if (this.showModal) {
        if (!this.updateContract) {
          this.title = "Nạp - rút - tặng quà";
        } else {
          this.title = "Sửa hợp đồng";
        }
        if (this.contract) {
          
          this.gift_select = this.contract.gift_select;
          if (this.gift_select) {
            this.gift_select.description = this.gift_select.description?.replace(/\n/g, '<br>');
          }
          this.center_id = this.contract.center_id;
          this.tuitionPayment = this.contract.tuition_payment != null ? this.contract.tuition_payment.id : '';

          this.showInputInstallment = false;
          this.disableEmail = true;
          this.is_edutalk = false;
          if (this.contract.center && this.contract.center.is_edutalk === 1) {
            this.showInputInstallment = true
            this.disableEmail = false;
            this.is_edutalk = true
          }
          this.getCourses();
          this.getListProgram();
          this.listCourseRefund();
          this.checkOpenModal();
          this.getTuitionPayments();
          if (this.fromPage === 'nap-rut') {
            this.disablePhone = true;
          }
          if (this.fromPage === 'danh-sach-hd') {
            this.showTuitionPayment = true
          }
          // set các giá trị cho việc hiển thị
          this.contract_detail = this.contract;
          this.getListBranch();
          this.refundData = this.contract_detail.log_refund.filter((item) => {
            return item.type_refund === 4;
          });
          this.giftData = this.contract_detail.log_refund.filter((item) => {
            return item.type_refund === 3;
          });
          this.subcontracts = this.contract_detail.subcontracts;
          if (this.contract_detail && this.contract_detail.user && this.contract_detail.user.profile) {
            this.contract_detail.user.profile.birthday = this.contract_detail.user.profile.birthday ?
                this.contract_detail.user.profile.birthday.split("-").reverse().join("-") : '';
          }
          this.contract_detail.user.profile.birthday = this.contract_detail.user.profile.birthday ?
              this.contract_detail.user.profile.birthday.split("-").reverse().join("-") : '';
          const subcontractsCourse = this.contract_detail.subcontracts.filter((item) => item.type === 1);
          //Xử lý những khóa học đã được rút
          let arrCourse = [];
          let courseWithDraw = subcontractsCourse.map(item => item.courses_relation).map(item => item.map(item => item.id));
          courseWithDraw.forEach(item => {
            item.forEach(value => arrCourse.push(value))
          })
          arrCourse = [...new Set(arrCourse)];
          let courseInCourseStudent = [];
          this.contract.student_register_courses.forEach((item) => {
            if (arrCourse.includes(item.course_id)) {
              courseInCourseStudent.push(item);
            }
          });
          //end
          //chuyển nhượng
          this.is_transferred = this.contract_detail.from_course_transfers.length > 0;
          //end

          const subcontractsWithinCourse = this.contract_detail.subcontracts.filter((item) => item.type === 2);
          //nếu là chuyển nhượng
          if (this.is_transferred) {
            this.totalCourseRefundFee = courseInCourseStudent.reduce((result, item) => result + (item.course_fee * (100 - item.course_sale) / 100) - item.promotion_fee, 0);
            this.totalPromotionAfterTransferred = this.contract_detail.total - this.contract_detail.total_after_voucher;
          } else { // rút tiền bth
            this.totalCourseRefundFee = courseInCourseStudent.reduce((result, item) => result + (item.course_fee * (100 - item.course_sale) / 100), 0);
          }
          this.totalCourseRefundFee = courseInCourseStudent.reduce((result, item) => result +(item.course_fee *(100 - item.course_sale)/ 100), 0);
          // Tổng khuyến mại sau hủy
          const khuyen_mai_nhung_khoa_da_huy = courseInCourseStudent.reduce((result, item) => result + item.promotion_fee , 0);
          this.tongKhuyenMaiSauHuy = this.totalDiscount - khuyen_mai_nhung_khoa_da_huy;
          //end
          this.totalDestroyFee = this.contract_detail.subcontracts.reduce((result, subcontract) => result + subcontract.destroy_fee, 0);
          this.totalContractAmount = this.contract_detail.total_after_voucher;
          this.totalCourseRegisterFee = this.contract.student_register_courses.reduce((result, item) => {
            return result + (item.course_fee * (100 - item.course_sale) / 100)
          }, 0);
          this.coursePrice = this.contract.student_register_courses.map((item) => item.course_fee);
          this.promotionForContract = this.contract.vouchers.filter(item => item.type !== 0).shift();
          let vouchersForContract = [];
          if(this.promotionForContract) {
            vouchersForContract.push(this.promotionForContract)
          }
          this.contract.vouchers.filter(item => {
            if(item.type === 0) {
              vouchersForContract.push(item);
            }
          });
          this.vouchersForContract = vouchersForContract;
          this.reloadConditionCourses();
          this.calculateTotalValueForVoucher();
          this.reloadTotalDiscount();
          this.id_voucher_disable = this.vouchersForContract.map(item => item.id);
          this.id_promotion_disable = this.promotionForContract && this.promotionForContract.id;
          this.getListPromotion();
          this.getListVouchers();
          this.getReason();
        }
      }
    },
    recalculate(){
      this.totalCourseRegisterFee = this.contract.student_register_courses.reduce((result, item) =>
      { return result + item.fee}, 0);
      this.contract_detail.total = _.reduce(this.contract_detail.student_register_courses,function (result, value, key){
        result += value.course_fee * (1 - value.course_sale/100);
        return result;
      }, 0);
      this.recalculateSubTotal++;
    },
    recalculateSubTotal() {
      this.calculateTotalValueForVoucher();
      this.reloadTotalDiscount();
      this.contract_detail.total_after_voucher = this.contract_detail.total - this.totalDiscount;
      this.contract_detail.total_debt = this.contract_detail.total_after_voucher - this.contract_detail.total_paid;
      this.totalContractAmount = this.contract_detail.total_after_voucher;
      if (this.contract_detail.total_after_voucher < 0) {
        this.$message.warning('Tiền khóa học phải lớn hơn tiền khuyến mại! Bạn vui lòng thêm khóa học');
        this.checkTotal = true;
      } else {
        this.checkTotal = false;
      }
    },
    contract_detail: {
      handler() {
        if (this.contract_detail.id) {
          this.coursePrice = this.contract_detail.student_register_courses.map((item) => item.course_fee);
        }
      },
      deep: true,
      immediate: true
    },
    withdraw_money() {
      this.changeCongNo();
    },
    destroy_fee() {
      this.changeCongNo();
    },
    // khuyenMaiSauHuy() {
    //   this.is_disable = this.khuyenMaiSauHuy > this.totalDiscount || this.khuyenMaiSauHuy < 0;
    //   if (this.khuyenMaiSauHuy > this.totalDiscount || this.khuyenMaiSauHuy < 0) {
    //     this.$notify({
    //       type: 'warning',
    //       title: 'Cảnh báo',
    //       message: 'Tổng khuyến mại sau hủy phải lớn hơn 0 và nhỏ hơn tổng khuyến mại cũ'
    //     });
    //   }
    // }
  },
  methods: {
    deleteGift(){
      this.allCode[this.gift_select?.program_id] =  this.allCode[this.gift_select?.program_id].map((code)=>{
        return +code.id === +this.gift_select.id ? {...code,selected:false,hidden: false} : code;
      })
      this.gift_select = null
      this.contract.gift_select = null
      this.resetGift()
    },
    resetGift() {
      this.contract.gift_id = [];
      this.gift_select = null;
    },
    cancelGift(event) {
      this.resetGift();
      let index = this.getIndex(event);
      if (index == null) {
        return;
      }
      this.removeContractVoucher(index + 1);
    },
    getIndex(event) {
      let index = null;
      for (let i = 0; i < this.vouchersForContract.length; i++) {
        let item = this.vouchersForContract[i];
        if (item.id === event.id) {
          index = i;
        }
      }
      return index;
    },
    pickGift(event) {
      this.contract.gift_id.push(event.id);
      this.gift_select = event;
    },
    checkOpenModal() {
      if (this.contract.confirm === 0 || this.updateContract) {
        this.$refs['modal-contract-detail'].show();
        return;
      }

			if (this.contract.center.is_edutalk === 0 && this.contract.total_paid === 0) {
				this.title = "Kiểm tra thông tin hợp đồng";
				this.$refs['modal-contract-detail'].show();
				return;
			}

      this.$refs['modal-nap-rut'].show();
    },
    getTuitionPayments() {
      this.$store.dispatch(GET_TUITION_PAYMENT).then((response) => {
        this.tuitionPayments = response.data
      });
    },
    getListVouchers(branch_id = null) {
      this.$store.dispatch(GET_VOUCHERS_BY_CONTRACT,
          {
            center_id: this.contract_detail.center_id,
            branch_id: branch_id || this.contract_detail.branch_id,
            tuition_payment_id: this.contract_detail?.tuition_payment_id,
            quantity_course: this.contract_detail?.student_register_courses?.map((course)=>course.course_id).length,
            course_ids: this.contract_detail?.student_register_courses?.map((course)=>course.course_id)??[],
          })
          .then((res) => {
            this.vouchers = res.data;
            this.getListGift()
          });
    },
    getListPromotion(branch_id = null) {
      let courseIds = this.contract_detail.student_register_courses.map(item => item.course_id)
      this.$store.dispatch(GET_PROMOTIONS_BY_CONTRACT,
          {
            courses: courseIds,
            center_id: this.contract_detail.center_id,
            branch_id: branch_id || this.contract_detail.branch_id
          }
      ).then((res) => {
        this.promotions = res.data;
      });
    },
    changeEditBranch(branch_id) {
      this.contract_detail.branch_id = branch_id;
      this.promotionForContract = '';
      this.vouchersForContract = [];
      this.id_voucher_disable = [];
      this.edit_branch = branch_id;
      this.getListVouchers(branch_id);
      this.getListPromotion(branch_id);
      this.recalculate++;
      this.listGift = [];
      this.resetGift();
      this.getListGift();
      this.getCourses();
      this.contract.student_register_courses = [];
    },
    getListGift(type = '') {
      let courseIds = this.contract_detail.student_register_courses ? this.contract_detail.student_register_courses.map(item => item.course_id) : []
      let tuition_payment_id = this.contract.tuition_payment_id;
      if(type == 'change-tuition') {
        tuition_payment_id = this.tuitionPayment;
      }
      this.listGift = [];
      if (this.contract.branch_id) {
        this.$store.dispatch(LIST_GIFT, {
          'branch_id': this.contract.branch_id,
          'quantity_course': this.getQuantityCourse(),
          'tuition_payment_id': tuition_payment_id,
          course_ids: courseIds,
        }).then((response) => {
          this.listGift = response.data;
          let giftModel = this.listGift.map((gift)=>{
            return new CodeModel(gift,PERIODIC_PROMOTIONS,true)
          })
          let voucherModel = this.vouchers?.map((voucher)=>{
            return new CodeModel(voucher,voucher.program_id)
          })
          let ListProgramUsed = this.contract?.vouchers.length === 0 ? (this.contract?.gift_select ? [this.contract?.gift_select.program_id] : [] ) : this.contract?.vouchers.map((promotion)=>promotion.program_id)

          let code = (voucherModel.concat(giftModel)).map((voucher)=>{
            if(this.contract?.vouchers.find((e)=>e.id ==voucher.id ) || this.contract?.gift_select?.id==voucher?.id ){
              return  {...voucher,selected:true}
            }
            voucher.hidden = true;
            if(((voucher.typeCode == FLASH_SALE || voucher.typeCode == OTHER_SCHOOL ||voucher.typeCode == DIGITAL ) && ListProgramUsed[0] == voucher.typeCode) || ListProgramUsed[0] == undefined) {
               voucher.hidden = false;
            }
            return voucher
          }
          )
          this.allCode = _.groupBy(code,({typeCode})=>typeCode)
        });
      }
    },
    getQuantityCourse() {
      return this.total_course = this.contract.student_register_courses.filter((item) => {
        return item.course.id > 0;
      }).length;
    },
    changeEditStatus(status) {
      this.contract_detail.confirm = status;
    },
    showGift() {
      this.$refs['show-gift-list'].click();
    },
    showVouchers() {
      this.$refs['show-vouchers-list'].click();
    },
    showPromotionDetail(promotion) {
      this.promotion_detail = promotion;
      this.$refs['show-promotion-detail'].click();
    },
    showVoucherDetail(voucher) {
      this.voucher_detail = voucher;
      this.$refs['show-voucher-detail'].click();
    },
    setPromotionForContract(promotion) {
      this.recalculateSubTotal += 1;
      this.promotionForContract = promotion;
      this.id_promotion_disable = promotion.id;
      this.$message.success('Áp dụng mã khuyến mại thành công');
      this.$refs['modal-list-promotions'].hide();
    },
    setVoucherForContract(voucher) {
      if (!voucher.amount && voucher.amount != null) {
        this.$message.warning('Voucher bạn chọn đã hết lượt sử dụng');
        return;
      }
      this.vouchersForContract.push(voucher);
      this.id_voucher_disable.push(voucher.id);
      this.recalculateSubTotal += 1;
      // this.$message.success('Áp dụng voucher thành công');
      // this.$refs['modal-list-vouchers'].hide();
    },
    setListVoucherForContract(type){
      // if(type == PERIODIC_PROMOTIONS) {
        this.checkGiff = true;
      // }
      this.vouchersForContract = [];
      this.id_voucher_disable = [];
      this.resetGift()
      this.totalDiscount = 0
      this.allCode[type].filter((code)=>code.selected === true).forEach((item)=>{
        item.isUseGift ? this.pickGift(item) : this.setVoucherForContract(item)
      })
    },
    removeContractPromotion(index) {
      this.promotionForContract = '';
      this.id_promotion_disable = '';
      this.recalculateSubTotal += 1;
      this.vouchersForContract.splice(index - 1, 1);
      this.id_voucher_disable.splice(index - 1, 1);
      this.clearVoucher();
    },
    removeContractVoucher(index) {
      this.amount = this.amount - 1;
      this.recalculateSubTotal += 1;
      this.vouchersForContract.splice(index - 1, 1);
      this.id_voucher_disable.splice(index - 1, 1);
      this.clearVoucher();
    },
    onHide() {
      this.$emit("clicked", true);
      this.gift_reason = '';
      this.gift_money = '';
      this.gift_name = '';
      this.withdraw_money = 0;
      this.withdraw_reason = '';
      this.withdraw_course = [];
      this.destroy_fee = 0;
      this.amount = '';
      this.type = '';
      this.hinh_thuc_thanh_toan = '';
      this.type_card = '';
      this.is_installment = '';
    },
    clearForm() {
      this.title = '';
    },
    hideModal() {
      this.$refs['modal-contract-detail'].hide();
    },
    toggleModal() {
      // when the modal has hidden
      this.$refs['modal-contract-detail'].toggle('#toggle-btn');
    },
    selectClass: function (param) {
      const studentRegisterCourse = this.contract_detail.student_register_courses[param.index];
      studentRegisterCourse.class_id = param.class_id;
    },
    selectCourse: function (param) {
      const course = this.courses.find(item => item.id === param.course_id);
      const studentRegisterCourse = this.contract_detail.student_register_courses[param.index];
      studentRegisterCourse.course_id = param.course_id;
      studentRegisterCourse.chi_nhanh_hoc = param.branch_id;
      let courseSelects = this.contract_detail.student_register_courses
          .filter(item => item.course_id !== '')
          .map(item => item.course_id);
      if (courseSelects.filter((item) => item === param.course_id).length >= 2) {
        studentRegisterCourse.course_id = '';
        this.$message.warning('Bạn không thể chọn 2 khóa học trùng nhau trong cùng 1 hợp đồng');
      } else {
        studentRegisterCourse.course = course;
        studentRegisterCourse.course_id = course.id;
        studentRegisterCourse.course_fee = course.fee;
        studentRegisterCourse.course_sale = course.sale;
        studentRegisterCourse.fee = course.fee * (100 - course.sale) / 100;
        studentRegisterCourse.course_id = course.id;
      }
      this.reloadConditionCourses();
      this.getListVouchers();
      this.getListGift();
      this.recalculate++;
    },
    addCourse() {
      this.contract_detail.student_register_courses.push({
        contract_id: this.contract.id,
        course_id: '',
        class_id: '',
        course_fee: 0,
        course_sale: 0,
        dang_ky_ho: 0,
        xac_nhan: 1,
        waiting_status: '',
        fee: 0,
        status: 2,
        paid: 0,
        user_id: this.contract_detail.user_id,
        center_id: this.contract_detail.center_id,
        branch_id: this.contract_detail.branch_id,
        center_class: {
          id: '',
          course_id: '',
          name: ''
        },
        course: {
          id: '',
          name: '',
          fee: '',
          sale: '',
        },
        amount: 0
      })
    },
    deleteCourse(index) {
      this.recalculate++;
      if (this.contract_detail.student_register_courses.length > 1) {
        this.contract_detail.student_register_courses.splice(index, 1);
      } else {
        this.$message.warning('Hợp đồng phải có khóa học');
      }
      this.reloadConditionCourses();
      this.clearVoucher();
    },
    reloadConditionCourses() {
      this.condition_courses = _.reduce(this.contract_detail.student_register_courses, function (result, value, key) {
        result.push({
          id: value.course.id, name: value.course.name, fee: value.course.fee, sale: value.course.sale,
        });
        return result
      }, []);
    },
    calculateTotalValueForVoucher(){
      this.totalDiscountByVoucher = _.reduce(this.vouchersForContract, function (result, value, key){
        if (value.unit === 0){
          result += value.value;
        }
        return result;
      },0);
      this.totalValueOfVoucher = _.reduce(this.vouchersForContract, function (result, value, key){
        if (value.unit === 1){
          result += value.value;
        }
        return result;
      }, 0);
    },
    reloadTotalDiscount() {
      // let valuePromotion = this.promotionForContract ? this.promotionForContract.value : 0;
      const totalFeeStudentRegisterCourse = this.contract.student_register_courses.reduce((result, item) => result +(item.course_fee *(100 - item.course_sale)/ 100), 0);
      if (this.is_transferred) { // chuyển nhượng
        //tổng số khuyến mại chuyển đi
        let totalPromotionTransfer = this.contract_detail.from_course_transfers.reduce((result, item) => result + item.total_promotion_transfer, 0);
        this.totalDiscount = this.contract_detail.total - this.contract_detail.total_after_voucher + totalPromotionTransfer;
        return;
      }
			this.totalDiscount = this.totalValueOfVoucher + (this.totalCourseRegisterFee * this.totalDiscountByVoucher / 100);
		},
    changeCoursePrice(index) {
      this.contract_detail.student_register_courses[index].course_fee = this.coursePrice[index];
      this.contract_detail.student_register_courses[index].fee = this.coursePrice[index] * (100 - this.contract.student_register_courses[index].course_sale) / 100;
      this.recalculate++;
    },
    saveContract() {
        this.is_disable = true;
        this.contract.vouchers = this.vouchersForContract;
        this.contract_detail.voucherIds = this.id_voucher_disable;
        if (this.showTuitionPayment) {
          this.contract_detail.tuition_payment_id = this.tuitionPayment
        }
        if (this.id_promotion_disable) this.id_voucher_disable.push(this.id_promotion_disable);
        this.contract_detail.voucherIds = this.id_voucher_disable;
        this.contract_detail.voucherIds = this.contract_detail.voucherIds.filter((item) => {
          return item != this.contract.gift_id[0] ?? null;
        });
        delete this.contract_detail.created_at
        delete this.contract_detail.updated_at
        this.$refs.form.validate().then(success => {
          if (!success) {
            return false;
          } else {
            Swal.fire({
              title: "Vui lòng kiểm tra kỹ thông tin hợp đồng?",
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: "Đồng ý",
              cancelButtonText: "Không",
            }).then((result) => {
              if (result.isConfirmed) {
                this.contract_detail.branch_id = this.edit_branch || this.contract_detail.branch_id;
                this.$store.dispatch(UPDATE_CONTRACT_BEFORE_DEPOSIT, {id: this.contract.id, params: this.contract_detail}
                ).then((res) => {
                  this.hideModal();
                  // nếu từ trang nạp rút thì mở popup nạp rút
                  if (this.fromPage === 'nap-rut') {
                    this.contract_detail = res.data;
                    this.$refs['modal-nap-rut'].show();
                  }
                  Swal.fire("Cập nhật thành công", "", "success");
                }).catch((response) => {
                  if (response?.data?.data?.message_validate_form) {
                    this.$refs.form.setErrors(response.data.data.message_validate_form);
                    return;
                  }
                  this.noticeMessage('error', 'Thất bại', response.data?.message);
                }).finally(() => this.is_disable = false)
              }
            });
          }
        });
      },
    actionNotBranch(){
      if(!this.contract_detail.branch_id) {
        this.noticeMessage('error', 'Thất bại', "Không có chi nhánh!");
        return false;
      }
      return true;
    },
    depositMoney() {
      if (!this.actionNotBranch()) {
        return; // Exit the function if branch ID is not present
      }
      this.contract_detail.amount = this.amount;
      if (this.id_promotion_disable) this.id_voucher_disable.push(this.id_promotion_disable);
      this.contract_detail.voucherIds = this.id_voucher_disable;
      this.contract_detail.hinh_thuc_thanh_toan = this.hinh_thuc_thanh_toan;
      this.is_disable = true;
      this.contract_detail.type = this.type ? 3 : 1;
      this.contract_detail.is_installment = this.is_installment;
      this.contract_detail.understood = this.understood;
      delete this.contract_detail.created_at
      delete this.contract_detail.updated_at
      this.$store.dispatch(DEPOSIT_MONEY, {
        id: this.contract_detail.id,
        params: this.contract_detail
      }).then((res) => {
        this.$emit('deposit');
        this.$refs['modal-nap-rut'].hide();
        this.amount = '';
        this.type = '';
        this.hinh_thuc_thanh_toan = '';
        this.type_card = '';
        this.is_installment = ''
        this.noticeMessage('success', 'Thành công', res.message);
      }).catch((response) => {
        switch (response.data?.data) {
          case NOT_DONE:
            // this.$bvModal.show('bv-modal-example');
            this.titleNoti = 'Khách hàng chưa được kiểm tra test đầu vào!';
            this.checkTestInput = false;
            break;
          default:
            // this.$bvModal.show('bv-modal-example');
            this.dataUserCustomer = response.data.data;
            this.titleNoti = 'Khoá học đầu tiên trong hợp đồng của khách hàng không khớp với Khoá học gợi ý khi test đầu vào.';
            this.checkTestInput = true;
            break;
        }
        if (response.data?.data == NOT_DONE) {
          return;
        }
        if (response.data?.data == UNDUPLICATED) {
          this.$bvModal.show('bv-modal-example')
          return;
        }
        this.noticeMessage('error', 'Thất bại', response.data?.message);
      }).finally(() => {
        this.is_disable = false;
      });
    },
    withdrawMoney() {
      if (!this.actionNotBranch()) {
        return; // Exit the function if branch ID is not present
      }
      this.withdraw_type = this.withdraw_course.length > 0 ? 1 : 2;
      let courses = this.withdraw_type === 1 ? _.reduce(this.withdraw_course, function (result, value) {
        result.push(value.id);
        return result;
      }, []) : [];
      if (this.is_cancel_course) {
        Swal.fire({
          title: "Việc rút tiền và hủy khóa sẽ dẫn việc thay đổi giá trị hợp đồng, công nợ khách hàng. Bạn vẫn muốn tiếp tục chứ?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Đồng ý",
          cancelButtonText: "Không",
        }).then((result) => {
          if (result.isConfirmed) {
            this.withdrawFunction(courses);
          }
        });
      } else {
        this.withdrawFunction(courses);
      }
    },
    withdrawFunction(courses) {
      this.is_disable = true;
      this.$store.dispatch(WITHDRAW_MONEY, {
        value: this.withdraw_money,
        reason: this.withdraw_reason,
        type_reason: this.reason,
        contract_id: this.contract_detail.id,
        type: this.withdraw_type,
        destroy_fee: this.destroy_fee,
        ben_thu_tien: 1,
        courses: courses,
      }).then((res) => {
        this.$refs['modal-nap-rut'].hide();
        this.withdraw_money = '';
        this.withdraw_reason = '';
        this.withdraw_course = [];
        this.destroy_fee = '';
        this.noticeMessage('success', 'Thành công', res.message);
      }).catch((response) => {
        if (response?.data?.data?.message_validate_form) {
          this.$refs['modal-nap-rut'].setErrors(response.data.data.message_validate_form);
        } else {
          this.noticeMessage('error', 'Thất bại', response.data.message);
        }
      }).finally(() => {
        this.is_disable = false;
      });
    },
    submitGift() {
      if (!this.actionNotBranch()) {
        return; // Exit the function if branch ID is not present
      }
      this.is_disable = true;
      this.$store.dispatch(CREATE_REFUND, {
        id: this.contract_detail.id,
        params: {
          user_id: this.contract_detail.user_id,
          type_refund: 3,
          amount: this.gift_money,
          note: this.gift_reason,
          gift: this.gift_name,
        }
      }).then((res) => {
        this.noticeMessage('success', 'Thành công', res.message);
        this.$refs['modal-nap-rut'].hide();
        this.gift_reason = '';
        this.gift_money = '';
        this.gift_name = '';
      }).catch((response) => {
        this.noticeMessage('error', 'Thất bại', response?.data?.message);
      }).finally(() => this.is_disable = false)
    },
    submitRefund() {
      if (!this.actionNotBranch()) {
        return; // Exit the function if branch ID is not present
      }
      this.is_disable = true;
      this.$store.dispatch(CREATE_REFUND, {
        id: this.contract_detail.id,
        params: {
          user_id: this.contract_detail.user_id,
          type_refund: 4,
          amount: this.refund_money,
          note: this.refund_note,
          ben_thu_tien: 1,
        }
      }).then((res) => {
        this.noticeMessage('success', 'Thành công', 'Hoàn tiền thành công');
        this.$refs['modal-nap-rut'].hide();
        this.refund_note = '';
        this.refund_money = '';
      }).catch((response) => {
        if (response?.data?.message) {
          this.noticeMessage('error', 'Thất bại', response.data?.message);
        }
      }).finally(() => {
        this.is_disable = false;
      })
    },
    getCourses: function () {
      this.$store.dispatch(GET_COURSES, {
        limit: 10000,
        status: 1,
        center_id: this.center_id,
        branch_id: this.contract_detail.branch_id,
        all: 1
      }).then((res) => {
        this.courses = _.map(res.data, (item) => {
          item = {id: item.id, name: item.name, fee: item.fee, sale: item.sale};
          return item;
        })
      });
    },
    openModalProgram: function () {
      this.showModalProgram = true;
    },

    closeModalProgram: function () {
      this.showModalProgram = false;
    },

    getListProgram: function () {
      this.$store.dispatch(LIST_CONTRACT_PROGRAMS, {})
        .then(() => {
        })
    },

    programCreateSuccess: function (res) {
      this.refund_note = res.name;
    },

    listCourseRefund: function () {
      this.$store.dispatch(LIST_COURSE_REFUND, {id: this.contract.id})
        .then((res) => {
          this.coursesRefund = res.data;
          this.isWithdrawWithCourse = this.coursesRefund.length > 0;
        });
    },
    handleInstallment() {
      if(this.type_card == 1) {
        this.is_installment = true;
      } else {
        this.is_installment = false;
      }
    },
    getReason() {
      this.$store.dispatch(GET_REASON_WITHDRAWAL, {})
        .then((res) => {
          this.listReason = res.data;

        });
    },
    changeReason() {
      if ([4, 5].includes(this.reason)) {
        this.checkReason = true;
        return;
      }
      this.checkReason = false;
    },
    changeTuitionPayment(){
      this.getListGift('change-tuition');
    },
    clearVoucher() {
      for (let i = 0; i < Object.values(this.allCode).length; i++) {
        Object.values(this.allCode)[i].forEach((item)=>{
          item.selected = false
          item.hidden = false
        })
      }
      this.vouchersForContract = [];
      this.id_voucher_disable = [];
      this.contract_detail.gift_id = [];
      this.contract_detail.id_promotion_disable = '';
      this.id_promotion_disable = '';
      this.gift_select = null;
      this.contract.gift_select = [];
      this.checkGiff = false;
      // voucherIds
    },
    getListBranch()
    {
      this.$store.dispatch(GET_LIST_BRANCHES, {
        center_id: this.contract_detail.center_id,
      }).then((res)=> {
        this.branchs = res.data;
        console.log(this.branchs, 'a')
      });
    }
    }
}
</script>
<style>
.bg-violet {
  background-color: #8950FC;
}
.border-violet {
  border-color: #8950FC !important;
}
</style>
