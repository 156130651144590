import ApiService from "@/core/services/api.service";
import {commonExport} from "@/core/common/commonHelper";
import {GET_BRANCH_CONTRACT} from "@/core/services/store/contract/contracts.module";
export const GET_CUSTOMER_CRM = "get-crm-customer";
export const GET_CUSTOMER_USER = "get-crm-user";
export const EXPORT_CUSTOMER_CRM = "crm-customer-export";
export const CUSTOMER_FINANCE = "customers/detail/finance";
export const CUSTOMER_STUDY = "customers/detail/study";
export const CUSTOMER_TICKET = "customers/detail/ticket";
export const CUSTOMER_TICKET_DETAIL = "customers/show/ticket";
export const CRM_KINH_DOANH = "crm-kinh-doanh";
export const CHANGE_NGUOI_GIOI_THIEU = "thay-doi-nguoi-gt";
export const UPDATE_CUSTOMER = "update";
export const HISTORY_CRM = "history-crm";
export const CUSTOMER_SCHEDULE_LIST = "customers/show/schedule";
export const GET_REFUND_BY_USER = "customers/refunds";
export const EXPORT_DSKH_TU_VAN = "customers/export-dskh-tu-van";
export const UPDATE_INFO_USER = "update-info-user";
export const GET_INFO_USER_PARENT = "get-info-user-parent";
export const GET_TEST_INPUT_USER = "customers/test-input";
export const ADD_USER_PARENT = "add-info-user-parent";
const mutations = {};
const state = {};
const getters = {};
const actions = {
  [HISTORY_CRM](context, payload) {
    return new Promise(resolve => {
      ApiService.query(`customers/${HISTORY_CRM}/${payload.id}`, payload).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response);
      });
    })
  },
  [UPDATE_CUSTOMER](context, payload) {
    return new Promise(resolve => {
      ApiService.post(`customers/${UPDATE_CUSTOMER}/${payload.customer_id}`, payload).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response);
      });
    })
  },

  [UPDATE_INFO_USER](context, payload) {
    console.log(payload,'data')
    return new Promise(resolve => {
      ApiService.post(`customers/${UPDATE_INFO_USER}`, payload).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response);
      });
    })
  },
  [CHANGE_NGUOI_GIOI_THIEU](context, payload) {
    return new Promise(resolve => {
      ApiService.query(`customers/${CHANGE_NGUOI_GIOI_THIEU}`, payload).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response);
      });
    })
  },
  [CRM_KINH_DOANH](context, payload) {
    return new Promise(resolve => {
      ApiService.query(`customers/${CRM_KINH_DOANH}/${payload.id}`, payload).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response);
      });
    })
  },
  [GET_CUSTOMER_CRM](context, payload) {
    return new Promise(resolve => {
      ApiService.query(`customers/${GET_CUSTOMER_CRM}`, payload).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response);
      });
    })
  },
  [GET_CUSTOMER_USER](context, payload) {
    return new Promise(resolve => {
      ApiService.query(`customers/user/${payload.id}`, {}).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response);
      });
    })
  },
  [GET_INFO_USER_PARENT](context, payload) {
    return new Promise(resolve => {
      ApiService.query(`customers/user-parent/${payload.id}`, {}).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response);
      });
    })
  },
  [ADD_USER_PARENT](context, payload) {
    return new Promise(resolve => {
      ApiService.post(`customers/add-user-parent`, payload).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response);
      });
    })
  },
  [GET_TEST_INPUT_USER](context, payload) {
    return new Promise(resolve => {
      ApiService.query(`${GET_TEST_INPUT_USER}/${payload.id}`, {}).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response);
      });
    })
  },
  [EXPORT_CUSTOMER_CRM](context, payload) {
    return new Promise(resolve => {
      ApiService.query(`customers/${EXPORT_CUSTOMER_CRM}`, payload).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response);
      });
    })
  },
  [EXPORT_DSKH_TU_VAN](context, payload) {
    return new Promise(resolve => {
      ApiService.query(`${EXPORT_DSKH_TU_VAN}`, payload).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response);
      });
    })
  },
  /**
   * api tài chính
   * @param context
   * @param id
   * @returns {Promise<unknown>}
   */
  [CUSTOMER_FINANCE](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${CUSTOMER_FINANCE}/${id}`).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        reject(response);
      });
    })
  },

  /**
   * api tài chính
   * @param context
   * @param id
   * @returns {Promise<unknown>}
   */
  [CUSTOMER_STUDY](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${CUSTOMER_STUDY}/${id}`).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        reject(response);
      });
    })
  },

  /**
   * api tài chăm sóc khách hàng
   * @param context
   * @param id
   * @returns {Promise<unknown>}
   */
  [CUSTOMER_TICKET](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${CUSTOMER_TICKET}/${id}`).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        reject(response);
      });
    })
  },

  /**
   * api chi tiết ticket
   * @param context
   * @param id
   * @returns {Promise<unknown>}
   */
  [CUSTOMER_TICKET_DETAIL](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${CUSTOMER_TICKET_DETAIL}/${id}`).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        reject(response);
      });
    })
  },

  /**
   * api chi tiết lớp học
   * @param context
   * @param id
   * @returns {Promise<unknown>}
   */
  [CUSTOMER_SCHEDULE_LIST](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${CUSTOMER_SCHEDULE_LIST}/${id}`).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        reject(response);
      });
    })
  },

  /**
   * api get qua tang theo user
   * @param context
   * @param id
   * @returns {Promise<unknown>}
   */

  [GET_REFUND_BY_USER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(`${GET_REFUND_BY_USER}`, payload).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        reject(response)
      });
    })
  },
}
export default {
  state,
  actions,
  mutations,
  getters
};
