import moment from "moment-timezone";

export class ProductionModel {
    constructor(obj) {
        this.id = obj?.id;
        this.code = obj.user_id ?? null;
        this.name = obj.user_name;
        this.phone = obj.user_phone;
        this.branch = obj.branch_name;
        this.course = obj.course_name;
        this.status = obj.status_student;
        this.timeStudy = obj?.time_available ? moment(obj.time_available).format('DD-MM-YYYY') : null;
        this.schedule = obj.student_desired_schedule;
        this.colorStatus = obj.status_student ? this.getColorStatus(obj.status_student) : obj.status_student;
        this.class_name = obj.class_name
    }
    getColorStatus(status) {
        switch (status.trim()) {
            case 'Chờ lớp':
                return 'yellow'
            case 'Đang học khóa dưới':
                return 'blue'
            default:
                return null
        }
    }
}