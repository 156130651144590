<template>
  <el-form :model="model" :rules="rules" ref="form" label-position="left" class="form-box">
    <el-form-item label="Chọn bài test" prop="test" class="w-100">
      <el-select v-model="model.test" clearable filterable placeholder="Chọn bài test" :disabled="isDisable">
        <el-option v-for="test in TEST"
                   :key="test.value"
                   :label="test.label"
                   :value="test.value">
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script>
import {TEST, POINT} from "../../../../core/option/testIncomeOption.js";

export default {
  components: {},
  props: {
    exam: {
      type: Object,
      default: () => {
        return {};
      }
    },
    testIncome: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      model: {
        test: '',
      },
      TEST,
      POINT,
      isDisable: false,
      rules: {
        test: [{
          required: true,
          message: 'Bài test là bắt buộc',
          trigger: 'change'
        }],
      }
    }
  },
  watch: {
    testIncome: {
      handler() {
        if (this.testIncome.type_test){
          this.model.test = this.testIncome.type_test;
          this.isDisable = true;
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          this.$emit('on-validate', valid, this.model)
          resolve(valid);
        });
      })
    }
  }

}
</script>

<style>
.form-box .el-form-item__label {
  padding: 0 0 0 5px;
}
.form-box .el-select{
  display: block;
}
.form-box .el-input__suffix {
  top: 22px;
}
</style>
