<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách học viên tiềm năng Cambridge</h3>
      </div>
      <div class="card-title">
        <button
            class="font-weight-bold font-size-3  btn btn-success"
            :class="{'spinner spinner-white spinner-left':is_disable_export_excel}"

            @click="exportExcel"
        >Xuất excel
        </button>
      </div>
    </div>
    <div class="card-body">
      <!--begin::Example-->
      <div class="row">
        <div class="col-md-3">
          <label>Học viên</label>
          <div class="form-group">
            <el-input placeholder="Nhập tên học viên, số điện thoại" v-model="query.keyword"></el-input>
          </div>
          <!--end::Form-->
        </div>
        <div class="col-md-2 mt-8">
          <div class="d-flex justify-content-between">
            <button class="btn btn-primary font-weight-bold mr-2"
                    :class="{'spinner spinner-white spinner-left': is_disable_search}"
                    @click="searchData">
              <i class="fa fa-search"></i> Tìm kiếm
            </button>
          </div>
        </div>
      </div>

      <div class="example mb-10">
        <div class="example-preview table-responsive leaderboard">
          <table class="table table-vertical-center">
            <thead class="thead-light">
            <tr>
              <th>Họ tên</th>
              <th>Số điện thoại</th>
              <th>Điểm số</th>
              <th>Xếp hạng</th>
              <th>Thời gian cập nhật</th>
            </tr>
            </thead>
            <tbody v-if="students.length === 0">
            <tr>
              <td colspan="5" class="text-center">
                Không có dữ liệu.
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr
                v-for="(rank, index) in students"
                :key="index"
            >
              <td>{{ rank?.cambridge_game_user?.name }}</td>
              <td>{{ rank?.cambridge_game_user?.is_fake ? null : rank.cambridge_game_user.phone }}
              </td>
              <td>{{ rank?.cambridge_game_user?.score }}</td>
              <td>
                <i v-if="rank.rank === 1" class="fas fa-trophy first-place-icon"></i>
                <i v-else-if="rank.rank ===  2" class="fas fa-trophy second-place-icon"></i>
                <i v-else-if="rank.rank === 3" class="fas fa-trophy third-place-icon"></i>
                <span v-else>{{ rank.rank }}</span>
              </td>
              <td>{{ rank?.cambridge_game_user?.score_updated_at | formatDateTimeVietnam }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mb-0 d-flex flex-row-reverse mr-10">
        <p class="mb-0">Tổng số bản ghi: <span class="text-primary ">{{ total }}</span></p>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import Multiselect from 'vue-multiselect'
import 'vue2-datepicker/index.css';
import {SUPER_DASH_USERS, SUPER_DASH_USERS_EXPORT} from "@/core/services/store/super-dash/super-dash";
import {formatDate, formatDateTime, formatDateTimeVietnam} from "@/core/filters";
import {exportExcel} from "../../../core/filters";

const _ = require('lodash');

export default {
  components: {Multiselect},
  name: "NewsIndex",
  data() {
    return {
      is_disable_search: false,
      is_disable_export_excel: false,
      news: [],
      students: [],
      status: [
        {
          value: 0,
          label: 'Ẩn',
        },
        {
          value: 1,
          label: 'Hiển thị',
        },
      ],
      // Paginate
      page: 1,
      last_page: 1,
      query: {
        keyword: ''
      },
      total: 0
    }
  },
  mounted() {
    this.getList();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý tin tức", route: 'profile-1'},
      {title: "Danh sách tin tức"}
    ]);
  },
  methods: {
    formatDateTimeVietnam,
    formatDateTime,
    formatDate,
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl()
      this.callApiGetList()
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'SuperDashIndex',
        query: {
          keyword: this.query.keyword
        }
      })
    },
    getList() {
      this.pullParamsUrl()
      this.callApiGetList()
    },
    pullParamsUrl() {

    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({path: '', query: {page: this.page}})
      this.callApiGetList()
    },
    exportExcel() {
      this.$store.dispatch(SUPER_DASH_USERS_EXPORT, {
        keyword: this.query.keyword
      }).then((res) => {
        if (!res.data.error) {
          this.handleExport(res.data);
        }
      }).finally(() => {
        this.is_disable_search = false;
      });
    },
    handleExport(data) {
      const HEADER_ROW_EXPORT_CLASSROOM_LIST = [
        {
          key: 'name',
          value: 'Họ tên',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'score',
          value: 'Điểm số',
        },
        {
          key: 'rank',
          value: 'Xếp hạng',
        },
        {
          key: 'updated_at',
          value: 'Thời gian cập nhật'
        }
      ];
      exportExcel(HEADER_ROW_EXPORT_CLASSROOM_LIST, data, "super-dash-list.xlsx", false)
    },
    callApiGetList() {
      this.$store.dispatch(SUPER_DASH_USERS, {
        keyword: this.query.keyword,
        page: this.page
      }).then((res) => {
        if (!res.data.error) {
          this.is_disable_search = false;
          this.students = res.data.data;
          this.last_page = res.data.last_page;
          this.total = res.data.total;
        }
      });
    },
  }
}
</script>
<style>

.leaderboard table {
  width: 100%;
  border-collapse: collapse;
}

.leaderboard th, .leaderboard td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.leaderboard tr:nth-child(even) {
  background-color: #f9f9f9;
}

.leaderboard th {
  background-color: #4CAF50;
  color: white;
}

/* Styles for top 3 */
.first-place {
  background-color: #FFD700; /* Gold */
  font-weight: bold;
  color: #000;
}

.second-place {
  background-color: #C0C0C0; /* Silver */
  font-weight: bold;
  color: #000;
}

.third-place {
  background-color: #CD7F32; /* Bronze */
  font-weight: bold;
  color: #000;
}

/* Trophy icon styling */
.first-place-icon {
  color: #FFD700; /* Gold */
}

.second-place-icon {
  color: #C0C0C0; /* Silver */
}

.third-place-icon {
  color: #CD7F32; /* Bronze */
}

/* General hover effect */
.leaderboard tr:hover {
  background-color: #ddd;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
