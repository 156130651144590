<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Báo cáo digital</h3>
        </div>
        <div class="action mt-5">
          <button class="btn btn-success btn-sm mr-4"><i class="fas fa-chart-line"></i>Xem biểu đồ</button>
          <ModalMarketingInputAmount
              :sources="sources"
              :centers="centers"
              @inputSuccess="getQuery"
          ></ModalMarketingInputAmount>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <div class="input-group row">
            <div class="col-md-2">
              <div class="form-group" data-app>
                <label for="">Chọn ngày</label>
                <b-form>
                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="dates"
                      transition="scale-transition"
                      offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <date-picker
                          @change="handleDate"
                          v-model="query.start_date"
                          type="date"
                          range
                          placeholder="Chọn ngày, tháng"
                          format="DD-MM-YYYY"
                          value-type="YYYY-MM-DD"
                      ></date-picker>
                    </template>
                    <v-date-picker
                        v-model="dates"
                        no-title
                        scrollable
                        range
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="saveRange">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </b-form>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Nguồn</label>
                <el-select v-model="query.source_id"
                           placeholder="Chọn nguồn"
                           clearable
                           filterable
                >
                  <el-option
                      v-for="item in sources"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Trung tâm</label>
                <el-select v-model="query.center_id"
                           placeholder="Chọn trung tâm"
                           clearable
                           filterable
                >
                  <el-option
                      v-for="item in centers"
                      :key="item.id"
                      :label="item.name_alias ? item.name_alias : item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Đội KD :
                  <span class="text-danger"></span></label>
                <treeselect
                    placeholder="Chọn..."
                    v-model="tree"
                    :multiple="false"
                    :options="sales"
                    :show-count="true"
                    :normalizer="normalizer"
                    @input="selectSale"
                >
                  <label slot="option-label"
                         slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                         :class="labelClassName">
                    <v-avatar size="20" style="border: 1px solid;">
                      <v-img
                          :src="node.raw.user_id ? node.raw.profile.account_type : node.raw.profile.account_type.image"></v-img>
                    </v-avatar>
                    {{ node.raw.name }}
                    <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                  </label>
                </treeselect>
              </div>
            </div>
            <div class="col-md-2 mt-8">
              <div class="d-flex justify-content-between">
                <button class="btn btn-primary" @click="searchReportDigital">Tìm kiếm</button>
              </div>
            </div>
          </div>
          <div class="b-table-sticky-column table-responsive">
            <table role="table" :aria-busy="isBusy" aria-colcount="0"
                   class="table table-hover table-bordered b-table" id="__BVID__822">
              <tr role="row" class="b-table-busy-slot" v-show="isBusy">
                <td colspan="3" role="cell" class="">
                  <div class="text-center text-danger my-2">
                    <span aria-hidden="true" class="align-middle spinner-border"></span>
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>
              <thead>
              <tr role="row">
                <th class="table-primary b-table-sticky-column" style="border-bottom: none">Chỉ số
                </th>
                <th class="font-size-10" style="min-width: 100px; vertical-align:middle">Tổng</th>
                <th v-show="!isBusy" class="font-size-10" v-for="(day,index) in days" :key="index">{{
                    day
                  }}
                </th>
              </tr>
              </thead>
              <tbody v-show="!isBusy">
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Số lead</td>
                <td class="font-size-10"><b>{{ totalLead }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{ renderLead(day) }}</td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Số lịch hẹn</td>
                <td class="font-size-10"><b>{{ totalScheduledAppointment }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">
                  {{ renderAppointment(day) }}
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Tỉ lệ lịch hẹn/ lead</td>
                <td class="font-size-10"><b>{{ totalAppointmentAndLead.toFixed(2) }}%</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">
                  {{ renderAppointmentRate(day).toFixed(2) }}%
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Số KH lên tư vấn</td>
                <td class="font-size-10"><b>{{ totalCustomerOnTest }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{
                    renderCustomerOnTest(day)
                  }}
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Số KH lên tư vấn/ lịch hẹn</td>
                <td class="font-size-10"><b>{{ totalCustomerAndAppointment.toFixed(2) }}%</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{
                    renderCustomerAndAppointment(day).toFixed(2)
                  }}%
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Số hợp đồng mới</td>
                <td class="font-size-10"><b>{{ totalNewContract }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">
                  {{ renderNewContract(day, 'so_hop_dong_moi') }}
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Doanh số HĐ mới</td>
                <td class="font-size-10"><b>{{ totalNewContractAmount | formatVND }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{
                    Math.abs(renderNewContract(day, 'ds_so_hop_dong_moi')) | formatVND
                  }}
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Doanh số HĐ nợ</td>
                <td class="font-size-10"><b>{{ totalDebtContractAmount | formatVND }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{
                    Math.abs(renderDebtContract(day, 'doanh_so_no')) | formatVND
                  }}
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Số tiền rút</td>
                <td class="font-size-10"><b>{{ Math.abs(totalWithDraw) | formatVND }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{
                    Math.abs(renderRefundMoreThan(day, 'withdraw')) | formatVND
                  }}
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Số tiền hoàn</td>
                <td class="font-size-10"><b>{{ Math.abs(totalRefund) | formatVND }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">
                  {{ Math.abs(renderRefundMoreThan(day, 'refund')) | formatVND }}
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Tổng doanh số</td>
                <td class="font-size-10"><b>{{ tongDoanhSo | formatVND }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{ renderDoanhSo(day) | formatVND }}
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Tỉ lệ HĐ mới/ lead</td>
                <td class="font-size-10"><b>{{ totalContractNewAndLead.toFixed(2) }}%</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{
                    renderContractNewAndLead(day).toFixed(2)
                  }}%
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Tỉ lệ HĐ mới/ lịch hẹn</td>
                <td class="font-size-10"><b>{{ totalContractNewAndAppointment.toFixed(2) }}%</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">
                  {{ renderContractNewAndAppointment(day).toFixed(2) }}%
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Tỉ lệ HĐ mới/ KH lên tư vấn</td>
                <td class="font-size-10"><b>{{ totalContractNewAndCustomerOnTest.toFixed(2) }}%</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{
                    renderContractNewAndCustomerOnTest(day).toFixed(2)
                  }}%
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Giá trị HĐ trung bình</td>
                <td class="font-size-10"><b>{{ mediumContract | formatPrice }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">
                  {{ renderMediumContract(day) | formatPrice }}
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Doanh số/ lượt đóng</td>
                <td class="font-size-10"><b>{{ tongDoanhSoLuotDong | formatPrice }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{
                    renderDoanhsoLuotDong(day) | formatPrice
                  }}
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Chi phí quảng cáo</td>
                <td class="font-size-10"><b>{{ totalSpendMarketing | formatPrice }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{
                    renderSpendMarketing(day) | formatPrice
                  }}
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Chi phí/ lead</td>
                <td class="font-size-10"><b>{{ totalSpendAndLead | formatPrice }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{
                    renderSpendAndLead(day) | formatPrice
                  }}
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Chi phí/ Hợp đồng</td>
                <td class="font-size-10"><b>{{ totalSpendAndContract |formatPrice }}</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{
                    renderSpendAndContract(day) | formatPrice
                  }}
                </td>
              </tr>
              <tr role="row">
                <td class="table-primary b-table-sticky-column">Chi phí/ Tổng doanh số</td>
                <td class="font-size-10"><b>{{ totalSpendAndRevenue.toFixed(2) }}%</b></td>
                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{
                    renderSpendAndRevenue(day).toFixed(2)
                  }}%
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <register-on-staff :showModal="showOnStaff" :dataDetail="dataDetail" :type-on-staff="typeOnStaff"
                         @clicked="clickOnStaff"></register-on-staff>
      <schedule-by-date-tvv :showModal="showScheduleDetail" :scheduleData="dataDetail" :type-action="typeAction"
                            :titleType="title" @clicked="clickOnSchedule"></schedule-by-date-tvv>
      <contract-detail-tvv-table :show-modal="showContractDetail" :contract-data="dataDetail"
                                 :type-action="typeAction"
                                 @clicked="clickOnContractDetail"></contract-detail-tvv-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {DSKH_DOI, REPORT_DIGITAL} from "../../../core/services/store/report/report.module";
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '@/assets/sass/custom/dskh-doi/dskh_doi.scss';

import RegisterOnStaff from "./RegisterOnStaff";
import ScheduleByDate from "./ScheduleByDate";
import ContractDetailTable from "./ContractDetailTable";
import ContractDetailTvvTable from "./tvv/ContractDetailTvvTable";
import ScheduleByDateTvv from "./tvv/ScheduleByDateTvv";
import {BIZFLY_GET_SOURCE} from "@/core/services/store/bizfly/source.module";
import {BIZFLY_GET_SALE_DIGITAL} from "@/core/services/store/bizfly/reportDigital.module";
import {GET_LIST_CENTERS} from "@/core/services/store/center/centers.module";
import ModalMarketingInputAmount from "@/view/pages/report/components/ModalMarketingInputAmount";

const _ = require('lodash');

export default {
  name: "ReportDigital",
  comments: {
    Treeselect
  },
  data() {
    return {
      /* query */
      query: {
        start_date: '',
        source_id: '',
        center_id: '',
        sale: ''
      },
      /* date-picker */
      dates: [],
      menu: false,
      isBusy: false,
      show_date: '',
      /* options */
      branchesByCenter: [],
      branches: [],
      areas: [],
      centers: [],
      sales: [],
      sources: [],
      tvv: [],
      days: [],
      tree: null,
      positions: [{name: 'Sale Expert', value: 5}, {name: 'Sale Member', value: 6}, {
        name: 'Sale Intern',
        value: 7
      }],
      /*Cache */
      cache_status: '',
      cache_current_account_type: '',
      cache_area: '',
      /*Loader */
      isLoading: false,
      fullPage: true,
      /*payload */
      payload: {
        limit: 1000000,
        status: 1,
        is_with: 0,
      },
      /* data response*/
      eventRegisters: [],
      dataDetail: [],
      title: '',
      leadersOnWork: [],
      scheduledAppointments: [],
      scheduledCancels: [],
      customersOnTest: [],
      benefit: [],
      debtContract: [],
      newContract: [],
      contractTvv: [],
      getSpendMarketing: [],
      contractInternal: [],
      refundMoreThan: [],
      idCreated: [],
      leads: [],
      /* is show variable */
      showOnStaff: false,
      typeOnStaff: false,
      showScheduleDetail: false,
      showContractDetail: false,
      typeAction: '',
      /* total variable */
      totalStaffOnWork: 0,
      totalRealStaffOnWork: 0,
      totalLeaderOnWork: 0,
      totalLead: 0,
      totalScheduledAppointment: 0,
      totalContractNewAndCustomerOnTest: 0, //Tỉ lệ HĐ mới/ KH lên tư vấn
      totalCustomerAndAppointment: 0, //Số KH lên tư vấn/lịch hẹn
      totalContractNewAndLead: 0, //Tỉ lệ HĐ mới/lead
      totalContractNewAndAppointment: 0, //Tỉ lệ HĐ mới/lịch hẹn
      totalAppointmentAndLead: 0, //Tỉ lệ lịch hẹn/lead
      tongDoanhSoLuotDong: 0, //Doanh số/lượt đóng
      totalSpendMarketing: 0, //Chi phí quảng cáo
      totalSpendAndLead: 0, //Chi phí/lead
      totalSpendAndContract: 0, //Chi phí/Hợp đồng
      totalSpendAndRevenue: 0, //Chi phí/Doanh thu
      countPaymentTurnContractNew: 0, //Số lượt đóng hợp đồng mới
      countPaymentTurnContractDebt: 0, //Số lượt đóng hợp đồng mới
      mediumContract: 0,
      totalScheduledCancel: 0,
      totalCustomerOnTest: 0,
      totalBenefit: 0,
      totalBenefitAmount: 0,
      totalDebtContract: 0,
      totalDebtContractAmount: 0,
      totalNewContract: 0,
      totalContractTvv: 0, // Giá trị hợp đồng
      totalNewContractAmount: 0,
      totalContractInternal: 0,
      totalContractInternalAmount: 0,
      totalWithDraw: 0,
      totalRefund: 0,
      contractClosing: 0,
      tongDoanhSo: 0, // Tổng doanh số
      percentNewSales: 0, // tỉ lệ doanh số mới
      //
      averageContract: 0
    };
  },
  components: {
    ModalMarketingInputAmount,
    ScheduleByDateTvv,
    ContractDetailTvvTable,
    Multiselect, DatePicker, RegisterOnStaff, ScheduleByDate, ContractDetailTable
  },
  computed: {},
  created() {
    this.isBusy = true;
    this.getSource();
    this.getCenter();
    this.setParams();
    this.getSalesDigital();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý tài chính"},
      {title: "Báo cáo digital"}
    ]);
  },
  methods: {
    handleDate() {
      this.getSalesDigital();
    },
    async getSalesDigital() {
      this.sales = [];
      this.query.sale = null;
      this.query.idCreated = [];
      let date = moment().startOf('month').format('YYYY-MM');
      if (this.query.start_date.length) {
        let start = this.query.start_date[0];
        if (start) {
          date = moment(start).format('YYYY-MM');
        }
      }
      await this.$store.dispatch(BIZFLY_GET_SALE_DIGITAL, {
        date: date
      }).then((res) => {
        this.sales = res.data;
      });
    },
    selectSale: function ($event) {
      this.query.sale = $event ? $event : '';
      this.pushParams();
    },
    getSource: function () {
      this.$store.dispatch(BIZFLY_GET_SOURCE).then((res) => {
        this.sources = res.data;
      });
    },
    getCenter: function () {
      this.$store.dispatch(GET_LIST_CENTERS).then((res) => {
        this.centers = res.data;
      });
    },
    searchReportDigital: async function () {
      await this.getSalesDigital();
      this.isBusy = true;
      this.pushParams();
      await this.getQuery();
    },
    getQuery: async function () {
      this.query.idCreated = [];
      this.query.action = undefined;
      this.query.date = undefined;
      await this.$store.dispatch(REPORT_DIGITAL, this.query).then((res) => {
        this.days = res.data.dataDays;
        this.idCreated = res.data.idCreated;
        this.leads = res.data.leads;
        this.newContract = res.data.newContract;
        this.eventRegisters = res.data.eventRegister;
        this.leadersOnWork = res.data.leaderRegister;
        this.scheduledAppointments = res.data.scheduledAppointment;
        this.customersOnTest = res.data.customersOnTest;
        this.scheduledCancels = res.data.scheduledCancel;
        this.benefit = res.data.benefit;
        this.debtContract = res.data.debtContract;
        this.contractInternal = res.data.contractInternal;
        this.getSpendMarketing = res.data.getSpendMarketing;
        this.refundMoreThan = res.data.refund;
        this.isBusy = false;
        this.countTotalStaffOnWork();
      });
    },
    saveRange: function () {
      this.$refs.menu.save(this.dates);
      let range = this.dates.sort(function (left, right) {
        return moment(left) - moment(right);
      }).map(item => moment(item).format('DD/MM/YYYY'));
      this.show_date = range.join(' - ');
      this.query.start_date = this.show_date;
    },
    setParams: async function () {
      this.query.start_date = this.$route.query.start_date ? this.$route.query.start_date : [];
      this.query.source_id = this.$route.query.source_id ? this.$route.query.source_id : '';
      this.query.center_id = this.$route.query.center_id ? this.$route.query.center_id : '';
      this.getQuery();
    },
    pushParams: function () {
      this.query.idCreated = [];
      this.$router.push({path: '/reports/digital', query: this.query});
    },
    onCancel: function () {
      console.log('User cancelled the loader.')
    },
    normalizer: function (node) {
      return {
        id: node.id,
        label: node.name,
      }
    },
    getFullDate: function (date) {
      let d = new Date();
      let year = d.getFullYear();
      date += '/' + year;
      return date;
    },

    /* all click function */
    clickOnStaff: function () {
      this.showOnStaff = false;
    },
    clickOnSchedule: function () {
      this.showScheduleDetail = false;
    },
    clickOnContractDetail: function () {
      this.showContractDetail = false;
    },
    /* all render function */
    renderData: function (day, data, type = '') {
      let value = _.find(data, function (item) {
        return item.day === day;
      });
      if (type === 'spend_digital' || type === 'so_quyen_loi' || type === 'gia_tri_hop_dong' || type === 'so_hop_dong_no' || type === 'so_hop_dong_moi' || type === 'so_hop_dong_noi_bo') {
        return value ? value.total : 0;
      } else if (type === 'quyen_loi' || type === 'doanh_so_no' || type === 'ds_so_hop_dong_moi' || type === 'ds_so_hop_dong_noi_bo') {
        return value ? value.amount : 0;
      } else if (type === 'withdraw') {
        return value && value.withdraw ? value.withdraw : 0;
      } else if (type === 'amount_contract') {
        return value && value.amount_contract ? value.amount_contract : 0;
      } else if (type === 'refund') {
        return value && value.refund ? value.refund : 0;
      } else if (type === 'count_amount') { // Số lượt đóng tiền
        return value && value.count_amount ? value.count_amount : 0;
      } else {
        return value ? value.number : 0;
      }
    },
    renderStaffOnWork: function (day) {
      return this.renderData(day, this.eventRegisters);
    },
    renderRealStaffOnWork: function (day) {
      let register = _.find(this.eventRegisters, function (item) {
        return item.day === day;
      });
      return register ? register.attendance : 0;
    },
    renderSchedule: function (day) {
      return this.renderData(day, this.leadersOnWork);
    },

    //Số lead
    renderLead: function (day) {
      return this.renderData(day, this.leads);
    },
    //Lịch hẹn
    renderAppointment: function (day) {
      return this.renderData(day, this.scheduledAppointments);
    },
    // Tỉ lệ lịch hẹn/lead
    renderAppointmentRate: function (day) {
      return this.renderLead(day) ? (this.renderAppointment(day) / this.renderLead(day)) * 100 : 0;
    },
    //Số KH lên tư vấn/lịch hẹn
    renderCustomerAndAppointment: function (day) {
      return this.renderAppointment(day) ? (this.renderCustomerOnTest(day) / this.renderAppointment(day)) * 100 : 0;
    },
    //Tỉ lệ HĐ mới/lead
    renderContractNewAndLead: function (day) {
      return this.renderLead(day) ? (this.renderNewContract(day, 'so_hop_dong_moi') / this.renderLead(day) * 100) : 0;
    },
    //Tỉ lệ HĐ mới/lịch hẹn
    renderContractNewAndAppointment: function (day) {
      return this.renderAppointment(day) ? (this.renderNewContract(day, 'so_hop_dong_moi') / this.renderAppointment(day)) * 100 : 0;
    },
    //Giá trị HĐ trung bình
    renderMediumContract: function (day) {
      let total = this.renderNewContract(day, 'so_hop_dong_moi');
      let amount = this.renderNewContract(day, 'amount_contract');
      return total ? amount / total : 0;
    },
    //Doanh số/lượt đóng
    renderDoanhsoLuotDong: function (day) {
      let doanhso_luotdong = this.renderNewContract(day, 'count_amount') + this.renderDebtContract(day, 'count_amount') + this.renderBenefit(day, 'so_quyen_loi');
      return doanhso_luotdong > 0 ? this.renderDoanhSo(day) / doanhso_luotdong : 0;
    },

    //Chi phí quảng cáo
    renderSpendMarketing: function (day) {
      return this.renderData(day, this.getSpendMarketing, 'spend_digital');
    },

    //Chi phí/lead
    renderSpendAndLead: function (day) {
      return this.renderLead(day) > 0 ? this.renderData(day, this.getSpendMarketing, 'spend_digital') / this.renderLead(day) : 0;
    },

    //Chi phí/lead
    renderSpendAndContract: function (day) {
      let totalContract = this.renderNewContract(day, 'so_hop_dong_moi');
      return totalContract > 0 ? this.renderData(day, this.getSpendMarketing, 'spend_digital') / totalContract : 0;
    },
    //Chi phí/Doanh thu
    renderSpendAndRevenue: function (day) {
      let tongDoanhSo = this.renderDoanhSo(day);
      let spend_digital = this.renderData(day, this.getSpendMarketing, 'spend_digital');
      return tongDoanhSo > 0 ? (spend_digital / tongDoanhSo) * 100 : 0;
    },

    //Tỉ lệ HĐ mới/ KH lên tư vấn render
    renderContractNewAndCustomerOnTest: function (day) {
      let totalContractNew = this.renderNewContract(day, 'so_hop_dong_moi');
      let customerOnTest = this.renderCustomerOnTest(day);
      return customerOnTest > 0 ? (totalContractNew / customerOnTest) * 100 : 0;
    },


    render: function (day) {
      return this.renderData(day, this.scheduledAppointments);
    },
    renderScheduleCancel: function (day) {
      return this.renderData(day, this.scheduledCancels);
    },
    renderCustomerOnTest: function (day) {
      return this.renderData(day, this.customersOnTest);
    },
    renderBenefit: function (day, type) {
      return this.renderData(day, this.benefit, type);
    },
    renderDebtContract: function (day, type) {
      return this.renderData(day, this.debtContract, type);
    },
    renderNewContract: function (day, type) {
      return this.renderData(day, this.newContract, type);
    },
    renderContractTvv: function (day, type) {
      return this.renderData(day, this.contractTvv, type);
    },
    renderContractClosing: function (day) {
      return this.renderCustomerOnTest(day) !== 0 ? Math.round(this.renderNewContract(day, 'so_hop_dong_moi') / this.renderCustomerOnTest(day) * 100) : 0;
    },
    renderAverageContract: function (day) {
      // let sales = this.renderBenefit(day, 'quyen_loi') + this.renderNewContract(day, 'ds_so_hop_dong_moi')
      //     + this.renderDebtContract(day, 'doanh_so_no')
      //     + this.renderRefundMoreThan(day, 'withdraw') + this.renderRefundMoreThan(day, 'refund');
      let sales = this.renderNewContract(day, 'ds_so_hop_dong_moi');
      let totalContractNew = Math.round(this.renderNewContract(day, 'so_hop_dong_moi'));
      return totalContractNew > 0 ? sales / totalContractNew : 0;
    },
    renderRefundMoreThan: function (day, type) {
      return this.renderData(day, this.refundMoreThan, type);
    },
    renderDoanhSo: function (day) {
      // Quyền lợi + Doanh số KH mới + Doanh số nợ + Doanh số nội bộ - Rút tiền - Hoàn tiền
      return this.renderBenefit(day, 'quyen_loi') + this.renderNewContract(day, 'ds_so_hop_dong_moi')
          + this.renderDebtContract(day, 'doanh_so_no')
          + this.renderRefundMoreThan(day, 'withdraw') + this.renderRefundMoreThan(day, 'refund');
    },

    renderPercentNewSales: function (day) {
      let tongDoanhso = Math.abs(this.renderDoanhSo(day, 'gia_tri_hop_dong'));
      let totalContractTvv = Math.abs(this.renderContractTvv(day, 'gia_tri_hop_dong'));
      return totalContractTvv > 0 ? (tongDoanhso / totalContractTvv) * 100 : 0;
    },
    /* all sum total function */
    countTotalStaffOnWork: function () {
      this.totalLead = _.reduce(this.leads, function (result, item) {
        result += item.number;
        return result;
      }, 0);

      this.totalStaffOnWork = _.reduce(this.eventRegisters, function (result, item, key) {
        result += item.number;
        return result;
      }, 0);
      this.totalRealStaffOnWork = _.reduce(this.eventRegisters, function (result, item, key) {
        result += item.attendance;
        return result;
      }, 0);
      this.totalLeaderOnWork = _.reduce(this.leadersOnWork, function (result, item, key) {
        result += item.number;
        return result;
      }, 0);
      this.totalScheduledAppointment = _.reduce(this.scheduledAppointments, function (result, item, key) {
        result += item.number;
        return result;
      }, 0);
      this.totalScheduledCancel = _.reduce(this.scheduledCancels, function (result, item, key) {
        result += item.number;
        return result;
      }, 0);
      this.totalCustomerOnTest = _.reduce(this.customersOnTest, function (result, item, key) {
        result += item.number;
        return result;
      }, 0);
      this.totalBenefit = _.reduce(this.benefit, function (result, item, key) {
        result += item.total;
        return result;
      }, 0);
      this.totalBenefitAmount = _.reduce(this.benefit, function (result, item, key) {
        result += item.amount;
        return result;
      }, 0);
      this.totalNewContract = _.reduce(this.newContract, function (result, item, key) {
        result += item.total;
        return result;
      }, 0);
      this.totalNewContractAmount = _.reduce(this.newContract, function (result, item, key) {
        if (item.amount_coefficient) {
          result += item.amount_coefficient;
        } else {
          result += item.amount;
        }
        return result;
      }, 0);
      this.totalDebtContract = _.reduce(this.debtContract, function (result, item, key) {
        result += item.total;
        return result;
      }, 0);
      this.totalDebtContractAmount = _.reduce(this.debtContract, function (result, item, key) {
        result += item.amount;
        return result;
      }, 0);
      this.totalContractInternal = _.reduce(this.contractInternal, function (result, item, key) {
        let total = item.total ? item.total : 0;
        result += total;
        return result;
      }, 0);
      this.totalContractInternalAmount = _.reduce(this.contractInternal, function (result, item, key) {
        result += item.amount;
        return result;
      }, 0);
      this.totalRefund = _.reduce(this.refundMoreThan, function (result, item, key) {
        if (item.refund) {
          result += item.refund;
        }
        return result;
      }, 0);
      this.totalWithDraw = _.reduce(this.refundMoreThan, function (result, item, key) {
        if (item.withdraw) {
          result += item.withdraw;
        }
        return result;
      }, 0);
      this.totalContractTvv = _.reduce(this.contractTvv, function (result, item, key) {
        result += item.total;
        return result;
      }, 0);
      this.contractClosing = this.totalCustomerOnTest > 0 ? this.totalNewContract / this.totalCustomerOnTest * 100 : 0;
      // Quyền lợi + Doanh số KH mới + Doanh số nợ + Doanh số nội bộ - Rút tiền - Hoàn tiền
      this.tongDoanhSo = this.totalBenefitAmount + this.totalNewContractAmount + this.totalDebtContractAmount + this.totalContractInternalAmount + this.totalWithDraw + this.totalRefund;
      this.averageContract = this.totalNewContract > 0 ? this.totalNewContractAmount / this.totalNewContract : 0;
      this.percentNewSales = this.totalContractTvv > 0 ? (this.tongDoanhSo / this.totalContractTvv) * 100 : 0;
      //Tỉ lệ lịch hẹn/lead
      this.totalAppointmentAndLead = this.totalLead ? (this.totalScheduledAppointment / this.totalLead) * 100 : 0;
      //Số KH lên tư vấn/lịch hẹn
      this.totalCustomerAndAppointment = this.totalScheduledAppointment ? (this.totalCustomerOnTest / this.totalScheduledAppointment) * 100 : 0;
      //Tỉ lệ HĐ mới/lead
      this.totalContractNewAndLead = this.totalLead ? (this.totalCustomerOnTest / this.totalLead) * 100 : 0;
      //Tỉ lệ HĐ mới/lịch hẹn
      this.totalContractNewAndAppointment = this.totalScheduledAppointment ? (this.totalNewContract / this.totalScheduledAppointment) * 100 : 0;
      //Giá trị HĐ trung bình
      this.mediumContract = _.reduce(this.newContract, function (result, item, key) {
        result += item.total ? item.amount_contract / item.total : 0;
        return result;
      }, 0);
      this.countPaymentTurnContractNew = _.reduce(this.newContract, function (result, item, key) {
        if (item.count_amount) {
          result += item.count_amount;
        }
        return result;
      }, 0);
      this.countPaymentTurnContractDebt = _.reduce(this.debtContract, function (result, item, key) {
        if (item.count_amount) {
          result += item.count_amount;
        }
        return result;
      }, 0);
      this.countPaymentTurnContractBenefit = _.reduce(this.benefit, function (result, item, key) {
        result += item.total;
        return result;
      }, 0);
      //Doanh số/lượt đóng
      this.tongDoanhSoLuotDong = (this.countPaymentTurnContractNew + this.countPaymentTurnContractDebt) > 0 ? this.tongDoanhSo / (this.countPaymentTurnContractNew + this.countPaymentTurnContractDebt + this.countPaymentTurnContractBenefit) : 0;
      //Chi phí quảng cáo
      this.totalSpendMarketing = _.reduce(this.getSpendMarketing, function (result, item, key) {
        result += item.total;
        return result;
      }, 0);
      // Chi phí/lead
      this.totalSpendAndLead = this.totalLead > 0 ? this.totalSpendMarketing / this.totalLead : 0;
      //Chi phí/Doanh thu
      this.totalSpendAndContract = this.totalNewContract > 0 ? this.totalSpendMarketing / this.totalNewContract : 0;
      this.totalSpendAndRevenue = this.tongDoanhSo > 0 ? (this.totalSpendMarketing / this.tongDoanhSo) * 100 : 0;
      //Tỉ lệ HĐ mới/ KH lên tư vấn
      this.totalContractNewAndCustomerOnTest = this.totalCustomerOnTest ? (this.totalNewContract / this.totalCustomerOnTest) * 100 : 0
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
