import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const GET_IP = "get-ip";
export const GET_IP_ADDED = "get-ip-added";
export const STORE_IP = "store-ip";
const state = {};
const getters = {};
const actions = {
    [GET_IP](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_IP, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_IP_ADDED](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_IP_ADDED, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [STORE_IP](context, payload) {
        return new Promise(resolve => {
            ApiService.post(STORE_IP, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
