import ApiService from "@/core/services/api.service";
import {commonExport} from "@/core/common/commonHelper";

//actions
export const LIST_LEAD_WEBSITE = 'lead-websites';
//mutations
export const SET_LEAD_WEBSITE = 'set-lead-websites';
export const SET_LEAD_WEBSITE_PAGINATE = 'set-lead-websites-paginate';
export const EXPORT_LEAD_WEBSITE = "website/leads/export-excel";
const state = {
    leadWebsites: [],
    leadWebsitesPaginatedData : [],
}
const getters = {
    getLeadWebsites(state) {
        return state.leadWebsites;
    },
    getLeadWebsitePaginate(state){
      return state.leadWebsitesPaginatedData;
    },
};

const actions = {
    [LIST_LEAD_WEBSITE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query("website/leads", payload)
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_LEAD_WEBSITE, data.data);
                    console.log(data)
                    const leadPagination = {
                        total: data.pagination.total, // total number of items
                        per_page: data.pagination.per_page,// items per page
                        current_page: data.pagination.current_page,
                        total_pages: data.pagination.last_page // total pages in record
                    }
                    console.log(leadPagination)
                    context.commit(SET_LEAD_WEBSITE_PAGINATE, leadPagination)
                })
                .catch((error) => {
                reject(error);
            });
        })
    },

    [EXPORT_LEAD_WEBSITE] (context,payload){
        return new Promise(resolve => {
            ApiService.query(`${EXPORT_LEAD_WEBSITE}`, payload).then(({data}) =>{
                resolve(data);
            }).catch((response) => {
                console.log(response);
            });
        })
    },

}

const mutations = {
    [SET_LEAD_WEBSITE](state, data) {
        state.leadWebsites = data;
    },
    [SET_LEAD_WEBSITE_PAGINATE](state, data) {
        state.leadWebsitesPaginatedData = data;
    },
}

export default {
    state, getters, actions, mutations
}