<template>
    <div class="double-scrollbar">
        <div ref="topScroll" class="top-scroll">
            <div class="scrollBar" ref="scrollBar" :style="scrollBarStyle">&nbsp;</div>
        </div>
        <div class="scroll-area" ref="scrollArea">
            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name:'DoubleScrollbar',
        data(){
            return {
                sWidth:'',
            }
        },
        computed:{
            scrollBarStyle(){
                return { paddingTop: "1px", width: this.sWidth }
            }
        },
        methods:{
            initScrollbar() {
                let topScroll = this.$refs.topScroll;
                let childWrapper = this.$refs.scrollArea;
                this.setWidth();
                topScroll.onscroll = function() {
                    childWrapper.scrollLeft = topScroll.scrollLeft;
                };
                childWrapper.onscroll= function() {
                    topScroll.scrollLeft = childWrapper.scrollLeft;
                };
                window.addEventListener("resize", this.setWidth);
            },
            getWidth() {
                let width = null;
                if (this.$refs.scrollArea && this.$refs.scrollArea.scrollWidth) {
                    width = this.$refs.scrollArea.scrollWidth + "px"
                }
                return width;
            },
            setWidth() {
                let width = this.getWidth();
                if (width == null) {
                    width = "auto";
                }
                if (width !== this.sWidth) {
                    this.sWidth = width
                }
            }
        },
        mounted(){
            this.initScrollbar();
            let th = this;
            setTimeout(function () {
                th.setWidth();
            },500)
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.setWidth);
        },
    }
</script>

<style scoped>
    .top-scroll{
        overflow-x: auto;
        overflow-y: hidden;
        height: 6px;
        cursor: pointer;
    }
    .scroll-area{
        overflow: auto;
        overflow-y: hidden
    }
    .top-scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    .top-scroll::-webkit-scrollbar {
        height: 6px;
        background-color: #F5F5F5;
    }

    .top-scroll::-webkit-scrollbar-thumb {
        background-color: #A8A8A8;
    }
</style>

