import ApiService from "@/core/services/api.service";
import {GET_BRANCH_BY_ID} from "../center/branch.module";
const _ = require('lodash');
export const GET_CUSTOMERS = "get_customers";
export const EXPORT_EXCEL_CUSTOMER = "export_excel_customer";
export const EXPORT_CUSTOMER_GOOGLE_SHEET = "export_customer_google_sheet";
export const GET_CUSTOMER_BY_ID = "get_customer_by_id";
export const UPDATE_CUSTOMER = "update_customer_user";
export const GET_CUSTOMERS_BY_USER_ID = "customers/get-customer-by-user";

const state = {};
const getters = {};
const actions = {
    [GET_CUSTOMERS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('customers', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [UPDATE_CUSTOMER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('customers', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_CUSTOMER_BY_ID](context, id) {
        return new Promise((resolve) => {
            ApiService.get("customers", id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response)
                });
        });
    },

    [GET_CUSTOMERS_BY_USER_ID](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_CUSTOMERS_BY_USER_ID, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [EXPORT_EXCEL_CUSTOMER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('customers/export', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_CUSTOMER_GOOGLE_SHEET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('customers/export-google-sheet', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
