import Swal from "sweetalert2";
<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Danh sách văn phòng</h3>
            </div>
        </div>
        <div class="card-body">
            <!--begin::Example-->
            <div class="example mb-10">
                <div class="row">
                    <div class="form-group col-md-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
																<span class="input-group-text line-height-0 py-0">
																	<span class="svg-icon">
																		<!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Search.svg-->
																		<svg xmlns="http://www.w3.org/2000/svg"
                                                                             xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                             width="24px" height="24px"
                                                                             viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" stroke-width="1"
                                                                               fill="none" fill-rule="evenodd">
																				<rect x="0" y="0" width="24"
                                                                                      height="24"></rect>
																				<path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                                                      fill="#000000" fill-rule="nonzero"
                                                                                      opacity="0.3"></path>
																				<path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                                                      fill="#000000"
                                                                                      fill-rule="nonzero"></path>
																			</g>
																		</svg>
                                                                        <!--end::Svg Icon-->
																	</span>
																</span>
                            </div>
                            <input type="text" class="form-control" placeholder="Tên văn phòng" v-model="query.keyword_alias"
                                   @input="search"
                                   aria-describedby="basic-addon2">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <multiselect v-model="cacheCenter" :options="centers"
                                         placeholder="Trung tâm"
                                         label="name"
                                         track-by="name"
                                         :hide-selected="true"
                                         @input="handleQueryCenter($event)"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <multiselect v-model="cacheProvince" :options="provinces"
                                         placeholder="Tỉnh thành"
                                         label="name"
                                         track-by="name"
                                         :hide-selected="true"
                                         @input="handleQueryProvince($event)"
                            ></multiselect>
                        </div>
                        <!--end::Form-->
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <multiselect v-model="cacheDistrict"
                                         :options="districtsByProvince"
                                         placeholder="Quận, huyện"
                                         label="name"
                                         track-by="name"
                                         :hide-selected="true"
                                         @input="handleQueryDistrict($event)"
                            ></multiselect>
                        </div>
                        <!--end::Form-->
                    </div>
                </div>
                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col" class="min-w-100px">Tên văn phòng</th>
                            <th scope="col" class="min-w-100px">Trung tâm</th>
                            <th scope="col" class="min-w-100px">Tên chi nhánh</th>
                            <th scope="col" class="min-w-100px">Tỉnh/thành phố</th>
                            <th scope="col" class="min-w-100px">Quận/huyện</th>
                            <th scope="col" class="min-w-100px">Địa chỉ</th>
                            <th scope="col" class="min-w-100px">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in branches.data" :key="index">
                            <td>{{ item.name_alias }}</td>
                            <td>{{ item.center != null ? item.center.name : '' }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{item.province != null ? item.province.name : ''}}</td>
                            <td>{{item.district != null ? item.district.name : ''}}</td>
                            <td>{{item.address}}</td>
                            <td>
                                <router-link :to="{ name: 'office-edit', params: {id : item.id} }" title="Sửa" class="btn btn-sm btn-icon mr-2 btn-outline-info">
                                    <i class="fas fa-pen-nib"></i>
                                </router-link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="edu-paginate mx-auto text-center">
            <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                    :page-class="'page-item'"
            >
            </paginate>
        </div>
    </div>
</template>
<script>
import {GET_BRANCHES, DELETE_BRANCH, IMPORT_BRANCH_DATA} from "../../../core/services/store/center/branch.module";
    import Swal from "sweetalert2";
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {GET_PROVINCES} from "../../../core/services/store/public/provinces.module";
    import {GET_DISTRICTS} from "../../../core/services/store/public/districts.module";
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
    import Multiselect from 'vue-multiselect'
    import FileUpload from 'vue-upload-component';
    const _ = require('lodash');

    export default {
        components: {
            Multiselect,FileUpload
        },
        name: "BranchIndex",
        data() {
            return {
                upload: null,
                files: [],
                isAble : true,
                branches: [],
                centers: [],
                provinces: [],
                districts: [],
                // Paginate
                page: 1,
                last_page: 1,
                query: {
                    keyword_alias: '',
                    province: '',
                    district: '',
                    center: '',

                },
                districtsByProvince: [],

                // cache
                cacheProvince: [],
                cacheCenter: [],
                cacheDistrict: [],
            }
        },
        async mounted() {
            await this.getCenters()
            await this.getProvinces()
            await this.getDistricts()
            await this.getList()
            await this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý tên văn phòng"},
                {title: "Danh sách văn phòng"}
            ]);
        },
        methods: {
            onSubmit () {
              let formData = new FormData();
              formData.append('dataImport', this.dataImport);
              this.$store.dispatch(IMPORT_BRANCH_DATA,formData).then( (res) => {
                this.removeFile();
                if (!res.error) {
                  this.$store.dispatch(GET_BRANCHES).then((data) => {
                    this.branches = data.data;
                    this.$bvToast.toast(res.message, {
                      title: 'Import',
                      variant: 'success',
                      solid: true
                    });
                  })
                }else {
                  this.$bvToast.toast(res.message, {
                    title: 'Import',
                    variant: 'danger',
                    solid: true
                  });
                }
              }).catch(() => {
                this.$bvToast.toast('Có lỗi xảy ra', {
                  title: 'Import',
                  variant: 'danger',
                  solid: true
                });
              });
            },
            async getList() {
                await this.pullParamsUrl()
                await this.setCacheTheLoad()
                await this.callApiGetList()
                await this.selectDistrictByProvince()
            },
            setCacheTheLoad() {
                if (this.query.center != '') {
                    let oldParamCenter = this.centers.filter((value) => {
                        if (parseInt(this.query.center) == value.id) {
                            return value
                        }
                    })
                    this.cacheCenter = oldParamCenter
                }
                if (this.query.province != '') {
                    let oldParamDistrict = this.provinces.filter((value) => {
                        if (parseInt(this.query.province) == value.id) {
                            return value
                        }
                    })
                    this.cacheProvince = oldParamDistrict
                }
                if (this.query.district != '') {
                    let oldParamDistrict = this.districts.filter((value) => {
                        if (parseInt(this.query.district) == value.id) {
                            return value
                        }
                    })
                    this.cacheDistrict = oldParamDistrict
                }
            },
            selectDistrictByProvince() {
                let provinceId = this.query.province
                this.districtsByProvince = []
                if (provinceId != undefined) {
                    this.districts.map((value) => {
                        if (value.province_id == provinceId) {
                            this.districtsByProvince.push(value)
                        }
                    })
                    this.districtsByProvince.unshift({
                        name: 'Quận, huyện'
                    })
                }

            },
            pullParamsUrl() {
                this.page = this.$route.query.page
                this.query.keyword_alias = this.$route.query.keyword_alias
                this.query.center = this.$route.query.center
                this.query.province = this.$route.query.province
                this.query.district = this.$route.query.district
            },
            search: _.debounce(function () {
                this.query.center = this.$route.query.center
                this.query.province = this.$route.query.province
                this.query.district = this.$route.query.district
                this.pushParamsUrl()
                this.page = 1
                this.callApiGetList()
            }, 200),
            pushParamsUrl() {
                this.$router.push({
                    name: 'office-index',
                    query: {
                        keyword_alias: this.query.keyword_alias,
                        center: this.query.center,
                        province: this.query.province,
                        district: this.query.district
                    }
                })
            },
            clickCallback(obj) {
                this.page = obj
                this.$router.push({path: '/office', query: {page: this.page}})
                this.callApiGetList()
            },
            callApiGetList() {
                this.$store.dispatch(GET_BRANCHES, {
                    page: this.page,
                    keyword_alias: this.query.keyword_alias,
                    center: this.query.center,
                    province: this.query.province,
                    district: this.query.district,
                    is_office: 1
                }).then((res) => {
                    this.branches = res.data
                    this.last_page = res.data.last_page
                });
            },
            async getProvinces() {
                await this.$store.dispatch(GET_PROVINCES, []).then((res) => {
                    if (!res.error) {
                        this.provinces = res.data
                        this.provinces.unshift({
                            name: 'Tỉnh thành'
                        })
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            async getDistricts() {
                await this.$store.dispatch(GET_DISTRICTS, []).then((res) => {
                    if (!res.error) {
                        this.districts = res.data
                        this.districts.unshift({
                            name: 'Quận, huyện'
                        })
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            async getCenters() {
                await this.$store.dispatch(GET_CENTERS, {
                    limit: 10000
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data
                        this.centers.unshift({
                            name: 'Trung tâm'
                        })
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            handleQueryCenter($event) {
                this.query.center = $event.id
                if (this.$route.query.keyword_alias != undefined) {
                    this.query.keyword_alias = this.$route.query.keyword_alias
                }
                if (this.$route.query.page != undefined) {
                    this.page = this.$route.query.page
                }
                if (this.$route.query.province != undefined) {
                    this.query.province = this.$route.query.province
                }
                if (this.$route.query.district != undefined) {
                    this.query.district = this.$route.query.district
                }
                this.pushParamsUrl()
                this.callApiGetList()
            },
            async handleQueryProvince($event) {
                this.cacheDistrict = []
                this.query.province = $event.id
                this.query.district = ''
                if (this.$route.query.keyword_alias != undefined) {
                    this.query.keyword_alias = this.$route.query.keyword_alias
                }
                if (this.$route.query.page != undefined) {
                    this.page = this.$route.query.page
                }
                if (this.$route.query.center != undefined) {
                    this.query.center = this.$route.query.center
                }
                await this.pushParamsUrl()
                await this.callApiGetList()
                await this.selectDistrictByProvince()
            },
            handleQueryDistrict($event) {
                this.query.district = $event.id
                if (this.$route.query.keyword_alias != undefined) {
                    this.query.keyword_alias = this.$route.query.keyword_alias
                }
                if (this.$route.query.page != undefined) {
                    this.page = this.$route.query.page
                }
                if (this.$route.query.center != undefined) {
                    this.query.center = this.$route.query.center
                }
                if (this.$route.query.province != undefined) {
                    this.query.province = this.$route.query.province
                }
                this.pushParamsUrl()
                this.callApiGetList()
            },
        }
    }
</script>
<style>
.fa-minus-circle:hover {
  color: red !important;

}
.input-group i {
  font-size : 0.9rem;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
