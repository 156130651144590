<template>
  <div>
    <button class="btn btn-success font-weight-bold btn-sm" v-b-modal="`Modal_Add_Class`">
      <i class="flaticon2-add-1"></i>
        {{ isVoucher ? 'Thêm lớp học' : 'Thêm điều kiện' }}
    </button>
    <b-modal id="Modal_Add_Class" :title="isVoucher ? 'Thêm mới lớp học' : 'Thêm mới điều kiện'" centered hide-footer ref="Modal_Add_Class"
             size="lg" @hidden="clearData()" @show="getListGift()"
    >
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" @submit.prevent autocomplete="off">
          <div v-if="isVoucher" class="row">
            <div class="col-lg-12 col-xs-12 mb-3">
              <p>Tên lớp học <span class="text-danger">*</span></p>
              <ValidationProvider name="Lớp học" rules="required" v-slot="{ errors }" vid="combo_id" >
                <el-input v-model="className" placeholder="Nhập tên lớp học"/>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-lg-12 col-xs-12 mb-3">
              <p><span v-text="isVoucher ? 'Voucher' : 'Quà tặng'"></span> <span class="text-danger">*</span></p>
              <ValidationProvider name="voucher" rules="required" v-slot="{ errors }" vid="id_voucher">
                <el-select v-model="idVoucher" clearable placeholder="Chọn voucher" class="w-100">
                  <el-option
                      v-for="item in dataFilter"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div v-else class="row" >
            <div class="col-lg-12 col-xs-12 mb-3">
              <p>Số lượng khóa <span class="text-danger">*</span></p>
              <ValidationProvider name="Số lượng khóa" rules="required" v-slot="{ errors }"
                                  vid="quantity">
                <el-select class=" d-block" placeholder="Tên khóa học/combo" size="large" v-model="dataClass.quantity"
                           @change="changeCombo">
                  <el-option label="1" value="1"/>
                  <el-option label="2" value="2"/>
                  <el-option label="3" value="3"/>
                  <el-option label="4" value="4"/>
                  <el-option label="5" value="5"/>
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-lg-12 col-xs-12 mb-3">
              <p>Tên khóa học/combo <span class="text-danger">*</span></p>
              <ValidationProvider name="Tên khóa học/combo" rules="required" v-slot="{ errors }"
                                  vid="combo_id" >
                <el-select class=" d-block" placeholder="Tên khóa học/combo" size="large" v-model="dataClass.combo_id">
                  <el-option
                    v-for="item in quantity"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"

                  />
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-lg-12 col-xs-12">
              <p>Loại voucher <span class="text-danger">*</span></p>
              <ValidationProvider name="Loại voucher" rules="required" v-slot="{ errors }"
                                  vid="type_voucher" >
                <el-select class=" d-block" placeholder="Chọn loại voucher" size="large" v-model="dataClass.type_voucher">
                  <el-option
                    v-for="item in typeVoucher"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"

                  />
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-lg-12 col-xs-12">
              <p>Quà tặng <span class="text-danger">*</span></p>
              <ValidationProvider name="Quà tặng" rules="required" v-slot="{ errors }"
                                  vid="gift" >
                <el-select class=" d-block" placeholder="Chọn quà tặng" size="large" v-model="dataClass.gift" multiple>
                  <el-option
                      v-for="item in dataFilter"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"

                  />
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="d-flex d-flex justify-content-end mt-4">
            <button
                type="button"
                class="btn btn-secondary mr-4 "
                @click="handleCancel()"
            >
              Hủy
            </button>
            <el-button
                type="button"
                :loading="loading"
                class="btn btn-success "
                @click="handleSubmit(createClass)"
            >
              Xác nhận
            </el-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import {CREATE_CLASS, GET_LIST_GIFT, GET_COMBO_BY_QUANTITY} from "@/core/services/store/rewards-programs/rewardsPrograms.module";
import {GiftModel, typeVoucher} from "@/view/pages/rewards-program/detail-program/model/InformationProgramModel";

export default {
  name: "ModalAllClass",
  data() {
    return {
      idProgram : '',
      listGift : [] ,
      dataClass : {
        name : null,
        gift : [],
        quantity: '',
        combo_id: '',
      },
      loading: false,
      sameNameError : false,
      quantity: {},
      typeVoucher: typeVoucher,
      idVoucher: null,
      className: '',
      dataFilter: []
    }
  },
  props: {
    isVoucher: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  watch: {
    isVoucher() {
      this.filterGift();
    }
  },
  async mounted() {
     this.idProgram = this.$route.params.id;
     await this.getListGift()
  },
  methods: {
    async getListGift() {
      const payload = {
        id:this.idProgram,
      per_page: 9999
      }
      await this.$store.dispatch(GET_LIST_GIFT,payload).then((data)=>{
        if(!data.error){
          this.listGift = data?.data?.map(item => {
            return new GiftModel(
                item?.id,
                item?.name,
                item?.file_name,
                item?.url,
                item?.program_type
            )
          })
        }
          this.filterGift()
      })
    },
    filterGift() {
        if (this.listGift.length > 0 && this.isVoucher) {
        this.dataFilter = this.listGift.filter(item => item.type === 1)
      } else {
        this.dataFilter = this.listGift.filter(item => item.type === 2)
      }
    },
    handleCancel(){
      this.$refs['Modal_Add_Class'].hide()
    },
    createClass(){
      if(this.dataClass.name === ''){
        return
      }
      this.loading = true;
      let payload = this.isVoucher ? {
            spin_reward_program_id : this.idProgram,
            name: this.className,
            spin_gift_id: this.idVoucher,
            program_type: this.isVoucher ? 1 : 2,
            is_of_slot: false
          } : {
        combo_id : this.dataClass.combo_id,
        type_voucher : this.dataClass.type_voucher,
        spin_gift_id : this.dataClass.gift,
        is_of_slot : false,
        spin_reward_program_id : this.idProgram,
        program_type: this.isVoucher ? 1 : 2
      }
      this.$store.dispatch(CREATE_CLASS,payload).then(()=>{
        this.$refs['Modal_Add_Class'].hide()
        this.loading = false;
        this.$notify({
          title: 'Thành công',
          message: this.isVoucher ? 'Thêm lớp học thành công' : 'Thêm điều kiện thành công',
          type: 'success'
        });
        this.$emit('createClassSuccess')
      }).catch((error)=>{
        this.loading = false;
        this.$refs.form.setErrors(error.data.data.message_validate_form);
      })
    },
    clearData(){
      this.dataClass = {
        name : null,
        gift : null,
      }
      this.className = ''
      this.idVoucher = ''
      this.sameNameError = false
    },
    changeCombo() {
      this.dataClass.combo_id = ''
      this.$store.dispatch(GET_COMBO_BY_QUANTITY,{quantity: this.dataClass.quantity}).then((data)=>{
        if(!data.error){
          this.quantity = data.data;
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
