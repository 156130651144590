<template>
  <div>
    <button type="button" class="btn btn-primary btn-sm ml-2" v-b-modal="`Modal_Browse_Team`">
      <i class="el-icon-circle-check"></i>
      Duyệt Team
    </button>
    <b-modal id="Modal_Browse_Team" title="Duyệt team tham gia giải đấu" centered size="xl" @show="getListTeam()"
             hide-footer @hidden="resetTeam()">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent>
          <div class="row">
            <div class="col-3">
              <div class="">
                <template>
                  <el-input placeholder="Tìm team/thành viên" v-model="keyword" @input="getListTeam"></el-input>
                </template>
              </div>
            </div>
            <div class="col-3">
              <div class="ml-0">
                <template>
                  <el-select v-model="status" placeholder="Phân Loại: Cần phê duyệt" ml-2 @input="getListTeam">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <table class="table table-bordered mt-3">
                    <thead>
                    <tr class="table-active">
                      <th scope="col">Tên Team</th>
                      <th scope="col">Danh sách thành viên</th>
                      <th scope="col">Tên nhân vật</th>
                      <th scope="col" class="text-center">Hành động</th>
                    </tr>
                    </thead>
                    <tbody v-if="listTeam.length == 0">
                    <tr class="text-center">
                      <td colspan="4">
                        Không có dữ liệu
                      </td>
                    </tr>
                    </tbody>
                    <tbody v-else>
                    <tr v-for="item in listTeam">
                      <td :rowspan="getRowSpan(item)" v-if="getRowSpan(item)" style="font-size: 13px ">
                        <div class="d-flex align-items-center" style="height: 88px">
                          <p class="mb-0 ml-3">
                            {{
                              item.team
                            }}
                          </p>
                        </div>
                      </td>
                      <td style="font-size: 13px">{{ item.full_name }} - {{ item.phone }} <span v-if="item.isCaptain"
                                                                                                class="text-danger "
                                                                                                style="font-size: 12px; font-weight: bold">(C)</span>
                      </td>
                      <td style="font-size: 13px ">{{ item.name }}</td>
                      <td :rowspan="getRowSpan(item)" v-if="getRowSpan(item)"
                          style="  vertical-align: middle;">

                        <div v-if="item.status == CAN_PHE_DUYET" >
                          <div class="d-flex justify-content-center ">
                            <button type="button" class="btn btn-danger" style="width: 120px ; background-color:  #038300; border :#038300;"
                                    @click="confirmTeam(item.id)">
                              <i
                                  class="el-icon-check"></i>
                              Phê duyệt
                            </button>
                          </div>
                          <div class="d-flex justify-content-center mt-3">
                            <button type="button" class="btn btn-success d-block mr-0" style="width: 120px; background-color: #AE2525;
; border :#AE2525;"
                                    @click="rejectTeam(item.id,item.team)"><i class="el-icon-close"></i>Từ chối
                            </button>
                          </div>
                        </div>
                        <div v-else-if="item.status == DA_PHE_DUYET">
                          <p style="color: #026C00">Đã phê duyệt.</p>
                        </div>

                        <div v-else>
                          <p style="color: #AE2525">Đã từ chối.</p>
                          <p style="color: #AE2525">Lý do: {{ item.reason }}</p>
                        </div>


                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <div class="d-flex justify-content-between">
                <div></div>
                <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                    :page-class="'page-item'"
                >
                </paginate>
                <p> Tổng số bàn ghi: {{ total }}</p>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
    <ModalEnterReason :idTeam=idTeam :nameTeam=nameTeam @listTeamBrowse="getListTeam()"></ModalEnterReason>
  </div>
</template>


<script>
import {BROWSE_TEAM, REASON_REFUSE, TEAM_BROWSING_LIST} from "@/core/services/store/tournament/tournament.module";
import {CAN_PHE_DUYET, DA_PHE_DUYET, DA_TU_CHOI} from "@/core/option/tournamentOption";
import ModalEnterReason from "@/view/pages/Tournaments/Component/ModalEnterReason";


export default {
  name: "ModalBrowseTeam",
  components: {ModalEnterReason},
  data() {
    return {
      listTeam: [],
      options: [{
        value: CAN_PHE_DUYET,
        label: 'Cần phê duyệt'
      }, {
        value: DA_PHE_DUYET,
        label: 'Đã phê duyệt'
      }],
      status: CAN_PHE_DUYET,
      idTeam: null,
      nameTeam: null,
      page: 1,
      last_page: 0,
      total: 0,
      keyword: "",
      CAN_PHE_DUYET: CAN_PHE_DUYET,
      DA_PHE_DUYET: DA_PHE_DUYET,
      DA_TU_CHOI: DA_TU_CHOI

    }
  },
  mounted() {
    this.getListTeam()
  },
  methods: {
    resetTeam() {
      this.listTeam = []
      this.status = CAN_PHE_DUYET
      this.idTeam = null
      this.nameTeam = null
      this.page = 1
      this.last_page = 0
      this.total = 0
      this.keyword = ""
      this.getListTeam()
    },
    clickCallback(obj) {
      this.page = obj;
      this.getListTeam()
    },
    rejectTeam(id, nameTeam) {
      this.idTeam = id
      this.nameTeam = nameTeam
      this.$root.$emit('bv::show::modal', 'modal-enter-reason', '#btnShow')

    },
    confirmTeam(id) {
      this.$confirm('Bạn chắc chắn muốn phê duyệt không ?', 'Xác nhận', {
        confirmButtonText: 'Phê Duyệt',
        cancelButtonText: 'Hủy',
        dangerouslyUseHTMLString:true,
        confirmButtonClass:"bg-success border-white",
        type: 'warning'
      }).then(() => {
        let payload = {
          tournament_team_id: id,
          status: 1
        }
        this.$store.dispatch(BROWSE_TEAM, payload).then(() => {
          this.$notify({
            title: 'Thành công',
            message: 'Phê duyệt thành công',
            type: 'success'
          });
          this.getListTeam()
        })

      })
    },
    getListTeam() {
      let payload = {
        // this.$route.params.id
        id: this.$route.params.id,
        data: {
          status: this.status === CAN_PHE_DUYET ? [0] : [1, 2],
          page: this.page,
          keyword: this.keyword
        }
      }
      this.$store.dispatch(TEAM_BROWSING_LIST, payload).then((data) => {
        this.total = data.pagination.total;
        this.page = data.pagination.page;
        this.last_page = data.pagination.last_page;
        this.$emit("countTeamBrowse",data?.data?.length)
        this.listTeam = data.data.map((item) => {
          return item.members.map(c => {
            return {
              id: c.tournament_team_id,
              team: item.name,
              full_name: c.name,
              phone: c.phone,
              isCaptain: c.is_captain,
              name: c.name_in_game,
              status: item.status,
              reason: item.reason
            };
          });
        }).reduce((c1, c2) => c1.concat(c2)) ?? [];
      }).catch(() => {
        this.listTeam = []
      })

    },
    getRowSpan(data) {
      let membersInTeam = this.listTeam.filter((d) => d.id == data.id);
      let index = membersInTeam.indexOf(data)
      return index == 0 ? membersInTeam.length : 0;
    }
  }
}
</script>
<style scoped>
</style>