<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Danh sách trung tâm</h3>
        </div>
        <div class="card-title">
          <div class="mr-5">

          </div>
          <router-link
              class="font-weight-bold font-size-3 btn btn-success"
              :to="{ name: 'center-create' }"
          ><i class="el-icon-circle-plus-outline"></i>Thêm mới
          </router-link>

        </div>

      </div>
      <div class="card-body">
        <div class="example mb-10">
          <div class="form-group">
            <div class="input-group row">
              <div class="col-md-3">
                <el-input type="text"
                          placeholder="Nhập tên trung tâm"
                          prefix-icon="el-icon-search"
                          v-on:input="searchCenters"
                          v-model="query.name"
                />
              </div>
              <div class="col-md-3">
                <el-select v-model="query.province_id" class="w-100"
                           placeholder="Chọn tỉnh thành"
                           clearable filterable
                           @change="updateDistricts"
                >
                  <el-option
                      v-for="item in provinces"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-3">
                <el-select v-model="query.district_id"
                           class="w-100"
                           clearable filterable
                           placeholder="Chọn quận/huyện"
                           @change="selectDistrict"
                >
                  <el-option
                      v-for="item in districts"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-3">
                <el-select v-model="query.status"
                           class="w-100"
                           clearable filterable
                           placeholder="Chọn trạng thái"
                           @change="selectStatus"
                >
                  <el-option
                      v-for="item in status_options"
                      :key="item.value"
                      :label="item.status"
                      :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="example-preview text-center table-responsive overflow-auto">
            <table :aria-busy="isBusy" class="table table-vertical-center table-bordered table-hover b-table">
              <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Mã trung tâm</th>
                <th scope="col">Tên trung tâm</th>
                <th scope="col">Tỉnh</th>
                <th scope="col">Quận/Huyện</th>
                <th scope="col">Trạng thái</th>
                <th scope="col">Ngày đăng</th>
                <th scope="col">Ẩn/Hiện</th>
                <th scope="col" class="min-w-50px">Hành động</th>
              </tr>
              </thead>
              <tbody v-if="isBusy">
              <tr role="row" class="b-table-busy-slot" >
                <td colspan="16" role="cell" class="text-center">
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(item, index) in centers" :key="index">
                  <th scope="row">{{ ++index }}</th>
                  <td class="">{{parseInt(item.id) + 100000}}</td>
                  <td class="">{{ item.name_mkt ? item.name_mkt : item.name }}</td>
                  <td class="">{{ item.province == null ? '' : item.province.name }}</td>
                  <td class="">{{ item.district == null ? '' : item.district.name }}</td>
                  <td class="">
                    <span class="badge"
                          v-bind:class="item.status === 1 ? classSpan.success : classSpan.danger">
                      {{item.status === 1 ? "Hoạt động" : 'Chưa hoạt động'}}
                    </span>
                  </td>
                  <td class="">{{item.created_at | formatDate}}</td>
                  <td class="">
                    <div class="d-flex justify-content-center">
                      <change-status :center="item" @changeSuccess="changeStatusSuccess"></change-status>
                    </div>

                  </td>
                  <td class="d-flex justify-content-around">
                    <router-link :to="{ name: 'center-update', params: {id : item.id}, query: {type : type} }" title="Sửa"
                                 class="btn btn-sm btn-icon mr-2 btn-outline-info" v-if="type === 'web-content'"><i
                        class="fas fa-pen-nib" ></i>
                    </router-link>
                    <router-link :to="{ name: 'center-old-update', params: {id : item.id} }" title="Sửa"
                                 class="btn btn-sm btn-icon mr-2 btn-outline-info" v-if="type === 'qldvsx'"><i
                        class="fas fa-pen-nib" ></i>
                    </router-link>
                    <a title="Xóa" @click="deleteCenter(item.id)" href="javascript:"
                       class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="edu-paginate mx-auto text-center">
        <paginate
            v-model="query.page"
            :page-count="total_pages"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'Trước'"
            :next-text="'Sau'"
            :container-class="'pagination b-pagination'"
            :pageLinkClass="'page-link'"
            :page-class="'page-item'"
            :next-link-class="'next-link-item'"
            :prev-link-class="'prev-link-item'"
            :prev-class="'page-link'"
            :next-class="'page-link'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import { DELETE_CENTER, GET_CENTERS } from "@/core/services/store/center/centers.module";
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_PROVINCES} from "@/core/services/store/event/event.module";
import {GET_DISTRICTS} from "@/core/services/store/public/districts.module";
import {mapGetters} from "vuex";
import changeStatus from "@/view/pages/centers/ChangeStatus";
const _ = require('lodash');

export default {
  name: "CommonCenterList",
  components:{
    changeStatus
  },
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      query: {
        name: "",
        page: 1,
        district_id : '',
        province_id: '',
        status: '',
      },
      page: 1,
      classSpan: {
        success: 'badge-success',
         danger: 'badge-danger'
      },
      provinces : [],
      districts: [],
      centers: [],
      status_options : [
        {
          status : 'Hoạt động',
          value : 1
        },
        {
          status : 'Chưa hoạt động',
          value : 0
        },
      ],
      dataImport : '',
      slug : '',
      isBusy: false
    };
  },
  computed: {
    ...mapGetters(['getCenterPaginate']),
    total_pages() {
      return this.getCenterPaginate?.total_pages || 0
    },
  },
  mounted() {
    this.query.name = this.$route.query.name ? this.$route.query.name : '';
    this.query.status = this.$route.query.status ? Number(this.$route.query.status) : '';
    this.query.province_id = this.$route.query.province_id ? Number(this.$route.query.province_id) : '';
    this.query.district_id = this.$route.query.district_id ? Number(this.$route.query.district_id) : '';
    this.query.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.queryProvinces();
    this.queryDistrict();
    this.getQuery();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý trung tâm"},
      {title: "Danh sách trung tâm"}
    ]);
  },
  watch: {},
  methods: {
    updateDistricts: function () {
      this.query.page = 1
      this.queryDistrict();
      this.pushParams();
      this.getQuery();
    },
    selectDistrict: function(){
      this.query.page = 1
      this.pushParams();
      this.getQuery();
    },
    selectStatus: function(){
      this.query.page = 1
      this.pushParams();
      this.getQuery();
    },
    deleteCenter(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_CENTER, id).then(() => {
            this.getQuery();
          });
        }
      });
    },
    searchCenters: _.debounce(function () {
      this.query.page = 1
      this.getQuery();
    }, 300),
    getQuery: function () {
      this.isBusy = true;
      this.$store.dispatch(GET_CENTERS, this.query).then((res) => {
        this.centers = res.data.data;
        this.isBusy = false;
      });
    },
    pushParams(){
      this.slug = `?name=${this.query.name}&page=${this.query.page}&district_id=${this.query.district_id}&province_id=${this.query.province_id}&status=${this.query.status}`;
      this.$router.push({path: '/centers', query : this.query});
    },
    queryProvinces(){
      this.$store.dispatch(GET_PROVINCES).then((res) => {
        this.provinces = res.data;
      })
    },
    queryDistrict(){
      this.$store.dispatch(GET_DISTRICTS, {province_id: this.query.province_id}).then((res) => {
        this.districts = res.data;
      })
    },
    clickCallback(pageNum) {
      this.query.page = pageNum
      this.$router.push({path: '', query : {page: pageNum, name: this.query.name, province_id : this.query.province_id, district_id : this.query.district_id, status : this.query.status}});
      this.getQuery();
    },
    changeStatusSuccess() {
      this.getQuery();
    },
  },
};
</script>
<style>
.checkbox-slide, .checkbox-switch {
  border-radius: 20px !important;
}
</style>


