<template>
  <div class="page">
    <section :class="'sheet padding-10mm ' + logo " id="section-to-print" >
      <div class="ieltsmentor-logo" style="position: absolute">
        <span class="info-company">Ngày giao dịch: {{ studentAmount.dated_paid }}</span>
        <br>
        <span class="info-company">Mã thanh toán: {{ studentAmount.id }}</span>
      </div>
      <table cellpadding="1" cellspacing="1" style="width:100%">
        <tbody>
        <tr>
          <td style="text-align:justify; width:60%"><span class="info-company">{{ infoCompany.name }}</span></td>
          <td style="text-align:right; width:40%"></td>
        </tr>
        <tr>
          <td style="text-align:left; overflow: hidden;"><b></b> <span class="w-80 info-company">MST: {{ infoCompany.mst }}</span></td>
        </tr>
        <tr>
          <td><span class="info-company">{{ infoCompany.address }}</span></td>
        </tr>
        </tbody>
      </table>

      <div style="margin-top:20px; text-align:center"><span class="page-title bold"><strong>PHIẾU THU</strong></span></div>

      <div style=" text-align:center">&nbsp;</div>
      <div style="text-align:center">
        <table cellpadding="1" cellspacing="1" style="width:100%">
          <tbody>
          <tr>
            <td style="text-align:justify; width:20%; overflow: hidden;"><span class="bold"><strong>Họ tên khách hàng:</strong></span></td>
            <td class="info-user" style="width: 58%"><span>{{ studentAmount.student_name }}</span></td>
            <td style="text-align:left; overflow: hidden;" class="ma-hoc-vien"><span class="bold"><strong>Mã học viên:</strong></span></td>
            <td class="info-user"><span>{{ studentAmount.student_id }}</span></td>
          </tr>
          <tr>
            <td style="text-align:left; overflow: hidden;"><span class="bold"><strong>Số điện thoại:</strong></span></td>
            <td class="info-user"><span>{{ studentAmount.student_phone }}</span></td>
            <td style="text-align:left; overflow: hidden;"><span class="bold"><strong>Mã hợp đồng:</strong></span></td>
            <td class="info-user"><span>{{ studentAmount.contract.id }}</span></td>
          </tr>
          </tbody>
        </table>
      </div>

      <div style="text-align:center; margin-top: 10px;">
        <table  cellpadding="1" cellspacing="1" style="width:100%">
          <tbody>
          <tr>
            <td style="text-align:justify; overflow: hidden; width: 20%">
              <span class="bold"><strong>Số tiền thanh toán:</strong></span>
            </td>
            <td style="text-align:left; overflow: hidden;" class="info-course">
              <span >{{ studentAmount.amount | formatPrice }}</span>
            </td>
          </tr>
          <tr style="line-height: 35px">
            <td style="text-align:justify; overflow: hidden; vertical-align: bottom; width: 20%;">
              <span class="bold"><strong>Khóa học đăng kí:</strong></span>
            </td>
            <td style="text-align: left" class="info-course">
              <span v-for="(course, k) in studentAmount.contract_courses">{{ course }}<span v-if="k < studentAmount.contract_courses.length-1">, </span></span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div style="text-align:center; margin-top: 5px;">
        <table  cellpadding="1" cellspacing="1" style="width:100%">
          <tbody>
          <tr>
            <td style="text-align:justify; overflow: hidden; vertical-align: top; width: 20%;">
              <span  class="bold"><strong>Chi tiết hợp đồng:</strong></span>
            </td>
            <td style="text-align:justify; overflow: hidden; width: 24%" class="info-course"><span>Tổng giá trị hợp đồng:</span></td>
            <td style="text-align:left; overflow: hidden;" class="info-course"><span>{{ studentAmount.contract.total_after_voucher | formatPrice }}</span></td>
          </tr>
          <tr>
            <td></td>
            <td style="text-align:justify; overflow: hidden;" class="info-course"><span>Đã thanh toán:</span></td>
            <td style="text-align:left; overflow: hidden;" class="info-course"><span>{{ studentAmount.contract.total_paid | formatPrice }}</span></td>
          </tr>
          <tr>
            <td></td>
            <td style="text-align:justify; overflow: hidden;" class="info-course"><span>Còn nợ:</span></td>
            <td style="text-align:left; overflow: hidden;" class="info-course">
              <span v-if="studentAmount.contract.total_debt > 0">{{ studentAmount.contract.total_debt | formatPrice }}</span>
              <span v-else>0đ</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div style="text-align:center; margin-top: 15px;">
        <table cellpadding="1" cellspacing="1" style="width:100%;margin-top: 15px">
          <tbody>
          <tr>
            <td style="text-align:center; width:25%; overflow: hidden;"> <strong class="bold">Người lập phiếu</strong>  <br><span class="info-course">(ký,họ tên)</span></td>
            <td style="text-align:center; width:20%; overflow: hidden; margin-top: 10px"><strong class="bold" >Người nộp tiền</strong> <br><span class="info-course">(ký,họ tên)</span></td>
            <td style="text-align:center; width:20%; overflow: hidden; margin-top: 10px"><strong class="bold" >Thủ quỹ</strong> <br><span class="info-course">(ký,họ tên)</span></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="footer-paper">
        <p class="f-szie top" ><strong>Lưu ý:</strong></p>
        <p class="f-szie" >Mất phiếu thu trung tâm không giải quyết tất cả các trường hợp.</p>
        <!--        <div class="theme">-->
        <!--          <div class="left-border"></div>-->
        <!--          <div class="right-border"></div>-->
        <!--        </div>-->
        <!--        <div style="page-break-before: always;"></div>-->
      </div>
    </section >
    <section style="text-align: center">
      <button class="btn btn-primary" @click="printBill">In</button>
      <button class="btn btn-success" style="margin-left: 20px;" @click="sendMail"><i v-if="loading" class="el-icon-loading"></i>Gửi khách hàng</button>
    </section>
  </div>
</template>

<script>
    import "@/assets/sass/custom/edutalk-font.css";
    import "@/assets/sass/custom/paper.css";
    import {
        GET_DETAIL, SEND_BILL
    } from "../../../core/services/store/contract/studentAmount.module";
    import SelectCustomer from "../../content/SelectCustomer";

    export default {
        name: "Bill",
        components: {SelectCustomer},
        data() {
            return {
                type: '',
                studentAmount: {
                    student_name: '',
                    id: '',
                    student_phone: '',
                    student_id: '',
                    student_email: '',
                    note: '',
                    amount: '',
                    amount_text: '',
                    hinh_thuc_thanh_toan: '',
                    dated_paid: '',
                    contract: {},
                    contract_courses: {}
                },
                logo: '',
                publicPath: process.env.VUE_APP_BASE_URL,
                infoCompany: {
                    name: '',
                    mst: '',
                    address: ''
                },
                loading: false
            }
        },
        methods: {
            getBill() {
                this.$store.dispatch(GET_DETAIL, this.$route.query.id).then((res) => {
                    if (res.data.error) {
                        this.$bvToast.toast(res.data.message, {
                            title: 'Chi tiết đóng tiền',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.studentAmount = res.data
                    }
                });
            },
            printBill() {
                window.print()
            },
            sendMail() {
                this.loading = true
                this.$store.dispatch(SEND_BILL,{id: this.studentAmount.id, type: this.type}).then((res) => {
                    if (res.data.error) {
                        this.$bvToast.toast(res.data.message, {
                            title: 'Gửi biên lai',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.loading = false
                        this.$bvToast.toast('Thành công', {
                            title: 'Gửi biên lai',
                            variant: 'success',
                            solid: true
                        });
                    }
                })
            }
        },
        mounted() {
            this.type = this.$route.query.type;
            this.getBill()
            if (this.type == 1 || this.type == 2 || this.type == 5) {
                this.infoCompany.name = "CÔNG TY CỔ PHẦN TƯ VẤN ĐÁNH GIÁ VÀ PHÁT TRIỂN GIÁO DỤC EDUTALK"
                this.infoCompany.mst = "0108157817"
                this.infoCompany.address = "Số 70 Đường Bờ Sông Sét, P.Tân Mai, Q.Hoàng Mai, TP.Hà Nội"
                this.logo = "edutalk"
            } else if (this.type == 3) {
                this.infoCompany.name = "CÔNG TY TNHH GIÁO DỤC IELTS MENTOR"
                this.infoCompany.mst = "0109740210"
                this.infoCompany.address = "Nhà A2, TH VPTTTM và Nhà ở 257 Giải Phóng, P.Tương Mai, Q.Đống Đa, TP.Hà Nội"
                this.logo = "ieltsmentor"
            } else {
                this.infoCompany.name = "CÔNG TY TNHH CÔNG NGHỆ GIÁO DỤC ECOACH"
                this.infoCompany.mst = "0109877180"
                this.infoCompany.address = "Số 43A Phố Tam Khương, P.Khương Thượng, Q.Đống Đa, TP.Hà Nội"
                this.logo = "ecoach"
            }
        }
    }
</script>
<style scoped>
@media print {
  .sheet{
    -webkit-print-color-adjust: exact
  }
}
</style>
