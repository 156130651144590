import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const CREATE_NEWS = "create_new";
export const UPDATE_NEWS = "update_new";
export const GET_NEWS = "get_new";
export const DELETE_NEWS = "delete_news";
export const GET_NEWS_BY_ID = "get_news_by_id";
export const CHECK_NUMBER_HIGHLIGHTS = "check_number_highlights";

// Setter
export const SET_TOTAL_HIGHLIGHTS = "set_total_highlights";

const state = {
    total_highlights: 0,
};
const getters = {
    getTotalHighlights(state) {
        return state.total_highlights;
    },
};
const actions = {
    // Get provinces
    [GET_NEWS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('news', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CHECK_NUMBER_HIGHLIGHTS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('news/check-total-highlights', payload).then(({data}) => {
                resolve(data);
                context.commit(SET_TOTAL_HIGHLIGHTS, data.data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_NEWS_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('news', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_NEWS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('news', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_NEWS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('news/' + payload.id + '?_method=PUT', payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_NEWS](context, id) {
        return new Promise(resolve => {
            ApiService.delete('news', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {
    [SET_TOTAL_HIGHLIGHTS](state, data) {
        state.total_highlights = data;
    },
};
export default {
    state,
    actions,
    mutations,
    getters
};
