<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Đổi mật khẩu</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Thay đổi mật khẩu cho tài khoản của bạn</span>
      </div>
      <div class="card-toolbar">
        <button
                @click="save()"
                class="btn btn-success mr-2"
                ref="kt_save_changes"
                :class="{'spinner spinner-light spinner-right': check_spinner }"
        >
          Lưu
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="kt_password_change_form">
      <div class="card-body">
        <div class="form-group row"></div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">Mật khẩu hiện tại</label>
          <div class="col-lg-9 col-xl-6">
            <input
                    type="password"
                    class="form-control form-control-lg form-control-solid mb-2"
                    value=""
                    placeholder="Mật khẩu hiện tại"
                    name="old_password"
                    ref="old_password"
            />
            <!--            <a href="#" class="text-sm font-weight-bold">Quên mật khẩu ?</a>-->
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">Mật khẩu mới</label>
          <div class="col-lg-9 col-xl-6">
            <input
                    type="password"
                    class="form-control form-control-lg form-control-solid"
                    value=""
                    placeholder="Mật khẩu mới"
                    name="new_password"
                    ref="new_password"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">Xác nhận mật khẩu mới</label>
          <div class="col-lg-9 col-xl-6">
            <input
                    type="password"
                    class="form-control form-control-lg form-control-solid"
                    value=""
                    placeholder="Xác nhận mật khẩu mới"
                    name="confirm_password"
                    ref="confirm_password"
            />
          </div>
        </div>
        <div class="form-group row"></div>
        <div class="form-group row"></div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import {UPDATE_PASSWORD} from "@/core/services/store/auth.module";
    import KTUtil from "@/assets/js/components/util";

    const _ = require('lodash')

    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

    // FormValidation plugins
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import Swal from "sweetalert2";

    export default {
        name: "ChangePassword",
        data() {
            return {
                password: "",
                status: "",
                valid: true,
                check_spinner: false,
            };
        },
        mounted() {
            const password_change_form = KTUtil.getById("kt_password_change_form");
            this.fv = formValidation(password_change_form, {
                fields: {
                    old_password: {
                        validators: {
                            notEmpty: {
                                message: "Bắt buộc nhập mật khẩu hiện tại"
                            },
                            blank: {
                                message: ""
                            }
                        }
                    },
                    new_password: {
                        validators: {
                            notEmpty: {
                                message: "Bắt buộc nhập mật khẩu mới"
                            },
                            blank: {
                                message: ""
                            }
                        }
                    },
                    confirm_password: {
                        validators: {
                            notEmpty: {
                                message: "Bắt buộc nhập xác nhận mật khẩu mới"
                            },
                            blank: {
                                message: ""
                            },
                            identical: {
                                compare: function () {
                                    return password_change_form.querySelector(
                                        '[name="new_password"]'
                                    ).value;
                                },
                                message: "Xác nhận mật khẩu không khớp"
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new Trigger(),
                    bootstrap: new Bootstrap(),
                    submitButton: new SubmitButton()
                }
            });
            this.fv.on("core.form.invalid", () => {
                Swal.fire({
                    title: "",
                    text: "Hãy kiểm tra lại thông tin của bạn",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary"
                });
            });
        },
        methods: {
            save() {
                this.fv.validate().then( (status) => {
                    if (status == 'Valid') {
                        var old_password = this.$refs.old_password.value;
                        var new_password = this.$refs.new_password.value;
                        var confirm_password = this.$refs.confirm_password.value;
                        const submitButton = this.$refs["kt_save_changes"];
                        this.check_spinner = true;
                        this.$store
                            .dispatch(UPDATE_PASSWORD, {old_password, new_password, confirm_password})
                            .then((rp) => {
                                this.check_spinner = false;
                                this.$router.push({name: "dashboard"}).then(() => {
                                    this.$bvToast.toast(rp.data,{
                                        title: 'Thành công',
                                        variant: 'success',
                                        solid: true
                                    });
                                });
                            }).catch((rp) => {
                                this.check_spinner = false;
                                console.log(rp);
                            });
                        setTimeout(() => {
                            _.forEach(this.errors, (value, key) => {
                                this.fv.updateValidatorOption(key, 'blank', 'message', value.length == 1 ? value[0] : value)
                                    .updateFieldStatus(key, 'Invalid', 'blank');
                            });
                        },500);
                    }
                });
            },
            cancel() {
                this.fv.resetForm();
                this.$refs.old_password.value = "";
                this.$refs.new_password.value = "";
                this.$refs.confirm_password.value = "";
            }
        },
        computed: {
            ...mapState({
                errors: state => state.auth.errors
            }),
            ...mapGetters(["currentUser"])
        }
    };
</script>
