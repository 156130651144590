<template>
  <div>
    <b-modal id="Modal-Confirmed-Plagiarism" centered title="Xác nhận bài thi đạo văn từ 50% trở lên" hide-footer
             :no-close-on-backdrop="true" hide-header-close @show="loading = false">
      <p class="">Khi chọn tỉ lệ đạo văn từ 50% trở lên, hệ thống sẽ tự động kĩ năng writing <span style="color: red">0 điểm</span>
        và kết thúc tác vụ này.</p>
      <p style="font-weight: bold">Bạn có chắc chắn muốn tiếp tục?</p>
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-secondary mr-4" @click="cancelModal()">Hủy bỏ</button>
        <button type="button" class="btn btn-info" @click="submit()" :disabled="loading"><i class="el-icon-loading" v-if="loading"></i> Xác nhận</button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ModalConfirmedPlagiarism",
  props: {
    allCriteria: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      loading:false
    }
  },
  methods: {
    cancelModal() {
      this.allCriteria.forEach((criteria) => {
        criteria.clearPointAndCommentAutoIs0Or1()
      })
      this.$bvModal.hide("Modal-Confirmed-Plagiarism")
    },
    submit() {
      this.loading = true
      this.$emit('submit');
      this.$bvModal.hide("Modal-Confirmed-Plagiarism");
    }
  }
}
</script>
<style scoped>

</style>