import ApiService from "@/core/services/api.service";
import {UPDATE_CONTRACT_BEFORE_DEPOSIT} from "./contracts.module";

const _ = require('lodash');
//action types
export const GET_REGISTER_COURSES = "get_register_courses";
export const EXPORT_EXCEL_REGISTER_COURSE = "export_excel_register_course";
export const EXPORT_EXCEL_REGISTER_COURSE_V2 = "export_excel_register_course_v2";
export const EXPORT_STUDENT_REGISTER_COURSE_GOOGLE_SHEET = "export_student_register_course_google_sheet";
export const GET_REGISTER_COURSES_BY_ID = "get_register_by_id";
export const GET_REGISTER_COURSES_BY_USER = "get_register_course_by_user";
export const DESTROY_STUDENT_REGISTER_COURSE = "destroy_student_register_course";
export const SPEND_STUDENT_REGISTER_COURSE = "spend_student_register_course";
const state = {};

const getters = {};
const actions = {
    [GET_REGISTER_COURSES](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("student-register-course", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((errors) => {
                    console.log(errors)
                });
        });
    },
    [GET_REGISTER_COURSES_BY_USER](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("student-register-course/by-user-id", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((errors) => {
                    console.log(errors)
                });
        });
    },
    [GET_REGISTER_COURSES_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('student-register-course', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [EXPORT_EXCEL_REGISTER_COURSE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('student-register-course/export-excel', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [EXPORT_EXCEL_REGISTER_COURSE_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('student-register-course/export-excel-v2', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    // GOOGLE SHEET
    [EXPORT_STUDENT_REGISTER_COURSE_GOOGLE_SHEET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('student-register-course/export-google-sheet', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DESTROY_STUDENT_REGISTER_COURSE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('destroy-student-register-course', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [SPEND_STUDENT_REGISTER_COURSE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('spend-student-register-course', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

};

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};
