<template>
    <div>
        <b-button  @click="getFeedbackById" v-b-modal="'feedback-ticket'" class="btn btn-secondary btn-sm">Đánh giá của người phản hồi</b-button>
        <b-modal id="feedback-ticket"
                 title="Đánh giá của người phản hồi"
                 size="xl"
                 :ok-disabled="okDisable"
                 ok-title="Cập nhật"
                 cancel-title="Để sau"
                 centered
                 @ok="handleOk"
        >
            <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                <form>
                    <div>
                        <el-radio v-model="is_feedback" label="1" border>Khách hàng có đánh giá</el-radio>
                        <el-radio v-model="is_feedback" label="2" border>Khách hàng không đánh giá</el-radio>
                    </div>
                    <div v-if="is_feedback === '1'" class="mt-4">
                        <div class="form-group">
                            <label for="exampleTextarea">Chọn số sao
                                <span class="text-danger">*</span></label>
                            <ValidationProvider vid="feedback_ticket_star" name="Số sao" rules="required"
                                                v-slot="{ errors,classes }">
                                <el-rate
                                        v-model="ticket.feedback_ticket_star"
                                        text-color="#ff9900"
                                        score-template="{value} points"></el-rate>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                        errors[0]
                                        }}
                                    </div>
                                </div>
                            </ValidationProvider> 
                        </div>
                        <div class="form-group mb-1">
                            <label for="exampleTextarea">Nội dung
                                <span class="text-danger"></span></label>
                            <ValidationProvider vid="feedback_ticket_comment" name="Nội dung Chọn số sao"
                                                rules=""
                                                v-slot="{ errors,classes }">
                        <textarea class="form-control" id="exampleTextarea" rows="3"
                                  v-model="ticket.feedback_ticket_comment" :class="classes"></textarea>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                        errors[0]
                                        }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </b-modal>
    </div>
</template>

<script>
    import {FEEDBACK_TICKET} from "../../../../core/services/store/user/ticket.module";
    import Swal from "sweetalert2";

    export default {
        name: "FeedbackTicketModal",
        props: {
            ticket: {
                type: Object,
                default: () => {
                    return null;
                }
            }
        },
        mounted() {
            this.checkRadioStar();
        },
        data() {
            return {
                handle_save_loading: false,
                okDisable: false,
                feedback_stars: [
                    {
                        id: 0,
                        name: "Không Chọn số sao"
                    },
                    {
                        id: 1,
                        name: "1 sao"
                    },
                    {
                        id: 2,
                        name: "2 sao"
                    },
                    {
                        id: 3,
                        name: "3 sao"
                    },
                    {
                        id: 4,
                        name: "4 sao"
                    },
                    {
                        id: 5,
                        name: "5 sao"
                    }
                ],
                is_feedback: ''
            }
        },
        methods: {
            checkRadioStar() {
                if (this.ticket.feedback_ticket_star > 0) {
                    this.is_feedback = '1';
                }
                this.ticket.status_feedback = this.ticket.status_comment === 1 ? 2 : '';

            },

            getFeedbackById() {
                this.ticket.feedback_ticket_comment = '';
                this.ticket.feedback_ticket_star = 0;
                this.is_feedback = ''; 
            },
            
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault();

                this.handle_save_loading = true;
                this.okDisable = true;
                if (this.is_feedback == '2') {
                    this.ticket.feedback_ticket_star = 0;
                }
                this.$store.dispatch(FEEDBACK_TICKET, this.ticket).then((data) => {
                    if (!data.error) {
                        this.$notify.success({
                            title: 'Thành công',
                            message: 'Đánh giá ticket thành công',
                            offset: 100
                            });
                        this.closeModal();
                        this.$emit('handleTicketSuccess', true);
                        this.handle_save_loading = false;
                        this.okDisable = false;
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                    this.handle_save_loading = false;
                    this.okDisable = false;

                })
            },
            closeModal() {
                this.$bvModal.hide('feedback-ticket');
                this.$emit("reloadFeedBack", true);
            }
        }
    }
</script>

<style scoped>

</style>