<template>
  <fragment>
    <li class="menu-section">
      <h4 class="menu-text">Quản lý dữ liệu</h4>
    </li>

    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fab fa-internet-explorer"></i>
        <span class="menu-text">Website bán hàng</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
          >
            <a href="#" class="menu-link">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Tin tức</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </fragment>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "QuanLyNhanSu",
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
