import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const GET_BOOK_COST_LIST = "finance/cost/book/list"
export const GET_BOX_COST_LIST = "finance/cost/box-and-admin-test/list";
export const PREVIEW_BOOK_COST = "finance/cost/book/preview"
export const PREVIEW_BOX_COST = "finance/cost/box-and-admin-test/preview"
export const IMPORT_EXCEL_BOOK_COST = "finance/cost/book/save";
export const IMPORT_EXCEL_BOX_COST = "finance/cost/box-and-admin-test/save";
export const DELETE_BOX_COST = "finance/cost/delete";
export const EXPORT_EXCEL_NET_REVENUE = "finance/export-net-revenue";

const state = {};

const getters = {};
const actions = {
    [GET_BOOK_COST_LIST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_BOOK_COST_LIST, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [GET_BOX_COST_LIST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_BOX_COST_LIST, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [PREVIEW_BOOK_COST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(PREVIEW_BOOK_COST, payload).then(({data}) => {
                resolve(data.data);
            }).catch(({response}) =>{
                reject(response);
            });
        });
    },
    [PREVIEW_BOX_COST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(PREVIEW_BOX_COST, payload).then(({data}) => {
                resolve(data.data);
            }).catch(({response}) =>{
                reject(response);
            });
        });
    },
    [IMPORT_EXCEL_BOOK_COST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(IMPORT_EXCEL_BOOK_COST, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) =>{
                reject(response);
            });
        });
    },
    [IMPORT_EXCEL_BOX_COST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(IMPORT_EXCEL_BOX_COST, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) =>{
                reject(response);
            });
        });
    },
    [DELETE_BOX_COST](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_BOX_COST, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [EXPORT_EXCEL_NET_REVENUE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(EXPORT_EXCEL_NET_REVENUE, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },

};


const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};