<template>
    <div>
      <b-button
          v-b-modal="'refuse-solution'"
          class="btn btn-danger btn-sm mr-3"
          :class="{ 'spinner spinner-white spinner-left': loadingRefueSolution}"
      >Từ chối giải pháp
      </b-button>
      <b-modal id="refuse-solution"
               title="Từ chối giải pháp"
               size="xl"
               ok-title="Cập nhật"
               ok-only
               centered
               hide-footer
               @ok="handleOk"
      >
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <form>
            <p>Trạng thái</p>
            <span :class="statusDefineCss[ticket.status]" class="mb-4">{{ statusDefine[ticket.status] }}</span>
            <div class="form-group mb-1">
              <label for="exampleTextarea">Nhập lý do từ chối giải pháp</label>
                   <el-input type="textarea" show-word-limit class="mt-1" style="border-radius: 5px; border:0.5px;" :rows="2"  maxlength="500" placeholder="Vui lòng nhập lý do từ chối giải pháp:" v-model="query.reason_refuse"></el-input>
            </div>
          </form>
        </ValidationObserver>
        <b-button
            variant="success"
            size="md"
            class="float-right"
            :disabled="loadingRefueSolution"
            @click="handleOk"
        >
          Lưu
        </b-button>
      </b-modal>
    </div>
  </template>
  <script>
      import {HANDLER_REFUSE_SOLUTION} from "../../../../core/services/store/user/ticket.module";
  
      export default {
        name: "RefuseSolution",
        components: {},
        props: {
          ticket: {
            type: Object,
            default: () => {
              return null;
            }
          }
          },
          mounted() {
          },
          data() {
            return {
            loadingRefueSolution: false,
              query: {
                reason_refuse: ''
              },
              dialogImageUrl: '',
              dialogVisible: false,
              statusDefine: {
                0: 'Hủy',
                1: 'Chờ xử lý',
                2: 'Đã nhập giải pháp',
                3: 'Đã hoàn thành'
              },
              statusDefineCss: {
                0: 'badge badge-danger',
                1: 'badge badge-warning',
                2: 'badge badge-primary',
                3: 'badge badge-success'
              }
            }
          },
          methods: {
            handleOk(bvModalEvent) {
              bvModalEvent.preventDefault();
              this.closeModal();
              this.callApi();
            },
            callApi() {
              this.loadingRefueSolution = true;
              let reason =  this.query.reason_refuse;
              var convert_reason = reason.replace(/\n/g, "<br>");
                this.loadingRefueSolution =  true;
                this.$store.dispatch(HANDLER_REFUSE_SOLUTION,{
                    ticket_id: this.$route.params.id,
                    reason_refuse: convert_reason
                    }).then((res) => {
                        this.$notify.success({
                            title: 'Thành công',
                            message: 'Từ chối giải pháp thành công',
                            offset: 100
                        });
                    this.$emit('refuseSolution', true);
                    this.loadingRefueSolution =  false;
                    }).catch((e) => {
                    
                    }).finally(() => {
                    this.loadingRefueSolution = true;

                });
            },
            closeModal() {
              this.$bvModal.hide('refuse-solution');
            },
    }
  }
  </script>
  
  <style scoped>
  
  </style>