import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const CREATE_PAGE = "create_page";
export const UPDATE_PAGE = "update_page";
export const GET_PAGE = "get_pages";
export const DELETE_PAGE = "delete_page";
export const GET_PAGE_BY_ID = "get_page_by_id";

// Setter

const state = {};
const getters = {};
const actions = {
    [GET_PAGE](context, payload) {
        return new Promise(resolve => {
            ApiService.query('pages', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_PAGE_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('pages', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_PAGE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('pages', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_PAGE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('pages', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_PAGE](context, id) {
        return new Promise(resolve => {
            ApiService.delete('pages', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
