import { render, staticRenderFns } from "./RegisterCalendarDefault.vue?vue&type=template&id=dca94f38&scoped=true"
import script from "./RegisterCalendarDefault.vue?vue&type=script&lang=js"
export * from "./RegisterCalendarDefault.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dca94f38",
  null
  
)

export default component.exports