<template>
  <div class="card  container flex-row py-1 mt-6" style="background-color: #F6F6F6;">
    <h5 class="col-xl-4 col-lg-12 mt-3">Chấm điểm {{currentTest?'Writing':'Speaking'}}: {{ this.$route.query.name ?? "" }}</h5>
  </div>
</template>

<script>
export default {
  name: "CustomerInformation",
  data() {
   return{
     TEST_SPEAKING: 0,
     TEST_WRITING: 1,
     currentTest: +this.$route.query.typeMark
   }
  },
  props: {
    dataUser: {
      type: Object,
      default() {
        return {}
      }
    },
  }
}
</script>

<style scoped>

</style>