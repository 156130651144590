var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2"},_vm._l((_vm.processTypes),function(item){return _c('div',{staticClass:"row mb-12"},[_c('div',{staticClass:"process-total",on:{"click":function($event){return _vm.getError(item.value)}}},[_c('span',{staticClass:"process-type",class:{'process-type-error-selected': item.amount > 0 && item.disable == true,
                            'process-type-error': item.amount > 0 && item.disable == false,
                            'process-type-success-selected': (item.amount == 0 | item.amount == null) && item.disable == true,
                            'process-type-success': (item.amount == 0 | item.amount == null) && item.disable == false
            }},[_vm._v(" "+_vm._s(item.label))])]),_c('span',{staticClass:"amount-error"},[_vm._v(_vm._s(item.amount > 0 ? item.amount : 0))])])}),0),_c('div',{staticClass:"col-md-1"}),_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"example-preview b-table-sticky-header"},[_c('table',{staticClass:"table table-hover border mt-5 table-vertical-center b-table",staticStyle:{"word-break":"break-word"}},[_vm._m(1),_c('tbody',[_vm._l((_vm.errors),function(error,key){return (_vm.errors.length > 0)?_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(key + 1))]),_c('td',[_vm._v(_vm._s(error.message))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDateTimeAsia")(error.date_appear)))])]):_vm._e()}),(_vm.errors.length == 0)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("Hiện tại không có lỗi nào phát sinh")])]):_vm._e()],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2"},[_vm._v(" Task việc: ")]),_c('div',{staticClass:"col-md-1"}),_c('div',{staticClass:"col-md-9"},[_vm._v(" Danh sách lỗi: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{staticClass:"min-w-50px",attrs:{"scope":"col"}},[_vm._v("STT")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nội dung")]),_c('th',{staticStyle:{"min-width":"170px"},attrs:{"scope":"col"}},[_vm._v("Thời gian phát sinh lỗi")])])])
}]

export { render, staticRenderFns }