<template>
  <div class="bg-white">
    <div class="header py-4 px-6">
      <h3 style="font-size: 18px; margin-bottom: 0">Quản lý chi phí box, admin test</h3>
      <el-button class="btn btn-success" @click="importExcel = true">Import</el-button>
    </div>
    <hr style="margin: 0">
    <div class="py-4 px-6">
      <div style="display: flex; align-items: end; gap: 20px">
        <div style="display: flex; flex-direction: column">
          <span style="font-weight: 600; margin-bottom: 5px">Thời gian </span>
          <el-date-picker
              v-model="dateTime"
              type="month"
              placeholder="Chọn tháng">
          </el-date-picker>
        </div>
        <el-button class="btn btn-primary" @click="getBoxCostList">Tìm kiếm</el-button>
      </div>
      <div class="table-responsive mt-3" style="overflow: hidden; padding-bottom: 30px">
        <table class="table" style="text-align: center;">
          <thead>
          <tr style="border: 1px solid #EBEDF3">
            <th scope="col" class="min-w-100px" style="background: #F8F8F8; text-align: start">ID trung tâm</th>
            <th scope="col" class="min-w-100px" style="background: #F8F8F8; text-align: start">Tên trung tâm</th>
            <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: start">Loại chi phí</th>
            <th scope="col" class="min-w-100px" style="background: #F8F8F8; text-align: start">Giá trị</th>
            <th scope="col" class="min-w-120px" style="background: #F8F8F8; text-align: start">Thời gian</th>
            <th scope="col" class="min-w-120px" style="background: #F8F8F8; text-align: center;">Hành động</th>
          </tr>
          </thead>
          <tbody v-if="loading">
          <tr class="col-12">
            <td class="text-center">
              <i class="el-icon-loading" style="position: absolute; left: 50%; margin: 15px 0"></i>
            </td>
          </tr>
          </tbody>
          <tbody v-else-if="dataTable?.length > 0">
          <tr v-for="item of dataTable" :key="item?.index" style="border: 1px solid #EBEDF3; border-top: 0">
            <td style="text-align: start">{{ item.centerId }}</td>
            <td style="text-align: start">{{ item?.name }}</td>
            <td style="text-align: start">{{ item.typeCostString }}</td>
            <td style="text-align: start">{{ item.costValue }}</td>
            <td style="text-align: start">{{ item?.date }}</td>
            <td>
              <button class="btn btn-icon btn-xs btn-outline-danger ml-2" @click="confirmDeleteBoxCost(item)">
                <i class="el-icon-delete"></i>
              </button>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr style="word-break: break-word; white-space: nowrap; position: relative">
            <label style="position: absolute; left: 50%; translate: -50%; top: 10px">Chưa có dữ liệu</label>
          </tr>
          </tbody>
        </table>
        <div v-if="dataTable?.length > 0">
          <div class="edu-paginate mx-auto text-center mt-10 mb-5" style="display: flex; justify-content: center; align-items: center; height: 50px">
            <paginate
                v-model="page"
                :page-count="last_page"
                :page-range="3"
                :margin-pages="1"
                :click-handler="clickCallback"
                :prev-text="'Trước'"
                :next-text="'Sau'"
                :container-class="'pagination b-pagination'"
                :pageLinkClass="'page-link'"
                :next-link-class="'next-link-item'"
                :prev-link-class="'prev-link-item'"
                :prev-class="'page-link'"
                :next-class="'page-link'"
                :page-class="'page-item'"
            >
            </paginate>
          </div>
          <div class="w-100" style="display: flex; justify-content: end; margin-top: -50px">
            Tổng số bản ghi: <span style="color: #0066FF; font-weight: 600; margin-left: 5px;">{{this.total}}</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="Xoá chi phí box, admin test" :visible.sync="isDelete">
      <span>Bạn có chắc chắn muốn xóa không?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isDelete = false" style="background: #CACACA;">Hủy bỏ</el-button>
        <el-button type="primary" style="color: white; background: #5D04B0" @click="handleDeleteBoxCost">Xác nhận</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="titleDialogImport" :visible.sync="importExcel" :show-close="false"
    >
      <hr style="margin-top: -20px; margin-bottom: 20px;">
      <DialogImportExcelBox @setTitleError="setTitle" @closeDialog="importExcel = false" @getListBox="getBoxCostList"/>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {DELETE_BOX_COST, GET_BOX_COST_LIST} from "@/core/services/store/payments/payments.module";
import {BoxCostModel} from "@/view/pages/payments/model/BoxCostModel";
import DialogImportExcel from "@/view/pages/payments/components/DialogImportExcel.vue";
import moment from "moment/moment";
import DialogImportExcelBox from "@/view/pages/payments/components/DialogImportExcelBox.vue";

export default {
  components: {DialogImportExcelBox, DialogImportExcel},
  data() {
    return {
      dateTime: null,
      loading: true,
      dataTable: [],
      page: 1,
      last_page: 1,
      total: 0,
      isDelete: false,
      recordDelete: {},
      importExcel: false,
      titleDialogImport: 'Import chi phí box, admin test'
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý chi phí box, admin test"}
    ]);
    this.getBoxCostList();
  },
  methods: {
    clickCallback(obj) {
      this.page = obj
      this.getBoxCostList()
    },
    getBoxCostList() {
      const payload = {
        date: this.dateTime ? moment(new Date(this.dateTime)).format('YYYY-MM') : null,
        page: this.page,
      }
      this.$store.dispatch(GET_BOX_COST_LIST, payload)
          .then(res => {
            this.loading = false
            this.total = res?.pagination?.total;
            this.last_page = res?.pagination?.last_page;
            this.page = res?.pagination?.current_page;
            this.dataTable = res?.data?.length > 0 ? res.data.map(item => {
              return new BoxCostModel(
                  item?.id,
                  item?.center_id,
                  item?.center_name ?? '',
                  null,
                  null,
                  item?.date,
                  [],
                  item?.type,
                  item?.price,
              )
            }) : []
          })
          .catch(e => {
            console.log(e)
          })
    },
    confirmDeleteBoxCost(item) {
      this.isDelete = true
      this.recordDelete = item
    },
    setTitle(newTitle) {
      this.titleDialogImport = newTitle
    },
    handleDeleteBoxCost() {
      this.$store.dispatch(DELETE_BOX_COST, this.recordDelete.id).then(() => {
        this.$message.success('Xóa thành công')
        this.getBoxCostList()
        this.isDelete = false
      }).catch(e => {
        console.log(e)
      })
    }
  }
}
</script>

<style lang="css" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>