<template>
    <div>
        <button v-if="item.status !== 4 && currentUser.user.permission.includes(permission.HUY)"
                class="btn btn-sm btn-outline-success font-weight-bold mr-2"
                @click="onSubmit()"
        >
            <i class="icon-md far fa-trash-alt"></i>Nạp sai
        </button>
        <button v-if="item.status === 4"
                v-b-modal="`modal-show-user-transfer${index}`"
                class="btn btn-sm btn-outline-danger font-weight-bold mr-2"
                @click="getLogWrongTransfer()"
        >
            <i class="icon-md far fa-times-circle"></i>Đã hủy
        </button>
        <b-modal :centered="true" :scrollable="true" size="xl" ref="modal-edit-created_at" hide-footer
                 :id="'modal-show-user-transfer' + index"
                 title="Chi tiết nạp sai">
            <div class="example mb-10">
                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col" class="min-w-100px">STT</th>
                            <th scope="col" class="min-w-200px">Người sửa</th>
                            <th scope="col" class="min-w-200px">Mã nhân viên</th>
                            <th scope="col" class="min-w-100px">Thời gian sửa</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in log_wrong_transfer" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.user ? item.user.name : '' }}</td>
                            <td>{{ item.user ? item.user.id + 100000 : '' }}</td>
                            <td>{{ item.created_at | formatDateTimeAsia }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import {
        GET_LOG_WRONG_TRANSFER,
        WRONG_TRANSFER
    } from "../../../core/services/store/contract/studentAmount.module";
    import Swal from "sweetalert2";
    import {mapGetters} from "vuex";

    export default {
        name: "ModalWrongTransferComponent",
        components: {},
        props: {
            item: Object,
            index: ''
        },
        data() {
            return {
                log_wrong_transfer: [],
            }
        },
        computed: {
          ...mapGetters(["currentUser"]),
        },
        methods: {
            onSubmit() {
                Swal.fire({
                    title: "Xác nhận nạp sai?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.callApi()
                    }
                });
            },
            getLogWrongTransfer() {
                this.$store.dispatch(GET_LOG_WRONG_TRANSFER, this.item.id).then((res) => {
                    this.log_wrong_transfer = res.data
                });
            },
            callApi() {
                this.$store.dispatch(WRONG_TRANSFER, {
                    student_amount_id: this.item.id,
                }).then((res) => {
                    if (!res.error) {
                      this.$notify({
                        title: 'Thành công',
                        message: res.message,
                        type: 'success'
                      });
                      this.$emit('update_success');
                    } else {
                      this.$notify({
                        title: 'Thất bại',
                        message: res.message,
                        type: 'error'
                      });
                    }
                });
            }
        }
    }
</script>
