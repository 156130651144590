<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="course_create" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Sửa trang tĩnh
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'page-index' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-9">
              <div class="form-group">
                <label>Tiêu đề
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="title" name="Tiêu đề" rules="required"
                                    v-slot="{ errors,classes }">
                  <el-input placeholder="Tiêu đề" v-model="page.title" :class="classes" @input="changeTitle"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Slug<span class="text-danger">*</span></label>
                <ValidationProvider vid="status" name="Slug" rules="required"
                                    v-slot="{ errors,classes }">
                  <el-input disabled placeholder="Slug" v-model="page.slug" :class="classes"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="courses" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Trạng thái <span class="text-danger">*</span></label>
                <ValidationProvider vid="status" name="Trạng thái" rules="required"
                                    v-slot="{ errors,classes }">
                  <el-select v-model="page.status" filterable class="w-100" placeholder="Trạng thái">
                    <el-option
                        v-for="item in status"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      <span style="float: left">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="courses" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group mb-1 col-md-12">
              <label>Nội dung
                <span class="text-danger">*</span></label>
              <ValidationProvider vid="content" name="Nội dung" rules="required"
                                  v-slot="{ errors,classes }">
                <vue-editor id="editor-content" :editorOptions="editorSettings" useCustomImageHandler
                            v-model="page.content"
                            :class="classes"
                            @image-added="handleImageAdded"></vue-editor>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                      errors[0]
                    }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button
                type="submit"
                class="btn btn-success mr-2"
            >
              Cập nhật
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>
import Multiselect from 'vue-multiselect'
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";

import {Money} from 'v-money';
import Swal from "sweetalert2";

// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

//editor
import ApiService from "@/core/services/api.service";
import {Quill, VueEditor} from 'vue2-editor'
import ImageResize from 'quill-image-resize-vue';
import {ImageDrop} from 'quill-image-drop-module';
import {mapGetters} from "vuex";
import {CREATE_PAGE, GET_PAGE_BY_ID, UPDATE_PAGE} from "../../../core/services/store/pages/page.module";
import {ChangeToSlug} from "../../../core/filters";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
export default {
  components: {
    Multiselect,
    DatePicker,
    VueEditor,
    Money
  },
  name: "NewsCreate",
  data() {
    return {
      status: [
        {
          value: 0,
          label: 'Ẩn',
        },
        {
          value: 1,
          label: 'Hoạt động',
        },
      ],

      // Check show
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      page: {
        title: '',
        status: '',
        slug: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapGetters(["getTotalHighlights"]),

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý trang tĩnh", route: 'page-index'},
      {title: "Sửa trang tĩnh"}
    ]);
    this.getById();
  },
  methods: {
    getById() {
      let id = this.$route.params.id;
      this.$store.dispatch(GET_PAGE_BY_ID, id).then((res) => {
        if (!res.error) {
          this.page = res.data;
        }
      })
    },
    changeTitle: function () {
      this.page.slug = ChangeToSlug(this.page.title);
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("image", file);

      ApiService.post('upload-image', formData)
          .then(({data}) => {
            let url = data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch(err => {
            console.log(err);
          });
    },
    onSubmit() {
      this.$store.dispatch(UPDATE_PAGE, this.page).then((res) => {
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Cập nhật',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$router.push({name: "page-index"}).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Cập nhật',
              variant: 'success',
              solid: true
            });
          })
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      });
    },
  }
}
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}

.image-input-wrapper {
  width: 215px !important;
  height: 215px !important;
  left: 0px !important;
  top: 24px !important;
  /*background: #C4C4C4;*/
  border-radius: 5px !important;
}

.image-input {
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
