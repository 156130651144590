import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "../auth.module";

const _ = require('lodash');

export const GET_CATEGORY = "get-category";
export const DELETE_CATEGORY = "delete-category";
export const CREATE_CATEGORY = "create-category";
export const GET_CATEGORY_BY_ID = "get-category-by-id";
export const UPDATE_CATEGORY = "update-category";
export const GET_DEPARTMENT = "get-department";
export const UPDATE_STATUS_CATEGORY = "ticket-category/change-status";

const state = {
    errors: null,
    paginateData: [],
};

const getters = {};

const actions = {
    [GET_DEPARTMENT](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query('departments-ticket', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },
    [GET_CATEGORY](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query('ticket-category', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },
    [GET_CATEGORY_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('ticket-category', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [UPDATE_CATEGORY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('ticket-category', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [UPDATE_STATUS_CATEGORY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(`${UPDATE_STATUS_CATEGORY}`, payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [CREATE_CATEGORY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('ticket-category', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [DELETE_CATEGORY](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('ticket-category', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
}

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
