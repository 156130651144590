<template>
  <div>
    <button class="btn btn-primary btn-sm font-weight-bold btn-sm"
            v-b-modal="`task-import-excel-modal`">
      <i class="flaticon-upload"></i>
      Import dữ liệu
    </button>
    <b-modal
        centered
        id="task-import-excel-modal"
        size="xl"
        title="Import dữ liệu"
        :ok-title="preview ? 'Xác định' : 'Tiếp theo'"
        :cancel-title="preview ? 'Quay lại' : 'Hủy'"
        :ok-disabled="okDisable"
        hide-footer
        @hidden="removeFile()"
    >
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" autocomplete="off">
          <div v-if="!preview" class="row import">
            <div class="col-md-12">
              <p>Bước 1: Tải file mẫu excel</p>
              <button type="button" class="btn btn-info btn-sm" @click="downloadExcelExample"><i
                  class="flaticon-download"></i>Tải
                file mẫu
                <i class="el-icon-loading" v-if="is_loading_download_file"></i>
              </button>
            </div>
            <div class="col-md-12 mt-4">
              <p>Bước 2: Thiết lập dữ liệu tại file googlesheet, tải xuống file định dạng .csv và upload file đó lên hệ
                thống</p>
              <div class="file-input">
                <ValidationProvider name="type_task" rules="required|max:30" v-slot="{ errors,classes }"
                                    vid="type_task">
                  <input
                      v-if="!disable_import"
                      type="file"
                      name="file-input"
                      id="file-input"
                      class="file-input__input"
                      @change="onFileChange"
                  />
                  <p v-if="error_file" class="text-danger">{{ error_file }}</p>
                </ValidationProvider>
                <label class="file-input__label" for="file-input"
                       :class="{'on-disable-import' : disable_import, 'disable-import' : !disable_import}">
                  <span><i class="flaticon-upload mr-2" style="color: #000000"></i>Upload file</span></label
                >
              </div>
              <div class="result-file" v-if="files">
                <label for="">{{ files.name }}</label>
                <i @click="removeFile" class="text-danger el-icon-delete cursor-pointer ml-4"></i>
              </div>
              <div v-else><p class="text-danger">Chưa có file nào được chọn</p></div>
            </div>
          </div>
          <div v-else class="row preview">
            <p class="ml-4">Bước 3: Kiểm tra dữ liệu</p>
            <table class="table">
              <thead>
              <tr>
                <th scope="col">STT</th>
                <th scope="col">Loại tác vụ</th>
                <th scope="col">Đối tượng xử lý</th>
                <th scope="col">Thời gian bắt đầu</th>
                <th scope="col">Thời gian kết thúc</th>
                <th scope="col">Người phụ trách</th>
                <th scope="col">Kết quả kiểm tra</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in data_preview" :key="index">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ item.loai_tac_vu }}</td>
                <td>{{ item.doi_tuong_xu_ly }}</td>
                <td>{{ item.thoi_gian_bat_dau }}</td>
                <td>{{ item.thoi_gian_ket_thuc }}</td>
                <td>{{ item.user_name }} - {{ item.user_phone }}</td>
                <td>
                <span v-if="!item.errors.length" class="badge badge-success font-weight-bold">
                    Đạt
                </span>
                  <span v-else>
                <span class="text-danger" v-for="(error, key) in item.errors" :key="key">
                  <span> {{ error }}</span> <br>
                </span>
                <br>
              </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-if="preview" class="row d-flex justify-content-end">
            <button type="button" class="btn btn-secondary mr-4" @click="comeBack() ">Quay lại</button>
            <button type="button" :disabled="!pass" class="btn btn-primary" @click="uploadImport">Xác nhận</button>
          </div>
          <div v-else class="row d-flex justify-content-end">
            <button type="button" class="btn btn-secondary mr-4" @click="closeModal">Hủy</button>
            <button type="button" class="btn btn-primary" @click="uploadDataTaskImport">Tiếp theo</button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  TEST_INPUT_DOWNLOAD_EXAMPLE,
  TEST_INPUT_GET_TESTER_BY_USER_ID, TEST_INPUT_PREVIEW_TASK,
  TEST_INPUT_STORE_TASK, TEST_INPUT_UPLOAD_DATA_EXCEL
} from "@/core/services/store/test/testInput.module";
import {TIME_PICKER_OPTIONS, TYPE_TASK} from "@/core/option/testInputOption";

export default {
  name: "TaskImportExcelModal",
  components: {DatePicker},
  props: {},
  data() {
    return {
      //setup
      preview: false,
      disable_import: false,
      pass: true,
      data_preview: [],
      is_loading_download_file: false,
      showTimePanel: false,
      showTimeRangePanel: false,
      okDisable: false,
      search_loading: false,
      fileList: [],
      type_task: TYPE_TASK,
      testers: [],
      files: null,
      schedule: {
        type_task: '',
        tester_id: '',
        schedule: '',
        end_schedule: '',
      },
      time_picker_options: TIME_PICKER_OPTIONS,
      error_file: ''
    }
  },
  methods: {
    comeBack(){
      this.preview = false
      this.disable_import = true
    },
    removeFile() {
      this.disable_import = false
      this.files = null
    },
    onFileChange(e) {
      this.disable_import = true;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.files = files[0];
    },
    uploadImport() {
      this.$store.dispatch(TEST_INPUT_UPLOAD_DATA_EXCEL, this.data_preview).then((data) => {
        this.preview = false;
        this.data_preview = [];
        this.$message.success(data.message);
        this.closeModal();
        this.$emit('create-success');
      }).catch((error) => {
        // this.$message.error(error.data.message);
      });
    },
    uploadDataTaskImport(bvModalEvent) {
      this.disable_import = true;
      this.error_file = '';
      bvModalEvent.preventDefault();
      let formData = new FormData();
      formData.append('dataImport', this.files);
      this.$store.dispatch(TEST_INPUT_PREVIEW_TASK, formData).then((data) => {
        this.preview = true;
        this.data_preview = data.data.result;
        this.pass = data.data.pass;
        // this.$message.success(data.message);
      }).catch((error) => {
        if (error?.data?.data?.message_validate_form?.dataImport) {
          this.error_file = error?.data.data.message_validate_form.dataImport[0]
        }
        // this.$message.error(error.data.message);
      }).finally(() => {
        this.disable_import = false;
      })
    },
    callApi() {
      this.okDisable = true;
      this.$store.dispatch(TEST_INPUT_STORE_TASK, this.schedule).then((data) => {
        this.okDisable = false;
        this.$message.success(data.message);
        this.$emit('create-success');
        this.closeModal();
      }).catch((e) => {
        this.okDisable = false;
        if (e.response.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.response.data.data.message_validate_form);
        }
      });
    },
    // Set phòng ban xử lý

    closeModal() {
      this.$bvModal.hide('task-import-excel-modal');
    },
    downloadExcelExample() {
      this.is_loading_download_file = true;
      this.$store.dispatch(TEST_INPUT_DOWNLOAD_EXAMPLE, {}).then((data) => {
        this.$message.success(data.message);
      }).catch((error) => {
        this.$message.error(error.data.message);
      }).finally(() => {
        this.is_loading_download_file = false;
      })
    },
    remoteMethod(query) {
      this.search_loading = true;
      this.$store.dispatch(TEST_INPUT_GET_TESTER_BY_USER_ID, {
        keyword: query
      }).then((data) => {
        this.search_loading = false;
        this.testers = data.data;
      })
      if (query !== '') {
        this.search_loading = true;
        setTimeout(() => {
          this.search_loading = false;
          this.options = this.list.filter(item => {
            return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
  }
}
</script>

<style scoped>
.el-select {
  width: 100%;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}

.file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  height: 34px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  padding: 7px 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.disable-import {
  background-color: #FF9900;
}

.on-disable-import {
  background-color: #a6a6a6;
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

</style>