<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
          Loại Danh mục
      </div>
      <div class="card-title">
          <button
              class="font-weight-bold font-size-3  btn btn-success"
              @click="addnew"
            >Thêm mới
          </button>
      </div>
    </div>

    <div class="card-body">
      <div class="example mb-10">
          <div class="form-group">
                <div class="input-group row">
                      <div class="col-md-3">
                          <input type="text" class="form-control input-search"
                              placeholder="Nhập tên loại danh mục"
                              v-model="query_name"
                              aria-describedby="basic-addon2">
                      </div>
                      <div class="col-md-3">
                          <multiselect v-model="category" :options="categories"
                              placeholder="Chọn danh mục"
                              label="name"
                              track-by="name"
                              @input="updateCategory"
                              >
                          </multiselect>
                      </div>
                </div>
            </div>
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
              <tr>
                <th>STT</th>
                <th>Name</th>
                <th>Danh mục</th>
                <th>Thời gian tạo</th>
                <th style="min-width: 100px">Hành động</th>
              </tr>
            </thead>
            <tbody v-if="data.length > 0" >
              <tr v-for="(item, index) in data" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.category.name }}</td>
                <td>{{ item.created_at | formatDateTimeAsia }}</td>
                <td>
                    <a title="Chỉnh sửa" class="btn btn-icon mr-2 btn-outline-info" @click="edit(index, item)" href="javascript:">
                                    <i class="fas fa-pen-nib"></i>
                                </a>
                    <a title="Xóa" @click="deleteCategory(item.id)" href="javascript:"
                        class="btn btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
                <paginate
                        v-model="page"
                        :page-count="total_pages"
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="getListType"
                        :prev-text="'Trước'"
                        :next-text="'Sau'"
                        :container-class="'pagination b-pagination'"
                        :pageLinkClass="'page-link'"
                        :page-class="'page-item'"
                        :next-link-class="'next-link-item'"
                        :prev-link-class="'prev-link-item'"
                        :prev-class="'page-link'"
                        :next-class="'page-link'"
                >
                </paginate>
            </div>
    
    <el-dialog :title="form_add ? 'Thêm mới loại danh mục' : 'Chỉnh sửa loại danh mục'" :visible.sync="checkVisibleAddEditForm">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" id="room_create" @submit.prevent="handleSubmit(form_add ? create : update )">
            <div class="card card-custom">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Tên loại danh mục
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider vid="name" name="Tên" rules="required"
                                                    v-slot="{ errors,classes }">
                                    <input type="text" ref="name" name="name" class="form-control"
                                           v-on:input="changeName"
                                           v-model="form.name"
                                           placeholder="Nhập tên loại danh mục" :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Danh mục
                                <span class="text-danger">*</span></label>
                                <ValidationProvider name="Danh mục" rules="required" v-slot="{ errors,classes }">
                                <multiselect
                                    v-model="form.category"
                                    :options="categories"
                                    placeholder="Danh mục"
                                    label="name"
                                    track-by="name"
                                    :class="classes"
                                    @input="updateStateCategory($event)"
                                ></multiselect>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                        errors[0]
                                    }}
                                    </div>
                                </div>
                                </ValidationProvider>
                            </div>
            </div>
                    </div>
                </div>

                <div class="card-header">
                    <div class="card-title align-items-start flex-column  float-right">
                        <button
                                type="submit"
                                class="btn btn-success mr-2 float-right"
                        >
                            {{ form_add ? 'Tạo mới' : "Chỉnh sửa" }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
      <div slot="footer" class="dialog-footer">
      </div>
  </el-dialog>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
  import { GET_TYPE, CREATE_TYPE , UPDATE_TYPE, DELETE_TYPE,GET_ALL_CATEGORY}
    from '../../../core/services/store/support/type.module';
  import Swal from "sweetalert2";
  import Multiselect from 'vue-multiselect'


  export default {
    name: "SupportType",

    components: {
        Multiselect
    },
    data() {
      return {
        page: 1,
        total_pages:0,
        data: [],
        checkVisibleAddEditForm: false,
        form: {
          name: ''
        },
        form_add: false,
        categories: [],
        query_name:"",
        category:null,
        category_id : null
      }
    },

    mounted() {
        this.getListType();
        this.getAllCategory();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Hỗ trợ"},
            {title: "Quản lý loại danh mục"}
        ]);
    },
    computed: {
    },
    watch: {
        query_name(after, before) {
            if (after !== before) {
                this.getListType();
            }
        },
    },
    methods: {
      updateCategory ($event) {
        if($event) {
        this.category_id = $event.id;
        this.getListType();
        } else {
           this.category_id = null; 
           this.getListType();
        }
      },
      updateStateCategory($event){
      },
      changeName(){
        this.form.slug = this.removeAccents(this.form.name);
      },
      removeAccents(str) {
        str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return str.replaceAll(' ','-');
      },
      getListType() {
          this.$store.dispatch(GET_TYPE, {
            page: this.page,
            name : this.query_name,
            category_id:this.category_id,
        }).then((res) => {
            this.data = res.data.data;
            this.total_pages = res.data.last_page;
        });          
        
      },

      getAllCategory() {
        this.$store.dispatch(GET_ALL_CATEGORY).then((res) => {
            this.categories = res.data;
        });
      },

      edit(index, item) {
          this.form_add = false;
          this.form = item
          this.checkVisibleAddEditForm = true;
          this.getListType();
      },

      addnew() {
        this.form = {}
        this.checkVisibleAddEditForm = true;
        this.form_add = true
      },
      deleteCategory(id){
            Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire("Xóa", "", "success");
                        this.$store.dispatch(DELETE_TYPE, id).then((res) => {
                            if (res.error) {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'danger',
                                    solid: true
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'success',
                                    solid: true
                                });
                                this.getListType();
                            }
                        });
                    }
                });
      },
      async create() {
        let data_create = {
            name: this.form.name,
            slug: this.form.slug,
            category_id: this.form.category.id,
        }
          this.$store.dispatch(CREATE_TYPE, data_create).then((res) => {
            this.$notify({
              title: 'Thành công',
              message: 'Thêm mới loại danh mục thành công',
              type: 'success'
            });
            this.getListType();
            this.checkVisibleAddEditForm = false;
          }).catch((e) => {
            this.$notify({
            title: 'Thất bại',
            message: e.data.message,
            type: 'success'
          });
          });
      },

      async update() {
        let data_update = {
            id:this.form.id,
            name: this.form.name,
            slug: this.form.slug,
            category_id: this.form.category.id,
        }
        try {
          this.$store.dispatch(UPDATE_TYPE, data_update).then((res) => {
            this.$bvToast.toast('Sửa loại danh mục thành công', {
                title: 'Chỉnh sửa',
                variant: 'success',
                solid: true
            });
            this.getListType();
            this.checkVisibleAddEditForm = false;
          });
        } catch (error) {
          console.log("error", error)
        }
      }
    }
  }

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
