<template>
  <div class="bg-white p-8">
    <div style="display: flex; justify-content: space-between; width: 100%; align-items: center;">
      <span style="font-size: 14px; font-weight: 400">Notification</span>
      <button class="btn btn-success" style="height: 30px; display: flex; align-items: center" @click="isNotificationDialog = true">Gửi thông báo</button>
    </div>
    <hr/>
    <div style="display: flex; flex-direction: column; gap: 10px">
      <div class="grid-filter">
        <div>
          <label class="font-weight-600">Target</label>
          <el-input placeholder="Nhập số điện thoại, telegram id" size="small" v-model="target"/>
        </div>
        <div>
          <label class="font-weight-600">Nội dung</label>
          <el-input placeholder="Nhập nội dung" size="small" v-model="content"/>
        </div>
              <div style="display: flex; flex-direction: column">
                <label class="font-weight-600">Kênh gửi tin</label>
                <el-select v-model="chanel" clearable placeholder="Kênh gửi tin" size="small">
                  <el-option
                      v-for="item in chanelOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div style="display: flex; flex-direction: column">
                <label class="font-weight-600">Trạng thái gửi</label>
                <el-select v-model="status" clearable placeholder="Chọn trạng thái" size="small">
                  <el-option
                      v-for="item in statusSend"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
        <div>
          <label class="font-weight-600">Mã lỗi</label>
          <el-input size="small" v-model="codeError" placeholder="Nhập mã lỗi"/>
        </div>
      </div>
      <div class="grid-filter" style="margin-bottom: 20px">
        <div>
          <label class="font-weight-600">Thời gian gửi từ</label>
          <el-date-picker
              v-model="timeStart"
              type="date"
              size="small"
              placeholder="Chọn thời gian"
              style="width: 100%"
          >
          </el-date-picker>
        </div>
        <div>
          <label class="font-weight-600">Thời gian gửi đến</label>
          <el-date-picker
              v-model="timeEnd"
              type="date"
              size="small"
              placeholder="Chọn thời gian"
              style="width: 100%"
          >
          </el-date-picker>
        </div>
        <div style="display: flex; align-items: end">
          <el-button @click="getListNotification" type="text" style="background: #4D44B5; color: white; padding: 8px 12px">
            Tìm kiếm
          </el-button>
        </div>
      </div>
    </div>
    <div class="table-responsive mt-3" style="overflow: hidden; padding-bottom: 30px">
      <table class="table" style="text-align: center;">
        <thead>
          <tr style="border: 1px solid #EBEDF3">
            <th scope="col" class="w-40px" style="background: #F8F8F8">STT</th>
            <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: start">Bên gửi</th>
            <th scope="col" class="min-w-100px" style="background: #F8F8F8; text-align: start">Target</th>
            <th scope="col" class="min-w-120px" style="background: #F8F8F8; text-align: start">Nội dung</th>
            <th scope="col" class="min-w-120px" style="background: #F8F8F8; text-align: start;">Trạng thái gửi</th>
            <th scope="col" class="w-80px" style="background: #F8F8F8; text-align: start">Mã lỗi</th>
            <th scope="col" class="min-w-120px" style="background: #F8F8F8; text-align: start">Lý do lỗi</th>
            <th scope="col" class="w-150px" style="background: #F8F8F8; text-align: start">Thời gian gửi</th>
            <th scope="col" class="w-100px" style="background: #F8F8F8">Hành động</th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <tr class="col-12">
            <td class="text-center">
              <i class="el-icon-loading" style="position: absolute; left: 50%; margin: 15px 0"></i>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="notificationList?.length > 0">
          <tr v-for="(item, index) of notificationList" :key="item?.index" style="border: 1px solid #EBEDF3; border-top: 0">
            <td>{{index + 1}}</td>
            <td style="text-align: start; text-transform: uppercase">
              {{item?.social}}
            </td>
            <td style="text-align: start">{{item?.target}}</td>
            <td>
              <p class="line-clamp-1" style="white-space: pre-wrap; text-align: start" v-html="item.content"></p>
            </td>
            <td style="display: flex; justify-content: start; border-top: 0 !important;">
              <span
                  v-if="item?.status"
                  class="label-custom"
                  :class="item?.status === 1 ? 'bg-green' : item?.status === 2 ? 'bg-red' : item?.status === 3 ? 'bg-gray' : ''"
              >
                {{item?.statusLabel}}
              </span>
            </td>
            <td style="text-align: start">{{item?.codeError}}</td>
            <td>
              <p v-if="item.codeError" class="line-clamp-1" style="text-align: start;">{{item?.reasonError}}</p>
            </td>
            <td style="text-align: start">{{item?.dateTime}}</td>
            <td>
              <button class="btn btn-icon btn-xs btn-outline-success ml-2" @click="openDetailNotification(item)">
                <i class="far fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr style="word-break: break-word; white-space: nowrap; position: relative">
            <label style="position: absolute; left: 50%; translate: -50%; top: 10px">Chưa có dữ liệu</label>
          </tr>
        </tbody>
      </table>
      <div v-if="notificationList?.length > 0">
        <div class="edu-paginate mx-auto text-center mt-10 mb-5" style="display: flex; justify-content: center; align-items: center; height: 50px">
          <paginate
              v-model="page"
              :page-count="last_page"
              :page-range="3"
              :margin-pages="1"
              :click-handler="clickCallback"
              :prev-text="'Trước'"
              :next-text="'Sau'"
              :container-class="'pagination b-pagination'"
              :pageLinkClass="'page-link'"
              :next-link-class="'next-link-item'"
              :prev-link-class="'prev-link-item'"
              :prev-class="'page-link'"
              :next-class="'page-link'"
              :page-class="'page-item'"
          >
          </paginate>
        </div>
        <div class="w-100" style="display: flex; justify-content: end; margin-top: -50px">
          Tổng số bản ghi: <span style="color: #0066FF; font-weight: 600; margin-left: 5px;">{{this.total}}</span>
        </div>
      </div>
    </div>
    <el-dialog title="Chi tiết" :show-close="false" :visible.sync="isDetailTicket">
      <div style="width: 100%; height: 1px; background: gray; margin-top: -20px"></div>
      <div style="display: grid; align-items: center; margin-top: 18px; gap: 15px; grid-template-columns: 4fr 4fr 4fr">
        <div style="display: flex; flex-direction: column;">
          <label class="label-detail">Bên gửi</label>
          <input disabled :value="notification.social" class="border rounded px-2 py-1" style="text-transform: uppercase"/>
        </div>
        <div style="display: flex; flex-direction: column;">
          <label class="label-detail">Target</label>
          <input disabled :value="notification.target" class="border rounded px-2 py-1"/>
        </div>
        <div style="display: flex; flex-direction: column;">
          <label class="label-detail">Thời gian gửi</label>
          <input disabled :value="notification.dateTime" class="border rounded px-2 py-1"/>
        </div>
      </div>
      <div class="mt-4 display: flex; flex-direction: column;">
        <label class="label-detail">Nội dung</label>
        <p style="word-break: break-word; min-height: 50px; width: 100%; border: 1px solid gray;
        border-radius: 5px; padding: 5px 14px; white-space: pre-wrap" v-html="notification.contentDetail"></p>
      </div>
      <div style="display: grid; align-items: center; margin-top: 18px; gap: 15px; grid-template-columns: 6fr 6fr">
        <div style="display: flex; flex-direction: column;">
          <label class="label-detail">Trạng thái</label>
          <input disabled :value="notification.statusLabel" class="border rounded px-2 py-1"/>
        </div>
        <div v-if="notification.codeError" style="display: flex; flex-direction: column;">
          <label class="label-detail">Mã lỗi</label>
          <input disabled :value="notification.codeError" class="border rounded px-2 py-1"/>
        </div>
      </div>
      <div v-if="notification.codeError" style="display: flex; flex-direction: column; margin-top: 15px">
        <label class="label-detail" v-if="notification.reasonError">Lý do lỗi</label>
        <div style="display: grid; grid-template-columns: 8fr 4fr; gap: 15px">
          <input v-if="notification.reasonError" disabled :value="notification.reasonError" class="border rounded px-2 py-1"/>
          <el-button
              v-if="notification.status === 2"
              type="primary"
              @click="confirmReSend = true"
              class="btn-send"
          >
            <i class="el-icon-s-promotion text-white"></i>
            <span>Gửi lại</span>
          </el-button>
        </div>
      </div>
      <div slot="footer" class="dialog-footer d-flex justify-content-end">
        <el-button @click="isDetailTicket = false" class="button-custom"
                   style="background: #E6E6E6 !important; color: #0a0a0a !important;">
          Đóng
        </el-button>
      </div>
    </el-dialog>
    <el-dialog title="Gửi lại" :show-close="false" :visible.sync="confirmReSend" width="40%">
      <div style="width: 100%; height: 1px; background: gray; margin-top: -20px; margin-bottom: 20px"></div>
      <p style="font-weight: 500">Bạn có chắc chắn muốn gửi lại tin cho target {{notification.target}}.</p>
      <div style="font-weight: 500">
        <label>Nội dung:</label>
        <p style="word-break: break-word; min-height: 50px; width: 100%; white-space: pre-wrap" v-html="notification.contentDetail">
        </p>
      </div>
      <p></p>
      <template #footer>
        <el-button style="background: #CACACA" @click="confirmReSend = false">Hủy bỏ</el-button>
        <el-button
            :disabled="loadingResend"
            :class="loadingResend ? 'bg-secondary' : ''"
            style="background: #4D44B5; color: white; position:relative;"
            @click="reSendNotification(notification.id)"
        >
          <i v-if="loadingResend" class="el-icon-loading" style="position: absolute; left: 50%; translate: -50%"></i>
          Xác nhận
        </el-button>
      </template>
    </el-dialog>
    <el-dialog :title="titleError"
               :show-close="false" :visible.sync="isNotificationDialog"
               :destroy-on-close="true"
    >
      <hr style="margin-top: -20px; margin-bottom: 20px"/>
      <DialogNotification @closeDialog="closeDialog" @setTitleError="setTitleError"/>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {chanelOption, NotificationModel, statusSend} from "@/view/pages/system-information/models/notificationModel";
import {GET_LIST_NOTIFICATION, NOTIFICATION_RESEND} from "@/core/services/store/course/classes.module";
import moment from "moment-timezone";
import DialogNotification from "@/view/pages/system-information/component/DialogNotification.vue";

export default {
  name: 'NotificationList',
  components: {DialogNotification},
  data() {
    return {
      page: 1,
      last_page: 1,
      total: 0,
      chanelOption: chanelOption,
      statusSend: statusSend,
      notificationList: [],
      isDetailTicket: false,
      notification: {},
      target: null ?? this.$route.query.target,
      content: null ?? this.$route.query.content,
      chanel: null ?? this.$route.query.provider,
      status: null ?? this.$route.query.status,
      codeError: null ?? this.$route.query.error_code,
      timeStart: null ?? this.$route.query.start_date,
      timeEnd: null ?? this.$route.query.end_date,
      loading: true,
      confirmReSend: false,
      loadingResend: false,
      isNotificationDialog: false,
      titleError: 'Gửi thông báo',
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Notification", name: 'NotificationList' },
      { title: "Quản lý hệ thống thông tin"},
    ]);
    this.getListNotification();
  },
  methods: {
    clickCallback(obj) {
      this.page = obj;
      this.getListNotification()
    },
    async getListNotification() {
      let payload = {
        target: this.target,
        content: this.content,
        provider: this.chanel,
        status: this.status,
        error_code: this.codeError,
        start_date: this.formatDate(this.timeStart),
        end_date: this.formatDate(this.timeEnd),
        page: this.page,
      }
      this.notificationList = await this.$store.dispatch(GET_LIST_NOTIFICATION, payload).then(res => {
        this.total = res.pagination.total;
        this.page = res.pagination.current_page;
        this.last_page = res.pagination.last_page;
        const data = res.data
        this.filterByCondition()
        this.loading = false
        if (data && data?.length > 0) {
          return data?.map(item => {
            return new NotificationModel(
                item?.id,
                item?.provider,
                item?.target,
                item?.content,
                item?.status,
                item.error_code !== 0 ? item.error_code : null,
                item?.error_text,
                item?.time,
                item?.quantitySend,
                item?.option
            )
          })
        }else {
          return [];
        }
      }).catch(e => {
        console.log(e)
      })
    },
    formatDate(date) {
      if (date) {
        return moment(date).format('YYYY-MM-DD');
      }
    },
    openDetailNotification(item) {
      this.isDetailTicket = true
      this.notification = item
    },
    reSendNotification(id) {
      this.loadingResend = true
      this.$store.dispatch(NOTIFICATION_RESEND, id).then((res) => {
        this.$message({
          message: 'Gửi lại thành công !',
          type: 'success'
        });
        this.confirmReSend = false
        this.isDetailTicket = false
        this.getListNotification()
        this.loadingResend = false
      }).catch((e) => {
        this.$message({
          message: 'Gửi lại thất bại !',
          type: 'error'
        });
      });
    },
    setTitleError(titleError) {
      this.titleError = titleError
    },
    closeDialog(data) {
      this.titleError = data
      this.isNotificationDialog = false
    },
     filterByCondition() {
      this.$router.push({
        name: 'NotificationList',
        query: {
          'target': this.target,
          'content': this.content,
          'provider': this.chanel,
          'status': this.status,
          'error_code': this.codeError,
          'start_date': this.formatDate(this.timeStart),
          'end_date': this.formatDate(this.timeEnd),
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .label-custom {
    padding: 4px 8px;
    border-radius: 5px;
    color: white;
  }
  .bg-green {
    background: #038E00;
  }
  .bg-red {
    background: #E53E3E;
  }
  .bg-gray {
    background: #706F6F;
  }
  .label-detail {
    font-size: 14px;
    font-weight: 600;
  }
  .grid-filter {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
    gap: 50px;
  }
  .font-weight-600 {
    font-weight: 600;
  }
  .btn-send {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background:#4D44B5 !important;
    border-radius: 80px;
    color: white;
    padding: 10px 15px;
    width: 120px;
  }
</style>
