<template>
    <div id="el-container" >
        <div id="app" class="mx-auto overflow-auto h-50">
            <CoolLightBox
                :items="items"
                :index="index"
                :effect="'fade'"
                @close="index = null">
            </CoolLightBox>
            <div class="images-wrapper">
                <div
                    class="image"
                    v-for="(image, imageIndex) in itemImages"
                    :key="imageIndex"
                    @click="index = imageIndex"
                    :style="{ backgroundImage: 'url(' + image.url + ')', opacity: image.is_use=== 0 ? 0.3 : 1 }"
                    :title="image.is_use === 1 ? 'Đã kích hoạt' : ''"
                >
                    <i class="fas fa-unlock-alt" style="color: yellow; opacity: 0.8" v-show="image.is_use"></i>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-end mt-3">
            <button class="btn btn-default mr-2" type="button" @click="close">
                <i v-if="loading" class="el-icon-loading"></i> Đóng
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "AllBadges",
    props: {
        items: {
            type: Array,
            default(){
                return [];
            }
        },
        itemImages: {
            type: Array,
            default(){
                return [];
            }
        },
    },
    data() {
        return {
            loading: false,
            index: null,
        }
    },
    methods : {
        close: function () {
            this.$emit('close');
        },
    }
}
</script>

<style scoped>

</style>