<template>
    <common-center-list type="qldvsx"></common-center-list>
</template>

<script>
    import CommonCenterList from "@/view/pages/centers/CommonCenterList";
    export default {
      name: "List-Center",
      components : { CommonCenterList },
    };
</script>

