<template>
  <div class="card card-custom gutter-b">
    <router-view></router-view>
    <div class="card-header">
      <div class="card-title">Quản lý giáo trình : {{ course ? course.name : '' }} - Buổi {{centerLearing.lesson}}</div>
      <div class="card-title">
        <b-button v-b-modal.modal-1 class="btn btn-success" @click="addCurriculu">Thêm mới</b-button>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="example-preview table-responsive">
          <table class="table table-bordered">
            <thead class="thead-light">
              <tr class="text-center">
                <th scope="col" style="width: 20%">Tên giáo trình</th>
                <th scope="col" style="width: 20%">Trung tâm áp dụng</th>
                <th scope="col" style="width: 20%" class="text-center">Người upload</th>
                <th scope="col" style="width: 15%" class="text-center">Thời gian upload</th>
                <th scope="col" style="width: 15%" class="text-center">Trạng thái</th>
                <th scope="col" style="width: 20%" class="text-center">Hành động</th>
              </tr>
            </thead>
            <tbody v-if="curriculum.length > 0">
              <tr v-for="(item, index) in curriculum" :key="index">
                <td class="text-center">{{ item.name_slide }}</td>
                <td class="text-center">
                  <span v-for="(item_p, index_p) in item.centers_curriculum" :key="index_p">
                      {{ item_p.center ? item_p.center.name : '' }} <span v-if="item.centers_curriculum.length > 1 && item.centers_curriculum.length > index_p + 1">,</span>
                  </span>
                </td>
                <td class="text-center">{{ item.user ? item.user.id + 100000 : '' }} - {{ item.user ? item.user.name : '' }}</td>
                <td class="text-center">{{ item.created_at |formatDateTimeVietnam}}</td>
                <td class="text-center text-white">
                  <span v-if="item.slide_status" class="bg-success p-2 rounded">Hiển thị</span>
                  <span v-else class="bg-secondary p-2 rounded">Ẩn</span>
                </td>

                <td class="text-center d-flex justify-content-center">
                  <a title="Tải xuống"
                     :href="item.url"
                     class="btn btn-sm btn-icon btn-outline-success" download>
                    <i class="el-icon-download"></i></a>
                  <button
                    title="Sửa"
                    v-b-modal.modal-1
                    class="btn btn-sm btn-icon btn-outline-primary mx-1"
                    @click="showDetail(item, index)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <a title="Xóa" @click="removeSlide(item.id)"
                      href="javascript:"
                     class="btn btn-sm btn-icon btn-outline-danger"><i class="el-icon-delete-solid"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-end m-2">
            Tổng số bản ghi : {{list_slide_pagination.total}}
          </div>
          <div v-if="curriculum.length <= 0" colspan="9" class="d-flex justify-content-center">Chưa có dữ liệu</div>
          <div class="d-flex justify-content-center" v-if="curriculum.length">
            <paginate
                :page-count="last_page"
                :page-range="3"
                :margin-pages="1"
                :click-handler="clickCallback"
                :prev-text="'Trước'"
                :next-text="'Sau'"
                :container-class="'pagination b-pagination'"
                :pageLinkClass="'page-link'"
                :next-link-class="'next-link-item'"
                :prev-link-class="'prev-link-item'"
                :prev-class="'page-link'"
                :next-class="'page-link'"
                :page-class="'page-item'"
            >
            </paginate>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" :title="title" size="lg" hide-footer @hidden="closeModal">
      <ValidationObserver v-slot="{ handleSubmit }" ref="update-form">
        <form autocomplete="off" @submit.prevent="handleSubmit(submitUpload)">
          <div class="container">
            <div class="col-12 row">
                <div class="col-6">
                    <label for=""
                    >Tên giáo trình
                    <span data-v-3736cd64="" class="text-danger">*</span></label
                    >
                    <ValidationProvider vid="name_slide" name="Tên" rules="required|max:50"
                    v-slot="{ errors,classes }">
                      <el-input v-model="form.name_slide"></el-input>
                      <error-message field="file" :errors="errors"></error-message>
                    </ValidationProvider>
                </div>
              <div class="col-6">
                    <label for=""
                    >Trung tâm áp dụng
                    <span data-v-3736cd64="" class="text-danger">*</span></label
                    >
                    <ValidationProvider vid="center_id" name="Trung tâm" rules="required"
                          v-slot="{ errors,classes }">
                      <el-select v-model="form.center_id"
                                  clearable filterable
                                  collapse-tags
                                  placeholder="Chọn trung tâm áp dụng" class="w-100"
                                  multiple
                      >
                        <el-option v-for="item in centers"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                        </el-option>
                      </el-select>
                    <error-message field="center" :errors="errors"></error-message>
                    </ValidationProvider>
              </div>
            </div>
            <div class="col-12 row mt-4">
                <div class="col-6">
                    <label for="">File giáo trình <span data-v-3736cd64="" class="text-danger">*</span></label>
                    <ValidationProvider vid="file" name="File tải lên" rules="required"
                                v-slot="{ errors,classes }">
                    <el-upload
                        ref="upload"
                        accept=".pdf"
                        :limit="1"
                        list-type="text"
                        action=""
                        :file-list="fileList"
                        :on-change="handleChange"
                        :on-remove="handleRemove"
                        :auto-upload="false"
                        :multiple="false"
                    >
                        <el-button size="small" type="warning">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-up" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                        Tải lên</el-button>
                    </el-upload>
                      <input  type="text" hidden name="file" ref="file" v-model="fileList">
                    <error-message field="file" :errors="errors"></error-message>
                  </ValidationProvider>
                </div>
                <div class="col-6">
                    <label for="" class="mr-2">Kích hoạt </label>
                    <el-switch v-model="form.slide_status"/>
                </div>
            </div>
          </div>
          <footer class="modal-footer mt-4">
            <button type="button" class="btn btn-secondary" @click="closeModal">Hủy bỏ</button>
            <button type="submit" :loading="loading" class="btn btn-success" :disabled="disable">Xác nhận</button>
          </footer>
        </form>
      </ValidationObserver>

    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { UPLOAD_CURRICULUM, SHOW_LIST_SLIDE, UPDATE_CURRICULUM, REMOVE_SLIDE, GET_CENTER_COURSE } from "@/core/services/store/course/classes.module";
import Swal from "sweetalert2";
import ModalDetail from "@/view/pages/schedule-course/component/modalDetail.vue";
import {GET_CENTERS} from "@/core/services/store/center/centers.module";
import ErrorMessage from "../common/ErrorMessage";
import { LOGIN } from '../../../core/services/store/auth.module';

export default {
  name: "curriculum",
  components: {
    ModalDetail,
    ErrorMessage
  },
  data() {
    return {
      page: 1,
      last_page: 1,
      curriculum: [],
      fileList: [],
      schedule_id: this.$route.params.id,
      current_schedule_id: this.$route.query.index,
      dialogUpload: false,
      disable: false,
      loading: false,
      detailSession: {},
      indexSession: null,
      form: {
        name_slide: '',
        slide_status: true,
        center_id: [],
        fileList: ''
      },
      centers: [],
      query: {
        index: this.$route.query.index,
        name_slide: '',
        slide_status: '',
        center_id: [],
        type: true
      },
      file_name: '',
      is_update: false,
      list_slide_pagination: {
        total: '',
        per_page: '',
        current_page: 1,
        total_pages: 1
      },
      course: '',
      title: 'Thêm mới giáo trình',
      dataForm: {},
      centerLearing: {},
      total: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý giáo trình ", route: "schedule-course-list" },
      { title: "Chi tiết lộ trình" },
    ]);
    this.getCenters();
    this.getList();
    this.getCourseById();

  },

  methods: {
    showDetail(item, index) {
      this.dataForm = item;
      this.is_update = true;
      this.title = 'Sửa giáo trình'
      this.file_name = this.dataForm.name + '.pdf';
      this.fileList = [{
        name: this.dataForm.name + '.pdf',
        url: this.dataForm.url
      }];
      this.form.fileList = [{
        name: this.dataForm.name + '.pdf',
        url: this.dataForm.url
      }]
      this.form = {...this.dataForm};
      this.form.slide_status = item.slide_status ? true : false;
    },
    getCourseById() {
      this.$store.dispatch(GET_CENTER_COURSE, {id: this.$route.params.id, learning_id: this.$route.query.index}).then((res) => {
        this.course = res.data.schedule;
        this.centerLearing = res.data.centerLearing;
      });
    },
    openDialogUpload(id) {
      this.dialogUpload = true;
      this.current_schedule_id = id;
    },
    closeModal() {
      this.resetForm();
      this.$bvModal.hide('modal-1');
      this.is_update = false;

    },
    handleRemove() {
      this.form.fileList = '';
    },
    handleChange(file, fileList) {
      console.log(fileList,'a');
      this.fileList = fileList;
      this.form.fileList = fileList;
      this.file_name = '';
    },
    pullParamsUrl() {
      this.page = this.$route.query.page ? Number(this.$route.query.page) : 1
      this.query.name_slide = (this.$route.query.name_slide) ? String(this.$route.query.name_slide) : '';
      this.query.center_id = this.$route.query.center_id ? this.$route.query.center_id : [] ;
      this.query.slide_status = Boolean(this.$route.query.slide_status) ? (this.$route.query.slide_status) : '';

    },
    getList() {
     this.pullParamsUrl();
      this.callApiGetList()
    },
    submitUpload() {
      this.loading = true;
      if (this.is_update) {
        this.updateCurriculum();
        return;
      }
      this.createCurriculum();
    },
    updateCurriculum() {
      this.loading = true;
      let formData = new FormData();

      for (const formKey in this.form) {
        formData.append(formKey, this.form[formKey]);
      }
      if(this.fileList) {
        formData.append('file', this.fileList[0].raw);
      }
      formData.append('current_schedule_id', this.current_schedule_id);
      formData.append('type', true);
      this.form.url = '';
      let payload = {
        id: this.form.id,
        params: formData,
      }
      this.$store.dispatch(UPDATE_CURRICULUM, payload).then((res) => {
        if (!res.data) {
          this.noticeMessage( 'error','Không thành công','Trung tâm chỉ được hiển thị một giáo trình');
          return;
        }
        this.noticeMessage( 'success','Thành công','Cập nhật giáo trình thành công');
        this.dialogEditUpload = false;
        this.callApiGetList();
        this.$bvModal.hide('modal-1');

      }).finally(() => {
        this.loading = false;
      })
    },
    createCurriculum() {
      let formData = new FormData();
      for (const formKey in this.form) {
        formData.append(formKey, this.form[formKey]);
      }
      formData.append('file', this.fileList[0].raw);
      let payload = {
        id: this.current_schedule_id,
        params: formData,
      }
      this.$store.dispatch(UPLOAD_CURRICULUM, payload).then((res) => {
        if (!res.data) {
          this.noticeMessage( 'error','Không thành công','Trung tâm chỉ được hiển thị một giáo trình');
          return;
        }
        this.noticeMessage( 'success','Thành công','Thêm mới thành công');
        this.fileList = [];
        this.dialogUpload = false;
        this.callApiGetList();
        this.$bvModal.hide('modal-1');

      }).finally(() => {
        this.loading = false
      })
    },
    removeSlide(id) {
      this.loading = true;
      Swal.fire({
        title: "Bạn chắc chắn muốn gỡ giáo trình?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(REMOVE_SLIDE, id).then((res) => {

            this.$notify({
              title: 'Thành công',
              message: 'Xóa giáo trình thành công',
              type: 'success'
            })
            this.loading = false;
            this.callApiGetList();
          }).catch((error) => {
            this.$notify({
              title: 'Thất bại',
              message: error.data.message,
              type: 'error'
            });
          })
        }
      });
    },
    getCenters() {
      this.$store.dispatch(GET_CENTERS, {
        status: 1,
        limit: 10000,
      }).then((res) => {
        if (!res.error) {
          this.centers = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    callApiGetList() {
      let payload = {
        id: this.current_schedule_id,
        params: {
          ...this.query,
          page: this.page
        }
      }
      this.$store.dispatch(SHOW_LIST_SLIDE, payload).then((res) => {
        if (!res.error) {
              this.curriculum = res.data
              this.curriculum.map(function(value) {
                return value.center_id = Object.keys(value.centers_curriculum).map(f=>value.centers_curriculum[f].center_id);
              });
              this.last_page = res.pagination.last_page;
              this.list_slide_pagination.total = Number(res.pagination.total),
              this.list_slide_pagination.per_page = Number(res.pagination.per_page),
              this.list_slide_pagination.current_page = Number(res.pagination.current_page),
              this.list_slide_pagination.total_pages = Number(res.pagination.last_page)
        }
      }).catch((e) => {
        console.log(e, 'error')
      }).finally(() => this.isBusy = false);

    },
    addCurriculu() {
      this.title = 'Thêm mới giáo trình';
      this.fileList = [];
    },
    resetForm(){
      this.form.name_slide = ''; 
      this.form.center_id = []; 
      this.form.fileList = [];
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({name: 'curriculum', params: {id: this.$route.params.id} , query: {index: this.$route.query.index, page: this.page}})
      this.callApiGetList()
    },
  },
};
</script>
