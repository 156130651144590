<template>
  <div class="card card-custom gutter-b example example-compact">
    <div class="card-header">
      <h3 class="card-title">Tạo hợp đồng</h3>
    </div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form class="form" id="contract_create" @submit.prevent="handleSubmit(onSubmit)">
        <div class="card-body">
          <div class="row">
            <div class="col-md-7">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="d-bloc">Tìm học viên</label>
                    <el-select v-model="student_id"
                               clearable
                               filterable
                               remote
                               reserve-keyword
                               placeholder="Nhập mã hv/ số điện thoại"
                               ref="new_student"
                               class="w-100"
                               :remote-method="remoteMethod"
                               :loading="loading"
                               @change="getUserById"
                    >
                      <template slot="empty" class="align-items-center justify-content-center">
                        <div
                            class="align-items-center justify-content-center el-select-dropdown__item hover cursor-pointer"
                            v-if="new_phone.length > 0" @click="createNewUser">
                          <div>
                            Không tìm thấy học viên ->
                            <span style="color: #00aff0">Tạo học viên mới</span>
                            <span><i class="fas fa-user-plus" style="color: #00aff0"></i></span>
                          </div>
                        </div>
                      </template>
                      <el-option v-for="user in userSearch"
                                 :key="user.id"
                                 :label="user.id + 100000+  ' - ' + user.name + ' - ' + user.phone"
                                 :value="user.id">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="row" v-if="showUser">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Họ tên</label>
                    <ValidationProvider name="Họ tên" vid="name" rules="required"
                                        v-slot="{ errors,classes }">
                      <input :disabled="isDisable" v-model="contract.user.name" type="text"
                             class="form-control"
                             placeholder="Họ tên"
                             :class="classes">
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Email</label>
                    <ValidationProvider vid="email" name="Email" rules="required"
                                        v-slot="{ errors,classes }">
                      <input :disabled="isDisable" v-model="contract.user.email" type="email"
                             class="form-control"
                             placeholder="Email"
                             :class="classes"
                      >
                      <div class="fv-plugins-message-container">
                        <div data-field="email" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label ref="label_phone">Số điện thoại</label>
                    <ValidationProvider vid="phone" name="Số điện thoại" rules="required"
                                        v-slot="{ errors,classes }">
                      <input :disabled="isDisable || disablePhone" v-model="contract.user.phone" type="number"
                             class="form-control"
                             placeholder="Số điện thoại"
                             :class="classes"
                      >
                      <div class="fv-plugins-message-container">
                        <div data-field="phone" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <ValidationProvider vid="birthday" name="Ngày sinh" rules="required"
                                        v-slot="{ errors,classes }">
                      <label class="d-block">Ngày sinh <span class="text-danger">*</span></label>
                      <el-date-picker
                          v-model="contract.user.profile.birthday"
                          type="date"
                          ref="birthday"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          placeholder="Chọn ngày sinh"
                          class="w-100"
                          :class="classes"
                      >
                      </el-date-picker>
                      <div class="fv-plugins-message-container">
                        <div data-field="birthday" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6" v-if="has_user">
                  <div class="form-group">
                    <button class="btn btn-primary" @click="createNewStudent" :disabled="disableButtuon">Tạo học viên
                    </button>
                  </div>
                </div>
              </div>

              <!--Center and Branch-->
              <div class="row" v-if="is_show">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Chọn Trung tâm
                      <span class="text-danger">*</span></label>
                    <ValidationProvider vid="center_id" name="Trung tâm" rules="required"
                                        v-slot="{ errors,classes }">
                      <el-select
                          clearable filterable
                          id="course"
                          v-model="contract.center_id"
                          class="w-100"
                          :class="classes"
                          placeholder="Trung tâm"
                          @change="setStateCenter"
                      >
                        <el-option v-for="item in centers"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Hình thức đóng học phí:<span class="text-danger"></span></label>
                    <ValidationProvider vid="tuitionPayment" name="Hình thức đóng học phí" rules="required"
                                        v-slot="{ errors,classes }">
                      <el-select class="w-100"
                                 v-model="contract.tuitionPayment"
                                 placeholder="Hình thức đóng học phí"
                                 @input="handleTuitionPayment"
                      >
                        <el-option
                            v-for="item in tuitionPayments"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Chọn Chi nhánh
                      <span class="text-danger">*</span></label>
                    <ValidationProvider vid="branch_id" name="Chi nhánh" rules="required"
                                        v-slot="{ errors,classes }">
                      <el-select
                          clearable filterable
                          id="course"
                          v-model="contract.branch_id"
                          class="w-100"
                          :class="classes"
                          placeholder="Chi nhánh"
                          @change="setStateBranch"
                      >
                        <el-option v-for="item in branches"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row" v-if="is_show">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Chọn người giới thiệu</label>
                    <select-search-user :class="classes" @selectChange="selectMGT"
                                        v-model="contract.ma_gioi_thieu"></select-search-user>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Chọn người tư vấn</label>
                    <select-search-user type="TVV" :class="classes" @selectChange="selectNguoiTuVan"
                                        v-model="contract.nguoi_tu_van"></select-search-user>
                  </div>
                </div>
              </div>
              <div class="row" v-if="is_show">
                <div class="col-md-12">
                  <div class="form-group">
                    <button type="button" class="btn btn-light-success" @click="addCourse">
                      <i class="flaticon2-plus mb-1"></i>
                      Thêm khóa học
                    </button>
                  </div>
                </div>
              </div>
              <CourseClass v-for="(course_info,index) in contract.student_register_courses"
                           :key="index"
                           :course_info="course_info"
                           :index="index"
                           :center_classes="center_classes"
                           :courses="courses"
                           :condition_courses="condition_courses"
                           :isLoadClass="isLoadClass"
                           :contract="contract"
                           :branchs="branches"
                           :is_create="true"
                           @deleteCourse="deleteCourse"
                           @selectCourse="selectCourse"
                           @selectClass="selectClass"
                           v-if="is_show"
              >
              </CourseClass>
            </div>
            <div class="col-md-5" v-if="is_show">
              <div class="card card-custom card-pd gutter-b example example-compact">
                <div class="card-header">
                  <h3 class="card-title">Khóa học đã chọn</h3>
                </div>
                <div class="card-body">
                  <div class="" v-if="coursePrice.length > 0">
                    <div class="row mb-2"
                         v-for="(course_info,index) in contract.student_register_courses"
                         :key="index">
                      <div class="col-md-6" v-if="course_info.course.fee > 0">
                                          <span>
                                              {{ course_info.course && course_info.course.name }}
                                          </span>
                      </div>
                      <div class="col-md-6" v-if="course_info.course.fee > 0">
                        <input type="number" v-model="coursePrice[index]" class="form-control input-custom"
                               v-on:input="changeCoursePrice(index)">
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-6">
                      <strong>Tổng tiền :</strong>
                    </div>
                    <div class="col-md-6">
                      <strong
                          class="input-slug">{{
                          contract.total != 0 ? formatPrice(contract.total, 'đ') : '0đ'
                        }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-custom card-bg gutter-b example example-compact">
                <div class="card-header">
                  <h3 class="card-title card-title--tlt">Chi tiết thanh toán</h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <p>Danh sách khuyến mại:</p>
                    </div>
                    <div class="col-6">
                      <a href="javascript:" class="promotion-list" @click="showVouchers">
                        <strong>Danh sách khuyến mại </strong>
                        <span class="promotion-count">{{ promotionTotal}}</span>
                      </a>
                    </div>
                  </div>
                  <div class="row" v-for="(voucher,index) in vouchersForContract" :key="index">
                    <div class="col-4">
                      <p>Voucher {{ ++index }}:</p>
                    </div>
                    <div class="col-4">
                                          <span v-if="voucher.type !== 0" class="promotion-icon">

                                          </span>
                                          <span v-else class="promotion-icon">
                                          </span>
                      <span v-if="voucher.type !== 0" class="promotion-list"><strong>{{'Quy đổi quà tặng - ' + voucher.name }}</strong></span>
                      <span v-else class="promotion-list"><strong>{{ voucher.name }}</strong></span>
                    </div>
                    <div class="col-4">
                      <strong class="text-danger" v-if="voucher.unit === 1 ">{{ voucher.value | toThousandFilter }}
                        ₫</strong>
                      <strong class="text-danger" v-else>{{ voucher.value }} %</strong>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <h5>Tổng cộng :</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>{{ contract.total_after_voucher | toThousandFilter }} ₫</h5>
                    </div>
                  </div>
                </div>
                <div class="col-12 row mt-3 ml-2" v-if="gift_select && gift_select.description">
                  <div class="col-md-6">
                    <p>Quà tặng :</p>
                  </div>
                  <div class="col-md-6">
                    {{ gift_select.description }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer" v-if="is_show">
          <button type="submit" class="btn btn-primary mr-2">Đăng ký</button>
        </div>
      </form>
    </ValidationObserver>
    <b-button id="show-gift-list" v-b-modal.list-promotions size="lg" hidden>Open Third Modal</b-button>
    <b-button class="show-vouchers-list" v-b-modal.list-vouchers size="lg" hidden>Open Third Modal</b-button>
    <!-- Danh sách mã giảm giá -->
    <b-modal id="list-promotions" :centered="true" :scrollable="true" noFade size="lg" ref="modal-list-promotions"
             hide-footer title="Danh sách quà tặng">
      <div class="mt-5 border border-primary" v-for="(gift,index) in listGift" :key="index"
           style="backgroundImage : linear-gradient(to bottom, #DFEAFF,#FFFFFF) "
      >
        <list-gift-item
            :gift="gift"
            :id_promotion_disable="id_promotion_disable"
            :gift-select="gift_select"
            :list-promotions="vouchersForContract"
            @showPromotionDetail="showPromotionDetail"
            @pickGift="pickGift"
            @pickPrice="setVoucherForContract"
            @cancelGift="cancelGift"
        >
        </list-gift-item>
      </div>
      <b-button id="show-promotion-detail" v-b-modal.promotion-detail size="sm" hidden>Open Third Modal</b-button>
    </b-modal>

    <!-- Danh sách voucher -->
    <b-modal id="list-vouchers" :centered="true" :scrollable="true" size="lg" ref="modal-list-vouchers" hide-footer
             title="Danh sách voucher">
      <div v-if="allCode[FLASH_SALE]?.length">
        <h1 style="font-size: 14px;font-weight: bold;text-align: left">Chương trình Flash sale</h1>
        <list-vouchers :vouchers="allCode[FLASH_SALE]"
                       :id_voucher_disable="id_voucher_disable"
                       @showVoucherDetail="showVoucherDetail"
                       @setListVoucherForContract="setListVoucherForContract(FLASH_SALE)"
                       :colorCode="'#FF8C22'"
                       :allCode='allCode' :type="FLASH_SALE"
        >
        </list-vouchers>
      </div>
      <div class="mt-5" v-if="allCode[PERIODIC_PROMOTIONS]?.length">
        <h1 style="font-size: 14px;font-weight: bold;text-align: left">Chương trình định kỳ</h1>
        <list-vouchers :vouchers="allCode[PERIODIC_PROMOTIONS]"
                       :id_voucher_disable="id_voucher_disable"
                       @showVoucherDetail="showVoucherDetail"
                       @setListVoucherForContract="setListVoucherForContract(PERIODIC_PROMOTIONS)"
                       :colorCode="'#1BC5BD'"
                       :allCode='allCode' :type="PERIODIC_PROMOTIONS"
        >
        </list-vouchers>
      </div>
      <div class="mt-5" v-if="allCode[SCHOOL_LINK]?.length">
        <h1 style="font-size: 14px;font-weight: bold;text-align: left">Chương trình liên kết trường</h1>
        <list-vouchers :vouchers="allCode[SCHOOL_LINK]"
                       :id_voucher_disable="id_voucher_disable"
                       @showVoucherDetail="showVoucherDetail"
                       @setListVoucherForContract="setListVoucherForContract(SCHOOL_LINK)"
                       :colorCode="'#3343D0'"
                       :allCode='allCode' :type="SCHOOL_LINK"
        >
        </list-vouchers>
      </div>
      <div class="mt-5" v-if="allCode[OTHER_SCHOOL]?.length">
        <h1 style="font-size: 14px;font-weight: bold;text-align: left">Chương trình khác</h1>
        <list-vouchers :vouchers="allCode[OTHER_SCHOOL]"
                       :id_voucher_disable="id_voucher_disable"
                       @showVoucherDetail="showVoucherDetail"
                       @setListVoucherForContract="setListVoucherForContract(OTHER_SCHOOL)"
                       :colorCode="'#D40000'"
                       :allCode='allCode' :type="OTHER_SCHOOL"
        >
        </list-vouchers>
      </div>
      <div class="mt-5" v-if="allCode[DIGITAL]?.length && +contract.branch_id_sale === digitalBranch">
        <h1 style="font-size: 14px;font-weight: bold;text-align: left">Chương trình Digital</h1>
        <list-vouchers :vouchers="allCode[DIGITAL]"
                       :id_voucher_disable="id_voucher_disable"
                       @showVoucherDetail="showVoucherDetail"
                       @setListVoucherForContract="setListVoucherForContract(DIGITAL)"
                       :colorCode="'#0094ff'"
                       :allCode='allCode' :type="DIGITAL"
        >
        </list-vouchers>
      </div>

      <b-button id="show-voucher-detail" v-b-modal.voucher-detail size="sm" hidden>Open Third Modal</b-button>
    </b-modal>

    <!-- Chi tiết mã giảm giá -->
    <b-modal id="promotion-detail" :centered="true" :scrollable="true" size="lg" ref="modal-promotion-detail"
             hide-footer title="Chi tiết quà tặng">
      <gift-detail :gift="gift_detail"></gift-detail>
    </b-modal>

    <!-- Chi tiết voucher -->
    <b-modal id="voucher-detail" :centered="true" :scrollable="true" size="lg" ref="modal-promotion-detail"
             hide-footer title="Chi tiết voucher">
      <voucher-detail :voucher="voucher_detail"></voucher-detail>
    </b-modal>
  </div>
</template>
<script>
import CourseClass from "@/view/pages/business/CourseClass";
import ListVouchers from "@/view/pages/business/modal-contract/ListVouchers";
import ListPromotions from "@/view/pages/business/modal-contract/ListPromotions";
import PromotionDetail from "@/view/pages/business/modal-contract/PromotionDetail";
import GiftDetail from "@/view/pages/business/modal-contract/GiftDetail";
import VoucherDetail from "@/view/pages/business/modal-contract/VoucherDetail";
import {GET_USER} from '@/core/services/ajaxUrl';
import {
  GET_PROMOTIONS_BY_CONTRACT,
  GET_VOUCHERS_BY_CONTRACT, LIST_GIFT
} from "../../../core/services/store/contract/promotions.module";
import {
  CREATE_CONTRACT,
  CREATE_NEW_USER_WHEN_CREATE_CONTRACT,
} from "../../../core/services/store/contract/contracts.module";
import {GET_USER_BY_ID, SEARCH_CUSTOMER_BY_OR_ID} from "../../../core/services/store/user/users.module";
import {Money} from 'v-money';
import '@/assets/sass/custom/tai-chinh/contractForm.scss';
import {GET_CLASSES} from "../../../core/services/store/course/classes.module";
import {GET_COURSES} from "../../../core/services/store/course/course.module";
import {GET_BRANCHES, GET_LIST_BRANCHES} from "../../../core/services/store/center/branch.module";
import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_TUITION_PAYMENT} from "@/core/services/store/installment/installment.module";
import ErrorMessage from "@/view/pages/common/ErrorMessage";
import SelectSearchUser from "@/view/pages/common/SelectSearchUser";
import ListGift from "@/view/pages/business/modal-contract/ListGiftItem";
import ListGiftItem from "@/view/pages/business/modal-contract/ListGiftItem";
import {TYPE_GIFT} from "@/core/option/promotionOption";
import {CodeModel} from "@/view/pages/contracts/CodeModel/code";
import {DIGITAL, FLASH_SALE, OTHER_SCHOOL, PERIODIC_PROMOTIONS, SCHOOL_LINK} from "@/core/option/contractOption";

const _ = require('lodash');

export default {
  components: {
    ListGiftItem,
    ListGift,
    CourseClass,
    Money,
    VoucherDetail,
    ListVouchers,
    PromotionDetail,
    ListPromotions,
    SelectSearchUser,
    ErrorMessage,
    GiftDetail
  },
  name: "ContractCreate",
  data() {
    return {
      total_course: 0,
      classes: null,
      isLoadClass: false,
      is_show_user_detail: false,
      contract: {
        branch_id_sale: null,
        gift_id: [],
        center_id: '',
        branch_id: '',
        user_id: '',
        nguoi_tu_van: '',
        ma_gioi_thieu: '',
        student_register_courses: [
          {
            id: '',
            contract_id: '',
            course_id: '',
            class_id: '',
            course_fee: 0,
            course_sale: '',
            dang_ky_ho: '',
            xac_nhan: 1,
            waiting_status: '',
            center_class: {
              id: '',
              course_id: '',
              name: ''
            },
            course: {
              id: '',
              name: '',
              fee: '',
              sale: '',
            },
          }
        ],
        user: {
          name: '',
          email: '',
          phone: '',
          profile: {
            birthday: ''
          }
        },
        tuitionPayment: ''
      },
      //Cache
      centers: [],
      branches: [],
      userSearch: [],
      //
      center_classes: [],
      courses: [],
      is_show_promotions: false,
      is_nap_rut_form: false,
      title: '',
      student_id: '',
      new_phone: '',
      /* recalculate when action */
      recalculate: 1,
      recalculateSubTotal: 1,
      check_general: false,
      loading: false,
      disableButtuon: false,
      is_show: false,
      has_user: false,
      showUser: false,
      otpCode: '',
      countDown: '',
      countDownTimer: 0,
      isDisable: false,
      disablePhone: false,
      gift_select: null,
      gift_detail: {},
      voucher_detail: {},
      /* list variable */
      promotions: [],
      listGift: [],
      vouchers: [],
      /* list variable of contract */
      promotionForContract: '',
      vouchersForContract: [],
      /* condition variable */
      id_promotion_disable: '',
      id_voucher_disable: [],
      condition_courses: [],
      courses_selected: [],
      /* calculate variable  */
      totalDiscountByVoucher: 0,
      totalValueOfVoucher: 0,
      totalDiscount: 0,
      /* nạp rút tặng quà */
      type: '',
      coursePrice: [],
      /* v-money */
      money: {
        decimal: ',',
        thousands: ',',
        prefix: '',
        suffix: ' VNĐ',
        precision: 0,
        masked: false,
      },
      tuitionPayments: [],
      numberCode:0,
      allCode:{},
      SCHOOL_LINK:SCHOOL_LINK,
      FLASH_SALE:FLASH_SALE,
      PERIODIC_PROMOTIONS:PERIODIC_PROMOTIONS,
      OTHER_SCHOOL:OTHER_SCHOOL,
      DIGITAL: DIGITAL,
      digitalBranch: 182,
      branchs: []
    }
  },
  computed: {
    getUserUrl: function () {
      return GET_USER
    },
    total_pages() {
      return 1;
    },
    promotionTotal(){
      if(this.listGift?.length === 0 && this.vouchers?.length === 0){
        return 0
      }
      if(this.contract.branch_id_sale === this.digitalBranch){
        return +this.vouchers?.length + this.listGift?.length
      }
      return (+this.vouchers?.length + this.listGift?.length) - (this.allCode[DIGITAL]?.length??0)
    }
  },
  mounted() {
    this.getCenters()
    this.getBranches()
    this.clearForm();
    this.getCourses();
    this.getClasses();
    this.getPromotions();
    this.getTuitionPayments();
    this.is_nap_rut_form = false;
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý hợp đồng", route: 'contract-index'},
      {title: "Tạo hợp đồng"}
    ]);
  },
  watch: {
    recalculate() {
      this.contract.total = _.reduce(this.contract.student_register_courses, function (result, value, key) {
        result += value.course_fee * (1 - value.course_sale / 100);
        return result;
      }, 0);
      this.recalculateSubTotal++;
    },
    recalculateSubTotal() {
      this.calculateTotalValueForVoucher();
      this.reloadTotalDiscount();
      this.contract.total_after_voucher = this.contract.total - this.totalDiscount;
      this.contract.total_debt = this.contract.total_after_voucher;
    },
    contract: {
      handler() {
        this.coursePrice = this.contract.student_register_courses.map((item) => item.course_fee);
        this.getPromotions();
        // this.getVouchers();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    cancelGift(event) {
      this.resetGift();
      let index = this.getIndex(event);
      if (index == null) {
        return;
      }
      this.removeContractVoucher(index + 1);
    },
	  getIndex(event) {
		  let index = null;
		  for (let i = 0; i < this.vouchersForContract.length; i++) {
			  let item = this.vouchersForContract[i];
			  if (item.id === event.id) {
				  index = i;
			  }
		  }
		  return index;
	  },
    resetGift() {
      this.contract.gift_id = [];
      this.gift_select = null;
    },
    pickGift(event) {
      this.contract.gift_id.push(event.id);
      this.gift_select = event;
      this.contract.total_after_voucher = this.contract.total_after_voucher < this.contract.total ? this.contract.total_after_voucher  + event.value : this.contract.total_after_voucher;

      // this.removeContractVoucher(0);
    },
    selectNguoiTuVan: function (value) {
      this.contract.nguoi_tu_van = value;
    },
    selectMGT: function (value,branch_id_sale) {
      this.contract.branch_id_sale = branch_id_sale;
      this.contract.ma_gioi_thieu = value;
    },
    remoteMethod: function (query) {
      if (query !== '' && query.length >= 6 && query.length <= 10) {
        this.new_phone = query;
        const params = {
          search_customer_by_phoneOrId: query,
          limit: 10000,
          status: 1,
          is_staff: 0,
        }
        this.loading = true;
        let vm = this;
        setTimeout(function () {
          vm.getStudents(params);
        }, 300)
      } else {
        this.new_phone = '';
      }
    },
    createNewUser: function () {
      this.contract.user.phone = this.new_phone;
      this.student_id = this.new_phone;
      this.new_phone = '';
      this.has_user = true;
      this.isDisable = false;
      this.showUser = true;
      this.$refs.new_student.visible = false;
    },
    createNewStudent: function () {
      this.disableButtuon = true;
      this.$store.dispatch(CREATE_NEW_USER_WHEN_CREATE_CONTRACT, {
        phone: this.contract.user.phone,
        name: this.contract.user.name,
        email: this.contract.user.email,
        birthday: this.contract.user.profile.birthday,
      }).then((res) => {
        this.contract.user = res.data;
        this.isDisable = true;
        this.$notify({
          title: 'Thành công',
          message: 'Tạo học viên mới',
          type: 'success'
        });
        this.is_show = true;
        this.has_user = false;
        this.contract.user_id = res.data.id;
      }).catch((error) => {
        if (error.data?.data?.message_validate_form) {
          this.$refs.form.setErrors(error.data.data.message_validate_form);
        }
        if (error.data.message) {
          this.$notify({
            title: 'Thất bại',
            message: error.data.message,
            type: 'error'
          });
        }
      }).finally(() => {
        this.disableButtuon = false;
      })
    },
    getStudents(params) {
      this.$store.dispatch(SEARCH_CUSTOMER_BY_OR_ID, params).then((res) => {
        this.userSearch = res.data.data;
        this.loading = false;
        if (res.data.data.length === 0) {
          this.setContractUser();
        }
      })
    },
    getCenters() {
      this.$store.dispatch(GET_CENTERS, {
        limit: 10000,
        status: 1
      }).then((res) => {
        this.centers = res.data.data
      })
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        center_id: this.contract.center_id,
        status: 1,
        type: [2, 3]
      }).then((res) => {
        this.branches = res.data.data
      })
    },
    setStateCenter($event) {
      this.contract.branch_id = '';
      this.branches = [];
      this.contract.center_id = $event;
      this.getBranches()
      this.getCourses()
      this.isLoadClass = !this.isLoadClass;
      this.getVouchers();
    },
    setStateBranch($event) {
      this.contract.branch_id = $event;
      this.getVouchers();
      this.getCourses()

        // this.getListGift();
    },
    handleTuitionPayment() {
      this.getListGift();
      this.getVouchers();
    },
    getUserById() {
      if (this.student_id) {
        this.$store.dispatch(GET_USER_BY_ID, this.student_id).then((res) => {
          if (!res.error) {
            this.contract.user = res.data;
            this.isDisable = true;
            if (this.contract.user.id != undefined) {
              this.contract.user_id = this.contract.user.id
            }
            this.is_show_user_detail = true
            this.has_user = false;
            this.is_show = true;
            this.showUser = true;
          }
        });
      } else {
        this.setContractUser()
      }
    },
    setContractUser() {
      this.is_show = false
      this.contract.user = {
        name: '',
        email: '',
        phone: '',
        profile: {
          birthday: ''
        }
      }
    },
    getClasses: function () {
      this.$store.dispatch(GET_CLASSES, {limit: 10000, status: 1}).then((res) => {
        this.center_classes = _.map(res.data.data, (item) => {
          item = {id: item.id, name: item.name, course_id: item.course_id};
          return item;
        })
      });
    },
    getCourses: function () {
      this.$store.dispatch(GET_COURSES, {
        limit: 10000,
        status: 1,
        center_id: this.contract.center_id,
        branch_id: this.contract.branch_id,
        all: 1
      }).then((res) => {
        this.courses = _.map(res.data, (item) => {
          item = {id: item.id, name: item.name, fee: item.fee, sale: item.sale,};
          return item;
        })
        this.contract.student_register_courses = [];
      });
    },
    showGift() {
      if (!this.contract.center_id && !this.contract.branch_id) {
        this.$bvToast.toast('Bạn chưa chọn Trung tâm hoặc chi nhánh', {
          title: 'Loading danh sách khuyến mại',
          variant: 'danger',
          solid: true
        });
        return;
      }
      document.getElementById('show-gift-list').click();
    },
    showVouchers() {
      $('.show-vouchers-list').click();
    },
    showPromotionDetail(promotion) {
      this.gift_detail = promotion;
      document.getElementById('show-promotion-detail').click();
    },
    showVoucherDetail(voucher) {
      this.voucher_detail = voucher;
      document.getElementById('show-voucher-detail').click();
    },
    setPromotionForContract(promotion) {
      this.recalculateSubTotal += 1;
      this.promotionForContract = promotion;
      this.id_promotion_disable = promotion.id;
      this.$notify({
        title: 'Thành công',
        message: 'Áp dụng mã khuyến mại thành công',
        type: 'success'
      });
      this.$refs['modal-list-promotions'].hide();
    },
    setVoucherForContract(voucher) {
      if (!voucher && !voucher.amount && voucher.amount != null) {
        this.$notify({
          title: 'Không thành công',
          message: 'Voucher đã hết lượt áp dụng',
          type: 'error'
        });
        return
      }
      this.vouchersForContract.push(voucher);
      this.id_voucher_disable.push(voucher.id);
      this.recalculateSubTotal += 1;
      if (voucher.type === TYPE_GIFT) {
        // this.$notify({
        //   title: 'Thành công',
        //   message: 'Quy đổi quà tặng thành công',
        //   type: 'success'
        // });
      } else {
        // this.$notify({
        //   title: 'Thành công',
        //   message: 'Áp dụng voucher thành công',
        //   type: 'success'
        // });
      }
    },
    setListVoucherForContract(type){
      this.vouchersForContract = [];
      this.id_voucher_disable = []
      this.resetGift()
      this.totalDiscount = 0
      this.contract.total_after_voucher = this.contract.total
      this.allCode[type].filter((code)=>code.selected === true).forEach((item)=>{
        item.isUseGift ? this.pickGift(item) : this.setVoucherForContract(item)
      })
    },
    removeContractPromotion(index) {
      this.promotionForContract = '';
      this.id_promotion_disable = '';
      this.recalculateSubTotal += 1;
      this.vouchersForContract.splice(index - 1, 1);
    },
    removeContractVoucher(index) {
      this.recalculateSubTotal += 1;
      this.vouchersForContract.splice(index - 1, 1);
      this.id_voucher_disable.splice(index - 1, 1);
    },
    clearForm() {
      this.title = '';
    },
    selectClass: function (param) {
      const studentRegisterCourse = this.contract.student_register_courses[param.index];
      studentRegisterCourse.class_id = param.class_id;
    },
    selectCourse: function (param) {
      this.resetGift();
      this.vouchersForContract = [];
      const course = this.courses.find(item => item.id === param.course_id);
      const studentRegisterCourse = this.contract.student_register_courses[param.index];
      studentRegisterCourse.course_id = param.course_id;
      studentRegisterCourse.chi_nhanh_hoc = param.branch_id ?? this.contract.branch_id;
      console.log(param.branch_id, 'aaaaaaaaaaaaa')
      let courseSelects = this.contract.student_register_courses
          .filter(item => item.course_id !== '')
          .map(item => item.course_id);
      if (courseSelects.filter((item) => item === param.course_id).length >= 2) {
        studentRegisterCourse.course_id = '';
        this.$message.warning('Bạn không thể chọn 2 khóa học trùng nhau trong cùng 1 hợp đồng');
      } else {
        studentRegisterCourse.course = course;
        studentRegisterCourse.course_id = course.id;
        studentRegisterCourse.course_fee = course.fee;
        studentRegisterCourse.course_sale = course.sale;
        studentRegisterCourse.fee = course.fee * (100 - course.sale) / 100;
        studentRegisterCourse.course_id = course.id;
      }
      this.reloadConditionCourses();
      this.recalculate++;
      this.getListGift();
      this.getVouchers()
    },
    addCourse() {
      this.contract.student_register_courses.push({
        contract_id: this.contract.id,
        course_id: '',
        class_id: '',
        course_fee: 0,
        course_sale: 0,
        dang_ky_ho: 0,
        xac_nhan: 1,
        waiting_status: '',
        fee: 0,
        status: 2,
        paid: 0,
        user_id: this.contract.user_id,
        center_id: this.contract.center_id,
        branch_id: this.contract.branch_id,
        center_class: {
          id: '',
          course_id: '',
          name: ''
        },
        course: {
          id: '',
          name: '',
          fee: '',
          sale: '',
        },
      })
    },
    deleteCourse(index) {
      this.recalculate++;
      if (this.contract.student_register_courses.length > 1) {
        this.contract.student_register_courses.splice(index, 1);
      } else {
        this.$message.warning('Hợp đồng phải có khóa học');
      }
      this.reloadConditionCourses();
    },
    reloadConditionCourses() {
      this.condition_courses = _.reduce(this.contract.student_register_courses, function (result, value, key) {
        result.push(value.course);
        return result
      }, []);
    },
    calculateTotalValueForVoucher() {
      this.totalDiscountByVoucher = _.reduce(this.vouchersForContract, function (result, value, key) {
        if (value.unit === 0) {
          result += value.value;
        }
        return result;
      }, 0);
      this.totalValueOfVoucher = _.reduce(this.vouchersForContract, function (result, value, key) {
        if (value.unit === 1) {
          result += value.value;
        }
        return result;
      }, 0);
    },
    reloadTotalDiscount() {
      // let valuePromotion = this.promotionForContract ? this.promotionForContract.value : 0;
      this.totalDiscount = this.totalValueOfVoucher + (this.contract.total * this.totalDiscountByVoucher / 100);
    },
    changeCoursePrice(index) {
      this.contract.student_register_courses[index].fee = this.coursePrice[index];
      this.contract.student_register_courses[index].course_fee = this.coursePrice[index] * 100 / (100 - this.contract.student_register_courses[index].course_sale);
      this.recalculate++;
    },
    onSubmit() {
      // if(this.vouchersForContract.length  || this.contract.gift_id.length ){
        this.contract.promotion_id = this.id_promotion_disable;
        this.contract.voucherIds = this.id_voucher_disable;
        if (this.id_promotion_disable) {
          this.id_voucher_disable.push(this.id_promotion_disable);
        }
        this.contract.student_register_courses.forEach((item) => {
          item.center_id = this.contract.center_id;
          item.branch_id = this.contract.branch_id;
        });
        this.contract.user_id = this.contract.user.id;
        this.$store.dispatch(CREATE_CONTRACT, this.contract).then((res) => {
          this.$router.push({name: "contract-index"}).then(() => {
            this.$notify({
              title: 'Thành công',
              message: res.message,
              type: 'success'
            });
          })
        }).catch((e) => {
          if (e.data?.data?.message_validate_form) {
            this.$refs.form.setErrors(e.data.data.message_validate_form);
          }
        });
      // }else {
      //   this.$message.error('Vui lòng chọn khuyến mại');
      // }


    },
    getVouchers() {
      this.$store.dispatch(GET_VOUCHERS_BY_CONTRACT, {
        center_id: this.contract.center_id,
        branch_id: this.contract.branch_id,
        quantity_course: this.contract?.student_register_courses?.map((course)=>course.course_id).length,
        course_ids: this.contract?.student_register_courses?.map((course)=>course.course_id)??[],
        tuition_payment_id: this.contract.tuitionPayment
      }).then((res) => {
        this.vouchers = res.data;
        this.getListGift()
      });
    },
    getListGift() {
      this.listGift = [];
      if (this.contract.branch_id) {
        this.$store.dispatch(LIST_GIFT, {
          'branch_id': this.contract.branch_id,
          'quantity_course': this.getQuantityCourse(),
          'tuition_payment_id': this.contract.tuitionPayment,
            course_ids: this.contract?.student_register_courses?.map((course)=>course.course_id)??[],
        }).then((response) => {
          this.listGift = response.data;
          let giftModel = this.listGift.map((gift)=>{
            return new CodeModel(gift,PERIODIC_PROMOTIONS,true)
          })
          let voucherModel = this.vouchers?.map((voucher)=>{
            return new CodeModel(voucher,voucher.program_id)
          })
          let code = (voucherModel.concat(giftModel))
          this.allCode = _.groupBy(code,({typeCode})=>typeCode)
        });
      }
    },
    setGiftSelect(listGift) {
      let gift_id = this.contract.gift_id[0];
      for (let i = 0; i < listGift.length; i++) {
        if (listGift[i].id === gift_id) {
          this.gift_select = listGift[i];
        }
      }
    },
    getQuantityCourse() {
      return this.total_course = this.contract.student_register_courses.filter((item) => {
        return item.course.id > 0;
      }).length;
    },
    getPromotions() {
      let courseIds = _.reduce(this.contract.student_register_courses, function (result, value, key) {
        result.push(value.course_id);
        return result;
      }, []);
      this.$store.dispatch(GET_PROMOTIONS_BY_CONTRACT, {
        courses: courseIds,
        center_id: this.contract.center_id,
        branch_id: this.contract.branch_id
      }).then((res) => {
        this.promotions = res.data;
      });
    },
    getTuitionPayments() {
      this.$store.dispatch(GET_TUITION_PAYMENT).then((response) => {
        this.tuitionPayments = response.data
      });
    },
  },
}
</script>
<style>
a.disable {
  pointer-events: none;
  cursor: default;
  color: #FFFFFF;
  background-color: #8c8ea4;
}
</style>


