<template>
    <div>
        <div>
            <div class="col-md-12 d-md-flex">
                <div class="col-md-4">
                    <label for="">Mã khách hàng</label>
                    <input type="text" v-model="user.id + 100000" class="form-control" disabled>
                </div>
                <div class="col-md-4">
                    <label for="">Họ và tên</label>
                    <input type="text" v-model="user.name" class="form-control " :disabled="checkDisable">
                </div>
                <div class="col-md-4">
                    <label for="">Số điện thoại đăng ký</label>
                    <input type="text" v-model="user.phone" class="form-control" disabled>
                </div>
            </div>
            <div class="col-md-12 d-md-flex mt-2">
                <div class="col-md-4">
                    <label for="">Số điện thoại liên hệ</label>
                    <input type="text" v-model="user.phone_other" class="form-control " :disabled="checkDisable">
                </div>
                <div class="col-md-4">
                    <label for="">Email</label>
                    <input type="email" v-model="user.email" class="form-control " :disabled="checkDisable">
                </div>
                <div class="col-md-4">
                    <label for="">Giới tính</label>
                    <div class="form-group">
                        <el-select v-model="user.profile.gender" class="w-100" :disabled="checkDisable">
                            <el-option v-for="(item, index) in listGender" :key="index"
                                    :value="item.value"
                                    :label="item.value"
                            >
                                <span>{{ item.value }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="col-md-12 d-md-flex mt-2">
                <div class="col-md-4">
                    <label for="">Ngày sinh</label>
                    <date-picker
                        placeholder="Chọn thời gian"
                        format="DD-MM-YYYY"
                        valueType="YYYY-MM-DD"
                        v-model="user.profile.birthday"
                        :disabled="checkDisable">
                    </date-picker>
                </div>
                <div class="col-md-4">
                    <label for="">Địa chỉ</label>
                    <input type="text" v-model="user.profile.address" class="form-control text-left" :disabled="checkDisable">
                </div>
            </div>
        </div>
        <div v-if="parent" class="mt-6">
            <h4 class="pl-7 mt-4">Thông tin người giám hộ</h4>
            <div class="col-md-12 d-md-flex mt-4">
                <div class="col-md-4">
                    <label for="">Họ và tên</label>
                    <input type="text" v-model="parent.parent.name" class="form-control" :disabled="checkDisable" placeholder="Không có">
                </div>
                <div class="col-md-4">
                    <label for="">Số điện thoại</label>
                    <input type="text" v-model="parent.parent.phone" class="form-control " :disabled="checkDisable" placeholder="Không có">
                </div>
                <div class="col-md-4">
                    <label for="">Mối quan hệ với khách hàng</label>
                    <input type="text" v-model="parent.value_other_type" class="form-control" :disabled="checkDisable" placeholder="Không có">
                </div>
            </div>
            <div class="col-md-12 d-md-flex mt-4">
                <div class="col-md-4">
                    <label for="">Số điện thoại khác</label>
                    <input type="text" v-model="parent.user.phone_other" class="form-control" :disabled="checkDisable" placeholder="Không có">
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button class="brand text-white h-25 px-3 py-2 rounded" @click="changeSubmit" v-if="checkDisable">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                    </svg>
                    Cập nhật
                </button>
                <div v-if="!checkDisable">
                    <button class="btn btn-secondary mr-2" @click="close">Hủy</button>
                    <button class="btn btn-primary" @click="submit">Hoàn thành</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {UPDATE_INFO_USER} from "@/core/services/store/customer/customer-crm.module";

export default {
    name: "CustomerUserInfo",
    components: {
      DatePicker
    },
    props: {
        user: {
            type: Object,
            default() {
                return {}
            }
        },
        parent: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data() {
        return {
            user_info: {},
            showModal: true,
            listGender: [
                {
                    id: 1,
                    value: 'Nam'
                },
                {
                    id: 2,
                    value: 'Nữ'
                }
            ],
            checkDisable: true
        }
    },
    mounted() {
    },

    methods: {
        submit() {
            let parent = this.parent;
            this.user_info = this.user;
            this.$store.dispatch(UPDATE_INFO_USER, {
                id: this.user.id,
                name: this.user.name,
                phone: this.user.phone,
                phone_other: this.user.phone_other,
                email: this.user.email,
                gender: this.user.profile.gender,
                birthday: this.user.profile.birthday,
                address: this.user.profile.address,
                parent: parent
            }).then((res) => {
                if (!res.error) {
                    this.checkDisable = true;
                    this.$notify({
                        type: 'success',
                        message: res.message,
                        title:' Thành công'
                    });
                    this.$emit('update', true);
                }
            })
        },
        changeSubmit() {
            this.checkDisable = false;
        },
        close() {
            this.checkDisable = true;
        }
    }
}
</script>

<style scoped>

</style>