<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách chính sách trụ sở tổng</h3>
      </div>
      <div class="card-title">
        <router-link
            class="font-weight-bold font-size-3  btn btn-success"
            :to="{ name: 'mechanism-create' }"
        >Thêm mới
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <!--Filter-->
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Tiêu đề, nội dung</label>
            <input type="text" v-model="query.keyword" class="form-control"
                   placeholder="Tiêu đề, nội dung">
          </div>
        </div>
        <div class="col-md-2 mt-8">
          <div class="d-flex justify-content-between">
            <button class=" btn btn-primary font-weight-bold mr-2"
                    @click="searchData">
              <i class="fa fa-search"></i> Tìm kiếm
            </button>
          </div>
        </div>
      </div>
      <div class="example mb-10">
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th scope="col" class="min-w-100px">Tiêu đề</th>
              <th scope="col" class="min-w-100px">Vị trí</th>
              <th scope="col" class="min-w-100px">Áp dụng với</th>
              <th scope="col" class="min-w-100px">Người tạo</th>
              <th scope="col" class="min-w-100px">Người cập nhật</th>
              <th scope="col" class="min-w-100px">Ngày tạo</th>
              <th scope="col" class="min-w-100px">Hành động</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in informations.data" :key="index">
              <td>{{ item.category }}</td>
              <td>{{ item.stt }}</td>
              <td>
                <span v-if="item.is_all">Tất cả</span>
                <span v-else>{{ getAccountType(item) }}</span>
              </td>
              <td>{{ item.created_user != null ? item.created_user.name : '' }}</td>
              <td>{{ item.updated_user != null ? item.updated_user.name : '' }}</td>
              <td>{{ item.created_at | formatDateTime }}</td>
              <td>
                <router-link :to="{ name: 'mechanism-edit', params: {id : item.id} }" title="Sửa"
                             class="btn btn-sm btn-icon mr-2 btn-outline-info"><i
                    class="fas fa-pen-nib"></i>
                </router-link>
                <a title="Xóa" @click="deleteMechanism(item.id)" href="javascript:"
                   class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_MECHANISMS, DELETE_MECHANISM} from "../../../core/services/store/system/mechanism.module";
import Multiselect from 'vue-multiselect'

const _ = require('lodash');

export default {
  components: {Multiselect},
  name: "InformationIndex",
  data() {
    return {
      is_disable_search: false,
      informations: [],
      // Paginate
      page: 1,
      last_page: 1,
      query: {
        keyword: '',
        type: 3,
        mechanism_type: 1
      },
      centers: [],
      cacheCenter: []
    }
  },
  mounted() {
    this.getList()
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý hệ thống thông tin", route: 'Thông báo'},
      {title: "Chính sách trụ sở tổng"}
    ]);
  },
  methods: {
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl()
      this.callApiGetList()
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'mechanism-index',
        query: {
          page: this.page,
          ...this.query
        }
      })
    },

    handleQueryCenter($event) {
      let id = $event.id
      this.query.center_id = id
    },
    getList() {
      this.pullParamsUrl()
      this.callApiGetList()
    },
    pullParamsUrl() {
      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      this.query.keyword = this.$route.query.keyword
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({path: '', query: {page: this.page}})
      this.callApiGetList()
    },
    callApiGetList() {
      this.$store.dispatch(GET_MECHANISMS, {
        page: this.page,
        ...this.query
      }).then((res) => {
        if (!res.data.error) {
          this.is_disable_search = false;
          this.informations = res.data;
          this.last_page = res.data.last_page;
        }
      });
    },
    getAccountType(item) {
      if (item.account_type != null) {
        let arrName = item.account_type.map(function (v) {
          return v.name
        })
        return arrName.join()
      }
    },
    deleteMechanism(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_MECHANISM, id).then((res) => {
            if (res.error) {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'danger',
                solid: true
              });
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'success',
                solid: true
              });
              this.getList()
            }
          });
        }
      });
    },
  }
}
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}

.multiselect__tags {
  padding: 3px 40px 0 9px !important;
  min-height: 38px !important;
}

.multiselect {
  min-height: 38px !important;
}

.mx-input {
  min-height: 38px !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
