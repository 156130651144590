import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_VERSION = "setVersion";
const state = {
  errors: null,
  user: JSON.parse(localStorage.getItem(`user`))||{},
  isAuthenticated: !!JwtService.getToken()
}

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.login("/login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
            context.commit(SET_VERSION);
          resolve(data);
        })
        .catch(( {response} ) => {
          context.commit(SET_ERROR, response && response.data ? response.data.message : '');
        });
    });
  },
  [LOGOUT](context) {
    // ApiService.setHeader();
    return new Promise((resolve,reject) => {
      ApiService.post('logout')
          .then(() => {
            context.commit(PURGE_AUTH);
            localStorage.removeItem('user');
            resolve();
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      // ApiService.setHeader();
      // ApiService.post('verify')
      //   .then(({ data }) => {
      //     context.commit(SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     context.commit(SET_ERROR, response && response.data ? response.data.errors : '');
      //   });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    return new Promise(resolve => {
      ApiService.post("/change-password", payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(( {response} ) => {
            if (response?.data?.data?.message_validate_form) {
              context.commit(SET_ERROR, response.data.data.message_validate_form);
            } else {
              context.commit(SET_ERROR, response && response.data ? response.data.message : '');
            }
          });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.user = { ...user, ...{ name: "Quý Kim", avatar: "" } };
    localStorage.user = JSON.stringify(user);
    state.errors = {};
    JwtService.saveToken('Edutalk');
  },
    [SET_VERSION](state, user) {
        ApiService.query('current-version').then(({data})=>{
            localStorage.version = data.data;
        })
    },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
