const statusManagementKpi = [
    {
        name: 'Cần nhập thành phẩm',
        value: 0,
    },
    {
        name: 'Cần chấm',
        value: 1,
    },
    {
        name: 'Đã chấm',
        value: 2,
    },
]

const pointOptionKpi = [
    {
        name: '0%',
        value: 0,
    },
    {
        name: '30%',
        value: 30,
    },
    {
        name: '60%',
        value: 60,
    },
    {
        name: '90%',
        value: 90,
    },
    {
        name: '100%',
        value: 100,
    },
]

const stageOption = [
    {
        name: 'Đang tích lũy',
        value: 0,
    },
    {
        name: 'Có hiệu lực',
        value: 1,
    },
    {
        name: 'Được chuyển nhượng/Bán',
        value: 2,
    },
    {
        name: 'Đã chuyển nhượng/Bán',
        value: 3,
    },
]

const resultPointWeekly = [
    {
        name: 'Không đạt',
        value: 0,
    },
    {
        name: 'Đạt',
        value: 1,
    },
]

const statusProduction = [
    {
        name: 'Chờ lớp',
        value: 1,
    },
    {
        name: 'Đang học khóa dưới',
        value: 2,
    },
]

const category_chamsoc_define = {
    1: 'Nghỉ học 2 buổi',
    2: 'Điểm kiểm tra thấp',
    3: 'Không làm bài',
    4: 'Chua đóng tiền học',
    5: 'Đã đóng tiền học',
    6: 'Feedback thấp',
    7: 'Nghỉ học 1 buổi',
    8: 'Vắng có phép',
    9: 'Hướng dẫn app cho phụ huynh',
    10: 'Kết quả BTVN không tốt',
    11: 'Kết quả BTVN không tốt',
    13: 'Nhắc nhở bảo hành',
    14: 'Nghỉ 1 buổi không phép',
    15: 'Nghỉ buổi khai giảng',
    16: 'Chăm sóc học viên giữa khóa'
}

const listReasonAbsent = [
    {
        'id': 1,
        'value': 'Nghỉ hẳn lớp học',
    },
    {
        'id': 2,
        'value': 'Bận việc cá nhân',
    },
    {
        'id': 3,
        'value': 'Vấn đề ve sức khỏe',
    },
    {
        'id': 4,
        'value': 'Quân sự/ thực tập',
    },
    {
        'id': 5,
        'value': 'Khác',
    },
    {
        id: 25,
        value: '2 buổi không làm BTVN',
    },
    {
        id: 26,
        value: 'Nghỉ học 2 buổi',
    },
    {
        id: 27,
        value: 'Nghỉ 1 buổi + Không làm BTVN 1 buổi.',
    },
]

const problems = [
    {
        id: 1,
        name: 'Feedback Giáo viên',
    },
    {
        id: 19,
        name: 'Feedback trợ giảng',
    },
    {
        id: 3,
        name: 'Cơ sở vật chất',
    },
    {
        id: 20,
        name: 'Xin thêm tài liệu',
    }, {
        id: 21,
        name: 'Feedback App BTVN',
    },
    {
        id: 22,
        name: 'Yêu cầu bổ trợ',
    },
    {
        id: 23,
        name: 'Khác',
    },
]
const listReasonOffLesson = [
    {
        id: 9,
        name: 'Bận việc cá nhân',
    },
    {
        id: 10,
        name: 'Trùng lịch học',
    },
    {
        id: 11,
        name: 'Nghỉ ốm',
    },
    {
        id: 12,
        name: 'Du lịch',
    }, {
        id: 13,
        name: 'Quân sự/ Thực tập',
    },
    {
        id: 14,
        name: 'Bảo lưu',
    },
    {
        id: 24,
        name: 'Nghỉ hẳn',
    },
    {
        id: 23,
        name: 'Khác',
    },
]

const statusDefine = {
    0: 'Hủy',
    1: 'Chờ xử lý',
    2: 'Đã nhập giải pháp',
    3: 'Đã hoàn thành'
}

export {
    problems,
    pointOptionKpi,
    resultPointWeekly,
    statusManagementKpi,
    stageOption,
    statusProduction,
    category_chamsoc_define,
    listReasonAbsent,
    listReasonOffLesson,
    statusDefine
}