<template>
    <tr>
        <td class="text-center">{{ attendance.user_name }}</td>
        <td class="text-center">{{ attendance.user_code }}</td>
        <td class="text-center">{{ attendance.phone }}</td>
        <td v-if="attendance.status_class == studying || (attendance.attendance != 3 && attendance.attendance != null)" class="text-center">
            <input v-model="checkbox.checkedActive" @change="clickActive('checkedActive',attendance.id)"
             class="attendance.id"
             :class="{'pointer-none':isDisable}"
             type="checkbox" id="att-check">
        </td>
        <td v-if="attendance.status_class == studying || (attendance.attendance != 3 && attendance.attendance != null)" class="text-center">
            <input v-model="checkbox.chekedAbsent" @change="clickActive('chekedAbsent',attendance.id)"
             class="attendance.id"
             :class="{'pointer-none':isDisable}"
             type="checkbox" id="att-check">
        </td>
        <td v-if="attendance.status_class == studying || (attendance.attendance != 3 && attendance.attendance != null)" class="text-center">
            <input v-model="checkbox.checkedUnPermission" @change="clickActive('checkedUnPermission',attendance.id)"
             class="attendance.id"
             :class="{'pointer-none':isDisable}"
             type="checkbox" id="att-check">
        </td>
        <td colspan="3" v-if="attendance.status_class == absent && attendance.attendance == null">
            <div class="bold">Học viên đã tạm dừng học lớp này</div>
        </td>
        <td colspan="3" v-if="attendance.status_class == stop_though && attendance.attendance == null">
          <div class="bold">Học viên đã tạm dừng học lớp này</div>
          <div>Học viên có thể tham gia buổi học này ? <button @click="continue_attendance(attendance)" class="bg-white border-0 text-info"><u>Điểm danh ngay</u></button></div>
        </td>
        <td>{{ attendance.reason }}</td>
    </tr>
</template>

<script>
import { STUDYING,ABSENT,STOP_THROUGH } from "@/core/services/store/course/classes.module";


export default {
    props: {
        attendance: Object,
        isShowModalAttendance:Boolean,
        clickAttendanceAgain:Boolean,
        isAttendanceAgain:Boolean
    },

    data() {
        return {
            checkbox : {
                checkedActive: false,
                chekedAbsent: false,
                checkedUnPermission: false,
            },
            isDisable:false,
            studying: STUDYING,
            absent: ABSENT,
            stop_though: STOP_THROUGH,
            
        }
    },
    mounted(){
        this.getCheckBefore();
    },
    watch: {
        isShowModalAttendance: {
          handler(){
                Object.fromEntries(Object.entries(this.checkbox).filter(([key]) => {
                    this.checkbox[key] = false;
            }));
            this.isDisable = false;
          },
          immediate: true,
          deep: true

        },
        attendance: {
          handler(){
            this.getCheckBefore();
          },
          immediate: true,
          deep: true
        },
        clickAttendanceAgain:{
            handler(){
                if(this.clickAttendanceAgain)
                this.isDisable = false;
            },
        }
    },

    methods: {
        getCheckBefore(){
            switch (this.attendance.attendance) {
                case 1:
                this.checkbox.checkedActive = true;
                this.isDisable = true;
                break;
                case 0:
                this.checkbox.chekedAbsent = true;
                this.isDisable = true;
                break;
                case 2:
                this.checkbox.checkedUnPermission = true;
                this.isDisable = true;
                break;
                case null:
                    if(!this.clickAttendanceAgain && this.isAttendanceAgain)
                this.isDisable = true;
                break;
                default:
                break;
            }
        },

        clickActive(type,course_student_id) {
            Object.fromEntries(Object.entries(this.checkbox).filter(([key]) => {
                if(key !== type) {
                    this.checkbox[key] = false;
                }
            }));

            let type_convert = '';
            switch (type) {
                case 'checkedActive':
                type_convert = 1;
                break;
                case 'chekedAbsent':
                type_convert = 0;
                break;
                case 'checkedUnPermission':
                type_convert = 2;
                break;
                default:
                break;
            }
            if(!this.checkbox[type]) {
                type_convert = null;
            }
            this.$emit('changeAttendance',type_convert,course_student_id)
        },
        continue_attendance(attendance){
            attendance.status_class = STUDYING;
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/custom/custom-teacher-edu.scss";

.mx-datepicker {
    width: 100% !important;
}

el-dialog {
    margin-top: 3vh !important;
}
.pointer-none {
    pointer-events: none;
}

</style>
<style>
.modal-check-poin .table-bordered {
    border: 1px solid #e9e9e9;
}
</style>
