<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="form" @submit.prevent="handleSubmit(onSubmit)">
      <div class="form-group">
        <div class="d-flex">
          <p class="min-w-100px form-label-common">Khách hàng: </p>
          <b>{{ contractStudentAmount.user.name }}</b>
        </div>
      </div>
      <div class="form-group">
        <div class="d-flex">
          <p class="min-w-100px form-label-common">Số tiền: </p>
          <b>{{ formatPrice(contractStudentAmount.amount, 'đ') }}</b>
        </div>
      </div>
      <div class="form-group d-md-flex">
        <div class="col-md-6">
          <ValidationProvider vid="nguoi_gt_id" name="Mã giới thiệu"
                              rules="min_value:0"
                              v-slot="{ errors,classes }">
            <div >
              <label for="nguoi_gt_id" class="min-w-100px">Mã giới thiệu <span class="text-danger"></span></label>
              <el-input-number id="nguoi_gt_id" :class="classes"
                               class="w-100"
                               :controls="false"
                               :step="1"
                               v-model="formUpdate.ma_gioi_thieu"
                               placeholder="Mã giới thiệu">
              </el-input-number>
            </div>
            <div class="fv-plugins-message-container">
              <div data-field="nguoi_gt_id" data-validator="notEmpty"
                   class="fv-help-block">
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider vid="nguoi_tu_van" name="Mã tư vấn"
                              rules="min_value:0"
                              v-slot="{ errors,classes }">
            <div >
              <label for="nguoi_tu_van" class="min-w-100px">Mã tư vấn <span class="text-danger"></span></label>
              <el-input-number :controls="false" id="nguoi_tu_van" :class="classes"
                               class="w-100"
                               :step="1"
                               v-model="formUpdate.nguoi_tu_van"
                               placeholder="Mã tư vấn...">
              </el-input-number>
            </div>
            <div class="fv-plugins-message-container">
              <div data-field="nguoi_tu_van" data-validator="notEmpty"
                   class="fv-help-block">
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>

      </div>
      <div class="form-group d-md-flex">
        <div>
          <ValidationProvider vid="business_coefficient" name="Hệ số đội"
                              rules="required|max_value:5"
                              v-slot="{ errors,classes }">
            <div >
              <label for="business_coefficient" class="min-w-100px">Hệ số đội <span class="text-danger">*</span></label>
              <el-input-number id="business_coefficient" :class="classes" type="number"
                               class="w-100"
                               v-model="formUpdate.business_coefficient"
                               placeholder="Hệ số đội">
              </el-input-number>
            </div>
            <div class="fv-plugins-message-container">
              <div data-field="business_coefficient" data-validator="notEmpty"
                   class="fv-help-block">
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="pl-md-2 pr-md-2">
          <ValidationProvider vid="business_coefficient" name="Hệ số Cá nhân"
                              rules="required|max_value:5"
                              v-slot="{ errors,classes }">
            <div >
              <label for="personal_coefficient" class="min-w-100px">Hệ số Cá nhân<span class="text-danger">*</span></label>
              <el-input-number id="personal_coefficient" :class="classes" type="number"
                               class="w-100"
                               v-model="formUpdate.personal_coefficient"
                               placeholder="Hệ số Cá nhân">
              </el-input-number>
            </div>
            <div class="fv-plugins-message-container">
              <div data-field="business_coefficient" data-validator="notEmpty"
                   class="fv-help-block">
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div>
          <ValidationProvider vid="business_coefficient" name="Hệ số Lên cấp"
                              rules="required|max_value:5"
                              v-slot="{ errors,classes }">
            <div >
              <label for="levelup_coefficient" class="min-w-100px">Hệ số Lên cấp<span class="text-danger">*</span></label>
              <el-input-number id="levelup_coefficient" :class="classes" type="number"
                               class="w-100"
                               v-model="formUpdate.levelup_coefficient"
                               placeholder="Hệ số Lên cấp">
              </el-input-number>
            </div>
            <div class="fv-plugins-message-container">
              <div data-field="business_coefficient" data-validator="notEmpty"
                   class="fv-help-block">
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="text-right">
        <el-button :loading="is_disable" type="primary" native-type="submit" class="btn btn-primary">Cập nhật</el-button>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import {UPDATE_STUDENT_AMOUNT} from "@/core/services/store/contract/studentAmount.module";

export default {
  name: "ModalEditAmount",
  props: {
    contractStudentAmount: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data(){
    return{
      formUpdate: {
        ma_gioi_thieu: '',
        nguoi_tu_van: '',
        business_coefficient: '',
        levelup_coefficient: '',
        personal_coefficient: ''
      },
      is_disable: false
    }
  },
  watch: {
    contractStudentAmount: {
      handler() {
        this.formUpdate.ma_gioi_thieu = this.contractStudentAmount.nguoi_gt_id ? this.contractStudentAmount.nguoi_gt_id  + 100000 : '';
        this.formUpdate.nguoi_tu_van = this.contractStudentAmount.nguoi_tu_van ? this.contractStudentAmount.nguoi_tu_van + 100000 : ''
        this.formUpdate.business_coefficient = this.contractStudentAmount.business_coefficient;
        this.formUpdate.levelup_coefficient = this.contractStudentAmount.levelup_coefficient;
        this.formUpdate.personal_coefficient = this.contractStudentAmount.personal_coefficient;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onSubmit() {
      this.is_disable = true;
      let params = {
        ...this.formUpdate,

        id: this.contractStudentAmount.id
      }
      this.$store.dispatch(UPDATE_STUDENT_AMOUNT, params).then((res) => {
        if (res.error) {
          return;
        }
        this.$emit('updateSuccess')
        this.$notify({
          type: 'success',
          title: 'Thành công',
          message: 'Cập nhật thành công'
        });
      }).catch((e) => {
        this.$notify({
          type: 'error',
          title: 'Không thành công',
          message: e.data.message
        });
      }).finally(() => {
        this.is_disable = false
      })
    }
  }
}
</script>

<style scoped>
.form-label-common{
  font-size: 12px;
  font-weight: 400;
  color: #3F4254;
}
</style>
