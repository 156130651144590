var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal-employees-on-staff-detail",staticClass:"modal-employees-on-staff-detail",attrs:{"size":"xl","centered":true,"scrollable":true,"hide-footer":"","title":_vm.title},on:{"hide":_vm.onHide}},[_c('div',{staticClass:"d-block text-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
              enabled: true
            },"pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 10,
              position: 'bottom',
              dropdownAllowAll: true,
              setCurrentPage: 1,
              nextLabel: 'Sau',
              prevLabel: 'Trước',
              rowsPerPageLabel: 'Số bản ghi',
              ofLabel: 'of',
              pageLabel: 'page', // for 'pages' mode
              allLabel: 'Tất cả',
            }}})],1)])]),_c('footer',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":_vm.hideModal}},[_vm._v("Hủy bỏ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }