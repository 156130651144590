import ApiService from "@/core/services/api.service";
import {CREATE_PERMISSION} from "./permission.module";
import {PUSH_PERCENT_LOADING_IMPORT_SALE_LOG, SET_PERCENT_LOADING_IMPORT_SALE_LOG} from "./users.module";
import {STORE_MARK} from "@/core/services/store/test/test.module";

//action types
export const GET_RESOURCES = "getallhumanResource";
export const EXPORT_DEMO_SALARY = "export_demo_salary";
export const EXPORT_DEMO_TEACHER_SALARY = "export-demo-teacher-salary";
export const PUSH_PERCENT_LOADING_IMPORT_SALARY = "push_percent_loading_import_salary";
export const PUSH_PERCENT_LOADING_IMPORT_SALARY_TEACHER = "push_percent_loading_import_salary_teacher";
export const POST_PAYCHECK = "POST_PAYCHECK"

//mutations type

export const SET_RESOURCES = "sethumanResources";
export const SET_RESOURCE_PAGINATE = "sethumanResourcepaginatevalue";
export const SET_PERCENT_LOADING_IMPORT_SALARY = "set_percent_loading_import_salary";
export const SET_PERCENT_LOADING_IMPORT_SALARY_TEACHER = "set_percent_loading_import_salary_teacher";
const state = {
    errors: null,
    humanResources: [],
    humanResource : '',
    humanResourcePaginatedData : '',
    percent : '',
    percent_teacher : ''
};

const getters = {
    getAllHumanResources(state) {
        return state.humanResources;
    },

    getHumanResourcePaginate(state) {
        return state.humanResourcePaginatedData;
    },

    percentLoadingImportSalary(state) {
        return state.percent;
    },

    percentLoadingImportSalaryTeacher(state) {
        return state.percent_teacher;
    }

};

const actions = {
    [GET_RESOURCES](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/resources", credentials)
                .then(({ data }) => {
                    context.commit(SET_RESOURCES, data.data.data);
                    const humanResourcePagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_RESOURCE_PAGINATE,humanResourcePagination);
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response)
                });
        });
    },
    [EXPORT_DEMO_SALARY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('user/export-demo-salary', payload).then(({ data }) => {
                let filename = "Thu-nhap-chuan.xlsx";
                let anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({ response }) => {
                reject(response);
            });
        });
    },
    [EXPORT_DEMO_TEACHER_SALARY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`user/${EXPORT_DEMO_TEACHER_SALARY}`, payload).then(({ data }) => {
                let filename = "Thu-nhap-chuan-teacher.xlsx";
                let anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({ response }) => {
                reject(response);
            });
        });
    },
    [POST_PAYCHECK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('user/save-paycheck', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    
    [PUSH_PERCENT_LOADING_IMPORT_SALARY](context, payload) {
        context.commit(SET_PERCENT_LOADING_IMPORT_SALARY, payload);
    },
    [PUSH_PERCENT_LOADING_IMPORT_SALARY_TEACHER](context, payload) {
        context.commit(SET_PERCENT_LOADING_IMPORT_SALARY_TEACHER, payload);
    },

};

const mutations = {
    [SET_RESOURCES](state, data) {
        state.humanResources = data;
        state.errors = {};
    },

    [SET_RESOURCE_PAGINATE](state, data) {
        state.humanResourcePaginatedData = data;
    },

    [SET_PERCENT_LOADING_IMPORT_SALARY](state, data) {
        state.percent = data;
    },

    [SET_PERCENT_LOADING_IMPORT_SALARY_TEACHER](state, data) {
        state.percent_teacher = data;
    },

};

export default {
    state,
    actions,
    mutations,
    getters,
};
