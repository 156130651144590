import ApiService from "@/core/services/api.service";


const _ = require('lodash');
const state = {};
export const GET_DETAIL_INFORMATION_PROGRAM = 'GET_DETAIL_INFORMATION_PROGRAM';
export const EDIT_INFORMATION_PROGRAM = 'EDIT_INFORMATION_PROGRAM';
export const GET_LIST_GIFT = 'GET_LIST_GIFT';
export const CREATE_CLASS = 'CREATE_CLASS';
export const GET_LIST_CLASS = 'GET_LIST_CLASS';
export const TICK_OUT_OF_SLOT = 'TICK_OUT_OF_SLOT'
export const DELETE_CLASS = 'DELETE_CLASS'
export const EXPORT_EXAM_CLASS = 'EXPORT_EXAM_CLASS'
export const PREVIEW_IMPORT = 'PREVIEW_IMPORT'
export const IMPORT_CLASS = 'IMPORT_CLASS'
export const GET_DATA_IMPORT = 'GET_DATA_CLASS'
export const EDIT_CLASS = 'EDIT_CLASS'
export const DELETE_GIFT = 'DELETE_GIFT'
export const CREATE_LIST_GIFT = 'CREATE_LIST_GIFT'
export const EXPORT_RESULT_PROGRAM = 'EXPORT_RESULT_PROGRAM'
export const GET_COMBO_BY_QUANTITY = 'GET_COMBO_BY_QUANTITY'
export const UPDATE_SLOT = 'spin/rewardProgramClassroom/update-tick-slot'
export const EXPORT_EXAM_CLASS_VOUCHER = 'EXPORT_EXAM_CLASS_VOUCHER'
export const GET_DATA_IMPORT_CLASS = 'GET_DATA_IMPORT_CLASS'
export const IMPORT_CLASS_VOUCHER = 'IMPORT_CLASS_VOUCHER'

// genz
export const CREATE_PROMOTION_PROGRAM_GENZ = "create-promotion-program-genz";  
export const GET_LIST_PROMOTION_PROGRAM_GENZ = "get-list-promotion-program-genz";  
export const DELETE_PROMOTION_BY_ID = "delete-promotion-by-id";
export const CHANGE_TYPE_PROMOTION_PROGRAM_GENZ = "change-type-program";
export const CREATE_PROMOTION_GENZ = "create-promotion-genz";
export const GET_LIST_PROMOTION_GENZ = "get-list-promotion-genz";
export const DELETE_PROMOTION = "delete-promotion-genz";
export const GET_LIST_STUDENT_REGISTER_PROMOTION = "get-list-student-register-promotion";
export const SHOW_PROGRAM_GENZ = "show-program-genz";
export const UPDATE_PROGRAM_GENZ = "update-program-genz";



const getters = {};
const actions = {
    [GET_DETAIL_INFORMATION_PROGRAM](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`spin/rewardPrograms`, id)
                .then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_COMBO_BY_QUANTITY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`spin/rewardProgramClassroom/get-combo-by-quantity`, payload.quantity)
                .then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [CREATE_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`spin/rewardProgramClassroom`, payload)
                .then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_LIST_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`spin/rewardProgramClassroom?spin_reward_program_id=${payload.id}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [TICK_OUT_OF_SLOT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`spin/rewardProgramClassroom/update-tick-slot`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DELETE_CLASS](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('spin/rewardProgramClassroom', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [EDIT_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('spin/rewardProgramClassroom', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_GIFT](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete("spin/gifts", id)
                .then(({data}) => {
                    resolve(data);  
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },

    [EXPORT_EXAM_CLASS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('spin/rewardProgramClassroom/export-example', payload).then(({data}) => {
                const filename = 'File-mau-import-dieu_kien' + ".xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [PREVIEW_IMPORT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`spin/rewardProgramClassroom/preview-import`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [IMPORT_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`spin/rewardProgramClassroom/import-class`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_DATA_IMPORT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`spin/rewardProgramClassroom/get-data-import`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_LIST_GIFT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`spin/gifts?spin_reward_program_id=${payload.id}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [EDIT_INFORMATION_PROGRAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('spin/rewardPrograms', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        });
    },
    [CREATE_LIST_GIFT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("spin/gifts", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [EXPORT_RESULT_PROGRAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`spin/spinCustomerHistory/export`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_EXAM_CLASS_VOUCHER](context, payload) {
        return new Promise(resolve => {
            ApiService.query('spin/rewardProgramClassroom/export-example-voucher', payload).then(({data}) => {
                const filename = 'File-mau-import-danh-sach-lop' + ".xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_DATA_IMPORT_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`spin/rewardProgramClassroom/get-data-import`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [IMPORT_CLASS_VOUCHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`spin/rewardProgramClassroom/save-import`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [CREATE_PROMOTION_PROGRAM_GENZ](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('spin/rewardPrograms', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response);
            });
        })
    },

    [CREATE_PROMOTION_GENZ](context, payload) {
        return new Promise(resolve => {
            ApiService.post('spin/SpinGiftController/create-promotion-genz', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_LIST_PROMOTION_PROGRAM_GENZ](context, payload) {
        return new Promise(resolve => {
            ApiService.query('spin/rewardPrograms', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_LIST_PROMOTION_GENZ](context, payload) {
        return new Promise(resolve => {
            ApiService.query('spin/SpinGiftController/get-promotion-genz', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_LIST_STUDENT_REGISTER_PROMOTION](context, payload) {
        return new Promise(resolve => {
            ApiService.query('spin/rewardPrograms/get-list-student-register-promotion', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    
    [DELETE_PROMOTION_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.delete("spin/rewardPrograms", id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [DELETE_PROMOTION](context, id) {
        return new Promise(resolve => {
            ApiService.post("spin/SpinGiftController/delete-promotion-genz", id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
  
    [CHANGE_TYPE_PROMOTION_PROGRAM_GENZ](context, payload) {
        return new Promise(resolve => {
            ApiService.query('spin/rewardPrograms/change-type-program', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [SHOW_PROGRAM_GENZ](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`spin/rewardPrograms/show`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    const response = error.response || {};
                    reject(response);
                });
        });
    },

    [UPDATE_PROGRAM_GENZ](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`spin/rewardPrograms/update`,payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    const response = error.response || {};
                    reject(response);
                });
        });
    }
};
const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters,
};
