<template>
  <b-modal @hide="onHide" size="sm" :centered="true" :scrollable="true"
           ref="modal-courses-refund"
           okTitle="Đóng"
           ok-only
           title="Danh sách khóa đã hủy"
           class="modal-contract-detail">
    <div class="d-block text-center">
      <ol class="list-group list-group-numbered">
        <li v-for="course in listCoursesRefund" class="list-group-item">{{course.name}}</li>
      </ol>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ListCourseRefund",
  props: {
    showModal: { default:false },
    listCoursesRefund: {
      type: Array,
      default: () => { return [] }
    }
  },
  watch: {
    showModal(){
      if (this.showModal){
        this.$refs['modal-courses-refund'].show();
      }
    }
  },
  methods: {
    onHide(evt) {
      this.$emit("clicked", true);
    },
    hideModal() {
      this.$refs['modal-courses-refund'].hide();
    },
    toggleModal() {
      // when the modal has hidden
      this.$refs['modal-courses-refund'].toggle('#toggle-btn');
    },
  }
}
</script>

<style scoped>

</style>
