import ApiService from "@/core/services/api.service";
const _ = require('lodash');
//action types
export const GET_USER_PROMOTION = "get_user_promotions";
export const GET_USER_DEGRADATION = "get_user_degradations";
export const CHANGE_STATUS_PROMOTION = "change_status_promotion";
export const CHANGE_STATUS_DEGRADATION = "change_status_degradation";
export const CHANGE_STATUS_PROMOTION_ALL = "change_status_promotion_all";
export const CHANGE_STATUS_DEGRADATION_ALL = "change_status_degradation_all";
export const REFRESH_USER_PROMOTION = "refresh_user_promotion";
export const REFRESH_USER_DEGRADATION = "refresh_user_degradation";


//mutations type

export const SET_USER_PROMOTION = "set_user_promotions";
export const SET_USER_DEGRADATION = "set_user_degradations";
export const SET_USER_PROMOTION_PAGINATE = "set_user_promotions_paginate";
export const SET_USER_DEGRADATION_PAGINATE = "set_user_degradations_paginate";
export const UPDATE_USER_PROMOTION_LIST = "update_user_promotion_list";
export const UPDATE_USER_DEGRADATION_LIST = "update_user_degradation_list";

const state = {
    errors: null,
    userPromotions: [],
    userDegradations: [],
    userPromotionPaginatedData : '',
    userDegradationPaginatedData : '',
};

const getters = {
    getAllUserPromotions(state) {
        return state.userPromotions;
    },
    getAllUserDegradations(state) {
        return state.userDegradations;
    },
    getUserPromotionPaginate(state) {
        return state.userPromotionPaginatedData;
    },
    getUserDegradationPaginate(state) {
        return state.userDegradationPaginatedData;
    },

};

const actions = {
    [GET_USER_PROMOTION](context, credentials) {
        return new Promise((resolve,reject) => {
            ApiService.query("user-promotions", credentials)
                .then(({ data }) => {
                    context.commit(SET_USER_PROMOTION, data.data.data);
                    const userPromotionPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_USER_PROMOTION_PAGINATE,userPromotionPagination);
                    resolve(data);
                })
                .catch(({response}) => {
                   reject(response);
                });
        });
    },
    [CHANGE_STATUS_PROMOTION](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.post("changeStatusPromotion/"+ payload.id, payload.params)
                .then(({ data }) => {
                    context.commit(UPDATE_USER_PROMOTION_LIST, data.data);
                    resolve(data);
                })
                .catch(({response}) => {
                   reject(response);
                });
        });
    },

    [CHANGE_STATUS_PROMOTION_ALL](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.post("changeStatusPromotionAll", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({response}) => {
                   reject(response);
                });
        });
    },

    [REFRESH_USER_PROMOTION](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.query("refresh-promotions", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },

    [GET_USER_DEGRADATION](context, credentials) {
        return new Promise((resolve,reject) => {
            ApiService.query("user-degradations", credentials)
                .then(({ data }) => {
                    context.commit(SET_USER_DEGRADATION, data.data.data);
                    const userDegradationPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_USER_DEGRADATION_PAGINATE, userDegradationPagination);
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },

    [CHANGE_STATUS_DEGRADATION](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.post("changeStatusDegradation/"+ payload.id, payload.params)
                .then(({ data }) => {
                    context.commit(UPDATE_USER_DEGRADATION_LIST, data.data);
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },

    [CHANGE_STATUS_DEGRADATION_ALL](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.post("changeStatusDegradationAll", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },

    [REFRESH_USER_DEGRADATION](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.query("refresh-degradations", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },

};

const mutations = {
    [SET_USER_PROMOTION](state, data) {
        state.userPromotions = data;
        state.errors = {};
    },
    [SET_USER_DEGRADATION](state, data) {
        state.userDegradations = data;
        state.errors = {};
    },
    [SET_USER_PROMOTION_PAGINATE](state, data) {
        state.userPromotionPaginatedData = data;
    },
    [SET_USER_DEGRADATION_PAGINATE](state, data) {
        state.userDegradationPaginatedData = data;
    },
    [UPDATE_USER_PROMOTION_LIST](state, userPromotion) {
        let oldUserPromotion = _.find(state.userPromotions, function (obj){
            return obj.id === userPromotion.id;
        });
        oldUserPromotion.status = userPromotion.status;
    },
    [UPDATE_USER_DEGRADATION_LIST](state, userDegradation) {
        let oldUserDegradation = _.find(state.userDegradations, function (obj){
            return obj.id === userDegradation.id;
        });
        oldUserDegradation.status = userDegradation.status;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
