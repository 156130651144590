<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        Khóa học đăng ký
      </div>
    </div>

    <div class="card-body">
      <div class="example mb-10">
        <div class="row filter_form">
<!--          <div class="col-md-2">-->
<!--              <div class="form-group">-->
<!--                <el-select v-model="query.center_id"-->
<!--                  placeholder="Chọn trung tâm"-->
<!--                  @change="getBranches"-->
<!--                  clearable-->
<!--                >-->
<!--                  <el-option-->
<!--                    v-for="item in centers"-->
<!--                    :key="item.id"-->
<!--                    :label="item.name"-->
<!--                    :value="item.id"-->
<!--                    >-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </div>-->
<!--          </div>-->

<!--          <div class="col-md-2">-->
<!--              <div class="form-group">-->
<!--                <el-select v-model="query.branch_id" placeholder="Chọn chi nhánh" clearable>-->
<!--                  <el-option-->
<!--                    v-for="item in branches"-->
<!--                    :key="item.id"-->
<!--                    :label="item.name"-->
<!--                    :value="item.id"-->
<!--                    >-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </div>-->
<!--          </div>-->
          <div class="col-md-3">
            <div class="d-flex">
              <button class=" btn btn-success font-weight-bold mr-2"
              :class="{'spinner spinner-white spinner-right' : is_loading_export}"
                      @click="exportExcel">
                  <i class="el-icon-download"></i> Xuất Excel
              </button>
              <button class=" btn btn-success font-weight-bold mr-2"
                      :class="{'spinner spinner-white spinner-right' : is_loading_export}"
                      @click="exportExcelV2">
                <i class="el-icon-download"></i> Xuất Excel v2
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
  import Multiselect from 'vue-multiselect';
  import { GET_LIST_COURSE_STUDENT, EXPORT_EXCEL_DS_COURSE_STUDENT, EXPORT_EXCEL_COURSE_STUDENT_V2 } from '../../../core/services/store/course/course.module';
  import { GET_CENTERS } from "../../../core/services/store/center/centers.module";
  import { GET_BRANCHES } from "../../../core/services/store/center/branch.module";
  import {exportExcel} from "../../../core/filters";
  export default {
    name: "Off-Lesson",

    components: {
        Multiselect
    },
    data() {
      return {
        is_loading_export : false,
        page: 1,
        last_page: 1,
        query:{
          center_id: this.$route.query.center_id ? Number(this.$route.query.center_id) : '',
          branch_id: this.$route.query.branch_id ? Number(this.$route.query.branch_id) : '',
          query_name: this.$route.query.query_name || '',
        },
        data: [],
        dialogFormVisible: false,
        item: {},
        index: 0,
        centers: {},
        branches: {}
      }
    },
    // created() {
    mounted() {
        this.getListRequestOffClass();
        this.getAllCenter();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Khóa học đăng ký", route: 'course-student'},
            {title: "Quản lý tài chính"}
        ]);
    },

    methods: {
      getListRequestOffClass() {
        this.$store.dispatch(GET_LIST_COURSE_STUDENT, {
            page: this.page,
            center_id: this.query.center_id,
            branch_id: this.query.branch_id,
            query_name: this.query.query_name
        }).then((res) => {
            this.data = res.data.data;
            this.last_page = res.data.last_page
        });
      },
      getAllCenter() {
        this.$store.dispatch(GET_CENTERS, {
            limit: 10000,
            status: 1
        }).then((res) => {
          if (!res.error) {
            this.centers = res.data.data
            // this.addFirstSelectExample(this.centers, '__Chọn__')
            // this.setCacheTheLoad()
          }
        }).catch((e) => {
            console.log(e, 'error')
        });
      },

      getBranches() {
        this.$store.dispatch(GET_BRANCHES, {
              limit: 10000,
              center_id: this.query.center_id
          }).then((res) => {
              if (!res.error) {
                  this.branches = res.data.data
                  this.addFirstSelectExample(this.branches, '__Chọn__')
                  this.setCacheTheLoad()
              }
          }).catch((e) => {
              console.log(e, 'error')
          });
      },

      searchData() {
        this.page = 1
        this.getListRequestOffClass()
        this.$router.push({path: '/payment/course-student', query: { 
            page: 1,
            branch_id: this.query.branch_id,
            center_id: this.query.center_id,
            query_name: this.query.query_name
          }
        });
      },
      exportExcelV2() {
        this.is_loading_export = true;
        const HEADER_ROW_EXPORT_DSKH = [
          {
            key: 'user_id',
            value: 'Mã học viên',
          },
          {
            key: 'name',
            value: 'Họ tên',
          },
          {
            key: 'phone',
            value: 'Số điện thoại',
          },
          {
            key: 'contract_id',
            value: 'Mã hợp đồng',
          },
          {
            key: 'center_name',
            value: 'Trung tâm',
          },
          {
            key: 'branch_name',
            value: 'Chi nhánh',
          },
          {
            key: 'course_id',
            value: 'ID khóa học',
          },
          {
            key: 'course_name',
            value: 'Tên khóa học',
          },
          {
            key: 'register_time',
            value: 'Thời gian đăng ký khóa học',
          },
          {
            key: 'classroom_name',
            value: 'Tên lớp học',
          },
          {
            key: 'start_time',
            value: 'Thời gian khai giảng',
          },
          {
            key: 'finish_time',
            value: 'Thời gian dự kiến kết thúc',
          },
          {
            key: 'total_after_voucher',
            value: 'Số tiền phải đóng'
          },
          {
            key: 'total_paid',
            value: 'Đã trả'
          },
          {
            key: 'total_debt',
            value: 'Công nợ'
          },
        ]
        this.$store.dispatch(EXPORT_EXCEL_COURSE_STUDENT_V2, this.query).then((data)=> {
          exportExcel(HEADER_ROW_EXPORT_DSKH, data.data, 'Danh sách đăng ký khóa học.xlsx',false)
          this.is_loading_export = false;
        });
      },
      exportExcel() {
        this.is_loading_export = true;
        const HEADER_ROW_EXPORT_DSKH = [
          {
            key: 'user_id',
            value: 'Mã học viên',
          },
          {
            key: 'name',
            value: 'Họ tên',
          },
          {
            key: 'phone',
            value: 'Số điện thoại',
          },
          {
            key: 'contract_id',
            value: 'Mã hợp đồng',
          },
          {
            key: 'center_name',
            value: 'Trung tâm',
          },
          {
            key: 'branch_name',
            value: 'Chi nhánh',
          },
          {
            key: 'course_id',
            value: 'ID khóa học',
          },
          {
            key: 'course_name',
            value: 'Tên khóa học',
          },
          {
            key: 'register_time',
            value: 'Thời gian đăng ký khóa học',
          },
          {
            key: 'classroom_name',
            value: 'Tên lớp học',
          },
          {
            key: 'start_time',
            value: 'Thời gian khai giảng',
          },
          {
            key: 'finish_time',
            value: 'Thời gian dự kiến kết thúc',
          },
        ]
        this.$store.dispatch(EXPORT_EXCEL_DS_COURSE_STUDENT, this.query).then((data)=> {
          exportExcel(HEADER_ROW_EXPORT_DSKH, data.data, 'Danh sách đăng ký khóa học.xlsx',false)
          this.is_loading_export = false;
        });
      },
      clickCallback(obj) {
          this.page = obj
          this.getListRequestOffClass()
      },
    }
  }

</script>

<style>

</style>
