<template>
  <b-modal id="watch-writing" ref="watch-writing"  centered  hide-footer   title="Xem lại bài general của học viên" @show="getDataWriting()" size="xl">
    <div v-if="loading" class="text-center">
      <i class="el-icon-loading" style="font-size: 25px"></i>
    </div>
    <div style="white-space: pre-wrap" v-else>
      <div class="example-preview b-table-sticky-header">
      <table class="table  b-table  table-bordered">
          <thead class="thead-light">
          <tr>
            <th scope="col">Phần bài</th>
            <th scope="col" style="width: 70px">Câu hỏi</th>
            <th scope="col">Học viên trả lời</th>
          </tr>
          </thead>
          <tbody v-for="(type,index) in general">
          <tr v-for="(sentence,indexSentence) in dataGeneral[type]">
            <td :rowspan="dataGeneral[type].length" v-if="indexSentence === 0" style="font-weight: bold">{{type}}</td>
            <td>{{indexSentence + 1}}</td>
            <td style="white-space: pre-wrap">{{handleAnswer(sentence.answers)}}</td>
          </tr>
          </tbody>
        </table>
        </div>
    </div>
    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-secondary" @click="cancelModal()">Đóng</button>
    </div>
  </b-modal>
</template>
<script>
import {REVIEW_WRITING} from "@/core/services/store/test/testInput.module";

export default {
  name: "ModalWatchWriting",
  data(){
    return {
      dataGeneral: {},
      loading: false,
      general : []
    }
  },
  props:{
    informationTask:{
      type: Number,
      default(){
        return null
      }
    }
  },
  methods:{
    getDataWriting(){
      this.loading = true;
      this.$store.dispatch(REVIEW_WRITING,this.informationTask?.testInputScheduleId).then((data)=>{
        this.dataGeneral = data?.data
        this.general = Object.keys(this.dataGeneral);
      }).finally(()=>{
        this.loading = false;
      })
    },
    cancelModal(){
      this.$bvModal.hide('watch-writing')
    },
    handleAnswer(answers){
      if(answers.length === 0){
        return ''
      }
      return answers.join('\n')
    }
  }
}
</script>
<style scoped>

</style>