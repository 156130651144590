import ApiService from "@/core/services/api.service";
import {GET_MECHANISM_BY_ID} from "@/core/services/store/system/mechanism.module";

const _ = require('lodash');
export const CREATE_TERM_POLICY = "create-term-policy";
export const GET_TERM_POLICY = "get-term-policy";
export const GET_CATALOG = "get-catalog";
export const CREATE_CATALOG = "create-catalog";
export const UPDATE_CATALOG = "update-catalog";
export const GET_TERM_POLICY_BY_ID = "get-term-policy-by-id";
export const GET_CATALOG_BY_ID_BY_ID = "get-catalog-by-id";
export const GET_CATALOG_BY_CENTER_ID = "get-catalog-by-center-id";
export const GET_LOG_POLICY = "get-log-policy";
export const GET_HISTORY_POLICY = "get-history-policy";
export const DELETE_POLICY = "delete-policy";

const state = {};
const getters = {};
const actions = {
    [GET_TERM_POLICY](context, payload) {
        return new Promise(resolve => {
            ApiService.query('term-policy', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                // console.log(response)
            });
        })
    },
    [GET_CATALOG](context, payload) {
        return new Promise(resolve => {
            ApiService.query('get-catalog', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                // console.log(response)
            });
        })
    },
    [GET_HISTORY_POLICY](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_HISTORY_POLICY, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                // console.log(response)
            });
        })
    },
    [GET_CATALOG_BY_ID_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get(GET_CATALOG_BY_ID_BY_ID, id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                // console.log(response)
            });
        })
    },
    [GET_CATALOG_BY_CENTER_ID](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_CATALOG_BY_CENTER_ID, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                // console.log(response)
            });
        })
    },
    [CREATE_TERM_POLICY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('term-policy', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                // reject(response)
            });
        })
    },
    [CREATE_CATALOG](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(CREATE_CATALOG, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_CATALOG](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_CATALOG, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_TERM_POLICY_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('term-policy', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                // console.log(response)
            });
        })
    },
    [GET_LOG_POLICY](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_LOG_POLICY, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                // console.log(response)
            });
        })
    },
    [DELETE_POLICY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(DELETE_POLICY, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                // reject(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
