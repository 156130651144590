<script>
import {CANCEL_TEST_INPUT} from "@/core/services/store/test/testInput.module";

export default {
  name: "ModalCancelScheduleTester",
  data(){
    return {

    }
  },
  methods:{
    cancelTask(){

      this.$store.dispatch(CANCEL_TEST_INPUT,{id:this.$route.params.id}).then((data)=>{
        this.$notify({
          title: 'Thành công',
          message: 'Hủy lịch thành công',
          type: 'success'
        });
        this.$refs['cancel-schedule'].hide()
        this.$emit('cancelSchedule')
      }).catch(()=>{
        this.$notify.error({
          title: 'Thất bại ',
          message: 'Bạn không có quyền hủy tác vụ của người khác phụ trách'
        });
      }).finally(()=>{

      })
    },
    closeModal(){
      this.$refs['cancel-schedule'].hide()
    }
  }
}
</script>

<template>
  <div>
    <b-modal id="cancel-schedule" ref="cancel-schedule"  centered  hide-footer   header-class="my-class">
      <template #modal-header>Xác nhận hủy tác vụ</template>
      <p style="color: #FF0000;">Chỉ hủy tác vụ khi khách hàng không vào phòng test.<br>
        Sau khi hủy, khách hàng sẽ không thể truy cập phòng test.</p>
      <p style="color: #FF0000;font-weight: bold;" >Bạn có chắc chắn muốn hủy tác vụ?</p>
      <div class="d-flex justify-content-end">
        <b-button variant="light" style="width: 100px;color: #0a0a0a" @click="closeModal()">Đóng</b-button>
        <b-button variant="danger" class="ml-3" @click="cancelTask()" > Hủy tác vụ</b-button>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
/deep/ .my-class {
  color: red;
  font-size: 16px;
  font-weight: bold;
}
</style>