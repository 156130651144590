<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Chi tiết khách hàng</h3>
      </div>
    </div>
    <div class="card-body" v-if="currentUser.user.permission.includes(permission.XEM_DS_KH)">
      <div class="container">
        <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="Tài chính" name="first">
            <tai-chinh :contracts="contracts"
                       :student-amounts="studentAmounts"
                       :student-register-courses="studentRegisterCourses"
                       :cash="cash"
                       :total-after-voucher="totalAfterVoucher"
                       :total-paid="totalPaid"
                       :total-debt="totalDebt"
                       :total-withdraw="totalWithdraw"
            ></tai-chinh>
          </el-tab-pane>
          <el-tab-pane label="Học tập" name="second"><hoc-tap :student-register-courses="courseStudentStudies"></hoc-tap></el-tab-pane>
          <el-tab-pane label="CSKH" name="third">
            <customer-c-s-k-h :tickets="tickets"
                              :ticket-waiting="ticketWaiting"
                              :ticket-working="ticketWorking"
                              :ticket-cancel="ticketCancel"
                              :ticket-done="ticketDone"
            ></customer-c-s-k-h>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TaiChinh from "@/view/pages/customers/customerDetail/Tai-Chinh";
import HocTap from "@/view/pages/customers/customerDetail/Hoc-Tap";
import CustomerCSKH from "@/view/pages/customers/customerDetail/Customer-CSKH";
import {CUSTOMER_FINANCE, CUSTOMER_STUDY, CUSTOMER_TICKET} from "@/core/services/store/customer/customer-crm.module";
export default {
  name: "CustomerDetail",
  components: {TaiChinh, HocTap, CustomerCSKH},
  computed: {
    ...mapGetters(["currentUser"]),
  },
  created() {
    const userBestCustomerLevelId = this.$route.params.id;
    this.getCustomerFinance(userBestCustomerLevelId);
    this.getCustomerStudy(userBestCustomerLevelId);
    this.getCustomerTicket(userBestCustomerLevelId);
  },
  data() {
    return {
      activeName: 'first',
      contracts: [],
      studentAmounts: [],
      studentRegisterCourses: [],
      totalAfterVoucher: 0,
      totalPaid: 0,
      totalDebt: 0,
      totalWithdraw: 0,
      cash: 0,
      courseStudentStudies: [],
      tickets: [],
      ticketWaiting : 0,
      ticketWorking : 0,
      ticketDone : 0,
      ticketCancel : 0,
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getCustomerFinance(userBestCustomerLevelId){
      this.$store.dispatch(CUSTOMER_FINANCE, userBestCustomerLevelId).then((res) => {
        this.contracts = res.data.contracts;
        this.studentAmounts = res.data.studentAmounts;
        this.studentRegisterCourses = res.data.studentRegisterCourses;
        this.totalAfterVoucher = res.data.totalAfterVoucher;
        this.totalPaid = res.data.totalPaid;
        this.totalDebt = res.data.totalDebt;
        this.totalWithdraw = res.data.totalWithdraw;
        this.cash = res.data.cash;
      }).catch(() => {
        this.$notify({
          type: 'error',
          message: 'Có lỗi xảy ra',
          title:' Thất bại'
        });
      })
    },
    getCustomerStudy(userBestCustomerLevelId){
      this.$store.dispatch(CUSTOMER_STUDY, userBestCustomerLevelId).then((res) => {
        this.courseStudentStudies = res.data;
      }).catch(() => {
        this.$notify({
          type: 'error',
          message: 'Lấy thông tin học tập thất bại',
          title:' Thất bại'
        });
      })
    },
    getCustomerTicket(userBestCustomerLevelId){
      this.$store.dispatch(CUSTOMER_TICKET, userBestCustomerLevelId).then((res) => {
        this.tickets = res.data.tickets;
        this.ticketWaiting = res.data.ticketWaiting;
        this.ticketWorking = res.data.ticketWorking;
        this.ticketDone = res.data.ticketDone;
        this.ticketCancel = res.data.ticketCancel;
      }).catch(() => {
        this.$notify({
          type: 'error',
          message: 'Lấy thông tin chăm sóc khách hàng thất bại',
          title:' Thất bại'
        });
      })
    }

  }
}
</script>

<style scoped>

</style>
