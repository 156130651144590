<template>
  <div>
    <b-modal
        centered id="modal-create-history"
        title="Lịch sử chính sách"
        hide-footer
        size="xl"
    >
      <el-collapse @change="handleChange">
        <el-collapse-item
            v-for="(item, index) in dataHistory"
            :title="item.policy_catalog ? item.policy_catalog.name : ''"
            :name="item.policy_catalog ? item.policy_catalog.id : ''"
        >
          <div class="ql-editor table-ck" v-html="item.content"></div>
        </el-collapse-item>

      </el-collapse>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "ModalPreviewHistory",
  props: {
    dataHistory: {
      default: null
    }
  },
  mounted() {
  },
  methods: {
    handleChange() {

    }
  }
}
</script>

<style scoped>

</style>