export const levels = [
    {
        value: '1A',
        name: "1A - Nhu cầu"
    },
    {
        value: '1C',
        name: "1C - Trống"
    },
    {
        value: '1D',
        name: "1D - Hủy data"
    },
    {
        value: '2A',
        name: "2A - Có lịch hẹn"
    },
    {
        value: '2B',
        name: "2B - Delay hẹn"
    },
    {
        value: '2C',
        name: "2C - Hủy hẹn"
    },
    {
        value: '3',
        name: "3 - Tư vấn"
    },
    {
        value: '3A',
        name: "3A - Đăng ký"
    },
    {
        value: '3B',
        name: "3B - Khách hàng đến"
    },
    {
        value: '3E',
        name: "3E - Phân vân"
    },
    {
        value: '4A',
        name: "4A - Khách hàng đóng trên 1200k"
    },
    {
        value: '4B',
        name: "4B - Đóng tiền dưới 1200k"
    },
    {
        value: '4D',
        name: "4D - Rút tiền"
    },
    {
        value: '6',
        name: "6 - Đang học"
    },
    {
        value: '7',
        name: "7 - Đợi lớp"
    },
    {
        value: '8',
        name: "8 - Ngừng học"
    }
]