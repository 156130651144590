<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách khu vực</h3>
      </div>
      <div class="card-title">
        <router-link
            class="font-weight-bold font-size-3  btn btn-success"
            :to="{ name: 'area-create' }"
        >Thêm mới
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Tên khu vực</label>
            <el-input placeholder="Tên khu vực" v-model="query.keyword"></el-input>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Loại khu vực</label>
            <el-select v-model="query.type_id"
                       filterable class="w-100"
                       placeholder="Loại khu vực"
                       clearable
            >
              <el-option
                  v-for="item in types"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3 mt-8">
          <div class="d-flex justify-content-between">
            <button type="button"
                    class="btn btn-primary font-weight-bold mr-2"
                    :disabled="is_disable_search"
                    :class="{'spinner spinner-white spinner-left' : is_disable_search}"
                    @click="search"
            >
              Tìm kiếm
            </button>
          </div>
        </div>
      </div>
      <!--begin::Example-->
      <div class="example mb-10">
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th scope="col">STT</th>
              <th scope="col">Tên khu vực</th>
              <th scope="col">Phân loại</th>
              <th scope="col">Hành động</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in areas.data" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ typeFormatText[item.type_id] }}</td>
              <td>
                <router-link :to="{ name: 'area-edit', params: {id : item.id} }" title="Sửa"
                             class="btn btn-xs btn-icon mr-2 btn-outline-info"><i
                    class="fas fa-pen-nib"></i>
                </router-link>
                <a title="Xóa" @click="deleteArea(item.id)" href="javascript:"
                   class="btn btn-xs btn-icon btn-outline-danger">
                  <i class="fas fa-trash"></i>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {DELETE_AREA, GET_AREAS} from "../../../core/services/store/center/area.module";
import {typeFormatText, types} from "@/core/option/area";

const _ = require('lodash');

export default {
  components: {},
  name: "BranchIndex",
  data() {
    return {
      areas: [],
      // Paginate
      page: 1,
      last_page: 1,
      typeFormatText: typeFormatText,
      types: types,
      query: {
        keyword: '',
        type_id: ''
      },
      is_disable_search: false
    }
  },
  async mounted() {
    this.pullParamsUrl();
    await this.getList()
    await this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý  khu vực", route: 'profile-1'},
      {title: "Danh sách khu vực"}
    ]);
  },
  methods: {
    search() {
      this.pushParamsUrl();
      this.callApiGetList();
    },
    pushParamsUrl() {
      this.$router.push({
        path: '', query: {
          page: this.page,
          ...this.query
        }
      })
    },
    pullParamsUrl() {
      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
      this.query.keyword = this.$route.query.keyword ? this.$route.query.keyword : ''
      this.query.type_id = this.$route.query.type_id ? parseInt(this.$route.query.type_id) : ''
    },
    async getList() {
      await this.callApiGetList()
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({path: 'area', query: {page: this.page}})
      this.callApiGetList()
    },
    callApiGetList() {
      this.is_disable_search = true;
      this.$store.dispatch(GET_AREAS, {
        ...this.query
      }).then((res) => {
        this.areas = res.data
        this.last_page = res.data.last_page
      }).finally(() => {
        this.is_disable_search = false;
      });
    },
    deleteArea(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_AREA, id).then((res) => {
            if (res.error) {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'danger',
                solid: true
              });
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'success',
                solid: true
              });
              this.getList()
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
