<template>
  <div style="background: white; border-radius: 4px">
    <div style="padding: 20px">
      <span style="font-weight: bold; font-size: 16px">Báo cáo cổ đông</span>
    </div>
    <hr style="padding: 0; margin: 0"/>
    <div style="display: flex; align-items: end; gap: 20px; padding: 20px">
      <div style="width: 200px">
        <label for="" style="font-weight: bold">Thời gian</label>
        <el-date-picker
            class="w-100"
            v-model="date"
            type="daterange"
            placeholder="Chọn tháng"
            format="MM/yyyy"
            value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <el-button :disabled="is_export_excel" type="success" style="color: white" @click="exportExcel">Xuất Excel</el-button>
    </div>
  </div>
</template>

<script>
import {exportExcel} from "@/core/filters";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {EXPORT_SHAREHOLDER_REPORT} from "@/core/services/store/finance/branchTotalAsset.module";
import {FinanceBranchReportModel} from "@/view/pages/finance/model/FinanceTransactionModel";

export default {
  name: "FinanceShareholderIndex",
  data() {
    return {
      date: [new Date(), new Date()],
      is_export_excel: false,
      dataExport: [],
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý vốn góp cổ đông thực tế", route: '#'},
      {title: "Báo cáo cổ đông"}
    ]);
  },
  methods: {
    exportExcel() {
      this.is_export_excel = true
      const HEADER_ROW_EXPORT_SHAREHOLDER = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'date',
          value: 'Thời gian',
        },
        {
          key: 'userId',
          value: 'Mã nhân sự',
        },
        {
          key: 'name',
          value: 'Tên',
        },
        {
          key: 'position',
          value: 'Vị trí',
        },
        {
          key: 'region',
          value: 'Vùng',
        },
        {
          key: 'area',
          value: 'Khu vực',
        },
        {
          key: 'branchId',
          value: 'ID VP',
        },
        {
          key: 'branchName',
          value: 'Tên VP',
        },
        {
          key: 'percent',
          value: '% cổ phần',
        },
      ]
      this.$store.dispatch(EXPORT_SHAREHOLDER_REPORT, {
        date_range: this.date
      }).then((res) => {
        this.is_export_excel = false
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        }
        this.dataExport = res?.data?.map(item => {
          return new FinanceBranchReportModel(item?.date, item?.user_id, item?.user_name, item?.position, item?.region, item?.area, item?.branch_id, item?.branch_name, item?.percent_share)
        })
        exportExcel(HEADER_ROW_EXPORT_SHAREHOLDER, this.dataExport, 'Báo cáo cổ đông.xlsx', true)
      });
    },
  }
}
</script>

<style lang="css" scoped>

</style>