import ApiService from "@/core/services/api.service";

export const GET_DEPARTMENT = "department/get-list";
export const GET_TIMEKEEPING_LIST = "okr/list";
export const GET_USER_MAPPING = "okr/list-finger-user";
export const STORE_TIMEKEEPING = "okr/store-attendance";
export const DELETE_TIMEKEEPING = "okr/delete-attendance";
export const DETAIL_TIMEKEEPING = "okr/get-by-date";
export const DETAIL_TIMEKEEPING_FROM_USER = "okr/get-by-date-user-id";
export const IMPORT_TIMEKEEPING = "okr/import-excel";
export const PREVIEW_TIMEKEEPING = "okr/preview-import-excel";
export const EXPORT_TIMEKEEPING = "okr/export-excel";
export const SHOW_DEPARTMENT = "department/show";
export const STORE_DEPARTMENT = "department/store";
export const UPDATE_DEPARTMENT = "department/update";
export const UPDATE_STATUS_DEPARTMENT = "department/update-status";
export const DELETE_DEPARTMENT = "department/delete";
export const GET_LIST_DEPARTMENT = "department/get-list-department-active";
export const ALL_DEPARTMENT = "department/all-department";

//role department
export const GET_LIST_ROLE_DEPARTMENT = "role-department";
export const STORE_ROLE_DEPARTMENT = "store-role-department";
export const UPDATE_ROLE_DEPARTMENT = "update-role-department";
export const ACTION_ROLE_DEPARTMENT = "action-role-department";
export const ALL_ROLE_DEPARTMENT = "get-all-role-department";
export const GET_ROLE_DEPARTMENT_BY_TYPE = "get-role-department-by-type";
export const GET_ROLE_DEPARTMENT_BY_BLOCK_ID = "get-department-by-block-id";
export const REMOVE_ROLE_DEPARTMENT = "remove_role_department";

//
export const DEPARTMENT_SET_DEPARTMENT = "set-department";



export const GDVH = 19;
export const DkD = 20;
export const GDCL = 21;
export const GDDH = 22;

//type role department
export const TYPE_ROLE_DEPARTMENT = [
    {name: 'Tổng công ty', value: -1},
    {name: 'Khối', value: 1},
    {name: 'Phòng ban', value: 2},
    {name: 'Bộ phận', value: 3}
];
export const BLOCK = 1;
export const DEPARTMENT = 2;
export const PART = 3;

const state = {
    department: null
};
const getters = {
    dataRoleDepartment(state) {
        return state.department;
    },
};
const actions = {
    [GET_TIMEKEEPING_LIST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_TIMEKEEPING_LIST}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_USER_MAPPING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_USER_MAPPING}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DETAIL_TIMEKEEPING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${DETAIL_TIMEKEEPING}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DETAIL_TIMEKEEPING_FROM_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${DETAIL_TIMEKEEPING_FROM_USER}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [STORE_TIMEKEEPING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${STORE_TIMEKEEPING}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DELETE_TIMEKEEPING](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${DELETE_TIMEKEEPING}`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_TIMEKEEPING](context, payload) {
        return new Promise(resolve => {
            ApiService.query(EXPORT_TIMEKEEPING, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_DEPARTMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_DEPARTMENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [SHOW_DEPARTMENT](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${SHOW_DEPARTMENT}`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [STORE_DEPARTMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${STORE_DEPARTMENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [UPDATE_DEPARTMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${UPDATE_DEPARTMENT}/${payload.id}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [UPDATE_STATUS_DEPARTMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${UPDATE_STATUS_DEPARTMENT}/${payload.id}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DELETE_DEPARTMENT](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${DELETE_DEPARTMENT}`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_LIST_DEPARTMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LIST_DEPARTMENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [IMPORT_TIMEKEEPING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${IMPORT_TIMEKEEPING}`, payload).then(data => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_LIST_ROLE_DEPARTMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`role-department`, payload).then(data => {
                context.commit(DEPARTMENT_SET_DEPARTMENT, data);
                // resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [STORE_ROLE_DEPARTMENT](context, payload) {
        console.log(payload, 'aa')
        return new Promise((resolve, reject) => {
            ApiService.post(`role-department`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [UPDATE_ROLE_DEPARTMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`role-department/${payload.id}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [PREVIEW_TIMEKEEPING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${PREVIEW_TIMEKEEPING}`, payload).then(data => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [ACTION_ROLE_DEPARTMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${ACTION_ROLE_DEPARTMENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [ALL_ROLE_DEPARTMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${ALL_ROLE_DEPARTMENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_ROLE_DEPARTMENT_BY_TYPE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_ROLE_DEPARTMENT_BY_TYPE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_ROLE_DEPARTMENT_BY_BLOCK_ID](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${GET_ROLE_DEPARTMENT_BY_BLOCK_ID}`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [REMOVE_ROLE_DEPARTMENT](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${REMOVE_ROLE_DEPARTMENT}`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [ALL_DEPARTMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${ALL_DEPARTMENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
}
const mutations = {
    [DEPARTMENT_SET_DEPARTMENT](state, data) {
        state.department = data.data.data.length ? data.data.data : null;
    },
};
export default {
    state,
    actions,
    mutations,
    getters
};