<template>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" id="classes_create" @submit.prevent="handleSubmit(onSubmit)">
            <div class="row">
                <div class="col-md-4">
                    <div class="card card-custom gutter-b example example-compact border border-success mb-3">
                        <div class="card-header bg-success">
                            <h3 class="card-title  text-white">Thông tin cơ bản</h3>
                        </div>
                        <!--begin::Form-->
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Họ tên
                                            <span class="text-danger">*</span></label>
                                        <ValidationProvider vid="phone" name="Họ tên" rules="required"
                                                            v-slot="{ errors,classes }">
                                            <input type="text" class="form-control" placeholder="Họ tên"
                                                   v-model="customer.name" :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Số điện thoại
                                            <span class="text-danger">*</span></label>
                                        <ValidationProvider vid="phone" name="Số điện thoại"

                                                            :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                                            v-slot="{ errors,classes }">

                                            <input type="number" :class="classes" class="form-control"
                                                   v-model="customer.phone"
                                                   placeholder="Số điện thoại">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Email
                                            <span class="text-danger">*</span></label>
                                        <ValidationProvider vid="email" name="Email" rules="required|email"
                                                            v-slot="{ errors,classes }">
                                            <input type="email" class="form-control" placeholder="Email"
                                                   v-model="customer.email"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Facebook
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider vid="email" name="Email" rules=""
                                                            v-slot="{ errors,classes }">
                                            <input type="text" class="form-control" placeholder="Facebook"
                                                   v-model="customer.link_fb"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Ngày sinh
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider vid="" name="Ngày sinh" rules=""
                                                            v-slot="{ errors,classes }">
                                            <date-picker format="DD-MM-YYYY"
                                                         valueType="DD-MM-YYYY" :class="classes"
                                                         v-model="customer.ngay_sinh"
                                                         placeholder="Ngày sinh"></date-picker>
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Quê quán
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider vid="" name="" rules=""
                                                            v-slot="{ errors,classes }">
                                            <input type="text" class="form-control" placeholder="Quê quán"
                                                   v-model="customer.que_quan"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Địa chỉ
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider vid="" name="" rules=""
                                                            v-slot="{ errors,classes }">
                                            <input type="text" class="form-control" placeholder="Địa chỉ"
                                                   v-model="customer.address"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Trường
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider vid="" name="" rules=""
                                                            v-slot="{ errors,classes }">
                                            <input type="text" class="form-control" placeholder="Trường"
                                                   v-model="customer.co_quan"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Nghành
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider vid="email" name="" rules=""
                                                            v-slot="{ errors,classes }">
                                            <input type="text" class="form-control" placeholder="Nghành học"
                                                   v-model="customer.nghanh"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Level
                                            <span class="text-danger"></span></label>
                                        <multiselect v-model="cacheLevel" :options="levels"
                                                     placeholder="Chọn level"
                                                     label="name"
                                                     track-by="name"
                                                     :hideSelected="true"
                                        ></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Mã giới thiệu
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider vid="email" name="" rules=""
                                                            v-slot="{ errors,classes }">
                                            <input type="text" class="form-control" placeholder="Mã giới thiệu"
                                                   v-model="customer.ma_gioi_thieu"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Mã học viên
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider vid="email" name="" rules=""
                                                            v-slot="{ errors,classes }">
                                            <input type="text" class="form-control" placeholder="Mã học viên"
                                                   v-model="customer.ma_hoc_vien"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end::Form-->
                    </div>
                    <div class="card card-custom gutter-b example example-compact border border-warning">
                        <div class="card-header bg-warning">
                            <h3 class="card-title text-white">Thông tin phụ huynh</h3>
                        </div>
                        <!--begin::Form-->
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Họ tên
                                            <span class="text-danger"></span></label>
                                        <input type="text" class="form-control" v-model="customer.bo_me"
                                               placeholder="Họ tên">

                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Phone
                                            <span class="text-danger"></span></label>
                                        <input type="text" class="form-control" v-model="customer.sdt_phu_huynh"
                                               placeholder="Phone">

                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Quê quán
                                            <span class="text-danger"></span></label>
                                        <input type="text" class="form-control" placeholder="Quê quán"
                                               v-model="customer.que_quan_phu_huynh"
                                        >
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Nghề
                                            <span class="text-danger"></span></label>
                                        <input type="text" class="form-control" placeholder="Nghề"
                                               v-model="customer.nghe_nghiep_phu_huynh"
                                        >
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Ý kiến
                                            <span class="text-danger"></span></label>
                                        <multiselect v-model="cacheYkien" :options="yKien"
                                                     placeholder="Ý kiến"
                                                     label="name"
                                                     track-by="name"
                                                     :hideSelected="true"
                                        ></multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end::Form-->
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-custom gutter-b example example-compact border border-primary">
                        <div class="card-header bg-primary">
                            <h3 class="card-title text-white">Thông tin khách hàng tiềm năng
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Nguồn
                                            <span class="text-danger"></span></label>
                                        <multiselect v-model="cacheNguon" :options="nguon"
                                                     placeholder="Chọn nguồn"
                                                     label="name"
                                                     track-by="name"
                                                     :hideSelected="true"
                                        ></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Mối quan hệ
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider vid="" name="" rules=""
                                                            v-slot="{ errors,classes }">
                                            <input type="text" class="form-control" placeholder="Mối quan hệ"
                                                   v-model="customer.mqh"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="exampleTextarea1">Mục tiêu
                                            <span class="text-danger"></span></label>
                                        <textarea class="form-control" id="exampleTextarea1" rows="3"
                                                  v-model="customer.muc_tieu"
                                                  placeholder="Mục tiêu"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="exampleTextarea2">Trình độ
                                            <span class="text-danger"></span></label>
                                        <textarea class="form-control" id="exampleTextarea2" rows="3"
                                                  v-model="customer.trinh_do"
                                                  placeholder="Trình độ"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group mb-1">
                                        <label>Phím nóng
                                            <span class="text-danger"></span></label>
                                        <textarea class="form-control" rows="3" placeholder="Phím nóng"
                                                  v-model="customer.phim_nong"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group mb-1">
                                        <label>Lịch sử tìm kiếm
                                            <span class="text-danger"></span></label>
                                        <textarea class="form-control" rows="3" v-model="customer.lich_su_tim_kiem"
                                                  placeholder="Lịch sử tìm kiếm"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Tài chính
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider mode="lazy" vid="dieu_kien_tai_chinh" name="Học phí"
                                                            rules="required|integer|min_value:0"
                                                            v-slot="{ errors,classes }">
                                            <money v-bind="money" class="form-control"
                                                   v-model="customer.dieu_kien_tai_chinh" :class="classes"
                                            ></money>
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{ errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Người ra quyết định
                                            <span class="text-danger"></span></label>
                                        <multiselect v-model="cacheNguoiRaQuyetDinh" :options="nguoi_ra_quyet_dinh"
                                                     placeholder="Người ra quyết định"
                                                     label="name"
                                                     track-by="name"
                                                     :hideSelected="true"
                                        ></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Quỹ thời gian
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider vid="" name="" rules=""
                                                            v-slot="{ errors,classes }">
                                            <input type="text" class="form-control" placeholder="Quỹ thời gian"
                                                   v-model="customer.quy_thoi_gian"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Khoảng cách
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider vid="" name="" rules=""
                                                            v-slot="{ errors,classes }">
                                            <input type="text" class="form-control" placeholder="Khoảng cách"
                                                   v-model="customer.khoang_cach"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group mb-1">
                                        <label>Rào cản
                                            <span class="text-danger"></span></label>
                                        <textarea class="form-control" rows="3" placeholder="Rào cản"
                                                  v-model="customer.rao_can"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group mb-1">
                                        <label>Giải pháp mời hẹn
                                            <span class="text-danger"></span></label>
                                        <textarea class="form-control" rows="3" v-model="customer.giai_phap_moi_hen"
                                                  placeholder="Giải pháp mời hẹn"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group mb-1">
                                        <label>Ghi chú
                                            <span class="text-danger"></span></label>
                                        <textarea class="form-control" rows="3" placeholder="Ghi chú"
                                                  v-model="customer.note"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-custom gutter-b example example-compact border border-info">
                        <div class="card-header bg-info">
                            <h3 class="card-title text-white">Kết quả tư vấn
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Trạng thái hẹn
                                            <span class="text-danger"></span></label>
                                        <multiselect v-model="cacheTrangThaiHen" :options="trangThaiHen"
                                                     placeholder="Trạng thái hẹn"
                                                     label="name"
                                                     track-by="name"
                                                     :hideSelected="true"
                                                     selectLabel=''
                                        ></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Trạng thái tư vấn
                                            <span class="text-danger"></span></label>
                                        <multiselect v-model="cacheTrangThaiTuVan" :options="trangThaiTuVan"
                                                     placeholder="Trạng thái tư vấn"
                                                     label="name"
                                                     track-by="name"
                                                     :hideSelected="true"
                                                     selectLabel=''
                                        ></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Mã TVV (đã trừ 100000)
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider vid="" name="" rules=""
                                                            v-slot="{ errors,classes }">
                                            <input type="text" class="form-control" placeholder="Mã tư vấn viên"
                                                   v-model="customer.nguoi_tu_van"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Ngày hẹn
                                            <span class="text-danger"></span></label>
                                        <ValidationProvider vid="" name="" rules=""
                                                            v-slot="{ errors,classes }">
                                            <date-picker
                                                    format="DD-MM-YYYY HH:mm"
                                                    valueType="YYYY-MM-DD HH:mm" :class="classes"
                                                    v-model="customer.ngay_hen" type="datetime"
                                                    placeholder="Ngày hẹn"></date-picker>
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Trung tâm hẹn
                                            <span class="text-danger"></span></label>
                                        <multiselect v-model="cacheTrungTamHen" :options="centers"
                                                     placeholder="Trung tâm hẹn"
                                                     label="name"
                                                     track-by="name"
                                                     :hideSelected="true"
                                        ></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Tư vấn viên
                                            <span class="text-danger"></span></label>
                                        <input disabled type="text" class="form-control" placeholder="Tư vấn viên"
                                               v-model="customer.nguoi_tu_van"
                                        >
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>SĐT tư vấn viên
                                            <span class="text-danger"></span></label>
                                        <input disabled type="text" class="form-control" placeholder="SĐT tư vấn viên"
                                        >
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Điểm test
                                            <span class="text-danger"></span></label>
                                        <input disabled type="text" class="form-control" placeholder="Điểm test"
                                               v-model="customer.diem_test"
                                        >
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Nhận xét
                                            <span class="text-danger"></span></label>
                                        <input disabled type="text" class="form-control" placeholder="Nhận xét"
                                               v-model="customer.nhan_xet_dau_vao"
                                        >
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Phím nóng bổ sung
                                            <span class="text-danger"></span></label>
                                        <textarea class="form-control" rows="3" disabled
                                                  v-model="customer.phim_nong_bo_sung"
                                        ></textarea>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Tài chính bổ sung
                                            <span class="text-danger"></span></label>
                                        <money disabled="" v-bind="money" class="form-control"
                                               v-model="customer.dieu_kien_tai_chinh_bo_sung"
                                        ></money>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Người ra quyết định
                                            <span class="text-danger"></span></label>
                                        <multiselect disabled="" v-model="cacheLevel" :options="levels"
                                                     placeholder="Người ra quyết định"
                                                     label="name"
                                                     track-by="name"
                                                     :hideSelected="true"
                                        ></multiselect>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Vấn đề khách hàng sau tư vấn
                                            <span class="text-danger"></span></label>
                                        <textarea class="form-control" rows="3" disabled
                                                  v-model="customer.khach_hang_van_dang_con_van_de"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card card-custom gutter-b example example-compact border border-danger">
                        <div class="card-header bg-danger">
                            <h3 class="card-title text-white">Thông tin chi tiết tài khoản
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="d-flex justify-content-between font-weight-bold">
                                <p>Tổng tiền đã đóng:</p>
                                <p class="h5">{{customer.total_paid ? formatPrice(customer.total_paid, ' đ') : '0đ'}}</p>
                            </div>
                            <div class="d-flex justify-content-between font-weight-bold">
                                <p>Tổng tiền đã tiêu:</p>
                                <p class="h5">{{customer.total_spent ? formatPrice(customer.total_spent, ' đ') : '0đ'}}</p>
                            </div>
                            <div class="d-flex justify-content-between font-weight-bold">
                                <p>Số nợ còn lại:</p>
                                <p class="h5 text-danger">{{customer.no ? formatPrice(customer.no, ' đ') : '0đ'}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <ClassInfo :classes="customer.classes">
        </ClassInfo>
        <ClassSupport :customer="customer">
        </ClassSupport>

    </ValidationObserver>

</template>
<script>
    import Multiselect from 'vue-multiselect'
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {Money} from 'v-money'
    // Date picker
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    //editor
    import {VueEditor, Quill} from 'vue2-editor'
    import ImageResize from 'quill-image-resize-vue';
    import {ImageDrop} from 'quill-image-drop-module';
    import {GET_CUSTOMER_BY_ID} from "../../../core/services/store/customer/customer.module";
    import {UPDATE_CLASS,} from "../../../core/services/store/course/classes.module";
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
    import ClassInfo from "./components/ClassInfo";
    import ClassSupport from "./components/ClassSupport";

    const _ = require('lodash');

    Quill.register("modules/imageDrop", ImageDrop);
    Quill.register("modules/imageResize", ImageResize);
    export default {
        components: {
            ClassSupport,
            ClassInfo,
            Multiselect,
            DatePicker,
            VueEditor,
            Money,
        },
        name: "CustomerEdit",
        data() {
            return {
                value4: '',
                editorSettings: {
                    modules: {
                        imageDrop: true,
                        imageResize: {}
                    }
                },
                customer: {},
                is_show_room: false,
                levels: [
                    // {
                    //     value: '',
                    //     name: "--Chọn--"
                    // },
                    {
                        value: '1A',
                        name: "1A-Nhu cầu"
                    },
                    {
                        value: '1C',
                        name: "1C-Trống"
                    },
                    {
                        value: '2A',
                        name: "2A-Có lịch hẹn"
                    },
                    {
                        value: '2B',
                        name: "2B-Delay hẹn"
                    },
                    {
                        value: '2C',
                        name: "2C-Hủy hẹn"
                    },
                    {
                        value: '3',
                        name: "3-Tư vấn"
                    },
                    {
                        value: '3A',
                        name: "3A-Đăng ký"
                    },
                    {
                        value: '5A',
                        name: "5A"
                    },
                ],
                // ket_qua_tu_van
                trangThaiTuVan: [
                    {
                        value: '',
                        name: "--Chọn--"
                    },
                    {
                        value: 0,
                        name: "Khách hàng không đăng ký"
                    },
                    {
                        value: 1,
                        name: "Khách hàng đã đăng ký"
                    },

                ],
                centers: [],
                yKien: [
                    {
                        value: '',
                        name: "--Chọn--"
                    },
                    {
                        value: 'Chưa hỏi',
                        name: 'Chưa hỏi',
                    }, {
                        value: 'Đã hỏi - Không đồng ý',
                        name: 'Đã hỏi - Không đồng ý',
                    }, {
                        value: 'Đã hỏi - Đồng ý',
                        name: 'Đã hỏi - Đồng ý',
                    },
                ],
                nguon: [
                    {
                        value: '',
                        name: "--Chọn--"
                    },
                    {
                        value: 'Online Thân',
                        name: 'Online Thân',
                    },
                    {
                        value: 'Online Gần',
                        name: 'Online Gần',
                    },
                    {
                        value: 'Online Xa',
                        name: 'Online Xa',
                    },
                    {
                        value: 'Truyền thông',
                        name: 'Truyền thông',
                    },
                    {
                        value: 'Tái đăng ký',
                        name: 'Tái đăng ký',
                    },
                    {
                        value: 'Nhân trước đăng ký',
                        name: 'Nhân trước đăng ký',
                    },
                    {
                        value: 'Nhân sau đăng ký',
                        name: 'Nhân sau đăng ký',
                    },
                ],
                nguoi_ra_quyet_dinh: [
                    {
                        value: '',
                        name: "--Chọn--"
                    },
                    {
                        value: '',
                        name: "Phụ huynh"
                    },
                    {
                        value: '',
                        name: "Bạn bè"
                    },
                    {
                        value: '',
                        name: "Bản thân"
                    },
                ],
                trangThaiHen: [
                    {
                        value: 0,
                        name: "Chưa có lịch hẹn"
                    }, {
                        value: 1,
                        name: "Có lịch hẹn"
                    },
                    {
                        value: 2,
                        name: "Delay hẹn"
                    },
                    {
                        value: 3,
                        name: "Hủy hẹn"
                    },
                    {
                        value: 4,
                        name: "Hủy data"
                    },
                ],

                // Cache
                cacheLevel: [],
                cacheNguon: [],
                cacheYkien: [],
                cacheTrangThaiHen: [],
                cacheTrangThaiTuVan: [],
                cacheTrungTamHen: [],
                cacheNguoiRaQuyetDinh: [],
                money: {
                    decimal: ',',
                    thousands: ',',
                    prefix: '',
                    suffix: ' VNĐ',
                    precision: 0,
                    masked: false,
                },
            }
        },
        watch: {
            centers: function (val) {
                // Trung tâm hẹn
                this.cacheTrungTamHen = this.centers.filter((item) => {
                    if (item.id == this.customer.center_id) {
                        return item
                    }
                })
            },
            customer: function (val) {
                this.setCacheTheLoad()
            },
        },
        async mounted() {
            await this.getCustomerById()
            await this.setCacheTheLoad()
            await this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý nhân sự", route: 'profile-1'},
                {title: "Thông tin khách hàng"}
            ]);
        },
        computed: {},
        methods: {
            getCenters() {
                this.$store.dispatch(GET_CENTERS, {
                    limit: 10000
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data
                        this.addFirstSelectExample(this.centers, '__Chọn__')
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getCustomerById() {
                let id = this.$route.params.id;
                this.$store.dispatch(GET_CUSTOMER_BY_ID, id).then((res) => {
                    if (!res.error) {
                        this.customer = res.data
                        this.getCenters()
                        this.setCacheTheLoad()
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            setCacheTheLoad() {
                // level
                this.cacheLevel = this.levels.filter((item) => {
                    if (item.value == this.customer.level) {
                        return item
                    }
                })
                // y kiến
                this.cacheYkien = this.levels.filter((item) => {
                    if (item.value == this.customer.y_kien_phu_huynh) {
                        return item
                    }
                })
                // Người ra quyết định
                this.cacheNguoiRaQuyetDinh = this.levels.filter((item) => {
                    if (item.value == this.customer.nguoi_ra_quyet_dinh_hoc_tap) {
                        return item
                    }
                })
                // Trạng thái hẹn
                this.cacheTrangThaiHen = this.trangThaiHen.filter((item) => {
                    if (item.value == this.customer.is_advice) {
                        return item
                    }
                })
                // Trạng thái tư vấn
                this.cacheTrangThaiTuVan = this.trangThaiTuVan.filter((item) => {
                    if (item.value == this.customer.ket_qua_tu_van) {
                        return item
                    }
                })

            },
            /*Set state*/
            setStateBranch($event) {
                let id = $event.id;
                this.classes_obj.branch_id = id
                // SET room
                if (id != undefined) {
                    this.is_show_room = true
                    this.classes_obj.room_id = '';
                    this.getRooms()
                }
            },
            /*End*/

            onSubmit() {
                this.$store.dispatch(UPDATE_CLASS, this.classes_obj).then((res) => {
                    if (res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Cập nhật',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.$router.push({name: "class-index"}).then(() => {
                            this.$bvToast.toast(res.message, {
                                title: 'Cập nhật',
                                variant: 'success',
                                solid: true
                            });
                        })
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                });
            },
            addFirstSelectExample(obj, text) {
                obj.unshift({
                    name: text
                })
            },
        }
    }
</script>
<style scoped>
    .mx-datepicker {
        width: 100% !important;
    }

    .card-header {
        color: #C9F7F5 !important;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
