<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Xem hồ sơ ứng viên</h3>
        </div>
        <div class="card-title">
          <router-link
            class="font-weight-bold font-size-3  btn btn-secondary"
            :to="{ name: 'jobs-list' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <div class="example mb-10">
          <div class="input-group row">
            <div class="col-md-2">
              <div class="form-group">
                <label for="keyword">Tên ứng viên <span class="text-danger"></span></label>
                <el-input
                  type="text"
                  id="keyword"
                  v-model="query.keyword"
                  v-on:input="searchByName"
                  placeholder="Tên ứng viên"
                />
              </div>
            </div>
            <div class="col-md-2 d-flex flex-column justify-content-end">
              <div class="form-group">
                <el-button class="btn btn-primary" @click="searchData" :loading="isLoading">
                  Tìm kiếm
                </el-button>
              </div>
            </div>
          </div>
          <div class="example-preview table-responsive overflow-auto text-center">
            <table class="table table-vertical-center table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">Tên ứng viên</th>
                  <th scope="col">Email</th>
                  <th scope="col">Số điện thoại</th>
                  <th scope="col">Hồ sơ ứng viên</th>
                  <th scope="col">Nội dung</th>
                  <th scope="col">Ngày gửi</th>
                  <th scope="col">Hành động</th>
                </tr>
              </thead>
              <tbody v-if="isLoading">
              <tr role="row" class="b-table-busy-slot" >
                <td colspan="7" role="cell" class="text-center">
                  <div class="text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>
              </tbody>
              <tbody v-else-if="getAllCvs.length > 0">
                <tr v-for="(cv, index) in getAllCvs" :key="index">
                  <td class="">{{ cv.name }}</td>
                  <td class="">{{ cv.email }}</td>
                  <td class="">{{ cv.phone }}</td>
                  <td class="">
                    <a href="javascript:" @click="downloadCv(cv)"
                      ><span class="badge badge-success">Tải về</span></a
                    >
                  </td>
                  <td class="cv-message">
                    <div class="limit-text" :id="String(cv.id)">
                      {{ cv.message }}
                    </div>
                    <!-- <b-tooltip :target="String(cv.id)" variant="primary">{{
                      cv.message
                    }}</b-tooltip> -->
                  </td>
                  <td class="">{{ cv.created_at | formatDateTimeAsia }}</td>
                  <td class="">
                    <a href="javascript:" title="Xem chi tiết"
                                   class="btn btn-sm btn-icon mr-2 btn-outline-success" @click="viewCv(cv)"><i class="far fa-eye"></i>
                      </a>
                    <a
                      title="Xóa"
                      @click="deleteCv(cv.id)"
                      href="javascript:"
                      class="btn btn-sm btn-icon btn-outline-danger"
                      ><i class="fas fa-trash"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="font-italic" colspan="7">Chưa có hồ sơ ứng viên</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="edu-paginate mx-auto text-center">
        <paginate
          v-model="query.page"
          :page-count="total_pages"
          :page-range="3"
          :margin-pages="2"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :page-class="'page-item'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
        >
        </paginate>
      </div>
    </div>
    <recruitment-detail :showModal="showDetailCv" :cvEach="cvEach" @clicked="onclickViewCv"></recruitment-detail>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import {
  DELETE_RECRUITMENT_CV,
  GET_RECRUITMENT_CV,
} from "@/core/services/store/recruitment/recruiment.module";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "@/assets/sass/custom/recruitment/cvlist.scss";
import {ChangeToSlug} from "@/core/filters";
import RecruitmentDetail from "./RecruitmentDetail";
const _ = require("lodash");

export default {
  name: "CurriculumVitaeList",
  data() {
    return {
      query: {
        page: 1,
        keyword: "",
        job_id: this.$route.params.jobId,
      },
      /*Loader */
      isLoading: false,
      fullPage: true,
      showDetailCv: false,
      cvEach : {}
    };
  },
  components: {RecruitmentDetail},
  computed: {
    ...mapGetters(["getAllCvs", "getCvPaginate"]),
    total_pages() {
      return this.getCvPaginate?.total_pages || 1;
    },
  },
  async created() {
    this.setParams();
    this.$store.dispatch(GET_RECRUITMENT_CV, this.query);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý công việc", route: 'jobs-list'},
      { title: "Xem hồ sơ ứng viên" },
    ]);
  },
  methods: {
    viewCv(cv) {
      this.cvEach = cv;
      this.showDetailCv = true;
    },
    onclickViewCv() {
      this.showDetailCv = false;
    },
    searchByName: function() {
      this.pushParams();
    },
    deleteCv(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_RECRUITMENT_CV, id).then(() => {});
        }
      });
    },
    setParams: function() {
      this.query.page = parseInt(
        this.$route.query.page ? this.$route.query.page : 1
      );
      this.query.keyword = this.$route.query.keyword;
    },
    searchData: function() {
      this.isLoading = true;
      this.getQuery();
    },
    renderType: function(type) {
      let result = _.find(this.types, function(item) {
        return item.value === type;
      });
      return result ? result.name : "";
    },
    clickCallback(pageNum) {
      this.query.page = pageNum;
      this.pushParams();
      this.isLoading = true;
      this.$store.dispatch(GET_RECRUITMENT_CV, this.query).then((res) => {
        this.isLoading = false;
      });
    },
    pushParams: function() {
      this.$router.push({
        name: "curriculum-vitae-list-by-job",
        query: this.query,
      });
    },
    getQuery: function() {
      this.$store.dispatch(GET_RECRUITMENT_CV, this.query).then((res) => {
        this.query.page = 1;
        this.isLoading = false;
      });
    },
    onCancel: function() {
      console.log("User cancelled the loader.");
    },
    downloadCv: function(cv) {
      cv.media.forEach((file)=> {
        let afterDot = file.file_name.substring(file.file_name.indexOf("."));
        fetch(cv.cv_file)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = ChangeToSlug(cv.name) + afterDot;
          link.click();
        })
        .catch(console.error);
      })
    },
  },
};
</script>
<style lang="scss">
.cv-message {
  .limit-text:hover{
  cursor: unset;
}
}

</style>
