import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const CREATE_MECHANISM = "create_mechanism";
export const UPDATE_MECHANISM = "update_mechanism";
export const GET_MECHANISMS = "get_mechanisms";
export const DELETE_MECHANISM = "delete_mechanism";
export const GET_MECHANISM_BY_ID = "get_mechanism_by_id";

export const LIST_TYPE_CONTENT = [
    {
        id: 0,
        name: 'Nhập nội dung'
    },
    {
        id: 1,
        name: 'Nhúng liên kết website'
    }
];
const state = {};
const getters = {};
const actions = {
    [GET_MECHANISMS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('mechanisms', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_MECHANISM_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('mechanisms', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_MECHANISM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('mechanisms', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_MECHANISM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('mechanisms', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_MECHANISM](context, id) {
        return new Promise(resolve => {
            ApiService.delete('mechanisms', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
