<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="course_create" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Tạo hệ số kinh doanh
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'business-coefficient-index' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="input-name">Tên hệ số <span class="text-danger">*</span></label>
                  <ValidationProvider vid="name" name="Tên hệ số" rules="required"
                                      v-slot="{ errors,classes }">
                    <el-input id="input-name" placeholder="Nhập tên hệ số" v-model="query.name" :class="classes">
                    </el-input>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group d-flex flex-column">
                  <label for="input-coefficient">Hệ số <span class="text-danger">*</span></label>
                  <ValidationProvider vid="coefficient" name="Hệ số" rules="required|excluded:0|min_value:0.1"
                                      v-slot="{ errors,classes }">
                    <el-input-number controls-position="right" id="input-coefficient" :class="classes" class="w-50"
                           :step=".01" v-model="query.coefficient"
                           placeholder="Hệ số kinh doanh">
                    </el-input-number>
                    <div class="fv-plugins-message-container">
                      <div data-field="coefficient" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 d-flex flex-column">
                <div class="">
                  <div class="form-group">
                    <label for="input-customer-type">Đối tượng khách hàng<span class="text-danger"> *</span></label>
                    <ValidationProvider vid="type" name="Đối tượng khách hàng" rules="required"
                                        v-slot="{ errors,classes }">
                      <el-select id="input-customer-type" filterable clearable class="w-100" placeholder="Chọn đối tượng khách hàng" v-model="query.customer_type"
                      >
                        <el-option
                            v-for="item in CUSTOMER_TYPES"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          <span style="float: left">{{ item.label }}</span>
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="courses" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="">
                  <div class="form-group">
                    <label for="input-coefficient-type">Hình thức đẩy doanh số<span class="text-danger"> *</span></label>
                    <ValidationProvider vid="type" name="Hình thức đẩy doanh số" rules="required"
                                        v-slot="{ errors,classes }">
                      <el-select id="input-coefficient-type" filterable clearable class="w-100" placeholder="Chọn hình thức đẩy doanh số" v-model="query.coefficient_type"
                      >
                        <el-option
                            v-for="item in COEFFICIENT_TYPES"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          <span style="float: left">{{ item.label }}</span>
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="courses" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label for="input-description">Chi tiết<span class="text-danger"> *</span></label>
                <div class="form-group mb-1">
                  <ValidationProvider vid="description" name="Chi tiết" rules="required"
                                      v-slot="{ errors,classes }">
                              <textarea id="input-description" class="form-control" rows="6"
                                        v-model="query.description"
                                        :class="classes"
                                        placeholder="Nhập chi tiết..."></textarea>
                    <div class="fv-plugins-message-container">
                      <div data-field="description" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="input-branches">Chi nhánh áp dụng<span class="text-danger"> *</span></label>
                  <ValidationProvider vid="branches" name="Chi nhánh áp dụng" rules="required"
                                      v-slot="{ errors,classes }">
                    <el-select id="input-branches" v-model="query.branchIds"
                               filterable clearable multiple class="w-100" :class="classes" placeholder="Chi nhánh áp dụng">
                      <el-option
                          v-for="item in branches"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                        <span style="float: left">{{ item.name }}</span>
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="branches" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-success mr-2" :disabled="is_disable">
                Tạo mới
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import {CUSTOMER_TYPES , COEFFICIENT_TYPES} from "@/core/option/businessCoefficientOption";
import {CREATE_COEFFICIENT} from "../../../core/services/store/business/coefficient.module";
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import vueCheckboxToggle from 'vue-checkbox-toggle';

export default {
  components: { vueCheckboxToggle },
  name: "BusinessCoefficientCreate",
  data() {
    return {
      branches: [],
      categories: [],
      COEFFICIENT_TYPES,
      CUSTOMER_TYPES,
      is_disable: false,
      // Check show
      query: {
        name: '',
        coefficient: 1,
        status: true,
        customer_type: '',
        coefficient_type: '',
        description: '',
        branchIds: [],
      },

      // Tags
      inputVisible: false,
      inputValue: ''
    }
  },
  computed: {
    ...mapGetters(["getTotalHighlights"]),
  },
  created() {
    this.getBranches();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý hệ số kinh doanh", route: 'business-coefficient-index'},
      {title: "Tạo hệ số kinh doanh"}
    ]);
  },
  methods: {
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 1000,
        status: 1
      }).then((res) => {
        this.branches = res.data.data;
      });
    },
    async onSubmit() {
      this.is_disable = true;
      let params = {
        ...this.query,
      }
      this.$store.dispatch(CREATE_COEFFICIENT, params).then((res) => {
        this.$router.push({name: "business-coefficient-index"}).then(() => {
          this.$notify({
            title: 'Tạo mới',
            message: res.message,
            type: 'success'
          });
        })
      }).catch((e) => {
        if (e.data?.data?.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        } else {
          this.$notify({
            title: 'Thất bại',
            message: e.data.message,
            type: 'error'
          });
        }
      }).finally(() => { this.is_disable = false });
    },
  }
}
</script>
<style>
.el-input-number {
  width: 30%;
}
</style>

