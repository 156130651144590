<template>
<transition name="fade-in-up">
  <router-view></router-view>
</transition>
</template>

<script>
export default {
  name: "tournaments"
}
</script>

<style scoped>

</style>