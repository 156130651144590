<template>
  <!--begin::Card-->
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title ">
            <h3 class="card-label">
              Tạo FAQ
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'support-question-list' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <!-- Ảnh đại diện -->
          <div class="form-group row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Câu hỏi
                      <span class="text-danger">*</span></label>
                    <ValidationProvider name="Câu hỏi" vid="name" rules="required" v-slot="{ errors,classes }">
                      <input type="text" ref="name" name="name" class="form-control" :class="classes"
                             placeholder="Câu hỏi"
                             v-model="question.name"
                             @change="changeTitle"
                      >
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Order
                      <span class="text-danger">*</span></label>
                    <ValidationProvider name="Order" vid="order" rules="required" v-slot="{ errors,classes }">
                      <input type="number" step="any" ref="order" name="order" class="form-control" :class="classes" min="0"
                             placeholder="Order"
                             v-model="question.order"
                      >
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Danh mục, loại danh mục -->
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Danh mục
                  <span class="text-danger">*</span></label>
                  <ValidationProvider name="Danh mục" rules="required" v-slot="{ errors,classes }">
                <multiselect v-model="category" :options="categories"
                             placeholder="Chọn danh mục"
                             label="name"
                             track-by="name"
                             :class="classes"
                             @input="updateCategory"
                >
                </multiselect>
                <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                  </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Loại danh mục
                  <span class="text-danger">*</span></label>
                  <ValidationProvider name="Loại danh mục" rules="required" v-slot="{ errors,classes }">
                <multiselect v-model="type" :options="types"
                             placeholder="Chọn loại danh mục"
                             label="name"
                             track-by="name"
                             :class="classes"
                             @input="updateType"
                ></multiselect>
                <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                  </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="form-group mb-1 col-md-12">
              <label>Content
                <span class="text-danger">*</span></label>
              <ValidationProvider vid="content" name="Nội dung" rules="required"
                                  v-slot="{ errors,classes }">
                <vue-editor id="editor" :editorOptions="editorSettings" useCustomImageHandler
                          @image-removed="removeImage" :class="classes"
                          @image-added="handleImageAdded" v-model="question.content"></vue-editor>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                      errors[0]
                    }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>

        </div>
      </div>
      <div class="mx-auto card-header py-3">
        <div class="card-toolbar">
          <button type="submit" :disabled="is_disable" class="btn btn-success mr-2">
            Tạo mới
          </button>
        </div>
      </div>
    </form>
  </ValidationObserver>
  <!--end::Card-->
</template>
<script>
import Multiselect from 'vue-multiselect';
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {Quill, VueEditor} from 'vue2-editor'
import ImageResize from 'quill-image-resize-vue';
import {ImageDrop} from 'quill-image-drop-module';
import ApiService from "@/core/services/api.service";
import {GET_ALL_CATEGORY,GET_TYPE_BY_CATEGORY}
    from '../../../core/services/store/support/type.module';
import { CREATE_QUESTION}
    from '../../../core/services/store/support/question.module';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
const _ = require('lodash');
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
  name: "SupportQuestionCreate",
  components: {
    Multiselect,
    VueEditor,
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      question : {
      },
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      is_disable: false,
      categories: [],
      types : [],
      category:null,
      type:null,
      category_id : null,
      type_id: null

    };
  },
  computed: {
   
  },
  beforeMount() {
  },
  mounted() {
    this.getAllCategory();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách FAQ"},
      {title: "Tạo mới FAQ"}
    ]);
  },
  watch: {
    
  },
  methods: {
    removeImage: function (delta, oldContents) {
      ApiService.post('remove-image', {url: delta})
          .then(({data}) => {

          })
          .catch(err => {
            console.log(err);
          });
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("image", file);

      ApiService.post('upload-image', formData)
          .then(({data}) => {
            let url = data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch(err => {
            console.log(err);
          });
    },
    updateCategory ($event) {
        if($event) {
        this.category_id = $event.id;
        this.getTypeByCategory($event.id);
        this.type = null;
        } else {
           this.category_id = null; 
           this.types = [];
           this.type = null;
        }
      },
      updateType($event){
      },
      getTypeByCategory(category_id) {
        this.$store.dispatch(GET_TYPE_BY_CATEGORY, {category_id:category_id}).then((res) => {
            this.types = res.data;
        });
      },
      getAllCategory() {
        this.$store.dispatch(GET_ALL_CATEGORY).then((res) => {
            this.categories = res.data;
        });
      },
    changeTitle () {
      this.question.slug = this.removeAccents(this.question.name);
    },
    removeAccents(str) {
        str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return str.replaceAll(' ','-');
      },
    async onSubmit() {
        this.question.slug = this.removeAccents(this.question.name);
        this.question.category_id = this.category.id;
        this.question.type_id = this.type.id;
        this.is_disable = true;
          this.$store.dispatch(CREATE_QUESTION, this.question).then((res) => {
            this.$router.push({name: "support-question-list"}).then(() => {
            this.$notify({
              title: 'Thành công',
              message: 'Thêm mới faq thành công',
              type: 'success'
            });
          })
            this.is_disable = false;
          }).catch((e) => {
            this.$notify({
            title: 'Thất bại',
            message: e.data.message,
            type: 'success'
          });
          });
    }
  }
};
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}

.dz-progress {
  /* progress bar covers file name */
  display: none !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

