<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Nạp rút tiền</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <div class="form-group">
            <label class="d-flex justify-content-center"><strong>Tìm khách hàng</strong></label>
            <div class="d-flex justify-content-center">
              <SelectCustomer minimumInputLength="6" v-model="cache_customer" placeholder="Nhập mã học viên, SĐT"
                              method="GET" :url="getUserUrl" @input="selectCustomer"></SelectCustomer>
            </div>
          </div>
          <div v-if="is_show">
            <div class="card-title">
              <h3 class="card-label">Danh sách hợp đồng</h3>
            </div>
            <div class="example-preview table-responsive">
              <table class="table table-vertical-center">
                <thead>
                  <tr>
                    <th>Mã hợp đồng</th>
                    <th>Trạng thái</th>
                    <th>Trung tâm</th>
                    <th>Khóa học đăng ký</th>
                    <th>Thông tin tư vấn viên</th>
                    <th>Thời gian tạo</th>
                    <th style="min-width: 100px">Hành động</th>
                  </tr>
            </thead>
                <tbody>
                <tr v-for="(contract,index) in contracts" :key="index" v-show="getAllContracts.length > 0">
                  <td>{{contract.id}}</td>
                  <td>{{getTextStatus(contract)}}</td>
                  <td>{{contract?.center?.name}}</td>
                  <td class="position-relative" style="min-width: 250px">
                    <span v-if="contract.courses" class="badge badge-primary mr-2">{{ contract?.courses[0]?.name}}</span>
                    <span v-if="contract.courses && contract?.courses?.length > 1" data-toggle="tooltip"
                     data-placement="top" :title="getCourses(contract.courses)" class="badge badge-warning cursor-pointer"> +{{ contract.courses.length - 1 }} </span>
                     <span v-if="contract.is_flash_sale == 1 && contract.contract_promotion_detail_flash_sales.length > 0" class="badge badge-pink position-absolute mr-10 right-0">Flash sale</span>
                  </td>
                  <td>{{contract.consultants_user ?
                   (contract.consultants_user.name + ' - ' + contract.consultants_user.phone)
                    :( contract.customer.get_tvv ?  contract.customer.get_tvv.name + ' - ' + contract.customer.get_tvv.phone : '')}}
                  </td>
                  <td>{{ contract.created_at | formatDateTimeVietnam }}</td>
                  <!-- <td class="">Hợp đồng : <strong>{{ contract.user ? contract.user.name : '' }} -
                    {{ contract.created_at | formatDate }}</strong></td> -->
                  <td class="">
                    <a href="javascript:" title="Xem chi tiết" v-if="contract.total_debt === 0"
                       class="btn btn-sm btn-icon mr-2 btn-outline-success" @click="viewContract(contract)"><i
                        class="far fa-eye"></i>
                    </a>
                    <a href="javascript:" title="Nạp - Rút - Tặng quà" @click="showDetail(contract)"
                       class="btn btn-sm btn-icon mr-2 btn-outline-info"><i class="fas fa-hand-holding-usd"></i>
                    </a>
                  </td>
                </tr>
                <tr v-show="getAllContracts.length === 0">
                  <td colspan="2"><span class="font-weight-bolder">Chưa có hợp đồng</span> - <a href="javascript:"
                                                                                                @click="searchTvv">Tìm
                    kiếm thông tin tư vấn viên</a></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="card" v-if="showTvv">
              <div class="card-header custom-header">
                <h5>Thông tin tư vấn viên</h5>
              </div>
              <div class="card-body" style="background-color: #e4e9f2">
                <div v-if="customer">
                  <div
                      class="contact-item tvv">
                    <div class="contact-detail-name">
                      Họ và tên: <b>{{ customer.name }}</b>
                    </div>
                    <div class="contact-detail-name">
                      Số điện thoại: <b>{{ customer.phone }}</b>
                    </div>
                    <div class="contact-detail-name">
                      Văn phòng làm việc: <b>{{ customer.branch }}</b>
                    </div>
                  </div>
                </div>
                <div v-if="!customer">
                  <div
                      class="">
                    <p>Không tìm thấy tư vấn viên. Vui lòng liên hệ với trung tâm hỗ trợ.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ContractForm from-page="nap-rut"
                        :showModal="showModalContract"
                        :contract="contract"
                        :editBranch="editBranch"
                        :editName="editName"
                        :editEmail="editEmail"
                        :editPhone="editPhone"
                        :editBirthDate="editBirthDate"
                        :listRefundByBranch="listRefundByBranch"
                        @clicked="onclickContractDetail"
                        @deposit="deposit"
                        >
                      </ContractForm>
          <contract-detail :showModal="showDetailContract" :contract="contract"
                           @clicked="onclickViewContractDetail"></contract-detail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import Multiselect from 'vue-multiselect';
import SelectCustomer from "@/view/content/SelectCustomer";
import ContractForm from "@/view/pages/business/ContractForm";
import ContractDetail from "./ContractDetail";
import {GET_USER} from '@/core/services/ajaxUrl';
import {GET_CONTRACT_BY_ID, GET_CONTRACTS} from "../../../core/services/store/contract/contracts.module";
import {mapGetters} from "vuex";
import {SEARCH_TVV} from "../../../core/services/store/user/users.module";
import {LIST_REFUND_PROGRAM_BY_BRANCH} from "@/core/services/store/contract/contract-program.module";
import {CHUYEN_NHUONG} from "@/core/option/contractOption";

export default {
  name: "NapRutTangQua",
  data() {
    return {
      CHUYEN_NHUONG: CHUYEN_NHUONG,
      is_show: false,
      contract_id: '',
      center_id: '',
      user_id: '',
      showModalContract: false,
      showDetailContract: false,
      showTvv: false,
      contracts: [],
      contract: {},
      cache_customer: '',
      center_classes: [],
      courses: [],
      customer: '',
      editBranch: true,
      editName: false,
      editEmail: false,
      editPhone: true,
      editBirthDate: false,
      listRefundByBranch: []
    };
  },
  components: {
    Multiselect, SelectCustomer, ContractForm, ContractDetail
  },
  computed: {
    ...mapGetters(['getAllContracts']),
    getUserUrl: function () {
      return GET_USER
    },
    total_pages() {
      return 1;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: 'Quản lý tài chính'},
      {title: 'Nạp rút tặng quà'},
    ])
  },
  methods: {
    getTextStatus(contract){
        if (contract.is_cancel == '2') {
            return 'Đã hủy'
        }
        if (contract.total_paid > 0) {
            return 'Đã đóng tiền'
        }
        if (contract.confirm == 1) {
            return 'Đã xác nhận'
        }
        return 'Chờ xác nhận';
    },
    getStatus(contract){
        if (contract.is_cancel == '2') {
            return 2
        }
        if (contract.total_paid > 0) {
            return 3
        }
        if (contract.confirm == 1) {
            return 1
        }
        return 0;
    },
    getCourses(courses){
      let courses_name = '';
      let comma = '';
      courses.filter((course,index)=>{
        if(index !== 0) {
          if(index == 1) {
            comma = '';
          } else {
            comma = ', ';
          }
          courses_name += comma + course.name;
        }
      })
       return courses_name
    },
    getContractDetail: async function () {
      await this.$store.dispatch(GET_CONTRACT_BY_ID, this.contract_id).then((res) => {
        this.contract = res.data;
      });
    },
    clickCallback: function (pageNum) {
    },
    selectCustomer: function (value) {
      if (value) {
        this.is_show = true;
        this.user_id = value;
        this.$store.dispatch(SEARCH_TVV, {user_id: value}).then((res) => {
          this.customer = res.data;
        })
        this.$store.dispatch(GET_CONTRACTS, {
          user_id: value,
          ket_qua_tu_van: 1,
          nap_rut_tang_qua: 1
        }).then((res) => {
          this.contracts = res.data.data;
          if(this.contracts.length == 0){
            this.showTvv = true;
          }
        }).catch((errors) => {
        })
      } else {
        this.is_show = false;
        this.showTvv = false;
      }
    },
    searchTvv: function () {
      let params = {user_id: this.user_id};
      this.$store.dispatch(SEARCH_TVV, params).then((res) => {
        this.showTvv = true;
        this.customer = res.data;
      })
    },
    showDetail: async function (contract) {
      let status_contract = this.getStatus(contract);
      let branch = contract.branch ? contract.branch.id : 0;
      let center_id = contract.center_id ? contract.center_id : 0;
      let total_paid = contract ? contract.total_paid : 0;
      this.editBranch = true;
      if (branch > 0 && total_paid == 0) {
        this.editBranch = false;
      }
      this.contract_id = contract.id;
      this.center_id = contract.center_id;
      await this.getContractDetail();

      if (status_contract == 0 || status_contract == 2) {
        this.$message.warning('Không thể nạp tiền do hợp đồng chưa được xác thực');
        return;
      }

      this.showModalContract = true;
      this.getRefundProgram(branch, center_id);
    },
    viewContract: async function (contract) {
      this.contract_id = contract.id;
      this.center_id = contract.center_id;
      await this.getContractDetail();
      this.showDetailContract = true;
    },
    onclickContractDetail() {
      this.showModalContract = false;

    },
    onclickViewContractDetail() {
      this.showDetailContract = false;
    },
    deposit(){
      this.showModalContract = false;
      this.selectCustomer(this.cache_customer);
    },
    getRefundProgram(branch, center_id) {
      this.$store.dispatch(LIST_REFUND_PROGRAM_BY_BRANCH, {branch: branch, center_id: center_id})
          .then((res) => {
            this.listRefundByBranch = res.data;

          });
    }
  },
  watch: {
    getAllContracts(after, before) {
      if (after !== before) {
        this.contracts = after;
      }
    }
  },

}
</script>

<style>
.multiselect {
  width: 100% !important;
}

#select-customer {
  width: 50%;
}

.tvv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px;
  align-content: center;
}

.custom-header {
  padding: 1rem 2.25rem;
}
.badge-pink {
  color: white;
  background-color: #BB2CFF;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
