<template>
    <tr>
        <td> {{ classError.name }} </td>
        <td>{{ classError.course_name }}</td>
        <td>{{ classError.teacher_name }}</td>
        <td style="width: 200px;">
            <span 
            :class="`${ classError.current_status == 'Sắp khai giảng' ?
             'badge badge-secondary' :(classError.current_status == 'Đang học' ? 'badge badge-success':
             (classError.current_status == 'Kết thúc' ? 'badge badge-danger' : '')
             ) }`"
             >
             {{ classError.current_status }}
            </span>
        </td>
        <td>{{ classError.branch_name }}</td>
        <td>{{ classError.date_start | formatDate }}</td>
        <td>{{ classError.finish_time | formatDate }}</td>
        <td class="d-flex justify-content-center">
            <div>
            <router-link style="width: 40px;" :to="{ name: 'schedule-class',params: {id : classError.id} }"
             title="Lộ trình học" href="javascript:"
                class="btn btn-sm btn btn-outline-primary">
                <i class="fa far fa-eye p-0"></i></router-link>
            </div>

            <div>
            <router-link style="width: 40px;" :to="{ name: 'upload-data-class',params: {id : classError.id} }"
                title="Tư liệu"  href="javascript:"
                class="btn btn-sm ml-1 btn-icon btn-outline-info">
                <i class="fas fa-link"></i>
            </router-link>
            </div>
        </td>
    </tr>
</template>

<script>
export default {
    props: {
        classError: Object
    },
    data() {
    return {
    };
  },
     
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/custom/custom-teacher-edu.scss";

.mx-datepicker {
    width: 100% !important;
}

el-dialog {
    margin-top: 3vh !important;
}
</style>

