<template>
  <div>
    <b-modal
        id="modal-policy-contract"
        title="Xem chi tiết điều khoản & chính sách"
        class="centered-input"
        ref="modal-policy-contract"
        hide-footer
        size="xl"

      >
      <el-collapse>
        <el-collapse-item
            v-for="(item, index) in policy"
            :key=index
            :title="item.name ?? ''"
            :name="item.catalog_id ?? ''"
        >
            <div class="table-ck" v-html="item.content"></div>
        </el-collapse-item>
      </el-collapse>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "ModalPolicyContract",
  props: {
    policy: {
      default: {}
    }
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
