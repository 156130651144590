<template>
    <b-modal @hide="onHide" size="xl" :centered="true" :scrollable="true" ref="modal-test-incomes" hide-footer
             title="Thêm mới lịch test đầu vào" class="modal-test-incomes">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <div class="d-block">
                <form class="form" @submit.prevent="handleSubmit(saveTestIncome)">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Họ tên khách hàng <span class="text-danger">(*)</span></label>
                                        <ValidationProvider name="Họ và tên" rules="required|max:30"
                                                            v-slot="{ errors,classes }" vid="name">
                                            <input type="text" ref="name" name="name" class="form-control"
                                                   placeholder="Họ và tên học viên"
                                                   :class="classes"
                                                   v-model=income.user_name>
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{ errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group">
                                        <label>Người test
                                            <span class="text-danger">(*)</span></label>
                                        <ValidationProvider vid="tester_id" name="Người test" rules="required"
                                                            v-slot="{ errors,classes }">
                                            <el-select v-model="income.tester_id" clearable filterable
                                                       class="w-100" placeholder="Chọn người test">
                                                <el-option
                                                        v-for="item in getTesters"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id"
                                                        :class="classes"
                                                >
                                                    <span style="float: left">{{ item.name }}</span>
                                                </el-option>
                                            </el-select>
<!--                                            <section class="box-item-add" @click="openModalTester">-->
<!--                                                <span class="box-item-txt">Hoặc thêm mới</span>-->
<!--                                                <span class="icon-add"><i class="fas fa-user-plus"></i></span>-->
<!--                                            </section>-->
                                            <div class="fv-plugins-message-container">
                                                <div data-field="tester_id" data-validator="notEmpty"
                                                     class="fv-help-block">{{ errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>SĐT khách hàng
                                            <span class="text-danger">(*)</span></label>
                                        <ValidationProvider vid="user_phone" name="Số điện thoại"
                                                            :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                                            v-slot="{ errors,classes }">
                                            <input type="number" v-model="income.user_phone" :class="classes"
                                                   class="form-control" placeholder="Nhập SĐT đúng định dạng">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="user_phone" data-validator="notEmpty"
                                                     class="fv-help-block">{{ errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group">
                                        <label>Lịch test
                                            <span class="text-danger">(*)</span></label>
                                        <!--                    :disabled-date="disabledBeforeTodayAndAfterAWeek"-->
                                        <ValidationProvider name="Lịch hẹn" vid="schedule" rules="required"
                                                            v-slot="{ errors,classes }">
                                            <date-picker
                                                    type="datetime"
                                                    placeholder="Chọn lịch test"
                                                    v-model="income.schedule"
                                                    format="DD-MM-YYYY HH:mm"
                                                    valueType="YYYY-MM-DD HH:mm"
                                                    :default-value="new Date()"
                                                    :class="classes"
                                            ></date-picker>
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{ errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3 mb-6">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <ValidationProvider name="Trung tâm" rules="required"
                                                            v-slot="{ errors,classes }"
                                                            vid="center_id">
                                            <label>Trung tâm
                                                <span class="text-danger">(*)</span></label>
                                            <el-select v-model="income.center_id" clearable
                                                       placeholder="Vui lòng chọn 1 trong 2 trung tâm">
                                                <el-option
                                                        v-for="item in centers"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id"
                                                        :class="classes"
                                                >
                                                </el-option>
                                            </el-select>
                                            <div class="fv-plugins-message-container">
                                                <div data-field="zoom_id" data-validator="notEmpty"
                                                     class="fv-help-block">{{ errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <ValidationProvider name="type" rules="required"
                                                            v-slot="{ errors,classes }"
                                                            vid="center_id">
                                            <label>Hình thức test
                                                <span class="text-danger">(*)</span></label>
                                            <el-select v-model="income.type" clearable
                                                       placeholder="Vui lòng chọn hình thức test"
                                                       @input="handleType"
                                            >
                                                <el-option
                                                        v-for="item in types"
                                                        :key="item.id"
                                                        :label="item.label"
                                                        :value="item.id"
                                                        :class="classes"
                                                >
                                                </el-option>
                                            </el-select>
                                            <div class="fv-plugins-message-container">
                                                <div data-field="zoom_id" data-validator="notEmpty"
                                                     class="fv-help-block">{{ errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" v-if="income.type == 1">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <ValidationProvider name="Zoom ID" rules="required" v-slot="{ errors,classes }"
                                                            vid="zoom_id">
                                            <label>Zoom ID
                                                <span class="text-danger">(*)</span></label>
                                            <input type="text" v-model="income.zoom_id" placeholder="Nhập zoom id"
                                                   class="form-control" :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="zoom_id" data-validator="notEmpty"
                                                     class="fv-help-block">{{ errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <ValidationProvider name="Zoom password" rules="required"
                                                            v-slot="{ errors,classes }" vid="zoom_password">
                                            <label>Zoom password
                                                <span class="text-danger">(*)</span></label>
                                            <input type="text" v-model="income.zoom_password"
                                                   placeholder="Nhập zoom password" class="form-control"
                                                   :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="zoom_password" data-validator="notEmpty"
                                                     class="fv-help-block">{{ errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <footer class="modal-footer pt-5 pb-0">
                        <button type="button" class="btn btn-secondary" @click="hideModal">Đóng</button>
                        <button type="submit" class="btn btn-success" :disabled="isDisable">Thêm mới</button>
                    </footer>
                </form>
            </div>
        </ValidationObserver>
        <tester-form :showModal="showModalTester" @clicked="closeTesterModal"
                     @createSuccess="createTesterSuccess"></tester-form>
    </b-modal>
</template>
<script>
    import {CREATE_INCOME} from "@/core/services/store/test/test.module";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import TesterForm from "./TesterForm";
    import {mapGetters} from "vuex";
    import {GET_CENTERS} from "@/core/services/store/center/centers.module";

    export default {
        name: "TestIncomeForm",
        props: {
            showModal: {
                type: Boolean,
                default: () => {
                    return false
                }
            },
        },
        components: {DatePicker, TesterForm},
        data() {
            return {
                income: {
                    user_name: '',
                    user_phone: '',
                    tester_id: '',
                    zoom_id: '',
                    zoom_password: '',
                    schedule: '',
                    center_id: '',
                    type: 1
                },
                testers: [],
                showModalTester: false,
                isDisable: false,
                centers: [],
                types: [
                    {
                        id: 1,
                        label: 'Online'
                    },
                    {
                        id: 2,
                        label: 'Offline'
                    }
                ],
            }
        },
        computed: {
            ...mapGetters(['getTesters'])
        },
        watch: {
            showModal: function () {
                if (this.showModal) {
                    this.$refs['modal-test-incomes'].show();
                }
            },
        },
        mounted() {
            this.getCenters();
        },
        methods: {
            handleType() {
                this.income.zoom_id = '';
                this.income.zoom_password = '';
            },
            getCenters() {
                this.$store.dispatch(GET_CENTERS, {center_ids: [295, 516]}).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data;
                    }
                })
            },
            saveTestIncome: function () {
                this.isDisable = true;
                this.$store.dispatch(CREATE_INCOME, this.income).then((res) => {
                    this.$bvToast.toast(res.message, {
                        title: 'Tạo test đầu vào',
                        variant: 'success',
                        solid: true
                    });
                    this.isDisable = false;
                    this.hideModal();
                    Object.keys(this.income).forEach((i) => this.income[i] = null);
                    this.$emit('createSuccess');
                }).catch((res) => {
                    this.isDisable = false;
                    if (res.data?.data?.message_validate_form) {
                        this.$refs.form.setErrors(res.data.data.message_validate_form);
                    }
                })
            },
            createTesterSuccess: function (tester) {
                this.income.tester_id = tester.id;
            },
            hideModal: function () {
                this.$refs['modal-test-incomes'].hide();
            },
            onHide: function () {
                this.$emit("clicked", true);
            },
            toggleModal() {
                // when the modal has hidden
                this.$refs['modal-test-incomes'].toggle('#toggle-btn');
            },
            openModalTester: function () {
                this.showModalTester = true;
            },
            closeTesterModal: function () {
                this.showModalTester = false;
            },

        }
    }
</script>

<style>
 .box-item-add {
        position: absolute;
        font-size: 14px;
        height: 24px;
        right: 20px;
        top: 120px;
        color: #000;
        cursor: pointer;
    }

    .box-item-add i {
        border: 1px solid #0073E9;
        box-sizing: border-box;
        border-radius: 5px;
        margin-left: 5px;
        color: #0073E9;
        font-size: 13px;
        padding: 5px 6px;
    }
</style>
<style scoped>
    .el-select {
        display: unset;
    }
</style>
