<template id="gift-ticket">
    <div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Mã ticket</label>
                    <input type="text" class="form-control form-control-solid" disabled :value="ticket.id" placeholder="Mã ticket">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Danh mục</label>
                    <input type="text" class="form-control form-control-solid" disabled value="Tặng quà" placeholder="Danh mục">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Chi nhánh</label>
                    <input type="text" class="form-control form-control-solid" disabled v-model="ticket.branch.name" placeholder="Chi nhánh">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Lớp học</label>
                    <input type="text" class="form-control form-control-solid" disabled v-model="ticket.classes.name" placeholder="Lớp học">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Thời điểm lên level</label>
                    <el-date-picker type="datetime" value-format="dd-MM-YYY H:i:s" class="w-100" disabled v-model="ticket.created_at" placeholder="Chi nhánh"></el-date-picker>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Bên xử lý</label>
                    <input type="text" class="form-control form-control-solid" disabled v-model="ticket.phong_ban_xu_ly_rel.name" placeholder="Bên xử lý">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Level</label>
                    <input type="text" class="form-control form-control-solid" disabled :value="ticket.level > 0 ? levels[ticket.level] : ''" placeholder="Level">
                </div>
        </div>
      </div>
  </div>
</template>

<script>
    import {LEVEL_KID} from "../../../../../core/option/ticket";
    export default {
        name: 'GiftTicket',
        props: {
            ticket: {
                type: Object,
                default: () => { return {} }
              },
        },
        data() {
            return {
                branches: [],
                levels: LEVEL_KID
            }
        },
        mounted() {

        },
        methods: {

        }
    }
</script>