<template>
    <div class="card-body">
        <div class="row">
          <div class="example-preview table-responsive">
            <table class="table table-vertical-center table-bordered" v-loading="loading" element-loading-text="Loading...">
              <thead>
                <tr style="background-color: #F8F8F8;">
                  <th>Khâu</th>
                  <th>Phân loại</th>
                  <th>Mã lớp</th>
                  <th>Nội dung</th>
                  <th>Thời gian phát sinh lỗi</th>
                  <th>Thời gian sửa lỗi</th>
                  <th>Trạng thái</th>
                  <th>Hành động</th>
                </tr>
              </thead>
              <tbody v-if="dataWarning.data?.length > 0">
                <tr v-for="(item, index) in dataWarning.data">
                  <td>
                    <span>{{ phaseDefine[item.phase] }}</span>
                  </td>
                  <td>{{  dataDeFileErro[item?.type] ?? '' }}</td>
                  <td>{{  item?.class_name ?? '' }}</td>
                  <td>{{  item?.title ?? '' }}</td>
                  <td>{{  item?.date_appear ?? '' }}</td>
                  <td>{{  item?.date_fixed ?? '' }}</td>
                  <td>
                        <span :class="statusDefineCss[item.type_error]">{{ statusDefine[item.type_error] }}</span>
                  </td>

                  <td>
                        <div class="d-flex justify-content-center">
                            <el-button title="Xoá" @click="openDialogdelete(item.id, item.title)" v-if="[1,2].includes(item.type_error)" style="border-color: red" class="p-1 btn-delete"><i style="color: red;" class="el-icon-delete"></i></el-button>
                            <!-- item?.type == 4 là lỗi mở slide -->
                            <el-button title="Sửa" @click="openDialogedit(item.id, item.title)" v-if="item?.type == 4 && item.type_error == 1" style="border-color: green" class="p-1"><i style="color: green;" class="bi bi-shield-check"></i></el-button>
                        </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
                </tr>
              </tbody>
            </table>
          </div>
            <div class="edu-paginate mx-auto text-center">
              <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{
                total ?? 0 }}</b></div>
              <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
                :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
                :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
                :next-class="'page-link'" :page-class="'page-item'">
              </paginate>
            </div>
        </div>

        <!-- dialog Sửa lỗi -->
        <el-dialog top="30vh" width="30%" title="Cập nhật trạng thái thành đã sửa" :visible.sync="dialogEditError">
          <div style="word-break:break-word;">
            <span>Bạn có chắc chắn muốn cập nhật trạng thái lỗi “{{ dataEdit.title }}” thành <b>đã sửa lỗi</b> không?</span>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button class="rounded" size="mini" style="background-color: #CACACA; color: #000;" @click="dialogEditError = false">Hủy bỏ</el-button>
            <el-button class="rounded" size="mini" style="background-color: #5D04B0; color: #F8F8F8;" @click="editError">Xác nhận</el-button>
          </div>
        </el-dialog>

        <el-dialog top="30vh" width="30%" title="Xoá lỗi" :visible.sync="dialogDeleteError">
          <div style="word-break:break-word;">
            <span>Bạn có chắc chắn muốn xóa lỗi “{{ dataDelete.title }}” không?</span>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button class="rounded" size="mini" style="background-color: #CACACA; color: #000;" @click="dialogDeleteError = false">Hủy bỏ</el-button>
            <el-button class="rounded" size="mini" style="background-color: #5D04B0; color: #F8F8F8;" @click="deleteError">Xác nhận</el-button>
          </div>
        </el-dialog>
      </div>
</template>

<script>
    import {DELETE_ERROR, EDIT_ERROR} from "../../../../core/services/store/report-error/reportError.module";
    import { STATUS_ERROR_DEFINE, STATUS_ERROR_CSS } from "../../../../core/option/warningError";
    export default {
        name: "",
        components: {
            
        },
        props: {
        dataWarning: {
            type: Array,
            default: () => {
                return {};
            }
        },

        dataDeFileErro: {
            type: Object,
            default: () => {
                return {};
            }
        },
        last_page: {
            type: Number,
            default: () => {
                return 1;
            }
        },
        page: {
            type: Number,
            default: () => {
                return 1;
            }
        },
        loading: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        total: {
            type: Number,
            default: () => {
                return 1;
            }
        },
        },
        data() {
            return {
              page: 1,
              last_page: 1,
              total: '',
              dialogDeleteError:false,
              dialogEditError:false,
              statusDefineCss:STATUS_ERROR_CSS,
              statusDefine:STATUS_ERROR_DEFINE,
                phaseDefine: {
                  1: "Test đầu vào",
                  2: "Dạy học",
                },
                dataEdit:{
                    id: '',
                    title: ''
                },
                dataDelete:{
                    id: '',
                    title: ''
                },
            }
        },
        mounted() {
            
        }, 
      
        methods: {
          openDialogedit(id, title){
            this.dataEdit.id = id;
            this.dataEdit.title = title;
            this.dialogEditError = true;
          },
          openDialogdelete(id, title) {
            this.dataDelete.id = id;
            this.dataDelete.title = title;
            this.dialogDeleteError = true;
          },

          editError() {
            this.$store.dispatch(EDIT_ERROR, {
                id: this.dataEdit.id
            }).then((res) => {
              if (!res.error) {
                this.$notify({
                  title: 'Thành công!',
                  message: 'Sửa lỗi thành công!',
                  type: 'success'
                });
              } 
              this.dialogEditError= false;
              this.$emit('emit', 1);
            });
          },

          deleteError() {
            this.$store.dispatch(DELETE_ERROR, {
                id: this.dataDelete.id
            }).then((res) => {
              if (!res.error) {
                this.$notify({
                  title: 'Thành công!',
                  message: 'Xoá lỗi thành công!',
                  type: 'success'
                });
              }  
              this.dialogDeleteError= false;
              this.$emit('emit', 1);
            });
            },
          clickCallback(obj) {
            this.$emit('emit', obj);
          },
        }
    }
</script>

<style scoped>
  .bg-secondari {
    background-color: rgba(111, 111, 111, 1);
    color: #ffff;
  }
</style>

