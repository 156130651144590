import ApiService from "@/core/services/api.service";
import { exportData } from '@/core/services/common.service'
const _ = require('lodash');

export const GET_STUDENT_AMOUNT = "get_student_amount";
export const CREATE_STUDENT_AMOUNT = "create_student_amount";
export const GET_DETAIL = "get_detail";
export const SEND_BILL = "send_bill";
export const CONTRACT_STUDENT_AMOUNT_SEND_SMS = "contract_student_amount_send_sms";
export const EXPORT_APPLICATION_CONTRACT = "export_application_contract";
export const EXPORT_EDUTALK_PAYMENT = "export_edutalk_payment";
export const EXPORT_EDUTALK_THU_TIEN_OR_HOP_DONG_UNG = "export_edutalk_thu_tien_or_hd_ung";
export const EXPORT_EDUTALK_THU_TIEN_V2 = "export_edutalk_thu_tien";
export const EXPORT_HOP_DONG_UNG_V2 = "export_hop_dong_ung";
export const EXPORT_CUSTOMER_PAYMENT = "export_customer_payment";
export const EXPORT_CUSTOMER_PAYMENT_V2 = "export_customer_payment_v2";
export const EXPORT_STUDENT_AMOUNT_GOOGLE_SHEET = "export_student_amount_google_sheet";
export const AMOUNT_IN_OUT = "amount_in_out";
export const CREATE_REFUND = "create_contract_refund";
export const UPDATE_STUDENT_AMOUNT = "update_student_amount";
export const WRONG_TRANSFER = "wrong_transfer";
export const GET_LOG_CHANGE_DATED_PAID = "get-log-change-dated-paid";
export const GET_CHANGE_LOGS = "get-change-logs";
export const GET_CONTRACT_SOURCES = "contract-sources";
export const GET_LOG_WRONG_TRANSFER = "get-log-wrong-transfer";
export const EXPORT_FILE_DEFAULT_HOA_DON = "student-amounts/export-file-mau-danh-sach-dong-tien";
export const UPLOAD_DANH_SACH_DONG_TIEN = "student-amounts/upload-danh-sach-dong-tien";
export const EXPORT_DATA_CKNH = 'bill-bank/export'

const state = {};
const getters = {};
const actions = {
    [GET_STUDENT_AMOUNT](context, payload) {
        return new Promise(resolve => {
            ApiService.query('student-amounts', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_LOG_CHANGE_DATED_PAID](context, payload) {
        return new Promise(resolve => {
            ApiService.get('student-amounts/get-log-change-dated-paid', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_LOG_WRONG_TRANSFER](context, payload) {
        return new Promise(resolve => {
            ApiService.get('student-amounts/get-log-wrong-transfer', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_DETAIL](context, id) {
        return new Promise(resolve => {
            ApiService.get('student-amounts', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [SEND_BILL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('student-amounts/send-bill', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [CONTRACT_STUDENT_AMOUNT_SEND_SMS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('student-amounts/send-sms', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [CREATE_STUDENT_AMOUNT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('student-amounts', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    [CREATE_REFUND](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('student-amounts/createRefund/' + payload.id, payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [UPDATE_STUDENT_AMOUNT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('student-amounts', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [WRONG_TRANSFER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('student-amounts/wrong-transfer', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [AMOUNT_IN_OUT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('student-amounts/amount-in-out', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [EXPORT_APPLICATION_CONTRACT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('student-amounts/export', payload).then(({data}) => {
                let filename = "hop_dong_ung_" + new Date().getTime() / 1000 + ".xlsx";
                exportData(filename, data);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_EDUTALK_PAYMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('student-amounts/export', payload).then(({data}) => {
                let filename = "danh_sach_edutalk_thu_tien_" + new Date().getTime() / 1000 + ".xlsx";
                exportData(filename, data);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_EDUTALK_THU_TIEN_OR_HOP_DONG_UNG](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('export/edutalk-thu-tien-or-hd-ung', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_EDUTALK_THU_TIEN_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('export/edutalk-thu-tien', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_HOP_DONG_UNG_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('export/hop-dong-ung', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_CUSTOMER_PAYMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('student-amounts/export', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_CUSTOMER_PAYMENT_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('student-amounts/export-danh-sach-dong-tien', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    // GOOGLE SHEET
    [EXPORT_STUDENT_AMOUNT_GOOGLE_SHEET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('student-amounts/export-google-sheet', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    /**
     * api lấy data log đã sửa
     * @param context
     * @param id
     * @returns {Promise<unknown>}
     */
    [GET_CHANGE_LOGS](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get('student-amounts/get-change-logs', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    /**
     * api lấy danh sách nguồn khách hàng
     * @param context
     * @param params
     * @returns {Promise<unknown>}
     */
    [GET_CONTRACT_SOURCES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.query('contract-sources', params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [EXPORT_FILE_DEFAULT_HOA_DON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_FILE_DEFAULT_HOA_DON, payload).then(({ data }) => {
                let filename = "File-mau-import-thong-tin-hoa-don.xlsx";
                let anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({ response }) => {
                reject(response);
            });
        });
    },

    [UPLOAD_DANH_SACH_DONG_TIEN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPLOAD_DANH_SACH_DONG_TIEN, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [EXPORT_DATA_CKNH](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${EXPORT_DATA_CKNH}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },



};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
