<template>
  <div class="bg-white p-8">
    <div style="display: flex; justify-content: space-between; align-items: center; margin-top: -10px">
      <span style="font-size: 18px">Quản lý BOT</span>
      <el-button type="text" style="background: #1BC5BD; color: white; padding: 8px 12px" @click="isOpenCreateBot = true">Thêm BOT</el-button>
    </div>
    <hr style=""/>
    <div style="display: flex; flex-direction: column; gap: 10px">
      <div class="grid-filter">
        <div>
          <label class="font-weight-600">BOT</label>
          <el-input placeholder="Nhập tên, mô tả BOT" size="small" v-model="query.botName"/>
        </div>
        <div style="display: flex; flex-direction: column; max-width: 200px">
          <label class="font-weight-600">Trạng thái</label>
          <el-select v-model="query.status" clearable placeholder="Chọn trạng thái" size="small">
            <el-option
                v-for="item in statusOption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <el-button type="primary"
                   style="height: 32px; width: 120px; color: white; display: flex; align-items: center; justify-content: center"
                   @click="searchManagerBot">
          Tìm kiếm
        </el-button>
      </div>
    </div>
    <div class="table-responsive mt-3" style="overflow: hidden; padding-bottom: 30px">
      <table class="table" style="text-align: center;">
        <thead>
          <tr style="border: 1px solid #EBEDF3">
            <th scope="col" class="w-80px" style="background: #F8F8F8; text-align: start">Mã BOT</th>
            <th scope="col" class="min-w-240px" style="background: #F8F8F8; text-align: start">Tên BOT</th>
            <th scope="col" class="min-w-240px" style="background: #F8F8F8; text-align: start">Mô tả</th>
            <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: start">Trạng thái</th>
            <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: center;">Hành động</th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <tr class="col-12">
            <td class="text-center">
              <i class="el-icon-loading" style="position: absolute; left: 50%; margin: 15px 0"></i>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="listBot?.length > 0">
          <tr v-for="item of listBot" :key="item?.code" style="border: 1px solid #EBEDF3; border-top: 0">
            <td style="text-align: start;">{{item?.code}}</td>
            <td style="text-align: start; text-transform: uppercase">
              <span class="line-clamp-1" :title="item?.name">{{item?.name}}</span>
            </td>
            <td style="text-align: start">
              <span class="line-clamp-1" :title="item?.description">{{item?.description}}</span>
            </td>
            <td style="text-align: start">
              <span
                  style="padding: 5px 8px; border-radius: 4px; color: white"
                  :class="item.status === 1 ? 'bg-green' : 'bg-gray'"
              >{{item?.labelStatus}}</span>
            </td>
            <td>
              <button
                  class="btn btn-icon btn-xs btn-edit-custom btn-outline-warning ml-2"
                  @click="openDialogUpdateBot(item)"
              >
                <img :src="publicPath + 'media/svg/edit.svg'" style="width: 20px; height: 20px" alt=""/>
              </button>
              <button
                  class="btn btn-icon btn-xs btn-view-bot-custom btn-outline-primary ml-2"
                  @click="openListUser(item)">
                <img :src="publicPath + 'media/svg/user.svg'" style="width: 20px; height: 20px" alt=""/>
              </button>
              <button
                  v-if="item.status === null || item.status === 0"
                  class="btn btn-icon btn-xs ml-2 btn-success-custom"
                  style="border: 1px solid green"
                  @click="confirmUpdateStatusBot(item,1)"
              >
                <img :src="publicPath + 'media/svg/success.svg'" style="width: 20px; height: 20px" alt=""/>
              </button>
              <button
                  v-if="item.status === 1"
                  class="btn btn-icon btn-cancel-custom btn-xs btn-outline-danger ml-2"
                  @click="confirmUpdateStatusBot(item,0)"
              >
                <img :src="publicPath + 'media/svg/cancel.svg'" style="width: 20px; height: 20px" alt=""/>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr style="word-break: break-word; white-space: nowrap; position: relative">
            <label style="position: absolute; left: 50%; translate: -50%; top: 10px">Chưa có dữ liệu</label>
          </tr>
        </tbody>
      </table>
      <div v-if="listBot?.length > 0">
        <div class="edu-paginate mx-auto text-center mt-10 mb-5" style="display: flex; justify-content: center; align-items: center; height: 50px">
          <paginate
              v-model="page"
              :page-count="lastPage"
              :page-range="3"
              :margin-pages="1"
              :click-handler="clickCallback"
              :prev-text="'Trước'"
              :next-text="'Sau'"
              :container-class="'pagination b-pagination'"
              :pageLinkClass="'page-link'"
              :next-link-class="'next-link-item'"
              :prev-link-class="'prev-link-item'"
              :prev-class="'page-link'"
              :next-class="'page-link'"
              :page-class="'page-item'"
          >
          </paginate>
        </div>
        <div class="w-100" style="display: flex; justify-content: end; margin-top: -50px">
          Tổng số bản ghi: <span style="color: #0066FF; font-weight: 600; margin-left: 5px;">{{this.total}}</span>
        </div>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" title="Thêm mới BOT" :show-close="false" :visible.sync="isOpenCreateBot" v-if="isOpenCreateBot">
      <DialogCreateBot @closeDialog="isOpenCreateBot = false" @getListManagerBot="getListManagerBot"/>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" title="Sửa BOT" :show-close="false" :visible.sync="isUpdateBot" v-if="isUpdateBot">
      <DialogUpdateBot :record-bot="recordBot" @closeDialog="isUpdateBot = false" @getListManagerBot="getListManagerBot"/>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :title="`Kích hoạt Bot ${botNameConfirm}`" width="30%" :show-close="false" :visible.sync="isConfirmActive">
      <hr style="margin-top: -20px; margin-bottom: 20px"/>
      <span style="word-break: break-word; display: block; margin-bottom: 20px">
        Bạn có chắc chắn muốn kích hoạt
        <span style="font-weight: 600">{{ botNameConfirm }}</span> không?
      </span>
      <div style="display: flex; gap: 6px; justify-content: end">
        <el-button @click="isConfirmActive = false"
                   style="background: #E6E6E6 !important; color: #0a0a0a !important;">
          Hủy bỏ
        </el-button>
        <button v-loading="loading"
                @click="handleUpdateStatusBot(1)"
                style="background: #4D44B5 !important; color: white !important; padding: 10px 20px; border-radius: 5px">
          Xác nhận
        </button>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :title="`Ngừng hoạt động Bot ${botNameConfirm}`" width="30%" :show-close="false" :visible.sync="isConfirmUnActive">
      <hr style="margin-top: -20px; margin-bottom: 20px"/>
      <span style="word-break: break-word; display: block; margin-bottom: 20px">
        Bạn có chắc chắn muốn ngừng hoạt động
        <span style="font-weight: 600">{{ botNameConfirm }}</span> không?
      </span>
      <div style="display: flex; gap: 6px; justify-content: end">
        <el-button @click="isConfirmUnActive = false"
                   style="background: #E6E6E6 !important; color: #0a0a0a !important;">
          Hủy bỏ
        </el-button>
        <button v-loading="loading"
                @click="handleUpdateStatusBot(0)"
                style="background: #4D44B5 !important; color: white !important; padding: 10px 20px; border-radius: 5px">
          Xác nhận
        </button>
      </div>
    </el-dialog>
    <el-dialog :show-close="false" title="Danh sách người dùng" style="min-width: 710px !important;" :visible.sync="isShowListUserByBot" v-if="isShowListUserByBot">
      <hr style="margin-top: -20px; margin-bottom: 20px"/>
      <DialogListBotByUser :botId="recordBot?.id"/>
      <div style="display: flex; gap: 6px; justify-content: end">
        <el-button @click="isShowListUserByBot = false"
                   style="background: #E6E6E6 !important; color: #0a0a0a !important;">
          Hủy bỏ
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_LIST_MANAGER_BOT, UPDATE_STATUS_BOT} from "@/core/services/store/user/account-type.module";
import {BotModel, statusBot} from "@/view/pages/account-type/model/BotModel";
import DialogCreateBot from "@/view/pages/account-type/component/DialogCreateBot.vue";
import DialogUpdateBot from "@/view/pages/account-type/component/DialogUpdateBot.vue";
import DialogListBotByUser from "@/view/pages/account-type/component/DialogListBotByUser.vue";

export default {
  name: 'ManagerBot',
  components: {DialogListBotByUser, DialogUpdateBot, DialogCreateBot},
  data() {
    return {
      page: 1,
      lastPage: 1,
      perPage: 0,
      total: 0,
      query: {
        botName: this.$route.query.name || null,
        status: this.$route.query.status ? parseInt(this.$route.query.status) : null,
      },
      listBot: [],
      isOpenCreateBot: false,
      loading: true,
      statusOption: statusBot,
      recordBot: null,
      isUpdateBot: false,
      isConfirmActive: false,
      isConfirmUnActive: false,
      botNameConfirm: null,
      isShowListUserByBot: false,
      loadingConfirm: false,
      publicPath: process.env.VUE_APP_BASE_URL
    }
  },
  watch: {
    '$route.query': {
      handler(newQuery) {
        this.getParams();
        this.callApi();
      },
      immediate: true,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý BOT" }
    ]);
    this.getListManagerBot();
  },
  methods: {
    clickCallback(obj) {
      this.page = obj;
      this.getListManagerBot()
    },
    callApi() {
      const payload = {
        name: this.query.botName,
        status: this.query.status,
        page: this.page,
      }
      this.$store.dispatch(GET_LIST_MANAGER_BOT, payload).then(res => {
        this.total = res.pagination.total;
        this.page = res.pagination.current_page;
        this.lastPage = res.pagination.last_page;
        this.perPage = res.pagination.per_page;
        const data = res.data
        this.loading = false
        if (!res.error && data && data?.length > 0) {
          this.listBot = data?.map((item, index) => {
            return new BotModel(
                item?.id,
                ((this.page - 1) * this.perPage) + (index + 1) ,
                item?.api_key,
                item?.name,
                item?.user_name,
                item?.description,
                item?.status,
                item?.account_type_ids,
                item?.department_ids,
            )
          })
        }else {
          this.listBot = [];
        }
      }).catch(e => {
        console.log(e, 'error list manager')
      })
    },
    async searchManagerBot() {
      await this.filterBOT()
    },
    async getListManagerBot() {
      await this.getParams()
      this.callApi()
    },
    getParams() {
      this.query.botName = this.$route.query.name || null;
      this.query.status = this.$route.query.status ? parseInt(this.$route.query.status) : null;
    },
    openListUser(item) {
      this.isShowListUserByBot = true
      this.recordBot = item
    },
    handleUpdateStatusBot(status) {
      const payload = {
        id: this.recordBot.id,
        status: status,
      }
      this.$store.dispatch(UPDATE_STATUS_BOT, payload).then(() => {
        this.loadingConfirm = true
        if (status === 0) {
          this.isConfirmUnActive = false
          this.loadingConfirm = false
          this.$message.success('Ngừng kích hoạt thành công');
        } else {
          this.loadingConfirm = false
          this.isConfirmActive = false
          this.$message.success('Kích hoạt thành công');
        }
        this.getListManagerBot();
      }).catch(e => {
        console.log(e, 'error')
      })
    },
    confirmUpdateStatusBot(item, status) {
      this.botNameConfirm = item.name
      this.recordBot = item
      if (status === 0) {
        this.isConfirmUnActive = true
      } else {
        this.isConfirmActive = true
      }
    },
    openDialogUpdateBot(item) {
      this.isUpdateBot = true
      this.recordBot = item
    },
    filterBOT() {
      this.$router.push({
        name: 'ManagerBot',
        query: {
          name: this.query.botName,
          status: this.query.status,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.label-custom {
  padding: 4px 8px;
  border-radius: 5px;
  color: white;
}
.bg-green {
  background: #038E00;
}
.bg-gray {
  background: #706F6F;
}
.label-detail {
  font-size: 14px;
  font-weight: 600;
}
.grid-filter {
  display: flex;
  gap: 50px;
  margin-bottom: 20px;
  align-items: end;
}
.font-weight-600 {
  font-weight: 600;
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.btn-view-bot-custom:hover {
  background: #bfd4f4 !important;
}

.btn-success-custom:hover {
  background: #bcf3bc !important;
}

.btn-cancel-custom:hover {
  background: #f4b6b6 !important;
}

.btn-edit-custom:hover {
  background: #f6ebb7 !important;
}
</style>
