<template>
  <div style="margin-top: 0">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(handleCreateHonorDetail)">
        <div style="display: grid; grid-template-columns: 4fr 4fr 4fr; gap: 40px">
          <ValidationProvider name="Loại hạng mục" rules="required" v-slot="{ errors }" >
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Hạng mục <span style="color: red">*</span></span>
              <el-select v-model="query.honor_category_id" placeholder="Chọn loại hạng mục" size="small">
                <el-option
                    v-for="item in typeOptionCategory"
                    :key="item.id"
                    :label="item.category"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="date" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div style="display: grid; grid-template-columns: 4fr 4fr 4fr; gap: 40px; margin-top: 15px">
          <ValidationProvider name="Tên vinh danh" rules="required|max:20" v-slot="{ errors }" >
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Tên vinh danh <span style="color: red">*</span></span>
              <el-input size="small" v-model="query.category_name" placeholder="Nhập tên vinh danh"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider name="Mô tả vinh danh" rules="required" v-slot="{ errors,classes }">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Mô tả <span style="color: red">*</span></span>
              <el-input size="small" v-model="query.newsletter_desc" placeholder="Mô tả vinh danh"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider name="Đơn vị vinh danh" rules="required" v-slot="{ errors,classes }" >
            <div v-if="visibleDepartment" style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Đơn vị <span style="color: red">*</span></span>
              <el-input size="small" v-model="query.department" placeholder="Nhập đơn vị vinh danh"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div style="display: flex; flex-direction: column; gap: 10px; margin-top: 15px">
          <span class="page-filter__title">Tiêu đề bài viết</span>
          <el-input
              type="textarea" size="small"
              :autosize="{ minRows: 1, maxRows: 2}"
              v-model="query.newsletter_title"
              placeholder="Nhập tiêu đề bài viết"
          />
        </div>
        <div style="display: flex; flex-direction: column; gap: 10px; margin-top: 25px">
          <span class="page-filter__title">Nội dung bài viết</span>
          <el-input size="small" type="textarea" v-model="query.content" placeholder="Nhập nội dung bài viết"/>
        </div>
        <div style="display: flex; flex-direction: column; gap: 10px; margin-top: 25px">
          <span class="page-filter__title">Ảnh <span style="color: red">*</span></span>
          <div>
              <UploadVideo
                  :is_limited= true
                  :is_image= true
                  @emitUpdateFile="emitUpdateFile"
              ></UploadVideo>
          </div>
        </div>
        <div style="display: flex; justify-content: end; margin-top: 30px; gap: 10px">
          <el-button style="background: #CACACA" @click="closeDialog()">Hủy bỏ</el-button>
          <button
              :disabled="loading"
              style="background: #5D04B0; padding: 0 10px; border-radius: 5px; color: white; position:relative;"
              @click="handleCreateHonorDetail"
          >
            <i v-if="loading" class="el-icon-loading text-white float-righ"></i>
            Xác nhận
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {HonorCategoryModel} from "@/view/pages/honors/Model/honorModel";
import {CREATE_HONOR_DETAIL, GET_LIST_HONOR_CATEGORY} from "@/core/services/store/honors/honor.module";
import UploadVideo from "../../../pages/tickets/components/UploadVideo";

export default {
  components: {UploadVideo},
  props: {
    addHonorDetail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    visibleDepartment(){
      const categorySelected = this.typeOptionCategory.find((item) => item.id === this?.query?.honor_category_id)
      return categorySelected?.type ==0;
    }
  },
  mounted() {
    this.getListCategory()
  },
  data() {
    return {
      query: {
        honor_category_id: '',
        name: '',
        category_name: '',
        newsletter_desc: '',
        honor_program_id: this.$route.params.id,
        department: '',
        newsletter_title: '',
        content: '',
      },
      fileList: [],
      loading: false,
      typeOptionCategory: []
    }
  },
  methods: {
    closeDialog() {
      this.loading = false;
      this.$emit('closeDialog');
    },
    async getListCategory() {
      this.typeOptionCategory = await this.$store.dispatch(GET_LIST_HONOR_CATEGORY, {
        program_id : this.$route.params.id
      }).then(data => {
        if (data.data.length > 0) {
          return data.data.map(item => {
            return new HonorCategoryModel(
                item?.id,
                item?.category_name,
                item?.honor_category_type,
                item?.category_desc,
            )
          })
        }
        return []
      })
    },
    handleCreateHonorDetail() {
      this.loading = true;
      let payload = this.getPayload();
      this.$store.dispatch(CREATE_HONOR_DETAIL, payload).then((res) => {
        this.closeDialog()
        this.$message.success('Thêm mới vinh danh thành công')
        this.addHonorDetail = false;
        this.$emit('getListDetail');
      }).catch(() => {
        this.loading = false;
      });
    },
    emitUpdateFile(e) {
      this.fileList = e;
    },
    getPayload() {
      let formData = new FormData();
      for (let key in this.query) {
        formData.append(key, this.query[key]);
      }
      for (let i = 0; i < this.fileList.length; i++) {
        formData.append('fileList', this.fileList[i].raw);
      }
      if(this.fileList.length == 0 || this.fileList.length >= 2) {
        var textError = '';
        let count = this.fileList.length;
        if(!count) {
          textError = 'Vui lòng nhập ảnh để thêm vinh danh!'
        }else {
          textError = 'Số lượng ảnh không quá 1 ảnh!'
        }
        this.$notify({
          title: "Thất bại",
          message: textError,
          type: "error",
        });
        return false;
      }
      return formData;
    },
  }
}
</script>

<style scoped>

</style>
