<template>
	<div>
		<LoadingCreateTestInput
			v-if="status === STATUS_PENDING"
			:seconds="secondsRemaining"
		></LoadingCreateTestInput>
		<div v-if="status=== STATUS_TO_DO">
			<SuccessCreateInput
				v-if="errorCode === 0"
				@close-modal="closeModal"
			>
			</SuccessCreateInput>
			<CancelCreateInput
				v-else
				@close-modal="closeModal"
				:action="action"
			></CancelCreateInput>
		</div>
		<div v-if="status === STATUS_FAlURE">
			<OverloadCreateTestInput
				v-if="seconds === 0"
				@close-modal="closeModal"
			>
			</OverloadCreateTestInput>
			<CancelCreateInput
				v-else
				@close-modal="closeModal"
			></CancelCreateInput>
		</div>
	</div>
</template>

<script>
import LoadingCreateTestInput from "@/view/pages/test-input/components/ModalHandleItems/LoadingCreateTestInput";
import CancelCreateInput from "@/view/pages/test-input/components/ModalHandleItems/CancelCreateInput";
import OverloadCreateTestInput from "@/view/pages/test-input/components/ModalHandleItems/OverloadCreateTestInput";
import SuccessCreateInput from "@/view/pages/test-input/components/ModalHandleItems/SuccessCreateInput";
import {
	STATUS_TO_DO,
	STATUS_CANCEL,
	STATUS_PENDING,
	STATUS_FAlURE
} from "@/core/option/testInputOption";
import {GET_SPEAKING, UPDATE_SPEAKING_FAILURE} from "@/core/services/store/test/testInput.module";

export default {
	name: "ModalHandleLoading",
	components: {OverloadCreateTestInput, CancelCreateInput, LoadingCreateTestInput, SuccessCreateInput},
	props: {
		id: {
			type: Number,
			default: () => {
				return 0;
			}
		},
		action: {
			type: String,
			default: () => {
				return 'create';
			}
		},
		statusProp: {
			type: Number,
			default: () => {
				return 0;
			}
		},
		propSeconds: {
			type: Number,
			default: () => {
				return 60;
			}
		},
		isShowLoading: {
			type: Boolean,
			default: () => {
				return false;
			}
		}
	},
	watch: {
		statusProp() {
			this.status = this.statusProp;
		},
		propSeconds() {
			this.seconds = this.propSeconds;
		},
		isShowLoading(newValue, oldValue) {
			if (this.isShowLoading) {
				this.seconds = 60;
				this.getSpeaking();
			}
		},
		seconds() {
			if (this.seconds <= 1 && this.status === STATUS_PENDING) {
				this.updateFailure();
			}
		}
	},
	data() {
		return {
			okDisable: false,
			STATUS_TO_DO: STATUS_TO_DO,
			STATUS_CANCEL: STATUS_CANCEL,
			STATUS_PENDING: STATUS_PENDING,
			STATUS_FAlURE: STATUS_FAlURE,
			seconds: 60,
			errorCode: 0,
			status: 0,
		}
	},
	mounted() {
		var timer = setInterval(() => {
			// Giảm số giây còn lại đi một
			this.seconds--;
			// Kiểm tra nếu số giây là 0, dừng bộ đếm và hiển thị thông báo 'Hết giờ'
			if (this.seconds <= 0) {
				clearInterval(timer);
			}
		}, 1000); // 1000ms = 1 giây
	},
	methods: {
		updateFailure() {
			this.$store.dispatch(UPDATE_SPEAKING_FAILURE, {
				id: this.id
			}).then((data) => {
				let item = data.data;
				this.status = item.status;
			});
		},
		getSpeaking() {
			this.$store.dispatch(GET_SPEAKING, {
				id: this.id
			}).then((data) => {
				let item = data.data;
				let status = item.status;
				if (status === STATUS_PENDING) {
					setTimeout(() => this.getSpeaking(), 2000);
				} else {
					this.status = status;
					this.errorCode = item.error_code;
					this.$emit('loading-success');
				}
			}).catch((error) => {
				this.$message.error(error.message)
			})
		},
		closeModal() {
			this.$emit('close-modal');
		}
	},
	computed: {
		secondsRemaining() {
			return this.seconds.toString();
		}
	}
}
</script>

<style scoped>

</style>