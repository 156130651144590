<template>
  <div>
    <b-modal id="Modal_Edit_News" title="Cập nhật tin tức" centered hide-footer ref="Modal_Edit_News" size="xl"
             @hidden="hideModal" @show="getDetailNews">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" @submit.prevent autocomplete="off">
          <div class="row">
            <div class="col-6">
              <p>Tiêu đề <span class="text-danger">*</span></p>
              <ValidationProvider name="Tiêu đề" rules="required" v-slot="{ errors,classes }"
                                  vid="title">
                <el-input v-model="title" placeholder="Nhập tiêu đề"/>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-6">
              <p>Thứ tự hiển thị <span class="text-danger">*</span></p>
              <ValidationProvider name="Thứ tự hiển thị" rules="required" v-slot="{ errors,classes }"
                                  vid="stt">
                <el-input-number v-model="stt"/>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6 col-lg-6 col-xs-12">
              <p>Loại tin tức<span class="text-danger">*</span></p>
              <ValidationProvider name="Loại tin tức" rules="required" v-slot="{ errors,classes }"
                                  vid="title">
                <el-select v-model="type" placeholder="Nhập loại tin tức" clearable>
                  <el-option
                      v-for="item in TYPE_NEWS"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-12 mt-5">
              <p>Ảnh <span class="text-danger">*</span></p>
              <input class="file-input__input" id="file-input-add-news" type="file" name="myImage"
                     accept="image/png, image/gif, image/jpeg" @change="" multiple="multiple" @input="addPhotoNews">
              <div v-if="urlImageNews==null">
                <label class="file-input__label " for="file-input-add-news"
                >
                  <span><i class="flaticon-upload mr-2 " style="color: white"></i>Tải lên</span></label
                >
                <p class="text-dark">Kích thước ảnh tiêu chuẩn là 340 x n pixels. Với n là chiều cao tùy theo mong muốn.</p>
                <p class="text-danger" v-if="checkNullImg">
                  Ảnh là bắt buộc
                </p>
              </div>
              <div class="d-flex" v-else>
                <div class="" @mouseover="upHereNews = true"
                     @mouseleave="upHereNews = false"
                     style="width: 100px ;height: 100px ;background-size: 100px 100px ; "
                     :style="{ 'background-image': 'url(' + urlImageNews + ')' }">
                  <div v-if="upHereNews" class=" w-100 h-100 d-flex justify-content-center align-items-center"
                       style="background-color: rgba(0,0,0,0.5)">
                    <i class="flaticon2-magnifier-tool mr-3" style=" cursor: pointer ;"
                       @click="openPhotoNews = true"></i>
                    <i class="flaticon-delete" @click=" removePhotoNews()"
                       style=" cursor: pointer ;"></i>
                  </div>
                  <el-dialog
                      :visible.sync="openPhotoNews"
                      width="30%"
                      center
                      z-index="10"
                  >
                    <img :src="urlImageNews" style="width: 100% ; height: auto">
                    <span slot="footer" class="dialog-footer">
                              <el-button @click="openPhotoNews = false">Đóng</el-button></span>
                  </el-dialog>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex d-flex flex-row-reverse">
            <button type="button" class="btn btn-success ml-4" @click="handleSubmit(confirmNews)" :disabled="loading">
              <div v-if="loading" class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div v-else>
                Xác nhận
              </div>
            </button>
            <button type="button" class=" btn btn-secondary" @click="cancel()">Hủy</button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import {
  EDIT_NEWS,
  GET_COUNT_NUMBER_ORDER,
  GET_DETAIL_NEWS,
  POST_NEWS
} from "@/core/services/store/tournament/tournament.module";
import {TYPE_NEWS} from "@/core/option/tournamentOption";

export default {
  name: "ModalEditNew",
  data() {
    return {
      title: null,
      stt: null,
      type: null,
      image_news: null,
      urlImageNews: null,
      openPhotoNews: false,
      upHereNews: false,
      checkNullImg: false,
      loading: false,
      TYPE_NEWS: TYPE_NEWS,
    }
  },
  props: {
    idNews: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  methods: {
    getDetailNews() {
      this.$store.dispatch(GET_DETAIL_NEWS, this.idNews).then((data) => {
        this.title = data?.data?.title
        this.stt = data?.data?.stt
        this.type = data?.data?.type
        this.urlImageNews = data?.data?.img_new[0]?.url
        fetch(`${data?.data?.img_new[0]?.url}`)
            .then(res => res.blob())
            .then(blob => {
              const file = new File([blob], `${data?.data?.img_new[0]?.file_name}`, {
                type: `${data?.data?.img_new[0]?.mime_type}`,
              })
              this.image_news = file
            })
      })
    },
    hideModal() {
      this.title = null
      this.stt = null
      this.image_news = null
      this.urlImageNews = null
      this.openPhotoNews = false
      this.upHereNews = false
      this.checkNullImg = false
    },
    confirmNews() {
      if (this.image_news == null) {
        this.checkNullImg = true
        return
      }
      this.editNew()

    },
    editNew() {
      this.loading = true
      this.$store.dispatch(EDIT_NEWS, this.formDataNew).then((data) => {
        this.$refs['Modal_Edit_News'].hide()
        this.$notify({
          title: 'Thành công',
          message: 'Thêm mới tin tức thành công',
          type: 'success'
        });
        this.$emit("getListNews")
      }).catch((error) => {
        this.$notify({
          title: 'Lỗi',
          message: `${error.data.data.message_validate_form.stt[0] ?? 'Đã có lỗi'}`,
          type: 'error'
        });
      }).finally(() => {
        this.loading = false
      })
    },
    cancel() {
      this.$refs['Modal_Edit_News'].hide()
    },
    addPhotoNews(e) {
      if (e.target.files.length) {
        this.urlImageNews = URL.createObjectURL(e.target.files[0])
        this.image_news = e.target.files[0]
      }
    },
    removePhotoNews() {
      this.$confirm('Bạn có chắc chắn muốn xóa ảnh này không?', 'Xóa ảnh', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
        type: 'Delete'
      }).then(() => {
        this.urlImageNews = null
        this.image_news = null
        this.$notify({
          title: 'Thành công',
          message: 'Xóa ảnh thành công',
          type: 'success'
        });
      }).catch(() => {
      });
    },
  },
  computed: {
    formDataNew() {
      let formData = new FormData();
      formData.append('title', this.title);
      formData.append('stt', this.stt);
      formData.append('type', this.type);
      formData.append('tournament_id', this.$route.params.id);
      formData.append('_method', "PUT");
      formData.append('img_new', this.image_news);
      return {
        id: this.idNews,
        data: formData
      }
    }
  }
}
</script>

<style scoped>
.file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.el-select {
  width: 100%;
}

.file-input__label {
  height: 34px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 7px 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  background-color: #667CA4;
}
</style>