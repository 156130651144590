<template xmlns="http://www.w3.org/1999/html">
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          Web Gen Z
        </div>
        <div class="card-title">
          <b-button variant="success" size="md" class="float-right ml-2"
                    :disabled="loading" :class="{'spinner spinner-white spinner-right' : loading}"
                    @click="dialogCreatePromotion=true">Thêm chương trình</b-button>
        </div>
      </div>
      <div class="card-body">
        <div class="example mb-10">
          <div class="row filter_form">
            <div class="col-xxl-2 col-md-4">
              <label for=""><b>Chương trình</b></label>
              <div class="form-group w-100">
                <el-input  class="shadow-sm" type="text" v-model="query.keyword" clearable
                  placeholder="Nhập tên chương trình, mô tả"></el-input>
              </div>
            </div>
            <div class="col-xxl-2 col-md-4 mt-2">
              <label for=""><b></b></label><br>
              <div class="form-group">
                <button @click="srearch" class="btn btn-primary font-weight-bold mr-2">
                  <i class="fa fa-search"></i>Tìm kiếm</button>
              </div>
            </div>
          </div>
          <div class="example-preview table-responsive">
            <table v-loading="loading" class="table table-vertical-center table-bordered" element-loading-text="Loading...">
              <thead>
                <tr style="background-color: #F8F8F8;">
                  <th>STT</th>
                  <th>Tên chương trình</th>
                  <th>Thời gian</th>
                  <th>Mô tả</th>
                  <th>Trạng thái</th>
                  <th class="text-center">Hành động</th>
                </tr>
              </thead>
              <tbody v-if="listPromotionGenz?.length > 0 || loading">
                <tr v-for="(item, index) in listPromotionGenz">
                  <td>{{ item ? index  + from : '' }}</td>
                  <td>{{ item ? item.name : '' }}</td>
                  <td>{{ item ? item.start_date  + ' - ' + item.end_date : '' }}</td>
                  <td class="w-50">{{ item ? truncatedText(item.description) : '' }}</td>
                  <td>
                    <el-switch
                      @change="switchTypeProgram(item.id)"
                      v-model="item.is_active"
                      :active-value="1"
                      :inactive-value="0"
                      active-color="#13ce66"
                      inactive-color="#ff4949">
                    </el-switch>
                  </td>
                  <td>
                    <div style="display: flex; justify-content: center; align-items: center; gap: 10px">
                      
                        <router-link :to="{ name: 'promotion-detail', query: {promotion_id : item.id, promotion_name: item.name } }" class="btn-action"
                        >
                        <i class="el-icon-edit" style="color: #0066FF"></i>
                       </router-link>
                      <button @click="clickHandler(item); dialogDeletePromotion= true" class="btn-action out-line-danger" style="border-color: #FF0000">
                        <i class="el-icon-delete" style="color: #FF0000"></i></button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{
            totalData }}</b></div>
        </div>

        <el-dialog title="Thêm chương trình khuyến mại" width="40%"  :visible.sync="dialogCreatePromotion">
            <CreatePromotion @closeDialog="reload" :is_show="dialogCreatePromotion"></CreatePromotion>
        </el-dialog>

        <el-dialog id="dialog-delete" title="Xoá chương trình" width="35%" :visible.sync="dialogDeletePromotion">
          <hr>
            <DeletePromotion @closeDialogDelete="reload" :item="item"></DeletePromotion>
        </el-dialog>


      </div>
      <div class="edu-paginate mx-auto text-center">
        <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
          :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
          :next-class="'page-link'" :page-class="'page-item'">
        </paginate>
      </div>
    </div>
  </template>
  
  <script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import CreatePromotion from "./Components/CreatePromotion.vue";
  import DeletePromotion from "./Components/DeletePromotion.vue";
  import {GET_LIST_PROMOTION_PROGRAM_GENZ, CHANGE_TYPE_PROMOTION_PROGRAM_GENZ} from '../../../core/services/store/rewards-programs/rewardsPrograms.module';
  export default {
    name: "promotion-detail",
    components: {
      DatePicker, CreatePromotion, DeletePromotion
    },
    data() {
      return {
        page: 1,
        last_page: 1,
        loading: false,
        listPromotionGenz: [],
        item: {},
        dialogCreatePromotion: false,
        dialogDeletePromotion: false,
        query : {
          keyword: '',
          type: '',
        },
        from: '',
        id_delete : '',
        totalData: '',
        
      }
    },

    mounted() {
      this.getListPromotionGenz();
    },

    methods: {
        getListPromotionGenz() {
              this.loading = true;
              this.$store.dispatch(GET_LIST_PROMOTION_PROGRAM_GENZ, {
                page: this.page,
                keyword: this.query.keyword,
                program_type: 3
            }).then((res) => {
              if (!res.data.error) {
                this.listPromotionGenz =  res.data.data;
                this.from =res.data.from;
                this.totalData = res.data.total;
                this.last_page = res.data.last_page
              }
              this.loading = false;
          });
        },

        reload(type) {
          switch (type) {
            case 'create':
                  this.dialogCreatePromotion = false;
              break;
            case 'delete':
                this.dialogDeletePromotion = false;
            break;  
            default:
              break;
          }
          this.getListPromotionGenz();
        },

        truncatedText(item) {
          let wordLimit = 150;
          let suffix = '...';
          if (item.length > wordLimit) {
          return item.slice(0, wordLimit) + suffix;
        }
        return item
      },

        switchTypeProgram(id) {
          this.$store.dispatch(CHANGE_TYPE_PROMOTION_PROGRAM_GENZ, {
            id_program: id
          })
          .then((res) => {
            this.$notify({
                title: 'Success',
                message: 'Thay đổi trạng thái chương trình thành công!',
                type: 'success'
              });
          })
          .catch((error) => {
            this.$notify({
              title: 'Error',
              message: 'Thay đổi trạng thái chương trình thất bại!',
              type: 'error'
            });
          })
        },


        srearch() {
          this.page = 1;
          this.getListPromotionGenz();
        },

        clickCallback(obj) {
          this.page = obj
          this.getListPromotionGenz()
        },

        clickHandler(item) {
          this.item = item;
        }
      }
  }
  </script>
  
  <style>
  
  .btn-action {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 5px;
    border: 1px solid;
  }
  
  #dialog-delete .el-dialog__body {
    padding: 0px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  </style>
  
  
  