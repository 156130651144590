export class StudyDetail {
    constructor(lesson, time, attendance, rating, homeworkStatus, comments, comment_phase, type, totalLesson, studentFeedback, autoComment, attendance_reason) {
        this.lesson = lesson;
        this.time = time;
        this.attendance = this.checkAttendance(attendance);
        this.rating = rating;
        this.homework_status = homeworkStatus
        this.comments = comments
        this.comment_phase = comment_phase
        this.student_feeback = studentFeedback
        this.autoComment = autoComment
        this.type = type;
        this.attendance_reason = attendance_reason;
    }

    checkAttendance (item) {
        switch (item) {
            case 0:
                return 'Vắng mặt';
            case 1:
                return 'Có mặt';
            case 2:
                return 'Có phép';
            case 3:
                return 'Ngừng học';
            default:
                return ''
        }
    }
    checkLesson(item, type, totalLesson) {
        if(item === 1) {
            return 'Buổi khai giảng'
        }
        if(type === 'test') {
            return 'Mid Term Test'
        }
        if(item === totalLesson - 1) {
            return 'Final Test'
        }
        if (item === totalLesson) {
            return 'Buổi tổng kết'
        }
        return `Buổi ${item}`
    }
}

