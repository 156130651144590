import ApiService from "@/core/services/api.service";

const _ = require('lodash');

export const GET_QUESTION = "support/question/list";
export const GET_BY_ID = "support/question/getById";
export const CREATE_QUESTION = "support/question/create";
export const UPDATE_QUESTION = "support/question/update";
export const DELETE_QUESTION = "support/question/delete";


const state = {};
const getters = {};
const actions = {
    // Get question
    [GET_QUESTION](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${GET_QUESTION}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_BY_ID](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${GET_BY_ID}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_QUESTION](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${CREATE_QUESTION}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_QUESTION](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${UPDATE_QUESTION}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_QUESTION](context, id) {
        return new Promise(resolve => {
            ApiService.delete(DELETE_QUESTION, id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
