<template>
  <common-center-list type="web-content"></common-center-list>
</template>

<script>
import CommonCenterList from "@/view/pages/centers/CommonCenterList";
export default {
  name: "CenterIndex",
  components : { CommonCenterList },
};
</script>

