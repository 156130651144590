import ApiService from "@/core/services/api.service";

const _ = require('lodash');

export const GET_DATA_FINACE = "finace-transaction";
export const DELETE_FINACE = "finace-transaction/transaction";
export const EXPORT_FILE_DEFAULT_FINACE_TRANSACTION = "finace-transaction/export-file-default-finace-transaction";
export const IMPORT_FINACE_TRANSACTION = "finace-transaction/import-finace-transaction";

export const GET_DATA_FINACE_ACTUAL = "finace-transaction/get-data-finace-actual";
export const DELETE_FINACE_ACTUAL = "finace-transaction/delete-finace-actual";
export const EXPORT_FILE_DEFAULT_FINACE_ACTUAL = "finace-transaction/export-file-default-finace-actual";
export const IMPORT_FINACE_ACTUAL = "finace-transaction/import-finace-actual";
export const EXPORT_EXCEL_SHARED = 'finance/export-shared'


const state = {};
const getters = {};
const actions = {
    /**
     * Get categories
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_DATA_FINACE_ACTUAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_DATA_FINACE_ACTUAL}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DELETE_FINACE_ACTUAL](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${DELETE_FINACE_ACTUAL}`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_FILE_DEFAULT_FINACE_ACTUAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_FILE_DEFAULT_FINACE_ACTUAL, payload).then(({ data }) => {
                let filename = "File-mau-import-chi-van-phong.xlsx";
                let anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({ response }) => {
                reject(response);
            });
        });
    },
    [IMPORT_FINACE_ACTUAL](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(IMPORT_FINACE_ACTUAL, params).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response);
            });
        })
    },
    [GET_DATA_FINACE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('finace-transaction/transaction', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DELETE_FINACE](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${DELETE_FINACE}`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_FILE_DEFAULT_FINACE_TRANSACTION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_FILE_DEFAULT_FINACE_TRANSACTION, payload).then(({ data }) => {
                let filename = "File-mau-import-quan-ly-von-gop.xlsx";
                let anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({ response }) => {
                reject(response);
            });
        });
    },
    [IMPORT_FINACE_TRANSACTION](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(IMPORT_FINACE_TRANSACTION, params).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response);
            });
        })
    },
    [EXPORT_EXCEL_SHARED](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_EXCEL_SHARED, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
