<template>
  <div class="card card-custom gutter-b">
    <div class="card-header card-header-tabs-line">
      <div class="card-toolbar">
        <ul class="nav nav-tabs nav-bold nav-tabs-line">
          <li class="nav-item" @click="activeTab(1)">
            <a class="nav-link" v-bind:class="{active : tab_active1}" data-toggle="tab"
               href="#kt_tab_pane_1_4">
              <span class="nav-icon"><i class="flaticon2-user"></i></span>
              <span class="nav-text">Sửa khách hàng</span>
            </a>
          </li>
          <li class="nav-item" @click="activeTab(2)">
            <a class="nav-link" v-bind:class="{active : tab_active2}" data-toggle="tab"
               href="#kt_tab_pane_2_4">
              <span class="nav-icon"><i class="flaticon-edit"></i></span>
              <span class="nav-text">Đổi mật khẩu</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <div class="tab-content">
        <div class="tab-pane fade" v-bind:class="{show : show_active_1, active :show_active_1}" role="tabpanel"
             aria-labelledby="kt_tab_pane_1_4">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form class="form" @submit.prevent="handleSubmit(onSubmit)">
              <div class="card card-custom">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-4 ">
                      <div class="form-group">
                        <label>Họ tên
                          <span class="text-danger">*</span></label>
                        <ValidationProvider name="Họ tên" rules="required"
                                            v-slot="{ errors,classes }">
                          <input type="text" ref="name" name="name" class="form-control"
                                 :class="classes"
                                 placeholder="Họ tên"
                                 v-model=user.name>
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty"
                                 class="fv-help-block">{{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="form-group">
                        <label>Email
                          <span class="text-danger">*</span></label>
                        <ValidationProvider name="Email" rules="required|email"
                                            v-slot="{ errors,classes }">
                          <input type="email" name="email" class="form-control"
                                 placeholder="Email"
                                 :class="classes"
                                 v-model=user.email
                                  disabled>
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty"
                                 class="fv-help-block">{{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="form-group">
                        <label>Số điện thoại
                          <span class="text-danger">*</span></label>
                        <ValidationProvider name="Số điện thoại" rules="required"
                                            v-slot="{ errors,classes }">
                          <input type="number" name="phone" class="form-control"
                                 placeholder="Số điện thoại"
                                 :class="classes"
                                 v-model=user.phone disabled>
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty"
                                 class="fv-help-block">{{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 ">
                      <div class="form-group">
                        <label>Chọn giới tính
                          <span class="text-danger"></span></label>
                        <multiselect v-model="user.profile.gender" :options="gender"
                                     :searchable="true"
                                     :close-on-select="true"
                                     :show-labels="true" placeholder="Chọn giới tính"
                        ></multiselect>
                      </div>
                      <!--end::Form-->
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Ngày tháng năm sinh
                          <span class="text-danger"></span></label>
                        <date-picker v-model="user.profile.birthday"
                                     format="DD-MM-YYYY"  valueType="YYYY-MM-DD"></date-picker>
                      </div>
                      <!--end::Form-->
                    </div>
                    <div class="col-md-4">
                      <div class="form-group mb-1">
                        <label>Địa chỉ
                          <span class="text-danger"></span></label>
                        <textarea class="form-control" rows="3"
                                  v-model="user.profile.address"></textarea>
                      </div>
                      <!--end::Form-->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>
                          <span class="text-danger"></span></label>
                        <div class="col-9 col-form-label">
                          <div class="checkbox-inline">
                            <label class="checkbox checkbox-success">
                              <input type="checkbox" name="Checkboxes5"
                                     v-model="user.status">
                              <span></span>Kích hoạt</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Thông tin tài khoản ngân hàng-->
                <div class="separator separator-dashed my-5"></div>
                <div class="card-header">
                  <div class="card-title align-items-start flex-column">
                    <button type="submit" class="btn btn-success mr-2">
                      Cập nhật
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="tab-pane fade" v-bind:class="{show:show_active_2 , active : show_active_2}" role="tabpanel"
             aria-labelledby="kt_tab_pane_2_4">
          <!--Đổi mật khẩu-->
          <div class="card card-custom">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Mật khẩu mới
                      <span class="text-danger">*</span></label>
                    <ValidationProvider name="Mật khẩu" rules="required|min:6"
                                        v-slot="{ errors,classes }">
                      <input name="new_password" type="password" class="form-control"
                             placeholder="Mật khẩu"
                             v-model="user.password" :class="classes">
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Nhập lại mật khẩu
                      <span class="text-danger">*</span></label>
                    <ValidationProvider name="Xác nhận mật khẩu"
                                        rules="required|min:6|confirmed:Mật khẩu"
                                        v-slot="{ errors,classes }">
                      <input name="password_confirmation" type="password" class="form-control"
                             placeholder="Xác thực mật khẩu" v-model="user.confirm_password"
                             :class="classes">
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-header py-3 float-left">
            <div class="card-title align-items-start flex-column">
            </div>
            <div class="card-toolbar">
              <button type="button" class="btn btn-success mr-2" @click="changePassword">
                Đổi
              </button>
              <!--<button type="reset" class="btn btn-secondary" @click="cancel()">-->
              <!--Quay lại-->
              <!--</button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--begin::Card-->
  <!--end::Card-->
</template>
<script>
import {mapGetters} from "vuex";
import {provinces} from "../../../../assets/js/components/provinces";
import {banks} from "../../../../assets/js/components/banks";
import {branchs} from "../../../../assets/js/components/branchs";
import {
  SEARCH_BY_MA_CAP_TREN,
  GET_USER_BY_ID,
  UPDATE_STAFF,
  CHANGE_PASSWORD, UPDATE_CUSTOMER,
} from "../../../../../src/core/services/store/user/users.module";
import Multiselect from 'vue-multiselect'
import BankAccountEdit from "../../user/BankAccountEdit";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Select2Ajax from "../../../content/Select2Ajax";
import Select2 from "../../../content/Select2";
import {GET_LIST_PERMISSION} from "../../../../core/services/ajaxUrl";
import Swal from "sweetalert2";
import {GET_ACCOUNT_TYPE, GET_POSITIONS} from "../../../../core/services/store/user/account-type.module";
import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";


export default {
  name: "Edit-User",
  components: {
    Multiselect,
    DatePicker,
    Select2,
    Select2Ajax
  },
  data() {
    return {
      tab_active1: true,
      tab_active2: false,
      show_active_1: true,
      show_active_2: false,
      user: {
        uuid: '',
        name: '',
        phone: "",
        email: "",
        email_verified_at: "",
        status: true,
        parent_id: "",
        remember_token: "",
        password: "",
        old_password: "",
        confirm_password: "",
        positions: [],
        profile: {
          uuid: "",
          ma_gioi_thieu: "",
          gioithieu_id: "",
          gender: 'Nam',
          address: "",
          birthday: "",
          start_to_work: "",
          loai_tai_khoan: "",
          chi_nhanh_id: "",
          trung_tam_id: "",
          xac_thuc_sdt: "",
          thoi_gian_nghi_viec: "",
          account_type_id: ''
        },
        bank_accounts: [],
      },
      /*Data default*/
      isLoadingBoss: false,
      keyword_search_boss_code: '',
      headquarters: [
        {
          id: 1,
          name: 'Hà Nội'
        },
        {
          id: 2,
          name: 'Thanh Hóa'
        },
        {
          id: 3,
          name: 'Hồ chí mình'
        },
      ],
      centers: [
        {
          id: 1,
          name: '70 Bờ Sông Sét',
        },
        {
          id: 2,
          name: '120 Đê La Thành',
        }
      ],
      status: {
        0: 'Không hoạt động',
        1: 'Đang hoạt động',
        2: "Nghỉ việc"
      },
      gender: [
        'Nam', 'Nữ', 'Khác'
      ],
      boss_type_account: {
        1: 'Sale Leader',
        2: 'Sale Member'
      },
      positions: [],
      provinces: [],
      banks: [],
      maCapTren: [],
      branchs: {},
      /*Cache */
      cacheCenter: [],
      cacheHeadquarters: [],
      cacheAccountType: [],
      cachePosition: [],
      cacheBank: [],
      accountType: [],
      cacheMaCapTren: [],
      components: [
        'Autocompletes', 'Comboboxes', 'Forms', 'Inputs', 'Overflow Buttons', 'Selects', 'Selection Controls', 'Sliders', 'Textareas', 'Text Fields',
      ],
    };
  },
  computed: {
    getPermissionUrl: function () {
      return GET_LIST_PERMISSION
    },
    ...mapGetters(["currentUserAccountInfo", 'roles'])
  },
  mounted() {
    this.provinces = provinces
    this.banks = banks
    this.branchs = branchs
    this.getPositions()
    this.getAccountType()
    this.getUserById()
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý nhân sự", route: 'profile-1'},
      {title: "Chỉnh sửa tài khoản khách hàng"}
    ]);
  },

  methods: {
    /*Thêm account*/
    addAccount() {
      this.user.bank_accounts.push({
        enable_branch_bank: false,
        owner_name: "",
        ngan_hang: "",
        chi_nhanh: "",
        stk: "",
        loai_tai_khoan: "",
      })
    },
    /*Xóa account*/
    minusAccount(index) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          if (index > 0) {
            this.user.bank_accounts.splice(index, 1)
          }
        }
      });
    },
    /*Nếu là ngân hàng agribank thì cho chọn thành phố vào chi nhánh*/
    updateStateCenter(obj) {
      let id = obj.id
      this.user.profile.trung_tam_id = id
    },
    updateStateHeadquarters(obj) {
      let id = obj.id
      this.user.profile.chi_nhanh_id = id
    },
    updateStateAccountType(obj) {
      let id = obj.id
      this.user.profile.account_type_id = id
    },
    updateStatePositions($event) {
      this.user.user_positions = []
      $event.map((value) => {
        this.positions.map((v) => {
          if (value.position == v.position) {
            this.user.user_positions.push({
              position: v.id
            })
          }
        })
      })
    },
    updateStateMacaptren(obj) {
      let id = obj.id
      this.user.parent_id = id
    },
    updateStateBank(obj) {
      let id = obj.id
      this.user.bank_accounts.ngan_hang = id
    },
    getAccountType() {
      this.$store.dispatch(GET_ACCOUNT_TYPE, {
        limit: 1000,
      }).then((res) => {
        if (!res.error) {
          this.accountType = res.data.data
        }
      });
    },
    getPositions() {
      this.$store.dispatch(GET_POSITIONS, {}).then((res) => {
        if (!res.error) {
          this.positions = res.data
        }
      });
    },
    searchUserByBossCode(query) {
      this.isLoadingBoss = true;
      if (query.length > 0) {
        this.$store.dispatch(SEARCH_BY_MA_CAP_TREN, {
          search_boss: query,
          limit: 100,
        }).then((res) => {
          if (!res.error) {
            this.maCapTren = res.data.data
            this.isLoadingBoss = false;
          }
        }).catch((error) => {
          console.log(error)
          this.isLoadingBoss = false;
        });
      } else {
        this.isLoadingBoss = false;
      }
    },
    onSubmit() {
      this.$store
          .dispatch(UPDATE_CUSTOMER, this.user)
          .then((res) => {
            if (!res.error) {
              this.$router.push({name: "user-list"}).then(() => {
                this.$bvToast.toast(res.message, {
                  title: 'Sửa khách hàng',
                  variant: 'success',
                  solid: true
                });
              })
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Sửa khách hàng',
                variant: 'danger',
                solid: true
              });
            }
          }).catch((e) => {
        this.$bvToast.toast('Có lỗi xảy ra', {
          title: 'Sửa khách hàng',
          variant: 'danger',
          solid: true
        });
      });
    },
    getUserById() {
      let id = this.$route.params.id;
      if (id) {
        this.$store.dispatch(GET_USER_BY_ID, id).then((res) => {
          if (!res.error) {
            let user = res.data
            this.user = user
            this.handleCache(user)
          }
        });
      }
    },
    handleCache(user) {
      this.cacheHeadquarters = this.headquarters.filter((value) => {
        if (user.profile.chi_nhanh_id == value.id) {
          return value
        }
      })
      this.cacheAccountType = user.profile.account_type
      this.cacheMaCapTren = user.user_boss
      user.user_positions.map((value) => {
        this.positions.map((v) => {
          if (value.position == v.id) {
            value.position = v.position
          }
        })
      })
      this.cachePosition = user.user_positions
    },
    activeTab(n) {
      if (n == 1) {
        this.tab_active1 = true
        this.tab_active2 = false
        this.show_active_1 = true
        this.show_active_2 = false
      } else {
        this.tab_active1 = false
        this.tab_active2 = true
        this.show_active_1 = false
        this.show_active_2 = true
      }
    },
    changePassword() {
      let id = this.$route.params.id;

      this.$store.dispatch(CHANGE_PASSWORD, {
        id: id,
        old_password: this.user.old_password,
        new_password: this.user.password,
        confirm_password: this.user.confirm_password
      }).then((res) => {
        if (!res.error) {
          this.$router.push({name: "user-list"}).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Đổi mật khẩu',
              variant: 'success',
              solid: true
            });
          })
        } else {
          this.$bvToast.toast(res.message, {
            title: 'Đổi mật khẩu',
            variant: 'danger',
            solid: true
          });
        }
      });
    }
  },
};
</script>
<style scoped>
.mx-datepicker {
  width: 100% !important;
}

.add-bank-account:hover i, .add-bank-account:hover .text-muted {
  color: #1BC5BD !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>

