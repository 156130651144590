export const STATUS = {
    0: 'Ẩn',
    1: 'Hiển thị',
}
export const STATUS_ACTIVE = [
    {
        id: 0,
        name: "Ẩn"
    },
    {
        id: 1,
        name: "Hiển thị"
    }
]
export const STATUS_CSS = {
    0: 'badge badge-danger',
    1: 'badge badge-primary',
}