<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        Danh sách Q&A
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="form-group col-md-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text line-height-0 py-0">
                  <span class="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000" fill-rule="nonzero"
                              opacity="0.3"></path>
                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000"
                              fill-rule="nonzero"></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <input type="text" class="form-control" placeholder="Họ tên" v-model="formSearch.name" aria-describedby="basic-addon2" @keyup="handleFormSearch()">
            </div>
          </div>
          <div class="form-group col-md-3">
            <div class="form-group">
              <el-select v-model="formSearch.is_reply" filterable clearable class="w-100" placeholder="Trạng thái" @change="handleFormSearch()">
                <el-option
                        v-for="item in status"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id"
                >
                  <span style="float: left">{{ item.value }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th style="min-width: 50px">Mã</th>
              <th style="min-width: 150px">Loại</th>
              <th>Đối tượng hỏi</th>
              <th style="min-width: 200px">Người hỏi</th>
              <th>Thời gian</th>
              <th>Câu hỏi</th>
              <th style="min-width: 75px">Trả lời</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="data.length > 0" v-for="item in data">
              <td>{{ item.id }}</td>
              <td>{{ item.type_text }}</td>
              <td>{{ item.doi_tuong_hoi }}</td>
              <td>{{ item.user_name }}</td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.question }}</td>
              <td>
                <a v-if="item.is_reply == 1" title="Trả lời" @click="showModal(item)" href="javascript:" class="btn btn-success btn-sm btn-icon" style="width: 85px; height: 25px">
                  <div>
                    <i class="fa fa-check-circle mr-1" style="font-size: 13px;"></i> Đã trả lời
                  </div>
                </a>
                <a v-else title="Trả lời" @click="showModal(item)" href="javascript:" class="btn btn-info btn-sm btn-icon" style="width: 85px; height: 25px">
                  <div>
                    <i class="fa fa-pen-nib mr-2" style="font-size: 13px;"></i> Trả lời
                  </div>
                </a>
              </td>
            </tr>
            <tr v-if="data.length == 0">
              <td colspan="7" class="event-title no-data">Chưa có dữ liệu</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
              v-model="page"
              :page-count="last_page"
              :page-range="3"
              :margin-pages="1"
              :click-handler="clickCallback"
              :prev-text="'Trước'"
              :next-text="'Sau'"
              :container-class="'pagination b-pagination'"
              :pageLinkClass="'page-link'"
              :next-link-class="'next-link-item'"
              :prev-link-class="'prev-link-item'"
              :prev-class="'page-link'"
              :next-class="'page-link'"
              :page-class="'page-item'"
      >
      </paginate>
    </div>
    <reply :showModal="showModalReply" :qa="qa" @completed="replyComplete" @clicked="onclickQuestion"></reply>
  </div>
</template>

<script>
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import DatePicker from "vue2-datepicker";
    import 'vue2-datepicker/index.css';
    import '@/assets/sass/custom/custom_event.scss';
    import {GET_LIST_QA} from "../../../core/services/store/center/QA.module";
    import Reply from "./Reply";

    export default {
        components: {
            DatePicker,
            Reply
        },
        name: "QA-List",
        data() {
            return {
                status: [
                    { id: 0, value: 'Chưa trả lời'},
                    { id: 1, value: 'Đã trả lời'}
                ],
                formSearch: {
                    name: '',
                    is_reply: '',
                },
                data: [],
                page: 1,
                last_page: 1,
                showModalReply: false,
                qa: {}
            }
        },
        mounted() {
            this.getListQA()
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý Q&A", route: 'q-a-list'},
                {title: "Danh sách Q&A"}
            ]);
        },
        methods : {
            handleFormSearch() {
                this.page = 1;
                this.getListQA()
            },
            getListQA() {
                this.$store.dispatch(GET_LIST_QA, {
                    page: this.page,
                    name: this.formSearch.name,
                    is_reply: this.formSearch.is_reply,
                }).then((res) => {
                    this.data = res.data
                    this.last_page = res.pagination.last_page
                });
            },
            clickCallback(obj) {
                this.page = obj
                this.getListQA()
            },
            showModal(item) {
                this.qa = item
                this.showModalReply = true
            },
            onclickQuestion() {
                this.showModalReply = false
            },
            replyComplete(item) {
                let index_qa = this.data.findIndex(i => i.id === item.id);
                let data = this.data[index_qa];
                _.merge(data, item);
                this.showModalReply = false
                this.$bvToast.toast(res.message, {
                    title: 'Trả lời câu hỏi',
                    variant: 'success',
                    solid: true
                });
            }
        }
    }
</script>

<style scoped>

</style>
