<template>
  <div class="card card-custom gutter-b" id="task-arrange-persionnal" v-loading="loadingTable">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        Tác vụ điều phối lớp học cá nhân
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="grid-filter">
          <div class="display-flex-column">
            <label for=""><b>Tác vụ</b></label>
            <el-input
                class="shadow-sm" type="text"
                v-model="query.id" clearable
                @change="handleChangeFilter"
                placeholder="Nhập mã tác vụ"
            />
          </div>
          <div class="display-flex-column">
            <label for=""><b>Khách hàng</b></label>
            <el-input
                class="shadow-sm" type="text"
                v-model="query.customer"
                @change="handleChangeFilter"
                clearable placeholder="Nhập tên, SĐT, mã HV"
            />
          </div>
          <div class="display-flex-column">
            <label for=""><b>Loại tác vụ</b></label>
            <el-select class="w-100 shadow-sm" v-model="query.typeTask" filterable clearable
                       placeholder="Chọn loại tác vụ" @change="handleChangeFilter">
              <el-option
                  v-for="item in TYPE_TASK"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column" v-if="query.typeTask != null && query.typeTask != KHAI_GIANG">
            <span>Phân loại</span>
            <el-select v-model="query.typeDetail" filterable clearable placeholder="Chọn trạng thái"
                       @change="handleChangeFilter" :multiple="query.typeTask === XEP_LOP">
              <el-option
                  v-for="item in listFilterTypeDetail"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column" v-if="query.typeTask != null && query.typeTask == KHAI_GIANG">
            <span>Phân loại</span>
            <el-select v-model="query.typeDetail" filterable clearable
                       @change="handleChangeFilter"   remote
                       reserve-keyword
                       placeholder="Tìm kiếm tên lớp"
                       :remote-method="filterClass"
                       :loading="loadingFilterClassroom">
              <el-option
                  v-for="item in filterClassroom"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column">
            <label for=""><b>Trạng thái</b></label>
            <el-select class="w-100 shadow-sm" v-model="query.status" placeholder="Chọn trạng thái"
                       collapse-tags clearable @change="handleChangeFilter">
              <el-option v-for="item in statusTask" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="display-flex-column">
            <label for=""><b>Kết quả xử lý gần nhất</b></label>
            <el-select class="w-100 shadow-sm" v-model="query.resultLast" placeholder="Chọn kết quả xử lý"
                       collapse-tags clearable filterable multiple @change="handleChangeFilter">
              <el-option v-for="item in ListNearResult"
                         :key="item.value"
                         :label="item.name"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column">
            <label for=""><b>Trung tâm</b></label>
            <el-select class="w-100 shadow-sm" v-model="query.center" style="width: 100%" filterable clearable
                       placeholder="Chọn trung tâm" @change="handleChangeFilter">
              <el-option
                  v-for="item in centers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column" v-if="query.center">
            <label for=""><b>Chi nhánh</b></label>
            <el-select class="w-100 shadow-sm" multiple v-model="query.branch" filterable placeholder="Chọn chi nhánh"
                       collapse-tags clearable @change="handleChangeFilter">
              <el-option
                  v-for="item in branches"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column">
            <label for=""><b>Nhà mạng</b></label>
            <el-select class="w-100 shadow-sm" v-model="query.network" placeholder="Chọn nhà mạng"
                       collapse-tags clearable @change="handleChangeFilter">
              <el-option v-for="item in networkOption"
                         :key="item.value"
                         :label="item.name"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="display-flex-column">
            <label for=""><b>Lịch gọi</b></label>
            <el-date-picker
                style="width: 100%"
                v-model="query.callCalendar"
                type="daterange"
                align="right"
                start-placeholder="Thời gian bắt đầu"
                end-placeholder="Thời gian kết thúc"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                :default-value="new Date()"
                @change="handleChangeFilter"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="example-preview table-responsive mt-8">
          <table class="table table-vertical-center table-bordered">
            <thead>
            <tr style="background-color: #F8F8F8;">
              <th>Mã tác vụ</th>
              <th>Loại tác vụ</th>
              <th>Phân loại</th>
              <th>Mã học viên</th>
              <th>Họ tên khách hàng</th>
              <th>Số điện thoại</th>
              <th>Trạng thái</th>
              <td>Kết quả xử lý gần nhất</td>
              <th>Lịch gọi</th>
              <th>Hành động</th>
            </tr>
            </thead>
            <tbody v-if="listTask?.length > 0">
            <tr v-for="task in listTask" :key="task.id">
              <td>{{ task.id }}</td>
              <td>
                <span style="padding: 4px 8px; border-radius: 9999px; white-space: nowrap; word-break: break-word" :class="task?.bgType">{{ task?.type }}</span>
              </td>
              <td style="text-align: start; ">{{task?.type === 'Khai giảng'?`Lớp ${task?.classroom_name}`:task?.type_detail}} </td>
              <td>{{ task?.studentCode + 100000 }}</td>
              <td>{{ task.customerName }}</td>
              <td>{{ task.phone }}</td>
              <td>
                <span style="padding: 4px 8px; border-radius: 5px; border: 0; white-space: nowrap; word-break: break-word" :class="task?.bgStatus">{{task.isCancel === 1 ? 'Hủy' : task?.status}}</span>
              </td>
              <td>
                <span style="padding: 4px 8px; border-radius: 5px; border: 0; white-space: nowrap; word-break: break-word" :class="task?.bgLastResult">{{ task?.latestResult }}</span>
              </td>
              <td>{{ task.callSchedule }}</td>
              <td>
                <router-link title="Xem"
                             :to="{ name: 'TaskManagementDetail', params: { id: task.id } }"
                             class="btn btn-icon btn-outline-primary mr-2 btn-sm">
                  <i class="fas far fa-eye fa-sm"></i>
                </router-link>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{
            totalData
          }}</b></div>
      </div>
      <div class="d-flex edu-paginate mx-auto text-center justify-content-center">
        <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1"
                  :click-handler="clickCallback"
                  :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'"
                  :pageLinkClass="'page-link'"
                  :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
                  :next-class="'page-link'" :page-class="'page-item'">
        </paginate>
      </div>
    </div>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  GET_ALL_LIST_TASK,
  GET_CENTER_TAG_E,
  GET_BRANCHES, GET_FILTER_CLASS_BY_NAME
} from "@/core/services/store/task-management/task-management.module";
import {
  HOC_VIEN_CHO_LOP_SAU,
  HOC_VIEN_FAIL_KHOA,
  HOC_VIEN_MOI,
  HOC_VIEN_PASS_KHOA,
  KHAI_GIANG,
  STATUS_TASK, THI_CHINH, THI_LAI_DETAIL,
  TYPE_DETAILS,
  XEP_LOP, XEP_LOP_THEO_YEU_CAU
} from "@/core/option/taskArrangePersonal";
import {TYPE_TASK} from "@/core/option/taskArrangePersonal";
import {
  phoneNetwork,
  resultLastType1andStatus1,
  resultLastType1andStatus2,
  resultLastType2andStatus1,
  resultLastType2andStatus2,
  resultLastType3andStatus1,
  resultLastType3andStatus2,
  resultLastType4andStatus1,
  resultLastType4andStatus2,
  statusManagement
} from "@/core/option/TaskManagementOption";
import {TaskManagementModel} from "@/view/pages/task-management/model/TaskManagementModel";

export default {
  name: "TaskArrangePersonal",
  components: {DatePicker},
  data() {
    return {
      page: 1,
      last_page: 1,
      query: {
        id: null,
        customer: null,
        status: null,
        resultLast: [],
        center: null,
        branch: [],
        network: null,
        callCalendar: [],
        typeTask: null,
        typeDetail: null
      },
      centers: [],
      branches: [],
      loadingTable: true,
      loading_export: false,
      totalData: '',
      ListNearResult: statusManagement,
      statusTask: STATUS_TASK,
      networkOption: phoneNetwork,
      listTask: [],
      TYPE_TASK,
      KHAI_GIANG: KHAI_GIANG,
      TYPE_DETAILS:TYPE_DETAILS,
      filterClassroom: [],
      loadingFilterClassroom: false,
      XEP_LOP: XEP_LOP
    }
  },
  mounted() {
    this.getCenterTagE()
    this.getListTaskPersonal();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Điều phối xếp lớp", name: 'TaskArrangePersonal'},
      {title: "Tác vụ điều phối xếp lớp cá nhân"},
    ]);
  },
  methods: {
    clickCallback(obj) {
      this.page = obj
      this.getListTaskPersonal()
    },
    getListResultLast(obj) {
      switch (obj.typeTask) {
        case 1:
          if (obj.status === 1) {
            this.ListNearResult = resultLastType1andStatus1;
          } else {
            this.ListNearResult = resultLastType1andStatus2;
          }
          break;
        case 2:
          if (obj.status === 1) {
            this.ListNearResult = resultLastType2andStatus1;
          } else {
            this.ListNearResult = resultLastType2andStatus2;
          }
          break;
        case 3:
          if (obj.status === 1) {
            this.ListNearResult = resultLastType3andStatus1;
          } else {
            this.ListNearResult = resultLastType3andStatus2;
          }
          break;
        case 4:
          if (obj.status === 1) {
            this.ListNearResult = resultLastType4andStatus1;
          } else {
            this.ListNearResult = resultLastType4andStatus2;
          }
          break;
        default:
          this.ListNearResult = statusManagement;
      }
    },
    getCenterTagE() {
      const payload = {
        limit: 10000,
        is_edutalk: 1,
        status: 1,
      }
      this.$store.dispatch(GET_CENTER_TAG_E, payload).then(res => {
        if (!res.error) {
          this.centers = res.data?.data
        }
      })
    },
    getBranches(centerId) {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 1000,
        center_id: centerId
      }).then((res) => {
        this.branches = res.data.data;
      });
    },
    async getListTaskPersonal() {
      this.getParams()
      this.callApi()
    },
    callApi() {
      const payload = {
        page: this.page,
        id: this.query.id,
        keyword: this.query.customer,
        type: this.query.typeTask,
        result_lastest: this.query.resultLast,
        status: this.query.status ? +this.query.status : null,
        center_id: this.query.center ? this.query.center : null,
        branch_ids: this.query.branch,
        network: this.query.network ?? null,
        date_range_call_time: this.query.callCalendar,
        type_filter: 'personal',
        type_detail: this.query.typeDetail
      }
      this.$store.dispatch(GET_ALL_LIST_TASK, payload).then((res) => {
        if (!res.error) {
          this.listTask = res.data?.map(task => {
            return new TaskManagementModel(
                task?.id,
                false,
                task?.type,
                task?.type_num,
                task?.status,
                task?.status_num,
                task?.user_name,
                task?.user_id,
                task?.phone,
                task?.person_in_charge,
                task?.latest_result,
                task?.created_at,
                task?.call_time_newest,
                task?.is_cancel,
                task?.type_detail,
                task?.classroom_name
            )
          })
        }
        this.totalData = res.pagination.total;
        this.last_page = res.pagination.last_page;
        this.page = res.pagination.page;
        this.loadingTable = false
      });
    },
    async handleChangeFilter(type = null) {
      if(type === 'Loại tác vụ'){
        this.query.typeDetail = null
      }
      if (this.query.center) {
        this.getBranches(this.query.center);
      }
      if (this.query.typeTask && this.query.status) {
        this.getListResultLast(this.query)
      }
      await this.queryFilterTask();
      await this.getListTaskPersonal();
    },
    getParams() {
      this.query.id = this.$route.query.id ?? null
      this.query.keyword = this.$route.query.keyword ?? null
      this.query.status = this.$route.query.status ? parseInt(this.$route.query.status) : null
      this.query.resultLast = this.$route.query.result_lastest ? JSON.parse(`[${this.$route.query.result_lastest}]`) : []
      this.query.center = this.$route.query.center_id ? parseInt(this.$route.query.center_id) : null
      this.query.branch = this.$route.query.branch_ids ? JSON.parse(`[${this.$route.query.branch_ids}]`) : null
      this.query.network = this.$route.query.network ?? null
      this.query.callCalendar = this.$route.query.date_range_call_time ?? []
      this.query.typeTask = this.$route.query.type ? parseInt(this.$route.query.type) : null
      this.query.type_filter = this.$route.query.type_filter ?? null
      if (+this.query.typeTask === XEP_LOP) {
        this.query.typeDetail = this.$route.query.type_detail ?? [];
      }else {
        this.query.typeDetail = this.$route.query.type_detail ?parseInt(this.$route.query.type_detail): null
      }
    },
    async queryFilterTask() {
      await this.$router.push({
        name: 'TaskArrangePersonal',
        query: {
          id: this.query.id,
          keyword: this.query.customer,
          status: this.query.status,
          result_lastest: this.query.resultLast,
          center_id: this.query.center,
          branch_ids: this.query.branch,
          person_in_charge_ids: this.query.personInCharge,
          network: this.query.network,
          date_range_created: this.query.createdAt,
          date_range_call_time: this.query.callCalendar,
          type: this.query.typeTask,
          desired_schedule: this.query.schedule,
          course_id: this.query.course,
          type_filter: 'personal',
          type_detail: this.query.typeDetail
        }
      })
      return null
    },
    filterClass(query) {
      this.filterClassroom = []
      this.loadingFilterClassroom = true
      this.$store.dispatch(GET_FILTER_CLASS_BY_NAME,{name:query}).then((data)=>{
        this.filterClassroom = data?.data.map((e)=>{
          return {
            id: e.id,
            name: e.name
          }
        })
      }).catch(()=>{
        this.filterClassroom = []
      }).finally(()=>{
        this.loadingFilterClassroom = false
      })
    }
  },
  computed:{
    listFilterTypeDetail(){
      if(this.query.typeTask === XEP_LOP){
        return [
          {id:HOC_VIEN_MOI, name:'Học viên mới'},
          {id:HOC_VIEN_PASS_KHOA, name:'Học viên pass khóa'},
          {id:HOC_VIEN_FAIL_KHOA, name:'Học viên fail khóa'},
          {id:HOC_VIEN_CHO_LOP_SAU, name:'Học viên chờ lớp sau'},
          {id:XEP_LOP_THEO_YEU_CAU, name:'Xếp lớp theo yêu cầu'},
        ]
      }
      return [
        {id:THI_CHINH, name:'Thi chính'},
        {id:THI_LAI_DETAIL, name:'Thi lại'}
      ]
    }
  }


}

</script>

<style scoped>
.display-flex-column {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.bg-violet {
  background: #B300C2;
  color: white;
}

.bg-type2 {
  background: #007499;
  color: white;
}

.bg-yellow {
  background: #FFB800;
  color: black;
}

.bg-blue {
  background: #0066FF;
  color: white;
}

.bg-amber {
  background: #856000;
  color: white;
}

.bg-red {
  background: #FF0000;
  color: white;
}

.bg-green {
  background: #038200;
  color: white;
}

.bg-green-500 {
  background: #006B04;
  color: white;
}

.bg-gray {
  background: #D2D2D2;
  color: black;
}

.bg-teal {
  background: #1BC5BD;
  color: white;
}

.bg-primary {
  background: #4C0192;
  color: white;
}

.grid-filter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px 60px;
}

@media screen and (max-width: 1560px) {
  .grid-filter {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px 30px;
  }
}
</style>