<template>
	<div class="text-center">
		<img src="../../../../../../public/media/test-input/failure.jpg" alt="">
		<h1 style="font-size: 16px;color: #FA5252;
" class="mt-2">Không thành công</h1>
		<h1 v-if="action === 'create'" class="mt-3" style="font-size: 13px;">Khung giờ hiện tại đã hết slot, vui lòng đặt
			lại lịch khác.</h1>
		<h1 v-else class="mt-3" style="font-size: 13px;">Người phụ trách hiện tại đã có tác vụ trong khung giờ này.</h1>
		<button
			type="button"
			class="btn btn-secondary mt-3"
			@click="closeModal"
		>Đóng
		</button>
	</div>
</template>

<script>
export default {
	name: "CancelCreateInput",
	props: {
		action: {
			type: String,
			default: () => {
				return 'create';
			}
		}
	},
	methods: {
		closeModal() {
			this.$emit('close-modal');
		}
	}
}
</script>

<style scoped>

</style>