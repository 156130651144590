<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Tạo mới phòng ban.
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Tạo mới phòng ban</span>
      </div>
      <div class="card-toolbar">

        <router-link
            class="font-weight-bold font-size-3  btn btn-secondary"
            :to="{ name: 'manage-department-list' }"
        ><i class="fas fa-angle-double-left"></i> Quay lại
        </router-link>
      </div>
    </div>
    <!--end::Header-->
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form  id="permission-add" ref="permission-add" class="form" @submit.prevent="handleSubmit(onSubmit)">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div id="input-group-2" role="group" class="form-group">
                <label id="input-group-2__BV_label_"
                       class="d-block">Tên phòng ban <span data-v-3736cd64="" class="text-danger">*</span></label>

                <div>
                  <ValidationProvider name="Tên phòng ban" rules="required" vid="name" v-slot="{ errors,classes }">
                    <input type="text" ref="name" name="name" class="form-control" :class="classes"
                           placeholder="Tên quyền"
                           v-model=form.name>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group fv-plugins-icon-container">
                <label>Chọn phòng ban cha</label>
                <select2 :options="permissions" v-model="form.parent_id" placeholder="Chọn phòng ban cha"></select2>
              </div>
              <div id="checkboxes-4" role="group" tabindex="-1" class="bv-no-focus-ring">
                <div v-for="guard in form.data"  class="custom-control custom-control-inline custom-checkbox b-custom-control-lg">
                  <input type="checkbox"
                         class="custom-control-input"
                         :id="'check'+guard.name"
                         v-model="guard.checked">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" class="btn btn-success">Tạo mới</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
  <!--end::Card-->
</template>

<script>
import {CREATE_DEPARTMENTS, GET_DEPARTMENTS} from "@/core/services/store/ticket/ticket.module";
import Select2 from "@/view/content/Select2";
const _ = require('lodash');
export default {
  components: {
    Select2
  },
  name: "Create-department",
  data() {
    return {
      permissions: [],
      form: {
        name: "",
        parent_id : null,
        data: [
          {
            name : "backend",
            checked: true
          },
          {
            name : "noibo",
            checked: false
          },
          {
            name : "educenter",
            checked: false
          }
        ],
      },
      isDisable: false
    };
  },
  mounted() {
    this.$store.dispatch(GET_DEPARTMENTS , { status: 1, all: true }).then((res) => {
      this.permissions = _.map(res.data,(item) => {
        item = {id: item.id, text: item.name, area_id : item.area_id};
        return item;
      });
    });
  },
  methods: {
    onSubmit(){
      this.$store.dispatch(CREATE_DEPARTMENTS , { parent_id: this.form.parent_id , name: this.form.name }).then((res) => {
        if (res) {
          this.$notify({
            title: 'Thành công',
            message: res.message,
            type: 'success'
          });
        }
      })
    },
  }
}
</script>

<style scoped>

</style>