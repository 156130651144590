<template>
  <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Chương trình quay thưởng</h3>
            </div>
            <div class="card-title">
                <button class="btn btn-success font-weight-bold btn-sm" v-b-modal.modal-create-prize-draw @click="createPrize({})">
                  Thêm chương trình
                </button>
            </div>
        </div>
    <div class="card-body">
        <div class="example mb-10">
            <div class="col-12 row">
                <div class="col-md-2">
                    <div class="form-group">
                        <label>Tên chương trình</label>
                        <el-input type="text" 
                                v-model="query.keyword"
                                placeholder="Nhập tên chương trình" @input="getPrizeDraw()"></el-input>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                    <label>Trung tâm</label>
                    <el-select filterable class="w-100"  placeholder="Chọn trung tâm"
                                clearable
                                v-model="query.center_id" @change="getPrizeDraw()">
                        <el-option v-for="(item, index) in listCenter" :key="index" :value="item.id" :label="item.name">
                        </el-option>
                    </el-select>
                    </div>
                </div>
            </div>
            <div class="example-preview b-table-sticky-header">
                <table class="table table-vertical-center b-table">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col" class="min-w-50px">STT</th>
                            <th scope="col" class="min-w-100px">Tên chương trình</th>
                            <th scope="col" class="min-w-100px">Trung tâm</th>
                          <th scope="col" class="min-w-100px">Loại chương trình</th>
                            <th scope="col" class="min-w-120px">Thời gian bắt đầu</th>
                            <th scope="col" class="min-w-120px">Thời gian kết thúc</th>
                            <th scope="col" class="min-w-100px">Trạng thái</th>
                            <th scope="col" class="min-w-150px text-center">Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in listPrize" :key="index">
                            <td>{{index + 1}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.centerName}}</td>
                            <td>{{item.typeToString}}</td>
                            <td>{{item.timeStart}}</td>
                            <td>{{item.timeEnd}}</td>
                            <td>
                              <span style="padding: 4px 8px; border-radius: 4px" :class="item.status === 'Chưa bắt đầu' ? 'bg-secondary'
                                : item.status === 'Đang diễn ra' ? 'bg-warning'
                                : item.status === 'Đã kết thúc' ? 'bg-success text-white' : ''">
                                {{item.status}}
                              </span>
                            </td>
                            <td class="text-center">
                                <a href="javascript:" title="Xem chi tiết"
                                @click="showDetailModal(item)"
                                class="btn btn-xs btn-icon btn-outline-success mr-2"
                                ><i class="far fa-eye"></i>
                                </a>
                                <a v-if="item.status === 'Chưa bắt đầu'" title="Xóa"  href="javascript:"
                                @click="deletePrizeDraw(item)"
                                class="btn btn-xs btn-icon btn-outline-danger">
                                <i class="fas fa-trash"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-center" style="margin: 10px 0" v-if="!total">Không có bản ghi</div>
            </div>
            <div class="d-flex justify-content-center" style="align-items: center; position: relative" v-if="total > 0">
                <paginate
                    v-model="query.page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                    :page-class="'page-item'"
                />
                <div class="d-flex justify-content-end" style="position: absolute; right: 0; align-items: center">
                  <span>Tổng số bản ghi : <span style="color: #0066FF">{{total}}</span></span>
                </div>
            </div>
        </div>
        <modal-create-prize-draw @commit="reloadData($event)"  :checkUpdate="checkUpdate" :listCenter="listCenter"></modal-create-prize-draw>
    </div>
  </div>
</template>

<script>
import ModalCreatePrizeDraw from '@/view/pages/rewards-program/ModalCreatePrizeDraw';
import {
  REWARD_PROGRAMS, DELETE_REWARD_PROGRAMS
} from "@/core/services/store/contract/contract-program.module";
import { GET_CENTERS } from "@/core/services/store/center/centers.module";
import Swal from 'sweetalert2';
import router from "@/router";
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import {InformationProgramModel} from "@/view/pages/rewards-program/detail-program/model/InformationProgramModel";

export default {
    name: "PrizeDrawIndex",
    components: {
        ModalCreatePrizeDraw
    },
    data() {
        return {
            query: {
                keyword: '',
                center_id: '',
                page: 1,
            },
            modalShow: '',
            listPrize: [],
            last_page: 1,
            checkUpdate: false,
            listCenter: [],
            total: ''
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý kinh doanh"},
            {title: "Chương trình quay thưởng"}
        ]);
        this.getPrizeDraw();
        this.getCenter();
    },
    methods: {
        createPrize(value) {
          this.checkUpdate = !!Object.values(value).length;
        },
        showDetailModal: function (item) {
          router.push({path: `/rewards-programs/detail-program/${item.id}?status=${item.status}`});
        },
        getCenter() {
            this.$store.dispatch(GET_CENTERS, {is_edutalk: 1, page_type: 'home'}).then(res => {
                this.listCenter = res.data.data;
            });
        },
        getPrizeDraw() {
            this.$store.dispatch(REWARD_PROGRAMS, this.query).then(res => {
                if(!res.error) {
                    this.listPrize = res.data?.data?.length > 0 ? res.data?.data?.map(item => {
                      return new InformationProgramModel(
                          item?.id,
                          item?.name,
                          item?.center?.id,
                          item?.center?.name,
                          item?.program_type,
                          item?.start_date,
                          item?.end_date
                      )
                    }) : [];
                    this.last_page = res.data.last_page
                    this.total = res.data.total
                }
            }).catch(e => {
              console.log(e)
            });
        },
        deletePrizeDraw(item) {
            if(item.length < 1) {
                this.$bvToast.toast(res.message, {
                    title: 'Không thể xóa khi đã có ít nhất 1 kết quả quay thưởng',
                    variant: 'danger',
                    solid: true,
                });
                return;
            }
            Swal.fire({
                title: "Hệ thống sẽ xóa tất cả thông tin của 1 chương trình (Quà tặng, danh sách lớp). Bạn có chắc chắn muốn xóa chương trình quay thưởng?",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Đồng ý",
                cancelButtonText: "Không",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch(DELETE_REWARD_PROGRAMS, {id: item.id}).then(res => {
                        if (res.data == null) {
                            this.$bvToast.toast('Không thể xóa chương trình quay thưởng khi đã có kết quả', {
                                title: 'Xóa',
                                variant: 'danger',
                                solid: true,
                            });
                            return;
                        }
                        this.$bvToast.toast(res.message, {
                        title: 'Xóa',
                        variant: 'success',
                        solid: true,
                        });
                        this.getPrizeDraw();
                    });
                }
            });
        },
        clickCallback(obj) {
            this.query.page = Number(obj);
            this.$router.push({name: 'list-program', query: {page: this.query.page, keyword: this.query.keyword}})
            this.getPrizeDraw()
        },
        reloadData(event) {
            this.getPrizeDraw()
        },
        search() {

        }
    }
}
</script>
