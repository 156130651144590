<template>
  <div class="card card-custom gutter-b h-100">
    <!--    header-->
    <div class="card-header">
      <div class="card-title">
        Chi tiết chương trình quay thưởng
      </div>
      <div class="card-title">
        <button class="font-weight-bold font-size-3 btn btn-success" @click="exportResult()"><i
            class="el-icon-download"></i> Export kết quả
        </button>
      </div>
    </div>
    <!--    body-->
    <div class="card-body">
      <!-- thông tin chương trình-->
      <section>
        <h1 style="font-size: 15px;color: #454545">Thông tin chương trình</h1>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form class="form" @submit.prevent autocomplete="off">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-sm-12 mb-5">
                  <label>Tên chương trình</label>
                  <ValidationProvider
                      name="Tên chương trình"
                      rules="required"
                      v-slot="{ errors, classes }"
                      vid=""
                  >
                    <el-input
                        placeholder="Nhập tên chương trình" v-model="detailProgram.name"
                        :disabled="!isEdit"
                    />
                    <div class="fv-plugins-message-container">
                      <div
                          data-field="name"
                          data-validator="notEmpty"
                          class="fv-help-block"
                      >
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6 col-sm-12 mb-5">
                  <label>Trung tâm</label>
                  <ValidationProvider
                      name="Trung tâm"
                      rules="required"
                      v-slot="{ errors, classes }"
                      vid=""
                  >
                    <el-select class="d-block" v-model="detailProgram.centerId" placeholder="Chọn trung tâm" size="large"
                               :disabled="!isEdit">
                      <el-option
                          v-for="item in centers"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"

                      />
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6 col-sm-12 mb-3">
                  <label class="d-block"
                  >Thời gian bắt đầu</label
                  >
                  <ValidationProvider
                      name="Thời gian bắt đầu"
                      rules="required"
                      v-slot="{ errors }"
                      vid="start_schedule"
                  >
                    <date-picker
                        type="datetime"
                        placeholder="Thời gian bắt đầu"
                        format="DD-MM-YYYY HH:mm:ss"
                        v-model="detailProgram.timeStart"
                        :disabled="!isEdit"
                        :disabled-date="disabledBeforeStartDate"
                        :disabled-time="disableHourStart"
                    >
                    </date-picker>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6 col-sm-12 mb-3">
                  <label class="d-block"
                  >Thời gian kết thúc</label
                  >
                  <ValidationProvider
                      name="Thời gian kết thúc"
                      rules="required"
                      v-slot="{ errors, classes }"
                      vid="start_schedule"
                  >
                    <date-picker
                        type="datetime"
                        placeholder="Thời gian kết thúc"
                        format="DD-MM-YYYY HH:mm:ss"
                        v-model="detailProgram.timeEnd"
                        :disabled="!isEdit"
                        :disabled-date="disabledBeforeEndDate"
                        :disabled-time="disableHourEnd"
                    >
                    </date-picker>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-6 col-sm-12 mb-5">
                  <label>Loại quay thưởng</label>
                  <ValidationProvider
                      name="Loại quay thưởng"
                      rules="required"
                      v-slot="{ errors }"
                      vid=""
                  >
                    <el-select class="d-block" v-model="detailProgram.type" placeholder="Chọn loại quay thưởng" size="large"
                               :disabled="!isEdit">
                      <el-option
                          v-for="item in typeProgramOption"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      />
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div
                          data-field="name"
                          data-validator="notEmpty"
                          class="fv-help-block"
                      >
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <footer class="container" v-if="status !== 'Đã kết thúc'">
              <div class="d-flex justify-content-end">
                <button
                    type="button"
                    class="btn  mb-5 button_confirm"
                    style="background-color: #0066FF;  color: white"
                    v-if="!isEdit"
                    @click="handleWantEdit()"
                >
                  <i class="el-icon-edit" style="color: white"></i>Sửa thông tin
                </button>
                <div v-else>
                  <button
                      type="button"
                      class="btn btn-secondary mr-4 "
                      @click="cancelEdit()"
                  >
                    Hủy
                  </button>
                  <button
                      type="button"
                      class="btn btn-success "
                      @click="handleSubmit(handleEdit)"
                  >
                    Lưu
                  </button>
                </div>
              </div>
            </footer>
          </form>
        </ValidationObserver>
        <hr>
      </section>
      <div class="row">
        <!--      danh sách quà tặng-->
        <section class="col-lg-4 col-sm-12">
          <ListGift @doneDelete="flagDelete = !flagDelete" :type-program="programType"></ListGift>
        </section>
        <!--      danh sách lớp học-->
        <section class="col-lg-8 col-sm-12 ">
          <ListClassroom :flagDelete="flagDelete" :type-program="programType"></ListClassroom>
        </section>
      </div>

    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ListClassroom from "@/view/pages/rewards-program/detail-program/ListClassroom";
import {InformationProgramModel, typeProgramOption} from "@/view/pages/rewards-program/detail-program/model/InformationProgramModel";
import {GET_CENTERS} from "@/core/services/store/center/centers.module";
import moment from "moment-timezone";
import {EDIT_INFORMATION_PROGRAM, EXPORT_RESULT_PROGRAM, GET_DETAIL_INFORMATION_PROGRAM} from "@/core/services/store/rewards-programs/rewardsPrograms.module";
import ListGift from "@/view/pages/rewards-program/detail-program/ListGift";
import writeXlsxFile from 'write-excel-file'
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "DetailProgram",
  components: {ListGift, ListClassroom, DatePicker},
  data() {
    return {
      detailProgram: {},
      centers: [],
      isEdit: false,
      idDetailProgram: '',
      flagDelete: false,
      typeProgramOption: typeProgramOption,
      status: null,
      programType: null,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý kinh doanh"},
      {title: "Chi tiết chương trình quay thưởng"}
    ]);
    this.idDetailProgram = this.$route.params.id;
    this.getDetailProgram()
    this.getCenters()
    this.status = this.$route.query.status
  },
  methods: {
    getDetailProgram() {
      this.$store.dispatch(GET_DETAIL_INFORMATION_PROGRAM, this.idDetailProgram).then((res) => {
        if (!res.error) {
          const data = res.data
          this.programType = data?.program_type
          this.detailProgram = new InformationProgramModel(
              data?.id,
              data?.name,
              data?.center_id,
              data?.center_name,
              data?.program_type,
              new Date(data?.start_date),
              new Date(data?.end_date),
          );
        }
      })
    },
    getCenters() {
      this.$store.dispatch(GET_CENTERS, {status: 1, is_edutalk: 1})
          .then((data) => {
            if (!data.error) {
              this.centers = data.data.data
            }
          });
    },
    handleWantEdit() {
      this.isEdit = true;
    },
    cancelEdit() {
      this.isEdit = false;
      this.getDetailProgram()
    },
    handleEdit() {
      const payload = {
        name: this.detailProgram.name,
        center_id: this.detailProgram.centerId,
        start_date: moment(this.detailProgram.timeStart).format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(this.detailProgram.timeEnd).format('YYYY-MM-DD HH:mm:ss'),
        program_type: this.detailProgram.type,
        id: this.idDetailProgram
      }
      this.$store.dispatch(EDIT_INFORMATION_PROGRAM, payload).then((data) => {
        if (!data.error) {
          this.$notify({
            title: 'Thành công',
            message: 'Sửa thông tin thành công ',
            type: 'success'
          });
        } else {
          this.$notify({
            title: 'Không thành công',
            message: 'Đã tồn tại chương trình nằm trong khoảng thời gian này. Vui lòng chọn lại.',
            type: 'error'
          });
        }
        this.getDetailProgram()
      }).catch(() => {
        this.$notify({
          title: 'Thất bại',
          message: 'Sửa thông tin thất bại ',
          type: 'error'
        });
      })
      this.isEdit = false
    },
    disabledBeforeStartDate(date) {
      const today = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      return moment(date) < today;
    },
    disableHourStart(hour) {
      let today = new Date();
      return today >= hour;
    },
    disabledBeforeEndDate(date) {
      return (
          moment(date).endOf("days") <= moment(this.detailProgram.timeStart)
      );
    },
    disableHourEnd(hour) {
      return this.detailProgram.timeStart >= hour;
    },
    async exportResult() {
      let payload = {
        spin_reward_program_id: this.idDetailProgram
      }
      this.$store.dispatch(EXPORT_RESULT_PROGRAM, payload).then(async (data) => {
        if (!data.error) {
          const HEADER_ROW = [
            {
              value: 'Họ và tên',
            },
            {
              value: 'Số điện thoại',
            },
            {
              value: 'Quà tặng',
            },
            {
              value: 'Thời gian quay',
            },
            // {
            //   value: 'Tên lớp',
            // }
          ]
          let dataExcel = data.data.sort((a, b) => a.time < b.time ? 1 : -1).map((e) => {
            return [
                {value: e.name},
              {value: e.phone},
              {value: e?.spin_gift?.name},
              {value: e.time},
              // {value: e?.classroom?.name}
            ]
          });

          let dataCol = [HEADER_ROW, ...dataExcel]
          let fileName = "ket_qua_quay_thuong.xlsx"
          await writeXlsxFile(dataCol, {fileName})
        }

      })
    }

  },

}
</script>

<style scoped>

</style>
