<template>
  <div class="question">
    <homework-container :question="question"></homework-container>
    <div class="row">
      <div class="col-md-6" v-for="(item, index) in question.questions" :key="index">
        <div class="answer">
          <div class="answer-title">
            <div v-html="formatBrTagText(item.title)"></div>
          </div>
          <div class="answer-detail d-flex align-items-center justify-content-between" v-for="(answer, key) in item.answers" :key="key">
            <div v-if="typeView === 'view-result'">
              <el-input type="text" placeholder="Your answer..." :class="renderClass(item)" :value="item.student_answer" class="w-50"></el-input>
              <b class="w-50 ml-3">
                Correct answer:<span class="text-success"> {{ answer.content }}</span>
              </b>
            </div>
            <div v-else>
              <el-input type="text" placeholder="Your answer..." class="w-100"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//css
import '@/assets/sass/homework/single-choice.scss';
import homeworkContainer from "./homework-container.vue";
export default {
  name: "fill-text-audio",
  components: {homeworkContainer},
  props: {
    question: {
      type: Object,
      default: () => { return {} }
    },
    typeView: {
      type: String,
      default: ''
    },
  },
  data(){
    return{}
  },
  methods: {
    formatBrTagText(value) {
      return value ? value.replace(/(\r\n|\n\r|\r|\n)/g, '<br>') : '';
    },
    renderClass(question){
      let correctAnswers = question.correct_answer
        .split("##")
        .find((d) => this.clean(d) == this.clean(question.student_answer));
      if (correctAnswers){
        return 'correct-answer';
      }
      return 'wrong-answer'
    },
    clean (string) {
      string = string.replace(" ## ", "#").trim();
      string = string.replace(/[^\w\d ]/g, " ");
      string = string.replace("  ", " ").trim();
      string = string.toLowerCase();
      console.log("string",string);
      return string;
    }
  }
}
</script>

<style scoped>

</style>