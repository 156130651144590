import Swal from "sweetalert2";
<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Danh sách phòng học</h3>
            </div>
            <div class="card-title">
                <router-link
                        class="font-weight-bold font-size-3  btn btn-success"
                        :to="{ name: 'room-create' }"
                >Thêm mới
                </router-link>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Trung tâm</label>
                        <multiselect v-model="cacheCenter" :options="centers"
                                     placeholder="Trung tâm"
                                     label="name"
                                     track-by="name"
                                     :hide-selected="true"
                                     @input="handleQueryCenter($event)"
                        ></multiselect>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Chi nhánh</label>
                        <multiselect v-model="cacheBranch" :options="branches_dependent"
                                     placeholder="Chi nhánh"
                                     label="name"
                                     track-by="name"
                                     :hide-selected="true"
                                     @input="handleQueryBranch($event)"
                        ></multiselect>
                    </div>
                </div>
            </div>
            <div class="example mb-10">
                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col">Tên phòng</th>
                            <th scope="col">Trung tâm</th>
                            <th scope="col">Chi nhánh</th>
                            <th scope="col">Sĩ số</th>
                            <th scope="col">Thiết bị</th>
                            <th scope="col">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="(item, index) in rooms.data" :key="index">
                            <td>{{item.name}}</td>
                            <td>{{item.center != null ? item.center.name : ''}}</td>
                            <td>{{item.branch != null ?item.branch.name : '' }}</td>
                            <td>{{item.number_student > 0 ? item.number_student : ''}}</td>
                            <td>{{item.equipment}}</td>
                            <td>
                                <router-link :to="{ name: 'room-edit', params: {id : item.id} }" title="Sửa"
                                             class="btn btn-sm btn-icon mr-2 btn-outline-info"><i
                                        class="fas fa-pen-nib"></i>
                                </router-link>
                                <a title="Xóa" @click="deleteCourse(item.id)" href="javascript:"
                                   class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="edu-paginate mx-auto text-center">
            <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                    :page-class="'page-item'"
            >
            </paginate>
        </div>
    </div>
</template>
<script>
    import Swal from "sweetalert2";
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {GET_ROOMS, DELETE_ROOM} from "../../../core/services/store/course/classes.module";
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
    import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
    import Multiselect from 'vue-multiselect'

    const _ = require('lodash');

    export default {
        components: {
            Multiselect
        },
        name: "RoomIndex",
        data() {
            return {
                rooms: [],
                // Paginate
                page: 1,
                last_page: 1,
                centers: [],
                branches: [],
                branches_dependent: [],
                query: {
                    center_id: '',
                    branch_id: ''
                },
                // cache
                cacheCenter: [],
                cacheBranch: [],
            }
        },
        async mounted() {
            await this.getCenters()
            await this.getBranches()
            await this.getList()
            await this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý phòng học", route: 'profile-1'},
                {title: "Danh sách phòng học"}
            ]);
        },
        methods: {
            async getCenters() {
                this.$store.dispatch(GET_CENTERS, {
                    limit: 10000,
                    status: 1
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data
                        this.addFirstSelectExample(this.centers, '__Chọn__')
                        this.setCacheTheLoad()
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getBranches() {
                this.$store.dispatch(GET_BRANCHES, {
                    limit: 10000
                }).then((res) => {
                    if (!res.error) {
                        this.branches = res.data.data
                        this.branches_dependent = res.data.data
                        this.addFirstSelectExample(this.branches_dependent, '__Chọn__')
                        this.setCacheTheLoad()
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            async getList() {
                await this.pullParamsUrl()
                await this.callApiGetList()
            },
            clickCallback(obj) {
                this.page = obj
                this.$router.push({
                    path: '',
                    query: {
                        page: this.page,
                        center_id: this.query.center_id,
                        branch_id: this.query.branch_id
                    }
                })
                this.callApiGetList()
            },
            callApiGetList() {
                this.$store.dispatch(GET_ROOMS, {
                    page: this.page,
                    center_id: this.query.center_id,
                    branch_id: this.query.branch_id,
                }).then((res) => {
                    this.rooms = res.data
                    this.last_page = res.data.last_page
                });
            },
            handleQueryCenter($event) {
                let id = $event.id
                this.query.center_id = id
                this.query.branch_id = ''
                this.pushParamsUrl()
                this.callApiGetList()
                this.updateBranchByCenter(id)
            },

            handleQueryBranch($event) {
                if ($event != null) {
                    this.query.branch_id = $event.id
                    this.pushParamsUrl()
                    this.callApiGetList()
                }
            },
            updateBranchByCenter(id) {
                if (id == undefined) return
                this.cacheBranch = []
                this.branches_dependent = this.branches.filter((value) => {
                    return value.center_id == id
                })
                this.addFirstSelectExample(this.branches_dependent, '__Chọn__')
            },
            addFirstSelectExample(obj, text) {
                obj.unshift({
                    name: text
                })
            },
            pushParamsUrl() {
                this.page = 1
                this.$router.push({
                    name: 'room-index',
                    query: {
                        page: this.page,
                        center_id: this.query.center_id,
                        branch_id: this.query.branch_id,
                    }
                })
            },
            pullParamsUrl() {
                this.page = this.$route.query.page
                this.query.branch_id = this.$route.query.branch_id
                this.query.center_id = this.$route.query.center_id
            },
            setCacheTheLoad() {
                if (this.query.center_id != '') {
                    let oldParamCenter = this.centers.filter((value) => {
                        if (parseInt(this.query.center_id) == value.id) {
                            return value
                        }
                    })
                    if (oldParamCenter.length) {
                        this.cacheCenter = oldParamCenter
                        if (oldParamCenter[0] != undefined) {
                            this.updateBranchByCenter(oldParamCenter[0].id)
                        }
                    }
                }
                if (this.query.branch_id != '') {
                    let oldParamBranch = this.branches.filter((value) => {
                        if (parseInt(this.query.branch_id) == value.id) {
                            return value
                        }
                    })
                    this.cacheBranch = oldParamBranch
                }
            },
            deleteCourse(id) {
                Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                  cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire("Xóa", "", "success");
                        this.$store.dispatch(DELETE_ROOM, id).then((res) => {
                            if (res.error) {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'danger',
                                    solid: true
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'success',
                                    solid: true
                                });
                                this.getList()
                            }
                        });
                    }
                });
            },
        }
    }
</script>
<style>
    .mx-datepicker {
        width: 100% !important;
    }

    .multiselect__tags {
        padding: 3px 40px 0 9px !important;
        min-height: 38px !important;
    }

    .multiselect {
        min-height: 38px !important;
    }

    .mx-input {
        min-height: 38px !important;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
