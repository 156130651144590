<template>
  <b-modal
      id="modal-enter-reason"
      ref="modal-enter-reason"
      :title="notify?'Thông báo':`Lí do từ chối phê duyệt team ${nameTeam}`"
      @hidden="resetModal"
      centered
      hide-footer
  >
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form ref="form" v-if="!notify">
        <ValidationProvider name="Lý do" rules="required" v-slot="{ errors,classes }"
                            vid="reason">
          <el-input placeholder="Nhập lí do từ chối phê duyệt" v-model="reason"></el-input>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
          </div>
        </ValidationProvider>
        <div class="d-flex justify-content-end mt-5">
          <button type="button" class="btn btn-secondary mr-3" @click="cancel()">Hủy</button>
          <button type="button" class="btn btn-success" @click="handleSubmit(confirmReason)">Xác nhận</button>
        </div>
      </form>
      <div v-else>
        <p style="font-size: 13px">Không thể phê duyệt team <span style="font-weight: bold">{{nameTeam}}</span>.</p>
        <p style="font-size: 13px">Lí do: <span style="font-weight: bold">{{reason}}</span></p>
        <div class="d-flex justify-content-center">
          <button type="button" class="btn btn-secondary mr-3" @click="cancel()">Đóng</button>
        </div>
      </div>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import {REASON_REFUSE} from "@/core/services/store/tournament/tournament.module";

export default {
  name: "ModalEnterReason",
  data() {
    return {
      reason: null,
      notify: false
    }
  },
  props: {
    idTeam: {
      type: Number,
      default() {
        return "0"
      }
    },
    nameTeam: {
      type: String,
      default() {
        return ""
      }
    }
  },
  methods: {
    resetModal() {
      this.reason = null
      this.notify = false
      this.$emit("listTeamBrowse")
    },
    cancel() {
      this.$refs['modal-enter-reason'].hide()
    },
    confirmReason() {
      let payload = {
        tournament_team_id: this.idTeam,
        reason: this.reason
      }
      this.$store.dispatch(REASON_REFUSE, payload).then(() => {
        this.notify = true
      })
    }
  }
}
</script>

<style scoped>

</style>