<template>
  <div>
    <b-modal
        id="modal-show-qr-code"
        :title="'QRCode: ' +  QRCodeTitle"
        class="dialog-active"
        hide-footer
        centered
    >
      <el-row :gutter="10" class="pr-2 pl-2">
        <qr-code
            class="d-flex justify-content-center"
            :text="qrCode"
        ></qr-code>
      </el-row>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalShowQrCode",
  props: {
    QRCodeTitle: {
      type: String,
      default: () => {
        return '';
      }
    },
    qrCode: {
      type: String,
      default: () => {
        return '';
      }
    },
  }
}
</script>

<style scoped>

</style>