<template>
<div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" id="course_edit" @submit.prevent="handleSubmit(onSubmit)">
            <div class="card card-custom">
                <!--begin::Header-->
                <div class="card-header">
                    <div class="card-title">
                        <h3 class="card-label">
                            Sửa khóa học
                        </h3>
                    </div>
                    <div class="card-toolbar">
                        <button type="button" class="font-weight-bold font-size-3  btn btn-danger mr-2" @click="useDataOld">
                                <i class="fas fa-times"></i> Sử dụng thông tin cũ
                            </button>
                        <router-link
                                class="font-weight-bold font-size-3  btn btn-secondary"
                                :to="{ name: 'course-index' }"
                        ><i class="fas fa-angle-double-left"></i> Quay lại
                        </router-link>
                    </div>
                </div>
                <!--end::Header-->
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-md-3">
                                <div class="row mx-auto mt-8">
                                    <label class="col-xl-4 col-lg-3 col-form-label text-right">Ảnh đại diện</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <div class="image-input image-input-outline" id="kt_profile_avatar">
                                            <div
                                                    class="image-input-wrapper"
                                                    :style="{ backgroundImage: `url(${photo})` }"
                                            ></div>
                                            <label
                                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="change"
                                                    data-toggle="tooltip"
                                                    title=""
                                                    data-original-title="Change avatar"
                                            >
                                                <i class="fa fa-pen icon-sm text-muted"></i>
                                                <input
                                                        type="file"
                                                        name="profile_avatar"
                                                        accept=".png, .jpg, .jpeg"
                                                        @change="onFileChange($event)"
                                                />
                                                <input type="hidden" name="profile_avatar_remove" />
                                            </label>
                                            <span
                                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="cancel"
                                                    data-toggle="tooltip"
                                                    title="Cancel avatar"
                                            >
                                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                                    </span>
                                            <span
                                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="remove"
                                                    data-toggle="tooltip"
                                                    title="Remove avatar"
                                                    @click="course.avatar_mkt = null"
                                            >
                                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                                    </span>
                                        </div>
                                        <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
                                    </div>
                                </div>
                            </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Tên khóa học
                                            <span class="text-danger">*</span></label>
                                            <u class="float-right" style="color: #00b300; cursor: pointer;" @click="handleUseCourse()">Sử dụng thông tin của khóa học</u>
                                        <ValidationProvider vid="name" name="Tên khóa học" rules="required"
                                                            v-slot="{ errors,classes }">
                                            <input type="text" ref="name" name="name" class="form-control" v-model="course.name"
                                                placeholder="Tên khóa học" :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Học phí
                                            <span class="text-danger">*</span></label>
                                        <ValidationProvider mode="lazy" name="Học phí" rules="required|integer|min_value:0"
                                                            v-slot="{ errors,classes }">
                                            <money v-model="course.fee" v-bind="money" class="form-control"
                                            ></money>
                                            <input type="hidden" v-model="course.fee" :class="classes">
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Trung tâm
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Trung tâm" rules="required" v-slot="{ errors,classes }">
                                    <multiselect
                                            v-model="cacheCenter"
                                            :options="centers"
                                            placeholder="Trung tâm"
                                            label="name"
                                            track-by="name"
                                            :class="classes"
                                            @input="updateStateCenter($event)"
                                    ></multiselect>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="col-md-4" v-if="is_show_course_type">
                            <div class="form-group">
                                <label>Loại khóa học
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Loại khóa học" rules="required" v-slot="{ errors,classes }">
                                    <multiselect
                                            v-model="cacheCourseType"
                                            :options="course_types"
                                            placeholder="Loại khóa học"
                                            label="name"
                                            track-by="name"
                                            :class="classes"
                                            @input="updateStateCourseType($event)"
                                    ></multiselect>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="is_show_level">
                            <div class="form-group">
                                <label>Trình độ đầu vào
                                    <span class="text-danger"></span></label>
                                <multiselect
                                        v-model="cacheLevelIn"
                                        :options="level_in_id"
                                        placeholder="Trình độ đầu vào"
                                        label="name"
                                        track-by="name"
                                        @input="updateStateLevelIn($event)"
                                ></multiselect>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="is_show_level">
                            <div class="form-group">
                                <label>Trình độ đầu ra
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Trình độ đầu ra" rules="required" v-slot="{ errors,classes }">
                                    <multiselect
                                            v-model="cacheLevelOut"
                                            :options="level_out_id"
                                            placeholder="Trình độ đầu ra"
                                            label="name"
                                            track-by="name"
                                            :class="classes"
                                            @input="updateStateLevelOut($event)"
                                    ></multiselect>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
<!--                        <div class="col-md-4">-->
<!--                            <div class="form-group">-->
<!--                                <label>Giảm giá (%)-->
<!--                                    <span class="text-danger"></span></label>-->
<!--                                <ValidationProvider name="Giá giảm" rules="between:0,100" v-slot="{ errors,classes }">-->
<!--                                    <input type="text" ref="name" name="name"-->
<!--                                           class="form-control"-->
<!--                                           placeholder="Giảm giá"-->
<!--                                           :class="classes"-->
<!--                                           v-model="course.sale"-->
<!--                                    >-->
<!--                                    <div class="fv-plugins-message-container">-->
<!--                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{-->
<!--                                            errors[0] }}-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </ValidationProvider>-->
<!--                            </div>-->
<!--                        </div>-->
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Học phí tối thiểu
                                        <span class="text-danger"></span></label>
                                    <ValidationProvider vid="minimum_tuition_fee" name="Học phí tối thiểu" rules="" v-slot="{ errors,classes }">
                                        <money v-model="course.minimum_tuition_fee" v-bind="money" class="form-control" :class="classes"
                                        ></money>
                                        <input type="hidden" v-model="course.minimum_tuition_fee">
                                        <div class="fv-plugins-message-container">
                                            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                                    errors[0]
                                                }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Video giới thiệu
                                    <span class="text-danger"></span></label>
                                <input type="text" ref="name" name="name" class="form-control"
                                       v-model="course.video_mkt"
                                       placeholder="Video">

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="checkbox-inline">
                                <label class="checkbox checkbox-success">
                                    <input type="checkbox" name="Checkboxes5" v-model="course.use_schedule">
                                    <span></span>Sử dụng lộ trình</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="checkbox-inline">
                                <label class="checkbox checkbox-success">
                                    <input type="checkbox" name="Checkboxes5" v-model="course.status">
                                    <span></span>Hiển thị</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="checkbox-inline">
                                <label class="checkbox checkbox-success">
                                    <input type="checkbox" name="Checkboxes5" v-model="course.is_priority">
                                    <span></span>Khóa học nổi bật</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="form-group mb-1 col-md-12">
                            <label>Nội dung bài học
                                <span class="text-danger">*</span></label>
                            <ValidationProvider name="Nội dung" rules="required" v-slot="{ errors,classes }">
                                <vue-editor id="editor-content" :editorOptions="editorSettings" useCustomImageHandler
                                            v-model="course.content" :class="classes"
                                            @image-added="handleImageAdded"></vue-editor>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                        errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="form-group mb-1">
                                <label>Mô tả ngắn
                                    <span class="text-danger"></span></label>
                                <textarea class="form-control" id="exampleTextarea" rows="3" v-model="course.excerpt"
                                          placeholder="Mô tả ngắn"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="form-group mb-1 col-md-12">
                            <label>Mô tả
                                <span class="text-danger">*</span></label>
                            <ValidationProvider name="Mô tả" rules="required" v-slot="{ errors,classes }">
                                <vue-editor id="editor-desc" :editorOptions="editorSettings" useCustomImageHandler
                                            v-model="course.description" :class="classes"
                                            @image-added="handleImageAdded"></vue-editor>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                        errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <!--Thông tin cấp trên-->
                <div class="separator separator-dashed my-5"></div>
                <div class="card-header">
                    <div class="card-title align-items-start flex-column">
                        <button
                                type="submit"
                                class="btn btn-success mr-2"
                        >
                            Cập nhật
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogFormVisible">
            <el-row :gutter="10" class="pr-2 pl-2">
                <course-educenter v-if="dialogFormVisible" @completed="completedUseInfoCourse" :course="course"></course-educenter>
            </el-row>
        </el-dialog>
        </div>

</template>
<script>
    import Multiselect from 'vue-multiselect'
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {UPDATE_COURSE, GET_COURSE_BY_ID} from "../../../core/services/store/course/course.module";
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
    import {GET_COURSE_TYPES} from "../../../core/services/store/course/courseType.module";
    import {GET_COURSE_LEVELS} from "../../../core/services/store/course/courseLevel.module";
    import {Money} from 'v-money'

    //editor
    import ApiService from "@/core/services/api.service";
    import {VueEditor, Quill} from 'vue2-editor'
    import ImageResize from 'quill-image-resize-vue';
    import {ImageDrop} from 'quill-image-drop-module';
    import Swal from "sweetalert2";
    import CourseEducenter from "./Course-Educenter.vue";

    Quill.register("modules/imageDrop", ImageDrop);
    Quill.register("modules/imageResize", ImageResize);

    export default {
        components: {
            Multiselect,
            DatePicker,
            VueEditor,
            Money,
            CourseEducenter
        },
        name: "CourseEdit",
        data() {
            return {
                id: '',
                // Check show
                is_show_course_type: true,
                is_show_level: true,

                editorSettings: {
                    modules: {
                        imageDrop: true,
                        imageResize: {}
                    }
                },
                course: {
                    name: '',
                    name_mkt :'',
                    fee: 0,
                    fee_mkt:0,
                    course_type_id: '',
                    level_in_id_id: '',
                    level_out_id_id: '',
                    sale: '',
                    sale_mkt: '',
                    time:'',
                    time_mkt:'',
                    minimum_tuition_fee: '',
                    video: '',
                    video_mkt: '',
                    use_schedule: false,
                    status: false,
                    is_priority: false,
                    excerpt: '',
                    excerpt_mkt: '',
                    description: '',
                    description_mkt: '',
                    center_id: '',
                    content: '',
                    content_mkt: ''
                },
                course_types: [],
                level_in_id: [],
                level_out_id: [],
                level_common: [],
                centers: [],

                // Cache
                cacheCenter: [],
                cacheLevelIn: [],
                cacheLevelOut: [],
                cacheCourseType: [],
                money: {
                    decimal: ',',
                    thousands: ',',
                    prefix: '',
                    suffix: ' VNĐ',
                    precision: 0,
                    masked: false
                },
                default_photo: "media/users/blank.png",
                current_photo: null,
                image_name: null,
                image_type: null,
                dialog: {
                    title: ''
                },
                dialogFormVisible: false,

            }
        },
        mounted() {
            this.getCourseById();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý khóa học", route: 'profile-1'},
                {title: "Sửa khóa học"}
            ]);
        },
        computed: {
            photo() {
                return this.course.avatar_mkt ? this.course.avatar_mkt : this.default_photo;
            }
        },
        beforeMount() {
            this.id = this.$route.params.id;
        },
        methods: {
            getCourseById() {
                let id = this.$route.params.id;
                this.$store.dispatch(GET_COURSE_BY_ID, id).then((res) => {
                    if (!res.error) {
                        this.course = res.data;
                        this.course.fee_mkt = res.data.fee_mkt ?? ''

                        // this.current_photo = this.course.avatar_mkt;
                        this.updateCacheState(res.data)
                        this.getCenters();
                        this.getCourseType();
                        this.getLevelInAndOut();
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getCenters() {
                this.$store.dispatch(GET_CENTERS, {
                    limit: 10000,
                    status: 1
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getCourseType() {
                this.$store.dispatch(GET_COURSE_TYPES, {
                    per_page: 10000,
                    center_id: this.course.center_id
                }).then((res) => {
                    if (!res.error) {
                        this.course_types = res.data.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getLevelInAndOut() {
                this.$store.dispatch(GET_COURSE_LEVELS, {
                    per_page: 10000,
                    course_type_id: this.course.course_type_id,
                    center_id: this.course.center_id
                }).then((res) => {
                    if (!res.error) {
                        this.level_in_id = res.data.data
                        this.level_out_id = res.data.data
                        this.level_common = res.data.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            updateStateCenter($event) {
                this.course.course_type_id = '';
                this.course.level_in_id = '';
                this.course.level_out_id = '';
                //reset cache
                this.cacheCourseType = [];
                this.cacheLevelIn = [];
                this.cacheLevelOut = [];
                if ($event == null) {
                    // Reset state
                    this.course.center_id = '';
                    this.is_show_course_type = false;
                    this.is_show_level = false;

                } else {
                    let id = $event.id;
                    this.course.center_id = id;
                    this.is_show_course_type = true;
                    this.getCourseType()
                }
            },
            updateStateCourseType($event) {
                // reset cache
                this.cacheLevelIn = [];
                this.cacheLevelOut = [];
                this.course.level_in_id = '';
                this.course.level_out_id = '';

                if ($event == null) {
                    // Reset state
                    this.course.course_type_id = '';


                    this.is_show_level = false;
                } else {
                    let id = $event.id
                    this.course.course_type_id = id;
                    this.getLevelInAndOut();
                    this.is_show_level = true;
                }
            },
            updateStateLevelIn($event) {
                let id = $event.id;
                this.course.level_in_id = id;
                let orderIn = $event.order;
                // Reset data
                this.level_out_id = [];
                this.cacheLevelOut = [];
                this.level_common.map((value) => {
                    if (value.order > orderIn) {
                        this.level_out_id.push(value)
                    }
                })
            },
            updateStateLevelOut($event) {
                let id = $event.id;
                this.course.level_out_id = id;
                let orderOut = $event.order;
                this.level_common.map((value) => {
                    if (value.order > orderOut) {
                        this.level_in_id.push(value)
                    }
                })
            },

            handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
                var formData = new FormData();
                formData.append("image", file);

                ApiService.post('upload-image', formData)
                    .then(({data}) => {
                        let url = data.data; // Get url from response
                        Editor.insertEmbed(cursorLocation, "image", url);
                        resetUploader();
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },
            onFileChange(e) {
                const file = e.target.files[0];
                if (typeof FileReader === "function") {
                    const reader = new FileReader();
                    reader.onload = event => {
                        this.course.avatar_mkt = event.target.result;
                    };
                    reader.readAsDataURL(file);
                    this.image_name = file.name;
                    this.image_type = file.type;
                } else {
                    alert("Sorry, FileReader API not supported");
                }
            },
            urltoFile(url, filename, mimeType) {
                    return (fetch(url)
                            .then(function (res) {
                                return res.arrayBuffer();
                            })
                            .then(function (buf) {
                                return new File([buf], filename, {type: mimeType});
                            }).catch((error) => {
                                return 1;
                            })
                    );
            },

            onSubmit: async function() {
                    let formData = new FormData();

                    //image
                    let avatar = this.photo;
                    if (avatar.search('https') === -1) {
                    let file = await this.urltoFile(avatar, this.image_name, this.image_type);
                    if (file !== 1 && file.name !== 'null') {
                        formData.append('avatar_mkt', file);
                    }

                    } else {
                    formData.append('avatar_mkt', avatar);
                    }

                    for(let key in this.course){
                        formData.append(key, this.course[key]);
                    }



                this.$store.dispatch(UPDATE_COURSE,
                    {id: this.id,
                    params: formData}).then((res) => {
                    if (res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Cập nhật',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.$router.push({name: "course-index"}).then(() => {
                            this.$bvToast.toast(res.message, {
                                title: 'Cập nhật',
                                variant: 'success',
                                solid: true
                            });
                        })
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                });
            },
            updateCacheState(course) {
                if (course.center != null) {
                    this.cacheCenter = course.center
                }
                if (course.course_type != null) {
                    this.cacheCourseType = course.course_type
                }
                if (course.level_in != null) {
                    this.cacheLevelIn = course.level_in
                }
                if (course.level_out != null) {
                    this.cacheLevelOut = course.level_out
                }
            },
            getInfoCourse() {
                this.$store.dispatch(GET_COURSE_BY_ID, this.id).then((res) => {
                    this.course = res.data;
                    this.course.fee_mkt = this.course.fee_mkt ?? 0;
                });
            },
            useDataOld() {
                Swal.fire({
                    title: "Bạn chắc chắn muốn xóa thông tin?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    denyButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.getInfoCourse()
                    }
                });
            },
            handleUseCourse(item) {
                this.dialog.title = 'Chọn chi tiết khóa học muốn sử dụng lại';
                this.dialogFormVisible = true;
            },
            completedUseInfoCourse(item) {
                for (var key in item) {
                    if(item[key] == true) {
                        this.course[key+"_mkt"] = this.course[key];
                    }
                }
                this.dialogFormVisible = false
            },
        }
    }
</script>
<style scoped>
    .mx-datepicker {
        width: 100% !important;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
