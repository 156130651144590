import ApiService from "@/core/services/api.service";

const PATH_HONOR = "honor-personnel/program-honor";
const PATH_HONOR_CATEGORY = "honor-personnel/category-honor";
const PATH_HONOR_NEWSLETTER = "honor-personnel/honor-newsletter";
export const GET_LIST_HONOR = "GET_LIST_HONOR";
export const CREATE_HONOR = "CREATE_HONOR";
export const UPDATE_HONOR = "UPDATE_HONOR";
export const UPDATE_STATUS_HONOR = "honor-personnel/change-status-program";
export const DELETE_HONOR = "DELETE_HONOR";
export const GET_LIST_HONOR_CATEGORY = "GET_LIST_HONOR_CATEGORY";
export const CREATE_HONOR_CATEGORY = "CREATE_HONOR_CATEGORY";
export const UPDATE_HONOR_CATEGORY = "UPDATE_HONOR_CATEGORY";
export const DELETE_HONOR_CATEGORY = "DELETE_HONOR_CATEGORY";
export const GET_LIST_HONOR_DETAIL = "GET_LIST_HONOR_DETAIL";
export const CREATE_HONOR_DETAIL = "CREATE_HONOR_DETAIL";
export const DELETE_HONOR_DETAIL = "DELETE_HONOR_DETAIL";
export const UPDATE_NEWSLETTER = "honor-personnel/update-image";


const state = {};

const getters = {};

const actions = {
    [GET_LIST_HONOR](context, payload) {
        return new Promise(resolve => {
            ApiService.query(PATH_HONOR, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_HONOR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(PATH_HONOR, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_HONOR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(PATH_HONOR, payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_HONOR](context, id) {
        return new Promise((resolve) => {
            ApiService.delete(PATH_HONOR, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response)
                });
        });
    },
    [UPDATE_STATUS_HONOR](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_STATUS_HONOR, {"id": id}).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_LIST_HONOR_CATEGORY](context, id) {
        return new Promise(resolve => {
            ApiService.query(PATH_HONOR_CATEGORY, id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_HONOR_CATEGORY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(PATH_HONOR_CATEGORY, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_HONOR_CATEGORY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(PATH_HONOR_CATEGORY, payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_HONOR_CATEGORY](context, id) {
        return new Promise((resolve) => {
            ApiService.delete(PATH_HONOR_CATEGORY, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response)
                });
        });
    },
    [GET_LIST_HONOR_DETAIL](context, id) {
        return new Promise(resolve => {
            ApiService.query(PATH_HONOR_NEWSLETTER, id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_HONOR_DETAIL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(PATH_HONOR_NEWSLETTER, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_NEWSLETTER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_NEWSLETTER}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_HONOR_DETAIL](context, id) {
        return new Promise((resolve) => {
            ApiService.delete(PATH_HONOR_NEWSLETTER, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response)
                });
        });
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};