<template>
  <div>
    <!-- dialog thêm mới giảng viên -->
    <b-modal id="handle-candidate-teacher" @change="showInfo()"
      :title="teacher.type === 'edit' ? 'Sửa giảng viên' : 'Thêm mới giảng viên'" size="xl" ok-only centered
      hide-footer>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" autocomplete="off" @submit.prevent="handleSubmit(handleOk)">
          <div v-loading="loadingShowinfo" class="row">
            <div class="col-md-4 mt-2">
              <label class="fs-1 font">Họ tên<span class="text-danger"> *</span></label>
              <ValidationProvider vid="name" name="Họ và tên" rules="required" v-slot="{ errors, classes }">
                <el-input :class="classes" type="text" placeholder="Nhập họ và tên" v-model="form.name" />
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-4 mt-2">
              <div class="form-group">
                <label class="fs-1 font">Số điện thoại<span class="text-danger"> *</span></label>
                <ValidationProvider vid="phone" name="Số điện thoại"
                  :rules="{ required: true, regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }" v-slot="{ errors, classes }">
                  <el-input type="number" v-model="form.phone" :class="classes" placeholder="Số điện thoại" />
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4 mt-2">
              <div class="form-group">
                <label class="fs-1 font">Email<span class="text-danger"> *</span></label>
                <ValidationProvider vid="email" name="Email" rules="required|email" v-slot="{ errors, classes }">
                  <el-input  maxlength="50" type="email" placeholder="Email" :class="classes" v-model="form.email" />
                  <div class="fv-plugins-message-container">
                    <div data-field="Email đăng nhập" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4 mt-2">
              <div class="form-group w-100">
                <label class="fs-1 font">Trung tâm<span class="text-danger"> *</span></label>
                <ValidationProvider vid="center" name="Trung tâm" rules="required" v-slot="{ errors, classes }">
                  <el-select :disabled ="teacher.type === 'edit'" :class="classes" class=" w-100 shadow-sm" placeholder="Chọn trung tâm" v-model="form.center_ids"
                    collapse-tags multiple filterable clearable>
                    <el-option v-for="item in centers" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="Trung tâm" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4 mt-2">
              <div class="form-group w-100">
                <label class="fs-1 font">Khu vực<span class="text-danger"> *</span></label>
                <ValidationProvider vid="area" name="Khu vực" rules="required" v-slot="{ errors, classes }">
                  <el-select :class="classes" class=" w-100 shadow-sm" placeholder="Chọn khu vực" v-model="form.area_id"
                    collapse-tags clearable>
                    <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="Khu vực" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4 mt-2">
              <div class="form-group w-100">
                <label class="fs-1 font">Trainer<span class="text-danger"> *</span></label>
                <ValidationProvider vid="area" name="Trainer" rules="required" v-slot="{ errors, classes }">
                  <el-select :class="classes" class=" w-100 shadow-sm" placeholder="Chọn trainer" v-model="form.trainer_ids"
                             collapse-tags clearable multiple>
                    <el-option v-for="item in trainers" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="Trainer" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <el-button :disabled="loading_create" size="small" class="float-right text-white rounded"
              style="background-color: rgba(93, 4, 176, 1);" native-type="submit">
              <i v-bind:class="[loading_create ? 'el-icon-loading' : '']"></i>
              Xác nhận
            </el-button>
          </div>
          <div>
            <el-button style="background-color: rgba(202, 202, 202, 1)" size="small" class="float-right mr-3 rounded"
              @click="closeModal">
              <i></i>
              Huỷ bỏ
            </el-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ADD_CANDIDATE_TEACHER, SHOW_CANDIDATE_TEACHER, UPDATE_CANDIDATE_TEACHER } from '../../../../core/services/store/course/classes.module';

export default {
  name: "FormCandidate",
  components: {},
  props: {
    centers: Array,
    teacher: Object,
    trainers: Array,
  },
  mounted() {

  },
  data() {
    return {
      errors: '',
      loading_create: false,
      dialogVisible: false,
      form: {
        name: '',
        email: '',
        phone: '',
        center_ids: [],
        area_id: '',
        trainer_ids: []
      },
      loadingShowinfo: false,
      infoEdit: {},
      areas: [
        { id: 1, name: 'Hà Nội và tỉnh' },
        { id: 2, name: 'TP HCM' },
      ]
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    handleOk(bvModalEvent) {
      // bvModalEvent.preventDefault();
      if (this.teacher.type === 'edit') {
        return this.editTeacher();
      }
      this.createTeacher();
    },

    editTeacher() {
      this.loading_create = true;
      this.$store.dispatch(UPDATE_CANDIDATE_TEACHER, (this.form)).then((res) => {
        this.$notify({
          title: "Thành công",
          message: "Sửa giảng viên thành công",
          type: "success",
        });
        this.closeModal();
      }).catch((e) => {
        this.$notify({
          title: "Lỗi",
          message: "Đã xảy ra lỗi khi cập nhật giảng viên.",
          type: "error",
        });
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
        this.loading_create = false;
      })
        .finally(() => {
          this.loading_create = false;
        });
    },
    createTeacher() {
      this.loading_create =true;
      this.$store.dispatch(ADD_CANDIDATE_TEACHER, (this.form)).then((res) => {
        this.$notify({
          title: "Thành công",
          message: "Thêm giảng viên thành công",
          type: "success",
        });
        this.loading_create = false;
        this.closeModal();
      }).catch((e) => {
        this.$notify({
          title: "Lỗi",
          message: e.data.message ?? "Đã xảy ra lỗi khi thêm mới giảng viên.",
          type: "error",
        });
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
        this.loading_create = false;
      }).finally(() => {
        this.loading_create = false;
      });
    },
    showInfo() {
      if (this.teacher.type === 'edit') {
        this.loadingShowinfo = true;
        this.$store.dispatch(SHOW_CANDIDATE_TEACHER, { id: this.teacher.id }).then((res) => {
          this.form.id = res.data.id,
            this.form.name = res.data.name,
            this.form.email = res.data.email,
            this.form.phone = res.data.phone,
            this.form.center_ids = res.data.center_ids,
            this.form.area_id = res.data.area_status,
            this.form.trainer_ids = res.data.trainer_ids
        }).catch((e) => {
        this.$notify({
          title: "Lỗi",
          message: "Đã xảy ra lỗi lấy thông tin giảng viên.",
          type: "error",
        });
        this.loadingShowinfo = false;
      }).finally(() => {
        this.loadingShowinfo = false;
      });
      }else {
        if (this.teacher.type === 'create') {
        this.form = {
          id: '',
          name: '',
          email: '',
          phone: '',
          center_ids: [],
          area_id: '',
        }
      }
      }
    },
    closeModal() {
      this.$bvModal.hide('handle-candidate-teacher');
      this.$emit("reload");
    }
  }
}
</script>

<style>
#handle-candidate-teacher {
  top: -100px;
}

#handle-candidate-teacher .font {
  font-size: 14px;
  font-weight: 600;
  color: rgba(69, 70, 116, 1);
}
</style>