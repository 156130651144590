<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Duyệt trả góp</h3>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-md-3 form-group">
            <label for="installment_keyword">Tên, SĐT khách hàng</label>
            <el-input id="installment_keyword" placeholder="Tên, SĐT khách hàng" v-model="query.customer_keyword"
                      suffix-icon="el-icon-search"></el-input>
          </div>
          <div class="col-md-3 form-group">
            <label for="customer_keyword">Tên, SĐT người làm trả góp</label>
            <el-input id="customer_keyword" placeholder="Tên, SĐT người làm trả góp" v-model="query.installment_keyword"
                      suffix-icon="el-icon-search"></el-input>
          </div>
          <div class="col-md-3 form-group">
            <label for="installment_method_id">Tình trạng trả góp</label>
            <el-select class="w-100" id="installment_method_id" clearable filterable multiple :collapse-tags="true"
                       placeholder="Chọn tình trạng trả góp" v-model="query.installment_method_id">
              <el-option
                  v-for="item in installmentMethods"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
<!--          <div class="col-md-3 form-group">-->
<!--            <label for="tuition_payment_id">Phân loại trả góp</label>-->
<!--            <el-select class="w-100" id="tuition_payment_id" clearable filterable multiple :collapse-tags="true"-->
<!--                       placeholder="Chọn phân loại trả góp" v-model="query.tuition_payment_id">-->
<!--              <el-option-->
<!--                  v-for="item in tuitionPayments"-->
<!--                  :key="item.id"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id"-->
<!--              >-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </div>-->
          <div class="col-md-3 form-group">
            <label for="range_date">Thời gian tạo</label>
            <date-picker id="range_date" v-model="query.range_date" type="date" format="DD-MM-YYYY" value-type="YYYY-MM-DD" range
                         placeholder="Chọn ngày tạo"
            ></date-picker>
          </div>
          <div class="col-md-3 form-group">
            <label for="center_ids">Trung tâm</label>
            <el-select class="w-100" id="center_ids" v-model="query.center_ids"
                       clearable filterable multiple :collapse-tags="true"
                       placeholder="Trung tâm" @change="changeCenter">
              <el-option
                  v-for="item in centers"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-md-3 form-group">
            <label for="branch_ids">Chi nhánh</label>
            <el-select class="w-100" id="branch_ids" v-model="query.branch_ids" clearable filterable multiple :collapse-tags="true"  placeholder="Chi nhánh">
              <el-option
                  v-for="item in branches"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="col-md-3 d-flex align-items-center justify-content-start">
            <div class="">
              <button class=" btn btn-primary font-weight-bold mr-2"
                      :class="is_disable_search ? 'spinner spinner-white spinner-left' : ''"
                      :disabled="is_disable_search"
                      @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive overflow-auto mt-3">
          <table :aria-busy="isBusy" class="table table-hover table-bordered table-hover b-table text-center">
            <thead class="thead-light">
              <tr>
                <th scope="col" class="min-w-30px">STT</th>
                <th scope="col" class="min-w-100px">Họ tên KH</th>
                <th scope="col" class="min-w-100px">SĐT KH</th>
                <th scope="col" class="min-w-100px">Mã hợp đồng</th>
                <th scope="col" class="min-w-100px">Người làm trả góp</th>
                <th scope="col" class="min-w-100px">SĐt người làm trả góp</th>
                <th scope="col" class="min-w-120px">Địa điểm trả góp</th>
                <th scope="col" class="min-w-120px">Phân loại trả góp</th>
                <th scope="col" class="min-w-120px">Tình trạng trả góp</th>
                <th scope="col" class="min-w-120px">Thời gian tạo</th>
                <th scope="col" class="min-w-100px">Hành động</th>
              </tr>
            </thead>
            <tbody v-if="isBusy">
              <tr role="row" class="b-table-busy-slot">
                <td colspan="16" role="cell" class="">
                  <div class="text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="installments.length > 0">
              <tr v-for="(item, index) in installments" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.contract_id }}</td>
                <td>{{ item.customer_name }}</td>
                <td>{{ item.customer_phone }}</td>
                <td>{{ item.branch }}</td>
                <td>{{ item.tuition_payment }}</td>
                <td>{{ item.installment_method }}</td>
                <td>{{ item.created_at | formatDateTime }}</td>
                <td>
                  <router-link :to="{name: 'installment-information-detail', params:{id: item.id}}" title="Xem chi tiết" class="btn btn-sm btn-icon btn-outline-success">
                    <i class="far fa-eye"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="10">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="edu-paginate mx-auto text-center" v-if="installments.length > 0">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import {GET_CENTERS} from "@/core/services/store/center/centers.module";
import {GET_BRANCHES} from "@/core/services/store/center/branch.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import '@/assets/sass/table/tableCommon.scss'
import {
  GET_INSTALLMENT_INFORMATION,
  GET_INSTALLMENT_METHODS,
  GET_TUITION_PAYMENT
} from "@/core/services/store/installment/installment.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "InstallmentInformationIndex",
  components: {
    DatePicker
  },
  data() {
    return {
      path: '',
      is_disable_search: false,
      isBusy: false,
      centers: [],
      branches: [],
      query: {
        keyword: '',
        center_ids: [],
        branch_ids: [],
        installment_keyword: '',
        customer_keyword: '',
        installment_method_id: [],
        tuition_payment_id: [],
        range_date: [],
      },
      installments: [],
      installmentMethods: [],
      tuitionPayments: [],
      createUsers: [],
      // Paginate
      page: 1,
      last_page: 1,
    }
  },
  created() {
    this.getList();
    this.getCenters();
    this.getBranches();
    this.getInstallmentMethods();
    this.getTuitionPayments();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý tài chính", route: '#'},
      {title: "Duyệt trả góp"}
    ]);
  },
  methods: {
    getInstallmentMethods: function () {
      this.$store.dispatch(GET_INSTALLMENT_METHODS).then((res) => {
        this.installmentMethods = res.data;
      });
    },
    getTuitionPayments: function () {
      this.$store.dispatch(GET_TUITION_PAYMENT).then((res) => {
        this.tuitionPayments = res.data;
      });
    },
    getCenters: function () {
      this.$store.dispatch(GET_CENTERS, {
        limit: 10000,
        status: 1,
      }).then((res) => {
        this.centers = _.map(res.data.data, (item) => {
          item = {value: item.id, label: item.name};
          return item;
        })
      });
    },
    getBranches: function () {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        status: 1,
        center_ids: this.query.center_ids
      }).then((res) => {
        this.branches = _.map(res.data.data, (item) => {
          item = {value: item.id, label: item.name};
          return item;
        })
      });
    },
    changeCenter() {
      this.getBranches();
    },
    getList() {
      this.pullParamsUrl();
      this.callApiGetList();
    },
    pullParamsUrl() {
      this.pullParamsFromUrl(this.query);
      this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    },
    pushParamsToUrl() {
      let query = {page: this.page, ...this.query};
      this.pushParamsUrl(query);
    },
    clickCallback(obj) {
      this.page = obj
      let query = {page: this.page, ...this.query};
      this.pushParamsUrl(query);
      this.callApiGetList()
    },
    callApiGetList() {
      this.isBusy = true;
      let payload = {...this.query, page: this.page}
      if (this.query.range_date?.length) {
        payload.start_date = this.query.range_date[0];
        payload.end_date = this.query.range_date[1];
      }
      this.$store.dispatch(GET_INSTALLMENT_INFORMATION, payload).then((res) => {
        this.isBusy = false;
        this.installments = res.data;
        this.last_page = res.pagination.last_page;
      }).catch((res) => {
        this.noticeMessage('error', 'Thất bại', res?.data?.message);
      }).finally(() => this.is_disable_search = false);
    },
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsToUrl()
      this.callApiGetList()
    },
  }
}
</script>

<style scoped>

</style>