import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "../auth.module";


const _ = require('lodash');
export const GET_LIST_SCHOOL = 'GET_LIST_SCHOOL';
export const CREATE_SCHOOL = 'CREATE_SCHOOL';
export const DELETE_SCHOOL = 'DELETE_SCHOOL';
export const CHANGE_STATUS_SCHOOL = 'CHANGE_STATUS_SCHOOL';
export const EDIT_SCHOOL = 'EDIT_SCHOOL';
export const DETAIL_SCHOOL = 'DETAIL_SCHOOL';

const state = {};

const getters = {};
const actions = {
    [GET_LIST_SCHOOL](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`affiliated-school`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [CREATE_SCHOOL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`affiliated-school`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DELETE_SCHOOL](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`affiliated-school`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [CHANGE_STATUS_SCHOOL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`affiliated-school/change-status/${payload.id}`,{status:payload.status}).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EDIT_SCHOOL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`affiliated-school/${payload.id}`,payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DETAIL_SCHOOL](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`affiliated-school`,id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
};


const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};