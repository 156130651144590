<template>
  <b-modal
    @hide="onHide"
    size="l"
    :centered="true"
    :scrollable="true"
    ref="modal-img-identity"
    hide-footer
    title="Thông tin xác minh:"
    class="modal-account-type-avatar"
  >
    <p>
      Họ tên: <b> {{ userIdentity.name }} </b>
    </p>
    <p>
      Địa chỉ thường trú:
      <b> {{ userIdentity.profile && userIdentity.profile.address && userIdentity.profile.address != 'null' ? userIdentity.profile.address : '' }} </b>
    </p>
    <p>
      Giới tính:
      <b> {{ userIdentity.profile && userIdentity.profile.gender }} </b>
    </p>
    <p>
      Ngày sinh:
      <b>
        {{ userIdentity.profile && formatDate(userIdentity.profile.birthday) }}
      </b>
    </p>
    <div class="d-flex">
      <div
        v-for="(veryfy_image, i) in userIdentity.veryfy_images_request"
        :key="i"
        @click="showImageIdentity"
        class="img-identity"
      >
        <img
          :src="veryfy_image.url"
          
        />
          <!-- src="https://beta-edutalk-cdn.sgp1.digitaloceanspaces.com/users/1652759826-photo-2022-05-09-08-48-44.png" -->
        
      </div>
    </div>
    <div
      id="modal-show-img"
      class="detail-img-modal"
      onclick="this.style.display='none'"
      style="display: none"
    >
      <div class="detail-img-modal-content detail-img-animate-zoom">
        <span
          class="
            detail-img-button
            detail-img-hover-red
            detail-img-xlarge
            detail-img-display-topright
          "
          >x</span
        >
        <img id="img-detail" style="width: 100%" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "Detail-image-identity",
  props: {
    showModal: { default: false },
    userIdentity: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {
    onHide(evt) {
      this.$emit("clicked", true);
    },
    hideModal() {
      this.$refs["modal-img-identity"].hide();
    },
    showImageIdentity(element) {
      document.getElementById("img-detail").src = element.srcElement.src;
      document.getElementById("modal-show-img").style.display = "block";
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      return moment(String(date)).format("DD/MM/YYYY");
    },
  },

  watch: {
    showModal() {
      if (this.showModal) {
        this.$refs["modal-img-identity"].show();
      }
    },
  },
};
</script>

<style>
.img-identity {
  width: 100px;
  height: 100px;
}
.img-identity:not(:first-child) {
  margin-left: 20px;
}
.img-identity img {
  width: 100%;
}
.img-identity:hover {
  opacity: 0.5;
  cursor: pointer;
}

.detail-img-modal {
  z-index: 3;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.detail-img-xlarge {
  font-size: 24px;
}
.detail-img-display-topright {
  position: absolute;
  right: 0;
  top: 0;
}
.detail-img-button {
  border: none;
  display: inline-block;
  padding: 4px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}
.detail-img-animate-zoom {
  animation: animatezoom 0.6s;
}
.detail-img-modal-content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  width: 600px;
}
@media (max-width: 730px) {
  .second {
    margin-left: 20px;
  }
  .detail-img-modal-content {
    width: 200px;
  }
  .detail-img-xlarge {
    font-size: 12px;
  }
  .detail-img-button {
    padding: 0px 8px;
  }
}
</style>