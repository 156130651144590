<template>
    <div class="example mb-10">
      <div class="example-preview table-responsive">
        <table class="table table-bordered table-hover table-vertical-center">
          <thead>
            <tr>
              <th scope="col" class="min-w-50px">STT</th>
              <th scope="col" class="min-w-110px">Thông tin sửa</th>
              <th scope="col" class="min-w-200px">Nội dung</th>
              <th scope="col" class="min-w-200px">Người sửa</th>
              <th scope="col" class="min-w-100px">Thời gian sửa</th>
            </tr>
          </thead>
          <tbody v-if="logs.length">
            <tr v-for="(value, index) in logs" :key="index">
              <td>{{ index + 1 }}</td>
              <td class="text-break">
                <p v-for="(item_p, index_p) in value.type_log.type" :key="index_p">
                  {{item_p}}
                </p>
              </td>
              <td class="text-break">
                <p v-for="(item_s, index_s) in value.type_log.message" :key="index_s">
                  {{item_s}}
                </p>
              </td>
              <td>{{ value.user ? (value.user.id + 100000) + ' - ' + value.user.name : ''}}</td>
              <td class="text-break">{{ value.created_at | formatDateTimeAsia }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5"><span class="font-italic"> Chưa có dữ liệu </span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>

<script>
import {GET_CHANGE_LOGS} from "@/core/services/store/contract/studentAmount.module";

export default {
  name: "HistoryUpdate",
  props: {
    contractStudentAmount: {
      type: Object,
      default: {}
    },
  },
  data(){
    return{
      logs: [],
    }
  },
  watch: {
    contractStudentAmount: {
      handler() {
        this.$store.dispatch(GET_CHANGE_LOGS, this.contractStudentAmount.id).then((res) => {
          this.logs = res.data
        })
      },
      immediate: true,
      deep: true
    }

  },
  methods: {}
}
</script>

<style scoped>

</style>