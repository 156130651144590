<template>

  <div class="bg-white rounded-sm px-4 pt-4 pb-2 mb-3">
    <div class="row ">
      <div class="col-6 d-flex  align-content-center">
        <img src="../../../../../assets/img/loaImg.png">
        <h4 class="ml-2 mb-0 mt-1">{{ resultTest?.nameType }}</h4>
      </div>
      <div class="col-6 score">
        <h4 class="">Số điểm: {{ resultTest?.totalPoint ?? resultTest?.totalPointAuto }} /{{ pointLadder }}</h4>
      </div>
    </div>
    <div class="mt-3">
      <h4 v-if="resultTest?.typePlagiarism !== 4">Nhận xét:</h4>
      <p v-if="resultTest.totalQuestions" class="mb-0">Học viên đúng
        {{ resultTest.correctQuestions }}/{{ resultTest.totalQuestions }} câu trong kỹ năng này</p>
      <div v-else>
        <div v-if="resultTest?.typePlagiarism !== 4">
          <p class="mb-0">Học viên đọc phần nhận xét chi tiết để hiểu rõ những tiêu chí của kĩ năng này</p>
          <div class="text-center mt-2">
            <a v-b-toggle="`${resultTest?.type}`">xem thêm <i class="fa fa-chevron-down text-xl ml-1 "
                                                              style="font-size: 10px"></i></a>
            <b-collapse :id="`${resultTest?.type}`">
              <b-card class="text-left " v-if="!check0And1Point">
                <div v-for="(dataComment,index) in resultTest.dataComments" class="mb-5">
                  <h4 style="font-weight: bold">Tiêu chí {{ index + 1 }}: {{ dataComment.nameCriteria }}</h4>
                  <p style="white-space: pre-line">
                    {{ dataComment.autoComment }}
                  </p>
                  <h4>Chi tiết :</h4>
                  <p style="white-space: pre-line">
                    {{ dataComment.comment }}
                  </p>
                  <hr>
                </div>
              </b-card>
              <b-card class="text-left " v-else>
                <div class="mb-5">
                  <p style="white-space: pre-line">
                    {{ resultTest?.dataComments[0].autoComment }}
                  </p>
                  <h4 v-if="resultTest?.dataComments[0].comment">Chi tiết :</h4>
                  <p style="white-space: pre-line">
                    {{ resultTest?.dataComments[0].comment }}
                  </p>
                  <hr>
                </div>
              </b-card>
            </b-collapse>
          </div>
        </div>
        <div v-if="+resultTest.type === writing && this.resultTest?.typePlagiarism">
          <hr>
          <p style="color: #FF0000;font-weight: bold">Bài viết có dấu hiệu đạo văn!</p>
          <div class="row">
            <div class="col-3">
              <p style="height: 30px">Tỉ lệ đạo văn: </p>
              <p style="height: 30px">Phương án xử lý:</p>
              <p>Nhận xét, dẫn chứng:</p>
            </div>
            <div class="col-8" style="padding-left: 0 !important;">
              <p style="height: 30px">{{dataPlagiarism?.label}}</p>
              <p style="height: 30px">{{dataPlagiarism?.problemSolution}}</p>
              <p style="white-space: pre-wrap">{{resultTest?.descriptionPlagiarism??''}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {toRoundingMark} from "@/view/pages/test-input/models/result_test";
import {PLAGIARISM_RATE, WRITING} from "@/core/option/testInputOption";

export default {
  name: "CommentsTest",
  data(){
    return {
    writing: WRITING
    }
  },
  props: {
    resultTest: {
      type: Object,
      default() {
        return {}
      }
    },
    testIELTS: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  mounted() {

  },
  computed: {
    totalPointTest() {
      return this.testIELTS ? this.resultTest?.totalPoint ?? this.resultTest?.totalPointAuto : toRoundingMark(this.resultTest?.totalPoint ?? this.resultTest?.totalPointAuto * 10 / 9)
    },
    pointLadder() {
      return this.testIELTS ? "9.0" : "10"
    },
    check0And1Point() {
      return [0, 1].includes(+this.resultTest.dataComments[0].point)
    },
    dataPlagiarism(){
      if(this.resultTest?.typePlagiarism){
        return PLAGIARISM_RATE.find((e)=>e.value === +this.resultTest?.typePlagiarism)
      }
    }
  }

}
</script>

<style scoped>
img {
  width: 20px;
}

.score {
  text-align: right;
}

h4 {
  font-size: 13px;
}
</style>