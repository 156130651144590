<template>
  <div>
    <button
      class="btn btn-success font-weight-bold btn-sm"
      v-b-modal.Modal_Add_Gift
    >
      <i class="flaticon2-add-1"></i> {{isVoucher ? 'Thêm voucher' : 'Thêm quà tặng'}}
    </button>

    <b-modal
      id="Modal_Add_Gift"
      :title="isVoucher ? 'Thêm mới voucher' : 'Thêm mới quà tặng'"
      centered
      hide-footer
      ref="Modal_Add_Gift"
      size="lg"
      @hidden="clearData()"
    >
      <ValidationObserver v-slot="{}" ref="form">
        <form class="form" @submit.prevent autocomplete="off">
          <div class="row">
            <div class="col-lg-12 col-xs-12 mb-3">
              <p><span v-text="isVoucher ? 'Tên voucher' : 'Tên quà tặng'"></span> <span class="text-danger">*</span></p>
              <ValidationProvider
                :name="isVoucher ? 'Tên voucher' : 'Tên quà tặng'"
                rules="required"
                v-slot="{ errors }"
                vid="name"
              >
                <el-input :placeholder="isVoucher ? 'Nhập tên voucher' : 'Nhập tên quà tặng'" v-model="name" />
                <div class="fv-plugins-message-container">
                  <div
                    data-field="name"
                    data-validator="notEmpty"
                    class="fv-help-block"
                  >
                    {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-lg-12 col-xs-12 mb-3" v-if="!isVoucher">
              <ValidationProvider
                name="Ảnh quà tặng"
                rules="required"
                v-slot="{ errors }"
                vid="file"
              >
                <el-upload
                  ref="upload"
                  accept=".png, .jpg, .jpeg"
                  :limit="1"
                  action=""
                  :file-list="fileList"
                  :on-change="handleChange"
                  :on-remove="handleRemove"
                  :auto-upload="false"
                  :multiple="false"
                  list-type="picture"
                  drag
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                </el-upload>

                <input type="hidden" name="profile_avatar_remove"/>
                <div class="fv-plugins-message-container">
                  <div
                    data-field="name"
                    data-validator="notEmpty"
                    class="fv-help-block"
                  >
                    {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="d-flex d-flex flex-row-reverse"></div>
          <div class="d-flex d-flex justify-content-end mt-4">
            <el-button
              type="button"
              class="btn btn-secondary mr-4"
              @click="cancelModal()"
            >
              Hủy
            </el-button>
            <el-button
              :disabled="load"
              :loading="load"
              type="button"
              class="btn btn-success"
              @click="createGift()"
            >
              Xác nhận
            </el-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import { CREATE_LIST_GIFT } from "@/core/services/store/rewards-programs/rewardsPrograms.module";
import {mapGetters} from "vuex";

export default {
  name: "ModelAddGift",
  data() {
    return {
      name: null,
      load: false,
      fileList: [],
      file: '',
      image_name: null,
      image_type: null,
      default_photo: "media/users/blank.png",
      current_photo: null,
      disabled: false

    };
  },
  props: {
    isVoucher: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
    ...mapGetters(["errorsAccountType"]),

  },
  methods: {
    cancelModal() {
      this.$refs["Modal_Add_Gift"].hide();
    },
    async createGift() {
      function urltoFile(url, filename, mimeType) {
        return (fetch(url)
            .then(function (res) {
              return res.arrayBuffer();
            })
            .then(function (buf) {
              return new File([buf], filename, {type: mimeType});
            }).catch((error) => {
              return 1;
            })
        );
      }
    if (!this.name) {
      this.name = ''
    }
      let avatar = this.photo;
      let formData = new FormData;
      formData.append('name', this.name);
      formData.append('spin_reward_program_id', this.$route.params.id);
      formData.append('program_type', this.isVoucher ? 1 : 2);
      let file = await urltoFile(avatar, this.image_name, this.image_type);
      if (!this.fileList.length) {
        file = ''
      }
      if (file !== 1 && file.name !== 'null') {
        formData.append('file', file);
      }
      this.load = true;
      this.$store
        .dispatch(CREATE_LIST_GIFT, formData)
        .then((data) => {
          this.$message.success(data.message);
          this.$refs["Modal_Add_Gift"].hide();
          this.$emit("doneAddGift");
        })
        .catch((e) => {
          if (e.data?.data?.message_validate_form) {
            this.$refs.form.setErrors(e.data.data.message_validate_form);
          }
          if (!this.name) {
            return;
          }
          this.$message({
            type: "error",
            message: `${e.data.message}`,
          });
        })
        .finally(() => {
          this.load = false;
        });
    },
    clearData() {
      this.name = '';
      this.fileList = []
    },
    handleRemove() {
      this.fileList = []
    },
    onFileChange(e) {
      const file = e.target.files[0];
        if (typeof FileReader === "function") {
            const reader = new FileReader();
            reader.onload = event => {
                this.current_photo = event.target.result;
            };
            reader.readAsDataURL(file);
            this.image_name = file.name;
            this.image_type = file.type;
        } else {
            alert("Sorry, FileReader API not supported");
        }
    },
    handleChange(files, fileList) {
      this.fileList = fileList;
      const file = files.raw;
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
        this.image_name = file.name;
        this.image_type = file.type;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    }
  },
};
</script>

<style>
.font-size {
    font-size: 50px;
    padding: 10px;
    border: 1px solid #d1d3e0;
}
</style>
