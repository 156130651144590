<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Chấm điểm kiếm tra lớp {{test.classroom ? test.classroom.name : ''}}</h3>
            </div>
        </div>
        <div class="card-body">
            <!--begin::Example-->
            <div class="row">
                <div class="col-md-2">
                    <div class="row">
                        <div class="col-md-6 font-weight-bold">Lớp học:</div>
                        <div class="col-md-6"><span class="badge badge-success">{{test.classroom ? test.classroom.name : ''}}</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-6 font-weight-bold">Buổi kiểm tra:</div>
                        <div class="col-md-6"><span class="badge badge-success">{{typeDefine[test.type]}}</span></div>
                    </div>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-2">
                    <div class="form-group">
                        <el-input placeholder="Tên học viên" v-model="query.student_name"></el-input>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <el-select v-model="query.tester_id" filterable class="w-100" placeholder="Chọn tester"
                                   clearable>
                            <el-option
                                    v-for="item in testers"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                <span style="float: left">{{ item.name }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <el-select v-model="query.status" filterable class="w-100" placeholder="Trạng thái"
                                   clearable>
                            <el-option
                                    v-for="item in status"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                <span style="float: left">{{ item.label }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="d-flex justify-content-between">
                        <button class=" btn btn-primary font-weight-bold mr-2"
                                :disabled="is_disable_search"
                                @click="searchData"
                                :class="{'spinner spinner-white spinner-left' : is_disable_search}"
                        >
                            <i class="fa fa-search"></i> Tìm kiếm
                        </button>
                    </div>
                </div>
            </div>

            <div class="example mb-10">
                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">Họ và tên</th>
                            <th scope="col">Số điện thoại</th>
                            <th scope="col">Người test nói</th>
                            <th scope="col">Trạng thái</th>
                            <th scope="col">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in students" :key="index">
                            <td>{{index + 1}}</td>
                            <td>{{item.user ? item.user.name : ''}}</td>
                            <td>{{item.user ? item.user.phone : ''}}</td>
                            <td>
                                <el-select
                                        :disabled="item.status == 4 || item.status == 5 || item.status == 1 || item.status == 2"
                                        v-model="item.tester_id"
                                        placeholder="Chọn tester" clearable
                                        @input="updateTester($event, item)"
                                >
                                    <el-option
                                            v-for="item in testers"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                            <td><span class="badge"
                                      :class="defineStatusCss[item.status]">{{defineStatus[item.status]}}</span></td>
                            <td class="d-flex">
                                <ModalConfirmDoHomework
                                        v-if="item.status == 0 || item.status == 3"
                                        :item="item"
                                        :test="test"
                                        @approve-success="getStudentByClass"
                                ></ModalConfirmDoHomework>
                                <ButtonRedoingTest
                                        v-if="item.status == 2 && test.type == 'last'"
                                        :item="item"
                                        :test="test"
                                ></ButtonRedoingTest>
                                <ModalMark
                                        :item="item"
                                        :test="test"
                                        @mark-success="getStudentByClass"
                                ></ModalMark>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="edu-paginate mx-auto text-center">
            <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                    :page-class="'page-item'"
            >
            </paginate>
        </div>
    </div>

</template>

<script>
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {GET_TESTERS} from "../../../core/services/store/test/test.module";
    import ModalConfirmDoHomework from "./components/ModalConfirmDoHomework";
    import ModalMark from "./components/ModalMark";
    import {
        GET_LIST_TEST,
        GET_LIST_TEST_DETAIL,
        GET_STUDENT_BY_CLASS,
        UPDATE_TESTER_ID
    } from "../../../core/services/store/exam/exam.module";
    import Swal from "sweetalert2";
    import ButtonRedoingTest from "./components/ButtonRedoingTest";

    export default {
        name: "MarkByClass",
        components: {
            ButtonRedoingTest,
            ModalMark,
            ModalConfirmDoHomework,
            DatePicker
        },
        data() {
            return {
                page: 1,
                last_page: 1,
                is_disable_search: false,
                testers: [],
                query: {
                    student_name: '',
                    tester_id: '',
                    status: '',
                },

                status: [
                    {
                        label: "Tất cả",
                        value: '',
                    },
                    {
                        label: "Làm bài",
                        value: 3,
                    },
                    {
                        label: "Đã nộp bài",
                        value: 4,
                    },
                    {
                        label: "Đã chấm xong",
                        value: 5,
                    },

                ],

                typeDefine: {
                    mid: 'Thi giữa khóa',
                    last: 'Cuối khóa',
                },

                students: [],
                test: {},
                defineStatus: {
                    0: 'Test nói',
                    1: 'Đã chấm xong',
                    2: 'Đã chấm xong',
                    3: 'Làm bài',
                    4: 'Đã nộp bài',
                    5: 'Đã chấm xong',
                },
                defineStatusCss: {
                    0: 'badge-warning',
                    1: 'badge-secondary',
                    2: 'badge-secondary',
                    3: 'badge-success',
                    4: 'badge-primary',
                    5: 'badge-secondary',
                }
            }
        },
        async mounted() {
            await this.pullParamUrl();
            this.getListTestById();
            this.getTesters();
            this.getStudentByClass();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Danh sách buổi kiểm tra", route: 'exam-index'},
                {title: "Chấm điểm"}
            ]);
        },
        methods: {
            getListTestById() {
                let list_test_id = this.$route.params.list_test_id;
                this.$store.dispatch(GET_LIST_TEST_DETAIL, {
                    id: list_test_id
                }).then((data) => {
                    this.test = data.data;
                });
            },
            clickCallback(obj) {
                this.page = obj;
                this.pushParamsUrl();
                this.getStudentByClass()
            },
            pushParamsUrl() {
                this.$router.push({
                    path: '',
                    query: {
                        page: this.page,
                        ...this.query
                    }
                })
            },
            pullParamUrl() {
                this.query.student_name = this.$route.query.student_name;
                this.query.tester_id = this.$route.query.tester_id ? parseInt(this.$route.query.tester_id) : '';
                this.query.status = this.$route.query.status ? parseInt(this.$route.query.status) : '';
            },
            getStudentByClass() {
                this.is_disable_search = true;
                let class_id = this.$route.params.class_id;
                let list_test_id = this.$route.params.list_test_id;
                this.$store.dispatch(GET_STUDENT_BY_CLASS, {
                    class_id: class_id,
                    list_test_id: list_test_id,
                    page: this.page,
                    ...this.query
                }).then((data) => {
                    this.is_disable_search = false;
                    this.students = data.data;
                    this.last_page = data.pagination.last_page;
                    this.is_disable_search = false;
                })
            },

            getTesters() {
                this.$store.dispatch(GET_TESTERS).then((data) => {
                    if (!data.error) {
                        this.testers = data.data;
                    }
                })
            },
            updateTester($event, item) {
                this.$store.dispatch(UPDATE_TESTER_ID, {
                    tester_id: $event,
                    course_student_id: item.id,
                    schedule_test_list_id: this.$route.params.list_test_id,
                }).then((data) => {
                    if (!data.error) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: data.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                })
            },
            searchData() {
                this.pushParamsUrl();
                this.getStudentByClass();
            }
        }
    }
</script>

<style scoped>
    .font-weight-bold {
        font-weight: bold !important;
    }
</style>