<template>
  <fragment>
    <li class="menu-section">
      <h4 class="menu-text">Quản lý trung tâm hỗ trợ</h4>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-headset"></i>
        <span class="menu-text">Hỗ trợ</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
              :to="{ name : 'support-category'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Danh sách danh mục</span>
              </a>
            </li>
          </router-link>
          <router-link
              :to="{ name : 'support-type'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Danh sách loại danh mục</span>
              </a>

            </li>
          </router-link>
          <router-link
              :to="{ name : 'support-notification'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Danh sách thông báo</span>
              </a>
            </li>
          </router-link>
          <router-link
              :to="{ name : 'support-question-list'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Danh sách FAQ</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

  </fragment>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "QuanLyTrungTamHoTro",
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
