<template>
  <div>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách tài khoản nhóm quyền {{$route.query.title_role}}</h3>
      </div>
    </div>
    <div class="card-body">

      <!--begin::Example-->
      <div class="example mb-10">
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
																<span class="input-group-text line-height-0 py-0">
																	<span class="svg-icon">
																		<svg xmlns="http://www.w3.org/2000/svg"
                                                                             width="24px" height="24px"
                                                                             viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" stroke-width="1"
                                                                               fill="none" fill-rule="evenodd">
																				<rect x="0" y="0" width="24"
                                                                                      height="24"></rect>
																				<path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                                                      fill="#000000" fill-rule="nonzero"
                                                                                      opacity="0.3"></path>
																				<path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                                                      fill="#000000"
                                                                                      fill-rule="nonzero"></path>
																			</g>
																		</svg>
																	</span>
																</span>
            </div>
            <input type="text" class="form-control" placeholder="Tìm kiếm"
                   v-on:input="searchRoles"
                   v-model="query.search"
                   aria-describedby="basic-addon2">
          </div>
        </div>
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th scope="col">STT</th>
              <th scope="col">Name</th>
              <th scope="col">Số điện thoại</th>
              <th scope="col">Thời gian gán quyền</th>
              <th scope="col">Người gán quyền</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in getAllRoleDetail" :key="index">
              <th scope="row">{{++index}}</th>
              <td>{{item.name}}</td>
              <td>{{item.phone}}</td>
              <td>{{item.profile && item.profile.updated_role_at && item.profile.updated_role_at |formatDateTimeAsia}}</td>
              <td>{{item.profile && item.profile.user_update_role && (item.profile.user_update_role.id + 100000)  +' - '+ item.profile.user_update_role.name}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
              v-model="query.page"
              :page-count="total_pages"
              :page-range="3"
              :margin-pages="2"
              :click-handler="clickCallback"
              :prev-text="'Trước'"
              :next-text="'Sau'"
              :container-class="'pagination b-pagination'"
              :pageLinkClass="'page-link'"
              :page-class="'page-item'"
              :next-link-class="'next-link-item'"
              :prev-link-class="'prev-link-item'"
              :prev-class="'page-link'"
              :next-class="'page-link'"
      >
      </paginate>
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_ROLES_LIST_USER } from "../../../../core/services/store/user/role.module";
import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";
const _ = require('lodash');

export default {
  name: "List-role",
  data() {
    
    return {
      id : this.$route.params.id,
      query : {
        search: "",
        page: 1,
      },
    };
  },
  computed: {
    ...mapGetters(["getAllRoleDetail","getRoleDetailPaginate"]),
    total_pages() {
      return this.getRoleDetailPaginate?.total_pages || 0
    },
  },

  mounted() {
    this.query.title_role = this.$route.query.title_role ? this.$route.query.title_role :'';
    this.query.search = this.$route.query.name ? this.$route.query.name :'';
    this.query.page = parseInt(this.$route.query.page ? this.$route.query.page : 1);
    let payload = {
        name : this.$route.query.name,
        page: this.query.page
        }
      this.$store.dispatch(GET_ROLES_LIST_USER,{
        id:this.id,
        params:payload
        });
      this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Nhóm quyền", route: 'role-list'},
      { title: "Danh sách tài khoản nhóm quyền" }
    ]);
  },
  methods: {
    created() {
    },
    searchRoles : _.debounce(function() {
      window.history.replaceState(null,'/role/detail/' + this.id,'?page='+this.query.page+'&name='+ this.query.search);
      this.$store.dispatch(GET_ROLES_LIST_USER,{
        id:this.id,
        params: {
        name : this.query.search,
        }
      });
    }, 300),
    clickCallback(pageNum) {
      window.history.replaceState(null,'/role/detail/'+ this.id,'?page='+pageNum+'&name='+ this.query.search);
      this.$store.dispatch(GET_ROLES_LIST_USER,{
        id:this.id,
        params: {
        page : pageNum,
        name : this.query.search
        }
      });
    }
  },
};
</script>

<style scoped>

</style>
