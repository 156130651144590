import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const GET_LIST_KRA_BY_USER = "kra/all";
export const CREATE_KRA = "kra";
export const UPDATE_KRA = "UPDATE_KRA";
export const DELETE_KRA = "DELETE_KRA";
export const GET_RESULT_KPI_BY_USER = "kraManager/details";
export const GET_LIST_MANAGEMENT_KPI = "kraMonthlyResults";
export const EXPORT_EXCEL_MANAGEMENT_KPI = "kraMonthlyResults/export";
export const MARK_KPI_MONTHLY = "kraManager/details/update"



const state = {};

const getters = {};
const actions = {

    [GET_LIST_KRA_BY_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LIST_KRA_BY_USER}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [CREATE_KRA](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(CREATE_KRA, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [UPDATE_KRA](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update("kra", payload.id, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [DELETE_KRA](context, id) {
        return new Promise(resolve => {
            ApiService.delete("kra", id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [EXPORT_EXCEL_MANAGEMENT_KPI](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${EXPORT_EXCEL_MANAGEMENT_KPI}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [GET_LIST_MANAGEMENT_KPI](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LIST_MANAGEMENT_KPI}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [GET_RESULT_KPI_BY_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_RESULT_KPI_BY_USER}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [MARK_KPI_MONTHLY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(MARK_KPI_MONTHLY, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

};


const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};