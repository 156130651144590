import ApiService from "@/core/services/api.service";

const _ = require('lodash');

export const GET_DEPARTMENTS = "get-departments";
export const GET_TICKET_CATEGORIES = "get-ticket-category";
export const GET_TICKETS = "get-tickets";
export const SHOW_TICKET = "show-ticket";
export const SHOW_TICKET_LOG = "show-ticket-log";
export const UPDATE_TICKET_FEEDBACK = "tickets/handle-ticket";
export const CREATE_DEPARTMENTS = "create-department";
export const SHOW_DEPARTMENTS = "show-department";
export const UPDATE_DEPARTMENTS = "update-department";

// lịch học
export const GET_SCHOOL_SCHEDULE = "classroom-time-manager/school-schedule-list";
export const CREATE_SCHOOL_SCHEDULE = "classroom-time-manager/school-schedule-create";
export const UPDATE_SCHOOL_SCHEDULE = "classroom-time-manager/school-schedule-update";
export const DELETE_SCHOOL_SCHEDULE = "classroom-time-manager/school-schedule-delete";


// Action
export const SET_DEPARTMENTS = 'set-departments';
export const SET_TICKET_CATEGORIES = 'set-ticket-category';
const state = {
    departments: [],
    ticket_categories: [],
};

const getters = {};

const actions = {
    /**
     * api lấy danh sách phòng ban
     * @param context
     * @param credentials
     * @returns {Promise<unknown>}
     */
    [GET_DEPARTMENTS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('departments', credentials)
                .then(({data}) => {
                    context.commit(SET_DEPARTMENTS, data.data)
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },

    /**
     * api lấy danh sách danh mục tickets
     * @param context
     * @param credentials
     * @returns {Promise<unknown>}
     */
    [GET_TICKET_CATEGORIES](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('ticket-category', credentials)
                .then(({data}) => {
                    context.commit(SET_TICKET_CATEGORIES, data.data)
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },

    /**
     * api lấy danh sách tickets
     * @param context
     * @param credentials
     * @returns {Promise<unknown>}
     */
    [GET_TICKETS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('tickets', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },

    /**
     * api chi tiết ticket
     * @param context
     * @param id
     * @returns {Promise<unknown>}
     */
    [SHOW_TICKET](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get('tickets', id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },

    

    /**
     * api nhập giải pháp
     * @param context
     * @param params
     * @returns {Promise<unknown>}
     */
    [UPDATE_TICKET_FEEDBACK](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_TICKET_FEEDBACK}`, params)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    /**
     * api nhập giải pháp
     * @param context
     * @param params
     * @returns {Promise<unknown>}
     */
    [CREATE_DEPARTMENTS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${CREATE_DEPARTMENTS}`, params)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [SHOW_DEPARTMENTS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${SHOW_DEPARTMENTS}`, payload.id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [UPDATE_DEPARTMENTS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_DEPARTMENTS}`, params)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },

    [SHOW_TICKET_LOG](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.query('tickets/show-ticket-log', params)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },

    [GET_SCHOOL_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`classroom-time-manager`, payload)
                .then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                reject(response)
            });
        })
    },

    [CREATE_SCHOOL_SCHEDULE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`classroom-time-manager`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [UPDATE_SCHOOL_SCHEDULE](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`classroom-time-manager/` + payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [DELETE_SCHOOL_SCHEDULE](context, id) {
        return new Promise(resolve => {
            ApiService.delete(`classroom-time-manager`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                console.log(response)
            });
        })
    },
 
}

const mutations = {
    [SET_DEPARTMENTS](state, data) {
        state.departments = data;
        state.errors = {};
    },
    [SET_TICKET_CATEGORIES](state, data) {
        state.ticket_categories = data;
        state.errors = {};
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
