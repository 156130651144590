<template>
    <div class="task-manager">
        <div class="card card-custom gutter-b">
            <div class="card-header d-flex justify-content-between">
                <div class="card-title">
                    Quản lý tác vụ
                </div>
                <div class="card-title card-action mt-5 row">
                    <SwapTaskModal
                        @create-success="createSuccess">
                      
                    </SwapTaskModal>
                    <TaskImportExcelModal
                        @create-success="createSuccess"
                        class="ml-5"
                    ></TaskImportExcelModal>
                    <task-create-modal
                        class="ml-5"
                        @create-success="createSuccess"
                    ></task-create-modal>
                    <task-suggest class="ml-5"></task-suggest>
                </div>
            </div>
            <div class="card-body">
                <div class="example mb-10">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Mã tác vụ</label>
                                <el-input
                                    @input="pushParamForUrl"
                                    v-model="query.id"
                                    placeholder="Mã tác vụ"
                                    clearable>
                                </el-input>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <div class="form-group">
                                <label>Loại tác vụ</label>
                                <el-select v-model="query.type_task"
                                           clearable
                                           placeholder="Loại tác vụ"
                                           @input="handleTypeTask()"
                                >
                                    <el-option
                                        v-for="item in type_task"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                      <div class="form-group col-md-3" v-if="query.type_task===test_dau_vao">
                        <div class="form-group">
                          <label>Tác vụ</label>
                          <el-select v-model="query.type_task_item"
                                     clearable
                                     placeholder="Chọn tác vụ"
                          >
                            <el-option
                                v-for="item in TYPE_TEST_INPUT_TASK"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                        <div class="form-group col-md-3">
                            <div class="form-group">
                                <label>Đối tượng xử lý</label>
                                <el-input
                                    @input="pushParamForUrl"
                                    placeholder="Nhập tên,số điện thoại"
                                    v-model="query.keyword"
                                ></el-input>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <div class="form-group">
                                <label>Trạng thái</label>
                                <el-select
                                    @input="pushParamForUrl"
                                    v-model="query.status"
                                    clearable
                                    placeholder="Chọn trạng thái">
                                    <el-option
                                        v-for="item in STATUS_ITEM"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <div class="form-group">
                                <label>Thời gian bắt đầu</label>
                                <el-date-picker
                                    @input="changeDate()"
                                    v-model="query.schedule"
                                    type="daterange"
                                    format="dd-MM-yyyy"
                                    start-placeholder="Ngày bắt đầu"
                                    end-placeholder="Ngày kết thúc"
                                />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Người phụ trách<span class="text-danger"></span></label>
                                <el-select
                                    @input="pushParamForUrl"
                                    v-model="query.tester_ids"
                                    clearable
                                    placeholder="Người phụ trách"
                                    multiple
                                    filterable
                                >
                                    <el-option
                                        v-for="item in testers"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="form-group col-md-3 mt-8">
                            <div class="form-group">
                                <button class="btn btn-primary"
                                        :class="{'spinner spinner-white spinner-left' : is_loading_search}"
                                        @click="search"
                                >Tìm kiếm
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="example-preview table-responsive">
                        <table class="table table-vertical-center table-hover">
                            <thead>
                            <tr>
                                <th>Mã tác vụ</th>
                                <th>Tác vụ</th>
                                <th>Đối tượng xử lý</th>
                                <th>Trạng thái</th>
                                <th>Thời gian bắt đầu</th>
                                <th>Thời gian kết thúc</th>
                                <th>Người phụ trách</th>
                                <th>Thời gian tạo</th>
                                <th>Hành động</th>
                            </tr>
                            </thead>
                            <tbody v-if="tasks.length > 0">
                            <tr v-for="(item, key) in tasks" :key="key">
                                <td>{{ item.id }}</td>
                                <td>
                  <span :class="item?.nameTypeAndColor?.color">
                    {{item?.nameTypeAndColor?.name}}
                  </span>
                                </td>
                              <td>
                                <p v-if="item.type_task === test_dau_vao" class="mb-0">{{ item.name ?? '' }} - {{ item.phone ?? '' }}  </p>
                                <p v-else class="mb-0">{{item?.processing_object}}</p>
                              </td>
                                <td>
                  <span :class="STATUS_ITEM_DEFINE_CSS[item.status]">
                    {{ STATUS_ITEM_DEFINE[item.status] }}
                  </span></td>
                                <td>{{ item.schedule | formatDateTime }}</td>
                                <td>{{ item.end_schedule | formatDateTime }}</td>
                                <td>{{ item.tester_name }} - {{ item.tester_phone }}</td>
                                <td>{{ item.created_at | formatDateTime }}</td>
                                <td class="d-flex">
                                    <button title="Sửa" class="btn btn-xs btn-icon mr-2 btn-outline-info"
                                            @click="editTask(item)">
                                        <i class="fas fa-pen-nib"></i>
                                    </button>
                                    <a v-if="[gia_su, coi_thi, tac_vu_khac].includes(item.type_task)"
                                       title="Xóa"
                                       href="javascript:"
                                       @click="deleteTask(item.id)"
                                       class="btn btn-xs btn-icon btn-outline-danger mr-2">
                                        <i class="fas fa-trash"></i></a>
                                    <button v-if="item.type_task === test_dau_vao" title="Chi tiết tác vụ"
                                            class="btn btn-xs btn-icon btn-outline-primary"
                                            @click="detailTask(item.id)">
                                        <i class="fa fa-eye"></i>
                                    </button>
                                  <button v-if="item.type_task !== test_dau_vao && item?.status !== STATUS_DONE " title="Hoàn thành"
                                          class="btn btn-xs btn-icon btn-outline-success"
                                          @click="handleOpenModalConfirm(item)"
                                  >
                                    <i class="el-icon-check"></i>
                                  </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td colspan="9" class="text-center text-danger event-title no-data">Không có tác vụ</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-end">
                            <p>Tổng số bản ghi: <span class="text-primary font-weight-bold">{{ total_row }}</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="edu-paginate mx-auto text-center">
                <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                    :page-class="'page-item'"
                >
                </paginate>
            </div>
        </div>
        <task-edit-modal
            class="ml-5"
            @create-success="getTestInputSchedule"
            :schedule_prop="task_edit"
        >
        </task-edit-modal>
      <el-dialog
          title="Hoàn thành tác vụ"
          :visible.sync="openModalConfirm"
          width="30%"
      >
        <p>Thao tác sẽ chuyển trạng thái của tác vụ {{ TYPE_TASK_ITEM_DEFINE[currentTask?.type_item_id]}} (ID {{currentTask?.id}}).</p>
        <p>Bạn chắc chắn muốn Tiếp tục tác vụ này chứ?</p>
        <span slot="footer" class="dialog-footer">
    <el-button @click="openModalConfirm = false">Đóng</el-button>
    <el-button type="primary" @click="confirmDoneTask">Hoàn thành</el-button>
  </span>
      </el-dialog>
    </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  CHECK_MY_TASK_PENDING,
  TEST_INPUT_DELETE_TASK, TEST_INPUT_GET_LIST_TESTER,
  TEST_INPUT_GET_TEST_INPUT_SCHEDULE, UPDATE_STATUS_DONE_TEST_INPUT_ITEM
} from "@/core/services/store/test/testInput.module";
import {
  COI_THI,
  GIA_SU, ONLINE,
  STATUS,
  STATUS_DEFINE,
  STATUS_DEFINE_CSS,
  STATUS_DONE,
  STATUS_ITEM,
  STATUS_ITEM_DEFINE,
  STATUS_ITEM_DEFINE_CSS,
  TAC_VU_KHAC,
  TEST_DAU_VAO,
  TYPE_TASK,
  TYPE_TASK_ITEM_DEFINE,
  TYPE_TASK_ITEM_DEFINE_CSS, TYPE_TASK_ITEM_GENERAL, TYPE_TASK_ITEM_SPEAKING,
  TYPE_TEST_INPUT_TASK,
  TYPES_NOT_ALL
} from "@/core/option/testInputOption";
import moment from "moment-timezone";
import TaskManagerCalendar from "@/view/pages/test-input/components/TaskManagerCalendar";
import {daysOfWeek} from "@/core/option/dayOption";
import TaskCreateModal from "@/view/pages/test-input/components/TaskCreateModal";
import TaskEditModal from "@/view/pages/test-input/components/TaskEditModal";
import Swal from "sweetalert2";
import TaskImportExcelModal from "@/view/pages/test-input/components/TaskImportExcelModal";
import TaskSuggest from "@/view/pages/test-input/components/TaskSuggest";
import router from "@/router";
import {formatDate, formatDateTime} from "@/core/filters";
import SwapTaskModal from "@/view/pages/test-input/components/SwapTaskModal.vue";

export default {
    name: "TaskManagerIndex",
    components: {
      SwapTaskModal,
        TaskSuggest, TaskImportExcelModal, TaskEditModal, TaskCreateModal, TaskManagerCalendar
    },
    data() {
        return {
            last_page: 1,
            page: 1,
            total_row: 1,
            show_calendar: false,
            is_loading_search: false,
            is_loading_download_file: false,
            search_loading: false,
            tasks: [],
            TYPE_TASK_ITEM_DEFINE: TYPE_TASK_ITEM_DEFINE,
            TYPE_TASK_ITEM_DEFINE_CSS: TYPE_TASK_ITEM_DEFINE_CSS,
            query: {
                id: '',
                type_task: '',
                tester_ids: [],
                keyword: '',
                status: '',
                schedule: '',
              type_task_item: null
                // date_week: moment().startOf('week'),
            },
            type_task: TYPE_TASK,
            testers: [],
            daysOfWeek: daysOfWeek,
            task_edit: null,
            gia_su: GIA_SU,
            test_dau_vao: TEST_DAU_VAO,
            coi_thi: COI_THI,
            STATUS_ITEM: STATUS_ITEM,
            STATUS_ITEM_DEFINE: STATUS_ITEM_DEFINE,
            STATUS_ITEM_DEFINE_CSS: STATUS_ITEM_DEFINE_CSS,
            STATUS: STATUS,
            STATUS_DEFINE: STATUS_DEFINE,
            STATUS_DEFINE_CSS: STATUS_DEFINE_CSS,
            tac_vu_khac: TAC_VU_KHAC,
            checkTestInput: false,
          TYPE_TEST_INPUT_TASK: TYPE_TEST_INPUT_TASK,
          STATUS_DONE: STATUS_DONE,
          openModalConfirm: false,
          currentTask: null,
        }
    },
    computed: {
        isTestInput() {
            if (this.query.type_task === TEST_DAU_VAO) {
                return true;
            }
            return false;
        },
        dateRange() {
            let startWeek = moment(this.query.date_week);
            let endWeek = startWeek.clone().endOf('week');
            return startWeek.format('DD/MM/YYYY') + ' - ' + endWeek.format('DD/MM/YYYY')
        },
        days() {
            let result = [];
            let startWeek = moment(this.query.date_week);
            for (let i = 0; i < 7; i++) {
                let tomorrow = startWeek.clone().add(i, 'days');
                result.push({
                    day: tomorrow.clone().format('DD-MM-YYYY'),
                    dayOfWeek: this.daysOfWeek[tomorrow.clone().day()]
                })
            }
            return result;
        }
    },
    async created() {
        await this.pullParamFromUrl();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý test đầu vào", route: 'task-manager'},
            {title: "Quản lý tác vụ"}
        ]);
        await this.getTesters();
    },
    mounted() {
        this.getTestInputSchedule();
    },
    methods: {
      formatDateTime,
      formatDate,
        pullParamFromUrl() {
            this.query.id = this.$route.query.id ? this.$route.query.id : '';
            this.query.type_task = this.$route.query.type_task ? parseInt(this.$route.query.type_task) : '';
            this.query.keyword = this.$route.query.keyword ? this.$route.query.keyword : '';
            this.query.schedule = this.$route.query.schedule ? [moment(this.$route.query.schedule[0]).format('YYYY-MM-DD'), moment(this.$route.query.schedule[1]).format('YYYY-MM-DD')] : '';

            // pull tester ids
            if (typeof this.$route.query.tester_ids == 'string') {
                let tester_ids = Array.from(this.$route.query.tester_ids);
                this.query.tester_ids = tester_ids.length ? tester_ids.map(str => parseInt(str, 10)) : [];
            } else {
                this.query.tester_ids = this.$route.query.tester_ids !== undefined && this.$route.query.tester_ids.length ? this.$route.query.tester_ids.map(str => parseInt(str, 10)) : [];
            }
            // pull status
            this.query.status = this.$route.query.status ? parseInt(this.$route.query.status) : '';
        },
        pushParamForUrl() {
            this.$router.push({
                name: 'task-manager',
                query: this.query
            })
        },
        handleTypeTask() {
            this.pushParamForUrl();
            this.checkTestInput = false
        },
        changeDate() {
            this.pushParamForUrl();
            this.query.schedule = this.query.schedule.map((date) => moment(date).format("YYYY-MM-DD"))
        },
        editTask(item) {
          this.task_edit = item;
          setTimeout(()=>{
            this.$bvModal.show('task-edit-modal')
          })
        },
        clickCallback(obj) {
            this.page = obj;
            this.getTestInputSchedule();
        },
        async getTesters() {
            await this.$store.dispatch(TEST_INPUT_GET_LIST_TESTER).then((data) => {
                this.testers = data.data;
            })
        },
        search() {
          this.page = 1
            this.checkTestInput = true
            this.is_loading_search = true;
            this.getTestInputSchedule();
        },
        createSuccess() {
            this.getTestInputSchedule();
        },
        getTestInputSchedule() {
            let payload = {
                page: this.page,
                ...this.query
                // start_week: moment(this.query.date_week).format('YYYY-MM-DD')
            }
            this.$store.dispatch(TEST_INPUT_GET_TEST_INPUT_SCHEDULE, payload).then((data) => {
                this.tasks = data?.data?.map(e=>{
                  return {...e,nameTypeAndColor: this.checkTypeTask(e?.type,e?.type_item_id)}
                });
                this.total_row = data.pagination.total;
                this.page = data.pagination.current_page;
                this.last_page = data.pagination.last_page;
            }).finally(() => {
                this.is_loading_search = false;
            });
        },
        getScheduleEnd(schedule) {
            return moment(schedule).add(30, 'minutes').format('DD-MM-YYYY HH:mm:ss')
        },
        deleteTask(id) {
            Swal.fire({
                title: "Bạn chắc chắn muốn xóa?",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Đồng ý",
                cancelButtonText: "Không",
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire("Xóa", "", "success");
                    this.$store.dispatch(TEST_INPUT_DELETE_TASK, id).then((res) => {
                        if (res.error) {
                            this.$bvToast.toast(res.message, {
                                title: 'Xóa',
                                variant: 'danger',
                                solid: true
                            });
                        } else {
                            this.getTestInputSchedule();
                            this.$bvToast.toast(res.message, {
                                title: 'Xóa',
                                variant: 'success',
                                solid: true
                            });
                            this.getList()
                        }
                    });
                }
            });
        },
        detailTask(id) {
            const routeData = router.resolve({name: 'task-details-coordinator', params: {id: id}});
            window.open(routeData.href, '_blank');
        },
      handleOpenModalConfirm(task){
        this.currentTask = task;
        setTimeout(()=>{
          this.openModalConfirm = true;
        })
      },
      confirmDoneTask(){
        this.$store.dispatch(UPDATE_STATUS_DONE_TEST_INPUT_ITEM,{id:this.currentTask.id}).then(()=>{
          this.$notify({
            title: 'Thành công',
            message: 'Đã hoàn thành',
            type: 'success'
          });
          this.getTestInputSchedule();
          this.openModalConfirm = false;
        }).catch(()=>{
          this.$notify.error({
            title: 'Error',
            message: 'Đã có lỗi xẩy ra'
          });
        })
      },
      checkTypeTask(type,typeTask){
        if (typeTask === TYPE_TASK_ITEM_GENERAL){
          return {
            name: TYPE_TASK_ITEM_DEFINE[typeTask] ,
            color: TYPE_TASK_ITEM_DEFINE_CSS[typeTask]
          }
        }
        if(typeTask === TYPE_TASK_ITEM_SPEAKING){
          return {
            name: type===ONLINE?'Test Speaking Online':'Test Speaking Offline' ,
            color: type===ONLINE?'badge badge-primary':'badge badge-warning'
          }
        }
        return {
          name: TYPE_TASK_ITEM_DEFINE[typeTask] ,
          color: 'badge badge-danger'
        }
      },
    }
}
</script>

<style scoped>
.el-input {
    width: 100% !important;
}

.el-select {
    width: 100%;
}

.el-date-editor {
    width: 100%;
}
</style>