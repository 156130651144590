<script>
export default {
  name: "ReviewSkillChild",
  components: {},
  props: {
    criteria: {
      type: Object,
      default() {
        return {}
      }
    },
    error:{
      type: String,
      default() {
        return ''
      }
    }
  },
}
</script>

<template>
  <div style="background-color: #F6F6F6" class="rounded p-4">
    <div class=" d-lg-block justify-content-between">
      <div class="   py-0 d-flex align-items-center  ">
        <p class="mb-0 text-dark badge badge-danger" style="font-size: 13px; background-color: #FF9393 ">
          {{ criteria.title }}</p>
      </div>
      <ValidationProvider name="Sao" rules="required" v-slot="{ errors,classes }"
                          :vid="criteria.id+'diem'">
        <div class="d-xl-flex d-block mt-4 align-items-center justify-content-between">
          <div class="d-xl-flex d-block ">
            <p class="mt-4">Điểm tiêu chí: <span class="text-danger">*</span></p>
            <el-rate class="mt-4 ml-3" v-model="criteria.start"></el-rate>
          </div>
        </div>
        <div v-if="criteria.start === 0 && errors !==''" style="color: #F64E60">{{error}}</div>
      </ValidationProvider>
    </div>
    <div class="mt-4">
      <p>Nhận xét: <span class="text-danger">*</span></p>
      <ValidationProvider name="Nhận xét" :rules="{required:true,min:50}" v-slot="{ errors,classes }"
                          :vid="criteria.id+'comment'">
      <el-input
          v-model="criteria.comment"
          :autosize="{ minRows: 4, maxRows: 4 }"
          type="textarea"
          placeholder="Vui lòng nhập nhận xét"
      />
        <div class="fv-plugins-message-container">
          <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
        </div>
      </ValidationProvider>
    </div>
    <hr>
  </div>
</template>

<style scoped>

</style>