export const CA_LAM_VIEC = {
    5: "Ca sáng 1 (08h15 - 09h00)",
    1: "Ca sáng (09h00 - 12h00)",
    6: "Ca chiều 1 (13h45 - 14h30)",
    2: "Ca chiều (14h30 - 18h00)",
    3: "Ca tối (18h00 - 21h00)",
    4: "Ca tối 2 (21h00 - 22h00)"
}

export const CA_LAM_VIEC_KEY_TEXT = {
    morning_1: "Ca sáng 1 (08h15 - 09h00)",
    morning: "Ca sáng (09h00 - 12h00)",
    afternoon_1: "Ca chiều 1 (13h45 - 14h30)",
    afternoon: "Ca chiều (14h30 - 18h00)",
    evening: "Ca tối (18h00 - 21h00)",
    evening_overtime: "Ca tối 2 (21h00 - 22h00)"
}

export const ALL = 0;
export const ONLINE = 1;
export const OFFLINE = 2;
export const TIME_PICKER_OPTIONS = {
    start: '08:15',
    step: '00:15',
    end: '21:45',
    format: 'HH:mm'
};
export const TIME_PICKER_OPTIONS_END = {
    start: '08:15',
    step: '00:15',
    end: '22:00',
    format: 'HH:mm'
};
export const TYPES = [
    {
        id: 0,
        name: "Tất cả",
    },
    {
        id: 1,
        name: "Online",
    },
    {
        id: 2,
        name: "Offline"
    }
];

export const TYPES_NOT_ALL = [
    {
        id: 1,
        name: "Online",
    },
    {
        id: 2,
        name: "Offline"
    }
];
export const TYPE_TASK_NOT_TEST_INPUT = [
    {
        id: 2,
        name: "Gia sư",
    },
    {
        id: 3,
        name: "Coi thi"
    },
    {
        id: 4,
        name: "Tác vụ khác"
    }
];

export const TYPE_TASK = [
    {
        id: 1,
        name: "Test đầu vào",
    },
    {
        id: 2,
        name: "Gia sư",
    },
    {
        id: 3,
        name: "Coi thi"
    },
    {
        id: 4,
        name: "Tác vụ khác"
    }
];

export const TYPE_TASK_SUGGEST = [
    {
        id: 2,
        name: "Gia sư",
    },
    {
        id: 3,
        name: "Coi thi"
    },
    {
        id: 4,
        name: "Tác vụ khác"
    }
];

export const TEST_DAU_VAO = 1;
export const TYPE_TASK_TEST_INPUT = 1;
export const GIA_SU = 2;
export const COI_THI = 3;
export const TAC_VU_KHAC = 4;

export const TYPE_TASK_ITEM_SPEAKING = 0;
export const TYPE_TASK_ITEM_GENERAL = 1;
export const TYPE_TASK_ITEM_GIA_SU = 2;
export const TYPE_TASK_ITEM_COI_THI = 3;
export const TYPE_TASK_ITEM_TAC_VU_KHAC = 4;

export const TYPE_TASK_ITEM_DEFINE = {
    0: 'Test Speaking',
    1: 'Chấm General',
    2: 'Gia sư',
    3: 'Coi thi',
    4: 'Tác vụ khác'
};

export const TYPE_TASK_ITEM_DEFINE_CSS = {
    0: 'badge badge-primary',
    1: 'badge badge-info',
    2: 'badge badge-success',
    3: 'badge badge-danger',
    4: 'badge badge-warning',
};
export const TYPE_TASK_ITEM_WARNING_DEFINE_CSS = {
    0: 'badge badge-info',
    1: 'badge badge-primary',
    2: 'badge badge-success',
    3: 'badge badge-danger',
    4: 'badge badge-warning',
};


export const WORKINGFORM = [
    {
        id: 0,
        name: "ALL"
    },
    {
        id: 1,
        name: "ONLINE"
    },
    {
        id: 2,
        name: "OFFLINE"
    },
]

export const WORKING_FORM_TASK = [

    {
        id: 1,
        name: "Online"
    },
    {
        id: 2,
        name: "Offline"
    },
]

export const TYPE_EXAMS = [
    {
        id: 0,
        name: "Ielts"
    },
    {
        id: 1,
        name: "Toeic"
    },
    {
        id: 2,
        name: "Giao tiêp"
    },
]
export const STATUS_ITEM = [
    {
        id: 0,
        name: "Cần làm"
    },
    {
        id: 1,
        name: "Hoàn thành"
    },
    {
        id: 2,
        name: "Hủy"
    }
];
export const STATUS_ITEM_DEFINE = {
    0: 'Cần làm',
    1: 'Hoàn thành',
    2: 'Hủy'
};
export const STATUS_DONE = 1;
export const STATUS_ITEM_DEFINE_CSS = {
    0: 'badge badge-warning',
    1: 'badge badge-success',
    2: 'badge badge-secondary'
};
export const STATUS_BG_ITEM_DEFINE_CSS = {
    0: 'bg-warning',
    1: 'bg-success',
    2: 'bg-secondary'
};

export const STATUS = [
    {
        id: 0,
        name: "Đã đặt lịch"
    },
    {
        id: 1,
        name: "Đã test general"
    },
    {
        id: 2,
        name: "Đã test speaking"
    },
    {
        id: 3,
        name: "Chờ chấm điểm"
    },
    {
        id: 4,
        name: "Đã trả kết quả"
    },
    {
        id: 5,
        name: "Hủy lịch"
    }
];

export const STATUS_DEFINE = {
    0: 'Đã đặt lịch',
    1: 'Đã test general',
    2: 'Đã test speaking',
    3: 'Chờ chấm điểm',
    4: 'Đã trả kết quả',
    5: 'Hủy lịch',
};

export const STATUS_DEFINE_CSS = {
    0: 'badge badge-secondary',
    1: 'badge badge-primary',
    2: 'badge badge-warning',
    3: 'badge badge-info',
    4: 'badge badge-danger',
    5: 'badge badge-success',
};

export const STATUS_HIDDEN = 0;
export const STATUS_VISIBLE = 1;

//tiêu chí nhận xét speaking
export const COMMENT_FLUENCY_AND_COHERENCE =
    {
        0: '• Học viên không tham gia Test ',
        1: "• Học viên chưa thể giao tiếp và truyền đạt được ý tưởng\n" +
            "• Ngôn ngữ chưa thể đánh giá được",
        2: "• Khi trình bày, có các khoảng dừng dài trước hầu hết các từ\n" +
            "• Khả năng truyền đạt ý tưởng cần cải thiện thêm ",
        3: "• Khi học viên trình bày còn có những khoảng dừng dài\n" +
            "• Khả năng liên kết các câu đơn còn hạn chế\n" +
            "• Chỉ đưa ra được những câu trả lời đơn giản và thường không truyền tải được thông điệp cơ bản",
        4: "• Trong lúc trả lời vẫn có những khoảng dừng đáng chú ý, tốc độ nói của học viên khá chậm, thường xuyên bị lặp và tự sửa lỗi\n" +
            "• Học viên có thể liên kết được các câu cơ bản nhưng còn sử dụng lặp đi lặp lại các phép liên kết đơn giản, tính mạch lạc vẫn còn bị gián đoạn. ",
        5: "• Học viên thường xuyên duy trì được độ trôi chảy của lời nói nhưng phải lặp lại, tự sửa lỗi và nói chậm để có thể nói liên tục\n" +
            "• Học viên có thể lạm dụng (sử dụng quá mức) một số từ nối, phép nối và discourse markers*\n" +
            "• Tạo ra được những lời nói đơn giản và lưu loát, nhưng việc truyền đạt bị phức tạp hơn nên gây ra các vấn đề về độ trôi chảy",
        6: "• Học viên có thể mở rộng câu trả lời, mặc dù đôi khi có thể mất độ mạch lạc do thỉnh thoảng lặp lại, tự sửa lỗi hoặc do ngập ngừng\n" +
            "• Sử dụng nhiều các phép nối và discourse markers nhưng không phải lúc nào cũng thích hợp",
        7: "• Học viên có thể mở rộng, kéo dài câu nói mà không cần nỗ lực nhiều hoặc mất tính mạch lạc\n" +
            "• Đôi khi có thể thể hiện sự ngập ngừng liên quan đến ngôn ngữ hoặc một số sự lặp lại và / hoặc tự điều chỉnh, sửa lỗi\n" +
            "• Sử dụng nhiều, đa dạng và linh hoạt các phép nối nối cũng như discourse markers",
        8: "        \n" +
            "• Học viên duy trì bài nói một cách trôi chảy, chỉ thỉnh thoảng lặp từ hoặc tự sửa lỗi hay ngập ngừng do tìm nội dung, ý diễn đạt chứ hiếm khi phải dừng để tìm từ ngữ\n" +
            "• Phát triển các chủ đề một cách mạch lạc và phù hợp",
        9: "• Học viên duy trì bài nói một cách trôi chảy và hiếm khi lặp lại hay tự điều chỉnh, sửa lỗi\n" +
            "• Mọi sự do dự, ngập ngừng trong lúc nói đều liên quan đến nội dung hơn là tìm từ hoặc ngữ pháp\n" +
            "• Nói mạch lạc sử dụng các đặc trưng liên kết một cách hoàn toàn thích hợp\n" +
            "• Phát triển các chủ đề một cách đầy đủ và hợp lý",
    }
export const COMMENT_LEXICAL_RESOURCE = {
    0: '• Học viên không tham gia Test ',
    1: "• Học viên chưa thể giao tiếp và truyền đạt được ý tưởng",
    2: "•  Học viên chỉ nói được những từ đơn, riêng biệt hoặc những câu nói được ghi nhớ",
    3: "• Chỉ sử dụng được những từ vựng đơn giản để truyền đạt thông tin cá nhân\n" +
        "•  Thiếu vốn từ để diễn đạt nhưng chủ đề ít quen thuộc hơn",
    4: "•  Có thể nói về các chủ đề quen thuộc. Tuy nhiên chỉ có thể truyền đạt ý nghĩa cơ bản về các chủ đề không quen thuộc và thường xuyên mắc lỗi trong việc lựa chọn từ ngữ\n" +
        "• Hiếm khi cố gắng thay đổi cách diễn đạt (paraphrase)",
    5: "• Có thể nói được về cả các chủ đề quen thuộc và không quen thuộc nhưng sử dụng từ vựng còn ít linh hoạt\n" +
        "• Có cố gắng sử dụng nhiều cách để diễn đạt nhưng thường không thành công",
    6: "• Có vốn từ vựng đủ rộng để có những cuộc thảo luận dài về nhiều chủ đề và làm cho ý nghĩa rõ ràng mặc dù còn những chỗ không phù hợp\n" +
        "• Nhìn chung diễn đạt ý được bằng nhiều cách chính xác",
    7: "• Sử dụng nguồn từ vựng một cách linh hoạt để thảo luận về nhiều chủ đề khác nhau\n" +
        "• Sử dụng được một số thành ngữ và các từ vựng ít phổ biến hơn, đồng thời cho thấy một số kiến thức về văn phong và cụm từ, tuy nhiên các sự lựa chọn chưa được phù hợp\n" +
        "• Sử dụng hiệu quả nhiều cách diễn đạt (paraphrase) khác nhau",
    8: "• Sử dụng nguồn từ vựng phong phú và linh hoạt để truyền đạt ý nghĩa một cách chính xác\n" +
        "• Sử dụng các từ vựng ít phổ biến và thành ngữ một cách khéo léo, chỉ đôi khi không chính xác\n" +
        "• Sử dụng nhiều cách diễn đạt hiệu quả như được yêu cầu",
    9: "• Sử dụng từ vựng một cách linh hoạt và chính xác trong tất cả các chủ đề\n" +
        "• Sử dụng các thành ngữ một cách tự nhiên và chính xác",
}
export const COMMENT_GRAMMATICAL_RANGE_ACCURACY = {
    0: '• Học viên không tham gia Test ',
    1: "• Không có sự giao tiếp",
    2: "• Không thể hình thành các dạng câu cơ bản",
    3: "• Cố gắng sử dụng các dạng câu cơ bản nhưng ít khi thành công hoặc dựa vào các câu nói có vẻ đã thuộc lòng\n" +
        "• Mắc nhiều lỗi ngoại trừ các cách diễn đạt đã được ghi nhớ",
    4: "• Hình thành được các dạng câu cơ bản và một số câu đơn giản đúng nhưng hiếm khi sử dụng các dạng cấu trúc câu phức\n" +
        "• Thường xuyên mắc lỗi và các lỗi có thể dẫn đến sự khó hiểu hoặc hiểu nhầm",
    5: "• Sử dụng các dạng câu cơ bản một cách hợp lý và chính xác\n" +
        "• Có sử dụng một số ít các cấu trúc phức tạp hơn, nhưng những cấu trúc này thường có lỗi và có thể gây ra một số vấn đề về việc hiểu",
    6: "• Sử dụng kết hợp các cấu trúc đơn giản và phức tạp nhưng ít linh hoạt\n" +
        "• Có thể vẫn mắc lỗi thường xuyên với các cấu trúc phức tạp nhưng những lỗi này hiếm khi gây ra các vấn đề về hiểu",
    7: "• Sử dụng nhiều cấu trúc phức tạp một cách khá linh hoạt\n" +
        "• Các câu được tạo ra thường là không có lỗi, hoặc chỉ tồn tại một số lỗi ngữ pháp",
    8: "• Sử dụng nhiều và đa dạng các loại cấu trúc một cách linh hoạt\n" +
        "• Phần lớn các câu không có lỗi, chỉ thỉnh thoảng không phù hợp hoặc mắc các lỗi cơ bản/ lỗi không hệ thống",
    9: "• Sử dụng đầy đủ các cấu trúc một cách tự nhiên và thích hợp\n" +
        "• Cấu trúc các câu chính xác và nhất quán, loại trừ các “lỗi nhỏ”’ trong đặc điểm cách nói của người bản ngữ",
}
export const COMMENT_PRONUNCIATION = {
    0: "• Học viên không tham gia Test ",
    1: "• Không có sự giao tiếp",
    2: "• Lời nói thường không thể hiểu được",
    3: "• Thể hiện một số đặc điểm của Band 2 và một số, nhưng không phải tất cả các tính năng tích cực của Band 4",
    4: "• Sử dụng được một số đặc điểm ngữ âm nhưng còn hạn chế\n" +
        "• Có nhiều đoạn ngừng có thể chấp nhận được, tuy nhiên ngữ điệu tổng thể vẫn còn thiếu sót\n" +
        "• Cố gắng sử dụng các yếu tố ngữ điệu và trọng âm, tuy nhiên việc kiểm soát còn hạn chế\n" +
        "• Các từ và âm tiết thường phát âm sai, gây khó hiểu.\n" +
        "• Người nghe cần cố gắng để hiểu nội dung, một vài phần trong câu nói gây khó hiểu\"",
    5: "• Thể hiện được tất cả các tính năng tích cực của Band 4 và một số, nhưng không phải tất cả các tính năng tích cực của Band 6",
    6: "• Sử dụng được một số các thành tố phát âm nhưng việc kiểm soát còn lẫn lộn\n" +
        "• Cho thấy khả năng sử dụng hiệu quả một số thành tố nhưng điều này không được duy trì\n" +
        "• Nhìn chung bài nói có thể được hiểu xuyên suốt, mặc dù việc phát âm sai các từ hoặc âm đôi khi làm giảm độ rõ ràng",
    7: "• Thể hiện tất cả các tính năng tích cực của Band 6 và một số, nhưng không phải tất cả các tính năng tích cực của Band 8",
    8: "• Sử dụng nhiều và đa dạng các thành tố phát âm\n" +
        "• Duy trì được việc sử dụng linh hoạt các thành tố này, chỉ thỉnh thoảng mắc lỗi\n" +
        "• Xuyên suốt bài nói dễ hiểu; giọng ngôn ngữ thứ nhất (tiếng Việt) ảnh hưởng rất ít đến khả năng hiểu",
    9: "• Sử dụng đầy đủ các thành tố phát âm với độ chính xác và sự tinh tế\n " +
        "• Duy trì việc sử dụng linh hoạt các thành tố này xuyên suốt bài nói\n" +
        "• Có thể dễ dàng hiểu mà không cần nỗ lực"
}

// tiêu chí nhạn xét writing
export const COMMENT_TASK_ACHIEVEMENT_WRITING = {
    0: "• Học viên không hoàn thành bài Test \n" +
        "• Học viên không tham gia phần thi Writing \n" +
        "• Bài làm có tỉ lệ đạo văn 100%",
    1: "• Bài viết hoàn toàn không liên quan đến đề bài",
    2: "• Bài viết hầu như không đáp ứng được yêu cầu đề bài \n" +
        "• Bài viết chưa bày tỏ được quan điểm\n" +
        "• Có thể cố gắng trình bày một hoặc hai ý tưởng nhưng chưa có sự phát triển ý ",
    3: "• Bài viết chưa đáp ứng được yêu cầu của đề bài \n" +
        "• Chưa bày tỏ quan điểm một cách rõ ràng\n" +
        "• Trình bày được một số ý tưởng, phần lớn chưa được phát triển hoặc không liên quan tới đề bài",
    4: "• Bài viết chỉ đáp ứng một phần rất nhỏ yêu cầu đề bài  hoặc câu trả lời là tiếp tuyến; cấu trúc bài có thể chưa phù hợp\n" +
        "• Đã có trình bày một quan điểm nhưng chưa thật sự rõ ràng\n" +
        "• Trình bày một số ý chính nhưng khó xác định và có thể lặp lại ý, không liên quan hoặc các supporting ideas chưa tốt. ",
    5: "• Bài viết chỉ đáp ứng một phần yêu cầu đề bài; cấu trúc có thể không phù hợp ở một số phần\n" +
        "• Bài viết đã thể hiện được quan điểm nhưng một số ý còn chưa rõ ràng và có thể không rút ra được kết luận cụ thể nào\n" +
        "• Trình bày được một số ý chính nhưng còn hạn chế và chưa phát triển đầy đủ; có thể có một vài chi tiết không liên quan",
    6: "• Bài viết đáp ứng được tất cả các yêu cầu của đề bài mặc dù các phần chưa được bao quát tương đương nhau \n" +
        "• Trình bày quan điểm có liên quan mặc dù kết luận có thể trở chưa rõ ràng hoặc lặp đi lặp lại\n" +
        "• Trình bày những ý chính có liên quan nhưng một số ý có thể chưa được phát triển đầy đủ/không rõ ràng",
    7: "• Bài viết đáp ứng được tất cả yêu cầu của đề bài \n" +
        "• Trình bày quan điểm rõ ràng trong suốt bài làm\n" +
        "• Trình bày, mở rộng và hỗ trợ tốt cho các ý chính, nhưng có thể có xu hướng khái quát hóa quá mức và/hoặc các supporting ideas còn lan man, thiếu trọng tâm",
    8: "• Bài viết đáp ứng được tất cả yêu cầu của đề bài \n" +
        "• Trình bày bài làm được phát triển tốt cho câu hỏi với các ý tưởng có liên quan, mở rộng và các supporting ideas tốt",
    9: "• Bài viết đáp ứng được tất cả yêu cầu của đề bài \n" +
        "• Trình bày bài làm rất tốt, phát triển đầy đủ ý để trả lời cho yêu cầu với các ý tưởng phù hợp, mở rộng đầy đủ và các supporting ideas tốt"
};
export const COMMENT_LEXICAL_RESOURCE_WRITING = {
    0: "• Học viên không tham gia phần thi Writing",
    1: "•Bài viết chỉ có thể sử dụng một vài từ riêng lẻ",
    2: "• Sử dụng một lượng từ vựng hạn chế; về cơ bản không kiểm soát được các lỗi sai về cấu tạo từ và/hoặc chính tả ",
    3: "• Chỉ sử dụng một số lượng từ và cách diễn đạt còn hạn chế với khả năng kiểm soát các lỗi sai về cấu tạo từ và/hoặc chính tả rất hạn chế \n" +
        "• Các lỗi trong bài có thể làm sai nghĩa các ý ",
    4: "• Chỉ sử dụng từ vựng cơ bản,  có thể các từ được sử dụng lặp đi lặp lại hoặc có thể sử dụng từ không phù hợp.\n " +
        "• Kiểm soát các lỗi sai về cấu tạo từ và/hoặc chính tả chưa tốt; lỗi có thể gây ra hiểu lầm cho người đọc",
    5: "• Sử dụng một lượng từ vựng hạn chế, nhưng điều này tối thiểu phù hợp với yêu cầu đề bài \n" +
        "• Bài viết mắc lỗi đáng chú ý về chính tả và/hoặc cấu tạo từ, có thể gây khó khăn cho người đọc",
    6: "• Bài viết sử dụng lượng từ vựng phù hợp theo yêu cầu đề bài\n" +
        "• Học viên đã cố gắng sử dụng từ vựng ít phổ biến hơn nhưng có một số chỗ, cách sử dụng từ chưa phù hợp \n" +
        "• Mắc một số lỗi chính tả và/hoặc cấu tạo từ, nhưng không làm sai nghĩa ",
    7: "• Bài viết sử dụng lượng từ vựng phù hợp, linh động, chính xác theo yêu cầu đề bài\n" +
        "• Sử dụng các từ vựng ít phổ biến hơn, có biết về Collocation và Style. \n" +
        "• Thỉnh thoảng có thể mắc lỗi trong việc lựa chọn từ, chính tả và/hoặc cấu tạo từ",
    8: "• Sử dụng nhiều loại từ vựng một cách trôi chảy và linh hoạt để truyền đạt ý nghĩa chính xác\n" +
        "• khéo léo sử dụng các mục từ vựng không phổ biến. Tuy nhiên,  việc lựa chọn từ và sắp xếp thứ tự còn chưa chính xác. \n" +
        "• Mắc các lỗi hiếm gặp về chính tả và/hoặc cấu tạo từ",
    9: "• Bài viết sử dụng nhiều loại từ vựng với khả năng kiểm soát các đặc điểm của từ vựng rất tự nhiên và tinh vi; các lỗi nhỏ hiếm gặp chỉ xảy ra dưới dạng 'nhầm lẫn'"
};
export const COMMENT_GRAMMATICAL_RANGE_ACCURACY_WRITING = {
    0: "• Bài viết hoàn toàn không thể sử dụng các mẫu câu",
    1: "• Bài viết không thể sử dụng các mẫu câu ngoại trừ trong các cụm từ được ghi nhớ",
    2: "• Học viên đã cố gắng đặt câu nhưng bài viết còn tồn tại nhiều lỗi ngữ pháp và dấu câu chiếm và làm sai lệch ý nghĩa",
    3: "• Bài viết chỉ sử dụng một phạm vi cấu trúc rất hạn chế và hiếm khi sử dụng các mệnh đề phụ\n" +
        "• Một số cấu trúc chính xác nhưng đa số vẫn mắc lỗi và dấu câu thường không phù hợp",
    4: "• Bài viết chỉ sử dụng một số cấu trúc hạn chế\n" +
        "• Cố gắng đặt những câu phức tạp nhưng những câu này có xu hướng kém chính xác hơn những câu đơn giản\n" +
        "• Có thể mắc lỗi ngữ pháp thường xuyên và dấu câu có thể bị lỗi; lỗi có thể gây ra một số khó khăn cho người đọc",
    5: "• Bài viết chỉ sử dụng một số cấu trúc hạn chế và trùng lặp.\n" +
        "• Học viên có cố gắng đặt câu phức tạp nhưng những câu này thường có lỗi. Cấu trúc câu đơn giản được sử dụng với độ chính xác cao.\n" +
        "• Bài viết tồn tại một số lỗi ngữ pháp và thỉnh thoảng gây khó khăn cho người đọc. \n" +
        "• Một số lỗi dùng dấu câu.",
    6: "• Bài viết sử dụng kết hợp các dạng câu đơn giản và phức tạp\n" +
        "• Mắc một số lỗi ngữ pháp và dấu câu nhưng hiếm khi làm ảnh hưởng tới ngữ nghĩa ",
    7: "• Bài viết sử dụng nhiều cấu trúc phức tạp\n" +
        "• Phần lớn các câu trong bài đều không mắc lỗi \n" +
        "• Kiểm soát ngữ pháp và dấu câu tốt nhưng có thể mắc một vài lỗi",
    8: "• Bài viết sử dụng đa dạng các cấu trúc câu \n" +
        "• Đa số các câu trong bài đều không mắc lỗi \n" +
        "• Mắc một số lỗi rất hiếm",
    9: "• Bài viết sử dụng đa dạng các cấu trúc với đầy đủ tính linh hoạt và chính xác; các lỗi nhỏ hiếm gặp chỉ xảy ra dưới dạng 'nhầm lẫn'"
};
export const COMMENT_COHERENCE_COHESION_WRITING = {
    0: "• Học viên không tham gia phần thi Writing",
    1: "• Bài viết chưa truyền đạt được ý",
    2: "• Chưa kiểm soát được các tính năng của cấu trúc bài",
    3: "• Chưa trình bày được ideas một cách logic\n" +
        "• Học viên có thể sử dụng một số lượng rất hạn chế các Từ nối. Tuy nhiên các từ nối có thể chưa liên kết được mối quan hệ logic giữa các ideas.",
    4: "• Bài viết trình bày thông tin và ý tưởng nhưng chưa được sắp xếp một cách mạch lạc và chưa có sự tiến triển rõ ràng trong câu trả lời\n" +
        "• Sử dụng một số Từ nối cơ bản nhưng những từ nối này có thể không chính xác hoặc lặp đi lặp lại\n" +
        "• Có thể không viết thành đoạn văn hoặc chức năng đoạn có thể gây nhầm lẫn",
    5: "• Bài viết trình bày thông tin với một số cách sắp xếp ý tưởng nhưng có thể thiếu diễn tiến tổng thể\n" +
        "• Các từ nối chưa đầy đủ, chưa chính xác hoặc lạm dụng. \n" +
        "• Có thể lặp đi lặp lại vì thiếu tham chiếu và thay thế\n" +
        "• Có thể không viết thành đoạn văn, hoặc đoạn văn có thể không đầy đủ",
    6: "• Bài viết sắp xếp thông tin và ý tưởng một cách mạch lạc và có một diễn tiến tổng thể rõ ràng\n" +
        "• Sử dụng các Từ nối một cách hiệu quả, nhưng sự liên kết bên trong và/hoặc giữa các câu có thể bị lỗi hoặc máy móc hoá\n" +
        "• Có thể không phải lúc nào cũng sử dụng tham chiếu một cách rõ ràng hoặc phù hợp\n" +
        "• Bài viết có phân đoạn, nhưng không phải lúc nào cũng hợp lý",
    7: "• Bài viết sắp xếp thông tin và ý tưởng một cách hợp lý; có sự tiến bộ rõ ràng trong suốt bài \n" +
        "• Sử dụng một loạt các từ nối một cách thích hợp mặc dù có thể có một số từ bị sử dụng dưới mức/quá mức\n" +
        "• Trình bày một ý chính, trọng tâm rõ ràng trong mỗi đoạn văn",
    8: "• Bài viết trình bày các  thông tin và ý tưởng một cách hợp lý\n" +
        "• Có tính liên kết cao\n" +
        "• Phân đoạn đầy đủ và phù hợp",
    9: "• Bài viết có tính liên kết cao, rất tự nhiên \n" +
        "• Phân đoạn rất tốt, kĩ thuật tốt. "
};

export const GENERAL = 0;
export const LISTENING = 1;
export const READING = 2;
export const WRITING = 3;
export const SPEAKING = 4;

export const TYPE_TEST = new Map([
    [GENERAL, 'Vocabulary & Grammar'],
    [LISTENING, 'Listening'],
    [READING, 'Reading'],
    [WRITING, 'Writing'],
    [SPEAKING, 'Speaking']
])

export const TYPE_TEST_BY_KEY = new Map([
    ['vocabulary', GENERAL],
    ['listening', LISTENING],
    ['reading', READING],
    ['writing', WRITING],
    ['speaking', SPEAKING]
])

export const SPEAKING_CRITERIAES = [1, 2, 3, 4]
export const FLUENCY_AND_COHERENCE = 1
export const LEXICAL_RESOURCE = 2
export const GRAMMATICAL_RANGE_ACCURACY = 3
export const PRONUNCIATION = 4
export const TASK_ACHIEVEMENT_WRITING = 5
export const LEXICAL_RESOURCE_WRITING = 6
export const GRAMMATICAL_RANGE_ACCURACY_WRITING = 7
export const COHERENCE_COHESION_WRITING = 8
//
export const NAME_CRITERIA = new Map([
    ["fluency_and_coherence", "Độ trôi chảy và mạch lạc"],
    ["lexical_resource", "Vốn từ"],
    ["grammatical_range_accuracy", "Độ đa dạng và chính xác của ngữ pháp"],
    ["pronunciation", "Phát âm"],
    ["task_achievement_writing", "Đáp ứng yêu cầu đề bài"],
    ["lexical_resource_writing", "Từ vựng"],
    ["grammatical_range_accuracy_writing", "Ngữ pháp"],
    ["coherence_cohesion_writing", "Độ mạch lạc"]
])


//
export const TASK_NAME = new Map([
    [0, 'Test & Chấm Speaking'],
    [1, 'Chấm General'],
])

export const SHIFTS = new Map([
    [1, ['09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45']],
    [2, [ '14:30', '14:45', '15:00',
        '15:15', '15:30', '15:45', '16:00',
        '16:15', '16:30', '16:45', '17:00',
        '17:15', '17:30']],
    [3, ['18:00', '18:15', '18:30',
        '18:45', '19:00', '19:15', '19:30',
        '19:45', '20:00', '20:15', '20:30']],
    [4, ['21:00', '21:15', '21:30', '21:45', '22:00']],
    [5, ['08:15','08:30','08:45']],
    [6, ['13:45','14:00', '14:15',]],

])

export const SHIFTS2 = new Map([
    [1, ["", "09:00", "09:45", "10:30", "11:15", "12:00", ""]],
    [2, ["", "14:30", "15:15", "16:00", "16:45", "17:30", ""]],
    [3, ["", "18:00", "18:45", "19:30", "20:15", "21:00", ""]],
])
export const SHIFTS3 = new Map([
    [1, ["", "08:15", "09:00", "09:45", "10:30", "11:15", "12:00", ""]],
    [2, ["", "13:45", "14:30", "15:15", "16:00", "16:45", "17:30", ""]],
    [3, ["", "18:00", "18:45", "19:30", "20:15", "21:00", "21:45", ""]],
])

export const SHIFTS_TESTER = new Map([
    [1, [{time: "", type: 0}, {time: '08:15', type: 1}, {time: '08:30', type: 0}, {
        time: '08:45',
        type: 1
    }, {time: '09:00', type: 0}]],
    [2, [{time: "", type: 0}, {time: '09:00', type: 1}, {time: '09:15', type: 0}, {
        time: '09:30',
        type: 1
    }, {time: '09:45', type: 0}, {time: '10:00', type: 1}, {time: '10:15', type: 0}, {
        time: '10:30',
        type: 1
    }, {time: '10:45', type: 0}, {time: '11:00', type: 1}, {time: '11:15', type: 0}, {
        time: '11:30',
        type: 1
    }, {time: '11:45', type: 0}, {time: '12:00', type: 1}]],
    [3, [{time: "", type: 0}, {time: '13:45', type: 1}, {time: '14:00', type: 0}, {time: '14:15', type: 1}]],

    [4, [{time: "", type: 0}, {
        time: '14:30',
        type: 1
    }, {time: '14:45', type: 0}, {
        time: '15:00',
        type: 1
    }, {time: '15:15', type: 0}, {time: '15:30', type: 1}, {time: '15:45', type: 0}, {
        time: '16:00',
        type: 1
    }, {time: '16:15', type: 0}, {time: '16:30', type: 1}, {time: '16:45', type: 0}, {
        time: '17:00',
        type: 1
    }, {time: '17:15', type: 0}, {time: '17:30', type: 1}]],

    [5, [{time: "", type: 0}, {time: '18:00', type: 1}, {time: '18:15', type: 0}, {
        time: '18:30',
        type: 1
    }, {time: '18:45', type: 0}, {time: '19:00', type: 1}, {time: '19:15', type: 0}, {
        time: '19:30',
        type: 1
    }, {time: '19:45', type: 0}, {time: '20:00', type: 1}, {time: '20:15', type: 0}, {
        time: '20:30',
        type: 1
    }]],
    [6, [{time: "", type: 0}, {time: '21:00', type: 1}, {time: '21:15', type: 0}, {
        time: '21:30',
        type: 1
    }, {time: '21:45', type: 0}, {time: '22:00', type: 1}]],
])
export const STATUS_TO_DO = 0;
export const STATUS_SUCCESS = 1;
export const STATUS_CANCEL = 2;
export const STATUS_PENDING = 3;
export const STATUS_FAlURE = 4;

export const MARK_SPEAKING = 0
export const MARK_GENERAL = 1

export const TIME_SELECT_OPTION = [
    {
        label: 'Sáng',
        live_time: 1,
        ca: 1,
        options: [
            {
                value: '09:00',
                label: '09:00',
                live_time: 0,
            },
            {
                value: '09:45',
                label: '09:45',
                live_time: 0,
            },
            {
                value: '10:30',
                label: '10:30',
                live_time: 0,
            },
            {
                value: '11:15',
                label: '11:15',
                live_time: 0,
            }
        ]
    },
    {
        label: 'Chiều',
        live_time: 2,
        ca: 2,
        options: [
            {
                value: '14:30',
                label: '14:30',
                live_time: 0,
            },
            {
                value: '15:15',
                label: '15:15',
                live_time: 0,
            },
            {
                value: '16:00',
                label: '16:00',
                live_time: 0,
            },
            {
                value: '16:45',
                label: '16:45',
                live_time: 0,
            }
        ]
    },
    {
        label: 'Tối',
        live_time: 3,
        ca: 3,
        options: [
            {
                value: '18:00',
                label: '18:00',
                live_time: 0,
            },
            {
                value: '18:45',
                label: '18:45',
                live_time: 0,
            },
            {
                value: '19:30',
                label: '19:30',
                live_time: 0,
            },
            {
                value: '20:15',
                label: '20:15',
                live_time: 0,
            }
        ]
    },
]
export const TIME_SELECT_OPTION_ONLINE = [
    {
        label: 'Sáng',
        live_time: 1,
        ca: 1,
        options: [
            {
                value: '08:15',
                label: '08:15',
                live_time: 0,
            },
            {
                value: '09:00',
                label: '09:00',
                live_time: 0,
            },
            {
                value: '09:45',
                label: '09:45',
                live_time: 0,
            },
            {
                value: '10:30',
                label: '10:30',
                live_time: 0,
            },
            {
                value: '11:15',
                label: '11:15',
                live_time: 0,
            }
        ]
    },
    {
        label: 'Chiều',
        live_time: 2,
        ca: 2,
        options: [
            {
                value: '13:45',
                label: '13:45',
                live_time: 0,
            },
            {
                value: '14:30',
                label: '14:30',
                live_time: 0,
            },
            {
                value: '15:15',
                label: '15:15',
                live_time: 0,
            },
            {
                value: '16:00',
                label: '16:00',
                live_time: 0,
            },
            {
                value: '16:45',
                label: '16:45',
                live_time: 0,
            }
        ]
    },
    {
        label: 'Tối',
        live_time: 3,
        ca: 3,
        options: [
            {
                value: '18:00',
                label: '18:00',
                live_time: 0,
            },
            {
                value: '18:45',
                label: '18:45',
                live_time: 0,
            },
            {
                value: '19:30',
                label: '19:30',
                live_time: 0,
            },
            {
                value: '20:15',
                label: '20:15',
                live_time: 0,
            },
            {
                value: '21:00',
                label: '21:00',
                live_time: 0,
            }
        ]
    },
]
// FLUENCY AND COHERENCE
export const EXPAND_AND_DEVELOPIDEAS = {
    vName: 'Mở rộng và phát triển ý',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Có thể trả lời câu hỏi nhưng chỉ bằng các từ ngữ đơn lẻ và đơn giản, chưa có khả năng mở rộng ý tưởng và diễn đạt ý kiến một cách logic.",
        3: "Chưa có khả năng mở rộng ý tưởng, thường sử dụng ngôn ngữ cơ bản và chưa diễn đạt ý kiến một cách rõ ràng và logic.",
        4: "Khả năng mở rộng ý tưởng rất hạn chế và không có sự phát triển ý rõ ràng. Bạn sử dụng ngôn từ ở mức cơ bản và chưa diễn đạt ý kiến một cách chính xác.",
        5: "Mở rộng ý tưởng tương đối hạn chế và chưa có sự phát triển ý tưởng rõ ràng. Bạn có thể sử dụng ngôn từ ở mức cơ bản, đôi khi thiếu sự chính xác khi biểu đạt ý kiến.",
        6: "Có khả năng mở rộng ý tưởng nhưng chưa đủ liên kết chặt chẽ, thi thoảng lặp lại ý tưởng hoặc thông tin. Bạn sử dụng ngôn từ ở mức cơ bản, chưa thực sự đa dạng và sáng tạo.",
        7: "Khả năng mở rộng ý tưởng của bạn khá tốt, nhưng đôi khi thiếu sự linh hoạt trong việc sử dụng ngôn ngữ. Bạn có thể diễn đạt ý kiến rõ ràng, nhưng chưa đa dạng trong cấu trúc ngữ pháp và từ vựng.",
        8: "Khả năng phát triển ý tưởng của bạn tốt và linh hoạt, dùng ngôn ngữ một cách chính xác và sáng tạo. Bạn biểu đạt ý kiến rõ ràng và có thể sử dụng một số cấu trúc ngữ pháp và từ vựng phức tạp.",
        9: "Khả năng mở rộng ý tưởng một cách tự nhiên và thành thạo. Bạn sử dụng một loạt các cấu trúc ngữ pháp và từ vựng phong phú, linh hoạt để trình bày ý kiến, và có khả năng diễn đạt ý tưởng phức tạp một cách rõ ràng và logic.",
    }
};
export const PAUSE_AND_HESITATE = {
    vName: 'Ngắt nghỉ và ngập ngừng',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Có thể trả lời câu hỏi nhưng chỉ bằng các từ ngữ đơn lẻ và mất quá nhiều thời gian để nói từ tiếp theo, có nhiều ngắt nghỉ và ngập ngừng dẫn đến khó hiểu.",
        3: "Gặp nhiều khó khăn trong việc duy trì luồng nói liền mạch, thường ngắt nghỉ và ngập ngừng nhiều, khiến bài nói trở nên khó hiểu và mất tính chính xác.",
        4: "Duy trì mạch nói rất hạn chế, thường xuyên ngắt nghỉ và ngập ngừng, dẫn đến bài nói chưa đủ tính mạch lạc và dễ hiểu.",
        5: "Duy trì mạch nói khá hạn chế và đa phần gặp ngắt nghỉ và ngập ngừng. Sự gián đoạn này có thể ảnh hưởng đến sự rõ ràng và dễ hiểu của bài nói.",
        6: "Duy trì mạch nói ở mức trung bình. Đôi lúc bạn ngắt nghỉ và ngập ngừng trong khi nói, có phần làm giảm sự mạch lạc của bài nói.",
        7: "Duy trì luồng nói tương đối liền mạch, mặc dù đôi khi bài nói có thể có một số ngắt nghỉ và ngập ngừng. Sự ngắt nghỉ này không gây ra sự rối loạn lớn trong luồng ý tưởng.",
        8: "Khả năng nói liền mạch một cách khá tự nhiên, mặc dù bài nói có thể có một số ngắt nghỉ nhỏ hoặc ngập ngừng không đáng kể. Tuy nhiên, điều này không ảnh hưởng nhiều đến việc truyền đạt ý kiến.",
        9: "Khả năng nói liền mạch một cách tự nhiên và mượt mà, không có ngắt nghỉ hoặc ngập ngừng đáng kể, nếu có thì đều có chủ đích. Bạn duy trì luồng nói một cách mạch lạc và dễ dàng theo dõi, thể hiện sự chính xác và tự tin trong diễn đạt.",
    }
};
export const SPEAKING_SPEED = {
    vName: 'Tốc độ nói',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Chưa có khả năng duy trì tốc độ nói ổn định, chỉ có thể nói được những từ đơn lẻ và rời rạc, vẫn có thể hiểu được nhưng gặp nhiều khó khăn.",
        3: "Chưa có khả năng duy trì tốc độ nói ổn định, đa phần có sự thay đổi đột ngột và không đều, khiến bài nói không thể hiện được tính chính xác và dễ hiểu.",
        4: "Duy trì tốc độ nói rất hạn chế, đa phần có sự thay đổi đột ngột trong tốc độ nói, làm bài nói thiếu đi tính liên tục và dễ hiểu.",
        5: "Duy trì tốc độ nói ở mức hạn chế và thường xuyên có sự thay đổi đột ngột, khiến bài nói thiếu đi tính liên tục và sự dễ hiểu.",
        6: "Khả năng duy trì tốc độ nói ở mức trung bình. Đôi khi có sự thay đổi đột ngột trong tốc độ nói, làm giảm đi tính liên tục và mạch lạc của bài nói.",
        7: "Khả năng duy trì tốc độ nói tương đối ổn định. Tuy nhiên, đôi lúc ngắt quãng và chậm lại, điều này có thể làm giảm sự mạch lạc trong bài nói.",
        8: "Khả năng duy trì tốc độ nói khá tốt. Bạn điều chỉnh tốc độ để phù hợp với nội dung và người nghe, mặc dù có thể có một số chỗ ngắt quãng và tốc độ không đều.",
        9: "Khả năng duy trì tốc độ nói lý tưởng, phù hợp với bài nói và người nghe. Bạn điều chỉnh tốc độ nói một cách linh hoạt để phù hợp với các ý tưởng phức tạp và biểu đạt một cách rõ ràng và tự tin.",
    }
};
export const USEPHRASES_TO_OPEN_LEAD_AND_CONNECT = {
    vName: 'Sử dụng các cụm từ để mở đầu, dẫn dắt, kết nối',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Chưa có khả năng sử dụng các cụm từ mở đầu và kết nối, do mới chỉ trả lời được bằng những từ ngữ đơn lẻ và rời rạc.",
        3: "Chưa có khả năng sử dụng các cụm từ mở đầu và kết nối, dẫn đến bài nói trở nên rất khó hiểu và không có mạch lạc.",
        4: "Sử dụng các cụm từ mở đầu và kết nối rất hạn chế, dẫn đến bài nói trở nên rời rạc và khó hiểu.",
        5: "Sử dụng các cụm từ mở đầu và kết nối hạn chế. Bạn sử dụng ít cấu trúc, cụm từ để mở đầu và kết nối ý tưởng, dẫn đến chưa thể hiện được sự mạch lạc trong bài nói.",
        6: "Sử dụng các cụm từ mở đầu và kết nối ở mức trung bình. Bạn có thể sử dụng một số cấu trúc, cụm từ để mở đầu và kết nối ý tưởng, nhưng đôi khi có sự lặp lại và thiếu đồng nhất trong việc sử dụng chúng.",
        7: "Khả năng sử dụng các cụm từ mở đầu và kết nối tương đối tốt. Bạn sử dụng một số cấu trúc, cụm từ để mở đầu và liên kết ý tưởng trong bài nói, mặc dù đôi lúc chưa thực sự đồng nhất và linh hoạt trong việc sử dụng chúng.",
        8: "Biết cách sử dụng các cụm từ mở đầu và kết nối một cách linh hoạt và khéo léo. Bạn có khả năng sử dụng một số cấu trúc, cụm từ để mở đầu, dẫn dắt ý tưởng và kết nối các phần trong bài nói một cách hợp lý.",
        9: "Biết cách sử dụng các cụm từ mở đầu và kết nối câu một cách tự nhiên và linh hoạt. Bạn có khả năng sử dụng một loạt các cấu trúc, cụm từ phong phú và đa dạng để mở đầu bài nói, dẫn dắt ý tưởng và liên kết các phần trong bài nói một cách mạch lạc và hợp lý.",
    }
};
export const COHERENCE = {
    vName: 'Độ mạch lạc',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Chưa có khả năng diễn đạt một cách mạch lạc, có nhiều sự gián đoạn và thiếu liên kết do mới chỉ trả lời được từng từ tách biệt và rời rạc",
        3: "Chưa có khả năng diễn đạt sự mạch lạc, có nhiều sự gián đoạn và thiếu liên kết, dẫn đến việc bài nói trở nên rất khó hiểu.",
        4: "Khả năng diễn đạt sự mạch lạc còn hạn chế, có nhiều sự gián đoạn và thiếu liên kết giữa các ý tưởng.",
        5: "Khả năng diễn đạt sự mạch lạc còn hạn chế và chưa có sự liên kết rõ ràng giữa các ý tưởng, khiến bài nói của bạn có thể bị gián đoạn và khó hiểu.",
        6: "Khả năng diễn đạt mạch lạc ở mức trung bình. Đôi khi có sự gián đoạn và thiếu liên kết giữa các ý tưởng, chưa thể hiện tốt logic trong bài nói.",
        7: "Khả năng diễn đạt mạch lạc nhưng đôi khi có một số gián đoạn nhỏ và sự thiếu liên kết giữa các ý tưởng. Bạn có khả năng móc nối logic giữa các ý tưởng nhưng chưa sử dụng được liên tục.",
        8: "Khả năng diễn đạt mạch lạc và logic. Bạn thể hiện sự liên kết rõ ràng giữa các ý tưởng và thông tin, mặc dù có một số gián đoạn nhỏ trong việc diễn đạt.",
        9: "Khả năng diễn đạt mạch lạc, logic và mượt mà. Bạn có khả năng sử dụng các liên kết logic giữa các ý tưởng, câu và đoạn văn một cách xuất sắc, tạo ra một bài nói tự nhiên và dễ hiểu.",
    }
};
// LEXICAL RESOURCE
export const WORD_DIVERSITY = {
    vName: 'Độ đa dạng của từ vựng',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Chưa có khả năng sử dụng từ vựng cơ bản, do mới chỉ có thể trả lời bằng những từ đơn giản và rời rạc. Thường xuyên sử dụng từ ngữ không chính xác hoặc không phù hợp.",
        3: "Chưa có khả năng sử dụng từ vựng cơ bản, gây ra sự không rõ ràng và không hiểu rõ ý kiến của bạn. Thường xuyên sử dụng từ ngữ chưa chính xác hoặc chưa phù hợp.",
        4: "Khả năng sử dụng từ vựng rất hạn chế và cơ bản. Đôi khi bạn sử dụng từ ngữ chưa phù hợp, khiến việc diễn đạt ý kiến chưa rõ ràng và chính xác.",
        5: "Khả năng sử dụng từ vựng khá hạn chế và cơ bản. Bạn sử dụng từ ngữ cơ bản để trình bày ý kiến, có thể còn gặp chút khó khăn trong việc biểu đạt ý tưởng phức tạp.",
        6: "Khả năng sử dụng từ vựng ở mức trung bình. Bạn sử dụng từ ngữ ở mức cơ bản và chưa được đa dạng, đôi khi lặp từ hoặc sử dụng từ vựng chưa phù hợp trong một số trường hợp.",
        7: "Khả năng sử dụng từ vựng tương đối đa dạng và phong phú. Bạn sử dụng từ ngữ chính xác để diễn đạt ý kiến, tuy nhiên, chưa thể hiện rõ tính linh hoạt và sự sáng tạo trong việc sử dụng từ vựng.",
        8: "Khả năng sử dụng từ vựng khá đa dạng và linh hoạt. Bạn sử dụng từ ngữ chính xác và phong phú để biểu đạt ý kiến, mặc dù có thể không sáng tạo và phức tạp như ở band điểm 9.",
        9: "Khả năng sử dụng từ vựng phong phú và linh hoạt. Bạn biết cách sử dụng từ ngữ chính xác, phức tạp và đa dạng để truyền đạt ý kiến một cách mượt mà và sáng tạo.",
    }
};
export const ACCURACY_IN_USE_VOCABULARY = {
    vName: 'Độ chính xác trong sử dụng từ vựng',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Gặp rất nhiều khó khăn trong việc sử dụng từ vựng. Lượng từ nói ra được còn hạn chế, và rời rạc, đi kèm với việc có xảy ra lỗi trong việc chọn từ hoặc sử dụng từ không phù hợp, khiến cho ý tưởng không được truyền tải đầy đủ và chính xác.",
        3: "Thường gặp nhiều khó khăn trong việc chọn từ vựng để sử dụng. Đa phần sử dụng từ vựng chưa phù hợp, dẫn đến sự truyền đạt ý kiến thiếu chính xác.",
        4: "Khả năng sử dụng từ vựng rất hạn chế và đa phần gặp nhiều lỗi trong việc chọn từ hoặc sử dụng từ không phù hợp, khiến sự diễn đạt chưa rõ ràng và chính xác.",
        5: "Khả năng sử dụng từ vựng khá hạn chế và thi thoảng gặp lỗi trong việc chọn từ, sử dụng từ chưa phù hợp với ngữ cảnh, gây hiểu lầm cho người nghe.",
        6: "Khả năng sử dụng từ vựng ở mức trung bình. Bạn sử dụng từ ngữ cơ bản và phù hợp để trình bày ý kiến, tuy nhiên có đôi lúc từ vựng bạn sử dụng dễ gây hiểu nhầm hoặc sự lặp từ vựng.",
        7: "Khả năng sử dụng từ vựng tương đối chính xác và phù hợp với ngữ cảnh. Bạn có khả năng chọn từ ngữ tương đối chính xác để diễn đạt ý kiến, mặc dù có đôi lúc từ vựng bạn sử dụng dễ gây hiểu nhầm hoặc sự lặp từ vựng.",
        8: "Khả năng sử dụng từ vựng khá chính xác và phong phú. Bạn chọn từ ngữ phù hợp với ngữ cảnh và biểu đạt ý kiến một cách rõ ràng. Mặc dù có thể chưa sáng tạo như ở band điểm cao hơn, nhưng vẫn đạt được mức độ linh hoạt.",
        9: "Khả năng sử dụng từ vựng rất chính xác và phong phú. Bạn chọn từ ngữ phù hợp với ngữ cảnh và biểu đạt ý kiến một cách chính xác, không gây hiểu lầm. Sự linh hoạt và sáng tạo trong việc sử dụng từ vựng cũng là điểm mạnh của bạn.",
    }
};
export const PARAPHRASING = {
    vName: 'Paraphrasing',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Chưa có khả năng paraphrase. Sự tái diễn ý tưởng chưa chính xác và chưa linh hoạt, dù giao tiếp được bằng những từ đơn lẻ, rời rạc, nhưng vẫn có sự lặp lại khá nhiều.",
        3: "Chưa có khả năng paraphrase. Sự tái diễn ý tưởng chưa chính xác hoặc không linh hoạt, dẫn đến sự hiểu lầm và không rõ ràng trong diễn đạt.",
        4: "Khả năng paraphrase rất hạn chế. Đa phần bạn chưa thể tái diễn ý tưởng một cách chính xác hoặc linh hoạt, và có thể dẫn đến sự hiểu lầm.",
        5: "Khả năng paraphrase hạn chế. Bạn có thể tái diễn được ý tưởng nhưng đa phần thiếu sự linh hoạt và chính xác trong việc sử dụng từ ngữ, dẫn đến sự lặp lại.",
        6: "Khả năng paraphrase ở mức trung bình. Bạn có thể tái diễn được ý tưởng nhưng đa phần sử dụng từ vựng và cấu trúc câu gần giống nhau, chưa có nhiều sự linh hoạt.",
        7: "Khả năng paraphrase tương đối tốt. Bạn có khả năng tái diễn ý tưởng với một mức độ chính xác và linh hoạt, dù có thể thiếu tính sáng tạo trong việc sử dụng từ ngữ.",
        8: "Khả năng paraphrase khá tốt. Bạn thường tái diễn ý tưởng một cách chính xác và linh hoạt, dùng từ vựng và cấu trúc câu khác nhau để biểu đạt ý kiến một cách rõ ràng.",
        9: "Khả năng paraphrase rất tốt và linh hoạt. Bạn biết cách tái diễn ý tưởng một cách sáng tạo và chính xác, sử dụng từ vựng và cấu trúc câu khác nhau một cách linh hoạt và tự nhiên.",
    }
};
// Paraphrasing
export const PRONUNCIATION_ABILITY = {
    vName: 'Khả năng phát âm',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Khả năng phát âm rất hạn chế do chỉ trả lời được bằng những từ đơn lẻ và rời rạc. Lỗi phát âm xuất hiện thường xuyên và gây khó hiểu khi truyền đạt ý kiến.",
        3: "Khả năng phát âm rất hạn chế. Lỗi phát âm xuất hiện thường xuyên và có thể gây khó hiểu khi truyền đạt ý kiến.",
        4: "Khả năng phát âm rất hạn chế. Bạn gặp nhiều lỗi phát âm và có thể không dễ hiểu cho người nghe.",
        5: "Khả năng phát âm hạn chế. Đa phần bạn gặp nhiều lỗi phát âm, dẫn đến mất đi tính rõ ràng và dễ hiểu của bài nói.",
        6: "Khả năng phát âm ở mức trung bình. Bạn có thể phát âm các âm và từ ngữ một cách tương đối rõ ràng, nhưng đôi khi gặp lỗi phát âm nhỏ, có thể làm giảm đi sự dễ hiểu của bài nói.",
        7: "Khả năng phát âm tương đối chính xác. Bạn có khả năng phát âm các âm thanh và từ ngữ rõ ràng và dễ hiểu, mặc dù có thể gặp một số lỗi nhỏ hoặc cần sự cải thiện để phát âm rõ ràng hơn.",
        8: "Khả năng phát âm khá chính xác và tự nhiên. Bạn thường phát âm các âm thanh và từ ngữ một cách rõ ràng và dễ hiểu, mặc dù có thể gặp một số lỗi nhỏ không ảnh hưởng nhiều đến sự dễ hiểu.",
        9: "Khả năng phát âm rất chính xác và tự nhiên. Bạn phát âm các âm thanh, từ ngữ và câu văn một cách rõ ràng và dễ hiểu, không gặp lỗi phát âm nào đáng kể.",
    }
};
export const STRESS = {
    vName: 'Trọng âm',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Chưa có khả năng nhấn trọng âm, do mới chỉ trả lời bằng những từ ngữ đơn lẻ cũng tạo nên sự khó hiểu trong nội dung trả lời.",
        3: "Thường không có khả năng nhấn trọng âm. Việc không nhấn trọng âm đúng gây ra mất tính rõ ràng và khó hiểu rõ ý kiến của bạn.",
        4: "Khả năng nhấn trọng âm cho các từ rất hạn chế. Bạn đa phần không nhấn đúng trọng âm, gây thiếu tính rõ ràng và dễ hiểu của bài nói.",
        5: "Khả năng nhấn trọng âm cho các từ hạn chế. Bạn đôi khi không nhấn đúng trọng âm cho một số từ, gây ra sự không rõ ràng và khó hiểu.",
        6: "Khả năng nhấn trọng âm cho các từ ở mức trung bình. Bạn có thể nhấn trọng âm cho các từ một cách tương đối đúng, nhưng còn gặp khó khăn trong việc duy trì chính xác và nhất quán.",
        7: "Khả năng nhấn trọng âm cho các từ tương đối chính xác. Bạn thường áp dụng quy tắc về trọng âm và làm cho các từ trở nên tương đối rõ ràng và dễ hiểu, mặc dù có thể có một số từ không được nhấn trọng âm chính xác.",
        8: "Khả năng nhấn trọng âm cho các từ khá chính xác. Bạn thường áp dụng đúng quy tắc về trọng âm và làm cho các từ trở nên rõ ràng và dễ hiểu, mặc dù có thể gặp một số trường hợp không nhấn trọng âm hoàn toàn chính xác.",
        9: "Khả năng nhấn trọng âm cho các từ rất chính xác và tự nhiên. Bạn hiểu và áp dụng chính xác quy tắc về trọng âm, khiến cho bài nói của họ trở nên dễ hiểu và tự nhiên.",
    }
};
export const INTONATION = {
    vName: 'Ngữ điệu',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Chưa có khả năng sử dụng ngữ điệu do chỉ có thể trả lời bằng những từ ngữ đơn lẻ và rời rạc. Từ đó dẫn đến thiếu sự rõ ràng và khó hiểu rõ ý nghĩa cần truyền đạt.",
        3: "Chưa có khả năng sử dụng ngữ điệu. Việc không sử dụng ngữ điệu gây mất tính rõ ràng và khó hiểu rõ ý nghĩa cần truyền đạt.",
        4: "Khả năng sử dụng ngữ điệu rất hạn chế. Bạn đa phần không thể thay đổi âm điệu một cách chính xác, chưa thể hiện được tính rõ ràng và cảm xúc của bài nói.",
        5: "Khả năng sử dụng ngữ điệu hạn chế. Bạn khó có thể thay đổi âm điệu một cách hiệu quả, dẫn đến thiếu tính rõ ràng và chưa đầy đủ ý nghĩa cần truyền đạt",
        6: "Khả năng sử dụng ngữ điệu ở mức trung bình. Bạn có thể thay đổi âm điệu nhưng đa phần chưa được nhất quán hoặc không chính xác, làm giảm tính rõ ràng của bài nói.",
        7: "Khả năng sử dụng ngữ điệu tương đối linh hoạt. Bạn có khả năng thay đổi âm điệu để diễn đạt ý nghĩa và cảm xúc, mặc dù có thể không nhất quán.",
        8: "Khả năng sử dụng ngữ điệu khá linh hoạt và tự nhiên. Bạn thường sử dụng ngữ điệu một cách rõ ràng để thể hiện ý nghĩa và cảm xúc trong bài nói, mặc dù có thể không xuất sắc như ở band điểm 9",
        9: "Khả năng sử dụng ngữ điệu một cách rất linh hoạt và tự nhiên. Bạn biết cách thay đổi âm điệu một cách chính xác để truyền đạt ý nghĩa, cảm xúc và sự nhấn mạnh trong bài nói của mình.",
    }
};
export const RHYTHM_SAID = {
    vName: 'Nhịp nói',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Chưa có khả năng duy trì nhịp điệu nói, do mới chỉ có thể trả lời bằng những từ ngữ đơn lẻ và rời rạc.",
        3: "Chưa có khả năng duy trì nhịp điệu. Chưa duy trì được nhịp điệu chính xác khiến bài nói mất đi tính liên tục và dễ nghe.",
        4: "Khả năng duy trì nhịp điệu rất hạn chế. Bạn đa phần không duy trì được nhịp điệu chính xác, khiến bài nói mất đi tính liên tục và dễ nghe.",
        5: "Khả năng duy trì nhịp điệu hạn chế. Bạn có thể vẫn còn gặp khó khăn trong việc duy trì một nhịp điệu chính xác và thường bị gián đoạn trong luồng nói.",
        6: "Khả năng duy trì nhịp điệu ở mức trung bình. Bạn có thể duy trì nhịp điệu tương đối chính xác, nhưng đôi lúc không nhất quán hoặc có thể bị gián đoạn.",
        7: "Khả năng duy trì nhịp điệu tương đối tự nhiên. Bạn có thể duy trì nhịp điệu, mặc dù có thể không nhất quán hoặc không linh hoạt hoàn toàn.",
        8: "Khả năng duy trì nhịp điệu khá tự nhiên và linh hoạt. Bạn thường duy trì một nhịp điệu chính xác, làm cho luồng nói trở nên dễ hiểu và truyền đạt ý nghĩa tốt",
        9: "Khả năng duy trì nhịp điệu một cách rất tự nhiên và linh hoạt. Bạn giữ được nhịp điệu câu chữ một cách chính xác và thích hợp, tạo ra một luồng nói rõ ràng và dễ nghe.",
    }
};
//GRAMMATICAL RANGE AND ACCURACY
export const GRAMMAR_DIVERSITY = {
    vName: 'Độ đa dạng của ngữ pháp',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Chưa thể hiện được khả năng sử dụng các cấu trúc ngữ pháp nào do chỉ có thể trả lời bằng các từ ngữ đơn lẻ, rời rạc.",
        3: "Thường không sử dụng các cấu trúc ngữ pháp nào ngoài câu đơn. Sự thiếu đa dạng và cấu trúc ngữ pháp chưa đúng khiến bài nói không thể hiện được chính xác và rõ ràng.",
        4: "Sử dụng các cấu trúc ngữ pháp rất hạn chế. Bạn đa phần chỉ sử dụng một số cấu trúc ngữ pháp cơ bản và có thể gặp nhiều lỗi khi sử dụng chúng.",
        5: "Sử dụng các cấu trúc ngữ pháp hạn chế. Bạn có thể sử dụng một số cấu trúc ngữ pháp cơ bản, nhưng đa phần chưa linh hoạt và có thể vẫn còn gặp khó khăn trong việc sử dụng một cách chính xác",
        6: "Sử dụng các cấu trúc ngữ pháp ở mức trung bình. Bạn đôi khi sử dụng một số cấu trúc ngữ pháp khá phổ biến, nhưng đa phần chưa đa dạng và có thể gặp lặp lại.",
        7: "Sử dụng các cấu trúc ngữ pháp tương đối đa dạng. Bạn có khả năng sử dụng một loạt các cấu trúc ngữ pháp, mặc dù có thể không nhất quán hoặc không sử dụng một cách linh hoạt",
        8: "Sử dụng các cấu trúc ngữ pháp khá đa dạng và phong phú. Bạn có khả năng sử dụng cấu trúc ngữ pháp phức tạp và linh hoạt, mặc dù có thể không linh hoạt và xuất sắc như ở band điểm 9.",
        9: "Sử dụng các cấu trúc ngữ pháp rất đa dạng và phong phú. Bạn biết cách sử dụng cấu trúc ngữ pháp phức tạp và linh hoạt, đồng thời tránh được sự lặp lại và sử dụng một cách chính xác, tự nhiên.",
    }
};
export const ACCURACY_IN_USING_GRAMMAR = {
    vName: 'Độ chính xác trong sử dụng ngữ pháp',
    pointLadder: {
        0: "Bạn gần như chưa thể nói được.",
        1: "Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi.",
        2: "Chưa thể hiện được khả năng kiểm soát ngữ pháp do chỉ có thể trả lời bằng các từ ngữ đơn lẻ và rời rạc. Có thể xuất hiện các lỗi ngữ pháp, gây ảnh hưởng đến nội dung câu trả lời muốn truyền tải.",
        3: "Chưa có sự chính xác ngữ pháp ở hầu hết các trường hợp. Lỗi ngữ pháp xuất hiện thường xuyên và ảnh hưởng tới tính chính xác của thông điệp bạn muốn truyền tải.",
        4: "Có sự chính xác ngữ pháp rất hạn chế. Bạn thường gặp nhiều lỗi ngữ pháp cơ bản và có thể gây hiểu lầm khi diễn đạt ý kiến.",
        5: "Có sự chính xác ngữ pháp hạn chế. Bạn đa phần sử dụng một số cấu trúc ngữ pháp cơ bản, đôi khi gặp lỗi và chưa thể hiện được tính chính xác trong diễn đạt.",
        6: "Có sự chính xác ngữ pháp ở mức trung bình. Bạn có thể sử dụng một số cấu trúc ngữ pháp cơ bản đúng, nhưng đôi khi vẫn gặp lỗi và có thể gây hiểu lầm cho người nghe.",
        7: "Có sự chính xác ngữ pháp tương đối. Bạn sử dụng ngữ pháp một cách chính xác trong phần lớn các trường hợp, nhưng đôi khi gặp một số lỗi không nghiêm trọng hoặc chưa linh hoạt khi sử dụng ngữ pháp phức tạp.",
        8: "Có sự chính xác ngữ pháp khá cao. Bạn thường sử dụng ngữ pháp một cách chính xác, nhưng có thể gặp một số lỗi nhỏ không ảnh hưởng nhiều đến sự hiểu rõ của thông điệp.",
        9: "Có sự chính xác ngữ pháp rất cao. Bạn sử dụng ngữ pháp một cách chính xác và linh hoạt trong nhiều tình huống khác nhau, hiểu rõ và sử dụng các cấu trúc ngữ pháp phức tạp một cách tự nhiên và không gặp lỗi nhiều.",
    }
};
//SkillWriting
export const CONTENT_COMMUNICATION_CIRCUIT = {
    vName: 'Thực hiện yêu cầu của đề bài',
    pointLadder: {
        1: "Học viên không viết được một chút nào",
        2: "Bạn chưa hoàn thành được bài viết. Bài viết của bạn chưa trả lời được vấn đề mà đề bài đặt ra, không liên quan hoặc hiểu sai chủ đề.",
        3: "Bạn chưa hoàn thành được bài viết. Bài viết của bạn không đáp ứng yêu cầu của đề bài, không liên quan hoặc không hiểu rõ chủ đề.",
        4: "Bạn hoàn thành bài viết ở mức rất hạn chế. Bạn chưa trả lời đầy đủ yêu cầu cơ bản của đề bài, có thể bị lạc khỏi chủ đề hoặc không hiểu rõ yêu cầu.",
        5: "Bạn hoàn thành bài viết ở mức hạn chế. Bạn chưa trả lời đầy đủ các yêu cầu của đề bài, thiếu chi tiết và logic, làm giảm tính toàn diện và hiệu quả của bài viết.",
        6: "Bạn hoàn thành bài viết ở mức trung bình. Bạn trả lời một số yêu cầu của đề bài, nhưng thường thiếu chi tiết và chưa rõ ràng hoặc hơi lệch hướng so với đề bài.",
        7: "Bạn hoàn thành bài viết tương đối tốt. Bạn trả lời được nhiều yêu cầu của đề bài, mặc dù có thể thiếu chi tiết, nhưng ý kiến vẫn rõ ràng và logic.",
        8: "Bạn hoàn thành bài viết khá tốt. Bạn trả lời đầy đủ các yêu cầu của đề bài và cung cấp ý kiến rõ ràng và logic, mặc dù có thể không chi tiết và sâu sắc như ở band điểm 9.",
        9: "Bạn hoàn thành bài viết một cách xuất sắc. Bạn trả lời đầy đủ và chi tiết các yêu cầu của đề bài, không chỉ đáp ứng các yêu cầu cơ bản mà còn phát triển ý kiến một cách sâu sắc, minh bạch và logic.",
    }
};
export const ARGUMENT_AND_LOGICAL_CALCULATION_OF_THE_ARTICLE = {
    vName: 'Lập luận và tính logic của bài viết',
    pointLadder: {
        1: "Học viên không viết được một chút nào",
        2: "Bài viết gần như không có lập luận. Các câu viết rời rạc và phần lớn không liên quan đến nhau.",
        3: "Thường không có lập luận. Lập luận của bạn không rõ ràng, không logic và gây mất tính rõ ràng và đúng đắn của bài viết.",
        4: "Có lập luận rất hạn chế. Bạn thường chưa cung cấp được lập luận logic, làm giảm tính toàn diện và ý nghĩa của bài viết.",
        5: "Có lập luận hạn chế. Bạn có thể cung cấp các ý kiến, nhưng thường chưa được sắp xếp logic, làm giảm tính rõ ràng và hiệu quả của bài viết.",
        6: "Có lập luận ở mức trung bình. Bạn có thể cung cấp các ý kiến một cách tương đối logic, nhưng thường thiếu chi tiết và có thể chưa rõ ràng hoặc hơi lệch hướng so với chủ đề.",
        7: "Có lập luận tương đối rõ ràng và có logic. Bạn cung cấp các ý kiến một cách hợp lý và có cấu trúc logic, mặc dù có đôi phần không nhất quán hoặc không được minh bạch hoàn toàn.",
        8: "Có lập luận khá rõ ràng và có logic. Bạn phát triển các ý kiến một cách rõ ràng và có cấu trúc logic, mặc dù không chi tiết và sâu sắc như ở band điểm 9.",
        9: "Có lập luận rất rõ ràng và có logic. Bạn phát triển các ý kiến một cách logic, minh bạch và liên kết, sử dụng các ví dụ và luận điểm phù hợp để minh chứng và bảo vệ quan điểm của mình.",
    }
};
// COHERENCE AND COHESION
export const COHERENCE_IN_CONTENT = {
    vName: 'Mạch lạc về mặt nội dung',
    pointLadder: {
        1: "Học viên không viết được một chút nào",
        2: "Gần như chưa có sự mạch lạc về mặt nội dung. Các câu văn rời rạc và không liên quan đến nhau.",
        3: "Đa phần không có sự mạch lạc về mặt nội dung. Sự thiếu liên kết và cấu trúc làm giảm tính rõ ràng và ý nghĩa của bài viết.",
        4: "Có sự mạch lạc về mặt nội dung rất hạn chế. Bài viết của bạn đa phần chưa có cấu trúc hoặc chưa có sự liên kết giữa các ý, dẫn đến sự không rõ ràng và lộn xộn.",
        5: "Có sự mạch lạc về mặt nội dung một cách hạn chế. Bài viết của bạn đa phần chưa được sắp xếp một cách rõ ràng, thiếu liên kết giữa các ý, làm giảm tính logic và rõ ràng của bài viết.",
        6: "Có sự mạch lạc về mặt nội dung ở mức trung bình. Bài viết của bạn có thể tổ chức một cách tương đối logic, nhưng thường thiếu sự kết nối rõ ràng giữa các ý. Sự chuyển đổi vẫn chưa được mượt mà.",
        7: "Có sự mạch lạc về mặt nội dung tương đối tốt. Bài viết của bạn có sự liên kết và cấu trúc, mặc dù có đôi phần thiếu tính nhất quán hoặc không rõ ràng. Sự chuyển đổi giữa các ý vẫn diễn ra một cách hợp lý.",
        8: "Có sự mạch lạc về mặt nội dung khá tốt. Bạn tổ chức bài viết một cách logic và có cấu trúc, mặc dù không chi tiết và liên kết sâu sắc như ở band điểm 9. Sự kết nối giữa các ý vẫn rõ ràng và mượt mà.",
        9: "Có sự mạch lạc về mặt nội dung xuất sắc. Bài viết của bạn được tổ chức một cách logic, với mỗi đoạn văn chứa ý chính rõ ràng và được kết nối chặt chẽ với các ý khác trong bài. Sự chuyển đổi giữa các ý và đoạn văn diễn ra một cách mượt mà và tự nhiên.",
    }
};
export const FORMALLY_COHERENT = {
    vName: 'Mạch lạc về mặt hình thức',
    pointLadder: {
        1: "Học viên không viết được một chút nào",
        2: "Chưa sử dụng được các từ nối và cấu trúc ngữ pháp cần thiết. Bài viết bị rời rạc do không có sự liên kết thông qua các cấu trúc ngữ pháp phù hợp.",
        3: "Đa phần chưa sử dụng các từ nối và cấu trúc ngữ pháp cần thiết. Sự thiếu liên kết và cấu trúc không rõ ràng gây khó khăn trong việc hiểu được ý nghĩa của bài viết.",
        4: "Sử dụng các từ nối và cấu trúc ngữ pháp rất hạn chế. Bài viết đa phần chưa có cấu trúc hoặc chưa có sự liên kết giữa các ý, dẫn đến sự không rõ ràng và lộn xộn.",
        5: "Sử dụng các từ nối và cấu trúc ngữ pháp khá hạn chế. Bài viết đôi khi chưa được tổ chức một cách rõ ràng và thiếu liên kết giữa các ý, làm giảm tính logic và rõ ràng của bài viết.",
        6: "Sử dụng các từ nối và cấu trúc ngữ pháp ở mức trung bình. Bài viết có tổ chức một cách tương đối logic, nhưng nhiều nơi vẫn thiếu sự kết nối rõ ràng giữa các ý. Sự chuyển đổi vẫn chưa được mượt mà.",
        7: "Sử dụng các từ nối và cấu trúc ngữ pháp một cách tương đối chính xác và linh hoạt. Bài viết có sự liên kết và cấu trúc khá hợp lý, mặc dù có đôi phần thiếu tính nhất quán hoặc không rõ ràng ở một vài chỗ. Sự chuyển đổi giữa các ý vẫn diễn ra một cách hợp lý, tạo nên một bài viết có sự liên kết tốt.",
        8: "Sử dụng các từ nối và cấu trúc ngữ pháp khá chính xác và linh hoạt để liên kết các ý kiến, câu và đoạn văn. Mặc dù không chi tiết và liên kết sâu sắc như ở band điểm 9, nhưng sự chuyển đổi giữa các ý vẫn rõ ràng và mạch lạc.",
        9: "Sử dụng các từ nối và cấu trúc ngữ pháp một cách rất chính xác và linh hoạt để nối các ý kiến, câu và đoạn văn một cách mượt mà và logic. Cách sắp xếp ý kiến giữa các câu và đoạn văn rất hợp lý và có sự liên kết chặt chẽ, tạo nên sự kết nối mạch lạc và logic",
    }
};
// LEXICAL RESOURCE
export const VOCABULARY_DIVERSITY = {
    vName: 'Độ đa dạng của từ vựng',
    pointLadder: {
        1: "Học viên không viết được một chút nào",
        2: "Chưa có khả năng sử dụng được đa dạng từ vựng, chỉ có thể sử dụng những từ vựng cơ bản nhất một cách lặp đi lặp lại. Bài viết chỉ thể hiện được vốn từ vựng cực kỳ hạn chế.",
        3: "Chưa có khả năng sử dụng được đa dạng từ vựng và hầu như chỉ lặp lại các từ cơ bản. Bài viết chưa có sự đa dạng trong từ ngữ và phát triển ý tưởng.",
        4: "Sử dụng từ vựng chưa được đa dạng. Bài viết thường không có sự đa dạng trong từ ngữ, bài viết chưa đủ tính hấp dẫn.",
        5: "Sử dụng từ vựng ở mức cơ bản, kém đa dạng. Bài viết thiếu sự linh hoạt trong việc sử dụng cụm từ hoặc từ vựng chuyên ngành, làm giảm tính phong phú và đa dạng của ý tưởng.",
        6: "Khả năng sử dụng từ vựng đa dạng ở mức trung bình. Bài viết đôi khi lặp lại từ ngữ hoặc thiếu sự linh hoạt trong việc sử dụng cụm từ, từ vựng chuyên ngành, làm giảm tính phong phú và sâu sắc của ý tưởng.",
        7: "Khả năng sử dụng từ vựng tương đối đa dạng. Bạn sử dụng một số từ vựng phong phú từ các lĩnh vực khác nhau, tuy nhiên, đôi khi thiếu sự linh hoạt trong việc sử dụng cụm từ hoặc từ vựng chuyên ngành.",
        8: "Khả năng sử dụng từ vựng tương đối đa dạng. Bạn sử dụng một số từ vựng phong phú từ các lĩnh vực khác nhau, tuy nhiên, đôi khi thiếu sự linh hoạt trong việc sử dụng cụm từ hoặc từ vựng chuyên ngành.",
        9: "Khả năng sử dụng một loạt các từ vựng rộng và phong phú. Bạn biết cách sử dụng từ ngữ chính xác, linh hoạt và đa dạng từ các lĩnh vực khác nhau, bao gồm từ vựng thông thường và chuyên ngành. Sự đa dạng trong từ vựng tạo nên sự sâu sắc và mạch lạc trong ý kiến và ý tưởng.",
    }
};
export const VOCABULARY_PRECISION = {
    vName: 'Độ chính xác của từ vựng',
    pointLadder: {
        1: "Học viên không viết được một chút nào",
        2: "Có rất ít từ vựng được sử dụng chính xác mục đích và vị trí của chúng. Sự thiếu chính xác xảy ra với tần suất dày đặc.",
        3: "Đa phần sử dụng từ vựng chưa chính xác. Sự thiếu chính xác này làm giảm tính rõ ràng và ý nghĩa của bài viết.",
        4: "Sử dụng từ vựng chưa chính xác với ngữ cảnh. Bài viết thường có nhiều lỗi về từ vựng, khiến ý nghĩa trở nên mơ hồ và không chính xác.",
        5: "Khả năng sử dụng từ vựng chưa chính xác với ngữ cảnh. Bài viết thường có nhiều lỗi về từ ngữ, chưa thể hiện được tính chính xác của ý tưởng.",
        6: "Khả năng sử dụng từ vựng ở mức trung bình. Bài viết có một số lỗi trong việc sử dụng từ ngữ hoặc không chính xác với ngữ cảnh, chưa thể hiện được tính chính xác và sâu sắc của ý tưởng.",
        7: "Khả năng sử dụng từ vựng tương đối chính xác. Bạn có khả năng sử dụng từ ngữ phù hợp, tuy nhiên, có thể xuất hiện một số lỗi nhỏ hoặc sự lặp lại từ ngữ không cần thiết trong bài viết.",
        8: "Khả năng sử dụng từ vựng khá chính xác. Bạn có khả năng sử dụng từ ngữ phù hợp và chính xác với ngữ cảnh, mặc dù có thể có một vài lỗi nhỏ hoặc sự lặp lại từ ngữ trong bài viết.",
        9: "Khả năng sử dụng các từ vựng một cách chính xác và linh hoạt. Bạn biết cách áp dụng từ ngữ đúng ngữ cảnh và không mắc lỗi về ý nghĩa hoặc cấu trúc câu. Việc sử dụng từ vựng chính xác góp phần tạo nên sự chính xác và sâu sắc trong ý kiến và ý tưởng.",
    }
};
// GRAMMATICAL RANGE AND ACCURACY
export const GRAMMAR_DIVERSITY_WRITING = {
    vName: 'Độ đa dạng của ngữ pháp',
    pointLadder: {
        1: "Học viên không viết được một chút nào",
        2: "Khả năng sử dụng ngữ pháp cực kỳ hạn chế. Chỉ có thể viết được những dạng câu đơn giản nhất để biểu lộ ý tưởng, tuy nhiên cũng không quá rõ ràng.",
        3: "Khả năng sử dụng ngữ pháp rất hạn chế. Sự thiếu đa dạng gây mất tính rõ ràng và ý nghĩa của bài viết.",
        4: "Khả năng sử dụng ngữ pháp không đa dạng. Bài viết thường không có sự đa dạng trong cấu trúc ngữ pháp, giảm tính hấp dẫn của ý tưởng.",
        5: "Khả năng sử dụng ngữ pháp kém đa dạng. Bài viết thường có nhiều lỗi về ngữ pháp, gây ra sự mất đi tính chính xác của ý tưởng.",
        6: "Khả năng sử dụng ngữ pháp ở mức trung bình. Bài viết có thể có một số lỗi trong việc sử dụng ngữ pháp hoặc không chính xác với ngữ cảnh, chưa thể hiện tính chính xác và sâu sắc của ý tưởng.",
        7: "Khả năng sử dụng ngữ pháp tương đối đa dạng. Bạn sử dụng một loạt các cấu trúc ngữ pháp từ cơ bản đến nâng cao, tuy nhiên, có thể xuất hiện một số lỗi nhỏ hoặc sự lặp lại cấu trúc không cần thiết trong bài viết.",
        8: "Khả năng sử dụng ngữ pháp khá đa dạng. Bạn sử dụng một loạt các cấu trúc ngữ pháp phong phú và linh hoạt, mặc dù có thể có một vài lỗi nhỏ hoặc sự lặp lại cấu trúc trong bài viết.",
        9: "Khả năng sử dụng một loạt các cấu trúc ngữ pháp linh hoạt và đa dạng. Bạn biết cách sử dụng các cấu trúc ngữ pháp phức tạp một cách linh hoạt và tự nhiên, không mắc lỗi nghiêm trọng. Sự linh hoạt trong ngữ pháp góp phần tạo ra sự đa dạng và sâu sắc trong ý kiến và ý tưởng.",
    }
};
export const GRAMMATICAL_ACCURACY = {
    vName: 'Độ chính xác của ngữ pháp',
    pointLadder: {
        1: "Học viên không viết được một chút nào",
        2: "Hầu hết tất cả các câu trong bài đều chứa lỗi ngữ pháp. Sự thiếu chính xác này có ảnh hưởng nghiêm trọng đến tính rõ ràng và ý nghĩa của bài viết.",
        3: "Đa phần sử dụng ngữ pháp chưa chính xác. Sự thiếu chính xác này dẫn đến sự thiếu tính rõ ràng và ý nghĩa của bài viết.",
        4: "Sử dụng ngữ pháp không chính xác. Bài viết chưa có sự chính xác trong cấu trúc ngữ pháp, gây ra sự nhàm chán và giảm tính hấp dẫn của ý tưởng.",
        5: "Khả năng sử dụng ngữ pháp không thực sự chính xác. Bài viết thường có nhiều lỗi về ngữ pháp, làm giảm tính chính xác của ý tưởng.",
        6: "Khả năng sử dụng ngữ pháp ở mức trung bình. Bài viết có một số lỗi trong việc sử dụng ngữ pháp hoặc chưa chính xác với ngữ cảnh, làm giảm tính chính xác và sâu sắc của ý tưởng.",
        7: "Khả năng sử dụng ngữ pháp tương đối chính xác. Bạn sử dụng một loạt các cấu trúc ngữ pháp từ cơ bản đến nâng cao, tuy nhiên, đôi khi xuất hiện một số lỗi nhỏ hoặc sự lặp lại cấu trúc không cần thiết trong bài viết.",
        8: "Khả năng sử dụng ngữ pháp khá chính xác. Bạn sử dụng các cấu trúc ngữ pháp một cách chính xác, mặc dù có thể có một vài lỗi nhỏ hoặc sự lặp lại cấu trúc trong bài viết.",
        9: "Khả năng sử dụng ngữ pháp một cách chính xác và linh hoạt. Bạn hiểu rõ các cấu trúc ngữ pháp và sử dụng chúng một cách tự nhiên, không mắc lỗi ngữ pháp nghiêm trọng. Sự linh hoạt và chính xác trong ngữ pháp góp phần tạo ra sự rõ ràng và sâu sắc trong ý kiến và ý tưởng.",
    }
};
export const CRITERIAES_GET_COMMENT_AUTO = new Map([
    [FLUENCY_AND_COHERENCE, [EXPAND_AND_DEVELOPIDEAS, PAUSE_AND_HESITATE, SPEAKING_SPEED, USEPHRASES_TO_OPEN_LEAD_AND_CONNECT, COHERENCE]],
    [LEXICAL_RESOURCE, [WORD_DIVERSITY, ACCURACY_IN_USE_VOCABULARY, PARAPHRASING]],
    [GRAMMATICAL_RANGE_ACCURACY, [GRAMMAR_DIVERSITY, ACCURACY_IN_USING_GRAMMAR]],
    [PRONUNCIATION, [PRONUNCIATION_ABILITY, STRESS, INTONATION, RHYTHM_SAID]],
    [TASK_ACHIEVEMENT_WRITING, [CONTENT_COMMUNICATION_CIRCUIT, ARGUMENT_AND_LOGICAL_CALCULATION_OF_THE_ARTICLE]],
    [LEXICAL_RESOURCE_WRITING, [VOCABULARY_PRECISION, VOCABULARY_DIVERSITY]],
    [GRAMMATICAL_RANGE_ACCURACY_WRITING, [GRAMMAR_DIVERSITY_WRITING, GRAMMATICAL_ACCURACY]],
    [COHERENCE_COHESION_WRITING, [FORMALLY_COHERENT, COHERENCE_IN_CONTENT]],
])

export const NOT_HAVE_ADMIN = 1;
export const TYPE_TEST_INPUT_TASK = [
    {
        id: 0,
        name: "Test Speaking"
    },
    {
        id: 1,
        name: "Chấm General"
    },
];
export const PLAGIARISM_RATE = [
    {
        value: 1,
        label: 'Dưới 20%',
        problemSolution: 'Không trừ điểm',
        minusPoints: 0
    },
    {
        value: 2,
        label: 'Từ 20% tới 30%',
        problemSolution: 'Trừ 30% điểm',
        minusPoints: 0.3
    },
    {
        value: 3,
        label: 'Từ 30% tới 50%',
        problemSolution: 'Trừ 50% điểm',
        minusPoints: 0.5
    },
    {
        value: 4,
        label: 'Từ 50% trở lên',
        problemSolution: 'Trừ 100% điểm',
        minusPoints: 1
    },
];
export const PROBLEM_SOLUTIONS = [
    {
        value: 1,
        label: 'Làm lại Writing'
    },
    {
        value: 2,
        label: 'Làm lại toàn bộ bài'
    },
    {
        value: 3,
        label: 'Đổi mã đề'
    },
]
const sang_1 = 5;
const Sang = 1;
const Chieu_1 = 6;
const Chieu = 2;
const Toi = 3;
const Toi_2 = 4
export const LOCATION_TESTER_CALENDAR = new Map([
    [sang_1, 1],
    [Sang, 2],
    [Chieu_1, 3],
    [Chieu, 4],
    [Toi, 5],
    [Toi_2, 6],
    ['morning_1', 1],
    ['morning', 2],
    ['afternoon', 4],
    ['afternoon_1', 3],
    ['evening', 5],
    ['evening_overtime', 6]


])
export const CHANGE_CODE = 3;
export const TYPE_HISTORY_LOG = new Map ([
    [1,'Hình thức trực test'],
    [2,'Cơ sở trực test'],
    [3,'Ẩn']
])

