var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-block text-center"},[_c('form',{staticClass:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"name","placeholder":"Tên vouvher","disabled":""},domProps:{"value":_vm.voucher.name}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"email","placeholder":"Giá trị","disabled":""},domProps:{"value":_vm.voucher.unit === 1 ? _vm.voucher.value + ' ₫' : _vm.voucher.value+ ' %'}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(2),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"start_date","placeholder":"Ngày bắt đầu","disabled":""},domProps:{"value":_vm.voucher.start_date}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(3),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"end_date","placeholder":"Ngày kết thúc","disabled":""},domProps:{"value":_vm.voucher.end_date}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(4),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"branch","placeholder":"Chi nhánh áp dụng","disabled":""},domProps:{"value":_vm.voucher.branch && _vm.voucher.branch.name}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_vm._m(5),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"branch","placeholder":"Mô tả","disabled":""},domProps:{"value":_vm.voucher.description}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Tên : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Giá trị : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Ngày bắt đầu : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Ngày kết thúc : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Chi nhánh : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Mô tả : "),_c('span',{staticClass:"text-danger"})])
}]

export { render, staticRenderFns }