<template>
  <div class="card " style="height: fit-content;width: 100%;">
    <div class="card w-100 h-100" style="background-color: #edeaf2">
      <div class="d-flex w-100 h-100 py-2 " >
        <div style="width: 20% ; height: 100%" class="d-flex align-items-center ">
          <p class="font-weight-boldest ml-5" style="margin-bottom: 0; font-size: 13px">{{label}}</p>
        </div>
        <div style="width: 30%;" class="d-flex  h-100">
          <ValidationProvider
              name="Thang điểm"
              rules="required"
              v-slot="{ errors, classes }"
              :vid="label"
              class="w-100 px-4"
          >
            <el-select
                v-model="markValue.valueOption"
                :placeholder="placeholder"
            >
              <el-option
                  v-for="item in optionScoreSpeaking"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <div class="fv-plugins-message-container">
              <div
                  data-field="name"
                  data-validator="notEmpty"
                  class="fv-help-block"
              >
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="d-flex  " style="width: 50%;">
          <ValidationProvider
              name="Nhận xét"
              :rules="{required :true,min: 50}"
              v-slot="{ errors, classes }"
              :vid="`nhanXet${label}`"
              class="w-100 px-4"
          >
            <el-input  placeholder="Nhập nhận xét" v-model="markValue.comment" style="width: 100%"></el-input>
            <div class="fv-plugins-message-container">
              <div
                  data-field="name"
                  data-validator="notEmpty"
                  class="fv-help-block"
              >
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {optionScoreSpeaking, optionScoreSpeaking9} from "@/core/option/selectScore";

export default {
  name: "SelectOption",
  data() {
    return {

    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    markValue: {
      type: Object,
      default: {
        valueOption: 0,
        comment: ''
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    typeMark:{
      type: Number,
      default: '',
    }
  },
  computed:{
    optionScoreSpeaking(){
      return this.typeMark === 10 ?optionScoreSpeaking : optionScoreSpeaking9
    }
  }
}
</script>

<style lang="scss" scoped>

</style>