<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        Quản lý nhân sự Admin
      </div>
      <div class="card-title">
        <a href="#" class="font-weight-bold font-size-3 btn btn-success"
           @click="openModalCreate">Thêm mới</a>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="form-group col-md-3">
            <label for="txt_name">Nhân sự admin</label>
            <el-input id="txt_name"
                      v-model="query.name"
                      placeholder="Nhập mã, tên, SĐT"
                      clearable>
            </el-input>
          </div>
          <div class="form-group col-md-3">
            <label for="txt_type_test">Hình thức trực test</label>
            <el-select
                id="txt_type_test"
                v-model="query.type"
                clearable
                placeholder="Hình thức trực test"
                filterable
            >
              <el-option
                  v-for="item in TYPES_NOT_ALL"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="form-group col-md-3">
            <label for="txt_status">Trạng thái</label>
            <el-select
                id="txt_status"
                v-model="query.status"
                clearable
                placeholder="Trạng thái"
                filterable
            >
              <el-option
                  v-for="item in status"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="form-group col-md-3 mt-8">
            <div class="form-group">
              <button class="btn btn-primary"
                      :class="{'spinner spinner-white spinner-left' : is_loading_search}"
                      @click="handleFormSearch"
              >Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center table-hover">
            <thead>
            <tr>
              <th>STT</th>
              <th>Mã nhân sự</th>
              <th>Họ tên</th>
              <th>Hình thức trực test</th>
              <th>Cơ sở trực offline</th>
              <th>Trạng thái</th>
              <th>Link test online</th>
              <th>Thời gian tạo</th>
              <th>Hành động</th>
            </tr>
            </thead>
            <tbody v-if="testers.length > 0">
            <tr v-for="(item, key) in testers" :key="key">
              <td>{{ ++key }}</td>
              <td>{{
                  item.user_id ? item.user_id + DIFFERENCE_NUMBER_USER : ''
                }}
              </td>
              <td>{{ item?.name }}</td>
              <td>
                <span v-if="[ALL, ONLINE].includes(item.type)" class="badge badge-primary">Online</span>
                <span v-if="[ALL, OFFLINE].includes(item.type)" class="ml-1 badge badge-warning">Offline</span>
              </td>
              <td>
                {{ getBranchNameFirst(item) }}
                <span
                    v-if="getCountBranch(item) > 0"
                    class="badge badge-warning cursor-pointer"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="getBranchNameLast(item)"
                >
                  +{{ getCountBranch(item) }}
                </span>
              </td>
              <td>
                <i :class="{'text-success':item.status > 0 }" class="bi bi-circle-fill mr-2 "
                   style="font-size: 8px"></i>
                <span>{{ handleDisplayStatus(item.status) }}</span>
              </td>
              <td>{{ item.link_test_online }}</td>
              <td>{{ item.created_at | formatDateTime }}</td>
              <td class="d-flex">
                <button class="btn btn-xs btn-icon mr-2 btn-outline-info"
                        @click="openModalWeekly(item)"
                        title="Tác vụ tuần"
                        v-b-modal="`tester-weekly-task`">
                  <i class="fa fa-calendar"></i>
                </button>
                <button class="btn btn-xs btn-icon mr-2 btn-outline-info"
                        @click="openModalCalendar(item)"
                        title="Lịch đi làm"
                        v-b-modal="`tester-calendar-modal`">
                  <i class="far fa-calendar-alt"></i>
                </button>
                <a href="javascript:" title="Sửa" class="btn btn-xs btn-icon mr-2 btn-outline-info"
                   @click="openModalEdit(item.id)">
                  <i class="fas fa-pen-nib"></i>
                </a>
                <a title="Xóa" @click="deleteTester(item)" href="javascript:"
                   class="btn btn-xs btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <tester-modal :showModal="showModalCreate"
                  @clicked="closeTesterModal"
                  @createSuccess="createTesterSuccess"
                  :tester-obj="tester"
                  :isModalEdit="isModalEdit"
    ></tester-modal>
    <tester-calendar-modal :tester="tester_calendar" :isOpenTesterCalendar="isOpenTesterCalendar"
                           @closeTesterCalendar="isOpenTesterCalendar=false"></tester-calendar-modal>
    <TesterWeeklyTask :tester="tester_calendar" :showModalWeekly="showModalWeekly"
                      @closeModalWeekly="showModalWeekly=false"></TesterWeeklyTask>
    <div class="edu-paginate d-flex justify-content-between px-12">
      <div></div>
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
      <div class="mt-4">
        <h3 style="font-size: 13px ">Tổng số bản ghi: <span class="text-primary">{{ total }}</span></h3>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_TESTERS, DELETE_TESTER, SHOW_TESTER} from "@/core/services/store/test/test.module";
import Swal from "sweetalert2";
import testerModal from "@/view/pages/test/tester/TesterModal";
import '@/assets/sass/custom/custom_event.scss';
import TesterCalendarModal from "@/view/pages/test/tester/TesterCalendarModal";
import {
  ALL,
  OFFLINE,
  ONLINE,
  STATUS_DEFINE,
  STATUS_DEFINE_CSS,
  STATUS_HIDDEN, TYPES_NOT_ALL
} from "../../../../core/option/testInputOption";
import {DIFFERENCE_NUMBER_USER} from "@/core/option/userOption";
import TesterWeeklyTask from "@/view/pages/test/tester/TesterWeeklyTask";
import {STATUS_ACTIVE, STATUS_CSS} from "@/core/option/commonOption";

export default {
  components: {TesterWeeklyTask, TesterCalendarModal, testerModal},
  name: "TesterIndex",
  data() {
    return {
      is_loading_search: false,
      query: {
        name: '',
        status: '',
        type: '',
      },
      testers: [],
      page: 1,
      last_page: 1,
      tester: {},
      tester_calendar: {},
      /*modal variable*/
      showModalCreate: false,
      showModalEdit: false,
      showModalWeekly: false,
      STATUS_CSS: STATUS_CSS,
      ONLINE: ONLINE,
      OFFLINE: OFFLINE,
      ALL: ALL,
      STATUS_DEFINE: STATUS_DEFINE,
      STATUS_DEFINE_CSS: STATUS_DEFINE_CSS,
      DIFFERENCE_NUMBER_USER: DIFFERENCE_NUMBER_USER,
      total: 0,
      isModalEdit: false,
      TYPES_NOT_ALL: TYPES_NOT_ALL,
      status: STATUS_ACTIVE,
      isOpenTesterCalendar: false,
    }
  },
  created() {
    this.setParams();
    this.getListTester();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý Admin", route: 'tester-list'},
      {title: "Quản lý nhân sự Admin"}
    ]);
  },
  computed: {
    ...mapGetters(['getTesters']),

  },

  methods: {
    getBranchNameFirst(item) {
      let branches = item.branches;
      if (branches.length) {
        return branches[0].name;
      }
    },
    getBranchNameLast(item) {
      let result = '';
      let branches = item.branches;
      for (let i = 0; i < branches.length; i++) {
        if (i === 0) {
          continue;
        }
        result = result + branches[i].name;
        if (i + 1 < branches.length) {
          result = result + ',';
        }
      }
      return result;
    },
    getBranchName(item) {
      let branches = item.branches;
      if (branches.length) {
        return branches[0].name;
      }
    },
    getCountBranch(item) {
      let branches = item.branches;
      return branches.length - 1;
    },
    setParams() {
      let name = this.$route.query.name;
      let phone = this.$route.query.phone;
      let status = this.$route.query.status;
      this.query.status = status;
      this.query.name = name;
      this.query.phone = phone;
    },

    pushParamsUrl() {
      let query = {...this.query}
      this.$router.push({
        name: 'tester-list',
        query: query
      })
    },

    handleFormSearch() {
      this.page = 1;
      this.pushParamsUrl();
      this.getListTester();
    },

    deleteTester(item) {
      console.log(item)
      Swal.fire({
        title: "Bạn có chắc chắn muốn xóa nhân sự admin " + item.name + " không?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_TESTER, item.id).then((res) => {
            this.$message.success(res.message);
            this.getListTester();
          }).catch((res) => {
            this.$message.error(res.data.message);
          });
        }
      });
    },

    getListTester() {
      this.is_loading_search = true;
      let payload = {...this.query, page: this.page};
      this.$store.dispatch(GET_TESTERS, payload).then((res) => {
        this.testers = res.data;
        this.last_page = res.pagination.last_page;
        this.total = res.pagination.total
      }).finally(() => {
        this.is_loading_search = false;
      });
    },

    clickCallback(obj) {
      this.page = obj;
      this.getListTester();
    },

    openModalCreate() {
      this.isModalEdit = false
      this.showModalCreate = true;
    },


    openModalEdit(id) {
      this.isModalEdit = true
      this.showModalCreate = true;
      this.getTesterById(id);
    },
    getTesterById(id) {
      this.titleModal = 'Sửa nhân sự admin';

      this.$store.dispatch(SHOW_TESTER, id).then((res) => {
        this.tester = res.data;
        // if (this.tester.user) {
        //   this.users.push(this.tester.user);
        // }
        if (this.tester.user_id === 0) {
          this.tester.user_id = ''
        }
      })
    },
    openModalCalendar(tester) {
      this.isOpenTesterCalendar = true
      this.tester_calendar = tester;

    },
    openModalWeekly(tester) {
      this.showModalWeekly = true
      this.tester_calendar = tester
    },

    closeTesterModal() {
      this.showModalCreate = false;
    },


    closeEditModal() {
      this.showModalEdit = false;
    },

    createTesterSuccess() {
      this.getListTester();
    },

    updateIncomeSuccess() {
      this.getListTester();
    },
    handleDisplayStatus(status) {
      return status === STATUS_HIDDEN ? "Ẩn" : "Hiển thị";
    }
  }
}
</script>

<style>
.el-select {
  width: 100%;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}
</style>
