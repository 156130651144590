<template>
  <div>
    <div class="image-input image-input-outline" id="kt_profile_avatar">
      <div
          class="image-input-wrapper"
          :style="{ backgroundImage: `url(${photo})` }"
      ></div>
      <label
          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
          data-action="change"
          data-toggle="tooltip"
          title=""
          data-original-title="Change avatar"
      >
        <i class="fa fa-pen icon-sm text-muted"></i>
        <input
            type="file"
            name="profile_avatar"
            accept=".png, .jpg, .jpeg"
            @change="onFileChange($event)"
        />
        <input type="hidden" name="profile_avatar_remove"/>
      </label>
      <span
          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
          data-action="cancel"
          data-toggle="tooltip"
          title="Cancel avatar"
      >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
      <span
          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
          data-action="remove"
          data-toggle="tooltip"
          title="Remove avatar"
          @click="removePhoto"
      >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
    </div>
    <span class="form-text text-muted">{{subText}}</span>
  </div>
</template>

<script>
export default {
  name: "InputAvatar",
  props: {
    photo: {
      type: String,
      default: () => { return '' },
    },
    current_photo: {
      type: String,
      default: () => { return '' },
    },
    subText: {
      type: String,
      default: () => { return 'Allowed file types: png, jpg, jpeg.' },
    }
  },
  data(){ return {} },
  methods: {
    onFileChange(e){
      this.$emit('onFileChange', e)
    },
    removePhoto(){
      this.$emit('removeAvatar')
    }
  }
}
</script>

<style scoped>

</style>