<template>
    <div>
      <div class="page-header" style="display: flex; justify-content: space-between; padding: 18px 0; align-items: center;">
        <h3 class="page-title" style="font-size: 16px; font-weight: 400; padding-bottom: 7px;">Danh sách học viên đăng ký</h3>
      </div>
      <div class="table-responsive" style="overflow: auto; height: 58vh">
        <table class="table table-vertical-center table-bordered">
          <thead style="position: sticky; top: 0">
          <tr style="border: 1px solid #EBEDF3">
            <th scope="col"  style="background: #F8F8F8; text-align: start">Họ tên học viên</th>
            <th scope="col"  style="background: #F8F8F8; text-align: start">Email</th>
            <th scope="col"  style="background: #F8F8F8;">Số điện thoại</th>
            <th scope="col"  style="background: #F8F8F8;">Thời gian đăng ký</th>
          </tr>
          </thead>
          <tbody v-if="loading">
          <tr class="col-12">
            <td class="text-center"><i class="el-icon-loading" style="position: absolute; left: 50%; margin: 15px 0"></i>
            </td>
          </tr>
          </tbody>
          <tbody v-else-if="listStudent?.length > 0">
          <tr v-for="(item, index) of listStudent" :key="item?.id" style="border: 1px solid #EBEDF3; border-top: 0">
            <td>{{ item?.name }}</td>
            <td>{{ item?.email }}</td>
            <td>{{ item?.phone }}</td>
            <td>{{ item?.created_at | formatDate}}</td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr style="word-break: break-word; white-space: nowrap; position: relative">
            <label style="position: absolute; left: 50%; translate: -50%; top: 10px">Thời gian là bắt buộc</label>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-8" style="display: flex; justify-content: end;" v-if="listStudent?.length > 0">
        <span>Tổng số bản ghi: <span style="color: #0066FF">{{listStudent?.length}}</span></span>
      </div>
    </div>
  </template>
  
  <script>
    import { GET_LIST_STUDENT_REGISTER_PROMOTION } from "../../../../core/services/store/rewards-programs/rewardsPrograms.module"; 
  
  
  export default {
    components: {},
    mounted() {
      this.program_id = this.$route.query.promotion_id;
      this.getListStudent();
    },

    data() {
      return {
        loading: false,
        loading_export: false,
        listStudent: [],
        addHonor: false,
        isEdit: false,
        isDelete: false,
      }
    },
    props: {
      endTime: {
        type: Date,
        required: true,
        default: '',
        loading_export: true,
        program_id: ''
      },

      startTime: {
        type: Date,
        required: true,
        default: null,
      }
    },
    methods: {
       getListStudent() {
        this.$store.dispatch(GET_LIST_STUDENT_REGISTER_PROMOTION,{program_id: this.program_id}).then((res) => {
          if(!res.data.error){
              this.listStudent = res.data;
          }
        })
      },

      exportExcel() {
        this.loading_export = true;
        this.$store.dispatch(GET_LIST_STUDENT_REGISTER_PROMOTION, {
          program_id: this.program_id,
          is_export: 1
        }).then((res) => {
          exportExcel(GET_LIST_STUDENT_REGISTER_PROMOTION, res.data, "danh_sach_ung_vien_giang_vien" + new Date().getTime() / 1000 + ".xlsx",false)
          this.loading_export = false;

        }).catch(() => {
          this.loading_export = false;

        });
      }
    }
  }
  </script>
  
  <style lang="css">
  
  .btn-action {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 5px;
    border: 1px solid;
  }
  </style>