import ApiService from "@/core/services/api.service";
import writeXlsxFile from 'write-excel-file';
import moment from 'moment';

//actions
export const EXPORT_LIST_CANDIDATE = "export-candidate";

export const EXPORT_EXCEL_REQUEST_APPLICATION = "propose-management/export-excel";
export const EXPORT_APPLITICATION_CATEGORY = "propose-management/get-all-data-export";
export const EXPORT_LIST_TVV_TRAINING = "classroom/export-tvv-training";

//mutations
const state = {
    listExport: [],
    dataHead: [],
    listExportPaginatedData: [],
}

const actions = {
    [EXPORT_LIST_CANDIDATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_LIST_CANDIDATE, payload).then(({ data }) => {
                 var head = data.data[0];
                 var dataExport = data.data[1];
                 state.listExport = data.data;
                 var dataHead = [];
                Object.values(head).forEach(function (item) {
                    var value = { value: item};
                    dataHead.push(value);
                });
                var dataCandidate = [dataHead, ];
                Object.values(dataExport).forEach(function (value) {
                    if(value.status !== null) {
                        switch (value.status) {
                            case 0:
                                var status_course = 'Chưa đào tạo';
                                break;
                            case 1:
                                var status_course = 'Đang đào tạo';
                                break;
                            case 2:
                                var status_course = 'Bị loại';
                                break;
                            case 3:
                                var status_course = 'Hoàn thành đào tạo';
                                break;
                            default:
                                break;
                        };
                    }else {
                        var status_course = '';
                    }

                    if(value.course && value.course.courses[0]) {
                        switch (value.course.courses[0].status) {
                            case 0:
                                var status_lesson1 = 'Chưa học';
                                break;
                            case 1:
                                var status_lesson1 = 'Đang học';
                                break;
                            case 2:
                                var status_lesson1 = 'Đã học';
                                break;
                            case 3:
                                var status_lesson1 = 'Hoàn thành';
                                break;
                            default:
                                break;
                        };
                    }

                    if(value.course && value.course.courses[1]) {
                        switch (value.course.courses[1].status) {
                            case 0:
                                var status_lesson2 = 'Chưa học';
                                break;
                            case 1:
                                var status_lesson2 = 'Đang học';
                                break;
                            case 2:
                                var status_lesson2 = 'Đã học';
                                break;
                            case 3:
                                var status_lesson2 = 'Hoàn thành';
                                break;
                            default:
                                break;
                        };
                    }else{
                    }
                    if(value.course && value.course.courses[0]) {
                        switch (value.course.courses[0].mode) {
                            case 0:
                                var type_lesson1 = 'Online';
                                break;
                            case 1:
                                var type_lesson1 = 'Offline';
                                break;
                            case null:
                                var type_lesson1 = '';
                            break;
                            default:
                                break;
                        };
                    } else {
                        var type_lesson1 = '';
                    }

                    if(value.course) {
                        if(value.course.courses[0]) {
                            if(moment(value.course.courses[0].startTime).format('yyyy-MM-DD HH:mm:ss') === 'Invalid date' ) {
                                 var time_start_lesson_1 = '';
                            }else {
                             var time_start_lesson_1 = moment(value.course.courses[0].startTime).format('yyyy-MM-DD HH:mm:ss');
                            }
                             if(moment(value.course.courses[0].learnCompletedTime).format('yyyy-MM-DD HH:mm:ss') == 'Invalid date' ) {
                                 var time_done_lesson_1 = '';
                            }else {
                              var time_done_lesson_1 = moment(value.course.courses[0].learnCompletedTime).format('yyyy-MM-DD HH:mm:ss');
                            }
                             if(moment(value.course.courses[0].completedTime).format('yyyy-MM-DD HH:mm:ss') == 'Invalid date' ) {
                                 var time_pass_lesson_1 = '';
                             }else {
                                    var time_pass_lesson_1 = moment(value.course.courses[0].completedTime).format('yyyy-MM-DD HH:mm:ss');
                             }
                        }else {
                            var time_start_lesson_1 = '';
                            var time_done_lesson_1 = '';
                            var time_pass_lesson_1 = '';
                        }
                        if(value.course.courses[1]) {
                            if (moment(value.course.courses[1].learnCompletedTime).format('yyyy-MM-DD HH:mm:ss') == 'Invalid date' ) {
                                var time_done_leson_2 = '';
                            }else {
                                var time_done_leson_2 = moment(value.course.courses[1].learnCompletedTime).format('yyyy-MM-DD HH:mm:ss');
                               }
    
                            if(moment(value.course.courses[1].completedTime).format('yyyy-MM-DD HH:mm:ss') == 'Invalid date' ) {
                                var time_pass_lesson_2 = '';
                            }else {
                                var time_pass_lesson_2 = moment(value.course.courses[1].completedTime).format('yyyy-MM-DD HH:mm:ss');
                            }
                        }else {
                            var time_done_leson_2 = '';
                            var time_pass_lesson_2 = '';
                        }
                    };

                    switch (value.type) {
                        case 0:
                            value.type = 'Sinh viên';
                            break;
                        case 1:
                            value.type = 'Học sinh';
                            break;
                        case 4:
                            value.type = 'Full time';
                            break;
                        case 5:
                            value.type = 'Part time';
                            break;
                        case 6:
                            value.type = 'Cộng tác viên';
                            break;
                        default:
                            value.type = '';
                            break;
                    };

                    var data_row = [
                        { value: value.id + 100000 ?? '' }, //  'Mã ứng viên'
                        { value: value.name ?? '' },  //  'Họ và tên'
                        { value: value.phone ?? '' }, // 'Số điện thoại'
                        { value: value.email ?? '' }, //  'Email'
                        { value: value.type ?? ''}, // loại ứng viên
                        { value: status_course ?? '' }, // 'Trạng thái ứng viên'
                        { value: value.area_name ?? '' }, // 'Khu vực'
                        { value: value.branch_name ?? '' }, // 'Văn phòng'
                        { value: value.created_at ?? '' }, //  'Thời gian tạo'
                        { value: value.deadline ?? '' },   //  'Thời gian deadline'
                        { value: status_lesson1 ?? '' }, // 'Trạng thái Buổi 1'
                        { value: status_lesson2 ?? '' }, // 'Trạng thái Buổi 2'
                        { value: type_lesson1 ?? ''}, //  'Hình thức học Buổi 1'
                        { value: value.course ? time_start_lesson_1 : '' }, // 'Thời gian bắt đầu Buổi 1, (Online)
                        { value: value.course ? time_done_lesson_1 : ''}, // Thời gian học xong Buổi 1 (Online)
                        { value: value.course ? time_pass_lesson_1 : '' }, // 'Thời gian Pass Buổi 1 (Online)
                        { value: value.course ? time_done_leson_2 : '' }, //  'Thời gian học xong Buổi 2 (Online)
                        { value: value.course ? time_pass_lesson_2 : '' }, // 'Thời gian Pass Buổi 2  (Online)
                        { value: value.start_to_work ? value.start_to_work : '' }, // Thời gian nhận việc (Online)

                    ];
                            dataCandidate.push(data_row);
                    });
                let fileName = "danh_sach_ung_vien" + new Date().getTime() / 1000 + ".xlsx";
                writeXlsxFile(dataCandidate, {fileName})
                resolve(data);
            }).catch(({ response }) => {
                reject(response);
            });
        });
    },
    [EXPORT_LIST_TVV_TRAINING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_LIST_TVV_TRAINING, payload).then(({ data }) => {
                var head = data.data[0];
                var dataExport = data.data[1];
                state.listExport = data.data;
                var dataHead = [];
                Object.values(head).forEach(function (item) {
                    var value = { value: item};
                    dataHead.push(value);
                });
                var dataCandidate = [dataHead, ];
                Object.values(dataExport).forEach(function (value) {
                    if(value.status !== null) {
                        switch (value.status_course) {
                            case 0:
                                var status_course = 'Chưa học';
                                break;
                            case 1:
                                var status_course = 'Đang học';
                                break;
                            case 2:
                                var status_course = 'Đã học';
                                break;
                            case 3:
                                var status_course = 'Hoàn thành';
                                break;
                            default:
                                break;
                        };
                    }else {
                        var status_course = null;
                    }
                    var data_row = [
                        { value: value.user.id + 100000 ?? '' }, //  'Mã ứng viên'
                        { value: value.user.name ?? '' },  //  'Họ và tên'
                        { value: value.user.phone ?? '' }, // 'Số điện thoại'
                        { value: value.user.email ?? '' }, //  'Email'
                        { value: value.profiles && value.profiles.area ? value.profiles.area.name : '' }, // 'Khu vực'
                        { value: value.profiles && value.profiles.branch ? (value.profiles.branch.name_alias ? value.profiles.branch.name_alias : value.profiles.branch.name) : '' }, // 'Văn phòng'
                        { value: status_course ?? '' }, // 'Trạng thái học'
                        { value: value.name_course ?? '' }, // 'Loại ứng viên'
                        { value: value.start_time_course ?? '' }, //  'Thời gian bắt đầu khoá học'
                        { value: value.time_start_course ? moment(value.time_start_course).format('DD-MM-YYYY HH:mm:ss') : '' }, //  'Thời gian bắt đầu học'
                        { value: value.time_done_course ?? '' }, //  'Thời gian hoàn thành'
                    ];
                    dataCandidate.push(data_row);
                });
                let fileName = "danh_sach_tvv" + new Date().getTime() / 1000 + ".xlsx";
                writeXlsxFile(dataCandidate, {fileName})
                resolve(data);
            }).catch(({ response }) => {
                reject(response);
            });
        });
    },

    [EXPORT_EXCEL_REQUEST_APPLICATION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_EXCEL_REQUEST_APPLICATION, payload).then(({ data }) => {
                    var row = data.data[0].hearder; 
                    var dataHead = [];
                    Object.values(row).forEach(function (item) {
                        var value = { value: item};
                        dataHead.push(value);
                    });
                    var dataApplication = [dataHead, ];
                    let dataExport = data.data[0];
                    Object.values(dataExport).forEach(function (value) {
                        switch (value.category) {
                            case 'Nghỉ phép':
                                var data_row = [
                                    { value: value.user_id ? value.user_id + 100000 : "" }, //  'Mã nhân sự'
                                    { value: value.name ? value.name : "" }, //  Họ và tên nhân sự
                                    { value: value.reason ? value.reason : "" }, //  Lý do nghỉ phép	
                                    { value: value.status ? value.status : "" }, //  Trạng thái
                                    { value: value.general_leave ? value.general_leave : "" }, //  Tổng công nghỉ phép
                                    { value: value.start_date ? value.start_date : "" }, //  Ngày bắt đầu
                                    { value: value.end_date ? value.end_date : "" }, //  Ngày kết thúc
                                    { value: value.start_shift_off ? value.start_shift_off : "" }, //  Buổi bắt đầu nghỉ phép
                                    { value: value.id_user_handler ? value.id_user_handler + 100000 : "" }, //  Mã nhân sự duyệt
                                    { value: value.name_user_handler ? value.name_user_handler : "" }, //  Tên nhân sự duyệt
                                    { value: value.reason_for_refusal ? value.reason_for_refusal : "" }, //  Lý do từ chối
                                    { value: value.type_leave ? "Nghỉ không dùng phép" : "Nghỉ dùng phép" }, //  Lý do từ chối
                                    ];
                                break;
                            case 'Tăng ca':
                                var data_row = [
                                    { value: value.user_id ? value.user_id + 100000 : "" }, //  'Mã nhân sự'
                                    { value: value.name ? value.name : "" }, //  Họ và tên nhân sự
                                    { value: value.reason ? value.reason : "" }, //  Lý do tăng ca
                                    { value: value.status ? value.status : "" }, //  Trạng thái
                                    { value: value.start_date ? value.start_date : "" }, //  Ngày tăng ca
                                    { value: value.time_checkin ? value.time_checkin : "" }, //  Giờ bắt đầu đề xuất
                                    { value: value.time_checkout ? value.time_checkout : "" }, //  Giờ kết thúc đề xuất
                                    { value: value.thoi_gian_tang_ca ? value.thoi_gian_tang_ca : "" }, //  Giờ Tăng ca
                                    { value: value.fingerprint_attendance_start ? value.fingerprint_attendance_start : "" }, //  Giờ bắt đầu thực tế
                                    { value: value.fingerprint_attendance_end ? value.fingerprint_attendance_end : "" }, //  Giờ kết thúc thực tế
                                    { value: value.id_user_handler ? value.id_user_handler + 100000 : "" }, //  Mã nhân sự duyệt
                                    { value: value.name_user_handler ? value.name_user_handler : "" }, //  Tên nhân sự duyệt
                                    { value: value.reason_for_refusal ? value.reason_for_refusal : "" }, //  Lý do từ chối
                                    { value: value.type_arise ? "Phát sinh công việc" : "Phát sinh giờ làm" }, //  Lý do từ chối
                                    { value: value.overtime_results ? value.overtime_results : "" }, //  Lý do từ chối
                                    ];
                            break;
                            case 'Xin đổi ca làm việc':
                                var data_row = [
                                        { value: value.user_id ? value.user_id + 100000 : "" }, //  'Mã nhân sự'
                                        { value: value.name ? value.name : "" }, //  Họ và tên nhân sự
                                        { value: value.reason ? value.reason : "" }, //  Lý do nghỉ phép	
                                        { value: value.status ? value.status : "" }, //  Trạng thái
                                        { value: value.current_working_hours ? value.current_working_hours : "" }, // Ca hiện tại
                                        { value: value.current_working_change ? value.current_working_change : "" }, // Ca thay đổi
                                        { value: value.id_user_handler ? value.id_user_handler + 100000 : "" }, //  Mã nhân sự duyệt
                                        { value: value.name_user_handler ? value.name_user_handler : "" }, //  Tên nhân sự duyệt
                                        { value: value.reason_for_refusal ? value.reason_for_refusal : "" }, //  Lý do từ
                                    ]; 
                            break;
                            case 'Bổ sung giờ làm việc ngoài văn phòng':
                                var data_row = [
                                        { value: value.user_id ? value.user_id + 100000 : "" }, //  'Mã nhân sự'
                                        { value: value.name ? value.name : "" }, //  Họ và tên nhân sự
                                        { value: value.reason ? value.reason : "" }, //  Lý do nghỉ phép	
                                        { value: value.status ? value.status : "" }, //  Trạng thái
                                        { value: value.start_date ? value.start_date : "" }, //  Ngày bổ sung
                                        { value: value.time_checkin ? value.time_checkin : "" }, //  Thời gian bắt đầu
                                        { value: value.time_checkout ? value.time_checkout : "" }, //  Thời gian kết thúc
                                        { value: value.id_user_handler ? value.id_user_handler + 100000 : "" }, //  Mã nhân sự duyệt
                                        { value: value.name_user_handler ? value.name_user_handler : "" }, //  Tên nhân sự duyệt
                                        { value: value.reason_for_refusal ? value.reason_for_refusal : "" }, //  Lý do từ
                                    ]; 
                            break;
                           
                            default:
                                return ;
                        }
                                dataApplication.push(data_row);
                    });
                    let fileName = "danh_sach_nhan_su_yeu_cau" + new Date().getTime() / 1000 + ".xlsx";
                    writeXlsxFile(dataApplication, {fileName})
                        
            }).catch(({ response }) => {
                reject(response);
            });
        });
    },

    [EXPORT_APPLITICATION_CATEGORY](context, payload) {
        return new Promise(resolve => {
            ApiService.query(EXPORT_APPLITICATION_CATEGORY, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    }
}

export default {
    state, actions
}
