<template>
  <div id="weeklyMeeting" class="table-responsive mt-5 table-week">
    <table class="table" style="text-align: center;">
      <thead>
      <tr style="border: 1px solid #EBEDF3">
        <th scope="col" class="min-w-200px headerTable">Tiêu chí</th>
        <th scope="col" class="min-w-80px headerTable" v-for="(item, index) in resultWeeklyMeeting[0]?.week">KQ tuần {{index + 1}}</th>
      </tr>
      </thead>
      <tbody v-if="resultWeeklyMeeting?.length > 0">
        <tr v-for="item in resultWeeklyMeeting" :key="item?.criteria_id" style="border: 1px solid #EBEDF3; border-top: 0">
          <td>
            <span class="line-clamp-1" style="text-align: start" :title="item?.criteria_name">{{item?.criteria_name}}</span>
          </td>
          <td style="text-align: start; text-transform: uppercase" v-for="(result, index) in item.week" :key="Object.keys(result) + 'week' + (index + 1)">
            <el-select
                v-model="item.week[index][Object.keys(result)[0]]" :disabled="!isEdit" :class="setColorValue(item.week[index][Object.keys(result)[0]])"
                class="custom-input" v-if="Object.keys(result).length > 0 && Object.keys(result)[0].startsWith('week')"
                style="width: 100%;" size="small" clearable placeholder="Chọn" @change="handleMarkResultKpi">
              <el-option
                  v-for="item in optionResult"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                  :class="setColor(item.value)"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {resultPointWeekly} from "@/common/define";

export default {
  name: 'TableGradingWeeklyMeeting',
  data() {
    return {
      optionResult: resultPointWeekly
    }
  },
  props: {
    resultWeeklyMeeting: {
      type: Array,
      default: [],
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  methods: {
    setColor(value) {
      if (value === 0) {
        return 'text-red'
      }
      if (value === 1){
        return 'text-green'
      }
    },
    setColorValue(value) {
      if (value === 0) {
        return 'color-value-red'
      }
      if (value === 1){
        return 'color-value-green'
      }
    },
    handleMarkResultKpi() {
      let totalPoint = 0;
      this.resultWeeklyMeeting.forEach(item => {
        item?.week?.forEach((week) => {
          const weekKey = Object.keys(week)[0];
          const value = week[weekKey];
          if (value) {
            totalPoint += value;
          }
        });
      });
      this.$emit('totalPointWeeklyMeeting', totalPoint);
    },
  },
}
</script>

<style lang="css" >
  .line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .text-red {
    color: red !important;
  }
  .text-green {
    color: green !important;
  }
  .color-value-red .el-input__inner{
    color: red !important;
  }
  .color-value-green .el-input__inner{
    color: green !important;
  }
  .headerTable {
    background: #F8F8F8;
    text-align: start;
    word-break: break-word;
  }
  .table-week {
    overflow: auto;
    padding-bottom: 30px;
    max-height: 240px;
    max-width: 1000px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
</style>e