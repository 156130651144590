<template>
  <fragment>
    <li class="menu-section">
      <h4 class="menu-text">Quản lý đơn vị hành chính</h4>
    </li>
    <router-link
        :to="{ name : 'area-index'}"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-chart-area"></i>
          <span class="menu-text">Khu vực</span>
        </a>
      </li>
    </router-link>
    <router-link
        :to="{ name : 'office-index'}"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-code-branch"></i>
          <span class="menu-text">Quản lý tên văn phòng</span>
        </a>
      </li>
    </router-link>
  </fragment>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "QuanLyHeThongThongTin",
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
