<template>
  <div class="example mb-10">
    <div class="example-preview table-responsive">
      <table class="table table-vertical-center">
        <thead>
        <tr>
          <th class="text-center">Ca làm việc</th>
          <th class="text-center">
            <span>Tất cả</span>
          </th>
          <th class="text-center">
            <span>Thứ 2</span>
          </th>
          <th class="text-center">
            <span>Thứ 3</span>
          </th>
          <th class="text-center">
            <span>Thứ 4</span>
          </th>
          <th class="text-center">
            <span>Thứ 5</span>
          </th>
          <th class="text-center">
            <span>Thứ 6</span>
          </th>
          <th class="text-center">
            <span>Thứ 7</span>
          </th>
          <th class="text-center">
            <span>Chủ nhật</span>
          </th>
        </tr>
        <tr v-for="(item, index) in data" :key="index">
          <td class="text-center" v-for="(v, i) in item" :key="`${index}-${i}`">
            <span v-if="v.type === 'title'">{{ CA_LAM_VIEC[v.name] }}</span>
            <el-checkbox v-else v-model="v.value" @input="handleCheckbox(v)"></el-checkbox>
          </td>
        </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {CA_LAM_VIEC_KEY_TEXT, LOCATION_TESTER_CALENDAR} from "@/core/option/testInputOption";
import alert from "@/view/pages/vue-bootstrap/Alert";

export default {
  name: "RegisterCalendarDefault",
  props: {
    data_prop: {
      type: Array,
      default() {
        return []
      }
    }
  },
  mounted() {
    this.setData();
  },
  data() {
    return {
      CA_LAM_VIEC: CA_LAM_VIEC_KEY_TEXT,
      data: [
        [
          {
            name: "morning_1",
            type: "title",
            value: "morning",
          },
          {
            name: "morning_1",
            type: "all",
            value: false,
          },
          {
            name: "monday",
            type: "boolean",
            value: false,
          },
          {
            name: "tuesday",
            type: "boolean",
            value: false,
          },
          {
            name: "wednesday",
            type: "boolean",
            value: false,
          },
          {
            name: "thursday",
            type: "boolean",
            value: false,
          },
          {
            name: "friday",
            type: "boolean",
            value: false,
          },
          {
            name: "saturday",
            type: "boolean",
            value: false,
          },
          {
            name: "sunday",
            type: "boolean",
            value: false,
          }
        ],
        [
          {
            name: "morning",
            type: "title",
            value: "morning",
          },
          {
            name: "morning",
            type: "all",
            value: false,
          },
          {
            name: "monday",
            type: "boolean",
            value: false,
          },
          {
            name: "tuesday",
            type: "boolean",
            value: false,
          },
          {
            name: "wednesday",
            type: "boolean",
            value: false,
          },
          {
            name: "thursday",
            type: "boolean",
            value: false,
          },
          {
            name: "friday",
            type: "boolean",
            value: false,
          },
          {
            name: "saturday",
            type: "boolean",
            value: false,
          },
          {
            name: "sunday",
            type: "boolean",
            value: false,
          }
        ],
        [
          {
            name: "afternoon_1",
            type: "title",
            value: "morning",
          },
          {
            name: "afternoon_1",
            type: "all",
            value: false,
          },
          {
            name: "monday",
            type: "boolean",
            value: false,
          },
          {
            name: "tuesday",
            type: "boolean",
            value: false,
          },
          {
            name: "wednesday",
            type: "boolean",
            value: false,
          },
          {
            name: "thursday",
            type: "boolean",
            value: false,
          },
          {
            name: "friday",
            type: "boolean",
            value: false,
          },
          {
            name: "saturday",
            type: "boolean",
            value: false,
          },
          {
            name: "sunday",
            type: "boolean",
            value: false,
          }
        ],
        [
          {
            name: "afternoon",
            type: "title",
            value: "morning",
          },
          {
            name: "afternoon",
            type: "all",
            value: false,
          },
          {
            name: "monday",
            type: "boolean",
            value: false,
          },
          {
            name: "tuesday",
            type: "boolean",
            value: false,
          },
          {
            name: "wednesday",
            type: "boolean",
            value: false,
          },
          {
            name: "thursday",
            type: "boolean",
            value: false,
          },
          {
            name: "friday",
            type: "boolean",
            value: false,
          },
          {
            name: "saturday",
            type: "boolean",
            value: false,
          },
          {
            name: "sunday",
            type: "boolean",
            value: false,
          }
        ],
        [
          {
            name: "evening",
            type: "title",
            value: "morning",
          },
          {
            name: "evening",
            type: "all",
            value: false,
          },
          {
            name: "monday",
            type: "boolean",
            value: false,
          },
          {
            name: "tuesday",
            type: "boolean",
            value: false,
          },
          {
            name: "wednesday",
            type: "boolean",
            value: false,
          },
          {
            name: "thursday",
            type: "boolean",
            value: false,
          },
          {
            name: "friday",
            type: "boolean",
            value: false,
          },
          {
            name: "saturday",
            type: "boolean",
            value: false,
          },
          {
            name: "sunday",
            type: "boolean",
            value: false,
          }
        ],
        [
          {
            name: "evening_overtime",
            type: "title",
            value: "morning",
          },
          {
            name: "evening_overtime",
            type: "all",
            value: false,
          },
          {
            name: "monday",
            type: "boolean",
            value: false,
          },
          {
            name: "tuesday",
            type: "boolean",
            value: false,
          },
          {
            name: "wednesday",
            type: "boolean",
            value: false,
          },
          {
            name: "thursday",
            type: "boolean",
            value: false,
          },
          {
            name: "friday",
            type: "boolean",
            value: false,
          },
          {
            name: "saturday",
            type: "boolean",
            value: false,
          },
          {
            name: "sunday",
            type: "boolean",
            value: false,
          }
        ],
      ],
    }
  },
  watch: {
    // data: {
    //   handler() {
    //     this.$emit('registerSuccess', this.data);
    //   },
    //   deep: true
    // },
    data_prop: {
      handler() {
        this.setData();
      },
      deep: true
    }
  },
  methods: {
    handleCheckbox(value) {
      let array_define = {
        'morning_1':0,
        'morning': 1,
        'afternoon_1': 2,
        'afternoon': 3,
        'evening': 4,
        'evening_overtime': 5
      }
      if (value.type !== 'all') {
        this.verifyCheckAll(value, array_define);
        this.changeData();
        return;
      }
      this.checkAll(value, array_define);
      this.changeData();
    },
    verifyCheckAll() {
      for (let i = 0; i < this.data.length; i++) {
        let items = this.data[i];
        this.data[i][1].value = this.getResultInItem(items);
      }
    },
    getResultInItem(items) {
      for (let j = 0; j < items.length; j++) {
        if (items[j].type === 'title' || items[j].type === 'all') {
          continue;
        }
        if (items[j].value === false) {
          return false;
        }
      }
      return true;
    },
    checkAll(value, array_define) {
      let key = array_define[value.name];
      let valueAll = value.value;
      for (let i = 0; i < this.data.length; i++) {
        if (i !== key) {
          continue;
        }
        let items = this.data[i];
        for (let j = 0; j < items.length; j++) {
          if (items[j].type === 'title') {
            continue;
          }
          this.data[i][j].value = valueAll;
        }
      }
    },
    setData() {
      if (this.data_prop.length) {
        this.data = JSON.parse(JSON.stringify(this.data_prop)).sort((a,b)=>{
          const titleTypeA = a.find(d => d.type === "title")?.name;
          const titleTypeB = b.find(d => d.type === "title")?.name;
          const firstLocation = titleTypeA ? LOCATION_TESTER_CALENDAR.get(titleTypeA) : 0;
          const secondLocation = titleTypeB ? LOCATION_TESTER_CALENDAR.get(titleTypeB) : 0;
          return firstLocation - secondLocation;
        })
      }
    },
    changeData(){
      this.$emit('registerSuccess', this.data);
    }
  }
}
</script>

<style scoped>

</style>