<template>
  <div>
    <div class="d-flex justify-content-between mb-5">
      <div style="color: #454545; font-size: 15px">{{ isVoucher ? 'Danh sách voucher' : 'Danh sách quà tặng' }}</div>
      <ModelAddGift @doneAddGift="getListGift()" :is-voucher="isVoucher"></ModelAddGift>
    </div>
    <div style="height: auto; max-height: 30vh; overflow: auto">
      <table class="table table-bordered">
        <thead class="table-secondary">
        <tr class="table-active text-center">
          <th scope="col">Id</th>
          <th scope="col" v-text="isVoucher ? 'Voucher' : 'Tên quà tặng'"></th>
          <th v-if="!isVoucher" scope="col">Ảnh quà tặng</th>
          <th scope="col">Hành động</th>
        </tr>
        </thead>
        <tbody v-if="post && post.length === 0">
        <tr class="text-center">
          <td colspan="4">Không có dữ liệu</td>
        </tr>
        </tbody>
        <tbody>
        <tr v-for="(item, index) in post" :key="index" class="text-center">
          <th scope="row">{{ item.id }}</th>
          <td>{{ item.name }}</td>
          <td v-if="!isVoucher">
            <a class="text-blue" @click="showImg(item.url)">{{ item.nameImage }}</a>
          </td>
          <td class="justify-content-center">
            <el-button
                type="text"
                @click="deleteGift(item.id)"
                class="btn btn-xs btn-icon mr-2 btn-outline-danger"
            ><i class="fas fa-trash"></i
            ></el-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="edu-paginate d-flex justify-content-between mt-10" v-if="post?.length > 0">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
      <div>
        <p class="mb-0">
          Tổng số bản ghi: <span class="text-primary">{{ post?.length }}</span>
        </p>
      </div>
    </div>
    <el-dialog title="" :visible.sync="outerVisible">
      <div>
        <img :src="url" alt="" class="w-100">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ModelAddGift from "./ModelAddGift.vue";
import {GET_LIST_GIFT} from "@/core/services/store/rewards-programs/rewardsPrograms.module";
import {DELETE_GIFT} from "@/core/services/store/rewards-programs/rewardsPrograms.module";
import {GiftModel} from "@/view/pages/rewards-program/detail-program/model/InformationProgramModel";

export default {
  components: {ModelAddGift},
  name: "ListGift",
  async mounted() {
    this.idProgram = this.$route.params.id;
    await this.getListGift();
  },
  props: {
    typeProgram: {
      type: Number || String,
      default: '',
    }
  },
  data() {
    return {
      post: [],
      page: 1,
      last_page: 0,
      total: 0,
      outerVisible: false,
      url: '',
      loading: true,
      dataTable: []
    };
  },
  computed: {
    isVoucher() {
      return this.typeProgram === 1;
    }
  },
  methods: {
    async getListGift() {
      const payload = {
        id: this.idProgram,
        page: this.page,
        program_type: this.typeProgram
      };
      await this.$store.dispatch(GET_LIST_GIFT, payload).then((data) => {
        if (!data.error) {
          this.total = data.pagination.total;
          this.page = data.pagination.current_page;
          this.last_page = data.pagination.last_page;
          this.post = data?.data?.map(item => {
            return new GiftModel(
                item?.id,
                item?.name,
                item?.file_name,
                item?.url,
                item?.program_type
            )
          });
        }
      });
    },
    deleteGift(id) {
      this.$confirm(
          "Bạn có chắc chắn muốn xóa quà tặng này không?",
          "Xóa quà tặng",
          {
            confirmButtonText: "Xác nhận",
            cancelButtonText: "Hủy",
            type: "warning",
          }
      )
          .then(() => {
            this.$store
                .dispatch(DELETE_GIFT, id)
                .then((data) => {
                  this.getListGift(data);
                  this.$emit('doneDelete')
                  this.$message({
                    type: "success",
                    message: `${data.message}`,
                  });
                })
                .catch((error) => {
                  this.$message({
                    type: "error",
                    message: `${error.data.message}`,
                  });
                });
          })
          .catch(() => {
          });
    },
    clickCallback(obj) {
      this.page = obj;
      this.getListGift();
    },
    showImg(url) {
      this.url = url;
      this.outerVisible = true;
    }
  },
};
</script>

<style scoped>
.text-blue {
  color: #00aff0;
}
</style>
