<template>
  <div class="example-preview table-responsive">
    <table role="table" :aria-busy="isBusy" aria-colcount="0" class="table b-table table-vertical-center"
           id="__BVID__822">
      <tr role="row" class="b-table-busy-slot" v-show="isBusy">
        <td colspan="3" role="cell" class="">
          <div class="text-center text-danger my-2">
            <span aria-hidden="true" class="align-middle spinner-border"></span>
            <strong>Loading...</strong>
          </div>
        </td>
      </tr>
      <thead>
      <tr>
        <th scope="col" class="min-w-100px">STT</th>
        <th scope="col" class="min-w-100px">Mã học viên</th>
        <th scope="col" class="min-w-100px">Tên học viên</th>
        <th scope="col" class="min-w-100px">Số điện thoại</th>
        <th scope="col" class="min-w-100px">Email</th>
        <th scope="col" class="min-w-100px">Ngày sinh</th>
        <th scope="col" class="min-w-120px">Hành động</th>
      </tr>
      </thead>
      <tbody v-show="!isBusy">
      <tr v-for="(item, index) in items.data" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ item.id + 100000 }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.phone }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.birthday | formatDate }}</td>
        <td>
          <router-link :to="{ name: 'student-all-show', params: {id : item.id} }" title="Chi tiết"
                       class="btn btn-sm btn-icon mr-2 btn-outline-info"><i
              class="far fa-eye"></i>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "ListStudentTable",
  props: ['isBusy', 'items'],
}

</script>

<style scoped>

</style>