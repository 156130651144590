<template>
  <div class="container mt-xl-0 mt-4">
    <h3>Kết quả bài test</h3>
    <div class="card" style="background-color: #F6F6F6 ;max-height: 700px;overflow: auto">
      <div class=" content_result  p-4     ">
        <div v-if="resultTests.length>0">
          <div class="card p-4 mb-4" v-for="(result,index) in resultTests" >
            <ResultTestDetailChild :result="result"></ResultTestDetailChild>
          </div>
          <div class="card p-4 mb-4" v-for="(item,index) in commentAndConclusion" >
             <comment-and-conclusion :comment="item"></comment-and-conclusion>
          </div>
        </div>
        <div v-else>
          <p class="mb-0">Chưa có kết quả.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OveralTest from "@/view/pages/test-input/components/ComponentsTaskDetail/OveralTest.vue";
import CommentsTest from "@/view/pages/test-input/components/ComponentsTaskDetail/CommentsTest.vue";
import {GET_RESULT_EXAM} from "@/core/services/store/test/testInput.module";
import ResultTestDetailChild from "@/view/pages/test-input/components/ComponentsTaskDetail/ResultTestDetailChild.vue";
import CommentAndConclusion from "@/view/pages/test-input/components/ComponentsTaskDetail/CommentAndConclusion.vue";

export default {
  name: "TestResultsChild",
  components: {CommentAndConclusion, ResultTestDetailChild, CommentsTest, OveralTest},
  data() {
    return {
      resultTests: [],
      commentAndConclusion:[]
    }
  },
  mounted() {
    this.getResultTests();
  },
  methods: {
    async getResultTests() {
      let test_input_schedule_id = this.$route.params.id
      let data = await this.$store.dispatch(GET_RESULT_EXAM, test_input_schedule_id ?? 0);
      if (!data.error) {
        this.resultTests = data?.data?.speaking.filter((e)=>e.id != null);
        this.commentAndConclusion = data?.data?.speaking.filter((e)=>e.id == null);
      }
    },
  }
}
</script>
<style scoped>
.circle {
  width: 20px;
  height: 20px;
  background-color: #013878;
  border-radius: 50%; /* Điều này làm cho div trở thành hình tròn */
}
</style>