<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form
      class="form"
      @submit.prevent="handleSubmit(summit)"
      autocomplete="off"
    >
      <el-row>
        <el-row class="table-responsive-lg">
          <el-row>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <p>
                  <span class="homework-title">Học viên: </span>
                  <b>{{ student.name }}</b>
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <p>
                  <span class="homework-title">Điểm chấm tự động: </span>
                  <b
                    >{{ student.test.score_auto_mark }}/{{
                      student.test.num_auto_mark
                    }}</b
                  >
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <p>
                  <span class="homework-title">Số điện thoại: </span>
                  <b>{{ student.phone }}</b>
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <p>
                  <span class="homework-title">Thời gian nộp bài: </span>
                  <b>{{ student.test.created_at | formatDateTimeVietnam }}</b>
                </p>
              </div>
            </el-col>
          </el-row>
        </el-row>
        <el-row class="" v-show="data.length > 0">
          <el-col
            :span="24"
            class="mt-3 homework"
            v-for="(item, index) in data"
            :key="index"
          >
            <div class="student-answer">
              <div class="box-question item-question">
                <p class="bold text-break">
                  Đề bài: {{ item.group_question.description }}
                </p>
                <p class="" style="white-space: pre-line">
                  {{ item.group_question.script }}
                </p>
                <el-image
                  :src="item.group_question.image"
                  fit="fill"
                  v-if="item.group_question.image"
                ></el-image>
                <div v-if="item.group_question.video">
                  <video class="rounded" width="320" height="240" controls preload="auto">
                    <source :src="item.group_question.video" type="video/mp4" />
                  </video>
                </div>
              </div>
              <div class="bold text-break mt-3 mb-3">
                Bài làm của học viên:
                <span
                  class="badge badge-warning"
                  style="padding: 5px"
                  v-if="!item.student_answer"
                >
                  Học viên chưa làm</span
                >
              </div>
              <div class="box-item box-item-image" v-if="item.student_answer">
                <div v-if="item.group_question.type === 'record_video'">
                  <video class="rounded" width="320" height="240" controls preload="auto">
                    <source :src="item.student_answer" type="video/mp4" />
                  </video>
                </div>
                <div v-else-if="item.group_question.type === 'record_audio'">
                  <audio controls preload="metadata" ref="audio">
                    <source :src="item.student_answer" type="audio/mpeg" />
                    <source :src="item.student_answer" type="audio/ogg" />
                  </audio>
                </div>
                <p class="box-item-answer" v-else>{{ item.student_answer }}</p>
              </div>
              <div v-if="item.student_answer">
                <div class="mt-2 mb-2 mx-auto" v-if="typeShow === 'mark'">
                  <label class="mb-0 mr-3 bold">Chấm điểm:</label>
                  <ValidationProvider
                    vid="score"
                    name="Điểm số"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="number"
                      class="form-control mt-1 w-50"
                      placeholder="Nhập điểm số....."
                      :class="classes"
                      v-model="item.score"
                      min="0"
                      max="10"
                    />
                    <div class="fv-plugins-message-container">
                      <div
                        data-field="score"
                        data-validator="notEmpty"
                        class="fv-help-block"
                      >
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="mt-2 mb-2" v-if="typeShow === 'mark'">
                  <ValidationProvider
                    vid="score"
                    name="Nhận xét"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <label class="mb-1 mr-3 bold">Nhận xét:</label>
                    <el-input
                      type="textarea"
                      class="w-100"
                      :class="classes"
                      :rows="7"
                      :maxlength="1000"
                      show-word-limit
                      placeholder="Nhập nhận xét....."
                      v-model="item.comment"
                    />
                    <div class="fv-plugins-message-container">
                      <div
                        data-field="score"
                        data-validator="notEmpty"
                        class="fv-help-block"
                      >
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <span
          class="dialog-footer mt-5 d-flex align-items-center justify-content-end"
        >
          <button
            type="button"
            class="btn btn-outline-default ml-2"
            @click="close"
          >
            Đóng
          </button>
          <el-button
            class="btn ml-2 btn-item"
            native-type="submit"
            :loading="loading"
            v-if="data.length > 0 && typeShow === 'mark'"
            >Lưu</el-button
          >
        </span>
      </el-row>
    </form>
  </ValidationObserver>
</template>

<script>
import { ADMIN_POST_MARK_HOMEWORK } from "@/core/services/store/course/classes.module";

import "@/assets/sass/homework/homework-exam.scss";
export default {
  name: "homework-exam",
  props: {
    student: {
      type: Object,
      default: () => {
        return {};
      },
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    typeShow: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      class_id: "",
      loading: false,
      comments: [],
    };
  },
  created() {
    this.class_id = this.$route.params.id;
    this.comments = this.data.map((item) => {
      return { comment: item.comment };
    });
  },
  watch: {
    student: {
      handler() {
        if (this.student.id && this?.$refs["audio"])
          this?.$refs["audio"]?.load();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    summit() {
      this.loading = true;
      let result = this.data.reduce((result, item, key) => {
        result.push({
          score: item.score,
          outcomes_id: item.outcomes_id,
          comment: item.comment,
        });
        return result;
      }, []);
      let overall_result_id = this.data[0].overall_result_id;
      this.$store
        .dispatch(ADMIN_POST_MARK_HOMEWORK, {
          classroom_id: this.class_id,
          course_student_id: this.student.course_student_id,
          overall_result_id: overall_result_id,
          type: "homework",
          results: result,
          by_admin: true
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "Chấm điểm thành công!",
            showClose: true,
          });
          this.$emit("markSuccess");
        })
        .catch((res) => {
          this.$message({
            type: "error",
            message: "Sever đang bận! " + res.data.message,
            showClose: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
