<template>
  <v-app>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Phân cấp sale</h3>
        </div>
        <div class="card-title"
             v-if="currentUser.user.permission.includes(permission.QUAN_LY_NHAN_SU_KHOI_CO_SO)">
          <button class="btn btn-primary font-weight-bold mr-2" @click="exportExcelExample">
            <i class="la la-file-excel"></i> Xuất file mẫu
          </button>
          <button class="btn btn-success font-weight-bold mr-2" @click="exportExcel">
            <i class="la la-file-excel"></i> Xuất excel
          </button>
        </div>
      </div>
      <div class="form-group row mb-2 ml-5">
        <div class="col-md-12">
          <ValidationObserver v-slot="{ handleSubmit }" class="col-md-3">
            <form class="form-inline" @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider ref="validate_file" rules="required" name="File"
                                  v-slot="{ validate, errors }" class="mr-2 mt-1">
                <div class="upload">
                  <div v-for="file in files" :key="file.id">
                    <i class="fas fa-minus-circle mr-2" style="cursor: pointer;"
                       @click="removeFile"></i>
                    <span>{{ file.name }}</span> -
                    <span>{{ file.size }}</span> -
                    <span v-if="file.error">{{ file.error }}</span>
                    <span v-else-if="file.success">success</span>
                    <span v-else-if="file.active">active</span>
                    <span v-else-if="!!file.error">{{ file.error }}</span>
                    <span v-else></span>
                  </div>
                  <div class="example-btn d-flex justify-content mt-2">
                    <file-upload
                        class="btn btn-text-dark-50 btn-icon-primary font-weight-bold btn-hover-bg-light mr-3"
                        post-action=""
                        extensions="xlsx,xls"
                        accept=".xlsx,.xls"
                        :size="1024 * 1024 * 10"
                        v-model="files"
                        @input-filter="inputFilter"
                        @input-file="inputFile"
                        ref="upload">
                      <i class="flaticon-upload"></i>
                      Chọn file
                    </file-upload>
                    <button type="button" @click="onSubmit" class="btn btn-success mr-3"
                            :class="{'spinner spinner-darker-success spinner-left' : is_loading_import}"
                            :disabled="isAble || percentLoadingImportSaleLog >= 50">
                      Upload
                    </button>
                    <button v-if="!isShowUpdateParent"
                            type="button"
                            @click="updateParent"
                            class="btn btn-danger mr-3"
                            :class="{'spinner spinner-darker-success spinner-left' : is_loading_import}"
                            :disabled="isDisableUpdateParent">
                      Upload Cấp trên
                    </button>
                  </div>
                </div>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </form>
          </ValidationObserver>
        </div>
        <div class="col-md-6" v-if="show_process_loading">
          <div class="progress">
            <div class="progress-bar" role="progressbar"
                 :style="{width : percentLoadingImportSaleLog + '%'}"
                 :aria-valuenow="`${percentLoadingImportSaleLog}`" aria-valuemin="0"
                 aria-valuemax="100">{{ percentLoadingImportSaleLog }}%
            </div>
          </div>
        </div>
      </div>
      <div class="row ml-5" v-if="show_process_loading">
        <div class="col-md-6 mt-2">
          <label class="mr-2">Import phân cấp nhân sự</label>
          <span v-if="percentLoadingImportSaleLog == 0" class="badge badge-warning">Chờ xử lý</span>
          <span v-if="percentLoadingImportSaleLog < 99 && percentLoadingImportSaleLog > 0"
                class="badge badge-primary">Đang xử lý </span>
          <span v-if="percentLoadingImportSaleLog >= 99" class="badge badge-success">Hoàn tất</span>

        </div>
      </div>
      <div class="row ml-5 mt-2" v-if="show_process_loading">
        <div class="col-md-6">
          <label class="mr-2">Đồng bộ mã cấp trên</label>
          <span v-if="percentLoadingImportSaleLog < 99" class="badge badge-warning">Chờ xử lý</span>
          <span v-if="percentLoadingImportSaleLog == 99"
                class="badge badge-primary">Đang xử lý </span>
          <span v-if="percentLoadingImportSaleLog == 100" class="badge badge-success">Hoàn tất</span>
        </div>
      </div>
      <!--Filter-->
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(searchData)">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <date-picker v-model="query.month" valueType="YYYY-MM" format="MM-YYYY"
                               placeholder="Chọn tháng"
                               type="month"></date-picker>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <el-select clearable v-model="query.area_id" filterable placeholder="Khu vực"
                             @input="handleQueryArea">
                    <el-option
                        v-for="item in areas"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <ValidationProvider vid="branch_id" name="Chi nhánh" rules="required"
                                      v-slot="{ errors,classes }">
                    <el-select clearable v-model="query.branch_id" filterable
                               placeholder="Chi nhánh">
                      <el-option
                          v-for="item in branches"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-3">
                <div class="d-flex justify-content-between">
                  <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                          type="submit">
                    <i class="fa fa-search"></i> Tìm kiếm
                  </button>
                  <button v-else type="button" disabled
                          class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                    Tìm kiếm
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <!--                            <div v-if="!items.length" class="col-md-12 alert alert-danger" role="alert">-->
              <!--                                Chưa có dữ liệu-->
              <!--                            </div>-->
              <div style="width: 100%!important;" v-if="!items.length"
                   class="alert alert-custom alert-light-danger fade show mb-5" role="alert">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <div class="alert-text">Chưa có dữ liệu!</div>
              </div>
              <v-treeview
                  v-else
                  v-model="tree"
                  :items="items"
                  activatable
                  color="success"
                  transition
              >
                <template v-slot:prepend="{ item, open }">
                  <v-avatar size="30" style="border: 1px solid;">
                    <v-img
                        :src="item.user_id && item.account_type != null? item.account_type.image : item.profile ? item.profile.account_type.image : ''"></v-img>
                  </v-avatar>
                </template>
                <template slot="label" slot-scope="{ item }">
                  <div @click="showDetailData(item)" class="user-hover">{{ item.name }}</div>
                </template>
              </v-treeview>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <form-detail :show-modal="showModal" :dataUserSale="dataUserSale"
                 @clicked="onclickViewFormDetail"></form-detail>
    <div class="vld-parent">
      <loading :active.sync="isLoading"
               :can-cancel="true"
               :on-cancel="onCancel"
               :is-full-page="fullPage"></loading>
    </div>
  </v-app>
</template>

<script>
import {mapGetters} from "vuex";
import {
  EXPORT_EXCEL_SALE_TREE_EXAMPLE,
  EXPORT_SALE_TREE,
  GET_DATA_SALE_LOG,
  IMPORT_SALE_TREE, IMPORT_SALE_TREE_UPDATE_PARENT,
  PUSH_PERCENT_LOADING_IMPORT_SALE_LOG,
} from "../../../core/services/store/user/users.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import {GET_AREAS} from "../../../core/services/store/center/area.module";

import Multiselect from 'vue-multiselect'

import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import FileUpload from 'vue-upload-component';
import FormDetail from "./FormDetail";
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
  name: "SaleTreeView",
  components: {
    DatePicker, FileUpload, FormDetail, Loading, Multiselect,
  },
  data: () => ({
    show_process_loading: false,
    is_loading_import: false,
    publicPath: process.env.VUE_APP_BASE_URL,
    tree: [],
    items: [],
    month: '',

    areas: [],
    branches: [],
    query: {
      area_id: '',
      branch_id: '',
      month: ''
    },
    // cache
    cacheArea: [],
    is_disable_search: false,

    upload: null,
    files: [],
    isAble: true,
    isShowUpdateParent: true,
    isDisableUpdateParent: false,
    showDetailUser: false,
    showModal: false,
    dataUserSale: {},
    type: 'sale-log',
    /*Loader */
    isLoading: false,
    fullPage: true,
    /*Socket*/
    import_month: '',
    import_year: '',
  }),
  mounted() {
    this.getAreas()
    this.getBranches()
    this.pullParamsUrl();
    let monthUrl = this.$route.query.month;
    this.getSaleLog();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: 'Quản lý nhân sự'},
      {title: 'Phân cấp sale'},
    ])
    this.excitedBroadcasting();
  },
  watch: {
    percentLoadingImportSaleLog: function (value) {
      if (value == 50) {
        this.$bvToast.toast('Đã import sale thành công, tiếp theo bạn có thể update cấp trên', {
          title: 'Import sale',
          variant: 'success',
          solid: true
        });
      }
      if (value == 50) {
        this.isShowUpdateParent = false;
      }
      if (value == 99) {
        this.fixTreeEvent();
      }
      if (value == 100) {
        Swal.fire(
            'Đã xong!',
            '',
            'success'
        )
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser", "percentLoadingImportSaleLog"]),
  },
  methods: {
    fixTreeEvent() {
      Swal.fire(
          'Import phân cấp hoàn tất!',
          'Đang bắt đầu fix tree: có thể mất một khoảng thời gian, bạn có thể làm việc khác thay vì đợi chờ @@',
          'success'
      )
    },
    excitedBroadcasting() {
      this.$echo.channel('loading_import_sale_log').listen('LoadingImportSaleLogEvent', (payload) => {
        this.import_month = payload.percent.month;
        this.import_year = payload.percent.year;
        let percent = payload.percent.percent;
        this.$store.dispatch(PUSH_PERCENT_LOADING_IMPORT_SALE_LOG, percent).then(() => {
        });
      });
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.dataImport = newFile.file;
        this.isAble = false;
      }
      if (newFile && oldFile) {
        // update
      }
      if (!newFile && oldFile) {
        // remove
      }
    },
    removeFile: function () {
      this.files.pop();
      this.isAble = true;
    },
    onFileChange(event) {
      const {valid} = this.$refs.validate_file.validate(event);
      this.dataImport = event.target.files[0];
    },
    updateParent() {
      this.isDisableUpdateParent = true;
      let formData = new FormData();
      formData.append('dataImport', this.dataImport);
      this.is_loading_import = true;
      this.$store.dispatch(IMPORT_SALE_TREE_UPDATE_PARENT, formData).then((res) => {
        this.isDisableUpdateParent = false;
        this.removeFile();
        this.is_loading_import = false
        if (!res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Update cấp trên',
            variant: 'success',
            solid: true
          });
        } else {
          this.$bvToast.toast(res.message, {
            title: 'Update cấp trên',
            variant: 'danger',
            solid: true
          });
        }
      }).catch((error) => {
        this.isDisableUpdateParent = false;
        if (error.data.error) {
          this.$bvToast.toast(error.data.data.message_validate_form[0], {
            title: 'Update cấp trên',
            variant: 'danger',
            solid: true
          });
        }
        this.is_loading_import = false
      });
    },
    onSubmit() {
      let formData = new FormData();
      formData.append('dataImport', this.dataImport);
      this.is_loading_import = true;
      this.show_process_loading = true;
      this.$store.dispatch(PUSH_PERCENT_LOADING_IMPORT_SALE_LOG, 0);
      this.$store.dispatch(IMPORT_SALE_TREE, formData).then((res) => {
        this.is_loading_import = false
        if (!res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Import',
            variant: 'success',
            solid: true
          });
        } else {
          this.$bvToast.toast(res.message, {
            title: 'Import',
            variant: 'danger',
            solid: true
          });
        }
      }).catch((error) => {
        if (error.data.error) {
          this.$bvToast.toast(error.data.data.message_validate_form[0], {
            title: 'Import',
            variant: 'danger',
            solid: true
          });
        }
        this.is_loading_import = false
      });
    },
    exportExcel() {
      this.isLoading = true;
      this.$store.dispatch(EXPORT_SALE_TREE, this.query).then((res) => {
        if (!res.error) {
          this.isLoading = false;
          this.$store.dispatch(GET_BRANCHES).then((data) => {
            this.branches = data.data;
            this.$bvToast.toast(res.message, {
              title: 'Export',
              variant: 'success',
              solid: true
            });
          })
        }
      });
    },
    exportExcelExample() {
      this.isLoading = true;
      this.$store.dispatch(EXPORT_EXCEL_SALE_TREE_EXAMPLE).then((res) => {
        if (!res.error) {
          this.isLoading = false;
        }
      });
    },
    showDetailData: function (item) {
      this.showModal = true;
      this.dataUserSale = item;
    },
    onclickViewFormDetail() {
      this.showModal = false;
    },
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl()
      if (!this.query.branch_id) {
        this.$bvToast.toast('Chi nhánh là bắt buộc', {
          title: 'Tìm kiếm',
          variant: 'danger',
          solid: true
        });
        this.is_disable_search = false
      } else {
        this.getSaleLog(this.query.month)
      }
    },
    getAreas() {
      this.$store.dispatch(GET_AREAS, {
        limit: 10000,
        status: 1,
        is_show_filter: 1
      }).then((res) => {
        if (!res.error) {
          this.areas = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        area_id: this.query.area_id,
        is_show_filter: 1
      }).then((res) => {
        if (!res.error) {
          this.branches = res.data.data
          this.setCacheTheLoad()
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getSaleLog() {
      this.$store.dispatch(GET_DATA_SALE_LOG, {
        month: this.query.month,
        branch_id: this.query.branch_id,
      }).then((res) => {
        if (!res.error) {
          this.is_disable_search = false
          this.items = res.data
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      });
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'sale-tree-view',
        query: {
          month: this.query.month,
          branch_id: this.query.branch_id,
        }
      })
    },
    pullParamsUrl() {
      this.query.month = this.$route.query.month;
      this.query.area_id = this.$route.query.area_id ? parseInt(this.$route.query.area_id) : '';
      this.query.branch_id = this.$route.query.branch_id ? parseInt(this.$route.query.branch_id) : '';
    },

    handleQueryArea() {
      this.query.branch_id = '';
      this.branches = [];
      this.getBranches();
    },

    setCacheTheLoad() {
      if (this.query.area_id != '') {
        let oldParamArea = this.areas.filter((value) => {
          if (parseInt(this.query.area_id) == value.id) {
            return value
          }
        })
        this.cacheArea = oldParamArea
      }
      if (this.query.branch_id != '') {
        let oldParamBranch = this.branches.filter((value) => {
          if (parseInt(this.query.branch_id) == value.id) {
            return value
          }
        })
        this.cacheBranch = oldParamBranch
      }
    },
    onCancel: function () {
      console.log('User cancelled the loader.')
    },
  }
}
</script>
<style scoped>
.mx-datepicker {
  width: 100% !important;
}

.add-bank-account:hover i, .add-bank-account:hover .text-muted {
  color: #1BC5BD !important;
}

.user-hover:hover {
  cursor: pointer;
}

.multiselect__tags {
  padding: 3px 40px 0 9px !important;
  min-height: 38px !important;
}

.multiselect {
  min-height: 38px !important;
}

.mx-input {
  min-height: 38px !important;
}

.multiselect__single {
  margin-top: 5px !important;
}

.el-select {
  width: 100%;
}

</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>




