<template>
    <div class="card card-custom">
        <div class="card-header">
            <h3 class="card-title">
                Chuyển số dư tài khoản vào hợp đồng
            </h3>
            <div class="card-toolbar">
                <div class="example-tools justify-content-center">
                    <span class="example-toggle" data-toggle="tooltip" title="View code"></span>
                    <span class="example-copy" data-toggle="tooltip" title="Copy code"></span>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card-body">
              <div class="form-group">
              <label class="d-flex justify-content-center">Tìm học viên<span></span></label>
              <div class="d-flex justify-content-center">
                <SelectCustomer minimumInputLength="6" placeholder="Nhập mã nv/SĐT"
                                method="GET"
                                :url="getUserUrl"
                                @input="selectCustomer($event)"
                >
                </SelectCustomer>
              </div>
            </div>
            </div>
          </div>
          <div class="col-md-12" v-if="show_detail">
            <div class="card-body">
              <span class="label label-xl label-light-info label-inline my-2 font-weight-bolder">Số dư tài khoản: {{ formatPrice(user.cash,' VNĐ') }}</span>
              <div class="example mb-10">
                <div class="example-preview table-responsive overflow-auto">
                  <table class="table table-vertical-center">
                    <tbody>
                    <tr  v-for="(item, index) in contracts" :key="index">
                      <td>Hợp đồng: <span class="font-weight-bolder">{{ item.user.name }} - {{ item.created_at | formatDate }}</span></td>
                      <td>
                        <ModalTransferMoney
                            :item="item"
                            :total="user.cash"
                            :user_id="user.id"
                            :index="index"
                            @move-success="moveSuccess"
                        >
                        </ModalTransferMoney>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import Multiselect from 'vue-multiselect';
    import SelectCustomer from "@/view/content/SelectCustomer";
    import {GET_USER} from '@/core/services/ajaxUrl';
    import {GET_USER_BY_ID} from "../../../core/services/store/user/users.module";
    import {Money} from 'v-money'
    import ModalTransferMoney from './ModalTransferMoney';
    import {GET_CONTRACTS} from "../../../core/services/store/contract/contracts.module";

    export default {
        name: "TieuSoDu",
        data() {
            return {
                price: 0,
                user: {
                  cash: 0
                },
                contracts: [],
                show_detail: false,
                isDebt: false,
                contract_id: '',
                money: {
                    decimal: ',',
                    thousands: ',',
                    prefix: '',
                    suffix: ' VNĐ',
                    precision: 0,
                    masked: false,
                }
            };
        },
        components: {
            Multiselect, SelectCustomer, ModalTransferMoney, Money
        },
        computed: {
            getUserUrl: function () {
                return GET_USER
            },
            total_pages() {
                return 1;
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: 'Quản lý tài chính'},
                {title: 'Xử lý số dư tài khoản'},
            ])
        },
        methods: {
          moveSuccess(data){
            this.user.cash = data.cash;
          },
            selectCustomer: function ($event) {
                this.getUserById($event)
            },
            getUserById(id) {
              if(id){
                this.$store.dispatch(GET_USER_BY_ID, id).then((res) => {
                  if (!res.error) {
                    this.user = res.data
                    this.show_detail = true
                  }
                });
                this.$store.dispatch(GET_CONTRACTS, {
                  user_id : id,
                  is_cancel : 0,
                  limit : 1000,
                  type: 1
                }).then((res) => {
                  if (!res.error) {
                    this.contracts = res.data.data
                  }
                });
              }else{
                this.show_detail = false
              }
            },
        },
    }
</script>

<style>

    #select-customer{
      width: 50%;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
