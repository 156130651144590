<template>
  <div>
    <button title="Chuyển số dư vào hợp đồng"
            v v-b-modal="`modal-transfer-${index}`"
            class="btn btn-icon btn-outline-info">
      <i class="fas fa-exchange-alt"></i>
    </button>
    <b-modal :centered="true" ref="modal-amount" hide-footer :id="'modal-transfer-' + index"
             title="Chuyển số dư vào hợp đồng">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" @submit.prevent="handleSubmit(onSubmit)">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Tổng số dư</label>
                <ValidationProvider mode="lazy" vid="amount" name="Số tiền chuyển"
                                    rules="excluded:0|min_value:1"
                                    v-slot="{ errors,classes }">
                  <input type="text" class="form-control" disabled :value="formatPrice(total_cash,' VNĐ')">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Số tiền chuyển
                  <span class="text-danger">*</span></label>
                <a class="float-right text-decoration-underline" href="javascript:void(0)" @click="setMax">Tối đa</a>
                <ValidationProvider mode="lazy" vid="amount" name="Số tiền chuyển"
                                    rules="excluded:0|min_value:1"
                                    v-slot="{ errors,classes }">
                  <money v-model="price" v-bind="money" class="form-control" :class="classes"
                  ></money>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="float-right">
            <button type="button" class="btn btn-secondary mr-2" @click="hideModal()"> Hủy bỏ</button>
            <button type="submit" class="btn btn-success">Xác nhận</button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>
<script>
import {mapGetters,} from "vuex";
import {Money} from 'v-money'
import {TIEU_SO_DU} from "../../../core/services/store/contract/contracts.module";

export default {
  components: {
    Money,
    mapGetters
  },
  props: {
    item: Object,
    index: '',
    total: Number,
    user_id: Number
  },
  data() {
    return {
      price: '',
      total_cash: this.total,
      money: {
        decimal: ',',
        thousands: ',',
        prefix: '',
        suffix: ' VNĐ',
        precision: 0,
        masked: false,
      }
    }
  },
  name: "ModalTransferMoney",

  methods: {
    setMax(){
      this.price = this.total;
    },
    onSubmit() {
      this.$store
          .dispatch(TIEU_SO_DU, {
            contract_id: this.item.id,
            amount: this.price,
            user_id: this.user_id
          })
          .then((res) => {
            if (!res.error) {
              this.$emit('move-success',res.data)
              this.$bvToast.toast(res.message, {
                title: 'Tiêu số dư',
                variant: 'success',
                solid: true
              });
              this.price = ''
              this.$refs['modal-amount'].hide();
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Tiêu số dư',
                variant: 'danger',
                solid: true
              });
            }
          }).catch((e) => {
        if (e.data.error) {
          this.$bvToast.toast(e.data.message, {
            title: 'Lỗi',
            variant: 'danger',
            solid: true
          });
        }
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      });
    },
    hideModal() {
      this.$refs['modal-amount'].hide();
    }
  },

  watch : {
    price(newValue,oldValue){
      if(newValue!==oldValue){
        let new_total_cash;
        new_total_cash = this.total - newValue;
        if(new_total_cash<0){
          this.$bvToast.toast('Không được nhập quá tổng số dư', {
            title: 'Chuyển tiền',
            variant: 'warning',
            solid: true
          });
          this.total_cash = this.total;
          this.price = 0;
        }else{
          this.total_cash = new_total_cash;
        }
      }
    }
  },
}
</script>

<style scoped>
</style>
