<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Quản lý điều khoản & chính sách</h3>
      </div>
      <div class="card-title">

      </div>
    </div>
    <div class="card-body">
      <!--Filter-->
      <div class="example mb-10">
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th scope="col" class="min-w-100px">STT</th>
              <th scope="col" class="min-w-100px">Tên chính sách</th>
              <th scope="col" class="min-w-100px">Thời gian update</th>
              <th scope="col" class="min-w-100px">Hành động</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in listPolicy" :key="index">
                <td>{{ page == 1 ? index + 1 : (page - 1) * 20 + index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.updated_policy |formatDateTimeVietnam}}</td>
                <td>
                  <router-link :to="{ name: 'term-policy-edit', params: {id : item.id} }" title="Sửa"
                               class="btn btn-xs btn-icon mr-2 btn-outline-info"><i
                      class="fas fa-pen-nib"></i>
                  </router-link>
                  <router-link :to="{ name: 'term-policy-history', params: {id : item.id} }" title="Xem lịch sử "
                               class="btn btn-xs btn-icon mr-2 btn-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                    </svg>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
<!--      <paginate-->
<!--          v-model="page"-->
<!--          :page-count="last_page"-->
<!--          :page-range="3"-->
<!--          :margin-pages="1"-->
<!--          :click-handler="clickCallback"-->
<!--          :prev-text="'Trước'"-->
<!--          :next-text="'Sau'"-->
<!--          :container-class="'pagination b-pagination'"-->
<!--          :pageLinkClass="'page-link'"-->
<!--          :next-link-class="'next-link-item'"-->
<!--          :prev-link-class="'prev-link-item'"-->
<!--          :prev-class="'page-link'"-->
<!--          :next-class="'page-link'"-->
<!--          :page-class="'page-item'"-->
<!--      >-->
<!--      </paginate>-->
    </div>
  </div>
</template>

<script>
import {GET_TERM_POLICY} from "@/core/services/store/system/termpolicy.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "TermPolicyIndex",

  data() {
    return {
      is_disable_search: false,
      informations: [],
      // Paginate
      page: 1,
      last_page: 1,

      centers: [],
      cacheCenter: [],
      listPolicy: {}
    }
  },
  mounted() {
    this.getList();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Khách hàng"},
      {title: "Quản lý điều khoản & chính sách", route: 'term-policy'}
    ]);
  },
  methods: {
    clickCallback(obj) {
      this.page = obj
      this.getList()
    },
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl()
      this.getList()
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'term-policy',
      })
    },
    getList() {
      this.$store.dispatch(GET_TERM_POLICY, {
        page: this.page
      }).then((res) => {
        this.listPolicy = res.data;
        this.last_page = res.data.last_page;

      }).catch((error) => {
        this.$notify({
          title: 'Thất bại',
          message: error.data.message,
          type: 'error'
        });
      })
    }
  }
}
</script>

<style scoped>

</style>