<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Danh sách phòng trống</h3>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Trung tâm</label>
                        <multiselect v-model="cacheCenter"
                                     :options="centers"
                                     placeholder="Trung tâm"
                                     label="name"
                                     track-by="name"
                                     :hide-selected="true"
                                     @input="handleQueryCenter($event)"
                        ></multiselect>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Chi nhánh</label>
                        <multiselect v-model="cacheBranch" :options="branches"
                                     placeholder="Chi nhánh"
                                     label="name"
                                     track-by="name"
                                     :hide-selected="true"
                                     @input="handleQueryBranch($event)"
                        ></multiselect>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Chọn thời gian</label>
                        <date-picker
                                v-model="query.date_range"
                                type="date"
                                range
                                format="DD-MM-YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Select datetime range"
                                @input="datesSet($event)"
                        ></date-picker>
                    </div>
                </div>
            </div>
            <div class="example mb-10">
                <FullCalendar ref="calendar"
                              :options="calendarOptions"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import Multiselect from 'vue-multiselect'
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
    import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
    import {GET_ROOM_EMPTY} from "../../../core/services/store/course/classes.module";

    const _ = require('lodash');

    // Fullcalendar
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import timeGridPlugin from '@fullcalendar/timegrid'
    import bootstrapPlugin from '@fullcalendar/bootstrap';

    // Date picker
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import moment from 'moment';

    export default {
        components: {
            Multiselect,
            FullCalendar,
            DatePicker,
            moment
        },
        name: "RoomEmpty",
        data() {
            return {
                centers: [],
                branches: [],
                branches_dependent: [],
                query: {
                    center_id: '',
                    branch_id: '',
                    date_range: ''
                },
                // cache
                cacheCenter: [],
                cacheBranch: [],
                calendarOptions: {
                    plugins: [
                        dayGridPlugin,
                        timeGridPlugin,
                        bootstrapPlugin
                    ],
                    themeSystem: 'bootstrap',
                    headerToolbar: {
                        right: 'prev,next',
                        // right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    },
                    initialView: 'timeGrid',
                    visibleRange: {
                        start: moment().startOf('isoweek').format(),
                        end: moment().endOf('isoweek').format()
                    },
                    initialEvents: [],
                    editable: true,
                    selectable: true,
                    selectMirror: true,
                    dayMaxEvents: true,
                    weekends: true,
                    locale: 'VI',
                    allDaySlot: false,
                    events: []
                },
                currentEvents: []
            }
        },
        async mounted() {
            await this.getCenters();
            await this.pullParamsUrl();
            await this.setRangeDateDefault();
            await this.callApiGetList();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý phòng học", route: 'profile-1'},
                {title: "Danh sách phòng học"}
            ]);

        },
        methods: {
            async getCenters() {
                this.$store.dispatch(GET_CENTERS, {
                    limit: 10000,
                    status: 1
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data
                        this.addFirstSelectExample(this.centers, '__Chọn__')
                        this.setCacheTheLoad()
                        this.getBranches();
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getBranches() {
                this.$store.dispatch(GET_BRANCHES, {
                    limit: 10000,
                    center_id: this.query.center_id
                }).then((res) => {
                    if (!res.error) {
                        this.branches = res.data.data
                        this.addFirstSelectExample(this.branches, '__Chọn__')
                        this.setCacheTheLoad()
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            handleQueryCenter($event) {
                this.cacheBranch = [];
                this.branches = [];
                this.query.branch_id = '';
                if ($event.id != undefined) {
                    let id = $event.id
                    this.query.center_id = id
                } else {
                    this.query.center_id = '';
                }
                this.getBranches()
                this.pushParamsUrl()
                this.callApiGetList()
            },
            handleQueryBranch($event) {
                if ($event != null) {
                    this.query.branch_id = $event.id
                    this.pushParamsUrl()
                    this.callApiGetList()
                }
            },
            datesSet($event) {
                if ($event[0] == null && $event[1] == null) {
                    this.query.date_range = []
                    this.setRangeDateDefault()
                    this.pushParamsUrl()
                } else {
                    this.$refs.calendar.options.visibleRange = {
                        start: moment($event[0]).format(),
                        end: moment($event[1]).add('days', 1).format()
                    };
                }
                this.pushParamsUrl()
                this.callApiGetList()

            },
            pullParamsUrl() {
                this.query.branch_id = this.$route.query.branch_id;
                this.query.center_id = this.$route.query.center_id;
                this.query.date_range = this.$route.query.date_range;
            },
            pushParamsUrl() {
                this.$router.push({
                    name: 'room-empty',
                    query: {
                        center_id: this.query.center_id,
                        branch_id: this.query.branch_id,
                        date_range: this.query.date_range
                    }
                })
            },
            callApiGetList() {
                this.$store.dispatch(GET_ROOM_EMPTY, {
                    center_id: this.query.center_id,
                    branch_id: this.query.branch_id,
                    date_range: this.query.date_range,
                }).then((res) => {
                    this.calendarOptions.events = res.data;
                }).catch((e) => {
                    this.$refs.calendar.options.visibleRange = {
                        start: moment().startOf('isoweek').format(),
                        end: moment().endOf('isoweek').format()
                    };
                    if (e.data.error) {
                        this.query.date_range = []
                        this.pushParamsUrl()
                        this.$bvToast.toast(e.data.message, {
                            title: 'Tạo mới',
                            variant: 'danger',
                            solid: true
                        });
                    }
                });
            },
            setRangeDateDefault() {
                let date_range = this.$route.query.date_range;
                if (date_range == undefined) {
                    this.$refs.calendar.options.visibleRange = {
                        start: moment().startOf('isoweek').format(),
                        end: moment().endOf('isoweek').format()
                    };
                } else {
                    if (date_range[0] == '' || date_range[1] == '') {
                        this.$refs.calendar.options.visibleRange = {
                            start: moment().startOf('isoweek').format(),
                            end: moment().endOf('isoweek').format()
                        };
                    } else {
                        this.$refs.calendar.options.visibleRange = {
                            start: moment(date_range[0]).format(),
                            end: moment(date_range[1]).add('days', 1).format()
                        };
                    }
                }
            },
            addFirstSelectExample(obj, text) {
                obj.unshift({
                    name: text
                })
            },
            setCacheTheLoad() {
                if (this.query.center_id != '') {
                    let oldParamCenter = this.centers.filter((value) => {
                        if (parseInt(this.query.center_id) == value.id) {
                            return value
                        }
                    })
                    this.cacheCenter = oldParamCenter
                }
                if (this.query.branch_id != '') {
                    let oldParamBranch = this.branches.filter((value) => {
                        if (parseInt(this.query.branch_id) == value.id) {
                            return value
                        }
                    })
                    this.cacheBranch = oldParamBranch
                }
            },
        }
    }
</script>
<style scoped>

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

