<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="event_edit" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 v-if="checkEdit && editWorkshop" class="card-label">Sửa Workshop</h3>
            <h3 v-if="checkEdit && !editWorkshop" class="card-label">Sửa Club</h3>
            <h3 v-if="!checkEdit" class="card-label">Tạo Workshop & Club</h3>
          </div>
          <div class="card-toolbar">
            <router-link
                    class="font-weight-bold font-size-3 btn btn-secondary"
                    :to="{ name: 'workshop-club-list' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Loại <span class="text-danger">*</span></label>
                <ValidationProvider vid="type" name="Loại" rules="required" v-slot="{ errors,classes }">
                  <el-select v-model="data.type" filterable class="w-100" placeholder="Chọn loại" @change="handleTypeChange($event)" :disabled="checkEdit">
                    <el-option
                            v-for="item in types"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id"
                            :class="classes"
                    >
                      <span style="float: left">{{ item.value }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="type" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Tên <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Tên" rules="required" v-slot="{ errors,classes }">
                  <input type="text" ref="name" name="name" class="form-control" v-model="data.name" placeholder="Text dài tối đa 40 kí tự" maxlength="40" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Nội dung <span class="text-danger">*</span></label>
                <ValidationProvider vid="title" name="Nội dung" rules="required" v-slot="{ errors,classes }">
                  <vue-editor id="editor-content" :editorOptions="editorSettings" useCustomImageHandler
                              v-model="data.content" :class="classes"
                              @image-added="handleImageAdded"></vue-editor>
                  <div class="fv-plugins-message-container">
                    <div data-field="title" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Thời gian diễn ra <span class="text-danger">*</span></label>
                <ValidationProvider vid="event_time" name="Thời gian diễn ra" rules="required"
                                    v-slot="{ errors,classes }">
                  <el-date-picker
                          :editable="false"
                          format="yyyy-MM-dd HH:mm"
                          value-format="yyyy-MM-dd HH:mm"
                          v-model="data.event_time"
                          type="datetime"
                          placeholder="Thời gian diễn ra">
                  </el-date-picker>
                  <div class="fv-plugins-message-container">
                    <div data-field="event_time" data-validator="notEmpty"
                         class="fv-help-block">{{errors[0]}}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Hình thức <span class="text-danger">*</span></label>
                <ValidationProvider vid="hinh_thuc" name="Hình thức" rules="required" v-slot="{ errors,classes }">
                  <el-radio-group v-model="data.hinh_thuc" @change="handleHinhThucChange($event)">
                    <el-radio :label="0">Offline</el-radio>
                    <el-radio :label="1">Online</el-radio>
                  </el-radio-group>
                  <div class="fv-plugins-message-container">
                    <div data-field="hinh_thuc" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row" v-if="showHinhThuc">
            <div class="col-md-6">
              <div class="form-group">
                <label>Zoom ID</label>
                <input type="text" ref="zoom-id" name="zoom-id" class="form-control" v-model="data.zoom_id" placeholder="Zoom ID">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Zoom Password</label>
                <input type="text" ref="zoom-password" name="zoom-password" class="form-control" v-model="data.zoom_password" placeholder="Zoom Password"></input>
              </div>
            </div>
          </div>
          <div class="row" v-if="showClassrooms">
            <div class="col-md-12">
              <div class="form-group">
                <label>Đối tượng áp dụng <span class="text-danger">*</span></label>
                <ValidationProvider vid="courses" name="Đối tượng áp dụng" rules="required" v-slot="{ errors,classes }">
                  <el-select v-model="data.classrooms" filterable class="w-100" placeholder="Chọn lớp học" multiple>
                    <el-option
                            v-for="item in classrooms"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            :class="classes"
                    >
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="courses" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button type="submit" class="btn btn-success mr-2">
              Cập nhật
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {Money} from 'v-money'

    //editor
    import ApiService from "@/core/services/api.service";
    import {VueEditor, Quill} from 'vue2-editor'
    import ImageResize from 'quill-image-resize-vue';
    import {ImageDrop} from 'quill-image-drop-module';
    import {
        CREATE_WORKSHOP_AND_CLUB,
        GET_WORKSHOP_AND_CLUB_BY_ID, UPDATE_WORKSHOP_AND_CLUB
    } from "../../../core/services/store/workshopAndClub/workshopAndClub.module";
    import {GET_CLASSES} from "../../../core/services/store/course/classes.module";
    Quill.register("modules/imageDrop", ImageDrop);
    Quill.register("modules/imageResize", ImageResize);
    export default {
        components: {
            DatePicker,
            VueEditor,
            Money
        },
        name: "WorkshopAndClubEdit",
        data() {
            return {
                types: [
                    { id: 0, value: 'Workshop'},
                    { id: 1, value: 'Club'}
                ],
                data: {
                    type: '',
                    name: '',
                    content: '',
                    event_time: '',
                    hinh_thuc: '',
                    classrooms: []
                },
                editorSettings: {
                    modules: {
                        imageDrop: true,
                        imageResize: {}
                    }
                },
                money: {
                    decimal: ',',
                    thousands: ',',
                    prefix: '',
                    suffix: ' VNĐ',
                    precision: 0,
                    masked: false
                },
                classrooms: [],
                checkCreate: false,
                checkEdit: false,
                editWorkshop: false,
                showHinhThuc: false,
                showClassrooms: false,
            }
        },
        mounted() {
            this.getAllClassrooms();
            if (this.$route.params.id !== undefined) {
                this.checkEdit = true;
                this.getWorkshopOrClubById();
            } else {
                this.$store.dispatch(SET_BREADCRUMB, [
                    {title: "Workshop & Club", route: 'workshop-club-list'},
                    {title: "Tạo Workshop & Club"}
                ]);
            }
        },
        computed: {},
        methods: {
            getWorkshopOrClubById() {
                let id = this.$route.params.id;
                this.$store.dispatch(GET_WORKSHOP_AND_CLUB_BY_ID, id).then((res) => {
                    if (!res.error) {
                        this.data = res.data
                        if (this.data.type == 1) {
                            this.showClassrooms = true
                            this.$store.dispatch(SET_BREADCRUMB, [
                                {title: "Workshop & Club", route: "workshop-club-list"},
                                {title: "Sửa Club", icon: 'fas fa-users'}
                            ]);
                        } else {
                            this.showClassrooms = false
                            this.editWorkshop = true
                            this.$store.dispatch(SET_BREADCRUMB, [
                                {title: "Workshop & Club", route: "workshop-club-list"},
                                {title: "Sửa Workshop", icon: 'fas fa-users'}
                            ]);
                        }
                        if (this.data.hinh_thuc == 1) {
                            this.showHinhThuc = true
                        } else {
                            this.showHinhThuc = false
                        }
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getAllClassrooms() {
                this.$store.dispatch(GET_CLASSES, {
                    limit: 10000,
                    type: 'official',
                    workshopAndClub: 1
                }).then((res) => {
                    if (!res.error) {
                        this.classrooms = res.data.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
                var formData = new FormData();
                formData.append("image", file);

                ApiService.post('upload-image', formData)
                    .then(({data}) => {
                        let url = data.data; // Get url from response
                        Editor.insertEmbed(cursorLocation, "image", url);
                        resetUploader();
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },
            onSubmit() {
                if (this.checkEdit) {
                    this.$store.dispatch(UPDATE_WORKSHOP_AND_CLUB, this.data).then((res) => {
                        if (res.error) {
                            this.$bvToast.toast(res.message, {
                                title: 'Cập nhật',
                                variant: 'danger',
                                solid: true
                            });
                        } else {
                            this.$router.push({name: "workshop-club-list"}).then(() => {
                                this.$bvToast.toast(res.message, {
                                    title: 'Cập nhật',
                                    variant: 'success',
                                    solid: true
                                });
                            })
                        }
                    }).catch((e) => {
                        if (e.data.data.message_validate_form) {
                            this.$refs.form.setErrors(e.data.data.message_validate_form);
                        }
                    });
                } else {
                    this.createWorkshopAndClub();
                }
            },
            createWorkshopAndClub() {
                this.$store.dispatch(CREATE_WORKSHOP_AND_CLUB, this.data).then((res) => {
                    if (res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Tạo mới',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.$router.push({name: "workshop-club-list"}).then(() => {
                            this.$bvToast.toast(res.message, {
                                title: 'Tạo mới',
                                variant: 'success',
                                solid: true
                            });
                        })
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                });
            },
            handleTypeChange(event) {
                if (event == 1) {
                    this.showClassrooms = true
                } else {
                    this.showClassrooms = false
                }
            },
            handleHinhThucChange(event) {
                if (event == 1) {
                    this.showHinhThuc = true
                } else {
                    this.showHinhThuc = false
                }
            },
        }
    }
</script>
<style scoped>
  .mx-datepicker {
    width: 100% !important;
  }
  .el-date-editor {
    width: 100% !important;
  }
  .el-radio-group {
    width: 100% !important;
  }
</style>
