<template>
  <b-modal
    @hide="onHide"
    size="lg"
    :centered="true"
    :scrollable="true"
    ref="modal-cv-detail"
    hide-footer
    title="Xem chi tiết ứng viên"
    class="modal-tester"
  >
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <div class="d-block">
        <form class="form" @submit.prevent="handleSubmit(saveProgram)">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Họ và tên</label>
                <el-input
                  type="text"
                  ref="name"
                  name="name"
                  placeholder="Họ và tên"
                  v-model="cvEach.name"
                  :readonly="true"
                />
              </div>
              <div class="form-group">
                <label>Số điện thoại</label>
                <el-input
                  type="text"
                  ref="phone"
                  name="phone"
                  placeholder="Số điện thoại"
                  v-model="cvEach.phone"
                  :readonly="true"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Email</label>
                <el-input
                  type="text"
                  ref="email"
                  name="email"
                  placeholder="Email"
                  v-model="cvEach.email"
                  :readonly="true"
                />
              </div>
              <div class="form-group">
                <label>Ngày gửi CV</label>
                <el-input
                  type="text"
                  ref="time-cv"
                  name="time-cv"
                  placeholder="Ngày gửi CV"
                  :value="cvEach.created_at | formatDateTimeAsia"
                  :readonly="true"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Nội dung</label>
                <el-input
                  type="textarea"
                  maxlength="3000"
                  show-word-limit
                  placeholder="Nội dung"
                  class=""
                  :rows="10"
                  v-model="cvEach.message"
                  :readonly="true"
                >
                </el-input>
              </div>
            </div>
          </div>
          <footer class="modal-footer pt-5 pb-0">
            <button
              type="button"
              class="btn btn-secondary"
              @click="hideModal"
              :disabled="isDisable"
            >
              Đóng
            </button>
          </footer>
        </form>
      </div>
    </ValidationObserver>
  </b-modal>
</template>

<script>
export default {
  name: "RecruitmentDetail",
  props: {
    showModal: { default: false },
    cvEach: { default: {} },
  },
  data() {
    return {
      name: "",
      isDisable: false,
    };
  },
  watch: {
    showModal() {
      if (this.showModal) {
        this.$refs["modal-cv-detail"].show();
      }
    },
  },
  methods: {
    onHide() {
      this.$emit("clicked", true);
    },
    hideModal() {
      this.$refs["modal-cv-detail"].hide();
    },
    toggleModal() {
      // when the modal has hidden
      this.$refs["modal-cv-detail"].toggle("#toggle-btn");
    },
  },
};
</script>

<style scoped></style>
