<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="event_edit" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 v-if="checkEdit" class="card-label">Sửa danh mục</h3>
            <h3 v-if="!checkEdit" class="card-label">Tạo danh mục</h3>
          </div>
          <div class="card-toolbar">
            <router-link
                    class="font-weight-bold font-size-3 btn btn-secondary"
                    :to="{ name: 'category-list' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Tên <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Tên" rules="required" v-slot="{ errors,classes }">
                  <input type="text" ref="name" name="name" class="form-control" v-model="data.name" placeholder="Tên danh mục" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Phòng ban<span class="text-danger"> *</span></label>
                <ValidationProvider vid="departments" name="Phòng ban" rules="required" v-slot="{ errors,classes }">
                  <el-select v-model="data.department_id" filterable class="w-100" placeholder="Chọn phòng ban">
                    <el-option
                            v-for="item in departments"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            :class="classes"
                    >
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="department_id" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <label class="ml-4">Thời gian nhập giải pháp (thời lượng): </label>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Giờ<span class="text-danger"> *</span></label>
                <ValidationProvider vid="hours" name="Giờ" rules="required"
                                    v-slot="{ errors,classes }">
                  <input type="number" min="0" ref="hours" name="hours" class="form-control" v-model="data.hours"
                         placeholder="Giờ" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="hours" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Phút<span class="text-danger"> *</span></label>
                <ValidationProvider vid="minutes" name="Phút" rules="required"
                                    v-slot="{ errors,classes }">
                  <input type="number" min="0" ref="minutes" name="minutes" class="form-control" v-model="data.minutes"
                         placeholder="Phút" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="minutes" data-validator="notEmpty" class="fv-help-block">{{errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
          </div>
        </div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button type="submit" class="btn btn-success mr-2">
              {{ checkEdit ? 'Cập nhật' : 'Lưu' }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {
        CREATE_CATEGORY,
        GET_CATEGORY_BY_ID,
        GET_DEPARTMENT, UPDATE_CATEGORY
    } from "../../../core/services/store/ticket-category/category.module";
    import {TICKET_CATEGORY} from '../../../core/option/ticketCategory';
    export default {
        components: {},
        name: "CategoryForm",
        data() {
            return {
                data: {
                    department_id: '',
                    name: '',
                    hours: '',
                    minutes: '',
                    priority: ''
                },
                checkCreate: false,
                checkEdit: false,
                departments: [],
                TICKET_CATEGORY
            }
        },
        mounted() {
            this.getListDepartment();
            if (this.$route.params.id !== undefined) {
                this.checkEdit = true;
                this.getTicketCategoryById();
            } else {
                this.$store.dispatch(SET_BREADCRUMB, [
                    {title: "Danh mục", route: 'category-list'},
                    {title: "Tạo danh mục"}
                ]);
            }
        },
        computed: {},
        methods: {
            getListDepartment() {
                this.$store.dispatch(GET_DEPARTMENT).then((res) => {
                    if (!res.error) {
                        this.departments = res.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getTicketCategoryById() {
                let id = this.$route.params.id;
                this.$store.dispatch(GET_CATEGORY_BY_ID, id).then((res) => {
                    if (!res.error) {
                        this.data = res.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            onSubmit() {
                if (this.checkEdit) {
                    this.$store.dispatch(UPDATE_CATEGORY, this.data).then((res) => {
                        if (res.error) {
                            this.$bvToast.toast(res.message, {
                                title: 'Cập nhật',
                                variant: 'danger',
                                solid: true
                            });
                        } else {
                            this.$router.push({name: "category-list"}).then(() => {
                                this.$bvToast.toast(res.message, {
                                    title: 'Cập nhật',
                                    variant: 'success',
                                    solid: true
                                });
                            })
                        }
                    }).catch((e) => {
                        if (e.data.data.message_validate_form) {
                            this.$refs.form.setErrors(e.data.data.message_validate_form);
                        }
                    });
                } else {
                    this.createTicketCategory();
                }
            },
            createTicketCategory() {
                this.$store.dispatch(CREATE_CATEGORY, this.data).then((res) => {
                    if (res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Tạo mới',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.$router.push({name: "category-list"}).then(() => {
                            this.$bvToast.toast(res.message, {
                                title: 'Tạo mới',
                                variant: 'success',
                                solid: true
                            });
                        })
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                });
            },
        }
    }
</script>
<style scoped>
  .mx-datepicker {
    width: 100% !important;
  }
  .el-date-editor {
    width: 100% !important;
  }
  .el-radio-group {
    width: 100% !important;
  }
</style>
