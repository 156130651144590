<template>
  <div style="display: flex; align-items: center; justify-content: space-between; padding: 10px 20px">
    <span style="font-size: 18px; font-weight: 500">Quản lý KPI nhân sự</span>
    <el-button type="primary" :disabled="is_export_excel" style="color: white" @click="exportExcel">Xuất excel</el-button>
  </div>
</template>

<script>

import {exportExcel} from "@/core/filters";
import {EXPORT_EXCEL_MANAGEMENT_KPI} from "@/core/services/store/backoffice/mangementKpi";
import {ExportExcelModel} from "@/view/pages/backoffice/managements/model/ManagementKpiModel";

export default {
  name: 'HeaderListManagementList',
  data() {
    return {
      is_export_excel: false,
    }
  },
  methods: {
    exportExcel() {
      this.is_export_excel = true
      const HEADER_ROW_EXPORT_LIST_MANAGEMENT_KPI = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'userId',
          value: 'Mã nhân sự',
        },
        {
          key: 'name',
          value: 'Họ và tên',
        },
        {
          key: 'department',
          value: 'Phòng ban',
        },
        {
          key: 'total',
          value: 'Tổng % KPI tháng',
        },
        {
          key: 'point',
          value: 'Điểm tích họp tuần',
        },
      ]
      const payload = this.$route.query
      this.$store.dispatch(EXPORT_EXCEL_MANAGEMENT_KPI, payload).then((res) => {
        this.is_export_excel = false
        if (res.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        } else {
          const data = res.data.map(item => {
            return new ExportExcelModel(item?.user_id + 100000, item?.user?.name, item?.department?.name, item?.total_point_kra, item?.total_point_meeting)
          })
          exportExcel(HEADER_ROW_EXPORT_LIST_MANAGEMENT_KPI, data, "Danh sách chấm điểm KPI và họp tuần.xlsx", true)
        }
      });
    },
  },
}
</script>