<template>
  <div class="card card-custom gutter-b h-100">
    <div class="card-header">
      <div class="card-title">Chi tiết giải đấu</div>
    </div>
    <div class="card-body">
      <div class="row h-100">
        <!--        body1-->
        <div class="col-sm-12 col-md-6">
          <div class="h-100">
            <div class=" ">
              <div class="card p-10">
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                  <form class="form" @submit.prevent>
                    <div class="d-flex justify-content-between">
                      <h1 style="font-size: 14px" class="font-weight-bolder">
                        Thông tin giải đấu
                      </h1>
                      <button
                          v-if="!statusEditInformation"
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="statusEditInformation = true"
                          :disabled="checkEndTournament"
                      >
                        <i class="bi bi-pencil-square"></i>
                        Sửa thông tin
                      </button>
                      <div v-else>
                        <button
                            type="button"
                            class="btn btn-secondary font-weight-bold btn-sm mr-5"
                            @click="cancelSaveInformation"
                        >
                          Hủy
                        </button>
                        <button
                            class="btn btn-success font-weight-bold btn-sm"
                            @click="handleSubmit(updateTournamentAPI)"
                        >
                          Lưu
                        </button>
                      </div>
                    </div>
                    <div>
                      <div class="pb-6 row">
                        <div class="col-md-6 col-sm-12 mb-3">
                          <label
                          >Tên giải đấu
                            <span class="text-danger" style="font-size: 20px"
                            >*</span
                            ></label
                          >
                          <ValidationProvider
                              name="Tên giải đấu"
                              rules="required"
                              v-slot="{ errors, classes }"
                              vid=""
                          >
                            <el-input
                                placeholder="Nhập tên giải đấu"
                                v-model="dataAddTournament.nameTournament"
                                :disabled="!statusEditInformation"
                            />
                            <div class="fv-plugins-message-container">
                              <div
                                  data-field="name"
                                  data-validator="notEmpty"
                                  class="fv-help-block"
                              >
                                {{ errors[0] }}
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6 col-sm-12 mb-3">
                          <label
                          >Trung tâm áp dụng
                            <span class="text-danger" style="font-size: 20px"
                            >*</span
                            ></label
                          >
                          <ValidationProvider
                              name="Trung tâm áp dụng"
                              rules="required"
                              v-slot="{ errors, classes }"
                              vid=""
                          >
                            <el-select
                                class="d-block"
                                placeholder="Nhập trung tâm áp dụng"
                                size="large"
                                filterable
                                v-model="dataAddTournament.centerApply"
                                :disabled="!statusEditInformation"
                                multiple
                                @change="handleChangeCenter()"
                            >
                              <el-option
                                  v-for="item in listCenter"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                              />
                            </el-select>
                            <div class="fv-plugins-message-container">
                              <div
                                  data-field="name"
                                  data-validator="notEmpty"
                                  class="fv-help-block"
                              >
                                {{ errors[0] }}
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6 col-sm-12 mb-3">
                          <label>Khu vực áp dụng </label>
                          <el-select
                              class="d-block"
                              placeholder="Nhập khu vực áp dụng"
                              size="large"
                              filterable
                              multiple
                              v-model="dataAddTournament.provincesApply"
                              :disabled="!statusEditInformation"
                              @change="handleChangeProvince()"
                          >
                            <el-option
                                v-for="(item, index) in provinces"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            />
                          </el-select>
                        </div>
                        <div class="col-md-6 col-sm-12 mb-3">
                          <label>Khóa học áp dụng </label>
                          <el-select
                              class="d-block"
                              placeholder="Nhập khóa học áp dụng"
                              size="large"
                              multiple
                              filterable
                              v-model="dataAddTournament.courseApply"
                              :disabled="!statusEditInformation"
                              @change="handleChangeCourse()"
                          >
                            <el-option
                                v-for="item in listCourse"
                                :key="item.id"
                                :label="`${item.name} ${item.center_name?`( ${item.center_name} )`:''}`"
                                :value="item.id"
                            />
                          </el-select>
                        </div>
                        <div class="col-md-6 col-sm-12 mb-3">
                          <label
                          >Số lượng cặp đấu trong một trận
                            <span class="text-danger" style="font-size: 20px"
                            >*</span
                            ></label
                          >
                          <ValidationProvider
                              name="Số lượng cặp đấu trong một trận"
                              rules="required"
                              v-slot="{ errors, classes }"
                              vid=""
                          >
                            <el-input-number
                                :min="1"
                                :max="10"
                                class="w-100"
                                placeholder="Nhập số lượng cặp đấu"
                                v-model="dataAddTournament.totalCompetition"
                                :disabled="!statusEditInformation"
                                controls-position="right"
                            />
                            <div class="fv-plugins-message-container">
                              <div
                                  data-field="name"
                                  data-validator="notEmpty"
                                  class="fv-help-block"
                              >
                                {{ errors[0] }}
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6 col-sm-12 mb-3">
                          <label class="d-block"
                          >Thời gian bắt đầu
                            <span class="text-danger" style="font-size: 20px"
                            >*</span
                            ></label
                          >
                          <ValidationProvider
                              name="Thời gian bắt đầu"
                              rules="required"
                              v-slot="{ errors, classes }"
                              vid="start_schedule"
                          >
                            <date-picker
                                type="datetime"
                                placeholder="Thời gian bắt đầu"
                                v-model="dataAddTournament.timeStart"
                                format="DD-MM-YYYY HH:mm"
                                :disabled-date="disabledBeforeStartDate"
                                :disabled="!statusEditInformation"
                                @change="changeStartTime()"
                                :disabled-time="disableHourStart"
                            >
                            </date-picker>
                            <div class="fv-plugins-message-container">
                              <div
                                  data-field="name"
                                  data-validator="notEmpty"
                                  class="fv-help-block"
                              >
                                {{ errors[0] }}
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6 col-sm-12 mb-3">
                          <label>Thời gian kết thúc<span
                              class="text-danger"
                              style="font-size: 20px"
                          >*</span></label>
                          <ValidationProvider
                              name="Thời gian kết thúc"
                              rules="required"
                              v-slot="{ errors, classes }"
                              vid="end_schedule"
                          >
                            <date-picker
                                type="datetime"
                                placeholder="Thời gian kết thúc"
                                format="DD-MM-YYYY HH:mm"
                                :disabled-date="disabledBeforeEndDate"
                                v-model="dataAddTournament.timeEnd"
                                :disabled="!statusEditInformation"
                                :disabled-time="disableHourEnd"
                            >
                            </date-picker>
                            <div class="fv-plugins-message-container">
                              <div
                                  data-field="name"
                                  data-validator="notEmpty"
                                  class="fv-help-block"
                              >
                                {{ errors[0] }}
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-12">
                          <label>Nội dung truyền thông<span
                              class="text-danger"
                              style="font-size: 20px"
                          >*</span></label>
                          <ValidationProvider
                              name="Nội dung truyền thông"
                              rules="required"
                              v-slot="{ errors, classes }"
                              vid="content_media"
                          >
                            <ckeditor :disabled="!statusEditInformation" :editor="editor" v-model="dataAddTournament.content_media"></ckeditor>
                            <div class="fv-plugins-message-container">
                              <div
                                  data-field="name"
                                  data-validator="notEmpty"
                                  class="fv-help-block"
                              >
                                {{ errors[0] }}
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div
                          class="col-md-6 col-sm-12"
                          style="width: fit-content"
                      >
                        <h1 style="font-size: 13px">
                          Ảnh banner giải đấu<span
                            class="text-danger"
                            style="font-size: 20px"
                        >*</span
                        >
                        </h1>
                        <input
                            class="file-input__input"
                            id="file-input"
                            type="file"
                            name="myImage"
                            accept="image/png, image/gif, image/jpeg"
                            @change="addPhotoBanner"
                        />
                        <div v-if="dataPhotoBanner == null">
                          <label class="file-input__label" for="file-input">
                            <span
                            ><i
                                class="flaticon-upload mr-2"
                                style="color: white"
                            ></i
                            >Tải lên</span
                            ></label
                          >
                          <p class="text-dark">
                            Kích thước ảnh tiêu chuẩn là 340 x n pixels. Với n là chiều cao tùy theo mong muốn.
                          </p>
                        </div>
                        <div class="d-flex" v-else>
                          <div
                              class=""
                              @mouseover="upHereBanner = true"
                              @mouseleave="upHereBanner = false"
                              style="
                              width: 100px;
                              height: 100px;
                              background-size: 100px 100px;
                            "
                              :style="{
                              'background-image':
                                'url(' + dataPhotoBanner + ')',
                            }"
                          >
                            <div
                                v-if="upHereBanner"
                                class="w-100 h-100 d-flex justify-content-center align-items-center"
                                style="background-color: rgba(0, 0, 0, 0.5)"
                            >
                              <i
                                  class="flaticon2-magnifier-tool"
                                  style="cursor: pointer"
                                  @click="openPhotoBanner = true"
                              ></i>
                              <i
                                  class="flaticon-delete ml-3"
                                  @click="removePhotoBanner()"
                                  style="cursor: pointer"
                                  v-if="statusEditInformation"
                              ></i>
                            </div>
                            <el-dialog
                                :visible.sync="openPhotoBanner"
                                width="30%"
                                center
                            >
                              <img
                                  :src="dataPhotoBanner"
                                  style="width: 100%; height: auto"
                              />
                              <span slot="footer" class="dialog-footer">
                                <el-button @click="openPhotoBanner = false"
                                >Đóng</el-button
                                ></span
                              >
                            </el-dialog>
                          </div>
                        </div>
                        <div
                            class="fv-plugins-message-container"
                            v-if="dataPhotoBanner == null && errorBanner"
                        >
                          <div>
                            <p class="text-danger">
                              Chưa có file nào được chọn
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <h1 style="font-size: 13px">
                          Ảnh thông tin giải đấu<span
                            class="text-danger"
                            style="font-size: 20px"
                        >*</span
                        >
                        </h1>
                        <input
                            class="file-input__input"
                            id="file-input-1"
                            type="file"
                            @change="addPhotoNews"
                            accept="image/png, image/gif, image/jpeg"
                        />
                        <div v-if="dataPhotoNews == null">
                          <label class="file-input__label" for="file-input-1">
                            <span
                            ><i
                                class="flaticon-upload mr-2"
                                style="color: white"
                            ></i
                            >Tải lên</span
                            ></label
                          >
                          <p class="text-danger">
                            Kích thước ảnh tiêu chuẩn là 340 x n pixels. Với n là chiều cao tùy theo mong muốn.
                          </p>
                        </div>
                        <div class="d-flex" v-else>
                          <div
                              class=""
                              @mouseover="upHereNews = true"
                              @mouseleave="upHereNews = false"
                              style="
                              width: 100px;
                              height: 100px;
                              background-size: 100px 100px;
                            "
                              :style="{
                              'background-image': 'url(' + dataPhotoNews + ')',
                            }"
                          >
                            <div
                                v-if="upHereNews"
                                class="w-100 h-100 d-flex justify-content-center align-items-center"
                                style="background-color: rgba(0, 0, 0, 0.5)"
                            >
                              <i
                                  class="flaticon2-magnifier-tool"
                                  style="cursor: pointer"
                                  @click="openPhotoNews = true"
                              ></i>
                              <i
                                  class="flaticon-delete ml-3"
                                  v-if="statusEditInformation"
                                  @click="removePhotoNews()"
                                  style="cursor: pointer"
                              ></i>
                            </div>
                            <el-dialog
                                :visible.sync="openPhotoNews"
                                width="30%"
                                center
                            >
                              <img :src="dataPhotoNews" style="width: 100%"/>
                              <span slot="footer" class="dialog-footer">
                                <el-button @click="openPhotoNews = false"
                                >Đóng</el-button
                                ></span
                              >
                            </el-dialog>
                          </div>
                        </div>
                        <div
                            class="fv-plugins-message-container"
                            v-if="dataPhotoNews == null && errorNews"
                        >
                          <div>
                            <p class="text-danger">
                              Chưa có file nào được chọn
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
              <list-news></list-news>
            </div>
          </div>
        </div>
        <!--        body2-->
        <ExamTournament></ExamTournament>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ModalAddNew from "@/view/pages/Tournaments/Component/ModalAddNew";
import ModalImportExam from "@/view/pages/Tournaments/Component/ModalImportExam";
import {GET_LIST_CENTERS} from "@/core/services/store/center/centers.module";
import {GET_PROVINCES} from "@/core/services/store/public/provinces.module";
import {GET_COURSES_LIST} from "@/core/services/store/course/course.module";
import ListNews from "@/view/pages/Tournaments/Component/listNews";
import ExamTournament from "@/view/pages/Tournaments/Component/ExamTournament";
import {
  GET_DETAIL_TOURNAMENT,
  UPDATE_TOURNAMENT,
} from "@/core/services/store/tournament/tournament.module";
import moment from "moment-timezone";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {ECOACH_ID, IELTS_MENTOR_ID} from "@/core/option/typeCenterBill";
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";

export default {
  name: "TournamentDetails",
  components: {
    ExamTournament,
    ListNews,
    ModalImportExam,
    ModalAddNew,
    DatePicker,
  },
  data() {
    return {
      dataPhotoBanner: null,
      dataPhotoNews: null,
      openPhotoBanner: false,
      upHereBanner: false,
      openPhotoNews: false,
      upHereNews: false,
      errorBanner: false,
      errorNews: false,
      statusEditInformation: false,
      dataAddTournament: {
        nameTournament: null,
        centerApply: null,
        provincesApply: null,
        courseApply: null,
        timeStart: null,
        timeEnd: null,
        imageBanner: null,
        imageNews: null,
        totalCompetition: null,
        content_media: null,
      },
      listCenter: [],
      provinces: [],
      listCourse: [],
      editor: CustomEditor
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách giải đấu", route: "tournamentList"},
      {title: "Chi tiết giải đấu"},
    ]);
    this.getInformationTournament().then(()=>{
      this.getCourse();
    });

  },
  methods: {
    getInformationTournament() {
      let id = this.$route.params.id;
     return  this.$store.dispatch(GET_DETAIL_TOURNAMENT, id).then((data) => {
        if (data?.data?.img_banner == null || data.data.img_banner.length == 0) {
          return;
        }
        fetch(`${data?.data?.img_banner[0]?.url}`)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File(
                  [blob],
                  `${data?.data?.img_banner[0]?.file_name}`,
                  {
                    type: `${data?.data?.img_banner[0]?.mime_type}`,
                  }
              );
              this.dataAddTournament.imageBanner = file;
            });
        fetch(`${data?.data?.img_info[0]?.url}`)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File(
                  [blob],
                  `${data?.data?.img_info[0]?.file_name}`,
                  {
                    type: `${data?.data?.img_info[0]?.mime_type}`,
                  }
              );
              this.dataAddTournament.imageNews = file;
            });
        this.dataAddTournament = {
          nameTournament: data?.data?.name,
          centerApply:
              data?.data?.center_ids.length == 0 ? [0] : data?.data?.center_ids,
          provincesApply:
              data?.data?.province_ids.length == 0
                  ? [0]
                  : data?.data?.province_ids,
          courseApply:
              data?.data?.course_ids.length == 0 ? [0] : data?.data?.course_ids,
          timeStart: new Date(data?.data?.start_date),
          timeEnd: new Date(data?.data?.end_date),
          totalCompetition: data?.data?.total_competition,
          content_media: data?.data?.content_media
        };
        this.dataPhotoBanner = data?.data?.img_banner[0]?.url;
        this.dataPhotoNews = data?.data?.img_info[0]?.url;
        this.getCenters()
      });
    },
    getCenters(flag = true) {
      let ids
      if (flag) {
        ids = [ECOACH_ID, IELTS_MENTOR_ID]
      } else {
        ids = this.dataAddTournament.centerApply[0] == 0 ? [ECOACH_ID, IELTS_MENTOR_ID] : this.dataAddTournament.centerApply
      }
      this.$store
          .dispatch(GET_LIST_CENTERS, {
            status: 1,
            ids: ids,
          })
          .then((data) => {
            if (!data.error) {
              let ids = data.data.flatMap((center) => center.branches.map((branch) => branch.province.id))
              this.getProvinces(ids)
              if (flag) {
                this.listCenter = data.data;
                this.listCenter.unshift({name: "Tất cả", id: 0});
                this.getCenters(false)
              }
            }
          });
    },
    getProvinces(ids) {
      this.$store.dispatch(GET_PROVINCES, {ids: ids}).then((res) => {
        if (!res.error) {
          this.provinces = res.data;
          this.provinces.unshift({name: "Tất cả", id: 0});
        }
      });
    },
    getCourse() {
      this.$store
          .dispatch(GET_COURSES_LIST, {
            status :  1 ,
            center_ids:
                this.dataAddTournament.centerApply[0] == 0
                    ? [ECOACH_ID, IELTS_MENTOR_ID]
                    : this.dataAddTournament.centerApply,
          })
          .then((res) => {
            this.listCourse = res.data.map((course) => {
              return {...course, center_name: this.listCenter.find((center) => center.id == course.center_id)?.name};
            });
            this.listCourse.unshift({name: "Tất cả", id: 0});
          });
    },
    async updateTournamentAPI() {
      this.$store
          .dispatch(UPDATE_TOURNAMENT, this.payloadEdit)
          .then(() => {
            this.statusEditInformation = false;
            this.$notify({
              title: "Thành công",
              message: "Sửa thông tin giải đấu thành công",
              type: "success",
            });
            this.getInformationTournament();
          })
          .catch(() => {
          })
          .finally(() => {
          });
    },
    cancelSaveInformation() {
      this.statusEditInformation = false;
      this.getInformationTournament();
    },
    addPhotoBanner(e) {
      if (e.target.files.length) {
        this.dataPhotoBanner = URL.createObjectURL(e.target.files[0]);
        this.dataAddTournament.imageBanner = e.target.files[0];
      }
    },
    addPhotoNews(e) {
      if (e.target.files.length) {
        this.dataPhotoNews = URL.createObjectURL(e.target.files[0]);
        this.dataAddTournament.imageNews = e.target.files[0];
      }
    },
    removePhotoBanner() {
      this.$confirm("Bạn có chắc chắn muốn xóa ảnh này không?", "Xóa ảnh", {
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        type: "Delete",
      })
          .then(() => {
            this.dataPhotoBanner = null;
            this.$notify({
              title: "Thành công",
              message: "Xóa ảnh thành công",
              type: "success",
            });
          })
          .catch(() => {
          });
    },
    removePhotoNews() {
      this.$confirm("Bạn có chắc chắn muốn xóa ảnh này không?", "Xóa ảnh", {
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        type: "Delete",
      })
          .then(() => {
            this.dataPhotoNews = null;
            this.$notify({
              title: "Thành công",
              message: "Xóa ảnh thành công",
              type: "success",
            });
          })
          .catch(() => {
          });
    },
    changeStartTime() {
      this.dataAddTournament.timeEnd = null;
    },
    disabledBeforeStartDate(date) {
      const today = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      return moment(date) < today;
    },
    disabledBeforeEndDate(date) {
      return (
          moment(date).endOf("days") <= moment(this.dataAddTournament.timeStart)
      );
    },
    disableHourStart(hour) {
      let today = new Date();
      return today >= hour;
    },
    disableHourEnd(hour) {
      return this.dataAddTournament.timeStart >= hour;
    },
    handleChangeCenter() {
      this.dataAddTournament.courseApply = [0];
      let quantityCenterApply = this.dataAddTournament.centerApply.length;
      if (quantityCenterApply == 0) {
        this.dataAddTournament.centerApply = [0];
        this.getCourse();
        this.getCenters(false);
        return
      }
      if (this.dataAddTournament.centerApply[quantityCenterApply - 1] == 0) {
        this.dataAddTournament.centerApply = [0];
        this.getCourse();
        this.getCenters(false);
        return
      }
      if (this.dataAddTournament.centerApply.includes(0)) {
        this.dataAddTournament.centerApply.splice(0, 1);
        this.getCourse();
        this.getCenters(false);
        return
      }
    },
    handleChangeProvince() {
      let quantityProvinceApply = this.dataAddTournament.provincesApply.length;
      if (quantityProvinceApply == 0) {
        return (this.dataAddTournament.provincesApply = [0]);
      }
      if (
          this.dataAddTournament.provincesApply[quantityProvinceApply - 1] == 0
      ) {
        return (this.dataAddTournament.provincesApply = [0]);
      }
      if (this.dataAddTournament.provincesApply.includes(0)) {
        return this.dataAddTournament.provincesApply.splice(0, 1);
      }
    },
    handleChangeCourse() {
      let quantityCourseApply = this.dataAddTournament.courseApply.length;
      if (quantityCourseApply == 0) {
        return (this.dataAddTournament.courseApply = [0]);
      }
      if (this.dataAddTournament.courseApply[quantityCourseApply - 1] == 0) {
        return (this.dataAddTournament.courseApply = [0]);
      }
      if (this.dataAddTournament.courseApply.includes(0)) {
        return this.dataAddTournament.courseApply.splice(0, 1);
      }
    },
  },
  computed: {
    payloadEdit() {
      let formData = new FormData();
      let timeStart =
          moment(this.dataAddTournament.timeStart).format(
              "YYYY-MM-DD HH:mm:ss"
          ) ?? null;
      let timeEnd =
          moment(this.dataAddTournament.timeEnd).format("YYYY-MM-DD HH:mm:ss") ??
          null;
      formData.append("name", this.dataAddTournament.nameTournament);
      if (this.dataAddTournament.centerApply[0] !== 0) {
        formData.append("center_ids[]", this.dataAddTournament.centerApply);
      }
      if (this.dataAddTournament.provincesApply[0] !== 0) {
        formData.append(
            "province_ids[]",
            this.dataAddTournament.provincesApply
        );
      }
      if (this.dataAddTournament.courseApply[0] !== 0) {
        formData.append("course_ids[]", this.dataAddTournament.courseApply);
      }
      formData.append("start_date", timeStart);
      formData.append("end_date", timeEnd);
      formData.append("img_banner", this.dataAddTournament.imageBanner);
      formData.append("img_info", this.dataAddTournament.imageNews);
      formData.append("content_media", this.dataAddTournament.content_media);
      formData.append(
          "total_competition",
          this.dataAddTournament.totalCompetition
      );
      return {
        id: this.$route.params.id,
        data: formData,
      };
    },
    checkEndTournament() {
      return new Date() >= this.dataAddTournament.timeEnd

    }
  },
};
</script>

<style scoped>
.file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  height: 34px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 7px 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  background-color: #667ca4;
}

@media only screen and (max-width: 768px) {
  .Information {
    display: block;
  }
}
</style>