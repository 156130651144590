export const branchs = {
    'Ho Chi Minh': [
        '',
        'NN&PTNT An Phu',
        'NN&PTNT An Suong',
        'NN&PTNT Bac Sai Gon',
        'NN&PTNT Ben Thanh',
        'NN&PTNT Binh Chanh',
        'NN&PTNT Binh Phu',
        'NN&PTNT Binh Tan',
        'NN&PTNT Binh Thanh',
        'NN&PTNT Binh Trieu',
        'NN&PTNT CN 10',
        'NN&PTNT CN 11',
        'NN&PTNT CN 3',
        'NN&PTNT CN 4',
        'NN&PTNT CN 6',
        'NN&PTNT CN 7',
        'NN&PTNT CN 8',
        'NN&PTNT CN 9',
        'NN&PTNT CN Trung Tam Sai Gon',
        'NN&PTNT Can Gio',
        'NN&PTNT Cho Lon',
        'NN&PTNT Cu Chi',
        'NN&PTNT Dong Sai Gon',
        'NN&PTNT Gia Dinh',
        'NN&PTNT Hiep Phuoc',
        'NN&PTNT Hoc Mon',
        'NN&PTNT Hung Vuong',
        'NN&PTNT KCN Tan Tao',
        'NN&PTNT Ly Thuong Kiet',
        'NN&PTNT Mien Dong',
        'NN&PTNT My Thanh',
        'NN&PTNT Nam Hoa',
        'NN&PTNT Nam Sai Gon',
        'NN&PTNT Nha Be',
        'NN&PTNT Phan Dinh Phung',
        'NN&PTNT Phu My Hung',
        'NN&PTNT Phu Nhuan',
        'NN&PTNT Phuoc Kien',
        'NN&PTNT Quan 1',
        'NN&PTNT Quan 5',
        'NN&PTNT Sai Gon',
        'NN&PTNT TPHCM',
        'NN&PTNT Tan Binh',
        'NN&PTNT Tan Phu',
        'NN&PTNT Tan Tao',
        'NN&PTNT Tay Sai Gon',
        'NN&PTNT Thanh Do',
        'NN&PTNT Thu Duc',
        'NN&PTNT Truong Son',
        'NN&PTNT VPDD KV Mien Nam',
        'NN&PTNT Xuyen A',
    ],
    'An Giang': [
        '',
        'NN&PTNT CN An Phu',
        'NN&PTNT CN Chau Phu',
        'NN&PTNT CN Chau Thanh An Giang',
        'NN&PTNT CN Cho Moi',
        'NN&PTNT CN Cho Vam',
        'NN&PTNT CN Phu Tan',
        'NN&PTNT CN Thoai Son',
        'NN&PTNT CN Tinh Bien',
        'NN&PTNT CN Tri Ton',
        'NN&PTNT Chi Lang',
        'NN&PTNT My Luong',
        'NN&PTNT TP Chau Doc',
        'NN&PTNT TP Long Xuyen',
        'NN&PTNT TX Tan Chau',
        'NN&PTNT Tinh An Giang',
    ],
    'Ba Ria-Vung Tau': [
        '',
        'NN&PTNT Ba Ria Vung Tau',
        'NN&PTNT CN Huyen Con Dao',
        'NN&PTNT CN Vung Tau',
        'NN&PTNT Huyen Chau Duc',
        'NN&PTNT Huyen Dat Do',
        'NN&PTNT Huyen Long Dien',
        'NN&PTNT Huyen Tan Thanh',
        'NN&PTNT Huyen Xuyen Moc',
        'NN&PTNT KCN Tan Thanh',
        "NN&PTNT TX Phu My"
    ],
    'Bac Can': [
        '',
        'NN&PTNT CN Bach Thong',
        'NN&PTNT CN Cho Don',
        'NN&PTNT CN Cho Moi',
        'NN&PTNT CN Na Ri',
        'NN&PTNT CN Ngan Son',
        'NN&PTNT CN Pac Nam',
        'NN&PTNT Huyen Ba Be',
        'NN&PTNT Tinh Bac Kan',
    ],
    'Bac Giang': [
        '',
        'NN&PTNT CN Bo Ha',
        'NN&PTNT CN Xuong Giang',
        'NN&PTNT Huyen Hiep Hoa',
        'NN&PTNT Huyen Lang Giang',
        'NN&PTNT Huyen Luc Nam',
        'NN&PTNT Huyen Luc Ngan',
        'NN&PTNT Huyen Son Dong',
        'NN&PTNT Huyen Tan Yen',
        'NN&PTNT Huyen Viet Yen',
        'NN&PTNT Huyen Yen Dung',
        'NN&PTNT Huyen Yen The',
        'NN&PTNT KCN Dinh Tram',
        'NN&PTNT TP Bac Giang',
        'NN&PTNT Tinh Bac Giang',
    ],
    'Bac Lieu': [
        '',
        'NN&PTNT CN Dong Hai',
        'NN&PTNT CN Gia Rai',
        'NN&PTNT CN Hoa Binh',
        'NN&PTNT CN Hong Dan',
        'NN&PTNT CN Phuoc Long',
        'NN&PTNT CN Vinh Loi',
        'NN&PTNT TP Bac Lieu',
        'NN&PTNT Tinh Bac Lieu',
    ],
    'Bac Ninh': [
        '',
        'NN&PTNT CN Thi Xa Tu Son',
        'NN&PTNT Huyen Gia Binh',
        'NN&PTNT Huyen Luong Tai',
        'NN&PTNT Huyen Que Vo',
        'NN&PTNT Huyen Thuan Thanh',
        'NN&PTNT Huyen Tien Du',
        'NN&PTNT Huyen Yen Phong',
        'NN&PTNT KCN Tien Son',
        'NN&PTNT TP Bac Ninh',
        'NN&PTNT Tinh Bac Ninh',
    ],
    'Ben Tre': [
        '',
        'NN&PTNT CN Ba Tri',
        'NN&PTNT CN Binh Dai',
        'NN&PTNT CN Chau Thanh',
        'NN&PTNT CN Cho Lach',
        'NN&PTNT CN Dong Khoi',
        'NN&PTNT CN Giong Trom',
        'NN&PTNT CN Mo Cay Bac',
        'NN&PTNT CN Mo Cay Nam',
        'NN&PTNT CN Thanh Phu',
        'NN&PTNT TP Ben Tre',
        'NN&PTNT Tinh Ben Tre',
    ],
    'Binh Dinh': [
        '',
        'NN&PTNT Binh Dinh',
        'NN&PTNT CN An Lao',
        'NN&PTNT CN Hoai An',
        'NN&PTNT CN Hoai Nhon',
        'NN&PTNT CN Phu Cat',
        'NN&PTNT CN Phu My',
        'NN&PTNT CN Tay Son',
        'NN&PTNT CN Tuy Phuoc',
        'NN&PTNT CN Van Canh',
        'NN&PTNT CN Vinh Thanh',
        'NN&PTNT KCN Phu Tai',
        'NN&PTNT TP Quy Nhon',
        'NN&PTNT TX An Nhon',
    ],
    'Binh Duong': [
        '',
        'NN&PTNT Binh Duong',
        'NN&PTNT CN So Sao',
        'NN&PTNT Huyen Ben Cat',
        'NN&PTNT Huyen Dau Tieng',
        'NN&PTNT Huyen Phu Giao',
        'NN&PTNT Huyen Tan Uyen',
        'NN&PTNT KCN Song Than',
        'NN&PTNT TX Di An',
        'NN&PTNT TX Thu Dau Mot',
        'NN&PTNT TX Thuan An',
        'NN&PTNT Tan Phuoc Khanh',
    ],
    'Binh Phuoc': [
        '',
        'NN&PTNT Bu Nho',
        'NN&PTNT CN Duc Lieu',
        'NN&PTNT CN Loc Hiep',
        'NN&PTNT CN Phuoc Binh',
        'NN&PTNT CN Tan Thanh',
        'NN&PTNT Da Kia',
        'NN&PTNT Huyen Binh Long',
        'NN&PTNT Huyen Bu Dang',
        'NN&PTNT Huyen Bu Dop',
        'NN&PTNT Huyen Bu Gia Map Binh Phuoc',
        'NN&PTNT Huyen Chon Thanh',
        'NN&PTNT Huyen Dong Phu',
        'NN&PTNT Huyen Hon Quan Binh Phuoc',
        'NN&PTNT Huyen Loc Ninh',
        'NN&PTNT Huyen Phuoc Long',
        'NN&PTNT Phu Rieng',
        'NN&PTNT TX Dong Xoai',
        'NN&PTNT Tinh Binh Phuoc',
    ],
    'Binh Thuan': [
        '',
        'NN&PTNT CN Bac Binh',
        'NN&PTNT CN Ham My',
        'NN&PTNT CN Ham Tan',
        'NN&PTNT CN Ham Thuan Bac',
        'NN&PTNT CN Ham Thuan Nam',
        'NN&PTNT CN Luong Son',
        'NN&PTNT CN Phan Ri Cua',
        'NN&PTNT CN Tanh Linh',
        'NN&PTNT Huyen Duc Linh',
        'NN&PTNT Huyen Phu Quy',
        'NN&PTNT Huyen Tuy Phong',
        'NN&PTNT Nam Phan Thiet',
        'NN&PTNT TP Phan Thiet',
        'NN&PTNT Thi Xa La Gi',
        'NN&PTNT Tinh Binh Thuan',
    ],
    'Ca Mau': [
        '',
        'NN&PTNT CN Cai Nuoc',
        'NN&PTNT CN Dam Doi',
        'NN&PTNT CN Nam Can',
        'NN&PTNT CN Ngoc Hien',
        'NN&PTNT CN Phu Tan',
        'NN&PTNT CN Thoi Binh',
        'NN&PTNT CN Tran Van Thoi',
        'NN&PTNT CN U Minh',
        'NN&PTNT TP Ca Mau',
        'NN&PTNT Tinh Ca Mau',
    ],
    'Can Tho': [
        '',
        'NN&PTNT Binh Thuy',
        'NN&PTNT CN Can Tho II',
        'NN&PTNT Cai Rang',
        'NN&PTNT Can Tho',
        'NN&PTNT Huyen Co Do CT II',
        'NN&PTNT Huyen Phong Dien CT II',
        'NN&PTNT Huyen Thoi Lai CT II',
        'NN&PTNT Huyen Vinh Thanh CT II',
        'NN&PTNT Quan O Mon',
        'NN&PTNT Thot Not Can Tho',
        'NN&PTNT VPDD KV Tay Nam Bo',
    ],
    'Cao Bang': [
        '',
        'NN&PTNT Huyen Bao Lac',
        'NN&PTNT Huyen Bao Lam',
        'NN&PTNT Huyen Ha Lang',
        'NN&PTNT Huyen Ha Quang',
        'NN&PTNT Huyen Hoa An',
        'NN&PTNT Huyen Nguyen Binh',
        'NN&PTNT Huyen Phuc Hoa',
        'NN&PTNT Huyen Quang Uyen',
        'NN&PTNT Huyen Thach An',
        'NN&PTNT Huyen Thong Nong',
        'NN&PTNT Huyen Tra Linh',
        'NN&PTNT Huyen Trung Khanh',
        'NN&PTNT TP Cao Bang',
        'NN&PTNT Tinh Cao Bang',
    ],
    'Da Nang': [
        '',
        'NN&PTNT Cam Le',
        'NN&PTNT Chi Lang',
        'NN&PTNT Cho Con',
        'NN&PTNT Cho Moi',
        'NN&PTNT Dong Da',
        'NN&PTNT Hai Chau',
        'NN&PTNT Hoa Vang',
        'NN&PTNT KCN Da Nang',
        'NN&PTNT KCN Hoa Khanh',
        'NN&PTNT Lien Chieu',
        'NN&PTNT Ngu Hanh Son',
        'NN&PTNT Ong Ich Khiem',
        'NN&PTNT Son Tra',
        'NN&PTNT TP Da Nang',
        'NN&PTNT Tan Chinh',
        'NN&PTNT Thanh Khe DN',
        'NN&PTNT VPDD KV Mien Trung',
    ],
    'Dac Lac': [
        '',
        'NN&PTNT CN Bac Dak Lak',
        'NN&PTNT CN Buon Don',
        'NN&PTNT CN Buon Ho',
        'NN&PTNT CN Cu Kuin',
        'NN&PTNT CN Cu Mgar',
        'NN&PTNT CN Ea Cpam',
        'NN&PTNT CN Ea HLeo',
        'NN&PTNT CN Ea Kar',
        'NN&PTNT CN Ea Knop',
        'NN&PTNT CN Ea Phe',
        'NN&PTNT CN Ea Ral',
        'NN&PTNT CN Ea Sup',
        'NN&PTNT CN Ea Tam',
        'NN&PTNT CN Ea Toh',
        'NN&PTNT CN Hoa Khanh',
        'NN&PTNT CN Hoa Thang',
        'NN&PTNT CN Hoa Thuan',
        'NN&PTNT CN Krong ANa',
        'NN&PTNT CN Krong Bong',
        'NN&PTNT CN Krong Nang',
        'NN&PTNT CN KrongPak',
        'NN&PTNT CN Lak',
        'NN&PTNT CN Le Hong Phong',
        'NN&PTNT CN Le Thanh Tong',
        'NN&PTNT CN M Drak',
        'NN&PTNT CN No Trang Long',
        'NN&PTNT CN Phan Chu Trinh',
        'NN&PTNT CN Tan Lap',
        'NN&PTNT Pong Drang',
        'NN&PTNT TP.Buon Ma Thuot',
        'NN&PTNT Tinh Dak Lak',
    ],
    'Dak Nong': [
        '',
        'NN&PTNT CN Dak Glong',
        'NN&PTNT CN Dak Mil',
        'NN&PTNT CN Dak Rlap',
        'NN&PTNT CN Dak Song',
        'NN&PTNT CN Krong No',
        'NN&PTNT CN Nam Dong',
        'NN&PTNT Dak Nong',
        'NN&PTNT TX Gia Nghia',
    ],
    'Dien Bien': [
        '',
        'NN&PTNT Huyen Dien Bien',
        'NN&PTNT Huyen Dien Bien Dong',
        'NN&PTNT Huyen Muong Ang',
        'NN&PTNT Huyen Muong Cha',
        'NN&PTNT Huyen Muong Nhe',
        'NN&PTNT Huyen Tua Chua',
        'NN&PTNT Huyen Tuan Giao',
        'NN&PTNT TP Dien Bien Phu',
        'NN&PTNT Thi Xa Muong Lay',
        'NN&PTNT Tinh Dien Bien',
    ],
    'Dong Nai': [
        '',
        'NN&PTNT Bien Hoa',
        'NN&PTNT CN KCN Tam Phuoc',
        'NN&PTNT CN TX Long Khanh',
        'NN&PTNT Dinh Quan',
        'NN&PTNT Dong Nai',
        'NN&PTNT Huyen Cam My',
        'NN&PTNT Huyen Long Thanh',
        'NN&PTNT Huyen Nhon Trach',
        'NN&PTNT Huyen Trang Bom',
        'NN&PTNT Huyen Xuan Loc',
        'NN&PTNT Tan Bien',
        'NN&PTNT Tan Hiep',
        'NN&PTNT Tan Phu',
        'NN&PTNT Thong Nhat',
        'NN&PTNT Vinh Cuu',
    ],
    'Dong Thap': [
        '',
        'NN&PTNT CN Cao Lanh',
        'NN&PTNT CN Chau Thanh Dong Thap',
        'NN&PTNT CN Lai Vung',
        'NN&PTNT CN Lap Vo',
        'NN&PTNT CN Tam Nong',
        'NN&PTNT CN Tan Hong',
        'NN&PTNT CN Thanh Binh',
        'NN&PTNT CN Thap Muoi',
        'NN&PTNT Dong Thap',
        'NN&PTNT Huyen Hong Ngu',

        'NN&PTNT TP Cao Lanh',
        'NN&PTNT TP Sa Dec',
    ],
    'Gia Lai': [
        '',
        'NN&PTNT CN Bien Ho',
        'NN&PTNT CN Chu Prong',
        'NN&PTNT CN Dak Doa',
        'NN&PTNT CN Dien Hong',
        'NN&PTNT CN Dong Gia Lai',
        'NN&PTNT CN Duc Co',
        'NN&PTNT CN Hoa Lu',
        'NN&PTNT CN Hoi Thuong',
        'NN&PTNT CN TP Pleiku',
        'NN&PTNT CN Yen Do',
        'NN&PTNT Gia Lai',
        'NN&PTNT Huyen AYunPa',
        'NN&PTNT Huyen An Khe',
        'NN&PTNT Huyen Chu Pah',
        'NN&PTNT Huyen Chu Puh',
        'NN&PTNT Huyen Chu Se',
        'NN&PTNT Huyen Dak Po',
        'NN&PTNT Huyen IaPa',
        'NN&PTNT Huyen KRong Pa',
        'NN&PTNT Huyen Kbang',
        'NN&PTNT Huyen KongChro',
        'NN&PTNT Huyen Mang Yang',
        'NN&PTNT Huyen Phu Thien',
        'NN&PTNT Huyen la Grai',
    ],
    'Ha Giang': [
        '',
        'NN&PTNT CN Bac Me',
        'NN&PTNT CN Bac Quang',
        'NN&PTNT CN Dong Van',
        'NN&PTNT CN Hoang Su Phi',
        'NN&PTNT CN Meo Vac',
        'NN&PTNT CN Quan Ba',
        'NN&PTNT CN Quang Binh',
        'NN&PTNT CN Thanh Thuy',
        'NN&PTNT CN Vi Xuyen',
        'NN&PTNT CN Xin Man',
        'NN&PTNT CN Yen Minh',
        'NN&PTNT Tinh Ha Giang',
    ],
    'Ha Nam': [
        '',
        'NN&PTNT  Huyen Binh Luc',
        'NN&PTNT  Huyen Duy Tien',
        'NN&PTNT  Huyen Kim Bang',
        'NN&PTNT  Huyen Ly Nhan',
        'NN&PTNT  Huyen Thanh Liem',
        'NN&PTNT TP Phu Ly',
        'NN&PTNT Tinh Ha Nam',
    ],
    'Ha Noi': [
        '',
        'NN&PTNT Bac Ha Noi',
        'NN&PTNT Bach Khoa',
        'NN&PTNT CN Hoa Lac',
        'NN&PTNT CN Me Linh',
        'NN&PTNT CN TX Son Tay',
        'NN&PTNT CN Trung Yen',
        'NN&PTNT CN Xuan Mai',
        'NN&PTNT Cau Giay',
        'NN&PTNT Dong Anh',
        'NN&PTNT Dong Da',
        'NN&PTNT Dong Ha Noi',
        'NN&PTNT Gia Lam',
        'NN&PTNT Ha Noi',
        'NN&PTNT Ha Tay',
        'NN&PTNT Ha Thanh',
        'NN&PTNT Hoan Kiem',
        'NN&PTNT Hoang Mai',
        'NN&PTNT Hoang Quoc Viet',
        'NN&PTNT Hong Ha',
        'NN&PTNT Hung Vuong Ha Noi',
        'NN&PTNT Huyen Ba Vi',
        'NN&PTNT Huyen Chuong My',
        'NN&PTNT Huyen Dan Phuong',
        'NN&PTNT Huyen Hoai Duc',
        'NN&PTNT Huyen My Duc',
        'NN&PTNT Huyen Phu Xuyen',
        'NN&PTNT Huyen Phuc Tho',
        'NN&PTNT Huyen Quoc Oai',
        'NN&PTNT Huyen Thach That',
        'NN&PTNT Huyen Thanh Oai',
        'NN&PTNT Huyen Thuong Tin',
        'NN&PTNT Huyen Ung Hoa ',
        'NN&PTNT Lang Ha',
        'NN&PTNT Long Bien',
        'NN&PTNT My Dinh',
        'NN&PTNT Nam Ha Noi',
        'NN&PTNT So Giao Dich',
        'NN&PTNT Soc Son Ha Noi',
        'NN&PTNT Tam Trinh',
        'NN&PTNT Tay Do',
        'NN&PTNT Tay Ha Noi',
        'NN&PTNT Tay Ho',
        'NN&PTNT Thang Long',
        'NN&PTNT Thanh Tri Ha Noi',
        'NN&PTNT Thanh Xuan',
        'NN&PTNT Thu Do',
        'NN&PTNT Trang An',
        'NN&PTNT Trung Tam Thanh Toan',
        'NN&PTNT Tu Liem Ha Noi',
        'NN&PTNT Viet Nam Hoi So Chinh',
    ],
    'Ha Tinh': [
        '',
        'NN&PTNT CN Tay Son - Ha Tinh',
        'NN&PTNT CN Thanh Sen',
        'NN&PTNT CN Voi',
        'NN&PTNT Ha Tinh',
        'NN&PTNT Huyen Cam Xuyen',
        'NN&PTNT Huyen Can Loc',
        'NN&PTNT Huyen Duc Tho',
        'NN&PTNT Huyen Huong Khe',
        'NN&PTNT Huyen Huong Son',
        'NN&PTNT Huyen Ky Anh',
        'NN&PTNT Huyen Loc Ha',
        'NN&PTNT Huyen Nghi Xuan',
        'NN&PTNT Huyen Thach Ha',
        'NN&PTNT Huyen Vu Quang',
        'NN&PTNT TP Ha Tinh',
        'NN&PTNT TX Ky Anh',
        'NN&PTNT Thi Xa Hong Linh',
    ],
    'Hai Duong': [
        '',
        'NN&PTNT CN Binh Giang',
        'NN&PTNT CN Cam Giang',
        'NN&PTNT CN Dai Tan',
        'NN&PTNT CN Gia Loc',
        'NN&PTNT CN Kim Thanh',
        'NN&PTNT CN Kinh Mon',
        'NN&PTNT CN Nam Sach',
        'NN&PTNT CN Ninh Giang',
        'NN&PTNT CN Pha Lai',
        'NN&PTNT CN Thanh Dong',
        'NN&PTNT CN Thanh Ha',
        'NN&PTNT CN Thanh Mien',
        'NN&PTNT CN Tu Ky',
        'NN&PTNT Sao Do',
        'NN&PTNT TP Hai Duong',
        'NN&PTNT Tinh Hai Duong',
        "NN&PTNT CN Hai Duong II"

    ],
    'Hai Phong': [
        '',
        'NN&PTNT An Duong',
        'NN&PTNT An Hung',
        'NN&PTNT An Lao',
        'NN&PTNT Bac Hai Phong',
        'NN&PTNT CN Dai Hop',
        'NN&PTNT CN Do Son',
        'NN&PTNT CN Dong Hai Phong',
        'NN&PTNT CN Duong Kinh',
        'NN&PTNT CN Hai An ',
        'NN&PTNT CN Ky Son',
        'NN&PTNT CN Nam Am',
        'NN&PTNT CN Ngu Lao',
        'NN&PTNT CN Tran Phu',
        'NN&PTNT CN Vam Lang',
        'NN&PTNT Cat Ba',
        'NN&PTNT Cat Hai',
        'NN&PTNT Kien An',
        'NN&PTNT Kien Thuy',
        'NN&PTNT Ngo Quyen',
        'NN&PTNT TP Hai Phong',
        'NN&PTNT Thuy Nguyen',
        'NN&PTNT Tien Lang',
        'NN&PTNT Vinh Bao',

    ],
    'Hau Giang': [
        '',
        'NN&PTNT CN Cai Tac',
        'NN&PTNT CN Chau Thanh A Hau Giang',
        'NN&PTNT CN Chau Thanh Hau Giang',
        'NN&PTNT CN Long My',
        'NN&PTNT CN Phung Hiep',
        'NN&PTNT CN Vi Thuy',
        'NN&PTNT Hau Giang',
        'NN&PTNT TP Vi Thanh',
        'NN&PTNT TX Nga Bay',

    ],
    'Hoa Binh': [
        '',
        'NN&PTNT CN Phuong Lam',
        'NN&PTNT CN Song Da',
        'NN&PTNT Huyen Cao Phong',
        'NN&PTNT Huyen Da Bac',
        'NN&PTNT Huyen Kim Boi',
        'NN&PTNT Huyen Ky Son',
        'NN&PTNT Huyen Lac Son',
        'NN&PTNT Huyen Lac Thuy',
        'NN&PTNT Huyen Luong Son - Hoa Binh',
        'NN&PTNT Huyen Mai Chau',
        'NN&PTNT Huyen Tan Lac',
        'NN&PTNT Huyen Yen Thuy',
        'NN&PTNT Tinh Hoa Binh',

    ],
    'Hung Yen': [
        '',
        'NN&PTNT CN An Thi',
        'NN&PTNT CN Khoai Chau',
        'NN&PTNT CN Kim Dong',
        'NN&PTNT CN Phu Cu',
        'NN&PTNT CN Tien Lu',
        'NN&PTNT CN Van Giang',
        'NN&PTNT CN Van Lam',
        'NN&PTNT CN Yen My',
        'NN&PTNT Hung Yen',
        'NN&PTNT KCN Minh Duc',
        'NN&PTNT My Hao',
        'NN&PTNT TP Hung Yen',
    ],
    'Khanh Hoa': [
        '',
        'NN&PTNT CN Cho Xom Moi',
        'NN&PTNT CN Nam Nha Trang',
        'NN&PTNT CN Vinh Hiep',
        'NN&PTNT CN Vinh Tho',
        'NN&PTNT Huyen Cam Lam',
        'NN&PTNT Huyen Dien Khanh',
        'NN&PTNT Huyen Khanh Son',
        'NN&PTNT Huyen Khanh Vinh',
        'NN&PTNT Huyen Ninh Hoa',
        'NN&PTNT Huyen Van Ninh',
        'NN&PTNT TP Nha Trang',
        'NN&PTNT Thi Xa Cam Ranh',
        'NN&PTNT Tinh Khanh Hoa',
    ],
    'Kien Giang': [
        '',
        'NN&PTNT CN An Bien',
        'NN&PTNT CN An Minh',
        'NN&PTNT CN Ba Hon',
        'NN&PTNT CN Ben Nhat',
        'NN&PTNT CN Chau Thanh Kien Giang',
        'NN&PTNT CN Giong Rieng',
        'NN&PTNT CN Go Quao',
        'NN&PTNT CN Hon Dat',
        'NN&PTNT CN Kien Hai',
        'NN&PTNT CN Kien Luong',
        'NN&PTNT CN My Lam',
        'NN&PTNT CN Rach Soi',
        'NN&PTNT CN Tan Hiep',
        'NN&PTNT CN U Minh Thuong',
        'NN&PTNT CN Vinh Lac',
        'NN&PTNT Huyen Vinh Thuan',
        'NN&PTNT Kien Giang',
        'NN&PTNT Phu Quoc',
        'NN&PTNT TX Ha Tien',
    ],
    'Kon Tum': [
        '',
        'NN&PTNT CN Quang Trung - Kon Tum',
        'NN&PTNT CN Quyet Thang',
        'NN&PTNT Huyen Dak Ha',
        'NN&PTNT Huyen Dak To',
        'NN&PTNT Huyen DakGlei',
        'NN&PTNT Huyen Kon Ray',
        'NN&PTNT Huyen Ngoc Hoi',
        'NN&PTNT Huyen Sa Thay',
        'NN&PTNT PGD Le Loi',
        'NN&PTNT PGD Thang Loi',
        'NN&PTNT Tinh Kon Tum',

    ],
    'Lai Chau': [
        '',
        'NN&PTNT CN Muong Te',
        'NN&PTNT CN Phong Tho',
        'NN&PTNT CN Sin Ho',
        'NN&PTNT CN Tam Duong',
        'NN&PTNT CN Tan Uyen',
        'NN&PTNT CN Than Uyen',
        'NN&PTNT TP Lai Chau',
        'NN&PTNT Tinh Lai Chau',
    ],
    'Lam Dong': [
        '',
        'NN&PTNT CN Bao Lam Nam Lam Dong',
        'NN&PTNT CN Cat Tien',
        'NN&PTNT CN Da Huoai',
        'NN&PTNT CN Da.Teh',
        'NN&PTNT CN Di Linh',
        'NN&PTNT CN Don Duong',
        'NN&PTNT CN Duc Trong',
        'NN&PTNT CN Hoa Ninh ',
        'NN&PTNT CN Lam Dong II',
        'NN&PTNT CN Loc An Nam Lam Dong',
        'NN&PTNT CN Loc Phat',
        'NN&PTNT CN Nam Lam Dong (Dau Tam To)',
        'NN&PTNT Huyen Dam Rong',
        'NN&PTNT Huyen Lac Duong',
        'NN&PTNT Huyen Lam Ha',
        'NN&PTNT Lam Dong',
        'NN&PTNT TP Da Lat',
    ],
    'Lang Son': [
        '',
        'NN&PTNT CN Bac Son',
        'NN&PTNT CN Binh Gia',
        'NN&PTNT CN Cao Loc',
        'NN&PTNT CN Chi Lang',
        'NN&PTNT CN Dinh Lap',
        'NN&PTNT CN Huu Lung',
        'NN&PTNT CN Loc Binh',
        'NN&PTNT CN Trang Dinh',
        'NN&PTNT CN Van Lang',
        'NN&PTNT CN Van Quan',
        'NN&PTNT Huyen Dong Dang',
        'NN&PTNT PGD Tan Thanh',
        'NN&PTNT TP Lang Son',
        'NN&PTNT Tinh Lang Son',
    ],
    'Lao Cai': [
        '',
        'NN&PTNT  CN Cam Duong',
        'NN&PTNT  Huyen Bac Ha',
        'NN&PTNT  Huyen Bao Thang',
        'NN&PTNT  Huyen Bao Yen',
        'NN&PTNT  Huyen Coc Leu',
        'NN&PTNT  Huyen Van Ban',
        'NN&PTNT  TP Lao Cai',
        'NN&PTNT Huyen Bat Xat',
        'NN&PTNT Huyen Kim Tan',
        'NN&PTNT Huyen Kim Thanh Lao Cai',
        'NN&PTNT Huyen Muong Khuong',
        'NN&PTNT Huyen Sa Pa',
        'NN&PTNT Tinh Lao Cai',
    ],
    'Long An': [
        '',
        'NN&PTNT CN Khu Vuc 3',
        'NN&PTNT Huyen Ben Luc',
        'NN&PTNT Huyen Can Duoc',
        'NN&PTNT Huyen Can Giuoc',
        'NN&PTNT Huyen Chau Thanh',
        'NN&PTNT Huyen Duc Hoa',
        'NN&PTNT Huyen Duc Hue',
        'NN&PTNT Huyen Tan Hung',
        'NN&PTNT Huyen Tan Thanh',
        'NN&PTNT Huyen Tan Tru',
        'NN&PTNT Huyen Thanh Hoa',
        'NN&PTNT Huyen Thu Thua',
        'NN&PTNT Huyen Vinh Hung',
        'NN&PTNT Khu Vuc Cau Voi',
        'NN&PTNT Khu Vuc Duc Hoa',
        'NN&PTNT Khu Vuc Go Den',
        'NN&PTNT Khu vuc 2',
        'NN&PTNT Thi Xa Kien Tuong',
        'NN&PTNT Thi Xa Tan An',
        'NN&PTNT Tinh Long An',

    ],
    'Nam Dinh': [
        '',
        'NN&PTNT Bac Nam Dinh',
        'NN&PTNT CN Con',
        'NN&PTNT CN Dong Binh',
        'NN&PTNT CN Giao Thuy',
        'NN&PTNT CN Hai Hau',
        'NN&PTNT CN My Loc',
        'NN&PTNT CN Nam Truc',
        'NN&PTNT CN Nghia Hung',
        'NN&PTNT CN Thinh Long',
        'NN&PTNT CN Truc Ninh',
        'NN&PTNT CN Vu Ban',
        'NN&PTNT CN Xuan Truong',
        'NN&PTNT CN Y Yen',
        'NN&PTNT TP Nam Dinh',
        'NN&PTNT Thanh Nam',
        'NN&PTNT Tinh Nam Dinh',

    ],
    'Nghe An': [
        '',
        'NN&PTNT CN Hoang Mai',
        'NN&PTNT Huyen Anh Son',
        'NN&PTNT Huyen Con Cuong',
        'NN&PTNT Huyen Dien Chau',
        'NN&PTNT Huyen Do Luong',
        'NN&PTNT Huyen Hung Nguyen',
        'NN&PTNT Huyen Ky Son',
        'NN&PTNT Huyen Nam Dan',
        'NN&PTNT Huyen Nghi Loc',
        'NN&PTNT Huyen Nghia Dan',
        'NN&PTNT Huyen Que Phong',
        'NN&PTNT Huyen Quy Chau',
        'NN&PTNT Huyen Quy Hop',
        'NN&PTNT Huyen Quynh Luu',
        'NN&PTNT Huyen Tan Ky',
        'NN&PTNT Huyen Thanh Chuong',
        'NN&PTNT Huyen Tuong Duong',
        'NN&PTNT Huyen Yen Thanh',
        'NN&PTNT Nghe An',
        'NN&PTNT TP Vinh',
        'NN&PTNT Thi Xa Cua Lo',
        'NN&PTNT Thi Xa Thai Hoa',
    ],
    'Ninh Binh': [
        '',
        'NN&PTNT Huyen Gia Vien',
        'NN&PTNT Huyen Hoa Lu',
        'NN&PTNT Huyen Kim Son',
        'NN&PTNT Huyen Nho Quan',
        'NN&PTNT Huyen Yen Khanh',
        'NN&PTNT Huyen Yen Mo',
        'NN&PTNT KV Binh Minh Ninh Binh',
        'NN&PTNT KV Gian Khau Ninh Binh',
        'NN&PTNT KV Ria Ninh Binh',
        'NN&PTNT Song Van',
        'NN&PTNT TP Ninh Binh',
        'NN&PTNT TP Tam Diep',
        'NN&PTNT Tinh Ninh Binh',
    ],
    'Ninh Thuan': [
        '',
        'NN&PTNT CN Thap Cham',
        'NN&PTNT Dong My Hai',
        'NN&PTNT Huyen Bac Ai',
        'NN&PTNT Huyen Ninh Hai',
        'NN&PTNT Huyen Ninh Phuoc',
        'NN&PTNT Huyen Ninh Son',
        'NN&PTNT TP Phan Rang',
        'NN&PTNT Tinh Ninh Thuan',
    ],
    'Phu Tho': [
        '',
        'NN&PTNT CN Cam Khe',
        'NN&PTNT CN Doan Hung',
        'NN&PTNT CN Gia Cam',
        'NN&PTNT CN Ha Hoa',
        'NN&PTNT CN Lam Thao',
        'NN&PTNT CN Phu Ninh',
        'NN&PTNT CN Tam Nong',
        'NN&PTNT CN Tan Son',
        'NN&PTNT CN Thanh Ba',
        'NN&PTNT CN Thanh Mieu',
        'NN&PTNT CN Thanh Son',
        'NN&PTNT CN Thanh Thuy',
        'NN&PTNT CN Van Co',
        'NN&PTNT CN Yen Lap',
        'NN&PTNT TX Phu Tho',
        'NN&PTNT Tinh Phu Tho',
    ],
    'Phu Yen': [
        '',
        'NN&PTNT CN Dong Hoa',
        'NN&PTNT CN Dong Xuan',
        'NN&PTNT CN Nam TP Tuy Hoa',
        'NN&PTNT CN Phu Hoa',
        'NN&PTNT CN Son Hoa',
        'NN&PTNT CN Song Hinh',
        'NN&PTNT CN Tay Hoa',
        'NN&PTNT CN Tuy An',
        'NN&PTNT TP Tuy Hoa',
        'NN&PTNT TX Song Cau',
        'NN&PTNT Tinh Phu Yen',
    ],
    'Quang Binh': [
        '',
        'NN&PTNT CN Bo Trach',
        'NN&PTNT CN Le Thuy',
        'NN&PTNT CN Ly Thai To',
        'NN&PTNT CN Ly Thuong Kiet',
        'NN&PTNT CN Quang Ninh',
        'NN&PTNT CN Quang Trung',
        'NN&PTNT CN Tran Hung Dao',
        'NN&PTNT Huyen Minh Hoa',
        'NN&PTNT Huyen Quang Trach',
        'NN&PTNT Huyen Tuyen Hoa',
        'NN&PTNT Quang Binh',
    ],
    'Quang Nam': [
        '',
        'NN&PTNT Bac Dien Ban',
        'NN&PTNT CN Bac Tra My',
        'NN&PTNT CN Dai Loc',
        'NN&PTNT CN Dong Giang',
        'NN&PTNT CN Duy Xuyen',
        'NN&PTNT CN Hiep Duc',
        'NN&PTNT CN Huong An',
        'NN&PTNT CN Nam Giang',
        'NN&PTNT CN Nam Tra My',
        'NN&PTNT CN Nong Son',
        'NN&PTNT CN Nui Thanh',
        'NN&PTNT CN Phu Ninh',
        'NN&PTNT CN Phuoc Son',
        'NN&PTNT CN Que Son',
        'NN&PTNT CN Tam Dan',
        'NN&PTNT CN Tay Giang',
        'NN&PTNT CN Thang Binh',
        'NN&PTNT CN Tien Phuoc',
        'NN&PTNT CN Truong Xuan',
        'NN&PTNT Cua Dai',
        'NN&PTNT Dien Ban',
        'NN&PTNT Dien Nam - Dien Ngoc',
        'NN&PTNT Khu Kinh te mo Chu Lai',
        'NN&PTNT Nam Phuoc',
        'NN&PTNT TP Hoi An',
        'NN&PTNT TP Tam Ky',
        'NN&PTNT Tinh Quang Nam',
    ],
    'Quang Ngai': [
        '',
        'NN&PTNT CN Ba To',
        'NN&PTNT CN Binh Son',
        'NN&PTNT CN Duc Pho',
        'NN&PTNT CN Dung Quat',
        'NN&PTNT CN Minh Long',
        'NN&PTNT CN Mo Duc',
        'NN&PTNT CN Nghia Hanh',
        'NN&PTNT CN Son Ha',
        'NN&PTNT CN Son Tinh',
        'NN&PTNT CN Tra Bong',
        'NN&PTNT CN Tu Nghia',
        'NN&PTNT Ly Son',
        'NN&PTNT Quang Ngai',
        'NN&PTNT Sa Huynh',
        'NN&PTNT TP Quang Ngai',
    ],
    'Quang Ninh': [
        '',
        'NN&PTNT CN Cao Thang',
        'NN&PTNT CN Ha Long I',
        'NN&PTNT CN Mao Khe',
        'NN&PTNT CN Nam Cam Pha',
        'NN&PTNT Huyen Ba Che',
        'NN&PTNT Huyen Binh Lieu',
        'NN&PTNT Huyen Co To',
        'NN&PTNT Huyen Dam Ha',
        'NN&PTNT Huyen Dong Trieu',
        'NN&PTNT Huyen Hai Ha',
        'NN&PTNT Huyen Hoanh Bo',
        'NN&PTNT Huyen Tien Yen',
        'NN&PTNT Huyen Van Don',
        'NN&PTNT Khu Vuc Bai Chay',
        'NN&PTNT TP Ha Long',
        'NN&PTNT TP Mong Cai',
        'NN&PTNT Thi Xa Cam Pha',
        'NN&PTNT Thi Xa Quang Yen',
        'NN&PTNT Thi Xa Uong Bi',
        'NN&PTNT Tinh Quang Ninh',
    ],
    'Quang Tri': [
        '',
        'NN&PTNT Huyen Cam Lo',
        'NN&PTNT Huyen Da Krong',
        'NN&PTNT Huyen Gio Linh',
        'NN&PTNT Huyen Hai Lang',
        'NN&PTNT Huyen Huong Hoa',
        'NN&PTNT Huyen Trieu Phong',
        'NN&PTNT Huyen Vinh Linh',
        'NN&PTNT Lao Bao',
        'NN&PTNT TP Dong Ha',
        'NN&PTNT TX Quang Tri',
        'NN&PTNT Tinh Quang Tri',
    ],
    'Soc Trang': [
        '',
        'NN&PTNT CN Ba Xuyen',
        'NN&PTNT CN Cu Lao Dung',
        'NN&PTNT CN Ke Sach',
        'NN&PTNT CN Long Phu',
        'NN&PTNT CN My Tu',
        'NN&PTNT CN My Xuyen',
        'NN&PTNT CN Nga Nam',
        'NN&PTNT CN Thanh Phu',
        'NN&PTNT CN Thanh Tri',
        'NN&PTNT CN Tran De',
        'NN&PTNT CN Vinh Chau',
        'NN&PTNT Cn Chau Thanh',
        'NN&PTNT PGD Dai Nghia',
        'NN&PTNT TP Soc Trang',
        'NN&PTNT Tinh Soc Trang',
    ],
    'Son La': [
        '',
        'NN&PTNT Huyen Bac Yen',
        'NN&PTNT Huyen Mai Son',
        'NN&PTNT Huyen Moc Chau',
        'NN&PTNT Huyen Muong La',
        'NN&PTNT Huyen Phu Yen',
        'NN&PTNT Huyen Quynh Nhai',
        'NN&PTNT Huyen Song Ma',
        'NN&PTNT Huyen Thuan Chau',
        'NN&PTNT Huyen Yen Chau',
        'NN&PTNT TP Son La',
        'NN&PTNT Tinh Son La',
    ],
    'Tay Ninh': [
        '',
        'NN&PTNT CN Ben Cau',
        'NN&PTNT CN Chau Thanh Tay Ninh',
        'NN&PTNT CN Duong Minh Chau',
        'NN&PTNT CN Go Dau',
        'NN&PTNT CN Hoa Thanh',
        'NN&PTNT CN KCN Trang Bang',
        'NN&PTNT CN KV Mia duong Tan Hung',
        'NN&PTNT CN Tan Bien',
        'NN&PTNT CN Tan Chau',
        'NN&PTNT CN Trang Bang',
        'NN&PTNT Tay Ninh',
    ],
    'Thai Binh': [
        '',
        'NN&PTNT CN Dong Hung',
        'NN&PTNT CN Hung Ha',
        'NN&PTNT CN Khu vuc I',
        'NN&PTNT CN Kien Xuong',
        'NN&PTNT CN Quynh Phu',
        'NN&PTNT CN Thai Thuy',
        'NN&PTNT CN Tien Hai',
        'NN&PTNT CN Vu Thu',
        'NN&PTNT TP Thai Binh',
        'NN&PTNT Tinh Thai Binh',
    ],
    'Thai Nguyen': [
        '',
        'NN&PTNT  Dai Tu',
        'NN&PTNT  Dinh Hoa',
        'NN&PTNT  Dong Hy',
        'NN&PTNT  Pho Yen',
        'NN&PTNT  Phu Binh',
        'NN&PTNT  Phu Luong',
        'NN&PTNT  Song Cong',
        'NN&PTNT  TP Thai Nguyen',
        'NN&PTNT  Vo Nhai',
        'NN&PTNT CN Song Cau',
        'NN&PTNT Tinh Thai Nguyen',
    ],
    'Thanh Hoa': [
        '',
        'NN&PTNT CN Ba Dinh',
        'NN&PTNT CN Ba Thuoc',
        'NN&PTNT CN Cam Thuy',
        'NN&PTNT CN Dong Son',
        'NN&PTNT CN Ha Trung',
        'NN&PTNT CN Hau Loc',
        'NN&PTNT CN Hoang Hoa',
        'NN&PTNT CN Lam Son',
        'NN&PTNT CN Lang Chanh',
        'NN&PTNT CN Muong Lat',
        'NN&PTNT CN Nga Son',
        'NN&PTNT CN Ngoc Lac',
        'NN&PTNT CN Nhu Thanh',
        'NN&PTNT CN Nhu Xuan',
        'NN&PTNT CN Nong Cong',
        'NN&PTNT CN Quan Hoa',
        'NN&PTNT CN Quan Son',
        'NN&PTNT CN Quang Xuong',
        'NN&PTNT CN So 2',
        'NN&PTNT CN So 3',
        'NN&PTNT CN So 4',
        'NN&PTNT CN Thach Thanh',
        'NN&PTNT CN Thieu Hoa',
        'NN&PTNT CN Tho Xuan',
        'NN&PTNT CN Thuong Xuan',
        'NN&PTNT CN Tinh Gia',
        'NN&PTNT CN Trieu Son',
        'NN&PTNT CN Vinh Loc',
        'NN&PTNT CN Yen Dinh',
        'NN&PTNT Nghi Son',
        'NN&PTNT Sam Son Thanh Hoa',
        'NN&PTNT TP Thanh Hoa',
        'NN&PTNT TX Bim Son',
        'NN&PTNT Tinh Thanh Hoa',
    ],
    'Thua Thien Hue': [
        '',
        'NN&PTNT CN Bac Song Huong',
        'NN&PTNT CN Nam Song Huong',
        'NN&PTNT CN Truong An',
        'NN&PTNT Huyen A Luoi',
        'NN&PTNT Huyen Huong Thuy',
        'NN&PTNT Huyen Huong Tra',
        'NN&PTNT Huyen Nam Dong',
        'NN&PTNT Huyen Phu Loc',
        'NN&PTNT Huyen Phu Vang',
        'NN&PTNT Huyen Quang Dien',
        'NN&PTNT Phong Dien',
        'NN&PTNT Thua Thien Hue',

    ],
    'Tien Giang': [
        '',
        'NN&PTNT CN Cai Be',
        'NN&PTNT CN Chau Thanh',
        'NN&PTNT CN Cho Gao',
        'NN&PTNT CN Go Cong Dong',
        'NN&PTNT CN Go Cong Tay',
        'NN&PTNT CN Tan Phu Dong',
        'NN&PTNT CN Tan Phuoc',
        'NN&PTNT KCN My Tho',
        'NN&PTNT TP My Tho',
        'NN&PTNT TX Cai Lay',
        'NN&PTNT Thi Xa Go Cong',
        'NN&PTNT Tinh Tien Giang',
    ],
    'Tra Vinh': [
        '',
        'NN&PTNT CN Cang Long',
        'NN&PTNT CN Cau Ke',
        'NN&PTNT CN Cau Ngang',
        'NN&PTNT CN Chau Thanh Tra Vinh',
        'NN&PTNT CN Duyen Hai',
        'NN&PTNT CN So 1',
        'NN&PTNT CN So 2',
        'NN&PTNT CN Tieu Can',
        'NN&PTNT CN Tra Cu',
        'NN&PTNT TP Tra Vinh',
        'NN&PTNT Tinh Tra Vinh',
    ],
    'Vinh Long': [
        '',
        'NN&PTNT CN Binh Tan Vinh Long',
        'NN&PTNT CN Long Ho',
        'NN&PTNT CN Mang Thit',
        'NN&PTNT CN Tam Binh',
        'NN&PTNT CN Tra on',
        'NN&PTNT CN Vung Liem',
        'NN&PTNT KCN Hoa Phu',
        'NN&PTNT TP Vinh Long',
        'NN&PTNT TX Binh Minh',
        'NN&PTNT Tinh Vinh Long',
    ],
    'Tuyen Quang': [
        '',
        'NN&PTNT Huyen Chiem Hoa',
        'NN&PTNT Huyen Ham Yen',
        'NN&PTNT Huyen Lam Binh - Tuyen Quang',
        'NN&PTNT Huyen Na Hang',
        'NN&PTNT Huyen Son Duong',
        'NN&PTNT Huyen Yen Son',
        'NN&PTNT TP Tuyen Quang',
        'NN&PTNT Tinh Tuyen Quang',
    ],
    'Vinh Phuc': [
        '',
        'NN&PTNT CN Phuc Yen',
        'NN&PTNT CN TP Vinh Yen',
        'NN&PTNT CN Tho Tang',
        'NN&PTNT Huyen Binh Xuyen',
        'NN&PTNT Huyen Lap Thach',
        'NN&PTNT Huyen Song Lo',
        'NN&PTNT Huyen Tam Dao',
        'NN&PTNT Huyen Tam Duong',
        'NN&PTNT Huyen Vinh Tuong',
        'NN&PTNT Huyen Yen Lac',
        'NN&PTNT Tinh Vinh Phuc',
        "NN&PTNT CN Vinh Phuc II"
    ],
    'Yen Bai': [
        '',
        'NN&PTNT CN Luc Yen',
        'NN&PTNT CN Mu Cang Chai',
        'NN&PTNT CN Tram Tau',
        'NN&PTNT CN Tran Yen',
        'NN&PTNT CN Van Chan',
        'NN&PTNT CN Van Yen',
        'NN&PTNT CN Yen Binh',
        'NN&PTNT TP Yen Bai',
        'NN&PTNT TP Yen Bai II',
        'NN&PTNT TX Nghia Lo',
        'NN&PTNT Tinh Yen Bai',
    ],
}
export const LIST_TYPE = [
    {
        id: 1,
        name: 'Showroom'
    },
    {
        id: 2,
        name: 'Cơ sở học & tư vấn'
    },
    {
        id: 3,
        name: 'Cơ sở học'
    },
];