<template>
  <fragment>
    <li class="menu-section">
      <h4 class="menu-text">CRM</h4>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-if="currentUser.user.permission.includes(permission.KHACH_HANG)">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-people-arrows"></i>
        <span class="menu-text">Khách hàng</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link :to="{ name : 'customer-user'}" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.DS_KHACH_HANG)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Danh sách khách hàng</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'customer-crm'}" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.XEM_QLKH)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Quản lý khách hàng</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'term-policy'}" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                v-if="currentUser.user.permission.includes(permission.QUAN_LY_DIEU_KHOAN_CHINH_SACH)"
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Quản lý điều khoản & chính sách</span>
              </a>
            </li>
          </router-link>
<!--          <router-link :to="{ name : 'policy-manage'}" v-slot="{ href, navigate, isActive, isExactActive }">-->
<!--            <li-->
<!--                v-if="currentUser.user.permission.includes(permission.QUAN_LY_DIEU_KHOAN_CHINH_SACH)"-->
<!--                aria-haspopup="true"-->
<!--                data-menu-toggle="hover"-->
<!--                class="menu-item"-->
<!--                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"-->
<!--            >-->
<!--              <a :href="href" class="menu-link" @click="navigate">-->
<!--                <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
<!--                <span class="menu-text">Quản lý phân quyền chương trình học</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->
        </ul>
      </div>
    </li>
    <router-link
        :to="{ name : 'contract-index'}" class="menu-link menu-toggle"
        v-slot="{ href, navigate, isActive, isExactActive }"
        v-if="currentUser.user.permission.includes(permission.DS_HD)">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-file-alt"></i>
          <span class="menu-text">Danh sách hợp đồng</span>
        </a>
      </li>
    </router-link>
    <router-link
        v-if="currentUser.user.permission.includes(permission.XEM_DANH_SACH_TICKET)"
        :to="{ name : 'ticket-index'}" class="menu-link menu-toggle"
        v-slot="{ href, navigate, isActive, isExactActive }">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-file-alt"></i>
          <span class="menu-text">Chăm sóc học viên</span>
        </a>

      </li>
    </router-link>
    <router-link
        v-if="currentUser.user.permission.includes(permission.LICH_SU_CUOC_GOI)"
        :to="{ name : 'history-call-stringee'}" class="menu-link menu-toggle"
        v-slot="{ href, navigate, isActive, isExactActive }">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-file-alt"></i>
          <span class="menu-text">Lịch sử cuộc gọi</span>
        </a>

      </li>
    </router-link>
    <router-link
        v-if="currentUser.user.permission.includes(permission.BAO_CAO_CUOC_GOI)"
        :to="{ name : 'call-statistics'}" class="menu-link menu-toggle"
        v-slot="{ href, navigate, isActive, isExactActive }">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-file-alt"></i>
          <span class="menu-text">Báo cáo cuộc gọi</span>
        </a>

      </li>
    </router-link>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-if="currentUser.user.permission.includes(permission.XU_LY_PHAN_HOI)"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-people-arrows"></i>
        <span class="menu-text">Xử lý phản hồi</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <!--          <li aria-haspopup="true"  data-menu-toggle="hover" class="menu-item">-->
          <!--            <a href="#" class="menu-link">-->
          <!--              <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
          <!--              <span class="menu-text">Xử lý phản hồi</span>-->
          <!--            </a>-->
          <!--          </li>-->
          <router-link
              v-if="currentUser.user.permission.includes(permission.XEM_DANH_SACH_HO_TRO)"
              :to="{ name : 'crm-ticket-index'}" class="menu-link menu-toggle"
              v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Call center xử lý</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'feedback-list'}" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.XEM_DANH_SACH_PHONG_BAN_XU_LY)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Phòng ban xử lý</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link :to="{ name : 'category-list'}" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.QUAN_LY_DANH_MUC)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Quản lý danh mục</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <router-link
        :to="{ name : 'report-error-response-handling-index'}" class="menu-link menu-toggle"
        v-slot="{ href, navigate, isActive, isExactActive }"
        v-if="currentUser.user.permission.includes(permission.BANG_BAO_LOI_XU_LY_PHAN_HOI)"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-bug"></i>
          <span class="menu-text">Bảng báo lỗi Xử lý phản hồi</span>
        </a>
      </li>
    </router-link>
  </fragment>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Crm",
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
