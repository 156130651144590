<template>
    <select class="form-control" id="select-customer" v-model="selected">
    </select>
</template>

<script>
    export default {
        name: 'SelectCustomer',
        props: ['value', 'url', 'method', 'selected', 'placeholder', 'minimumInputLength', 'date1907'],
        mounted: function () {
            var vm = this;
            $(this.$el)
            // init select2
                .select2({
                    ajax: {
                        url: this.url,
                        dataType: 'json',
                        method: this.method,
                        cache: false,
                        xhrFields: {
                          withCredentials: true
                        },
                        data: function (params) {
                            var query = {
                                search_customer_by_phoneOrId: params.term,
                                limit: 10000,
                                status: 1,
                                is_staff: 0,
                                date_197: vm.date1907
                            };
                            // Query parameters will be ?search=[search]&_type=query&q=q
                            return query;
                        },
                        // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
                        processResults: function (data) {
                            if (!data.data.data.length){
                                vm.$emit('napRut197')
                            }
                            return {
                                results: $.map(data.data.data, function (item) {
                                    return {
                                        text: (item.id + 100000) + ' - ' + item.name + ' - ' + item.phone,
                                        id: item.id,
                                    }
                                })
                            };
                        }
                    },
                    placeholder: this.placeholder,
                    minimumInputLength: this.minimumInputLength,
                    allowClear: true,
                    language: {
                      "noResults": function () {
                        return "Không tìm thấy học viên";
                      }
                    }
                })
                .val(this.value)
                .trigger('change')
                // emit event on change.
                .on('change', function () {
                    vm.$emit('input', this.value);
                });
        },
        watch: {
            value: function (value) {
                // update value
                $(this.$el).val(value).trigger('change');
            },
            options: function (options) {
                // update options
                $(this.$el)
                    .empty()
                    .select2({data: options});
            }
        },
        destroyed: function () {
            $(this.$el)
                .off()
                .select2('destroy');
        }
    };
</script>

<style>
</style>
