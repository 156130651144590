<template id="ExtendHandle">
    <div>
        <button @click="handleOk"
                class="btn btn-success btn-sm"
                :class="{ 'spinner spinner-white spinner-left': loadingExtend}"
                :disabled="loadingExtend"
        >Gia hạn xử lý 
        </button>
        <b-modal id="extend-ticket"
        title="Gia hạn xử lý ticket"
        size="xs"
        ok-title="Cập nhật"
        centered
        hide-footer
>
   <ValidationObserver v-slot="{ handleSubmit }" ref="form">
       <form>
                    <label class="fs-1" style="font-size: 14px">Vui lòng chọn thời gian để gia hạn xử lý cho ticket  {{ ticket.id }}</label>
                    <label class="fs-1 mt-2" style="color:rgba(69, 70, 116, 1); font-size: 14px">Thời gian gia hạn<span class="text-danger"> *</span></label>
                     <div class="form-group">
                       <div class="d-flex">
                         <div class="mr-10">
                           <el-select filterable @change="checkTimeExtend()" @blur="checkTimeExtend" clearable style="width:150px" v-model="time_extend_hours" placeholder="Chọn giờ">
                             <el-option
                                 v-for="hour in hours"
                                 :key="hour"
                                 :label="hour"
                                 :value="hour">
                             </el-option>
                           </el-select>
                         </div>
                         <div>
                           <el-select filterable @change="checkTimeExtend()" @blur="checkTimeExtend" clearable style="width:150px" v-model="time_extend_minute" placeholder="Chọn phút">
                             <el-option
                                 v-for="minute in minutes"
                                 :key="minute"
                                 :label="minute"
                                 :value="minute">
                             </el-option>
                           </el-select>
                         </div>
                       </div>
                     </div>
                     <span v-if="is_check_time" class="text-danger">
                       Vui lòng nhập thời gian gia hạn ticket
                     </span>
       </form>

   </ValidationObserver>
   <b-button
           style="background-color: rgb(93, 4, 176); color: #ffff"
           size="sm"
           class="float-right"
           :disabled="loadingExtend"
           :class="{'spinner spinner-white spinner-right' : loadingExtend}"
           @click="extendTicket()"
   >
       Xác nhận
   </b-button>
   <b-button
   style="background-color: #cacaca; color: black"
   size="sm"
   class="float-right mr-1"
   :disabled="loadingExtend"
   :class="{'spinner spinner-white spinner-right' : loadingExtend}"
   @click="closeModal"
>
Huỷ bỏ
</b-button>
</b-modal>
    </div>
</template>

<script>
    import {EXTEND_DEADLINE_TICKET} from "../../../../../core/services/store/user/ticket.module";
    import alert from "../../../vue-bootstrap/Alert.vue";
    export default {
        name: "CancelMoveDepartment",
        props: {
            ticket: {
                type: Object,
                default: () => {
                    return null;
                }
            },
        },
        data() {
            return {
                is_check_time: false,
                time_extend_hours: '',
                time_extend_minute: '',
                loadingExtend: false,
                hours: Array.from({ length: 1000 }, (_, index) => index),
                minutes: Array.from({ length: 60 }, (_, index) => index)
            }
        },

        
        methods: {
        handleOk() {
            this.$bvModal.show('extend-ticket');
        },

        checkTimeExtend() {
          if(!this.time_extend_hours && !this.time_extend_minute) {
            this.is_check_time = true
            return false;
          }
          this.is_check_time = false
        },

        extendTicket() {
            this.checkTimeExtend();
            if(this.is_check_time) {
              return false;
            }

            this.loadingExtend = true;
            this.$store.dispatch(EXTEND_DEADLINE_TICKET, {
                    id: this.ticket.id,
                    time_extend_hours: this.time_extend_hours ? this.time_extend_hours : 0,
                    time_extend_minute: this.time_extend_minute ? this.time_extend_minute : 0 
                }).then((res) => {
                    if (!res.error) {
                        this.$bvToast.toast('Gia hạn xử lý thành công', {
                            title: 'Thành công',
                            variant: 'success',
                            solid: true
                        });
                        this.loadingExtend = false;
                        this.$emit('extendHandle', true);
                    }
                }).catch((e) => {
                    this.$bvToast.toast('Không thể gia hạn khi ticket đã nhập giải pháp hoặc đã báo lỗi', {
                        title: 'Thất bại!',
                        variant: 'danger',
                        solid: true
                    });
                });          
            this.closeModal();

        },
            closeModal() {
                this.$bvModal.hide('extend-ticket');
                this.time_extend_hours = ''; 
                this.time_extend_minute = '';
            },

          // openModal() {
          //   console.log('111111111111111');
          // }
        }
    }
</script>

<style>
    /* Chrome, Safari, Edge, Opera */
    #ExtendHandle input::-webkit-outer-spin-button,
     input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    #ExtendHandle input[type=number] {
    -moz-appearance: textfield;
}
</style>