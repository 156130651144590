<template>
  <div class="table-responsive mt-3" style="overflow: auto; padding-bottom: 30px; max-height: 70vh">
    <table class="table" style="text-align: center;">
      <thead>
      <tr style="border: 1px solid #EBEDF3">
        <th scope="col" class="w-120px header-table">Mã nhân sự</th>
        <th scope="col" class="w-80px header-table">Mốc tích lũy</th>
        <th scope="col" class="w-120px header-table">Thời điểm bắt đầu tích lũy</th>
        <th scope="col" class="w-130px header-table">Thời điểm trái phiếu có hiệu lực</th>
        <th scope="col" class="w-150px header-table">Thời điểm được chuyển nhượng/ bán</th>
        <th scope="col" class="w-80px header-table">Năm thứ</th>
        <th scope="col" class="w-150px header-table">Giá trị</th>
        <th scope="col" class="min-w-180px header-table">Lỗi</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item of responseError" :key="item?.index" style="border: 1px solid #EBEDF3; border-top: 0">
        <td style="text-align: start">{{item?.userId}}</td>
        <td style="text-align: start">{{item?.milestone}}</td>
        <td style="text-align: start">{{item?.startDate}}</td>
        <td style="text-align: start">{{item?.soldDate}}</td>
        <td style="text-align: start">{{item?.endDate}}</td>
        <td style="text-align: start">{{item?.year}}</td>
        <td style="text-align: start">{{typeof item?.value === 'number' ? formatMoney(item?.value) : item?.value}}</td>
        <td><div v-if="item.errors.length > 0" style="text-align: start; display: flex; flex-direction: column">
          <span v-for="(error, index) in item.errors" :key="index + error" style="word-break: break-word">
            {{error}}
          </span>
        </div></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {formatPrice} from "@/core/filters";

export default {
  name: 'TableErrorBond',
  data() {
    return {}
  },
  props: {
    responseError: {
      type: Array,
      default: []
    }
  },
  methods: {
    formatMoney(value) {
      return formatPrice(value);
    }
  }
}
</script>

<style lang="css" scoped>
.header-table {
  background: #F8F8F8;
  text-align: start;
  word-break: break-word;
}
</style>