<template>
    <tr class="group-color-yet">
        <td></td>
        <td colspan="7"><b>Chưa có team</b></td>
    </tr>
</template>

<script>
export default {
    name: "RowYet"
}
</script>

<style scoped>

</style>