import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const GET_LIST_TOTAL_ASSET = "get_list_total_asset";
export const EXPORT_FINANCE_REPORT = "finance/total-asset-log/export";
export const EXPORT_SHAREHOLDER_REPORT = 'finance/stock/export'


const state = {};
const getters = {};
const actions = {
    /**
     * Get categories
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_LIST_TOTAL_ASSET](context, payload) {
        return new Promise(resolve => {
            ApiService.query('finance/total-asset-log', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [EXPORT_FINANCE_REPORT](context, payload) {
        return new Promise(resolve => {
            ApiService.post(EXPORT_FINANCE_REPORT, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [EXPORT_SHAREHOLDER_REPORT](context, payload) {
        return new Promise(resolve => {
            ApiService.post(EXPORT_SHAREHOLDER_REPORT, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
