<template>
  <div>
    <button type="button" class="btn btn-success btn-sm ml-4" v-b-modal="`Modal_Import_Schedule`">
      <i class="el-icon-upload2"></i>
      Import
    </button>
    <b-modal id="Modal_Import_Schedule" title="Import lịch thi đấu" centered hide-footer size="lg"
             ref="Modal_Import_Schedule" @hidden="hideModal">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent v-if="!scheduleError">
          <div>
            <div class="row import">
              <div class="col-md-12">
                <p>Bước 1: Tải file mẫu</p>
                <button type="button" class="btn btn-info btn-sm" @click="downloadFile()"><i
                    class="flaticon-download"></i>Tải
                  file mẫu
                </button>
              </div>
              <div class="col-md-12 mt-4">
                <p>Bước 2: Tải file excel lên hệ thống</p>
                <div class="file-input">
                  <ValidationProvider name="type_task" rules="required|max:30" v-slot="{ errors,classes }"
                                      vid="type_task">
                    <input
                        ref="fileSchedule"
                        type="file"
                        name="file-input-team"
                        id="file-input-team"
                        class="file-input__input"
                        @change="getDataFile"
                        :disabled="disableImport"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                    <label class="file-input__label" for="file-input-team">
                      <span><i class="flaticon-upload mr-2" style="color: #000000"></i>Tải lên</span></label
                    >
                  </ValidationProvider>
                  <div v-if="fileDataImport">
                    <p class="badge badge-secondary  p-2 ">{{ fileDataImport.name }}</p>
                    <i class="fas fa-trash ml-3 text-danger  " style="font-size: 13px; cursor: pointer"
                       @click="deleteFileExcel()"></i>
                  </div>
                  <div v-else class="text-danger">
                    vui lòng chọn file
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex d-flex flex-row-reverse">
            <button type="button" class="btn btn-success ml-4" @click="previewImportExcel()" :disabled="loading">
              <p class="mb-0" v-if="!loading">
                Xác nhận
              </p>
              <div v-else class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
            <button type="button" class=" btn btn-secondary" @click="cancelModal()">Hủy</button>
          </div>
        </form>
        <div v-else>
          <p>Hệ thống phát hiện có lỗi trong quá trình import dữ liệu:</p>
          <div class="mt-5 table-responsive">
            <table class="table table-bordered  table-vertical-center ">
              <thead>
              <tr class="" style="background-color: #F8F8F8 ; font-size: 13px">
                <th>Trận</th>
                <th>Cặp thi đấu</th>
                <th>Thời gian đấu</th>
                <th>Lỗi</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(error,index) in errorSchedules">
                <td class="">{{ error.schedule.Stt }}</td>
                <td class="">{{ error.schedule.Team1 }}<br>{{ error.schedule.Team2 }}</td>
                <td>
                  <p> {{ error.schedule.Day }} - {{ error.schedule.Time }}</p>
                </td>
                <td>
                  <p class="text-danger" v-for="item in error.error">
                    {{ item }}
                  </p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary" @click="comeback()">Quay lại</button>
          </div>
        </div>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import {utils, read} from "xlsx";
import {groupBy} from "core-js";
import moment from 'moment-timezone';

import {
  DOWNLOAD_SCHEDULE,
  DOWNLOAD_TEAM,
  IMPORT_SCHEDULE,
  IMPORT_TEAM
} from "@/core/services/store/tournament/tournament.module";

export default {
  name: "ModalImportSchedule",
  data() {
    return {
      fileDataImport: null,
      importData: null,
      disableImport: false,
      errorSchedules: [],
      scheduleError: false,
      disableSave: false,
      loading: false
    }
  },
  methods: {
    hideModal() {
      this.fileDataImport = null
      this.importData = null
      this.disableImport = false
      this.errorQuestions = []
      this.scheduleError = false
      this.disableSave = false
    },
    async getDataFile(e) {
      if (e.target.files.length) {
        this.disableImport = true
        this.fileDataImport = e.target.files[0]
        this.importData = await this.fileToJson(this.fileDataImport)
        this.importData = this.importData.map((data) => {
          let fromExcel = data.Time; //translates to 17:02:00
          let basenumber = (fromExcel * 24)
          let hour = Math.floor(basenumber).toString();

          var minute = Math.round((basenumber % 1) * 60).toString();
          if (minute.length < 2) {
            minute = '0' + minute;
          }
          if(minute == '60'){
            minute = '00'
            hour = +hour + 1
          }
          if (hour.length < 2) {
            hour = '0' + hour;
          }
          let Timestring = (hour + ':' + minute + ':00');
          var utc_days = Math.floor(data.Day - 25569);
          var utc_value = utc_days * 86400;
          var date_info = new Date(utc_value * 1000);
          let date = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate())
          date = moment(date).format("YYYY-MM-DD")
          if(data.Time == undefined){
            Timestring = null
          }
          if(data.Day ==undefined){
            date = null
          }
          return {...data, Time: Timestring, Day: date}
        })
      }
    },
    fileToJson(file) {
      return new Promise((res, rej) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader()
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result
          const wb = read(bstr, {type: 'binary'})
          /* Get first worksheet */
          const scheduleName = wb.SheetNames[0]
          const scheduleSheet = wb.Sheets[scheduleName]
          /* Convert array of arrays */
          const schedule = utils.sheet_to_json(scheduleSheet)
          let dataImport = schedule.map((d)=>{
            return {
              Code:d.Code??null,
              Day : d.Day??null,
              Stt : d.Stt??null,
              Team1 : d.Team1??null,
              Team2 : d.Team2??null,
              Time :d.Time??null
            }
          })
          /* Update state */
          res(dataImport.filter((d) => d != []));
          //const header = data.shift()
        }
        reader.onerror = (e) => {
          rej(e);
        };
        reader.readAsBinaryString(file)
      });
    },
    checkErrorDataExel() {
      this.errorSchedules = this.importData.map((schedule, index) => {
        return this.checkScheduleError(schedule, index);
      })
    },
    checkScheduleError(schedule, index) {
      let error = []
      let fieldErrors = ['Team1', 'Team2', 'Code', 'Day', 'Time', "Stt"].map(d => {
        if (!schedule[d] || schedule[d] == '') {
          return "Thiếu trường " + d;
        }
      }).filter((a) => a != undefined);
      error = [...fieldErrors]

      let fault = this.importData.find((data, indexData) => data.Stt == schedule.Stt && indexData != index)
      if (fault) {
        error = [...error, "Stt bị trùng"]
      }
      return {schedule: schedule, error: error}

    },
    deleteFileExcel() {
      this.disableImport = false
      this.importData = null
      this.fileDataImport = null
      this.$refs.fileSchedule.value = null;
    },
    previewImportExcel() {

      if (this.importData == null) {
        return
      }
      this.checkErrorDataExel()
      this.disableSave = true
      let is_error = false
      if (this.errorSchedules.filter((error)=>error.error.length>0).length > 0) {
        this.scheduleError = true
        is_error = true
      }
      let payload = {
        id: this.$route.params.id,
        data: {
          data :this.importData.map((d) => {
            return {
              team_1: d.Team1,
              team_2: d.Team2,
              code_exam: d.Code?d.Code.toString():null,
              day: d.Day,
              start_time: d.Time,
              stt: d.Stt
            }
          }),
          is_error : is_error
        }
      }
      this.loading = true
      this.$store.dispatch(IMPORT_SCHEDULE, payload).then((data) => {
        if (!data.error) {
          this.$emit("getExam")
          this.$notify({
            title: 'Thành công',
            message: 'Import lịch thi đấu thành công',
            type: 'success'
          });
          this.$refs['Modal_Import_Schedule'].hide()
          this.$emit("getSchedule")
        } else {
          this.$notify({
            title: 'Lỗi',
            message: `${data.message}`,
            type: 'error'
          });
        }
      }).catch((error) => {
        this.scheduleError = true
        // console.log(error.data.data)
        //  error.data.data.forEach((data,index)=>{
        //   if( data?.error?.length>0 && this.errorSchedules[index].error?.length>0  ){
        //     data.error =  data?.error.concat(this.errorSchedules[index].error)
        //   }
        // })
       let errorBE = error.data.data.map((d)=>{
          return {
            schedule : {
              Stt : d.stt,
              Team1 : d.team_1,
              Team2 : d.team_2,
              Day : d.day,
              Time : d.start_time,
            },
            error : d.errors??false
          }
        })
        errorBE.forEach((error,index)=>{
          if(error.error.length>0){
            error.error = this.errorSchedules[index].error.concat(error.error)
          }
        })
        this.errorSchedules = errorBE.filter((error)=>error.error.length>0)
      }).finally(()=>{
        this.loading =false
      })
    },
    cancelModal() {
      this.$refs['Modal_Import_Schedule'].hide()
    },
    comeback() {
      this.scheduleError = false
      this.disableSave = false
    },
    downloadFile() {
      this.$store.dispatch(DOWNLOAD_SCHEDULE).then(() => {

      }).catch(() => {

      })
    },
  }
}
</script>

<style scoped>
.file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.file-input__label {
  height: 34px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 7px 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  background-color: #FF9900;
}

input:disabled + label {
  background-color: #a6a6a6;
}

</style>