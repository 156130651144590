<template>
    <div>
        <button :class="{'btn-outline-warning' : !item.schedule_mark_test, 'btn-outline-success' : item.schedule_mark_test }"
                :disabled="item.schedule_mark_test"
                title="Làm bài" v-b-modal="`confirm-do-home-work-${item.id}`"
                class="btn btn-xs btn-icon mr-2 ">
            <i class="fa far fa-play-circle"></i>
        </button>
        <b-modal :id="'confirm-do-home-work-' + item.id"
                 :centered="true"
                 :title="'Xác nhận cho phép học viên '+ item.user.name + '-' + item.user.phone + ' làm bài kiểm tra?'"
                 size="lg"
                 ok-title="Xác nhận"
                 cancel-title="Hủy bỏ"
                 @ok="doHomeWork"
        >
            <div class="container">
                <p>Đối với test online: Sau khi học viên rời zoom, màn hình sẽ hiển thị bài kiểm tra và bắt đầu chạy
                    thời
                    gian làm bài.</p>
                <p>Đối với test tại điểm: Học viên có thể chọn làm bài trong app để bắt đầu làm bài.</p>
            </div>
        </b-modal>
    </div>

</template>

<script>
    import Swal from "sweetalert2";
    import {APPROVE_DO_TEST} from "../../../../core/services/store/exam/exam.module";

    export default {
        name: "ModalConfirmDoHomework",
        props: {
            item: {
                type: Object,
                default: () => {
                    return null;
                }
            },
            test: {
                type: Object,
                default: () => {
                    return null;
                }
            }
        },
        methods: {
            doHomeWork() {
                this.$store.dispatch(APPROVE_DO_TEST, {
                    course_student_id: this.item.id,
                    schedule_test_list_id: this.test.id,
                    student_id: this.item.user.id,
                    type: this.test.type,
                    lesson: this.test.lesson,
                }).then((data) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Thông báo',
                        text: 'Cho học viên làm bài thành công. Học viên có thể bắt đầu làm bài ngay.',
                        timer: 5000
                    })
                    this.$emit('approve-success');
                });
            },
        }
    }
</script>

<style scoped>

</style>