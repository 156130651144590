<template>
    <tr>
        <td>{{ attendance.user_name }}</td>
        <td>{{ attendance.user_code }}</td>
        <td>{{ attendance.phone }}</td>
        <td class="text-center" v-if="attendance.status_class == 0">
            <el-checkbox v-model="attendance_A" @change="changeAttendance(1, attendance.id)"
                            class="checkbox-calendar"
                            key="1"></el-checkbox>
        </td>
        <td class="text-center" v-if="attendance.status_class == 0">
            <el-checkbox v-model="attendance_N" @change="changeAttendance(0, attendance.id)"
                            class="checkbox-calendar"
                            key="2"></el-checkbox>
        </td>
        <td class="text-center" v-if="attendance.status_class == 0">
            <el-checkbox v-model="attendance_P" @change="changeAttendance(2, attendance.id)"
                            class="checkbox-calendar"
                            key="3"></el-checkbox>
        </td>
        <td v-else colspan="3" ><div class="bold text-center">Học viên đã tạm dừng học lớp này</div></td>
    </tr>
</template>

<script>
    export default {
        name: "AttendanceItem",
        props: {
            attendance: {
                type: Object,
                default: () => {
                    return null;
                }
            },
        },
        data() {
            return {
                attendance_A: null,
                attendance_N: null,
                attendance_P: null,
            }
        },
        created() {
            this.checkBeforeBtn();
        },
        methods: {
            checkBeforeBtn() {
                if (this.attendance.attendance != null) {
                    this.switchBtn(this.attendance.attendance);
                }
            },
            changeAttendance(type, course_student_id) {
                this.switchBtn(type);
                if (!this.attendance_P && !this.attendance_N && !this.attendance_A) {
                    type = null;
                }
                this.$emit('changeAttendance', type, course_student_id)
            },
            switchBtn(type) {
                switch (type) {
                    case 1:
                        this.attendance_P = false;
                        this.attendance_N = false;
                        this.attendance_A = true;
                        break;
                    case 0:
                        this.attendance_P = false;
                        this.attendance_A = false;
                        this.attendance_N = true;
                        break;
                    default:
                        this.attendance_A = false;
                        this.attendance_N = false;
                        this.attendance_P = true;
                        break;
                }
            }
        }
    }
</script>

<style scoped>

</style>