<template>
  <div class="example-preview table-responsive">
    <table class="table table-bordered text-center table-vertical-center ">
      <thead>
      <tr class="text-center">
        <th colspan="2" class="shift">Ca trực</th>
        <th class="calendar" v-for="(item, index) in days" :key="index">{{ item.dayOfWeek }}<br> <span
            class="text-secondary ">({{ item.day }})</span></th>
      </tr>
      </thead>
      <tbody v-for="shift in [1,2,3]">
      <tr v-for="(hour,index) in SHIFTS.get(shift).filter((shift2,indexShift)=>indexShift!=SHIFTS.get(shift).length -1)">
        <td v-if="index === 0" :rowspan="SHIFTS.get(shift).length ">
          {{ shift === 1 ? "Sáng" : shift === 2 ? "Chiều" : "Tối" }}
        </td>
        <td class="pl-0">
          <p v-if="index===0" style="position: relative ;top: -8px ; left: 8px ">{{ hour }}</p>
          <div v-else-if="index===SHIFTS.get(shift).length-2" style="height: 35px" >
            <p style="position: relative ;top: -18px ;background-color: white; " class="pl-5">{{ hour }}</p>
            <p class="mb-0 p-0" style="position: relative ; left: 7px ; bottom: 10px "> {{  SHIFTS.get(shift)[index+1] }}</p>
          </div>
          <p v-else style="position: relative ;top: -20px ;background-color: white; " class="pl-5">{{ hour }}</p>
        </td>
        <td v-for="(day,indexDay) in days" class="calendar">
         <div v-if="index === 0 || index === SHIFTS.get(shift).length - 2 "></div>
          <div v-else>
            <span v-if="typeTest==null || (typeTest=== OFFLINE && branch == ``) "></span>
            <span v-else class=" badge " :class="{'badge-secondary':handleTesters(hour, day.day).length===0,'badge-primary' :handleTesters(hour, day.day).length !==0 }" data-toggle="tooltip" data-placement="top"
                  :title="currentTestersHide(handleTesters(hour,day.day))" style="	cursor: pointer ">{{
                handleTesters(hour, day.day).length===0?"Không có admin":`${handleTesters(hour, day.day).length} admin`
              }}</span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
import {OFFLINE, SHIFTS2, SHIFTS3} from "@/core/option/testInputOption";

export default {
  name: "EntranceTestScheduleItem",
  props: {
    days: {
      type: Array,
      default: () => {
        return [];
      }
    },
    testingSchedule: {
      type: Object,
      default: () => {
        return {}
      }
    },
    typeTest:{
      type:Number,
      default:()=>{
        return null
      }
    },
    branch:{
      type:String,
      default:()=>{
        return ""
      }
    }
  },
  async mounted() {


  },
  data() {
    return {
      timeFrame: ["08:30", "09:00", "09:30", "10:30", "11:00", "11:30", "12:00", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00"],
      SHIFTS: SHIFTS3,
      OFFLINE:OFFLINE,
      SHIFTS3: SHIFTS3,
      SHIFTS2: SHIFTS2,
    }
  },
  watch:{
    typeTest(newValue){
     this.SHIFTS =  this.SHIFTS3
    }
  },
  methods: {
    getValueByPropertyName(obj, pro) {
      return obj?.hasOwnProperty(pro) === true ? obj[pro] : {} | [];
    },
    findObjectDateByTime(hour) {
      return this.getValueByPropertyName(this.dataHourMorning, hour);
    },
    findTestersByDate(hour, day) {
      let objectDate = this.findObjectDateByTime(hour)
      return this.getValueByPropertyName(objectDate, day.split("-").reverse().join("-"));
    },
    handleTesters(hour, day) {
      let list = this.findTestersByDate(hour, day)
      if (!list) {
        return [];
      }

      return list
    },
    currentTestersHide(listTesters) {
      let strTestersHide = ''
      for (let i = 0; i < listTesters.length; i++) {
        strTestersHide += `\n${listTesters[i]} `
      }
      return strTestersHide
    }

  },
  computed: {
    dataHourMorning() {
      return this.testingSchedule
    },
  }
}
</script>

<style scoped>
.shift {
  width: 6.6%;
}

.calendar {
  width: 13.2%;

}

.badge {
  cursor: default;
}

.text-secondary {
  font-size: 11px;
  color: #888888 !important;
}
</style>