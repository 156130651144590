<template>
  <div class="container">
    <div class="form" v-if="ticket.feedback_ticket_star > 0">
      <div class="form-group d-flex">
        <label>Số sao</label>
        <el-rate
            v-model="ticket.feedback_ticket_star"
            class="ml-5"
            disabled>
        </el-rate>
      </div>
      <div class="form-group">
        <label for="ticket_comment">Nội dung đánh giá</label>
        <el-input type="textarea" id="ticket_comment" :rows="5" v-model="ticket.feedback_ticket_comment"></el-input>
      </div>
    </div>
    <div class="form" v-else-if="ticket.feedback_ticket_star === 0">
      Khách hàng không đánh giá
    </div>
    <div class="form" v-else>
      Khách chưa đánh giá
    </div>
  </div>
</template>

<script>
export default {
  name: "Comment",
  props: {
    ticket: {
      type: Object,
      default: () => { return {} }
    }
  }
}
</script>

<style scoped>

</style>