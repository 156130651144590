import { render, staticRenderFns } from "./AccountTypeIndex.vue?vue&type=template&id=4b6a8608&scoped=true"
import script from "./AccountTypeIndex.vue?vue&type=script&lang=js"
export * from "./AccountTypeIndex.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b6a8608",
  null
  
)

export default component.exports