<template>
  <div>
    <button class="btn btn-info font-weight-bold btn-sm"
            v-b-modal="`task-suggest`">
      <i class="flaticon-star"></i>
      Gợi ý
    </button>
    <b-modal
        centered id="task-suggest"
        size="xl"
        title="Gợi ý người phụ trách"
        hide-footer
        @show="resetModal"
        @hidden="resetModal"
    >
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent>
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="mb-8">
                <label class="d-block">Loại tác vụ<span class="ml-2 text-danger">*</span></label>
                <ValidationProvider name="Tác vụ" rules="required" v-slot="{ errors,classes }"
                                    vid="tester_id">
                  <el-select v-model="query.taskTypeId" placeholder="Chọn tác vụ" class="w-100"
                             @change="resetTimeStartEnd">
                    <el-option
                        v-for="item in taskTypes"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="mb-8">
                <label>Ngày làm<span class="ml-2 text-danger">*</span></label>
                <ValidationProvider name="Ngày bắt đầu" rules="required" v-slot="{ errors,classes }"
                                    vid="day">
                  <date-picker
                      v-model="query.day"
                      type="date"
                      placeholder="Thời gian bắt đầu"
                      size="small"
                      format="DD-MM-YYYY"
                      :disabled-date="disabledBeforeToday"
                  >
                  </date-picker>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="mb-8">
                <label>Thời gian bắt đầu<span class="ml-2 text-danger">*</span></label>
                <ValidationProvider name="Thời gian bắt đầu" rules="required" v-slot="{ errors,classes }"
                                    vid="time_start">
                  <date-picker
                      v-model="query.timeStart"
                      type="time"
                      placeholder="Thời gian bắt đầu"
                      size="small"
                      :time-picker-options="time_picker_options"
                      :disabled-time="disableOptionTimeStart"
                      :disabled=isDisableTimeStart
                      @change="onStartTimeChanged"
                  >
                  </date-picker>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="mb-8">
                <label>Thời gian kết thúc<span class="ml-2 text-danger">*</span></label>
                <ValidationProvider name="Thời gian kết thúc" rules="required" v-slot="{ errors,classes }"
                                    vid="time_end">
                  <date-picker
                      v-model="query.timeEnd"
                      type="time"
                      placeholder="Thời gian bắt đầu"
                      size="small"
                      :time-picker-options="time_picker_options"
                      :disabled-time="disableOptionTimeEnd"
                      :disabled=isDisableTimeEnd

                  >
                  </date-picker>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="mb-8 ">
                <label class="d-block">Địa điểm làm việc</label>
                <el-select v-model="query.workLocation" placeholder="Chọn địa điểm làm việc (nếu có)" class="w-100"
                           filterable clearable
                >
                  <el-option
                      v-for="item in workLocation"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
              </div>
              <div class="d-flex flex-row-reverse">
                <button type="submit" class="btn btn-info font-weight-bold btn-sm " @click="handleSubmit(getDataQuery)"
                        style="width: 70px">
                  Gợi ý
                </button>
              </div>


            </div>
            <div class="col-lg-8 col-12">
              <label class="d-block">Danh sách người phụ trách phù hợp</label>

              <div class="table">
                <table class="table table-bordered">
                  <thead>
                  <tr class="w-100">
                    <th style="max-width: 40%">Nhân sự Admin</th>
                    <th style="max-width: 20%">% Khối lượng CV</th>
                    <th style="max-width: 40%">Cơ sở trực offline</th>
                  </tr>
                  </thead>
                  <tbody v-if="loading == null">
                  <tr>
                    <td colspan="3" class="text-center">Chưa có dữ liệu.</td>
                  </tr>
                  </tbody>
                  <tbody v-if="loading">
                  <tr>
                    <td colspan="3" class="text-center">
                      <b-spinner label="Loading..."></b-spinner>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else-if="loading==false&&listOfPeopleInCharge.length==0">
                  <tr>
                    <td colspan="3" class="text-center">Không tìm thấy người phụ trách phù hợp.</td>
                  </tr>
                  </tbody>
                  <tbody v-else v-for="peopleInCharge in listOfPeopleInCharge">
                  <tr>
                    <td>{{ peopleInCharge.toString() }}</td>
                    <td>{{
                        `${Number.isInteger(peopleInCharge.percent) ? peopleInCharge.percent : peopleInCharge.percent.toFixed(2)}%`
                      }}
                    </td>
                    <td>
                    <span>
                      {{ peopleInCharge.mainBranch }}
                    </span>
                      <span v-if="peopleInCharge.otherBranches.length > 0" class="ml-2 badge badge-secondary"
                            :title="currentBranchesHide(peopleInCharge.otherBranches)">
                      +{{ peopleInCharge.otherBranches.length }}
                    </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>


            </div>
          </div>
        </form>

      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import {
  COI_THI,
  GIA_SU,
  TAC_VU_KHAC,
  TYPE_TASK_SUGGEST,
  TYPE_TASK_TEST_INPUT,
} from "@/core/option/testInputOption";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {GET_BRANCHES} from "@/core/services/store/center/branch.module";
import {GET_LIST_SUGGEST_TESTER} from "@/core/services/store/test/testInput.module";
import moment from "moment-timezone";
import {SuggestTesterModel} from "@/view/pages/test-input/models/suggest-tester-model";
import {GET_CENTERS} from "@/core/services/store/center/centers.module";


export default {
  name: "TaskSuggest",
  components: {DatePicker},
  data() {
    return {
      time_picker_options: {
        start: '08:30',
        step: '00:30',
        end: '20:30',
      },
      taskTypes: TYPE_TASK_SUGGEST,
      workLocation: [],
      query: {
        taskTypeId: null,
        day: new Date(),
        timeStart: null,
        timeEnd: null,
        workLocation: "",
      },
      listOfPeopleInCharge: SuggestTesterModel[0],
      loading: null,
      timeTasks: new Map([

      ])
    }
  },
  mounted() {
    this.getAllCenterIsEdutalk();
  },
  methods: {
    resetModal() {
      this.listOfPeopleInCharge = [];
      this.query = {
        taskTypeId: null,
        timeStart: null,
        timeEnd: null,
        workLocation: "",
        day: new Date()
      }
      this.loading = null
    },
    getDataQuery() {
      let payload = {
        type_task: this.query.taskTypeId,
        branch_id: this.query.workLocation,
        day: moment(this.query.day).format("YYYY-MM-DD"),
        start_time: moment(this.query.timeStart).format("HH:mm:ss"),
        end_time: moment(this.query.timeEnd).format("HH:mm:ss")
      }
      this.loading = true
      this.$store.dispatch(GET_LIST_SUGGEST_TESTER, payload).then((data) => {
        if (!data.error) {
          this.listOfPeopleInCharge = data.data.map(d => {
            return new SuggestTesterModel(d.user_name, d.user_phone, d.total_time, d.time_pick, d.branches.map(c => c.name));
          })
          this.loading = false
        }
      })
    },
  getAllCenterIsEdutalk() {
        this.$store.dispatch(GET_CENTERS, {
        is_edutalk: 1
      }).then((data) => {
        let ids = data.data.data.map((branch)=>branch.id);
          this.getAllBranches(ids);
      })
    },
    getAllBranches(ids) {
       this.$store.dispatch(GET_BRANCHES, {
        center_ids: ids , status : 1
      }).then((data) => {
        if (!data.error) {
            this.workLocation = data.data.data;
        }
      })
    },
    disabledBeforeToday(date) {
      const today = moment().startOf('day');
      return moment(date) < today;
    },
    disableOptionTimeStart(time) {
      let currentTime = moment(time).format("HH:mm");
      if (this.query.taskTypeId == TYPE_TASK_TEST_INPUT || this.query.taskTypeId === TAC_VU_KHAC) {
        return ("11:30" < currentTime && currentTime < "14:00")
      }
      return ("11:00" <= currentTime && currentTime < "14:00")
          || ("17:00" <= currentTime && currentTime < "18:00")
          || ("20:00" <= currentTime)
    },
    disableOptionTimeEnd(time) {
      let currentTime = moment(time).format("HH:mm");
      return (("11:30" < currentTime && currentTime < "14:00") || time <= this.query.timeStart)
    },
    calculateEndTime(date) {
      if (this.query.taskTypeId > 0 && this.timeTasks.has(this.query.taskTypeId)) {
        let minutes = this.timeTasks.get(this.query.taskTypeId);
        return new Date(date.getTime() + minutes * 60000)
      }
      return null;
    },
    onStartTimeChanged() {
      this.query.timeEnd = this.calculateEndTime(this.query.timeStart);
    },
    resetTimeStartEnd() {
      this.query.timeStart = null
      this.query.timeEnd = null
    },
    currentBranchesHide(branches) {
      let strTestersHide = ''
      for (let i = 0; i < branches.length; i++) {
        strTestersHide += `\n${branches[i]} `
      }
      return strTestersHide.trim("\n");
    }
  },
  computed: {
    isDisableTimeEnd() {
      return this.query.timeStart == null
    },
    isDisableTimeStart() {
      return this.query.taskTypeId == null
    }
  }

}
</script>

<style scoped>

</style>