<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Cập nhập điều khoản & chính sách</h3>
      </div>
      <div class="card-title">
      </div>
    </div>
    <div class="card-body">
      <!--Filter-->
      <div class="container pb-2 d-flex justify-content-end">
        <button v-b-modal.modal-create-policy class="btn-primary p-2 rounded" @click="addNew">
          Thêm mới
        </button>
      </div>
      <div class="example mb-10">
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th scope="col" class="min-w-100px">STT</th>
              <th scope="col" class="min-w-100px">Tên danh mục</th>
              <th scope="col" class="min-w-100px">Vị trí hiển thị</th>
              <th scope="col" class="min-w-100px">Thời gian update </th>
              <th scope="col" class="min-w-100px">Người update</th>
              <th scope="col" class="min-w-100px">Hành động</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in termPolicy" :key="index">
              <td>{{ page == 1 ? index + 1 : (page - 1) * 20 + index + 1 }}</td>
              <td>{{ item.name }} {{ item.tuition_payment ? '(' + item.tuition_payment.name + ')' : '' }}</td>
              <td>{{ item.index }}</td>
              <td>{{ item.updated_at |formatDateTimeVietnam }}</td>
              <td>{{ item.user ? (item.user.id + 100000) + ' - ' + item.user.name : '' }}</td>
              <td class="">
                <a title="Cập nhập"
                   v-b-modal.modal-create-policy
                   @click="updatePolicy(item.id)"
                   class="btn btn-xs btn-icon btn-outline-info ml-2 mr-2"><i
                    class="fas fa-pen-nib"></i>
                </a>
                <a title="Xóa" class="btn btn-xs btn-icon mr-2 btn-danger" @click="deletePolicy(item.id)">
                  <i class="fas fa-trash"></i>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>

    <modal-create-term-policy
        :center_id="center_id_param"
        :check_update="check_update"
        :data_prop="policy"
        :termPolicy="termPolicy"
        :dialogTableVisible="dialogTableVisible"
        @addParam="reaload"
        @showDialog="showDialog"
        :tuitionPayments="tuitionPayments"
    >
    </modal-create-term-policy>
  </div>
</template>
<script>

import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import Multiselect from 'vue-multiselect';
import ModalCreateTermPolicy from "./components/ModalCreateTermPolicy"
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";
import {
  CREATE_TERM_POLICY, DELETE_POLICY,
  GET_CATALOG, GET_CATALOG_BY_CENTER_ID, GET_CATALOG_BY_ID_BY_ID,
  GET_TERM_POLICY,
  GET_TERM_POLICY_BY_ID
} from "@/core/services/store/system/termpolicy.module";
import ErrorMessage from "../common/ErrorMessage";
import Swal from "sweetalert2";
import {GET_TUITION_PAYMENT} from "@/core/services/store/installment/installment.module";

export default {
  components: {
    Multiselect,
    DatePicker,
    ErrorMessage,
    ModalCreateTermPolicy,

  },
  name: "TermPolicyEdit",
  data() {
    return {
      editor: CustomEditor,
      files: [],
      term_policy: [],
      center_id: '',
      accountType: [],
      // Cache
      cacheAccountType: [],
      money: {
        decimal: ',',
        thousands: ',',
        prefix: '',
        suffix: ' VNĐ',
        precision: 0,
        masked: false,
      },
      termPolicy: {},
      fileUpdates: [],
      form: {
        name_slide: '',
        slide_status: true,
        center_id: '',
        file: ''
      },
      center: [
        {
          id: 1,
          value: 'Ecoach'
        },
        {
          id: 2,
          value: 'Ielts mentor'
        }
      ],
      center_id_param: '',
      policy: {},
      check_update: false,
      page: 1,
      last_page: 1,
      dialogTableVisible: false,
      tuitionPayments: []
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý chính sách điều khoản", route: 'term-policy'},
      {title: "Cập nhập chính sách điều khoản"}
    ]);
    this.getTermPolicyByCenterId();
    this.getTuitionPayments();
  },
  methods: {
    getTermPolicyByCenterId() {
      let id = this.$route.params.id;
      this.center_id_param = this.$route.params.id;
      this.$store.dispatch(GET_CATALOG_BY_CENTER_ID,{id: id , page: this.page}).then((res) => {
        this.termPolicy = res.data.data;
        this.last_page = res.data.last_page;
        this.center_id = res.data ? res.data.data[0].center_id : ''
      });
    },
    reaload() {
      this.getTermPolicyByCenterId();
    },
    updatePolicy(id) {
      this.check_update = true;
      this.dialogTableVisible = false;
      this.dialogTableVisible = true;
      this.$store.dispatch(GET_CATALOG_BY_ID_BY_ID,id).then((res) => {
        this.policy = res.data;
      }).catch((error) => {

      })
    },
    addNew() {
      this.dialogTableVisible = true;
      this.policy = {};
      this.policy.index = this.termPolicy.length + 1;
      this.check_update = false;
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({path: '', query: {page: this.page}})
      this.getTermPolicyByCenterId()
    },
    deletePolicy(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_POLICY,{id:id}).then((res) => {
            this.policy = res.data;
            this.$notify({
              title: 'Xóa',
              message: 'Xóa thành công',
              type: 'success'
            });
            this.reaload();
          }).catch((error) => {

          })
        }
      });
    },
    showDialog(value) {
      this.dialogTableVisible = value;
    },
    getTuitionPayments() {
      this.$store.dispatch(GET_TUITION_PAYMENT).then((response) => {
        this.tuitionPayments = response.data
      });
    },
  }
}
</script>
<style scoped>
.mx-datepicker {
  width: 100% !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
