<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Khách hàng đóng tiền</h3>
      </div>
      <div class="card-title" v-if="currentUser.user.permission.includes(permission.EXPORT_KHACH_HANG_DONG_TIEN)">
        <export-google-sheet :disable-button="isLoading" @exportGoogleSheet="exportGoogleSheet"
          :options="options"></export-google-sheet>
        <div>
          <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcel">
            <i class="la la-file-excel"></i> Xuất excel
          </button>
          <button v-else disabled class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
            Xuất excel
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Ngày nạp
                <span class="text-danger"></span></label>
              <date-picker placeholder="Chọn thời gian" format="DD-MM-YYYY" range valueType="YYYY-MM-DD"
                v-model="query.date_range">
              </date-picker>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">Bên thu tiền</label>
              <el-select v-model="query.ben_thu_tien" clearable filterable placeholder="Chọn bên thu tiền"
                class="w-100">
                <el-option v-for="item in BEN_THU_TIEN" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">Trung tâm</label>
              <el-select v-model="query.center_id" clearable filterable placeholder="Chọn trung tâm" class="w-100"
                @change="handleQueryCenter">
                <el-option v-for="item in centers" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">Chi nhánh</label>
              <el-select v-model="query.branch_id" clearable filterable placeholder="Chọn chi nhánh" class="w-100">
                <el-option v-for="item in branches" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Tên, SĐT, Mã học viên
                <span class="text-danger"></span></label>
              <el-input type="text" v-model="query.keyword" placeholder="Tên, SĐT, Mã học viên"></el-input>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">Phân loại nạp, rút, hoàn</label>
              <el-select v-model="query.type_nap_rut" clearable filterable placeholder="Chọn loại" class="w-100">
                <el-option v-for="item in TYPE_NAP_RUT_KHDT" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3" v-if="query.type_nap_rut === 1">
            <div class="form-group">
              <label>Phân loại rút</label>
              <el-select v-model="query.type_withdraw" clearable filterable placeholder="Chọn loại rút tiền"
                class="w-100">
                <el-option v-for="item in WITHDRAW_TYPE" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">Trạng thái</label>
              <el-select v-model="query.status" clearable filterable placeholder="Trạng thái" class="w-100">
                <el-option v-for="status in STUDENT_AMOUNT_STATUS" :key="status.value" :label="status.label"
                  :value="status.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Ngày tạo
                <span class="text-danger"></span></label>
              <date-picker placeholder="Chọn thời gian" format="DD-MM-YYYY" range valueType="YYYY-MM-DD"
                v-model="query.created_at">
              </date-picker>
            </div>
          </div>
          <div class="col-md-3" v-if="query.type_nap_rut === 1">
            <div class="form-group">
              <label class="d-block">Lý do rút</label>
              <el-select v-model="query.created_at" clearable filterable placeholder="Lý do rút" class="w-100" multiple>
                <el-option v-for="status in listReason" :key="status.id" :label="status.name" :value="status.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="d-flex justify-content-between">
              <button class="btn btn-primary font-weight-bold mr-2"
                :class="is_disable_search ? 'spinner spinner-white spinner-left' : ''" :disabled="is_disable_search"
                @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-3 mt-5">
            Tổng tiền đã nộp:
            <span class="label label-info label-inline mr-2 h6 bold">{{
        student_amounts.data != undefined && student_amounts.data[0] != undefined ?
          formatPrice(student_amounts.data[0].total_all) : '0đ'
      }}</span>
          </div>
          <div class="col-md-12 mb-3">
            Số lượt nộp :
            <span class="label label-danger label-inline mr-2 h6 bold">{{ student_amounts.total }}</span>
          </div>
        </div>
        <div class="example-preview b-table-sticky-header">
          <table class="table table-vertical-center table-hover table-bordered text-center b-table">
            <thead class="thead-light">
              <tr>
                <th scope="col" class="min-w-20px">STT</th>
                <th scope="col" class="min-w-100px">Mã hóa đơn</th>
                <th scope="col" class="min-w-180px">Tên học viên</th>
                <th scope="col" class="min-w-100px">Mã học viên</th>
                <th scope="col" class="min-w-120px">Số điện thoại</th>
                <th scope="col" class="min-w-120px">Mã hợp đồng</th>
                <th scope="col" class="min-w-100px">Số tiền nạp</th>
                <th scope="col" class="min-w-100px">Bên thu tiền</th>
                <th scope="col" class="min-w-200px">Chi nhánh</th>
                <th scope="col" class="min-w-100px">Người tạo</th>
                <th scope="col" class="min-w-150px">Ngày nạp tiền</th>
                <th scope="col" class="min-w-150px">Phân loại</th>
                <th scope="col" class="min-w-150px" v-if="isRefund">Chương trình</th>
                <th scope="col" class="min-w-150px" v-if="isWithdraw">Phân loại rút tiền</th>
                <th scope="col" class="min-w-150px" v-if="isWithdraw">Phí hủy khóa</th>
                <th scope="col" class="min-w-100px">Trạng thái</th>
                <th scope="col" class="min-w-200px">Hành động</th>
              </tr>
            </thead>
            <tbody v-if="is_disable_search">
              <tr role="row" class="b-table-busy-slot">
                <td colspan="16" role="cell" class="text-center">
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="student_amounts.data && student_amounts.data.length > 0">
              <tr v-for="(item, index) in student_amounts.data" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.id }}</td>
                <td>{{ item.user != null ? item.user.name : '' }}</td>
                <td>{{ item.user_id + 100000 }}</td>
                <td>{{ item.user != null ? item.user.phone : '' }}</td>
                <td>{{ item.contract_id ?? '' }}</td>
                <td>{{ formatPrice(item.amount, 'đ') }}</td>
                <td>
                  <span v-if="item.ben_thu_tien == 1" class="badge badge-pill badge-primary">Edutalk</span>
                  <span v-else class="badge badge-pill badge-danger">Trung tâm</span>
                </td>
                <td>{{ item.branch != null ? item.branch.name : '' }}</td>
                <td>{{ item.create_user != null ? item.create_user.email : '' }}</td>
                <td>{{ (item.dated_paid && item.dated_paid != '0000-00-00 00:00:00' ? item.dated_paid : item.created_at)
        | formatDateTimeAsia }}</td>
                <td v-if="item.type !== 6">
                  <ModalShowReason v-if="item.amount < 0 && item.is_refund !== 1" :item="item" :index="index">
                  </ModalShowReason>
                  <span v-else-if="item.amount > 0" class="badge badge-pill badge-primary">Nạp tiền</span>
                  <span v-else class="badge badge-pill badge-warning cursor-pointer" @click="showRefund(item)">Hoàn
                    tiền</span>
                </td>
                <td v-else>
                  <span class="badge badge-info">Chuyển nhượng</span>
                </td>
                <td v-if="isRefund">
                  {{ item.note }}
                </td>
                <td v-if="isWithdraw">
                  <span class="badge badge-pill badge-warning"
                    v-if="item.sub_contracts && item.sub_contracts.type === 1">Hủy khóa</span>
                  <span class="badge badge-pill badge-info" v-else>Không hủy khóa</span>
                </td>
                <td v-if="isWithdraw">{{ item.sub_contracts ? formatPrice(item.sub_contracts.destroy_fee, 'đ') : 0 }}
                </td>
                <td>
                  <span class="badge" :class="item.status === 1 ? 'badge-success' : 'badge-danger'">
                    {{ item.status === 1 ? 'Tồn tại' : 'Đã hủy' }}
                  </span>
                </td>
                <td class="d-flex justify-content-around align-items-center sticky-action" style="width: max-content;">
                  <ModalEditCreatedAt ModalListDetail :item="item" :index="index" @update_success="updateSuccess">
                  </ModalEditCreatedAt>
                  <ModalWrongTransferComponent :item="item" :index="index" @update_success="updateSuccess">
                  </ModalWrongTransferComponent>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="12" class="text-center font-italic">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <el-dialog title="Chương trình hoàn tiền" class="responsive" :visible.sync="dialogFormVisibleRefund">
      <div class="">
        {{ note }}
      </div>
    </el-dialog>
    <div class="edu-paginate mx-auto text-center" v-if="student_amounts.data && student_amounts.data.length > 0">
      <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
        :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'"
        :pageLinkClass="'page-link'" :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'"
        :prev-class="'page-link'" :next-class="'page-link'" :page-class="'page-item'">
      </paginate>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { GET_FOLDERS_DRIVERS } from "@/core/services/store/public/google.module";
import {
  EXPORT_CUSTOMER_PAYMENT,
  EXPORT_STUDENT_AMOUNT_GOOGLE_SHEET,
  GET_STUDENT_AMOUNT,
} from "@/core/services/store/contract/studentAmount.module";
import { GET_BRANCHES } from "@/core/services/store/center/branch.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_CENTERS } from "@/core/services/store/center/centers.module";
import { STUDENT_AMOUNT_STATUS, WITHDRAW_TYPE, BEN_THU_TIEN, TYPE_NAP_RUT_KHDT } from '@/core/option/studentAmountOption'
import ModalShowReason from "../contracts/ModalShowReason";
import ModalEditCreatedAt from '../contracts/ModalEditCreatedAt';
import ModalWrongTransferComponent from '../contracts/WrongTransferComponent';
import ExportGoogleSheet from "@/view/pages/common/ExportGoogleSheet";
// Datetime picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment-timezone';
import { GET_REASON_WITHDRAWAL } from "@/core/services/store/contract/contracts.module";
import { exportExcel } from "../../../core/filters";

const _ = require('lodash');
// Modal edit created at
export default {
  components: {
    ModalShowReason,
    DatePicker,
    ModalEditCreatedAt,
    ModalWrongTransferComponent,
    ExportGoogleSheet
  },
  name: "PaymentLog",
  data() {
    return {
      w_200: true,
      w_300: false,
      /*Loader */
      isLoading: false,
      isWithdraw: false,
      isRefund: false,
      fullPage: true,
      /* popover */
      options: [],
      /* end popover */
      is_disable_search: false,
      is_export_excel: false,
      is_export_sheet: false,
      dialogFormVisibleRefund: false,
      branches: [],
      WITHDRAW_TYPE,
      STUDENT_AMOUNT_STATUS,
      BEN_THU_TIEN,
      TYPE_NAP_RUT_KHDT,
      centers: [],
      student_amounts: [],
      // Paginate
      page: 1,
      last_page: 1,
      query: {
        center_id: '',
        branch_id: '',
        date_range: [],
        keyword: '',
        ben_thu_tien: '',
        type_nap_rut: 0,
        type_withdraw: '',
        status: '',
        // Condition default
        other_type: 3,
        parent_log_id: 0,
        is_get_log: true,
        reason_id: [],
        // is_refund: 0,
        created_at: [],
      },
      date_filter_default: [],
      districtsByProvince: [],
      log_change_dated_paid: [],
      // cache
      cacheCenter: [],
      cacheBranch: [],
      cacheBenThuTien: [],
      cacheWithdrawType: '',
      note: '',
      showModalListCourses: false,
      listReason: []
    }
  },
  mounted() {
    this.getDateFilterDefault();
    this.getFolders();
    this.getCenters();
    this.getBranches();
    this.getList();
    this.getReason();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Báo cáo", route: '#' },
      { title: "Khách hàng đóng tiền" }
    ]);
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    folderData(val) {
      if (val) {
        this.validateState = true;
        this.path = val;
      }
    },
  },
  methods: {
    getDateFilterDefault() {
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
      this.date_filter_default.push(startOfMonth);
      this.date_filter_default.push(endOfMonth);
    },
    getFolders: function () {
      this.$store.dispatch(GET_FOLDERS_DRIVERS).then((res) => {
        this.options = _.reduce(res.data, function (result, item, key) {
          result.push({
            text: item.name,
            value: item.path
          });
          return result;
        }, [])
        this.options.unshift({ text: '- Chọn thư mục -', value: '' });
      });

    },
    updateSuccess() {
      this.getList()
    },
    moveSuccess() {
      this.getList()
    },
    getList() {
      this.pullParamsUrl()
      this.callApiGetList()
    },
    pullParamsUrl() {
      this.page = this.$route.query.page ? Number(this.$route.query.page) : 1
      this.query.keyword = this.$route.query.keyword;
      this.query.center_id = this.$route.query.center_id ? Number(this.$route.query.center_id) : '';
      this.query.branch_id = this.$route.query.branch_id ? Number(this.$route.query.branch_id) : '';
      this.query.date_range = this.$route.query.date_range ? this.$route.query.date_range : this.date_filter_default;
      this.query.ben_thu_tien = this.$route.query.ben_thu_tien ? Number(this.$route.query.ben_thu_tien) : '';
      this.query.type_nap_rut = this.$route.query.type_nap_rut ? Number(this.$route.query.type_nap_rut) : 0;
      this.query.type_withdraw = this.$route.query.type_withdraw;
      this.query.reason_id = this.$route.query.reason_id ? this.$route.query.reason_id : [];
      this.query.status = this.$route.query.status ? Number(this.$route.query.status) : 1;
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'payment-log',
        query: {
          page: this.page,
          ...this.query,
          date_range: this.query.date_range ? this.query.date_range : this.date_filter_default,
        }
      })
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({
        path: '',
        query: {
          page: this.page,
          center_id: this.query.center_id,
          branch_id: this.query.branch_id,
          keyword: this.query.keyword,
          date_range: this.query.date_range ? this.query.date_range : this.date_filter_default,
          type_nap_rut: this.query.type_nap_rut,
          reason_id: this.query.reason_id,
        }
      })
      this.callApiGetList()
    },
    callApiGetList() {
      this.is_disable_search = true;
      this.$store.dispatch(GET_STUDENT_AMOUNT, {
        page: this.page,
        ...this.query,
        date_range: this.query.date_range ? this.query.date_range : this.date_filter_default,
      }).then((res) => {
        if (!res.error) {
          this.is_disable_search = false;
          this.student_amounts = res.data;
          this.last_page = res.data.last_page;
          this.isWithdraw = this.query.type_nap_rut === 1;
          this.isRefund = this.query.type_nap_rut === 3;
          this.is_export_excel = false;
        }
      });
    },

    exportExcel() {
      this.is_export_excel = true
      let HEADER_ROW_EXPORT_KHDT = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'id',
          value: 'Mã hóa đơn',
        },
        {
          key: 'name',
          value: 'Tên học viên',
        },
        {
          key: 'userId',
          value: 'Mã học viên',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'contractId',
          value: 'Mã hợp đồng',
        },
        {
          key: 'amount',
          value: 'Số tiền nạp',
        },
        {
          key: 'thuTien',
          value: 'Bên thu tiền',
        },
        {
          key: 'createdName',
          value: 'Người tạo',
        },
        {
          key: 'createdId',
          value: 'Mã người tạo',
        },
        {
          key: 'centerName',
          value: 'Trung tâm',
        },
        {
          key: 'centerId',
          value: 'Mã trung tâm',
        },
        {
          key: 'brName',
          value: 'Chi nhánh',
        },
        {
          key: 'brId',
          value: 'Mã chi nhánh',
        },
        {
          key: 'datedPaid',
          value: 'Ngày nạp tiền',
        },
        {
          key: 'createdAt',
          value: 'Ngày tạo',
        },
        {
          key: 'hd_noi_bo',
          value: 'Hợp đồng nội bộ (?)',
        },
        {
          key: 'gt_id',
          value: 'Mã giới thiệu',
        },
        {
          key: 'tvv_id',
          value: 'Mã tư vấn viên',
        },
        {
          key: 'status',
          value: 'Trạng thái',
        },
        {
          key: 'plRut',
          value: 'Phân loại rút',
        },
        {
          key: 'khHuy',
          value: 'Khóa học bị hủy(nếu có)',
        },
        {
          key: 'phKhoa',
          value: 'Phí hủy khóa',
        },
        {
          key: 'birth',
          value: 'Ngày sinh',
        },
        {
          key: 'email',
          value: 'Email',
        },
        {
          key: 'newCus',
          value: 'Khách hàng mới',
        },
        {
          key: 'httv',
          value: 'Hình thức tư vấn',
        },
        {
          key: 'httt',
          value: 'Hình thức thanh toán',
        },
        {
          key: 'saleBrId',
          value: 'Mã văn phòng Sale',
        },
        {
          key: 'saleBrName',
          value: 'Văn phòng Sale',
        },
        {
          key: 'saleAreaId',
          value: 'Mã khu vực Sale',
        },
        {
          key: 'saleAreaName',
          value: 'Khu vực Sale',
        },
        {
          key: 'tvvBrId',
          value: 'Mã văn phòng TVV',
        },
        {
          key: 'tvvBrName',
          value: 'Văn phòng TVV',
        },
        {
          key: 'tvvAreaId',
          value: 'Mã khu vực TVV',
        },
        {
          key: 'tvvAreaName',
          value: 'Khu vực TVV',
        },
        {
          key: 'tienHeSo',
          value: 'Số tiền nạp đã nhân hệ số',
        },
        {
          key: 'type',
          value: 'Phân loại',
        },
        {
          key: 'lyDoRut',
          value: 'Lý do rút',
        },
        {
          key: 'chiTietRut',
          value: 'Chi tiết rút',
        },
        {
          key: 'moiNhan',
          value: 'Học viên mới của nhãn',
        },
        {
          key: 'moiE',
          value: 'Học viên mới của Edutalk',
        },
        {
          key: 'traGop',
          value: 'Trả góp',
        },
        {
          key: 'ctCreatedAt',
          value: 'Thời gian tạo hợp đồng',
        },
        {
          key: 'ct197',
          value: 'Hợp đồng 19/7',
        },
        {
          key: 'book_material',
          value: 'NVL_Sách',
        },
        {
          key: 'certificate_material',
          value: 'NVL_Chứng chỉ',
        },
        {
          key: 'output_bonus_material',
          value: 'NVL_Thưởng đầu ra',
        },
        {
          key: 'party_material',
          value: 'NVL_Liên hoan KG/KT',
        },
        {
          key: 'stationery_material',
          value: 'NVL_Văn phòng phẩm',
        },
        {
          key: 'box_material',
          value: 'NVL_Box',
        },
        {
          key: 'teacher_labor',
          value: 'NC_Giáo viên',
        },
        {
          key: 'open_end_labor',
          value: 'NC_Khai giảng & Kết thúc',
        },
        {
          key: 'demo_labor',
          value: 'NC_Demo',
        },
        {
          key: 'end_gift_labor',
          value: 'NC_Quà kết thúc',
        },
        {
          key: 'teaching_assistant_labor',
          value: 'NC_Trợ giảng',
        },
        {
          key: 'amount_installment',
          value: 'Phí trả góp',
        },
        {
          key: 'gia_tri_hop_dong',
          value: 'Giá trị hợp đồng',
        },
        {
          key: 'affiliated_school_id',
          value: 'Mã trường liên kết',
        },
        {
          key: 'kenh_kh',
          value: 'Kênh KH',
        },
        {
          key: 'userParent_name',
          value: 'Tên người giám hộ',
        },
        {
          key: 'userParent_phone',
          value: 'Số điện thoại người giám hộ',
        },
      ]
      this.$store.dispatch(EXPORT_CUSTOMER_PAYMENT, {
        ...this.query,
        date_range: this.query.date_range ? this.query.date_range : this.date_filter_default,
      }).then((res) => {
        console.log(res.data);
        if (this.query.type_nap_rut != 1) {
          HEADER_ROW_EXPORT_KHDT = HEADER_ROW_EXPORT_KHDT.filter(function (obj) {
            return (obj.key != 'plRut' && obj.key != 'khHuy' && obj.key != 'phKhoa')
          });
        }
        exportExcel(HEADER_ROW_EXPORT_KHDT, res.data, "Khách hàng đóng tiền.xlsx", true)
        this.noticeMessage('success', 'Thành công', res.message);
      }).catch((error) => {
        this.noticeMessage('error', 'Thất bại', error.data?.message);
      }).finally(() => this.is_export_excel = false);;
    },

    exportGoogleSheet() {
      this.isLoading = true;
      this.$store.dispatch(EXPORT_STUDENT_AMOUNT_GOOGLE_SHEET, {
        ...this.query,
        date_range: this.query.date_range ? this.query.date_range : this.date_filter_default,
        path: this.path,
      }).then((res) => {
        this.noticeMessage('success', 'Thành công', res.message);
      }).catch((error) => {
        this.noticeMessage('error', 'Thất bại', error.data?.message);
      }).finally(() => this.isLoading = false);
    },

    getCenters() {
      this.$store.dispatch(GET_CENTERS, {
        limit: 10000,
      }).then((res) => {
        if (!res.error) {
          this.centers = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        center_id: this.query.center_id
      }).then((res) => {
        if (!res.error) {
          this.branches = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    handleQueryCenter() {
      this.query.branch_id = '';
      this.branches = [];
      this.getBranches();
    },
    searchData() {
      this.page = 1;
      this.is_disable_search = true;
      this.pushParamsUrl();
      this.callApiGetList();
    },
    showRefund(item) {
      this.dialogFormVisibleRefund = true;
      this.note = item.note;
    },
    getReason() {
      this.$store.dispatch(GET_REASON_WITHDRAWAL, {})
        .then((res) => {
          this.listReason = res.data;
          console.log(this.listReason, 'alo');
        });
    },
  }
}
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}

.mx-input {
  min-height: 38px !important;
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 500px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #EBEDF3;
}

.col-form-label {
  display: none;
}

.b-table-sticky-header>.table.b-table>thead>tr>th {
  background: #F3F6F9;
}
</style>
