import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const SUPER_DASH_USERS = "super-dash/cambridgeGameUser";
export const SUPER_DASH_USERS_EXPORT = "super-dash/cambridgeGameUser/export";


// Setter
export const SET_TOTAL_HIGHLIGHTS = "set_total_highlights";

const state = {};
const getters = {};
const actions = {
    // Get provinces
    [SUPER_DASH_USERS](context, payload) {
        return new Promise(resolve => {
            ApiService.query(SUPER_DASH_USERS, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [SUPER_DASH_USERS_EXPORT](context, payload) {
        return new Promise(resolve => {
            ApiService.post(SUPER_DASH_USERS_EXPORT, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    }
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
