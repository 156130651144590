import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "../auth.module";

const _ = require('lodash');

export const GET_EVENTS = "get-event";
export const DELETE_EVENT = "delete-event";
export const CREATE_EVENT = "create-event";
export const GET_EVENT_BY_ID = "get-event-by-id";
export const UPDATE_EVENT = "update-event";
export const GET_ALL_EVENT = "get-all-event";
export const GET_PROVINCES = "get-provinces";

//mutations types
export const SET_EVENT = "setEvent";

const state = {
    errors: null,
    events: [],
    eventPaginateData: [],
};

const getters = {

};

const actions = {
    [GET_EVENTS](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query('events', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },
    [GET_PROVINCES](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query('events/provinces', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },
    [GET_ALL_EVENT](context, payload) {
        return new Promise(resolve => {
            ApiService.query('events/all').then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_EVENT_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('events', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [UPDATE_EVENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('events', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [CREATE_EVENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('events', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [DELETE_EVENT](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('events', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
}

const mutations = {
    [SET_EVENT](state, data) {
        state.events = data;
        state.errors = {};
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
