import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const CREATE_HANDBOOK = "create_handbook";
export const UPDATE_HANDBOOK = "update_handbook";
export const GET_HANDBOOK = "get_handbooks";
export const DELETE_HANDBOOK = "delete_handbook";
export const GET_HANDBOOK_BY_ID = "get_handbook_by_id";
const state = {};
const getters = {};
const actions = {
    [GET_HANDBOOK](context, payload) {
        return new Promise(resolve => {
            ApiService.query('handbooks', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_HANDBOOK_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('handbooks', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_HANDBOOK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('handbooks', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_HANDBOOK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('handbooks', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_HANDBOOK](context, id) {
        return new Promise(resolve => {
            ApiService.delete('handbooks', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
