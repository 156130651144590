<template>
  <div class="table-responsive" style="overflow: hidden; margin-top: -20px" v-loading="loading">
    <table class="table" style="text-align: center;">
      <thead>
      <tr style="border: 1px solid #EBEDF3">
        <th scope="col" class="min-w-120px" style="background: #F8F8F8; text-align: start">Năm</th>
        <th scope="col" class="min-w-120px" style="background: #F8F8F8; text-align: start">Giá trị trái phiếu tích lũy</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item of detail" :key="item?.index" style="border: 1px solid #EBEDF3; border-top: 0">
        <td style="text-align: start">{{ item?.year_number ?? '' }}</td>
        <td style="text-align: start">{{ formatMoney(item?.amount) ?? '' }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {formatPrice} from "@/core/filters";

export default {
  name: 'BondDetailModal',
  props: {
    detail: {
      type: Array,
      required: true,
      default: []
    },
  },
  methods: {
    formatMoney(value) {
      return formatPrice(value)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>