<template>
  <div>
    <b-modal id="my-modal" centered title='Cập nhật thông tin tác vụ' hide-footer @show="getNickName()">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form" @submit.prevent autocomplete="off">
          <p>Thời gian bắt đầu ca test speaking thực tế <span style="color: red">*</span></p>
          <ValidationProvider
              name="Thời gian"
              rules="required"
              v-slot="{ errors, classes }"
              vid="time"
          >
            <date-picker
                type="datetime"
                placeholder="Chọn giờ"
                v-model="hour"
                format="DD-MM-YYYY HH:mm"
                valueType="YYYY-MM-DD HH:mm:ss"
                :default-value="new Date()"
            ></date-picker>
            <div class="fv-plugins-message-container">
              <div
                  data-field="name"
                  data-validator="notEmpty"
                  class="fv-help-block"
              >
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
          <div v-if="+informationTask?.centerID === Cambridge_Mentor_ID">
            <p class="mt-4">Tên thay thế của khách hàng <span style="color: red">*</span></p>
            <ValidationProvider
                name="Tên thay thế"
                rules="required"
                v-slot="{ errors, classes }"
                vid="name"
            >
              <el-input placeholder="Nhập tên thay thế" v-model="nickName" maxlength="20"></el-input>
              <div class="fv-plugins-message-container">
                <div
                    data-field="name"
                    data-validator="notEmpty"
                    class="fv-help-block"
                >
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="d-flex justify-content-end mt-4">
            <button type="button" class="btn btn-secondary mr-3" @click="cancel()">Bỏ qua</button>
            <button type="button" class="btn btn-info" @click="handleSubmit(save)" :disabled="loading"><i v-if="loading" class="el-icon-loading"></i> Lưu</button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {Cambridge_Mentor_ID} from "@/core/option/typeCenterBill";
import {GET_NICK_NAME, UPDATE_INFORMATION_TASK} from "@/core/services/store/test/testInput.module";
import router from "@/router";
import 'vue2-datepicker/locale/vi';

export default {
  name: "ModalUpdateTaskInformation",
  components: {DatePicker},
  data() {
    return {
      hour: null,
      nickName: null,
      Cambridge_Mentor_ID: Cambridge_Mentor_ID,
      loading: false,
    }
  },
  props: {
    informationTask: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    save() {
      this.loading = true;
      const payload = {
        nickname:this.nickName,
        date_come_check: this.hour,
        test_input_schedule_item_id: this.$route.params.id
      };
      this.$store.dispatch(UPDATE_INFORMATION_TASK,payload).then((data)=>{
        this.$notify({
          title: 'Thành công',
          message: 'Update thành công',
          type: 'success'
        });
        const test_input_schedule_id = this.$route.params.id;
        router.push({
          name: 'mark-the-exam',
          params: {id: test_input_schedule_id},
          query: {typeMark: this.informationTask?.taskItem, name: this.informationTask?.customerName, phone: this.informationTask?.customerPhone,centerID:this.informationTask?.centerID}
        })
      }).catch(()=>{
        this.$notify.error({
          title: 'Lỗi',
          message: 'Đã có lỗi xẩy ra'
        });
      }).finally(()=>{this.loading = false});
    },
    getNickName() {
      this.hour = null;
      this.nickName = null;
      this.$store.dispatch(GET_NICK_NAME, {test_input_schedule_item_id: this.$route.params.id}).then((data) => {
        this.hour = data?.data?.date_come_check;
        this.nickName = data?.data?.nickname;
      })
    },
    cancel() {
      this.$bvModal.hide('my-modal')
    }
  }
}
</script>
<style scoped>

</style>