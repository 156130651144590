import moment from "moment-timezone";
import {formatPrice} from "@/core/filters";

export class BondModel {
    constructor(model) {
        this.id = model.id;
        this.userId = model.user_id;
        this.user = this.getUser(model.user_name, parseInt(model.user_id) + 100000)
        this.milestone = model.milestone
        this.startDate = model.start_date ? moment(model.start_date).format('DD-MM-YYYY') : ''
        this.allowedSaleDate = model.allowed_sale_date ? moment(model.allowed_sale_date).format('DD-MM-YYYY') : ''
        this.endDate = moment(model.end_date).format('DD-MM-YYYY')
        this.bondTotal = model.amount_total || model.amount_total === 0 ? formatPrice(model.amount_total) : ''
        this.phase = model.phase
        this.phaseString = this.getPhaseString(model.phase)
        this.amountSold = model.amount_sold || model.amount_sold === 0  ? formatPrice(model.amount_sold) : ''
        this.amountBalance = model.amount_balance || model.amount_balance === 0 ? formatPrice(model.amount_balance) : ''
        this.updatedAt = moment(model.updated_at).format('HH:mm:ss DD-MM-YYYY')
    }
    getUser(name, code) {
        return `${name}-${code}`
    }
    getPhaseString(phase) {
        switch (phase) {
            case 0:
                return 'Đang tích lũy'
            case 1:
                return 'Có hiệu lực'
            case 2:
                return 'Được chuyển nhượng/ Bán'
            case 3:
                return 'Đã chuyển nhượng/Bán'
            default:
                return ''
        }
    }
}

export class BondHistoryModel {
    constructor(history) {
        this.id = history.id;
        this.category = this.getCategory(history?.meta_data?.field);
        this.fromValue = history?.meta_data?.old || history?.meta_data?.old === 0 ? this.getValue(history?.meta_data?.field, history?.meta_data?.old) : '';
        this.toValue = history?.meta_data?.new || history?.meta_data?.new === 0 ? this.getValue(history?.meta_data?.field, history?.meta_data?.new) : '';
        this.updatedAt = history?.updated_at ? moment(history?.updated_at).format('HH:mm:ss DD-MM-YYYY') : '';
        this.personalUpdate = history?.updater;
        this.year = history.year_number && history.year_number > 0 ? history?.year_number : '';
    }
    getValue(category, value) {
        switch (category) {
            case 'amount':
            case 'amountSold':
            case 'amountBalance':
                return formatPrice(value);
            case 'phase':
            case 'milestone':
                return value
            case 'startDate':
            case 'endDate':
            case 'allowedSaleDate':
                return moment(value).format('DD-MM-YYYY')
            default:
                return null
        }
    }
    getCategory(category) {
        switch (category) {
            case 'amount':
                return 'Tổng giá trị TP'
            case 'milestone':
                return 'Mốc tích lũy'
            case 'startDate':
                return 'Thời điểm bắt đầu tích lũy'
            case 'endDate':
                return 'Thời điểm trái phiếu có hiệu lực'
            case 'allowedSaleDate':
                return 'Thời điểm được chuyển nhượng/bán'
            case 'phase':
                return 'Giai đoạn'
            case 'amountSold':
                return 'Số lượng TP đã bán/chuyển nhượng'
            case 'amountBalance':
                return 'Số lượng TP còn lại'
        }
    }
}