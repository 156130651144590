import Vue from "vue";
import lang from 'element-ui/lib/locale/lang/vi'
import locale from 'element-ui/lib/locale'
import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

// import EduSelect from "@/view/content/select/src/select";
// Vue.component('edu-select', EduSelect);

Vue.use(ElementUI, { locale });
locale.use(lang);
