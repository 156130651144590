<template>
  <div class="mt-2">
    <el-upload
        :limit="6"
        action=""
        accept="image/jpeg,image/gif,image/png,video/mp4,video/x-m4v,video/*"
        list-type="picture-card"
        :file-list="fileList"
        :on-change="handleChange"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :auto-upload="false"
    >
      <div slot="file" slot-scope="{file}" v-if="checkIsVideo(file)">
          <video style="width: 166% !important; height: 100% !important;" controls="controls">
            <source :src="file.url" type="video/mp4"/>
          </video>
          <span class="el-upload-list__item-actions">
          <span
              class="el-upload-list__item-preview"
              @click="handleVideoView(file)"
          >
            <i class="el-icon-video-play"></i>
          </span>
          <span
              class="el-upload-list__item-delete"
              @click="handleRemoveVideo(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>

      <i class="el-icon-upload"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog :visible.sync="dialogVideo" append-to-body :before-close="closeVideoModal">
      <video style="width: 100% !important; height: 100% !important;" controls="controls" ref="video-upload">
        <source :src="targetVideo" type="video/mp4"/>
      </video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "UploadFile",
  props: {
    fileListProp: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isDisable: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  data() {
    return {
      imageUrl: "",
      dialogImageUrl: '',
      targetVideo: '',
      dialogVisible: false,
      dialogVideo: false,
      fileList: [],
      images: [],
      videos: [],
      rightData: {},
      stt: 0,
      disableUpload: false,
    }
  },
  watch: {
    fileList() {
      this.emitPushData();
    }
  },
  mounted() {
    this.convertCndToFile();
  },
  methods: {
    convertCndToFile() {
      if (this.fileListProp.length) {
        this.fileListProp.map((value) => {
          let file = new File(["foo"], value.url, {
            type: value.mime_type
          });
          let data = {
            name: '',
            percentage: 0,
            raw: file,
            status: "ready",
            uid: value.uid,
            url: value.url
          }
          this.fileList.push(data);
        })
      }
    },

    handleChange(file, fileList) {
      let checkVideo = this.checkIsVideo(file);
      let verifyFile = 0;
      if (checkVideo) {
        verifyFile = file.size / 1024 / 1024 < 30;
      } else {
        verifyFile = file.size / 1024 / 1024 < 10;
      }
      if (!verifyFile) {
        if (checkVideo) {
          this.$message.warning('Chèn tệp thất bại do vượt quá dung lượng cho phép. Video tối đa 30MB');
        } else {
          this.$message.warning('Chèn tệp thất bại do vượt quá dung lượng cho phép. Ảnh tối đa 10Mb');
        }
        fileList.splice(fileList.indexOf(file), 1);
        return false;
      }
      let count = this.fileList.filter((value) => {
        return value.uid === file.uid
      })
      if (count.length !== 0) return false;
      if ((this.videos.length === 1 && checkVideo) || (this.images.length === 5 && !checkVideo)){
        this.$message.warning('Cho phép upload tối đa 1 video và 5 ảnh');
        fileList.splice(fileList.indexOf(file), 1);
        return false;
      }
      this.fileList.push(file);
      if (checkVideo){
        this.videos.push(file);
      }else{
        this.images.push(file);
      }
      this.stt++;
      if (this.stt >= 6) {
        this.disableUpload = true
      }
    },
    handleRemove(file, fileList) {
      this.images.splice(this.images.indexOf(file), 1);
      this.fileList = fileList;
    },
    handleRemoveVideo(file) {
      this.videos = [];
      this.fileList.splice(this.fileList.indexOf(file), 1);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleVideoView(file) {
      this.targetVideo = file.url;
      this.dialogVideo = true;
      let _this = this;
      setTimeout(function () {
        _this.$refs['video-upload'].play();
      }, 200);
    },
    closeVideoModal() {
      this.dialogVideo = false;
      this.$refs['video-upload'].pause();
    },
    checkIsVideo(file) {
      let type = file.raw.type;
      return type === 'video/mp4';
    },
    emitPushData() {
      this.$emit('emitUpdateFile', this.fileList);
    },

  }
}
</script>

<style scoped>
.el-upload-list__item video {
  height: 100%;
}
</style>
