import ApiService from "@/core/services/api.service";

const _ = require('lodash');

export const BIZFLY_GET_SOURCE = "bizfly_get_source";

const state = {};

const getters = {};

const actions = {
    [BIZFLY_GET_SOURCE](context, payload) {
        return new Promise(resolve => {
            ApiService.query('bizfly/sources/get-all', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
