<template>
  <div>
    <!-- dialog xoá giảng viên -->
    <b-modal
      id="handle-delete-candidate"
      title="Xoá giảng viên"
      size="lg"
      ok-only
      centered
      hide-footer
    >
          <div>
            <div>
              <h4>
                Bạn có chắc chắn muốn xóa giảng viên {{ teacher.name }} không?
              </h4>
            </div>
          </div>
          <div class="mt-8">
            <el-button
              :disabled="loading_delete"
              @click="deleteCandidate()"
              size="small"
              class="float-right text-white rounded"
              style="background-color: rgba(93, 4, 176, 1);"
            >
              <i v-bind:class="[loading_delete ? 'el-icon-loading' : '']"></i>
              Xác nhận
            </el-button>
          </div>
            <el-button
              style="background-color: rgba(202, 202, 202, 1); padding: 11px 20px"
              size="small"
              class="float-right mr-3 rounded"
              @click="closeModal"
            >
              Huỷ bỏ
            </el-button>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { REMOVE_CANDIDATE_TEACHER } from '../../../../core/services/store/course/classes.module';


export default {
  name: "DeleteCandidate",
  components: {},
  props: {
    teacher: Object,
  },
  mounted() {},
  data() {
    return {
      loading_delete: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {},
  methods: {
    deleteCandidate() {
      this.loading_delete = true;
      this.$store
        .dispatch(REMOVE_CANDIDATE_TEACHER, {
          id: this.teacher.id,
        })
        .then((res) => {
          this.loading_export = false;
          this.$notify({
            title: "Thành công",
            message: "Xoá giảng viên thành công",
            type: "success",
          });
          this.closeModal();
        })
        .finally(() => {
          this.loading_delete = false;
        });
    },

    closeModal() {
      this.$bvModal.hide("handle-delete-candidate");
      this.$emit("reload");
    },
  },
};
</script>

<style>
#handle-delete-candidate {
  top: -100px;
}
</style>
