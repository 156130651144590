import {formatVND} from "@/core/filters";

export class BoxCostModel {
    constructor(id, centerId, name, boxCost, adminCost, date, errors = [], typeCost = null, costValue = null) {
        this.id = id;
        this.centerId = centerId;
        this.name = name;
        this.boxCost = formatVND(boxCost);
        this.adminCost = formatVND(adminCost);
        this.date = date;
        this.errors = errors.join(", ");
        this.typeCost = typeCost;
        this.costValue = formatVND(costValue);
        this.typeCostString = this.setTypeCostString(typeCost)
    }
    setTypeCostString(typeCost) {
        if (typeCost === 2) {
            return 'Chi phí box'
        } else if (typeCost === 3) {
            return 'Chi phí admin test'
        }
    }
}

export const rowTableBoxError = [
    {centerId: 'ID trung tâm'},
    {boxCost: 'Chi phí box'},
    {adminCost: 'Chi phí admin test'},
    {date: 'Thời gian'},
    {errors: 'Lỗi'}
]