<script>
export default {
  name: "SelectOptionView",
  props:{
    skillPoint:{
      type:Object,
      default(){
        return {}
      }
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="row px-3 py-2">
      <div class="col-3 d-flex align-items-center">
        <span class=" mb-0 bold " style="height: fit-content;font-size: 13px">{{skillPoint?.nameSkill}}</span>
      </div>
      <div class="col-3">
        <el-input  disabled :value="skillPoint?.pointSkill" />
      </div>
      <div class="col-6">
        <el-input style="white-space: pre-wrap"     :value="skillPoint?.commentSkill"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>