<template>
  <div>
    <b-modal
        id="modal-history-contract"
        title="Lịch sử chỉnh sửa"
        class="text-center"
        size="xl"
        ok-only
    >
      <div class="card-body" >
        <table class="table">
          <thead>
          <tr>
            <th scope="col">STT</th>
            <th scope="col">Nội dung thay đổi</th>
            <th scope="col">Lý do chỉnh sửa</th>
            <th scope="col">Người sửa</th>
            <th scope="col">Thời gian sửa</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item , index) in data" :key="index">
            <th scope="row">{{index + 1}}</th>
            <td>
                <span v-html="item.created_user_text"></span>
              <span v-html=" item.user_new_text"></span>

            </td>
            <td>{{ item.reason }}</td>
            <td>{{item.user.id + 100000}} - {{ item.user.name }}</td>
            <td>{{ item.created_at| formatDateTimeVietnam }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalHistoryContract",
  props: {
      data: {
        type: Array,
        default() {
          return [];
        }
      },
  },
}
</script>

<style scoped>

</style>