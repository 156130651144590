<template>
  <b-modal @hide="onHide" size="lg" :centered="true" :scrollable="true" ref="modal-program" hide-footer title="Thêm mới chương trình"  class="modal-tester">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <div class="d-block">
        <form class="form" @submit.prevent="handleSubmit(saveProgram)">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Nhập tên chương trình: <span class="text-danger">(*)</span></label>
                <ValidationProvider name="Tên chương trình" rules="required" v-slot="{ errors,classes }" vid="name">
                  <input type="text" name="name" class="form-control" placeholder="Nhập tên chương trình"
                         :class="classes"
                         v-model=name>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <footer class="modal-footer pt-5 pb-0">
            <button type="button" class="btn btn-secondary" @click="hideModal" :disabled="isDisable">Đóng</button>
            <button type="submit" class="btn btn-success">Thêm mới</button>
          </footer>
        </form>
      </div>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import {CREATE_CONTRACT_PROGRAMS} from "@/core/services/store/contract/contract-program.module";

export default {
  name: "ProgramCreate",
  props: {
    showModal: {default: false}
  },
  data() {
    return {
      name: '',
      isDisable: false
    }
  },
  watch: {
    showModal() {
      if (this.showModal){
        this.$refs['modal-program'].show();
      }
    }
  },
  methods: {
    saveProgram(){
      this.isDisable = true;
      this.$store.dispatch(CREATE_CONTRACT_PROGRAMS, {name: this.name})
          .then((res) => {
            this.$bvToast.toast('Tạo mới chương trình thành công!', {
              title: 'Tạo mới',
              variant: 'success',
              solid: true
            });
            this.isDisable = false;
            this.$emit('programCreateSuccess', res.data)
            this.$refs['modal-program'].hide();
          }).catch((res) => {
            this.$bvToast.toast(res.data?.message, {
              title: 'Tạo mới',
              variant: 'danger',
              solid: true
            });
            this.isDisable = false;
       })
    },
    onHide() {
      this.$emit("clicked", true);
    },
    hideModal() {
      this.$refs['modal-program'].hide();
    },
    toggleModal() {
      // when the modal has hidden
      this.$refs['modal-program'].toggle('#toggle-btn');
    },
  }
}
</script>

<style scoped>

</style>
