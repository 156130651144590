<template>
  <div class="card card-custom gutter-b h-100">
    <!--  header-->
    <div class="card-header">
      <div class="card-title">
        Chi tiết yêu cầu
      </div>
      <div class="card-title">
        <ReceiveRequestModal v-if="detailTicket.status === CHUA_TIEP_NHAN" @Done_Receive_Request ="getDetailTicket()"></ReceiveRequestModal>
        <CompleteRequest v-if="detailTicket.status === DANG_XU_LY" @doneCompleteRequest="getDetailTicket()"></CompleteRequest>
      </div>
    </div>
    <!--  body-->
    <div class="card-body">
      <section class="row ticket-csvc">
        <div class="col-md-6 col-sm-12">
          <div style="margin-bottom: 30px">
            <p style="margin-bottom: 5px">Người đề xuất</p>
            <el-input :value="detailTicket?.user_name" placeholder="Please input" disabled/>
          </div>
          <div style="margin-bottom: 30px">
            <p style="margin-bottom: 5px">Vị trí</p>
            <el-input  placeholder="Please input" disabled :value="detailTicket.accountName"/>
          </div>
          <div style="margin-bottom: 30px">
            <p style="margin-bottom: 5px">Số điện thoại liên hệ</p>
            <el-input :value="detailTicket?.user_phone" placeholder="Please input" disabled/>
          </div>
          <div style="margin-bottom: 30px">
            <p style="margin-bottom: 5px">Địa điểm cần bổ sung, sửa chữa cơ sở vật chất</p>
            <el-input :value="detailTicket?.branch?.name" placeholder="Please input" disabled/>
          </div>
          <div style="margin-bottom: 30px">
            <p style="margin-bottom: 5px">CSVC cần bổ sung, sửa chữa</p>
            <el-input :value="detailTicket?.name_ticket" placeholder="Please input" disabled/>
          </div>
          <div style="margin-bottom: 30px">
            <p style="margin-bottom: 5px">Mô tả chi tiết (quy cách, diễn biến)</p>
            <el-input
                :autosize="{ minRows: 4, maxRows: 20 }"
                type="textarea"
                placeholder="Please input"
                disabled
                :value="detailTicket?.description"
            />
          </div>
          <div style="margin-bottom: 30px">
            <p style="margin-bottom: 5px">Hình ảnh, video mô tả</p>
            <div class="d-flex flex-wrap">
              <div v-for="(item,index) in detailTicket?.files" class="mr-4 mb-4" :key="index" >
                <ImageModal v-if="checkIsFileImage(item?.mime_type)" class="mr-4" :urlProps="item?.url"></ImageModal>
                <VideoModal v-else :urlVideo="item?.url"></VideoModal>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 ">
          <div class="card mb-5" style="min-height: 100px; padding: 15px 23px ">
            <h1 style="font-size: 13px;color: #515151; font-weight: bold ">Tiến trình xử lý yêu cầu</h1>
            <div v-if="detailTicket?.status === CHUA_TIEP_NHAN" style="padding: 8px;background-color: #EFEFEF;width: fit-content; border-radius: 3px; ">
              <p class="mb-0" >Chưa tiếp nhận xử lý</p>
            </div>
            <div v-else style="padding: 10px;width: 100%; border-radius: 8px;border: #EFEFEF solid 1px ">
              <div class="row mb-2">
                <div class="col-4">
                  Yêu cầu đã được tiếp nhận bởi
                </div>
                <div style="font-weight: bold" class="col-8">
                  {{detailTicket?.user_handle?.name}} - {{detailTicket?.user_handle?.phone}}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">
                  Phương án xử lý:
                </div>
                <div class="col-8">
                  {{detailTicket?.solution}}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">
                  Thời gian dự kiến hoàn thành:
                </div>
                <div class="col-8">
                  {{detailTicket?.date_time}}
                </div>
              </div>
              <ReceiveRequestModal v-if="detailTicket?.status === DANG_XU_LY" :isEdit="true" :detailTicket="detailTicket" @Done_Receive_Request ="getDetailTicket()"></ReceiveRequestModal>
            </div>
            <div v-if="detailTicket.status === HOAN_THANH" style="padding: 10px;width: 100%; border-radius: 8px;border: #EFEFEF solid 1px " class="mt-4">
              <div class="row mb-2">
                <div class="col-4">
                  Yêu cầu đã được xử lý xong bởi
                </div>
                <div style="font-weight: bold" class="col-8">
                  {{detailTicket?.user_handle?.name}} - {{detailTicket?.user_handle?.phone}}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  Thời gian hoàn thành:
                </div>
                <div class="col-8">
                  {{detailTicket?.date_success_at}}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  Chi phí xử lý:
                </div>
                <div class="col-8">
                  {{detailTicket?.price?detailTicket?.price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'}):'Không tốn chi phí'}}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  Ghi chú:
                </div>
                <div class="col-8">
                  {{detailTicket?.description_success}}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  Hình ảnh:
                </div>
                <div class="col-8">
                  <div class="d-flex flex-wrap">
                    <ImageModal v-for="(image,index) in detailTicket?.image_success " :key="index"    class="mr-4" :urlProps="image?.url"></ImageModal>
                  </div>
                </div>
              </div>
             <CompleteRequest  :isEdit="true" @doneCompleteRequest="getDetailTicket()" :detailTicket="detailTicket"></CompleteRequest>
            </div>
          </div>
          <div class="card" style="min-height: 100px; padding: 15px 23px ">
            <h1 style="font-size: 13px;color: #515151; font-weight: bold ">Mức độ hài lòng của người đưa yêu cầu</h1>
            <div v-if="!detailTicket?.rate_star" style="padding: 8px;background-color: #EFEFEF;width: fit-content; border-radius: 3px; ">
              Chưa đánh giá
            </div>
            <div v-else>
              <div v-if="detailTicket?.rate_star === 3" class="d-flex align-items-center" style="gap: 8px">
                <img style="width: 30px" src="/media/heart.png" alt="">
                <span>Rất hài lòng</span>
              </div>
              <div v-if="detailTicket?.rate_star === 2" class="d-flex align-items-center" style="gap: 8px">
                <img style="width: 30px" src="/media/smile.png" alt="">
                <span>Hài lòng</span>
              </div>
              <div v-if="detailTicket?.rate_star === 1" class="d-flex align-items-center" style="gap: 8px">
                <img style="width: 30px" src="/media/tired.png" alt="">
                <span>Chưa hài lòng</span>
              </div>
              <p class="mt-2">Bình luận: {{detailTicket?.rate_comment}}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ImageModal from "@/view/pages/infrastructure/component/ImageModal";
import VideoModal from "@/view/pages/infrastructure/component/VideoModal";
import ReceiveRequestModal from "@/view/pages/infrastructure/component/ReceiveRequestModal";
import CompleteRequest from "@/view/pages/infrastructure/component/CompleteRequest";
import {GET_DETAIL_TICKET_CSVC} from "@/core/services/store/csvc/csvc.module";
import {CHUA_TIEP_NHAN, DANG_XU_LY, HOAN_THANH} from "@/core/option/csvcOption";
import moment from "moment-timezone";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "DetailInfrastructure",
  components: {CompleteRequest, ReceiveRequestModal, VideoModal, ImageModal},
  data() {
    return {
      idDetailRequest: null,
      detailTicket: {},
      CHUA_TIEP_NHAN : CHUA_TIEP_NHAN,
      DANG_XU_LY : DANG_XU_LY,
      HOAN_THANH : HOAN_THANH
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách yêu cầu", route: "infrastructureList" },
      { title: "Chi tiết yêu cầu"},
    ]);
    this.idDetailRequest = this.$route.params.id
    this.getDetailTicket()
  },
  methods: {
    getDetailTicket() {
      this.$store.dispatch(GET_DETAIL_TICKET_CSVC, this.idDetailRequest).then((data) => {
        this.detailTicket = data.data
        this.detailTicket.date_time = moment(this.detailTicket.date_time).format("DD-MM-YYYY")
        this.detailTicket.date_success_at = moment(this.detailTicket.date_success_at).format("HH:mm DD-MM-YYYY ")
      })
    },
    checkIsFileImage(fileType) {
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
      return validImageTypes.includes(fileType);

    }
  }
}
</script>

<style>
.ticket-csvc .el-input.is-disabled .el-input__inner {
  color: black !important;
  -webkit-text-fill-color: black !important;
  webkit-opacity: 1 !important;
}
.ticket-csvc .el-textarea.is-disabled .el-textarea__inner {
  color: black !important;
  -webkit-text-fill-color: black !important;
  webkit-opacity: 1 !important;
}
</style>
