<template>
  <div class="table-responsive" style="margin: 10px 14px">
    <div class="table-wrapper">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form" @submit.prevent="handleSubmit(handleCreateKra)">
          <table class="table">
            <thead v-if="isShowKra || listKra.length > 0">
            <tr style="border: 1px solid gray">
              <th style="min-width: 300px; max-width: 300px">KRA</th>
              <th style="min-width: 300px; max-width: 300px">Yêu cầu thành phẩm</th>
              <th style="width: 200px;">Số giờ</th>
              <th style="width: 200px;">Trọng số</th>
              <th>Hành động</th>
            </tr>
            </thead>
            <tbody v-if="listKra.length > 0">
            <tr style="border: 1px solid gray" v-for="kra in listKra" :key="kra.id">
              <td data-label="KRA">
                <ValidationProvider name="Tên KRA" rules="required" v-slot="{ errors,classes }" vid="name">
                  <input :disabled="!kra.isEdit" :class="kra.isEdit ? 'input-focus' : 'input-custom line-clamp-1'"
                         v-model="kra.name" :title="kra.name" placeholder="Nhập tên KRA..."/>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </td>
              <td data-label="Yêu cầu thành phẩm">
                <ValidationProvider name="Thành phẩm" rules="required" v-slot="{ errors,classes }" vid="result">
                  <input :disabled="!kra.isEdit" :class="kra.isEdit ? 'input-focus' : 'input-custom line-clamp-1'"
                         v-model="kra.result" :title="kra.result" placeholder="Nhập yêu cầu thành phẩm..."/>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </td>
              <td data-label="Số giờ">
                <ValidationProvider name="Số giờ" rules="required" v-slot="{ errors,classes }" vid="time">
                  <input :disabled="!kra.isEdit" :class="kra.isEdit ? 'input-focus' : 'input-custom'" v-model="kra.time"
                         placeholder="Nhập thời gian..."/>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </td>
              <td data-label="Trọng số">
                <ValidationProvider name="Trọng số" rules="required" v-slot="{ errors,classes }" vid="weight">
                  <div style="display: flex; align-items: center;">
                    <input :disabled="!kra.isEdit" :class="kra.isEdit ? 'input-focus' : 'input-custom'"
                           v-model="kra.weight" placeholder="Nhập trọng số..."/>
                    <span>%</span>
                  </div>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </td>
              <td data-label="Hành động">
                <div style="display: flex; align-items: center; justify-content: center; margin-top: 4px; gap: 5px">
                  <button v-if="!kra.isEdit" type="button" class="action-btn" title="Sửa"
                          style="border-color: #FF9900; padding: 5px"
                          @click="confirmUpdateKra(kra)">
                    <img :src="publicPath + '/media/svg/edit-icon.svg'" style="width: 20px; height: 20px" alt="action-edit"/>
                  </button>
                  <button v-if="kra.isEdit" type="button" class="action-btn" title="Lưu"
                          style="border-color: #0066FF; padding: 4px 5px" @click="handleUpdateKra(kra)" v-loading="loading" :disabled="loading">
                    <img :src="publicPath + '/media/svg/save-icon.svg'" style="width: 20px; height: 20px" alt="action-save"/>
                  </button>
                  <button type="button" class="action-btn" title="Xoá" style="padding: 4px"
                          @click="confirmDeleteKRA(kra.id)">
                    <i class="el-icon-delete" style="color: red"></i>
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody v-if="isShowKra">
            <tr style="border: 1px solid gray" v-if="isShowKra && isCreate">
              <td data-label="KRA">
                <ValidationProvider name="Tên KRA" rules="required" v-slot="{ errors,classes }" vid="name">
                  <el-input v-model="formData.name" placeholder="Nhập tên KRA..."/>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </td>
              <td data-label="Yêu cầu thành phẩm">
                <ValidationProvider name="Thành phẩm" rules="required" v-slot="{ errors,classes }" vid="result">
                  <el-input v-model="formData.result" placeholder="Nhập yêu cầu thành phẩm..."/>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </td>
              <td data-label="Số giờ">
                <ValidationProvider name="Số giờ" rules="required" v-slot="{ errors,classes }" vid="time">
                  <el-input v-model="formData.time" placeholder="Nhập thời gian..."/>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </td>
              <td data-label="Trọng số">
                <ValidationProvider name="Trọng số" rules="required" v-slot="{ errors,classes }" vid="weight">
                  <el-input v-model="formData.weight" placeholder="Nhập trọng số..."/>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </td>
              <td data-label="Hành động">
                <div style="display: flex; align-items: center; justify-content: center; margin-top: 4px; gap: 5px">
                  <button type="submit" class="action-btn" title="Lưu" style="border-color: #0066FF; padding: 4px 5px" v-loading="loading" :disabled="loading">
                    <img :src="publicPath + '/media/svg/save-icon.svg'" style="width: 20px; height: 20px" alt="action-save"/>
                  </button>
                  <button type="button" class="action-btn" style="padding: 4px" title="Xoá" @click="deleteItemKra">
                    <i class="el-icon-delete" style="color: red"></i>
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </form>
      </ValidationObserver>
    </div>
    <el-dialog
        title="Xóa KRA"
        :visible.sync="isDelete"
        width="30%"
        :before-close="closeDialog"
        :show-close="false"
    >
      <hr style="width: 100%; margin-top: -20px; margin-bottom: 20px"/>
      <DialogConfirmDeleteKRA @closeDialog="closeDialog" @getListKraByUser="getListKraByUser" :idKRA="idKRA"/>
    </el-dialog>
  </div>
</template>

<script>
import DialogConfirmDeleteKRA from "@/view/pages/user/Component/DialogConfirmDeleteKRA.vue";
import {CREATE_KRA, GET_LIST_KRA_BY_USER, UPDATE_KRA} from "@/core/services/store/backoffice/mangementKpi";
import {kraModel} from "@/view/pages/user/model/ImportKraModel";

export default {
  name: "CreateKRA",
  components: {DialogConfirmDeleteKRA},
  data() {
    return {
      isDelete: false,
      formData: {
        name: '',
        result: '',
        time: '',
        weight: '',
      },
      idKRA: null,
      userId: null,
      listKra: [],
      loading: false,
      publicPath: process.env.VUE_APP_BASE_URL
    }
  },
  props: {
    data: {
      type: Object,
      default: {}
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    isShowKra: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    this.userId = this.$route.params.id;
    this.getListKraByUser()
  },
  methods: {
    closeDialog() {
      this.isDelete = false
    },
    getListKraByUser() {
      this.$store.dispatch(GET_LIST_KRA_BY_USER, {user_id: this.userId}).then(res => {
        if (!res.error) {
          this.listKra = res.data?.map(item => {
            return new kraModel(
                item?.id,
                item?.name,
                item?.request_result,
                item?.number_hour,
                item?.percent_score, false,
                item?.department_id,
                item?.account_type_id
            );
          }).filter(kra => {
            if (this.data.part_id) {
              return kra.departmentId === this.data.part_id && kra.accountTypeId === this.data.account_type_id;
            } else {
              return kra.departmentId === this.data.department_id && kra.accountTypeId === this.data.account_type_id;
            }
          })
        }
      })
    },
    confirmDeleteKRA(id) {
      this.isDelete = true
      this.idKRA = id
    },
    deleteItemKra() {
      this.$emit('isCreatedKra')
      this.$emit('isShowKra')
    },
    handleCreateKra() {
      this.loading = true
      let department = this.handleDepartment();
      const payload = {
        user_id: this.userId,
        account_type_id: this.data.account_type_id,
        department_id: department,
        name: this.formData.name,
        percent_score: this.formData.weight,
        number_hour: this.formData.time,
        request_result: this.formData.result,
      }
      this.$store.dispatch(CREATE_KRA, payload).then(res => {
        this.$emit('isCreatedKra')
        this.$emit('isShowKra')
        this.$emit('getListKraByUser')
        this.isCreate = false
        this.$message.success('Tạo kra thành công')
        this.getListKraByUser();
        this.formData = {
          name: '',
          result: '',
          time: '',
          weight: '',
        }
        this.loading = false
      }).catch((e) => {
        console.log(e, 'Error create kra!')
      })
    },
    confirmUpdateKra(kra) {
      kra.isEdit = true
    },
    handleUpdateKra(kra) {
      this.loading = true
      let department = this.handleDepartment();
      const payload = {
        id: kra.id,
        user_id: +this.$route.params.id,
        account_type_id: this.data.account_type_id,
        department_id: department,
        name: kra.name,
        percent_score: kra.weight,
        number_hour: kra.time,
        request_result: kra.result,
      }
      this.$store.dispatch(UPDATE_KRA, payload).then(res => {
        this.$emit('getListKraByUser')
        kra.isEdit = false
        this.loading = false
        this.$message.success('Cập nhật kra thành công')
      }).catch((e) => {
        console.log(e, 'Error update kra!')
      })
    },
    handleDepartment() {
      return this.data.part_id ? this.data.part_id : (this.data.department_id ? this.data.department_id : this.data.block_id);
    }
  }
}
</script>

<style lang="css" scoped>
.action-btn {
  border-radius: 4px;
  border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-custom {
  width: 100%;
  padding: 4px 6px;
  border: none !important;
  outline: none !important;
}

.line-clamp-1 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
}

.input-focus {
  width: 100%;
  outline: 1px solid #1da1f2;
  padding: 4px 6px;
  border-radius: 4px;
}

.table-wrapper {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

thead th {
  background-color: #472F92;
  color: white;
  padding: 10px;
  text-align: left;
}

tbody td {
  padding: 10px;
  border: 1px solid #ddd;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

@media screen and (max-width: 600px) {
  thead {
    display: none;
  }

  tbody tr {
    display: block;
    margin-bottom: 10px;
  }

  tbody td {
    display: block;
    text-align: right;
    border: none;
    position: relative;
    padding-left: 50%;
  }

  tbody td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }
}
</style>