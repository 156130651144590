import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "../auth.module";

const _ = require('lodash');

export const GET_LIST_QA = "get-list-qa";
export const GET_QUESTION_BY_ID = "get-question-by-id";
export const UPDATE_QA = "update-qa";

//mutations types
export const SET_QA = "setQA";

const state = {
    errors: null,
    qa: [],
    paginateData: [],
};

const getters = {};

const actions = {
    [GET_LIST_QA](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query('qa', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },
    [GET_QUESTION_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('qa', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [UPDATE_QA](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('qa', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
}

const mutations = {
    [SET_QA](state, data) {
        state.qa = data;
        state.errors = {};
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
