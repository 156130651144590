<template>
  <div style="display: flex; flex-direction: column; gap: 10px">
    <span style="color: red">Dữ liệu bị xóa sẽ không thể khôi phục được.</span>
    <span>Vui lòng nhập lí do hủy tác vụ:</span>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form class="form" id="area_create" @submit.prevent="handleSubmit(handleDeleteTask)">
        <ValidationProvider vid="name" name="Lí do hủy tác vụ" rules="required|max:100"
                            v-slot="{ errors,classes }">
          <el-input type="textarea" ref="name" name="reason" v-model="reasonDeleteTask"
                    placeholder="Nhập lí do hủy tác vụ..." :class="classes"/>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                errors[0]
              }}
            </div>
          </div>
        </ValidationProvider>
        <div style="display: flex; justify-content: end; gap: 5px; margin-top: 10px">
          <el-button @click="closeDialog">Đóng</el-button>
          <button style="background: red; color: white; padding-left: 15px; padding-right: 15px; border-radius: 4px" type="submit">Hủy tác vụ</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {DELETE_TASK_MANAGEMENT} from "@/core/services/store/task-management/task-management.module";

export default {
  name: 'DialogCancelTask',
  data() {
    return {
      reasonDeleteTask: null,
    }
  },
  props: {
    idTask: {
      type: String | Number,
      default: null,
      required: true,
    }
  },
  methods: {
    closeDialog() {
      this.reasonDeleteTask = null
      this.$emit('closeDialog')
    },
    handleDeleteTask() {
      const payload = {
        id: this.idTask,
        params: {
          reason: this.reasonDeleteTask
        }
      }
      this.$store.dispatch(DELETE_TASK_MANAGEMENT, payload).then(res => {
        if (!res.error) {
          this.$emit('getListTaskManagement')
          this.$message.success('Hủy tác vụ thành công')
        } else {
          this.$message.error(res.message)
        }
      }).catch(e => {
        console.log(e, 'error')
      }).finally(() => {
        this.closeDialog()
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>