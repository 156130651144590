<template>
  <div>
    <b-modal
        centered id="tester-weekly-task"
        size="xl"
        title="Tác vụ tuần"
        hide-footer
        @hidden="resetModal"
    >
      <div>
        <div class="row" style="width: 90% ; z-index: 1">
          <div class="form-group col-xl-6 col-lg-12 ">
            <label class="d-block">Nhân sự Admin</label>
            <el-input disabled placeholder="Please input"
                      :value="`${  tester.user_id ? tester.user_id + DIFFERENCE_NUMBER_USER : ''} - ${tester?.name??''} - ${tester?.phone??''}`"
                      style="width: 70%"/>
          </div>
          <div class="form-group col-xl-6 col-lg-12 ">
            <label class="d-block">Tuần làm việc</label>
            <el-date-picker
                v-model="query.date_week"
                type="week"
                placeholder="Pick a week"
                :format="dateRange"
                style="width: 70%"
                clearable
                @input="onDateChanged()"
            />
          </div>
        </div>
        <div class="table-responsive fixTableHead container1" style="z-index: 1">
<!--          <table class="table table-bordered text-center table-vertical-center " style="  height: 100% ">-->
<!--            <thead>-->
<!--            <tr class="text-center">-->
<!--              <th colspan="2" class="shift" style="background-color: white">-->
<!--                <div class="bg-white" style="z-index: 1">-->
<!--                  Thời gian-->
<!--                </div>-->
<!--              </th>-->
<!--              <th class="calendar " v-for="(item, index) in days" :key="index" style="padding: 0">-->
<!--                <div class="bg-white">-->
<!--                  <div v-if="checkDayNow( item.day)" style="background-color: #5E00B6 ; width: 100% ; height: 5px">-->
<!--                  </div>-->
<!--                  <p>{{ item.dayOfWeek }}</p> <span-->
<!--                    class="text-secondary ">({{ item.day }})</span>-->
<!--                </div>-->
<!--              </th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody style="  height: 100%" v-for="(shift,index) in [1,2,3]">-->
<!--            <tr v-for="(time,index) in SHIFTS_TESTER.get(shift)">-->
<!--              <td v-if="index === 0" :rowspan="SHIFTS_TESTER.get(shift).length">{{shift === 1?"Sáng":shift===2 ? 'Chiều':'Tối'}}</td>-->
<!--              <td style="position: relative;top:-15px;height: 10px">-->
<!--                <p v-if="time.type" style="background-color: white;margin-left: 0;width: 90%;text-align: right">{{ time.time }}</p>-->
<!--                <p v-else style="background-color: white;margin-left: 0;color: white">{{ time.time }}</p>-->
<!--              </td>-->
<!--              <td :colspan="!(time.time === '' || index ===SHIFTS_TESTER.get(shift).length -1) ? 1:7"-->
<!--                  v-for="(item,index1) in (time.time === '' || index ===SHIFTS_TESTER.get(shift).length -1)?[1]: [2,1,1,1,1,1,0]"></td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--          </table>-->
          <!--          table đè lên nè-->
          <table class="table table-bordered text-center table-vertical-center overlay" style="  height: 100% ">
            <thead>
            <tr class="text-center">
              <th colspan="2" class="shift" style="background-color: white">
                <div class="bg-white" style="z-index: 1">
                  Thời gian
                </div>
              </th>
              <th class="calendar " v-for="(item, index) in days" :key="index" style="padding: 0">
                <div class="bg-white">
                  <div v-if="checkDayNow( item.day)" style="background-color: #5E00B6 ; width: 100% ; height: 5px">
                  </div>
                  <p>{{ item.dayOfWeek }}</p> <span
                    class="text-secondary ">({{ item.day }})</span>
                </div>
              </th>
            </tr>
            </thead>
            <tbody style="  height: 100%" v-for="(shift,indexShift) in [1,2,3,4,5,6]">
            <tr v-for="(time,index) in SHIFTS_TESTER.get(shift)">
              <td v-if="index === 0" :rowspan="SHIFTS_TESTER.get(shift).length">{{shift === 1?"Sáng 1":shift===2 ? 'Sáng':shift===3?'Chiều 1':shift ===4?'Chiều':shift==5?'Tối':'Tối 2'}}</td>
              <td style="position: relative;top:-10px;height: 10px">
                <p v-if="time.type"  style="background-color: white;margin-left: 0;width: 90%;text-align: right;font-weight: bold">{{ time.time }}</p>
                <p v-else style="background-color: white;margin-left: 0;color: white">{{ time.time }}</p>
              </td>
              <td :colspan="!(time.time === '' || index ===SHIFTS_TESTER.get(shift).length -1) ? 1:7" :rowspan="item1.rowSpan" v-if="item1.rowSpan!==0"
                  v-for="(item1,index1) in (time.time === '' || index ===SHIFTS_TESTER.get(shift).length -1)?[{rowSpan: 1}]: calculateTask2(time.time,index,shift)">
                <div style="width: 100%;height: 100%" class="d-flex justify-content-center align-items-center">
                  <p   v-if="item1?.nameTask"     :class="item1.color"
                       style="width: 90%;height: 95%;margin-bottom: 0" class="d-flex align-items-center justify-content-center">
                  <span>
                    <span >{{item1.idTask}} - {{item1?.nameTask}}</span>
                                  <br v-if="item1?.tasksDuplicate?.length>0 ">
                                    <br v-if="item1?.tasksDuplicate?.length>0 ">
                                                      <span v-if="item1?.tasksDuplicate?.length>0 " style="padding-top: 10px;cursor: pointer" :title="item1.nameTaskDuplicate" >
                                                                        +{{item1?.tasksDuplicate?.length}} tác vụ
                                                      </span>
                  </span>
                  </p>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </b-modal>
  </div>
</template>
<script>
import moment from "moment-timezone";
import {daysOfWeek} from "@/core/option/dayOption";
import {
  registerCalendar,
  registerCalendars,
  WeekLyTaskModel,
  WeekLyTasks
} from "@/view/pages/test/model/WeekLyTaskModel";
import {SHIFTS, SHIFTS_TESTER, STATUS_DEFINE_CSS, TYPE_TASK_ITEM_DEFINE_CSS} from "@/core/option/testInputOption";
import {} from "core-js"
import x from "@/assets/plugins/formvalidation/dist/es6/validators/vat";
import {GET_CALENDAR_WEEK_BY_TEST_ID} from "@/core/services/store/test/testInput.module";
import {DIFFERENCE_NUMBER_USER} from "@/core/option/userOption";

export default {
  name: "TesterWeeklyTask",
  data() {
    return {
      query: {
        date_week: moment().startOf('week').add(1, 'days'),
      },
      daysOfWeek: daysOfWeek,
      timeFrame: {
        allDay: Array.from(SHIFTS.values()).flat(),
        morning: SHIFTS.get(1),
        afternoon: SHIFTS.get(2),
        tonight: SHIFTS.get(3)
      },
      SHIFTS: SHIFTS,
      weeklyTasks: [],
      arr: [],
      TYPE_TASK_DEFINE_CSS: TYPE_TASK_ITEM_DEFINE_CSS,
      registerCalendars: [],
      tasks: [],
      DIFFERENCE_NUMBER_USER: DIFFERENCE_NUMBER_USER,
      SHIFTS_TESTER:SHIFTS_TESTER
    }
  },
  async mounted() {

  },
  watch: {
    async showModalWeekly() {
      if (this.showModalWeekly == false) {
        return
      }
      await this.initData()
    }
  },
  props: {
    tester: {
      type: Object,
      default() {
        return {}
      }
    },
    showModalWeekly: {
      type: Boolean,
      default() {
        return false
      }

    }
  },
  computed: {

    fullTasks() {
      return this.registerCalendars.flatMap(d => d.shift.map(c => {
        return {hour: c, day: d.dayWord};
      }));
    },
    dateRange() {
      let startWeek = moment(this.query.date_week);
      let endWeek = startWeek.clone().endOf('week');
      return startWeek.format('DD/MM/YYYY') + ' - ' + endWeek.format('DD/MM/YYYY')
    },
    days() {
      let result = [];
      if (this.query.date_week == null) {
        this.query.date_week = moment().startOf('week')
      }
      let startWeek = moment(this.query.date_week);
      for (let i = 0; i < 7; i++) {
        let tomorrow = startWeek.clone().add(i, 'days');
        result.push({
          day: tomorrow.clone().format('DD/MM/YYYY'),
          dayOfWeek: this.daysOfWeek[tomorrow.clone().day()]
        })
      }
      return result;
    },
  },
  methods: {
    calculateTask2(time, indexKhungGio, shift) {
      let taskTester = []
      this.days.forEach(({day}, index) => {
        const checkTheCalendarToSign = this.registerCalendars.find((registerCalendar) => registerCalendar?.dayWord === day && registerCalendar?.shift?.includes(time))
        if (checkTheCalendarToSign === undefined) {
          indexKhungGio === 1 ? taskTester.push({rowSpan: SHIFTS_TESTER.get(shift )?.length, nameTask: 'Không đi làm',color:'badge badge-secondary'}) : taskTester.push({rowSpan: 0})
          return
        }
        const checkTask = this.weeklyTasks.filter((task) => task.date === day && (task.timeStart <= time && time < task.timeEnd))
        if (checkTask.length > 0) {
          if(time === checkTask[0].timeStart ){
            let tasksDuplicate = [...checkTask].splice(1)
            let nameTaskDuplicate=''
            tasksDuplicate.forEach((taskDuplicate)=>{
              nameTaskDuplicate += `\n${taskDuplicate.idTask} - ${taskDuplicate.nameTask} `
            })
            taskTester.push({...checkTask[0],rowSpan:this.subtractTime(checkTask[0].timeStart,checkTask[0].timeEnd)/15,tasksDuplicate:tasksDuplicate,nameTaskDuplicate:nameTaskDuplicate})
          }else {
             taskTester.push({rowSpan: 0})
          }
          return;
        }
        taskTester.push({rowSpan: 1})
        return
      })
      return taskTester
    },
    subtractTime(time1, time2) {
      const moment1 = moment(time1, 'HH:mm');
      const moment2 = moment(time2, 'HH:mm');
      return moment2.diff(moment1, 'minutes');
    },
    checkDayNow(day) {
      return day == moment(new Date()).format('DD/MM/YYYY')
    },
    resetModal() {
      this.registerCalendars = null;
      this.query.date_week = moment().startOf('week').add(1, 'days')
      this.$emit("closeModalWeekly")
    },
    checkTask(day, hour) {
      let task = this.weeklyTasks.findLast((weekLyTask) => weekLyTask.timeStart <= hour && weekLyTask.timeEnd > hour && weekLyTask.date == day)
      return task
    },
    checkForDuplicateTasks(task) {
      return this.weeklyTasks.filter((weekLyTask) => weekLyTask.timeStart == task.timeStart && weekLyTask.timeEnd == task.timeEnd && weekLyTask.date == task.date && weekLyTask.idTask != task.idTask)

    },
    calculateTasks(timeFrame) {
      let tasks = [];
      this.days.forEach((day, i) => {
        timeFrame.forEach((hour, j) => {
          if (j === timeFrame.length - 1) {
            return;
          }
          let task = this.checkTask(day.day, hour);
          //Unassigned task
          if (task == null) {
            // last task
            let aboveTask = tasks.find(d => d.x == i && (j == 0 || d.y == j - 1));
            let isWork = this.fullTasks.find(d => d.hour == hour && d.day == day.day) != undefined;
            if (isWork == false && (aboveTask == null || aboveTask.idTask > 0 || aboveTask.work != false)) {
              tasks.push({idTask: null, x: i, y: j, span: 1, work: isWork});
              return;
            }
            let rootUnassignedTask = tasks.find(d => d.x == i && d.span >= 1 && d.y + d.span == j && d.idTask == null && d.work == false && isWork == false);
            if (rootUnassignedTask) {
              rootUnassignedTask.span++;
              tasks.push({idTask: null, x: i, y: j, span: 0, work: isWork});
              return;
            }
            tasks.push({idTask: null, x: i, y: j, span: 1, work: isWork});
            return;
          }
          //Assigned task
          let existTask = tasks.find(d => d.idTask == task.idTask);
          if (existTask == null || existTask == undefined) {
            let tasksDuplicate = this.checkForDuplicateTasks(task)
            if (tasksDuplicate) {
              let nameTaskDuplicate = ''
              tasksDuplicate.forEach((taskDuplicate) => {
                nameTaskDuplicate += `\n ${taskDuplicate.idTask} - ${taskDuplicate.nameTask} `
              })
              tasks.push({
                ...task,
                x: i,
                y: j,
                span: 1,
                count: tasksDuplicate.length,
                nameTaskDuplicate: nameTaskDuplicate
              });
              return;
            }
            tasks.push({...task, x: i, y: j, span: 1});
            return;
          }
          existTask.span++;
          tasks.push({...task, x: i, y: j, span: 0});
        })
      })
      return tasks.group((d) => d.y);
    },
    dataTask(task) {
      if (!task) {
        return {nameTask: "Không đi làm"}
      }
      return task
    },
    getCalendarWeekByTestId() {
      let payload = {
        start_week: moment(this.query.date_week).format('YYYY-MM-DD'),
        tester_id: this.tester.id
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      return this.$store.dispatch(GET_CALENDAR_WEEK_BY_TEST_ID, payload).then((data) => {
        if (!data.error) {
          this.weeklyTasks = data.data.task.map((element) => {
            return new WeekLyTaskModel(element.id, element.type_task, moment(element.schedule).format('HH:mm'), moment(element.end_schedule).format('HH:mm'), moment(element.schedule).format('DD/MM/YYYY'), element.type_item_id)
          })
          this.registerCalendars = data.data.register_calendar.map((element) => {
            return new registerCalendar(moment(element.day).format('DD/MM/YYYY'), element.ca_lam_viec)
          })
          return
        }
      }).finally(()=>{
        loading.close()
      })
    },
    async onDateChanged() {
      await this.initData()
    },
    async initData() {
      await this.getCalendarWeekByTestId();
    }
  }
}
</script>

<style scoped>

td {
  max-height: 30px !important;
  max-width: 100px !important;
  padding: 0 !important;
}

table, th, td {
  border: 1px solid #E4E6EF;
}

.bg-not-work {
  background-color: #efefef;
}

.fixTableHead {
  overflow-y: auto;
  height: 60vh;
}

.fixTableHead thead th {
  position: sticky;
  top: 0;
  z-index: 100;
}

table {
  border-collapse: collapse;
  width: 100%;
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5); /* Sử dụng màu nền trong suốt để bảng dưới có thể nhìn thấy */
}

.container1 {
  position: relative;
}


</style>