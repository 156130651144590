<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="course_create" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Tạo sự kiện
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'event-web-index' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-4 col-sm-12 col-lg-4 col-xl-3">
              <div class="image-input image-input-outline" id="kt_profile_avatar">
                <div
                    class="image-input-wrapper"
                    :style="{ backgroundImage: `url(${photo})` }"
                ></div>
                <label
                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                >
                  <i class="fa fa-pen icon-sm text-muted"></i>
                  <input
                      type="file"
                      name="profile_avatar"
                      accept=".png, .jpg, .jpeg"
                      @change="onFileChange($event)"
                  />
                  <input type="hidden" name="profile_avatar_remove"/>
                </label>
                <span
                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="cancel"
                    data-toggle="tooltip"
                    title="Cancel avatar"
                >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                <span
                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="remove"
                    data-toggle="tooltip"
                    title="Remove avatar"
                    @click="current_photo = null"
                >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
              </div>
              <br>
              <span class="text-muted">Allowed file types: png, jpg, jpeg.</span>
            </div>
            <div class="col-md-8 col-sm-12 col-lg-8 col-xl-9">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Tiêu đề
                      <span class="text-danger">*</span></label>
                    <ValidationProvider vid="title" name="Tiêu đề" rules="required"
                                        v-slot="{ errors,classes }">
                      <el-input placeholder="Tiêu đề" v-model="event_website.title" :class="classes"></el-input>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                            errors[0]
                          }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Website <span class="text-danger">*</span></label>
                    <ValidationProvider vid="website" name="Website" rules="required"
                                        v-slot="{ errors,classes }">
                      <el-select v-model="event_website.website" filterable class="w-100" placeholder="Website"
                                 @change="changeWebsite">
                        <el-option
                            v-for="item in websites"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          <span style="float: left">{{ item.label }}</span>
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="courses" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Danh mục<span class="text-danger"></span></label>
                    <ValidationProvider vid="website" name="Website" rules=""
                                        v-slot="{ errors,classes }">
                      <el-select v-model="event_website.category_id" filterable class="w-100" placeholder="Danh mục"
                      >
                        <el-option
                            v-for="item in categories"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                          <span style="float: left">{{ item.name }}</span>
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="courses" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <label>Mô tả ngắn
                    <span class="text-danger">*</span></label>
                  <div class="form-group mb-1">
                    <ValidationProvider vid="type" name="Mô tả ngắn" rules="required"
                                        v-slot="{ errors,classes }">
                                <textarea class="form-control" id="" rows="3"
                                          v-model="event_website.short_description"
                                          :class="classes"
                                          placeholder="Mô tả ngắn"></textarea>
                      <div class="fv-plugins-message-container">
                        <div data-field="courses" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-3" v-if="show_type">
                  <div class="form-group">
                    <label>Loại bài viết<span class="text-danger"> *</span></label>
                    <ValidationProvider vid="type" name="Loại bài viết" rules="required"
                                        v-slot="{ errors,classes }">
                      <el-select filterable class="w-100" placeholder="Loại bài viết" v-model="event_website.type"
                                 @change="changeType"
                                 :disabled="disable_type"
                      >
                        <el-option
                            v-for="item in types"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          <span style="float: left">{{ item.label }}</span>
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="courses" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group mb-1 col-md-12">
              <label>Nội dung
                <span class="text-danger">*</span></label>
              <ValidationProvider vid="content" name="Nội dung" rules="required"
                                  v-slot="{ errors,classes }">
                <ckeditor :editor="editor" v-model="event_website.content"></ckeditor>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                      errors[0]
                    }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Trạng thái<span class="text-danger">*</span></label>
                <ValidationProvider vid="status" name="Trạng thái" rules="required"
                                    v-slot="{ errors,classes }">
                  <el-select v-model="event_website.status" filterable class="w-100" placeholder="Trạng thái">
                    <el-option
                        v-for="item in status"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      <span style="float: left">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Publish<span class="text-danger">*</span></label>
                <ValidationProvider vid="publish" name="Publish" rules="required"
                                    v-slot="{ errors,classes }">
                  <el-select v-model="event_website.publish" filterable class="w-100" placeholder="Publish"
                             @change="changePublish">
                    <el-option
                        v-for="item in publish"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      <span style="float: left">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-3" v-if="show_time_publish">
              <div class="form-group">
                <label>Thời gian xuất bản<span class="text-danger">*</span></label>
                <ValidationProvider vid="time_publish" name="Thời gian xuất bản" rules="required"
                                    v-slot="{ errors,classes }">
                  <date-picker
                      type="datetime"
                      placeholder="Thời gian xuất bản"
                      v-model="event_website.time_publish"
                      format="DD-MM-YYYY hh:mm"
                      valueType="YYYY-MM-DD hh:mm"
                      :default-value="new Date()"
                      :disabled-date="disabledBeforeTodayAndAfterAWeek"
                  ></date-picker>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Tags:<span class="text-danger mr-2"></span></label>
                <el-tag
                    class="mb-2"
                    :key="tag"
                    v-for="tag in event_website.tags"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag)">
                  {{ tag }}
                </el-tag>
                <el-input
                    class="input-new-tag"
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    size="mini"
                    @keyup.enter.native="handleInputConfirm"
                    @blur="handleInputConfirm"
                >
                </el-input>
                <el-button v-else class="button-new-tag" size="small" @click="showInput">+ Thêm tag</el-button>
              </div>
            </div>
          </div>
        </div>
        <!--Thông tin cấp trên-->
      </div>
      <div class="card card-custom mt-3">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Meta data
            </h3>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Meta title<span class="text-danger"></span></label>
                <ValidationProvider vid="meta_title" name="Meta title" rules=""
                                    v-slot="{ errors,classes }">
                  <el-input placeholder="Meta title" :class="classes" v-model="event_website.meta_title"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="courses" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Meta description
                  <span class="text-danger"></span></label>
                <ValidationProvider vid="name" name="Meta description" rules=""
                                    v-slot="{ errors,classes }">
                  <textarea class="form-control" rows="3"
                            v-model="event_website.meta_description"
                            placeholder="Mô tả ngắn"></textarea>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <!--Thông tin cấp trên-->
      </div>
      <div class="card card-custom mt-3">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Facebook data
            </h3>
          </div>
          <div class="card-toolbar">
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Og title
                  <span class="text-danger"></span></label>
                <ValidationProvider vid="og_title" name="Og title" rules=""
                                    v-slot="{ errors,classes }">
                  <el-input placeholder="Og title" :class="classes" v-model="event_website.og_title"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Og description
                  <span class="text-danger"></span></label>
                <ValidationProvider vid="og_description" name="Og description" rules=""
                                    v-slot="{ errors,classes }">

                  <textarea class="form-control" id="exampleTextarea" rows="3" v-model="event_website.og_description"
                            placeholder="Og description"></textarea>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Og type
                  <span class="text-danger"></span></label>
                <ValidationProvider vid="name" name="Og type" rules=""
                                    v-slot="{ errors,classes }">
                  <el-input placeholder="Og type" :class="classes" v-model="event_website.og_type"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <!--Thông tin cấp trên-->
        <div class="separator separator-dashed my-5"></div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button
                type="submit"
                class="btn btn-success mr-2"
            >
              Tạo mới
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>
import Multiselect from 'vue-multiselect'
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";

import {Money} from 'v-money';
import Swal from "sweetalert2";

// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

//editor
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";
import ApiService from "@/core/services/api.service";
import {Quill, VueEditor} from 'vue2-editor'
import ImageResize from 'quill-image-resize-vue';
import {ImageDrop} from 'quill-image-drop-module';
import {CREATE_EVENT_WEBSITE, CHECK_NUMBER_HIGHLIGHTS} from "../../../core/services/store/event-web/event-web.module";
import {TYPE_NOI_BAT, TYPE_THONG_THUONG, WEBSITE_EDUTALK} from "../../../core/config/default/newConfig";
import {mapGetters} from "vuex";
import {GET_CATEGORIES} from "../../../core/services/store/news/category.module";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
export default {
  components: {
    Multiselect,
    DatePicker,
    VueEditor,
    Money
  },
  name: "EventWebsiteCreate",
  data() {
    return {
      editor: CustomEditor,
      websites: [
        {
          value: 1,
          label: 'Tất cả',
        },
        {
          value: 2,
          label: 'Edutalk',
        },
        {
          value: 3,
          label: 'STVT',
        },
        {
          value: 4,
          label: 'Educoach',
        },
        {
          value: 5,
          label: 'Ielts Mentor',
        },
      ],
      types: [
        {
          value: 'normal',
          label: 'Thông thường',
        },
        {
          value: 'highlights',
          label: 'Nổi bật',
        },
      ],
      status: [
        {
          value: 0,
          label: 'Ẩn',
        },
        {
          value: 1,
          label: 'Hiển thị',
        },
      ],
      publish: [
        {
          value: 0,
          label: 'Nháp',
        },
        {
          value: 1,
          label: 'Xuất bản',
        },
        {
          value: 2,
          label: 'Hẹn giờ xuất bản',
        },
        {
          value: 3,
          label: 'Không xuất bản',
        },
      ],
      categories: [],
      show_type: false,
      disable_type: false,
      show_time_publish: false,
      // Check show
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      event_website: {
        title: '',
        category_id: '',
        website: '',
        type: '',
        short_description: '',
        content: '',
        status: '',
        meta_title: '',
        meta_description: '',
        og_title: '',
        og_description: '',
        og_type: '',
        publish: 0,
        time_publish: '',
        tags: []
      },
      /*photo*/
      default_photo: "media/users/blank.png",
      current_photo: null,
      image_name: null,
      image_type: null,

      // Tags
      inputVisible: false,
      inputValue: ''
    }
  },
  computed: {
    ...mapGetters(["getTotalEventHighlights"]),
    photo() {
      return this.current_photo == null
          ? this.default_photo
          : this.current_photo;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý sự kiện", route: 'event-web-index'},
      {title: "Tạo sự kiện"}
    ]);
  },
  methods: {
    changePublish($event) {
      this.event_website.time_publish = '';
      if ($event == 2) {
        this.show_time_publish = true;
      } else {
        this.show_time_publish = false;
      }
    },
    disabledBeforeTodayAndAfterAWeek(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    changeWebsite($event) {
      this.event_website.category_id = '';
      this.getCategories();
      this.show_type = true;
      if ($event != WEBSITE_EDUTALK) {
        this.event_website.type = TYPE_THONG_THUONG;
        this.disable_type = true;
      } else {
        this.disable_type = false;
      }
    },
    getCategories() {
      this.$store.dispatch(GET_CATEGORIES, {
        limit: 1000,
        website_id: this.event_website.website
      }).then((res) => {
        if (!res.data.error) {
          this.categories = res.data.data;
        }
      });
    },
    changeType($event) {
      if ($event == TYPE_NOI_BAT) {
        this.sumHighlights();
      }
    },
    sumHighlights() {
      this.$store.dispatch(CHECK_NUMBER_HIGHLIGHTS).then((res) => {
        if (!res.error) {
          if (this.getTotalEventHighlights >= 6) {
            this.event_website.type = '';
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Tối đa 6 bài nổi bật thuộc trang này!',
              // footer: '<a href="">Tối đa 6 bài nổi bật thuộc trang này</a>'
            })
          }
        }
      })
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
        this.image_name = file.name;
        this.image_type = file.type;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("image", file);

      ApiService.post('upload-image', formData)
          .then(({data}) => {
            let url = data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch(err => {
            console.log(err);
          });
    },
    async onSubmit() {
      let formData = new FormData();
      for (var key in this.event_website) {
        formData.append(key, this.event_website[key]);
      }
      let avatar = this.photo;

      function urltoFile(url, filename, mimeType) {
        return (fetch(url)
                .then(function (res) {
                  return res.arrayBuffer();
                })
                .then(function (buf) {
                  return new File([buf], filename, {type: mimeType});
                }).catch((error) => {
                  return 1;
                })
        );
      }

      if (avatar.search('https') === -1) {
        let file = await urltoFile(avatar, this.image_name, this.image_type);
        if (file !== 1 && file.name !== 'null') {
          formData.append('avatar', file);
        }
      }

      this.$store.dispatch(CREATE_EVENT_WEBSITE, formData).then((res) => {
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Tạo mới',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$router.push({name: "event-web-index"}).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Tạo mới',
              variant: 'success',
              solid: true
            });
          })
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      });
    },
    /*Tags*/
    handleClose(tag) {
      this.event_website.tags.splice(this.event_website.tags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.event_website.tags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    }
    /*End tags*/
  }
}
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}

.image-input-wrapper {
  width: 215px !important;
  height: 215px !important;
  left: 0px !important;
  top: 24px !important;
  /*background: #C4C4C4;*/
  border-radius: 5px !important;
}

/*Tags*/
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
  margin-bottom: 8px;
}

/*End tags*/

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
