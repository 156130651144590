<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Chi tiết khách hàng</h3>
            </div>
            
        </div>
        <div class="card-body" v-if="currentUser.user.permission.includes(permission.XEM_QLKH)">
            <div class="d-flex justify-content-end mb-2">
                <b-button  v-if="!parent" v-b-modal="'handle-add-guardian'" class="btn btn-success btn-sm">Thêm người giám hộ
                </b-button>
                <addGuardian
                @reload="reload">
                </addGuardian>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <el-tabs type="border-card" v-model="active_name">
                        <el-tab-pane>
                            <span slot="label">Thông tin cơ bản</span>
                            <customer-user-info :user="this.user" :parent="parent" :is_kid="is_kid" @update="updateUser"></customer-user-info>
                        </el-tab-pane>
                        <el-tab-pane>
                            <span slot="label">Kinh doanh</span>
                            <crm-kinh-doanh></crm-kinh-doanh>
                        </el-tab-pane>
                        <el-tab-pane label="Tài chính">
                            <tai-chinh :contracts="contracts"
                                       :student-amounts="studentAmounts"
                                       :student-register-courses="studentRegisterCourses"
                                       :cash="cash"
                                       :total-after-voucher="totalAfterVoucher"
                                       :total-paid="totalPaid"
                                       :total-debt="totalDebt"
                                       :refunds="refunds"
                                       :total-withdraw="totalWithdraw"
                                       :history-transfer="historyTransfer"
                            ></tai-chinh>
                        </el-tab-pane>
                        <el-tab-pane label="Học tập">
                            <hoc-tap :student-register-courses="courseStudentStudies"></hoc-tap>
                            <!-- <entry-test-result :testInputResults="testInputResults"></entry-test-result> -->
                        </el-tab-pane>
                        <el-tab-pane name="cskh" label="CSKH">
                            <customer-c-s-k-h :tickets="tickets"
                                              :ticket-waiting="ticketWaiting"
                                              :ticket-working="ticketWorking"
                                              :ticket-cancel="ticketCancel"
                                              :ticket-done="ticketDone"
                                              :ticket-reason="ticketReason"
                            ></customer-c-s-k-h>
                        </el-tab-pane>
                        <el-tab-pane label="Lịch sử khách hàng">
                            <crm-history></crm-history>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {GET_CUSTOMER_USER, CUSTOMER_FINANCE, GET_REFUND_BY_USER, CUSTOMER_STUDY, CUSTOMER_TICKET, GET_INFO_USER_PARENT, GET_TEST_INPUT_USER} from "../../../core/services/store/customer/customer-crm.module";
import CrmKinhDoanh from "./CrmKinhDoanh";
import TaiChinh from "@/view/pages/customers/customerDetail/Tai-Chinh";
import HocTap from "@/view/pages/customers/customerDetail/Hoc-Tap";
import EntryTestResult from "@/view/pages/customers/customerDetail/EntryTestResult";
import CustomerCSKH from "@/view/pages/customers/customerDetail/Customer-CSKH";
import CustomerUserInfo from "@/view/pages/customers/CustomerUserInfo";
import CrmHistory from "@/view/pages/customers/CrmHistory";
import AddGuardian from "@/view/pages/customers/customerDetail/AddGuardian";
import moment from "moment-timezone";

export default {
    name: "DetailCustomerCrm",
    components: {TaiChinh, HocTap, CustomerCSKH, CrmKinhDoanh, CustomerUserInfo, CrmHistory, EntryTestResult, AddGuardian},
    computed: {
        ...mapGetters(["currentUser"]),
    },
    created() {
        const userBestCustomerLevelId = this.$route.query.best_customer_id;
        this.getCustomerFinance(userBestCustomerLevelId);
        this.getCustomerStudy(userBestCustomerLevelId);
        this.getCustomerTicket(userBestCustomerLevelId);
    },
    data() {
      return {
          user: {
              profile: {}
          },
          activeName: 'first',
          contracts: [],
          studentAmounts: [],
          historyTransfer: [],
          studentRegisterCourses: [],
          totalAfterVoucher: 0,
          totalPaid: 0,
          totalDebt: 0,
          totalWithdraw: 0,
          cash: 0,
          courseStudentStudies: [],
          tickets: [],
          ticketWaiting : 0,
          ticketWorking : 0,
          ticketDone : 0,
          ticketCancel : 0,
          active_name : '',
          ticketReason: [],
          refunds: [],
          parent: {},
          testInputResults: [],
          is_kid: ''
      }
    },
    mounted() {
      this.getRefunds(this.$route.params.id);
      this.getCustomer();
      this.getParent();
      this.active_name = this.$route.query.active_name ? this.$route.query.active_name : '';
      this.$store.dispatch(GET_CUSTOMER_USER, { id: this.$route.params.id}).then(res => {
        if (!res.error) {
          this.user = res.data;
        }
      });
    //   this.getTestInputResult();
    },
    methods: {
        // getTestInputResult(){
                // this.$store.dispatch(GET_TEST_INPUT_USER, { id: this.$route.params.id}).then((res) => {
                    // this.testInputResults = res.data;
                // }).catch(() => {
                    // this.$notify({
                        // type: 'error',
                        // message: 'Lấy kết quả test đầu vào thất bại',
                        // title:' Thất bại'
                    // });
                // })
            // },
        getCustomerFinance(userBestCustomerLevelId){
            this.$store.dispatch(CUSTOMER_FINANCE, userBestCustomerLevelId).then((res) => {
                this.contracts = res.data.contracts;
                this.studentAmounts = res.data.studentAmounts;
                this.historyTransfer = res.data.historyTransfer;
                let filterStudentRegisterCourses = res.data.studentRegisterCourses;
                this.studentRegisterCourses = filterStudentRegisterCourses.filter( (course) => course.status_transfer != 1);
                this.totalAfterVoucher = res.data.totalAfterVoucher;
                this.totalPaid = res.data.totalPaid;
                this.totalDebt = res.data.totalDebt;
                this.totalWithdraw = res.data.totalWithdraw;
                this.cash = res.data.cash;
            }).catch(() => {
                this.$notify({
                    type: 'error',
                    message: 'Có lỗi xảy ra',
                    title:' Thất bại'
                });
            })
        },
        getCustomerStudy(userBestCustomerLevelId){
            this.$store.dispatch(CUSTOMER_STUDY, userBestCustomerLevelId).then((res) => {
                this.courseStudentStudies = res.data;
            }).catch(() => {
                this.$notify({
                    type: 'error',
                    message: 'Lấy thông tin học tập thất bại',
                    title:' Thất bại'
                });
            })
        },
        getCustomerTicket(userBestCustomerLevelId){
            this.$store.dispatch(CUSTOMER_TICKET, userBestCustomerLevelId).then((res) => {
                this.tickets = res.data.tickets;
                this.ticketWaiting = res.data.ticketWaiting;
                this.ticketWorking = res.data.ticketWorking;
                this.ticketDone = res.data.ticketDone;
                this.ticketCancel = res.data.ticketCancel;
                this.ticketReason = res.data.ticketReason;
            }).catch(() => {
                this.$notify({
                    type: 'error',
                    message: 'Lấy thông tin chăm sóc khách hàng thất bại',
                    title:' Thất bại'
                });
            })
        },
        getRefunds(userBestCustomerLevelId){
          this.$store.dispatch(GET_REFUND_BY_USER ,{
            user_id:userBestCustomerLevelId
          }).then((res) => {
            this.refunds = res.data;
          }).catch(() => {
            this.$notify({
              type: 'error',
              message: 'Lấy thông tin chăm sóc khách hàng thất bại',
              title:' Thất bại'
            });
          })
        },
        updateUser(value) {
            if (value) {
                this.getCustomer();
            }
        },
        getCustomer() {
            this.$store.dispatch(GET_CUSTOMER_USER, { id: this.$route.params.id}).then(res => {
                if (!res.error) {
                    this.user = res.data;
                }
            });
        },
        getParent() {
            this.$store.dispatch(GET_INFO_USER_PARENT, { id: this.$route.params.id}).then(res => {
                if (!res.error) {
                    this.parent = res.data.parent;
                    this.is_kid = res.data.is_kid;
                    this.parent.value_other_type = res.data.parent ? res.data.parent.type == 'me' ? 'Mẹ' : (res.data.parent.type == 'bo' ? 'Bố' : res.data.parent.value_other_type) : '';
                }
            });
        },
        getType(type) {
            let text = '';
            switch (type) {
                case 'me':
                    text = 'Mẹ';
                    break;
                case 'bo':
                    text = 'Bố';
                    break;

                default:
                    text = 'Khác';
                    break;
            }
            return text;
        },

        reload() {
            this.getRefunds(this.$route.params.id);
            this.getCustomer();
            this.getParent();
        }
    },
}
</script>

<style scoped>
    .btn-lich-su-khach-hang {
        text-align: center;
        border: 1px solid #ccc;
        padding: 8px 20px;
        width: 150px !important;
        border-radius: 5px;
    }
    @media(max-width: 425px) {
        .customer-tab {
          padding: 0 !important;
        }
    }
    .info-user {
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
        border: 1px solid #ccc;
        display: flex;
        padding: 20px;
    }
    .user_info {
        padding-top: 5px;
    }
</style>
