<template>
  <div class="">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title"
           style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
        <h3 class="card-label">Báo cáo tài chính văn phòng</h3>
        <el-button type="primary" style="color: white" @click="exportExcel">Xuất Excel</el-button>
      </div>
    </div>
    <div class="card-body bg-white">
      <div class="example">
        <div class="row">
          <div class="col-md-2">
            <label for="">Thời gian</label>
            <el-date-picker
                class="w-100"
                v-model="query.range_date"
                type="daterange"
                placeholder="Chọn tháng"
                format="MM/yyyy"
                value-format="yyyy-MM"
            >
            </el-date-picker>
          </div>
          <div class="col-md-2">
            <label for="">Khu vực</label>
            <el-select v-model="query.area_id" clearable filterable collapse-tags placeholder="Chọn khu vực"
                       @change="changeArea">
              <el-option
                  v-for="item in areas"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-md-2">
            <label for="">Văn phòng</label>
            <el-select v-model="query.branch_id" clearable filterable collapse-tags
                       placeholder="Nhập tên văn phòng">
              <el-option
                  v-for="item in branches"
                  :key="item.id"
                  :label="item.name_alias ? item.name_alias : item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-md-2 mt-8">
            <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                    @click="searchData">
              <i :class="{
                                'fa fa-search': !isLoading,
                                'el-icon-loading': isLoading,
                            }"></i> Tìm kiếm
            </button>
          </div>
        </div>

        <div class="example-preview table-responsive overflow-auto mt-6">
          <table :aria-busy="isBusy"
                 class="table table-hover table-bordered table-vertical-center b-table bg-white"
                 style="text-align: center">
            <thead>
            <tr>
              <th scope="col" class="min-w-30px">ID</th>
              <th scope="col" class="min-w-100px">Tên văn phòng</th>
              <th scope="col" class="min-w-100px">Khu vực</th>
              <th scope="col" class="min-w-100px">Size văn phòng</th>
              <th scope="col" class="min-w-100px">Lợi nhuận văn phòng còn lại</th>
              <th scope="col" class="min-w-120px">Tổng tài sản</th>
              <th scope="col" class="min-w-120px">Thời gian</th>
            </tr>
            </thead>
            <tbody v-if="data.length > 0">
            <tr v-for="(item, index) in data " :key="index">
              <td>{{ item.branch_id }}</td>
              <td>{{ item.branch_name }}</td>
              <td>{{ item.area_name }}</td>
              <td>{{ item.size }}</td>
              <td>{{ item.remaining_profit | formatVND }}</td>
              <td>{{ item.total | formatVND }}</td>
              <td>{{ item.date }}</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
            </tr>
            </tbody>
          </table>
        </div>
        <p class="d-flex justify-content-end">Tổng số bản ghi: <span
            class="text-primary font-weight-bold">{{ total }}</span></p>
      </div>
    </div>

    <div class="edu-paginate mx-auto d-flex text-center justify-content-center bg-white">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {EXPORT_FINANCE_REPORT, GET_LIST_TOTAL_ASSET} from "@/core/services/store/finance/branchTotalAsset.module";
import {GET_LIST_AREAS} from "@/core/services/store/center/area.module";
import {GET_BRANCHES} from "@/core/services/store/center/branch.module";
import {exportExcel} from "@/core/filters";

export default {
  name: "FinanceBranchReportIndex",
  components: {
    DatePicker
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  data() {
    return {
      isBusy: false,
      page: 1,
      last_page: 1,
      total: 0,
      data: [],
      areas: [],
      branches: [],
      query: {
        data: null,
        branch_id: null,
        area_id: null,
        range_date: []
      },
      is_disable_search: false,
      isLoading: false,
      is_export_excel: false
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý vốn góp cổ đông thực tế", route: '#'},
      {title: "Báo cáo tài chính văn phòng"}
    ]);
    this.getList();
    this.getAreas();
  },
  methods: {
    getList() {
      this.isLoading = true;
      let payload = {
        page: this.page,
        ...this.query
      }
      this.$store.dispatch(GET_LIST_TOTAL_ASSET, payload).then((res) => {
        this.data = res.data;
        this.last_page = res.pagination.last_page;
        this.total = res.pagination.total;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    exportExcel() {
      this.is_export_excel = true
      const HEADER_ROW_EXPORT_BRANCH_REPORT = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'branch_id',
          value: 'ID văn phòng',
        },
        {
          key: 'branch_name',
          value: 'Tên văn phòng',
        },
        {
          key: 'area_name',
          value: 'Khu vực',
        },
        {
          key: 'size',
          value: 'Size văn phòng',
        },
        {
          key: 'remaining_profit',
          value: 'Lợi nhuận văn phòng còn lại',
        },
        {
          key: 'total',
          value: 'Tổng tài sản',
        },
        {
          key: 'date',
          value: 'Thời gian',
        },
        {
          key: 'created_at',
          value: 'Thời điểm tính',
        },
      ]
      const payload = {
        area_id: this.query.area_id,
        branch_id: this.query.branch_id,
        range_date: this.query.range_date,
      }
      this.$store.dispatch(EXPORT_FINANCE_REPORT, payload).then((res) => {
        this.is_export_excel = false
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        }
        exportExcel(HEADER_ROW_EXPORT_BRANCH_REPORT, res.data, 'Báo cáo tài chính văn phòng.xlsx', true)
      });
    },
    getAreas() {
      this.$store.dispatch(GET_LIST_AREAS, {}).then(data => {
        this.areas = data.data;
      });
    },
    changeArea() {
      this.query.branch_id = null;
      this.branches = [];
      this.getBranches();
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {area_id: this.query.area_id, all: true}).then((res) => {
        this.branches = res.data;
      });
    },
    searchData() {
      this.page = 1;
      this.$router.push({name: 'finance-branch-report-index', query: {page: this.page, ...this.query}})
      this.getList();
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({name: 'finance-branch-report-index', query: {...this.query, page: this.page}})
      this.getList()
    },
  },

  computed: {
    ...mapGetters(["dataRoleDepartment"]),
  },
}
</script>

<style scoped>
.text-dark {
  color: red !important;
}
</style>