<template>
    <div class="">
        <div class="card-header d-flex justify-content-left">
            <div class="card-title">
                <h3 class="card-label">Báo cáo xuất - nhập kho</h3>
            </div>
        </div>
        <div class="card-body bg-white d-flex">
            <el-date-picker
                v-model="date_range"
                type="daterange"
                range-separator="To"
                start-placeholder="Ngày bắt đầu"
                value-format="yyyy-MM-dd"
                end-placeholder="Ngày kết thúc">
            </el-date-picker>
            <button
                class="font-weight-bold font-size-3  btn btn-success ml-4"
                @click="exportExcel"
                v-loading="loading_export"
            >Xuất excel
            </button>
        </div>
    </div>
</template>

<script>
import {EXPORT_COURSE_GIFT} from "../../../core/services/store/course/courseGift.module";
import {exportExcel} from "@/core/filters";

export default {
    name: "ExportCourseGift",
    data() {
        return {
            date_range: '',
            loading_export: false
        }
    },
    methods: {
        exportExcel() {
            this.loading_export = true;
            const HEADER_ROW_EXPORT_COURSE_GIFT = [
                {
                    key: 'center_name',
                    value: 'Trung tâm',
                },
                {
                    key: 'branch_name',
                    value: 'Chi nhánh',
                },
                {
                    key: 'created_at',
                    value: 'Thời gian',
                },
                {
                    key: 'name_gift',
                    value: 'Tên quà',
                },
                {
                    key: 'code_gift',
                    value: 'Mã quà',
                },
                {
                    key: 'quantity_imported',
                    value: 'Số lượng nhập kho',
                },
                {
                    key: 'quantity_issued_students',
                    value: 'Số lượng xuất cho học viên',
                },
                {
                    key: 'quantity_exported_branch',
                    value: 'Số lượng xuất sang chi nhánh',
                },
                {
                    key: 'number_refunds_students',
                    value: 'Số lượng hoàn từ học viên',
                },
                {
                    key: 'quantity_in_stock',
                    value: 'Số lượng còn trong kho',
                },

            ]
            this.$store.dispatch(EXPORT_COURSE_GIFT, {date_range: this.date_range}).then((res) => {
                this.dataExport = res.data;
                this.loading_export = false;

                if (res.data.error) {
                    this.$bvToast.toast(res.data.message, {
                        title: 'Export excel',
                        variant: 'danger',
                        solid: true
                    });
                }
                exportExcel(HEADER_ROW_EXPORT_COURSE_GIFT, this.dataExport, "Danh sách qùa tặng theo khóa.xlsx", false)
            })
        }
    }
}
</script>

<style scoped>

</style>