<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form" @submit.prevent="handleSubmit(summit)" autocomplete="off">
            <h4>Vui lòng nhập Số HP muốn trừ:</h4>
            <h4>Học viên đi muộn: Trừ <b class="text-danger">10</b> HP</h4>
            <h4>Học viên vắng học: Trừ <b class="text-danger">30</b> HP</h4>
            <h4>Học viên học không tốt: Trừ <b class="text-danger">10 - 40</b> HP</h4>
            <div class="form-row mt-4">
                <div class="col-md-12 mb-3">
                    <ValidationProvider vid="birthday" name="Số HP " rules="required|max:150"
                                        v-slot="{ errors, classes }">
                        <input type="number" max="150" class="form-control" :class="classes" min="0" step="1" placeholder="Nhập số HP muốn trừ" autocomplete="false" v-model="form.hp">
                        <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                            {{ errors[0] }}
                        </div>
                    </ValidationProvider>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-end mt-3">
                <button class="btn btn-default mr-2" type="button" @click="close">
                    <i v-if="loading" class="el-icon-loading"></i> Đóng
                </button>
                <button class="btn btn-primary" type="submit">
                    <i v-if="loading" class="el-icon-loading"></i> Đồng ý
                </button>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
const _ = deepdash(lodash);
import {SUB_HP} from "../../../../core/services/store/course/classes.module";

export default {
    name: "Punish",
    props: {
        // id của team
        idMember : {
            type: Number,
            default: 0
        },// id của team
        nameMember : {
            type: String,
            default: null
        },
    },
    data() {
        return {
            loading: false,
            form: {
                hp: ''
            }
        }
    },
    methods: {
        summit() {
            this.$store.dispatch(SUB_HP, {user_clan_id : this.idMember, hp: this.form.hp}).then(()=> {
                this.close();
                this.$message({
                    type: 'success',
                    message: 'Trừ HP thành công!',
                    showClose: true
                });
            }).catch(() =>{
                this.$message({
                    type: 'error',
                    message: 'Sever đang bận. Vui lòng thử lại sau!',
                    showClose: true
                });
            })
        },
        close: function () {
            this.$emit('close');
        },
    },
}
</script>

<style scoped>

</style>