<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Danh sách khóa học</h3>
            </div>
            <div class="card-title">
                <router-link
                        class="font-weight-bold font-size-3  btn btn-success"
                        :to="{ name: 'course-create' }"
                >Thêm mới
                </router-link>
            </div>
        </div>
        <div class="card-body">
            <!--begin::Example-->
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" v-model="query.keyword" class="form-control"
                               placeholder="Tên khóa học">
                    </div>
                    <!--end::Form-->
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                      <el-select v-model="query.center_id" filterable class="w-100" placeholder="Trung tâm" clearable>
                        <el-option
                            v-for="item in centers"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                          <span style="float: left">{{ item.name }}</span>
                        </el-option>
                      </el-select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="d-flex justify-content-between">
                        <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                                @click="searchData">
                            <i class="fa fa-search"></i> Tìm kiếm
                        </button>
                        <button v-else type="button" disabled
                                class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                            Tìm kiếm
                        </button>
                    </div>
                </div>
            </div>

            <div class="example mb-10">
                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col">Tên khóa học</th>
                            <th scope="col">Học phí</th>
                            <th scope="col">Trung tâm</th>
                            <th scope="col">Trình độ đầu vào</th>
                            <th scope="col">Trình độ đầu ra</th>
                            <th scope="col">Loại khóa học</th>
                            <th scope="col">Sale (%)</th>
                            <th scope="col">Video giới thiệu</th>
                          <th scope="col">Trạng thái</th>
                            <th scope="col">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="(item, index) in courses.data" :key="index">
                            <td>{{item.name}}</td>
                            <td>{{item.fee > 0 ? formatPrice(item.fee, 'đ') : ''}}</td>
                            <td>{{item.center != null ? item.center.name : ''}}</td>
                            <td>{{item.level_in != null ? item.level_in.name : ''}}</td>
                            <td>{{item.level_out != null ? item.level_out.name : ''}}</td>
                            <td>{{item.course_type != null ? item.course_type.name : ''}}</td>
                            <td>{{item.sale != null ? item.sale + `%` : ''}}</td>
                            <td>{{item.video}}</td>
                          <td>
                            <a href="javascript:" class="badge" v-bind:class="item.status === 1 ? classSpan.success : classSpan.danger">
                              {{item.status === 1 ? "Hiển thị" : "Ẩn"}}
                            </a>
                          </td>
                            <td>
                                <router-link :to="{ name: 'course-edit', params: {id : item.id} }" title="Sửa"
                                             class="btn btn-sm btn-icon mr-2 btn-outline-info"><i
                                        class="fas fa-pen-nib"></i>
                                </router-link>
                                <a title="Xóa" @click="deleteCourse(item.id)" href="javascript:"
                                   class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="edu-paginate mx-auto text-center">
            <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                    :page-class="'page-item'"
            >
            </paginate>
        </div>
    </div>
</template>
<script>
    import Swal from "sweetalert2";
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {GET_COURSES, DELETE_COURSE} from "../../../core/services/store/course/course.module";
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
    import Multiselect from 'vue-multiselect'

    const _ = require('lodash');

    export default {
        components: {Multiselect},
        name: "BranchIndex",
        data() {
            return {
                is_disable_search: false,
                courses: [],
                // Paginate
                page: 1,
                last_page: 1,
                query: {
                    keyword: '',
                    center_id: ''
                },
                centers: [],
                cacheCenter: [],
                classSpan: {
                  success: 'badge-success',
                  danger: 'badge-danger'
                },
            }
        },
        mounted() {
            this.getList()
            this.getCenters()
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý khóa học", route: 'profile-1'},
                {title: "Danh sách khóa học"}
            ]);
        },
        methods: {
            searchData() {
                this.page = 1
                this.is_disable_search = true
                this.pushParamsUrl()
                this.callApiGetList()
            },
            pushParamsUrl() {
                this.$router.push({
                    name: 'course-index',
                    query: {
                        page: this.page,
                        keyword: this.query.keyword,
                        center_id: this.query.center_id,
                    }
                })
            },
            async getCenters() {
                this.$store.dispatch(GET_CENTERS, {
                    limit: 10000,
                    status: 1
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data
                        this.setCacheTheLoad()
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            handleQueryCenter($event) {
                let id = $event.id
                this.query.center_id = id
            },
            getList() {
                this.pullParamsUrl()
                this.callApiGetList()
            },
            pullParamsUrl() {
                this.page = parseInt(this.$route.query.page);
                this.query.keyword = this.$route.query.keyword
                this.query.center_id = this.$route.query.center_id
            },
            setCacheTheLoad() {
                if (this.query.center_id != '') {
                    let oldParamCenter = this.centers.filter((value) => {
                        if (parseInt(this.query.center_id) == value.id) {
                            return value
                        }
                    })
                    this.cacheCenter = oldParamCenter
                }
            },
            addFirstSelectExample(obj, text) {
                obj.unshift({
                    name: text
                })
            },
            clickCallback(obj) {
                this.page = obj
                this.$router.push({path: '', query: {page: this.page}})
                this.callApiGetList()
            },
            callApiGetList() {
                this.$store.dispatch(GET_COURSES, {
                    page: this.page,
                    keyword: this.query.keyword,
                    center_id: this.query.center_id,
                }).then((res) => {
                    if (!res.data.error) {
                        this.is_disable_search = false;
                        this.courses = res.data;
                        this.last_page = res.data.last_page;
                    }
                });
            },
            deleteCourse(id) {
                Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire("Xóa", "", "success");
                        this.$store.dispatch(DELETE_COURSE, id).then((res) => {
                            if (res.error) {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'danger',
                                    solid: true
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'success',
                                    solid: true
                                });
                                this.getList()
                            }
                        });
                    }
                });
            },
        }
    }
</script>
<style>
    .mx-datepicker {
        width: 100% !important;
    }

    .mx-input {
        min-height: 38px !important;
    }
</style>
