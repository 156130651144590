<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Import quay thưởng</h3>
        </div>
      </div>
      <div class="card-body">
        <div class="example mb-10">
          <div class="input-group row mb-5">
            <div class="col-md-12">
              <ValidationObserver v-slot="{ handleSubmit }" >
                <form class="form-inline" @submit.prevent="handleSubmit(onSubmit)">
                  <ValidationProvider ref="validate_file" rules="required" name="File" v-slot="{ validate, errors }" class="mr-2 mt-1">
                    <div class="upload">
                      <div v-for="file in files" :key="file.id">
                        <i class="fas fa-minus-circle mr-2" style="cursor: pointer;" @click="removeFile"></i>
                        <span>{{file.name}}</span> -
                        <span>{{file.size}}</span> -
                        <span v-if="file.error">{{file.error}}</span>
                        <span v-else-if="file.success">success</span>
                        <span v-else-if="file.active">active</span>
                        <span v-else-if="!!file.error">{{file.error}}</span>
                        <span v-else></span>
                      </div>
                      <div class="example-btn d-flex justify-content">
                        <file-upload
                            class="btn btn-text-dark-50 btn-icon-primary font-weight-bold btn-hover-bg-light mr-3"
                            post-action=""
                            extensions="xlsx,xls"
                            accept=".xlsx,.xls"
                            :size="1024 * 1024 * 10"
                            v-model="files"
                            @input-filter="inputFilter"
                            @input-file="inputFile"
                            ref="upload">
                          <i class="flaticon-upload"></i>
                          Chọn file
                        </file-upload>
                        <button type="button" @click="onSubmit" class="btn btn-success ml-2" :disabled="isAble" :class="loadingClass">
                          <i class="fa fa-arrow-up" aria-hidden="true"></i>
                          Upload thu nhập
                        </button>
                      </div>
                    </div>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </form>
              </ValidationObserver>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Multiselect from 'vue-multiselect';
import {
  IMPORT_LUCKY_DRAW
} from "@/core/services/store/user/users.module";
import FileUpload from 'vue-upload-component';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Swal from "sweetalert2";
const _ = require('lodash');

export default {
  name: "LuckyDraw",
  data() {
    return {
      upload: null,
      files: [],
      isAble : true,
      loading : false,
      show_process_loading: false,
      loadingClass: ''
    }
  },
  components : {
    Multiselect,FileUpload,DatePicker
  },
  computed : {
  },
  mounted() {
     this.setBreadcrumb();
  },
  methods : {
    inputFilter: function(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile: function(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.dataImport = newFile.file;
        this.isAble = false;
      }
      if (newFile && oldFile) {
        // update
      }
      if (!newFile && oldFile) {
        // remove
      }
    },
    removeFile : function (){
      this.files.pop();
      this.isAble = true;
    },
    onFileChange (event) {
      const { valid } = this.$refs.validate_file.validate(event);
      this.dataImport = event.target.files[0];
    },
    onSubmit: function () {
      let formData = new FormData();
      this.isAble = true;
      this.loadingClass = 'spinner spinner-darker-success spinner-left mr-3';
      this.show_process_loading = true;
      formData.append('dataImport', this.dataImport);
      this.$store.dispatch(IMPORT_LUCKY_DRAW,formData).then( (res) => {
        this.removeFile();
        this.loadingClass = '';
      }).catch(() => {
        this.$bvToast.toast('Có lỗi xảy ra', {
          title: 'Import',
          variant: 'danger',
          solid: true
        });
        this.loadingClass = '';
        this.show_process_loading = false;
        setPercent(0);
      });
    },
    setBreadcrumb: function (){
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Quản lý nhân sự"},
        { title: "Thu nhập nhân sự" }
      ]);
    },
  }
}
</script>

<style>
.fa-minus-circle:hover {
  color: red !important;

}
.input-group i {
  font-size : 0.9rem;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
