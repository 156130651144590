import ApiService from "@/core/services/api.service";
import {IMPORT_SALE_TREE} from "@/core/services/store/user/users.module";

const _ = require('lodash');

export const ATTENDANCE_GET_USER_LIST = "attendance-get-user_list";
export const ATTENDANCE_MAPPING_USER = "attendance_mapping_user";
export const ATTENDANCE_UPDATE_STATUS = "attendance_update_status";
export const ATTENDANCE_MAPPING_TELEGRAM = "attendance_mapping_telegram";
export const ATTENDANCE_MAPPING_DEVICE = "attendance_mapping_device";
export const ATTENDANCE_MAPPING_TYPE = "attendance_mapping_type";
export const ATTENDANCE_MAPPING_TIMEWORK = "attendance_mapping_timework";
export const ATTENDANCE_MAPPING_TIMELATE = "attendance_mapping_timelate";
export const ATTENDANCE_IMPORT_EXCEL = "attendance_import_excel";

const state = {};

const getters = {
    getEventAttendancePaginate(state) {
        return state.userPaginatedData;
    },
};

const actions = {
    [ATTENDANCE_GET_USER_LIST](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('okr/list-user-attendance', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    reject(res);
                });
        });
    },
    [ATTENDANCE_MAPPING_TYPE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('okr/attendance-mapping-type', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [ATTENDANCE_MAPPING_TIMEWORK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('okr/attendance-mapping-timework', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [ATTENDANCE_MAPPING_TIMELATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('okr/attendance-mapping-time-late', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [ATTENDANCE_MAPPING_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('okr/mapping-user-fingerprint', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [ATTENDANCE_MAPPING_TELEGRAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('okr/mapping-telegram-fingerprint', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [ATTENDANCE_MAPPING_DEVICE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('okr/mapping-device-fingerprint', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [ATTENDANCE_UPDATE_STATUS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('okr/update-status', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },


    [ATTENDANCE_IMPORT_EXCEL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("okr/import-excel", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
}

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};
