import ApiService from "@/core/services/api.service";
// action types
export const CREATE_ACCOUNT_TYPE = "create_account_type";
export const UPDATE_ACCOUNT_TYPE = "update_account_type";
export const UPDATE_ACCOUNT_TYPE_AVATAR = "update_account_type_avatar";
export const DELETE_ACCOUNT_TYPE = "delete_account_type";
export const GET_ACCOUNT_TYPE_BY_ID = "get_account_type_by_id";
export const GET_ACCOUNT_TYPE = "get_account_type_index";
export const GET_ACCOUNT_TYPE_FOR_SELECT = "account-type-list";
export const GET_CUSTOMER_ACCOUNT_TYPE = "get_customer_account_type";
export const GET_POSITIONS = "get_positions";
export const GET_ROLES = "get_role";
export const GET_ROLE_BACKEND = "role-backend";
export const SET_ROLES = "set_role";
export const SET_ACCOUNT_TYPE_INDEX = "set_role";
export const SET_ACCOUNT_TYPE_BY_ID = "set_account_type_by_id";
export const SET_ERROR_MESSAGE = "set_error_message";
export const GET_ACCOUNT_TYPE_FOR_PROGRAM = "get_account_type_for_program";
export const PATH_MANAGER_BOT = "botTelegramManager";
export const CREATE_BOT = "CREATE_BOT";
export const UPDATE_BOT = "UPDATE_BOT";
export const GET_LIST_MANAGER_BOT = "GET_LIST_MANAGER_BOT"
export const GET_LIST_ACCOUNT_TYPE = "botTelegramManager/take-account-type"
export const UPDATE_STATUS_BOT = "botTelegramManager/update-status"
export const GET_LIST_BOT_BY_USER = "list-user-by-bot"
export const UPDATE_STATUS_USER_BY_BOT = "botTelegramUser/update-status"

export const SALE_LEAD = 1;
export const SALE_MEMBER = 3;
export const GDKV = 5;
export const GDVP = 6;
export const UNG_VIEN = 29;

const state = {
    errors: null,
    roles: [],
    accountTypes: [],
    accountType: '',
};

const getters = {
    roles(state) {
        return state.accountTypes;
    },
    accountTypes(state) {
        return state.roles;
    },
    getAccountType(state) {
        return state.accountType;
    },
    errorsAccountType(state) {
        return state.errors;
    },
};

const actions = {
    [CREATE_ACCOUNT_TYPE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('account-type', payload).then((data) => {
                resolve({data});
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [UPDATE_ACCOUNT_TYPE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('account-type/' + payload.id + '?_method=PUT', payload.params).then((data) => {
                resolve({data});
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_ACCOUNT_TYPE_AVATAR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('update-accountType-avatar/' + payload.id, payload.params).then((data) => {
                resolve({data});
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_ACCOUNT_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.delete('account-type', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_ACCOUNT_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.query('account-type', payload).then(({data}) => {
                context.commit(SET_ACCOUNT_TYPE_INDEX, data.data.data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_ACCOUNT_TYPE_FOR_SELECT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_ACCOUNT_TYPE_FOR_SELECT, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_ACCOUNT_TYPE_BY_ID](context, slug) {
        return new Promise((resolve) => {
            ApiService.get("account-type", slug)
                .then(({data}) => {
                    context.commit(SET_ACCOUNT_TYPE_BY_ID, data)
                    resolve(data);
                })
                .catch((response) => {
                });
        });
    },
    [GET_ACCOUNT_TYPE_FOR_PROGRAM](context) {
        return new Promise((resolve) => {
            ApiService.query(GET_ACCOUNT_TYPE_FOR_PROGRAM)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                });
        });
    },
    [GET_CUSTOMER_ACCOUNT_TYPE](context, slug) {
        return new Promise((resolve) => {
            ApiService.query("customer-account-type", slug)
                .then(({data}) => {
                    context.commit(SET_ACCOUNT_TYPE_BY_ID, data.data)
                    resolve(data);
                })
                .catch(() => {
                });
        });
    },
    [GET_POSITIONS](context, slug) {
        return new Promise((resolve) => {
            ApiService.query("users/positions", slug)
                .then(({data}) => {
                    // context.commit('', data)
                    resolve(data);
                })
                .catch((response) => {
                    context.commit('', response.error);
                });
        });
    },
    // Get roles
    [GET_ROLES](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/role", credentials)
                .then(({data}) => {
                    context.commit(SET_ROLES, data.data);
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response);
                });
        });
    },
    [GET_ROLE_BACKEND](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query(GET_ROLE_BACKEND, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response);
                });
        });
    },

    [GET_LIST_MANAGER_BOT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH_MANAGER_BOT, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                });
        });
    },

    [GET_LIST_BOT_BY_USER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(GET_LIST_BOT_BY_USER, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                });
        });
    },

    [GET_LIST_ACCOUNT_TYPE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(GET_LIST_ACCOUNT_TYPE, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                });
        });
    },

    [CREATE_BOT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(PATH_MANAGER_BOT, payload).then((data) => {
                resolve({data});
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    [UPDATE_BOT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(PATH_MANAGER_BOT, payload.id, payload.params).then((data) => {
                resolve({data});
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [UPDATE_STATUS_BOT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_STATUS_BOT, payload).then((data) => {
                resolve({data});
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [UPDATE_STATUS_USER_BY_BOT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_STATUS_USER_BY_BOT, payload).then((data) => {
                resolve({data});
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
};

const mutations = {
    [SET_ROLES](state, roles) {
        state.roles = roles;
    },
    [SET_ACCOUNT_TYPE_INDEX](state, accountTypes) {
        state.accountTypes = accountTypes;
    },
    [SET_ERROR_MESSAGE](state, error) {
        state.errors = error;
    },
    [SET_ACCOUNT_TYPE_BY_ID](state, data) {
        state.accountType = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
