<template>
  <div>
    <button
        class="btn btn-success font-weight-bold btn-sm"
        @click="create"
        v-b-modal="`task-create-modal`"
    >
      Thêm mới
    </button>
    <b-modal
        centered
        id="task-create-modal"
        size="lg"
        title="Thêm mới tác vụ"
        :ok-disabled="okDisable"
        @hidden="resetModal"
        hide-footer
        ref="task-create-modal"
    >
      <ValidationObserver v-show="!isShowLoading" v-slot="{ handleSubmit }" ref="form">
        <form class="form" autocomplete="off">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Loại tác vụ <span class="text-danger">*</span></label>
                  <ValidationProvider name="Loại tác vụ" rules="required|max:30" v-slot="{ errors,classes }"
                                      vid="type_task">
                    <el-select v-model="schedule.type_task"
                               placeholder="Loại tác vụ"
                               clearable
                               @change="handleTypeTask()"
                    >
                      <el-option
                          v-for="item in type_task"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Đối tượng xử lý<span class="text-danger">*</span></label>
                  <ValidationProvider name="Đối tượng xử lý" rules="required|max:30" v-slot="{ errors,classes }"
                                      vid="processing_object">
                    <el-input placeholder="Nhập đối tượng xử lý" v-model="schedule.processing_object"></el-input>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Thời gian bắt đầu<span class="text-danger">*</span></label>
                  <ValidationProvider name="Thời gian bắt đầu" rules="required" v-slot="{ errors,classes }"
                                      vid="schedule">
                    <date-picker
                        :disabled="!schedule.type_task"
                        @change="onStartDateChanged"
                        v-model="schedule.schedule"
                        type="datetime"
                        :disabled-date="disabledBeforeStartDate"
                        placeholder="Thời gian bắt đầu"
                        :time-picker-options="time_picker_options"
                        :show-time-panel="showTimePanel"
                        :disabled-time="disableOptionTimeStart"
                    >
                      <!--                      :open="showTimePicker"-->
                    </date-picker>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Thời gian kết thúc<span class="text-danger">*</span></label>
                  <ValidationProvider name="Thời gian kết thúc" rules="required" v-slot="{ errors,classes }"
                                      vid="end_schedule">
                    <date-picker
                        :disabled="isDisableTimeEnd"
                        @change="onEndDateChanged"
                        v-model="schedule.end_schedule"
                        :disabled-date="disabledBeforeEndDate"
                        type="datetime"
                        placeholder="Thời gian kết thúc"
                        :time-picker-options="time_picker_options_end"
                        :disabled-time="disableOptionTimeEnd"
                    >
                    </date-picker>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Người phụ trách<span class="text-danger">*</span></label>
                  <ValidationProvider name="Chọn người phụ trách" rules="required" v-slot="{ errors,classes }"
                                      vid="tester_id">
                    <el-select
                        v-model="schedule.tester_id"
                        filterable
                        placeholder="Người phụ trách"
                        clearable
                        :disabled="isDisableTester"
                    >
                      <el-option
                          v-for="item in testers"
                          :key="item.id"
                          :label="`${item.name} - ${item.phone}`"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-secondary mr-2" @click="closeModal">Hủy bỏ</button>
              <button type="button" class="btn btn-primary font-weight-bold btn-sm" @click="handleSubmit(handleOk)">
                <i class="el-icon-loading" v-if="okDisable"></i>Xác nhận
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <modal-handle-loading
          v-show="isShowLoading"
          :status-prop="statusSpeaking"
          :id="idSpeaking"
          :prop-seconds="seconds"
          :isShowLoading="isShowLoading"
          @close-modal="closeModal"
          @loading-success="loadingSuccess"
      ></modal-handle-loading>
    </b-modal>
  </div>
</template>

<script>
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  LIST_TESTER_FREE_TIME,
  TEST_INPUT_STORE_TASK
} from "@/core/services/store/test/testInput.module";
import {
  NOT_HAVE_ADMIN,
  TAC_VU_KHAC,
  TIME_PICKER_OPTIONS,
  TIME_PICKER_OPTIONS_END,
  TYPE_TASK_NOT_TEST_INPUT,
  TYPE_TASK_TEST_INPUT
} from "@/core/option/testInputOption";
import moment from "moment-timezone";
import ModalHandleLoading from "./ModalHandleLoading";
import {CHECK_MY_TASK_PENDING} from "../../../../core/services/store/test/testInput.module";


export default {
  name: "TaskCreateModal",
  components: {ModalHandleLoading, DatePicker},
  props: {},
  data() {
    return {
      //setup
      showTimePanel: false,
      showTimePicker: null,
      showTimeRangePanel: false,
      okDisable: false,
      search_loading: false,
      fileList: [],
      type_task: TYPE_TASK_NOT_TEST_INPUT,
      TAC_VU_KHAC: TAC_VU_KHAC,
      testers: [],
      schedule: {
        type_task: '',
        tester_id: '',
        schedule: '',
        end_schedule: '',
        processing_object: ''
      },
      time_picker_options: TIME_PICKER_OPTIONS,
      time_picker_options_end: TIME_PICKER_OPTIONS_END,
      pick: false,
      timeTasks: new Map([
        [TYPE_TASK_TEST_INPUT, 30]
      ]),

      //Loading
      isShowLoading: false,
      statusSpeaking: 0,
      idSpeaking: 0,
      seconds: 60,
      //End Loading
    }
  },
  mounted() {
    this.resetDataDefault();
  },
  methods: {
    checkMyTaskPending() {
			this.isShowLoading = false;
      this.$store.dispatch(CHECK_MY_TASK_PENDING).then((data) => {
        let item = data.data;
        if (item == null) {
          this.isShowLoading = false;
          return;
        }
        this.idSpeaking = item.id;
        this.isShowLoading = true;
        this.statusSpeaking = item.status;
        this.handleSeconds(item.created_at);
      })
    },
    handleSeconds(created_at) {
      let seconds = this.getSeconds(created_at);
      let minusSeconds = 60 - seconds;
      if (minusSeconds < 0) {
        this.seconds = 0;
      }
      if (minusSeconds > 60) {
        this.seconds = 60;
      }
    },
    getSeconds(created_at) {
      let create = new Date(created_at);
      let currentTime = new Date();
      return Math.floor((currentTime - create) / 1000);
    },
    create() {
      this.checkMyTaskPending();
    },
    resetModal() {
      this.schedule = {
        type_task: '',
        tester_id: '',
        schedule: '',
        end_schedule: '',
      }
    },
    onStartDateChanged(date, type) {
      this.handleDate(date, type, "dateStart");
    },
    onEndDateChanged(date, type) {
      this.handleDate(date, type, "dateEnd");
    },
    disableOptionTimeStart(time) {
      let currentTime = moment(time).format("HH:mm");
      // if (this.schedule.type_task == TYPE_TASK_TEST_INPUT || this.schedule.type_task === TAC_VU_KHAC) {
      //   return ("11:30" < currentTime && currentTime < "14:00") || ("17:30" <= currentTime && currentTime < "18:00")
      // }
      return ("11:30" < currentTime && currentTime <= "13:30")
          || ("17:00" < currentTime && currentTime < "18:00")
          || ("21:45" < currentTime);
    },
    disableOptionTimeEnd(time) {
      let currentTime = moment(time).format("HH:mm");
      // || ("12:00" < currentTime && currentTime < "14:00")
      return time <= this.schedule.schedule || ("12:00" < currentTime && currentTime < "14:00");
    },
    handleDate(date, type, typeTime) {
      if (type !== 'date') {
        this.pick = true;
        this.showTimePanel = null
        this.showTimePicker = null
        if (typeTime == "dateStart") {
          this.schedule.end_schedule = this.calculateEndTime(date)
        }

        return;
      }
      this.showTimePanel = this.pick || moment(date).isSame(moment(date).startOf('day')) //
      this.showTimePicker = this.showTimePanel ? true : null
      this.pick = false;
    },
    calculateEndTime(date) {
      if (this.schedule.schedule > 0 && this.timeTasks.has(this.schedule.type_task)) {
        let minutes = this.timeTasks.get(this.schedule.type_task);
        return new Date(date.getTime() + minutes * 60000)
      }
      return null;
    },
    handleOk() {
      this.store();
    },
    disabledBeforeStartDate(date) {
      const today = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
      return moment(date) < today;
    },
    disabledBeforeEndDate(date) {
      return moment(date).endOf('days') <= moment(this.schedule.schedule);
    },
    resetDataDefault() {
      this.schedule = {
        type_task: '',
        tester_id: '',
        schedule: '',
        end_schedule: ''
      }
    },
    afterCreateSuccess(speaking) {
      if (!speaking) {
        return;
      }
      this.isShowLoading = true;
      this.statusSpeaking = speaking.status;
      this.idSpeaking = speaking.id;
      this.seconds = 60;
    },
    loadingSuccess() {
      this.$emit('create-success');
    },
    store() {
      if (this.okDisable) {
        return;
      }
      this.okDisable = true;
      this.$store.dispatch(TEST_INPUT_STORE_TASK, this.schedule).then((data) => {
        this.$emit('create-success');
        this.resetDataDefault();
        this.afterCreateSuccess(data.data);
      }).catch((e) => {
        if (+e.response.data.error_code === NOT_HAVE_ADMIN) {
          this.$message.error(`${e.response.data.message}`);
          this.schedule.tester_id = null;
          this.getTestersFrees()
          return
        }
        if (e.response.data.message) {
          this.$refs.form.setErrors(e.response.data.message);
        }else {
          this.$refs.form.setErrors(e.response.message);
        }
      }).finally(() => {
        setTimeout(() => {
          this.okDisable = false;
        }, 1000);
      });
    },
    // Set phòng ban xử lý
    handleTypeTask() {
      this.schedule.schedule = '';
      this.schedule.end_schedule = '';
    },
    closeModal() {
      this.isShowLoading = false;
      this.idSpeaking = 0;
      this.statusSpeaking = 0;
      this.$bvModal.hide('task-create-modal');
    },
    getTestersFrees() {
			if (!this.schedule.schedule || !this.schedule.end_schedule) {
				return;
			}
      let payload = {
        start_date: moment(this.schedule.schedule).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(this.schedule.end_schedule).format("YYYY-MM-DD HH:mm:ss"),
        type_task: this.schedule.type_task
      }
      this.search_loading = true;
      this.$store.dispatch(LIST_TESTER_FREE_TIME, payload).then((data) => {
        this.search_loading = false;
        this.testers = data.data;
      })
    },
  },
  computed: {
    isDisableTimeEnd() {
      return this.schedule.schedule == '' || [TYPE_TASK_TEST_INPUT].includes(this.schedule.type_task)
    },
    isDisableTester() {
      this.getTestersFrees()
      this.schedule.tester_id = null
      return (this.schedule.end_schedule == '' || this.schedule.end_schedule == null)
    }
  }
}
</script>

<style scoped>
.el-select {
  width: 100%;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}
</style>