<template>
  <div>
      <div class="card card-custom gutter-b">
          <div class="card-header d-flex justify-content-between">
              <div class="card-title">
                  <h3 class="card-label">Danh sách quyền</h3>
              </div>
              <div class="card-title">
                  <router-link
                          class="font-weight-bold font-size-3  btn btn-success"
                          :to="{ name: 'permission-create' }"
                  >Thêm mới
                  </router-link>
              </div>
          </div>
          <div class="card-body">
              <div class="form-group mb-8">
                  <div class="alert alert-custom alert-default" role="alert">
                      <div class="alert-icon">
																<span class="svg-icon svg-icon-primary svg-icon-xl">
																	<svg xmlns="http://www.w3.org/2000/svg"
                                                                         width="24px" height="24px" viewBox="0 0 24 24"
                                                                         version="1.1">
																		<g stroke="none" stroke-width="1" fill="none"
                                                                           fill-rule="evenodd">
																			<rect x="0" y="0" width="24"
                                                                                  height="24"></rect>
																			<path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z"
                                                                                  fill="#000000" opacity="0.3"></path>
																			<path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z"
                                                                                  fill="#000000"
                                                                                  fill-rule="nonzero"></path>
																		</g>
																	</svg>
                                                                    <!--end::Svg Icon-->
																</span>
                      </div>
                      <div class="alert-text">
                          <span>Chú thích:</span>
                          <span class="label label-inline label-light-primary font-weight-bold ml-1">Backend</span>
                          <span class="label label-inline label-light-success font-weight-bold ml-1">Nội bộ</span>
                          <span class="label label-inline label-light-danger font-weight-bold ml-1">Center</span>
                      </div>
                  </div>
              </div>
              <!--begin::Example-->
              <div class="example mb-10">
                  <div class="form-group">
                      <div class="input-group">
                          <div class="input-group-prepend">
																<span class="input-group-text line-height-0 py-0">
																	<span class="svg-icon">
																		<!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Search.svg-->
																		<svg xmlns="http://www.w3.org/2000/svg"
                                                                             xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                             width="24px" height="24px"
                                                                             viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" stroke-width="1"
                                                                               fill="none" fill-rule="evenodd">
																				<rect x="0" y="0" width="24"
                                                                                      height="24"></rect>
																				<path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                                                      fill="#000000" fill-rule="nonzero"
                                                                                      opacity="0.3"></path>
																				<path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                                                      fill="#000000"
                                                                                      fill-rule="nonzero"></path>
																			</g>
																		</svg>
                                                                        <!--end::Svg Icon-->
																	</span>
																</span>
                          </div>
                          <input type="text" class="form-control" placeholder="Tìm kiếm" v-on:input="searchPermissions"
                                 v-model="query.search"
                                 aria-describedby="basic-addon2">
                      </div>
                  </div>
                  <div class="example-preview table-responsive">
                      <table class="table table-hover text-center table-bordered table-vertical-center">
                          <thead>
                          <tr>
                              <th scope="col">STT</th>
                              <th scope="col">Quyền</th>
                              <th scope="col">Quyền cha</th>
                              <th scope="col">Trang</th>
                              <th scope="col">Hành động</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item, index) in getAllPermissions" :key="index">
                              <th scope="row">{{++index}}</th>
                              <td>{{item.name}}</td>
                              <td>{{item.parentt ? item.parentt.name: ''}}</td>
                              <td>
                              <span class="label label-inline label-light-primary font-weight-bold"
                                    v-if="item.guard_name === 'backend'">{{item.guard_name}}</span>
                                  <span class="label label-inline label-light-success font-weight-bold"
                                        v-if="item.guard_name === 'noibo'">{{item.guard_name}}</span>
                                  <span class="label label-inline label-light-danger font-weight-bold"
                                        v-if="item.guard_name === 'educenter'">{{item.guard_name}}</span>
                              <td class="d-flex justify-content-around">
                                  <router-link :to="{ name: 'permission-update', params: {id : item.id} }" title="Sửa"
                                               class="btn btn-sm btn-icon mr-2 btn-outline-info"><i class="fas fa-pen-nib"></i>
                                  </router-link>
                                  <a title="Xóa" @click="deletePermission(item.id)" href="javascript:"
                                     class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
          <div class="edu-paginate mx-auto text-center">
              <paginate
                      v-model="query.page"
                      :page-count="total_pages"
                      :page-range="3"
                      :margin-pages="2"
                      :click-handler="clickCallback"
                      :prev-text="'Trước'"
                      :next-text="'Sau'"
                      :container-class="'pagination b-pagination'"
                      :pageLinkClass="'page-link'"
                      :page-class="'page-item'"
                      :next-link-class="'next-link-item'"
                      :prev-link-class="'prev-link-item'"
                      :prev-class="'page-link'"
                      :next-class="'page-link'"
              >
              </paginate>
          </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {GET_PERMISSION,DELETE_PERMISSION,} from "../../../../core/services/store/user/permission.module";
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";
const _ = require('lodash');

export default {
  name: "List-permission",
  data() {
    return {
        query : {
            search: "",
            page: 1,
        },
    };
  },
  computed: {
    ...mapGetters(["getAllPermissions","getPermissionPaginate"]),
      total_pages() {
        return this.getPermissionPaginate?.total_pages || 0
      },
  },

    mounted() {
        this.query.search = this.$route.query.name ? this.$route.query.name :'';
        this.query.page = parseInt(this.$route.query.page ? this.$route.query.page : 1);
        this.$store.dispatch(GET_PERMISSION, {
            name : this.$route.query.name,
            page: this.query.page,
            show_progress: 1
        });
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Tài khoản", route: 'account'},
            { title: "Danh sách quyền" }
        ]);
    },
  methods: {
    deletePermission(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_PERMISSION, id).then(() => {
          });
        }
      });
    },
      searchPermissions: _.debounce(function() {
              window.history.replaceState(null,'/permission','?page='+this.query.page+'&name='+ this.query.search);
              this.$store.dispatch(GET_PERMISSION,{
                  name : this.query.search,
              });
          }, 300),
      clickCallback(pageNum) {
          window.history.replaceState(null,'/','?page='+pageNum+'&name='+ this.query.search);
          this.$store.dispatch(GET_PERMISSION,{
              page : pageNum,
              name : this.query.search
          });
      }
  },
};
</script>
<style>

</style>
