import ApiService from "../../api.service";

export const GET_INSTALLMENT_METHODS = 'installment/methods';
export const GET_TUITION_PAYMENT = 'installment/tuitionPayments';
export const GET_INSTALLMENT_INFORMATION = 'installment/information';
export const GET_LIST_CREATE_USERS = 'installment/list-create-users';
export const SHOW_INSTALLMENT_INFORMATION = 'installment/show-detail';
export const CREATE_INSTALLMENT_INFORMATION_LOG = 'installment/create-log-installment';

const state = {};
const getters = {};
const actions = {
    /**
     * danh sách hình thức thanh toán
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_INSTALLMENT_METHODS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_INSTALLMENT_METHODS}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    /**
     * danh sách tình trạng trả góp
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_TUITION_PAYMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_TUITION_PAYMENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    /**
     * danh sách trả góp
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_INSTALLMENT_INFORMATION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_INSTALLMENT_INFORMATION}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    /**
     * danh sách người tạo
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_LIST_CREATE_USERS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LIST_CREATE_USERS}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    /**
     * chi tiết trả góp
     * @param context
     * @param id
     * @returns {Promise<unknown>}
     */
    [SHOW_INSTALLMENT_INFORMATION](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${GET_INSTALLMENT_INFORMATION}`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    /**
     * chi tiết trả góp
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [CREATE_INSTALLMENT_INFORMATION_LOG](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${GET_INSTALLMENT_INFORMATION}/${payload.id}/update-method-payment/${payload.contract_id}`, payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
};
const mutations = {};
export default {
    state, actions, mutations, getters
};