<template>
    <div class="row employee-container">
        <div class="col-md-5">
            <div class="card card-custom gutter-b">
                <div class="card-header d-flex justify-content-between">
                    <div class="card-title">
                        <h3 class="card-label">Bảng báo cáo số lượng nhân sự hiện tại theo vị trí</h3>
                    </div>
                </div>
                <div class="card-body">
                    <div class="example mb-10">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <el-select @change="changeType()" v-model="type" clearable
                                               placeholder="Tổng công ty">
                                        <el-option label="Tổng công ty" value=""></el-option>
                                        <el-option label="Các khu vực" value="1"></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div v-if="this.type" class="col-md-4">
                                <div class="form-group">
                                    <el-select @change="changeArea()" v-model="area" clearable placeholder="Chọn khu vực">
                                        <el-option
                                             v-for="(item, index) in areas"
                                             :key="index"
                                             :label="item.name"
                                             :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div v-if="this.type" class="col-md-4">
                                <div class="form-group">
                                    <el-select v-model="branch" clearable placeholder="Chọn văn phòng">
                                        <el-option
                                             v-for="(item, index) in branches"
                                             :key="index"
                                             :label="item.name_alias ? item.name_alias : item.name"
                                             :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <table class="table table-bordered dataTable">
                                    <thead>
                                    <tr role="row">
                                        <th>Mảng</th>
                                        <th>Vị trí</th>
                                        <th class="text-center">Số lượng</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="el-table__row">
                                        <td rowspan="38">Đội kinh doanh</td>
                                        <td colspan="2" class="bold text-center" style="background: #d0d0d0">Sales Intern</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Intern</td>
                                      <td class="text-center bold">{{ account_type[SALE_INTERN] ? account_type[SALE_INTERN] : 0}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="bold text-center" style="background: #d0d0d0">Sales</td>
                                    </tr>

                                    <tr>
                                      <td class="text-center">Junior</td>
                                      <td class="text-center bold">{{ account_type[SALE_MEMBER] && account_type[SALE_MEMBER]['Junior'] ? account_type[SALE_MEMBER]['Junior'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Rookie</td>
                                      <td class="text-center bold">{{ account_type[SALE_MEMBER] && account_type[SALE_MEMBER]['Rookie'] ? account_type[SALE_MEMBER]['Rookie'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Executive</td>
                                      <td class="text-center bold">{{ account_type[SALE_MEMBER] && account_type[SALE_MEMBER]['Executive'] ? account_type[SALE_MEMBER]['Executive'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Pro</td>
                                      <td class="text-center bold">{{ account_type[SALE_MEMBER] && account_type[SALE_MEMBER]['Pro'] ? account_type[SALE_MEMBER]['Pro'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Master</td>
                                      <td class="text-center bold">{{ account_type[SALE_MEMBER] && account_type[SALE_MEMBER]['Master'] ? account_type[SALE_MEMBER]['Master'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Tất cả cấp bậc</td>
                                      <td class="text-center bold">  {{  total_level[SALE_MEMBER] ?? 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="bold text-center" style="background: #d0d0d0">Sales Leader</td>
<!--                                        <td class="text-center bold">{{ account_type['sale-leader'] || 0 }}</td>-->
                                    </tr>
                                    <tr>
                                      <td class="text-center">Junior</td>
                                      <td class="text-center bold">{{ account_type[SALE_LEADER] && account_type[SALE_LEADER]['Junior'] ? account_type[SALE_LEADER]['Junior'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Rookie</td>
                                      <td class="text-center bold">{{ account_type[SALE_LEADER] && account_type[SALE_LEADER]['Rookie'] ? account_type[SALE_LEADER]['Rookie'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Executive</td>
                                      <td class="text-center bold">{{ account_type[SALE_LEADER] && account_type[SALE_LEADER]['Executive'] ? account_type[SALE_LEADER]['Executive'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Pro</td>
                                      <td class="text-center bold">{{ account_type[SALE_LEADER] && account_type[SALE_LEADER]['Pro'] ? account_type[SALE_LEADER]['Pro'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Master</td>
                                      <td class="text-center bold">{{ account_type[SALE_LEADER] && account_type[SALE_LEADER]['Master'] ? account_type[SALE_LEADER]['Master'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Tất cả cấp bậc</td>
                                      <td class="text-center bold">{{  total_level[SALE_LEADER] ?? 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="bold text-center" style="background: #d0d0d0">Giám đốc văn phòng</td>
<!--                                        <td class="text-center bold">{{ account_type['gdvp'] || 0 }}</td>-->
                                    </tr>
                                    <tr>
                                      <td class="text-center">Junior</td>
                                      <td class="text-center bold">{{ account_type[GDVP] && account_type[GDVP]['Junior'] ? account_type[GDVP]['Junior'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Rookie</td>
                                      <td class="text-center bold">{{ account_type[GDVP] && account_type[GDVP]['Rookie'] ? account_type[GDVP]['Rookie'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Executive</td>
                                      <td class="text-center bold">{{ account_type[GDVP] && account_type[GDVP]['Executive'] ? account_type[GDVP]['Executive'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Pro</td>
                                      <td class="text-center bold">{{ account_type[GDVP] && account_type[GDVP]['Pro'] ? account_type[GDVP]['Pro'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Master</td>
                                      <td class="text-center bold">{{ account_type[GDVP] && account_type[GDVP]['Master'] ? account_type[GDVP]['Master'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Tất cả cấp bậc</td>
                                      <td class="text-center bold">{{  total_level[GDVP] ?? 0 }}</td>
                                    </tr>
                                    <tr>
                                      <td colspan="2" class="bold text-center" style="background: #d0d0d0">Giám đốc khu vực</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Junior</td>
                                      <td class="text-center bold">{{ account_type[GDKV] && account_type[GDKV]['Junior'] ? account_type[GDKV]['Junior'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Rookie</td>
                                      <td class="text-center bold">{{ account_type[GDKV] && account_type[GDKV]['Rookie'] ? account_type[GDKV]['Rookie'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Executive</td>
                                      <td class="text-center bold">{{ account_type[GDKV] && account_type[GDKV]['Executive'] ? account_type[GDKV]['Executive'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Pro</td>
                                      <td class="text-center bold">{{ account_type[GDKV] && account_type[GDKV]['Pro'] ? account_type[GDKV]['Pro'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Master</td>
                                      <td class="text-center bold">{{ account_type[GDKV] && account_type[GDKV]['Master'] ? account_type[GDKV]['Master'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Tất cả cấp bậc</td>
                                      <td class="text-center bold">{{  total_level[GDKV] ?? 0 }}</td>
                                    </tr>

                                    <tr>
                                      <td colspan="2" class="bold text-center" style="background: #d0d0d0">Giám đốc vùng</td>
                                      <!--                                      <td class="text-center bold">{{ account_type['gdvp'] || 0 }}</td>-->
                                    </tr>
                                    <tr>
                                      <td class="text-center">Junior</td>
                                      <td class="text-center bold">{{ account_type[GDV] && account_type[GDV]['Junior'] ? account_type[GDV]['Junior'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Rookie</td>
                                      <td class="text-center bold">{{ account_type[GDV] && account_type[GDV]['Rookie'] ? account_type[GDV]['Rookie'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Executive</td>
                                      <td class="text-center bold">{{ account_type[GDV] && account_type[GDV]['Executive'] ? account_type[GDV]['Executive'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Pro</td>
                                      <td class="text-center bold">{{ account_type[GDV] && account_type[GDV]['Pro'] ? account_type[GDV]['Pro'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Master</td>
                                      <td class="text-center bold">{{ account_type[GDV] && account_type[GDV]['Master'] ? account_type[GDV]['Master'] : 0}}</td>
                                    </tr>
                                    <tr>
                                      <td class="text-center">Tất cả cấp bậc</td>
                                      <td class="text-center bold">{{  total_level[GDV] ?? 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td style="background: #efb252; color: black"
                                            class="text-center bold">
                                            Tổng
                                        </td>
                                        <td style="background: #efb252; color: black" class="text-center bold">{{
                                            total_in_year[0]
                                            }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="card card-custom gutter-b">
                <div class="card-header d-flex justify-content-between">
                    <div class="card-title">
                        <h3 class="card-label">Biểu đồ báo cáo tình hình biến động nhân sự văn phòng năm:
                            {{ this.year instanceof Date ? this.year.getFullYear() : this.year }}</h3>
                    </div>
                </div>
                <div class="card-body">
                    <div class="example mb-10">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <el-date-picker v-model="year" type="year" format="yyyy" value-format="yyyy"
                                                    placeholder="Chọn năm">
                                    </el-date-picker>
                                </div>
                                <!--end::Form-->
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <el-button :loading="loading" @click="querySever()" class="btn btn-success" style="height: 40px;margin-left: 15px"><i class="fa fa-search"></i> Search</el-button>
                                </div>
                                <!--end::Form-->
                            </div>
                            <div class="col-md-12">
                                <zingchart ref="chart" :data="this.chartData" :theme="zingChartTheme"></zingchart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {zingChartTheme} from "@/core/config/zingChartTheme";
import {GET_LIST_AREAS} from "../../../core/services/store/center/area.module";
import {GET_LIST_BRANCHES} from "../../../core/services/store/center/branch.module";
import {GET_EMPLOYEE} from "../../../core/services/store/report/report.module";
import {mapGetters} from "vuex";
import {
  SALE_LEADER,
  SALE_EXPERT,
  SALE_MEMBER,
  SALE_INTERN,
  GDVP,
  QLHV,
  HANH_CHINH_KHU_VUC,
  GDKV, GDV
} from "@/core/option/accountTypeOption";
export default {
    name: "Employee",
    data() {
        return {
            zingChartTheme: zingChartTheme,
            year: new Date(),
            type: "",
            pluralities: {},
            areas: [],
            branches: [],
            area: "",
            branch: "",
            account_type: {
                'sale-intern': 0,
                'sale-member': 0,
                'sale-expert': 0,
                'sale-leader': 0,
                'gdvp': 0
            },
            total: 0,
            start_in_year: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            off_in_year: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            total_in_year: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            percent_in_year: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            loading: false,
            SALE_LEADER,
            SALE_EXPERT,
            SALE_MEMBER,
            SALE_INTERN,
            GDVP,
            QLHV,
            HANH_CHINH_KHU_VUC,
            GDKV,
            GDV,
            total_level: 0
        }
    },
    async mounted() {
        await this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý nhân sự"},
            {title: "Khối cơ sở"},
            {title: "Báo cáo"},
            {title: "Biến động nhân sự"}
        ]);
        this.getAreas();
        this.getBranches();
        this.querySever();
    },
    methods: {
        getAreas() {
            this.$store.dispatch(GET_LIST_AREAS, {}).then(data => {
                this.areas = data.data;
            });
        },
        getBranches() {
            this.branch = "";
            let payload = { area_id: this.area }
            this.$store.dispatch(GET_LIST_BRANCHES, payload).then(data => {
                this.branches = data.data;
            });
        },
        changeType() {
            if (!this.type) {
                this.area = "";
                this.branch = "";
            }
        },
        changeArea() {
            this.getBranches();
        },
        querySever() {
            this.loading = true;
            let payload = {
                area_id: this.area,
                branch_id: this.branch,
                year: this.year instanceof Date ? this.year.getFullYear() : this.year,
            }
            this.$store.dispatch(GET_EMPLOYEE, payload).then(data => {
                this.loading = false;
                this.account_type = data.data.account_type;
                this.pluralities = data.data.plurality;
                this.start_in_year = data.data.start_work;
                this.off_in_year = data.data.layoff;
                // this.total_in_year = data.data.total_user_each_month;
                this.data_percent = data.data.data_percent;
                this.total_level = data.data.total_Level;
                this.total_in_year = [(this.account_type[SALE_INTERN] ?? 0) + (this.total_level[SALE_MEMBER] ?? 0) + (this.total_level[SALE_LEADER] ?? 0) + (this.total_level[GDVP] ?? 0) + (this.total_level[GDKV] ?? 0) + (this.total_level[GDV] ?? 0)];
            })
        },
    },
    computed: {
        ...mapGetters(["currentUser"]),
        chartData() {
            return {
                type: 'mixed',
                title: {
                    fontSize: 20,
                    adjustLayout: true,
                    flat: true
                },
                gui: {
                    contextMenu: {
                        empty: true
                    }
                },
                globals: {
                    fontFamily: "SVN-Gilroy"
                },
                plot: {
                    tooltip: {
                        "text": "%t: <b>%v</b> %data-percent",
                        "padding": "10%",
                        "border-radius": "5px",
                        "sticky": true,
                        "timeout": 5000
                    },
                    animation: {
                        effect: "ANIMATION_FADE_IN",
                        method: "ANIMATION_LINEAR",
                        sequence: "ANIMATION_BY_PLOT_AND_NODE",
                        speed: 275
                    }
                },
                legend: {
                    "toggle-action": "hide",
                    "header": {
                        "text": "Ghi chú"
                    },
                    "item": {
                        "cursor": "pointer"
                    },
                    "draggable": true,
                    x: '8%',
                    y: 0,
                },
                scalableYaxis: { // need this object
                    scalable: true,
                    chartid: 'myChart', // could get chartId in a more dynamic way if needed
                },
                scaleX: {
                    zooming: false,
                    label: {
                        text: 'Tháng ' + ((new Date()).getMonth() + 1),
                        fontFamily: 'SVN-Gilroy',
                        fontSize: 16,
                    },
                    maxItems: 12,
                    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

                },
                scaleY: {
                    zooming: false,
                    maxItems: 10,
                    format: "%v",
                    'thousands-separator': ".",
                    label: {
                        text: 'Số nhân sự',
                        fontFamily: 'SVN-Gilroy',
                        fontSize: 16,
                    },
                    lineColor: '#e53935' // make scale line color match (not necessary)
                },
                scaleY2: {
                    label: {
                        text: 'Tỷ lệ nghỉ việc',
                        fontFamily: 'SVN-Gilroy',
                        fontSize: 16,
                    },
                    maxItems: 10
                },
                plotarea: {
                    margin: 'dynamic'
                },
                series: [
                    {
                        type: "bar",
                        values: this.start_in_year,
                        "background-color": "#584475",
                        'thousands-separator': ".",
                        text: "Tuyển thêm",
                        'data-percent': 'nhân sự'
                    },
                    {
                        type: "bar",
                        values: this.off_in_year,
                        "background-color": "#9d9d9d",
                        'thousands-separator': ".",
                        text: "Nghỉ việc",
                        'data-percent': 'nhân sự'
                    },
                    {
                        type: "bar",
                        values: this.total_in_year,
                        "background-color": "#f39c12",
                        'thousands-separator': ".",
                        text: "Tổng",
                        'data-percent': 'nhân sự'
                    },
                    {
                        type: "line",
                        values: this.data_percent,
                        lineColor: '#e53935',
                        marker: {
                            backgroundColor: '#e53935'
                        },
                        scales: 'scale-x, scale-y-2',
                        text: 'Tỷ lệ nghỉ việc',
                        'data-percent': '%'
                    },
                ]
            }
        }
    }
}
</script>

<style scoped>
.employee-container {

}

.employee-container .table-bordered th, .table-bordered td {
    border: 1px dotted #ccc;
}
</style>
