<template>
  <div>
    <TableErrorBondSold v-if="tableError" :responseError="listErrors"/>
    <div v-if="!tableError" style="display: flex; flex-direction: column; gap: 20px">
      <div style="display: flex; flex-direction: column">
        <label>Bước 1: Tải xuống file mẫu</label>
        <button class="btn btn-primary" style="width: 130px" @click="downloadFileExcelExample">
          <i class="el-icon-bottom" style="color: white"></i>
          Tải xuống
        </button>
      </div>
      <div style="display: flex; flex-direction: column">
        <label>Bước 2: Tải file excel lên hệ thống</label>
        <div v-if="fileImport" style="display: flex; align-items: center; gap: 8px">
          <span>{{fileImport.name}}</span>
          <el-button type="text" @click="fileImport = null" class="btn-remove">
            <i class="el-icon-delete" style="color: red !important;"></i>
          </el-button>
        </div>
        <label v-else class="btn btn-warning" for="upload-file" style="width: 130px">
          <input type="file" style="display: none" id="upload-file" @change="handleFileUpload" accept=".xlsx, .xls" />
          <i class="el-icon-top" style="color: white"></i>
          Tải lên
        </label>
      </div>
    </div>
    <div style="display: flex; justify-content: end; align-items: center; gap: 20px">
      <button class="btn" style="background: #CACACA" @click="closeDialog">Hủy bỏ</button>
      <el-button v-if="!tableError" :disabled="disableImport" v-loading="disableImport" class="btn btn-primary text-white" style="background: #5D04B0" @click="handleImportFileExcel">Xác nhận</el-button>
      <button
          class="btn btn-primary text-white"
          v-if="tableError"
          style="background: #5D04B0"
          @click="backToImport"
      >Quay lại</button>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import TableErrorBondSold from "@/view/pages/baseoffice/bonds-management/component/TableErrorBondSold.vue";
import {STAFF_BOND_IMPORT_SOLD, STAFF_PREVIEW_BOND_SOLD} from "@/core/services/store/baseoffice/bondManagement";
import {BondModel} from "@/view/pages/baseoffice/bonds-management/model/bondModel";

export default {
  name: "DialogImportBondSold",
  components: {TableErrorBondSold},
  data() {
    return {
      fileImport: null,
      dataImport: [],
      tableError: false,
      listErrors: [],
      disableImport: false,
    }
  },
  mounted() {},
  methods: {
    downloadFileExcelExample() {
      const filePath = process.env.BASE_URL + 'excel/Import bán trái phiếu.xlsx';
      const a = document.createElement('a');
      a.href = filePath;
      a.download = 'Import bán trái phiếu.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileImport = file
        this.readExcelFile(file);
      }
    },
    closeDialog() {
      this.$emit('closeDialog');
      this.$emit('setTitleError', 'Import bán trái phiếu')
      this.listErrors = []
      this.fileImport = null
      this.tableError = false
      this.disableImport = false
    },
    readExcelFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const columnMapping = {
          "Mã nhân sự": 'ma_nhan_su',
          "Mốc tích lũy": 'moc_tich_luy',
          "Số lượng chuyển nhượng/bán": 'so_luong_chuyen_nhuong_ban',
        }
        const headers = jsonData[0];
        const rows = jsonData.slice(1);
        const translatedHeaders = headers.map(header => columnMapping[header.trim()] || header.trim());
        this.dataImport = rows.filter(item => item.length > 0).map(row => {
          const obj = {};
          translatedHeaders.forEach((header, index) => {
            obj[header] = row[index] !== undefined && row[index] !== null
                ? row[index].toString().trim()
                : null;
          });
          return obj;
        })
      };
      reader.readAsArrayBuffer(file);
    },
    backToImport() {
      this.fileImport = null
      this.tableError = false
      this.disableImport = false
      this.listErrors = []
      this.$emit('setTitleError', 'Import bán trái phiếu')
    },
    async handleImportFileExcel() {
      this.disableImport = true
      if (!this.fileImport) {
        this.$notify({
          title: 'Thất bại',
          message: 'Chưa có file nào được chọn',
          type: 'error'
        });
        return
      }
      if (this.dataImport.length > 0) {
        await this.$store.dispatch(STAFF_PREVIEW_BOND_SOLD, this.dataImport).then(                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                res => {
          if (res?.data?.length > 0) {
            this.listErrors = res.data.map((item, index) => {
              if (item?.errors?.length > 0) {
                this.$emit('setTitleError', 'Báo lỗi')
                return {
                  userId: item?.ma_nhan_su,
                  milestone: item?.moc_tich_luy,
                  amountSold: item?.so_luong_chuyen_nhuong_ban,
                  errors: item?.errors
                }
              }
            })?.filter(item => item?.errors?.length > 0)
            if (this.listErrors.length > 0) {
              this.tableError = true
            }
          }
        })
        if (this.listErrors.length === 0) {
          this.$store.dispatch(STAFF_BOND_IMPORT_SOLD, this.dataImport).then(() => {
            this.$message.success('Import thành công')
            this.$emit('getListBondManagement')
            this.closeDialog();
          }).catch(e => {
            console.log(e, 'Import thất bại')
            this.$message.error('Import thất bại')
          })
        }
      } else {
        this.$message.warning('Bạn không thể import khi file excel không có bản ghi nào')
        this.disableImport = false
      }
    },
  }
}
</script>