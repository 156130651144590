<template>
  <b-modal @hide="onHide" size="xl" :centered="true" :scrollable="true" ref="modal-sale-detail" hide-footer title="Thông tin chi tiết nhân sự"  class="modal-sale-detail">
    <div class="d-block">
      <ValidationObserver ref="form">
        <form class="form">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Họ và tên: <span class="text-danger"></span></label>
                    <ValidationProvider name="Họ và tên" rules="required|min:6" v-slot="{ errors,classes }" vid="name">
                      <input type="text" name="name" disabled class="form-control" placeholder="Họ và tên"
                             :class="classes"
                             :value=userSale.name>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Mã nhân sự
                      <span class="text-danger"></span></label>
                    <input v-if="userType==='sale-log'" type="text" disabled="disabled" :value="userSale.user_id + 100000" placeholder="Mã nhân sự" class="form-control">
                    <input v-else type="text" disabled="disabled" :value="userSale.id+100000" placeholder="Mã nhân sự" class="form-control">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Email
                      <span class="text-danger"></span></label>
                    <ValidationProvider name="Email" vid="email" rules="required|email" v-slot="{ errors,classes }">
                      <input  type="email" name="email" disabled class="form-control" placeholder="Email"
                             :class="classes"
                             :value=userSale.email>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Số điện thoại:
                      <span class="text-danger"></span></label>
                      <input type="number" disabled="disabled" :value="userSale.phone" placeholder="Số điện thoại" class="form-control">
                  </div>
                </div>
              </div>
              <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Vị trí:
                  <span class="text-danger"></span></label>
                <input type="text" v-if="userType==='sale-log'" disabled="disabled" :value="userSale.account_type ? userSale.account_type.name : ''" placeholder="Vị trí" class="form-control">
                <input type="text" v-else disabled="disabled" :value="userSale.profile.account_type ? userSale.profile.account_type.name : ''" placeholder="Vị trí" class="form-control">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Chức vụ kiêm nhiệm:
                  <span class="text-danger"></span></label>
                <multiselect  v-if="userType==='sale-log'" :value="userSale.user && userSale.user.user_positions ?  userSale.user.user_positions : ''" :options="positions"
                             :close-on-select="true"
                             placeholder="Chức vụ kiêm nhiệm"
                             label="position"
                             track-by="position"
                             multiple
                             disabled
                ></multiselect>
                <multiselect  v-else :value="userSale.user_positions" :options="positions"
                             :close-on-select="true"
                             placeholder="Chức vụ kiêm nhiệm"
                             label="position"
                             track-by="position"
                             multiple
                             disabled
                ></multiselect>
              </div>
            </div>
          </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Văn phòng:
                      <span class="text-danger"></span></label>
                    <input v-if="userType==='sale-log'" type="text" disabled="disabled" :value="userSale.branch ? (userSale.branch.name_alias ? userSale.branch.name_alias : userSale.branch.name) : ''" placeholder="Văn phòng" class="form-control">
                    <input v-else type="text" disabled="disabled" :value="userSale.profile.branch ? (userSale.profile.branch.name_alias ? userSale.profile.branch.name_alias : userSale.profile.branch.name) : '' " placeholder="Văn phòng" class="form-control">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Khu vực:
                      <span class="text-danger"></span></label>
                    <input v-if="userType==='sale-log'" type="text" disabled="disabled" :value="userSale.branch && userSale.branch.area? userSale.branch.area.name : ''" placeholder="Khu vực" class="form-control">
                    <input v-else type="text" disabled="disabled" :value="userSale.profile.branch && userSale.profile.branch.area ? userSale.profile.branch.area.name : '' " placeholder="Khu vực" class="form-control">
                  </div>
                </div>
              </div>
        </form>
      </ValidationObserver>
    </div>
    <footer class="modal-footer">
      <button type="button" class="btn btn-secondary" @click="hideModal">Hủy bỏ</button>
    </footer>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
const _ = require('lodash');
export default {
  name: "FormDetail",
  props: {
    showModal: { default: false },
    dataUserSale : {
      type : Object,
      default()  {
        return {}
      }
    }
  },
  data() {
    return {
      userSale: {
        name: '',
        email: '',
        phone: '',
        address: '',
        center_id: '',
        branch_id: '',
        parent_id: '',
        user : {
          user_positions : [],
        },
        profile : {
          address : '',
          trung_tam_id : '',
          chi_nhanh_id : '',
          khu_vuc_id : '',
          account_type : {
            name : ''
          }
        },
        account_type : {
          name : ''
        },
        user_positions : [],
      },
      ma_cap_tren : '',
      userType : 'sale-log',
      positions: [],
    }
  },
  components : {
    Multiselect
  },
  methods: {
    onHide(evt) {
      this.$emit("clicked", true);
    },
    hideModal() {
      this.$refs['modal-sale-detail'].hide();
    },
    toggleModal() {
      // when the modal has hidden
      this.$refs['modal-sale-detail'].toggle('#toggle-btn');
    },
  },
  watch: {
    showModal() {
      if (this.showModal) {
        if (this.dataUserSale){
          this.userSale = this.dataUserSale;
          this.ma_cap_tren = this.userSale.parent_id ? this.userSale.parent_id + 100000 : '';
          this.$refs['modal-sale-detail'].show();
        }
      }
    },
    dataUserSale(){
      this.userSale = this.dataUserSale;
      if (!this.userSale.user_id) {
        this.userType = 'user';
      } else {
        this.userType = 'sale-log';
      }
    }
  }
}
</script>

<style>

</style>
