export const CONTRACT_TYPES = [
    {value : 2, title: 'Hợp đồng chuyển nhượng'},
    {value : 1, title: 'Hợp đồng thường'},
];
export const HOP_DONG_BINH_THUONG = 1;
export const CHUYEN_NHUONG = 2;
export const TICH_NO = 3;
export const PERCENT = 0;
export const MONEY = 1;
export const PERIODIC_PROMOTIONS = 1;
export const FLASH_SALE = 2;
export const SCHOOL_LINK = 3;
export const OTHER_SCHOOL = 4;
export const DIGITAL = 5;
