<template>
  <div class="d-block text-center">
    <form class="form">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Tên : <span class="text-danger">*</span></label>
            <input type="text" name="name" class="form-control" placeholder="Họ và tên học viên"
                   :value="promotion.name" disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Giá trị :
              <span class="text-danger">*</span></label>
            <input type="email" name="email" class="form-control" placeholder="Email"
                   :value="promotion.value" disabled>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Danh sách khóa học : <span class="text-danger">*</span></label>
            <input type="text" name="courses" class="form-control" placeholder="Họ và tên học viên"
                   :value="1" disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Chi nhánh áp dụng :
              <span class="text-danger">*</span></label>
            <input type="text" name="branch" class="form-control" placeholder="Email"
                   :value="promotion.branch && promotion.branch.name" disabled>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Ngày bắt đầu : <span class="text-danger">*</span></label>
            <input type="text" name="start_date" class="form-control" placeholder="Họ và tên học viên"
                   :value="promotion.start_date" disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Ngày kết thúc :
              <span class="text-danger"></span></label>
            <input type="text" name="end_date" class="form-control" placeholder="Email"
                   :value="promotion.end_date" disabled>
          </div>
        </div>
      </div>
		<div class="row" v-if="gift_select && gift_select.description">
			<div class="col-md-12">
				<label>Quà tặng:
					<span class="text-danger"></span></label>
				<el-input
					type="textarea"
					:autosize="{ minRows: 2, maxRows: 4}"
					placeholder="Please input"
					v-model="gift_select.description" disabled>
				</el-input>
			</div>
		</div>
    </form>
  </div>
</template>

<script>
export default {
  name: "PromotionDetail",
  props: {
    promotion: {
      type: [Array, Object],
      default: () => { return {}}
    },
	  gift_select: {
		  type: [Array, Object],
		  default: () => { return {}}
	  }
  }
}
</script>

<style scoped>

</style>
