<template>
    <div class="row">
        <div class="col-xl-12">
            <div id="panel-1" class="panel">
                <div class="card-header d-flex justify-content-between">
                    <div class="card-title">
                    </div>
                    <div class="card-title" v-if="classroom.center_id != 530">
                        <el-button class="btn btn-success" @click="dialogCreateTeam = true">
                            <i class="fa fa-plus"></i>Thêm mới team
                        </el-button>
                    </div>
                </div>
                <div class="panel-container show">
                    <div class="panel-content bg-white">

                        <el-row class="table-responsive-lg">
                            <el-empty v-if="isError" description="Không có dữ liệu"></el-empty>
                            <table class="table table-bordered m-0 responsive" v-else>
                                <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th class="text-center">Team</th>
                                    <th class="text-center">Tên học viên</th>
                                    <th class="text-center">Chức danh</th>
                                    <th class="text-center">Nhân vật</th>
                                    <th class="text-center">HP</th>
                                    <th class="text-center">Thao tác</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template  v-for="(clan, key) in clans">
                                    <row-group :key="key" :accept="true"  @addMember="openAddMember" :clan="clan" v-if="clan.approve_status === 1" @reloadPage="reloadPage"></row-group>
                                    <row-group v-else @denied="openDeniedTeam" @reloadPage="reloadPage()" :clan="clan" :key="key" :accept="false" ></row-group>
                                    <template v-for="(member, k) in clan.user_clan">
                                        <row-member :key="member.id + k"
                                                    :member="member"
                                                    :accept="true"
                                                    :clan="clan"
                                                    :indexMember="++k"
                                                    @punish="openPunish"
                                                    @gift="openGift"
                                                    @detail="openDetailMember"
                                                    @reloadPage="reloadPage()"
                                        >
                                        </row-member>
                                    </template>
                                </template>
                                <row-yet ></row-yet>
                                <row-member v-for="(member, index) in notInClans" :key="member.id + index" :accept="false" :member="member" :indexMember="++index"></row-member>
                                </tbody>
                            </table>
                        </el-row>
                        <div class="row mb-5"></div>
                    </div>
                </div>
            </div>
        </div>

        <!--  Chi tiết học viên -->
        <el-dialog :title="dialog.title" class="responsive w-auto" :visible.sync="dialogDetailMember">
            <detail-member @close="closeDialog()"
                           v-if="dialogDetailMember"
                           :idMember="dialog.id"
                           :course_student_id="dialog.course_student_id"
                           :clan="dialog.clan"
            ></detail-member>
        </el-dialog>

        <!--  Từ chối -->
        <el-dialog :title="dialog.title" class="responsive w-30" :visible.sync="dialogDenied">
            <denied @close="closeDialog()" @reloadPage="reloadPage()" v-if="dialogDenied" :idTeam="dialog.id"></denied>
        </el-dialog>

        <!-- Thêm thành viên  -->
        <el-dialog :title="dialog.title" class="responsive w-30" :visible.sync="dialogAddMember">
            <add-member @close="closeDialog()" @reloadPage="reloadPage()" v-if="dialogAddMember" :idTeam="dialog.id"></add-member>
        </el-dialog>

        <!-- Thưởng Thành viên  -->
        <el-dialog :title="dialog.title" class="responsive w-30" :visible.sync="dialogGiftSuccess">
            <gift @close="closeDialog()" :idMember="dialog.id" v-if="dialogGiftSuccess" :nameMember="dialog.name"></gift>
        </el-dialog>

        <!-- Phạt thành viên  -->
        <el-dialog :title="dialog.title" class="responsive w-30" :visible.sync="dialogPunish">
            <punish @close="closeDialog()" :idMember="dialog.id" v-if="dialogPunish" :nameMember="dialog.name"></punish>
        </el-dialog>

<!--        Thêm mới team-->
        <el-dialog title="Thêm mới team" :visible.sync="dialogCreateTeam" width="50">
            <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                <form class="form" id="branch_create" @submit.prevent="handleSubmit(onSubmit)">
                    <div>
                        <div>
                            <label for="">Tên team <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" v-model="team.name" placeholder="Nhập tên team">
                            <ValidationProvider vid="name" name="Tên team" rules="required" v-slot="{ errors,classes }">
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0]
                                        }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div>
                            <label for="">Leader <span class="text-danger">*</span></label>
                            <el-select id="gender" v-model="team.general_id" class="w-100"
                                       placeholder="Chọn Leader">
                                <el-option
                                    v-for="item in notInClans"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            <ValidationProvider vid="general_id" name="Leader" rules="required" v-slot="{ errors,classes }">
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0]
                                        }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div slot="footer" class="dialog-footer d-flex justify-content-end mt-4">
                        <el-button @click="dialogCreateTeam = false">Đóng</el-button>
                        <el-button type="submit" class="bg-violet text-white" @click="onSubmit">Thêm mới</el-button>
                    </div>
                </form>
            </ValidationObserver>
        </el-dialog>
    </div>
</template>

<script>
import AddMember from "./AddMember";
import Denied from "./Denied";
import DetailMember from "./DetailMember";
import Gift from "./Gift";
import Punish from "./Punish";
import RowGroup from "./RowGroup";
import RowMember from "./RowMember";
import RowYet from "./RowYet";
import {CREATE_TEAM, GET_ClASS_BY_ID, LIST_MEMBER_CLASS} from "../../../../core/services/store/course/classes.module";

import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

const _ = deepdash(lodash);

export default {
    name: "Team",
    components: {
        AddMember,
        Denied,
        DetailMember,
        Gift,
        Punish,
        RowGroup,
        RowMember,
        RowYet
    },
    data() {
        return {
            dialogDenied: false,
            dialogAddMember: false,
            dialogGiftSuccess: false,
            dialogPunish: false,
            dialogDetailMember: false,
            dialog: {
                title: null,
                name: null,
            },
            classroom_id : '',
            clans: [],
            notInClans: [],
            isError: false,
            dialogCreateTeam: false,
            team: {
                name: '',
                general_id: '',
                class_id: this.$route.params.id,
                course_id: ''
            },
          classroom: {}
        }
    },
    created() {
        this.classroom_id = this.$route.params.id;
        this.getClass();
        this.getData();
    },
    methods: {
        getData() {
            this.$store.dispatch(LIST_MEMBER_CLASS, {classroom_id : this.classroom_id}).then((res) => {
                this.clans = res.data.clan;
                this.notInClans = res.data.not_in_clan;
            }).catch(($ex) => {
                this.isError = true;
            })
        },
        closeDialog() {
            this.dialogDetailMember = false;
            this.dialogDenied = false;
            this.dialogAddMember = false;
            this.dialogGiftSuccess = false;
            this.dialogPunish = false;
        },

        openDetailMember({ id, course_student_id, clan }) {
            this.dialog.title = 'Chi tiết học viên';
            this.dialog.id = id;
            this.dialog.course_student_id = course_student_id;
            this.dialog.clan = clan;
            this.dialogDetailMember = true;
        },
        openDeniedTeam({ id }) {
            this.dialog.title = 'Lý do từ chối'
            this.dialog.id = id;
            this.dialogDenied = true;
        },

        openAddMember({id, name}) {
            this.dialog.title = `Thêm thành viên vào : ${name}`
            this.dialog.id = id;
            this.dialogAddMember = true;
        },

        openGift({id, name}) {
            this.dialog.title = `Thưởng`
            this.dialog.id = id;
            this.dialog.name = name;
            this.dialogGiftSuccess = true;
        },

        openPunish({id, name}) {
            this.dialog.title = `Phạt`
            this.dialog.id = id;
            this.dialog.name = name;
            this.dialogPunish = true;
        },

        querySever(customProperties) {
        },

        mergeParams(customProperties) {
            let params = {
                page: this.paging.current_page,
                per_page: this.paging.per_page,
                view_as: this.form.view_as,
            };
            if (this.form.name) {
                params = _.merge(params, {name: this.form.name})
            }

            if (this.form.branch_id) {
                params = _.merge(params, {branch: this.form.branch_id})
            }

            if (this.form.course_id) {
                params = _.merge(params, {course: this.form.course_id})
            }

            if (this.form.status) {
                params = _.merge(params, {status: this.form.status})
            }

            if (this.form.startDate) {
                params = _.merge(params, {start_date: this.form.startDate})
            }

            if (this.form.endDate) {
                params = _.merge(params, {end_date: this.form.endDate})
            }
            params = _.merge(params, customProperties);
            return params;
        },
        reloadPage() {
            this.classroom_id = this.$route.params.id;
            this.$store.dispatch(LIST_MEMBER_CLASS, {classroom_id : this.classroom_id}).then((res) => {
                this.clans = res.data.clan;
                this.notInClans = res.data.not_in_clan;
            }).catch(() => {
                this.isError = true;
            })
        },

        onSubmit()
        {
            this.$store.dispatch(CREATE_TEAM, this.team).then((res) => {
                this.$message({
                    type: 'success',
                    message: 'Thay đổi thành công'
                });
                this.getData();
                this.dialogCreateTeam = false;
            }).catch((e) => {
                if (e.data.data.message_validate_form) {
                    this.$refs.form.setErrors(e.data.data.message_validate_form);
                }
            })
        },
      getClass()
      {
        this.$store.dispatch(GET_ClASS_BY_ID, this.$route.params.id).then((res) => {
          this.classroom = res.data
        }).catch((e) => {

        })
      }
    }
}
</script>

<style scoped>
.group-color {
    background: #ffc24129;
}
.bg-violet {
    background: #5E00B6;
}
.group-color-yet {
    background: #2196f326;
}
</style>