<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form
      class="form"
      id="course_create"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Tạo khóa học</h3>
          </div>
          <div class="card-toolbar">
            <router-link
              class="font-weight-bold font-size-3 btn btn-secondary"
              :to="{ name: 'course-index' }"
              ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Tên khóa học
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Tên khóa học" rules="required"
                                    v-slot="{ errors,classes }">
                  <input type="text" ref="name" name="name" class="form-control" v-model="course.name"
                         placeholder="Tên khóa học" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="row col-md-9">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Tên khóa học <span class="text-danger">*</span></label>
                  <ValidationProvider
                    vid="name"
                    name="Tên khóa học"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      ref="name"
                      name="name"
                      class="form-control"
                      v-model="course.name"
                      placeholder="Tên khóa học"
                      :class="classes"
                    />
                    <div class="fv-plugins-message-container">
                      <div
                        data-field="name"
                        data-validator="notEmpty"
                        class="fv-help-block"
                      >
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Học phí <span class="text-danger">*</span></label>
                  <ValidationProvider
                    mode="lazy"
                    vid="fee"
                    name="Học phí"
                    rules="required|integer|min_value:0"
                    v-slot="{ errors, classes }"
                  >
                    <money
                      v-model="course.fees_sale"
                      v-bind="money"
                      class="form-control"
                      :class="classes"
                    ></money>
                    <input type="hidden" v-model="course.fee" />
                    <div class="fv-plugins-message-container">
                      <div
                        data-field="name"
                        data-validator="notEmpty"
                        class="fv-help-block"
                      >
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Trung tâm <span class="text-danger">*</span></label>
                <ValidationProvider
                  name="Trung tâm"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <multiselect
                    v-model="cacheCenter"
                    :options="centers"
                    placeholder="Trung tâm"
                    label="name"
                    track-by="name"
                    :class="classes"
                    @input="updateStateCenter($event)"
                  ></multiselect>
                  <div class="fv-plugins-message-container">
                    <div
                      data-field="name"
                      data-validator="notEmpty"
                      class="fv-help-block"
                    >
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4" v-if="is_show_course_type">
              <div class="form-group">
                <label>Loại khóa học <span class="text-danger">*</span></label>
                <ValidationProvider
                  name="Loại khóa học"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <multiselect
                    v-model="cacheCourseType"
                    :options="course_types"
                    placeholder="Loại khóa học"
                    label="name"
                    track-by="name"
                    :class="classes"
                    @input="updateStateCourseType($event)"
                  ></multiselect>
                  <div class="fv-plugins-message-container">
                    <div
                      data-field="name"
                      data-validator="notEmpty"
                      class="fv-help-block"
                    >
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4" v-if="is_show_level">
              <div class="form-group">
                <label
                  >Trình độ đầu vào <span class="text-danger"></span
                ></label>
                <multiselect
                    v-model="cacheLevelIn"
                    :options="level_in"
                    placeholder="Trình độ đầu vào"
                    label="name"
                    track-by="name"
                    @input="updateStateLevelIn($event)"
                ></multiselect>
              </div>
            </div>
            <div class="col-md-6" v-if="is_show_level">
              <div class="form-group">
                <label
                  >Trình độ đầu ra <span class="text-danger">*</span></label
                >
                <ValidationProvider
                  name="Trình độ đầu ra"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <multiselect
                      v-model="cacheLevelOut"
                      :options="level_out"
                      placeholder="Trình độ đầu ra"
                      label="name"
                      track-by="name"
                      :class="classes"
                      @input="updateStateLevelOut($event)"
                  ></multiselect>
                  <div class="fv-plugins-message-container">
                    <div
                      data-field="name"
                      data-validator="notEmpty"
                      class="fv-help-block"
                    >
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
<!--            <div class="col-md-4">-->
<!--              <div class="form-group">-->
<!--                <label>Giảm giá (%)-->
<!--                  <span class="text-danger"></span></label>-->
<!--                <ValidationProvider vid="sale" name="Giá giảm" rules="" v-slot="{ errors,classes }">-->
<!--                  <input type="text" ref="name" name="name"-->
<!--                         class="form-control"-->
<!--                         placeholder="Giảm giá"-->
<!--                         :class="classes"-->
<!--                         v-model="course.sale"-->
<!--                  >-->
<!--                  <div class="fv-plugins-message-container">-->
<!--                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{-->
<!--                        errors[0]-->
<!--                      }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </ValidationProvider>-->
<!--              </div>-->
<!--            </div>-->
            <div class="col-md-4">
              <div class="form-group">
                <label>Học phí tối thiểu
                  <span class="text-danger"></span></label>
                <ValidationProvider vid="minimum_tuition_fee" name="Học phí tối thiểu" rules="" v-slot="{ errors,classes }">
                  <money v-model="course.minimum_tuition_fee" v-bind="money" class="form-control" :class="classes"
                  ></money>
                  <input type="hidden" v-model="course.minimum_tuition_fee">
                  <div class="fv-plugins-message-container">
                    <div
                      data-field="name"
                      data-validator="notEmpty"
                      class="fv-help-block"
                    >
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Thời lượng học<span class="text-danger"></span></label>
                <ValidationProvider
                  vid="sale"
                  name="Giá giảm"
                  rules=""
                  v-slot="{ errors, classes }"
                >
                  <input
                    type="text"
                    ref="name"
                    name="name"
                    class="form-control"
                    placeholder="Thời lượng học"
                    :class="classes"
                    v-model="course.time"
                  />
                  <div class="fv-plugins-message-container">
                    <div
                      data-field="name"
                      data-validator="notEmpty"
                      class="fv-help-block"
                    >
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label
                  >Video giới thiệu <span class="text-danger"></span
                ></label>
                <input
                  type="text"
                  ref="name"
                  name="name"
                  class="form-control"
                  v-model="course.video"
                  placeholder="Video"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input
                    type="checkbox"
                    name="Checkboxes5"
                    v-model="course.use_schedule"
                  />
                  <span></span>Sử dụng lộ trình</label
                >
              </div>
            </div>
            <div class="col-md-4">
              <div class="checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input
                    type="checkbox"
                    name="Checkboxes5"
                    v-model="course.status"
                  />
                  <span></span>Hiển thị</label
                >
              </div>
            </div>
            <div class="col-md-4">
              <div class="checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input
                    type="checkbox"
                    name="Checkboxes5"
                    v-model="course.is_priority"
                  />
                  <span></span>Khóa học nổi bật</label
                >
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="form-group mb-1 col-md-12">
              <label>Nội dung bài học <span class="text-danger">*</span></label>
              <ValidationProvider
                vid="content"
                name="Nội dung"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <vue-editor
                  id="editor-content"
                  :editorOptions="editorSettings"
                  useCustomImageHandler
                  v-model="course.content"
                  :class="classes"
                  @image-added="handleImageAdded"
                ></vue-editor>
                <div class="fv-plugins-message-container">
                  <div
                    data-field="name"
                    data-validator="notEmpty"
                    class="fv-help-block"
                  >
                    {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <label>Mô tả ngắn <span class="text-danger"></span></label>
              <div class="form-group mb-1">
                <textarea
                  class="form-control"
                  id="exampleTextarea"
                  rows="3"
                  v-model="course.excerpt"
                  placeholder="Mô tả ngắn"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="form-group mb-1 col-md-12">
              <label>Mô tả <span class="text-danger">*</span></label>
              <ValidationProvider
                name="Mô tả"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <vue-editor
                  id="editor"
                  :editorOptions="editorSettings"
                  useCustomImageHandler
                  v-model="course.description"
                  :class="classes"
                  @image-added="handleImageAdded"
                ></vue-editor>
                <div class="fv-plugins-message-container">
                  <div
                    data-field="name"
                    data-validator="notEmpty"
                    class="fv-help-block"
                  >
                    {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <!--Thông tin cấp trên-->
        <div class="separator separator-dashed my-5"></div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button type="submit" class="btn btn-success mr-2">Tạo mới</button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import Multiselect from "vue-multiselect";
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { CREATE_COURSE } from "../../../core/services/store/course/course.module";
import { GET_CENTERS } from "../../../core/services/store/center/centers.module";
import { Money } from "v-money";

//editor
import ApiService from "@/core/services/api.service";
import { VueEditor, Quill } from "vue2-editor";
import ImageResize from "quill-image-resize-vue";
import { ImageDrop } from "quill-image-drop-module";
import { GET_COURSE_TYPES } from "../../../core/services/store/course/courseType.module";
import { GET_COURSE_LEVELS } from "../../../core/services/store/course/courseLevel.module";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
export default {
  components: {
    Multiselect,
    DatePicker,
    VueEditor,
    Money,
  },
  name: "CourseCreate",
  data() {
    return {
      // Check show
      is_show_course_type: true,
      is_show_level: true,
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
        },
      },
      course: {
        name: '',
        fees_sale: '',
        type_id: '',
        level_in: '',
        level_out: '',
        sale: '',
        time: "",
        minimum_tuition_fee: '',
        video: '',
        use_schedule: false,
        status: false,
        is_priority: false,
        excerpt: "",
        description: "",
        center_id: "",
        content: "",
      },
      course_types: [],
      level_in: [],
      level_out: [],
      level_common: [],
      centers: [],

      // Cache
      cacheCenter: [],
      cacheLevelIn: [],
      cacheLevelOut: [],
      cacheCourseType: [],
      money: {
        decimal: ",",
        thousands: ",",
        prefix: "",
        suffix: " VNĐ",
        precision: 0,
        masked: false,
      },
      current_photo: null,
      image_name: null,
      image_type: null,
    };
  },
  mounted() {
    this.getCenters();
    this.getLevelInAndOut();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý khóa học", route: "profile-1" },
      { title: "Tạo khóa học" },
    ]);
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  methods: {
    getCenters() {
      this.$store
        .dispatch(GET_CENTERS, {
          limit: 10000,
          status: 1,
        })
        .then((res) => {
          if (!res.error) {
            this.centers = res.data.data;
          }
        })
        .catch((e) => {
          console.log(e, "error");
        });
    },
    getCourseType() {
      this.$store
        .dispatch(GET_COURSE_TYPES, {
          per_page: 10000,
          center_id: this.course.center_id,
        })
        .then((res) => {
          if (!res.error) {
            this.course_types = res.data.data;
          }
        })
        .catch((e) => {
          console.log(e, "error");
        });
    },
    getLevelInAndOut() {
      this.$store.dispatch(GET_COURSE_LEVELS, {
        per_page: 10000,
        center_id: this.course.center_id,
        type_id: this.course.type_id
      }).then((res) => {
        if (!res.error) {
          this.level_in = res.data.data
          this.level_out = res.data.data
          this.level_common = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    updateStateCenter($event) {
      this.course.type_id = '';
      this.course.level_in = '';
      this.course.level_out = '';
      // Reset cache
      this.cacheCourseType = [];
      this.cacheLevelIn = [];
      this.cacheLevelOut = [];
      if ($event == null) {
        // Reset state
        this.course.center_id = "";
        this.is_show_course_type = false;
        this.is_show_level = false;
      } else {
        let id = $event.id;
        this.course.center_id = id;
        this.is_show_course_type = true;
        this.getCourseType();
      }
    },
    updateStateCourseType($event) {
      // reset cache
      this.course.level_in = '';
      this.course.level_out = '';
      this.cacheLevelIn = [];
      this.cacheLevelOut = [];

      if ($event == null) {
        this.course.type_id = '';
        this.is_show_level = false;
      } else {
        let id = $event.id
        this.course.type_id = id;
        this.getLevelInAndOut();
        this.is_show_level = true;
      }
    },
    updateStateLevelIn($event) {
      let id = $event.id
      this.course.level_in = id
      let orderIn = $event.order
      // Reset data
      this.level_out = [];
      this.cacheLevelOut = [];

      this.level_common.map((value) => {
        if (value.order > orderIn) {
          this.level_out.push(value)
        }
      });
    },
    updateStateLevelOut($event) {
      let id = $event.id;
      this.course.level_out = id;
      let orderOut = $event.order;
      this.level_common.map((value) => {
        if (value.order > orderOut) {
          this.level_in.push(value)
        }
      });
    },

    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("image", file);

      ApiService.post("upload-image", formData)
        .then(({ data }) => {
          let url = data.data; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onFileChange(e) {
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
        this.image_name = file.name;
        this.image_type = file.type;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    async onSubmit() {
      let formData = new FormData();

      // image
      let avatar = this.photo;
      function urltoFile(url, filename, mimeType) {
        return fetch(url)
          .then(function (res) {
            return res.arrayBuffer();
          })
          .then(function (buf) {
            return new File([buf], filename, { type: mimeType });
          })
          .catch((error) => {
            return 1;
          });
      }
      if (avatar !== undefined) {
        if (avatar.search("https") === -1) {
          let file = await urltoFile(avatar, this.image_name, this.image_type);
          if (file !== 1 && file.name !== "null") {
            formData.append("avatar_mkt", file);
          }
        }
      }


      for (let key in this.course) {
        formData.append(key, this.course[key]);
      }

      this.$store
        .dispatch(CREATE_COURSE, formData)
        .then((res) => {
          if (res.error) {
            this.$bvToast.toast(res.message, {
              title: "Tạo mới",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$router.push({ name: "course-index" }).then(() => {
              this.$bvToast.toast(res.message, {
                title: "Tạo mới",
                variant: "success",
                solid: true,
              });
            });
          }
        })
        .catch((e) => {
          if (e.data.data.message_validate_form) {
            this.$refs.form.setErrors(e.data.data.message_validate_form);
          }
        });
    },
  },
};
</script>
<style scoped>
.mx-datepicker {
  width: 100% !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
