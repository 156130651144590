<template>
    <div>
        <button v-b-modal="`modal-show-detail-${item.id}`" title="Xem"
                class="btn btn-xs btn-icon mr-2 btn-outline-info">
            <i class="fa far fa-eye"></i>
        </button>

        <b-modal :id="'modal-show-detail-' + item.id"
                 hide-footer
                 :centered="true" title="Thông tin chi tiết buổi kiểm tra" size="lg">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 font-weight-bold">Lớp:</div>
                    <div class="col-md-8">{{item.classroom ? item.classroom.name : ''}}</div>
                </div>
                <div class="row mt-6">
                    <div class="col-md-4 font-weight-bold">Buổi kiếm tra:</div>
                    <div class="col-md-8">{{typeDefine[item.type]}}</div>
                </div>
                <div class="row mt-6">
                    <div class="col-md-4"><span class="font-weight-bold">Số lượng học viên:</span></div>
                    <div class="col-md-8">{{item.classroom ? item.classroom.number_student : ''}}</div>
                </div>
                <div class="row mt-6">
                    <div class="col-md-4 font-weight-bold">Thời gian:</div>
                    <div class="col-md-8">{{item.date | formatDateTimeAsia  }}</div>
                </div>
                <div class="row mt-6">
                    <div class="col-md-4 font-weight-bold">Hình thức kiểm tra:</div>
                    <div class="col-md-8" v-if="item.classroom ">
                        <span :class="{'badge badge-success' : item.classroom.form_study =='online', 'badge badge-secondary' : item.classroom.form_study !='online'}"
                        >{{ item.classroom.form_study}}</span>
                    </div>
                </div>
                <div v-if="item.classroom && item.classroom.form_study">
                    <div v-if="item.classroom.form_study =='online'">
                        <div class="row mt-6">
                            <div class="col-md-4 font-weight-bold">ID Zoom:</div>
                            <div class="col-md-8">{{item.classroom.zoom_id}}</div>
                        </div>
                        <div class="row mt-6">
                            <div class="col-md-4 font-weight-bold">Pass:</div>
                            <div class="col-md-8">{{item.classroom.zoom_password}}</div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="row mt-6" v-if="item.branch">
                            <div class="col-md-4 font-weight-bold">Địa chỉ:</div>
                            <div class="col-md-8">{{item.branch.address}}</div>
                        </div>
                        <div class="row mt-6" v-if="item.classroom && item.classroom.room">
                            <div class="col-md-4 font-weight-bold">Phòng học:</div>
                            <div class="col-md-8">{{item.classroom.room.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {GET_TESTERS} from "../../../../core/services/store/test/test.module";

    export default {
        name: "ModalShowExamDetail",
        props: {
            item: {
                type: Object,
                default: () => {
                    return null;
                }
            }
        },
        data() {
            return {
                is_show_select_tester: false,
                is_tester: false,
                testers: [],
                value: '',
                typeDefine: {
                    mid: 'Thi giữa khóa',
                    last: 'Cuối khóa',
                },
                statusDefine: {
                    1: 'Chưa làm bài',
                    2: 'Đã chấm xong',
                }
            }
        },
        created() {
            // this.getTesters();
        },
        methods: {
            getTesters() {
                this.$store.dispatch(GET_TESTERS).then((data) => {
                    if (!data.error) {
                        this.testers = data.data;
                    }
                })
            },
            handleTester() {
                this.is_show_select_tester = true;
            }
        },

    }
</script>

<style scoped>
    .font-weight-bold {
        font-weight: bold !important;
    }
</style>