import ApiService from "@/core/services/api.service";
import {GET_CUSTOMERS} from "@/core/services/store/customer/customer.module";
import {UPDATE_STATUS_DEPARTMENT} from "@/core/services/store/department/department.module";
export const EDU_OPTION_GET = `edutalk-option`;
export const EDU_OPTION_POST = `edutalk-option-post`;
export const EDU_OPTION_PUT = `edutalk-option-put`;
export const EDU_OPTION_DELETE = "delete-edutalk-option";
export const EDU_OPTION_CREATE = "create-edutalk-option";
export const EDU_OPTION_UPLOAD = "create-edutalk-option";
export const GET_EDU_OPTION = "get-edutalk-option";

//mutations types

const state = {
    edutalkOptions: [],
};

const getters = {
};

const actions = {
    [EDU_OPTION_POST](context, payload) {
        return new Promise((resolve, reject) => {
            // let a = { local: 1}
            ApiService.post('edutalk-option', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    [GET_EDU_OPTION](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_EDU_OPTION, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
            });
        })
    },
    [EDU_OPTION_PUT](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`${'edutalk-option'}/${payload.id}` , payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
            });
        })
    },
}

const mutations = {

};

export default {
    state,
    actions,
    mutations,
    getters
};
