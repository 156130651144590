<template>
  <div class="table-responsive table-result">
    <table class="table" style="text-align: center;">
      <thead>
      <tr style="border: 1px solid #EBEDF3">
        <th scope="col" class="min-w-150px headerTable">KRAs</th>
        <th scope="col" class="min-w-120px headerTable">Yêu cầu thành phẩm</th>
        <th scope="col" class="min-w-130px headerTable">Bảng thành phẩm</th>
        <th scope="col" class="min-w-80px headerTable">Trọng số</th>
        <th scope="col" class="min-w-60px headerTable" v-for="(item, index) in listManagementKpi[0]?.kra_manager_weeks">KQ tuần {{index + 1}}</th>
        <th scope="col" class="min-w-80px headerTable">Kết quả KRA</th>
        <th scope="col" class="min-w-120px headerTable">Nhận xét</th>
      </tr>
      </thead>
      <tbody v-if="listManagementKpi?.length > 0">
        <tr v-for="(item, index) of listManagementKpi" :key="index + 'kra'" style="border: 1px solid #EBEDF3; border-top: 0">
          <td style="text-align: start;"><span :title="item?.name" class="line-clamp-1">{{item?.name}}</span></td>
          <td style="text-align: start"><span :title="item?.request_result" class="line-clamp-1" v-html="item?.request_result"></span></td>
          <td style="text-align: start"><span :title="item?.results" class="line-clamp-1">{{item?.results ?? 'Chưa có'}}</span></td>
          <td style="text-align: start">{{ item.percent_score ? `${item?.percent_score }%` : 'Chưa có' }}</td>
          <td v-for="week in item?.kra_manager_weeks" :key="week.id">
            <div style="position: relative;">
              <el-select
                  v-model="week.percent_results"
                  style="width: 100%;"
                  :class="setColorValue(week.percent_results)"
                  @change="handleMarkResultKpi(item, week, item?.kra_manager_weeks)"
                  size="small" :disabled="!isEdit"
                  clearable placeholder="Chọn">
                <el-option
                    v-for="item in pointOption"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                    :class="setColor(item.value)"
                >
                </el-option>
              </el-select>
              <div ref="popup" v-show="weekSelected?.week === week?.week && kraSelected?.id === item?.id && isShowPopup" class="popup-suggest">
                <div style="font-weight: 700; display: flex;">Tự động điền</div>
                <div style="word-break: break-word; white-space: nowrap">Chấm các tuần còn lại của KRA này là {{percent}}%?</div>
                <div style="display: flex; justify-content: center; align-items: center; gap: 10px">
                  <i class="el-icon-circle-check" style="color: #0C9200; cursor: pointer; font-size: 25px;" @click="setAllValue"></i>
                  <i class="el-icon-circle-close" style="color: red; cursor: pointer; font-size: 25px;" @click="isShowPopup = false"></i>
                </div>
              </div>
            </div>
          </td>
          <td style="text-align: start">{{ (item?.percent_results || item?.percent_results === 0)  ? `${item?.percent_results}%` : 'Chưa có' }}</td>
          <td id="comment">
            <el-input v-model="item.description" size="small" :disabled="!isEdit" placeholder="Nhập nhận xét..."/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {pointOptionKpi} from "@/common/define";

export default {
  name: 'TableResultKpi',
  data() {
    return {
      pointOption: pointOptionKpi,
      listWeekly: [],
      weekSelected: null,
      kraSelected: null,
      percent: null,
      isShowPopup: false,
    }
  },
  watch: {
    kraSelected: {
      handler(val) {
        this.calculatorTotalPercent(val)
      },
      deep: true,
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  props: {
    listManagementKpi: {
      type: Array,
      default: [],
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  methods: {
    handleClickOutside(event) {
      this.isShowPopup = false;
    },
    setColor(value) {
      switch (value) {
        case 0:
          return 'text-red'
        case 30:
        case 60:
        case 90:
          return 'text-blue'
        case 100:
          return 'text-green'
      }
    },
    setColorValue(value) {
      switch (value) {
        case 0:
          return 'value-color-red'
        case 30:
        case 60:
        case 90:
          return 'value-color-blue'
        case 100:
          return 'value-color-green'
      }
    },
    roundDecimal(value, decimals) {
      if (value % 1 !== 0) {
        return parseFloat(value.toFixed(decimals));
      }
      return value;
    },
    setAllValue() {
      for (let i = 0; i < this.listWeekly.length; i++) {
        this.listWeekly[i].percent_results = this.percent
      }
    },
    calculatorTotalPercent(kra) {
      let totalPercent = 0
      let lengthMarked = 0
      let totalPercentUser = 0
      kra?.kra_manager_weeks?.map(item => {
        if (item?.percent_results || item?.percent_results === 0) {
          totalPercent += item?.percent_results
          lengthMarked += 1
        }
      })
      kra.percent_results = this.roundDecimal((totalPercent / lengthMarked), 2)
      this.listManagementKpi.map(item => {
        if (item?.percent_results || item?.percent_results === 0) {
          totalPercentUser += (item?.percent_results * item?.percent_score) / 100
        }
      })
      this.$emit('totalPercentKra', this.roundDecimal(totalPercentUser, 2))
    },
    handleMarkResultKpi(kra, week, weeks) {
      this.weekSelected = week
      if (typeof week?.percent_results === 'string') {
        this.weekSelected.percent_results = null
        this.isShowPopup = false
      }else if (week.week === 1 && week?.percent_results || week.week === 1 && week?.percent_results === 0 || week.week === 2 && week?.percent_results === weeks[0]?.percent_results) {
        this.isShowPopup = true
        this.kraSelected = kra
        this.percent = weeks[0]?.percent_results
        this.listWeekly = weeks
      } else if (!weeks[0]?.percent_results && !weeks[1]?.percent_results) {
        this.isShowPopup = false
      }
      this.calculatorTotalPercent(kra)
    },
  },
}
</script>

<style lang="css">
  .line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .value-color-red .el-input__inner{
    color: red !important;
  }
  .value-color-blue .el-input__inner{
    color: blue !important;
  }
  .value-color-green .el-input__inner{
    color: green !important;
  }
  .text-red{
    color: red;
  }
  .text-blue{
    color: blue;
  }
  .text-green{
    color: green;
  }
  .headerTable {
    background: #F8F8F8;
    text-align: start;
    word-break: break-word;
  }
  .table-result {
    overflow: auto;
    margin-bottom: 30px;
    max-height: 230px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  }
  .popup-suggest {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    position: absolute;
    top: 40px;
    left: 100px;
    min-width: 320px;
    max-width: 360px;
    padding: 10px 20px;
    border-radius: 8px;
    background: white;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    z-index: 99;
  }
</style>