<template>
    <div>
        <button @click="handleOk(); checkCommentStatus();"
                class="btn btn-success btn-sm"
                :title="ticket.status != 2 ? 'Không thể hoàn thành ticket khi chưa nhập giải pháp' : ''"
                :disabled="ticket.status != 2"
        > Hoàn thành
        </button>
    </div>
</template>

<script>
    import {CHANGE_STATUS} from "../../../../../core/services/store/user/ticket.module";
    import Swal from "sweetalert2";

    export default {
        name: "ModalStatusSuccess",
        props: {
            ticket: {
                type: Object,
                default: () => {
                    return null;
                }
            }
        },
        data() {
            return {
                update_loading: false
            }
        },
        
         methods: {
            async checkCommentStatus() {
                await this.$emit('changeStatusSuccess', false);
            },

            async handleOk() {
                await this.$emit('changeStatusSuccess');

                Swal.fire({
                    title: "Bạn chắc chắn muốn hoàn thành ticket " + this.ticket.id,
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Xác nhận",
                    cancelButtonText: "Hủy bỏ",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(CHANGE_STATUS, {
                            id: this.ticket.id,
                            status: 3, // Hoàn thành
                            status_feedback: this.ticket.status_comment === 1 ? 2 : ''
                        }).then((res) => {
                            if (!res.error) {
                                this.$bvToast.toast('Ticket đã được xử lý xong', {
                                    title: 'Thành công',
                                    variant: 'success',
                                    solid: true
                                });
                                this.$emit('changeStatusSuccess', true);
                            }
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>