import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "../auth.module";

const _ = require('lodash');
export const POST_TOURNAMENT = "POST_TOURNAMENT";
export const GET_TOURNAMENT = "GET_TOURNAMENT";
export const DELETE_TOURNAMENT = "DELETE_TOURNAMENT"
export const GET_DETAIL_TOURNAMENT = "GET_DETAIL_TOURNAMENT";
export const UPDATE_TOURNAMENT = "UPDATE_TOURNAMENT";
export const POST_NEWS = "POST_NEWS";
export const GET_NEWS = "GET_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
export const GET_COUNT_NUMBER_ORDER = "GET_COUNT_NUMBER_ORDER"
export const GET_DETAIL_NEWS = "GET_DETAIL_NEWS";
export const EDIT_NEWS = "EDIT_NEWS";
export const IMPORT_EXAM = "IMPORT_EXAM";
export const DOWNLOAD_EXAM = "DOWNLOAD_EXAM";
export const GET_EXAM = "GET_EXAM";
export const IMPORT_TEAM = "IMPORT_TEAM";
export const DOWNLOAD_TEAM = "DOWNLOAD_TEAM"
export const TEAM_BROWSING_LIST = "TEAM_BROWSING_LIST"
export const BROWSE_TEAM = "BROWSE_TEAM"
export const REASON_REFUSE = "REASON_REFUSE"
export const GET_TEAM = "GET_TEAM"
export const DELETE_TEAM = "DELETE_TEAM"
export const DOWNLOAD_LIST_TEAM = "DOWNLOAD_LIST_TEAM"
export const DOWNLOAD_SCHEDULE = "DOWNLOAD_SCHEDULE"
export const IMPORT_SCHEDULE = "IMPORT_SCHEDULE";
export const GET_SCHEDULE = "GET_SCHEDULE"
export const DELETE_SCHEDULE = "DELETE_SCHEDULE"
export const DOWNLOAD_LIST_SCHEDULE = "DOWNLOAD_LIST_SCHEDULE"
const state = {};

const getters = {};
const actions = {
    [POST_TOURNAMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`tournament/tournaments`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_TOURNAMENT](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`tournament/tournaments`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [DELETE_TOURNAMENT](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('tournament/tournaments', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [GET_DETAIL_TOURNAMENT](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get('tournament/tournaments', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [UPDATE_TOURNAMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`tournament/tournaments/${payload.id}?_method=PUT`, payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [POST_NEWS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`tournament/news`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_NEWS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`tournament/news`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [DELETE_NEWS](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('tournament/news', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [GET_COUNT_NUMBER_ORDER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`tournament/news/count-number-order`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [GET_DETAIL_NEWS](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get('tournament/news', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [EDIT_NEWS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`tournament/news/${payload.id}`, payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [IMPORT_EXAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`tournament/question/import/${payload.id}`, payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [DOWNLOAD_EXAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`tournament/question/download-example`, payload).then(({data}) => {
                const filename = "File mẫu import đề thi.xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_EXAM](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`tournament/questions`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [IMPORT_TEAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`tournament/teamMember/import/${payload.id}`, payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [DOWNLOAD_TEAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`tournament/teamMember/download-example`).then(({data}) => {
                const filename = "File mẫu import team.xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [TEAM_BROWSING_LIST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`tournament/teamMember/browse-team-list/${payload.id}`, payload.data)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [BROWSE_TEAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`tournament/teamMember/browse-team`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [REASON_REFUSE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`tournament/teamMember/reason-refuse`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_TEAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`tournament/teams`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [DELETE_TEAM](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('tournament/teams', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [DOWNLOAD_LIST_TEAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`tournament/teams/export?keyword=${payload.keyword}&status[]=1`, payload.data).then(({data}) => {
                const filename = "File danh sách team.xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DOWNLOAD_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`tournament/matches/download-example`).then(({data}) => {
                const filename = "File mẫu import lịch thi đấu.xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [IMPORT_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`tournament/matches/import/${payload.id}`, payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [GET_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`tournament/matches`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [DELETE_SCHEDULE](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('tournament/matches', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [DOWNLOAD_LIST_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`tournament/matches/export`, payload).then(({data}) => {
                const filename = "File lịch thi đấu.xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

};


const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};