<template>
  <div class="task-manager">
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          Danh sách tác vụ
        </div>
      </div>
      <div class="card-body">
        <div class="example mb-10">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label></label>
                <el-date-picker
                    v-model="query.date_week"
                    clearable
                    type="week"
                    :format="dateRange"
                    placeholder="Pick a week">
                </el-date-picker>
              </div>
            </div>
            <div class="form-group col-md-3">
              <div class="form-group">
                <el-input
                    v-model="query.phone"
                    placeholder="Nhập số điện thoại tester"
                    clearable>
                </el-input>
              </div>
            </div>
            <div class="form-group col-md-3">
              <div class="form-group">
                <button class="btn btn-primary"
                        :class="{'spinner spinner-white spinner-left' : is_loading_search}"
                        @click="search"
                >Tìm kiếm
                </button>
              </div>
            </div>
          </div>
          <TaskManagerCalendar :tasks="tasks" :days="days"></TaskManagerCalendar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {TEST_INPUT_GET_TEST_INPUT_SCHEDULE} from "@/core/services/store/test/testInput.module";
import {TYPE_TASK_ITEM_DEFINE, TYPE_TASK_ITEM_DEFINE_CSS} from "@/core/option/testInputOption";
import moment from "moment-timezone";
import TaskManagerCalendar from "@/view/pages/test-input/components/TaskManagerCalendar";
import {daysOfWeek} from "@/core/option/dayOption";

export default {
  name: "TaskManagerIndex",
  components: {TaskManagerCalendar},
  data() {
    return {
      is_loading_search: false,
      tasks: [],
      TYPE_TASK_DEFINE: TYPE_TASK_ITEM_DEFINE,
      TYPE_TASK_DEFINE_CSS: TYPE_TASK_ITEM_DEFINE_CSS,
      query: {
        id: '',
        type_task: '',
        status: '',
        tester_id: 40,
        date_week: moment().startOf('week'),
      },
      daysOfWeek: daysOfWeek
    }
  },
  computed: {
    dateRange() {
      let startWeek = moment(this.query.date_week);
      let endWeek = startWeek.clone().endOf('week');
      return startWeek.format('DD/MM/YYYY') + ' - ' + endWeek.format('DD/MM/YYYY')
    },
    days() {
      let result = [];
      let startWeek = moment(this.query.date_week);
      for (let i = 0; i < 7; i++) {
        let tomorrow = startWeek.clone().add(i, 'days');
        result.push({
          day: tomorrow.clone().format('DD-MM-YYYY'),
          dayOfWeek: this.daysOfWeek[tomorrow.clone().day()]
        })
      }
      return result;
    }
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý tác vụ", route: 'task-manager'},
      {title: "Quản lý tác vụ"}
    ]);
  },
  mounted() {
    this.getTestInputSchedule();
  },
  methods: {
    search() {
      this.is_disable_search = true;
      this.getCalendar();
    },
    getTestInputSchedule() {
      let payload = {
        ...this.query,
        start_week: moment(this.query.date_week).format('YYYY-MM-DD')
      }
      this.$store.dispatch(TEST_INPUT_GET_TEST_INPUT_SCHEDULE, payload).then((data) => {
        this.tasks = data.data;
      });
    },
    getScheduleEnd(schedule) {
      return moment(schedule).add(30, 'minutes').format('DD-MM-YYYY HH:mm:ss')
    }
  }
}
</script>

<style scoped>
.el-input {
  width: 100% !important;
}
</style>