<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách chính sách trụ sở tổng</h3>
      </div>
      <div class="card-title">
        <div>
          <b-button v-b-modal.modal-1>Thêm mới</b-button>
          <b-modal id="modal-1" title="Thêm mới đầu mục" hide-footer :class="closeModal">
            <ValidationObserver v-slot="{ handleSubmit }" ref="form">
              <form class="form" id="course_create" @submit.prevent="handleSubmit(onSubmit)">
                <div class="card card-custom">
                  <div class="card-body">
                    <div class="row mt-3">
                      <div class="form-group mb-1 col-md-12">
                        <label>Tên
                          <span class="text-danger">*</span></label>
                        <ValidationProvider vid="name" name="Tên" rules="required"
                                            v-slot="{ errors,classes }">
                          <input type="text" v-model="name" class="form-control">
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                errors[0]
                              }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="separator separator-dashed my-5"></div>
                  <div class="card-header">
                    <div class="card-title align-items-start flex-column">
                      <button
                          type="submit"
                          class="btn btn-success mr-2"
                      >
                        Tạo mới
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </b-modal>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th scope="col" class="min-w-100px">Tiêu đề</th>
              <th scope="col" class="min-w-100px">Tên</th>
              <th scope="col" class="min-w-100px">Ngày tạo</th>
              <th scope="col" class="min-w-100px">Hành động</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in listCatalog" :key="index">
              <td>{{ ++index }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.created_at |formatDateTimeVietnam}}</td>
              <td>
                  <b-button v-b-modal.modal_edit class="btn btn-xs btn-icon mr-2 btn-outline-info" @click="showEdit(item.id)"><i class="fas fa-pen-nib"></i></b-button>
              </td>
            </tr>
            </tbody>
          </table>
          <b-modal id="modal_edit" title="Cập nhập " hide-footer>
            <ValidationObserver v-slot="{ handleSubmit }" ref="form">
              <form class="form" id="course_create" @submit.prevent="handleSubmit(onUpdate)">
                <div class="card card-custom">
                  <div class="card-body">
                    <div class="row mt-3">
                      <div class="form-group mb-1 col-md-12">
                        <label>Tên
                          <span class="text-danger">*</span></label>
                        <ValidationProvider vid="name" name="Tên" rules="required"
                                            v-slot="{ errors,classes }">
                          <input type="text" v-model="catalog.name" class="form-control">
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                errors[0]
                              }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="separator separator-dashed my-5"></div>
                  <div class="card-header">
                    <div class="card-title align-items-start flex-column">
                      <button
                          type="submit"
                          class="btn btn-success mr-2"
                      >
                        Tạo mới
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </b-modal>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
<!--      <paginate-->
<!--          v-model="page"-->
<!--          :page-count="last_page"-->
<!--          :page-range="3"-->
<!--          :margin-pages="1"-->
<!--          :click-handler="clickCallback"-->
<!--          :prev-text="'Trước'"-->
<!--          :next-text="'Sau'"-->
<!--          :container-class="'pagination b-pagination'"-->
<!--          :pageLinkClass="'page-link'"-->
<!--          :next-link-class="'next-link-item'"-->
<!--          :prev-link-class="'prev-link-item'"-->
<!--          :prev-class="'page-link'"-->
<!--          :next-class="'page-link'"-->
<!--          :page-class="'page-item'"-->
<!--      >-->
<!--      </paginate>-->
    </div>
  </div>
</template>

<script>
import {
  CREATE_CATALOG,
  GET_CATALOG,
  GET_CATALOG_BY_ID_BY_ID,
  UPDATE_CATALOG
} from "@/core/services/store/system/termpolicy.module";

export default {
  name: "CatalogPolicyIndex",
  data() {
    return {
      listCatalog: {},
      last_page: '',
      page: 1,
      pageCount: '',
      name: '',
      closeModal: false,
      catalog: {}
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    clickCallback(obj) {
      // this.page = obj
      // this.$router.push({path: '', query: {page: this.page}})
      // this.getList()
    },
    getList() {
      this.$store.dispatch(GET_CATALOG,{}).then((res) => {
        this.listCatalog = res.data;
      }).catch((error) => {

      })
    },
    onSubmit() {
      this.$store.dispatch(CREATE_CATALOG,{name: this.name}).then((res) => {
        this.closeModal = 'hidden';
        this.$bvToast.toast(res.message, {
          title: 'Tạo mới',
          variant: 'success',
          solid: true
        });
        this.getList();
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      })
    },
    showEdit(id) {
      this.$store.dispatch(GET_CATALOG_BY_ID_BY_ID,id).then((res) => {
        this.catalog = res.data;
      }).catch((error) => {

      })
    },
    onUpdate() {
      this.$store.dispatch(UPDATE_CATALOG,{id: this.catalog.id, name: this.catalog.name}).then((res) => {
        this.closeModal = 'hidden';
        this.$bvToast.toast(res.message, {
          title: 'Cập nhập',
          variant: 'success',
          solid: true
        });
        this.getList();
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      })
    }
  }
}
</script>

<style scoped>

</style>