<template>
    <div class="">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Quản lý quà theo khóa học</h3>
            </div>
            <div class="card-title d-flex">
                <button
                    class="font-weight-bold font-size-3  btn btn-primary"
                    @click="showModal"
                >Thêm sách
                </button>
            </div>
        </div>
        <div class="card-body bg-white">
            <div class="example mb-10">
                <div class="example-preview table-responsive overflow-auto mt-6">
                    <table class="table table-hover table-bordered table-vertical-center b-table bg-white"
                           style="text-align: center">
                        <thead>
                        <tr>
                            <th scope="col" class="min-w-30px">Mã quà</th>
                            <th scope="col" class="min-w-100px">Tên quà</th>
                            <th scope="col" class="min-w-100px">Trung tâm</th>
                            <th scope="col" class="min-w-100px">Khóa học</th>
                            <th scope="col" class="min-w-100px">Hành động</th>
                        </tr>
                        </thead>
                        <tbody v-if="isBusy">
                        <tr role="row" class="b-table-busy-slot">
                            <td colspan="16" role="cell" class="">
                                <div class="text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-else-if="!dataCourseGift.length">
                        <tr role="row" class="b-table-busy-slot">
                            <td colspan="16" role="cell" class="">
                                <div class=" my-2">
                                    <strong>Không có thông tin </strong>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr v-for="(item, index) in dataCourseGift" :key="index">
                            <td> {{ item.code_gift }} </td>
                            <td> {{ item.name }} </td>
                            <td> {{ item.center ? item.center.name : '' }} </td>
                            <td> {{ handleDataCourse(item.course_gift_detail) }} </td>
                            <td>
                                <a
                                    title="Chỉnh sửa"
                                    @click="editCourseGift(item)"
                                    href="javascript:"
                                    class="btn btn-xs btn-icon btn-warning ml-2"><i class="flaticon2-pen"></i></a>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end bg-white pr-4">
            Tổng số bản ghi : {{ total }}
        </div>
        <div class="edu-paginate mx-auto d-flex text-center justify-content-center bg-white">
            <paginate
                v-model="page"
                :page-count="last_page"
                :page-range="3"
                :margin-pages="1"
                :click-handler="clickCallback"
                :prev-text="'Trước'"
                :next-text="'Sau'"
                :container-class="'pagination b-pagination'"
                :pageLinkClass="'page-link'"
                :next-link-class="'next-link-item'"
                :prev-link-class="'prev-link-item'"
                :prev-class="'page-link'"
                :next-class="'page-link'"
                :page-class="'page-item'"
            >
            </paginate>
        </div>
        <el-dialog :title="title" :visible.sync="dialogCreateForm">
            <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                <form ref="form" @submit.stop.prevent="submit">
                    <div class="row col-md-12">
                        <div class="col-md-6">
                            <label for="">Mã quà <span class="text-danger">*</span></label>
                            <ValidationProvider name="Mã quà" vid="code_gift" rules="required" v-slot="{ errors,classes }">
                                <input type="text" class="form-control" v-model="form.code_gift" placeholder="Nhập mã quà" maxlength="20">
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-6">
                            <label for="">Tên quà<span class="text-danger">*</span></label>
                            <ValidationProvider name="Tên quà" vid="name" rules="required" v-slot="{ errors,classes }">
                                <input type="text" class="form-control" v-model="form.name" placeholder="Nhập tên quà" maxlength="50">
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-6">
                            <label for="">Trung tâm <span class="text-danger">*</span></label>
                            <div class="form-group">
                                <ValidationProvider name="Trung tâm" vid="center_id" rules="required" v-slot="{ errors,classes }">
                                    <el-select v-model="form.center_id" placeholder="Chọn Trung tâm" class="w-100"
                                               @input="handleCenter" clearable>
                                        <el-option
                                            v-for="item in centers"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                                errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="">Khóa học</label>
                            <div class="form-group">
                                <el-select v-model="form.course_ids" placeholder="Chọn Khóa học" class="w-100"
                                           multiple
                                           clearable>
                                    <el-option
                                        v-for="item in courses"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <p slot="footer" class="col-md-12 dialog-footer d-flex justify-content-end">
                            <el-button @click="cancelBook">Hủy bỏ</el-button>
                            <el-button class="bg-violet text-white" type="primary" @click="submit">Xác nhận</el-button>
                        </p>
                    </div>
                </form>
            </ValidationObserver>

        </el-dialog>
    </div>
</template>

<script>
import {
    CREATE_COURSE_GIFT,
    GET_LIST_COURSE_GIFT,
    UPDATE_COURSE_GIFT
} from "../../../core/services/store/course/courseGift.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
import {GET_COURSES} from "../../../core/services/store/course/course.module";

export default {
    name: "CourseGiftIndex",
    data() {
        return {
            total: 0,
            isBusy: false,
            page: 1,
            last_page: 1,
            dataCourseGift: [],
            dialogCreateForm: false,
            form: {
                id: '',
                code_gift: '',
                name: '',
                center_id: '',
                course_ids: []
            },
            centers: [],
            courses: [],
            title: 'Thêm mới quà',
            isUpdate: false,
            dataExport: [],
            loading_export: false
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý quà theo khóa học"},
            {title: "Quản lý"}
        ]);
        this.getList();
        this.getCenters();
    },
    methods: {
        showModal()
        {
            this.form = {
                id: '',
                code_gift: '',
                name: '',
                center_id: '',
                course_ids: []
            };

            this.dialogCreateForm = true;
            this.title = 'Thêm mới quà';
            this.isUpdate = false;
        },
        getList() {
            this.$store.dispatch(GET_LIST_COURSE_GIFT, {page: this.page}).then((res) => {
                this.dataCourseGift = res.data.data;
                this.last_page = res.data.last_page;
                this.total = res.data.total;

            });
        },
        clickCallback(obj) {
            this.page = obj
            this.$router.push({
                name: 'list-course-gift',
                query: {
                    page: this.page,
                }
            })
            this.getList()
        },
        submit() {
            if(!this.isUpdate)
            {
                this.$store.dispatch(CREATE_COURSE_GIFT, this.form).then((res)=>{
                    if (res.data)
                    {
                        this.$notify({
                            title: 'Thành công',
                            message: 'Thêm mới quà tặng thành công',
                            type: 'success'
                        });
                        this.dialogCreateForm = false;
                        this.getList();
                        this.form = {
                            id: '',
                            code_gift: '',
                            name: '',
                            center_id: '',
                            course_ids: []
                        };
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                });
                return;
            }
            this.updateCourseGift();
        },
        cancelBook()
        {
            this.dialogCreateForm = false;
            this.form = {};
        },
        editCourseGift(item)
        {
            this.title = 'Sửa quà tặng';
            this.dialogCreateForm = true;
            this.isUpdate = true;
            this.handleCenter(item.center_id);
            this.form = {
                id: item.id,
                code_gift: item.code_gift,
                name: item.name,
                center_id: item.center_id,
                course_ids: _.map(item.course_gift_detail, 'course_id')
            };
        },
        handleCenter(center_id)
        {
            this.form.course_ids = [];
            this.$store.dispatch(GET_COURSES, {
                limit:10000,
                status: 1,
                center_id: center_id,
                all: 1
            }).then((res)=>{
                this.courses = res.data;
            });
        },
        getCenters() {
            this.$store.dispatch(GET_CENTERS, {
                limit: 10000,
                status: 1
            }).then((res) => {
                if (!res.error) {
                    this.centers = res.data.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        handleDataCourse(item)
        {
            const courseNames = item.map(detail => detail.course.name);
            let result;
            if (courseNames.length > 2) {
                result = `${courseNames.slice(0, 2).join(', ')} ...`;
            } else {
                result = courseNames.join(', ');
            }
            return result;
        },
        updateCourseGift()
        {

            this.$store.dispatch(UPDATE_COURSE_GIFT, this.form).then((res)=>{
                if (res.data)
                {
                    this.$notify({
                        title: 'Thành công',
                        message: 'Chỉnh sửa quà tặng thành công',
                        type: 'success'
                    });
                    this.dialogCreateForm = false;
                    this.getList();
                }
            }).catch((e) => {
                console.log(e.data.data.message_validate_form, 'e.data.data.message_validate_form')
                if (e.data.data.message_validate_form) {
                    this.$refs.form.setErrors(e.data.data.message_validate_form);
                }
            })
        },

    }
}
</script>

<style scoped>
    .bg-violet {
        background: #5D04B0;
    }
</style>