<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách tài khoản</h3>
      </div>
      <div class="card-title">
        <router-link
            class="font-weight-bold font-size-3  btn btn-success"
            :to="{ name: 'account-create' }"
        >Thêm mới
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row filter_form">
          <div class="form-group col-md-2">
            <div class="input-group">
              <div class="input-group-prepend">
																<span class="input-group-text line-height-0 py-0">
																	<span class="svg-icon">
																		<svg xmlns="http://www.w3.org/2000/svg"
                                         width="24px" height="24px"
                                         viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" stroke-width="1"
                                         fill="none" fill-rule="evenodd">
																				<rect x="0" y="0" width="24"
                                              height="24"></rect>
																				<path
                                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                            fill="#000000" fill-rule="nonzero"
                                            opacity="0.3"></path>
																				<path
                                            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                            fill="#000000"
                                            fill-rule="nonzero"></path>
																			</g>
																		</svg>
																	</span>
																</span>
              </div>
              <input type="text" class="form-control" placeholder="Tìm kiếm" v-model="query.keyword"
                     @input="search"
                     aria-describedby="basic-addon2">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <el-select v-model="query.branch_id" clearable filterable class="w-100" placeholder="Chọn văn phòng">
                <el-option
                    v-for="(item, key) in headquarters"
                    :label="item.name_alias ? item.name_alias : item.name"
                    :value="item.id"
                    :key="key"
                >
                  <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <el-select v-model="query.account_type_id" clearable filterable class="w-100" placeholder="Chọn chức vụ">
                <el-option
                    v-for="(item, key) in accountType"
                    :label="item.name"
                    :value="item.id"
                    :key="key"
                >
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <el-select v-model="query.status" clearable filterable class="w-100" placeholder="Chọn trạng thái">
                <el-option
                    v-for="(item, key) in status_search"
                    :label="item.name"
                    :value="item.id"
                    :key="key"
                >
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <button class="btn btn-primary font-weight-bold mr-2"
                        :class="is_disable_search ? 'spinner spinner-white spinner-left' : ''"
                        @click="searchData()">
                  <i class="fa fa-search" v-if="!is_disable_search"></i> Tìm kiếm
                </button>
              </div>
            </div>
          </div>
        </div>
        <DoubleScrollbar class="example-preview table-responsive overflow-auto">
          <table class="table table-vertical-center table-hover">
            <thead>
            <tr>
              <th scope="col">Họ tên</th>
              <th scope="col">Mã nhân viên</th>
              <th scope="col">SĐT</th>
              <th scope="col">Email</th>
              <th scope="col">Chức vụ</th>
              <th scope="col">Kiêm nhiệm</th>
              <th scope="col">Mã cấp trên</th>
              <th scope="col">Văn phòng</th>
              <th scope="col" width="8%">Trạng thái</th>
              <th scope="col" class="min-w-200px">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in users.data" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ parseInt(item.id) + 100000 }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.email }}</td>
              <td>{{
                  item.profile != null && item.profile.account_type != null ?
                      item.profile.account_type.name : ''
                }}
              </td>
              <td v-if="item.user_positions !== undefined && item.user_positions.length"><span
                  v-for="(i , k) in item.user_positions"
                  :key="k"><span
                  class="badge badge-pill badge-success mt-1">{{ i.position }}</span></span>
              </td>
              <td v-else></td>
              <td>{{ item.parent_id ? parseInt(item.parent_id) + 100000 : '' }}</td>
              <td>{{ getChiNhanh(item) }}
              </td>
              <td>
                <span class="badge"
                   v-bind:class="item.status === 1 ? classSpan.success : classSpan.danger">
                  {{ item.status === 1 ? "Hoạt động" : 'Nghỉ việc' }}
                </span>
              </td>
              <td>
                <router-link :to="{ name: 'account-edit', params: {id : item.id} }" title="Sửa"
                             class="btn btn-icon mr-2 btn-outline-info"><i class="fas fa-pen-nib"></i>
                </router-link>
<!--                <a title="Xóa" @click="deleteUser(item.id)" href="javascript:"-->
<!--                   class="btn btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>-->
                <router-link :to="{ name: 'user-log-histories', params: {id : item.id} }"
                             title="Xem Lịch sử đăng nhập"
                             class="btn btn-icon btn-outline-info"><i class="far fa-eye"></i>
                </router-link>
                <router-link :to="{ name: 'user-log-action-histories', params: {id : item.id} }"
                             title="Xem Lịch sử hoạt động"
                             class="btn btn-icon ml-2 btn-outline-success"><i
                    class="fas fa-history"></i>
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>
        </DoubleScrollbar>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="2"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :page-class="'page-item'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {GET_ACCOUNT_TYPE, GET_POSITIONS} from "../../../core/services/store/user/account-type.module";
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {
  ACTIVE_USER,
  DELETE_USER,
  DISMISS_USER,
  EXPORT_CUSTOMERS,
  GET_USERS,
} from "../../../core/services/store/user/users.module";
import Multiselect from 'vue-multiselect'
import FileUpload from 'vue-upload-component';
import DoubleScrollbar from '@/view/content/DoubleScrollbar';
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import moment from 'moment-timezone';

const _ = require('lodash');

export default {
  components: {
    Multiselect, FileUpload, DoubleScrollbar
  },
  name: "UserIndex",
  data() {
    return {
      query: {
        keyword: '',
        positions: '',
        status: '',
        branch_id: '',
        account_type_id: ''
      },
      users: [],
      keyword: '',
      // Paginate
      page: 1,
      last_page: 1,
      accountType: [],
      status: {
        0: 'Không hoạt động',
        1: 'Hoạt động',
        2: "Nghỉ việc"
      },
      headquarters: [],
      status_search: [
        {
          id: 0,
          name: 'Không hoạt động'
        }, {
          id: 1,
          name: 'Hoạt động'
        }, {
          id: 2,
          name: 'Nghỉ việc'
        },

      ],
      classSpan: {
        success: 'badge-success',
        danger: 'badge-danger'
      },
      positions: [],
      // Cache
      cacheHeadquarters: [],
      cachePosition: [],
      cacheStatus: [],
      cacheAccountType: [],
      is_disable_search: false,
    }
  },
  watch: {
    positions() {
      if (this.query.position != undefined) {
        let filerCachePosition = this.positions.filter((value) => {
          if (this.query.position == value.id) {
            return value
          }
        })
        this.cachePosition = filerCachePosition
      }
    }
  },
  created() {
    this.getBranches();
    this.getAccountType();
    this.getPositions();
    this.getList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Tài khoản", route: 'account'},
      {title: "Danh sách tài khoản"}
    ]);
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    getAccountType() {
      this.$store.dispatch(GET_ACCOUNT_TYPE, {
        limit: 1000,
      }).then((res) => {
        if (!res.error) {
          this.accountType = res.data.data;
        }
      });
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        center_id: this.query.center_id
      }).then((res) => {
        if (!res.error) {
          this.headquarters = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getList() {
      this.pullParamsUrl();
      this.callApiGetList();
    },
    pushParamsUrl() {
      let pageParam = { page: this.page};
      let payload = {...this.query, ...pageParam};
      this.$router.push({
        name: 'account',
        query: payload
      })
    },
    pullParamsUrl() {
      this.query.keyword = this.$route.query.keyword;
      this.query.branch_id = this.$route.query.branch_id ? parseInt(this.$route.query.branch_id) : '';
      this.query.position = this.$route.query.position;
      this.query.status = this.$route.query.status ? parseInt(this.$route.query.status) : '';
      this.query.account_type_id = this.$route.query.account_type_id ? parseInt(this.$route.query.account_type_id) : '';
      this.query.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      this.query.area_id = this.$route.query.area_id ? parseInt(this.$route.query.area_id) : '';
    },
    search: _.debounce(function () {
      this.pushParamsUrl();
      this.page = 1
      this.callApiGetList()
    }, 200),
    clickCallback(obj) {
      this.page = obj
      this.$router.push({path: 'account', query: {...this.query, page: this.page}})
      this.callApiGetList()
    },
    searchData() {
      this.pushParamsUrl();
      this.callApiGetList();
    },
    callApiGetList() {
      this.is_disable_search = true;
      let pageParam = {
        page: this.page,
        type : 'account'
      };
      let payload = {...this.query, ...pageParam};
      this.$store.dispatch(GET_USERS, payload).then((res) => {
        this.users = res.data
        this.last_page = res.data.last_page;
      }).finally(() => {
        this.is_disable_search = false;
      });
    },
    getPositions() {
      this.$store.dispatch(GET_POSITIONS, {}).then((res) => {
        if (!res.error) {
          this.positions = res.data
        }
      });
    },
    deleteUser(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_USER, id).then((res) => {
            if (res.error) {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'danger',
                solid: true
              });
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'success',
                solid: true
              });
              this.getList()
            }
          });
        }
      });
    },
    getChiNhanh(item) {
      return item.profile?.branch ? (item.profile?.branch.name_alias ? item.profile?.branch.name_alias : item.profile?.branch.name) : '';
    },
    exportCustomers: function () {
      this.$store.dispatch(EXPORT_CUSTOMERS, {
        keyword: this.query.keyword,
        branch_id: this.query.branch_id,
        position: this.query.positions,
        status: this.query.status,
        is_staff: 1
      });
    },
    dismissOrActiveUser: function (user) {
      if (user.status === 1) {
        Swal.fire({
          title: "Bạn chắc chắn muốn cho " + user.name + " thôi việc?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Đồng ý",
          cancelButtonText: "Không",
          html: '<input id="swal-date" class="swal2-input" type="date" placeholder="Chọn ngày nghỉ việc">' +
              `<input id="swal-reason" class="swal2-input" type="text" placeholder="Lý do nghỉ việc" value="Out ${user.profile.account_type.name}" disabled>`,
          preConfirm() {
            if ($('#swal-date').val() == "") {
              Swal.showValidationMessage("Ngày nghỉ việc không được để trống");
            } else {
              return {date: $('#swal-date').val(), reason: $('#swal-reason').val()};
            }
          }
        }).then((result) => {
          if (result.isConfirmed) {
            let payload = {
              id: user.id,
              params: result.value
            }
            this.$store.dispatch(DISMISS_USER, payload).then((res) => {
              Swal.fire("Thành công", "", "success");
            }).catch((error) => {
              this.$notify({
                title: 'Thất bại',
                message: error.data.message,
                type: 'error'
              });
            });
          }
        })
      } else {
        Swal.fire({
          title: "Bạn chắc chắn muốn kích hoat " + user.name + " ?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Đồng ý",
          cancelButtonText: "Không",
        }).then((result) => {
          if (result.isConfirmed) {
            let payload = {
              id: user.id,
              params: ''
            }
            this.$store.dispatch(ACTIVE_USER, payload);
            Swal.fire("Thành công", "", "success");
          }
        });
      }
    },
  }
}
</script>
<style>
.fa-minus-circle:hover {
  color: red !important;

}

.input-group i {
  font-size: 0.9rem;
}

/*Css multiple select*/
.mx-datepicker {
  width: 100% !important;
}

.multiselect__tags {
  padding: 3px 40px 0 9px !important;
  min-height: 38px !important;
}

.multiselect {
  min-height: 38px !important;
}

.mx-input {
  min-height: 38px !important;
}

.multiselect__single {
  margin-top: 5px !important;
}
.dialog-active {
      width: 50%;
      margin: 0 auto;
}
.el-dialog__wrapper {
        overflow: hidden;
}

@media only screen and (max-width: 640px) {
  .dialog-active {
    width: 100%;
    margin: 0 auto;
  }
  .el-dialog {
    width: 95%;
  }
  }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
