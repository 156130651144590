<template>
    <div>
        <button v-b-modal="`modal-cskh-${index}`"
                class="btn btn-primary btn-sm mr-2">
            <i class="flaticon-paper-plane icon-sm"></i>Trả lời
        </button>
        <b-modal :centered="true" :scrollable="true" size="md" ref="modal-amount" hide-footer
                 :id="'modal-cskh-' + index" title="Chăm sóc khách hàng">
            <div class="card-body">
                <div class="example mb-10">
                    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                        <form class="form" @submit.prevent="handleSubmit(onsubmit)">
                            <div class="row">

                                <div class="form-group col-md-12">
                                    <div class="input-group">
                                        <ValidationProvider mode="lazy" name="Nội dung" rules="required"
                                                            v-slot="{ errors,classes }">
                                            <textarea class="form-control" id="exampleTextarea" rows="3"
                                                      v-model="cskh.content_reply_cskh"
                                                      placeholder="Nội dung" :class="classes"></textarea>
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty"
                                                     class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>

                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <ValidationProvider name="Trạng thái" rules="required"
                                                            v-slot="{ errors,classes }">
                                            <multiselect v-model="cskh.status_cskh" :options="status"
                                                         :searchable="false"
                                                         :close-on-select="true" :show-labels="false"
                                                         placeholder="Pick a value"
                                                         :class="classes"
                                            ></multiselect>

                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty"
                                                     class="fv-help-block">
                                                    {{
                                                    errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="d-flex justify-content-between">
                                        <button
                                                class=" btn btn-primary font-weight-bold mr-2">Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
            <!--            <div class="float-right">-->
            <!--                <button type="button" class="btn btn-secondary mr-2" @click="hideModal()"> Đóng</button>-->
            <!--            </div>-->
        </b-modal>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect'
    import {UPDATE_CUSTOMER_LOG} from "../../../../core/services/store/customer/customer-log.module";

    export default {
        components: {Multiselect},
        props: {
            item: Object,
            index: ''
        },
        data() {
            return {
                cskh: {
                    customer_id: this.item.customer_id,
                    type: this.item.type,
                    old_level: this.item.old_level,
                    new_level: this.item.new_level,
                    content: this.item.content,
                    log_type: this.item.log_type,
                    type_cskh: this.item.type_cskh,
                    content_cskh: this.item.content_cskh,
                    content_reply_cskh: this.item.content_reply_cskh,
                    status_cskh: this.item.status_cskh,
                    action: this.item.action,
                    user_cskh: this.item.user_cskh,
                    course_student_id: this.item.course_student_id,
                    lesson: this.item.lesson,
                    help_id: this.item.help_id,
                    user_id: this.item.user_id,
                    data: this.item.data,
                    reason: this.item.reason,
                    center_user_id: this.item.center_user_id,
                    ngay_hen: this.item.ngay_hen,
                    id : this.item.id
                },
                cacheStatus: [],
                status: ['Tiếp nhận', 'Đang xử lý', 'Đã xử lý xong', 'Hài lòng'],
            }
        },
        name: "ModalCSKH",
        methods: {

            hideModal() {
                this.$refs['modal-amount'].hide();
            },
            handleStatus($event) {
            },
            onsubmit() {
                this.$store.dispatch(UPDATE_CUSTOMER_LOG, this.cskh).then((res) => {
                    if (res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Chăm sóc khách hàng',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.$bvToast.toast(res.message, {
                            title: 'Chăm sóc khách hàng',
                            variant: 'success',
                            solid: true
                        });
                        this.updateSuccess()
                        this.hideModal()
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                });
            },
            updateSuccess() {
                this.$emit('updateSuccess');
            },
        }
    }
</script>

<style>

    /*Css multiple select*/
    .mx-datepicker {
        width: 100% !important;
    }

    .multiselect__tags {
        padding: 3px 40px 0 9px !important;
        min-height: 38px !important;
    }

    .multiselect {
        min-height: 38px !important;
    }

    .mx-input {
        min-height: 38px !important;
    }

    .multiselect__single {
        margin-top: 5px !important;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
