<template>
    <!--begin::Card-->
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" @submit.prevent="handleSubmit(onSubmit)" autocomplete="off">
            <div class="card card-custom">
                <!--begin::Header-->
                <div class="card-header">
                    <div class="card-title ">
                        <h3 class="card-label">
                            Tạo nhân viên
                        </h3>
                    </div>
                    <div class="card-toolbar">
                        <router-link
                            class="font-weight-bold font-size-3  btn btn-secondary"
                            :to="{ name: 'user-index' }"
                        ><i class="fas fa-angle-double-left"></i> Quay lại
                        </router-link>
                    </div>
                </div>
                <!--end::Header-->
                <div class="card-body">
                    <div class="row">
                        <h3 class="col-md-12 text-dark font-weight-bold my-2 mr-5">Thông tin cá nhân</h3>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Họ tên
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Họ tên" rules="required" v-slot="{ errors,classes }">
                                    <input type="text" ref="name" name="name" class="form-control" :class="classes"
                                           placeholder="Họ tên"
                                           v-model=user.name>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Email
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider vid="emails" name="Email đăng nhập" rules="required|email"
                                                    v-slot="{ errors,classes }">
                                    <input type="email" ref="email" class="form-control" placeholder="Email"
                                           :class="classes"
                                           v-model=user.email>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="Email đăng nhập" data-validator="notEmpty"
                                             class="fv-help-block">{{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Số điện thoại
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider vid="phone" name="Số điện thoại"
                                                    :rules="{ required: true, regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                                    v-slot="{ errors,classes }">

                                    <input type="number" v-model="user.phone" :class="classes" class="form-control"
                                           placeholder="Số điện thoại">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Giới tính
                                    <span class="text-danger">*</span></label>
                                <multiselect v-model="user.profile.gender" :options="gender"
                                             :searchable="true"
                                             :close-on-select="true"
                                             :show-labels="true" placeholder="Pick a value"
                                ></multiselect>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Ngày sinh
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider vid="profile.birthday" name="Ngày sinh" rules="required"
                                                    v-slot="{ errors,classes }">
                                    <date-picker v-model="user.profile.birthday" format="DD-MM-YYYY"
                                                 placeholder="Chọn ngày sinh"
                                                 valueType="YYYY-MM-DD" :class="classes"></date-picker>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Địa chỉ
                                    <span class="text-danger"></span></label>
                                <textarea placeholder="Nhập địa chỉ" class="form-control" rows="3"
                                          v-model="user.profile.address"></textarea>
                            </div>
                        </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Cơ sở làm việc</label>
                          <el-select
                              multiple
                              filterable
                              v-model="user.profile.work_branch_id"
                              placeholder="Chọn cơ sở làm việc"
                              clearable
                          >
                            <el-option v-for="(item, index) in allBranchOffice"
                                       :key="index"
                                       :label="item.name"
                                       :value="item.id"
                            >
                              <span>{{ item.name }}</span>
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>
                                    <span class="text-danger"></span></label>
                                <div class="col-9 col-form-label">
                                    <div class="checkbox-inline">
                                        <label class="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5"
                                                   v-model="user.profile.status_verify">
                                            <span></span>Xác minh danh tính</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-for="(item, index) in data" :key="index">
                        <user-item
                            @checkCaption="checkCaption"
                            @removeForm="removeForm"
                            :data="item"
                            :index="index"
                        >
                        </user-item>
                        <div class="col-md-12 text-danger m-2 mb-4">{{ messageDuplicate }}</div>
                    </div>
                    <div class="col-md-12 p-0 mb-4">
                        <button @click="addTotalForm" class="btn btn-success mr-2 btn-sm">Thêm công việc</button>
                    </div>
                    <!--            Thông tin Tài khoản ngân hàng-->
                    <div class="row">
                        <h3 class="col-md-12 text-dark font-weight-bold my-2 mr-5">Thông tin Tài khoản ngân hàng</h3>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Chủ tài khoản</label>
                                <input type="text" ref="name" name="name" class="form-control"
                                       placeholder="Chủ tài khoản"
                                       v-model="user.bank_accounts.owner_name" @input="formatInput">
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Chọn ngân hàng</label>
                                <el-select v-model="user.bank_accounts.ngan_hang" @change="changeBank">
                                    <el-option v-for="(item, index) in banks"
                                               :key="index"
                                               :label="item.name" :value="item.id"
                                    >
                                        <span>{{ item.name }}</span>
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-md-4 " v-if="hideProvince">
                            <div class="form-group">
                                <label>Tình thành</label>
                                <el-select v-model="user.bank_accounts.province" @change="changeProvince">
                                    <el-option v-for="(item, index) in provinces"
                                               :key="index"
                                               :label="item" :value="item"
                                    >
                                        <span>{{ item }}</span>
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-md-4 " v-if="hideProvince">
                            <div class="form-group">
                                <label>Chi nhánh</label>
                                <div>
                                    <el-select v-model="user.bank_accounts.chi_nhanh">
                                        <el-option v-for="(item, index) in branchs"
                                                   :key="index"
                                                   :label="item"
                                                   :value="item"
                                        >
                                            <span>{{ item }}</span>
                                        </el-option>
                                    </el-select>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Số tài khoản</label>
                                <input type="text" ref="name" name="name" class="form-control"
                                       placeholder="Số tài khoản"
                                       v-model=user.bank_accounts.stk>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <h3 class="col-md-12 text-dark font-weight-bold my-2 mr-5">Thông tin tài khoản</h3>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Email đăng nhập</label>
                                <ValidationProvider vid="email" name="Email đăng nhập" rules="required|email"
                                                    v-slot="{ errors,classes }">
                                    <input type="email" disabled class="form-control" placeholder="Email"
                                           :class="classes"
                                           v-model=user.email>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="Email đăng nhập" data-validator="notEmpty"
                                             class="fv-help-block">{{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Mật khẩu<span class="text-danger">*</span></label>
                                <ValidationProvider name="Mật khẩu" rules="required|min:6" v-slot="{ errors,classes }">
                                    <input type="password" class="form-control" placeholder="Mật khẩu"
                                           v-model="user.password" :class="classes" autocomplete="new-password">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Nhập lại mật khẩu
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Xác nhận mật khẩu" rules="required|min:6|confirmed:Mật khẩu"
                                                    v-slot="{ errors,classes }">
                                    <input name="password_confirmation" type="password" class="form-control"
                                           placeholder="Xác thực mật khẩu" v-model="user.confirm_password"
                                           :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Phân quyền trang admin
                                    <span class="text-danger"></span></label>
                                <el-select v-model="user.backend_role_id" clearable filterable
                                           class="w-100" placeholder="Chọn quyền"
                                           ref="backend_role_id">
                                    <el-option
                                        v-for="(item, key) in backend_roles"
                                        :label="item.name"
                                        :value="item.id"
                                        :key="key"
                                    >
                                        <span style="float: left">{{ item.name }}</span>
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Telegram ID
                                    <span class="text-danger"></span></label>
                                <input type="number" name="name" class="form-control"
                                       placeholder="Telegram ID"
                                       v-model=user.profile.telegram_id>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-header">
                    <div class="card-title align-items-start flex-column">
                        <button type="submit" class="btn btn-success mr-2" :disabled="is_disable">
                            Tạo mới
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
    <!--end::Card-->
</template>
<script>
import {mapGetters} from "vuex";
import {provinces} from "@/assets/js/components/provinces";
import {banks} from "@/assets/js/components/banks";
import {branchs} from "@/assets/js/components/branchs";
import {CREATE_STAFF,} from "@/core/services/store/user/users.module";
import Multiselect from 'vue-multiselect'
import BankAccount from "./BankAccount";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {GET_ACCOUNT_TYPE, GET_POSITIONS, GET_ROLES,} from "@/core/services/store/user/account-type.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_AREAS} from "@/core/services/store/center/area.module";
import {
    BOD,
    GDKV,
    GDVP,
    HANH_CHINH_KHU_VUC,
    SALE_EXPERT,
    SALE_INTERN,
    SALE_LEADER,
    SALE_MEMBER,
    STAFF,
    TEAMLEADER
} from "@/core/option/accountTypeOption";
import {BLOCK, GET_DEPARTMENT, GET_ROLE_DEPARTMENT_BY_TYPE} from "@/core/services/store/department/department.module";
import {DkD, GET_ROLE_DEPARTMENT_BY_BLOCK_ID} from "@/core/services/store/department/department.module";
import UserItem from "./Component/UserItem";
import {GET_ALL_BRANCHES, GET_BRANCHES} from "@/core/services/store/center/branch.module";

export default {
    name: "UserCreate",
    components: {
        BankAccount,
        Multiselect,
        DatePicker,
        UserItem
    },
    data() {
        return {
            search_loading: false,
            users: [],
            user: {
                department_id: '',
                block_id: '',
                part_id: '',
                uuid: '',
                name: '',
                phone: "",
                email: "",
                email_verified_at: "",
                status: true,
                parent_id: "",
                remember_token: "",
                password: "",
                confirm_password: "",
                // positions: [],
                profile: {
                    uuid: "",
                    ma_gioi_thieu: "",
                    gioithieu_id: "",
                    gender: 'Nam',
                    address: "",
                    birthday: "",
                    start_to_work: "",
                    loai_tai_khoan: "",
                    chi_nhanh_id: "",
                    van_phong_id: "",
                    xac_thuc_sdt: "",
                    thoi_gian_nghi_viec: "",
                    account_type_id: '',
                    khuvuc_id: "",
                    business_level_id: '',
                    salary_mechanism_id: '',
                    status_verify: true,
                    work_branch_id: []
                },
                bank_accounts: {
                    owner_name: "",
                    ngan_hang: "",
                    province: "",
                    chi_nhanh: "",
                    stk: "",
                    enable_branch_bank: false,
                    branch_affter_select_province: [],
                    is_default: true
                },
                backend_role_id: ''
            },
            /*Data default*/
            keyword_search_boss_code: '',
            status: {
                0: 'Không hoạt động',
                1: 'Đang hoạt động',
                2: "Nghỉ việc"
            },
            gender: [
                'Nam', 'Nữ', 'Khác'
            ],
            boss_type_account: {
                1: 'Sale Leader',
                2: 'Sale Member'
            },
            // positions: [],
            provinces: [],
            banks: [],
            maCapTren: [],
            allBranchOffice: [],
            branchs: {}, // Branch này của tài khoản ngân hàng
            accountType: [],
            backend_roles: [],
            /*Cache */
            cachePosition: [],
            components: [
                'Autocompletes', 'Comboboxes', 'Forms', 'Inputs', 'Overflow Buttons', 'Selects', 'Selection Controls', 'Sliders', 'Textareas', 'Text Fields',
            ],
            // Check show
            showKhuvuc: false,
            showSelectBoss: false,
            is_show_branch: false,
            showTrungTam: false,
            is_disable: false,
            businessLevels: [],
            is_show_mechanism: false,
            totalForm: 1,
            formData: [],
            data: [
                {
                    block_id: '',
                    cachePosition: [],
                    salary_mechanism_id: '',
                    khuvuc_id: '',
                    chi_nhanh_id: '',
                    department_id: '',
                    part_id: '',
                    account_type_id: '',
                    parent_id: '',
                    start_to_work: '',
                    captain: false
                }
            ],
            array: [],
            messageDuplicate: '',
            hideProvince: false
        };
    },
    mounted() {
        this.getRoles();
        this.getAreas();
        this.setDefault();
        this.getBranchOffice();
    },
    computed: {
        ...mapGetters(["currentUserAccountInfo", 'roles'])
    },

    methods: {
        setDefault() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý nhân sự", route: 'user-index'},
                {title: "Tạo nhân viên"}
            ]);
            this.provinces = provinces
            this.banks = banks
            this.branchs = branchs
        },
        getRoles() {
            this.$store.dispatch(GET_ROLES, {
                limit: 1000
            }).then((res) => {
                this.backend_roles = res.data.data.filter(value => value.guard_name === 'backend');
            });
        },
        getAreas() {
            this.$store.dispatch(GET_AREAS, {
                limit: 10000,
                status: 1,
            }).then((res) => {
                if (!res.error) {
                    this.areas = res.data.data
                }
            });
        },
        getBranchOffice(){
          this.$store.dispatch(GET_BRANCHES, {
            limit: 1000,
            is_office: 1
          }).then(res => {
            if (!res.error) {
             this.allBranchOffice = res.data.data;
            }
          })
        },
        getAccountType(block_id) {
            this.$store.dispatch(GET_ACCOUNT_TYPE, {
                type_department: block_id === DkD ? 2 : 1,
                limit: 1000
            }).then((res) => {
                if (!res.error) {
                    this.accountType = res.data.data
                }
            });
        },
        getPositions() {
            this.$store.dispatch(GET_POSITIONS, {}).then((res) => {
                if (!res.error) {
                    this.positions = res.data
                }
            });
        },
        handleAccountType(id) {
            this.updateShowSelectBoss(id)
            this.checkShowCenterAndBranch(id)
            this.checkShowBusinessLevel(id);
            this.checkShowMechanism();
        },
        checkShowBusinessLevel(id) {
            this.checkShow = ![BOD, STAFF, TEAMLEADER].includes(id);
            this.getBusinessLevels();
        },
        checkShowArea(id) {
            if ([GDVP, GDKV, HANH_CHINH_KHU_VUC, SALE_LEADER, SALE_MEMBER, SALE_INTERN, SALE_EXPERT].includes(id)) {
                this.showKhuvuc = true;
                return
            }
            this.showKhuvuc = false;
        },
        checkDuplicateDepartment() {
            var values = this.data;
            var block_id = values.map(function (item) {
                return item.block_id
            });
            var department_id = values.map(function (item) {
                return item.department_id
            });
            var part_id = values.map(function (item) {
                return item.part_id
            });

            var isDuplicateBlockId = block_id.some(function (item, idx) {
                return block_id.indexOf(item) !== idx
            });
            var isDuplicateDepartmentId = department_id.some(function (item, idx) {
                return department_id.indexOf(item) !== idx && part_id.indexOf(item) !== '' && idx !== ''
            });
            var isDuplicatePartId = part_id.some(function (item, idx) {
                return part_id.indexOf(item) !== idx && part_id.indexOf(item) !== '' && idx !== ''
            });

            return [isDuplicateBlockId, isDuplicateDepartmentId, isDuplicatePartId].includes(true);
        },
        onSubmit() {
            this.messageDuplicate = '';
            if (this.checkDuplicateDepartment() && Object.values(this.data).length > 1) {
                this.messageDuplicate = 'Thông tin bị trùng lặp phòng ban !';
                return;
            }
            this.message = '';
            this.is_disable = true;
            this.user.user_profile_account_type = this.data;
            this.convertDataDepartment();
            if (Object.values(this.data.filter((d) => d.captain === true)).length === 0) {
                this.$bvToast.toast('Bạn chưa chọn công việc chính', {
                    title: 'Tạo nhân viên',
                    variant: 'danger',
                    solid: true
                });
                this.is_disable = false;
                return;
            }

            this.$store
                .dispatch(CREATE_STAFF, this.user)
                .then((res) => {
                    this.$router.push({name: "user-index"}).then(() => {
                        this.$bvToast.toast(res.message, {
                            title: 'Tạo nhân viên',
                            variant: 'success',
                            solid: true
                        });
                    })
                }).catch((e) => {
                if (e.data?.data?.message_validate_form) {
                    this.$refs.form.setErrors(e.data.data.message_validate_form);
                }
                if (e.data?.message) {
                    this.$bvToast.toast('Tạo nhân viên thất bại', {
                        title: 'Tạo nhân viên',
                        variant: 'danger',
                        solid: true
                    });
                }
            }).finally(() => {
                this.is_disable = false;
            });
        },
        addTotalForm() {
            this.data.push({
                block_id: '',
                cachePosition: [],
                salary_mechanism_id: '',
                khuvuc_id: '',
                chi_nhanh_id: '',
                department_id: '',
                part_id: '',
                account_type_id: '',
                parent_id: '',
                start_to_work: '',
                captain: false
            })
        },
        getListDepartment(block_id) {
            this.show = true;
            this.user.department_id = '';
            this.user.part_id = '';
            this.user.profile.khuvuc_id = '';
            this.user.profile.chi_nhanh_id = '';
            this.user.parent_id = '';
            this.user.profile.account_type_id = '';
            this.user.profile.start_to_work = '';
            if (block_id != DkD) {
                this.checkShowDepartment = true;
            } else {
                this.checkShowDepartment = false;
            }
            this.$store.dispatch(GET_DEPARTMENT, {parent_id: block_id}).then((res) => {
                if (!res.error) {
                    this.departments = res.data
                    this.getAccountType(block_id);
                    this.getListPart(block_id);
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getDepartmentByBlockId(block_id) {
            this.$store.dispatch(GET_ROLE_DEPARTMENT_BY_BLOCK_ID, block_id).then((res) => {
                if (!res.error) {
                    this.accountType = res.data
                    this.checkShowDepartment = true;
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getListPart(department_id) {
            this.$store.dispatch(GET_DEPARTMENT, {parent_id: department_id, type_block_part: true}).then((res) => {
                if (!res.error) {
                    this.part = res.data
                    if (department_id != 20) {
                        this.checkShowDepartment = true;
                    }
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getBlock() {
            this.$store.dispatch(GET_ROLE_DEPARTMENT_BY_TYPE, {type: BLOCK}).then((res) => {
                if (!res.error) {
                    this.listBlock = res.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        checkCaption(even_index, value_cap) {
            let items = this.data.filter((value) => value != value_cap && value.captain == true);
            if (items) {
                items.forEach((d) => {
                    d.captain = false;
                });
            }
        },
        removeForm(index) {
            const array = this.data;
            if (Object.values(this.data).length == 1) {
                this.$bvToast.toast('Không thể bỏ thông tin nhân viên', {
                    title: 'Không thể bỏ thông tin nhân viên',
                    variant: 'danger',
                    solid: true
                });
                return;
            }

            if (index > -1) {
                array.splice(index, 1);
            }
        },
        convertDataDepartment() {
            this.data.forEach((d) => {
                d.department_id_prop = d.parent_id ? d.parent_id : (d.department_id ? d.department_id : (d.block_id ? d.block_id : ''));
            });
        },
        changeBank() {
            this.hideProvince = false;
            if (this.user.bank_accounts.ngan_hang === 'Agribank') {
                this.hideProvince = true;
                this.user.bank_accounts.province = '';
                this.user.bank_accounts.chi_nhanh = '';
                this.branchs = {};
            }
        },
        changeProvince() {
            this.branchs = branchs[this.user.bank_accounts.province];
            this.user.bank_accounts.chi_nhanh = '';
        },
        removeAccents(str) {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        },
        formatInput() {
            // Chuyển đổi chữ thường sang chữ hoa và loại bỏ dấu
            this.user.bank_accounts.owner_name = this.removeAccents(this.user.bank_accounts.owner_name.toUpperCase());
        }
    },
};
</script>F
<style scoped>
.mx-datepicker {
    width: 100% !important;
}

.add-bank-account:hover i, .add-bank-account:hover .text-muted {
    color: #1BC5BD !important;
}

.el-select {
    width: 100%;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

