import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "../auth.module";
import {exportData} from "@/core/services/common.service";
const _ = require('lodash');

export const GET_ERROR_LIST = "report-error-list";
export const GET_TOTAL_ERROR = "total";
export const GET_ERROR_RESPONSE_HANDLING_LIST = "report-error-response-handling-list";
export const GET_TOTAL_ERROR_RESPONSE_HANDLING = "total-error-response-handling";
export const EXPORT_ERROR_LIST = "export-error"
export const EXPORT_ERROR_LIST_FEEDBACK = "export-error-feedback"
export const EXPORT_ERROR_LIST_V2 = "export-error-v2"
export const EXPORT_ERROR_LIST_FEEDBACK_V2 = "export-error-feedback-v2"
export const GET_TYPE_ERROR = "get-list-error-by-process"
export const GET_LIST_ERROR = "get-list-error"
export const DELETE_ERROR = "delete-error"
export const EDIT_ERROR = "edit-error"

export const GET_LIST_TYPE_ERROR = "/error-type-list"
export const ADD_NEW_ERROR = "/add-error-class"
const state = {
    errors: null
};

const getters = {};

const actions = {
    [GET_ERROR_LIST](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/error-list", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },
    [GET_TOTAL_ERROR](context, credentials) {
        return new Promise((resolve) => {
            ApiService.post(GET_TOTAL_ERROR, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },
    
    [GET_ERROR_RESPONSE_HANDLING_LIST](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/error-list-response-handling", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },
    [GET_TOTAL_ERROR_RESPONSE_HANDLING](context, credentials) {
        return new Promise((resolve) => {
            ApiService.post(GET_TOTAL_ERROR_RESPONSE_HANDLING, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },
    [EXPORT_ERROR_LIST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_ERROR_LIST, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [EXPORT_ERROR_LIST_FEEDBACK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_ERROR_LIST_FEEDBACK, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_ERROR_LIST_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_ERROR_LIST_V2, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [EXPORT_ERROR_LIST_FEEDBACK_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_ERROR_LIST_FEEDBACK_V2, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_LIST_TYPE_ERROR](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query(GET_LIST_TYPE_ERROR, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },
    [ADD_NEW_ERROR](context, credentials) {
        return new Promise((resolve) => {
            ApiService.post(ADD_NEW_ERROR, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },
    [GET_LIST_ERROR](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query(GET_LIST_ERROR, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },

    [DELETE_ERROR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(DELETE_ERROR, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },

    [EDIT_ERROR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(EDIT_ERROR, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
}

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
