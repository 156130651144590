import { render, staticRenderFns } from "./TesterWeeklyTask.vue?vue&type=template&id=8548a37c&scoped=true"
import script from "./TesterWeeklyTask.vue?vue&type=script&lang=js"
export * from "./TesterWeeklyTask.vue?vue&type=script&lang=js"
import style0 from "./TesterWeeklyTask.vue?vue&type=style&index=0&id=8548a37c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8548a37c",
  null
  
)

export default component.exports