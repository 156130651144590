export const STATUS_TOURNAMENT = new Map([
    [0, 'Chưa bắt đầu'],
    [1, 'Đang diễn ra'],
    [2, 'Kết thúc']
]);
export const CHUA_BAT_DAU = 0;
export const DANG_DIEN_RA = 1;
export const KET_THUC = 2;
export const CSS_STATUS_TOURNAMENT = new Map([
    [0, 'badge badge-warning'],
    [1, 'badge badge-info'],
    [2, 'badge badge-secondary']
]);
export const CAN_PHE_DUYET = 0
export const DA_PHE_DUYET = 1
export const DA_TU_CHOI = 2
export const CHUA_BAT_DAU_MATCH = 0;
export const DANG_BAT_DAU_MATCH = 1
export const DA_KET_THUC_MATCH = 2;


export const TYPE_LIST_TEAM_JOIN = 0;
export const TYPE_LIST_SCHEDULE = 1;
export const TYPE_RESULT_MATCH = 2;
export const TYPE_CLOSE = 3; // Bế mạc
export const TYPE_OTHER = 4;


export const STATUS_MATCH = [
    {
        name: 'Chưa thi đấu',
        value: CHUA_BAT_DAU_MATCH
    },
    {
        name: 'Đang thi đấu',
        value: DANG_BAT_DAU_MATCH
    },
    {
        name: 'Đã thi đấu',
        value: DA_KET_THUC_MATCH
    },
]

export const TYPE_NEWS = [
    {
        name: 'Danh sách đội tham gia',
        id: TYPE_LIST_TEAM_JOIN
    },
    {
        name: 'Danh sách lịch thi đấu',
        id: TYPE_LIST_SCHEDULE
    },
    {
        name: 'Danh sách kết quả trận đấu',
        id: TYPE_RESULT_MATCH
    },
    {
        name: 'Bế mạc',
        id: TYPE_CLOSE
    },
    {
        name: 'Khác',
        id: TYPE_OTHER
    }
]

export const TYPE_NEWS_DEFINE = {
    0: 'Danh sách đội tham gia',
    1: 'Danh sách lịch thi đấu',
    2: 'Danh sách kết quả trận đấu',
    3: 'Bế mạc',
    4: 'Khác'
}
