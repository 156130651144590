<template>
  <div>
    <div v-if="vouchers.length > 0">
      <div class="row mt-5"  v-for="(voucher,index) in vouchers" :key="index+colorCode"
           :style="{ border: `solid 1px ${colorCode}` }">
        <div class="col-md-3">
          <div style="width:95px ; height:100%; position: relative; "
               class="  d-flex align-items-center justify-content-center"
               :style="{ border: `solid 1px ${colorCode}`, backgroundColor : `${colorCode}`}"
          >
            <div class="mt-4 " v-if="voucher.type == 0" style="width: fit-content; text-align: center !important;">
              <img alt="" src="../../../../assets/img/imgVoucher.png">
              <p class="mt-2" style="color: white ; font-size: 13px">Voucher</p>
            </div>
            <div v-else class="mt-4 text-center " style="width: fit-content ; text-align: center !important;">
              <img alt="" src="../../../../assets/img/imgGift.png">
              <p class="mt-2" style="color: white ; font-size: 13px">Quà tặng</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-5">
          <div >
            <span style="font-weight: bold">{{voucher.name}}</span>
          </div>
          <div style="width: fit-content;font-size: 12px;padding: 5px;border-radius: 4px;color: white" class="mt-2"
               :style="{ backgroundColor : `${colorCode}`}" v-if="+voucher.value > 0">
            <span v-if="voucher.unit === 1 ">Giảm: {{ voucher.value | toThousandFilter }} ₫</span>
            <span v-else>Giảm: {{ voucher.value }} %</span>
          </div>
          <div class="mt-2">
            <img alt="" src="../../../../assets/img/imgTime.png">
            <span style="position: relative;top: 2px;left: 5px">Hiệu lực đến: {{voucher.end_date}}</span>
          </div>
          <div class="mt-2">
            <a href="javascript:" @click="showVoucherDetail(voucher)"><strong>Xem chi tiết</strong></a>
          </div>
        </div>
        <div class="col-md-3 mt-5" v-if="voucher.type == isVoucher">
          <button v-if="!voucher.selected" type="button" class="btn btn-outline-primary"
          :class="{'btn-outline-secondary':voucher.hidden}" :disabled="voucher.hidden" @click="applyVoucher(voucher)"
                  >
            Áp dụng
          </button>
          <button v-if="voucher.selected" type="button"  class="btn btn-danger" @click="cancelVoucher(voucher)">Hủy áp
            dụng
          </button>
        </div>
        <div class="col-md-3 mt-5" v-else>
          <button v-if="!voucher.selected" type="button" class="btn btn-primary"
                  :class="{'btn-secondary':voucher.hidden}" :disabled="voucher.hidden"
                  @click="applyVoucher(voucher)">Áp dụng
          </button>
          <button  v-if="!voucher.selected && +voucher.value > 0" type="button" class="btn btn-outline-primary my-3"
                   :class="{'btn-outline-secondary':voucher.hidden}" :disabled="voucher.hidden"
                   @click="changeVoucher(voucher)"
                   >Quy đổi voucher
          </button>
          <button v-if="voucher.selected" type="button" @click="cancelVoucher(voucher)" class="btn btn-danger">Hủy áp
            dụng
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="text-center w-100 center_important">
          <span class="text-danger">Không có voucher
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DIGITAL, FLASH_SALE, PERIODIC_PROMOTIONS, SCHOOL_LINK} from "@/core/option/contractOption";
import {OTHER_SCHOOL} from "../../../../core/option/contractOption";

export default {
  name: "ListPromotions",
  props : {
    vouchers: {
      type: Array,
      default: () => { return [] }
    },
    id_voucher_disable: {
      type: Array,
      default: () => { return [] }
    },
    colorCode: {
      type: String,
      default() {
        return '';
      }
    },
    allCode: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: Number,
      default() {
        return null
      }
    }

  },
  data(){
    return {
      SCHOOL_LINK:SCHOOL_LINK,
      FLASH_SALE:FLASH_SALE,
      PERIODIC_PROMOTIONS:PERIODIC_PROMOTIONS,
      isGift:3,
      isVoucher:0
    }
  },
  methods: {
    showVoucherDetail(voucher) {
      this.$emit('showVoucherDetail', voucher);
    },
    pickPromotions(voucher,isCancel = false, isUseGift = true) {
      if (+this.type === PERIODIC_PROMOTIONS||+this.type === DIGITAL) {
        this.allCode[this.type].forEach((code) => {
            code.selected = !code.selected
          code.isUseGift = code.type == this.isGift ? isUseGift : false
        })
      } else {
          voucher.selected = !voucher.selected
        voucher.isUseGift = voucher.type == this.isGift ? isUseGift : false
      }
      let selectedAmount = this.allCode[this.type].filter((e)=>e.selected).length
      for (let key in this.allCode) {
          // if(+this.type === SCHOOL_LINK){
            this.allCode[key].forEach((code) => {
              if(!isCancel){
                code.hidden = true
              }
              if(selectedAmount === 0 && isCancel){
                code.hidden = false
              }
            })
          // }
        if (+key !== +this.type) {
          this.allCode[key].forEach((code) => {
            if(!isCancel){
              code.hidden = true
            }
            if(selectedAmount === 0 && isCancel){
              code.hidden = false
            }
          })
        }
      }
      this.$emit('setListVoucherForContract');
    },

    applyVoucher(voucher, isUseGift = true) {
      for (let key in this.allCode) {
        this.allCode[key].forEach((code) => {
          code.hidden = true
        });
      }

      if (this.type === PERIODIC_PROMOTIONS || this.type === DIGITAL) {
        this.allCode[this.type].forEach((code) => {
          code.selected = true
          code.isUseGift = code.type == this.isGift ? isUseGift : false
        });

      } else {
        voucher.selected = !voucher.selected
        voucher.isUseGift = voucher.type == this.isGift ? isUseGift : false
      }
      this.$emit('setListVoucherForContract');

      if (this.type == OTHER_SCHOOL || this.type == FLASH_SALE) {
          this.allCode[this.type].forEach((code) => {
            code.hidden = false
          });
      }
    },
    changeVoucher(voucher) {
      if (voucher.typeCode !== PERIODIC_PROMOTIONS && voucher.typeCode !== DIGITAL) {
        voucher.selected = true;
        voucher.isUseGift = false;
        voucher.hidden = false;
        for (let key in this.allCode) {
          this.allCode[key].forEach((code) => {
            code.hidden = true
          });
        }
        if (voucher.typeCode == OTHER_SCHOOL || voucher.typeCode == FLASH_SALE) {
          this.allCode[voucher.typeCode].forEach((code) => {
            code.hidden = false
          });
        }
        this.$emit('setListVoucherForContract');
        return;
      }

        this.allCode[this.type].forEach((code) => {
          code.selected = true
          code.isUseGift = false
        });
        for (let key in this.allCode) {
          this.allCode[key].forEach((code) => {
            code.hidden = true
          });
        }
        this.$emit('setListVoucherForContract');

    },
    cancelVoucher(voucher) {
      voucher.hidden = false;
      voucher.selected = false

      let selectedAmount = this.allCode[this.type].filter((e)=>e.selected).length

      if (selectedAmount == 0 || (voucher.typeCode != OTHER_SCHOOL && voucher.typeCode != FLASH_SALE)) {
        for (let key in this.allCode) {
          this.allCode[key].forEach((code) => {
            code.hidden = false;
            code.selected = false
            code.isUseGift = code.type == this.isGift ? true : false
          });
        }
      }

      this.$emit('setListVoucherForContract');
    }
  }
}
</script>

<style scoped>

</style>
