<template>
    <div>
        <div class="card card-custom gutter-b">
            <div class="card-header d-flex justify-content-between">
                <div class="card-title">
                    <h3 class="card-label">Danh sách học viên</h3>
                </div>
                <div class="card-title">
                    <router-link
                            class="font-weight-bold font-size-3  btn btn-success"
                            :to="{ name: 'customer-create' }"
                    >Thêm mới
                    </router-link>
                </div>
            </div>
            <div class="card-body">
                <!--begin::Example-->
                <div class="example mb-10">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-prepend">
																<span class="input-group-text line-height-0 py-0">
																	<span class="svg-icon">
																		<!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Search.svg-->
																		<svg xmlns="http://www.w3.org/2000/svg"
                                                                             xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                             width="24px" height="24px"
                                                                             viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" stroke-width="1"
                                                                               fill="none" fill-rule="evenodd">
																				<rect x="0" y="0" width="24"
                                                                                      height="24"></rect>
																				<path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                                                      fill="#000000" fill-rule="nonzero"
                                                                                      opacity="0.3"></path>
																				<path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                                                      fill="#000000"
                                                                                      fill-rule="nonzero"></path>
																			</g>
																		</svg>
                                                                        <!--end::Svg Icon-->
																	</span>
																</span>
                            </div>
                            <input type="text" class="form-control" placeholder="Nhập mã, tên, số điện thoại hoặc email "
                                   v-on:input="searchUsers"
                                   v-model="query.keyword"
                                   aria-describedby="basic-addon2">
                        </div>
                    </div>
                    <div class="example-preview table-responsive">
                        <table class="table table-vertical-center">
                            <thead>
                            <tr>
                                <th scope="col">No</th>
                                <th scope="col">Mã học viên</th>
                                <th scope="col">Tên</th>
                                <th scope="col">Email</th>
                                <th scope="col">Số điện thoại</th>
                                <th scope="col" class="min-w-100px">Hành động</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in getAllUsers" :key="index">
                                <th scope="row">{{++index}}</th>
                                <td class="">{{parseInt(item.id) + 100000}}</td>
                                <td class="">{{item.name}}</td>
                                <td class="">{{item.email}}</td>
                                <td class="">{{item.phone}}</td>
                                <td class="">
                                    <router-link :to="{ name: 'user-update', params: {id : item.id} }" title="Sửa"
                                                 class="btn btn-sm btn-icon mr-2 btn-outline-info"><i class="fas fa-pen-nib"></i>
                                    </router-link>
                                    <a title="Xóa" @click="deleteUser(item.id)" href="javascript:"
                                       class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="edu-paginate mx-auto text-center">
                <paginate
                        v-model="query.page"
                        :page-count="total_pages"
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="clickCallback"
                        :prev-text="'Trước'"
                        :next-text="'Sau'"
                        :container-class="'pagination b-pagination'"
                        :pageLinkClass="'page-link'"
                        :page-class="'page-item'"
                        :next-link-class="'next-link-item'"
                        :prev-link-class="'prev-link-item'"
                        :prev-class="'page-link'"
                        :next-class="'page-link'"
                >
                </paginate>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import {
        GET_USERS,
        DELETE_USER,
        DISMISS_USER,
        ACTIVE_USER,
    } from "../../../../core/services/store/user/users.module";
    import Swal from "sweetalert2";
    import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";
    const _ = require('lodash');

    export default {
        name: "List-user",
        data() {
            return {
                query : {
                  keyword: "",
                    page: 1,
                },
                classSpan : {
                    success : 'label-success',
                    danger : 'label-danger'
                },
                dismiss: {
                    date: '',
                    reason : ''
                }
            };
        },
        computed: {
            ...mapGetters(["getAllUsers","getUserPaginate"]),
            total_pages() {
                return this.getUserPaginate?.total_pages || 0
            },
        },

        mounted() {
            this.query.keyword = this.$route.query.keyword ? this.$route.query.keyword :'';
            this.query.page = parseInt(this.$route.query.page ? this.$route.query.page : 1);
            this.$store.dispatch(GET_USERS, {
                keyword : this.query.keyword,
                page: this.query.page,
                is_staff : 0
            });
            this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Quản lý nhân sự"},
                { title: "Danh sách học viên" }
            ]);
        },
        methods: {
            deleteUser(id) {
                Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire("Xóa", "", "success");
                        this.$store.dispatch(DELETE_USER, id).then(() => {
                        });
                    }
                });
            },
            searchUsers : _.debounce(function() {
              this.$router.push({ path : '' , query : { keyword : this.query.keyword, is_staff : 0}});
                this.$store.dispatch(GET_USERS,{
                    keyword : this.query.keyword,
                    is_staff : 0,
                }).then(() =>{
                    this.query.page = 1;
                });
            }, 300),
            clickCallback(pageNum) {
                this.$router.push({ path : '' , query : { page : pageNum, keyword : this.query.keyword, is_staff : 0}});
                this.$store.dispatch(GET_USERS,{
                    page : pageNum,
                    keyword : this.query.keyword,
                    is_staff : 0
                });
            },
            dismissOrActiveUser : function (user) {
                if (user.status === 1){
                    Swal.fire({
                        title: "Bạn chắc chắn muốn cho "+ user.name +" thôi việc?",
                        showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: "Đồng ý",
                        cancelButtonText: "Không",
                        html: '<input id="swal-date" class="swal2-input" type="date" placeholder="Chọn ngày nghỉ việc">' +
                            `<input id="swal-reason" class="swal2-input" type="text" placeholder="Lý do nghỉ việc" value="Out ${user.profile.account_type.name}" disabled>`,
                        preConfirm() {
                            if ($('#swal-date').val() == "") {
                                Swal.showValidationMessage("Ngày nghỉ việc không được để trống");
                            } else {
                                return {date: $('#swal-date').val(), reason: $('#swal-reason').val()};
                            }
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let payload = {
                                id : user.id,
                                params : result.value
                            }
                            this.$store.dispatch(DISMISS_USER, payload);
                            Swal.fire("Thành công", "", "success");
                        }
                    });
                }else{
                    Swal.fire({
                        title: "Bạn chắc chắn muốn kích hoat "+ user.name +" ?",
                        showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: "Đồng ý",
                        cancelButtonText: "Không",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let payload = {
                                id : user.id,
                                params : ''
                            }
                            this.$store.dispatch(ACTIVE_USER, payload);
                            Swal.fire("Thành công", "", "success");
                        }
                    });
                }
            },

        },
    };
</script>

<style scoped>

</style>