<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Hợp đồng ứng</h3>
            </div>
            <div class="card-title" v-if="currentUser.user.permission.includes(permission.HOP_DONG_UNG)">
                <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcel">
                    <i class="la la-file-excel"></i> Xuất excel
                </button>
                <button v-else disabled
                        class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
                    Xuất excel
                </button>
              <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcelV2">
                <i class="la la-file-excel"></i> Xuất excel v2
              </button>
              <button v-else disabled class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
                Xuất excel v2
              </button>
            </div>
        </div>
        <div class="card-body">
            <!--begin::Example-->
            <div class="example mb-10">
                <div class="row">
                    <div class="col-md-2">
                        <date-picker
                                placeholder="Chọn thời gian"
                                format="DD-MM-YYYY"
                                range
                                valueType="YYYY-MM-DD" v-model="query.date_range"></date-picker>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <multiselect v-model="cacheCenter"
                                         :options="centers"
                                         placeholder="Trung tâm"
                                         :searchable="false"
                                         label="name"
                                         track-by="name"
                                         :show-labels="false"
                                         :hide-selected="true"
                                         @input="handleQueryCenter($event)"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <multiselect v-model="cacheBranch" :options="branches"
                                         placeholder="Chi nhánh"
                                         label="name"
                                         track-by="name"
                                         :show-labels="false"
                                         :hide-selected="true"
                                         @input="handleQueryBranch($event)"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <input type="text" v-model="query.keyword" class="form-control"
                                   placeholder="Tên, SĐT, Mã học viên">
                        </div>
                        <!--end::Form-->
                    </div>
                    <div class="col-md-2">
                        <div class="d-flex justify-content-between">
                            <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                                    @click="searchData">
                                <i class="fa fa-search"></i> Tìm kiếm
                            </button>
                            <button v-else type="button" disabled
                                    class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                                Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
                <div class="example-preview b-table-sticky-header">
                    <table class="table table-vertical-center b-table">
                        <thead class="thead-light">
                        <tr>
                            <th scope="col" class="min-w-50px">STT</th>
                            <th scope="col" class="min-w-100px">Mã hóa đơn</th>
                            <th scope="col" class="min-w-100px">Tên học viên</th>
                            <th scope="col" class="min-w-100px">Mã học viên</th>
                            <th scope="col" class="min-w-120px">Số điện thoại</th>
                            <th scope="col" class="min-w-100px">Mã hợp đồng</th>
                            <th scope="col" class="min-w-100px">Số tiền nạp</th>
                            <th scope="col" class="min-w-100px">Trạng thái</th>
                            <th scope="col" class="min-w-150px">Chi nhánh</th>
                            <th scope="col" class="min-w-100px">Người tạo</th>
                            <th scope="col" class="min-w-150px">Ngày nạp tiền</th>
                            <th scope="col" class="min-w-90px">Đã chuyển</th>
                            <th scope="col" class="min-w-140px">Chuyển tiền</th>
                            <th scope="col" class="min-w-140px">Nạp sai</th>
                            <th scope="col" class="min-w-120px">Lịch sử chuyển</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in student_amounts.data" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.id }}</td>
                            <td>{{ item.user != null ? item.user.name : '' }}</td>
                            <td>{{ item.user_id + 100000 }}</td>
                            <td>{{ item.user != null ? item.user.phone : '0đ' }}</td>
                            <td>{{ item.contract != null ? item.contract.id : '' }}</td>
                            <td>{{ item.amount > 0 ? formatPrice(item.amount, 'đ') : '' }}</td>
                            <td><span class="badge"
                                      v-bind:class="item.status === 4 ? classSpan.danger : classSpan.success">
                                    {{ item.status === 4 ? "Đã hủy" : 'Kích hoạt' }}
                                </span></td>
                            <td>{{ item.branch != null ? item.branch.name : '' }}</td>
                            <td>{{ item.create_user != null ? item.create_user.email : '' }}</td>
                            <td>{{ item.dated_paid | formatDateTimeAsia }}</td>
                            <td>{{
                                item.total_amount_childes > 0 ? formatPrice(item.total_amount_childes, 'đ') : '0đ'
                                }}
                            </td>
                            <td>
                                <ModalTransferMoney v-show="item.status != 4"
                                                    v-if="item.amount>0"
                                                    :item="item"
                                                    :index="index"
                                                    @move-success="moveSuccess"
                                >
                                </ModalTransferMoney>
                            </td>
                            <td>
                                <ModalWrongTransferComponent
                                        :item="item"
                                        :index="index"
                                        @update_success="updateSuccess"
                                >
                                </ModalWrongTransferComponent>
                            </td>
                            <td>
                                <ModalListDetail v-show="item.check_show_detail != 0" #ModalListDetail
                                                 :item="item"
                                                 :index="index"
                                                 @updateSuccess="moveSuccess"
                                >
                                </ModalListDetail>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="edu-paginate mx-auto text-center">
            <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                    :page-class="'page-item'"
            >
            </paginate>
        </div>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";
    import {ROLE} from '@/role';
    import {PERMISSION} from "@/permission";

    const _ = require('lodash');
    import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
    import {
      GET_STUDENT_AMOUNT,
      EXPORT_EDUTALK_THU_TIEN_OR_HOP_DONG_UNG, EXPORT_HOP_DONG_UNG_V2
    } from "../../../core/services/store/contract/studentAmount.module";
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
    import Multiselect from 'vue-multiselect'
    import ModalTransferMoney from './ModalTransferMoney';
    import ModalListDetail from './ModalListDetail';
    import ModalWrongTransferComponent from './WrongTransferComponent';
    // Datetime picker
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {exportExcel} from "../../../core/filters";


    export default {
        components: {
            Multiselect,
            DatePicker,
            ModalTransferMoney,
            ModalListDetail,
            ModalWrongTransferComponent
        },
        name: "StudentAmount",
        data() {
            return {
                role: ROLE,
                permission: PERMISSION,
                is_disable_search: false,
                is_export_excel: false,
                branches: [],
                centers: [],
                student_amounts: [],
                // Paginate
                page:
                    1,
                last_page:
                    1,
                query:
                    {
                        center_id: '',
                        branch_id: '',
                        date_range: '',
                        keyword: '',
                    }
                ,
                districtsByProvince: [],

                // cache
                cacheCenter: [],
                cacheBranch: [],
                classSpan: {
                    success: 'badge-success',
                    danger: 'badge-danger'
                },
            }
        },
        async mounted() {
            await this.getCenters()
            await this.getBranches()
            await this.getList()
            await this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý hợp đồng", route: '#'},
                {title: "Danh sách hợp đồng ứng"}
            ]);
        },
        computed: {
            ...mapGetters(["currentUser"]),
        },
        methods: {
            moveSuccess() {
                this.getList()
            },
            getList() {
                this.pullParamsUrl()
                this.setCacheTheLoad()
                this.callApiGetList()
            },
            setCacheTheLoad() {
                if (this.query.center_id != '') {
                    let oldParamCenter = this.centers.filter((value) => {
                        if (parseInt(this.query.center_id) == value.id) {
                            return value
                        }
                    })

                    this.cacheCenter = oldParamCenter
                }

                if (this.query.branch_id != '') {
                    let oldParamBranch = this.branches.filter((value) => {
                        if (parseInt(this.query.branch_id) == value.id) {
                            return value
                        }
                    })
                    this.cacheBranch = oldParamBranch
                }
            },

            pullParamsUrl() {
                this.page = this.$route.query.page
                this.query.keyword = this.$route.query.keyword
                this.query.center_id = this.$route.query.center_id
                this.query.branch_id = this.$route.query.branch_id
                this.query.date_range = this.$route.query.date_range
            },
            pushParamsUrl() {
                this.$router.push({
                    name: 'move-amount',
                    query: {
                        page: this.page,
                        keyword: this.query.keyword,
                        center_id: this.query.center_id,
                        branch_id: this.query.branch_id,
                        date_range: this.query.date_range
                    }
                })
            },
            clickCallback(obj) {
                this.page = obj
                this.$router.push({
                    path: '',
                    query: {
                        page: this.page,
                        center_id: this.query.center_id,
                        branch_id: this.query.branch_id,
                        keyword: this.query.keyword,
                        date_range: this.query.date_range
                    }
                })
                this.callApiGetList()
            },
            callApiGetList() {
                this.$store.dispatch(GET_STUDENT_AMOUNT, {
                    page: this.page,
                    keyword: this.query.keyword,
                    center_id: this.query.center_id,
                    branch_id: this.query.branch_id,
                    date_range: this.query.date_range,
                    // Condition
                    type: 3,
                    parent_log_id: 0,
                    ben_thu_tien: 1,
                    is_sum_amount_childs: true
                }).then((res) => {
                    this.is_disable_search = false
                    this.student_amounts = res.data
                    this.last_page = res.data.last_page
                });
            },

            exportExcel() {
                this.is_export_excel = true
                const HEADER_ROW_EXPORT_HD_UNG = [
                    {
                        key: 'stt',
                        value: 'STT',
                    },
                    {
                        key: 'id',
                        value: 'Mã hóa đơn',
                    },
                    {
                        key: 'name',
                        value: 'Tên học viên',
                    },
                    {
                        key: 'user_id',
                        value: 'Mã học viên',
                    },
                    {
                        key: 'phone',
                        value: 'Số điện thoại',
                    },
                    {
                        key: 'contract_id',
                        value: 'Mã hợp đồng',
                    },
                    {
                        key: 'amount',
                        value: 'Số tiền nạp',
                    },
                    {
                        key: 'ben_thu_tien',
                        value: 'Bên thu tiền',
                    },
                    {
                        key: 'center_name',
                        value: 'Trung tâm',
                    },
                    {
                        key: 'branch_name',
                        value: 'Chi nhánh',
                    },
                    {
                        key: 'created_user',
                        value: 'Người tạo',
                    },
                    {
                        key: 'dated_paid',
                        value: 'Ngày nạp tiền',
                    },
                    {
                        key: 'total_amount_childes',
                        value: 'Đã chuyển',
                    },
                    {
                        key: 'status',
                        value: 'Trạng thái',
                    },
                ]
                this.$store.dispatch(EXPORT_EDUTALK_THU_TIEN_OR_HOP_DONG_UNG, {
                    page: this.page,
                    keyword: this.query.keyword,
                    center_id: this.query.center_id,
                    branch_id: this.query.branch_id,
                    date_range: this.query.date_range,
                    // Condition
                    type: 3,
                    parent_log_id: 0,
                    ben_thu_tien: 1,
                }).then((res) => {
                    exportExcel(HEADER_ROW_EXPORT_HD_UNG, res.data, "Danh sách hợp đồng ứng.xlsx", true)
                    this.is_export_excel = false
                });
            },
            exportExcelV2() {
                this.is_export_excel = true
                const HEADER_ROW_EXPORT_HD_UNG = [
                    {
                      key: 'stt',
                      value: 'STT',
                    },
                    {
                      key: 'id',
                      value: 'Mã hóa đơn',
                    },
                    {
                      key: 'name',
                      value: 'Tên học viên',
                    },
                    {
                      key: 'user_id',
                      value: 'Mã học viên',
                    },
                    {
                      key: 'phone',
                      value: 'Số điện thoại',
                    },
                    {
                      key: 'contract_id',
                      value: 'Mã hợp đồng',
                    },
                    {
                      key: 'amount',
                      value: 'Số tiền nạp',
                    },
                    {
                      key: 'ben_thu_tien',
                      value: 'Bên thu tiền',
                    },
                    {
                      key: 'center_name',
                      value: 'Trung tâm',
                    },
                    {
                      key: 'branch_name',
                      value: 'Chi nhánh',
                    },
                    {
                      key: 'created_user',
                      value: 'Người tạo',
                    },
                    {
                      key: 'dated_paid',
                      value: 'Ngày nạp tiền',
                    },
                    {
                      key: 'total_amount_childes',
                      value: 'Đã chuyển',
                    },
                    {
                      key: 'status',
                      value: 'Trạng thái',
                    },
                ]
                this.$store.dispatch(EXPORT_HOP_DONG_UNG_V2, {
                    page: this.page,
                    keyword: this.query.keyword,
                    center_id: this.query.center_id,
                    branch_id: this.query.branch_id,
                    date_range: this.query.date_range,
                    // Condition
                    type: 3,
                    parent_log_id: 0,
                    ben_thu_tien: 1,
                }).then((res) => {
                    exportExcel(HEADER_ROW_EXPORT_HD_UNG, res.data, "Danh sách hợp đồng ứng.xlsx", true)
                    this.is_export_excel = false
                });
            },
            async getCenters() {
                this.$store.dispatch(GET_CENTERS, {
                    limit: 10000,
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data
                        this.addFirstSelectExample(this.centers, '__Chọn__')
                        this.setCacheTheLoad()
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getBranches() {
                this.$store.dispatch(GET_BRANCHES, {
                    limit: 10000,
                    center_id: this.query.center_id
                }).then((res) => {
                    if (!res.error) {
                        this.branches = res.data.data
                        this.addFirstSelectExample(this.branches, '__Chọn__')
                        this.setCacheTheLoad()
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            handleQueryCenter($event) {
                this.cacheBranch = []
                this.query.branch_id = ''
                this.branches = []
                if ($event.id != undefined) {
                    this.query.center_id = $event.id
                    this.getBranches()
                } else {
                    this.query.center_id = ''
                }
            },
            handleQueryBranch($event) {
                this.query.branch_id = $event.id
            },
            searchData() {
                this.page = 1
                this.is_disable_search = true
                this.pushParamsUrl()
                this.callApiGetList()
            },
            updateSuccess() {
                this.getList();
            },
            addFirstSelectExample(obj, text) {
                obj.unshift({
                    name: text
                })
            },
        }
    }
</script>
<style>
    .mx-datepicker {
        width: 100% !important;
    }

    .multiselect__tags {
        padding: 3px 40px 0 9px !important;
        min-height: 38px !important;
    }

    .multiselect {
        min-height: 38px !important;
    }

    .mx-input {
        min-height: 38px !important;
    }

    .multiselect__single {
        margin-top: 5px !important;
    }

    .b-table-sticky-header {
        overflow-y: auto;
        max-height: 500px;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
