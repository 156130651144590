<template>
  <div>
    <p>Nhân sự: {{ data.tester ? data.tester.name : '' }} -
      {{ data.tester && data.tester.user ? data.tester.id + 100000 : '' }}
      <button v-if="is_show" type="button" class="btn btn-warning btn-sm" @click="is_show=!is_show"><i
          class="el-icon-edit"></i>Sửa lịch
      </button>
      <button v-if="!is_show" type="button" class="btn btn-secondary btn-sm mr-2" @click="is_show=!is_show">Hủy</button>
      <button v-if="!is_show" type="button" class="btn btn-warning btn-sm" @click="save"><i
          class="el-icon-circle-check"></i>Lưu lại
      </button>
    </p>
    <div class="example mb-10">
      <div class="example-preview table-responsive">
        <table class="table table-vertical-center">
          <thead>
          <tr>
            <th class="text-center">Ca làm việc</th>
            <th class="text-center">
              <span>Thứ 2</span>
            </th>
            <th class="text-center">
              <span>Thứ 3</span>
            </th>
            <th class="text-center">
              <span>Thứ 4</span>
            </th>
            <th class="text-center">
              <span>Thứ 5</span>
            </th>
            <th class="text-center">
              <span>Thứ 6</span>
            </th>
            <th class="text-center">
              <span>Thứ 7</span>
            </th>
            <th class="text-center">
              <span>Chủ nhật</span>
            </th>
          </tr>
          <tr v-for="(item, index) in data.data" :key="index">
            <td class="text-center" v-for="(v, i) in item" :key="i">
              <span v-if="v.type =='title'">{{ CA_LAM_VIEC[v.name] }}</span>
              <el-checkbox :disabled="is_show" v-else v-model="v.value"></el-checkbox>
            </td>
          </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import {CA_LAM_VIEC} from "@/core/option/testInputOption";
import {TEST_INPUT_SAVE_CALENDAR} from "@/core/services/store/test/testInput.module";

export default {
  name: "CalendarItem",
  props: {
    item_prop: {
      type: Object,
      default() {
        return null
      }
    }
  },
  watch: {
    item_prop: {
      handler() {
        this.data = this.item_prop;
      }
    }
  },
  mounted() {
    this.data = this.item_prop;
  },
  data() {
    return {
      is_show: true,
      checked: false,
      is_load_save: false,
      data: [],
      CA_LAM_VIEC: CA_LAM_VIEC,
    }
  },
  methods: {
    save() {
      this.is_load_save = true;
      this.$store.dispatch(TEST_INPUT_SAVE_CALENDAR, {
        calendars: this.data.data,
        user_id: this.data.tester.user_id
      }).then((data) => {
        this.$emit('saveCalendarSuccess');
        this.$message.success(data.message);
        this.is_show = true;
      }).catch((error) => {
        this.$message.error(error.data.message);
      }).finally(() => {
        this.is_load_save = false;
      })
    }
  }
}
</script>

<style scoped>

</style>