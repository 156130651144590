import { render, staticRenderFns } from "./AreaIndex.vue?vue&type=template&id=4db2958f&scoped=true"
import script from "./AreaIndex.vue?vue&type=script&lang=js"
export * from "./AreaIndex.vue?vue&type=script&lang=js"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4db2958f",
  null
  
)

export default component.exports