<template>
    <div>
        <FormulateForm
            :schema="schema"
            v-model="values"
            #default="{ isLoading }"
            @focus-confirm="focusedOn = 'confirm'"
            @file-upload-complete="handleData"
            @submit="submitHandler">
            {{ focusedOn }}
        </FormulateForm>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {homePage} from "./index";
import {
  EDU_OPTION_POST,
  EDU_OPTION_PUT,
  GET_EDU_OPTION
} from "../../../core/services/store/edutalk-option/edutalk-option.module";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
const _ = deepdash(lodash);
export default {
    name: "home-page",
    data () {
        return {
            values: {},
            image_home : [],
            focusedOn: null,
            schema: homePage,
            fileName: {},
            array: [],
            array_image_block_01: [],
            array_image_block_02: [],
            array_image_block_03: [],
            array_image_logo_block_03: [],
            array_prop: []
        }
    },
    created() {
        this.setBreadcrumb();
    },
    mounted() {
      this.getOption();
    },
    computed: {
        ...mapGetters(["currentUser"]),
    },
    methods: {
        setBreadcrumb() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Setting Page", route: 'setting-page'},
                {title: "Cấu hình trang Home Edutalk"}
            ]);
        },
       handleData(data) {
        let index = null;
        let exits = null
        _.eachDeep(this.values[`${data.parent}`], function (value, key, path, depth, parent) {
          if (index === null) {
            index = key
          }
          if (key === 'home_image') {
            if (value.context.attributes.id === data.id) {
              exits = depth;
              data.indexParent = exits._item.path[0];
              data.grop_one = exits._item.path[1];
              data.index = exits._item.path[2];
              data.grop_two = exits._item.path[3];
            }
            return false
          }
        });
        if (data.grop_one){
          this.array.push(data);
        }
         if(data.parent === 'home_image_block_01') {
            this.array_image_block_01.push([data]);
         }
         if(data.parent === 'home_image_block_02') {
            this.array_image_block_02.push([data]);
         }
         if(data.parent === 'home_image_block_03') {
            this.array_image_block_03.push([data]);
         }
         if(data.parent === 'home_image_logo_block_03') {
            this.array_image_logo_block_03.push([data]);
         }
      },

      submitHandler(data, log) {
        let data_form = {};
        let index = null;
        if(Object.keys(this.array_prop).length) {
          _.eachDeep(data, function (value, key, path, depth, parent) {
            if (index === null) {
              index = key
            }
            if (key && key.indexOf("home_image") !== -1) {

              path[key] = path[key].results;
              return false
            }
          });
          data_form = data;
        } else {
          this.array.map((value) => {
            let description = data[value.parent][value.indexParent][value.grop_one][value.index].description;
            let title_description = data[value.parent][value.indexParent][value.grop_one][value.index].title_description;
            data[value.parent][value.indexParent][value.grop_one][value.index] = {home_image: [{url: value.url , name: value.name}] , description: description , title_description: title_description};
          });
          for (var i = 0; i < this.array_image_block_01.length; i++) {
            data['home_img_items_01'][i]['home_image_block_01'] = this.array_image_block_01[i];
          }
          for (var i = 0; i < this.array_image_block_02.length; i++) {
            data['home_img_items_02'][i]['home_image_block_02'] = this.array_image_block_02[i];
          }
          for (var i = 0; i < this.array_image_block_03.length; i++) {
            data['home_img_items_03'][i]['home_image_block_03'] = this.array_image_block_03[i];
          }
          for (var i = 0; i < this.array_image_logo_block_03.length; i++) {
            data['home_img_items_03'][i]['home_image_logo_block_03'] = this.array_image_logo_block_03[i];
          }
          data_form = data;
        }

        if(this.id) {
          this.$store.dispatch(EDU_OPTION_PUT, {id: this.id,template: 'home-page' , data: JSON.stringify(data_form)})
              .then((res) => {
                if (!res.error) {
                  this.$bvToast.toast(res.message, {
                    title: 'Cập nhật',
                    variant: 'success',
                    solid: true
                  });
                }
              }).catch((e) => {
          });
        } else {
          this.$store.dispatch(EDU_OPTION_POST, {template: 'home-page' , data: JSON.stringify(data_form)})
              .then((res) => {
                if (!res.error) {
                  this.$bvToast.toast(res.message, {
                    title: 'Thêm mới',
                    variant: 'success',
                    solid: true
                  });
                }
              }).catch((e) => {
          });
        }
      },
      getOption() {
        this.$store.dispatch(GET_EDU_OPTION, {template: 'home-page'})
            .then((res) => {
              if (!res.error) {
                this.array_prop = JSON.parse(res[1]);
                this.id = res[2];
                this.values = res[0].length === 0 ? {} : res[0];
              }
            }).catch((e) => {
        });
      },
    }
}
</script>

<style scoped>

</style>
