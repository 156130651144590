<template>
  <div>
    <el-dialog
        centered id="modal-create-policy"
        size="lg"
        :title="check_update ? 'Cập nhập danh mục' : 'Thêm mới danh mục'"
        ok-title="Lưu"
        cancel-title="Xem trước"
        @close="closeDialog"
        :visible.sync="showDialog"
        disableEnforceFocus
    >
      <ValidationObserver v-slot="{ handleSubmit }" ref="form" >
        <form class="form" autocomplete="off" @submit.prevent="handleSubmit(handleOk)">
          <div class="mb-2">
            <label for="">Tiêu đề</label>
            <ValidationProvider vid="name" name="Tiêu đề"
                                :rules="{ required: true }"
                                v-slot="{ errors,classes }">
              <input type="text" class="form-control" v-model="data_prop.name">
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                    errors[0]
                  }}
                </div>
              </div>
            </ValidationProvider>
          </div>

          <div class="mb-2">
            <ValidationProvider vid="tuition_payment_id" name="Danh mục con"
                                :rules="{  }"
                                v-slot="{ errors,classes }">
            <label for="">Danh mục con</label>
              <el-select class="w-100" v-model="data_prop.tuition_payment_id" clearable>
                <el-option v-for="(item, index) in tuitionPayments"
                           :key="index"
                           :label="item.name"
                           :value="item.id"
                >

                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                    errors[0]
                  }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="mb-2">
            <label for="">Thứ tự hiển thị</label>
            <ValidationProvider vid="index" name="Thứ tự hiển thị"
                                :rules="{ required: true }"
                                v-slot="{ errors,classes }">
              <input type="number" class="form-control" v-model="data_prop.index">
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                    errors[0]
                  }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="mb-2">
            <ValidationProvider vid="type_course" name="Danh mục con"
                                :rules="{  }"
                                v-slot="{ errors,classes }">
              <label for="">Loại khoá</label>
              <el-select class="w-100" v-model="data_prop.type_course" clearable>
                <el-option v-for="(item, index) in typeCourse"
                           :key="index"
                           :label="item.name"
                           :value="item.id"
                >

                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                    errors[0]
                  }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div>
            <label for="">Nội dung</label>
            <ValidationProvider vid="content" name="Nội dung"
                                :rules="{ required: true }"
                                v-slot="{ errors,classes }">
              <ckeditor :editor="editor" v-model="data_prop.content"></ckeditor>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                    errors[0]
                  }}
                </div>
              </div>
            </ValidationProvider>
          </div>

          <div slot="footer" class="dialog-footer d-flex justify-content-end mt-4">
            <el-button @click="preview">Xem trước</el-button>
            <button :disabled="disableSubmit" class="el-button el-button--default bg-primary text-white" type="submit">Xác nhận </button>
          </div>
        </form>
      </ValidationObserver>
    </el-dialog>
    <b-modal
        @ok="handleSubmit"
        id="modal-preview-policy"
        ref="preview-policy"
        title="Xem trước bản điều khoản chính sách"
        size="xl"
        ok-title="Lưu"
        cancel-title="Quay lại"
        @cancel="goBack"
        @hide="doSometing"
    >
      <el-collapse @change="handleChange">
        <el-collapse-item
            v-for="(item, index) in termPolicy"
            v-if="(index + 1) != data_prop.index"
            :title="item.name"
            :name="item.id"
        >
          <div class="ql-editor table-ck" v-html="item.content"></div>
        </el-collapse-item>
        <el-collapse-item :title="data_prop.name" :name="data_prop.index" >
          <div class="ql-editor table-ck" v-html="data_prop.content"></div>
        </el-collapse-item>
      </el-collapse>
    </b-modal>
  </div>
</template>

<script>
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";
import {CREATE_CATALOG, UPDATE_CATALOG} from "@/core/services/store/system/termpolicy.module";
const lodash = require('lodash');

export default {
  name: "ModalCreateTermPolicy",

  data() {
    return {
      editor: CustomEditor,
      formData: {
        content: '',
        index: '',
        name: '',
        center_id: '',
        tuition_payment_id: ''
      },
      showPreview: false,
      showDialog: false,
      disableSubmit: false,
      typeCourse: [
        {
          id: 1,
          name: 'Loại khoá VSTEP'
        },
        {
          id: 0,
          name: 'Loại khoá khác'
        }
      ]
    }
  },
  props: {
    center_id: {
      default: null
    },
    data_prop: {
      default: {}
    },
    check_update: {
      default: false
    },
    dialogTableVisible: {
      default: false
    },
    termPolicy: {
      default: {}
    },
    tuitionPayments: {
      default: {}
    }
  },
    watch: {
    dialogTableVisible: function(value) {
      this.showDialog = value;
    },
    data_prop: function(value) {
        this.data_prop.tuition_payment_id = value.tuition_payment_id ? value.tuition_payment_id : '';
    },
  },
  methods: {
    handleChange(val) {
    },
    handleOk() {
      this.data_prop.center_id = this.center_id;
      if (this.check_update) {
        this.updateCatalog();
        this.$emit("showDialog", this.showDialog);
        return;
      }
      this.createCatalog();
      this.$emit("showDialog", this.showDialog);

    },
      preview() {
      this.$refs['preview-policy'].show();
      this.showPreview = true;
      this.showDialog = false;
    },
    closeDialog() {
      this.showDialog = false;
      this.$emit("showDialog", this.showDialog);
    },
    handleSubmit() {
        this.showPreview = false;
        this.disableSubmit = true;
        this.$emit("showDialog", this.showDialog);

      this.data_prop.center_id = this.center_id;
      if (this.check_update) {
          this.updateCatalog()
        return;
      }
        this.createCatalog();
    },
      updateCatalog() {
        if(this.disableSubmit){
            return;
        }
        this.disableSubmit = true;
      this.$store.dispatch(UPDATE_CATALOG, this.data_prop).then((res) => {
        this.$emit("addParam");
          this.showDialog = false;
          this.$bvToast.toast(res.message, {
          title: 'Cập nhập',
          variant: 'success',
          solid: true
        });
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.showDialog = true;
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      }).finally(()=>{
          setTimeout(()=>this.setTimeDisableSubmit(), 1000);
      });
    },
      createCatalog() {
        if(this.disableSubmit){
            return;
        }
        this.disableSubmit = true;
      this.$store.dispatch(CREATE_CATALOG, this.data_prop).then((res) => {
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Tạo mới',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$emit("addParam");
            this.showDialog = false;
            this.$bvToast.toast(res.message, {
            title: 'Tạo mới',
            variant: 'success',
            solid: true
          });
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      }).finally(()=>{
          setTimeout(()=>this.setTimeDisableSubmit(), 1000);
      });
    },
    setTimeDisableSubmit() {
        this.disableSubmit = false;
    },
    goBack() {
      this.showDialog = true;
      this.$refs['preview-policy'].hide();
    },
    doSometing() {
      this.showDialog = true;
      this.$refs['preview-policy'].hide();
    }
  },
}
</script>

<style scoped>

</style>