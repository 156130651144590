<template>
  <b-modal @hide="onHide" size="lg" :centered="true" :scrollable="true" ref="modal-tester" hide-footer title="Thêm mới tester"  class="modal-tester">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <div class="d-block">
        <form class="form" @submit.prevent="handleSubmit(saveTester)">
          <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Họ tên tester <span class="text-danger">(*)</span></label>
                    <ValidationProvider name="Họ và tên" rules="required|max:30" v-slot="{ errors,classes }" vid="name">
                      <input type="text" name="name" class="form-control" placeholder="Nhập họ tên"
                             :class="classes"
                             v-model=tester.name>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Số điện thoại
                      <span class="text-danger">(*)</span></label>
                    <ValidationProvider vid="phone" name="Số điện thoại"
                                        :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                        v-slot="{ errors,classes }">
                      <input type="number" v-model="tester.phone" :class="classes" class="form-control" placeholder="Nhập SĐT đúng định dạng">
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
<!--                <div class="col-md-6">-->
<!--                  <div class="form-group">-->
<!--                    <label>Email-->
<!--                      <span class="text-danger">*</span></label>-->
<!--                    <ValidationProvider name="Email" vid="email" rules="required|email" v-slot="{ errors,classes }">-->
<!--                      <input type="email" name="email" class="form-control" placeholder="Email"-->
<!--                             :class="classes"-->
<!--                             v-model=tester.email>-->
<!--                      <div class="fv-plugins-message-container">-->
<!--                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </ValidationProvider>-->
<!--                  </div>-->
<!--                </div>-->
          </div>
          <footer class="modal-footer pt-5 pb-0">
            <button type="button" class="btn btn-secondary" @click="hideModal">Đóng</button>
            <button type="submit" class="btn btn-success">Thêm mới</button>
          </footer>
        </form>
    </div>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import {CREATE_TESTER} from "@/core/services/store/test/test.module";

export default {
  name: "TesterForm",
  props: {
    showModal: {
      type: Boolean,
      default: () => { return false}
    }
  },
  data() {
    return {
      tester: {
        name: '',
        phone: '',
        email: ''
      }
    }
  },
  watch: {
    showModal: function(){
      if (this.showModal){
        this.$refs['modal-tester'].show();
      }
    }
  },
  methods: {
    saveTester: function (){
      this.$store.dispatch(CREATE_TESTER, this.tester).then((res) => {
        this.$bvToast.toast(res.message, {
          title: 'Tạo tester',
          variant: 'success',
          solid: true
        });
        this.hideModal();
        this.$emit('createSuccess', res.data)
      }).catch((res) => {
        if (res.data.data.message_validate_form) {
          this.$refs.form.setErrors(res.data.data.message_validate_form);
        }
      })
    },
    hideModal: function (){
      this.$refs['modal-tester'].hide();
    },
    onHide: function (){
      this.$emit("clicked", true);
    },
    toggleModal() {
      // when the modal has hidden
      this.$refs['modal-tester'].toggle('#toggle-btn');
    },
  }
}
</script>

<style scoped>

</style>
