import ApiService from "@/core/services/api.service";
import {EXPORT_APPLITICATION_CATEGORY, EXPORT_LIST_CANDIDATE, EXPORT_LIST_TVV_TRAINING} from "../export/export.module";
import moment from "moment";
import writeXlsxFile from "write-excel-file";

const _ = require('lodash');
export const GET_TICKET_BY_USER_ID = "get_ticket_by_user_id";
export const GET_TICKET_BY_ID = "get_ticket_by_id";
export const GET_ALL_TICKET = "get_all_ticket";
export const GET_ALL_CREATED_USER_TICKET = "get_all_created_user_ticket";
export const HANDLE_TICKET = "handle_ticket";
export const EXPORT_EXCEL_TICKET = "export_excel_ticket";
export const EXPORT_EXCEL_TICKET_V2 = "export_excel_ticket_v2";
export const UPDATE_TICKET = "update_ticket";
export const FEEDBACK_TICKET = "feedback_ticket";
export const UPDATE_TICKET_FORM = "update_ticket_form";
export const UPDATE_TICKET_TAKE_CARE_STUDENT_FORM = "update_ticket_take_care_student_form";
export const CHANGE_STATUS = "tickets/change-status";
export const GET_SOURCE = "get_source";
export const GET_TICKET_CATEGORIES = "get_ticket_categories";
export const GET_OBJECT_FEEDBACK = "get_object_feedback";
export const CREATE_TICKET = "create_ticket";
export const DELETE_TICKET = "delete_ticket";
export const GET_TICKET_REASON_BY_TYPE = "tickets/get-ticket-reason";
export const UPDATE_TICKET_REASON = "tickets/update-ticket-reason";
export const GET_LEARNING_SCHEDULE_ID = "tickets/get-learning-schedule";
export const HANDLER_REFUSE_SOLUTION = "tickets/refuse-solution";
export const CLASSIFY_FEEDBACK_TICKET_LOW = "tickets/classify-feedback-low";
export const EXTEND_DEADLINE_TICKET = "tickets/extend-time-warning";
export const GET_DATA_STUDY_IN_CLASS = "tickets/get-data-study-in-class";

//stringee
export const CONNECT_STRINGEE  = "tickets/connect-stringee";
export const HANDLE_HISTORY_STRINGEE  = "tickets/handle-history-stringee";
export const GET_HISTORY_STRINGEE = "tickets/get-history-stringee";
export const RELOAD_CONNECT_STRINGEE = "tickets/reload-connect-stringee";
export const CHANGE_LOG_STRINGEE = "tickets/change-log-stringee";
export const GET_CALL_STATISTICS = "tickets/call-statistics";
export const EXPORT_CALL_STATISTICS = "tickets/export-statistics";
export const UPDATE_HISTORY_STRINGEE = "tickets/update-history-stringee";
export const GET_CUSTOMER_CARE_PHONE = "tickets/get-customer-care-phone";
export const ASSIGNEE_TICKET = "tickets/assignee-ticket";
export const GET_ASSIGNEE_TICKET = "tickets/get-ticket-assignee";
export const GET_ASSIGNEE_TICKET_STRINGEE = "tickets/get-ticket-assignee-stringee";


export const REASON_ERROR = {
    NOT_INIT: '',
    SUCCESS: '',
    ANSWER_URL_EMPTY: '',
    ANSWER_URL_SCCO_INCORRECT_FORMAT: '',
    TO_TYPE_IS_NOT_INTERNAL_OR_EXTERNAL: '',
    FROM_NUMBER_NOT_FOUND: 'Không tìm thấy số điện thoại',
    FROM_NUMBER_NOT_BELONG_YOUR_ACCOUNT: '',
    SIP_TRUNK_NOT_FOUND: '',
    SIP_TRUNK_NOT_BELONG_YOUR_ACCOUNT: '',
    NOT_ENOUGH_MONEY: 'Tài khoản không đủ tiền',
    UNKNOW_ERROR_1: '',
    FROM_NUMBER_OR_TO_NUMBER_INVALID_FORMAT: 'Định dạng số điện thoại không hợp lệ',
    CALL_NOT_ALLOWED_BY_YOUR_SERVER: 'Máy chủ của bạn không cho phép cuộc gọi tới số điện thoại này',
    MAX_CONCURRENT_CALL: '',
    WAIT_TEXT_TO_SPEECH: '',
    TO_NUMBER_INVALID: 'Số điện thoại không hợp lệ',
    FROM_NUMBER_NOT_BELONG_YOUR_PROJECT: '',
    NOT_ALLOW_CHAT_USER: '',
    NOT_ALLOW_CALLOUT: '',
    REQUEST_ANSWER_URL_ERROR: '',
    ACCOUNT_LOCKED: 'Tài khoản bị khóa',
    CREATE_PEER_CONNECTION_ERROR: 'Cuộc gọi đồng thời',
    GET_USER_MEDIA_ERROR: 'Không có quyền truy cập Camera/Mic hoặc WebRTC không hỗ trợ'
};


export const CATEGORY_LIST = [
    {
        id: 1,
        name: 'Nghỉ học 2 buổi',
        status: 2,

    },
    {
        id: 2,
        name: 'Điểm kiểm tra thấp',
        status: 2,
    },
    {
        id: 3,
        name: 'Không làm bài',
        status: 2,
    },
    {
        id: 4,
        name: 'Chua đóng tiền học',
        status: 2,
    },
    {
        id: 5,
        name: 'Đã đóng tiền học',
        status: 2,
    },
    {
        id: 6,
        name: 'Feedback thấp',
        status: 2,
    },
    {
        id: 7,
        name: 'Nghỉ học 1 buổi',
        status: 2,
    },
    {
        id: 8,
        name: 'Nghỉ học 1 buổi',
        status: 2,
    },
    {
        id: 9,
        name: 'Hướng dẫn app cho phụ huynh',
        status: 2,
    },
];
export const CATEGORY = {
    1: "Bảo hành",
    2: "Bảo lưu",
    3: "Khiếu nại",
    4: "Hỗ trợ học tập",
    5: "Rút tiền",
    6: "Đề xuất",
    7: "Khác",
    8: "Trung tâm hỗ trợ",
    9: "Chăm sóc học viên",
};

export const CATEGORY_CHAM_SOC = {
    1: 'Nghỉ học 2 buổi',
    2: 'Điểm kiểm tra thấp',
    3: 'Không làm bài',
    4: 'Chua đóng tiền học',
    5: 'Đã đóng tiền học',
    6: 'Feedback thấp',
    7: 'Nghỉ học 1 buổi',
    8: 'Vắng có phép',
    9: 'Hướng dẫn app cho phụ huynh',
    10: 'Kết quả BTVN không tốt',
    11: 'Kết quả BTVN không tốt'
};
export const HEADER_ROW_EXPORT_CALL_LOG = [
    {
        key: 'user_name',
        value: 'Khách hàng',
    },
    {
        key: 'type',
        value: 'Loại',
    },
    {
        key: 'brand_name',
        value: 'Brand name',
    },
    {
        key: 'from_number',
        value: 'Từ số',
    },
    {
        key: 'to_number',
        value: 'Đến số',
    },    {
        key: 'status',
        value: 'Trạng thái',
    },
    {
        key: 'reply_speed',
        value: 'Thời lượng',
    },
    {
        key: 'start_time_datetime',
        value: 'Thời gian bắt đầu',
    },
    {
        key: 'answer_time_datetime',
        value: 'Thời gian trả lời',
    },
    {
        key: 'stop_time_datetime',
        value: 'Thời gian kết thúc',
    },
    {
        key: 'type',
        value: 'Loại',
    },
    {
        key: 'ticket_id',
        value: 'Mã ticket',
    },
    {
        key: 'category',
        value: 'Danh mục',
    },
    {
        key: 'error',
        value: 'Lỗi ',
    },
];
const state = {};

const getters = {};

const actions = {
    [GET_ALL_TICKET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query("tickets", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    /**
     * Export excel ticket
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [EXPORT_EXCEL_TICKET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query("tickets/export-excel", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [EXPORT_EXCEL_TICKET_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query("tickets/export-excel-v2", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [GET_TICKET_BY_ID](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get("tickets", id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    [UPDATE_TICKET_REASON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(UPDATE_TICKET_REASON, payload.id, payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },

    [GET_TICKET_REASON_BY_TYPE](context, type) {
        return new Promise((resolve) => {
            ApiService.get(GET_TICKET_REASON_BY_TYPE, type)
                .then(({ data }) => {
                    // console.log(data);
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response)
                });
        });
    },
    [GET_TICKET_BY_ID](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get("tickets", id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    [GET_TICKET_BY_ID](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get("tickets", id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    [GET_TICKET_BY_USER_ID](context, id) {
        return new Promise((resolve) => {
            ApiService.get("tickets/get-by-user-id", id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response)
                });
        });
    },
    /**
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_SOURCE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query("tickets/get-source", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    /**
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_TICKET_CATEGORIES](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query("ticket-category", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    /**
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [GET_OBJECT_FEEDBACK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query("tickets/get-handle-object", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    /**
     * Handle ticket
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [HANDLE_TICKET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("tickets/handle-ticket", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                });
        });
    },

    [GET_LEARNING_SCHEDULE_ID](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("tickets/learning-schedule", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                });
        });
    },

    [CLASSIFY_FEEDBACK_TICKET_LOW](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("tickets/classify-feedback-low", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                });
        });
    },

    /**
     * Update ticket
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [UPDATE_TICKET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('tickets', payload.id, payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },
    [UPDATE_TICKET_REASON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(UPDATE_TICKET_REASON, payload.id, payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },

    [FEEDBACK_TICKET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('tickets/feedback', payload.id, payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },

    [CHANGE_STATUS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('tickets/change-status', payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },

    [UPDATE_TICKET_FORM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('tickets/update-form', payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },

    [UPDATE_TICKET_TAKE_CARE_STUDENT_FORM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('tickets/update-form-form-take-care-student', payload).then(({ data }) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },

    [CREATE_TICKET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("tickets", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                });
        });
    },
    [GET_TICKET_REASON_BY_TYPE](context, type) {
        return new Promise((resolve) => {
            ApiService.get(GET_TICKET_REASON_BY_TYPE, type)
                .then(({ data }) => {
                    // console.log(data);
                    resolve(data);
                })
                .catch((response) => {
                    console.log(response)
                });
        });
    },
    /**
     * @param context
     * @param id
     * @returns {Promise<unknown>}
     */
    [DELETE_TICKET](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('tickets', id).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [GET_ALL_CREATED_USER_TICKET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query("tickets/get-created-users", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },

    [HANDLER_REFUSE_SOLUTION](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('tickets/refuse-solution', params)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [CONNECT_STRINGEE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(CONNECT_STRINGEE, payload)
              .then(({ data }) => {
                  resolve(data);
              })
              .catch((response) => {
                  reject(response);
              });
        });
    },
    [HANDLE_HISTORY_STRINGEE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(HANDLE_HISTORY_STRINGEE, params)
              .then(({data}) => {
                  resolve(data);
              })
              .catch(({res}) => {
                  reject(res);
              });
        });
    },
    [GET_HISTORY_STRINGEE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_HISTORY_STRINGEE, payload)
              .then(({ data }) => {
                  resolve(data);
              })
              .catch((response) => {
                  reject(response);
              });
        });
    },
    [RELOAD_CONNECT_STRINGEE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(RELOAD_CONNECT_STRINGEE, payload)
              .then(({ data }) => {
                  resolve(data);
              })
              .catch((response) => {
                  reject(response);
              });
        });
    },
    [CHANGE_LOG_STRINGEE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(CHANGE_LOG_STRINGEE, params)
              .then(({data}) => {
                  resolve(data);
              })
              .catch(({res}) => {
                  reject(res);
              });
        });
    },
    [GET_CALL_STATISTICS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_CALL_STATISTICS, payload)
              .then(({ data }) => {
                  resolve(data);
              })
              .catch((response) => {
                  reject(response);
              });
        });
    },
    [GET_CUSTOMER_CARE_PHONE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_CUSTOMER_CARE_PHONE, payload)
              .then(({ data }) => {
                  resolve(data);
              })
              .catch((response) => {
                  reject(response);
              });
        });
    },
    [UPDATE_HISTORY_STRINGEE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_HISTORY_STRINGEE, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [EXPORT_CALL_STATISTICS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_CALL_STATISTICS, payload).then(({ data }) => {
                var head = data.data[0];
                var dataExport = data.data[1];
                state.listExport = data.data;
                var dataHead = [];
                Object.values(head).forEach(function (item) {
                    var value = { value: item};
                    dataHead.push(value);
                });
                var dataCandidate = [dataHead, ];

                Object.values(dataExport).forEach(function (value) {
                    var category = '';
                    if (value.ticket_log && value.ticket_log.ticket && value.ticket_log.ticket.category_ticket) {
                        category = value.ticket_log.ticket.category_ticket.name
                    }

                    var data_row = [
                        { value: value.user_customer ? value.user_customer.name : '( Không xác định )' }, //  'khách hàng'
                        { value: value.category == 2 ? 'Gọi đến' : 'Gọi đi' }, //  'loại'
                        { value: value.category == 1 ? value.from_phone : value.to_phone }, //  'Brand name'
                        { value: value.from_phone }, //  'từ số'
                        { value: value.to_phone }, //  'tới số'
                        { value: value.status == 1 ? 'Thành công ' : 'Thất bại' }, //  'Trạng thái'
                        { value: value.answer_duration }, //  'thời lượng'
                        { value: value.start_time_datetime }, //  'thời gian bắt đầu'
                        { value: value.answer_time_datetime }, //  'thời gian trả lời'
                        { value: value.stop_time_datetime }, //  'thời gian kết thúc'
                        { value: value.ticket ? 'Có ticket' : 'Không có ticket' }, //  'Loại ticket'
                        { value: value.ticket ? value.ticket.id : '' }, //  Mã ticket
                        { value: category ? category : CATEGORY_CHAM_SOC[value.ticket_log && value.ticket_log.ticket ? value.ticket_log.ticket.category_chamsoc : ''] }, //  danh muc
                        { value: value.status == 2 ? REASON_ERROR[value.reason] : '' }, //  Lỗi
                    ];
                    dataCandidate.push(data_row);
                });
                let fileName = "danh_sach_report_bao_cao" + new Date().getTime() / 1000 + ".xlsx";
                writeXlsxFile(dataCandidate, {fileName})
                resolve(data);
            }).catch(({ response }) => {
                reject(response);
            });
        });
    },

    [EXTEND_DEADLINE_TICKET](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post('tickets/extend-time-warning', params)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },

    [ASSIGNEE_TICKET](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(ASSIGNEE_TICKET, params)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },

    [GET_ASSIGNEE_TICKET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_ASSIGNEE_TICKET, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },

    [GET_ASSIGNEE_TICKET_STRINGEE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_ASSIGNEE_TICKET_STRINGEE, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },

    [GET_DATA_STUDY_IN_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_DATA_STUDY_IN_CLASS, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
