<template>
  <div class="card card-custom gutter-b ">
    <div class="card-header">
      <div class="card-title">
        <h1 style="font-size: 16px">Tổ chức giải đấu</h1>
      </div>
    </div>
    <div class="row">
      <div class="card-body h-100 col-lg-6 col-md-12">
        <div class="">
          <div class="row justify-content-start">
            <div class="col-12">
              <h2 style="font-size: 16px">Danh sách team tham gia giải đấu</h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-start">
          <div class="col-lg-12 col-xl-6 col-sm-12">
            <div class="d-flex flex-row mb-3">
              <div class="">
                <template>
                  <el-input placeholder="Tìm team/thành viên" v-model="keyword" @input="getListTeam"></el-input>
                </template>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-md-12 col-sm-12">
            <div class="col-12 d-flex ">
              <div class="col-12 d-flex">
                <button type="button" class="btn btn-info btn-sm " @click="exportTeam()">
                  <div v-if="loadingExport">
                    <i class="el-icon-loading"></i>
                  </div>
                  <div v-else>
                    <i class="el-icon-download"></i>
                    Export
                  </div>
                </button>
                <el-badge :value="count" class="item">
                  <modal-browse-team @countTeamBrowse="handleCountTeamBrowse"></modal-browse-team>
                </el-badge>
                <modal-import-team @getTeam="getListTeam"></modal-import-team>
              </div>
            </div>
          </div>
        </div>
        <div class=" table-responsive" style="height: auto ; max-height: 60vh">
          <table class="table table-bordered">
            <thead>
            <tr align-items class="table-active">
              <th scope="col">Tên team</th>
              <th scope="col">Đội trưởng</th>
              <th scope="col">Số lượng TV</th>
              <th scope="col">Hành động</th>
            </tr>
            </thead>
            <tbody v-if="listTeam.length==0">
            <tr class="text-center">
              <td colspan="4">
                Không có dữ liệu
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr v-for="item in listTeam">
              <th scope="row"> {{ item.name }}</th>
              <td>{{ item.captain.name }} - {{ item.captain.phone }}</td>
              <td>{{ item.quantity }}</td>
              <td class="d-flex justify-content-center">
                <button class="btn btn-xs btn-icon mr-2 btn-outline-primary mr-top-3"
                        title="Chi tiết giải đấu" @click="watchDetailTeam(item.members,item.name)">
                  <i class="fa fa-eye"></i>
                </button>
                <button
                    class="btn btn-xs btn-icon mr-2 btn-outline-danger"
                    title="xóa"
                    @click="deleteTeam(item.id,item.name)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-between">
          <div></div>
          <paginate
              v-model="page"
              :page-count="last_page"
              :page-range="3"
              :margin-pages="1"
              :click-handler="clickCallback"
              :prev-text="'Trước'"
              :next-text="'Sau'"
              :container-class="'pagination b-pagination'"
              :pageLinkClass="'page-link'"
              :next-link-class="'next-link-item'"
              :prev-link-class="'prev-link-item'"
              :prev-class="'page-link'"
              :next-class="'page-link'"
              :page-class="'page-item'"
          >
          </paginate>
          Tổng số bản ghi: {{ total }}
        </div>
      </div>

      <div class="card-body h-100 col-lg-6 col-md-12">
        <ScheduleTournament ></ScheduleTournament>
      </div>
    </div>
    <ModalDetailTeam :members="members" :teamName="teamName"></ModalDetailTeam>
  </div>

</template>

<script>
import ModalImportTeam from "@/view/pages/Tournaments/Component/ModalImportTeam";
import ModalImportSchedule from "@/view/pages/Tournaments/Component/ModalImportSchedule";
import {DELETE_TEAM, DOWNLOAD_LIST_TEAM, GET_TEAM} from "@/core/services/store/tournament/tournament.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "OrganizeTournament",
  components: {ModalImportSchedule, ModalImportTeam},
  data() {
    return {
      listTeam: [],

      keyword: "",
      page: 1,
      last_page: 0,
      total: 0,
      members : [],
      teamName :'',
      count : null,
      loadingExport : false
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách giải đấu", route: "tournamentList" },
      { title: "Tổ chức giải đấu" },
    ]);
    this.getListTeam()

  },
  methods: {
    handleCountTeamBrowse(count){
      this.count = count
      this.getListTeam()
    },
    watchDetailTeam(members,nameTeam){
      this.teamName = nameTeam
      this.members = members??[]
      this.$root.$emit('bv::show::modal', 'modal-detail-team', '#btnShow')
    },
    exportTeam(){
      this.loadingExport = true
      let payload = {
        data:{
          tournament_id : this.$route.params.id
        },
        keyword : this.keyword
      }
      this.$store.dispatch(DOWNLOAD_LIST_TEAM,payload).then(()=>{
      }).catch(()=>{
      }).finally(()=>{
        this.loadingExport = false
      })
    },
    deleteTeam(id,name) {
      this.$confirm(`Bạn có chắc chắn muốn xóa team ${name} không?`, 'Xóa team', {
        confirmButtonText: 'Xác nhận',
        confirmButtonClass:"bg-success border-white",
        cancelButtonText: 'Hủy',
        type: 'Xóa team'
      }).then(() => {
        this.$store.dispatch(DELETE_TEAM, id).then(() => {
          this.getListTeam(),
              this.$notify({
                title: 'Thành công',
                message: 'Xóa team thành công',
                type: 'success'
              });
        }).catch((data) => {
          this.$notify({
            title: 'Lỗi',
            message: `Không thể xóa team đã có lịch thi đấu`,
            type: 'error'
          });
        });
      })
    },
    clickCallback(obj) {
      this.page = obj;
      this.getListTeam()
    },
    getListTeam() {
      let payload = {
        tournament_id: this.$route.params.id,
        keyword: this.keyword,
        page: this.page,
        status:[1]
      }
      this.$store.dispatch(GET_TEAM, payload).then((data) => {
        this.total = data.data.total;
        this.page = data.data.current_page;
        this.last_page = data.data.last_page;
        this.listTeam = data.data.data.map((team) => {
          return {
            id: team.id,
            name: team.name,
            captain: team.tournament_team_members.find((member) => member.is_captain == 1),
            quantity: team.tournament_team_members.length,
            members : team.tournament_team_members.map((member)=>{
              return {
                name : member.name,
                phone : member.phone,
                name_in_game : member.name_in_game,
                is_captain : member.is_captain
              }
            })
          }
        }) ?? []
      })
    },

  }
}

</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .list {
    display: block;
  }
}

.rounded-circle {
  position: relative;
  bottom: 16px;
  right: 18px;

}

</style>
<script setup>
import ModalBrowseTeam from "./Component/ModalBrowseTeam.vue";
import ModalDetailTeam from "@/view/pages/Tournaments/Component/ModalDetailTeam";
import ScheduleTournament from "@/view/pages/Tournaments/Component/ScheduleTournament";
</script>
