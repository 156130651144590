import Swal from "sweetalert2";
<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        Danh sách sự kiện
      </div>
      <div class="card-title">
        <router-link class="font-weight-bold font-size-3 btn btn-success" :to="{name: 'event-create'}">Thêm mới</router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="form-group col-md-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text line-height-0 py-0">
                  <span class="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000" fill-rule="nonzero"
                              opacity="0.3"></path>
                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000"
                              fill-rule="nonzero"></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <input type="text" class="form-control" placeholder="Tiêu đề" v-model="formSearch.title" aria-describedby="basic-addon2" @keyup="handleFormSearch($event)">
            </div>
          </div>
<!--          <div class="form-group col-md-3">-->
<!--            <div class="form-group">-->
<!--              <el-select v-model="formSearch.area" filterable class="w-100" placeholder="Chọn khu vực" @change="handleQueryArea($event)">-->
<!--                <el-option-->
<!--                        v-for="item in areas"-->
<!--                        :key="item.id"-->
<!--                        :label="item.name"-->
<!--                        :value="item.id"-->
<!--                        :class="classes"-->
<!--                >-->
<!--                  <span style="float: left">{{ item.name }}</span>-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="form-group col-md-3">-->
<!--            <div class="form-group">-->
<!--              <el-select v-model="formSearch.center" filterable class="w-100" placeholder="Chọn trung tâm" @change="handleQueryCenter($event)">-->
<!--                <el-option-->
<!--                        v-for="item in centers"-->
<!--                        :key="item.id"-->
<!--                        :label="item.name"-->
<!--                        :value="item.id"-->
<!--                        :class="classes"-->
<!--                >-->
<!--                  <span style="float: left">{{ item.name }}</span>-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </div>-->
<!--          </div>-->
          <div class="form-group col-md-3">
            <div class="form-group">
              <date-picker v-model="formSearch.start_date" valueType="format" @input="handleFormSearch" placeholder="Từ ngày"></date-picker>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div class="form-group">
              <date-picker v-model="formSearch.end_date" valueType="format" @input="handleFormSearch" placeholder="Đến ngày"></date-picker>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive">
          <table class="table table-bordered m-0 table-vertical-center">
            <thead>
              <tr>
                <th rowspan="2" class="event-title">Mã</th>
                <th rowspan="2" class="event-title">Tiêu đề</th>
                <th colspan="6" class="event-title">Điều kiện áp dụng</th>
                <th rowspan="2" class="event-title" style="min-width: 100px">Hành động</th>
              </tr>
              <tr>
                <th class="event-title">Khu vực</th>
                <th class="event-title">Trung tâm</th>
                <th class="event-title">Khóa học</th>
                <th class="event-title" style="min-width: 100px">Ngày bắt đầu</th>
                <th class="event-title" style="min-width: 100px">Ngày kết thúc</th>
                <th class="event-title">Số lượng team</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="events.length > 0" v-for="event in events">
                <td>{{ event.id }}</td>
                <td>{{ event.title }}</td>
                <td>
                  <span v-if="event.areas.length > 0" v-for="(area, key) in event.areas" class="badge badge-success badge-custom">{{ event.areas[key] }}</span>
                </td>
                <td>
                  <span v-if="event.centers.length > 0" v-for="(center, key) in event.centers" class="badge badge-success badge-custom">{{ event.centers[key] }}</span>
                </td>
                <td>
                  <span v-if="event.courses.length > 0" v-for="(course, key) in event.courses" class="badge badge-success badge-custom">{{ event.courses[key] }}</span>
                </td>
                <td>{{ event.start_date }}</td>
                <td>{{ event.end_date }}</td>
                <td>{{ event.number_team }}</td>
                <td>
                  <router-link :to="{ name: 'event-edit', params: {id : event.id} }" title="Sửa" class="btn btn-sm btn-icon mr-2 btn-outline-info">
                     <i class="fas fa-pen-nib"></i>
                  </router-link>
                  <a title="Xóa" @click="deleteEvent(event.id)" href="javascript:"
                     class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                </td>
              </tr>
              <tr v-if="events.length == 0">
                <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
              v-model="page"
              :page-count="last_page"
              :page-range="3"
              :margin-pages="1"
              :click-handler="clickCallback"
              :prev-text="'Trước'"
              :next-text="'Sau'"
              :container-class="'pagination b-pagination'"
              :pageLinkClass="'page-link'"
              :next-link-class="'next-link-item'"
              :prev-link-class="'prev-link-item'"
              :prev-class="'page-link'"
              :next-class="'page-link'"
              :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
    import Swal from "sweetalert2";
    import Multiselect from 'vue-multiselect';
    import {mapGetters} from "vuex";
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import DatePicker from "vue2-datepicker";
    import 'vue2-datepicker/index.css';
    import '@/assets/sass/custom/custom_event.scss';
    import {DELETE_EVENT, GET_EVENTS} from "../../../core/services/store/event/event.module";

    export default {
        components: {
            DatePicker,
            Multiselect
        },
        name: "Event-List",
        data() {
            return {
                areas: [],
                centers: [],
                formSearch: {
                    title: '',
                    area: '',
                    center: '',
                    startDate: '',
                    endDate: ''
                },
                events: [],
                page: 1,
                last_page: 1,
            }
        },
        mounted() {
            this.getListEvents();
             this.$store.dispatch(SET_BREADCRUMB, [
                  {title: "Quản lý sự kiện", route: 'event-list'},
                  {title: "Danh sách sự kiện"}
             ]);
        },
        methods : {
            handleFormSearch() {
                this.page = 1
                this.getListEvents()
            },
            getListEvents() {
                this.$store.dispatch(GET_EVENTS, {
                    page: this.page,
                    title: this.formSearch.title,
                    area: this.formSearch.area,
                    center: this.formSearch.center,
                    start_date: this.formSearch.start_date,
                    end_date: this.formSearch.end_date
                }).then((res) => {
                    this.events = res.data
                    this.last_page = res.pagination.last_page
                });
            },
            clickCallback(obj) {
                this.page = obj
                this.getListEvents()
            },
            deleteEvent(id) {
                Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(DELETE_EVENT, id).then((res) => {
                            if (res.error) {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'danger',
                                    solid: true
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'success',
                                    solid: true
                                });
                                this.getListEvents();
                            }
                        }).catch((res) => {
                            this.$bvToast.toast(res.data.message, {
                                title: 'Xóa',
                                variant: 'danger',
                                solid: true
                            });
                        });
                    }
                });
            },

        }
    }
</script>

<style scoped>

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
