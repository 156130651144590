<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Sale lên cấp</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <div class="input-group row">
            <div class="col-md-2">
              <label> Thông tin nhân viên
                <span class="text-danger"></span></label>
              <div class="form-group">
                <input type="text" v-model="query.keyword" placeholder="Nhập tên, SDT, mã nhân viên" class="form-control" v-on:input="searchByKeyword">
              </div>
            </div>
            <div class="col-md-2">
              <label> Chọn tháng
                <span class="text-danger"></span></label>
              <div class="form-group">
                <date-picker v-model="query.start_date" placeholder="Chọn tháng" :full-month-name="true" type="month" format="MM-YYYY" :clear-button="true"  valueType="MM-YYYY" class="w-100 date-picker-level-up" @input="selectDate"></date-picker>
              </div>
            </div>
            <div class="col-md-2">
              <label> Chọn chi nhánh
                <span class="text-danger"></span></label>
              <div class="form-group">
                <multiselect v-model="cache_branch" :options="branches"
                             placeholder="Chọn chi nhánh"
                             :custom-label="({ name_alias, name }) => `${name_alias ? name_alias : name}`"
                             track-by="name"
                             @input="selectBranch($event)"
                ></multiselect>
              </div>
            </div>
            <div class="col-md-2">
              <label> Chọn chức vụ
                <span class="text-danger"></span></label>
              <div class="form-group">
                <multiselect v-model="cache_current_account_type" :options="positions"
                             placeholder="Chọn chức vụ"
                             label="name"
                             track-by="name"
                             @input="selectPosition($event)"
                ></multiselect>
              </div>
            </div>
            <div class="col-md-2">
              <label> Chọn trạng thái
                <span class="text-danger"></span></label>
              <div class="form-group">
                <multiselect v-model="cache_status" :options="statuses"
                             placeholder="Chọn trạng thái"
                             label="name"
                             track-by="name"
                             @input="selectStatus($event)"
                ></multiselect>
              </div>
            </div>
            <div class="col-md-2">
              <label>
                <span class="text-danger"></span></label>
              <div class="form-group mt-3">
                <button class="btn btn-primary" @click="searchSalePromotion">Tìm kiếm</button>
              </div>
            </div>
          </div>
          <div class="input-group row">
            <div class="form-group">
              <div class="col-md-12">
                <button class="btn btn-success" @click="changeStatusAll(1)">Duyệt tất cả</button>
                <button class="btn btn-warning" @click="changeStatusAll(2)">Từ chối tất cả</button>
                <button class="btn btn-primary" @click="refreshList">Làm mới danh sách</button>
              </div>
            </div>
          </div>
          <div class="example-preview table-responsive">
            <table class="table table-vertical-center">
              <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Tên</th>
                <th scope="col">Mã NV	</th>
                <th scope="col">Văn phòng</th>
                <th scope="col">Chức vụ trước duyệt</th>
                <th scope="col">Chức vụ sau duyệt	</th>
                <th scope="col">Doanh số tháng trước</th>
                <th scope="col">Trạng thái</th>
                <th scope="col">Kỳ xét duyệt</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(userPromotion,index) in getAllUserPromotions" :key="index">
                <td class="">{{++index}}</td>
                <td class="">{{userPromotion.user.name}}</td>
                <td class="">{{userPromotion.user.id + 100000}}</td>
                <td class="">{{userPromotion.branch ? (userPromotion.branch.name_alias ? userPromotion.branch.name_alias : userPromotion.branch.name) : ''}}</td>
                <td class="">{{userPromotion.position_before.name}}</td>
                <td class="">{{userPromotion.position_after.name}}</td>
                <td class="">{{userPromotion.sales | toThousandFilter}} ₫</td>
                <td class="" v-if="userPromotion.status === 1"><span class="badge badge-success">Đã duyệt</span></td>
                <td class="" v-else-if="userPromotion.status === 2"><span class="badge badge-warning">Đã từ chối</span></td>
                <td class="" v-else>
                  <b-dropdown size="sm" variant="primary" text="Chưa xử lý">
                    <b-dropdown-item @click="changeStatus(userPromotion.id, 1)"><a href="javascript:">Duyệt</a></b-dropdown-item>
                    <b-dropdown-item @click="changeStatus(userPromotion.id, 2)"><a href="javascript:">Từ chối</a></b-dropdown-item>
                  </b-dropdown>
                <td class="">{{ renderKyXetDuyet(userPromotion) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="edu-paginate mx-auto text-center">
        <paginate
            v-model="query.page"
            :page-count="total_pages"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'Trước'"
            :next-text="'Sau'"
            :container-class="'pagination b-pagination'"
            :pageLinkClass="'page-link'"
            :page-class="'page-item'"
            :next-link-class="'next-link-item'"
            :prev-link-class="'prev-link-item'"
            :prev-class="'page-link'"
            :next-class="'page-link'"
        >
        </paginate>
      </div>
    </div>
    <div class="vld-parent">
      <loading :active.sync="isLoading"
               :can-cancel="true"
               :on-cancel="onCancel"
               :is-full-page="fullPage"></loading>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import {
  CHANGE_STATUS_PROMOTION, CHANGE_STATUS_PROMOTION_ALL,
  GET_USER_PROMOTION, REFRESH_USER_PROMOTION
} from "../../../core/services/store/user/user_promotion_degradation.module";
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from "sweetalert2";
import {DELETE_TEACHER} from "../../../core/services/store/teacher/teacher.module";

const _ = require('lodash');

export default {
  name: "Level-Up",
  data() {
    return {
      query : {
        page: 1,
        start_date : '',
        branch_id : '',
        current_account_type : '',
        status : '',
      },
      classSpan : {
        success : 'badge-success',
        warning : 'badge-warning',
        danger : 'badge-danger',
      },
      branchesByCenter : [],
      /* options */
      branches : [],
      positions : [{name : 'Sale Expert', value : 2}, {name : 'Sale Member',value: 3}, {name : 'Sale Intern', value : 4}],
      statuses : [{name : 'Chưa xử lý', value: 0}, {name : 'Đã duyệt', value : 1} , {name : 'Đã từ chối', value: 2}],
      /*Cache */
      cache_status : '',
      cache_current_account_type : '',
      cache_branch : '',
      /*Loader */
      isLoading : false,
      fullPage : true,
      payload : {
        limit : 1000000,
        status : 1
      },
    };
  },
  components : {
    Multiselect,DatePicker,Loading
  },
  async created() {
    await this.getBranches();
    await this.setParams();
    await this.$store.dispatch(GET_USER_PROMOTION,this.query);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý nhân sự"},
      { title: "Sale lên cấp" }
    ]);
  },
  computed: {
    ...mapGetters(["getAllUserPromotions","getUserPromotionPaginate"]),
    total_pages() {
      return this.getUserPromotionPaginate?.total_pages || 1;
    },
  },
  methods: {
    async getBranches() {
      await this.$store.dispatch(GET_BRANCHES,this.payload).then((res)=>{
        this.branches = res.data.data;
        this.branchesByCenter = res.data.data;
      });
    },
    searchByKeyword : function (){
      this.pushParams();
    },
    selectDate : function (){
      this.pushParams();
    },
    selectBranch: function ($event){
      this.query.branch_id = $event != null ? $event.id : '';
      this.pushParams();
    },
    selectPosition: function ($event){
      this.query.current_account_type = $event != null ? $event.value : '';
      this.pushParams();
    },
    selectStatus: function ($event){
      this.query.status = $event != null ? $event.value : '';
      this.pushParams();
    },
    changeStatus : function (id, value) {
      this.$store.dispatch(CHANGE_STATUS_PROMOTION, {
        id : id,
        params : { status : value}
      }).then((res) => {
        if (!res.error){
          this.$bvToast.toast(res.message, {
            title: 'Thành công',
            variant: 'success',
            solid: true
          });
        }
      })
    },
    changeStatusAll : function (value){
      this.query.new_status = value;
      this.query.is_change_all = 99;
      this.$store.dispatch(CHANGE_STATUS_PROMOTION_ALL, this.query).then((res) => {
        if (!res.error){
          this.query.is_change_all = '';
          this.query.new_status = '';
          this.$bvToast.toast(res.message, {
            title: 'Thành công',
            variant: 'success',
            solid: true
          });
          this.getQuery();
        }
      })
    },
    refreshList : function (){
      Swal.fire({
        title: "Bạn có chắc chắn muốn làm mới danh sách?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
        html: "<div style='color: red'> Lưu ý: Làm mới danh sách chỉ có tác dụng cho kỳ xét duyệt của tháng hiện tại và CHƯA XỬ LÝ bất kỳ trường hợp nào. </div>",
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.$store.dispatch(REFRESH_USER_PROMOTION).then((res) => {
            if (!res.error){
              this.$bvToast.toast(res.message, {
                title: 'Thành công',
                variant: 'success',
                solid: true
              });
              this.query.is_change_all = '';
              this.query.new_status = '';
              this.isLoading = false;
              this.getQuery();
            }
          })
        }
      });
    },
    setParams : async function (){
      this.query.page = parseInt(this.$route.query.page ? this.$route.query.page : 1);
      let branch_id = this.query.branch_id = this.$route.query.branch_id ? this.$route.query.branch_id : '';
      this.cache_branch = _.find(this.branches, function (branch){
        return branch.id === parseInt(branch_id);
      });
      let status = this.$route.query.status ? this.$route.query.status : '';
      this.cache_status  = _.find(this.statuses, function (obj){
        return obj.value === parseInt(status);
      })
      let current_account_type = this.$route.query.current_account_type ? this.$route.query.current_account_type : '';
      this.cache_current_account_type  = _.find(this.positions, function (obj){
        return obj.value === parseInt(current_account_type);
      });
      this.query.start_date = this.$route.query.start_date ? this.$route.query.start_date : '';
      this.query.status = status;
      this.query.current_account_type = current_account_type;
      this.query.keyword = this.$route.query.keyword ? this.$route.query.keyword : '';
    },
    searchSalePromotion : function() {
      this.isLoading = true;
      this.getQuery();
    },
    clickCallback(pageNum) {
      this.query.page = pageNum;
      this.pushParams();
      this.isLoading = true;
      this.$store.dispatch(GET_USER_PROMOTION,this.query).then((res)=>{
        this.isLoading = false;
      });
    },
    pushParams : function (){
      this.$router.push({ path : '/sale/promotion' , query : this.query});
    },
    getQuery : function (){
      this.$store.dispatch(GET_USER_PROMOTION,this.query).then((res) => {
        this.query.page = 1;
        this.isLoading = false;
      });
    },
    onCancel :function () {
      console.log('User cancelled the loader.')
    },
    renderKyXetDuyet : function (promotion){
      let month = promotion.month !== 12 ? promotion.month + 1 : 1;
      let year = promotion.month !== 12 ? promotion.year : promotion.year + 1;
      return month + ' - ' + year;
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.date-picker-level-up input{
  height: 39px;
}
</style>
