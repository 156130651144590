<template>
	<div class="text-center">
		<img src="../../../../../../public/media/test-input/success.jpg" alt="">
		<h1 style="font-size: 16px;color: #40C057;
" class="mt-2">Thành công</h1>
		<button type="button" class="btn btn-secondary mt-3" @click="closeModal">Đóng</button>
	</div>
</template>

<script>
export default {
	name: "SuccessCreateInput",
	methods: {
		closeModal() {
			this.$emit('close-modal');
		}
	}
}
</script>

<style scoped>

</style>