<template>
    <!--begin::Card-->
    <div class="card card-custom gutter-b">
        <div class="card-header">
            <div class="card-title">
                <h3 class="card-label">Database Cluster </h3>
            </div>
        </div>
        <div class="card-body">
            <!--begin::Example-->
            <el-tabs tab-position="left">
                <el-tab-pane label="Secure this database cluster">
                    <h3>Restrict inbound connections</h3>
                    <p>To restrict connections to trusted sources, add at least one inbound source below. When you do,
                        all other public and private connections will be denied. Why is this so important?</p>
                    <h4>Add trusted sources</h4>
                    <p> Search for Droplets, Kubernetes clusters, Apps and tags, or enter a specific IP (IPv4 CIDR).</p>
                    {{ valueIp }}
                    <edutalk-el-select
                         v-model="value"
                         multiple
                         class="w-100"
                         filterable
                         allow-create
                         popper-append-to-body
                         default-first-option
                         validate-ip
                         :validate-options="optionDefault"
                         @change="changeValue"
                         placeholder="Search for sources">
                        <el-option
                             v-for="item in options"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                        </el-option>
                    </edutalk-el-select>
                    <p>Note: Restricting access to specific ports or IPv6 addresses is not currently supported.

                        Continue, I'll do this later</p>
                    <div>
                        <el-button :loading="button_loading" @click="submitIp" class="btn btn-success">Submit
                        </el-button>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="Connection details">
                    <h3 style="margin-bottom: 20px">Connect to this database cluster</h3>
                    <div class="database-content">
                        <div class="user">
                            <div>
                                <el-button @click="copyUser" class="btn btn-success btn-xs"><i class="fas fa-copy"></i> copy</el-button>
                            </div>
                            <div>
                                <div class="database-detail">username = {{ user }}</div>
                                <div class="database-detail">password = {{ password_show }}
                                    <el-button @click="handleShowPassword" style="border: none;background: unset; height: 20px;width: 30px;padding: 0;color: blue;margin-left: 15px">{{ is_show_password }}</el-button>
                                </div>
                                <div class="database-detail">host = 68.183.180.5</div>
                                <div class="database-detail">post = 25060</div>
                                <div class="database-detail">database = {{ database }}</div>
                                <div class="database-detail">sslmode = REQUIRED</div>
                            </div>
                        </div>
                        <hr>
                        <div class="database-select-footer">
                            <div>User:
                                <el-select v-model="user" placeholder="Chọn user">
                                    <el-option
                                         v-for="(item, key) in users"
                                         :key="key"
                                         :value="item.id"
                                         :label="item.label"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div>Database:
                                <el-select v-model="database" placeholder="Chọn database">
                                    <el-option
                                         v-for="(item, key) in databases"
                                         :key="key"
                                         :value="item.id"
                                         :label="item.label"
                                    ></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="Next steps">
                    <div class="database-content">
                        <p>mysql -u {{ user }} -p{{password}} -h 68.183.180.5 - p 25060 </p>
                        <div style="display: flex;justify-content: flex-end">
                            <el-button @click="copyQuery" class="btn btn-success btn-xs"><i class="fas fa-copy"></i> copy</el-button>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <!--end::Example-->
        </div>
    </div>
    <!--end::Card-->
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_IP, GET_IP_ADDED, STORE_IP} from "@/core/services/store/system/setting.module";
import EdutalkElSelect from "../../../view/content/select/edutalk/src/select";

const _ = require('lodash');
export default {
    components: {EdutalkElSelect},
    data() {
        return {
            button_loading: false,
            optionDefault: [
                {'label': `Edutalk Node`, 'value': '206.189.46.158'},
                {'label': `Edutalk Staging`, 'value': '103.253.147.140'},
                {'label': `Edutalk Beta`, 'value': '128.199.167.7'},
                {'label': `Edutalk Help`, 'value': '159.65.15.189'},
                {'label': `Edutalk Live`, 'value': '68.183.180.5'},
            ],
            releases: [],
            value: [],
            valueIp: [],
            options: [],
            localIP: 0,
            user: 'doadmin',
            password: 'xpyb03h5qzjp875r',
            is_show_password: 'show',
            password_show: '',
            users: [{
                id: 'doadmin',
                label: 'doadmin'
            }],
            database: 'beta-api',
            databases: [{
                id: 'beta-api',
                label: 'Edutalk Beta',
            }, {
                id: 'staging',
                label: 'Edutalk Staging',
            }],
        };
    },
    methods: {
        copyQuery() {
            let text = `mysql -u ${ this.user } -p${this.password} -h 68.183.180.5 - p 25060 `;
            navigator.clipboard.writeText(text).then(() => {
                this.$bvToast.toast(text, {
                    title: 'Copy thành công',
                    variant: 'success',
                    solid: true
                });
            }, (err) => {
                this.$bvToast.toast('Copy thất bại', {
                    title: 'Thất bại',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        copyUser() {
            let text = `username = ${this.user} password = ${this.password} host = 68.183.180.5 post = 25060 database = ${this.database } sslmode = REQUIRED`;
            navigator.clipboard.writeText(text).then(() => {
                this.$bvToast.toast(text, {
                    title: 'Copy thành công',
                    variant: 'success',
                    solid: true
                });
            }, (err) => {
                this.$bvToast.toast('Copy thất bại', {
                    title: 'Thất bại',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        submitIp() {
            let payload = {
                valueIp: this.valueIp,
                optionDefault: this.optionDefault
            }
            this.button_loading = true;
            this.$store.dispatch(STORE_IP, payload).then((response) => {
                this.button_loading = false;
                this.resetValue();
                this.$bvToast.toast('Thành công', {
                    title: 'Lưu dữ liệu',
                    variant: 'success',
                    solid: true
                });
            }).catch(errors => {
                this.$bvToast.toast(errors, {
                    title: 'Thất bại',
                    variant: 'danger',
                    solid: true
                });
            });
        },

        resetValue() {
            let option = [];
            _.each(this.value, (item) => {
                let option_label = _.find(this.optionDefault, {'label': item});
                let option_value = _.find(this.optionDefault, {'value': item});
                if (!option_label && !option_value) {
                    if (this.validateIp(item)) {
                        option.push(item);
                    }
                } else {
                    option.push(item);
                }
            });
            this.value = [];
            this.value = option;
        },
        handleShowPassword() {
            this.is_show_password = this.is_show_password === 'show' ? 'hide' : 'show';
            this.getPasswordShow();
        },
        getPasswordShow() {
            if (this.is_show_password === 'show') {
                let chart = '*';
                this.password_show = chart.repeat(this.password.length);
            } else {
                this.password_show = this.password;
            }
        },

        changeValue() {
            this.rewriteOption();
            this.getValueIP();
        },

        getValueIP() {
            let option = [];
            _.each(this.value, (item) => {
                let option_label = _.find(this.optionDefault, {'label': item});
                let option_value = _.find(this.optionDefault, {'value': item});
                if (option_label) {
                    option.push(option_label.value);
                } else if (option_value) {
                    option.push(option_value.value);
                } else {
                    if (this.validateIp(item)) {
                        option.push(item);
                    }
                }
            })
            this.valueIp = option;
        },

        validateIp(item) {
            let split = item.split(".");
            if (split.length !== 4) {
                return false;
            }
            let result = true;
            split.forEach(item => {
                if (!(item >= 0 && item <= 255)) {
                    result = false;
                }
            })
            return result;
        },

        getIPAddedToServe() {
            this.$store.dispatch(GET_IP_ADDED).then((response) => {
                _.each(response, (item) => {
                    this.value.push(item.name);
                })
                this.rewriteOption();
                this.getValueIP();
            });
        },

        rewriteOption() {
            let option = [];
            _.map(this.optionDefault, (item) => {
                let inArray = this.value.includes(item.value) || this.value.includes(item.label);
                if (!inArray) {
                    option.push(item)
                }
            })
            this.options = option;
        },

        getCurrentIP() {
            this.$store.dispatch(GET_IP).then((response) => {
                let currentIP = response.data.ip;
                this.localIP = currentIP;
                this.optionDefault.push({'label': `Your current computer's IP (${currentIP})`, 'value': currentIP})
            });
        }
    },
    async mounted() {
        this.getPasswordShow();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Release note"}
        ]);
        await this.getCurrentIP()
        await this.getIPAddedToServe();
    }
}
</script>
<style>
.database-content {
    background-color: #eef0f8;
    border-radius: 5px;
    padding: 15px;
    width: 100%;
    /*display: flex;*/
}

.database-content .user {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.database-select-footer {
    display: flex;
    justify-content: space-between;
}

.database-detail {
    width: 100%;
    margin-bottom: 5px;
}
</style>