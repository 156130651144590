<template>
  <div class="bg-white" style="overflow: hidden">
    <div class="page-header">
      <h3 class="page-title" style="font-size: 18px;">
        Chi tiết chương trình: {{ dataProgram.name }}
      </h3>
    </div>
    <div class="d-flex">
        <div class="col-xxl-6 col-md-6">
            <label for=""><b>Thời gian bắt đầu</b></label>
            <div class="form-group w-100 shadow-sm">
                <el-date-picker
                  disabled
                  v-model="dataProgram.start_date"
                  @change="handleTime"
                  class="w-100"
                  type="datetime"
                  placeholder="Thời gian bắt đầu">
                </el-date-picker>
        </div>
      </div>

        <div class="col-xxl-6 col-md-6">
            <label for=""><b>Thời gian kết thúc</b></label>
            <div class="form-group w-100 shadow-sm">
                <el-date-picker
                  disabled
                  v-model="dataProgram.end_date"
                  @change="handleTime"
                  class="w-100"
                  type="datetime"
                  placeholder="Thời gian kết thúc">
                </el-date-picker>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end">
      <el-button type="primary" class="p-2 mb-4 mr-7" style="border-radius: 5px; width: 150px; color: white" @click="dialogEditProgram = true">
        <i class="el-icon-edit text-white mr-1"></i>
        Sửa thông tin
      </el-button>
    </div>

    <div style="width: 100%; height: 1px; background: #D6D5D5"></div>

    <div class="layout-detail">
      <div>
         <ListPromotion @close="closeDialog" :start_time="query.start_time" :end_time="query.end_time"></ListPromotion>
      </div>
      <div>
       <ListStudentRegister></ListStudentRegister>
      </div>
    </div>
    <el-dialog title="Sửa thông tin" :visible.sync="dialogEditProgram">
      <EditProgramGenz @closeDialogEdit="dialogEditProgram=false" @hanldeLocalDataProgram="getProgaramGenz()" :dataProgram="dataProgram"></EditProgramGenz>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ListStudentRegister from "./Components/ListStudentRegister.vue";
import ListPromotion from "./Components/ListPromotion.vue";
import EditProgramGenz from "./Components/EditProgramGenz.vue";
import { SHOW_PROGRAM_GENZ } from "../../../core/services/store/rewards-programs/rewardsPrograms.module";
import moment from "moment-timezone";

export default {
  components: {ListStudentRegister, ListPromotion, EditProgramGenz},

  data() {
    return {
      query: {
        start_time: '',
        end_time: ''
      },
      dialogEditProgram: false,
      dataProgram: []
    }
  },
  mounted() {
    this.getProgaramGenz();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Thông tin chương trình", route: "promotion-detail" },
    ]);
    document.body.classList.remove('no-scroll');
  },

  methods: {
    getProgaramGenz() {
       this.$store.dispatch(SHOW_PROGRAM_GENZ,{
        id_program: this.$route.query.promotion_id
      }).then(data => {
        if (!data.data.error) {
          this.dataProgram = data.data;
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
.page-header {
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  align-items: center;
}

.layout-detail {
  padding: 18px 24px;
  display: grid;
  grid-template-columns: 4fr 8fr;
  gap: 25px
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .layout-detail {
    display: grid;
    grid-template-columns: 1fr;
  }
}
</style>