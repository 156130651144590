<template>
  <div class="table-responsive mt-3" style="overflow: auto; padding-bottom: 30px; max-height: 70vh">
    <table class="table" style="text-align: center;">
      <thead>
      <tr style="border: 1px solid #EBEDF3">
        <th scope="col" class="w-60px header-table">STT</th>
        <th scope="col" class="w-100px header-table">Mã nhân sự</th>
        <th scope="col" class="min-w-100px header-table">Vị trí</th>
        <th scope="col" class="min-w-100px header-table">Đơn vị</th>
        <th scope="col" class="min-w-120px header-table">KRA</th>
        <th scope="col" class="min-w-120px header-table">Yêu cầu thành phẩm</th>
        <th scope="col" class="w-80px header-table">Số giờ</th>
        <th scope="col" class="w-80px header-table">Trọng số</th>
        <th scope="col" class="min-w-180px header-table">Lỗi</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item of responseError" :key="item?.index" style="border: 1px solid #EBEDF3; border-top: 0">
        <td>{{item?.index}}</td>
        <td style="text-align: start; text-transform: uppercase">{{item?.userId}}</td>
        <td style="text-align: start">{{item?.role}}</td>
        <td><p style="white-space: pre-wrap; text-align: start; word-break: break-word" v-html="item.department"></p></td>
        <td style="display: flex; justify-content: start; border-top: 0 !important; word-break: break-word; text-align: start">{{item?.kra}}</td>
        <td style="text-align: start; word-break: break-word">{{item?.requestResult}}</td>
        <td style="text-align: start">{{item?.hours}}</td>
        <td style="text-align: start; word-break: break-word">{{item?.weight}}</td>
        <td><div v-if="item.errors.length > 0" style="text-align: start; display: flex; flex-direction: column">
          <span v-for="(error, index) in item.errors" :key="index + error" style="word-break: break-word">
            {{error}}
          </span>
        </div></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TableErrorKRA',
  data() {
    return {}
  },
  props: {
    responseError: {
      type: Array,
      default: []
    }
  }
}
</script>

<style lang="css" scoped>
  .header-table {
    background: #F8F8F8;
    text-align: start;
    word-break: break-word;
  }
</style>