<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Chuyển nhượng khóa học</h3>
        </div>
      </div>
      <div class="card-body">
        <div class="example mb-10">
          <div class="form-group">
            <label class="d-flex justify-content-center">
              <strong>Tìm học viên muốn chuyển nhượng</strong>
            </label>
            <div class="d-flex justify-content-center">
              <el-select v-model="student_id"
                         clearable
                         filterable
                         remote
                         reserve-keyword
                         placeholder="Nhập số điện thoại"
                         ref="new_student"
                         class="w-50"
                         :remote-method="remoteMethod"
                         :loading="loading"
                         @change="getUserById"
                         @clear="clearData"
              >
                <template slot="empty" class="align-items-center justify-content-center">
                </template>
                  <el-option v-for="user in userSearch"
                             :key="user.id"
                             :label="user.id + 100000 +  ' - ' + user.name + ' - ' + user.phone"
                             :value="user.id">
                  </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="example mb-10 overflow-auto table-responsive" v-if="showContracts">
          <table class="table table-bordered table-hover">
            <caption>Danh sách hợp đồng cần chuyển</caption>
            <thead>
              <tr>
                <th style="width: 15px"></th>
                <th scope="col" class="min-w-100px">Thời gian tạo</th>
                <th scope="col" class="min-w-100px">Mã hợp đồng</th>
                <th scope="col" class="min-w-120px">Số tiền phải đóng</th>
                <th scope="col" class="min-w-120px">Trạng thái</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in fromUser.contracts" :key="index">
              <td class="">
                <input type="radio" v-model="selectedContract" :value="item.id">
              <td>{{ item.created_at | formatDate }}</td>
              <td>{{ item.id }}</td>
              <td>{{ formatPrice(item.total_after_voucher, 'đ') }}</td>
              <td>{{ item.is_cancel ? "Đã hủy" : "Tồn tại" }}</td>
            </tr>
            </tbody>
          </table>
          <div class="float-right">
            <el-button type="primary" @click="checkUserBusiness()">Xác nhận</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectCustomer from "@/view/content/SelectCustomer";
import {
  COURSE_TRANSFER_CHECK_BUSINESS,
  FROM_USER_INFORMATION
} from "@/core/services/store/course/courseTransfer.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_USER_BY_ID, SEARCH_CUSTOMER_BY_OR_ID} from "@/core/services/store/user/users.module";
export default {
  name: "CourseTransferCreate",
  components: { SelectCustomer },
  data() {
    return {
      is_show : false,
      user_id: '',
      cache_customer : '',
      courses: [],
      user: {},
      fromUser: {
        contracts: []
      },
      loading: false,
      student_id: '',
      selectedContract: false,
      userSearch: [],
      showContracts: false
    };
  },
  computed : {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Chuyển nhượng khóa học", route: 'course-transfers-list'},
      {title : 'Thêm mới chuyển nhượng'},
    ])
  },
  methods : {
    selectCustomer: function (value){
      if (value){
        this.is_show = true;
        this.user_id = value;
        this.checkUserBusiness();
      }else {
        this.is_show = false;
      }
    },
    remoteMethod: function (query){
      if (query !== '' && query.length >=6 && query.length <= 10) {
        const params = {
          search_customer_by_phoneOrId: query,
          limit: 10000,
          status: 1,
          is_staff: 0,
        }
        this.loading = true;
        let vm = this;
        setTimeout(function() {
          vm.getStudents(params);
        }, 200)
      }
    },
    getUserById() {
      this.showContracts = false;
      if (this.student_id){
        this.$store.dispatch(FROM_USER_INFORMATION, this.student_id).then((res) => {
          this.fromUser = res.data;
          if (this.fromUser.contracts.length === 1){
            this.selectedContract = this.fromUser.contracts[0].id;
            this.checkUserBusiness();
          }else if (this.fromUser.contracts.length === 0){
            this.$notify({
              title: 'Cảnh báo',
              message: 'Không thể chọn do khách hàng chưa có hợp đồng',
              type: 'warning'
            });
            return;
          } else {
            this.showContracts = true;
          }
        });
      }else {
        this.showContracts = false
      }
    },
    clearData() {
      this.showContracts = false
    },
    getStudents(params) {
      this.$store.dispatch(SEARCH_CUSTOMER_BY_OR_ID, params).then((res) => {
        this.userSearch = res.data.data;
        this.loading = false;
      })
    },
    checkUserBusiness: function (){
      if (!this.selectedContract){
        this.noticeMessage('warning', 'Cảnh báo', 'Vui lòng chọn hợp đồng');
        return;
      }
      if (this.student_id) {
        let params = {user_id : this.student_id, contract_id: this.selectedContract};
        this.$store.dispatch(COURSE_TRANSFER_CHECK_BUSINESS, params).then((res) => {
          this.user = res.data;
          this.$router.push({ name: "course-transfers-select-student", params: { from_student: this.student_id, contract_id: this.selectedContract } }).then(() => {
            this.noticeMessage('success','Thành công','Chọn học viên chuyển thành công');
          })
        }).catch((res) => {
          this.noticeMessage('error', 'Thất bại', res.data?.message);
        })
      }
    },
  },
}
</script>

<style scoped>
#select-customer{
  width: 50%;
}
</style>
