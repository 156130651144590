<template>
    <select class="form-control" :options="options">
    </select>
</template>
<script>
    import {LOGIN} from "../../core/services/store/auth.module";

    export default {
        name: "Select2",
        props: ["options", "value", "placeholder"],
        mounted: function () {
            var vm = this;
            $(this.$el)
                // init select2
                .select2({data: this.options, placeholder: this.placeholder, allowClear: true}).val(this.value);
        },
        watch: {
            value: function(value) {
                // update value
                $(this.$el).val(value).trigger('change');
            },
            options: function (options) {
                // update options
                let vm = this;
                $(this.$el).
                        empty()
                    // init select2
                    .select2({data: options, placeholder: this.placeholder, allowClear: true})
                    .val(this.value)
                    .trigger("change")
                    // emit event on change.
                    .on("change", function () {
                        vm.$emit("input", this.value);
                    });
            }
        },
        destroyed: function () {
            $(this.$el)
                .off()
                .select2("destroy");
        },
    };
</script>
