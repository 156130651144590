import ApiService from "@/core/services/api.service";
import writeXlsxFile from "write-excel-file";

const _ = require('lodash');

export const GET_LIST_COURSE_GIFT = 'course-gift/gift';
export const CREATE_COURSE_GIFT = 'course-gift/create/gift';
export const UPDATE_COURSE_GIFT = 'course-gift/update/gift';
export const EXPORT_COURSE_GIFT = 'course-gift/export/gift';

const state = {};
const getters = {};
const actions = {
    [GET_LIST_COURSE_GIFT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${GET_LIST_COURSE_GIFT}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_COURSE_GIFT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${GET_LIST_COURSE_GIFT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_COURSE_GIFT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${GET_LIST_COURSE_GIFT}/${payload.id}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)

            });
        })
    },
    [EXPORT_COURSE_GIFT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${EXPORT_COURSE_GIFT}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
}
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};