<template>
  <div class="card card-custom gutter-b example example-compact">
    <div class="card-header">
      <h3 class="card-title">Chuyển nhượng khóa học</h3>
    </div>
        <div class="card-body">
          <router-link
              class="font-weight-bold font-size-3 btn btn-secondary btn-sm"
              :to="{ name: 'course-transfers-create' }"
          ><i class="fas fa-arrow-alt-circle-left"></i> Quay lại
          </router-link>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="d-flex justify-content-center"><strong>Tìm học viên nhận khóa học</strong></label>
                <div class="d-flex justify-content-center">
                  <el-select v-model="to_student_id"
                             clearable
                             filterable
                             remote
                             reserve-keyword
                             placeholder="Nhập số điện thoại"
                             ref="new_student"
                             class="w-50"
                             :remote-method="remoteMethod"
                             :loading="loading"
                             @change="getUserById"
                  >
                    <template slot="empty" class="align-items-center justify-content-center">
                      <div class="align-items-center justify-content-center el-select-dropdown__item hover cursor-pointer" v-if="new_phone.length > 0" @click="createNewUser">
                        <div>
                          Không tìm thấy học viên ->
                          <span style="color: #00aff0">Tạo học viên mới</span>
                          <span><i class="fas fa-user-plus" style="color: #00aff0"></i></span>
                        </div>
                      </div>
                    </template>
                    <el-option v-for="user in userSearch"
                               :key="user.id"
                               :label="user.id + 100000 +  ' - ' + user.name + ' - ' + user.phone"
                               :value="user.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-md-around w-100">
              <div class="card from-user">
                <div class="card-header custom-header">
                  <strong>Học viên chuyển nhượng</strong>
                </div>
                <div class="card-body">
                  <div class="from-user-detail">
                    <div class="detail">
                      <span class="detail-title">Họ và tên:</span> <b>{{ fromUser.name }}</b>
                    </div>
                    <div class="detail">
                      <span class="detail-title">Số điện thoại:</span> <b>{{ fromUser.phone }}</b>
                    </div>
                    <div class="detail">
                      <span class="detail-title">Mã học viên:</span> <b>{{ fromUser.id + 100000 }}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card from-user" v-if="show_received">
                <div class="card-header custom-header">
                  <strong>Học viên nhận khóa học</strong>
                </div>
                <div class="card-body">
                  <div class="from-user-detail">
                    <div class="detail">
                      <span class="detail-title">Họ và tên:</span> <b>{{ toUser.name }}</b>
                    </div>
                    <div class="detail">
                      <span class="detail-title">Số điện thoại:</span> <b>{{ toUser.phone }}</b>
                    </div>
                    <div class="detail">
                      <span class="detail-title">Mã học viên:</span> <b>{{ toUser.id + 100000 }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class=" mt-6 mx-auto" v-if="show_received">
              <div class="card course-list">
                <div class="card-header custom-header">
                  <strong>Chọn khóa học chuyển nhượng</strong>
                </div>
                <div class="card-body course-list-body">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                    <form class="form" ref="course-transfer" @submit.prevent="handleSubmit(onSubmit)">
                      <div class="form-group">
                        <div class="d-flex justify-content-center mt-2" v-for="(item, key) in student_register_courses">
                            <el-input type="text" disabled :value="item.course.name"></el-input>
                            <input type="checkbox" :value="item.course.id" v-model="checkList" class="ml-5">
                        </div>
                      </div>
                      <div class="form-group">
                        <el-button type="primary" native-type="submit" :loading="loadingBtn" style="color: white" class="float-right">Xác nhận</el-button>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
            <user-form :dialog-form-visible="dialogFormVisible" :user_phone="new_phone" @successCreate="successCreate" @close="dialogFormVisible = false"></user-form>
          </div>
        </div>
  </div>
</template>
<script>

import {GET_USER_BY_ID, SEARCH_CUSTOMER_BY_OR_ID} from "@/core/services/store/user/users.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import UserForm from "@/view/pages/course-transfer/modal/UserForm";
import {CREATE_COURSE_TRANSFER} from "@/core/services/store/course/courseTransfer.module";
import {FROM_USER_COURSES} from "@/core/services/store/course/courseTransfer.module";
import Swal from "sweetalert2";
const _ = require('lodash');

export default {
  components: {UserForm},
  name: "CourseTransferSelectStudent",
  data() {
    return {
      loading: false,
      loadingBtn: false,
      show_received: false,
      is_show_user_detail: false,
      dialogFormVisible: false,
      fromUser: {
        name: '',
        email: '',
        phone: '',
        id: ''
      },
      toUser: {
        name: '',
        email: '',
        phone: '',
        birthday: '',
        id: '',
      },
      new_phone: '',
      to_student_id: '',
      userSearch: [],
      student_register_courses: [],
      checkList: [],
      from_user_id: parseInt(this.$route.params.from_student),
      contract_id: parseInt(this.$route.params.contract_id),
    }
  },
  computed: {},
  created() {
    this.getUserById();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý chuyển nhượng", route: 'course-transfers-list'},
      {title: "Danh sách chuyển nhượng"}
    ]);
  },
  watch: {},
  methods: {
    remoteMethod: function (query){
      if (query.length >= 10) {
        this.new_phone = query;
        const params = {
          search_customer_by_phoneOrId: query,
          limit: 10000,
          status: 1,
          is_staff: 0,
        }
        this.loading = true;
        let vm = this;
        setTimeout(function() {
          vm.getStudents(params);
        }, 200)
      }else {
        this.new_phone = '';
      }
    },
    getStudents(params) {
      this.$store.dispatch(SEARCH_CUSTOMER_BY_OR_ID, params).then((res) => {
        this.userSearch = res.data.data;
        this.loading = false;
      })
    },
    getUserById() {
      if (this.from_user_id === this.to_student_id){
        this.noticeMessage('warning','Cảnh báo','Người nhận và người chuyển là 2 người khác nhau');
        return;
      }
      if (this.from_user_id){
        this.$store.dispatch(FROM_USER_COURSES, {user_id: this.from_user_id, contract_id: this.contract_id}).then((res) => {
          if (!res.error) {
            this.fromUser = res.data.user;
            this.student_register_courses = res.data.contract.student_register_courses
          }
        });
      }
      if (this.to_student_id){
        this.$store.dispatch(GET_USER_BY_ID, this.to_student_id).then((res) => {
          if (!res.error) {
            this.toUser = res.data;
            this.show_received = true;
          }
        });
      }
    },
    createNewUser() {
      this.dialogFormVisible = true;
    },
    successCreate(user) {
      this.toUser = user;
      this.show_received = true;
    },
    onSubmit() {
      if(this.checkList.length === 0){
        this.noticeMessage('warning','Cảnh báo','Vui lòng chọn khóa học');
        return;
      }
      Swal.fire({
        title: "Bạn chắc chắn muốn chuyển nhượng?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadingBtn = true;
          let studentRegisterCourseSelect = this.student_register_courses.filter((studentRegisterCourse) => this.checkList.includes(studentRegisterCourse.course_id))
              .map(item => item.id)
          let params = {
            from_user_id: this.from_user_id,
            to_user_id: this.to_student_id ? this.to_student_id : this.toUser.id,
            courseIds: this.checkList,
            contract_id: this.contract_id,
            studentRegisterCoursesSelect: studentRegisterCourseSelect
          }
          this.$store.dispatch(CREATE_COURSE_TRANSFER, params).then(() => {
            this.$router.push({name: "course-transfers-list"}).then(() => {
              this.noticeMessage('success', 'Thành công', 'Tạo chuyển nhượng thành công');
            })
          }).catch((e) => {
            this.noticeMessage('error', 'Thất baị', e.data?.message);
          }).finally(() => {
            this.loadingBtn = false;
          });
        }
      });
    },
  },
}
</script>
<style>
a.disable {
  pointer-events: none;
  cursor: default;
  color: #FFFFFF;
  background-color: #8c8ea4;
}
.card.from-user{
 width: 650px;
}
.course-list-body {
  background-color: #FFFFFF;
  width: 650px;
}
.from-user,.course-list {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.card-body{
  background-color: transparent;
}
.from-user-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.detail {
  display: flex;
  margin: 5px 0;
}

.detail-title {
  min-width: 100px;
}
.card-header {
  padding: 1rem 1.25rem;
}
</style>


