<template>
  <div class=" container ">
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent>
        <div class="row  ">
          <div v-for="(item, index) in tab">
            <p class="ml-8 text-decoration" style="cursor: pointer" :class="{'text-primary border_bottom_item text-underline': index === tabCurrent}"
              @click="changePage(index,handleSubmit)">{{ item}}</p>
          </div>
        </div>
        <div class="content_test" >
          <div class="  row rounded  py-5 pr-8" style="background-color: white;">
            <div class="col-xl-6 col-md-12 mb-5 " v-for="(item, index) in skillCriteria"
                 :key="tabCurrent.toString() + index" v-if="tabCurrent === 0">
                <review-skill-child :criteria="item" :error="error"></review-skill-child>
            </div>
            <div class="col-xl-6 col-md-12 mb-5 rounded p-4" v-if="tabCurrent === 0" style="background-color: #F6F6F6" >
              <p class="mb-0 text-dark badge badge-primary" style="font-size: 13px; background-color: #93BEFF ">Overall</p>
              <div class="d-xl-flex d-block mt-4">
                <p class="mt-4">Điểm tổng quan: <span class="text-danger">*</span></p>
                <el-rate class="mt-4 ml-3" v-model="overall.start" ></el-rate>
              </div>
              <div class="mt-4">
                <p>Nhận xét: <span class="text-danger">*</span></p>
                <ValidationProvider name="Nhận xét" :rules="{required:true,min:50}" v-slot="{ errors,classes }"
                                    :vid="'overal'+'comment'">
                  <el-input
                      v-model="overall.comment"
                      :autosize="{ minRows: 4, maxRows: 4 }"
                      type="textarea"
                      placeholder="Vui lòng nhập nhận xét"
                  />
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
              <div v-if="overall.start === 0 && error !==''" style="color: #F64E60">{{error}}</div>
            </div>
            <div style="width: 100%" v-if="tabCurrent === 1">
              <div style="background-color: #F6F6F6" class="rounded p-4 mt-4" v-for="(item,index) in otherComments">
                <p>{{item.title}}<span class="text-danger" v-if="item.type"> *</span></p>
                <ValidationProvider :name="item.title" :rules="{required:!!item.type,min:item.type === 2 || item.type === 0  ? 0:50}" v-slot="{ errors,classes }"
                                    :vid="`item${index}`">
                <el-input
                    v-model="item.comment"
                    :autosize="{ minRows: 4, maxRows: 4 }"
                    type="textarea"
                    placeholder="Nhập nội dung"

                />
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div style="width: 100%" v-if="tabCurrent === 2">
              <div style="background-color: #F6F6F6" class="rounded p-4 mt-4" v-for="(item,index) in conclusion" :key="index">
                <p>{{item.title}}<span :class="{'text-danger':item.type}" > {{item.type?'*':' (nếu có)'}}</span></p>
                <ValidationProvider :name="item.title" :rules="{required:!!item.type,min:item.type === 2 || item.type === 0  ? 0:50}" v-slot="{ errors,classes }"
                                    :vid="`item${index}`">
                  <el-select v-if="item.type === 2" v-model="item.comment" placeholder="Chọn khóa học"  class="w-100">
                    <el-option
                        v-for="item in listCourse"
                        :key="item.name"
                        :label="item.name.toString()"
                        :value="item.name">
                    </el-option>
                  </el-select>
                  <el-input
                      v-else
                      v-model="item.comment"
                      :autosize="{ minRows: 4, maxRows: 4 }"
                      type="textarea"
                      placeholder="Nhập nội dung"

                  />
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="d-flex justify-content-end col-12 mt-3">
              <button type="submit" class="btn btn-primary" @click="handleSubmit(confirm)" :disabled="loading">
                <i v-if="loading" class="el-icon-loading"></i> {{tabCurrent === 2 ? 'Hoàn thành' : 'Tiếp theo'}}
              </button>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import ReviewSkillChild from "@/view/pages/test-input/components/ComponentMarkTheExam/ReviewSkillChild.vue";
import {MAKE_EXAM_SPEAKING} from "@/core/services/store/test/testInput.module";
import router from "@/router";
import {GET_COURSES_LIST} from "@/core/services/store/course/course.module";
import {Cambridge_Mentor_ID, ECOACH_ID, IELTS_MENTOR_ID} from "@/core/option/typeCenterBill";
export default {
  name: "TestSpeakingChild",
  components: {ReviewSkillChild},
  data(){
    return{
      tab:['ĐÁNH GIÁ KỸ NĂNG','NHẬN XÉT KHÁC','KẾT LUẬN'],
      tabCurrent : 0,
      skills:[{title:'Vocabulary',name:'Vocabulary (Từ vựng)'},{title:'Pronunciation',name:'Pronunciation (Phát âm)'},{title:'Interaction',name:'Interaction (Tương tác)'}],
      overall: {title:'Overall',name:'Overall (Tổng quan)',start:0,comment:'',id:4},
      skillCriteria:[],
      otherComments:[],
      conclusion:[],
      listCourse: [],
      error: '',
      loading: false
    }
  },
  created() {
    this.skillCriteria = this.skills.map((skill,index)=>{
      return{
        id:index + 1,
        title:skill.title,
        start:undefined,
        comment: '',
        name:skill.name
      }
    });
    const nameOtherComments = ['Điểm trên trường','Tương tác với Giáo viên','Khả năng tiếp thu','Khó khăn khi học Tiếng Anh'];
    this.otherComments = nameOtherComments.map((name)=>{
      return {
        title : name,
        comment: '',
        type : name === 'Điểm trên trường' ? 0: 1
      }
    });
    const nameConclusion = ['Năng lực','Động lực','Khóa học phù hợp','Hỗ trợ đặc biệt'];
    this.conclusion = nameConclusion.map((name)=>{
      return {
        title : name,
        comment: '',
        type : ['Hỗ trợ đặc biệt','Động lực','Năng lực'].includes(name)? 0 :name === 'Khóa học phù hợp'? 2: 1
      }
    })
    this.getCourse()
  },
  methods:{
    async confirm(){
      if(this.checkStarNull()){
        return
      }
      if(this.tabCurrent !== 2){
        this.tabCurrent ++
        return
      }
      this.skillCriteria.push(this.overall)
      let data = {
        center_id:this.$route.query.centerID,
        type: "speaking",
        criterias: [...this.skillCriteria,{title:'OtherComments',name:'Other comments (Nhận xét khác)',listComment:this.otherComments},{title:'Conclusion',name:'Conclusion (Kết luận)',listComment:this.conclusion}]
      }
      let test_input_schedule_id = this.$route.params.id
      this.loading = true;
      await this.$store.dispatch(MAKE_EXAM_SPEAKING, {id: test_input_schedule_id, data: data})
          .then((data) => {
            this.$message.success('Chấm Speaking thành công');
            router.push({name: 'task-details', params: {id: test_input_schedule_id}})
          }).catch((error) => {
            this.$message.error(error.data.message)
          }).finally(() => {
            this.loading = false;
          })
    },
    getCourse() {
      this.$store.dispatch(GET_COURSES_LIST, {
        center_ids: [Cambridge_Mentor_ID],
        status: 1
      }).then((res) => {
            this.listCourse = res.data
        console.log(this.listCourse);
          });
    },
    checkStarNull(){
      if(this.tabCurrent === 0){
        if(this.skillCriteria.find((skill)=>skill.start === 0) != null || this.overall.start === 0){
          this.error = 'Vui lòng đánh giá sao'
          return true
        }
        this.error = ''
        return false
      }
    },
    changePage(index,handleSubmit){
        if(this.checkStarNull()){
          return
        }
        if(this.tabCurrent > index){
          this.tabCurrent = index
          return;
        }
        handleSubmit(()=>{
          this.tabCurrent = index
        })
    }
  }
}
</script>

<style scoped>
.text-underline{
  text-decoration: underline;
  text-underline-position: under;
}
</style>