<template>
  <div>
    <button v-b-modal="`modal-show-reason-${index}`"
            class="badge badge-pill badge-danger cursor-pointer"
            title="Click để xem lý do"
    >
      Rút tiền
    </button>
    <b-modal :centered="true" :scrollable="true" size="md" ref="modal-show-reason" hide-footer
             :id="'modal-show-reason-' + index"
             title="Lý do rút tiền">
      <div class="example-preview table-responsive">
        <p v-if="item.sub_contracts && item.sub_contracts.reason_withdrawals">
          Lý do rút: 
          <input type="button" value="" v-model="item.sub_contracts.reason_withdrawals.name">
        </p>
        <p v-if="item.sub_contracts && item.sub_contracts.reason">
          Chi tiết lý do: {{item.sub_contracts.reason}}
        </p>
      </div>
      <div class="float-right">
      </div>
    </b-modal>
  </div>
</template>
<script>
import {UPDATE_STUDENT_AMOUNT} from "../../../core/services/store/contract/studentAmount.module";
// Datetime picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';

export default {
  name: "ModalShowReason",
  components: {
    DatePicker
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      }
    }
  },
  data() {
    return {
      reasonWithdraw: ''
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>