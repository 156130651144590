<template>
    <div class="row">
        <div class="col-md-2 text-center mt-9">
            <h5>{{item.day}}</h5>
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <label>Thời gian bắt đầu
                    <span class="text-danger">*</span></label>
                <ValidationProvider :vid="'start_date' + index" name="Thời gian bắt đầu" rules="required"
                                    v-slot="{ errors,classes }">
                    <date-picker v-model="item.start_date"
                                 type="time"
                                 placeholder="Thời gian bắt đầu"
                                 format="HH:mm"
                                 valueType="format"
                                 :class="classes"
                                 disabled
                    ></date-picker>
                    <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                            errors[0] }}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <label>Thời gian kết thúc
                    <span class="text-danger">*</span></label>
                <ValidationProvider :vid="'schedules.' + index + '.end_date'" name="Thời gian kết thúc" rules="required"
                                    v-slot="{ errors,classes }">
                    <date-picker v-model="item.end_date" type="time" valueType="format"
                                 placeholder="Thời gian kết thúc" :class="classes" format="HH:mm"
                                 disabled ></date-picker>
                    <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                            errors[0] }}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
        </div>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default {
        name: "ScheduleItem",
        props: {
            item: Object,
            index: null
        },
        components: {
            DatePicker
        },
        data() {
            return {};
        },
        methods: {},
    }
</script>

<style scoped>
    .mx-datepicker {
        display: block;
        width: 100%;
    }
</style>
