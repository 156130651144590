<template>
  <div class="box-item-information">
    <el-collapse v-if="!is_edutalk">
      <div>
        <el-collapse-item title="Xem chi tiết Rút tiền" name="1" class="mt-2">
          <div v-for="(subcontract, index) in subcontracts" class="mt-2" :key="index">
            <div class="p-3">
              <div class="d-flex justify-content-center" v-if="subcontract.log_cash">
                <strong>{{ subcontract.log_cash.dated_paid | formatDateTime }} <span v-if="subcontract.deleted_at">(Đã hủy)</span></strong>
              </div>
              <div class="mt-2">
                <div class="item d-flex justify-content-between">
                  <p><strong>Số tiền rút:</strong></p>
                  <strong>{{ Math.abs(subcontract.value) | formatVND }}</strong>
                </div>
                <div class="item d-flex justify-content-between">
                  <p><strong>Lý do rút:</strong></p>
                  <strong>{{ subcontract.reason }}</strong>
                </div>
                <div class="item d-flex justify-content-between">
                  <p><strong>Hủy khóa:</strong></p>
                  <strong>{{ renderCourse(subcontract.courses_relation) }}</strong>
                </div>
                <div class="item d-flex justify-content-between">
                  <p><strong>Phí hủy khóa:</strong></p>
                  <strong>{{ subcontract.destroy_fee | formatVND }}</strong>
                </div>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </div>
     <div class="mt-2">
       <el-collapse-item title="Xem chi tiết Hoàn tiền" name="2" class="mt-2">
         <div v-for="(refund, key) in refundData" class="mb-2" :key="key">
           <div class="p-3">
             <div class="d-flex justify-content-center">
               <strong>{{ refund.dated_paid | formatDateTime }}</strong>
             </div>
             <div>
               <div class="item d-flex justify-content-between">
                 <p><strong>Số tiền:</strong></p>
                 <strong>{{ Math.abs(refund.amount) | formatVND }}</strong>
               </div>
               <div class="item d-flex justify-content-between">
                 <p><strong>Chương trình:</strong> </p>
                 <strong>{{ refund.note }}</strong>
               </div>
             </div>
           </div>
         </div>
       </el-collapse-item>
     </div>
      <div>
        <el-collapse-item title="Xem chi tiết Tặng quà" name="3" class="mt-2">
          <div v-for="(gift, key) in giftData">
            <div class="p-3">
              <div class="d-flex justify-content-center">
                <strong>{{ gift.dated_paid | formatDateTime }}</strong>
              </div>
              <div>
                <div class="item d-flex justify-content-between">
                  <p><strong>Số tiền:</strong></p>
                  <strong>{{ Math.abs(gift.amount) | formatVND }}</strong>
                </div>
                <div class="item d-flex justify-content-between">
                  <p><strong>Chương trình:</strong> </p>
                  <strong>{{ gift.note }}</strong>
                </div>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </div>
    </el-collapse>
  </div>

</template>

<script>
export default {
  name: "PaymentInformation",
  props: {
    subcontracts: {
      type: Array,
      default: () => { return [] }
    },
    refundData: {
      type: Array,
      default: () => { return [] }
    },
    giftData: {
      type: Array,
      default: () => { return [] }
    },
    is_edutalk: {
      type: Boolean,
      default: () => { return false }
    },
  },
  data() {
    return {

    };
  },
  methods: {
    renderCourse(courses_relation_data){
      return courses_relation_data.map((item) => item.name).join(', ');
    }
  }
}
</script>

<style scoped>

</style>
