import ApiService from "@/core/services/api.service";
const _ = require('lodash');
export const GET_CUSTOMER_LOG = "get_customer_log";
export const CREATE_CUSTOMER_LOG = "create_customer_log";
export const UPDATE_CUSTOMER_LOG = "update_customer_log";

const state = {};
const getters = {};
const actions = {
    [GET_CUSTOMER_LOG](context, payload) {
        return new Promise(resolve => {
            ApiService.query('customer-log', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_CUSTOMER_LOG](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('customer-log', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_CUSTOMER_LOG](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('customer-log', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
