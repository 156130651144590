<template>
  <div class="col-sm-12 col-md-6  ">
    <div class="card ">
      <div class="container mt-10">
        <h1 class="col-6 " style="font-size:14px ; font-weight: bolder">Đề thi</h1>
        <div class="d-flex justify-content-between">
          <el-input placeholder="Tìm mã đề, câu hỏi" style="width: 40%" v-model="findQuestion" @input="getDataExam()" />
          <ModalImportExam @getExam="getDataExam"></ModalImportExam>
        </div>
        <div class="mt-10 table-responsive" style="height: auto ; max-height: 800px">
          <table class="table table-bordered  table-vertical-center ">
            <thead>
              <tr class="" style="background-color: #F8F8F8 ; font-size: 13px">
                <th>Mã đề</th>
                <th>Câu hỏi</th>
                <th>Câu trả lời</th>
              </tr>
            </thead>
            <tbody v-if="dataExam.length == 0">
              <tr>
                <td colspan="5" class="text-center">
                  Không có dữ liệu.
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="item in dataExam">
                <td>{{ item.codeTitle }}</td>
                <td>
                  <p>{{ item.question }}</p>
                  <img v-if="item.image" :src="item.image">
                </td>
                <td>
                  <div v-for="answerExam in item.answers">
                    <p :class="{ 'text-primary': answerExam.status }">
                      {{ answerExam.answer }}
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mb-0 d-flex flex-row-reverse mr-10">
        <p class="mb-0">Tổng số bản ghi: <span class="text-primary ">{{ total }}</span></p>
      </div>
      <div class="edu-paginate mx-auto text-center">
        <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1"
          :click-handler="clickCallback" :prev-text="'Trước'" :next-text="'Sau'"
          :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'" :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'" :prev-class="'page-link'" :next-class="'page-link'"
          :page-class="'page-item'">
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import ModalImportExam from "@/view/pages/Tournaments/Component/ModalImportExam";
import { GET_EXAM } from "@/core/services/store/tournament/tournament.module";

export default {
  name: "ExamTournament",
  components: { ModalImportExam },
  data() {
    return {
      dataExam: [],
      findQuestion: '',
      page: 1,
      last_page: 0,
      total: 0
    }
  },
  mounted() {
    this.getDataExam()
  },
  methods: {
    getDataExam() {
      let payload = {
        tournament_id: this.$route.params.id,
        keyword: this.findQuestion,
        page: this.page

      }
      this.$store.dispatch(GET_EXAM, payload).then((data) => {
        this.dataExam = data.data.map((question) => {
          return {
            codeTitle: question.exam_code,
            question: question.question,
            image: question.image,
            answers: question.answers.map((answer) => {
              return {
                answer: answer.answer,
                status: answer.is_right_answer ? true : false
              }
            })
          }
        })
        this.total = data.pagination.total;
        this.page = data.pagination.page;
        this.last_page = data.pagination.last_page;
      }).catch((error) => {

      })
    },
    clickCallback(obj) {
      this.page = obj;
      this.getDataExam();
    }
  }
}
</script>

<style scoped>
img{
  width: 100px;
}
</style>