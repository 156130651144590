import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "../auth.module";
const _ = require('lodash');
export const GET_HISTORY_SENT_NOTIFICATION = 'notification/histories'
export const NOTIFY_ZALO = 'student-amounts/send-zalo'

const state = {};

const getters = {};
const actions = {
    [GET_HISTORY_SENT_NOTIFICATION](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_HISTORY_SENT_NOTIFICATION}`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [NOTIFY_ZALO](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${NOTIFY_ZALO}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

};


const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};