<template id="allComment">
        <div>
            <div v-for="(log, key) in tickets" id="BoxTicketLog" class="mb-4">
                <div class="d-flex align-items-center w-100">
                    <div>
                        <img v-if="log.avatar" class="avatar mr-4" :src="log.avatar ? log.avatar : ''" alt="">
                        <img v-else class="avatar mr-4" src="../../../../../../public/media/users/default.jpg" alt="">
                    </div>
                    <div class="flex-column w-100">
                        <div class="mb-2">
                            <strong>{{ log.user_handle_name ?  log.user_handle_name+ ' - ' : '' }} </strong>
                                    {{ log.department ? log.department + ' - ' : '' }}
                                    {{ log.category_stringee == 1 ? 'Cuộc gọi đi' :
                                     (log.category_stringee == 2 ? 'Cuộc gọi đến' :
                                      actionDefine[log.action] ? actionDefine[log.action] + ' - ' : '') }}
                                    {{ log.compare_date }}
                            
                        </div>
                        <div class="content content-ck-fix ck ck-editor__editable" style="overflow: auto;" :class="isScroll(log)">
                            <div @click="showImg($event)">
                                <el-input v-if="log.action == 3 || log.action == 4" 
                                style="height: auto;" class="w-100" v-html="log.reason ? log.reason : '' "></el-input>
                            </div>
                            <el-input v-if="log.action == 5" v-html="log.reason ? log.reason : 'Không có lý do từ chối'"></el-input>
                            <!-- <el-input v-if="log.action == 1 || log.action == 2 || log.action == 6 || log.action == 8 || log.action == 9"  -->
                            <el-input v-if="logAction_type_1.includes(log.action)"
                            style="height: auto;" class="w-100" v-html="log.content ? log.content : ''"></el-input>
                            <div v-if="log.action == 7 || log.action == 12">
                                    <div v-if="log.feedback_comment || log.feedback_star">
                                        <el-rate  style="height: auto;" class="w-100 mb-2" disabled :value="log.feedback_star ? log.feedback_star : ''"></el-rate>
                                    <span class="ml-1">{{ log.feedback_comment ? log.feedback_comment : '' }}</span>
                                    </div>
                                    <div v-else>
                                        <span>{{ log.content }}</span>
                                    </div>
                            </div>
                            <div v-if="log.action == 8 || log.action == 9">
                                <p>{{ log.content }}</p>
                                <button @click="changeAudio(log.audio)">
                                  <i class="bi bi-volume-up border p-2" style="font-size: 25px"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <el-dialog class="m-0 p-0"
                    :visible.sync="dialogShowImgLog" center>
                    <img style="width: 100%; height: 85vh; object-fit: contain;" :src="imgLog" alt="">
                </el-dialog>
            <el-dialog
                title="Chi tiết file ghi âm"
                :visible.sync="centerDialogVisible"
                width="30%"
                center>
                <div class="text-center">
                    <audio controls :key="audio">
                        <source :src="audio" type="audio/ogg">
                    </audio>
                </div>
            </el-dialog>
        </div>
</template>


<script>
export default {
    name: 'Tat-ca-binh-luan-giai-phap',
    props: {
      tickets: {
        type: Array,
        default: () => { return [] }
        },

        tabName: {
        type: String,
        default: () => { return }
        },
    },

    data() {
    return {
        actionDefine: 
        {
            1: 'Đã chuyển phòng ban',
            2: 'Đã huỷ chuyển phòng ban',
            3: 'Đã bình luận',
            4: 'Đã nhập giải pháp',
            5: 'Đã từ chối giải pháp',
            6: 'Đã hoàn thành',
            7: 'Đã đánh giá',
            8: 'Cuộc gọi đi',
            9: 'Cuộc gọi đến',
            10: 'Đã thay đổi danh mục',
            11: 'Đã gia hạn deadline ticket',
            12: 'Đã chuyển xử lý',
        },
        imgLog:'',
        dialogShowImgLog: false,
        logAction_type_1: [1,2,6,10,11],
        audio: '',
        centerDialogVisible: false
    }
  },

  mounted() {

  },

  methods: {
    showImg(e) {
        let target = e.target.src;
        if(!target){
            return false;
        }else{
            this.dialogShowImgLog = true;
            this.imgLog = target;
        }
    },    

    tiemCreateAt(time_create) {
        var start = new Date(time_create);
        var now = new Date();
        if(!start || !now) {
            return false;
        }
        var timeDifference = now.getTime() - start.getTime();
        var daysDifference = Math.ceil(timeDifference / (1000 * 60));
        if(daysDifference < 60) {
            return daysDifference + ' Phút'
        }

        if( daysDifference > 60 && daysDifference < 1440) {
            return daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60)) + ' Giờ'
        }

        if(daysDifference > 1440) {
            return daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) + ' Ngày'
        }
    },

    isScroll(item) {
            switch (item.action) {
                case 3,4,5:
                    return 'box-content';
                    break;
                case 7:
                return 'rate';
                break;

                default:
                     return 'comment-solution';
            }
        },
    changeAudio(audio) {
      this.audio = audio;
      this.centerDialogVisible = true
    }
  }
}
</script>

<style>
.avatar {
    width: 42.81px;
    height: 44px;
    object-fit: cover;
    border-radius: 50%;
}

.content {
    height: auto;
    border: 0.2px solid #80808059;
    border-radius: 5px;
    padding: 10px;
}

#BoxTicketLog .content img{
    width: 112px;
    height: 150px;
}

.box-content {
    border-radius: 5px;
    border: 0.2px solid #e4e9f2;
    width: 100%;
    text-align: start;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
    height: auto;
}

.comment-solution {
    max-height: 200px 
}
.rate {
    max-height: 100px;
}

.container-log {
    width: 100%;
}
.content-ck-fix .ck {
    display: none;
}
.content-ck-fix .ck-widget_selected, .ck-widget_selected:hover {
    outline: white !important;
}
    
</style>