<template>
  <div class="row">
    <!-- Khóa học đã chọn -->
    <div class="example-preview table-responsive">
      <h4 class="mb-3" style="font-weight: 700">Khóa học đã chọn</h4>
      <b-button v-b-toggle.list-chosen-courses class="m-1 m-0 show-inf" variant="success" id="button_show_value" @click="changeChervon">
        Chi tiết khóa học <i class="fas fa-chevron-down"></i>
      </b-button>
      <b-collapse id="list-chosen-courses">
        <table class="table table-vertical-center">
          <tbody>
          <tr v-for="(course_infor,index) in contract.student_register_courses" :key="index">
            <td>{{ course_infor.course.name }}</td>
            <td width="25%"><input v-model="coursePrice[index]" type="number" class="form-control input-course" v-on:input="changeCoursePrice(index)"></td>
            <td>{{ course_infor.course_sale}} %</td>
            <td>{{ parseInt(course_infor.course_fee) * (1 - parseInt(course_infor.course_sale)/100) | toThousandFilter}} ₫</td>
          </tr>
          </tbody>
        </table>
      </b-collapse>
      <div class="row pt-1">
        <div class="col-md-6">
          <strong class="pl-2">Tổng giá khóa (1)</strong>
        </div>
        <div class="col-md-6 text-right">
          <strong>{{totalCourseRegisterFee | toThousandFilter }} ₫</strong>
        </div>
      </div>
    </div>
    <!-- Chi tiết thanh toán -->
    <div class="payment-detail pt-5">
      <div>
        <h4 class="mb-3" style="font-weight: 700">Chi tiết thanh toán</h4>
      </div>
      <b-button v-b-toggle.payment-contract-detail class="m-1 m-0 button_show_value show-inf" variant="success" @click="changeChervonPromotion">
        Chi tiết khuyến mại <i class="fas fa-chevron-down"></i>
      </b-button>
      <b-collapse id="payment-contract-detail">
        <div class="row" style="padding: 0.75rem" v-if="vouchersForContract.length > 0" v-for="(voucher,index) in vouchersForContract" :key="index">
            <div class="col-4">
              <p>Voucher {{++index}}:</p>
            </div>
            <div class="col-4">
              <!-- Giảm giá mã Km -->
              <span v-if="voucher.type !== 0" class="promotion-list"><strong>{{'Quy đổi quà tặng - ' + voucher.name }}</strong></span>
              <span v-else class="promotion-list"><strong>{{ voucher.name }}</strong></span>
            </div>
            <div class="col-4">
              <strong class="text-danger" v-if="voucher.unit === 1 ">{{ voucher.value | toThousandFilter }} ₫</strong>
              <strong class="text-danger" v-else>{{ voucher.value }} %</strong>
            </div>
        </div>
      </b-collapse>
      <div class="row p-3">
        <div class="col-md-6 pb-2">
          <strong>Tổng khuyến mại (2)</strong>
        </div>
        <div class="col-md-6 pb-2 text-right">
          <strong>{{ totalDiscount | toThousandFilter }} ₫</strong>
        </div>
      </div>

      <hr class="hr">

      <div class="row p-3">
        <div class="col-md-6 pb-2" v-if="isWithdrawWithCourse">
          <strong>
            <span>Giá trị khóa bị hủy (3)</span>
            <i
                class="fas fa-exclamation-circle ml-3"
                style="cursor: pointer; color: red"
                @click="openModalListCourses"
                v-tooltip="'Danh sách khóa bị hủy'"
            ></i>
          </strong>
        </div>
        <div class="col-md-6 pb-2 text-right" v-if="isWithdrawWithCourse">
          <strong>{{ totalCourseRefundFee | toThousandFilter }} ₫</strong>
        </div>

<!--        <div class="col-md-6 pb-2" v-if="isWithdrawWithCourse">-->
<!--          <strong>Tổng khuyến mại sau hủy (4)</strong>-->
<!--        </div>-->
<!--        <div class="col-md-6 pb-2 text-right" v-if="isWithdrawWithCourse">-->
<!--          <strong>{{ Math.abs(tongKhuyenMaiSauHuy) | toThousandFilter }} ₫</strong>-->
<!--        </div>-->

        <div class="col-md-6 pb-2" v-if="isWithdrawWithCourse || (!isWithdrawWithCourse && totalDestroyFee > 0)">
          <strong>Phí hủy khóa
            <span v-if="isWithdrawWithCourse">(5)</span>
            <span v-if="!isWithdrawWithCourse">(3)</span>
          </strong>
        </div>
        <div class="col-md-6 text-right" v-if="isWithdrawWithCourse || (!isWithdrawWithCourse && totalDestroyFee > 0)">
          <strong>{{ totalDestroyFee | toThousandFilter }} ₫</strong>
        </div>
        <div class="col-md-6 pb-2" v-if="isTransferred">
          <strong>Khuyến mại sau thay đổi</strong>
        </div>
        <div class="col-md-6 text-right" v-if="isTransferred && isWithdrawWithCourse">
          <strong>{{ totalPromotionAfterTransferred | toThousandFilter }} ₫</strong>
        </div>
      </div>

      <hr class="hr" v-if="isWithdrawWithCourse">

      <div class="row p-3">
        <div class="col-md-6 pb-2">
          <strong>Tổng giá trị hợp đồng
            <span v-if="isWithdrawWithCourse">(6) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(5) = (1) - (3) - (4) + (5)'"></i></span>
            <span v-if="!isWithdrawWithCourse && totalDestroyFee > 0">(4) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(4) = (1) - (2) + (3)'"></i></span>
            <span v-if="!isWithdrawWithCourse && totalDestroyFee === 0">(3) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(3) = (1) - (2) '"></i></span>
          </strong>
        </div>
        <div class="col-md-6 pb-2 text-right">
          <strong>{{  totalPriceContract | toThousandFilter }} ₫</strong>
        </div>

        <div class="col-md-6 text-center">
          <strong>Đã thanh toán
            <span v-if="isWithdrawWithCourse">(7)</span>
            <span v-if="!isWithdrawWithCourse && totalDestroyFee > 0">(5)</span>
            <span v-if="!isWithdrawWithCourse && totalDestroyFee === 0">(4)</span>
          </strong>
        </div>
        <div class="col-md-6 text-right">
          <strong>{{ contract.total_paid | toThousandFilter }} ₫</strong>
        </div>

        <div class="col-md-6 pt-2">
          <strong>Còn nợ
            <span v-if="isWithdrawWithCourse">
                        (8) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(8) = (6) - (7)'"></i>
                      </span>
            <span v-if="!isWithdrawWithCourse && totalDestroyFee > 0">
                        (6) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(6) = (4) - (5)'"></i>
                      </span>
            <span v-if="!isWithdrawWithCourse && totalDestroyFee === 0">
                        (5) <i class="fas fa-info-circle" style="cursor: pointer; color: #3a8ee6" v-tooltip="'(5) = (3) - (4)'"></i>
                      </span>
          </strong>
        </div>
        <div class="col-md-6 pt-2 text-right">
          <strong>{{ totalPriceContract - contract.total_paid | toThousandFilter }} ₫</strong>
        </div>
      </div>

    </div>

    <!-- Quà tặng -->
    <div v-if="is_edutalk && gift_select" class="payment-detail pt-5 table-responsive">
      <div>
        <h4 class="mb-3" style="font-weight: 700">Quà tặng</h4>
      </div>
      <b-button v-b-toggle.payment-gift-detail class="m-1 m-0 button_show_value show-inf" variant="success">
        {{ gift_select.name }} <i class="fas fa-chevron-down"></i>
      </b-button>
      <b-collapse id="payment-gift-detail">
        <p v-html="gift_select?.description" class="mt-3 pl-2 ml-3 font-weight-bold"></p>
      </b-collapse>
    </div>
    <list-course-refund :show-modal="showModalListCourses" @clicked="closeModalListCourses" :list-courses-refund="coursesRefund"></list-course-refund>
  </div>
</template>

<script>
import ListCourseRefund from "./ListCourseRefund";
export default {
  name: "PaymentDetailNapRutForm",
  components: {
    ListCourseRefund
  },
  props: {
    contract: {
      type: Object,
      default: () => { return {} }
    },
    isWithdrawWithCourse: {
      default: false
    },
    promotionForContract: {
      type: [Object, String],
      default: () => { return '' }
    },
    vouchersForContract: {
      type: Array,
      default: () => { return [] }
    },
    coursePrice: {
      type: [Array],
      default: () => { return [] }
    },
    promotions: {
      type: [Array],
      default: () => { return [] }
    },
    vouchers: {
      type: [Array],
      default: () => { return [] }
    },
    totalDiscount: {
      type: [String, Number],
      default: () => { return 0 }
    },
    totalCourseRefundFee: {
      type: [String, Number],
      default: () => { return 0 }
    },
    totalDestroyFee: {
      type: [String, Number],
      default: () => { return 0 }
    },
    totalCourseRegisterFee: {
      type: [String, Number],
      default: () => { return 0 }
    },
    totalContractAmount: {
      type: [String, Number],
      default: () => { return 0 }
    },
    coursesRefund: {
      type: [Array],
      default: () => { return [] }
    },
    tongKhuyenMaiSauHuy: {
      type: Number,
      default: 0
    },
    isTransferred: {
      type: Boolean,
      default: () => { return false }
    },
    totalPromotionAfterTransferred: {
      type: Number,
      default: () => { return 0 }
    },
    is_edutalk: {
      type: Boolean,
      default: () => { return false }
    },
    gift_select: {
      type: Object,
      default: () => { return {} }
    },
  },
  data() {
    return {
      showModalListCourses: false
    }
  },
  computed: {
    totalPriceContract(){
      return this.totalCourseRegisterFee - this.totalDiscount - this.totalCourseRefundFee + this.totalDestroyFee;
    }
  },
  methods: {
    changeCoursePrice(index){
      this.$emit('changeCoursePrice', index);
    },
    changeChervon(){
      $('#button_show_value').children('.fas').toggleClass('fa-chevron-up fa-chevron-down');
    },
    changeChervonPromotion(){
      $('.button_show_value').children('.fas').toggleClass('fa-chevron-up fa-chevron-down');
    },
    openModalListCourses : function (){
      this.showModalListCourses = true;
    },
    closeModalListCourses : function (){
      this.showModalListCourses = false;
    },
  }
}
</script>

<style scoped>

</style>
