import ApiService from "@/core/services/api.service";
import {ChangeToSlug} from "../../../filters";
const _ = require('lodash');
//action types
export const GET_RECRUITMENT_JOB_BY_ID = "get_job_by_id";
export const GET_RECRUITMENT_JOBS = "list-job";
export const GET_RECRUITMENT_CV = "list-cv";
export const CREATE_RECRUITMENT_JOB = "create_job";
export const UPDATE_RECRUITMENT_JOB = "update_job";
export const DELETE_RECRUITMENT_JOB = "delete_job";
export const DELETE_RECRUITMENT_CV = "delete_cv";
export const DOWNLOAD_CV = "download-cv";

//mutations types
export const SET_RECRUITMENT_JOBS = "setJobs";
export const SET_RECRUITMENT_CV = "setcvs";
export const ADD_RECRUITMENT_JOBS = "addJobs";
export const SET_RECRUITMENT_JOBS_ERROR = "errorJobs";
export const SET_RECRUITMENT_JOB_BY_ID = "getJobById";
export const SET_RECRUITMENT_JOB_PAGINATE = "set_job_paginate_value";
export const SET_RECRUITMENT_CV_PAGINATE = "set_cv_paginate_value";
export const UPDATE_LIST_RECRUITMENT_JOB = "set_jobs_when_active_job";
export const UPDATE_LIST_RECRUITMENT_JOB_WHEN_DELETE = "set_jobs_when_delete_job";
export const UPDATE_LIST_RECRUITMENT_CV_WHEN_DELETE = "set_cvs_when_delete_job";


const state = {
    errors: null,
    jobs: [],
    cvs: [],
    job : '',
    jobPaginatedData : [],
    cvPaginatedData : [],
};

const getters = {
    getAllJobs(state) {
        return state.jobs;
    },
    getAllCvs(state) {
        return state.cvs;
    },
    getJobById(state) {
        return state.job;
    },
    getJobPaginate(state) {
        return state.jobPaginatedData;
    },
    getCvPaginate(state) {
        return state.cvPaginatedData;
    },
};

const actions = {
    [CREATE_RECRUITMENT_JOB](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('jobs', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_RECRUITMENT_JOB](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('jobs/'+ payload.id +'?_method=PUT', payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_RECRUITMENT_JOBS](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("jobs", credentials)
                .then(({data}) => {
                    context.commit(SET_RECRUITMENT_JOBS, data.data.data);
                    const jobPagination = {
                        total: data.data.meta.pagination.total,  // total number of elements or items
                        per_page: data.data.meta.pagination.per_page, // items per page
                        current_page: data.data.meta.pagination.current_page, // current page (it will be automatically updated when jobs clicks on some page number).
                        total_pages: data.data.meta.pagination.total_pages // total pages in record
                    }
                    context.commit(SET_RECRUITMENT_JOB_PAGINATE, jobPagination)
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },
    [GET_RECRUITMENT_CV](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("cvs", credentials)
                .then(({data}) => {
                    context.commit(SET_RECRUITMENT_CV, data.data.data);
                    const cvPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when jobs clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_RECRUITMENT_CV_PAGINATE, cvPagination)
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },

    [GET_RECRUITMENT_JOB_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('jobs', id).then(({data}) => {
                context.commit(SET_RECRUITMENT_JOB_BY_ID, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [DELETE_RECRUITMENT_JOB](context, id) {
        return new Promise(resolve => {
            ApiService.delete('jobs', id).then(({data}) => {
                context.commit(UPDATE_LIST_RECRUITMENT_JOB_WHEN_DELETE, id);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [DELETE_RECRUITMENT_CV](context, id) {
        return new Promise(resolve => {
            ApiService.delete('cvs', id).then(({data}) => {
                context.commit(UPDATE_LIST_RECRUITMENT_CV_WHEN_DELETE, id);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [DOWNLOAD_CV](context, cv) {
        return new Promise(resolve => {
            ApiService.post('download/cv', cv).then(({data}) => {
                let afterDot = cv.file.substring( cv.file.indexOf("."));
                let name = ChangeToSlug(cv.name);
                let filename = name + afterDot;
                let anchor = document.createElement("a");
                anchor.href =  data.data;
                anchor.download = filename;
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};


const mutations = {
    [SET_RECRUITMENT_JOBS](state, data) {
        state.jobs = data;
        state.errors = {};
    },
    [SET_RECRUITMENT_CV](state, data) {
        state.cvs = data;
        state.errors = {};
    },
    [ADD_RECRUITMENT_JOBS](state, data) {
        state.jobs.push(data);
        state.errors = {};
    },
    [SET_RECRUITMENT_JOBS_ERROR](state, error) {
        state.errors = error;
    },
    [SET_RECRUITMENT_JOB_BY_ID](state, data) {
        state.job = data;
    },
    [SET_RECRUITMENT_JOB_PAGINATE](state, data) {
        state.jobPaginatedData = data;
    },
    [SET_RECRUITMENT_CV_PAGINATE](state, data) {
        state.cvPaginatedData = data;
    },
    [UPDATE_LIST_RECRUITMENT_JOB](state, job){
        let jobs = state.jobs;
        let oldJob = _.find(jobs,function (object) {
            return object.id === job.id;
        });
        oldJob.status = job.status;
    },

    [UPDATE_LIST_RECRUITMENT_JOB_WHEN_DELETE](state, id){
        let jobs = state.jobs;
        let indexJob = _.findIndex(jobs,function (object) {
            return object.id === id;
        });
        jobs.splice(indexJob,1);
    },
    [UPDATE_LIST_RECRUITMENT_CV_WHEN_DELETE](state, id){
        let jobs = state.cvs;
        let indexJob = _.findIndex(jobs,function (object) {
            return object.id === id;
        });
        jobs.splice(indexJob,1);
    },

};

export default {
    state,
    actions,
    mutations,
    getters
};
