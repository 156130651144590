<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Tổng học viên</h3>
      </div>
      <div class="card-title" v-if="currentUser.user.permission.includes(permission.EXPORT_KHACH_HANG_DONG_TIEN)">
        <div v-show="is_show_export" class="export-google-sheet">
          <div class="my-3">
            <!-- Our triggering (target) element -->
            <b-button id="popover-reactive-1" variant="primary" ref="button" class="mr-2">
              <i class="la la-file-excel"></i> Xuất google sheet
            </b-button>
          </div>
          <!-- Output from the popover interaction -->
          <b-popover target="popover-reactive-1" triggers="click" :show.sync="popoverShow" placement="auto"
            container="my-container" ref="popover" @show="onShow" @hidden="onHidden">
            <template #title>
              <b-button @click="onClose" class="close" aria-label="Close">
                <span class="d-inline-block" aria-hidden="true">&times;</span>
              </b-button>
              Danh sách thư mục
            </template>

            <div>
              <b-form-group label-for="popover-input-1" label-cols="3" :state="validateState" class="mb-1"
                invalid-feedback="Không được để trống" size="lg">
                <b-form-select id="popover-input-1" v-model="folderData" :state="validateState" :options="options"
                  size="sm" class="mb-3"></b-form-select>
              </b-form-group>
              <b-button @click="onClose" size="sm" variant="danger">Cancel</b-button>
              <b-button @click="onOk" size="sm" variant="primary" class="ml-2">Ok</b-button>
            </div>
          </b-popover>
        </div>
        <div v-show="is_show_export">
          <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcel">
            <i class="la la-file-excel"></i> Xuất excel
          </button>
          <button v-else disabled class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
            Xuất excel
          </button>
        </div>
        <div v-show="is_show_export">
          <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcelV2">
            <i class="la la-file-excel"></i> Xuất excel v2
          </button>
          <button v-else disabled class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
            Xuất excel v2
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Trung tâm
                <span class="text-danger"></span></label>
              <multiselect v-model="cache_center" :options="centers" placeholder="Chọn trung tâm" label="name"
                track-by="name" @input="selectCenter($event)"></multiselect>
            </div>
            <!--end::Form-->
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Chi nhánh
                <span class="text-danger"></span></label>
              <multiselect v-model="cache_branch" :options="branches" placeholder="Chọn chi nhánh" label="name"
                track-by="name" @input="selectBranch($event)"></multiselect>
            </div>
            <!--end::Form-->
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Khóa học
                <span class="text-danger"></span></label>
              <multiselect v-model="cache_course" :options="courses" placeholder="Chọn khóa học" label="name"
                track-by="name" @input="selectCourse($event)"></multiselect>
            </div>
            <!--end::Form-->
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Lớp học
                <span class="text-danger"></span></label>
              <multiselect v-model="cache_class" :options="classes" placeholder="Chọn lớp học" label="name"
                track-by="name" @input="selectClass($event)"></multiselect>
            </div>
            <!--end::Form-->
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Mã, Số điện thoại, Họ tên
                <span class="text-danger"></span></label>
              <input type="text" v-model="query.keyword" class="form-control form-information"
                placeholder="Mã, Số điện thoại, Họ tên">
            </div>
            <!--end::Form-->
          </div>
          <div class="col-md-2 mt-8 mb-6">
            <div class="d-flex justify-content-between">
              <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2" @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
              </button>
              <button v-else type="button" disabled
                class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div class="card-toolbar">
          <ul class="nav nav-tabs nav-bold nav-tabs-line">
            <li class="nav-item" @click="activeTab(1)" style="cursor: grab;">
              <a class="nav-link" v-bind:class="{ active: active_tab_1 }" data-toggle="tab">
                <span class="nav-icon"><i class="flaticon-users-1"></i></span>
                <span class="nav-text font-weight-bold">Học viên edutalk</span>
              </a>
            </li>
            <li class="nav-item" @click="activeTab(2)" style="cursor: grab;">
              <a class="nav-link" v-bind:class="{ active: !active_tab_1 }" data-toggle="tab">
                <span class="nav-icon"><i class="flaticon2-user"></i></span>
                <span class="nav-text font-weight-bold">Học viên trung tâm</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div class="tab-pane fade" v-bind:class="{ show: active_tab_1, active: active_tab_1 }" role="tabpanel"
            aria-labelledby="kt_tab_pane_1_4">
            <ListStudentTable :items="student_all_edutalk" :isBusy="isBusy"></ListStudentTable>
          </div>
          <div class="tab-pane fade" v-bind:class="{ show: !active_tab_1, active: !active_tab_1 }" role="tabpanel"
            aria-labelledby="kt_tab_pane_2_4">
            <ListStudentTable :items="student_all_center" :isBusy="isBusy"></ListStudentTable>
          </div>
        </div>

      </div>
    </div>

    <div class="edu-paginate mx-auto text-center">
      <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
        :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
        :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
        :next-class="'page-link'" :page-class="'page-item'">
      </paginate>
    </div>


  </div>
</template>
<script>
import { GET_FOLDERS_DRIVERS } from "../../../core/services/store/public/google.module";

const _ = require('lodash');

import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import Multiselect from 'vue-multiselect'
// Datetime picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
/*Show modal edit*/
import ContractForm from "@/view/pages/business/ContractForm";
import ContractDetail from "@/view/pages/business/ContractDetail";
import { GET_COURSES } from "../../../core/services/store/course/course.module";
import { GET_CLASSES } from "../../../core/services/store/course/classes.module";
import {
  EXPORT_EXCEL_REGISTER_COURSE, EXPORT_EXCEL_REGISTER_COURSE_V2, EXPORT_STUDENT_REGISTER_COURSE_GOOGLE_SHEET,
} from "../../../core/services/store/contract/student-register-course.module";
/*End*/

// Date range picker
import DateRangePicker from 'vue2-daterange-picker';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { GET_CENTERS } from "../../../core/services/store/center/centers.module";
import { GET_BRANCHES } from "../../../core/services/store/center/branch.module";
import { mapGetters } from "vuex";
import { GET_STUDENT_ALL } from "../../../core/services/store/user/users.module";
import { GET_STUDENT_ALL_CENTER } from "../../../core/services/store/user/center-user.module";
import ListStudentTable from "./components/ListStudentTable";
import {exportExcel} from "../../../core/filters";
// Multiple select

export default {
  components: {
    ListStudentTable,
    Multiselect,
    DatePicker,
    ContractForm,
    ContractDetail,
    Loading,
    DateRangePicker,
  },
  name: "StudentAll",
  data() {
    return {
      is_show_export: true,
      active_tab_1: true,
      /*Loader */
      isBusy: false,
      fullPage: true,
      /* popover */
      path: '',
      showPop: false,
      folderData: '',
      validateState: null,
      options: [],
      popoverShow: false,
      center_id: '',
      /*End*/
      is_disable_search: false,
      is_export_excel: false,
      is_export_sheet: false,
      student_all_edutalk: [],
      student_all_center: [],
      // Paginate
      dateRange: {},
      page: 1,
      last_page: 1,
      query:
      {
        keyword: '',
        center_id: '',
        branch_id: '',
        course_id: '',
        class_id: '',
      }
      ,
      localData: {
        direction: 'ltr',
        format: 'dd-mm-yyyy',
        separator: ' ~ ',
        applyLabel: 'Đồng ý',
        cancelLabel: 'Hủy bỏ',
        weekLabel: 'Tuần',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
        monthNames: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
        firstDay: 0,
      },

      // Data Load
      centers: [],
      courses: [],
      branches: [],
      classes: [],
      // Cache
      cache_branch: [],
      cache_center: [],
      cache_course: [],
      cache_class: [],

    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    folderData(val) {
      if (val) {
        this.validateState = true;
        this.path = val;
      }
    },
  },
  async mounted() {
    await this.getCenters();
    await this.getBranches();
    await this.getList();
    await this.setCacheTheLoad()
    this.getFolders()

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý học viên", route: '#' },
      { title: " Tổng học viên" }
    ]);
  },
  methods: {
    activeTab(tab) {
      if (tab == 1) {
        this.is_show_export = true;
        this.active_tab_1 = true;
      } else {
        this.is_show_export = false;
        this.active_tab_1 = false;
      }
    },
    onCancel() {
      console.log('User cancelled the loader.')
    },
    onClose() {
      this.popoverShow = false
    },
    onOk() {
      if (!this.folderData) {
        this.validateState = false
      }
      if (this.folderData) {
        this.onClose()
        // Return our popover form results
        this.exportGoogleSheet();
      }
    },
    onShow() {
      this.folderData = ''
      this.validateState = null
    },
    onHidden() {
      this.focusRef(this.$refs.button)
    },
    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          ; (ref.$el || ref).focus()
        })
      })
    },
    getFolders: function () {
      this.$store.dispatch(GET_FOLDERS_DRIVERS).then((res) => {
        this.options = _.reduce(res.data, function (result, item, key) {
          result.push({
            text: item.name,
            value: item.path
          });
          return result;
        }, [])
        this.options.unshift({ text: '- Chọn thư mục -', value: '' });
      });

    },
    setCacheTheLoad() {
      if (this.query.center_id != '') {
        let oldParamCenter = this.centers.filter((value) => {
          if (parseInt(this.query.center_id) == value.id) {
            return value
          }
        })
        this.cache_center = oldParamCenter
      }
      if (this.query.branch_id != '') {
        let oldParamDistrict = this.branches.filter((value) => {
          if (parseInt(this.query.branch_id) == value.id) {
            return value
          }
        })
        this.cache_branch = oldParamDistrict
      }
      if (this.query.course_id != '') {
        let oldParamDistrict = this.courses.filter((value) => {
          if (parseInt(this.query.course_id) == value.id) {
            return value
          }
        })
        this.cache_course = oldParamDistrict
      }
    },

    setDateParams: function (d) {
      return d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
    },
    /*Show modal edit contract*/
    getCourses: async function () {
      await this.$store.dispatch(GET_COURSES, {
        limit: 10000,
        status: 1,
        center_id: this.query.center_id
      }).then((res) => {
        this.courses = _.map(res.data.data, (item) => {
          item = { id: item.id, name: item.name, fee: item.fee, sale: item.sale, };
          return item;
        })
      });
    },

    async getCenters() {
      await this.$store.dispatch(GET_CENTERS, {
        limit: 10000,
        status: 1,
      }).then((res) => {
        if (!res.error) {
          this.centers = res.data.data
        }
      });
    },
    async getBranches() {
      await this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        status: 1,
        center_id: this.query.center_id
      }).then((res) => {
        if (!res.error) {
          this.branches = res.data.data;
        }
      });
    },
    async getClasses() {
      await this.$store.dispatch(GET_CLASSES, {
        limit: 10000,
        status: 1,
        center_id: this.query.center_id,
        course_id: this.query.course_id,
        branch_id: this.query.branch_id,
      }).then((res) => {
        if (!res.error) {
          this.classes = res.data.data;
        }
      });
    },

    /*End*/
    getList() {
      this.pullParamsUrl()
      this.callApiGetList()
    },
    pullParamsUrl() {
      if (typeof this.$route.query.page == "string") {
        this.page = parseInt(this.$route.query.page)
      }
      this.query.keyword = this.$route.query.keyword;
      this.query.center_id = this.$route.query.center_id;
      this.query.branch_id = this.$route.query.branch_id;
      this.query.course_id = this.$route.query.course_id;
      this.query.class_id = this.$route.query.class_id;

    },
    pushParamsUrl() {
      this.$router.push({
        name: 'student-all',
        query: {
          page: this.page,
          keyword: this.query.keyword,
          center_id: this.query.center_id,
          branch_id: this.query.branch_id,
          course_id: this.query.course_id,
          class_id: this.query.class_id,
        }
      })
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({
        name: 'student-all',
        query: {
          page: this.page,
          keyword: this.query.keyword,
          center_id: this.query.center_id,
          branch_id: this.query.branch_id,
          course_id: this.query.course_id,
          class_id: this.query.class_id,
        }
      })
      this.callApiGetList()
    },
    async callApiGetList() {
      this.isBusy = true;

      // Danh sach hoc vien tong trung tam
      await this.$store.dispatch(GET_STUDENT_ALL_CENTER, {
        page: this.page,
        keyword: this.query.keyword,
        center_id: this.query.center_id,
        branch_id: this.query.branch_id,
        course_id: this.query.course_id,
      }).then((res) => {
        if (!res.error) {
          this.student_all_center = res.data
          this.is_disable_search = false
          this.last_page = res.data.last_page
          this.isBusy = false;
        }
      });

      // Danh sach hoc vien tong edutalk
      await this.$store.dispatch(GET_STUDENT_ALL, {
        page: this.page,
        keyword: this.query.keyword,
        center_id: this.query.center_id,
        branch_id: this.query.branch_id,
        course_id: this.query.course_id,
      }).then((res) => {
        if (!res.error) {
          this.student_all_edutalk = res.data
          this.is_disable_search = false
          this.last_page = res.data.last_page
          this.isBusy = false;
        }
      });

    },
    exportExcel() {
      const HEADER_ROW_EXPORT_STUDENT_LIST = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'id',
          value: 'Mã học viên',
        },
        {
          key: 'name',
          value: 'Tên học viên',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'email',
          value: 'Email',
        },
        {
          key: 'birthday',
          value: 'Ngày sinh',
        },
      ]
      this.$store.dispatch(EXPORT_EXCEL_REGISTER_COURSE, {
        keyword: this.query.keyword,
        center_id: this.query.center_id,
        branch_id: this.query.branch_id,
        course_id: this.query.course_id,
      }).then((res) => {
        exportExcel(HEADER_ROW_EXPORT_STUDENT_LIST, res.data, "Danh sách tổng học viên.xlsx", true)
      });
    },
    exportExcelV2() {
      const HEADER_ROW_EXPORT_STUDENT_LIST = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'user_id',
          value: 'Mã học viên',
        },
        {
          key: 'name',
          value: 'Tên học viên',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'email',
          value: 'Email',
        },
        {
          key: 'birthday',
          value: 'Ngày sinh',
        },
      ]
      this.$store.dispatch(EXPORT_EXCEL_REGISTER_COURSE_V2, {
        keyword: this.query.keyword,
        center_id: this.query.center_id,
        branch_id: this.query.branch_id,
        course_id: this.query.course_id,
      }).then((res) => {
        exportExcel(HEADER_ROW_EXPORT_STUDENT_LIST, res.data, "Danh sách tổng học viên.xlsx", true)
      });
    },
    exportGoogleSheet() {
      this.$store.dispatch(EXPORT_STUDENT_REGISTER_COURSE_GOOGLE_SHEET, {
        keyword: this.query.keyword,
        center_id: this.query.center_id,
        branch_id: this.query.branch_id,
        course_id: this.query.course_id,
        path: this.path,
      }).then((res) => {
        if (res.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export google sheet',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$bvToast.toast(res.message, {
            title: 'Export google sheet',
            variant: 'success',
            solid: true
          });
        }
      });
    },
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl()
      this.callApiGetList()
    },
    selectCenter($event) {
      this.cache_branch = [];
      this.cache_course = [];
      this.cache_class = [];
      this.courses = [];
      this.classes = [];
      this.query.branch_id = ''
      if ($event) {
        this.query.center_id = $event.id
        this.getCourses();
      } else {
        this.query.center_id = ''
      }
      this.getBranches();
    },
    selectBranch($event) {
      this.cache_class = []
      if ($event) {
        this.query.branch_id = $event.id
      } else this.query.branch_id = '';
      this.getClasses();
    },
    selectCourse($event) {
      this.cache_class = [];
      this.classes = [];
      if ($event) {
        this.getClasses();
        this.query.course_id = $event.id
      } else {
        this.query.course_id = '';
      }
    },
    selectClass($event) {
      if ($event) {
        this.query.class_id = $event.id
      } else this.query.class_id = '';
    },
  }
}
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}

#btn-export-excel {
  position: relative;
}

.col-form-label {
  display: none;
}

.vue-daterange-picker {
  width: 100%;
}

.reportrange-text[data-v-79d7c92a] {
  text-align: center;
  padding: 12px 10px;
}

.calendars {
  flex-wrap: unset !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
