<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="course_create" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Thêm mới chính sách điều khoản
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'term-policy' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div v-for="(item, index) in listCatalog" :key="index">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Đầu mục
                    <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" :value="item.name" disabled>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="form-group mb-1 col-md-12">
                <label>Nội dung
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="content" name="Nội dung" rules="required"
                                    v-slot="{ errors,classes }">
                  <ckeditor :editor="editor" v-model="term_policy[index]"></ckeditor>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <label>Trung tâm</label>
          <div>
            <el-select v-model="center_id" >
              <el-option v-for="(item, index) in center" :key="index" :value="item.id" :label="item.value">
                <span>{{item.value}}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button
                type="submit"
                class="btn btn-success mr-2"
            >
              Tạo mới
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>

import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import Multiselect from 'vue-multiselect'
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";
import {CREATE_TERM_POLICY, GET_CATALOG, GET_TERM_POLICY} from "@/core/services/store/system/termpolicy.module";
import ErrorMessage from "../common/ErrorMessage";

export default {
  components: {
    Multiselect,
    DatePicker,
    ErrorMessage
  },
  name: "TermPolicyCreate",
  data() {
    return {
      editor: CustomEditor,
      files: [],
      term_policy: [],
      center_id: '',
      accountType: [],
      // Cache
      cacheAccountType: [],
      money: {
        decimal: ',',
        thousands: ',',
        prefix: '',
        suffix: ' VNĐ',
        precision: 0,
        masked: false,
      },
      listCatalog: {},
      fileUpdates: [],
      form: {
        name_slide: '',
        slide_status: true,
        center_id: '',
        file: ''
      },
      center: [
        {
          id: 1,
          value: 'Ecoach'
        },
        {
          id: 2,
          value: 'Ielts mentor'
        }
      ]
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý chính sách điều khoản", route: 'profile-1'},
      {title: "Tạo chính sách điều khoản"}
    ]);
    this.getCatalog();
  },
  computed: {},
  methods: {
    getCatalog() {
      this.$store.dispatch(GET_CATALOG,{}).then((res) => {
        this.listCatalog = res.data;
      });
    },
    onSubmit() {
      let formData = new FormData();
      formData.append('data', this.term_policy);
      formData.append('center_id', this.center_id);
      this.$store.dispatch(CREATE_TERM_POLICY, formData).then((res) => {
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Tạo mới',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$router.push({name: "term-policy"}).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Tạo mới',
              variant: 'success',
              solid: true
            });
          })
        }
      }).catch((e) => {

      });
    },
    handlePreview(file) {
    },
    handleRemove(file, fileList) {
      this.files = [];
      this.form.file = '';
    },
    handleChange(file, fileList) {
      this.files = fileList;
      this.form.file = fileList.length ? fileList[0] : '';
    },
    handleExceed(files, fileList) {

    },
  }
}
</script>
<style scoped>
.mx-datepicker {
  width: 100% !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
