<template>
  <div>
    <!-- dialog duyệt giảng viên -->
    <b-modal
      id="handle-approve-candidate"
      title="Duyệt giảng viên"
      size="lg"
      ok-only
      centered
      hide-footer>
      <h4 v-if="teacher.approved">
        Ứng viên {{ teacher.name }} đã được duyệt thành giảng viên chính thức.
      </h4>
      <h4 v-else>
        Bạn có chắc chắn muốn duyệt giảng viên {{ teacher.name }} lên giảng viên
        chính thức?
      </h4>
      <div class="mt-8">
        <el-button
          :disabled="loading_approve"
          native-type="submit"
          size="small"
          class="float-right text-white rounded"
          style="background-color: rgba(93, 4, 176, 1);"
          @click="handleOk"
          v-if="!teacher.approved"
        >
          <i v-bind:class="[loading_approve ? 'el-icon-loading' : '']"></i>
          Xác nhận
        </el-button>
      </div>
      <div>
        <el-button
          style="background-color: rgba(202, 202, 202, 1); padding: 11px 20px"
          size="small"
          class="float-right mr-3 rounded"
          @click="closeModal"
        >
          Huỷ bỏ
        </el-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { APPROVE_CANDIDATE_TEACHER } from "../../../../core/services/store/course/classes.module";

export default {
  name: "ApproveCandidate",
  components: {},
  props: {
    teacher: Object,
  },
  mounted() {},
  data() {
    return {
      loading_approve: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    handleOk() {
      this.callApi();
    },

    callApi() {
      this.loading_approve = true;
      this.$store
        .dispatch(APPROVE_CANDIDATE_TEACHER, {
          id: this.teacher.id
        })
        .then((res) => {
          this.loading_export = false;
          this.$notify({
            title: "Thành công",
            message: "Duyệt giảng viên thành công!",
            type: "success",
          });
          this.loading_approve = false;
          this.closeModal();
        }).catch((e) => {
            this.$notify({
               title: "Lỗi",
               message: "Đã xảy ra lỗi khi duyệt giảng viên.",
               type: "error",
            });
        }).finally(() => {
        });
    },
    closeModal() {
      this.$bvModal.hide("handle-approve-candidate");
      this.$emit("reload");
    },
  },
};
</script>

<style>
#handle-approve-candidate {
  top: -100px;
}
</style>
