<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="event_edit" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 v-if="checkEdit" class="card-label">Sửa sự kiện</h3>
            <h3 v-else class="card-label">Tạo sự kiện</h3>
          </div>
          <div class="card-toolbar">
            <router-link
                    class="font-weight-bold font-size-3 btn btn-secondary"
                    :to="{ name: 'event-list' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Tiêu đề <span class="text-danger">*</span></label>
                <ValidationProvider vid="title" name="Tiêu đề" rules="required" v-slot="{ errors,classes }">
                  <input type="text" ref="title" name="title" class="form-control" v-model="event.title" placeholder="Tiêu đề" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="title" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Nội dung <span class="text-danger">*</span></label>
                <ValidationProvider vid="content" name="Nội dung" rules="required" v-slot="{ errors,classes }">
                  <vue-editor id="editor-content" :editorOptions="editorSettings" useCustomImageHandler
                              v-model="event.content" :class="classes"
                              @image-added="handleImageAdded"></vue-editor>
                  <div class="fv-plugins-message-container">
                    <div data-field="content" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Đối tượng áp dụng <span class="text-danger">*</span></label>
                <ValidationProvider vid="type" name="Đối tượng áp dụng" rules="required" v-slot="{ errors,classes }">
                  <el-radio-group v-model="event.type" @change="handleTypeChange($event)">
                    <el-radio :label="0">Khu vực</el-radio>
                    <el-radio :label="1">Trung tâm</el-radio>
                    <el-radio :label="2">Khóa học</el-radio>
                  </el-radio-group>
                  <div class="fv-plugins-message-container">
                    <div data-field="type" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row" v-if="showArea">
          <div class="col-md-12">
            <div class="form-group">
              <label>Khu vực <span class="text-danger">*</span></label>
              <ValidationProvider vid="areas" name="Khu vực" rules="required" v-slot="{ errors,classes }">
                <el-select v-model="event.areas" filterable class="w-100" placeholder="Chọn khu vực" multiple>
                  <el-option
                          v-for="item in areas"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="areas" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
          <div class="row" v-if="showCenter">
            <div class="col-md-12">
              <div class="form-group">
                <label>Trung tâm <span class="text-danger">*</span></label>
                <ValidationProvider vid="centers" name="Trung tâm" rules="required" v-slot="{ errors,classes }">
                  <el-select v-model="event.centers" filterable class="w-100" placeholder="Chọn trung tâm" multiple>
                    <el-option
                            v-for="item in centers"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                    >
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="centers" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row" v-if="showCourse">
            <div class="col-md-12">
              <div class="form-group">
                <label>Khóa học <span class="text-danger">*</span></label>
                <ValidationProvider vid="courses" name="Khóa học" rules="required" v-slot="{ errors,classes }">
                  <el-select v-model="event.courses" filterable class="w-100" placeholder="Chọn khóa học" multiple>
                    <el-option
                            v-for="item in courses"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                    >
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="courses" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Ngày bắt đầu <span class="text-danger">*</span></label>
                <ValidationProvider vid="start_date" name="Ngày bắt đầu" rules="required" v-slot="{ errors,classes }">
                  <date-picker v-model="event.start_date" format="DD-MM-YYYY" valueType="YYYY-MM-DD" :class="classes"></date-picker>
                  <div class="fv-plugins-message-container">
                    <div data-field="start_date" data-validator="notEmpty"
                         class="fv-help-block">{{errors[0]}}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Ngày kết thúc</label>
                <ValidationProvider vid="end_date" name="Ngày kết thúc" rules="" v-slot="{ errors,classes }">
                  <date-picker v-model="event.end_date" format="DD-MM-YYYY" valueType="YYYY-MM-DD" :class="classes"></date-picker>
                  <div class="fv-plugins-message-container">
                    <div data-field="end_date" data-validator="notEmpty"
                         class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Số lượng team</label>
                <input type="text" ref="number_team" name="number_team" class="form-control" v-model="event.number_team" placeholder="Số lượng team">
              </div>
            </div>
          </div>
        </div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button type="submit" class="btn btn-success mr-2">
              Cập nhật
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {GET_COURSES} from "../../../core/services/store/course/course.module";
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";

    //editor
    import ApiService from "@/core/services/api.service";
    import {VueEditor, Quill} from 'vue2-editor'
    import ImageResize from 'quill-image-resize-vue';
    import {ImageDrop} from 'quill-image-drop-module';
    import {
        CREATE_EVENT,
        GET_EVENT_BY_ID,
        GET_PROVINCES,
        UPDATE_EVENT
    } from "../../../core/services/store/event/event.module";
    Quill.register("modules/imageDrop", ImageDrop);
    Quill.register("modules/imageResize", ImageResize);
    export default {
        components: {
            DatePicker,
            VueEditor,
        },
        name: "EventForm",
        data() {
            return {
                // Check show
                editorSettings: {
                    modules: {
                        imageDrop: true,
                        imageResize: {}
                    }
                },
                // Cache
                cacheCenter: [],
                cacheLevelIn: [],
                cacheLevelOut: [],
                cacheCourseType: [],
                event: {
                    id: '',
                    title: '',
                    type: '',
                    areas: [],
                    centers: [],
                    courses: [],
                    start_date: '',
                    end_date: null,
                    number_team: null
                },
                areas: [],
                centers: [],
                courses: [],
                checkCreate: false,
                checkEdit: false,
                showArea: false,
                showCenter: false,
                showCourse: false
            }
        },
        mounted() {
            this.getAreas();
            this.getCenters();
            this.getCourses();
            if (this.$route.params.id !== undefined) {
                this.checkEdit = true;
                this.$store.dispatch(SET_BREADCRUMB, [
                    {title: "Sự kiện", route: "event-list"},
                    {title: "Sửa sự kiện", icon: 'fas fa-users'}
                ]);
                this.getEventById();
            } else {
                this.$store.dispatch(SET_BREADCRUMB, [
                    {title: "Sự kiện", route: 'event-list'},
                    {title: "Tạo sự kiện"}
                ]);
            }
        },
        computed: {},
        methods: {
            getEventById() {
                let id = this.$route.params.id;
                this.$store.dispatch(GET_EVENT_BY_ID, id).then((res) => {
                    if (!res.error) {
                        console.log(res.data);
                        this.event = res.data
                        this.handleTypeChange(this.event.type);
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getAreas() {
                this.$store.dispatch(GET_PROVINCES).then((res) => {
                    if (!res.error) {
                        this.areas = res.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getCenters() {
                this.$store.dispatch(GET_CENTERS, {
                    limit: 10000,
                    areas: this.event.areas,
                    status: 1
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getCourses() {
                this.$store.dispatch(GET_COURSES, {
                    per_page: 10000,
                    center: this.areas.centers,
                    status: 1
                }).then((res) => {
                    if (!res.error) {
                        this.courses = res.data.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
                var formData = new FormData();
                formData.append("image", file);
                ApiService.post('upload-image', formData)
                    .then(({data}) => {
                        let url = data.data; // Get url from response
                        Editor.insertEmbed(cursorLocation, "image", url);
                        resetUploader();
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },
            onSubmit() {
                if (this.checkEdit) {
                    this.$store.dispatch(UPDATE_EVENT, this.event).then((res) => {
                        if (res.error) {
                            this.$bvToast.toast(res.message, {
                                title: 'Cập nhật',
                                variant: 'danger',
                                solid: true
                            });
                        } else {
                            this.$router.push({name: "event-list"}).then(() => {
                                this.$bvToast.toast(res.message, {
                                    title: 'Cập nhật',
                                    variant: 'success',
                                    solid: true
                                });
                            })
                        }
                    }).catch((e) => {
                        console.log(e);
                        if (e.data.data.message_validate_form) {
                            console.log(e.data.data.message_validate_form);
                            this.$refs.form.setErrors(e.data.data.message_validate_form);
                        }
                    });
                } else {
                    this.createEvent();
                }

            },
            createEvent() {
                this.$store.dispatch(CREATE_EVENT, this.event).then((res) => {
                    if (res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Tạo mới',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.$router.push({name: "event-list"}).then(() => {
                            this.$bvToast.toast(res.message, {
                                title: 'Tạo mới',
                                variant: 'success',
                                solid: true
                            });
                        })
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                });
            },
            handleTypeChange(event) {
                if (event == '2') {
                    this.showCourse = true
                    this.showArea = false
                    this.showCenter = false
                } else if (event == '1') {
                    this.showCenter = true
                    this.showCourse = false
                    this.showArea = false
                } else if (event == '0') {
                    this.showArea = true
                    this.showCourse = false
                    this.showCenter = false
                }
            },
        }
    }
</script>
<style scoped>
  .mx-datepicker {
    width: 100% !important;
  }
  .el-radio-group {
    width: 100% !important;
  }
</style>
