<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Hệ số kinh doanh</h3>
      </div>
      <div class="card-title">
        <router-link
            class="font-weight-bold font-size-3  btn btn-success"
            :to="{ name: 'business-coefficient-create' }"
        >Thêm mới
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="input-name">Tên hệ số</label>
            <el-input id="input-name" placeholder="Tên hệ số" v-model="query.keyword"></el-input>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="input-coefficient">Hệ số</label>
            <el-select v-model="query.coefficient" id="input-coefficient" class="w-100" placeholder="Hệ số" filterable clearable>
              <el-option
                  v-for="item in coefficients"
                  :key="item.coefficient"
                  :label="item.coefficient"
                  :value="item.coefficient">
                <span style="float: left">{{ item.coefficient }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="input-customer">Đối tượng khách hàng</label>
            <el-select id="input-customer" class="w-100" placeholder="Đối tượng khách hàng" v-model="query.customer_type" filterable clearable
            >
              <el-option
                  v-for="item in CUSTOMER_TYPES"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                <span style="float: left">{{ item.label }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="input-type">Hình thức đẩy doanh số</label>
            <el-select id="input-type" v-model="query.coefficient_type" filterable class="w-100" placeholder="Trạng thái" clearable>
              <el-option
                  v-for="item in COEFFICIENT_TYPES"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                <span style="float: left">{{ item.label }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="d-flex justify-content-between">
            <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                    @click="searchData">
              <i class="fa fa-search"></i> Tìm kiếm
            </button>
            <button v-else type="button" disabled
                    class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
              Tìm kiếm
            </button>
          </div>
        </div>
      </div>

      <div class="example mb-10 mt-5">
        <div class="example-preview table-responsive">
          <table class="table table-hover table-bordered table-vertical-center">
            <thead>
              <tr>
              <th scope="col" style="width: 150px">Mã hệ số</th>
              <th scope="col">Tên hệ số</th>
              <th scope="col">Chi tiết</th>
              <th scope="col" style="width: 100px">Hệ số</th>
              <th scope="col">Chi nhánh áp dụng</th>
              <th scope="col">Đối tượng khách hàng</th>
              <th scope="col">Hình thức đẩy doanh số</th>
              <th scope="col">Hoạt động</th>
              <th scope="col">Hành động</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in business_coefficients" :key="index">
              <td>{{ item.id }}</td>
              <td class="min-w-150px max-w-250px text-break">{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td><strong class="text-danger">{{ item.coefficient }}</strong></td>
              <td class="max-w-250px">
                <div class="text-break">
                  <span class="ml-1 badge badge-pill badge-success mt-1"
                        v-if="item.branches.length > 0" v-for="(name , k) in item.branches"
                        :key="k">
                     {{ name }}
                  </span>
                </div>
              </td>
              <td>{{ item.customer_type }}</td>
              <td>{{ item.coefficient_type }}</td>
              <td>
                <change-status :business-coefficient="item"></change-status>
              </td>
              <td>
                <div class="d-flex justify-content-around align-items-center">
                  <router-link :to="{ name: 'business-coefficient-update', params: {id : item.id} }" title="Sửa"
                               class="btn btn-sm btn-icon btn-outline-info"><i
                      class="fas fa-pen-nib"></i>
                  </router-link>
                  <a title="Xóa" @click="deleteNews(item.id)" href="javascript:"
                     class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {DELETE_COEFFICIENT, GET_COEFFICIENTS} from "@/core/services/store/business/coefficient.module";
import {CUSTOMER_TYPES , COEFFICIENT_TYPES} from "@/core/option/businessCoefficientOption";
import {GET_DISTINCT_COEFFICIENTS} from "../../../core/services/store/business/coefficient.module";
import changeStatus from './ChangeStatus';

const _ = require('lodash');

export default {
  components: {changeStatus},
  name: "BusinessCoefficientIndex",
  data() {
    return {
      is_disable_search: false,
      business_coefficients: [],
      coefficients: [],
      COEFFICIENT_TYPES,
      CUSTOMER_TYPES,
      // Paginate
      page: 1,
      last_page: 1,
      query: {
        keyword: '',
        coefficient: '',
        customer_type: '',
        coefficient_type: '',
      },
    }
  },
  created() {
    this.getDistinctCoefficient();
    this.getList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý hệ số kinh doanh", route: 'business-coefficient-index'},
      {title: "Hệ số kinh doanh"}
    ]);
  },
  methods: {
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl()
      this.callApiGetList()
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'business-coefficient-index',
        query: {
          page: this.page,
          ...this.query
        }
      })
    },
    getList() {
      this.pullParamsUrl();
      this.callApiGetList();
    },
    getDistinctCoefficient(){
      this.$store.dispatch(GET_DISTINCT_COEFFICIENTS).then((res) => {
        this.coefficients = res.data;
      });
    },
    pullParamsUrl() {
      this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
      this.query.keyword = this.$route.query.keyword;
      this.query.coefficient = this.$route.query.coefficient ? Number(this.$route.query.coefficient) : '';
      this.query.customer_type = this.$route.query.customer_type ? Number(this.$route.query.customer_type) : '';
      this.query.coefficient_type = this.$route.query.coefficient_type ? Number(this.$route.query.coefficient_type) : '';
    },
    clickCallback(page) {
      this.page = page
      this.$router.push({path: '', query: {page: this.page}})
      this.callApiGetList()
    },
    callApiGetList() {
      this.$store.dispatch(GET_COEFFICIENTS, {
        page: this.page,
        ...this.query
      }).then((res) => {
        this.business_coefficients = res.data;
        this.last_page = res.pagination.last_page;
      }).finally(() => this.is_disable_search = false);
    },

    deleteNews(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        denyButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_COEFFICIENT, id).then(() => {
            Swal.fire("Xóa", "", "success");
            this.getList()
          }).catch((error) => {
            this.$notify({
              title: 'Thất bại',
              message: error.data.message,
              type: 'error'
            });
          });
        }
      });
    },

  }
}
</script>
<style>
.checkbox-slide, .checkbox-switch {
  border-radius: 20px !important;
}
</style>

