<template>
  <div>
    <FormulateForm
        :schema="schema"
        v-model="values"
        #default="{ isLoading }"
        @focus-confirm="focusedOn = 'confirm'"
        @file-upload-complete="handleData"
        @submit="submitHandler">
      {{ focusedOn }}
    </FormulateForm>
  </div>
</template>

<script>
import {ecosystemPage} from "./index";
import {
  EDU_OPTION_POST,
  EDU_OPTION_PUT,
  GET_EDU_OPTION
} from "@/core/services/store/edutalk-option/edutalk-option.module";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import d from "@/assets/plugins/formvalidation/dist/es6/validators/ip";

const _ = deepdash(lodash);
export default {
  name: "ecosystem-page",
  data() {
    return {
      schema: ecosystemPage,
      focusedOn: null,
      values: {
      },
      value_prop: {},
      fileName: {
        ecosystem_image: {
          url: "",
          name: ""
        }
      },
      convertData: [],
      array: [],
      array_image_block: [],
      array_image_block_04: [],
      array_prop: [],
      id: ''
    }
  },
  mounted() {
    this.getOption();
  },
  methods: {
    async handleData(data) {
      let index = null;
      let exits = null;
      _.eachDeep(this.values[`${data.parent}`], function (value, key, path, depth, parent) {
        if (index === null) {
          index = key
        }
        if (key === 'ecosystem_image') {
          if (value.context.attributes.id === data.id) {
            exits = depth;
            data.indexParent = exits._item.path[0];
            data.grop_one = exits._item.path[1];
            data.index = exits._item.path[2];
            data.grop_two = exits._item.path[3];
          }
          return false
        }
      });
      if (data.grop_one){
        this.array.push(data);
      }

      if(data.parent === 'ecosystem_image_block') {
        this.array_image_block.push([data]);
      }
      if(data.parent === 'ecosystem_image_block_04') {
        this.array_image_block_04.push([data]);
      }
    },

    submitHandler(data, log) {
      let data_form = {};
      let index = null;
      if(Object.keys(this.array_prop).length) {
        _.eachDeep(data, function (value, key, path, depth, parent) {
          if (index === null) {
            index = key
          }
          if (key && key.indexOf("ecosystem_image") !== -1) {
            path[key] = path[key].results;
            return false
          }
        });
        data_form = data;
      } else {
        this.array.map((value) => {
          let description = data[value.parent][value.indexParent][value.grop_one][value.index].description;
          let title_description = data[value.parent][value.indexParent][value.grop_one][value.index].title_description;
          data[value.parent][value.indexParent][value.grop_one][value.index] = {ecosystem_image: [{url: value.url , name: value.name}] , description: description , title_description: title_description};
        });
        for (let i = 0;i<this.array_image_block.length;i++) {
          data['ecosystem_img_items_01'][i]['ecosystem_image_block'] = this.array_image_block[i];
        }

        for (let i = 0;i<this.array_image_block_04.length;i++) {
          data['ecosystem_img_items_04'][i]['ecosystem_image_block_04'] = this.array_image_block_04[i];
        }
        data_form = data;
      }
      if(this.id) {
        this.$store.dispatch(EDU_OPTION_PUT, {id: this.id,template: 'ecosystem' , data: JSON.stringify(data_form)})
            .then((res) => {
              if (!res.error) {
                this.$bvToast.toast(res.message, {
                  title: 'Cập nhật',
                  variant: 'success',
                  solid: true
                });
              }
            }).catch((e) => {
        });
      } else {
        this.$store.dispatch(EDU_OPTION_POST, {template: 'ecosystem' , data: JSON.stringify(data_form)})
            .then((res) => {
              if (!res.error) {
                this.$bvToast.toast(res.message, {
                  title: 'Thêm mới',
                  variant: 'success',
                  solid: true
                });
              }
            }).catch((e) => {
        });
      }
    },
    getOption() {
      this.$store.dispatch(GET_EDU_OPTION, {template: 'ecosystem'})
          .then((res) => {
            if (!res.error) {
              this.array_prop = JSON.parse(res[1]);
              this.id = res[2];
              this.values = res[0].length === 0 ? {} : res[0];
            }
          }).catch((e) => {
      });
    },
  }
}
</script>

<style scoped>

</style>