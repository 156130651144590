import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "../auth.module";
const _ = require('lodash');
export const GET_TICKET_CSVC = "csvc/tickets"
export const GET_DETAIL_TICKET_CSVC = "GET_DETAIL_TICKET_CSVC";
export const RECEIVE_PROCESS = "RECEIVE_PROCESS";
export const COMPLETE_REQUEST = "COMPLETE_REQUEST";
export const EXPORT_TICKET_CSVC = "EXPORT_TICKET_CSVC";
export const GET_LIST_BRANCH_EDUTALK_HEADQUARTER = 'branches/get-list-is-edutalk-headquarters'
const state = {};

const getters = {};
const actions = {
    [GET_TICKET_CSVC](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_TICKET_CSVC}`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [GET_DETAIL_TICKET_CSVC](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`csvc/tickets`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [RECEIVE_PROCESS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`csvc/progressing/${payload.id}`, payload.body).then(({data}) => {
                resolve(data);
            }).catch(({response}) =>{
                reject(response);
            });
        });
    },
    [COMPLETE_REQUEST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`csvc/success/${payload.id}`, payload.body).then(({data}) => {
                resolve(data);
            }).catch(({response}) =>{
                reject(response);
            });
        });
    },
    [EXPORT_TICKET_CSVC](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`csvc/tickets/export`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [GET_LIST_BRANCH_EDUTALK_HEADQUARTER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LIST_BRANCH_EDUTALK_HEADQUARTER}`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

};


const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};