<template>
  <el-form :model="model" :rules="rules" ref="form">
    <el-form-item :label="testIncome.type_test == 'daihoc'? 'Nhận xét' : 'Nhận xét tổng quan'" prop="comment">
      <el-input
          type="textarea"
          :rows="2"
          :disabled="isDisable"
          placeholder="Nhập nhận xét"
          v-model="model.comment">
      </el-input>
    </el-form-item>
    <el-form-item label="Khóa học gợi ý" prop="course">
      <el-input
          type="textarea"
          :rows="2"
          :disabled="isDisable"
          placeholder="Nhập khóa học gợi ý"
          v-model="model.course">
      </el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    testIncome: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isDisable: false
  },
  data(){
    return {
      model: {
        comment: '',
        course: '',
      },
      rules: {
        comment: [
          {required: true,message: 'Nhận xét là bắt buộc',trigger: 'blur'},
          {min: 0, max: 1000, message: 'Độ dài không vượt quá 1000 kí tự', trigger: ['change', 'blur']},
        ],
        course: [
          {required: true,message: 'Khóa học gợi ý là bắt buộc',trigger: 'blur'},
          {min: 0, max: 150, message: 'Độ dài không vượt quá 150 kí tự', trigger: ['change', 'blur']},
        ],
      }
    }},
  watch: {
    testIncome: {
      handler() {
        if (this.testIncome.course_suggest) {
          this.model.course = this.testIncome.course_suggest;
        }
        if (this.testIncome.comment) {
          this.model.comment = this.testIncome.comment;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          this.$emit('on-validate', valid, this.model)
          resolve(valid);
        });
      })

    }
  }

}
</script>

<style>

</style>
