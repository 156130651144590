<template>
  <div class="input-group">
    <money
        @change.native="changeAmount"
        v-model="amount_copy"
        class="form-control"
        v-bind="money">
    </money>
  </div>
</template>

<script>
export default {
  name: "InputAmountItem",
  props: {
    date: {
      type: String,
      default: () => {
        return '';
      }
    },
    amount: {
      type: Number,
      default: () => {
        return 0;
      }
    },
    sources: {
      type: Array,
      default: () => {
        return [];
      }
    },
    centers: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      amount_copy: 0,
      money: {
        decimal: ',',
        thousands: ',',
        prefix: '',
        suffix: ' VNĐ',
        precision: 0,
        masked: false,
      }
    }
  },
  watch: {
    amount() {
      this.amount_copy = this.amount;
    }
  },
  mounted() {
    this.amount_copy = this.amount;
  },
  methods: {
    changeAmount() {
      this.$emit('changeInputAmount', this.amount_copy, this.date);
    }
  }
}
</script>

<style scoped>
.input-group-addon {
  display: inline;
}
</style>