<script>
import {
  CREATE_SCHOOL,
  DETAIL_SCHOOL,
  EDIT_SCHOOL
} from "@/core/services/store/school-link-source/school-link-source.module";
import _debounce from 'lodash/debounce';
export default {
  name: "ModalEditSchool",
  props:{
    provinces:{
      type: Array,
      default(){
        return []
      }
    },
    schoolCurrent:{
      type: Object,
      default(){
        return null
      }
    }
  },
  data(){
    return {
      nameSchool: null,
      provinces_id: null,
      coefficient: undefined,
      loading: false,
      school_rate: '',
      source: '',
    }
  },
  methods: {
    clearDataAndCloseModal(){
      this.$bvModal.hide('Modal_Edit_School');
      this.nameSchool = null;
      this.provinces_id = null;
      this.coefficient = undefined;
      this.school_rate = '';
      this.source = '';
    },
    confirmEditSchool(){
      this.loading = true;
      let payload = {
        id: this.schoolCurrent.id,
        data: {
          name: this.nameSchool,
          province_id: this.provinces_id,
          business_coefficient: this.coefficient,
          school_rate: this.school_rate,
          source: this.source
        }
      };
      this.$store.dispatch(EDIT_SCHOOL,payload).then((data)=>{
        this.clearDataAndCloseModal();
        this.$notify({
          title: 'Thành công',
          message: 'Sửa trường liên kết thành công',
          type: 'success'
        });
        this.$emit('refreshData')
      }).catch((e)=>{
        this.$notify.error({
          title: 'Đã có lỗi xẩy ra',
          message: 'Vui lòng thử lại'
        });
      }).finally(()=>{
        this.loading = false;
      })
    },
    debouncedCreate: _debounce(function() {
      this.confirmEditSchool();
    }, 500),
    getDetailSchool(){
      this.provinces_id = this.schoolCurrent.province_id;
      this.nameSchool = this.schoolCurrent.name;
      this.coefficient = this.schoolCurrent?.business_coefficient??undefined;
      this.school_rate = this.schoolCurrent?.school_rate;
      this.source = this.schoolCurrent?.source;
    }
  }
}
</script>

<template>
  <b-modal id="Modal_Edit_School" title="Sửa trường liên kết" centered hide-footer ref="Modal_Edit_School"
           size="xl" @hidden="clearDataAndCloseModal()" @show="getDetailSchool">
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form class="form" @submit.prevent autocomplete="off">
        <div class="row">
          <div class="col-lg-4 col-xs-12">
            <p>Tên trường <span class="text-danger">*</span></p>
            <ValidationProvider name="Tên trường" :rules="{max:100,required:true}" v-slot="{ errors,classes }"
                                vid="title">
              <el-input  placeholder="Nhập tên trường" v-model="nameSchool"  show-word-limit />
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-lg-4 col-xs-12">
            <p>Khu vực <span class="text-danger">*</span></p>
            <ValidationProvider name="Khu vực" rules="required" v-slot="{ errors,classes }"
                                vid="title1">
              <el-select v-model="provinces_id" placeholder="Chọn khu vực" clearable style="width: 100%" filterable>
                <el-option
                    v-for="item in provinces"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-lg-4 col-xs-12">
            <p>Hệ số kinh doanh </p>
            <el-input-number controls-position="right" id="input-coefficient"
                             :step=".01" v-model="coefficient"
                             placeholder="Nhập hệ số kinh doanh"
                             class="w-100">
            </el-input-number>
          </div>
          <div class="col-lg-4 col-xs-12 mt-2">
            <p>Phần trăm nhà trường </p>
            <el-input-number controls-position="right" id="input-school-rate"
                             :step="1" v-model="school_rate"
                             max="100"
                             min="0"
                             placeholder="Nhập hệ số kinh doanh"
                             class="w-100"
            >

            </el-input-number>
          </div>
          <div class="col-lg-4 col-xs-12 mt-2">
            <p>Nguồn trích phần trăm  </p>
            <el-select v-model="source">
              <el-option :value="1" label="Voucher">Voucher</el-option>
              <el-option :value="2" label="Đội kinh doanh">Đội kinh doanh</el-option>
            </el-select>
          </div>
        </div>
        <div class="d-flex d-flex flex-row-reverse mt-5">
          <button type="button" class="btn  ml-4" style="background-color: #5D04B0;color: white" @click="handleSubmit(debouncedCreate)">
            <i v-if="loading" class="el-icon-loading"></i> Xác nhận
          </button>
          <button type="button" class=" btn btn-secondary" @click="clearDataAndCloseModal()"  >Hủy</button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<style scoped>

</style>