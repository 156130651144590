<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label fw-bold">Quản lý lịch học</h3>
            </div>
            <div class="mt-2">
                <el-button @click="create" class="btn-success btn">Thêm mới</el-button>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Lịch học</label>
                        <el-input @input="search" placeholder="Nhập lịch học" v-model="query.keyword"></el-input>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Trung tâm</label>
                        <el-select @change="search" multiple v-model="query.center_id" filterable class="w-100" placeholder="Trung tâm" clearable
                                   collapse-tags>
                            <el-option v-for="item in centers" :key="item.id" :label="item.name" :value="item.id">
                                <span style="float: left">{{ item.name }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="example mb-10">
                <div class="example-preview table-responsive ">
                    <table class="table table-vertical-center">
                        <thead class="table-secondary">
                        <tr>
                            <th class="min-w-50px" scope="col">Tên lịch học</th>
                            <th scope="col" class="min-w-150px">Trung tâm áp dụng</th>
                            <th scope="col" class="min-w-200px">Trạng thái</th>
                            <th scope="col" class="min-w-100px">Người cập nhật</th>
                            <th scope="col" class="min-w-120px">Thời gian cập nhật</th>
                            <th scope="col" class="min-w-120px">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="text-center" v-if="!data.length">
                            <td colspan="10">Không có dữ liệu</td>
                        </tr>
                        <tr v-for="(item, index) in data" :key="index" v-else>
                            <td> {{ item.name }} </td>
                            <td>
                                <span v-if="centers.length == item.center_name.length"> Tất cả trung tâm </span>
                                <span v-else v-for="(i, t) in item.center_name">
                                    {{ i }}
                                    <span v-if="item.center_name.length - t != 1">,</span>
                                </span>
                            </td>
                            <td> {{ item.status ? 'Hiển thị' : 'Ẩn' }} </td>
                            <td> {{item.user ? item.user.name : ''}} - {{item.user ? item.user.id + 100000 : ''}} </td>
                            <td> {{ item.updated_at |formatDateTimeVietnam}} </td>
                            <td>
                                <a href="javascript:" title="Sửa hợp đồng" @click="showDetail(item)"
                                   class="btn btn-xs btn-icon btn-outline-info ml-2">
                                    <i class="fas fa-pen-nib"></i>
                                </a>
                                <a
                                   title="Hủy"
                                   @click="deleteItem(item.id)"
                                   href="javascript:"
                                   class="btn btn-xs btn-icon btn-outline-danger ml-2"><i class="fas fa-trash"></i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{
                    totalData }}</b></div>
        </div>
        <div class="edu-paginate mx-auto d-flex justify-content-center">
            <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
                      :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
                      :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
                      :next-class="'page-link'" :page-class="'page-item'">
            </paginate>
        </div>
        <div>
            <el-dialog :title="title" :visible.sync="outerVisible">
                <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                    <form  id="permission-add" ref="permission-add" class="form" @submit.prevent="handleSubmit(handleData)">
                        <div class="d-flex">
                            <div class="col-md-6">
                                <label for="">Tên lịch học</label>
                                <ValidationProvider vid="name" name="Tên lịch học" rules="required"
                                                    v-slot="{ errors,classes }">
                                    <input type="text" class="form-control" v-model="dataCreate.name" maxlength="20">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-6">
                                <label for="">Trung tâm áp dụng</label>
                                <ValidationProvider vid="center_id" name="Trung tâm áp dụng" rules="required"
                                                    v-slot="{ errors,classes }">
                                    <el-select multiple v-model="dataCreate.center_id" filterable class="w-100" placeholder="Trung tâm" clearable
                                               collapse-tags>
                                        <el-option v-for="item in centers" :key="item.id" :label="item.name" :value="item.id">
                                            <span style="float: left">{{ item.name }}</span>
                                        </el-option>
                                    </el-select>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>

                            </div>
                        </div>
                        <div>
                            <label class="col-3 col-form-label">Ẩn/Hiển thị</label>
                            <div class="col-5">
                        <span class="switch switch-sm switch-icon">
                            <label>
                                <input v-model="dataCreate.action" type="checkbox" checked="checked" name="select"/>
                                <span></span>
                            </label>
                        </span>
                            </div>
                        </div>
                        <div class="col-md-12 mt-4">
                            <label for="">Lịch học</label>
                            <ValidationProvider vid="date" name="Lịch học áp dụng" rules="required"
                                                v-slot="{ errors,classes }">
                                <el-select v-model="dataCreate.date" class="w-100" placeholder="Lịch học"  multiple
                                           @change="onCalendarChange">
                                    <el-option v-for="item in calendarDates" :key="item.id" :label="item.name" :value="item.id">
                                        <span style="float: left">{{ item.name }}</span>
                                    </el-option>
                                </el-select>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                        {{ errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>

                        </div>
                        <div class="col-md-12 mt-4">
                            <div class="row mb-3" v-for="(item, index) in dataCreate.calendarList" :key="index">
                                <div class="col mt-4 mb-2 d-flex flex-wrap align-items-center">
                                    <label class="calendar-label font-weight-bold" style="margin-right: 10px">{{
                                            item.day
                                        }}</label>
                                    <div class="form-group flex-column d-flex col-md-4 col-lg-5">
                                        <label for="">Thời gian bắt đầu</label>
                                        <ValidationProvider vid="start_date" name="Thời gian bắt đầu" rules="required"
                                                            v-slot="{ errors,classes }">
                                            <el-time-picker
                                                v-model="item.start_date"
                                                @change="changeStartTime"
                                                :picker-options="{
                                                  selectableRange: '06:00:00 - 23:59:00'
                                                }"
                                                format="HH:mm"
                                                placeholder="Select time">
                                            </el-time-picker>
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{ errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>

                                    </div>
                                    <div class="form-group flex-column d-flex col-md-4 col-lg-5">
                                        <label for="">Thời gian kết thúc</label>

                                        <ValidationProvider vid="end_date" name="Thời gian kết thúc" rules="required"
                                                            v-slot="{ errors,classes }">
                                            <el-time-picker
                                                v-model="item.end_date"
                                                @change="changeEndDate(item)"
                                                :picker-options="{
                                                  selectableRange: '06:00:00 - 23:59:00'
                                                }"
                                                format="HH:mm"
                                                placeholder="Select time">
                                            </el-time-picker>
                                            <div class="fv-plugins-message-container">
                                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                    {{ errors[0] }}
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <span @click="addOrRemoveNewCalenderOnDay(item,'add')" ><i
                                        class="fas fa-plus-circle text-success mr-2" style="font-size: 1.3em;"></i></span>
                                    <span v-if="checkCanRemove(item)" @click="addOrRemoveNewCalenderOnDay(item,'remove')" ><i
                                        class="fas fa-minus-circle text-danger" style="font-size: 1.3em;"></i></span>
                                </div>
                            </div>
                        </div>
                        <div slot="footer" class="dialog-footer d-flex justify-content-end">
                            <el-button class="btn btn-secondary mr-2" @click="closeData">Huỷ</el-button>
                            <button type="submit" class="btn btn-success mr-2">
                                Xác nhận
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import moment from "moment-timezone";
import {
    GET_SCHOOL_SCHEDULE,
    CREATE_SCHOOL_SCHEDULE,
    UPDATE_SCHOOL_SCHEDULE,
    DELETE_SCHOOL_SCHEDULE
} from "../../../core/services/store/ticket/ticket.module";
import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
import Swal from "sweetalert2";
export default {
    name: "ManageSchoolSchedules",
    data() {
        return {
            data: {},
            dataCreate: {
                name: '',
                calendarList: [],
                action: 1,
                date: [],
                center_id: ''
            },
            page: 1,
            last_page: 1,
            totalData: 0,
            query: {
                keyword: '',
                center_id: '',
                date: []
            },
            centers: [],
            outerVisible: false,
            calendarDates: [
                {
                    id: 1,
                    name: 'Thứ 2',
                },
                {
                    id: 2,
                    name: 'Thứ 3',
                },
                {
                    id: 3,
                    name: 'Thứ 4',
                },
                {
                    id: 4,
                    name: 'Thứ 5',
                },
                {
                    id: 5,
                    name: 'Thứ 6',
                },
                {
                    id: 6,
                    name: 'Thứ 7',
                },
                {
                    id: 0,
                    name: 'Chủ nhật',
                },
            ],
            title: '',
            isUpdate: false,
            start_time: ''
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Quản lý sản xuất" },
            { title: "Quản lý lịch học" }
        ]);
        this.getData();
        this.getCenters();
    },
    methods: {
        getCenters() {
            this.$store.dispatch(GET_CENTERS, {
                limit: 10000,
                is_edutalk: 1,
                status: 1
            }).then((res) => {
                if (!res.error) {
                    this.centers = res.data.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getData()
        {
            this.$store.dispatch(GET_SCHOOL_SCHEDULE, {
                keyword: this.query.keyword,
                center_id: this.query.center_id
            }).then((res) => {
                this.data = res.data.data;
                this.totalData = res.data.total;
            })
        },
        clickCallback()
        {

        },
        create()
        {
            this.outerVisible = true;
            this.title = 'Thêm mới lịch học';
            this.isUpdate = false;
            this.dataCreate = {
                name: '',
                calendarList: [],
                action: 1,
                date: [],
                center_id: ''
            };
        },
        showDetail(item)
        {
            this.outerVisible = true;
            this.title = 'Sửa lịch học';
            this.isUpdate = true;

            let calendarList = item.detail_classroom_time.map(
                (item, key) => ({
                    ...item,
                    day: this.calendarDates.find((x) => x.id === item.day_of_week).name,
                    date: item.day_of_week,
                    start_date: new Date(2024,1,1,moment(item.start_time, "HH:mm:ss").hour(), moment(item.start_time, "HH:mm:ss").minute()),
                    end_date: new Date(2024,1,1,moment(item.end_time, "HH:mm:ss").hour(), moment(item.end_time, "HH:mm:ss").minute()),
                    count: key,
                })
            );

            this.dataCreate = {
                id: item.id,
                name: item.name,
                calendarList: calendarList,
                action: item.status,
                date: [...new Set(item.detail_classroom_time.map(item => item.day_of_week))],
                center_id: item.center_id,
            };
        },
        deleteItem(id)
        {
            Swal.fire({
                title: "Bạn chắc chắn muốn xóa?",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Đồng ý",
                cancelButtonText: "Không",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch(DELETE_SCHOOL_SCHEDULE, id).then((res) => {
                        if (!res.error) {
                            Swal.fire("Xóa", "", "success");
                            this.getData();
                            return;
                        }
                        this.$notify({
                            title: 'Xóa',
                            message: 'Lịch học đã được áp dụng !',
                            type: 'error'
                        });
                    });
                }
            });
        },
        closeData() {
            this.outerVisible = false;
            this.dataCreate = [];
        },
        handleData()
        {
            if (this.isUpdate)
            {
                this.$store.dispatch(UPDATE_SCHOOL_SCHEDULE, this.dataCreate).then((res) => {
                    if (!res.error)
                    {
                        this.$notify({
                            title: 'Thành công',
                            message: 'Cập nhật thành công',
                            type: 'success'
                        });
                        this.outerVisible = false;
                        this.getData();
                        return;
                    }
                    this.$notify({
                        title: 'Thất bại',
                        message: 'Lịch học đã được áp dụng !',
                        type: 'error'
                    });
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                });
                return;
            }

            this.$store.dispatch(CREATE_SCHOOL_SCHEDULE, this.dataCreate).then((res) => {
                this.$notify({
                    title: 'Thành công',
                    message: 'Thêm mới thành công',
                    type: 'success'
                });
                this.outerVisible = false;
                this.getData();
                this.dataCreate = [];

            }).catch((e) => {
                if (e.data.data.message_validate_form) {
                    this.$refs.form.setErrors(e.data.data.message_validate_form);
                }
            });


            this.outerVisible = false;
        },
        onCalendarChange(dates) {
            const calendarUniqueList = [];
            this.dataCreate.calendarList.forEach((calendar) => {
                if (!calendarUniqueList.includes(calendar.date)  ) {
                    calendarUniqueList.push(calendar.date);
                }
            });
            const countOldCalendar = calendarUniqueList.length;
            const countNewCalendar = dates.length;

            const lastDate = dates[dates.length - 1];

            if (countNewCalendar > countOldCalendar) {
                const foundCalendar = this.calendarDates.find((x) => x.id === lastDate);
                if (foundCalendar  ) {
                    const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;
                    const startDate = this.convertToDate();
                    const endDate = new Date(startDate.getTime() + FIVE_MINUTES_IN_MS);


                    this.dataCreate.calendarList.push({
                        day: foundCalendar.name,
                        date: lastDate,
                        start_time: startDate,
                        end_time: endDate,
                        start_date: '',
                        end_date: '',
                        count: this.dataCreate.calendarList.length,
                    });
                }
            } else if (countNewCalendar < countOldCalendar) {
                const diff = calendarUniqueList.find(
                    (element) => !dates.includes(element)
                );
                if (diff !== undefined) {
                    this.dataCreate.calendarList = this.dataCreate.calendarList.filter(
                        (x) => x.date !== diff
                    );
                }
            }
            console.log(this.dataCreate.calendarList, 'calendarList')
        },
        convertToDate(date, up) {
            if (date == null) {
                const currentDate = new Date();
                if (up) {
                    currentDate.setMinutes(Math.ceil((new Date().getMinutes() + 1) / 5) * 5);
                } else {
                    currentDate.setMinutes(
                        Math.round((new Date().getMinutes() - 1) / 5) * 5
                    );
                }
                return currentDate;
            }
            return new Date(date);
        },
        addOrRemoveNewCalenderOnDay(item, action)
        {
            if (action === 'add') {
                this.dataCreate.calendarList.push({
                    day: item.day,
                    date: item.date,
                    start_time: item.start_time,
                    end_time: item.end_time,
                    start_date: moment(item.start_time.value).format('HH:mm'),
                    end_date: moment(item.end_time.value).format('HH:mm'),
                    count: this.dataCreate.calendarList.length,
                });
            } else {
                const index = this.dataCreate.calendarList.indexOf(item);
                if (index > -1) {
                    this.dataCreate.calendarList.splice(index, 1);
                }
            }
            this.sortArrayByDate();
        },
        checkCanRemove(item)
        {
            return this.dataCreate.calendarList.filter((element) => element.date === item.date).length > 1;
        },
        sortArrayByDate() {
            this.dataCreate.calendarList.sort((
                a,
                b
            ) => {
                // tslint:disable-next-line:one-variable-per-declaration
                const keyA = a.date,
                    keyB = b.date;
                // Compare the 2 dates
                if (keyA < keyB) {
                    return -1;
                }
                if (keyA > keyB) {
                    return 1;
                }
                return 0;
            });
        },
        search()
        {
            this.page = 1;
            this.pushParamsUrl();
            this.getData();
        },
        pushParamsUrl() {
            this.$router.push({
                path: '', query: {
                    page: this.page,
                    ...this.query
                }
            })
        },
        changeEndDate(item)
        {
            const calendarIndex = this.dataCreate.calendarList.indexOf(item);
            let start_time = item.end_date;
            if (start_time < this.start_time) {
                this.$notify({
                    title: 'Thất bại',
                    message: 'Thời gian kết thúc phải nhỏ hơn thời gian bắt đầu',
                    type: 'error'
                });
                this.dataCreate.calendarList[calendarIndex]['end_date'] = '';
            }
        },
        changeStartTime(time)
        {
            this.start_time = time;
        }
    }
}
</script>

<style scoped>

</style>