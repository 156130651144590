<template>
    <div>
        <header class="d-lg-flex justify-content-between d-sm-block ">
            <h1 style="color:#454545; font-size: 15px">{{
                    isVoucher ? 'Danh sách lớp học' : 'Cài đặt điều kiện nhận quà'
                }}</h1>
            <div class="d-flex mb-5">
                <button class="btn btn-warning mr-4 font-weight-bold btn-sm" style="color: black"
                        @click="dialogImport = true"> Import
                </button>
                <ModalAllClass @createClassSuccess="loadAgainData()" :is-voucher="isVoucher"></ModalAllClass>
            </div>
        </header>
        <div class=" table-responsive" style="height: auto ; max-height: 30vh ; overflow: auto">
            <table class="table table-bordered ">
                <thead class="table-secondary">
                <tr v-if="!isVoucher" class="table-active text-center">
                    <th scope="col">Số lượng khóa</th>
                    <th scope="col">Tên khóa học/combo</th>
                    <th scope="col">Loại voucher</th>
                    <th scope="col">Quà tặng</th>
                    <th scope="col">Hành động</th>
                </tr>
                <tr v-else class="table-active text-center">
                    <th scope="col">Lớp học đăng ký</th>
                    <th scope="col">Voucher</th>
                    <th scope="col">Hết slot</th>
                    <th scope="col">Hành động</th>
                </tr>
                </thead>
                <tbody v-if="listClass && listClass.length === 0 && listCondition && listCondition.length === 0">
                <tr class="text-center">
                    <td colspan="5">
                        Không có dữ liệu
                    </td>
                </tr>
                </tbody>
                <tbody v-else-if="!isVoucher">
                <tr v-for="(itemClass,index) in listCondition" :key="index" class="text-center">
                    <td>{{ itemClass?.quantity }}</td>
                    <td>{{ itemClass?.name }}</td>
                    <td>{{ itemClass?.typeString }}</td>
                    <td>{{ itemClass?.giftArr.join(', ') }}</td>
                    <td>
                        <button class="btn btn-xs btn-icon mr-2 btn-outline-primary"
                                title="Chỉnh sửa lớp học"
                                @click="EditClass(itemClass)"
                        >
                            <i class="flaticon2-pen"></i>
                        </button>
                        <button class="btn btn-xs btn-icon mr-2 btn-outline-danger"
                                title="xóa"
                                @click="deleteClass(itemClass?.id)"
                        >
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
                <tbody v-else-if="isVoucher">
                <tr v-for="(itemClass,index) in listClass" :key="index" class="text-center">
                    <td>{{ itemClass?.className }}</td>
                    <td>{{ itemClass?.voucher }}</td>
                    <td>
                        <el-checkbox v-model="itemClass.slot" @change="handleCheckSlot($event, itemClass.id)"></el-checkbox>
                    </td>
                    <td>
                        <button class="btn btn-xs btn-icon mr-2 btn-outline-primary"
                                title="Chỉnh sửa lớp học"
                                @click="EditClass(itemClass)"
                        >
                            <i class="flaticon2-pen"></i>
                        </button>
                        <button class="btn btn-xs btn-icon mr-2 btn-outline-danger"
                                title="xóa"
                                @click="deleteClass(itemClass?.id)"
                        >
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <el-dialog saS title="Cập nhật lớp học" centered hide-footer :visible.sync="Modal_Edit_Class"
                   size="lg">
            <ModalEditClass @closeEdit="closeEdit(); loadAgainData()" :itemClass="itemClass" @editClassSuccess="loadAgainData()"
                            :is-voucher="isVoucher"></ModalEditClass>
        </el-dialog>
        <div class="edu-paginate mx-auto d-flex justify-content-between align-items-center mt-10" v-if="total > 0">
            <paginate
                v-model="page"
                :page-count="last_page"
                :page-range="3"
                :margin-pages="1"
                :click-handler="clickCallback"
                :prev-text="'Trước'"
                :next-text="'Sau'"
                :container-class="'pagination b-pagination'"
                :pageLinkClass="'page-link'"
                :next-link-class="'next-link-item'"
                :prev-link-class="'prev-link-item'"
                :prev-class="'page-link'"
                :next-class="'page-link'"
                :page-class="'page-item'"
            >
            </paginate>
            <p class="mb-0">Tổng số bản ghi: <span
                class="text-primary ">{{ isVoucher ? listClass?.length : listCondition?.length }}</span></p>
        </div>
        <el-dialog class="responsive" :visible.sync="dialogImport"
                   :title="`${isVoucher?'Import danh sách lớp':'Import điều kiện nhận quà'}`">
            <modal-import-class-voucher v-if="isVoucher" @closeModalImport="closeModalImport"
                                        @importSuccess="importSuccess"
                                        :loadingPreview="loadingPreview"></modal-import-class-voucher>
            <modal-import-class v-else @closeModalImport="closeModalImport" @importSuccess="importSuccess"
                                :loadingPreview="loadingPreview"></modal-import-class>
        </el-dialog>
        <el-dialog class="responsive" :visible.sync="dialogPreview"
                   :title="`${isVoucher?'Report dữ liệu danh sách lớp':'Report dữ liệu điều kiện nhận quà'}`">
            <modal-preview-data-import-voucher v-if="isVoucher" @closeModalReview="closeModalReview"
                                               @doneImport="doneImport"
                                               :dataPreview="dataPreview"></modal-preview-data-import-voucher>
            <modal-preview-data-import v-else @closeModalReview="closeModalReview" @doneImport="doneImport"
                                       :dataPreview="dataPreview"></modal-preview-data-import>

        </el-dialog>
    </div>
</template>

<script>
import ModalAllClass from "@/view/pages/rewards-program/detail-program/ModalAddClass";
import {
    DELETE_CLASS,
    GET_LIST_CLASS,
    GET_LIST_GIFT
} from "@/core/services/store/rewards-programs/rewardsPrograms.module";
import CheckBoxOutOfSlots from "@/view/pages/rewards-program/detail-program/CheckBoxOutOfSlots";
import ModalEditClass from "@/view/pages/rewards-program/detail-program/ModalEditClass";
import ModalImportClass from '@/view/pages/rewards-program/detail-program/model/ModalImportClass'
import ModalPreviewDataImport from '@/view/pages/rewards-program/detail-program/model/ModalPreviewDataImport'
import {
    ClassDetailModel,
    ConditionModel
} from "@/view/pages/rewards-program/detail-program/model/InformationProgramModel";
import ModalImportClassVoucher from "@/view/pages/rewards-program/detail-program/model/ModalImportClassVoucher.vue";
import ModalPreviewDataImportVoucher
    from "@/view/pages/rewards-program/detail-program/model/ModalPreviewDataImportVoucher.vue";
import {TICK_OUT_OF_SLOT} from "../../../../core/services/store/rewards-programs/rewardsPrograms.module";

export default {
    name: "ListClassroom",
    components: {
        ModalPreviewDataImportVoucher,
        ModalImportClassVoucher,
        ModalEditClass,
        CheckBoxOutOfSlots,
        ModalAllClass,
        ModalImportClass,
        ModalPreviewDataImport

    },
    data() {
        return {
            listClass: [],
            listGift: [],
            itemClass: {},
            page: 1,
            last_page: 0,
            total: 0,
            dialogImport: false,
            dialogPreview: false,
            dataPreview: [],
            closePreview: false,
            loadingPreview: true,
            checked: true,
            loading: true,
            listCondition: [],
            Modal_Edit_Class: false
        }
    },
    props: {
        flagDelete: {
            type: Boolean,
            default() {
                return false
            }
        },
        typeProgram: {
            type: Number || String,
            default: 'Voucher',
        }
    },
    computed: {
        isVoucher() {
            return this.typeProgram === 1;
        }
    },
    watch: {
        async flagDelete() {
            await this.getListGift()
            this.getListClass();
        },
        async typeProgram() {
            await this.getListGift()
            this.getListClass();
        }
    },
    async mounted() {
        this.idProgram = this.$route.params.id
        await this.getListGift()
        this.getListClass();
    },
    methods: {
        handleCheckSlot(value, classId){
            let payload = {
                is_of_slot : value,
                spin_reward_program_classroom_id : classId
            }
            this.$store.dispatch(TICK_OUT_OF_SLOT,payload).then(()=>{
            }).catch(()=>{

            })
        },
        getListClass() {
            const payload = {
                id: this.idProgram,
                page: this.page,
                program_type: this.typeProgram
            }

            this.$store.dispatch(GET_LIST_CLASS, payload).then((data) => {
                if (!data.error) {
                    const res = data.data
                    this.listClass = res?.length > 0 ? res?.filter(item => item.program_type === 1).map(item => {
                        return new ClassDetailModel(
                            item?.id,
                            item?.name,
                            item?.gift?.name,
                            item?.is_of_slot !== 0,
                            item?.gift?.id
                        )
                    }) : []
                    this.listCondition = res?.length > 0 ? res?.map(item => {
                        return new ConditionModel(
                            item?.id,
                            item?.combo?.quantity,
                            item?.combo?.name,
                            item?.type_voucher,
                            item?.gift,
                            item?.combo_id,
                            item?.gift_ids,
                        )
                    }) : []
                    this.total = data.pagination.total;
                    this.page = data.pagination.current_page;
                    this.last_page = data.pagination.last_page;
                }
            })
        },
        closeEdit() {
            this.Modal_Edit_Class = false
        },
        async getListGift() {
            const payload = {
                id: this.idProgram,
                page: this.page,

            };
            await this.$store.dispatch(GET_LIST_GIFT, payload).then((data) => {
                if (!data.error) {
                    this.listGift = data.data.data
                }
            })
        },
        deleteClass(id) {
            this.$confirm('Hệ thống sẽ xóa tất cả thông tin của 1 chương trình (Quà tặng, cài đặt điều kiện nhận thưởng).' +
                ' Bạn có chắc chắn muốn xóa chương trình quay thưởng?', {
                confirmButtonText: 'Xác nhận',
                cancelButtonText: 'Hủy',
            }).then(() => {
                this.$store.dispatch(DELETE_CLASS, id).then(() => {
                    this.getListClass()
                    this.$notify({
                        title: 'Thành công',
                        message: 'Đã xóa thành công ',
                        type: 'success'
                    });
                }).catch(() => {
                    this.$notify({
                        title: 'Thất bại',
                        message: 'Đã có lỗi xẩy ra ',
                        type: 'error'
                    });
                })

            }).catch(() => {
            });
        },
        async EditClass(item) {
            this.itemClass = await {...item}
            this.Modal_Edit_Class = true;
        },
        clickCallback(obj) {
            this.page = obj;
            this.getListClass()
        },

        importSuccess(event) {
            this.dataPreview = event;
            this.dialogImport = false;
            this.dialogPreview = true;
            this.getListClass()
        },
        closeModalReview(event) {
            this.dialogPreview = event;
            this.dialogImport = true;
            this.loadingPreview = true;
        },
        closeModalImport(event) {
            this.dialogImport = false;
        },
        doneImport(event) {
            this.dialogImport = event;
            this.dialogPreview = event;
            this.getListClass();
        },
        async loadAgainData() {
            await this.getListGift()
            this.getListClass();
        },
        currentTestersHide(listTesters) {
            let strTestersHide = ''
            if (listTesters) {
                for (let i = 1; i < listTesters.length; i++) {
                    strTestersHide += `\n${listTesters[i]} `
                }
                return strTestersHide
            }
            return '';
        }
    }
}
</script>

<style scoped>
.el-message-box__message {
  text-align: center !important;
}
</style>