<template>
  <el-table
    ref="multipleTable"
    :data="tableData"
    v-loading="loading"
    style="width: 100%"
    @selection-change="handleSelectionChange">
    <el-table-column
      type="selection"
      width="50">
    </el-table-column>
    <el-table-column
      property="name"
      label="Tên lớp"
      width="120">
    </el-table-column>
    <el-table-column
      property="branch_name"
      label="Chi nhánh">
    </el-table-column>
    <el-table-column
      prop="status_name"
      label="Tình trạng lớp">
    </el-table-column>
    <el-table-column
      prop="teacher_name"
      label="Giảng viên">
    </el-table-column>
    <el-table-column
      prop="start_date"
      label="Ngày khai giảng">
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "classroomTable",
  props: {
    tableData: [],
    toggle: 0,
    loading: false
  },
  watch: {
    toggle(newValue, oldValue) {
      this.toggleSelection();
    }
  },
  data() {
    return {
      listClass: []
    }
  },
  methods: {
    toggleSelection() {
      this.$refs.multipleTable.clearSelection();
    },
    handleSelectionChange(data) {
      this.listClass = data.map((val) => {
        return val;
      });
      this.$emit('changeListClass', this.listClass,data.length);
    }
  }
}
</script>

<style scoped>

</style>