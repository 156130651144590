<template>
  <div>
    <TableError v-if="tableError" :list-errors="listErrors" type-error="bookCosts"/>
    <div v-else-if="fileImport" style="display: flex; align-items: center; gap: 10px">
      <span>{{fileImport.name}}</span>
      <button class="btn btn-icon btn-xs btn-outline-danger ml-2" @click="fileImport = null">
        <i class="el-icon-delete"></i>
      </button>
    </div>
    <div v-else style="display: flex; flex-direction: column; gap: 20px">
      <div style="display: flex; flex-direction: column">
        <label>Bước 1: Tải xuống file mẫu</label>
        <button class="btn btn-primary" style="width: 130px" @click="downloadFileExcelExample">
          <i class="el-icon-bottom" style="color: white"></i>
          Tải xuống
        </button>
      </div>
      <div style="display: flex; flex-direction: column">
        <label>Bước 2: Tải file excel lên hệ thống</label>
        <label class="btn btn-warning" for="upload-file" style="width: 130px">
          <input type="file" style="display: none" id="upload-file" @change="handleFileUpload" accept=".xlsx, .xls" />
          <i class="el-icon-top" style="color: white"></i>
          Tải lên
        </label>
      </div>
    </div>
    <div style="display: flex; justify-content: end; align-items: center; gap: 20px">
      <button class="btn" style="background: #CACACA" @click="closeDialog">Hủy bỏ</button>
      <button v-if="!tableError && !disableImport" class="btn btn-primary text-white" style="background: #5D04B0" @click="handleImportFileExcel">Xác nhận</button>
      <button v-if="disableImport && !tableError" class="btn btn-primary text-white" style="background: #9c999e">Xác nhận</button>
      <button
          class="btn btn-primary text-white"
          v-if="tableError"
          style="background: #5D04B0"
          @click="backToImport"
      >Quay lại</button>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import TableError from "@/view/pages/payments/components/TableError.vue";
import {BookCostModel} from "@/view/pages/payments/model/BookCostModel";
import {IMPORT_EXCEL_BOOK_COST, PREVIEW_BOOK_COST} from "@/core/services/store/payments/payments.module";

export default {
  components: {TableError},
  data() {
    return {
      fileImport: null,
      dataImport: [],
      tableError: false,
      listErrors: [],
      disableImport: false,
    }
  },
  methods: {
    downloadFileExcelExample() {
      const filePath = process.env.BASE_URL + 'excel/Import chi phí sách.xlsx';
      const a = document.createElement('a');
      a.href = filePath;
      a.download = 'File mẫu chi phí sách.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileImport = file
        this.readExcelFile(file);
      }
    },
    closeDialog() {
      this.$emit('closeDialog');
      this.$emit('setTitleError', 'Import chi phí sách')
      this.fileImport = null
      this.tableError = false
      this.disableImport = false
    },
    readExcelFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const columnMapping = { "ID khóa học": 'course_id', "Chi phí sách": 'price', "Thời gian": 'date'}
        const headers = jsonData[0];
        const rows = jsonData.slice(1);
        const translatedHeaders = headers.map(header => columnMapping[header] || header);
        this.dataImport = rows.filter(item => item.length > 0).map(row => {
          const obj = {};
          translatedHeaders.forEach((header, index) => {
            obj[header] = row[index];
          });
          return obj;
        })
      };
      reader.readAsArrayBuffer(file);
    },
    backToImport() {
      this.fileImport = null
      this.tableError = false
      this.disableImport = false
      this.$emit('setTitleError', 'Import chi phí sách')
    },
    async handleImportFileExcel() {
      this.disableImport = true
      if (!this.fileImport) {
        this.$notify({
          title: 'Thất bại',
          message: 'Chưa có file nào được chọn',
          type: 'error'
        });
        return
      }
      if (this.dataImport.length > 0) {
        await this.$store.dispatch(PREVIEW_BOOK_COST, this.dataImport).then(res => {
          if (res && res.isError === true) {
            this.$emit('setTitleError', 'Lỗi')
            this.tableError = true
            this.listErrors = res.data.map(item => {
              return new BookCostModel(item?.id, item?.course_id, '', item?.price, item?.date, item .errors)
            }).filter(item => item?.errors?.length > 0)
          } else {
            this.$store.dispatch(IMPORT_EXCEL_BOOK_COST, this.dataImport).then(() => {
              this.disableImport = false
              this.$emit('getListBook')
              this.$message.success('Import thành công')
              this.closeDialog()
            }).catch(e => {
              console.log(e)
              this.$message.error('Import thất bại')
            })
          }
        })
      }
    },
  }
}
</script>