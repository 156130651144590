import Swal from "sweetalert2";
<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Danh sách chi nhánh</h3>
            </div>
            <div class="card-title">
                <router-link
                        class="font-weight-bold font-size-3  btn btn-success"
                        :to="{ name: 'branch-create' }"
                >Thêm mới
                </router-link>
            </div>
        </div>
        <div class="card-body">
            <!--begin::Example-->
            <div class="example mb-10">
                <div class="row">
                    <div class="form-group col-md-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
																<span class="input-group-text line-height-0 py-0">
																	<span class="svg-icon">
																		<!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Search.svg-->
																		<svg xmlns="http://www.w3.org/2000/svg"
                                                                             xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                             width="24px" height="24px"
                                                                             viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" stroke-width="1"
                                                                               fill="none" fill-rule="evenodd">
																				<rect x="0" y="0" width="24"
                                                                                      height="24"></rect>
																				<path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                                                      fill="#000000" fill-rule="nonzero"
                                                                                      opacity="0.3"></path>
																				<path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                                                      fill="#000000"
                                                                                      fill-rule="nonzero"></path>
																			</g>
																		</svg>
                                                                        <!--end::Svg Icon-->
																	</span>
																</span>
                            </div>
                            <input type="text" class="form-control" placeholder="Tìm kiếm" v-model="query.keyword"
                                   @input="search"
                                   aria-describedby="basic-addon2">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <multiselect v-model="cacheCenter" :options="centers"
                                         placeholder="Trung tâm"
                                         label="name"
                                         track-by="name"
                                         :hide-selected="true"
                                         @input="handleQueryCenter($event)"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <multiselect v-model="cacheProvince" :options="provinces"
                                         placeholder="Tỉnh thành"
                                         label="name"
                                         track-by="name"
                                         :hide-selected="true"
                                         @input="handleQueryProvince($event)"
                            ></multiselect>
                        </div>
                        <!--end::Form-->
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <multiselect v-model="cacheDistrict"
                                         :options="districtsByProvince"
                                         placeholder="Quận, huyện"
                                         label="name"
                                         track-by="name"
                                         :hide-selected="true"
                                         @input="handleQueryDistrict($event)"
                            ></multiselect>
                        </div>
                        <!--end::Form-->
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <multiselect v-model="cacheStatus"
                                         :options="status"
                                         placeholder="Trạng thái"
                                         label="name"
                                         track-by="name"
                                         :hide-selected="true"
                                         @input="handleQueryStatus($event)"
                            ></multiselect>
                        </div>
                        <!--end::Form-->
                    </div>
                </div>
<!--              <div class="input-group row mb-2">-->
<!--                <ValidationObserver v-slot="{ handleSubmit }" class="col-md-3">-->
<!--                  <form class="form-inline" @submit.prevent="handleSubmit(onSubmit)">-->
<!--                    <ValidationProvider ref="validate_file" rules="required" name="File" v-slot="{ validate, errors }" class="mr-2 mt-1">-->
<!--                      <div class="upload">-->
<!--                        <div v-for="file in files" :key="file.id">-->
<!--                          <i class="fas fa-minus-circle mr-2" style="cursor: pointer;" @click="removeFile"></i>-->
<!--                          <span>{{file.name}}</span> - -->
<!--                          <span>{{file.size}}</span> - -->
<!--                          <span v-if="file.error">{{file.error}}</span>-->
<!--                          <span v-else-if="file.success">success</span>-->
<!--                          <span v-else-if="file.active">active</span>-->
<!--                          <span v-else-if="!!file.error">{{file.error}}</span>-->
<!--                          <span v-else></span>-->
<!--                        </div>-->
<!--                        <div class="example-btn d-flex justify-content">-->
<!--                          <file-upload-->
<!--                              class="btn btn-primary"-->
<!--                              post-action=""-->
<!--                              extensions="xlsx,xls"-->
<!--                              accept=".xlsx,.xls"-->
<!--                              :size="1024 * 1024 * 10"-->
<!--                              v-model="files"-->
<!--                              @input-filter="inputFilter"-->
<!--                              @input-file="inputFile"-->
<!--                              ref="upload">-->
<!--                            <i class="fa fa-plus"></i>-->
<!--                            Chọn files-->
<!--                          </file-upload>-->
<!--                          <button type="button" @click="onSubmit" class="btn btn-success ml-2" :disabled="isAble">-->
<!--                            <i class="fa fa-arrow-up" aria-hidden="true"></i>-->
<!--                            Upload Hệ số KD-->
<!--                          </button>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="fv-plugins-message-container">-->
<!--                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">-->
<!--                          {{ errors[0] }}-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </ValidationProvider>-->
<!--                  </form>-->
<!--                </ValidationObserver>-->
<!--              </div>-->
                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col" class="min-w-100px">Tên chi nhánh</th>
                            <th scope="col" class="min-w-100px">Trung tâm</th>
                            <th scope="col" class="min-w-100px">Số điện thoại</th>
                            <th scope="col" class="min-w-100px">Địa chỉ</th>
                            <th scope="col" class="min-w-100px">Tỉnh/thành phố</th>
                            <th scope="col" class="min-w-100px">Quận/huyện</th>
<!--                            <th scope="col" class="min-w-100px">Hệ số kinh doanh</th>-->
<!--                            <th scope="col" class="min-w-100px">Hệ số lên cấp</th>-->
                            <th scope="col" class="min-w-100px">Trạng thái</th>
                            <th scope="col" class="min-w-100px">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in branches.data" :key="index">
                            <td>{{item.name}}</td>
                            <td>
                                {{item.center != null ? item.center.name : ''}}
                            </td>
                            <td>{{item.phone}}</td>
                            <td>{{item.address}}</td>
                            <td>{{item.province != null ? item.province.name : ''}}</td>
                            <td>{{item.district != null ? item.district.name : ''}}</td>
<!--                            <td>{{item.he_so_kinh_doanh}}</td>-->
<!--                            <td>{{item.he_so_len_cap}}</td>-->
                            <td>{{item.status == 1 ? "Hoạt động" : (item.status == 0 ? "Đóng cửa" : "")}}</td>
                            <td>
                                <router-link :to="{ name: 'branch-edit', params: {id : item.id} }" title="Sửa"
                                             class="btn btn-sm btn-icon mr-2 btn-outline-info"><i
                                        class="fas fa-pen-nib"></i>
                                </router-link>
                                <a title="Xóa" @click="deleteBranch(item.id)" href="javascript:"
                                   class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="edu-paginate mx-auto text-center">
            <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                    :page-class="'page-item'"
            >
            </paginate>
        </div>
    </div>
</template>
<script>
import {GET_BRANCHES, DELETE_BRANCH, IMPORT_BRANCH_DATA} from "../../../core/services/store/center/branch.module";
    import Swal from "sweetalert2";
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {GET_PROVINCES} from "../../../core/services/store/public/provinces.module";
    import {GET_DISTRICTS} from "../../../core/services/store/public/districts.module";
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
    import Multiselect from 'vue-multiselect'
    import FileUpload from 'vue-upload-component';
    const _ = require('lodash');

    export default {
        components: {
            Multiselect,FileUpload
        },
        name: "BranchIndex",
        data() {
            return {
                upload: null,
                files: [],
                isAble : true,
                branches: [],
                centers: [],
                provinces: [],
                districts: [],
                // Paginate
                page: 1,
                last_page: 1,
                query: {
                    keyword: '',
                    province: '',
                    district: '',
                    center: '',
                    status:''
                },
                districtsByProvince: [],

                status:[
                    {
                        id:"",
                        name:"Trạng thái"
                    },
                    {
                        id:1,
                        name:"Hoạt động"
                    },
                    {
                        id:0,
                        name:"Đóng cửa"
                    },
                ],
                // cache
                cacheProvince: [],
                cacheCenter: [],
                cacheDistrict: [],
                cacheStatus:[]
            }
        },
        async mounted() {
            await this.getCenters()
            await this.getProvinces()
            await this.getDistricts()
            await this.getList()
            await this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý trung tâm", route: 'profile-1'},
                {title: "Danh sách chi nhánh"}
            ]);
        },
        methods: {
            inputFilter(newFile, oldFile, prevent) {
              if (newFile && !oldFile) {
                // Before adding a file
                // 添加文件前
                // Filter system files or hide files
                // 过滤系统文件 和隐藏文件
                if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                  return prevent()
                }

                // Filter php html js file
                // 过滤 php html js 文件
                if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                  return prevent()
                }
              }
            },
            inputFile(newFile, oldFile) {
              if (newFile && !oldFile) {
                this.dataImport = newFile.file;
                this.isAble = false;
              }
              if (newFile && oldFile) {
                // update
              }
              if (!newFile && oldFile) {
                // remove
              }
            },
            removeFile : function (){
              this.files.pop();
              this.isAble = true;
            },
            async onFileChange (event) {
              const { valid } = this.$refs.validate_file.validate(event);
              this.dataImport = event.target.files[0];
            },
            onSubmit () {
              let formData = new FormData();
              formData.append('dataImport', this.dataImport);
              this.$store.dispatch(IMPORT_BRANCH_DATA,formData).then( (res) => {
                this.removeFile();
                if (!res.error) {
                  this.$store.dispatch(GET_BRANCHES).then((data) => {
                    this.branches = data.data;
                    this.$bvToast.toast(res.message, {
                      title: 'Import',
                      variant: 'success',
                      solid: true
                    });
                  })
                }else {
                  this.$bvToast.toast(res.message, {
                    title: 'Import',
                    variant: 'danger',
                    solid: true
                  });
                }
              }).catch(() => {
                this.$bvToast.toast('Có lỗi xảy ra', {
                  title: 'Import',
                  variant: 'danger',
                  solid: true
                });
              });
            },
            async getList() {
                await this.pullParamsUrl()
                await this.setCacheTheLoad()
                await this.callApiGetList()
                await this.selectDistrictByProvince()
            },
            setCacheTheLoad() {
                if (this.query.center != '') {
                    let oldParamCenter = this.centers.filter((value) => {
                        if (parseInt(this.query.center) == value.id) {
                            return value
                        }
                    })
                    this.cacheCenter = oldParamCenter
                }
                if (this.query.province != '') {
                    let oldParamDistrict = this.provinces.filter((value) => {
                        if (parseInt(this.query.province) == value.id) {
                            return value
                        }
                    })
                    this.cacheProvince = oldParamDistrict
                }
                if (this.query.district != '') {
                    let oldParamDistrict = this.districts.filter((value) => {
                        if (parseInt(this.query.district) == value.id) {
                            return value
                        }
                    })
                    this.cacheDistrict = oldParamDistrict
                }

                if (this.query.status != '') {
                    let oldParamStatus = this.status.filter((value) => {
                        if (parseInt(this.query.status) == value.id) {
                            return value
                        }
                    })
                    this.cacheStatus = oldParamStatus
                }
            },
            selectDistrictByProvince() {
                let provinceId = this.query.province
                this.districtsByProvince = []
                if (provinceId != undefined) {
                    this.districts.map((value) => {
                        if (value.province_id == provinceId) {
                            this.districtsByProvince.push(value)
                        }
                    })
                    this.districtsByProvince.unshift({
                        name: 'Quận, huyện'
                    })
                }

            },
            pullParamsUrl() {
                this.page = this.$route.query.page
                this.query.keyword = this.$route.query.keyword
                this.query.center = this.$route.query.center
                this.query.province = this.$route.query.province
                this.query.district = this.$route.query.district
                this.query.status = this.$route.query.status
            },
            search: _.debounce(function () {
                this.query.center = this.$route.query.center
                this.query.province = this.$route.query.province
                this.query.district = this.$route.query.district
                this.query.status = this.$route.query.status
                this.pushParamsUrl()
                this.page = 1
                this.callApiGetList()
            }, 200),
            pushParamsUrl() {
                this.$router.push({
                    name: 'branch-index',
                    query: {
                        keyword: this.query.keyword,
                        center: this.query.center,
                        province: this.query.province,
                        district: this.query.district,
                        status:this.query.status
                    }
                })
            },
            clickCallback(obj) {
                this.page = obj
                this.$router.push({path: '/branch', query: {page: this.page}})
                this.callApiGetList()
            },
            callApiGetList() {
                this.$store.dispatch(GET_BRANCHES, {
                    page: this.page,
                    keyword: this.query.keyword,
                    center: this.query.center,
                    province: this.query.province,
                    district: this.query.district,
                    status: this.query.status,
                }).then((res) => {
                    this.branches = res.data
                    this.last_page = res.data.last_page
                });
            },
            deleteBranch(id) {
                Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire("Xóa", "", "success");
                        this.$store.dispatch(DELETE_BRANCH, id).then((res) => {
                            if (res.error) {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'danger',
                                    solid: true
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'success',
                                    solid: true
                                });
                                this.getList()
                            }
                        });
                    }
                });
            },
            async getProvinces() {
                await this.$store.dispatch(GET_PROVINCES, []).then((res) => {
                    if (!res.error) {
                        this.provinces = res.data
                        this.provinces.unshift({
                            name: 'Tỉnh thành'
                        })
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            async getDistricts() {
                await this.$store.dispatch(GET_DISTRICTS, []).then((res) => {
                    if (!res.error) {
                        this.districts = res.data
                        this.districts.unshift({
                            name: 'Quận, huyện'
                        })
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            async getCenters() {
                await this.$store.dispatch(GET_CENTERS, {
                    limit: 10000
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data
                        this.centers.unshift({
                            name: 'Trung tâm'
                        })
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            handleQueryCenter($event) {
                this.query.center = $event.id
                if (this.$route.query.keyword != undefined) {
                    this.query.keyword = this.$route.query.keyword
                }
                if (this.$route.query.page != undefined) {
                    this.page = this.$route.query.page
                }
                if (this.$route.query.province != undefined) {
                    this.query.province = this.$route.query.province
                }
                if (this.$route.query.district != undefined) {
                    this.query.district = this.$route.query.district
                }

                if (this.$route.query.status != undefined) {
                    this.query.status = this.$route.query.status
                }

                this.pushParamsUrl()
                this.callApiGetList()
            },
            async handleQueryProvince($event) {
                this.cacheDistrict = []
                this.query.province = $event.id
                this.query.district = ''
                if (this.$route.query.keyword != undefined) {
                    this.query.keyword = this.$route.query.keyword
                }
                if (this.$route.query.page != undefined) {
                    this.page = this.$route.query.page
                }
                if (this.$route.query.center != undefined) {
                    this.query.center = this.$route.query.center
                }
                if (this.$route.query.status != undefined) {
                    this.query.status = this.$route.query.status
                }
                await this.pushParamsUrl()
                await this.callApiGetList()
                await this.selectDistrictByProvince()
            },
            handleQueryDistrict($event) {
                this.query.district = $event.id
                if (this.$route.query.keyword != undefined) {
                    this.query.keyword = this.$route.query.keyword
                }
                if (this.$route.query.page != undefined) {
                    this.page = this.$route.query.page
                }
                if (this.$route.query.center != undefined) {
                    this.query.center = this.$route.query.center
                }
                if (this.$route.query.province != undefined) {
                    this.query.province = this.$route.query.province
                }
                if (this.$route.query.status != undefined) {
                    this.query.status = this.$route.query.status
                }
                this.pushParamsUrl()
                this.callApiGetList()
            },
            handleQueryStatus($event) {
                this.query.status = $event.id
                if (this.$route.query.keyword != undefined) {
                    this.query.keyword = this.$route.query.keyword
                }
                if (this.$route.query.page != undefined) {
                    this.page = this.$route.query.page
                }
                if (this.$route.query.province != undefined) {
                    this.query.province = this.$route.query.province
                }
                if (this.$route.query.district != undefined) {
                    this.query.district = this.$route.query.district
                }

                this.pushParamsUrl()
                this.callApiGetList()
            }
        }
    }
</script>
<style>
.fa-minus-circle:hover {
  color: red !important;

}
.input-group i {
  font-size : 0.9rem;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
