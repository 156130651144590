const selectScore = [
    {
        value: 0,
        label: '0'
    },
    {
        value: 0.5,
        label: '0.5'
    },
    {
        value: 1,
        label: '1'
    },
    {
        value: 1.5,
        label: '1.5'
    },
    {
        value: 2,
        label: '2'
    },
    {
        value: 2.5,
        label: '2.5'
    },
    {
        value: 3,
        label: '3'
    },
    {
        value: 3.5,
        label: '3.5'
    },
    {
        value: 4,
        label: '4'
    },
    {
        value: 4.5,
        label: '4.5'
    },
    {
        value: 5,
        label: '5'
    },
    {
        value: 5.5,
        label: '5.5'
    },
    {
        value: 6,
        label: '6'
    },
    {
        value: 6.5,
        label: '6.5'
    },
    {
        value: 7,
        label: '7'
    },
    {
        value: 7.5,
        label: '7.5'
    },
    {
        value: 8,
        label: '8'
    },
    {
        value: 8.5,
        label: '8.5'
    },
    {
        value: 9,
        label: '9'
    },
    {
        value: 9.5,
        label: '9.5'
    },
    {
        value: 10,
        label: '10'
    },
]

const selectScore9 = [
    {
        value: 0,
        label: '0'
    },
    {
        value: 0.5,
        label: '0.5'
    },
    {
        value: 1,
        label: '1'
    },
    {
        value: 1.5,
        label: '1.5'
    },
    {
        value: 2,
        label: '2'
    },
    {
        value: 2.5,
        label: '2.5'
    },
    {
        value: 3,
        label: '3'
    },
    {
        value: 3.5,
        label: '3.5'
    },
    {
        value: 4,
        label: '4'
    },
    {
        value: 4.5,
        label: '4.5'
    },
    {
        value: 5,
        label: '5'
    },
    {
        value: 5.5,
        label: '5.5'
    },
    {
        value: 6,
        label: '6'
    },
    {
        value: 6.5,
        label: '6.5'
    },
    {
        value: 7,
        label: '7'
    },
    {
        value: 7.5,
        label: '7.5'
    },
    {
        value: 8,
        label: '8'
    },
    {
        value: 8.5,
        label: '8.5'
    },
    {
        value: 9,
        label: '9'
    },

]

const optionScoreSpeaking = [
    {
        value: 1,
        label: '1 (Dưới trung bình)'
    },
    {
        value: 2,
        label: '2 (Khá tốt)'
    },
    {
        value: 3,
        label: '3 (Tốt)'
    },
    {
        value: 4,
        label: '4 (Rất tốt)'
    },
    {
        value: 5,
        label: '5 (Xuất sắc)'
    },
]

const optionScoreSpeaking9 = [
    {
        value: 1,
        label: '1'
    },
    {
        value: 2,
        label: '2'
    },
    {
        value: 3,
        label: '3'
    },
    {
        value: 4,
        label: '4'
    },
    {
        value: 5,
        label: '5'
    },
    {
        value: 6,
        label: '6'
    },
    {
        value: 7,
        label: '7'
    },
    {
        value: 8,
        label: '8'
    },
    {
        value: 9,
        label: '9'
    },
]
const RECORD_AUDIO = 'record_audio';
const WRITE_PARAGRAPH = 'write_paragraph';
const RECORD_VIDEO = 'record_video';
const NameSkill = new Map(
    [
        ['fluency', 'Fluency & Coherence'],
        ['vocabularyRange', 'Lexical Resource'],
        ['grammaticalRange', 'Grammatical range & Accuracy'],
        ['pronunciation', 'Pronunciation'],
    ])
const NameSkillOld = new Map(
    [
        ['fluency', 'Fluency'],
        ['coherence', 'Coherence'],
        ['grammaticalRange', 'Grammatical Range'],
        ['grammaticalAccuracy', 'Grammatical Accuracy'],
        ['pronunciation', 'Pronunciation'],
        ['vocabularyRange', 'Vocabulary Range'],
        ['vocabularyAccuracy', 'Vocabulary Accuracy']
    ])

export {
    selectScore,
    optionScoreSpeaking,
    RECORD_AUDIO,
    WRITE_PARAGRAPH,
    RECORD_VIDEO,
    selectScore9,
    optionScoreSpeaking9,
    NameSkill,
    NameSkillOld
}