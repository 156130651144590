import { render, staticRenderFns } from "./DeletePromotion.vue?vue&type=template&id=6f960ba2&scoped=true"
import script from "./DeletePromotion.vue?vue&type=script&lang=js"
export * from "./DeletePromotion.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f960ba2",
  null
  
)

export default component.exports