import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const CREATE_COURSE = "create_course";
export const UPDATE_COURSE = "update_course";
export const GET_COURSES = "get_courses";
export const GET_COURSES_LIST = "course/get-list";
export const DELETE_COURSE = "delete_course";
export const GET_COURSE_BY_ID = "get_course_by_id";
export const GET_LIST_COURSE_STUDENT = 'list-course-student';
export const GET_LIST_COURSE = 'course/get-list';
export const EXPORT_EXCEL_DS_COURSE_STUDENT = 'export-excel-course-student';
export const EXPORT_EXCEL_COURSE_STUDENT_V2 = 'export-excel-course-student-v2';

const state = {};
const getters = {};
const actions = {
    [GET_LIST_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${GET_LIST_COURSE}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    // Get provinces
    [GET_COURSES](context, payload) {
        return new Promise(resolve => {
            ApiService.query('courses', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_COURSES_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_COURSES_LIST, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_COURSE_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('courses', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_COURSE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('courses', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_COURSE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('courses/'+ payload.id +'?_method=PUT', payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_COURSE](context, id) {
        return new Promise(resolve => {
            ApiService.delete('courses', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_LIST_COURSE_STUDENT](context, payload) {
        return new Promise(resolve => {
            ApiService.query('list-course-student', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [EXPORT_EXCEL_DS_COURSE_STUDENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('export-course-student', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [EXPORT_EXCEL_COURSE_STUDENT_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('export-course-student-v2', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
