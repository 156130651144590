export function SuggestTesterModel(name, phone, totalTime, timePick, allBranches) {

    this.name = name ?? "";
    this.phone = phone ?? "";
    this.totalTime = totalTime ?? 0;
    this.timePick = timePick ?? 0;
    this.mainBranch = allBranches.length >= 0 ? allBranches[0] : "";
    this.percent = this.workPercentage();

    this.otherBranches = [...allBranches];
    this.otherBranches.shift();
    this.otherBranches ??= [];
}

SuggestTesterModel.prototype.toString = function () {
    if (this.phone == "") {
        return this.name;
    }
    return `${this.name}-${this.phone}`;
}

SuggestTesterModel.prototype.workPercentage = function () {
    if (this.totalTime == 0) {
        return 0;
    }
    return (this.timePick / this.totalTime) * 100;
}
