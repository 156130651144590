<template>
  <div>
    <div class="table-responsive mt-3" style="overflow: hidden; padding-bottom: 30px">
      <table class="table" style="text-align: center;">
        <thead>
        <tr style="border: 1px solid #EBEDF3"  >
          <th
              scope="col" class="min-w-100px"
              style="background: #F8F8F8; text-align: start"
              v-for="item of headerTable"
          >{{ item[Object.keys(item)] }}</th>
        </tr>
        </thead>
        <tbody  >
          <tr v-for="(item, index) of listErrors" :key="index + item.name" style="border: 1px solid #EBEDF3; border-top: 0">
            <td v-for="col of headerTable" :key="'errors'+index + item.name"  style="text-align: start">{{ item[Object.keys(col)] }}</td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
</template>

<script>
import {rowTableBookError} from "@/view/pages/payments/model/BookCostModel";
import {rowTableBoxError} from "@/view/pages/payments/model/BoxCostModel";

export default {
  data() {
    return {
      rowTableBookError: rowTableBookError,
      rowTableBoxError: rowTableBoxError,
    }
  },
  methods: {},
  props: {
    listErrors: {
      type: Array,
      require: false,
      default: []
    },
    typeError: {
      type: String,
      default: ''
    }
  },
  computed:{
    headerTable() {
      return this.typeError === 'bookCosts' ? rowTableBookError : rowTableBoxError;
    },
    dataError() {
      return this.listErrors.map(item => {
        console.log(item, 'data')
      })
    }
  },
  mounted() {
  }
}
</script>

<style lang="css" scoped>

</style>