import moment from "moment-timezone";

export class FinanceTransactionModel {
    constructor(name, userId, branchId, branchName, size, areaId, areaName, regionId, regionName, businessLevel, date,
                percent, oldPercent, percentNoGDKVFirst, oldPercentNoGDKVFirst, prePercentNoGDKVFirst, oldPrePercentNoGDKVFirst, stockValue) {
        this.name = name;
        this.userId = userId;
        this.branchId = branchId;
        this.branchName = branchName;
        this.size = size;
        this.areaId = areaId;
        this.areaName = areaName;
        this.regionId = regionId;
        this.regionName = regionName;
        this.businessLevel = businessLevel;
        this.date = date;
        this.percent = percent - oldPercent;
        this.percentNoGDKVFirst = percentNoGDKVFirst - oldPercentNoGDKVFirst;
        this.prePercentNoGDKVFirst = prePercentNoGDKVFirst - oldPercentNoGDKVFirst;
        this.stockValue = stockValue;
    }
}

export class FinanceBranchReportModel {
    constructor(date, userId, name, position, region, area, branchId, branchName, percent) {
        this.date = date ? moment(new Date(date)).format('MM-YYYY') : '';
        this.userId = userId + 100000;
        this.name = name;
        this.position = position;
        this.region = region;
        this.area = area;
        this.branchId = branchId;
        this.branchName = branchName;
        this.percent = percent + '%';
    }
}