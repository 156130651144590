<template>
  <ul class="menu-nav">
    <router-link
        to="/dashboard"
        v-slot="{ href, navigate, isActive, isExactActive }"
        v-if="[role_auth.ADMIN].includes(currentUser.user.role)||true"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Trang chủ</span>
        </a>
      </li>
    </router-link>

    <quan-ly-nhan-su v-if="currentUser.user.permission.includes(permission.QUAN_LY_NHAN_SU)"/>
    <quan-ly-kinh-doanh v-if="currentUser.user.permission.includes(permission.QUAN_LY_KINH_DOANH)"/>
    <quan-ly-tai-chinh v-if="currentUser.user.permission.includes(permission.QUAN_LY_TAI_CHINH)"/>
    <quan-ly-chuoi-cung-ung v-if="currentUser.user.permission.includes(permission.QUAN_LY_SAN_XUAT)"/>
    <quan-ly-he-thong-thong-tin v-if="currentUser.user.permission.includes(permission.QUAN_LY_HE_THONG_THONG_TIN)"/>
    <quan-ly-du-lieu v-if="currentUser.user.permission.includes(permission.QUAN_LY_DU_LIEU)"/>
    <CRM v-if="currentUser.user.permission.includes(permission.CRM)"/>
    <CSVC v-if="currentUser.user.permission.includes(permission.CO_SO_VAT_CHAT)"/>
    <quan-ly-phan-quyen v-if="currentUser.user.permission.includes(permission.QUAN_LY_TAI_KHOAN)"/>
    <setting v-if="currentUser.user.permission.includes(permission.QUAN_LY_HE_THONG_CAI_DAT)"></setting>
    <quan-ly-trung-tam-ho-tro v-if="currentUser.user.permission.includes(permission.QUAN_LY_TRUNG_TAM_HO_TRO)"/>
  </ul>
</template>

<script>
import {mapGetters} from "vuex";
import QuanLyNhanSu from './QuanLyNhanSu';
import QuanLyDonViHanhChinh from "./QuanLyDonViHanhChinh";
import QuanLyKinhDoanh from './QuanLyKinhDoanh';
import QuanLyTaiChinh from './QuanLyTaiChinh';
import QuanLyChuoiCungUng from './QuanLyChuoiCungUng';
import QuanLyHeThongThongTin from './QuanLyHeThongThongTin';
import QuanLyDuLieu from './QuanLyDuLieu';
import CRM from './CRM';
import QuanLyPhanQuyen from './QuanLyPhanQuyen';
import Setting from './Setting';
import QuanLyTrungTamHoTro from './QuanLyTrungTamHoTro';
import CSVC from "@/view/layout/aside/CSVC";


export default {
  name: "KTMenu",
  data() {
    return {}
  },
  components: {
    CSVC,
    QuanLyNhanSu,
    QuanLyDonViHanhChinh,
    QuanLyKinhDoanh,
    QuanLyTaiChinh,
    QuanLyChuoiCungUng,
    QuanLyHeThongThongTin,
    QuanLyDuLieu,
    CRM,
    Setting,
    QuanLyPhanQuyen,
    QuanLyTrungTamHoTro
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
