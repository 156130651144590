<template>
  <div class=" container ">
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent>
        <div class="row  ">
          <div v-for="(item, index) in speakingCriteria">
            <p class="ml-5" style="cursor: pointer;font-size: 12px" :class="{'text-primary border_bottom_item': index === tabCurrent}"
               @click="handleSubmit(changeTab(index))">{{ item.name }}</p>
          </div>
        </div>
        <div class="content_test" :key="tabCurrent" >
          <div class="  row rounded  py-5 pr-8" style="background-color: #F5F5F5;">
            <div class="col-xl-6 col-md-12 mb-5 " v-for="(item, index) in listDetailSkill"
                 :key="tabCurrent.toString() + index">
              <ReviewSkill :criteria="item" @setAllPoint="setAllPoint()" :flagMarkIs0="flagMarkIs0"
                           :id="tabCurrent.toString() + index" :skillCurrent="skillCurrent"
                           :allCriteria="speakingCriteria"></ReviewSkill>
            </div>
            <div style="width: 100%">
              <InputSpecificComments :skillCurrent="skillCurrent"></InputSpecificComments>
            </div>
            <div class="d-flex justify-content-end col-12 mt-3">
              <button type="submit" class="btn btn-primary" @click="handleSubmit(confirm)" :disabled="isDisable">
                {{ checkFillAllSkill() ? 'Hoàn Thành' : 'Tiếp theo' }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <modal-confirmed-low-scoring :allCriteria="speakingCriteria" @submit="makePoint"></modal-confirmed-low-scoring>
  </div>
</template>
<script >
import ReviewSkill from "@/view/pages/test-input/components/ComponentMarkTheExam/ReviewSkill";
import {SPEAKING_CRITERIA} from "@/view/pages/test-input/models/criteria_model";
import {MAKE_EXAM_SPEAKING} from "@/core/services/store/test/testInput.module";
import router from "@/router";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import InputSpecificComments from "@/view/pages/test-input/components/ComponentMarkTheExam/InputSpecificComments";
import {CriteriaModel} from "@/view/pages/test-input/models/criteria_model"
import ModalConfirmedLowScoring
  from "@/view/pages/test-input/components/ComponentMarkTheExam/ModalConfirmedLowScoring.vue";

export default {
  name: "TestSpeaking",
  components: {ModalConfirmedLowScoring, InputSpecificComments, ReviewSkill},
  data() {
    return {
      speakingCriteria: [
        new CriteriaModel(1, "ĐỘ TRÔI CHẢY VÀ MẠCH LẠC", "fluency_and_coherence"),
        new CriteriaModel(2, "VỐN TỪ", "lexical_resource"),
        new CriteriaModel(3, "ĐỘ ĐA DẠNG VÀ CHÍNH XÁC CỦA NGỮ PHÁP", "grammatical_range_accuracy"),
        new CriteriaModel(4, "PHÁT ÂM", "pronunciation"),
      ],
      flagMarkIs0: false,
      tabCurrent: 0,
      listTabDone: [0],
      isDisable: false
    }
  },
  mounted() {
    let dataLocal = JSON.parse(localStorage.getItem('data_mark' + this.$route.params.id));
    if(dataLocal){
      this.speakingCriteria = dataLocal.map((data) => {
        return new CriteriaModel(data.id,data.name,data.eName,data.score,data.commentAuto,data.specificComments,data.listdetailSkill)
      });
    }
  },
  watch: {
    speakingCriteria: {
      handler(newVal, oldVal) {
        this.setLocalDataMark()
      },
      deep: true
    }
  },
  props: {
    currentTest: {
      type: Number
    },
    doneTest: {
      type: Boolean
    }
  },
  methods: {
    async confirm() {
      if (this.checkLowScore()) {
        this.$bvModal.show("Modal-Confirmed-Low-Scoring")
        return
      }
      if (!this.checkNextSkill()) {
        this.$message.error('Bạn chưa hoàn thành skill này');
        return
      }
      if (this.checkFillAllSkill()) {
        await this.makePoint();
        return
      }
      this.nextSkill()


    },
    async makePoint() {
      this.isDisable = true
      for (const criteria of this.speakingCriteria) {
        await criteria.calculationPointAndCommentAuto();
      }
      let data = {
        type: "speaking",
        criterias: this.criteria
      }
      let test_input_schedule_id = this.$route.params.id
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      await this.$store.dispatch(MAKE_EXAM_SPEAKING, {id: test_input_schedule_id, data: data})
          .then((data) => {
            this.$message.success('Chấm Speaking thành công');
            this.resetLocalDataMark()
            if (!this.doneTest) {
              router.push({name: 'task-details', params: {id: test_input_schedule_id}})
            } else {
              this.$emit("change")
            }
          }).catch((error) => {
            this.$message.error(error.data.message)
          }).finally(() => {
            this.isDisable = false;
            loading.close();
          })
    },
    nextSkill() {
      if (this.tabCurrent == this.speakingCriteria.length - 1) {
        this.tabCurrent = 0
        return
      }
      this.tabCurrent++;
      this.listTabDone = [...this.listTabDone, this.tabCurrent];
    },
    setAllPoint() {
      this.speakingCriteria.forEach((criteria) => {
        criteria.setMark(0)
      })
      this.flagMarkIs0 = !this.flagMarkIs0
    },
    changeTab(index) {
      if (!this.checkNextSkill(index)) {
        this.$message.error('Bạn chưa hoàn thành skill này');
        return
      }
      if (this.checkLowScore() && !this.listTabDone.includes(index)) {
        this.$bvModal.show("Modal-Confirmed-Low-Scoring")
        return
      }
      this.tabCurrent = index;
      this.listTabDone = [...this.listTabDone, this.tabCurrent]
    },
    checkNextSkill(index = -1) {
      // && (this.skillCurrent.specificComments != '')
      console.log((this.listDetailSkill.find((detailSkill) => detailSkill.scoreDetail === null) == null))
      return ((this.listDetailSkill.find((detailSkill) => detailSkill.scoreDetail === null) == null)) || this.listTabDone.includes(index)
    },
    checkFillAllSkill() {
      return this.speakingCriteria.find((skill) => {
            return ((skill.listdetailSkill.find((detailSkill) => detailSkill.scoreDetail == null)) != null) || skill.specificComments == ''
          }
      ) == null;
    },
    checkLowScore() {
      return !!this.skillCurrent.listdetailSkill.find((detailSkill) => detailSkill.scoreDetail < 2)
    },
    setLocalDataMark() {
      localStorage.setItem('data_mark' + this.$route.params.id, JSON.stringify(this.speakingCriteria))
    },
    resetLocalDataMark(){
      localStorage.removeItem('data_mark' + this.$route.params.id)
    }
  },
  computed: {
    criteria() {
      return this.speakingCriteria.map((criteria) => {
        return {
          key: criteria.eName,
          point: criteria.score,
          comment: criteria.specificComments,
          comment_auto: criteria.commentAuto,
        }
      })
    },
    listDetailSkill() {
      return this.speakingCriteria[this.tabCurrent].listdetailSkill
    },
    skillCurrent() {
      return this.speakingCriteria[this.tabCurrent]
    },
  },

}
</script>

<style scoped>
.border_bottom {
  border-bottom: 6px solid #D6D5D5;
  border-width: 1px;
}

.border_bottom_item {
  border-bottom: 6px solid #0066FF;
  border-width: 1px;
}

p {
  font-weight: bold;
  font-size: 15px;
}

.tabs {
  position: fixed;
  z-index: 100;
}

.content_test {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden
}

</style>