<template>
  <select class="form-control" style="width: 100%" v-model="selected">
  </select>
</template>

<script>
  import JwtService from "@/core/services/jwt.service";
  export default {
    name: 'Select2Ajax',
    props: ['value','url','method','selected','placeholder','minimumInputLength'],
    mounted: function() {
      var vm = this;
      $(this.$el)
              // init select2
              .select2({
                ajax: {
                  // beforeSend: function (xhr) {
                  //   xhr.setRequestHeader ("Authorization", `Bearer ${JwtService.getToken()} `);
                  // },
                  url: this.url,
                  dataType: 'json',
                  method: this.method,
                  cache: false,
                  xhrFields: {
                    withCredentials: true
                  },
                  data: function(params) {
                    var query = {
                      keyword: params.term
                    };
                    // Query parameters will be ?search=[search]&_type=query&q=q
                    return query;
                  },
                  // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
                  processResults: function(data) {
                    return {
                      results: $.map(data.data.data, function (item) {
                        return {
                          text: item.name,
                          id: item.id,
                        }
                      })
                    };
                  }
                },
                placeholder: this.placeholder,
                minimumInputLength: this.minimumInputLength,
                allowClear: true,
                // templateResult: function(repo) {
                //   if (repo.id) {
                //     return repo.id + ' : ' + repo.text;
                //   }
                //   return repo.text;
                // },
                // templateSelection: function(repo) {
                //   if (repo.id) {
                //     return repo.id + ' : ' + repo.text;
                //   }
                //   return repo.text;
                // }
              })
              .val(this.value)
              .trigger('change')
              // emit event on change.
              .on('change', function() {
                vm.$emit('input', this.value);
              });
    },
    watch: {
      value: function(value) {
        // update value
        $(this.$el).val(value).trigger('change');
      },
      options: function(options) {
        // update options
        $(this.$el)
                .empty()
                .select2({ data: options });
      }
    },
    destroyed: function() {
      $(this.$el)
              .off()
              .select2('destroy');
    }
  };
</script>

<style>
</style>
