<template>
  <div>
    <div>
      <div>
          <span class="text-break">Bạn có chắc chắn muốn xóa chương trình “ {{ item.name }} ” không?</span>
      </div>
      <div slot="footer" class="dialog-footer d-flex justify-content-end mt-8 pb-4">
          <el-button size="mini" style="background: rgba(202, 202, 202, 1);" class="text-black" @click="closeDialog">Hủy bỏ</el-button>
          <el-button size="mini" style="background: rgba(93, 4, 176, 1);" class="text-white" @click="handleDelete(item.id)">
              <i v-if="loading" class="el-icon-loading text-white float-righ"></i>
              Xác nhận</el-button>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import { DELETE_PROMOTION_BY_ID } from '../../../../core/services/store/rewards-programs/rewardsPrograms.module';
  export default {
    components: {

    },
    props: {
        item: {
        type: Object,
        default: {},
      },
    },
    data() {
      return {
       loading: false
      }
    },
   
    methods: {
      closeDialog() {
        this.loading = false;
        this.$emit('closeDialogDelete', 'delete')
      },

      handleDelete(id) {
        this.loading = true;
        this.$store.dispatch(DELETE_PROMOTION_BY_ID, id).then((res) => {
            if (!res.data.error) {
              this.$notify({
                  title: 'Thành công!',
                  message: 'Xoá khuyến mại thành công',
                  type: 'success'
              });
              this.closeDialog();
            }
          }).catch((e) => {
            this.$notify.error({
              title: 'Error',
              message: 'Xoá khuyến mại thất bại'
            });
            this.closeDialog();
        })
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>