<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách lead website</h3>
      </div>
      <div class="card-title">
        <div>
          <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2 fa-pull-right"
                  @click="exportExcel">
            <i class="la la-file-excel"></i> Xuất excel
          </button>
          <button v-else disabled
                  class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left fa-pull-right">
            Xuất excel
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Họ Tên, SĐT, Email
                <span class="text-danger"></span></label>
              <el-input type="text" v-model="query.keyword"
                        placeholder="Họ Tên, SĐT, Email"></el-input>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Thời gian gửi
                <span class="text-danger"></span></label>
              <date-picker
                  placeholder="Chọn thời gian"
                  format="DD-MM-YYYY"
                  range
                  valueType="YYYY-MM-DD" v-model="query.date_range">
              </date-picker>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">Nguồn website</label>
              <el-select v-model="query.website"
                         clearable filterable
                         placeholder="Chọn nguồn website"
                         class="w-100">
                <el-option v-for="item in fromWebsites"
                           :key="item.id"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3 mt-8">
            <div class="d-flex justify-content-between">
              <button :disabled="isBusy" class="btn btn-primary font-weight-bold mr-2"
                      :class="isBusy ? 'spinner spinner-white spinner-left' : ''"
                      @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive">
          <table :aria-busy="isBusy" class="table table-vertical-center table-bordered table-hover b-table">
            <thead class="thead-light">
            <tr>
              <th style="text-align: center" scope="col" class="min-w-20px">STT</th>
              <th style="text-align: center" scope="col" class="min-w-120px">Họ tên</th>
              <th style="text-align: center" scope="col" class="min-w-120px">Email</th>
              <th style="text-align: center" scope="col" class="min-w-110px">Số điện thoại</th>
              <th style="text-align: center" scope="col" class="min-w-250px">Nội dung</th>
              <th style="text-align: center" scope="col" class="min-w-150px">Thời gian gửi</th>
              <th style="text-align: center" scope="col" class="min-w-100px">Nguồn website</th>
            </tr>
            </thead>
            <tbody v-if="isBusy">
            <tr role="row" class="b-table-busy-slot">
              <td colspan="16" role="cell" class="text-center">
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody v-else-if="getLeadWebsites.length > 0">
            <tr v-for="(item, index) in getLeadWebsites" :key="index">
              <td>{{ (getLeadWebsitePaginate.current_page - 1) * getLeadWebsitePaginate.per_page + index + 1 }}
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.question }}</td>
              <td>{{ item.created_at | formatDateTimeVietnam }}</td>
              <td>{{defineSource(item.website)}}</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="7" class="text-center font-italic">Chưa có dữ liệu</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center" v-if="getLeadWebsites.length > 0">
      <paginate
          v-model="page"
          :page-count="total_pages"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {EXPORT_LEAD_WEBSITE, LIST_LEAD_WEBSITE} from "../../../core/services/store/website/leadWebsite.module";

import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';
import {exportExcel} from "../../../core/filters";

const _ = require('lodash');

export default {
  components: {
    DatePicker,
  },
  name: "PaymentLog",
  data() {
    return {
      fromWebsites: [{label: 'Ecoach', value: 'ecoach'}, {label: 'Ielts Mentor', value: 'ielts'}],
      sources: [],
      // Paginate
      page: 1,
      is_disable_search: false,
      isBusy: false,
      last_page: 1,
      is_export_excel: false,
      query: {
        date_range: [],
        keyword: '',
        website: ''
      },
      date_filter_default: [],

    }
  },
  created() {
    this.getDateFilterDefault();
    this.getList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý lead website", route: '#'},
      {title: "Danh sách lead website"}
    ]);
    this.page = this.getLeadWebsitePaginate.current_page
  },
  computed: {
    ...mapGetters(["getLeadWebsites", "getLeadWebsitePaginate"]),
    total_pages() {
      return this.getLeadWebsitePaginate?.total_pages || 1
    },
  },
  watch: {},
  methods: {
    defineSource(value) {
      if(value === 'ielts'){
        return 'Ielts Mentor'
      } else  (value === 'ecoach')
        return 'Ecoach'
    },
    getDateFilterDefault() {
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
      this.date_filter_default.push(startOfMonth);
      this.date_filter_default.push(endOfMonth);
    },
    updateSuccess() {
      this.dialogUpdate = false;
      this.getList();
    },
    getList() {
      this.pullParamsUrl()
      this.callApiGetList()
    },
    pullParamsUrl() {
      this.page = this.$route.query.page ? Number(this.$route.query.page) : 1
      this.query.keyword = this.$route.query.keyword;
      this.query.date_range = this.$route.query.date_range ? this.$route.query.date_range : [];
    },
    pushParamsToUrl() {
      let query = {
        page: this.page,
        ...this.query,
        date_range: this.query.date_range ? this.query.date_range : this.date_filter_default,
      }
      this.pushParamsUrl(query);
    },
    clickCallback(pageNum) {
      this.query.page = pageNum
      this.pushParamsUrl(this.query)
      this.callApiGetList()
    },
    callApiGetList() {
      this.isBusy = true;
      this.$store.dispatch(LIST_LEAD_WEBSITE, {
        ...this.query,
        keyword: this.query.keyword?.trim(),
        page: this.page
      }).then((res) => {
      }).finally(() => this.isBusy = false);
    },

    exportExcel() {
      this.is_export_excel = true
      let payload = this.query;
      const HEADER_ROW_EXPORT_LEAD_WEBSITE= [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'name',
          value: 'Họ tên ',
        },
        {
          key: 'email',
          value: 'Email',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'question',
          value: 'Nội dung',
        },
        {
          key: 'created_at',
          value: 'Thời gian gửi',
        },
        {
          key: 'center',
          value: 'Nguồn website',
        },
      ]
      this.$store.dispatch(EXPORT_LEAD_WEBSITE, payload).then((res) => {
        exportExcel(HEADER_ROW_EXPORT_LEAD_WEBSITE, res.data, 'Danh sách lead website',true)
        this.is_export_excel = false
      });
    },

    searchData() {
      this.page = 1
      this.isBusy = true
      this.pushParamsToUrl()
      this.callApiGetList()
    },


  }
}
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}
.b-table-sticky-header {
  overflow-y: auto;
  max-height: 500px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #EBEDF3;
}

.table td {
  text-align: center;
  vertical-align: unset;
}
.col-form-label {
  display: none;
}

.b-table-sticky-header > .table.b-table > thead > tr > th {
  background: #F3F6F9;
}
</style>
