<template>
  <div class="container mt-xl-0 mt-4">
    <h3>Kết quả bài test</h3>
    <div class="card" style="background-color: #F6F6F6">
      <div class=" content_result  p-4     ">
        <div v-if="resultTests.length>0">
          <h3>Điểm và nhận xét</h3>
          <div v-for="resultTest in resultTests">
            <CommentsTest :resultTest=resultTest :testIELTS="testIELTS"></CommentsTest>
          </div>
          <OveralTest v-if="(testIELTS && resultTests.length == 4 ) ||((!testIELTS && resultTests.length == 2 ))"
                      :pointOverall="pointOverall" :testIELTS="testIELTS"></OveralTest>
          <div v-if="suggestedCourse || suggestedCourse != ''">
            <h3>Khóa học gợi ý</h3>
            <div class="bg-white rounded-sm p-5">
              {{ suggestedCourse }}
            </div>
          </div>
        </div>
        <div v-else>
          <p class="mb-0">Chưa có kết quả.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommentsTest from "@/view/pages/test-input/components/ComponentsTaskDetail/CommentsTest";
import {ResultTest, ResultTests, toRoundingMark} from "@/view/pages/test-input/models/result_test";
import {GET_RESULT_EXAM, UPDATE_COURSE_SUGGEST} from "@/core/services/store/test/testInput.module";
import {SPEAKING, TYPE_TEST_BY_KEY} from "@/core/option/testInputOption";
import OveralTest from "@/view/pages/test-input/components/ComponentsTaskDetail/OveralTest";

export default {
  name: "TestResults",
  components: {OveralTest, CommentsTest},
  mounted() {
    this.getResultTests()
  },
  data() {
    return {
      resultTests: [],
      testIELTS: false,
      suggestedCourse: null,
      idSchedule: null,
      pointOverall : null
    }
  },
  methods: {
    async getResultTests() {
      let test_input_schedule_id = this.$route.params.id
      let data = await this.$store.dispatch(GET_RESULT_EXAM, test_input_schedule_id ?? 0);
      if (!data.error) {
        this.suggestedCourse = data?.data?.course_suggest
        this.idSchedule = data.data.test_input_schedule_id
        let keys = ["speaking", "writing", "listening", "vocabulary", "reading"];
        if (data.data?.vocabulary?.right_answers > 19) {
          keys = ["speaking", "writing", "listening", "reading"];
          this.testIELTS = true
          this.$emit('isIelts')
        } else {
          keys = ["speaking", "vocabulary"];
        }
        let count = 0
        let res = keys.map((obj) => {
          if (!data?.data?.hasOwnProperty(obj) || data.data[obj] == null) {
            return null;
          }
          count++
          return new ResultTest(TYPE_TEST_BY_KEY.get(obj), data.data[obj].right_answers ?? null, data.data[obj].total_correct ?? null, data.data[obj].avg_point ?? null, data.data[obj],data.data);
        }).filter(d => d != null);
        this.resultTests = res;
        let totalPoint = this.resultTests.map((resultTest) => resultTest?.totalPoint ?? resultTest?.totalPointAuto ?? 0).reduce((a, b) => parseFloat(a) + parseFloat(b))
        let keyGeneral = ["writing", "listening", "reading"]
        let countGeneral = 0
        keyGeneral.forEach((obj)=>{
          if (!data?.data?.hasOwnProperty(obj) || data.data[obj] == null) {
            return null;
          }
          countGeneral++
        })
        if(countGeneral === 3 || (data?.data?.vocabulary !== null && data?.data?.vocabulary?.right_answers<20)){
          this.$emit("doneTest", true)
        }

        if (((count == 4 && this.testIELTS) || (count == 2 && !this.testIELTS)) ) {
          this.pointOverall = data?.data?.overall;
        }
      }
    },
    autoSuggestedCourse(pointOverall) {
      let speaking = this.resultTests.find((test) => test.type == 4)
      if (!this.testIELTS) {
        if (pointOverall > 2.5 && speaking.totalPoint > 2) {
          return "Giao tiếp cơ bản, Pre IELTS"
        }
        return "Basic"
      }
      if (pointOverall <= 2.5) {
        return "Basic"
      }
      if (pointOverall < 3.5) {
        return "Giao tiếp cơ bản, Pre IELTS"
      }

      if (pointOverall <= 4) {
        return "IELTS 3.5 - 4.5"
      }

      if (pointOverall <= 5) {
        return "IELTS 4.5 - 5.5"
      }
      return "IELTS 5.5 - 6.5"

    },
    saveCourseSuggest(course) {
      let payload = {
        id: this.idSchedule,
        data: {
          course_suggest: course
        }
      }
      this.$store.dispatch(UPDATE_COURSE_SUGGEST, payload).then((data) => {
      })
    },
  },
  computed: {
    // pointOverall() {
    //   let totalPoint = this.resultTests.map((resultTest) => resultTest?.totalPoint ?? resultTest?.totalPointAuto ?? 0).reduce((a, b) => parseFloat(a) + parseFloat(b));
    //   return toRoundingMark(totalPoint / this.resultTests.length);
    // }
  }
}
</script>

<style scoped>
.content_result {
  max-height: 730px;
  overflow-y: auto;

}
</style>