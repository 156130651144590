<template>
  <div>
    <b-modal
        @ok="handleOk"
        centered id="test-input-edit-modal"
        size="lg"
        title="Sửa tác vụ"
        ok-title="Lưu"
        cancel-title="Hủy"
        :ok-disabled="okDisable"
    >
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" autocomplete="off">
          <div class="container">
            <div class="row">
              <div class="col-md-12 mb-2">
                <h5>Thông tin tác vụ</h5>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Loại tác vụ <span class="text-danger">*</span></label>
                  <ValidationProvider name="type_task" rules="required|max:30" v-slot="{ errors,classes }"
                                      vid="type_task">
                    <el-select disabled v-model="schedule.type_task"
                               placeholder="Loại tác vụ"
                               clearable
                    >
                      <el-option
                          v-for="item in type_task"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Trung tâm test<span class="text-danger">*</span></label>
                  <ValidationProvider name="Trung tâm test" rules="required" v-slot="{ errors,classes }"
                                      vid="center_id">
                    <el-select v-model="schedule.center_id"
                               @input="handleCenter"
                               placeholder="Trung tâm test"
                               clearable
                    >
                      <el-option
                          v-for="item in centers"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Cơ sở test<span class="text-danger">*</span></label>
                  <ValidationProvider name="Cơ sở test" rules="required" v-slot="{ errors,classes }"
                                      vid="branch_id">
                    <el-select v-model="schedule.branch_id"
                               placeholder="Cơ sở test"
                               clearable
                    >
                      <el-option
                          v-for="item in branches"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Hình thức test<span class="text-danger">*</span></label>
                  <ValidationProvider name="Hình thức test" rules="required" v-slot="{ errors,classes }"
                                      vid="type">
                    <el-select v-model="schedule.type"
                               placeholder="Cơ sở test"
                               clearable
                    >
                      <el-option
                          v-for="item in WORKINGFORM"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
<!--              <div class="col-md-6">-->
<!--                <div class="form-group">-->
<!--                  <label>Loại đề test<span class="text-danger">*</span></label>-->
<!--                  <ValidationProvider name="Loại đề test" rules="required" v-slot="{ errors,classes }"-->
<!--                                      vid="type">-->
<!--                    <el-select v-model="schedule.type_exam_id"-->
<!--                               placeholder="Cơ sở test"-->
<!--                               clearable-->
<!--                    >-->
<!--                      <el-option-->
<!--                          v-for="item in TYPE_EXAMS"-->
<!--                          :key="item.id"-->
<!--                          :label="item.name"-->
<!--                          :value="item.id">-->
<!--                      </el-option>-->
<!--                    </el-select>-->
<!--                    <div class="fv-plugins-message-container">-->
<!--                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>-->
<!--                    </div>-->
<!--                  </ValidationProvider>-->
<!--                </div>-->
<!--              </div>-->
              <div class="col-md-6">
                <div class="form-group">
                  <label>Ngày làm<span class="text-danger">*</span></label>
                  <ValidationProvider name="Ngày làm" rules="required" v-slot="{ errors,classes }"
                                      vid="schedule">
                    <date-picker
                        v-model="schedule.schedule"
                        type="date"
                        placeholder="Ngày làm"
                        :disabled-date="disabledBeforeToday"
                        value-type="YYYY-MM-DD"
                        format="DD-MM-YYYY"
                        @input="handleSchedule"
                    >
                      <template v-slot:footer>
                        <button class="mx-btn mx-btn-text" @click="toggleTimePanel">
                          {{ showTimePanel ? 'select date' : 'select time' }}
                        </button>
                      </template>
                    </date-picker>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <label>Thời gian bắt đầu<span class="text-danger">*</span></label>
                <date-picker
                    v-model="schedule.start_time"
                    value-type="format"
                    type="time"
                    :open.sync="open"
                    placeholder="Thời gian bắt đầu"
                    @change="handleChange"
                    :time-picker-options="time_picker_options"
                ></date-picker>
              </div>
              <div class="col-md-12 mt-4 mb-2">
                <h5>Thông tin khách hàng</h5>
              </div>
              <div class="col-md-6">
                <label>Họ và tên<span class="text-danger">*</span></label>
                <ValidationProvider name="Họ và tên" rules="required" v-slot="{ errors,classes }"
                                    vid="name">
                  <el-input placeholder="Họ và tên" v-model="schedule.name"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <label>Số điện thoại<span class="text-danger">*</span></label>
                <ValidationProvider name="Số điện thoại" rules="required" v-slot="{ errors,classes }"
                                    vid="phone">
                  <el-input placeholder="Số điện thoại" v-model="schedule.phone"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  TEST_INPUT_GET_TESTER_BY_USER_ID,
  TEST_INPUT_STORE_TASK_PERSONAL, TEST_INPUT_UPDATE_TASK_PERSONAL
} from "@/core/services/store/test/testInput.module";
import {
  TIME_PICKER_OPTIONS,
  TYPE_EXAMS,
  TYPE_TASK,
  TYPE_TASK_TEST_INPUT,
  WORKINGFORM
} from "@/core/option/testInputOption";
import {mapGetters} from "vuex";
import moment from "moment-timezone";
import {GET_LIST_CENTERS} from "@/core/services/store/center/centers.module";
import {GET_BRANCHES} from "@/core/services/store/center/branch.module";

export default {
  name: "TestInputEditModal",
  components: {DatePicker},
  props: {
    schedule_prop: {
      type: Object,
      default: () => {
        return null;
      }
    },
  },
  data() {
    return {
      //setup
      showTimePanel: false,
      okDisable: false,
      search_loading: false,
      fileList: [],
      type_task: TYPE_TASK,
      WORKINGFORM: WORKINGFORM,
      TYPE_EXAMS: TYPE_EXAMS,
      testers: [],
      users: [],
      centers: [],
      branches: [],
      schedule: {
        type_task: TYPE_TASK_TEST_INPUT,
        tester_id: '',
        schedule: '',
        end_schedule: '',
        start_time: '',
        user_id: '',
        type: '',
        type_exam_id: '',
        name: '',
        phone: '',
        center_id: '',
        branch_id: ''
      },
      time_picker_options: TIME_PICKER_OPTIONS,
      open: false
    }
  },
  watch: {
    schedule_prop: {
      handler() {
        this.handleTestInput();
      }
    }
  },
  mounted() {
    // this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
    // this.handleTestInput();
    // })
    this.getUser();
    this.getCenters();
    this.getBranches();
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    handleTestInput() {
      this.schedule = this.schedule_prop;
      this.schedule.start_time = moment(this.schedule.schedule).format('HH:mm:ss')
      this.schedule.schedule = moment(this.schedule.schedule).format('YYYY-MM-DD')
    },
    handleCenter() {
      this.schedule.branch_id = '';
      this.getBranches();
    },
    handleChange(value, type) {
      if (type === 'second') {
        this.open = false;
      }
    },
    getCenters() {
      this.$store.dispatch(GET_LIST_CENTERS).then((data) => {
        this.centers = data.data;
      });
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        center_id: this.schedule.center_id,
        limit: 100000
      }).then((data) => {
        this.branches = data.data.data;
      });
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    handleSchedule() {
      let end_date = moment(this.schedule.schedule).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss');
      this.schedule.end_schedule = end_date;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.updateTestInput();
    },
    disabledBeforeToday(date) {
      const today = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
      return moment(date) < today;
    },
    updateTestInput() {
      this.okDisable = true;
      this.$store.dispatch(TEST_INPUT_UPDATE_TASK_PERSONAL, this.getPayload()).then((data) => {
        this.okDisable = false;
        this.$message.success(data.message);
        this.$emit('create-success');
        this.closeModal();
      }).catch((e) => {
        this.okDisable = false;
        if (e.response.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.response.data.data.message_validate_form);
        }
      });
    },
    getPayload() {
      return {
        id: this.schedule.id,
        schedule: this.schedule.schedule,
        start_time: this.schedule.start_time,
        type: this.schedule.type,
        name: this.schedule.name,
        phone: this.schedule.phone,
        center_id: this.schedule.center_id,
        user_id: this.currentUser.user.id,
        branch_id: this.schedule.branch_id,
        type_exam_id: this.schedule.type_exam_id,
        type_task: this.schedule.type_task
      };
    },
    // Set phòng ban xử lý

    closeModal() {
      this.$bvModal.hide('test-input-edit-modal');
    },
    getUser() {
      this.$store.dispatch(TEST_INPUT_GET_TESTER_BY_USER_ID, {
        keyword: this.currentUser.user.id
      }).then((data) => {
        let tester = data.data.length ? data.data[0] : null;
        if (tester && tester.user) {
          this.users.push(tester.user);
          this.schedule.tester_id = tester.id;
        }
        this.schedule.user_id = this.currentUser.user.id;
      })
    }
  }
}
</script>

<style scoped>
.el-select {
  width: 100%;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}
</style>