<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Danh sách rating</h3>
        </div>
        <div class="card-title">
          <h3 class="card-label" v-if="is_show"><span>Điểm đánh giá trung bình :</span> {{rating}}</h3>
        </div>
        <div class="card-title">
          <router-link
              class="font-weight-bold font-size-3  btn btn-success"
              :to="{ name: 'review-create' }"
          >Thêm mới
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
            <div class="input-group row">
              <div class="col">
                <div class="form-group">
                    <multiselect v-model="cache_center" :options="centers"
                                 placeholder="Chọn trung tâm"
                                 label="name"
                                 track-by="name"
                                 @input="selectCenter($event)"
                    ></multiselect>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                    <multiselect v-model="cache_branch" :options="branches"
                                 placeholder="Chọn chi nhánh"
                                 label="name"
                                 track-by="name"
                                 @input="selectBranch($event)"
                    ></multiselect>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                    <multiselect v-model="cache_course" :options="courses"
                                 placeholder="Chọn khóa học"
                                 label="name"
                                 track-by="name"
                                 @input="selectCourse($event)"
                    ></multiselect>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                    <multiselect v-model="cache_class" :options="center_classes"
                                 placeholder="Chọn lớp"
                                 label="name"
                                 track-by="name"
                                 @input="selectClass($event)"
                    ></multiselect>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                    <multiselect v-model="cache_teacher" :options="teachers"
                                 placeholder="Chọn giảng viên"
                                 label="name"
                                 track-by="name"
                                 @input="selectTeacher($event)"
                    ></multiselect>
                </div>
              </div>
            </div>
          <div class="example-preview table-responsive  datatable-bordered  datatable-default datatable-primary datatable-loaded">
            <table class="table table-vertical-center datatable-table">
              <thead>
              <tr>
                <th class="datatable-cell datatable-toggle-detail"><span></span></th>
                <th scope="col">Bình luận</th>
                <th scope="col">Rating</th>
                <th scope="col">Tên trung tâm</th>
                <th scope="col">Tên chi nhánh</th>
                <th scope="col">Tên khóa học</th>
                <th scope="col">Tên lớp</th>
                <th scope="col">Tên giảng viên, trợ giảng</th>
                <th scope="col">Phân loại</th>
                <th scope="col">Trạng thái</th>
                <th scope="col" class="min-w-100px">Hành động</th>
              </tr>
              </thead>
              <tbody class="datatable-body" v-for="(item, index) in getAllReviews" :key="index" v-if="item !== null && item.parent_id === 0">
              <tr  class="datatable-row" >
                <td class="datatable-cell datatable-toggle-detail"><a class="datatable-toggle-detail" title="Xem trả lời" href="javascript:" @click="showReplyComment(item.id)"><i
                    class="fa fa-caret-right" style="color: #00aff0"></i></a></td>
                <td class="">{{item.comment}}</td>
                <td class="d-flex justify-content-center"> <v-rating
                    class=""
                    size="30"
                    v-model="item.rating"
                    background-color="grey lighten-1"
                    color="warning"
                    large
                    readonly
                >
                </v-rating></td>
                <td class="">{{item.center ? item.center.name : ''}}</td>
                <td class="">{{item.branch ?item.branch.name: ''}}</td>
                <td class="">{{item.course ? item.course.name : ''}}</td>
                <td class="">{{item.center_class ? item.center_class.name: ''}}</td>
                <td class="">{{ renderTeacherTutorName(item) }}</td>
                <td class=""><span class="badge" v-bind:class="item.is_fake === 1 ? classFake.danger : classFake.success">
                                    {{item.is_fake === 1 ? "Rating ảo" : 'Rating thực tế'}}
                                </span></td>
                <td class=""><span class="badge"
                                   v-bind:class="item.status === 1 ? classSpan.success : classSpan.danger">
                                    {{item.status === 1 ? "Hiển thị" : 'Không hiển thị'}}
                                </span></td>
                <td class="">
                  <router-link :to="{ name: 'review-update', params: {id : item.id} }" title="Sửa"
                               class="btn btn-sm btn-icon mr-2 btn-outline-info"><i class="fas fa-pen-nib"></i>
                  </router-link>
                  <a title="Xóa" @click="deleteReview(item.id)" href="javascript:"
                     class="btn btn-sm btn-icon btn-outline-danger mr-2"><i class="fas fa-trash"></i></a>
                </td>
              </tr>
              <tr v-if="opened.includes(item.id)" v-for="(review,key) in openData" :key="key">
                <td colspan=""></td>
                <td colspan="3" class="text-left"><strong>Bình luận: </strong>{{review.comment}}</td>
                <td colspan="3" class="text-left"><strong>Trả lời bởi: </strong>{{ review.user? review.user.name : 'Admin'}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="edu-paginate mx-auto text-center">
        <paginate
            v-model="query.page"
            :page-count="total_pages"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'Trước'"
            :next-text="'Sau'"
            :container-class="'pagination b-pagination'"
            :pageLinkClass="'page-link'"
            :page-class="'page-item'"
            :next-link-class="'next-link-item'"
            :prev-link-class="'prev-link-item'"
            :prev-class="'page-link'"
            :next-class="'page-link'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Multiselect from 'vue-multiselect';
import {
  DELETE_REVIEW, GET_AVG_RATING,
  GET_REVIEWS,
} from "../../../core/services/store/review/review.module";
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import {GET_COURSES} from "../../../core/services/store/course/course.module";
import {GET_CLASSES} from "../../../core/services/store/course/classes.module";
import {GET_TEACHERS} from "../../../core/services/store/teacher/teacher.module";

const _ = require('lodash');

export default {
  name: "List-Review",
  data() {
    return {
      rating : 0,
      is_show : false,
      opened: [],
      openData: [],
      query : {
        page: 1,
        center_id: '',
        branch_id : '',
        class_id : '',
        course_id : '',
        teacher_id : '',
        show_progress: 1
      },
      classSpan : {
        success : 'badge-success',
        danger : 'badge-danger'
      },
      classFake : {
        success : 'badge-success',
        danger : 'badge-warning'
      },
      branchesByCenter : [],
      coursesByCenter : [],
      classesByCourse : [],
      teachersByBranch : [],
      /* options */
      centers: [],
      branches : [],
      courses : [],
      center_classes: [],
      teachers: [],
      reviews: [],
      /*Cache */
      cache_center: '',
      cache_branch: '',
      cache_course : '',
      cache_class : '',
      cache_teacher : '',
      payload : {
        limit : 100000,
        status: 1,
      }
    };
  },
  components : {
    Multiselect,
  },
  computed: {
    ...mapGetters(["getAllReviews","getReviewPaginate"]),
    total_pages() {
      return this.getReviewPaginate?.total_pages || 1;
    },
  },
  async created() {
    await this.getCenters();
    await this.getBranches();
    await this.getCourses();
    await this.getClasses();
    await this.getTeachers();
    await this.setParams();
    await this.getAvgRating();
    await this.$store.dispatch(GET_REVIEWS,this.query).then((res)=>{
      this.reviews = res.data.data;
    });
  },
   mounted() {
     this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý trung tâm"},
      { title: "Danh sách rating" }
    ]);
  },
  methods: {
    async getCenters (){
      this.$store.dispatch(GET_CENTERS,this.payload).then((res) => {
        this.centers = res.data.data;
      });
    },
    async getBranches() {
      await this.$store.dispatch(GET_BRANCHES,this.payload).then((res)=>{
        this.branches = res.data.data;
        this.branchesByCenter = res.data.data;
      });
    },
    async getCourses() {
      await this.$store.dispatch(GET_COURSES,this.payload).then((res)=>{
        this.courses = res.data.data;
        this.coursesByCenter = res.data.data;
      });
    },
    async getClasses() {
      await this.$store.dispatch(GET_CLASSES,this.payload).then((res)=>{
        this.center_classes = res.data.data;
        this.classesByCourse = res.data.data;
      });
    },
    async getTeachers() {
      await this.$store.dispatch(GET_TEACHERS,{limit: 10000, status: 1, is_staff: 2}).then((res)=>{
        this.teachers = res.data.data;
        this.teachersByBranch  = res.data.data;
      });
    },
    selectCenter : function ($event){
      let center_id = $event != null ? $event.id : '';
      this.is_show = $event != null;
      this.query.center_id = center_id;
      if (center_id !== ''){
        this.branches = _.reduce(this.branchesByCenter, function (result,value,key){
          if (center_id === value.center_id){
            result.push(value);
          }
          return result;
        },[]);
        this.courses = _.reduce(this.coursesByCenter,function (result,value,key){
          if (center_id === value.center_id){
            result.push(value);
          }
          return result;
        },[]);
      }else{
        this.branches = this.branchesByCenter;
        this.courses = this.coursesByCenter;
      }
      this.cache_branch = '';
      this.cache_course = '';
      this.pushParams();
      this.getQuery();
      this.getAvgRating({center_id : center_id});
    },
    selectBranch: function ($event){
      this.is_show = $event != null;
      this.query.branch_id = $event != null ? $event.id : '';
      let branch_id = $event != null ? $event.id : '';
      if (branch_id !== ''){
        this.teachers = _.reduce(this.teachersByBranch, function (result,value,key){
          if (branch_id === value.profile.chi_nhanh_id){
            result.push(value);
          }
          return result;
        },[]);
      }else{
        this.teachers = this.teachersByBranch;
      }
      this.cache_class = '';
      this.cache_teacher = '';
      this.pushParams();
      this.getQuery();
      this.getAvgRating({branch_id : this.query.branch_id});
    },
    selectCourse : function ($event){
      this.is_show = $event != null;
      let course_id = $event != null ? $event.id : '';
      this.query.course_id = course_id;
      if (course_id !== ''){
        this.center_classes = _.reduce(this.classesByCourse, function (result,value,key){
          if (course_id === value.course_id){
            result.push(value);
          }
          return result;
        },[]);
      }else{
        this.center_classes = this.classesByCourse;
      }
      this.cache_class = '';
      this.pushParams();
      this.getQuery();
      this.getAvgRating({course_id : course_id});
    },
    selectClass : function ($event){
      this.is_show = $event != null;
      this.query.class_id = $event != null ? $event.id : '';
      this.pushParams();
      this.getQuery();
      this.getAvgRating({class_id : $event != null ? $event.id : ''});
    },
    selectTeacher : function ($event){
      this.is_show = $event != null;
      this.query.teacher_id = $event != null ? $event.id : '';
      this.pushParams();
      this.getQuery();
      this.getAvgRating({teacher_id : $event != null ? $event.id : ''});
    },
    setParams : async function (){
      this.query.page = parseInt(this.$route.query.page ? this.$route.query.page : 1);
      let center_id = this.query.center_id = this.$route.query.center_id ? this.$route.query.center_id : '';
      let branch_id = this.query.branch_id = this.$route.query.branch_id ? this.$route.query.branch_id : '';
      let class_id = this.query.class_id = this.$route.query.class_id ? this.$route.query.class_id : '';
      let course_id = this.query.course_id = this.$route.query.course_id ? this.$route.query.course_id : '';
      this.cache_center = _.find(this.centers, function (center){
        return center.id === parseInt(center_id);
      });
      this.cache_branch = _.find(this.branches, function (branch){
        return branch.id === parseInt(branch_id);
      });
      this.cache_course = _.find(this.courses, function (course){
        return course.id === parseInt(course_id);
      });
      this.cache_class = _.find(this.center_classes, function (value){
        return value.id === parseInt(class_id);
      });
    },
    showReplyComment : function (parent_id){
      this.openData = _.reduce(this.reviews, function (result,value,key){
        if (value.parent_id === parent_id){
          result.push(value);
        }
        return result;
      },[]);
      const index = this.opened.indexOf(parent_id);
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(parent_id)
      }
    },
    deleteReview(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_REVIEW, id).then(() => {
          });
        }
      });
    },
    searchReviews : _.debounce(function() {
      this.getQuery();
    }, 300),
    selectAccountType : function (event){
      this.query.account_position = event != null ? event.id : '';
      this.getQuery();
    },
    clickCallback(pageNum) {
      this.query.page = pageNum;
      this.pushParams();
      this.$store.dispatch(GET_REVIEWS,this.query);
    },
    pushParams : function (){
      this.$router.push({ path : '/reviews' , query : this.query});
    },
    getQuery : function (){
      this.$store.dispatch(GET_REVIEWS,this.query).then((res) => {
        this.query.page = 1;
      });
    },
    async getAvgRating(payload){
      await this.$store.dispatch(GET_AVG_RATING, payload).then((res)=>{
        this.rating = res.data;
        if (this.rating > 0){
          this.is_show = true;
        }
      });
    },
    renderTeacherTutorName(review)
    {
      if (review.center_class && review.center_class.tutors){
        return  review.center_class.teacher.name + ', ' + review.center_class.tutors.name;
      }else {
        return review.center_class.teacher.name;
      }

    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.theme--light.v-icon {
  color: #ffa800 !important;
}
.v-icon {
  font-size: 15px !important;
}
</style>
