import {SHIFTS, TYPE_TASK_ITEM_DEFINE, TYPE_TASK_ITEM_DEFINE_CSS} from "@/core/option/testInputOption";
import moment from "moment-timezone";
export function WeekLyTaskModel(idTask,typeTask,timeStart ,timeEnd,date ,typeItem  ){
    this.idTask = idTask
    this.timeStart = timeStart
    this.timeEnd = timeEnd
    this.date = date
    this.nameTask = toNameTask(typeTask ,typeItem)
    this.typeTask = typeTask
    this.color = toColor(typeTask ,typeItem)
}

export const WeekLyTasks = [
    new WeekLyTaskModel(1,2,"08:30","09:30","12-06-2023"),
    new WeekLyTaskModel(2,4,"09:30","10:30","13-06-2023"),
    new WeekLyTaskModel(3,1,"14:30","15:30","14-06-2023"),
]

export function registerCalendar(dayWord,shift){
    this.dayWord = dayWord
    this.shift = toShift(shift)
}
export const registerCalendars = [
    new registerCalendar("12-06-2023",1),
    new registerCalendar("13-06-2023",1),
    new registerCalendar("14-06-2023",2)

]
function toShift(shift){
return SHIFTS.get(shift)
}
function toNameTask (typeTask , typeItem){
    if(typeItem != undefined && typeTask ==1){
        return TYPE_TASK_ITEM_DEFINE[typeItem]
    }
    return TYPE_TASK_ITEM_DEFINE[typeTask]
}
function toColor(typeTask , typeItem){
    if(typeItem != undefined && typeTask ==1){
        return TYPE_TASK_ITEM_DEFINE_CSS[typeItem]
    }
    return TYPE_TASK_ITEM_DEFINE_CSS[typeTask]
}

