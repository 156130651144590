import moment from "moment/moment";

export class ManagementKpiModel {
    constructor(id, name = '', userId, department = '', departmentId, total, point, personHandle = '', status, date) {
        this.id = id;
        this.name = name;
        this.userId = userId;
        this.department = department;
        this.departmentId = departmentId;
        this.total = total;
        this.point = point;
        this.personHandle = personHandle;
        this.status = status;
        this.date = this.formatDate(date);
        this.statusType = this.setTypeStatus(status);
    }

    setTypeStatus(status) {
        switch (status) {
            case 0:
                return 'Cần nhập thành phẩm'
            case 1:
                return 'Cần chấm'
            case 2:
                return 'Đã chấm'
            default:
                return 'Chưa nhập thành phẩm'
        }
    }

    formatDate(date) {
        if (date) {
            return moment(date).format('MM-YYYY');
        }
    }
}

export class ExportExcelModel {
    constructor(userId, name, department, total, point) {
        this.userId = userId;
        this.name = name;
        this.department = department;
        this.total = this.setTotalPercent(total);
        this.point = point ?? 0;
    }
    setTotalPercent(total) {
        if (total) {
            return `${total}%`;
        } else
            return "0%";
    }
}

export class ResultKpiDetailModel {
    constructor(id, userId, name, requestResult, result, weight, listKra,totalPercent, comment) {
        this.id = id;
        this.name = name;
        this.userId = userId;
        this.requestResult = requestResult;
        this.result = result;
        this.weight = weight;
        this.listKra = this.setListKra(listKra);
        this.totalPercent = totalPercent;
        this.comment = comment;
    }
    setListKra(array) {
        if (array.length > 0) {
            return array.map(kra => {
                return new KraModel(
                    kra?.id,
                    kra?.kra_manager_id,
                    kra?.week,
                    kra?.percent_results
                )
            })
        }
    }
    setListWeeklyResult(results) {
        if (results.length > 0) {
            return results.map(item => {
                return new WeeklyResultModel(
                    item?.id,
                    item?.week,
                    item?.kra_manager_id,
                    item?.is_success,
                )
            })
        }
    }
}

export class KraModel {
    constructor(id, kraId, week, percentResults) {
        this.id = id;
        this.kraId = kraId;
        this.week = week;
        this.percentResults = percentResults;
    }
}

export class WeeklyResultModel {
    constructor(id, name, week) {
        this.id = id;
        this.name = name;
        this.week = week
    }
}