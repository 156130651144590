<template>
  <div class="d-block text-center">
    <form class="form">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Tên : <span class="text-danger">*</span></label>
            <input type="text" name="name" class="form-control" placeholder="Tên vouvher"
                   :value="voucher.name" disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Giá trị :
              <span class="text-danger">*</span></label>
            <input type="text" name="email" class="form-control" placeholder="Giá trị"
                   :value="voucher.unit === 1 ? voucher.value + ' ₫' : voucher.value+ ' %' " disabled>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Ngày bắt đầu : <span class="text-danger">*</span></label>
            <input type="text" name="start_date" class="form-control" placeholder="Ngày bắt đầu"
                   :value="voucher.start_date" disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Ngày kết thúc :
              <span class="text-danger">*</span></label>
            <input type="text" name="end_date" class="form-control" placeholder="Ngày kết thúc"
                   :value="voucher.end_date" disabled>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Chi nhánh :
              <span class="text-danger">*</span></label>
            <input type="text" name="branch" class="form-control" placeholder="Chi nhánh áp dụng"
                   :value="voucher.branch && voucher.branch.name" disabled>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Mô tả :
              <span class="text-danger"></span></label>
            <input type="text" name="branch" class="form-control" placeholder="Mô tả"
                   :value="voucher.description" disabled>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "VoucherDetail",
  props: {
    voucher: {
      type: [Array, Object],
      default: () => { return {}}
    }
  }
}
</script>

<style scoped>

</style>
