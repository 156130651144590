<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Chương trình hoàn tiền</h3>
      </div>
      <div class="card-title">
        <button class="btn btn-success font-weight-bold btn-sm"
                v-b-modal.modal-create-ticket
                @click="showDetailModal({})"
        >
          Thêm mới
        </button>
      </div>
    </div>
    <div class="card-body">
      <!--begin::Example-->
      <div class="example mb-10">
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label>Tên chương trình</label>
              <input type="text" class="form-control"
                     v-model="query.keyword"
                     placeholder="Nhập tên chương trình">
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Trạng thái</label>
              <el-select filterable class="w-100" placeholder="Trạng thái"
                         clearable
                         v-model="query.status"
              >
                <el-option
                    v-for="(item, index) in this.status()"
                    :key="index"
                    :label="item"
                    :value="index"
                >
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Trung tâm áp dụng</label>
              <el-select filterable class="w-100" placeholder="Chọn trung tâm áp dụng"
                         clearable
                         multiple
                         v-model="query.center_ids"
                         @input="handleDepartment"
              >
                <el-option
                    v-for="item in listCenter"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Chi nhánh áp dụng</label>
              <el-select filterable class="w-100"
                         placeholder="Chọn chi nhánh áp dụng"
                         clearable
                         multiple
                         v-model="query.branch_ids"
                         @input="checkBranch"
              >
                <el-option
                    v-for="item in listBranch"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Thời gian áp dụng</label>
              <date-picker
                  placeholder="Chọn thời gian"
                  format="DD-MM-YYYY"
                  range
                  valueType="YYYY-MM-DD" v-model="query.date_range"
                  @clear="query.date_range = ''"
              >
              </date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="d-flex justify-content-between">
              <button class=" btn btn-primary font-weight-bold mr-2 mb-4"
                      @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div class="example-preview b-table-sticky-header">
          <table class="table table-vertical-center b-table">
            <thead class="thead-light">
            <tr>
              <th scope="col" class="min-w-50px">STT</th>
              <th scope="col" class="min-w-100px">Tên chương trình</th>
              <th scope="col" class="min-w-100px">Trung tâm áp dụng</th>
              <th scope="col" class="min-w-100px">Chi nhánh áp dụng</th>
              <th scope="col" class="min-w-120px">Thời gian áp dụng</th>
              <th scope="col" class="min-w-100px">Trạng thái</th>
              <th scope="col" class="min-w-150px">Hành động</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item , index) in listRefund" :key="index">
              <td>{{ page == 1 ? index + 1 : (page - 1) * 20 + index + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>
                <button type="button"
                        class=""
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        :title="getTextTooltipCenter(item.centers)">
                  {{ item.centers && item.centers[0] ? item.centers[0].name : 'Tất cả trung tâm' }} <span
                    class="border rounded border-dark p-1 ml-2"
                    v-if="item.centers && item.centers[0] && item.centers.length > 1"> +{{ item.centers.length > 1 ? item.centers.length - 1 : ''}}</span>
                </button>
              </td>
              <td>
                <button type="button"
                        class=""
                        data-container="body"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        :title="getTextTooltipBranch(item.branches)">
                  {{ item.branches && item.branches[0] ? item.branches[0].name : 'Tất cả chi nhánh' }} <span
                    v-if="item.branches.length > 1"
                    class="border rounded border-dark p-1 ml-2">+{{ item.branches.length - 1}}</span>
                </button>
              </td>
              <td>{{ item.time_start |formatDate }} - {{ item.time_end |formatDate }}</td>
              <td>{{ status()[item.status] }}</td>
              <td><a title="Sửa hợp đồng" @click="showDetailModal(item)"
                     v-b-modal.modal-create-ticket
                     class="btn btn-xs btn-icon btn-outline-info ml-2"><i
                  class="fas fa-pen-nib"></i>
              </a>
                <a
                    title="Xóa"
                    @click="deleteRefund(item.id,item.name)"
                    class="btn btn-xs btn-icon btn-outline-danger ml-2"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <modal-create-refund :program="showDetail"
                         :listBranch_prop="listBranch"
                         :listCenter_prop="listCenterProp"
                         @addParam="reaload"
    ></modal-create-refund>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>

import ModalCreateRefund from "@/view/pages/refund/components/ModalCreateRefund";
import {
  DELETE_REFUND_PROGRAM, GET_CONTRACT_STUDENT_REFUND,
  LIST_CENTER,
  LIST_REFUND_PROGRAM
} from "@/core/services/store/contract/contract-program.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from "moment-timezone";
import {
  GET_ALL_BRANCHES,
  GET_BRANCH_BY_LIST_CENTER_ID,
  GET_BRANCHES
} from "@/core/services/store/center/branch.module";
import Swal from "sweetalert2";
import {DELETE_TESTER} from "@/core/services/store/test/test.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "RefundList",
  components: {
    ModalCreateRefund, DatePicker
  },
  data() {
    return {
      page: 1,
      last_page: 1,
      listRefund: [],
      total_refund: 0,
      listCenter: [{
        id: 0,
        name: 'Tất cả trung tâm'
      }],
      listCenterProp: [],
      listBranch: [],

      query: {
        keyword: '',
        status: [],
        center_ids: [],
        branch_ids: [],
        date_range: [],
      },
      date_filter_default: [],
      showDetail: {
        status: '',
        center_id: '',
        branch_id: '',
        name: '',
        id: '',
        date_range: [],
        listBranch: []
      },
      title: ['tét', 'sss', 'ss']
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý tài chính", route: '#'},
      {title: "Chương trình hoàn tiền"}
    ]);
    this.getCenter();
    this.getList();
    this.getDateFilterDefault();
  },
  computed: {},
  methods: {
    getTextTooltipCenter(centers) {
      let result = '';
      for (let i = 0; i < centers.length; i++) {
        result = result + centers[i].name + '\n';
      }
      return result;
    },
    getTextTooltipBranch(branch) {
      let result = '';
      for (let i = 0; i < branch.length; i++) {
        result = result + branch[i].name + '\n';
      }
      return result;
    },
    getList() {
      this.pullParamsUrl()
      this.listRefundProgram()
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({
        name: 'refund-list',
        query: {
          page: this.page,
          ...this.query
        }
      })
      this.listRefundProgram()
    },
    pullParamsUrl() {
      this.page = this.$route.query.page ? Number(this.$route.query.page) : 1
      this.query.keyword = this.$route.query.keyword
      this.query.status = this.$route.query.status ? [(this.$route.query.status)].map(item => Number(item)) : ''
      this.query.center_ids = this.$route.query.center_ids ? [(this.$route.query.center_ids)].map(item => Number(item)) : ''
      this.query.branch_ids = this.$route.query.branch_ids ? [(this.$route.query.branch_ids)].map(item => Number(item)) : ''
      this.query.date_range = this.$route.query.date_range ? this.$route.query.date_range : [];
    },
    listRefundProgram() {
      let page = this.page;
      let keyword = this.query.keyword ? this.query.keyword.trim() : "";
      let status = this.query.status;
      let center_ids = this.query.center_ids;
      let branch_ids = this.query.branch_ids;
      let date_range = this.query.date_range;
      let total_center = 0;
      let total_branch = 0;
      if(center_ids == 0){
        total_center = 1;
      }
      if(branch_ids == 0) {
        total_branch = 1;
      }

      this.$store.dispatch(LIST_REFUND_PROGRAM, {
        keyword: keyword,
        status: status,
        center_ids: center_ids,
        branch_ids: branch_ids,
        date_range: date_range,
        page: page,
        total_branch:total_branch,
        total_center:total_center
      }).then(res => {
        if (!res.error) {
          this.total_refund = res.data.last_page;
          this.listRefund = res.data.data;
          this.page = res.data.current_page;
          this.last_page = res.data.last_page;
        }
      })
    },
    getCenter() {
      this.$store.dispatch(LIST_CENTER, {}).then(res => {
        if (!res.error) {
          this.listCenter = this.listCenter.concat(res.data);
          this.listCenterProp = res.data;
        }
      })
    },
    handleDepartment() {
      let center_id = this.query.center_ids;
      if (center_id.includes(0)) {
        if (center_id[0] == 0 && center_id.length != 1) {
          let index = center_id.indexOf(0);
          if (index > -1) { // only splice array when item is found
            center_id.splice(index, 1); // 2nd parameter means remove one item only
          }
          this.checkCenter(center_id);
        } else {
          this.query.center_ids = [0];
          this.$store.dispatch(GET_ALL_BRANCHES, {}).then(res => {
            if (!res.error) {
              let listBranch = [{
                id: 0,
                name:'Tất cả chi nhánh'
              }];
              this.listBranch = listBranch.concat(res);
            }
          })
        }
      } else {
        this.checkCenter(center_id);
      }
      this.removeNoNeedBranch();
    },
    checkBranch(){
      let branch_id = this.query.branch_ids;
      if (branch_id[0] == 0 && branch_id.length != 1) {
        let index = branch_id.indexOf(0);
        if (index > -1) { // only splice array when item is found
          branch_id.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
      if(branch_id.includes(0)){
          this.query.branch_ids = [0];
      }
    },
    checkCenter(center_id) {
      this.$store.dispatch(GET_BRANCH_BY_LIST_CENTER_ID, {center_id: center_id}).then(res => {
        if (!res.error) {
          let listBranch = [{
            id: 0,
            name:'Tất cả chi nhánh'
          }];
          this.listBranch = listBranch.concat(res.data);
        }
      })
    },
    getDateFilterDefault() {
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
      this.date_filter_default.push(startOfMonth);
      this.date_filter_default.push(endOfMonth);
    },
    status() {
      return ['Ẩn', 'Hiển thị'];
    },
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.listRefundProgram()
    },
    showDetailModal: function (refund) {
      this.showDetail = refund;
    },
    reaload() {
      this.listRefundProgram();
    },
    deleteRefund(id, name) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then((result) => {
        if(result.isConfirmed) {
          this.$store.dispatch(GET_CONTRACT_STUDENT_REFUND, {name: name}).then((res) => {
            if (res) {
              Swal.fire({
                title: "Không thể xóa do chương trình đã được áp dụng!",
                showDenyButton: true,
              })
            } else {
              this.$store.dispatch(DELETE_REFUND_PROGRAM, id).then((res) => {
                this.listRefundProgram();
                this.$notify({
                  title: 'Xóa',
                  message: res.message,
                  type: 'success'
                });
                this.getTestingSchedule();
              }).catch((res) => {
                this.$notify({
                  title: 'Thất bại',
                  message: res.data.message,
                  type: 'error'
                });
              });
            }
          });
        }
      });
    },
    removeNoNeedBranch(){
      var updateLevelSelect = [];
      if(this.query.center_ids.length){
        _.forEach(this.query.branch_ids, (value, key) => {
          let check = this.listBranch.find(x => x.id == value);
          if (check) {
            updateLevelSelect.push(value)
          }
        });
      }else {
        updateLevelSelect = [];
      }
      this.query.branch_ids = updateLevelSelect;
    },
  }
}
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}

.multiselect__tags {
  padding: 3px 40px 0 9px !important;
  min-height: 38px !important;
}

.multiselect {
  min-height: 38px !important;
}

.mx-input {
  min-height: 38px !important;
}

.multiselect__single {
  margin-top: 5px !important;
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 500px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
