<template>
  <div class="container">
    <h3>Thông tin ca test</h3>
    <div class="bg-secondary p-4 pb-6  row  rounded-sm ">
      <div class="col-6 mb-3">
        <label>Mã tác vụ</label>
        <input class="bg-white  d-block w-100  rounded-sm px-3 py-1">
      </div>
      <div class="col-6 mb-3">
        <label>Mã tác vụ</label>
        <input class="bg-white  d-block w-100  rounded-sm px-3 py-1">
      </div>
      <div class="col-6 mb-3">
        <label>Mã tác vụ</label>
        <input class="bg-white  d-block w-100  rounded-sm px-3 py-1">
      </div>
      <div class="col-6 mb-3">
        <label>Mã tác vụ</label>
        <input class="bg-white  d-block w-100  rounded-sm px-3 py-1">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestCaseInformation"
}
</script>

<style scoped>

</style>