<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="course_create" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Tạo cẩm nang vận hành
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'handbook-index' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Tiêu đề/Danh mục
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Tiêu đề, danh mục" rules="required"
                                    v-slot="{ errors,classes }">
                  <input type="text" ref="name" name="name" class="form-control"
                         v-model="handbook.category"
                         placeholder="Tiêu đề, danh mục" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Số thứ tự
                  <span class="text-danger"></span></label>
                <input type="number" ref="name" name="name" class="form-control"
                       v-model="handbook.stt"
                       placeholder="Số thứ tự">

              </div>
            </div>

          </div>
          <div class="row mb-4">
            <div class="col-md-6">
              <div class="checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input type="checkbox" name="Checkboxes5" v-model="handbook.is_all">
                  <span></span>Tất cả thành viên Edutalk có thể xem</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="!handbook.is_all">
            <div class="col-md-6">
              <div class="form-group">
                <label>Phân quyền xem
                  <span class="text-danger"></span></label>
                <ValidationProvider name="Phân quyền xem" rules="" v-slot="{ errors,classes }">
                  <el-select v-model="cacheAccountType" @change="updateStateAccountType()" filterable multiple class="w-100" placeholder="Loại tài khoản" clearable>
                    <el-option
                        v-for="item in accountType"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Cấp bậc</label>
                <el-select v-model="levelSelect" filterable multiple class="w-100" placeholder="Cấp bậc" clearable>
                  <el-option
                      v-for="item in levels"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Khu vực</label>
                <el-select v-model="areaSelect" filterable multiple class="w-100" placeholder="Khu vực" clearable>
                  <el-option
                      v-for="item in areas"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Phân quyền chức vụ kiêm nhiệm
                  <span class="text-danger"></span></label>
                <ValidationProvider name="Phân quyền xem" rules="" v-slot="{ errors,classes }">
                  <el-select v-model="cachePosition" filterable multiple class="w-100" placeholder="Loại tài khoản" clearable>
                    <el-option
                        v-for="item in positions"
                        :key="item.id"
                        :label="item.position"
                        :value="item.id"
                    >
                      <span style="float: left">{{ item.position }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6" v-if="checkDepartment">
              <div class="form-group">
                <label>Phòng ban</label>
                <el-select v-model="departmentSelect" multiple filterable class="w-100" placeholder="Phòng ban" clearable>
                  <el-option
                      v-for="item in departments"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-4">
              <div class="checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input type="checkbox" name="Checkboxes5" v-model="handbook.status">
                  <span></span>Hiển thị</label>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="form-group mb-1 col-md-12">
              <label>
                Kiểu thêm nội dung
                <span class="text-danger">*</span>
              </label>
              <div class="col-md-12 p-0">
                <ValidationProvider vid="typeContent" name="Kiểu thêm nội dung" rules="required"
                                    v-slot="{ errors,classes }">
                  <el-select v-model="handbook.type_content" placeholder="Chọn kiểu thêm nội dung" @change="checkShow">
                    <el-option
                        v-for="(item, index) in listTypeContent"
                        :key="index"
                        :value="item.id"
                        :label="item.name">

                    </el-option>
                  </el-select>

                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>

            <div class="form-group mb-1 col-md-12" v-if="handbook.type_content === 0">
              <label>Nội dung
                <span class="text-danger">*</span></label>
              <ValidationProvider vid="content" name="Nội dung" rules="required"
                                  v-slot="{ errors,classes }">
                <ckeditor :editor="editor" v-model="handbook.content"></ckeditor>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                      errors[0]
                    }}
                  </div>
                </div>
              </ValidationProvider>
            </div>

            <div class="form-group mb-1 col-md-12" v-if="handbook.type_content === 1">
              <label>Liên kết
                <span class="text-danger">*</span>
              </label>
              <div class="col-md-12 p-0">
                <ValidationProvider vid="content" name="Liên kết" rules="required"
                                    v-slot="{ errors,classes }">
                  <textarea name="" id="" class="col-12 border" v-model="handbook.content" placeholder="Dán liên kết tại đây">
                  </textarea>

                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>

              </div>
            </div>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button
                type="submit"
                class="btn btn-success mr-2"
            >
              Tạo mới
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>

import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {CREATE_HANDBOOK} from "../../../core/services/store/system/handbook.module";
import {GET_ACCOUNT_TYPE, GET_POSITIONS} from "../../../core/services/store/user/account-type.module";
import Multiselect from 'vue-multiselect'
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
//editor
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";
import {GET_LIST_AREAS} from "@/core/services/store/center/area.module";
import {GET_BUSINESS_LEVELS} from "@/core/services/store/system/information.module";
import {LIST_TYPE_CONTENT} from "@/core/services/store/system/mechanism.module";
import { STAFF, BOD, TEAMLEADER, HEAD_OF_DEPARTMENT } from '../../../core/option/accountTypeOption';
import {ALL_DEPARTMENT} from '../../../core/services/store/department/department.module';

export default {
  components: {
    Multiselect,
    DatePicker
  },
  name: "HandbookCreate",
  data() {
    return {
      //Editor
      editor: CustomEditor,
      // Check show
      is_show_course_type: false,
      is_show_level: false,
      handbook: {
        content: '',
        type: 2,
        status: true,
        publish: '',
        created_user_id: '',
        updated_user_id: '',
        category: '',
        stt: 1,
        id_parent: 0,
        slug: '',
        start_date: '',
        end_date: '',
        is_all: false,
        type_system_handbook: '',
        link: '',
        items: '',
        roles: [],
        positions: [],
        mechanism_type: 0,
        type_content: '',
        department_id: ''
      },

      accountType: [],
      positions: [],

      // Cache
      cacheAccountType: [],
      cachePosition: [],
      money: {
        decimal: ',',
        thousands: ',',
        prefix: '',
        suffix: ' VNĐ',
        precision: 0,
        masked: false,
      },
      levelSelect: [],
      levels: [],
      areaSelect: [],
      areas: [],
      listTypeContent: LIST_TYPE_CONTENT,
      checkDepartment: false,
      departments: {},
      departmentSelect: [],
    }
  },
  mounted() {
    this.getPositions()
    this.getAccountType()
    this.getListArea()
    this.getListDepartment()
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý cẩm nang vận hành", route: 'profile-1'},
      {title: "Tạo cẩm nang vận hành"}
    ]);
  },
  computed: {},
  methods: {
    getListArea() {
      this.$store.dispatch(GET_LIST_AREAS, {}).then(res => {
        if (!res.error) {
          this.areas = res.data;
        }
      })
    },
    getPositions() {
      this.$store.dispatch(GET_POSITIONS, {}).then((res) => {
        if (!res.error) {
          this.positions = res.data
        }
      });
    },
    updateStateAccountType() {
        let decentali = [STAFF, BOD, TEAMLEADER, HEAD_OF_DEPARTMENT];
        const check = this.cacheAccountType.some(r=> decentali.includes(r))
        this.checkDepartment = false;

        if (check) {
            this.checkDepartment = true;
        }
      this.$store.dispatch(GET_BUSINESS_LEVELS, {
        account_type_ids: this.cacheAccountType.length > 0 ? this.cacheAccountType : [0],
      }).then((res) => {
        if (!res.error) {
          this.levels = res.data
          var updateLevelSelect = []
          _.forEach(this.levelSelect, (value, key) => {
            let check = this.levels.find(x => x.id == value);
            if (check) {
              updateLevelSelect.push(value)
            }
          });
          this.levelSelect = updateLevelSelect
        }
      });
    },
    getAccountType() {
      this.$store.dispatch(GET_ACCOUNT_TYPE, {
        limit: 1000,
      }).then((res) => {
        if (!res.error) {
          this.accountType = res.data.data
        }
      });
    },
    onSubmit() {
      this.handbook.roles = this.cacheAccountType
      this.handbook.business_level = this.levelSelect
      this.handbook.areas = this.areaSelect
      this.handbook.positions = this.cachePosition
      this.handbook.department_id = this.departmentSelect

      this.$store.dispatch(CREATE_HANDBOOK, this.handbook).then((res) => {
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Tạo mới',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$router.push({name: "handbook-index"}).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Tạo mới',
              variant: 'success',
              solid: true
            });
          })
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      });
    },
    getListDepartment() {
        this.$store.dispatch(ALL_DEPARTMENT).then((res) => {
            if (!res.error) {
                this.departments = res.data
            }
        }).catch((e) => {
            console.log(e, 'error')
        });
    },
    checkShow() {
      this.handbook.content = '';
    }
  }
}
</script>
<style scoped>
.mx-datepicker {
  width: 100% !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
