<template>
  <div style="background: white" v-loading="loading">
    <HeaderBondManagement @getListBondManagement="getListBondManagement"/>
    <hr style="margin-top: 0;"/>
    <div style="background: white; padding: 10px 20px">
      <div style="display: flex; align-items: end; gap: 30px;">
        <div class="display-flex__column">
          <span style="font-weight: 700">Nhân sự</span>
          <el-input style="min-width: 250px" v-model="query.keyword" clearable placeholder="Nhập tên, SĐT, mã nhân sự"/>
        </div>
        <div class="display-flex__column">
          <span style="font-weight: 700">Giai đoạn</span>
          <el-select v-model="query.phase" style="min-width: 250px" clearable placeholder="Chọn giai đoạn">
            <el-option
                v-for="item in stageOption"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <el-button
            type="primary" style="background: #0066FF; color: white; border: #0066FF; height: 40px"
            @click="searchBond" v-loading="loadingSearch">Tìm kiếm
        </el-button>
      </div>
      <div class="table-responsive mt-8" style="overflow: hidden; padding-bottom: 30px">
        <table class="table" style="text-align: center;">
          <thead>
          <tr style="border: 1px solid #EBEDF3">
            <th scope="col" class="w-180px" style="background: #F8F8F8; text-align: start">Họ tên - Mã nhân sự</th>
            <th scope="col" class="w-100px" style="background: #F8F8F8; text-align: start">Mốc tích lũy</th>
            <th scope="col" class="w-110px" style="background: #F8F8F8; text-align: start">Thời điểm bắt đầu tích lũy</th>
            <th scope="col" class="w-130px" style="background: #F8F8F8; text-align: start">Thời điểm trái phiếu có hiệu lực</th>
            <th scope="col" class="w-150px" style="background: #F8F8F8; text-align: start;">Thời điểm được chuyển nhượng/bán</th>
            <th scope="col" class="w-100px" style="background: #F8F8F8; text-align: start">Tổng giá trị TP</th>
            <th scope="col" class="w-150px" style="background: #F8F8F8; text-align: start">Giai đoạn</th>
            <th scope="col" class="w-150px" style="background: #F8F8F8; text-align: start">Số lượng TP đã bán/chuyển nhượng</th>
            <th scope="col" class="w-100px" style="background: #F8F8F8; text-align: start">Số lượng TP còn lại</th>
            <th scope="col" class="w-140px" style="background: #F8F8F8; text-align: start">Thời gian cập nhật</th>
            <th scope="col" class="w-100px" style="background: #F8F8F8; text-align: center">Hành động</th>
          </tr>
          </thead>
          <tbody v-if="listBondManagement?.length > 0">
          <tr v-for="item of listBondManagement" :key="item?.index" style="border: 1px solid #EBEDF3; border-top: 0">
            <td style="text-align: start" :title="item?.user">{{ item?.user ?? '' }}</td>
            <td style="text-align: start; text-transform: uppercase">{{ item?.milestone ?? '' }}</td>
            <td style="text-align: start">{{ item?.startDate ?? '' }}</td>
            <td style="text-align: start">{{ item?.endDate ?? '' }}</td>
            <td style="text-align: start">{{ item?.allowedSaleDate ?? '' }}</td>
            <td style="text-align: start">{{ item?.bondTotal ?? '' }}</td>
            <td style="text-align: start">{{ item?.phaseString ?? '' }}</td>
            <td style="text-align: start">{{ item?.amountSold ?? '' }}</td>
            <td style="text-align: start">{{ item?.amountBalance ?? '' }}</td>
            <td style="text-align: start">{{ item?.updatedAt ?? '' }}</td>
            <td>
              <div style="display: flex; align-items: center; gap: 8px; justify-content: center">
                <button class="btn btn-icon btn-xs btn-outline-success ml-2" @click="getBondDetail(item)" title="Xem chi tiết">
                  <i class="far fa-eye"></i>
                </button>
                <button class="btn btn-icon btn-xs btn-outline-primary ml-2" @click="getBondHistory(item)" title="Lịch sử">
                  <i class="fas fa-history"></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
          <tbody v-if="!loading && listBondManagement?.length === 0">
          <tr style="word-break: break-word; white-space: nowrap; position: relative">
            <label style="position: absolute; left: 50%; translate: -50%; top: 10px">Chưa có dữ liệu</label>
          </tr>
          </tbody>
        </table>
        <div v-if="listBondManagement?.length > 0">
          <div class="edu-paginate mx-auto text-center mt-10 mb-5"
               style="display: flex; justify-content: center; align-items: center; height: 50px">
            <paginate
                v-model="page"
                :page-count="last_page"
                :page-range="3"
                :margin-pages="1"
                :click-handler="clickCallback"
                :prev-text="'Trước'"
                :next-text="'Sau'"
                :container-class="'pagination b-pagination'"
                :pageLinkClass="'page-link'"
                :next-link-class="'next-link-item'"
                :prev-link-class="'prev-link-item'"
                :prev-class="'page-link'"
                :next-class="'page-link'"
                :page-class="'page-item'"
            >
            </paginate>
          </div>
          <div class="w-100" style="display: flex; justify-content: end; margin-top: -50px">
            Tổng số bản ghi: <span style="color: #0066FF; font-weight: 600; margin-left: 5px;">{{ this.total }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        v-if="isDetail"
        title="Chi tiết tích lũy trái phiếu"
        :visible.sync="isDetail"
        width="30%"
        :before-close="handleCloseDetail">
      <div  v-loading="loadingDetail">
        <BondDetailModal v-if="!loadingDetail" :detail="bondDetail"/>
      </div>
    </el-dialog>
    <el-dialog
        v-if="isHistory"
        title="Lịch sử trái phiếu"
        :visible.sync="isHistory"
        width="60%"
        :before-close="handleCloseHistory">
      <BondHistoryModal :record="userHistory"/>
    </el-dialog>
  </div>
</template>

<script>
import HeaderBondManagement from "@/view/pages/baseoffice/bonds-management/component/HeaderBondManagement.vue";
import {stageOption} from "@/common/define";
import {GET_BOND_DETAIL, GET_LIST_BOND_MANAGEMENT} from "@/core/services/store/baseoffice/bondManagement";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {BondModel} from "@/view/pages/baseoffice/bonds-management/model/bondModel";
import BondDetailModal from "@/view/pages/baseoffice/bonds-management/component/BondDetailModal.vue";
import BondHistoryModal from "@/view/pages/baseoffice/bonds-management/component/BondHistoryModal.vue";

export default {
  name: 'BondManagementList',
  components: {BondHistoryModal, BondDetailModal, HeaderBondManagement},
  data() {
    return {
      stageOption: stageOption,
      loading: true,
      loadingSearch: false,
      loadingDetail: true,
      loadingHistory: true,
      query: {
        keyword: null,
        phase: null
      },
      page: 1,
      last_page: 1,
      total: 0,
      listBondManagement: [],
      isDetail: false,
      bondDetail: [],
      bondHistory: [],
      isHistory: false,
      userHistory: null,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý", icon: 'far fa-info-square'},
      {title: "Quản lý trái phiếu", icon: 'fas fa-list'}
    ]);
    this.getListBondManagement();
  },
  methods: {
    async clickCallback(obj) {
      this.page = obj;
      await this.pullParams();
      await this.getListBondManagement()
    },
    pullParams() {
      this.query.keyword = this.$route.query.keyword ?? null
      this.query.phase = this.getPhase(this.$route.query.phase)
    },
    getPhase(phase) {
      if (phase === "0") {
        return 0
      } else if (phase === null || phase === undefined || phase === "") {
        return null
      } else {
        return phase
      }
    },
    async pushParams() {
      await this.$router.push({
        name: 'BondManagementList',
        query: {
          keyword: this.query.keyword ?? null,
          phase: this.query.phase && typeof this.query.phase !== 'string' || this.query.phase == 0 ? this.query.phase : null
        },
        replace: true
      }).catch((e) => {
        console.log(e)
      })
    },
    async searchBond() {
      this.page = 1
      this.loadingSearch = true
      await this.pushParams();
      await this.getListBondManagement()
    },
    async getListBondManagement() {
      await this.pullParams()
      this.callApi()
    },
    callApi() {
      const payload = {
        page: this.page,
        keyword: this.query.keyword && this.query?.keyword?.length > 0 ? this.query.keyword : null,
        phase: this.query.phase && typeof this.query.phase !== 'string' || this.query.phase === 0 ? this.query.phase : null
      }
      this.$store.dispatch(GET_LIST_BOND_MANAGEMENT, payload).then(response => {
        if (!response.error) {
          this.page = response.pagination.current_page;
          this.total = response.pagination.total;
          this.last_page = response.pagination.last_page;
          this.listBondManagement = response.data?.map(item => {
            return new BondModel(item)
          })
        }
      }).catch(e => {
        console.log(e, 'Get list bonds management error!')
      }).finally(() => {
        this.loading = false
        this.loadingSearch = false
      })
    },
    getBondDetail(item) {
      this.isDetail = true
      const payload = {
        milestone: item.milestone,
        user_id: item?.userId,
      }
      this.$store.dispatch(GET_BOND_DETAIL, payload).then(res => {
        if (!res?.error) {
          this.loadingDetail = false
          this.bondDetail = res.data?.items
        }
      })
    },
    handleCloseDetail() {
      this.isDetail = false
      this.loadingDetail = true
    },
    getBondHistory(item) {
      this.userHistory = item
      this.isHistory = true
    },
    handleCloseHistory() {
      this.isHistory = false
      this.loadingHistory = true
    },
  }
}
</script>

<style lang="css" scoped>
  .display-flex__column {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
</style>