export const CUSTOMER_TYPES = [
    {label: 'Khách hàng', value: 1},
    {label: 'Nhân sự', value: 2},
    {label: 'Tất cả', value: 3},
];

export const COEFFICIENT_TYPES = [
    {label: 'Tại điểm', value: 1},
    {label: 'Khác điểm', value: 2},
    {label: 'Tất cả', value: 3},
];
