<script>
import {
  CHANGE_STATUS_SCHOOL,
  DELETE_SCHOOL, DETAIL_SCHOOL,
  GET_LIST_SCHOOL
} from "@/core/services/store/school-link-source/school-link-source.module";
import {StatusSchool} from "@/core/option/schoolLinkSource";
import {GET_PROVINCES} from "@/core/services/store/public/provinces.module";
import ModalAddSchool from "@/view/pages/school-link-source/component/ModalAddSchool.vue";
import ModalEditSchool from "@/view/pages/school-link-source/component/ModalEditSchool.vue";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";


export default {
  name: "ListSchool",
  components: {ModalEditSchool, ModalAddSchool},
  data() {
    return {
      listSchool: [],
      operate: 1,
      shutDown: 0,
      page: 1,
      last_page: 0,
      total: 0,
      nameSchool: null,
      provinceId: null,
      status: null,
      statusSchool: StatusSchool,
      provinces: [],
      loading: false,
      idSchool:null,
      schoolCurrent: null
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý trường liên kết"},
    ]);
    this.getListSchool();
    this.getProvinces()
  },
  methods: {
    getListSchool(isSearch = false) {
      this.loading = true;
      let payload = {
        name: this.nameSchool,
        province_id: this.provinceId,
        status: this.status,
        page: isSearch?1:this.page
      }
      this.$store.dispatch(GET_LIST_SCHOOL, payload).then((res) => {
        this.listSchool = res?.data.map((school) => {
          return {
            idSchool: school.id,
            nameSchool: school.name,
            area: school.province,
            coefficient: school.business_coefficient,
            status: school.status,
            check_voucher_ongoing: school.check_voucher_ongoing,
            check_delete: school.check_delete,
            school_rate: school.school_rate,
            source: school.source
          }
        })
        this.total = res.pagination.total;
        this.page = res.pagination.current_page;
        this.last_page = res.pagination.last_page;
      }).finally(() => {
        this.loading = false;
      })
    },
    clickCallback(obj) {
      this.page = obj;
      this.getListSchool();
    },
    getProvinces() {
      this.$store.dispatch(GET_PROVINCES).then((res) => {
        if (!res.error) {
          this.provinces = res.data;
        }
      });
    },
    openAddSchool() {
      this.$bvModal.show("Modal_Add_School")
    },
   async openEditSchool(school) {
     this.getDetailSchool(school.idSchool);
    },
    deleteSchool(nameSchool, id) {
      console.log(this.page);
      this.$confirm(`Bạn có chắc chắn muốn xóa trường ${nameSchool} không?`, 'Xoá trường', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
      }).then(() => {
        this.$store.dispatch(DELETE_SCHOOL, id).then((data) => {
          if (!data.error) {
            this.$notify({
              title: 'Thành công',
              message: 'Xóa trường liên kết thành công',
              type: 'success'
            });
            console.log(this.page)
            this.getListSchool()
          } else {
            this.$notify.error({
              title: 'Đã có lỗi xẩy ra',
              message: `${data.message}`
            });
          }

        }).catch((e) => {
          this.$notify.error({
            title: 'Đã có lỗi xẩy ra',
            message: `${e.message}`
          });
        })
      }).catch(() => {
      });
    },
    changeStatusSchool(nameSchool, id,status){
      this.$confirm(`Bạn có chắc chắn muốn ${status==='Vận hành'?'Ngừng liên kết':'Liên kết'} trường ${nameSchool} không?`, `${status==='Vận hành'?'Ngừng liên kết':'Liên kết'} trường`, {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
      }).then(() => {
        let payload = {
          id: id,
          status: status === 'Vận hành'?1:0
        }
        this.$store.dispatch(CHANGE_STATUS_SCHOOL, payload).then((data) => {
          if (!data.error) {
            this.$notify({
              title: 'Thành công',
              message: `${status==='Vận hành'?'Ngừng liên kết':'Liên kết'} trường thành công`,
              type: 'success'
            });
            this.getListSchool()
          } else {
            this.$notify.error({
              title: 'Đã có lỗi xẩy ra',
              message: `${data.message}`
            });
          }

        }).catch((e) => {
          this.$notify.error({
            title: 'Đã có lỗi xẩy ra',
            message: `${e.message}`
          });
        })
      }).catch(() => {
      });
    },
    getDetailSchool(id){
      this.$store.dispatch(DETAIL_SCHOOL,id).then((data)=>{
        this.schoolCurrent = data.data;
        setTimeout(()=>{
          this.$bvModal.show("Modal_Edit_School");
        },600)

      })
    }
  },

}
</script>

<template>
  <div class="card card-custom gutter-b h-100">
    <!--    header-->
    <div class="card-header">
      <div class="card-title">
        Quản lý trường liên kết
      </div>
      <div class="card-title">
        <button class="button-75 mr-4"
                style="background-color: #0066FF;color: white;min-width:94px;padding: 5px ;border-radius: 5px">
          <span>Xuất excel</span>
        </button>
        <button class="button-75"
                style="background-color: #1BC5BD;color: white;min-width:120px;padding: 5px ;border-radius: 5px"
                @click="openAddSchool()">
          <span>Thêm trường</span>
        </button>
      </div>
    </div>
    <!--    body-->
    <div class="card-body">
      <div class="row">
        <div class="col-md-2 col-sm-12 mb-3">
          <h4 style="font-size: 13px">Trường</h4>
          <el-input placeholder="Nhập tên, mã trường" v-model="nameSchool"></el-input>
        </div>
        <div class="col-md-2 col-sm-12 mb-3">
          <h4 style="font-size: 13px">Khu vực</h4>
          <el-select v-model="provinceId" placeholder="Chọn khu vực" clearable filterable>
            <el-option
                v-for="item in provinces"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-2 col-sm-12 mb-3">
          <h4 style="font-size: 13px">Trạng thái</h4>
          <el-select v-model="status" placeholder="Chọn trạng thái" clearable>
            <el-option
                v-for="item in statusSchool"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-2 col-sm-12 mb-3">
          <button class="button-75 mr-4"
                  style="background-color: #0066FF;color: white;min-width:94px;padding: 5px ;border-radius: 5px;height: 38px;margin-top:22px"
                  @click="getListSchool(true)">
            <span>Tìm kiếm</span>
          </button>
        </div>
      </div>
      <!--      table-->
      <div class="mt-10 table-responsive b-table-sticky-header" style="height: 480px">
        <table class="table table-bordered  table-vertical-center b-table ">
          <thead class="thead-light">
          <tr style="background-color: #F8F8F8 ; font-size: 13px">
            <th>Mã trường</th>
            <th>Tên trường</th>
            <th>Khu vực</th>
            <th>Hệ số đội kinh doanh</th>
            <th>Phần trăm nhà trường</th>
            <th>Nguồn trích % nhà trường</th>
            <th>Trạng thái</th>
            <th class="text-center">Hành động</th>
          </tr>
          </thead>
          <tbody v-if="loading">
          <tr>
            <td colspan="6" class="text-center">
              <div>
                <img src="../../../assets/icons/Book.gif" alt="" style="width: 40px">
                <span style="display: block">Đang tải</span>
              </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else-if="listSchool.length === 0">
          <tr>
            <td colspan="6" class="text-center">
              <div>
                Không có dữ liệu.
              </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else-if="listSchool.length !== 0">
          <tr v-for="(school,index) in listSchool" :key="index">
            <td>
              {{ school.idSchool }}
            </td>
            <td>
              {{ school.nameSchool }}
            </td>
            <td>
              {{ school.area }}
            </td>
            <td>
              {{ school.coefficient}}
            </td>
            <td>{{ school.school_rate ?? 0 }} %</td>
            <td>{{ school.source == 2 ? 'Đội kinh doanh' : (school.source == 1 ? 'Voucher' : '') }}</td>
            <td>
              <span :class="{'status-success': school.status==='Vận hành','status-error':school.status!=='Vận hành'}"
                    style="font-size: 13px">{{ school.status }}</span>
            </td>
            <td class="text-center">
              <button class="btn btn-xs btn-icon mr-2 btn-outline-warning mr-top-3 "
                      title="Sửa" @click="openEditSchool(school)">
                <i class="flaticon2-pen" ></i>
              </button>
              <button
                  v-if="!school.check_voucher_ongoing"
                  class=" btn btn-xs btn-icon mr-2 btn-outline-danger "
                  :title="school.status==='Vận hành'?'Ngừng liên kết':'Liên kết'"
                  :class="{'stop_linking':school.status==='Vận hành','linking':school.status!=='Vận hành'}"
                  @click="changeStatusSchool(school.nameSchool,school.idSchool,school.status)"
              >
                <img v-if="school.status!=='Vận hành'" src="../../../assets/img/LienKet.jpg" alt="">
                <img v-if="school.status==='Vận hành'" src="../../../assets/img/NgungLienKet.png" alt="">

              </button>
              <button
                  v-if="school.check_delete"
                  class="btn btn-xs btn-icon mr-2 btn-outline-danger "
                  title="Xóa"
                  @click="deleteSchool(school.nameSchool,school.idSchool)"
              >
                <i class="fas fa-trash" ></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mb-0 d-flex flex-row-reverse mr-10">
      <p class="mb-0">Tổng số bản ghi: <span class="text-primary ">{{ total }}</span></p>
    </div>
    <div class="edu-paginate mx-auto text-center mt-10 ">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
    <modal-add-school :provinces="provinces" @refreshData="getListSchool"></modal-add-school>
    <modal-edit-school :provinces="provinces" @refreshData="getListSchool" :schoolCurrent = "schoolCurrent"></modal-edit-school>
  </div>
</template>

<style scoped>
.stop_linking{
  border-color: #001A72;

}
.stop_linking:hover,
.stop_linking:focus {
  background-color: white!important;
  border-color: #001A72!important;
}
.linking{
  border-color: #00800D;
}
.linking:hover,
.linking:focus {
  background-color: white!important;
  border-color: #00800D!important;
}

.status-success {
  background: #00800D;
  padding: 5px;
  border-radius: 4px;
  color: white;
  font-size: 13px;
}
.status-error {
  background: #6F6F6F;
  padding: 5px;
  border-radius: 4px;
  color: white;
  font-size: 13px;
}

.button-75 span {
  font-size: 14px;
  cursor: pointer;
  transition: font-size 1s;
}

.button-75:hover span {
  font-size: 12px;
  opacity: .75;
}
</style>