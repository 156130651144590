export const banks = [
    {
        id: 'Techcombank',
        name: 'NH TMCP Ky Thuong (Techcombank)',
    }, {
        id: 'BIDV',
        name: 'NH Dau Tu va Phat Trien (BIDV)',
    }, {
        id: 'Agribank',
        name: 'NH Nong Nghiep va Phat Trien Nong Thon (AGRIBANK)',
    }, {
        id: 'MBBank',
        name: 'NH TMCP Quan Doi (MB)',
    }, {
        id: 'Vietinbank',
        name: 'NH Cong thuong (VietinBank)',
    }, {
        id: 'SHB',
        name: 'NH TMCP Sai Gon - Ha Noi (SHB)',
    }, {
        id: 'ACBBank',
        name: 'NH TMCP A Chau (ACB)',
    }, {
        id: 'TPBank',
        name: 'NH TMCP Tien Phong',
    }, {
        id: 'VPBank',
        name: 'NH TMCP Viet Nam Thinh Vuong (VPBANK)',
    }, {
        id: 'Sacombank',
        name: 'NH TMCP Sai Gon Thuong Tin (Sacombank)',
    }, {
        id: 'VIBBank',
        name: 'NH TMCP Quoc Te (VIB BANK)',
    }, {
        id: 'VCB',
        name: 'NH Ngoai Thuong (VCB)',
    },
]



