<template>
	<div class="task-manager">
		<div class="card card-custom gutter-b">
			<div class="card-header">
				<div class="card-title">
					Danh sách tác vụ
				</div>
				<div class="card-title card-action mt-5 row">
					<test-input-create-modal class="ml-5" @create-success="getTestInputSchedule"></test-input-create-modal>
				</div>
			</div>
			<div class="card-body pb-0">
				<div class="example mb-10">
					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<label>Khách hàng</label>
								<el-input
									@change="pushParamForUrl"
									v-model="query.customer_id"
									placeholder="Nhập tên, số điện thoại"
									clearable>
								</el-input>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>Mã tác vụ</label>
								<el-input
									@change="pushParamForUrl"
									v-model="query.id"
									placeholder="Nhập mã tác vụ"
									clearable>
								</el-input>
							</div>
						</div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Loại tác vụ</label>
                <el-select @change="pushParamForUrl"
                           v-model="query.type_task"
                           clearable
                           collapse-tags
                           placeholder="Loại tác vụ"
                >
                  <el-option
                      v-for="item in type_task"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
						<div class="form-group col-md-3">
							<div class="form-group">
								<label>Trạng thái</label>
								<el-select @change="pushParamForUrl"
													 v-model="query.status"
													 clearable
													 multiple
													 collapse-tags
													 placeholder="Trạng thái"
								>
									<el-option
										v-for="item in STATUS_ITEM"
										:key="item.id"
										:label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>Thời gian bắt đầu</label>
								<el-date-picker
									@input="changeDate()"
									v-model="query.schedule"
									type="daterange"
									format="dd-MM-yyyy"
									start-placeholder="Ngày bắt đầu"
									end-placeholder="Ngày kết thúc"
								/>
							</div>
						</div>
						<div class="form-group col-md-3 mt-8 ml-4">
							<div class="form-group">
								<button class="btn btn-primary"
												@click="search"
								><i v-if="is_loading_search" class="el-icon-loading"></i>Tìm kiếm
								</button>
							</div>
						</div>
					</div>
					<div class="example-preview table-responsive">
						<table class="table table-vertical-center table-hover">
							<thead>
							<tr>
								<th>Mã tác vụ</th>
								<th>Tác vụ</th>
								<th>Đối tượng xử lý</th>
								<th>Trạng thái</th>
								<th>Thời gian bắt đầu</th>
								<th>Thời gian kết thúc</th>
								<th class="text-center">Hành động</th>
							</tr>
							</thead>
							<tbody v-if="tasks.length > 0">
							<tr v-for="(item, key) in tasks" :key="key">
								<td>{{ item.id }}</td>
								<td>
                  <span :class="item?.nameTypeAndColor?.color">
                    {{item?.nameTypeAndColor?.name}}
                  </span>

								</td>
								<td>
                  <p v-if="item.type_task === test_dau_vao" class="mb-0">{{ item.phone ?? '' }} - {{ item.name ?? '' }}</p>
                  <p v-else class="mb-0">{{item?.processing_object}}</p>
                  </td>
								<td>
                  <div >
                    <span :class="STATUS_ITEM_DEFINE_CSS[item.status]" >{{ STATUS_ITEM_DEFINE[item.status] }}</span>
                  </div>
								</td>
								<td>{{ item.schedule | formatDateTime }}</td>
								<td>{{ item.end_schedule | formatDateTime }}</td>
								<td class="d-flex justify-content-center">
									<button v-if="item.type_task === test_dau_vao" title="Xem chi tiết"
													class="btn btn-xs btn-icon btn-outline-primary "
													@click="detailTask(item.id)"
									>
										<i class="fa fa-eye "></i>
									</button>
                  <button v-if="item.type_task !== test_dau_vao && item.status !== STATUS_DONE " title="Hoàn thành"
                          class="btn btn-xs btn-icon btn-outline-success"
                          @click="handleOpenModalConfirm(item)"
                  >
                    <i class="el-icon-check"></i>
                  </button>
								</td>
							</tr>
							</tbody>
							<tbody v-else>
							<tr>
								<td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="mb-0 d-flex flex-row-reverse mr-10">
				<p class="mb-0">Tổng số bản ghi: <span class="text-primary ">{{ totalTasks }}</span></p>
			</div>
			<div class="edu-paginate mx-auto text-center ">
				<paginate
					v-model="page"
					:page-count="last_page"
					:page-range="3"
					:margin-pages="1"
					:click-handler="clickCallback"
					:prev-text="'Trước'"
					:next-text="'Sau'"
					:container-class="'pagination b-pagination'"
					:pageLinkClass="'page-link'"
					:next-link-class="'next-link-item'"
					:prev-link-class="'prev-link-item'"
					:prev-class="'page-link'"
					:next-class="'page-link'"
					:page-class="'page-item'"
				>
				</paginate>

			</div>
		</div>
		<test-input-edit-modal class="ml-5"
													 @create-success="getTestInputSchedule"
													 :schedule_prop="task_edit">
		</test-input-edit-modal>
    <el-dialog
        title="Hoàn thành tác vụ"
        :visible.sync="openModalConfirm"
        width="30%"
        >
      <p>Thao tác sẽ chuyển trạng thái của tác vụ {{currentTask?.nameTypeAndColor?.name}} (ID {{currentTask?.id}}).</p>
      <p>Bạn chắc chắn muốn Tiếp tục tác vụ này chứ?</p>
      <span slot="footer" class="dialog-footer">
    <el-button @click="openModalConfirm = false">Đóng</el-button>
    <el-button type="primary" @click="confirmDoneTask">Hoàn thành</el-button>
  </span>
    </el-dialog>
	</div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  TEST_INPUT_DELETE_TASK, TEST_INPUT_GET_TEST_INPUT_ONLY,
  TEST_INPUT_GET_TESTER_BY_USER_ID, UPDATE_STATUS_DONE_TEST_INPUT_ITEM
} from "@/core/services/store/test/testInput.module";
import {
  COI_THI,
  GIA_SU, OFFLINE, ONLINE,
  STATUS, STATUS_DONE,
  STATUS_ITEM, STATUS_ITEM_DEFINE, STATUS_ITEM_DEFINE_CSS, TAC_VU_KHAC, TEST_DAU_VAO,
  TYPE_TASK,
  TYPE_TASK_ITEM_DEFINE,
  TYPE_TASK_ITEM_DEFINE_CSS, TYPE_TASK_ITEM_GENERAL, TYPE_TASK_ITEM_SPEAKING
} from "@/core/option/testInputOption";
import moment from "moment-timezone";
import TaskManagerCalendar from "@/view/pages/test-input/components/TaskManagerCalendar";
import {daysOfWeek} from "@/core/option/dayOption";
import TaskCreateModal from "@/view/pages/test-input/components/TaskCreateModal";
import TaskEditModal from "@/view/pages/test-input/components/TaskEditModal";
import Swal from "sweetalert2";
import TaskImportExcelModal from "@/view/pages/test-input/components/TaskImportExcelModal";
import TestInputCreateModal from "@/view/pages/test-input/components/TestInputCreateModal";
import TestInputEditModal from "@/view/pages/test-input/components/TestInputEditModal";
import router from "@/router";


export default {
	name: "TaskManagerPersonalIndex",
	components: {
		TestInputEditModal,
		TestInputCreateModal, TaskImportExcelModal, TaskEditModal, TaskCreateModal, TaskManagerCalendar
	},
	data() {
		return {
			last_page: 1,
			page: 1,
			show_calendar: false,
			is_loading_search: false,
			is_loading_download_file: false,
			search_loading: false,
			tasks: [],
			status: STATUS,
			STATUS_ITEM: STATUS_ITEM,
			STATUS_ITEM_DEFINE: STATUS_ITEM_DEFINE,
			STATUS_ITEM_DEFINE_CSS: STATUS_ITEM_DEFINE_CSS,
			TYPE_TASK_DEFINE: TYPE_TASK_ITEM_DEFINE,
			TYPE_TASK_DEFINE_CSS: TYPE_TASK_ITEM_DEFINE_CSS,
			query: {
				id: '',
				customer_id: '',
				schedule: [],
				status: '',
        type_task: null
			},
			type_task: TYPE_TASK,
			testers: [],
			daysOfWeek: daysOfWeek,
			task_edit: null,
			totalTasks: 0,
			test_dau_vao: TEST_DAU_VAO,
      GIA_SU:GIA_SU,
      COI_THI:COI_THI,
      TAC_VU_KHAC:TAC_VU_KHAC,
      ONLINE:ONLINE,
      OFFLINE: OFFLINE,
      STATUS_DONE: STATUS_DONE,
      openModalConfirm: false,
      currentTask: null

		}
	},
	computed: {
		dateRange() {
			let startWeek = moment(this.query.date_week);
			let endWeek = startWeek.clone().endOf('week');
			return startWeek.format('DD/MM/YYYY') + ' - ' + endWeek.format('DD/MM/YYYY')
		},
		days() {
			let result = [];
			let startWeek = moment(this.query.date_week);
			for (let i = 0; i < 7; i++) {
				let tomorrow = startWeek.clone().add(i, 'days');
				result.push({
					day: tomorrow.clone().format('DD-MM-YYYY'),
					dayOfWeek: this.daysOfWeek[tomorrow.clone().day()]
				})
			}
			return result;
		}
	},
	created() {

		this.$store.dispatch(SET_BREADCRUMB, [
			{title: "Danh sách tác vụ", route: 'task-manager'},
			{title: "Danh sách tác vụ"}
		]);
	},
  async mounted() {
     await this.pullParamFromUrl();
      this.getTestInputSchedule();
	},
	methods: {
		pullParamFromUrl() {
			this.query.id = this.$route.query.id ? this.$route.query.id : '';
			this.query.customer_id = this.$route.query.customer_id ? this.$route.query.customer_id : '';
			this.query.schedule = this.$route.query.schedule ? [moment(this.$route.query.schedule[0]).format('YYYY-MM-DD'), moment(this.$route.query.schedule[1]).format('YYYY-MM-DD')] : ''
			if (typeof this.$route.query.status == 'string') {
				let status = Array.from(this.$route.query.status);
				this.query.status = status.length ? status.map(str => parseInt(str, 10)) : [];
			} else {
				this.query.status = this.$route.query.status !== undefined && this.$route.query.status.length ? this.$route.query.status.map(str => parseInt(str, 10)) : [];
			}
      this.query.type_task = this.$route.query.type_task?+this.$route.query.type_task:null;
      return null
		},
		pushParamForUrl() {
			this.$router.push({
				name: 'task-manager-personal',
				query: this.query
			})
		},
		changeDate() {
			this.pushParamForUrl();
			this.query.schedule = this.query.schedule.map((date) => moment(date).format("YYYY-MM-DD"))
		},
		detailTask(id) {
			router.push({name: 'task-details', params: {id: id}, query: {flag: true}})
		},
		editTask(item) {
			this.task_edit = item;
		},
		clickCallback(obj) {
			this.page = obj;
			this.getTestInputSchedule();
		},
		remoteMethod(query) {
			this.search_loading = true;
			this.$store.dispatch(TEST_INPUT_GET_TESTER_BY_USER_ID, {
				keyword: query
			}).then((data) => {
				this.search_loading = false;
				this.testers = data.data;
			})

			if (query !== '') {
				this.search_loading = true;
				setTimeout(() => {
					this.search_loading = false;
					this.options = this.list.filter(item => {
						return item.label.toLowerCase()
							.indexOf(query.toLowerCase()) > -1;
					});
				}, 200);
			} else {
				this.options = [];
			}
		},
		search() {
			this.page = 1;
			this.is_loading_search = true;
			this.getTestInputSchedule();
		},
		getTestInputSchedule() {
			let payload = {
				...this.query,
				schedule: this.query.schedule ?? '',
				page: this.page
			}
			this.$store.dispatch(TEST_INPUT_GET_TEST_INPUT_ONLY, payload).then((data) => {
				this.tasks = data?.data?.map(e=>{
        return {...e,nameTypeAndColor: this.checkTypeTask(e?.type,e?.type_item_id)}
        })
				this.totalTasks = data?.pagination?.total ?? 0;
				this.last_page = data?.pagination?.last_page ?? 1;
			}).finally(() => {
				this.is_loading_search = false;
			});
		},
		getScheduleEnd(schedule) {
			return moment(schedule).add(30, 'minutes').format('DD-MM-YYYY HH:mm:ss')
		},
		deleteTask(id) {
			Swal.fire({
				title: "Bạn chắc chắn muốn xóa?",
				showDenyButton: true,
				showCancelButton: true,
				confirmButtonText: "Đồng ý",
				cancelButtonText: "Không",
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire("Xóa", "", "success");
					this.$store.dispatch(TEST_INPUT_DELETE_TASK, id).then((res) => {
						if (res.error) {
							this.$bvToast.toast(res.message, {
								title: 'Xóa',
								variant: 'danger',
								solid: true
							});
						} else {
							this.getTestInputSchedule();
							this.$bvToast.toast(res.message, {
								title: 'Xóa',
								variant: 'success',
								solid: true
							});
							this.getList()
						}
					});
				}
			});
		},
    checkTypeTask(type,typeTask){
      if (typeTask === TYPE_TASK_ITEM_GENERAL){
        return {
          name: this.TYPE_TASK_DEFINE[typeTask] ,
          color: this.TYPE_TASK_DEFINE_CSS[typeTask]
        }
      }
      if(typeTask === TYPE_TASK_ITEM_SPEAKING){
        return {
          name: type===ONLINE?'Test Speaking Online':'Test Speaking Offline' ,
          color: type===ONLINE?'badge badge-primary':'badge badge-warning'
        }
      }
      return {
        name: this.TYPE_TASK_DEFINE[typeTask] ,
        color: 'badge badge-danger'
      }
    },
    handleOpenModalConfirm(task){
      this.currentTask = task;
      setTimeout(()=>{
        this.openModalConfirm = true;
      })
    },
    confirmDoneTask(){
      this.$store.dispatch(UPDATE_STATUS_DONE_TEST_INPUT_ITEM,{id:this.currentTask.id}).then(()=>{
        this.$notify({
          title: 'Thành công',
          message: 'Đã hoàn thành',
          type: 'success'
        });
        this.getTestInputSchedule();
        this.openModalConfirm = false;
      }).catch(()=>{
        this.$notify.error({
          title: 'Error',
          message: 'Đã có lỗi xẩy ra'
        });
      })
    }
	}
}
</script>

<style scoped>
.el-input {
	width: 100% !important;
}

.el-select {
	width: 100%;
}
</style>