import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const GET_DEPARTMENT = "get_department";
export const GET_DEPARTMENT_DEPARTMENT_TAKE_CARE_STUDENT = "get-department-take-care-student";


const state = {};

const getters = {};

const actions = {
    [GET_DEPARTMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query("departments", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    [GET_DEPARTMENT_DEPARTMENT_TAKE_CARE_STUDENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_DEPARTMENT_DEPARTMENT_TAKE_CARE_STUDENT, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    }
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
