<template>
  <div style="display: flex; justify-content: space-between; align-items: center; padding: 15px 20px">
    <h4 style="margin-bottom: 0;">Quản lý tác vụ điều phối xếp lớp</h4>
    <div class="">
      <el-button v-if="currentUser.user.permission.includes(permission.EXPORT_TAC_VU_DIEU_PHOI_LOP_HOC)" type="primary" class="text-white" @click="selectTypeExport = true">
        <i class="el-icon-download text-white"></i> <span>Export</span>
      </el-button>
      <el-button v-if="currentUser.user.permission.includes(permission.THEM_MOI_TAC_VU_DIEU_PHOI_LOP_HOC)" type="success" class="text-white" @click="handleCreateTask">
        <i class="el-icon-plus text-white"></i> <span>Thêm mới</span>
      </el-button>
    </div>
    <el-dialog title="Vui lòng chọn dữ liệu để tải xuống" :show-close="false" :visible.sync="selectTypeExport" v-if="selectTypeExport">
      <hr style="padding: 0; margin-top: -20px"/>
      <div style="display: flex; flex-direction: column; gap: 5px">
        <el-radio v-model="isLog" label="2">Danh sách tác vụ</el-radio>
        <el-radio v-model="isLog" label="1">Danh sách ghi chú cuộc gọi của lịch sử xử lý</el-radio>
      </div>
      <div style="display: flex; justify-content: end; gap: 5px; margin-top: 10px">
        <el-button @click="selectTypeExport = false">Đóng</el-button>
        <el-button style="background: #5E00B6; color: white; padding-left: 15px; padding-right: 15px; border-radius: 4px" @click="exportExcelTaskManagement" :disabled="is_export_excel"><i class="el-icon-download text-white"></i> Export</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {exportExcel} from "@/core/filters";
import {EXPORT_TASK_MANAGEMENT} from "@/core/services/store/task-management/task-management.module";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      isLog: '2',
      selectTypeExport: false,
      is_export_excel: false,
    }
  },
  props: {
    query: {
      type: Object,
      required: true,
      default: {}
    }
  },
  methods: {
    exportExcelTaskManagement() {
      this.is_export_excel = true
      const HEADER_ROW_EXPORT_TASK_MANAGEMENT_LIST = [
        {
          key: 'id',
          value: 'Mã tác vụ',
        },
        {
          key: 'type',
          value: 'Loại tác vụ',
        },
        {
          key: 'user_id',
          value: 'Mã học viên',
        },
        {
          key: 'user_name',
          value: 'Họ tên khách hàng',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'status',
          value: 'Trạng thái',
        },
        {
          key: 'latest_result',
          value: 'Kết quả xử lý gần nhất',
        },
        {
          key: 'person_in_charge',
          value: 'Người phụ trách',
        },
        {
          key: 'created_at',
          value: 'Thời gian tạo',
        },
        {
          key: 'call_time_newest',
          value: 'Lịch gọi gần nhất',
        },
        {
          key: 'schedule_desired',
          value: 'Lịch học mong muốn',
        },
        {
          key: 'next_course',
          value: 'Khóa học tiếp theo',
        },
        {
          key:'note',
          value: 'Ghi chú gần nhất'
        }
      ]
      const HEADER_ROW_EXPORT_LOG = [
        {
          key: 'ticket_classroom_arrangement_id',
          value: 'Mã tác vụ',
        },
        {
          key: 'status_text',
          value: 'Kết quả xử lý',
        },
        {
          key: 'note',
          value: 'Ghi chú',
        },
        {
          key: 'created_at',
          value: 'Thời gian nhập',
        },
        {
          key: 'user_handle',
          value: 'Người nhập',
        },
      ]
      const payload = {
        log: this.isLog === '1' ? 1 : null,
        id: this.query.id ?? null,
        keyword: this.query.customer ?? null,
        status: this.query.status ? +this.query.status : null,
        result_lastest: this.query.resultLast ?? [],
        center_id: this.query.center ? this.query.center : null,
        branch_ids: this.query.branch,
        person_in_charge_ids: this.query.personInCharge,
        network: this.query.network ?? null,
        date_range_created: this.query.createdAt,
        date_range_call_time: this.query.callCalendar,
        type: this.query.typeTask,
        desired_schedule: this.query.schedule,
        course_id: this.query.course
      }
      this.$store.dispatch(EXPORT_TASK_MANAGEMENT, payload).then((res) => {
        this.is_export_excel = false
        this.selectTypeExport = false
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        } else {
          const data = this.isLog !== '1' ? res.data?.map(item => {
            return {
              id: item?.id,
              type: item?.type,
              user_id: item?.user_id + 100000,
              user_name: item?.user_name,
              phone: item?.phone,
              status: item?.status,
              latest_result: item?.latest_result,
              person_in_charge: item?.person_in_charge,
              created_at: item?.created_at,
              call_time_newest: item?.call_time_newest,
              schedule_desired: item?.schedule_desired,
              next_course: item?.next_course,
              note: item?.note
            }
          }) : res.data
          exportExcel(
              this.isLog !== '1' ? HEADER_ROW_EXPORT_TASK_MANAGEMENT_LIST : HEADER_ROW_EXPORT_LOG, data,
              this.isLog !== '1' ? "Danh sách tác vụ điều phối lớp học.xlsx" : "Danh sách ghi chú điều phối lớp học.xlsx",
              false)
        }
      });
    },
    handleCreateTask() {
      this.$emit('createTaskManagement');
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  }
}
</script>

<style lang="scss" scoped>

</style>