<template>
  <div class="row">
    <div class="col-md-2 ml-5">
      <div style="width:95px ; height:100%; position: relative; background-color: #3254CB "
           class="d-flex align-items-center justify-content-center"
      >
        <div class="mt-4 " style="width: fit-content; text-align: center !important;">
          <i class="el-icon-s-management " style="font-size: 30px; color: white"></i>
          <p class="mt-2" style="color: white ; font-size: 13px">Quà tặng</p>
        </div>
      </div>
    </div>
    <div class="col-md-6 mt-9 ml-10">
      <div><span>Tên : {{ gift.name }}</span></div>
      <div>
        <span v-if="gift.value" class="badge badge-primary">Giá trị quy đổi tiền mặt : {{ gift.value | toThousandFilter }} ₫</span>
        <span v-else class="badge badge-secondary">Không có giá trị quy đổi</span>
      </div>
      <a href="javascript:" @click="showPromotionDetail(gift)"><strong>Xem chi tiết</strong></a>
    </div>
    <div class="col-md-2 mt-5 mb-5" v-if="!meCheckSelectGift && !meCheckPickVoucherExchange">
      <button class="btn"
              style="background-color: #472F92; color: white ; width: 130px"
              @click="pickGift(gift)"
      >
        Nhận quà
      </button>
      <button class="btn mt-2"
              style="border-color: #472F92; color: #472F92; background-color: white;width: 130px"
              @click="pickPrice(gift)"
      >Quy đổi
        voucher
      </button>
    </div>
    <div v-else class="mt-8 ml-4">
      <button class="btn" style="background: #A70000;color: white;width: 130px;" @click="cancelGift(gift)">
        Hủy áp dụng
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListGiftItem",
  props: {
    giftSelect: {
      type: Object,
      default: () => {
        return null;
      }
    },
    listPromotions: {
      type: Array,
      default: () => {
        return [];
      }
    },
    gift: {
      type: Object,
      default: () => {
        return null
      }
    },
    id_promotion_disable: {
      type: [String, Number, Array],
      default: () => {
        return []
      }
    }
  },
  methods: {
    cancelGift(gift) {
      this.$emit('cancelGift', gift);
    },
    showPromotionDetail(gift) {
      this.$emit('showPromotionDetail', gift);
    },
    pickGift(gift) {
      this.$emit('pickGift', gift);
    },
    pickPrice(gift) {
      this.$emit('pickPrice', gift);
    },
  },
  computed: {
    meCheckSelectGift() {
      if (!this.giftSelect) {
        return false;
      }
      if (this.giftSelect.id === this.gift.id) {
        return true;
      }
      return false;
    },
    meCheckPickVoucherExchange() {
      const foundItem = this.listPromotions.find(item => item.id === this.gift.id);
      if (foundItem) {
        return true;
      }
      return false;
    }
  }
}
</script>

<style scoped>

</style>
