<template>
    <div>
        <div class="card card-custom gutter-b">
            <div class="card-header d-flex justify-content-between">
                <div class="card-title">
                    <h3 class="card-label">Lịch sử hoạt động</h3>
                </div>
            </div>
            <div class="card-body">
                <!--begin::Example-->
                <div class="example">
                    <div class="form-group">
                        <form class="form-inline">
                                <div role="group" class="input-group">
                                    <multiselect v-model="form.description" :options="actions"
                                                 :searchable="true"
                                                 :close-on-select="true"
                                                 :show-labels="true" placeholder="Chọn hành động"
                                                 @input="selectAction"
                                    ></multiselect>
                                </div>
                                <div role="group" class="input-group ml-4">
                                    <date-picker v-model="form.choose_date" valueType="format" placeholder="Chọn ngày tháng" @input="selectDate"></date-picker>
                                </div>
                    </form>
                    </div>
                    <div class="example-preview table-responsive">
                        <table class="table table-vertical-center">
                            <thead>
                            <tr>
                                <th scope="col">No</th>
                                <th scope="col">Hành Động</th>
                                <th scope="col">Đối tượng chỉnh sửa</th>
                                <th scope="col">Dữ liệu trước khi chỉnh sửa</th>
                                <th scope="col">Dữ liệu sau khi chỉnh sửa</th>
                                <th scope="col">Ngày chỉnh sửa</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in getUserActionLogData" :key="index">
                                <th scope="row">{{++index}}</th>
                                <td class="">{{item.description}}</td>
                                <td class="">{{item.subject_type.replace('App\\Models\\','')}}</td>
                                <td class=""><vue-json-pretty :path="'res'" :data="{ key: item.properties.old }"></vue-json-pretty></td>
                                <td class=""><vue-json-pretty :path="'res'" :data="{ key: item.properties.attributes }"></vue-json-pretty></td>
                                <td class="">{{item.created_at | formatDate}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="edu-paginate mx-auto text-center">
                <paginate
                        v-model="query.page"
                        :page-count="total_pages"
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="clickCallback"
                        :prev-text="'Trước'"
                        :next-text="'Sau'"
                        :container-class="'pagination b-pagination'"
                        :pageLinkClass="'page-link'"
                        :page-class="'page-item'"
                        :next-link-class="'next-link-item'"
                        :prev-link-class="'prev-link-item'"
                        :prev-class="'page-link'"
                        :next-class="'page-link'"
                >
                </paginate>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import VueJsonPretty from 'vue-json-pretty';
    import 'vue-json-pretty/lib/styles.css';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import Multiselect  from 'vue-multiselect';
    import Select2Ajax from "../../../content/Select2Ajax";
    import Select2 from "../../../content/Select2";
    import {GET_USER_LOG_ACTION_DATA, GET_USER_LOG_TIME_WORK} from "../../../../core/services/store/user/users.module";
    import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";
    const _ = require('lodash');

    export default {
        name: "Log-Action-History",
        data() {
            return {
                id : '',
                query : {
                    page: 1,
                    search: "",
                },
                form : {
                  description : '',
                  choose_date : '',
                },
                actions : ['created','updated','deleted'],
            }
        },
        components : {
            VueJsonPretty,DatePicker,Multiselect ,Select2Ajax,Select2
        },
        mounted() {
            this.loaded = false
            this.id = this.$route.params.id;
            this.query.page = parseInt(this.$route.query.page ? this.$route.query.page : 1);
            this.form.description = this.$route.query.description ? this.$route.query.description : '';
            this.form.choose_date = this.$route.query.choose_date ? this.$route.query.choose_date : '';
            this.$store.dispatch(GET_USER_LOG_TIME_WORK, this.id).then(() => {
                this.loaded = true;
            });
            this.$store.dispatch(GET_USER_LOG_ACTION_DATA, {
                id : this.id,
                params : {
                    page : this.query.page,
                    description : this.form.description,
                    choose_date : this.form.choose_date,

                }
            });
            this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Quản lý nhân sự",route: 'profile-1'},
                { title: "Lịch sử hành động" }
            ]);

        },
        computed : {
            ...mapGetters(['getUserActionLogData','getUserLogHistoriesPaginate']),
            total_pages() {
                return this.getUserLogHistoriesPaginate?.total_pages || 0;
            },
        },
        methods : {
            clickCallback(pageNum) {
                this.$router.push({path: '', query : {page : pageNum, description: this.form.description,choose_date : this.form.choose_date,}});
                this.$store.dispatch(GET_USER_LOG_ACTION_DATA, {
                    id : this.id,
                    params : {
                        page : this.query.page,
                        description : this.form.description,
                        choose_date : this.form.choose_date,
                    }
                })
            },
          getQuery: function () {
              this.$store.dispatch(GET_USER_LOG_ACTION_DATA,{
                id : this.id,
                params : this.form
              }).then(() => {
                  this.query.page = 1;
              });
          },
          selectAction : function () {
              this.$router.push({path: '', query : this.form});
              this.getQuery();
          },
          selectDate : function () {
                this.$router.push({path: '', query : this.form});
                this.getQuery();
          },
        },
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .multiselect {
        width: 214px;
    }
    .mx-input {
        height: 40px;
    }
    .table th, .table td {
        word-break: break-all;
    }
</style>