<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="course_create" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Sửa chính sách trụ sở tổng
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'mechanism-index' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Tiêu đề/Danh mục
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Tiêu đề, danh mục" rules="required"
                                    v-slot="{ errors,classes }">
                  <input type="text" ref="name" name="name" class="form-control"
                         v-model="mechanism.category"
                         placeholder="Tiêu đề, danh mục" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Số thứ tự
                  <span class="text-danger"></span></label>
                <input type="number" ref="name" name="name" class="form-control"
                       v-model="mechanism.stt"
                       placeholder="Số thứ tự">

              </div>
            </div>

          </div>
          <div class="row mb-4">
            <div class="col-md-6">
              <div class="checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input type="checkbox" name="Checkboxes5" v-model="mechanism.is_all">
                  <span></span>Tất cả thành viên Edutalk có thể xem</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="!mechanism.is_all">
            <div class="col-md-6">
              <div class="form-group">
                <label>Phân quyền xem
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Phân quyền xem" rules="required" v-slot="{ errors,classes }">
                  <multiselect v-model="cacheAccountType" :options="accountType"
                               placeholder="Loại tài khoản"
                               label="name"
                               track-by="name"
                               @input="updateStateAccountType"
                               :class="classes"
                               :hideSelected="true"
                               :multiple="true"
                               :close-on-select="false"
                  ></multiselect>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-4">
              <div class="checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input type="checkbox" name="Checkboxes5" v-model="mechanism.status">
                  <span></span>Hiển thị</label>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="form-group mb-1 col-md-12">
              <label>Nội dung
                <span class="text-danger">*</span></label>
              <ValidationProvider vid="content" name="Nội dung" rules="required"
                                  v-slot="{ errors,classes }">
                <ckeditor :editor="editor" v-model="mechanism.content"></ckeditor>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                      errors[0]
                    }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button
                type="submit"
                class="btn btn-success mr-2"
            >
              Cập nhật
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_MECHANISM_BY_ID, UPDATE_MECHANISM} from "../../../core/services/store/system/mechanism.module";
import {GET_ACCOUNT_TYPE} from "../../../core/services/store/user/account-type.module";
import Multiselect from 'vue-multiselect'
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
//editor
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";

export default {
  components: {
    Multiselect,
    DatePicker
  },
  name: "MechanismEdit",
  data() {
    return {
      //Editor
      editor: CustomEditor,
      // Check show
      is_show_course_type: false,
      is_show_level: false,
      mechanism: {
        content: '',
        type: '',
        status: true,
        publish: '',
        created_user: '',
        updated_user: '',
        category: '',
        stt: 1,
        id_parent: '',
        slug: '',
        start_date: '',
        end_date: '',
        is_all: false,
        type_system_information: '',
        link: '',
        items: '',
        roles: [],
        mechanism_type: 1,
      },

      accountType: [],

      // Cache
      cacheAccountType: [],
      money: {
        decimal: ',',
        thousands: ',',
        prefix: '',
        suffix: ' VNĐ',
        precision: 0,
        masked: false,
      },
    }
  },
  mounted() {
    this.getAccountType()
    this.getMechanismById()
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý chính sách trụ sở tổng", route: 'profile-1'},
      {title: "Sửa chính sách trụ sở tổng"}
    ]);
  },
  computed: {},
  methods: {
    getMechanismById() {
      let id = this.$route.params.id;
      this.$store.dispatch(GET_MECHANISM_BY_ID, id).then((res) => {
        if (!res.error) {
          this.mechanism = res.data
          this.setCacheAccountType(res.data)
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    setCacheAccountType(mechanism) {
      if (mechanism.account_type.length) {
        this.cacheAccountType = mechanism.account_type
      }
    },
    updateStateAccountType(obj) {
      this.mechanism.roles = []
      obj.map((v) => {
        this.mechanism.roles.push(v.id)
      })
    },
    getAccountType() {
      this.$store.dispatch(GET_ACCOUNT_TYPE, {
        limit: 1000,
      }).then((res) => {
        if (!res.error) {
          this.accountType = res.data.data
        }
      });
    },

    onSubmit() {
      this.$store.dispatch(UPDATE_MECHANISM, this.mechanism).then((res) => {
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Cập nhật',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$router.push({name: "mechanism-index"}).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Cập nhật',
              variant: 'success',
              solid: true
            });
          })
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      });
    },
  }
}
</script>
<style scoped>
.mx-datepicker {
  width: 100% !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
