<template>
  <div>
    <button class="btn btn-success font-weight-bold btn-sm" v-b-modal="`Modal_Import_Exam`">
      <i class="bi bi-box-arrow-up"></i>
      Import
    </button>
    <b-modal id="Modal_Import_Exam" title="Import đề thi" centered hide-footer ref="Modal_Import_Exam"
      @hidden="hideModal" size="lg">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent v-if="!tableError">
          <div>
            <div class="row import">
              <div class="col-md-12">
                <p>Bước 1: Tải file mẫu</p>
                <button type="button" class="btn btn-info btn-sm" @click="downloadFile()"><i
                    class="flaticon-download"></i>Tải
                  file mẫu
                </button>
              </div>
              <div class="col-md-12 mt-4">
                <p>Bước 2: Tải file excel lên hệ thống</p>
                <div class="file-input">
                  <ValidationProvider name="type_task" rules="required|max:30" v-slot="{ errors, classes }"
                    vid="type_task">
                    <input ref="fileExam" type="file" name="file-input-exam" id="file-input-exam"
                      class="file-input__input" @change="getDataFile" :disabled="disableImport"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                  </ValidationProvider>
                  <label class="file-input__label " for="file-input-exam"
                    :class="{ 'disable-import': !disableImport, 'on-disable-import': disableImport }">
                    <span><i class="flaticon-upload mr-2" style="color: #000000"></i>Tải lên</span></label>
                  <div v-if="fileDataImport">
                    <p class="badge badge-secondary  p-2 ">{{ fileDataImport.name }}</p>
                    <i class="fas fa-trash ml-3 text-danger  " style="font-size: 13px; cursor: pointer"
                      @click="deleteFileExcel()"></i>
                  </div>
                  <div v-else class="text-danger">
                    vui lòng chọn file
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex d-flex flex-row-reverse">
            <button type="button" class="btn btn-success ml-4" @click="previewImportExcel()" :disabled=disableSave>
              Xác nhận
            </button>
            <button type="button" class=" btn btn-secondary" @click="cancelModal()">Hủy</button>
          </div>
        </form>
        <div v-else>
          <p>Hệ thống phát hiện có lỗi trong quá trình import dữ liệu:</p>
          <div class="mt-5 table-responsive">
            <table class="table table-bordered  table-vertical-center ">
              <thead>
                <tr class="" style="background-color: #F8F8F8 ; font-size: 13px">
                  <th>ID</th>
                  <th>Câu hỏi</th>
                  <th>Lỗi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in errorQuestions">
                  <td>{{ item.Id }}</td>
                  <td>{{ item.Question }}</td>
                  <td>
                    <p class="text-danger" v-for="error in item.errors">
                      {{ error }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary" @click="comeback()">Quay lại</button>
          </div>
        </div>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import { utils, read } from "xlsx";
import { DOWNLOAD_EXAM, IMPORT_EXAM } from "@/core/services/store/tournament/tournament.module";

export default {
  name: "ModalImportExam",
  data() {
    return {
      fileDataImport: null,
      importData: null,
      disableImport: false,
      errorQuestions: [],
      tableError: false,
      disableSave: false
    }
  },
  methods: {
    hideModal() {
      this.fileDataImport = null
      this.importData = null
      this.disableImport = false
      this.errorQuestions = []
      this.tableError = false
      this.disableSave = false
    },
    downloadFile() {
      this.$store.dispatch(DOWNLOAD_EXAM).then(() => {

      }).catch(() => {

      })
    },
    async previewImportExcel() {
      if (this.importData == null) {
        return
      }
      this.errorQuestions = await this.checkErrorDataExel()
      this.disableSave = true
      if (this.errorQuestions.length > 0) {
        this.tableError = true
        return;
      }
      let payload = {
        id: this.$route.params.id,
        data: this.importData.map((data) => {
          return {
            exam_code: data.Code.toString(),
            question: data.Question.toString(),
            image: data.Image,
            answers: data.answers.map((e) => {
              return {
                answer: e.answer.toString(),
                is_correct: e.is_correct.toString()
              }
            })
          }
        })
      }
      this.$store.dispatch(IMPORT_EXAM, payload).then((data) => {
        this.$emit("getExam")
        this.$notify({
          title: 'Thành công',
          message: 'Import đề thi thành công, vui lòng chờ đợi trong giây lát và reload lại trang',
          type: 'success'
        });
        this.$refs['Modal_Import_Exam'].hide()
      }).catch(() => {
        this.$notify({
          title: 'Lỗi',
          message: 'Đã có lỗi xẩy ra',
          type: 'error'
        });
      }).finally(() => {
        this.disableSave = false
      })
    },
    comeback() {
      this.tableError = false
      this.disableSave = false
    },
    async getDataFile(e) {
      if (e.target.files.length) {
        this.disableImport = true
        this.fileDataImport = e.target.files[0]
        this.importData = await this.fileToJson(this.fileDataImport);
      }
    },
    cancelModal() {
      this.$refs['Modal_Import_Exam'].hide()
    },
    deleteFileExcel() {
      this.disableImport = false
      this.importData = null
      this.fileDataImport = null
      this.$refs.fileExam.value = null;
    },
    async checkErrorDataExel() {
      const errors = (await Promise.all(this.importData.map((question) => {
        return this.checkQuestionError(question);
      }))).filter(d => d.errors?.length > 0);

      return errors;
    },
    async checkQuestionError(question) {
      if (!question) {
        return question;
      }
      if (question.errors) {
        return { errors: [question.errors] }
      }
      let errors = [];
      let fieldErrors = ["Id", "Question", "Code", "answers"].map(d => {
        if (!question[d]) {
          return "Thiếu trường " + d;
        }
      }).filter((a) => a != undefined);
      errors = [...fieldErrors];
      if (!question.answers) {
        return { ...question, errors: errors };
      }
      let correctAnswers = question.answers.filter(d => d.is_correct == 1);
      if (correctAnswers.length == 0) {
        errors = [...errors, "Không có đáp án đúng"];
      }

      if (correctAnswers.length > 1) {
        errors = [...errors, "Thừa đáp án đúng"];
      } 
      const imageError = await this.checkImage(question.Image);
      console.log(imageError,"imageError");
      
      if (imageError) {
        errors = [...errors, imageError];
      }

      return { ...question, errors: errors };
    },
    async checkImage(image) {
      if (!image) {
        return "";
      }
      if (!this.isValidImageURL(image)) {
        return "Ảnh không đúng định dạng";
      }
      // if (!await this.checkImageExists(image)) {
      //   return "Link ảnh không hợp lệ";
      // }
      return "";
    },
    async checkImageExists(url) {
      try {
        const response = await fetch(url, {
          method: 'HEAD',
          mode: 'no-cors'
        });

        if (response.ok) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    isValidImageURL(url) {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
      const extension = url.slice((url.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
      return imageExtensions.includes(`.${extension}`);
    },
    fileToJson(file) {
      return new Promise((res, rej) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader()
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result
          const wb = read(bstr, { type: 'binary' })
          /* Get first worksheet */
          const questionName = wb.SheetNames[0]
          const answerName = wb.SheetNames[1]
          const questionSheet = wb.Sheets[questionName]
          const answerSheet = wb.Sheets[answerName]
          /* Convert array of arrays */
          const questions = utils.sheet_to_json(questionSheet)
          const answers = utils.sheet_to_json(answerSheet)
          let questionsAndAnswers = questions.map((d) => {
            return {
              ...d, answers: answers.filter(c => c.QuestionId == d.Id).map((answer) => {
                return {
                  answer: answer.Answer,
                  is_correct: answer.Correct ?? 0
                }
              })
            };
          })
          let answersHaveQuestionId = questionsAndAnswers.map((d) => d.Id)
          let answersNotQuestionId = answers.filter((answer) => !answersHaveQuestionId.includes(answer.QuestionId ?? null)).map((d) => {
            if (d.QuestionId == undefined) {
              return {
                errors: "câu trả lời không có IdQuestion"
              }
            }
            return {
              errors: `câu trả lời có IdQuestion:${d.QuestionId} không tìm thấy câu hỏi`
            }
          })
          /* Update state */
          res(questionsAndAnswers.concat(answersNotQuestionId));
          //const header = data.shift()
        }
        reader.onerror = (e) => {
          rej(e);
        };
        reader.readAsBinaryString(file)
      });
    },
  }
}
</script>

<style scoped>
.file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  height: 34px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 7px 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);

}

.disable-import {
  background-color: #FF9900;
}

.on-disable-import {
  background-color: #a6a6a6;
}
</style>