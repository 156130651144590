<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="area_create" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Tạo khu vực
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'area-index' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Tên khu vực
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Tên khu vực" rules="required"
                                    v-slot="{ errors,classes }">
                  <input type="text" ref="name" name="name" class="form-control" v-model="area.name"
                         placeholder="Tên khu vực" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Loại khu vực
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Loại khu vực" rules="required"
                                    v-slot="{ errors,classes }">
                  <el-select v-model="area.type_id"
                             class="w-100"
                             placeholder="Loại khu vực"
                             clearable
                             collapse-tags
                             filterable
                  >
                    <el-option
                        v-for="item in types"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div>
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="div" class="p-1 card-header" role="tab">
                  <b-card-title block variant="info" class="text-left">
                    Thêm chi nhánh
                  </b-card-title>
                </b-card-header>
                <b-card-body>
                  <div class="checkbox-inline">
                    <b-form-checkbox class="col-md-3" v-for="(item,index) in area.branches"
                                     :key="index"
                                     v-model="item.checked"
                                     size="lg">{{ item.name_alias ? item.name_alias : item.name }}
                    </b-form-checkbox>
                  </div>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
        <!--Thông tin cấp trên-->
        <div class="separator separator-dashed my-5"></div>
        <div class="card-header py-3r">
          <div class="card-title align-items-start flex-column">
            <button
                type="submit"
                class="btn btn-success mr-2"
            >
              Tạo mới
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {CREATE_AREA, GET_BRANCHES_NOT_AREA} from "../../../core/services/store/center/area.module";
import {types}  from "@/core/option/area";
export default {
  components: {
    DatePicker,
  },
  name: "AreaCreate",
  data() {
    return {
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      area: {
        name: '',
        type_id: '',
        branches: []
      },
      types: types
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý trung tâm", route: 'profile-1'},
      {title: "Tạo khu vực"}
    ]);
    this.getBranches()
  },
  computed: {},
  methods: {
    onSubmit() {
      this.$store.dispatch(CREATE_AREA, this.area).then((res) => {
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Tạo mới',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$router.push({name: "area-index"}).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Tạo mới',
              variant: 'success',
              solid: true
            });
          })
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      });
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES_NOT_AREA, this.area).then((res) => {
        res.data.map((value) => {
          this.area.branches.push({
            id: value.id,
            name: value.name,
            name_alias: value.name_alias,
            checked: false
          })
        })
      });
    }
  }
}
</script>
<style scoped>
.mx-datepicker {
  width: 100% !important;
}
</style>
