<template>
  <div>
    <FormulateForm
        :schema="schema"
        v-model="values"
        #default="{ isLoading }"
        @focus-confirm="focusedOn = 'confirm'"
        @file-upload-complete="handleData"
        @submit="submitHandler">
      {{ focusedOn }}
    </FormulateForm>
  </div>
</template>

<script>
import {peopleInEdutalkPage} from "@/view/pages/setting-page/index";
import {
  EDU_OPTION_POST,
  EDU_OPTION_PUT,
  GET_EDU_OPTION
} from "@/core/services/store/edutalk-option/edutalk-option.module";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

const _ = deepdash(lodash);
export default {
  name: "peopleInEdutalk-page",
  data() {
    return {
      schema: peopleInEdutalkPage,
      focusedOn: null,
      values: {
      },
      value_prop: {},
      fileName: {
        ecosystem_image: {
          url: "",
          name: ""
        }
      },
      convertData: [],
      array: [],
      array_image_avata_leader: [],
      array_image_avata_personnel: [],
      id: '',
      array_prop: []
    }
  },
  mounted() {
    this.getOption();
  },
  methods: {
    async handleData(data) {
      if(data.parent === 'avata_leader') {
        this.array_image_avata_leader.push([data]);
      }
      if(data.parent === 'avata_personnel') {
        this.array_image_avata_personnel.push([data]);
      }
    },

    submitHandler(data, log) {
      let data_form = {};
      let index = null;
      if (this.array_prop.banner_items) {
        _.eachDeep(data, function (value, key, path, depth, parent) {
          if (index === null) {
            index = key
          }
          if (key && key.indexOf("avata") !== -1) {
            path[key] = path[key].results;
            return false
          }
        });
        data_form = data;
      }
      else {
        for (var i = 0;i<this.array_image_avata_leader.length;i++) {
          data['leader_edutalk_items'][i]['avata_leader'] = this.array_image_avata_leader[i];
        }
        for (var i = 0;i<this.array_image_avata_personnel.length;i++) {
          data['personnel_edutalk_items'][i]['avata_personnel'] = this.array_image_avata_personnel[i];
        }
        data_form = data;
      }
      if(this.id) {
        this.$store.dispatch(EDU_OPTION_PUT, {id: this.id,template: 'peopleInEdutalk' , data: JSON.stringify(data_form)})
            .then((res) => {
              if (!res.error) {
                this.$bvToast.toast(res.message, {
                  title: 'Cập nhật',
                  variant: 'success',
                  solid: true
                });
              }
            }).catch((e) => {
        });
      } else {
        this.$store.dispatch(EDU_OPTION_POST, {template: 'peopleInEdutalk' , data: JSON.stringify(data_form)})
            .then((res) => {
              if (!res.error) {
                this.$bvToast.toast(res.message, {
                  title: 'Thêm mới',
                  variant: 'success',
                  solid: true
                });
              }
            }).catch((e) => {
        });
      }
    },
    getOption() {
      this.$store.dispatch(GET_EDU_OPTION, {template: 'peopleInEdutalk'})
          .then((res) => {
            if (!res.error) {
              this.array_prop = JSON.parse(res[1]);
              this.id = res[2];
              this.values = res[0].length === 0 ? {} : res[0];
            }
          }).catch((e) => {
      });
    },
  }
}
</script>

<style scoped>

</style>