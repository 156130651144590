<template>
  <div class="">
    <h2 class="mb-5">Nhận xét học viên buổi {{ this.$route.params.id }}</h2>
      <div class="card card-custom gutter-b bg-white p-5 rounded-lg">
        <div class="grid-custom">
         <div class="bg-base p-5 rounded-lg">
          <div style="height: 36px; display: flex; align-content: center">
            <span class="title-custom mt-4 mr-2">SỐ HỌC VIÊN ĐÃ NHẬN XÉT:</span>
            <span style="color: #FF0000; font-size: 20px; margin-top: 5px; font-weight: bolder">{{
              quantityCommented?.length }} / {{ studentList?.length }}</span>
          </div>
          <div class="line"></div>
          <div>
            <div class="d-flex mx-8 my-3">
              <div class="font-weight-bolder">Mã HV</div>
              <div class="font-weight-bolder ml-4">Họ và tên</div>
            </div>
            <div style="height: 55vh; overflow-y: auto">
              <div class="d-flex justify-content-between align-content-center bg-white rounded-lg mb-4 px-8 py-4 student-hover"
                   v-for="(student) in studentList" @click.prevent="selectStudent(student)" :key="student.user_id"
                   :class="student.user_id === studentSelected.user_id ? 'border-selected' : ''"
              >
              <span class="font-weight-bold d-flex align-self-center">
                {{ student?.user_id + MHV }}
              </span>
                <span class="w-100 font-weight-bold mx-3 d-flex align-self-center">
                {{ student?.name }}
              </span>
                <div class="d-flex justify-content-end">
                  <div
                      v-if="student?.isCompleted"
                      class="btn-done action-student"
                  >
                    <i class="el-icon-check" style="color: #159400"></i>
                    <span style="text-wrap: nowrap;">Hoàn thành</span>
                  </div>
                  <div
                      v-else-if="student?.status === 1"
                      class="btn-edit action-student"
                  >
                    <i class="el-icon-edit" style="color: #0066FF"></i>
                    <span style="text-wrap: nowrap;">Đang nhận xét</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white px-5 pt-5 rounded-lg border border-dark">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <div class="d-flex justify-content-between align-content-center">
              <h2 class="title-custom mt-4">Chi tiết nhận xét: {{ studentSelected?.name }}</h2>
              <el-button
                  v-if="studentSelected?.isCompleted !== true"
                  class="button-custom"
                  @click="handleSubmit(onSave)"
                  :disabled="loadingSave && checkDisable && !studentSelected?.comment_phase.comment"
              >
                <span class="pt-2">Lưu nhận xét</span>
              </el-button>
            </div>
            <div class="line"></div>
            <div style="height: 55vh; overflow-y: auto">
              <div
                  v-if="studentSelected?.attendance === CO_MAT"
                  class="bg-base rounded-lg p-5 mb-5"
                  v-for="(comment, index) in studentSelected?.comments"
                  :key="index"
              >
                <SelectOptionComment
                    :key="`comment-${index}`"
                    :student="studentSelected"
                    :comment="comment"
                    @change-input="changeInput"
                    @change-topic="updateDisableReview"
                />
              </div>
              <div v-if="checkShowPhase && studentSelected?.comment_phase" class="bg-base rounded-lg p-5">
                <h6>NHẬN XÉT TỔNG QUAN GIAI ĐOẠN {{ getPhaseTime }} <span class="text-danger">*</span></h6>
                <div class="line"></div>
                <ValidationProvider
                    vid="phase-comment"
                    name="Nhận xét tổng quan học viên giai đoạn"
                    rules="required|min:80" v-slot="{ errors }"
                >
                  <el-input type="textarea" :placeholder="`Nhập nhận xét tổng quan học viên giai đoạn ${getPhaseTime}`"
                            :rows="3" v-model="studentSelected.comment_phase.comment"
                            :disabled="studentSelected?.isCompleted" class="text-black"
                  />
                  <div class="fv-plugins-message-container">
                    <div data-field="Nhận xét tổng quan học viên giai đoạn" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <!--  Modal Confirm Change Comment -->
    <el-dialog title="Nhắc nhở" :visible.sync="showConfirmChangeStudent">
      <div class="line" style="margin-top: -20px;"></div>
      <div class="d-flex flex-column">
        <label>Bạn chưa hoàn thành nhận xét học viên <span
            class="font-weight-bolder">{{ studentSelected?.name
            }}</span>.</label>
        <label class="mt-4">Bạn có chắc chắn muốn chuyển nhận xét học viên khác không?</label>
      </div>
      <div slot="footer" class="dialog-footer d-flex justify-content-end">
        <el-button @click="cancelConfirm()" class="button-custom"
          style="background: #E6E6E6 !important; color: #0a0a0a !important;">
          Hủy
        </el-button>
        <el-button type="primary" @click="confirmChangeStudent" class="button-custom">Xác nhận</el-button>
      </div>
    </el-dialog>
    <!--Modal  Confirm Save Feedback-->
    <el-dialog title="Lưu kết quả nhận xét" :visible.sync="isSave">
      <div class="line" style="margin-top: -20px;"></div>
      <div class="d-flex flex-column">
        <label>Kết quả nhận xét sau khi lưu lại sẽ không thể thay đổi.</label>
        <label class="mt-4">Bạn có chắc chắn muốn lưu không?</label>
      </div>
      <div slot="footer" class="dialog-footer d-flex justify-content-end">
        <el-button @click="closeConfirmSave" class="button-custom"
          style="background: #E6E6E6 !important; color: #0a0a0a !important;">
          Hủy
        </el-button>
        <div class="position-relative ml-4">
          <el-button type="primary" :disabled="loading" class="button-custom" @click="onSubmit">
            Xác nhận
          </el-button>
          <div class="loading-custom" v-if="loading">
            <i class="el-icon-loading"></i>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  POST_COMMENT_STUDENT,
  GET_LISTSTUDENT_COMMENT,
  GET_AUTO_COMMENT,
  ATTENDANCE_LESSION
} from "@/core/services/store/course/classes.module";
import { StudentComment, Comment } from "./model/StudentComment"
import SelectOptionComment from "./components/selectOptionComment.vue"
import moment from "moment-timezone";
import {
  PHAT_BIEU_HOI_BAI,
  THAM_GIA_HOAT_DONG,
  VUI_VE_HOA_DONG,
  GHI_CHEP,
  NGHE_GIANG,
  RAT_TOT,
  TOT,
  KHA,
  KEM,
  RAT_KEM
} from "@/core/option/commentStudentType"
import {CO_MAT, VANG_MAT, CO_PHEP, MHV} from "@/core/option/attendanceStudent";

export default {
  components: { SelectOptionComment },
  data() {
    return {
      isChange: false,
      studentChange: null,
      loading: false,
      studentSelected: new StudentComment(),
      isSave: false,
      lesson: null,
      loadingSave: true,
      notificationComment: false,
      studentList: [],
      listTest: [],
      listLesson: [],
      lastComment: [],
      CO_MAT: CO_MAT,
      VANG_MAT: VANG_MAT,
      CO_PHEP: CO_PHEP,
      MHV: MHV
    }
  },
  computed: {
    showConfirmChangeStudent(){
      return this.studentList.find(d=>d.changeStudent) != null;
    },
    quantityCommented() {
      return this.studentList.filter(item => item?.isCompleted === true)
    },
    checkDisable() {
      return this.studentSelected?.comments?.find(item => item.review !== "" && item.review !== null) == null
    },
    checkShowPhase() {
      return this.getCurrentPhase !== undefined;
    },
    getPhaseTime() {
      const currentIndex = this.getPhaseDefault?.indexOf(this.getCurrentPhase);
      if (currentIndex === -1) {
        return "";
      }
      let startDate = "";
      if (currentIndex === 0) {
        startDate = this.listLesson?.find(d => true)?.date_only ?? "";
      }
      else {
        startDate = this.listLesson?.find(d => d.lesson === (this.getPhaseDefault[currentIndex - 1].lesson + 1))?.date_only ?? "";
      }

      return `${moment(startDate).format('DD/MM')} - ${moment(this.getCurrentPhase.date).format('DD/MM')}`;
    },
    getCurrentPhase() {
      const lesson = parseInt(this.$route.params.id)
      const phaseDefault = this.getPhaseDefault;
      return phaseDefault?.find(d => d.lesson === lesson);
    },
    getPhaseDefault() {
      const listDateLesson = this.listLesson?.map((date, index) => {
        return {
          index: index,
          lesson: date.lesson,
          date: date.date_only,
          type: date.type
        }
      })
      const phaseDefault = [6, 12, 18, 24, 28, 32, 36, 40]
      return phaseDefault.map(phase => {
        let current = listDateLesson?.find(lesson => lesson.lesson === phase)
        if (!current) {
          return null
        }
        if (current.type === 'homework') {
          return current
        }
        return listDateLesson?.find(lesson => lesson?.lesson === phase + 1)
      }).filter(d => d !== null && d !== undefined);
    },
    startPhaseTime() {
      const currentIndex = this.getPhaseDefault?.indexOf(this.getCurrentPhase);
      if (currentIndex === -1) {
        return "";
      }
      let startDate = "";
      if (currentIndex === 0) {
        startDate = this.listLesson?.find(d => true)?.date_only ?? "";
      }
      else {
        startDate = this.listLesson?.find(d => d.lesson === (this?.getPhaseDefault[currentIndex - 1].lesson + 1))?.date_only ?? "";
      }

      return startDate;
    },
    endPhaseTime() {
      return this.getCurrentPhase?.date;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Sửa lỗi dạy học", route: 'manage-error' },
      { title: "Nhận xét học viên" }
    ]);
    this.getListStudent()
    this.getLastComments()
    this.listTest = JSON.parse(localStorage.getItem("listTest"))
    this.listLesson = JSON.parse(localStorage.getItem("listLesson"))
  },
  methods: {
    cancelConfirm() {
      let student = this.studentList.find(d => d.changeStudent);
      if (student) {
        student.changeStudent = false;
      }
    },
    changeInput(comment) {
      this.getAutoComment(comment).then(data => {
        this.loadingSave = false
        comment.recommendAuto = data.data.content;
      });
      this.updateDisableReview(comment);
    },
    closeConfirmSave() {
      this.isSave = false
      this.loading = false
    },
    confirmChangeStudent() {
      let studentChanged = this.studentList.find(d => d.changeStudent);
      studentChanged.changeStudent = false;
      this.studentSelected.status = 0;
      this.studentSelected = studentChanged;
      this.studentSelected.status = 1;
    },
    generateEcoachTopic(title) {
      switch (title) {
        case 1:
          return [
            {
              label: "Phát biểu hỏi bài",
              value: PHAT_BIEU_HOI_BAI,
            },
            {
              label: "Tham gia hoạt động",
              value: THAM_GIA_HOAT_DONG,
            },
          ]
        case 2:
          return [
            {
              label: "Nghe giảng",
              value: NGHE_GIANG,
            },
          ]
        case 3:
          return null
      }
    },
    generateIEComment(comments, center_id) {
      if (comments == null || comments.length === 0) {
        if (center_id === 516) {
          return [
            new Comment(1, false, null, '', null, '',
                [
                  {
                    label: "Phát biểu hỏi bài",
                    value: PHAT_BIEU_HOI_BAI,
                  },
                  {
                    label: "Tham gia hoạt động",
                    value: THAM_GIA_HOAT_DONG,
                  },
                  {
                    label: "Vui vẻ hoà đồng",
                    value: VUI_VE_HOA_DONG,
                  }
                ],
            ),
            new Comment(2, false, null, '', null, '',
                [
                  {
                    label: "Ghi chép",
                    value: GHI_CHEP,
                  },
                  {
                    label: "Nghe giảng",
                    value: NGHE_GIANG,
                  },
                ],
            ),
            new Comment(3, false, null, '', null, '', null,),
          ];
        } else {
          return [
            new Comment(1, false, null, '', null, '',
                [
                  {
                    label: "Phát biểu hỏi bài",
                    value: PHAT_BIEU_HOI_BAI,
                  },
                  {
                    label: "Tham gia hoạt động",
                    value: THAM_GIA_HOAT_DONG,
                  },
                ],
            ),
            new Comment(2, false, null, '', null, '',
                [
                  {
                    label: "Nghe giảng",
                    value: NGHE_GIANG,
                  },
                ],
            ),
            new Comment(3, false, null, '', null, '', null),
          ];
        }
      }
      return comments.map(d => {
        return new Comment(d.title, false, d.topic, d.feedback, d.review, d.recommendAuto, this.generateTopic(d.title, center_id))
      });
    },
    generateIeltsTopic(title) {
      switch (title) {
        case 1:
          return [
            {
              label: "Phát biểu hỏi bài",
              value: PHAT_BIEU_HOI_BAI,
            },
            {
              label: "Tham gia hoạt động",
              value: THAM_GIA_HOAT_DONG,
            },
            {
              label: "Vui vẻ hoà đồng",
              value: GHI_CHEP,
            },
          ]
        case 2:
          return [
            {
              label: "Ghi chép",
              value: GHI_CHEP,
            },
            {
              label: "Nghe giảng",
              value: NGHE_GIANG,
            },
          ]
        case 3:
          return null
      }
    },
    generateTopic(title, center_id) {
      if (center_id === 516) {
        return this.generateIeltsTopic(title)
      } else {
        return this.generateEcoachTopic(title)
      }
    },
    updateDisableReview(comment){
      if(!this.studentSelected || !comment ){
        return;
      }
      const hasGoodReview = this.studentSelected.comments.find(d=>d.review > 0 && d.review <= TOT);
      let commentReviews = this.studentSelected.comments.filter(d=> d.title !== hasGoodReview?.title);
      commentReviews.forEach((d)=>{
        d.reviews = d.reviews.map((c) => {
          return {...c, disabled: hasGoodReview? c.value <= 2 || c.disabled : false};
        });
      });
    },
    selectStudent(student) {
      if (student === this.studentSelected) {
        return;
      }
      if (this.studentSelected?.isCompleted) {
        this.studentSelected = student;
        this.studentSelected.status = 1;
        return;
      }
      student.changeStudent = true;
    },
    getLastComments() {
      this.$store.dispatch(GET_LISTSTUDENT_COMMENT, {
        classroom_id: this.$route.query.classroom_id,
        lesson: (parseInt(this.$route.params.id) - 1).toString()
      }).then(data => {
        const lastLesson = data.data.filter(item =>
          item.id === this.studentSelected.user_id
        )
        this.lastComment = lastLesson[0]?.comments
      }).catch(e => {
        console.log(e)
      })
    },
    getAutoComment(comment) {
      if(!comment.review){
        return Promise.reject();
      }
      let payload = {
        lesson: parseInt(this.$route.params.id),
        classroom_id: parseInt(this.$route.query.classroom_id),
        user_id: 1,
        rate: comment.review,
        criteria: comment.title,
        subcategory: comment.topic ?? 6
      }
      return this.$store.dispatch(GET_AUTO_COMMENT, payload);
    },
    async getListStudent() {
      this.studentSelected = await this.studentList.find(d => true);
      let response = await this.$store.dispatch(ATTENDANCE_LESSION, {
        classroom_id: parseInt(this.$route.query.classroom_id),
        lesson: parseInt(this.$route.params.id)
      });
      let data = response.data;
      const studentListPhase = data.map((item) => {
        return new StudentComment(
            item?.user_name,
            item?.user_id,
            item?.comment_phase !== null || item.comments != null && item.comments.length > 0,
            false,
            this.$route.query.classroom_id,
            this.$route.params.id,
            item?.comment_phase,
            this.generateIEComment(item?.comments, item?.center_id),
            item?.comment_phase !== null || item.comments != null && item.comments.length > 0,
            item?.attendance
        )
      })
      const studentListNormal = data.filter(item => item.attendance === CO_MAT).map(item => {
        return new StudentComment(
            item?.user_name,
            item?.user_id,
            item.comments != null && item.comments.length > 0,
            false,
            this.$route.query.classroom_id,
            this.$route.params.id,
            item?.comment_phase,
            this.generateIEComment(item?.comments, item?.center_id),
            item.comments != null && item.comments.length > 0,
            item?.attendance
        )
      })
      this.studentList = this.checkShowPhase ? studentListPhase : studentListNormal
      this.studentSelected = this.studentList?.find((item) => item?.isCompleted !== true) ?? this.studentList?.find(item => true);
      if (this.studentSelected) {
        this.studentSelected.status = 1;
      }
    },
    onSave() {
      this.studentSelected?.comments?.find(item => {
        switch (this.checkShowPhase) {
          case true:
            if (this.studentSelected.attendance === CO_MAT) {
              if(item.review !== null) {
                this.isSave = true
              }
            } else {
              this.isSave = true
            }
            break;
          case false:
            if (item.review !== null) {
              this.isSave = true
            }
        }
      })
    },
    onSubmit() {
      this.loading = true
      if(this.checkShowPhase && (this.studentSelected.attendance === 0 || this.checkShowPhase === 2)) {
        this.studentSelected.comments = null
      }
      let payload = {
          ...{
            ...this.studentSelected, comments: this.studentSelected?.comments?.map(d => {
              return {
                topic: d.topic === '' ? 6 : d.topic,
                review: d.review === '' ? null : d.review,
                disabled: d.disabled,
                feedback: d.feedback??'',
                nameTitle: d.nameTitle,
                recommendAuto: d.recommendAuto??'',
                title: d.title,
              };
            })
          },
          "comment_phase": !this.checkShowPhase ? null : {
            "comment": this.studentSelected?.comment_phase?.comment,
            "start": this.startPhaseTime,
            "end": this.endPhaseTime
          }
      }
      this.$store.dispatch(POST_COMMENT_STUDENT, payload).then((res) => {
        if (!res.error) {
          this.loading = false
          this.$message({
            message: 'Nhận xét thành công',
            type: 'success'
          });
          this.isSave = false
          this.getListStudent()
        } else {
          this.$message.error('Oops, Đánh giá thất bại.');
        }
      });
    },
  },
}
</script>

<style scoped>
.grid-custom {
  display: grid;
  grid-template-columns: 4fr 8fr;
  gap: 30px
}

.bg-base {
  background-color: #F0EDF7;
}

.line {
  background-color: #989898;
  height: 1px;
  width: 100%;
  margin: 18px 0;
}

.title-custom {
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: bolder;
}

.button-custom {
  min-width: 110px;
  line-height: 20px;
  display: flex !important;
  align-content: center !important;
  justify-content: center !important;
  background-color: #544B7E !important;
  color: white !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-radius: 8px !important;
  border: 0 !important;
}

.loading-custom {
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
}
.student-hover:hover {
  background-color: #544B7E !important;;
  cursor: pointer;
  color: white !important;
}
.student-hover:hover span{
  color: white !important;
}
.student-hover:hover i{
  color: white !important;
}

.border-selected {
  border: 1px solid #7F67CF;
  background: #ececf8 !important;
}
.btn-done {
  color: #159400
}
.btn-edit {
  color: #0066FF;
}

.action-student {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .grid-custom {
    display: grid;
    grid-template-columns: 12fr;
    gap: 30px;
  }

  .title-custom {
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
  }
}
</style>
