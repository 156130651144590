<template>
  <!--begin::Card-->
  <div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form class="form" @submit.prevent="handleSubmit(onSubmit)">
        <div class="card card-custom">
          <!--begin::Header-->
          <div class="card-header">
            <div class="card-title ">
              <h3 class="card-label">
                Sửa trung tâm
              </h3>
            </div>
            <div class="card-toolbar">
              <router-link
                  class="font-weight-bold font-size-3  btn btn-secondary"
                  :to="{ name: 'center-list' }"
              ><i class="fas fa-angle-double-left"></i> Quay lại
              </router-link>
            </div>
          </div>
          <!--end::Header-->
          <div class="card-body">
            <!-- Ảnh đại diện -->
            <div class="form-group row">
              <div class="col-md-3">
                <div class="row mx-auto mt-8">
                  <label class="col-xl-4 col-lg-3 col-form-label text-right">Ảnh đại diện</label>
                  <div class="col-lg-9 col-xl-6">
                    <div class="image-input image-input-outline" id="kt_profile_avatar">
                      <div
                          class="image-input-wrapper"
                          :style="{ backgroundImage: `url(${photo})` }"
                      ></div>
                      <label
                          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="change"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Change avatar"
                      >
                        <i class="fa fa-pen icon-sm text-muted"></i>
                        <input
                            type="file"
                            name="profile_avatar"
                            accept=".png, .jpg, .jpeg"
                            @change="onFileChange($event)"
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>
                      <span
                          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="cancel"
                          data-toggle="tooltip"
                          title="Cancel avatar"
                      >
                                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                                    </span>
                      <span
                          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="remove"
                          data-toggle="tooltip"
                          title="Remove avatar"
                          @click="current_photo = null"
                      >
                                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                                    </span>
                    </div>
                    <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Tên trung tâm<span class="text-danger">*</span></label>
                      <ValidationProvider name="Tên trung tâm" vid="name" rules="required" v-slot="{ errors,classes }">
                        <el-input type="text" ref="name" name="name"  :class="classes"
                                  placeholder="Tên trung tâm"
                                  v-model="center.name"
                                  @input="changeTitle"
                        />
                        <div class="fv-plugins-message-container">
                          <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                            {{ errors[0] }}
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Đường dẫn</label>
                      <div class="el-input el-input-group el-input-group--prepend">
                        <el-input autocomplete="off" type="text" disabled v-model="center.slug" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="d-block">Loại trung tâm</label>
                  <el-select v-model=center.is_edutalk
                             clearable filterable
                             placeholder="Chọn loại trung tâm"
                             class="w-100">

                    <el-option v-for="item in TYPE_CENTER"
                               :key="item.id"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Số điện thoại
                    <span class="text-danger"></span></label>
                  <el-input name="phone" placeholder="Số điện thoại" v-model="center.phone" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Thời gian làm việc</label>
                  <el-input type="text" name="work_time"
                            placeholder="Thời gian làm việc (Ví dụ: 8h-17h)"
                            :value="showWorktime(center.work_time)" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Website
                    <span class="text-danger"></span></label>
                  <el-input name="website" placeholder="Website" v-model="center.website" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Facebook</label>
                  <el-input type="text" name="work_time"
                            placeholder="Facebook"
                            v-model="center.fanpage" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group mb-1 col-md-12">
                <label for="center-description">Mô tả ngắn</label>
                <el-input id="center-description" placeholder="Mô tả ngắn" type="textarea" :rows="3" v-model="center.excerpt"></el-input>
              </div>
            </div>

            <div class="row">
              <div class="form-group mb-1 col-md-12">
                <label class="mt-6">Công cụ bán hàng</label>
                <ckeditor :editor="editor" v-model="center.schedule"></ckeditor>

              </div>
            </div>

          </div>
          <div class="position-fixed center-fix-button">
            <div class="">
              <button type="submit" class="btn btn-success mr-2" :disabled="disable">
                Cập nhật
              </button>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
  <!--end::Card-->
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {Quill, VueEditor} from 'vue2-editor'
import ImageResize from 'quill-image-resize-vue';
import {ImageDrop} from 'quill-image-drop-module';
import {GET_EDUCENTER_INFORMATION, UPDATE_CENTER} from "@/core/services/store/center/centers.module";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import ApiService from "@/core/services/api.service";
import { ChangeToSlug } from "@/core/filters";
import GoogleMap from "../google-map/GoogleMap";
import {UPDATE_EDUCENTER_CENTER} from "@/core/services/store/center/centers.module";
import {TYPE_CENTER} from "@/core/option/centerOption";
import CustomerEdit from "@/core/plugins/ckeditor5/custom-editor";

const _ = require('lodash');
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
  name: "Edit-Educenter-Center",
  components: {
    VueEditor,
    vueDropzone: vue2Dropzone,
    GoogleMap
  },
  data() {
    return {
      editor: CustomerEdit,
      removeFiles: [],
      center: {
        name_mkt: '',
        avatar_mkt: '',
        phone_mkt: '',
        address_mkt: '',
        long_description_mkt: '',
        short_description_mkt: '',
        thumbnail_mkt: '',
        information_mkt: {
          intro: '',
          special: '',
          course: '',
          schedule: '',
          teacher: '',
          sale: '',
          feedback: ''
        },
        name: '',
        user_id: "",
        address: "",
        province_id: "",
        district_id: "",
        status: '',
        work_time: '',
        slug: "",
        fan_page: "",
        youtube_channel: "",
        website: '',
        long_description: '',
        short_description: '',
        is_popular: '',
        avg_rate: '',
        has_assistant: '',
        is_center: '',
        lng: '',
        lat: '',
        google_map: '',
        information: {
          intro: '',
          special: '',
          course: '',
          schedule: '',
          teacher: '',
          sale: '',
          feedback: ''
        },
        area_id: '',
        phone: '',
        thumbnail: [],
        avatar: '',
        is_edutalk: ''
      },
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      id: '',
      type: '',
      default_photo: "media/users/blank.png",
      current_photo: null,
      image_name: null,
      image_type: null,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        // thumbnailWidth: 200,
        maxFilesize: 0.5,
        thumbnailMethod: 'contain',
        addRemoveLinks: true,
        autoProcessQueue: false,
        headers: { "My-Awesome-Header": "header value" },
        init: function() {
          this.on("complete", function(file) {
            // this.files.push(file);
          });
        }
      },
      dialogFormVisible: false,
      disable: false,
      dialog: {
        title: ''
      },
      center_const: {},
      TYPE_CENTER,
    };
  },
  computed: {
    photo() {
      return this.current_photo ? this.current_photo : this.default_photo;
    }
  },
  created() {
    this.getInfoCenter();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Quản lý trung tâm"},{title: "Sửa trung tâm"}]);
  },
  methods: {
    changeTitle: function () {
      this.center.slug = ChangeToSlug(this.center.name);
    },
    removeFile(file, error, xhr){
      this.removeFiles.push(file);
    },
    removeImage: function(delta, oldContents){
      ApiService.post('remove-image', {url:delta})
          .then(({data}) => {
          })
          .catch(err => {
            console.log(err);
          });
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      var formData = new FormData();
      formData.append("image", file);
      ApiService.post('upload-image',formData)
          .then(({data}) => {
            let url = data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch(err => {
            console.log(err);
          });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
        this.image_name = file.name;
        this.image_type = file.type;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onSubmit: async function () {
      this.disable = true;
      let formData = new FormData();
      for (let i = 0; i < this.removeFiles.length; i++) {
        formData.append('removeFiles[]', this.removeFiles[i]);
      }
      let avatar = this.photo;
      function urltoFile(url, filename, mimeType) {
        return (fetch(url)
                .then(function (res) {
                  return res.arrayBuffer();
                })
                .then(function (buf) {
                  return new File([buf], filename, {type: mimeType});
                }).catch((error) => {
                  return 1;
                })
        );
      }
      if (avatar.search('https') === -1) {
        let file = await urltoFile(avatar, this.image_name, this.image_type);
        if (file !== 1 && file.name !== 'null') {
          formData.append('thumbnail', file);
        }
      } else {
        formData.append('thumbnail', avatar);
      }

      for (const key in this.center) {
        formData.append(key, this.center[key]);
      }
      this.$store.dispatch(UPDATE_EDUCENTER_CENTER, {
        id: this.id,
        params: formData,
      }).then((res) => {
        this.$router.push({name: "center-educenter"}).then(() => {
          this.$notify({
            title: 'Thành công',
            message: 'Cập nhật trung tâm thành công',
            type: 'success'
          });
        })
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
        if (e.data?.message){
          this.$notify({
            title: 'Thất bại',
            message: e.data.message,
            type: 'error'
          });
        }
      }).finally(() => { this.disable = false });
    },
    getInfoCenter() {
      this.id = this.$route.params.id;
      this.$store.dispatch(GET_EDUCENTER_INFORMATION, this.id).then((res) => {
        this.center = res.data;
        this.current_photo = this.center.avatar;
      });
    },
    showWorktime(workTime) {
      if (workTime){
        let workTimeStr = eval('(' + workTime + ')');
        let workTimeObj = JSON.parse(JSON.stringify(workTimeStr)) || {}
        return workTimeObj?.start ? workTimeObj.start + '' + ' - ' + workTimeObj.end + '' : '';
      }
      return '';
    },
  },
};
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}
.dz-progress {
  display: none !important;
}
.quillWrapper{
  height: 630px;
  overflow: auto;
}
.ql-container{
  height: 560px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

