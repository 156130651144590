<template>
  <div class="container">
    <div class="customer-cskh">
      <div class="customer-list-courses">
        <div class="title">
          <h4>Thông tin tổng quan</h4>
        </div>
        <div class="row">
          <div class="col-md-3 form-group">
            <label>Chờ xử lý:</label>
            <input type="text" class="form-control" :value="ticketWaiting" disabled>
          </div>
          <div class="col-md-3 form-group">
            <label>Đã nhập giải pháp:</label>
            <input type="text" class="form-control" :value="ticketWorking" disabled>
          </div>
          <!--          <div class="col-md-3 form-group">-->
          <!--            <label>Giữ lại:</label>-->
          <!--            <input type="text" class="form-control" :value="ticketCancel" disabled>-->
          <!--          </div>-->
          <div class="col-md-3 form-group">
            <label>Hoàn thành:</label>
            <input type="text" class="form-control" :value="ticketDone" disabled>
          </div>
        </div>
        <div class="table-responsive overflow-auto text-center">
          <table class="table table-vertical-center table-bordered table-hover">
            <thead>
            <tr>
              <th>STT</th>
              <th>Mã ticket</th>
              <th>Danh mục</th>
              <th>Phân loại</th>
              <th>Trạng thái</th>
              <th>Thời gian tạo ticket</th>
              <th>Hành động</th>
            </tr>
            </thead>
            <tbody v-if="tickets.length > 0">
            <tr v-for="(item, key) in tickets">
              <td>{{ ++key }}</td>
              <td>{{ item.id }}</td>
              <td>{{ getCategory(item) }}</td>
              <td>{{ showClassify(item) }}</td>
              <td>{{ STATUS[item.status] }}</td>
              <td>{{ item.created_at | formatDateTime }}</td>
              <td>
                <router-link :to="{ name: 'crm-ticket-show', params: {id : item.id} }"
                             v-if="item.is_auto === 1 && item.category_chamsoc != 16 && item.category_chamsoc != 14 && item.category_chamsoc != 15 && item.category_chamsoc != 13"
                             title="Xem"
                             class="btn btn-sm btn-icon  btn-outline-info mr-2"
                >
                  <i class="fas far fa-eye"></i>
                </router-link>
                <router-link :to="{ name: 'ticket-show', params: {id : item.id} }"
                             title="Xem"
                             v-if="item.is_auto === 3 || (item.is_auto === 1 && (item.category_chamsoc == 16 || item.category_chamsoc == 14 || item.category_chamsoc == 15 || item.category_chamsoc == 13))"
                             class="btn btn-sm btn-icon  btn-outline-info"><i
                    class="fas far fa-eye"></i>
                </router-link>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="7" class="event-title no-data text-center">Chưa có dữ liệu</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <c-s-k-h-detail :show-modal="showModal" :ticket="ticketObj" @clicked="onclickModalTicket"></c-s-k-h-detail>
  </div>
</template>

<script>
import {CATEGORY, CATEGORY_CHAMSOC, STATUS} from "@/core/option/ticketOption";
import CSKHDetail from "@/view/pages/customers/customerDetail/CSKH-Detail";
import {ARRAY_CATE_CHAMSOC, CHAM_SOC} from "../../../../core/option/ticket";

export default {
  name: "Customer-CSKH",
  components: {CSKHDetail},
  props: {
    tickets: {
      type: Array,
      default: () => {
        return []
      }
    },
    ticketReason: {
      type: Array,
      default: () => {
        return []
      }
    },
    ticketWaiting: 0,
    ticketWorking: 0,
    ticketDone: 0,
    ticketCancel: 0
  },
  data() {
    return {
      showModal: false,
      CATEGORY,
      CATEGORY_CHAMSOC,
      STATUS,
      ticketObj: {}
    }
  },
  methods: {
    getCategory(item) {
      if (item.is_auto === CHAM_SOC) {
        return ARRAY_CATE_CHAMSOC[item.category_chamsoc];
      }
      return  item?.category_ticket?.name;
    },
    showModalDetail(ticket) {
      this.showModal = true;
      this.ticketObj = ticket;
    },

    showClassify(ticket) {
      let result = '';
      switch (ticket.is_auto) {
        case 1:
          result = 'Chăm sóc học viên';
          break;
          case 2:
          case 3:
          result = 'Yêu cầu hỗ trợ';
          break;
      }
      return result
    },
    onclickModalTicket() {
      this.showModal = false;
    },
    showUrl(is_auto){
      let result = '';
      switch (is_auto){
        case 1:
          result = 'ticket-show';
          break;
        case 2:
        case 3:
          result = 'crm-ticket-show';
          break;
      }
      return result;
    }
  }
}
</script>

<style scoped>

</style>
