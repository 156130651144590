<template>
  <div>
    <div class="">
      <div class="row justify-content-start">
        <div class="col-6">
          <h2 style="font-size: 16px">Lịch thi đấu</h2>
        </div>
      </div>
    </div>
    <div class="row justify-content-start">
      <div class="col-6">
        <div class="d-flex flex-row mb-3">
          <div class="">
            <template>
              <el-input placeholder="Tìm trận/team" v-model="keyword" @input="getListSchedule"></el-input>
            </template>
          </div>
          <div class="ml-2">
            <template>
              <el-select placeholder="Trạng Thái" @input="getListSchedule" v-model="status" clearable>
                <el-option
                    v-for="item in listStatus"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </template>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-md-12 col-sm-12">
        <div class="col-12 ">
          <div class="col-12 d-flex justify-content-end">
            <button type="button" class="btn btn-info btn-sm ml-5" @click="exportListSchedule">
              <div v-if="loadingExport">
                <i class="el-icon-loading"></i>
              </div>
              <div v-else>
                <i class="el-icon-download"></i>
                Export
              </div>
            </button>
            <ModalImportSchedule @getSchedule="getListSchedule"></ModalImportSchedule>
          </div>
        </div>
      </div>
    </div>
       <div class=" table-responsive" style="height: auto ; max-height: 60vh">
         <table class="table table-bordered " >
           <thead>
           <tr align-items class="table-active">
             <th scope="col">Trận</th>
             <th scope="col">Thời gian</th>
             <th scope="col">Đội thi đấu</th>
             <th scope="col">Mã đề</th>
             <th scope="col">MVP</th>
             <th scope="col">Xóa</th>
           </tr>
           </thead>
           <tbody v-if="listSchedule.length == 0">
           <tr class="text-center">
             <th colspan="6">Không có dữ liệu</th>
           </tr>
           </tbody>
           <tbody v-else>
           <tr v-for="item in listSchedule">
             <th scope="row"> {{ item.stt }}</th>
             <td>
               <p class="badge badge-info">{{ item.time }}</p>
               <p>{{ item.day }}</p>
             </td>
             <td>
               <div class="d-flex justify-content-between">
                 <p :class="nameClass(item.point1,item.point2,item.timeTeam1,item.timeTeam2,'team1',item.status)"
                    class=" mb-0" style="font-weight: bold !important;"><span style="font-size: 16px">➀</span><span class="ml-1">{{ item.team1 }}</span></p>
                 <p :class="nameClass(item.point1,item.point2,item.timeTeam1,item.timeTeam2,'team1',item.status)" style="font-weight: bold !important;">
                   {{ item.point1 }}</p>
               </div>
               <div class="d-flex justify-content-between mt-5">
                 <p :class="nameClass(item.point1,item.point2,item.timeTeam1,item.timeTeam2,'team2',item.status)"
                    class=" mb-0" style="font-weight: bold !important;"><span style="font-size: 16px">➁</span><span class="ml-1">{{ item.team2 }}</span></p>
                 <p  :class="nameClass(item.point1,item.point2,item.timeTeam1,item.timeTeam2,'team2',item.status)" style="font-weight: bold !important;">
                   {{ item.point2 }}</p>
               </div>
             </td>
             <td>
               {{ item.code }}
             </td>
             <td>
               <p class="px-2  " v-if="item.status" style="margin-bottom: 32px">➀ {{ item.mvp1 }}</p>
               <p class="px-2 mb-0  " v-if="item.status">➁ {{ item.mvp2 }}</p>
             </td>
             <td>
               <div class="d-flex justify-content-center align-items-center " style="height: 70px">
                 <button
                     v-if="!item.status"
                     class="btn btn-xs btn-icon  btn-outline-danger"
                     title="xóa"
                     @click="deleteSchedule(item.id, item.stt )">
                   <i class="fas fa-trash"></i>
                 </button>
               </div>
             </td>
           </tr>
           </tbody>
         </table>
       </div>

    <div class="d-flex justify-content-between">
      <div></div>
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
      Tổng số bản ghi: {{ total }}
    </div>
  </div>
</template>

<script>
import ModalImportSchedule from "@/view/pages/Tournaments/Component/ModalImportSchedule";
import {
  DELETE_SCHEDULE,
  DOWNLOAD_LIST_SCHEDULE,
  GET_SCHEDULE
} from "@/core/services/store/tournament/tournament.module";
import {CHUA_BAT_DAU_MATCH, DA_KET_THUC_MATCH, STATUS_MATCH} from "@/core/option/tournamentOption";
import moment from "moment-timezone";

export default {
  name: "ScheduleTournament",
  components: {ModalImportSchedule},
  data() {
    return {
      listSchedule: [],
      keyword: "",
      status: null,
      CHUA_BAT_DAU_MATCH: CHUA_BAT_DAU_MATCH,
      DA_KET_THUC_MATCH: DA_KET_THUC_MATCH,
      listStatus: STATUS_MATCH,
      page: 1,
      last_page: 0,
      total: 0,
      loadingExport: false

    }
  },
  mounted() {
    this.getListSchedule()
  },
  methods: {
    clickCallback(obj) {
      this.page = obj;
      this.getListSchedule()
    },
    exportListSchedule() {
      this.loadingExport = true
      let payload = {
        keyword: this.keyword,
        status: (this.status == null || this.status == ['']) ? [0, 1, 2] : [this.status],
        tournament_id: this.$route.params.id
      }
      this.$store.dispatch(DOWNLOAD_LIST_SCHEDULE, payload).then(() => {

      }).catch(() => {
      }).finally(() => {
        this.loadingExport = false
      })
    },
    deleteSchedule(id, stt) {
      this.$confirm(`Bạn có chắc chắn muốn xóa Trận ${stt} không?`, 'Xóa trận đấu', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
        confirmButtonClass: "bg-success border-white",
        type: 'Xóa giải đấu'
      }).then(() => {
        this.$store.dispatch(DELETE_SCHEDULE, id).then(() => {
          this.getListSchedule()
          this.$notify({
            title: 'Thành công',
            message: 'Xóa trận đấu thành công',
            type: 'success'
          });
        })
      })

    },
    getListSchedule() {
      let payload = {
        keyword: this.keyword,
        status: this.status == null||this.status === '' ? [0, 1, 2] : [this.status],
        tournament_id: this.$route.params.id,
        page : this.page
      }
      this.$store.dispatch(GET_SCHEDULE, payload).then((data) => {
        this.total = data.data.total;
        this.page = data.data.current_page;
        this.last_page = data.data.last_page;
        this.listSchedule = data.data.data.map((schedule) => {
          return {
            id: schedule.id,
            day: moment(schedule.day).format("DD/MM/YYYY"),
            stt: schedule.stt,
            time: schedule.start_time,
            code: schedule.code_exam,
            team1: schedule?.tournament_match_items[0]?.team?.name ?? null,
            team2: schedule?.tournament_match_items[1]?.team?.name ?? null,
            status: schedule.status,
            mvp1: schedule?.tournament_match_items[0]?.member_mvp?.name ?? null,
            mvp2: schedule?.tournament_match_items[1]?.member_mvp?.name ?? null,
            point1: schedule?.tournament_match_items[0]?.point ?? null,
            point2: schedule?.tournament_match_items[1]?.point ?? null,
            timeTeam1: schedule?.tournament_match_items[0]?.time ? schedule?.tournament_match_items[0]?.time / 1000 : null,
            timeTeam2: schedule?.tournament_match_items[1]?.time ? schedule?.tournament_match_items[1]?.time / 1000 : null,
          }
        }) ?? []

      })
    },
    checkColorTeamWin(scoreTeam1, scoreTeam2, timeTeam1, timeTeam2) {
      if (scoreTeam1 == 0 && scoreTeam2 == 0) {
        return "null"
      }
      if (scoreTeam1 > scoreTeam2 || (scoreTeam1 == scoreTeam2 && timeTeam1 < timeTeam2)) {
        return "team1"
      }
      return "team2"
    },
    nameClass(scoreTeam1, scoreTeam2, timeTeam1, timeTeam2, nameTeam, status) {
      if (this.checkColorTeamWin(scoreTeam1, scoreTeam2, timeTeam1, timeTeam2) == "null" && status == 2) {
        return ''
      }
      if (nameTeam == this.checkColorTeamWin(scoreTeam1, scoreTeam2, timeTeam1, timeTeam2) && status == 2) {
        return "success font-weight-bold"
      }
      if (nameTeam != this.checkColorTeamWin(scoreTeam1, scoreTeam2, timeTeam1, timeTeam2) && status ==2) {
        return "danger font-weight-bold"
      }

    }
  }
}
</script>

<style scoped>
.muted{

}
.success{
  color:  #00880E;
}
.danger{
  color: #CB0000;
;
}
</style>