<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">
          Xem chi tiết lớp học
        </h3>
      </div>
      <div class="card-toolbar">
        <router-link
            class="font-weight-bold font-size-3  btn btn-secondary"
            :to="{ name: 'class-index' }"
        ><i class="fas fa-angle-double-left"></i> Quay lại
        </router-link>
      </div>
    </div>
    <!--end::Header-->
    <!--end::body-->

    <div class="card-body">
      <div class="row">
        <div class="col-md-6"><h5>Thông tin lớp học</h5></div>
        <div class="col-md-5"><h5>Danh sách học viên</h5></div>
      </div>
      <div class="row">
        <div style="padding: 40px;margin: 20px 0px 0px 0px;" class="col-md-6 border border-light">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Tên lớp
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Tên lớp" rules="required"
                                    v-slot="{ errors,classes }">
                  <input disabled type="text" ref="name" name="name" class="form-control"
                         v-model="classes_obj.name"
                         placeholder="Tên lớp" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div>
                  <label>Khóa học
                    <span class="text-danger">*</span></label>
                </div>
                <div>
                  <el-select
                      v-model="classes_obj.course_id"
                      placeholder="Khóa học"
                      class="w-100"
                      disabled
                  >
                    <el-option v-for="item in courses"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id"
                               :options="courses"
                    >
                    </el-option>
                  </el-select>

                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Sĩ số
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Sĩ số" rules="required|min_value:0"
                                    v-slot="{ errors,classes }">
                  <input disabled type="number" ref="name" name="name" class="form-control"
                         v-model="classes_obj.number_student"
                         placeholder="Tên lớp" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">

            <div class="col-md-6">
              <div class="form-group">
                <label>Số bài học
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Số bài học" rules="required|min_value:0"
                                    v-slot="{ errors,classes }">
                  <input disabled type="number" ref="name" name="name" class="form-control"
                         v-model="classes_obj.number_lesson"
                         placeholder="Tên lớp" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Số bài kiểm tra
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Số bài kiểm tra" rules="required|min_value:0"
                                    v-slot="{ errors,classes }">
                  <input disabled type="number" ref="name" name="name" class="form-control"
                         v-model="classes_obj.number_test"
                         placeholder="Tên lớp" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">

                  <label>Chi nhánh
                    <span class="text-danger">*</span></label>

                <el-select  v-model="classes_obj.branch_id"
                            class="w-100"
                            disabled
                >
                  <el-option v-for="item in branches"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id"
                  >
                  </el-option>

                </el-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Giảng viên
                  <span class="text-danger">*</span></label>
                <el-select v-model="classes_obj.teacher_id"
                           class="w-100"
                           disabled
                >
                  <el-option v-for="item in teachers"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id"
                  >
                  </el-option>
                </el-select>

              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">

                  <label>Trợ giảng
                    <span class="text-danger"></span></label>
                  <el-select
                      v-model="classes_obj.tutors_id"
                      class="w-100"
                      disabled
                  >
                    <el-option v-for="item in tutors"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id"
                    >
                    </el-option>
                  </el-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                  <label>Phòng học
                    <span class="text-danger"></span></label>
                <el-select v-model="classes_obj.room_id"
                            class="w-100"
                            disabled
                >
                  <el-option v-for="item in rooms"
                              :key="item.id"
                              :label="item.name"
                             :value="item.id"
                  >

                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Offline/Online
                  <span class="text-danger"></span></label>
                <el-select v-model="classes_obj.form_study"
                           class="w-100"
                           disabled
                >
                  <el-option v-for="item in rooms"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id"
                  >

                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>
                  <span class="text-danger"></span></label>
                <div class="col-9 col-form-label">
                  <div class="checkbox-inline">
                    <label class="checkbox checkbox-success">
                      <input disabled type="checkbox" name="Checkboxes5" v-model="classes_obj.status">
                      <span></span>Hiển thị</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Lịch học
                  <span class="text-danger">*</span></label>
                  <el-select
                      disabled
                      v-model="cacheSchedule"
                      placeholder="Lịch học"
                      class="w-100"
                  >
                    <el-option v-for="item in schedules"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                    >
                    </el-option>
                  </el-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Khai giảng từ ngày
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Ngày bắt đầu" rules="required" v-slot="{ errors,classes }">
                  <date-picker disabled v-model="classes_obj.start_date" valueType="YYYY-MM-DD"
                               format="DD-MM-YYYY"
                               :class="classes"></date-picker>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Ngày kết thúc dự kiến
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Ngày kết thúc" rules="required" v-slot="{ errors,classes }">
                  <date-picker disabled v-model="classes_obj.end_date" valueType="YYYY-MM-DD"
                               format="DD-MM-YYYY"
                               :class="classes"></date-picker>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <ScheduleItem v-for="(item, index) in classes_obj.schedules"
                        :key="index"
                        :item="item"
                        :index="index"
          >
          </ScheduleItem>
        </div>

        <div style="padding: 40px;margin: 20px;" class="col-md-5 border border-light">
          <div class="example-preview table-responsive">
            <table class="table table-vertical-center table-bordered table-hover b-table">
              <thead class="thead-light">
              <tr>
                <th style="text-align: center" scope="col" class="min-w-20px">STT</th>
                <th style="text-align: center" scope="col" class="min-w-120px">Họ tên</th>
                <th style="text-align: center" scope="col" class="min-w-110px">Số điện thoại</th>
                <th style="text-align: center" scope="col" class="min-w-110px">Thời gian vào lớp</th>
                <th style="text-align: center" scope="col" class="min-w-150px">Người xếp lớp</th>
              </tr>
              </thead>
              <!--                     <tbody v-if="isBusy">-->
              <!--                     <tr role="row" class="b-table-busy-slot">-->
              <!--                       <td colspan="16" role="cell" class="text-center">-->
              <!--                         <div class="text-center text-danger my-2">-->
              <!--                           <b-spinner class="align-middle"></b-spinner>-->
              <!--                           <strong>Loading...</strong>-->
              <!--                         </div>-->
              <!--                       </td>-->
              <!--                     </tr>-->
              <!--                     </tbody>-->
              <tbody v-if="isBusy">
              <tr v-for="(item, index) in studentCourse" :key="index">
                <td>{{ ++index }}</td>
                <td>{{ item.user && item.user.name }}</td>
                <td>{{ item.user && item.user.phone }}</td>
                <td> {{ item.time_to_class ? item.time_to_class : '' }}</td>
                <td> {{ item.arranger && item.arranger.id }} - {{ item.arranger && item.arranger.name }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="7" class="text-center font-italic">Chưa có dữ liệu</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!--Thông tin cấp trên-->
      <div class="separator separator-dashed my-5"></div>
      <div class="card-header">
        <div class="card-title align-items-start flex-column">
          <div class="card-title">
            <b-button class="btn btn-success" @click="clickViewCourse">Xem lộ trình học</b-button>
          </div>
        </div>
      </div>
      <el-dialog title="Lộ trình học" :visible.sync="dialog" @close="closeModal">
        <a href="#" ref="a-tag"></a>
        <div class="row m-2 justify-content-between">
          <ClassScheduleCourseItem
              class="col-md-6"
              v-for="(item, index) in scheduleClass"
              :key="index"
              :item="item"
              :id="index"
              @indexItem="scrollToLesson"
              :items="scheduleClass">
          </ClassScheduleCourseItem>
        </div>
      </el-dialog>
    </div>
  </div>

</template>
<script>
import ScheduleItem from './ScheduleItem'
import Multiselect from 'vue-multiselect'
import ClassScheduleCourseItem from "@/view/pages/classes/ClassScheduleCourseItem";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_COURSES} from "../../../core/services/store/course/course.module";
import {Money} from 'v-money'
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
//editor
import ApiService from "@/core/services/api.service";
import {VueEditor, Quill} from 'vue2-editor'
import ImageResize from 'quill-image-resize-vue';
import {ImageDrop} from 'quill-image-drop-module';
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import {
  GET_TEACHERS_OR_TUTORS,
  GET_ClASS_BY_ID, UPDATE_CLASS, GET_ROOMS, SCHEDULE_OF_CLASS
} from "../../../core/services/store/course/classes.module";

const _ = require('lodash');

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
export default {
  components: {
    Multiselect,
    DatePicker,
    VueEditor,
    Money,
    ScheduleItem,
    ClassScheduleCourseItem
  },
  name: "ClassDetail",
  data() {
    return {
      scheduleClass: [],
      check: 0,
      clone: [],
      dialog: false,
      classroom_id: this.$route.params.id,
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      is_show_room: false,
      class: {
        name: ''
      },
      classes_obj: {
        name: '',
        course_id: '',
        form_study: '',
        number_student: '',
        number_lesson: '',
        number_test: '',
        room_id: '',
        branch_id: '',
        teacher_id: '',
        tutors_id: '',
        status: true,
        start_date: '',
        end_date: '',
        schedules: []
      },
      test: [1, 2, 3],
      isBusy: true,
      courses: [],
      branches: [],
      teachers: [],
      tutors: [],
      rooms: [],
      schedules: [
        {
          id: 1,
          name: 'Thứ 2'
        },
        {
          id: 2,
          name: 'Thứ 3'
        },
        {
          id: 3,
          name: 'Thứ 4'
        },
        {
          id: 4,
          name: 'Thứ 5'
        },
        {
          id: 5,
          name: 'Thứ 6'
        },
        {
          id: 6,
          name: 'Thứ 7'
        },
        {
          id: 7,
          name: 'Chủ nhật'
        },
      ],

      // Cache
      cacheCourse: [],
      cacheBranch: [],
      cacheTeacher: [],
      cacheTutors: [],
      cacheRoom: [],
      cacheSchedule: [],
      studentCourse: [],
    }
  },
  mounted() {
    this.getClassById()
    this.getCourses()
    this.getBranches()
    this.getTeachers()
    this.getTutors()
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý lớp học", route: 'class-index'},
      {title: "Xem lớp học"}
    ]);
  },
  computed: {},
  methods: {
    getClassById() {
      let id = this.$route.params.id;
      this.$store.dispatch(GET_ClASS_BY_ID, id).then((res) => {
        if (!res.error) {
          this.classes_obj = res.data
          console.log(this.classes_obj);
          this.updateCacheState(res.data)
          this.getRooms()
          this.studentCourse = res.data.course_students;
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getRooms() {
      this.$store.dispatch(GET_ROOMS, {
        limit: 10000,
        is_teacher: 1,
        branch_id: this.classes_obj.branch_id
      }).then((res) => {
        if (!res.error) {
          this.rooms = res.data.data
          this.rooms.unshift({
            name: '--Chọn--'
          })
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getTeachers() {
      this.$store.dispatch(GET_TEACHERS_OR_TUTORS, {
        limit: 10000,
        is_teacher: 1
      }).then((res) => {
        if (!res.error) {
          this.teachers = res.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getTutors() {
      this.$store.dispatch(GET_TEACHERS_OR_TUTORS, {
        limit: 10000,
        is_tutors: 1
      }).then((res) => {
        if (!res.error) {
          this.tutors = res.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },

    getCourses() {
      this.$store.dispatch(GET_COURSES, {
        per_page: 10000
      }).then((res) => {
        if (!res.error) {
          this.courses = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        status: 1
      }).then((res) => {
        if (!res.error) {
          this.branches = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    /*Set state*/
    setStateCourse($event) {
      let id = $event.id
      this.classes_obj.course_id = id
    },
    setStateBranch($event) {
      let id = $event.id;
      this.classes_obj.branch_id = id
      // SET room
      if (id != undefined) {
        this.is_show_room = true
        this.cacheRoom = [];
        this.classes_obj.room_id = '';
        this.getRooms()
      }
    },
    setStateTeacher($event) {
      if ($event == null) {
        this.classes_obj.teacher_id = ''
      } else {
        let id = $event.id
        this.classes_obj.teacher_id = id
      }
    },
    setStateTutors($event) {
      if ($event == null) {
        this.classes_obj.tutors_id = ''
      } else {
        let id = $event.id
        this.classes_obj.tutors_id = id
      }
    },
    setStateRoom($event) {
      let id = $event.id
      this.classes_obj.room_id = id
    },
    setStateSchedule($event) {
      this.addSchedule($event)
      this.minusSchedule($event)
    },
    /*End*/
    addSchedule(event) {
      event.map((value) => {
        let schedules = this.classes_obj.schedules
        let findId = _.findIndex(schedules, (o) => {
          return o.id == value.id;
        })
        if (findId == -1) {
          this.classes_obj.schedules.push({
            id: value.id,
            day: value.name,
            start_date: '',
            end_date: ''
          })
        }
      })
    },
    minusSchedule(event) {
      let schedules = this.classes_obj.schedules
      schedules.map((value, index) => {
        let findId = _.findIndex(event, (o) => {
          return o.id == value.id;
        })
        if (findId == -1) {
          schedules.splice(index, 1);
        }
      })
    },
    // onSubmit() {
    //   this.$store.dispatch(UPDATE_CLASS, this.classes_obj).then((res) => {
    //     if (res.error) {
    //       this.$bvToast.toast(res.message, {
    //         title: 'Cập nhật',
    //         variant: 'danger',
    //         solid: true
    //       });
    //     } else {
    //       this.$router.push({name: "class-index"}).then(() => {
    //         this.$bvToast.toast(res.message, {
    //           title: 'Cập nhật',
    //           variant: 'success',
    //           solid: true
    //         });
    //       })
    //     }
    //   }).catch((e) => {
    //     if (e.data.data.message_validate_form) {
    //       this.$refs.form.setErrors(e.data.data.message_validate_form);
    //     }
    //   });
    // },
    closeModal() {
    },
    clickViewCourse() {
      this.dialog = true;
      this.callApiScheduleOfClass();
    },
    callApiScheduleOfClass() {
      let payload = {
        classroom_id: Number(this.classroom_id)
      }
      this.$store.dispatch(SCHEDULE_OF_CLASS, payload).then((res) => {
        this.scheduleClass = res.data;
        this.clone = res.data;
      })
    },
    updateCacheState(data) {
      if (data.course != null) {
        this.cacheCourse = data.course
      }
      if (data.branch != null) {
        this.cacheBranch = data.branch
      }
      if (data.teacher != null) {
        this.cacheTeacher = data.teacher
      }
      if (data.tutors != null) {
        this.cacheTutors = data.tutors
      }
      if (data.room != null) {
        this.cacheRoom = data.room
      }
      if (data.schedules != null) {
        data.schedules.map((value, index, array) => {
          return value.name = value.day
        })
        this.cacheSchedule = data.schedules.map( (schedule) => schedule.day)
      }
    },
    scrollToLesson(id) {
      setTimeout(() => {
        this.$refs["a-tag"].setAttribute('href', `#${id}`);
        this.$refs["a-tag"].click();
      }, 500)
    },
  }
}
</script>
<style>
.mx-datepicker {
  width: 100% !important;
}

.mx-input:disabled, .mx-input.disabled {
  background-color: #F3F6F9
}

#test {
  background: #00c0ef;
}

.multiselect__tags {
  min-height: 32px;
  display: block;
  padding: 3px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
