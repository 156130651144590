<template>
    <div>
        <div>
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(handleCreatePromotion)">
                <div style="width: 100%; height: 1px; background: gray; margin-top: -20px; margin-bottom: 20px"></div>
                <div style="display: grid; grid-template-columns: 6fr 6fr; gap: 40px">
                  <ValidationProvider name="Tên chương trình" rules="required|max:50" v-slot="{ errors,classes }" vid="name">
                    <div style="display: flex; flex-direction: column; gap: 10px">
                      <span class="page-filter__title">Tên chương trình <span style="color: red">*</span></span>
                      <el-input size="medium" v-model="localDataProgram.name" placeholder="Nhập tên chương trình"/>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </div>
                  </ValidationProvider>
                  <div class="d-flex">
                  <ValidationProvider name="Start time chương trình" rules="required" v-slot="{ errors, classes }" vid="start_date">
                    <div style="display: flex; flex-direction: column; gap: 10px">
                      <span class="page-filter__title">Thời gian bắt đầu<span style="color: red">*</span></span>
                            <el-date-picker
                            v-model="localDataProgram.start_date"
                            type="datetime"
                            placeholder="Sửa thời gian bắt đầu">
                          </el-date-picker>
                      
                      <div class="fv-plugins-message-container">
                        <div data-field="start_date" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </div>
                  </ValidationProvider>
                  <div class="mr-2 ml-2 d-flex align-items-center">
                    <h1>-</h1>
                    </div>
                  <ValidationProvider name="End time thúc chương trình" rules="required" v-slot="{ errors, classes }" vid="end_date">
                    <div style="display: flex; flex-direction: column; gap: 10px">
                      <span class="page-filter__title">Thời gian kết thúc <span style="color: red">*</span></span>
                        <div class="d-flex">
                            <el-date-picker
                            v-model="localDataProgram.end_date"
                            type="datetime"
                            placeholder="Sửa thời gian kết thúc">
                            </el-date-picker>
                          <div class="mr-2 ml-2">
                          </div>
                        </div>
                      
                      <div class="fv-plugins-message-container">
                        <div data-field="end_date" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </div>
                  </ValidationProvider>
                </div>

                </div>
                <div style="display: flex; flex-direction: column; gap: 10px; margin-top: 15px">
                  <span class="page-filter__title">Mô tả</span>
                  <el-input size="small" type="textarea" v-model="localDataProgram.description" placeholder="Nhập mô tả"/>
                </div>
                <div style="display: flex; justify-content: end; margin-top: 30px; gap: 10px">
                  <el-button size="mini" style="background: #CACACA" @click="closeDialog()">Hủy bỏ</el-button>
                  <button
                      @click="handleEdit"
                      :disabled="loading"
                      style="background: #5D04B0; padding: 0 10px; border-radius: 5px; color: white; position:relative;"
                  >
                    <i v-if="loading" class="el-icon-loading text-white float-righ"></i>
                    Xác nhận
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
    </div>
  </template>
  
  <script>
  import moment from 'moment-timezone';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import { UPDATE_PROGRAM_GENZ } from '../../../../core/services/store/rewards-programs/rewardsPrograms.module';
  export default {
    components: {
      DatePicker
    },
    props: {
      dataProgram: {
        type: Array,
        default: [],
      },
    },
    data() {
    return {
      loading: false,
      localDataProgram: { ...this.dataProgram },
    };
  },
  watch: {
    dataProgram: {
      handler(newVal) {
        this.localDataProgram = { ...newVal };
      },
      deep: true,
    },
  },

    methods: {
      handleEdit() {
      let result = this.validator();
      if(!result) return;
      this.loading = true;
      const payload = {
        name: this.localDataProgram.name,
        center_id: this.localDataProgram.centerId,
        start_date: moment(this.localDataProgram.start_date).format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(this.localDataProgram.end_date).format('YYYY-MM-DD HH:mm:ss'),
        description: this.localDataProgram.description,
        id: this.$route.query.promotion_id
      }
      
      this.$store.dispatch(UPDATE_PROGRAM_GENZ, payload).then((data) => {
        if(!data.error) {
          this.$notify({
          title: 'Thành công!',
          message: 'Sửa thông tin chương trình thành công ',
          type: 'success'
        });
        this.closeDialog();
        }
      }).catch((e) => {
       if(e.status === 400) {
        this.$notify({
          title: 'Thất bại',
          message: 'Đã tồn tại chương trình nằm trong khoảng thời gian này. Vui lòng chọn lại ',
          type: 'error'
        });
        this.closeDialog();
       }else {
        this.$notify({
          title: 'Thất bại',
          message: 'Sửa thông tin chương trình thất bại ',
          type: 'error'
        });
       }
      })
      this.$emit('hanldeLocalDataProgram',)
      this.closeDialog();
    },

    validator() {
      let start_date = this.localDataProgram.start_date ? moment(this.localDataProgram.start_date).format('YYYY-MM-DD HH:mm:ss') : '';
      let end_date = this.localDataProgram.end_date ? moment(this.localDataProgram.end_date).format('YYYY-MM-DD HH:mm:ss') : '';
      let name = this.localDataProgram.name;
      if(!name || !start_date | !end_date) {
          this.$notify({
          title: 'Thiếu dữ liệu',
          message: 'Vui lòng nhập đủ dữ liệu',
          type: 'warning'
        });
        return false;
      }

      if(moment(start_date).isAfter(moment(end_date)) && start_date && end_date) {
        this.$notify({
          title: 'Dư liệu sai!',
          message: 'Thời gian bắt đầu không được lớn hơn Thời gian kết thúc',
          type: 'warning'
        });
        this.localDataProgram.start_date = '';
        this.localDataProgram.end_date = '';
        return false;
      }
      return true;
    },

    closeDialog() {
      this.loading = false;
      this.$emit('closeDialogEdit',)
    },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>