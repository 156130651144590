<template>
    <div class="row">

        <div class="col-xl-6 mt-3">
        <label for="">Mã tác vụ</label>
          <el-input disabled class="w-100" :value="taskInfo?.task_id"></el-input>
        </div>
        <div class="col-xl-6 mt-3">
            <label for="">Loại tác vụ</label>
            <el-input disabled class="w-100" :value="taskInfo?.type"></el-input>
        </div>

        <div class="col-xl-6 mt-3">
            <label for="">Khóa học cần xếp lớp</label>
            <el-input disabled class="w-100" :value="taskInfo?.course_name"></el-input>
        </div>

        <div class="col-xl-6 mt-3">
            <label for="">Chi nhánh học</label>
            <el-input disabled class="w-100" :value="taskInfo?.branch_name"></el-input>
        </div>
      <div class="col-xl-6 mt-3">
        <label for="">Người phụ trách tác vụ</label>
        <el-input disabled class="w-100" :value="taskInfo?.responsible"></el-input>
      </div>
      <div class="col-xl-6 mt-3">
        <label for="">Thời gian tạo</label>
        <el-input disabled class="w-100" :value="taskInfo?.created_at"></el-input>
      </div>
      <div class="col-xl-6 mt-3">
          <label for="">Tư vấn viên</label>
          <div  disabled class="d-flex justify-content-between w-100 rounded input-detail">
            <span>{{ taskInfo?.nguoi_tu_van?.name}}</span>
            <span class="show_detail" @click="dialogVisible = true" v-if="taskInfo?.nguoi_tu_van?.name">Xem chi tiết</span>
          </div>
        </div>
      <div class="col-xl-6 mt-3">
        <label for="">Họ tên khách hàng</label>
        <div  disabled class="d-flex justify-content-between w-100 rounded input-detail">
          <span>{{ taskInfo?.user_name }}</span>
          <span class="show_detail" @click="navigateDetailCustomer(taskInfo)" >Xem chi tiết</span>
        </div>
      </div>
      <div class="col-xl-6 mt-3">
        <label for="">Số điện thoại đăng ký</label>
        <el-input disabled class="w-100" :value="taskInfo?.Phone"></el-input>
      </div>
      <div class="col-xl-6 mt-3">
        <label for="">Số điện thoại liên hệ</label>
        <el-input disabled class="w-100" :value="taskInfo?.user_phone_other"></el-input>
      </div>
      <div class="col-xl-6 mt-3">
          <label for="">Nhà mạng</label>
          <el-input disabled class="w-100" :value="taskInfo?.network"></el-input>
        </div>
      <el-dialog
          title="Thông tin tư vấn viên"
          :visible.sync="dialogVisible"
          width="30%"
          top="30vh"
      >
        <div class="d-flex">
          <div>
            <div class="col-xl-12 mt-3">
              <label for="">Họ và tên</label>
              <el-input disabled class="w-100" :value="taskInfo?.nguoi_tu_van?.name"></el-input>
            </div>

            <div class="col-xl-12 mt-3">
              <label for="">Khu vực làm việc</label>
              <el-input disabled class="w-100" :value="taskInfo?.nguoi_tu_van?.area_name"></el-input>
            </div>
          </div>
          <div>
            <div class="col-xl-12 mt-3">
              <label for="">Số điện thoại liên hệ</label>
              <el-input disabled class="w-100" :value="taskInfo?.nguoi_tu_van?.phone_other"></el-input>
            </div>

            <div class="col-xl-12 mt-3">
              <label for="">Văn phòng làm việc</label>
              <el-input disabled class="w-100" :value="taskInfo?.nguoi_tu_van?.branch_name"></el-input>
            </div>
            <div class="col-xl-12 mt-3">
              <label for="">Văn phòng làm việc</label>
              <el-input disabled class="w-100" :value="taskInfo?.nguoi_tu_van?.branch_name"></el-input>
            </div>
          </div>
        </div>
        <div style="text-align: right; margin-right: 11px" class="mt-3">
          <button type="button" class="btn btn-secondary" @click="dialogVisible = false">Đóng</button>
        </div>
      </el-dialog>








    </div>
  </template>

<script>
import { TYPE_TASK_DIFINE } from '@/core/option/taskArrangePersonal';
export default {
  name: "infoLayering",
  props: {
    taskInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  components: {},
  data() {
    return {
      typeTaskDifine:TYPE_TASK_DIFINE,
      dialogVisible: false
    }
  },
  mounted() {
    
  },

  methods: {
    navigateDetailCustomer(taskInfo){
      window.open(this.$router.resolve({ name: 'customer-crm-detail', params: { id : taskInfo.user_id }, query: {best_customer_id: taskInfo.best_customer_id}}
      ).href,`_blank`)
    }
  },
};
</script>

<style scoped>
.show_detail {
      color:rgba(0, 102, 255, 1);
      text-decoration: underline;
      cursor: pointer;
      transition: 0.3s;
    }

    .show_detail:hover  {
        color: rgb(0, 0, 255);
    }
</style>