import ApiService from "@/core/services/api.service";
import {DELETE_NEWS} from "@/core/services/store/news/news.module";

export const CITIME_COMBO_CREATE = "citime_combo_create";
export const CITIME_COMBO_INDEX = "citime_combo_index";
export const CITIME_COMBO_DELETE = "citime_combo_delete";

const state = {};

const getters = {};
const actions = {
    [CITIME_COMBO_CREATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('citime/citimes/save', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [CITIME_COMBO_INDEX](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('citime/citimes', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [CITIME_COMBO_DELETE](context, id) {
        return new Promise(resolve => {
            ApiService.delete('citime/citimes', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    }
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
