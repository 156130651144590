<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(handleCreateCategory)">
        <div style="width: 100%; height: 1px; background: gray; margin-top: -20px; margin-bottom: 20px"></div>
        <div style="display: grid; grid-template-columns: 6fr 6fr; gap: 40px">
          <ValidationProvider name="Tên khuyến mại" rules="required" v-slot="{ errors }" vid="name">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Tên khuyến mại <span style="color: red">*</span></span>
              <el-input size="small" v-model="query.name" placeholder="Nhập tên khuyến mại"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider name="Giá trị giảm" rules="required" v-slot="{ errors }" vid="name">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Giá trị giảm <span style="color: red">*</span></span>
              <el-input size="small" v-model="query.value_reduce" placeholder="Nhập giá trị giảm"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
         
          <ValidationProvider name="Giá còn lại" rules="required" v-slot="{ errors }" vid="name">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Giá còn lại <span style="color: red">*</span></span>
              <el-input size="small" v-model="query.value_remaining" placeholder="Nhập giá còn lại"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider name="Mô tả thêm" rules="required" v-slot="{ errors }" vid="name">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Mô tả thêm<span style="color: red"></span></span>
              <el-input size="small" v-model="query.descrition" placeholder="Nhập mô tả thêm"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
        </div>

        <div style="display: flex; justify-content: end; margin-top: 20px; gap: 10px">
          <el-button size="mini" style="background: rgba(202, 202, 202, 1); border-radius: 5px;" @click="closeDialog()">Hủy bỏ</el-button>
          <el-button
              size="mini"
              :disabled="loading"
              style="background: #5D04B0; border-radius: 5px; color: white; position:relative;">
            <i v-if="loading" class="el-icon-loading text-white float-righ"></i>
            Xác nhận
          </el-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {CREATE_HONOR_CATEGORY} from "@/core/services/store/honors/honor.module";

export default {
  props: {
    addCategory: {
      type: Boolean,
      required: true,
      default: false,
    },
    parentId: {
      type: Number,
      required: true,
      default: null,
    }
  },
  data() {
    return {
     query: {
      name: '',
      value_reduce: '',
      value_remaining: '',
      descrition: ''
     },
      loading: false,
    }
  },
  methods: {
    handleCreateCategory() {
      this.loading = true;
      this.loading = true
      const payload = {
        "honors_program_id": this.parentId,
        "category_name": this.name,
        "honor_category_type": this.type,
        "category_desc": this.description,
      }
      this.$store.dispatch(CREATE_HONOR_CATEGORY, payload).then(() => {
        this.$message.success('Thêm hạng mục thành công');
        this.closeDialog();
        this.$emit('getListCategory');
        this.addCategory = false;
      })
    },
    closeDialog() {
      this.loading = false;
      this.$emit('closeDialog');

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
