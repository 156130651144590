<template>
  <div class="card mt-5 p-10 ">
    <div class="d-flex justify-content-between Information">
      <h1 style="font-size: 15px ; font-weight: bolder">Danh sách tin tức</h1>
      <ModalAddNew @getListNews="getListNews()"></ModalAddNew>
    </div>
    <div class="mt-5 table-responsive" style="height: auto ; max-height: 300px">
      <table class="table table-bordered  table-vertical-center ">
        <thead>
        <tr class="" style="background-color: #F8F8F8 ; font-size: 13px">
          <th>Thứ tự</th>
          <th>Tiêu đề</th>
          <th>Loại tin tức</th>
          <th>Ảnh</th>
          <th>Hành động</th>
        </tr>
        </thead>
        <tbody v-if="listNews.length == 0">
        <tr>
          <td colspan="5" class="text-center">
            Không có dữ liệu
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr v-for="(item, index) in listNews" :key="index">
          <td>{{ item.order }}</td>
          <td>{{ item.title }}</td>
          <td>{{ TYPE_NEWS_DEFINE[item.type] }}</td>
          <td>
            <img :src="item.image" style="width: 100px ; height: 50px">
          </td>
          <td>
            <button class="btn btn-xs btn-icon mr-2 btn-outline-primary"
                    title="Chỉnh sửa tin tức"
                    @click="EditNews(item.idNews)"
            >
              <i class="flaticon2-pen"></i>
            </button>
            <button class="btn btn-xs btn-icon mr-2 btn-outline-danger"
                    title="xóa"
                    @click="deleteNew(item.idNews)"
            >
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-0 d-flex flex-row-reverse mr-10">
      <p class="mb-0">Tổng số bản ghi: <span class="text-primary ">{{ total }}</span></p>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
    <ModalEditNew :idNews="idNews" @getListNews="getListNews()"></ModalEditNew>
  </div>
</template>

<script>
import ModalAddNew from "@/view/pages/Tournaments/Component/ModalAddNew";
import {DELETE_NEWS, GET_NEWS} from "@/core/services/store/tournament/tournament.module";
import ModalEditNew from "@/view/pages/Tournaments/Component/ModalEditNew";
import {TYPE_NEWS_DEFINE} from "@/core/option/tournamentOption";

export default {
  name: "listNews",
  components: {ModalEditNew, ModalAddNew},
  data() {
    return {
      listNews: [],
      TYPE_NEWS_DEFINE: TYPE_NEWS_DEFINE,
      idNews: null,
      page: 1,
      last_page: 0,
      total: 0
    }
  },
  mounted() {
    this.getListNews()
  },
  methods: {
    getListNews() {
      let payload = {
        tournament_id: this.$route.params.id,
        page: this.page
      }
      this.$store.dispatch(GET_NEWS, payload).then((data) => {
        this.listNews = data.data.map((news) => {
          return {
            order: news.stt,
            title: news.title,
            type: news.type,
            image: news.img_new.length ? news.img_new[0].url : '',
            idNews: news.id
          }
        }).sort((a, b) => {
          return b.order - a.order;
        });
        this.total = data.pagination.total;
        this.page = data.pagination.page;
        this.last_page = data.pagination.last_page;
      })

    },
    deleteNew(id) {
      this.$confirm('Bạn có chắc chắn muốn xóa tin Kết quả vòng bảng này không?', 'Xóa tin tức', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
        confirmButtonClass: "bg-success border-white",
        type: 'Xóa tin tức',
        lockScroll : false
      }).then(() => {
        this.$store.dispatch(DELETE_NEWS, id).then(() => {
          this.getListNews()
          this.$notify({
            title: 'Thành công',
            message: 'Xóa tin tức thành công',
            type: 'success',
          });
        }).catch(() => {
          this.$notify({
            title: 'Lỗi',
            message: 'Xóa không thành công',
            type: 'error'
          });
        })
      }).catch(() => {

      });
    },
    EditNews(id) {
      this.idNews = id
      setTimeout(() => {
        this.$bvModal.show("Modal_Edit_News")
      }, 80)
    },
    clickCallback(obj) {
      this.page = obj;
      this.getListNews();
    }
  }
}
</script>

<style scoped>

</style>