<template>
  <div class="list-courses col-md-12">
    <span v-if="!disable && !isWithdrawCourse(index)" title="Xóa" @click="deleteCourse(index)" class="delete-course"><i class="fas fa-minus-circle mr-2" style="cursor: pointer;" ></i></span>
        <div class="row col-md-12">
          <div class="form-group col-md-6">
            <label for="course">Khóa học</label>
            <ValidationProvider name="khóa học" vid="course" rules="required" v-slot="{ errors, classes }">
                <el-select
                          clearable filterable
                          id="course"
                          v-model="course_id"
                          class="w-100"
                          :class="classes"
                          placeholder="Chọn khóa học"
                          :disabled="disable || isWithdrawCourse(index)"
                          @change="selectCourse"
                >
                  <el-option v-for="item in courses"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              <span class="badge badge-danger withdraw-course" v-if="isWithdrawCourse(index)">Đã hủy</span>
              <div class="fv-plugins-message-container">
                <div data-field="course" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="form-group col-md-6" v-if="!is_create">
            <label>Chi nhánh
              <span class="text-danger"></span></label>
            <el-select
              class="w-100"
              filterable
              v-model="branch_id"
              placeholder="Tên chi nhánh"
              @change="changeBranch"
              :disabled="disable || isWithdrawCourse(index)"
            >
              <el-option
                v-for="item in branchs"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
      </div>
  </div>
</template>
<script>
  import '@/assets/sass/custom/custom_course_type.scss';
  import {GET_CLASSES} from "@/core/services/store/course/classes.module";
  const _ = require('lodash');
  export default {
    name: "CourseClass",
    props: {
      showModal: { default: false },
      disable : { default :false },
      course_infor : {
          type: Object,
          default(){
            return {};
          }
        },
      contract: {
        type: Object,
        default: () =>{ return {} }
      },
      condition_courses : {
          type: Array,
          default(){
            return [];
          }
        },
      index: null,
      center_classes: {
        type: Array,
        default(){
          return [];
        }
      },
      courses: {
        type: Array,
        default(){
          return [];
        }
      },
      coursesRefund: {
        type: Array,
        default: () => { return [] }
      },
      isTransferred: {
        type: Boolean,
        default: () => { return false }
      },
      is_create: {
        type: Boolean,
        default: () => { return false }
      },
      branchs : {
        type: Array,
        default(){
          return () => { return [] }
        }
      },
    },
    data() {
      return {
        course_id: '',
        class_id: '',
        cache_course : '',
        cache_class: '',
        classesByCourse: [],
        branch_id: '',
      }
    },
    methods : {
      selectCourse: function ($event){
        let course_id = $event;
        if (course_id !== ''){
          this.$store.dispatch(GET_CLASSES, {
            course_id: course_id,
            limit: 100000,
            status: 1,
            type: 'official',
            branch_id: this.contract.branch_id,
            center_id: this.contract.center_id,
          }).then((res)=> {
            this.class_id = '';
            this.classesByCourse = res.data.data;
          });
          this.$emit('selectCourse', {course_id: course_id, branch_id: this.branch_id, index: this.index})
        }else{
          this.classesByCourse = [];
        }
      },
      selectClass: function ($event){
          this.$emit('selectClass', {class_id: $event, index: this.index})
      },
      isWithdrawCourse(index) {
        return this.coursesRefund.filter(item => this.condition_courses[index]?.id === item.id).length > 0 && this.isTransferred;
      },
      deleteCourse(index) {
        this.$emit('deleteCourse', index)
      },
      changeBranch()
      {
        this.$emit('selectCourse', {course_id: this.course_id, branch_id: this.branch_id, index: this.index})
      }
    },
    mounted() {
      if (this.course_infor.course_id){
        this.$store.dispatch(GET_CLASSES, {
          course_id: this.course_id,
          limit: 100000,
          status: 1,
          type: 'official',
          branch_id: this.contract.branch_id,
          center_id: this.contract.center_id,
        }).then((res)=> {
          this.classesByCourse = res.data.data;
        });
      }
      this.classesByCourse = this.center_classes;
    },
    watch: {
      course_infor: {
        handler(){
          this.course_id = this.course_infor.course_id ? this.course_infor.course_id : null;
          this.branch_id = this.is_create ? this.course_infor.branch_id : (this.course_infor.chi_nhanh_hoc ? this.course_infor.chi_nhanh_hoc : null);
          this.class_id = this.course_infor.class_id ? this.course_infor.class_id : '';
        },
        deep:true,
        immediate: true
      }
    }
  }
</script>
<style scoped>
</style>
