<template>
  <div>
    <button type="button" class="btn btn-success btn-sm ml-4" v-b-modal="`Modal_Import_Team`">
      <i class="el-icon-upload2"></i>
      Import
    </button>
    <b-modal id="Modal_Import_Team" title="Import team tham gia giải đấu" centered hide-footer size="lg"
             ref="Modal_Import_Team" @hidden="hideModal">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent v-if="!tableError">
          <div>
            <div class="row import">
              <div class="col-md-12">
                <p>Bước 1: Tải file mẫu</p>
                <button type="button" class="btn btn-info btn-sm" @click="downloadFile()"><i
                    class="flaticon-download"></i>Tải
                  file mẫu
                </button>
              </div>
              <div class="col-md-12 mt-4">
                <p>Bước 2: Tải file excel lên hệ thống</p>
                <div class="file-input">
                  <ValidationProvider name="type_task" rules="required|max:30" v-slot="{ errors,classes }"
                                      vid="type_task">
                    <input
                        ref="fileTeam"
                        type="file"
                        name="file-input-team"
                        id="file-input-team"
                        class="file-input__input"
                        @change="getDataFile"
                        :disabled="disableImport"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                    <label class="file-input__label" for="file-input-team">
                      <span><i class="flaticon-upload mr-2" style="color: #000000"></i>Tải lên</span></label
                    >
                  </ValidationProvider>
                  <div v-if="fileDataImport">
                    <p class="badge badge-secondary  p-2 ">{{ fileDataImport.name }}</p>
                    <i class="fas fa-trash ml-3 text-danger  " style="font-size: 13px; cursor: pointer"
                       @click="deleteFileExcel()"></i>
                  </div>
                  <div v-else class="text-danger">
                    vui lòng chọn file
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex d-flex flex-row-reverse">
            <button type="button" class="btn btn-success ml-4" @click="previewImportExcel()" :disabled="disableSave">
              Xác nhận
            </button>
            <button type="button" class=" btn btn-secondary" @click="cancelModal()">Hủy</button>
          </div>
        </form>
        <div v-else>
          <p>Hệ thống phát hiện có lỗi trong quá trình import dữ liệu:</p>
          <div class="mt-5 table-responsive">
            <table class="table table-bordered  table-vertical-center ">
              <thead>
              <tr class="" style="background-color: #F8F8F8 ; font-size: 13px">
                <th>STT</th>
                <th>Team</th>
                <th>Lỗi</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(error,index) in errorTeams">
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-center">{{ error?.team[0]?.Team }}</td>
                <td>
                  <p class="text-danger" v-for="item in error.error"> {{ item }}</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary" @click="comeback()">Quay lại</button>
          </div>
        </div>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import {utils, read} from "xlsx";
import {groupBy} from "core-js";

import {DOWNLOAD_TEAM, IMPORT_TEAM} from "@/core/services/store/tournament/tournament.module";

export default {
  name: "ModalImportTeam",
  data() {
    return {
      fileDataImport: null,
      importData: null,
      disableImport: false,
      errorTeams: [],
      tableError: false,
      disableSave: false
    }
  },
  methods: {
    hideModal() {
      this.fileDataImport = null
      this.importData = null
      this.disableImport = false
      this.errorQuestions = []
      this.tableError = false
      this.disableSave = false
    },
    async getDataFile(e) {
      if (e.target.files.length) {
        this.disableImport = true
        this.fileDataImport = e.target.files[0]
        this.importData = await this.fileToJson(this.fileDataImport)
        this.importData = Object.values(this.importData.groupBy((member) => member.Team))
      }
    },
    fileToJson(file) {
      return new Promise((res, rej) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader()
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result
          const wb = read(bstr, {type: 'binary'})
          /* Get first worksheet */
          const teamName = wb.SheetNames[0]
          const teamSheet = wb.Sheets[teamName]
          /* Convert array of arrays */
          const team = utils.sheet_to_json(teamSheet)
          /* Update state */
          res(team.filter((d) => d != []));
          //const header = data.shift()
        }
        reader.onerror = (e) => {
          rej(e);
        };
        reader.readAsBinaryString(file)
      });
    },
    checkErrorDataExel() {
      this.errorTeams = this.importData.map((team) => {
        return {team: team, error: this.checkTeamError(team)};
      }).filter((d) => d.error.length > 0)
    },
    checkTeamError(team) {
      let errors = [];
      if (team.length < 2) {
        errors = [...errors, "Team chưa đạt số lượng tối thiểu (2 người)"]
      }
      let countTeamCaptain = team.filter((member) => member.Captain == 1)
      if (countTeamCaptain.length == 0) {
        errors = [...errors, "Thiếu đội trưởng"]
      }
      if (countTeamCaptain.length > 1) {
        errors = [...errors, "Team có nhiều hơn 1 đội trưởng"]
      }
      if (team[0]?.Team?.length > 40) {
        errors = [...errors, "Tên team vượt quá giới hạn 40 kí tự"]
      }
      if (team[0] == '') {
        errors = [...errors, "Thiếu tên team"]
      }
      let checkInformation = false
      let checkPhone
      team.forEach((member) => {
        let vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
        checkPhone = vnf_regex.test(member.Phone)
        let fieldErrors = ["member_name", "Phone", "Captain", "Character_name"].map(d => {
          if (member[d] == undefined) {
            return "lỗi";
          }
        }).filter((a) => a != undefined)
        if (fieldErrors.length > 0) {
          checkInformation = true
          return
        }
      })
      if (checkInformation) {
        errors = [...errors, "Tồn tại thành viên thiếu thông tin họ tên/tên nhân vật/số điện thoại/vị trí"]
      }
      if (checkPhone == false) {
        errors = [...errors, "Tồn tại thành viên sai định dạng số điện thoại"]
      }
      return errors;
    },
    deleteFileExcel() {
      this.disableImport = false
      this.importData = null
      this.fileDataImport = null
      this.$refs.fileTeam.value = null;
    },
    previewImportExcel() {
      if (this.importData == null) {
        return
      }
      this.checkErrorDataExel()
      this.disableSave = true
      let is_error = false
      if (this.errorTeams.length > 0) {
        this.tableError = true
        is_error = true
      }

      let payload = {
        id: this.$route.params.id,
        data: {
          data: this.importData.reduce((a, b) => a.concat(b)).map((d) => {
            return {
              name_team: d.Team,
              member_name: d.member_name,
              phone: d.Phone,
              is_captain: d.Captain,
              name_in_game: d.Character_name
            }
          }),
          is_error: is_error
        }

      }
      this.$store.dispatch(IMPORT_TEAM, payload).then((data) => {
        if (!data.error) {
          this.$emit("getExam")
          this.$notify({
            title: 'Thành công',
            message: 'Import Team thành công, vui lòng chờ đợi trong giây lát và reload lại trang',
            type: 'success'
          });
          this.$refs['Modal_Import_Team'].hide()
          this.$emit("getTeam")
        } else {
          this.$notify({
            title: 'Lỗi',
            message: `${data.message}`,
            type: 'error'
          });
        }
      }).catch((error) => {
        this.errorTeams = error.data.data.filter((e) => e.errors != false).map((d) => {
          return {
            team: [{Team: d.name_team}],
            error: d.errors
          }
        }).concat(this.errorTeams)
        this.tableError = true
        this.disableSave = false
      })
    },
    cancelModal() {
      this.$refs['Modal_Import_Team'].hide()
    },
    comeback() {
      this.tableError = false
      this.disableSave = false
    },
    downloadFile() {
      this.$store.dispatch(DOWNLOAD_TEAM).then(() => {

      }).catch(() => {

      })
    },
  }
}
</script>

<style scoped>
.file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.file-input__label {
  height: 34px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 7px 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  background-color: #FF9900;
}

input:disabled + label {
  background-color: #a6a6a6;
}

</style>