<template>
  <b-modal @hide="onHide" size="xl" :centered="true" :scrollable="true" ref="modal-employees-on-staff-detail"
           hide-footer :title="title" class="modal-employees-on-staff-detail">
    <div class="d-block text-center">
      <div class="row">
        <div class="col-md-12">
          <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'bottom',
                dropdownAllowAll: true,
                setCurrentPage: 1,
                nextLabel: 'Sau',
                prevLabel: 'Trước',
                rowsPerPageLabel: 'Số bản ghi',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'Tất cả',
              }"
          />
        </div>
      </div>
    </div>
    <footer class="modal-footer">
      <button type="button" class="btn btn-secondary" @click="hideModal">Hủy bỏ</button>
    </footer>
  </b-modal>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import {VueGoodTable} from 'vue-good-table';

const _ = require('lodash');
export default {
  name: "RegisterOnStaff",
  props: {
    showModal: {default: false},
    typeOnStaff: {default: false},
    dataDetail: {
      type: [Array, Object],
      default() {
        return [];
      }
    },
  },
  data() {
    return {
      employeeRegisters: [],
      rows: [],
      title: '',
      query: {
        stt: '',
        user_id: '',
        user_name: '',
        morning: '',
        afternoon: '',
        evening: '',
        center_name: ''
      },
      columns: [
        {
          label: 'STT',
          field: 'stt',
          tdClass: 'text-center',
        },
        {
          label: 'Mã nhân viên',
          field: 'user_id',
          type: 'number',
          tdClass: 'text-center',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Tìm kiếm mã nhân viên', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            trigger: '', //only trigger on enter not on keyup
          },
        },
        {
          label: 'Tên',
          field: 'user_name',
          tdClass: 'text-center',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Tìm kiếm tên nhân viên', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            trigger: '', //only trigger on enter not on keyup
          },
        },
        {
          label: 'Sáng',
          field: 'register_morning',
          html: true,
          tdClass: 'text-center',
        },
        {
          label: 'Chiều',
          field: 'register_afternoon',
          html: true,
          tdClass: 'text-center',
        },
        {
          label: 'Tối',
          field: 'register_evening',
          html: true,
          tdClass: 'text-center',
        },
        {
          label: 'Văn phòng',
          field: 'branch_name',
          tdClass: 'text-center',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Tìm kiếm văn phòng', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            trigger: '', //only trigger on enter not on keyup
          },
        },
      ],
    }
  },
  components: {VueGoodTable},
  computed: {

  },
  methods: {
    hideModal: function () {
      this.$refs['modal-employees-on-staff-detail'].hide();
    },
    onHide: function (evt) {
      this.$emit("clicked", true);
    },
    toggleModal() {
      // when the modal has hidden
      this.$refs['modal-employees-on-staff-detail'].toggle('#toggle-btn');
    },
  },
  watch: {
    showModal() {
      if (this.showModal) {
        this.$refs['modal-employees-on-staff-detail'].show();
        // set các giá trị cho việc hiển thị
        if (this.typeOnStaff === 'dk_lam'){
          this.title = 'Danh sách nhân sự đăng ký đi làm';
          this.rows = _.reduce(this.dataDetail, function (result, item, key) {
            let data = {
              stt: ++key,
              user_id: item.user.id + 100000,
              user_name: item.user.name,
              register_morning: item.register_morning === '1' ? `<i class="fa fa-fw fa-check-circle" style="color: green" ></i>` : '' ,
              register_afternoon: item.register_afternoon === '1' ? `<i class="fa fa-fw fa-check-circle" style="color: green" ></i>` : '' ,
              register_evening : item.register_evening === '1' ? `<i class="fa fa-fw fa-check-circle" style="color: green" ></i>` : '' ,
              branch_name : item.branch ? (item.branch.name_alias ? item.branch.name_alias : item.branch.name) : '',
            }
            result.push(data);
            return result;
          }, []);
        }else if(this.typeOnStaff === 'thuc_te_lam'){
          this.title = 'Danh sách nhân sự thực tế đi làm';
          this.rows = _.reduce(this.dataDetail, function (result, item, key) {
            let data = {
              stt: ++key,
              user_id: item.user.id + 100000,
              user_name: item.user.name,
              register_morning: item.morning_time_keep === '1' ? `<i class="fa fa-fw fa-check-circle" style="color: green" ></i>` : '' ,
              register_afternoon: item.afternoon_time_keep === '1' ? `<i class="fa fa-fw fa-check-circle" style="color: green" ></i>` : '' ,
              register_evening : item.evening_time_keep === '1' ? `<i class="fa fa-fw fa-check-circle" style="color: green" ></i>` : '' ,
              branch_name : item.branch ? (item.branch.name_alias ? item.branch.name_alias : item.branch.name) : '',
            }
            result.push(data);
            return result;
          }, []);
        }else if (this.typeOnStaff === 'so_ca_lead'){
            this.title = 'Số ca Lead/ngày';
          this.rows = _.reduce(this.dataDetail, function (result, item, key) {
          let data = {
            stt: ++key,
            user_id: item.user.id + 100000,
            user_name: item.user.name,
            register_morning: item.session === 'morning' ? `<i class="fa fa-fw fa-check-circle" style="color: green" ></i>` : '' ,
            register_afternoon: item.session === 'afternoon' ? `<i class="fa fa-fw fa-check-circle" style="color: green" ></i>` : '' ,
            register_evening : item.session === 'evening' ? `<i class="fa fa-fw fa-check-circle" style="color: green" ></i>` : '' ,
            branch_name : item.branch ? (item.branch.name_alias ? item.branch.name_alias : item.branch.name) : '',
          }
          result.push(data);
          return result;
        }, []);
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
