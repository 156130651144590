<template>
  <div>
    <b-button
        v-b-modal="'handle-ticket'"
        class="btn btn-info btn-sm"
        :title="ticket.feedback_ticket_star !== null ? 'Đã nhập đánh giá không được phép nhập giải pháp11' : ''"
        :disabled=" ticket.category_chamsoc === 10 && ticket.category_chamsoc === 11 && ticket.feedback_ticket_star !== null"
    >Nhập giải pháp
    </b-button>
    <b-modal id="handle-ticket"
             title="Nhập giải pháp cho vấn đề"
             size="xl"
             ok-title="Cập nhật"
             ok-only
             centered
             hide-footer
             @ok="handleOk"
    >
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form>
          <p>Trạng thái</p>
          <span :class="statusDefineCss[ticket.status]" class="mb-4">{{ statusDefine[ticket.status] }}</span>
          <div class="form-group mb-1">
            <label for="exampleTextarea">Nội dung
              <span class="text-danger">*</span></label>
            <ValidationProvider vid="reason" name="Nội dung" rules="required"
                                v-slot="{ errors,classes }">
                        <textarea class="form-control" id="exampleTextarea" rows="3"
                                  v-model="ticket_log.reason" :class="classes"></textarea>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                    errors[0]
                  }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <p class="mt-3">Ảnh/video</p>
          <UploadVideo @emitUpdateFile="emitUpdateFile"></UploadVideo>
        </form>
      </ValidationObserver>
      <b-button
          variant="success"
          size="md"
          class="float-right"
          :class="{'spinner spinner-white spinner-right' : handle_save_loading}"
          @click="handleOk"
      >
        Lưu
      </b-button>
    </b-modal>
  </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {HANDLE_TICKET} from "../../../../core/services/store/user/ticket.module";
    import Swal from "sweetalert2";
    import UploadVideo from "./UploadVideo";

    export default {
      name: "HandleTicketModal",
      components: {UploadVideo},
      props: {
        ticket: {
          type: Object,
          default: () => {
            return null;
          }
        }
        },
        mounted() {
        },
        data() {
          return {
            handle_save_loading: false,
            fileList: [],
            ticket_log: {
              ticket_id: '',
              type: 'change_status',
              user_handle: '',
              old_content: '',
              new_content: '',
              content: '',
              reason: '',
              is_auto: 1
            },
            dialogImageUrl: '',
            dialogVisible: false,
            statusDefine: {
              0: 'Hủy',
              1: 'Chờ xử lý',
              2: 'Đã nhập giải pháp',
              3: 'Đã hoàn thành'
            },
            statusDefineCss: {
              0: 'badge badge-danger',
              1: 'badge badge-warning',
              2: 'badge badge-primary',
              3: 'badge badge-success'
            }
          }
        },
        computed: {
          ...mapGetters(["currentUser"]),
        },
        methods: {
          handleOk(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.callApi();
          },
          callApi() {
            this.ticket_log.ticket_id = this.ticket.id;
            this.ticket_log.user_handle = this.currentUser.user.id;
            this.handle_save_loading = true;
            this.ticket_log.ticket_id = this.ticket.id;
            this.$store.dispatch(HANDLE_TICKET, this.getPayload()).then((data) => {
              if (!data.error) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Xử lý ticket thành công',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.handle_save_loading = false;
                this.closeModal();  
                this.$emit('handleTicketSuccess');
              }
            }).catch((e) => {
              if (e.response.data.data.message_validate_form) {
                this.$refs.form.setErrors(e.response.data.data.message_validate_form);
              }
              this.handle_save_loading = false;
            });
          },
          closeModal() {
            this.$bvModal.hide('handle-ticket');
          },
          getPayload() {
            let formData = new FormData();
            for (let key in this.ticket_log) {
              formData.append(key, this.ticket_log[key]);
            }
            for (let i = 0; i < this.fileList.length; i++) {
              formData.append('fileList[]', this.fileList[i].raw);
            }
            return formData;
          },
          emitUpdateFile(e) {
            this.fileList = e;
    }
  }
}
</script>

<style scoped>

</style>