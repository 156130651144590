<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" ref="course-transfer" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card-body">
        <div class="row">
          <el-dialog title="Thêm mới học viên" @close="closeModal" :visible.sync="dialogFormVisible">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Họ tên
                    <span class="text-danger">*</span></label>
                  <ValidationProvider name="Họ tên" vid="name" rules="required"
                                      v-slot="{ errors,classes }">
                    <input v-model="toUser.name" type="text"
                           class="form-control"
                           placeholder="Họ tên"
                           :class="classes">
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Email
                    <span class="text-danger">*</span></label>
                  <ValidationProvider vid="email" name="Email" rules="required"
                                      v-slot="{ errors,classes }">
                    <input v-model="toUser.email" type="email"
                           class="form-control"
                           placeholder="Email"
                           :class="classes"
                    >
                    <div class="fv-plugins-message-container">
                      <div data-field="email" data-validator="notEmpty" class="fv-help-block">
                        {{  errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label ref="label_phone">Số điện thoại
                    <span class="text-danger">*</span></label>
                  <ValidationProvider vid="phone" name="Số điện thoại"
                                      :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                      v-slot="{ errors,classes }">
                    <input v-model="toUser.phone" type="number"
                           class="form-control"
                           placeholder="Số điện thoại"
                           :class="classes"
                           disabled
                    >
                    <div class="fv-plugins-message-container">
                      <div data-field="phone" data-validator="notEmpty" class="fv-help-block">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <ValidationProvider vid="birthday" name="Ngày sinh" rules="required"
                                      v-slot="{ errors,classes }">
                    <label class="d-block">Ngày sinh <span class="text-danger">*</span></label>
                    <el-date-picker
                        v-model="toUser.birthday"
                        type="date"
                        ref="birthday"
                        placeholder="Chọn ngày sinh"
                        class="w-100"
                        :class="classes"
                        value-format="yyyy/MM/dd"
                    >
                    </el-date-picker>
                    <div class="fv-plugins-message-container">
                      <div data-field="birthday" data-validator="notEmpty" class="fv-help-block">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button type="info" @click="dialogFormVisible = false" style="color: #FFFFFF">Hủy</el-button>
              <el-button type="primary" native-type="submit" :loading="loading" style="color: #FFFFFF">Lưu</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {CREATE_CUSTOMER} from "@/core/services/store/user/users.module";

export default {
  name: "UserForm",
  props: {
    dialogFormVisible: false,
    user_phone: ''
  },
  data() {
    return {
      toUser: {
        name: '',
        email: '',
        phone: '',
        birthday: '',
      },
      loading: false
    }
  },
  watch: {
    dialogFormVisible() {
      if (this.dialogFormVisible){
        this.toUser.phone = this.user_phone
      }
    }
  },
  methods: {
    onSubmit() {
      this.loading = true;
      let params = {
        ...this.toUser,
        password: 123456,
        confirm_password: 123456,
        status: 1,
        profile: {
          birthday: this.toUser.birthday,
          account_type_id : 9 // Hard code học viên
        }
      }
      this.$store.dispatch(CREATE_CUSTOMER, params).then((res) => {
        this.$notify({
          type: 'success',
          title: 'Thành công',
          message: 'Thêm mới học viên thành công'
        });
        this.$emit('successCreate', res.data);
        this.closeModal();
      }).catch((e) => {
        if (e.data?.data?.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
        this.$notify({
          type: 'error',
          title: 'Thất bại',
          message: e.data.message
        });
      }).finally(() => this.loading = false)
    },
    closeModal() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>
