import Vue from "vue";
import Vuex from "vuex";
import createCache from 'vuex-cache';
import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import permission from "./user/permission.module";
import user from "./user/users.module";
import centerUser from "./user/center-user.module";
import accountType from "./user/account-type.module";
import role from "./user/role.module";
import center from "./center/centers.module";
import province from "./public/provinces.module";
import district from "./public/districts.module";
import branch from "./center/branch.module";
import area from "./center/area.module";
import courseType from "./course/courseType.module";
import courseLevel from "./course/courseLevel.module";
import course from "./course/course.module";
import classes from "./course/classes.module";
import exports from "./export/export.module";
import teacher from "./teacher/teacher.module";
import humanResource from "./user/log_human_resource.module";
import review from "./review/review.module";
import contract from "./contract/contracts.module";
import studentRegisterCourse from "./contract/student-register-course.module";
import promotion from "./contract/promotions.module";
import studentAmount from "./contract/studentAmount.module";
import user_promotion_degradation from "./user/user_promotion_degradation.module";
import report from "./report/report.module";
import information from "./system/information.module";
import handbook from "./system/handbook.module";
import mechanism from "./system/mechanism.module";
import customer from "./customer/customer.module";
import google from "./public/google.module";
import customerLog from "./customer/customer-log.module";
import recruitment from "./recruitment/recruiment.module";
import ticket from "./user/ticket.module";
import event from "./event/event.module";
import news from "./news/news.module";
import category from "./news/category.module";
import page from "./pages/page.module";
import workshopAndClub from "./workshopAndClub/workshopAndClub.module";
import QA from "./center/QA.module";
import test from "./test/test.module";
import contractProgram from "./contract/contract-program.module";
import exam from "./exam/exam.module";
import customerCrm from "./customer/customer-crm.module";
import businessCoefficient from "./business/coefficient.module";
import reportError from "./report-error/reportError.module";
import courseTransfer from "@/core/services/store/course/courseTransfer.module";
import department from "./user/department.module";
import ticketCategory from "./ticket-category/category.module";
import department_backend from "./department/department.module";
import customer_ticket from "./ticket/ticket.module";
import leadWebsite from "./website/leadWebsite.module";
import business_level from "./business/level.module";
import installment from "./installment/installment.module";
import company_event from "./company-event/company-event.module";
import attendance from "./attendance/attendance.module";
import source from "./bizfly/source.module";
import reportDigital from "./bizfly/reportDigital.module";
import edutalk_option from "./edutalk-option/edutalk-option.module";
import term_policy from "./system/termpolicy.module";
import citime from "./citime/citime.module";
import tournament from "./tournament/tournament.module";
import rewards_programs from "./rewards-programs/rewardsPrograms.module";
import test_input from "./test/testInput.module";
import event_web from "./event-web/event-web.module";
import notification from "./notification/notification.module";
import school_link_source from "./school-link-source/school-link-source.module";
import honor from "./honors/honor.module";
import taskManagement from "./task-management/task-management.module";
import training from "./sale-level-up/training.module";

import csvc from "./csvc/csvc.module"
import costprice from "./costprice/costprice.module"
import branchTotalAsset from "./finance/branchTotalAsset.module";
import financeTransaction from "./finance/financeTransaction.module";
import region from './center/region.module'
import payments from './payments/payments.module'
import managementKpi from './backoffice/mangementKpi'
import bondManagement from "@/core/services/store/baseoffice/bondManagement";
import superDash from './super-dash/super-dash'
import courseGift from './course/courseGift.module'

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createCache()],
    modules: {
        auth,
        htmlClass,
        config,
        breadcrumbs,
        profile,
        permission,
        user,
        centerUser,
        accountType,
        role,
        center,
        province,
        district,
        branch,
        area,
        courseType,
        courseLevel,
        course,
        teacher,
        classes,
        exports,
        humanResource,
        review,
        contract,
        studentRegisterCourse,
        promotion,
        studentAmount,
        user_promotion_degradation,
        report,
        information,
        handbook,
        mechanism,
        customer,
        google,
        customerLog,
        recruitment,
        ticket,
        event,
        news,
        category,
        page,
        workshopAndClub,
        QA,
        test,
        contractProgram,
        exam,
        customerCrm,
        businessCoefficient,
        reportError,
        courseTransfer,
        department,
        ticketCategory,
        customer_ticket,
        department_backend,
        business_level,
        leadWebsite,
        company_event,
        attendance,
        source,
        reportDigital,
        edutalk_option,
        test_input,
        installment,
        term_policy,
        tournament,
        citime,
        rewards_programs,
        event_web,
        notification,
        csvc,
        costprice,
        school_link_source,
        honor,
        taskManagement,
        branchTotalAsset,
        financeTransaction,
        region,
        payments,
        managementKpi,
        training,
        bondManagement,
        superDash,
        courseGift
    }
});
