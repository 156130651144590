import { render, staticRenderFns } from "./DetailMember.vue?vue&type=template&id=ae10cfb2&scoped=true"
import script from "./DetailMember.vue?vue&type=script&lang=js"
export * from "./DetailMember.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae10cfb2",
  null
  
)

export default component.exports