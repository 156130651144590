import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "../auth.module";

const _ = require('lodash');

export const GET_WORKSHOP_AND_CLUB = "get-workshop-and-club";
export const DELETE_WORKSHOP_AND_CLUB = "delete-workshop-and-club";
export const CREATE_WORKSHOP_AND_CLUB = "create-workshop-and-club";
export const GET_WORKSHOP_AND_CLUB_BY_ID = "get-workshop-and-club-by-id";
export const UPDATE_WORKSHOP_AND_CLUB = "update-workshop-and-club";

//mutations types
export const SET_WORKSHOP_AND_CLUB = "setWorkshopAndClub";

const state = {
    errors: null,
    workshopAndClub: [],
    paginateData: [],
};

const getters = {
    getWCPaginate(state) {
        return state.userPaginatedData;
    },
};

const actions = {
    [GET_WORKSHOP_AND_CLUB](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query('workshop-club', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },
    [GET_WORKSHOP_AND_CLUB_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('workshop-club', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [UPDATE_WORKSHOP_AND_CLUB](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('workshop-club', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [CREATE_WORKSHOP_AND_CLUB](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('workshop-club', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [DELETE_WORKSHOP_AND_CLUB](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('workshop-club', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
}

const mutations = {
    [SET_WORKSHOP_AND_CLUB](state, data) {
        state.workshopAndClub = data;
        state.errors = {};
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
