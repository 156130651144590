<template>
  <div
      :class="context.classes.element"
      data-type="image"
      :data-has-files="hasFiles"
  >
    <FormulateSlot
        name="prefix"
        :context="context"
    >
      <component
          :is="context.slotComponents.prefix"
          v-if="context.slotComponents.prefix"
          :context="context"
      />
    </FormulateSlot>
    <div
        :class="context.classes.uploadArea"
        :data-has-files="hasFiles"
    >
      <input
          ref="file"
          :data-is-drag-hover="isOver"
          type="file"
          v-bind="attributes"
          v-on="$listeners"
          @change="handleFile"
          @dragover="handleDragOver"
          @dragleave="handleDragLeave"
      >
      <FormulateSlot
          name="uploadAreaMask"
          :context="context"
          :has-files="hasFiles"
      >
        <component
            :is="context.slotComponents.uploadAreaMask"
            v-show="!hasFiles"
            :has-files="context.slotComponents.uploadAreaMask === 'div' ? false : hasFiles"
            :data-has-files="context.slotComponents.uploadAreaMask === 'div' ? hasFiles : false"
            :class="context.classes.uploadAreaMask"
        />
      </FormulateSlot>
      <FormulateFiles
          v-if="hasFiles"
          :files="context.model"
          :image-preview="context.type === 'eduImage' && context.imageBehavior === 'preview'"
          :context="context"
      />
    </div>
    <FormulateSlot
        name="suffix"
        :context="context"
    >
      <component
          :is="context.slotComponents.suffix"
          v-if="context.slotComponents.suffix"
          :context="context"
      />
    </FormulateSlot>
  </div>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'
import FileUpload from '@braid/vue-formulate/src/FileUpload'
import FormulateFiles from '@braid/vue-formulate/src/FormulateFiles.vue'

export default {
  name: 'FormulateImage',
  components: {
    FormulateFiles
  },
  mixins: [FormulateInputMixin],
  data() {
    return {
      isOver: false
    }
  },
  computed: {
    hasFiles() {
      return !!(typeof this.context.model === 'object' && this.context.model.files?.length)
    }
  },
  created() {

  },
  watch: {
    context(newQuestion, oldQuestion) {
      if (Array.isArray(this.context.model)) {
        if (typeof this.context.model[0][this.$formulate.getFileUrlKey()] === 'string') {
          this.context.model = this.$formulate.createUpload({
            files: this.context.model
          }, this.context)
        }
      }
    }
  },
  mounted() {
    // Add a listener to the window to prevent drag/drops that miss the dropzone
    // from opening the file and navigating the user away from the page.
    if (window && this.context.preventWindowDrops) {
      window.addEventListener('dragover', this.preventDefault)
      window.addEventListener('drop', this.preventDefault)
    }
  },
  destroyed() {
    if (window && this.context.preventWindowDrops) {
      window.removeEventListener('dragover', this.preventDefault)
      window.removeEventListener('drop', this.preventDefault)
    }
  },
  methods: {
    preventDefault(e) {
      console.log('preventDefault');
      if (e.target.tagName !== 'INPUT' && e.target.getAttribute('type') !== 'file') {
        e = e || event
        e.preventDefault()
      }
    },
    handleFile() {
      this.isOver = false
      const input = this.$refs.file
      if (input.files.length) {
        this.context.model = this.$formulate.createUpload(input, this.context)
        // nextTick required for attemptImmediateUpload to pass instanceof reliably
        this.$nextTick(() => this.attemptImmediateUpload())

      }
    },
    attemptImmediateUpload() {
      if (this.context.uploadBehavior === 'live' && typeof this.context.model === 'object') {
        this.context.hasValidationErrors().then(errors => {
          if (!errors) {
            this.context.model.upload()
          }
        })
      }
    },
    handleDragOver(e) {
      e.preventDefault()
      this.isOver = true
    },
    handleDragLeave(e) {
      e.preventDefault()
      this.isOver = false
    }
  }
}
</script>
