<template>
    <b-modal @hide="onHide" size="xl" :centered="true" :scrollable="true" ref="modal-schedule-detail"
             hide-footer :title="titleType" class="modal-schedule-detail">
        <div class="d-block text-center">
            <div class="row">
                <div class="col-md-12">
                    <vue-good-table
                            :columns="columns"
                            :rows="rows"
                            :search-options="{
                enabled: true
              }"
                            :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'bottom',
                dropdownAllowAll: true,
                setCurrentPage: 1,
                nextLabel: 'Sau',
                prevLabel: 'Trước',
                rowsPerPageLabel: 'Số bản ghi',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'Tất cả',
              }"
                    />
                </div>
            </div>
        </div>
        <footer class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideModal">Hủy bỏ</button>
        </footer>
    </b-modal>
</template>

<script>
    import 'vue-good-table/dist/vue-good-table.css'
    import {VueGoodTable} from 'vue-good-table';
    import moment from "moment-timezone";

    const _ = require('lodash');
    export default {
        name: "ScheduleByDateTvv",
        props: {
            showModal: {default: false},
            typeAction: {default: ''},
            titleType: {default: ''},
            scheduleData: {
                type: Array,
                default() {
                    return [];
                }
            },
        },
        data() {
            return {
                employeeRegisters: [],
                rows: [],
                title: '',
                query: {
                    stt: '',
                    user_id: '',
                    user_name: '',
                    morning: '',
                    afternoon: '',
                    evening: '',
                    center_name: ''
                },
                columns: [
                    {
                        label: 'STT',
                        field: 'stt',
                        tdClass: 'text-center',
                    },
                    {
                        label: 'Tên khách hàng',
                        field: 'user_name',
                        tdClass: 'text-center',
                        filterOptions: {
                            styleClass: 'class1', // class to be added to the parent th element
                            enabled: true, // enable filter for this column
                            placeholder: 'Tìm kiếm tên nhân viên', // placeholder for filter input
                            filterValue: '', // initial populated value for this filter
                            trigger: '', //only trigger on enter not on keyup
                        },
                    },
                    {
                        label: 'Số điện thoại',
                        field: 'user_phone',
                        tdClass: 'text-center',
                        filterOptions: {
                            styleClass: 'class1', // class to be added to the parent th element
                            enabled: true, // enable filter for this column
                            placeholder: 'Số điện thoại', // placeholder for filter input
                            filterValue: '', // initial populated value for this filter
                            trigger: '', //only trigger on enter not on keyup
                        },
                    },
                    {
                        label: 'Thời gian hẹn',
                        field: 'schedule',
                        html: true,
                        tdClass: 'text-center',
                        filterOptions: {
                            styleClass: 'class1', // class to be added to the parent th element
                            enabled: true, // enable filter for this column
                            placeholder: 'Lịch hẹn', // placeholder for filter input
                            filterValue: '', // initial populated value for this filter
                            trigger: '', //only trigger on enter not on keyup
                        },
                    },
                    {
                        label: 'Địa điểm hẹn',
                        field: 'dia_diem_hen',
                        html: true,
                        tdClass: 'text-center',
                        filterOptions: {
                            styleClass: 'class1', // class to be added to the parent th element
                            enabled: true, // enable filter for this column
                            placeholder: 'Địa điểm hẹn', // placeholder for filter input
                            filterValue: '', // initial populated value for this filter
                            trigger: '', //only trigger on enter not on keyup
                        },
                    },
                    {
                        label: 'Tư vấn viên',
                        field: 'presenter',
                        html: true,
                        tdClass: 'text-center',
                        filterOptions: {
                            styleClass: 'class1', // class to be added to the parent th element
                            enabled: true, // enable filter for this column
                            placeholder: 'Người giới thiệu', // placeholder for filter input
                            filterValue: '', // initial populated value for this filter
                            trigger: '', //only trigger on enter not on keyup
                        },
                    },
                    {
                        label: 'Văn Phòng',
                        field: 'branch_name',
                        tdClass: 'text-center',
                        filterOptions: {
                            styleClass: 'class1', // class to be added to the parent th element
                            enabled: true, // enable filter for this column
                            placeholder: 'Tìm kiếm văn phòng', // placeholder for filter input
                            filterValue: '', // initial populated value for this filter
                            trigger: '', //only trigger on enter not on keyup
                        },
                    },
                    {
                        label: 'Khu vực',
                        field: 'area_name',
                        tdClass: 'text-center',
                        filterOptions: {
                            styleClass: 'class1', // class to be added to the parent th element
                            enabled: true, // enable filter for this column
                            placeholder: 'Tìm kiếm khu vực', // placeholder for filter input
                            filterValue: '', // initial populated value for this filter
                            trigger: '', //only trigger on enter not on keyup
                        },
                    },
                ],
            }
        },
        components: {VueGoodTable},
        computed: {},
        methods: {
            hideModal: function () {
                this.$refs['modal-schedule-detail'].hide();
            },
            onHide: function (evt) {
                this.$emit("clicked", true);
            },
            toggleModal() {
                // when the modal has hidden
                this.$refs['modal-schedule-detail'].toggle('#toggle-btn');
            },
        },
        watch: {
            showModal() {
                if (this.showModal) {
                    this.$refs['modal-schedule-detail'].show();
                    // set các giá trị cho việc hiển thị
                    let _this = this;
                    this.rows = _.reduce(this.scheduleData, function (result, item, key) {
                        let data = {
                            stt: ++key,
                            user_name: item.customer ? item.customer.name : '',
                            user_phone: item.customer ? item.customer.phone : '',
                            schedule:(item.ngay_hen || item.lich_hen_tu_van) ? moment(_this.typeAction!=='lich_hen'?item.ngay_hen:item.lich_hen_tu_van).format('HH:mm') : '',
                            presenter: item.tvv ? item.tvv.name : '',
                            branch_name: item.tvv && item.tvv.profile.branch ? item.tvv.profile.branch.name_alias : '',
                            area_name: item.tvv && item.tvv.profile.branch && item.tvv.profile.branch.area ? item.tvv.profile.branch.area.name : '',
                            dia_diem_hen: item.branch ? item.branch.name_alias ? item.branch.name_alias : item.branch.name : '',
                        }
                        result.push(data);
                        return result;
                    }, []);
                }
            },
        }
    }
</script>

<style scoped>

</style>
