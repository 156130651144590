import ApiService from "@/core/services/api.service";

//sale-level-up-tranining
export const SALE_LEVEL_UP_TRAINING = "sale-level-up-training";
export const SALE_LEVEL_UP_PROGRAMS = "sale-level-up-programs";
export const SALE_LEVEL_UP_COURSES = "sale-level-up-courses";
export const ATTEND_SALE = "attend-sale";
const state = {};

const getters = {};
const actions = {
    [SALE_LEVEL_UP_TRAINING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${SALE_LEVEL_UP_TRAINING}`, payload)
            .then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [SALE_LEVEL_UP_PROGRAMS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${SALE_LEVEL_UP_PROGRAMS}`, payload)
            .then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [SALE_LEVEL_UP_COURSES](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${SALE_LEVEL_UP_COURSES}`, payload)
            .then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [ATTEND_SALE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${ATTEND_SALE}`, payload)
                .then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                reject(response);
            });
        });
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
