import {TYPE_TASK_ITEM_DEFINE} from "@/core/option/testInputOption";
import moment from "moment-timezone";
export function TaskWarningModel(idTask=0,typeTask,timeStart,timeEnd,dataEmployees = [],typeTaskItem){
    this.idTask = idTask
    this.typeTask = typeTask
    this.typeTaskName = typeTaskToString(typeTaskItem)
    this.timeStart = formatTime(timeStart)
    this.timeEnd =  formatTime(timeEnd)
    this.dataEmployees= dataEmployees
}
function typeTaskToString(typeTask){
    return TYPE_TASK_ITEM_DEFINE[typeTask]
}
export function formatTime(time){
    const format = "HH:mm DD-MM-YYYY"
    return moment(time).format(format)
}

export let TasksWarning = [ new TaskWarningModel(1,1,"2023-07-15 14:00:00","2023-07-15 14:30:00",[1,2,3]),
    new TaskWarningModel(1,1,"2023-07-15 14:00:00","2023-07-15 14:30:00",[1,2,3]),
    new TaskWarningModel(1,2,"2023-07-15 14:00:00","2023-07-15 14:30:00",[1,2,3])
]

