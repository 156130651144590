<template>
    <div class="container">
        <div class="customer-study">
            <div class="customer-list-courses">
                <div class="title">
                    <h4>Kết quả test đầu vào</h4>
                </div>
                <div class="test-input table-responsive overflow-auto">
                    <table class="table table-vertical-center table-hover">
                        <thead>
                        <tr>
                            <th>STT</th>
                            <th>Trung tâm test</th>
                            <th>Trạng thái ca test</th>
                            <th>Hình thức test</th>
                            <th>Overall</th>
                            <th>Khóa học gợi ý</th>
                            <th>Thời gian test</th>
                            <th>Hành động</th>
                        </tr>
                        </thead>
                        <tbody v-if="testInputResults.length > 0">
                        <tr v-for="(item, key) in testInputResults" :key="key">
                            <td>{{ key + 1 }}</td>
                            <td>{{ item.center_name }}</td>
                            <td>{{ item.status_name}}</td>
                            <td>{{ item.type_name }}</td>
                            <td>{{ item.overall }}</td>
                            <td>{{ item.course_suggest }}</td>
                            <td>{{ item.time_test}}</td>
                            <td>
                                <a href="#" title="Xem chi tiết"
                                   class="btn btn-xs btn-icon btn-outline-success ml-2"
                                   @click="showModalDetail(item.test_input_schedule_id)"><i class="far fa-eye"></i>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr>
                            <td colspan="8" class="event-title no-data text-center">Chưa có dữ liệu</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Hoc-Tap",
    components: {},
    props: {
        testInputResults: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
        }
    },
    methods: {
        showModalDetail(test_input_schedule_id) {
            const routeData = this.$router.resolve({name: 'task-details', params: {id: test_input_schedule_id}});
            window.open(routeData.href, '_blank');
        },
    }
}
</script>

<style scoped>
.test-input th, td {
    text-align: center;
}
</style>
