<script>
import router from "@/router";

export default {
  name: "ModalWarningAdmin",
  data(){
    return{
    }
  },
  props:{
    open : {
      type: Boolean,
      default(){
        return false
      }
    }
  },
  methods:{
    navigateMyTasks(){
      router.push('/test-input/task-manager-personal')
    }
  }
}
</script>

<template>
<div>
  <b-modal id="modal-center" centered title="Thông báo" hide-footer :no-close-on-backdrop="true" hide-header-close v-model="open" >
    <p>Tác vụ Test & chấm speaking này đã được admin khác phụ trách.</p>
    <div class="d-flex justify-content-end">
      <b-button variant="light" style=" background: #5E00B6; color: white;
" @click="navigateMyTasks()">Đã hiểu</b-button>
    </div>

  </b-modal>
</div>
</template>

<style scoped>

</style>