<template>
  <div class="container disabled-css" id="crm-ticket-show">
    <div class="row">
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header d-flex justify-content-between">
            <div class="card-title">
              <h3 class="card-label">
                Thông tin ticket
              </h3>
            </div>
            <div v-if="currentUser.user.permission.includes(permission.SUA_HO_TRO)" class="mt-5">
              <button v-if="is_edit && checkShowButtonCRUD" class="btn btn-primary btn-sm" @click="is_edit = !is_edit">Sửa</button>
              <div class="row d-flex justify-content-end" v-if="!is_edit && checkShowButtonCRUD">
                <button class="btn btn-secondary mr-2 btn-sm" @click="is_edit = true">Hủy bỏ</button>
                <button class="btn btn-primary btn-sm"
                        :class="{'spinner spinner-white spinner-right' : update_loading}"
                        @click="update">Cập nhật
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <ValidationObserver v-slot="{ handleSubmit }" ref="form">
              <form method="post">
                <div class="row" v-if="!checkTicketTangQua">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Mã ticket</label>
                      <input type="text" class="form-control form-control-solid" disabled
                             v-model="ticket.id" placeholder="Mã ticket">
                    </div>
                    <div class="form-group">
                      <label>Gán ticket cho khách hàng</label>
                      <ValidationProvider vid="phone" name="Số điện thoại"
                                          rules=""
                                          v-slot="{ errors,classes }">
                        <el-select
                            v-model="ticket.user_id"
                            filterable
                            clearable
                            remote
                            reserve-keyword
                            placeholder="Nhập số điện thoại"
                            :disabled="is_edit"
                            :remote-method="remoteMethod"
                            :loading="search_user_loading">
                          <el-option
                              v-for="(item, index) in users" :index="index"
                              :key="item.id + index"
                              :label="item.name"
                              :value="item.id">
                          </el-option>
                        </el-select>
                        <div class="fv-plugins-message-container">
                          <div data-field="name" data-validator="notEmpty"
                               class="fv-help-block">{{
                              errors[0]
                            }}
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Người phản hồi:</label>
                      <input type="text" class="form-control form-control-solid"
                             v-model="ticket.nguoi_phan_hoi"
                             placeholder="Người phản hồi"
                             :disabled="is_edit">
                    </div>
                    <!--Tùy biến-->
                    <div class="form-group">
                      <label>Danh mục <span class="text-danger">*</span></label>
                      <ValidationProvider vid="phone" name="Danh mục"
                                          :rules="{ required: true }"
                                          v-slot="{ errors,classes }">
                        <el-select filterable class="w-100" placeholder="Danh mục"
                                   :disabled="is_edit"
                                   v-model="ticket.category_ticket_id"
                                   clearable
                        >
                          <el-option
                              v-for="item in categories"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                          >
                            <span style="float: left">{{ item.name }}</span>
                          </el-option>
                        </el-select>
                        <div class="fv-plugins-message-container">
                          <div data-field="name" data-validator="notEmpty"
                               class="fv-help-block">{{
                              errors[0]
                            }}
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="form-group">
                      <label>Vấn đề phòng ban <span class="text-danger">*</span></label>
                      <ValidationProvider vid="phone" name="Vấn đề phòng ban"
                                          :rules="{ required: true }"
                                          v-slot="{ errors,classes }">
                        <el-select filterable class="w-100" placeholder="" :disabled="is_edit"
                                   v-model="ticket.van_de_phong_ban"
                                   clearable
                        >
                          <el-option
                              v-for="item in departments"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                          >
                            <span style="float: left">{{ item.name }}</span>
                          </el-option>
                        </el-select>
                        <div class="fv-plugins-message-container">
                          <div data-field="name" data-validator="notEmpty"
                               class="fv-help-block">{{
                              errors[0]
                            }}
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                        <label>Độ ưu tiên <span class="text-danger">*</span></label>
                          <el-select :disabled="is_edit" v-model="ticket.priority" filterable
                           class="w-100" placeholder="Độ ưu tiên">
                            <el-option label="Tiêu chuẩn" :value="1"> </el-option>
                            <el-option label="Ưu tiên" :value="2"> </el-option>
                          </el-select>
                    </div>
                    <!--End-->
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Nguồn<span class="text-danger">*</span></label>
                      <ValidationProvider vid="phone" name="Nguồn"
                                          :rules="{ required: true }"
                                          v-slot="{ errors,classes }">
                        <el-select filterable class="w-100" placeholder="Nguồn"
                                   v-model="ticket.source"
                                   clearable
                                   :disabled="is_edit"
                        >
                          <el-option
                              v-for="item in sources"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                          >
                            <span style="float: left">{{ item.name }}</span>
                          </el-option>
                        </el-select>
                        <div class="fv-plugins-message-container">
                          <div data-field="name" data-validator="notEmpty"
                               class="fv-help-block">{{
                              errors[0]
                            }}
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Đối tượng phản hồi <span class="text-danger">*</span></label>
                      <ValidationProvider vid="phone" name="Đối tượng phản hồi"
                                          :rules="{ required: true }"
                                          v-slot="{ errors,classes }">
                        <el-select filterable class="w-100" placeholder="Đối tượng phản hồi"
                                   v-model="ticket.doi_tuong_phan_hoi"
                                   clearable
                                   :disabled="is_edit"
                        >
                          <el-option
                              v-for="item in objectFeedback"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                          >
                            <span style="float: left">{{ item.name }}</span>
                          </el-option>
                        </el-select>
                        <div class="fv-plugins-message-container">
                          <div data-field="name" data-validator="notEmpty"
                               class="fv-help-block">{{
                              errors[0]
                            }}
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Bên xử lý<span class="text-danger">*</span></label>
                      <ValidationProvider vid="phone" name="Bên xử lý"
                                          :rules="{ required: true }"
                                          v-slot="{ errors,classes }">
                        <el-select filterable class="w-100" placeholder="Bên xử lý"
                                   v-model="ticket.data_xu_ly"
                                   clearable
                                   disabled
                        >
                          <el-option
                              v-for="item in dataProcessingParty"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                          >
                            <span style="float: left">{{ item.name }}</span>
                          </el-option>
                        </el-select>
                        <div class="fv-plugins-message-container">
                          <div data-field="name" data-validator="notEmpty"
                               class="fv-help-block">{{
                              errors[0]
                            }}
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>


                    <div class="form-group">
                      <label>Thời gian tạo ticket:</label>
                      <input type="text" class="form-control form-control-solid"
                             :value="ticket.created_at | formatDateTimeVietnam"
                             placeholder="Thời gian tạo ticket" disabled>
                    </div>
                    <div class="form-group">
                      <label>Thời gian khách hàng nhắn:</label>
                      <input type="text" class="form-control form-control-solid"
                             :value="ticket.send_message_at | formatDateTimeVietnam"
                             placeholder="Thời gian khách hàng nhắn" disabled>
                    </div>
                    <div class="form-group">
                      <label>Thời gian trả lời:</label>
                      <input type="text" class="form-control form-control-solid"
                             :value="ticket.reply_message_at | formatDateTimeVietnam"
                             placeholder="Thời gian trả lời" disabled>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group mb-1">
                      <label for="exampleTextarea">Nội dung đánh giá
                        <span class="text-danger"></span></label>
                      <textarea class="form-control" id="exampleTextarea" rows="3"
                                :disabled="is_edit"
                                v-model="ticket.content"></textarea>
                    </div>
                  </div>
                  <div class="col-md-12 mt-8">
                    <b>Ảnh/Video:</b>
                    <UploadVideo
                        :disableUpload="false"
                        :fileListProp="ticket.attach_video_image_files"
                        :isDisable="is_edit"
                        :mediaIds="ticket.media_ids"
                        @emitUpdateFile="emitUpdateFile"
                        @spliceMediaId="spliceMediaId"
                    ></UploadVideo>

                  </div>
                </div>
                <div v-else>
                  <gift-ticket :ticket="ticket"></gift-ticket>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div class="card-header" v-if="is_edit">
            <div class="card-title">
              <h3 class="card-label">
                Thông tin học viên
              </h3>
            </div>
          </div>
          <div class="card-body" v-if="is_edit">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Họ tên:</label>
                  <input type="text" class="form-control form-control-solid" disabled=""
                         placeholder="Họ tên"
                         :value="ticket && ticket.student_edutalk ? ticket.student_edutalk.name : ''">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Số điện thoại liên hệ:</label>
                  <input type="text" class="form-control form-control-solid" disabled=""
                         placeholder="Số điện thoại"
                         :value="getPhone">
                  <el-button class="border border-dark p-3 rounded position-absolute" style="right: 15px; top: 25px;" @click="changePhone">
                    <i class="el-icon-phone-outline"></i>
                  </el-button>
                </div>
              </div>
            </div>
            <router-link v-if="ticket && ticket.student_edutalk && ticket.student_edutalk.best_customer"
                         :to="{ name: 'customer-crm-detail',
                        params: {id: ticket.student_edutalk.id},
                        query: { best_customer_id: ticket.student_edutalk.best_customer.id, active_name: 'cskh' }
                        }" class="btn btn-sm btn-outline-info">Xem chi tiết
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title mb-4">
              <h3 class="card-label">
                Tình trạng xử lý
              </h3>
            </div>

            <div class="d-flex justify-content-around mt-5"
                v-if="currentUser.user.permission.includes(permission.XU_LY_HO_TRO) && this.show_action != 1">
                <ExtendHandle
                  v-if="ticket.status ==  1 && ticket.warning_solution"
                  class="mr-3"
                  :ticket="ticket"
                  @extendHandle="getTicketById">
              </ExtendHandle>

              <MoveDepartmentModal
                  v-if="show_move_department"
                  :departments="departments"
                  class="mr-3"
                  :ticket="ticket"
                  @moveDepartment="getTicketById"
              ></MoveDepartmentModal>
              <CancelMoveDepartment
                  v-else
                  class="mr-3"
                  :ticket="ticket"
                  @cancelMoveDepartment="getTicketById"
              ></CancelMoveDepartment>

              <FeedbackTicketModal v-if="ticket.status == 3"
                                   :ticket="ticket"
                                   class="mr-3"
                                   @reloadFeedBack="reloadFeedBack"
              ></FeedbackTicketModal>
              <RefuseSolution 
              v-if="currentUser.user.permission.includes(permission.NHAP_GIAI_PHAP_PHONG_BAN_XU_LY) && ticket.status == 2"
              :ticket="ticket"
              @refuseSolution="getTicketById"
              >
              </RefuseSolution>
              <ModalStatusSuccess
                  class="mr-3"
                  v-if="ticket.status == 2"
                  :ticket="ticket"
                  @changeStatusSuccess="getTicketById"
              ></ModalStatusSuccess>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div class="card-title">
                <h4 class="card-label">Trạng thái chăm sóc</h4>
              </div>
              <div class="form-group">
                <label>Trạng thái</label>
                <p>
                      <span class="" v-if="ticket"
                      :class="statusDefineCss[ticket.status]">{{ statusDefine[ticket.status] }}</span>
                </p>
              </div>
            </div>
            <div class=" row col-12" v-if="!boxCommentReason">
                  <button @click="boxCommentReason = true" style="width: 100%; height: 51px; text-align: start; color: rgba(0, 0, 0, 0.5);" class="border rounded border-1 pl-4">Nhập giải pháp hoặc bình luận</button>
            </div>

            <div class="form-group mb-1 col-md-12" v-if="boxCommentReason">
                <ValidationProvider vid="content" name="Nội dung" rules="required"
                                    v-slot="{ errors, classes }">
                  <div>
                    <i title="Đóng" @click="boxCommentReason = false" style="position: absolute; right: 18px; top: 5px" class="el-icon-close d-flex justify-content-end closes-edit-crm-log"></i>
                  </div>
                  <ckeditor style="position: relative;" :editor="editor" v-model="query.reason"></ckeditor>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
                <div class="box-bottom-comment-reason d-flex justify-content-end">
                  <div class="d-flex align-items-end">
                    <el-checkbox v-if="ticket.status == 1" v-model="query.is_solution">Đánh dấu là giải pháp</el-checkbox>
                  </div>
                  <div>
                    <b-button variant="success" size="md" class="float-right ml-2" :disabled="loadingSave" :class="{'spinner spinner-white spinner-right' : loadingSave}" @click="handlerCommentReasonTicket">Lưu</b-button>
                  </div>
                </div>
            </div>
              <div class="col-md-12 mt-8 show-comment" style="overflow: scroll; height: 94vh;">
                <div class="" style="scroll-behavior: smooth;">
                  <template class="w-100">
                  <el-tabs  v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="Tất cả" name="all">
                    </el-tab-pane>
                    <el-tab-pane label="Giải pháp" name="solution">
                    </el-tab-pane>
                    <el-tab-pane label="Bình luận" name="comment">
                    </el-tab-pane>
                    <AllTicketLog
                    v-loading="loadingTabName"
                    :tickets="ticketLog"
                    :tabName="activeName">
                    </AllTicketLog>
                  </el-tabs>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";
import HandleTicketModal from "../../tickets/components/HandleTicketModal";
import UploadVideo from "../../tickets/components/UploadVideo";
import FeedbackTicketModal from "../../tickets/components/FeedbackTicketModal";
import RefuseSolution from "../../../pages/tickets/components/RefuseSolution";
import {mapGetters} from "vuex";
import {
  GET_OBJECT_FEEDBACK,
  GET_SOURCE,
  GET_TICKET_BY_ID,
  GET_TICKET_CATEGORIES,
  UPDATE_TICKET_FORM,
  HANDLER_REFUSE_SOLUTION,
  CATEGORY, REASON_ERROR,
  GET_ASSIGNEE_TICKET
} from "../../../../core/services/store/user/ticket.module";
import {UPDATE_TICKET_FEEDBACK, SHOW_TICKET_LOG} from "@/core/services/store/ticket/ticket.module";
import {GET_DEPARTMENT} from "../../../../core/services/store/user/department.module";
import AllTicketLog from "../tickets/components/AllTicketLog.vue";
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";
import MoveDepartmentModal from "./components/MoveDepartmentModal";
import ModalStatusSuccess from "./components/ModalStatusSuccess";
import CancelMoveDepartment from "./components/CancelMoveDepartment";
import ExtendHandle from "../tickets/components/ExtendHandle.vue";
import {TANG_QUA} from "../../../../core/option/ticket";
import GiftTicket from "./components/GiftTicket";
import { GET_BRANCHES } from "@/core/services/store/center/branch.module";
import { GET_CENTERS } from "@/core/services/store/center/centers.module";

export default {
  name: "CrmTicketShow",
  components: {

    GiftTicket,
    CancelMoveDepartment,
    ModalStatusSuccess, MoveDepartmentModal, FeedbackTicketModal, UploadVideo,
    HandleTicketModal, RefuseSolution, AllTicketLog, ExtendHandle
  },
  data() {
    return {
      update_loading: false,
      is_edit: true,
      editor: CustomEditor,
      show_move_department: false,
      search_user_loading: false,
      loadingTabName: false,
      ticket: {
        status: ''
      },
      category_chamsoc_define: {
        1: 'Nghỉ học 2 buổi',
        2: 'Điểm kiểm tra thấp',
        3: 'Không làm bài',
        4: 'Chua đóng tiền học',
        5: 'Đã đóng tiền học',
        6: 'Feedback thấp'
      },
      statusDefine: {
        0: 'Hủy',
        1: 'Chờ xử lý',
        2: 'Đã nhập giải pháp',
        3: 'Đã hoàn thành'
      },
      statusDefineCss: {
        0: 'badge badge-danger',
        1: 'badge badge-warning',
        2: 'badge badge-primary',
        3: 'badge badge-success'
      },
      priorityDefine: {
        1: 'Bình thường',
        2: 'Ưu tiên',
      },
      examTypeDefine: {
        'mid': 'Giữa khóa',
        'last': 'Cuối khóa',
      },
      sources: [],
      categories: [],
      users: [],
      fileList: [],
      departments: [],
      objectFeedback: [],
      ticketLog: [], 
      objectFeedbackConvert: {},
      sourcesObject: {},
      show_action: this.$route.query.show_action,
      ticketObj: {},
      activeName: 'all',
      boxCommentReason: false,
      loadingSave: false,
      loadingReffuseSolution: false,
      query: {
              ticket_id: '',
              type: 'change_status',
              user_handle: '',
              old_content: '',
              new_content: '',
              content: '',
              reason: '',
              reason_refuse: '', 
              is_auto: 1,
              is_solution: false
            },
      callStringeeId: '',
      checkIncoming: false,
      dialogAssignTicket: false,
      formTicket: {
        ticket_id: '',
      },
      search_loading: false,
      dataFindTicket: {},
      loadingAssignee: false,
      checkChangeIcon: false,
      category: CATEGORY,
      config: {
        showMode: 'none',
        top: 45,
        right: 250,
        arrowLeft: 155,
        arrowDisplay: 'top',
        fromNumbers: [
          { alias: 'Number-1', number: '+84899199586' },
          { alias: 'Number-2', number: '+2222' }
        ],
        askCallTypeWhenMakeCall: false,
        appendToElement: null,
        makeAndReceiveCallInNewPopupWindow: false,
      },
      accessToken: '',
      numbers: '',
      fromNumber: '',
      toNumber: '',
      callType: '',
      messageError: '',
      reasonError: REASON_ERROR,
      checkTicketTangQua: false,
      checkShowButtonCRUD: true,
      branches: [],
      centers: [],
      dataProcessingParty: []
    }
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Trung tâm hỗ trợ", route: 'crm-ticket-index'},
      {title: "Chi tiết ticket"}
    ]);
    this.getTicketById();
    this.showTicketLog();
  },
  computed: {
    ...mapGetters(["currentUser"]),
    getPhone(){
      if (!this.ticket.student_edutalk) {
        return  '';
      }

      if (this.ticket.student_edutalk.phone_other){
        return this.ticket.student_edutalk.phone_other;
      }

      if (this.ticket.student_edutalk.phone){
        return this.ticket.student_edutalk.phone;
      }

      return  '';
    }
  },
   mounted() {
    if ($("div").hasClass('hide')) {
      document.getElementById("buttonCall").classList.add('show');
      document.getElementById("buttonCall").classList.remove('hide');
    }
    this.getSource();
    this.getObjectFeedback();
    this.getTicketCategories();
    this.getDepartments();
    this.getCenters();
  },
  methods: {
    reloadFeedBack(e) {
      this.getTicketById(e);
      this.activeName = "all";
    },

    getTicketCategories() {
      this.$store.dispatch(GET_TICKET_CATEGORIES, {
        limit: 1000
      }).then((data) => {
        this.categories = data.data;
      })
    },
    getDepartments() {
      this.$store.dispatch(GET_DEPARTMENT, {
        all: true
      }).then((data) => {
        this.departments = data.data;
      })
    },
    getTicketById(e) {
      this.$store.dispatch(GET_TICKET_BY_ID, this.$route.params.id).then((data) => {
        if (!data.error) {
          this.ticket = data.data;
          this.ticketObj = data.data
          if (data.data.category_chamsoc == TANG_QUA) {
            this.checkTicketTangQua = true
            this.checkShowButtonCRUD = false
          } else {
            if (this.ticket.phong_ban_xu_ly == 0) {
              this.ticket.phong_ban_xu_ly = '';
            }
            if (this.ticket && this.ticket.student_edutalk) {
              this.users.push(this.ticket.student_edutalk);
            }
            this.checkShowMoveDepartment();
            this.activeName = "all";
          }
            this.showTicketLog();

        }
      })
    },
      checkShowMoveDepartment() {
          if ((!this.ticket.phong_ban_xu_ly || this.ticket.phong_ban_xu_ly === 1) && this.ticket.checkbox_department_handle == 1) {
              this.show_move_department = true;
          } else {
              this.show_move_department = false;
          }
      },
    getSource() {
      this.sources = [];
      this.$store.dispatch(GET_SOURCE).then((data) => {
        for (const key in data.data) {
          this.sources.push({
            id: parseInt(key),
            name: data.data[key]
          })
          this.sourcesObject = this.arrayConvertToObject(this.sources);
        }
      })
    },
    getObjectFeedback() {
      this.objectFeedback = [];
      this.$store.dispatch(GET_OBJECT_FEEDBACK).then((data) => {
        for (const key in data.data) {
          this.objectFeedback.push({
            id: parseInt(key),
            name: data.data[key]
          })
        }
        this.objectFeedbackConvert = this.arrayConvertToObject(this.objectFeedback);
      })
    },
    arrayConvertToObject(array) {
      let object = {};
      for (let i = 0; i < array.length; i++) {
        let item = array[i];
        object[item.id] = item.name;
      }
      return object;
    },
    update(e) {
      e.preventDefault();
      this.update_loading = true;
      let payload = this.getPayload();
      this.$store.dispatch(UPDATE_TICKET_FORM, payload).then((data) => {
        this.$bvToast.toast(data.message, {
          title: 'Cập nhật',
          variant: 'success',
          solid: true
        });
        this.update_loading = false;
        this.is_edit = true;
        this.showTicketLog();
      });
    },
    getPayload() {
      let formData = new FormData();
      for (let key in this.ticket) {
        formData.append(key, this.ticket[key]);
      }
      for (let i = 0; i < this.fileList.length; i++) {
        // Chỉ lấy những file mới up
        if (this.fileList[i].id == undefined) {
          formData.append('fileList[]', this.fileList[i].raw);
        }
      }
      return formData;
    },
    emitUpdateFile(e) {
      this.fileList = e;
    },
    spliceMediaId(data) {
      this.ticket.media_ids = data;
    },

    handlerCommentReasonTicket() {
      let reason = this.$el.querySelector(".ck-content").innerHTML;
      if(!this.query.reason) {
        this.$notify.warning({
            title: 'Thiếu dữ liệu',
            message: 'Vui lòng nhập đủ dữ liệu',
            offset: 100
          });
          return false;
      }
    this.loadingSave = true;
    var is_solution =  this.query.is_solution ? 1 : 0;
     setTimeout(() => {
      this.$store.dispatch(UPDATE_TICKET_FEEDBACK,{
            ticket_id: this.$route.params.id,
            is_solution : is_solution,
            reason: reason,
            status: this.ticket.status,
            status_feedback: 2
            }).then((res) => {
              this.loadingSave = false;
              this.boxCommentReason = false;
              this.query.reason = '';
              this.activeName = "all";
              this.getTicketById();
              this.showTicketLog();
                if(is_solution) {
                  this.query.is_solution = false;
                  this.$notify.success({
                  title: 'Thành công',
                  message: 'Nhập giải pháp thành công',
                  offset: 100
                });
                }else {
                  this.$notify.success({
                  title: 'Thành công',
                  message: 'Nhập bình luận thành công',
                  offset: 100
                });
                }
            }).catch((e) => {
              
            }).finally(() => {  
      });
     }, 2000);
     clearTimeout();
    },

    showTicketLog() {
      this.loadingTabName = true;
      this.$store.dispatch(SHOW_TICKET_LOG, {
          id: this.$route.params.id,
          type: this.activeName
        }).then((data) => {
          this.loadingTabName = false;
          this.ticketLog = data.data;
        }).catch((e) => {
        })
    },

    handleClick() {
      this.showTicketLog();
    },
    
    handlerRefuseSolution() {
      this.loadingReffuseSolution =  true;
      this.$store.dispatch(HANDLER_REFUSE_SOLUTION,{
        ticket_id: this.$route.params.id,
        reason_refuse: this.query.reason_refuse
        }).then((res) => {
          this.getTicketById();
              this.$notify.success({
                title: 'Thành công',
                message: 'Từ chối giải pháp thành công',
                offset: 100
              });
          this.loadingReffuseSolution =  false;
          this.activeName = "all";
        }).catch((e) => {
          
        }).finally(() => {
      });
    },
    remoteMethod(query) {
      this.search_loading = true;
      this.$store.dispatch(GET_ASSIGNEE_TICKET, {ticket_id: query}).then((data) => {
        if (data.data) {
          this.users = data.data;
        }
      })
      this.search_loading = false;

    },
    changePhone() {
        StringeeSoftPhone.config({ showMode: 'full' });
        document.getElementById("buttonCall").classList.add('show');
      StringeeSoftPhone._iframe.contentWindow.stringeePhone.changeInput(this.getPhone)
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        status: 1,
        limit: 100000,
        center_ids: [this.ticket.trung_tam_xu_ly]
      }).then((data) => {
        if (!data.error) {
          this.branches = data.data.data;
            this.handleDataProcessingParty();

        }
      })
    },
    getCenters() {
      this.$store.dispatch(GET_CENTERS, {
        status: 1,
        limit: 100000
      }).then((data) => {
        if (!data.error) {
          this.centers = data.data.data;
          this.getBranches();
        }
      })
    },
    handleDataProcessingParty()
    {
        console.log(this.ticket.checkbox_department_handle, this.ticket.chi_nhanh_xu_ly, this.branches)
        switch (this.ticket.checkbox_department_handle) {
            case 1:
              this.dataProcessingParty = this.departments;
              break;
            case 2:
                this.dataProcessingParty = this.ticket.chi_nhanh_xu_ly ? this.branches : this.centers;
              break;
        }
    }
  }
}
</script>

<style>

#crm-ticket-show .el-loading-spinner .circular {
    margin-top: 5px;
    height: 36px;
    width: 28px;
}

.el-select {
  width: 100%;
}

.el-input.is-disabled .el-input__inner {
  color: red !important;
}

.form-control.form-control-solid {
  background-color: unset !important;
  border-color: #DCDFE6;
}
#crm-ticket-show .ck-content .image-inline img, .ck-content .image-inline picture,
#crm-ticket-show .ck-content .image img {
      height: 300px;
      width: 200px;
      object-fit: cover;
  }

  .closes-edit-crm-log::before{
    border: 1px solid #00000059;
    border-radius: 50px;
    font-size: 12px;
    padding: 1px;
    z-index: 1000;
  }

  .closes-edit-crm-log:hover{
   color: red;
  }
  .bg-violet {
    background: #5E00B6;
  }
</style>