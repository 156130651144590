<template>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-1">
                        <div class="form-group">
                            <label style="text-indent: -9999px;"> 1</label>
                            <label class="radio radio-success radio-lg" style="top:5px;" title="Chọn làm mặc định">
                                <input type="radio" name="default" :value="index" v-model="vmodel_index"
                                       @input="updateRadio(index)">
                                <span></span></label>
                        </div>
                    </div>
                    <div class="col-ld-11 col-md-10 col-sm-11 col-11">
                        <i v-if="index > 0" class="fas fa-minus-circle mr-2" style="cursor: pointer;"
                           @click="minusAccount(index)"></i>
                        <label>Chủ tài khoản
                            <span class="text-danger"></span>
                        </label>
                        <div class="form-group">
                            <ValidationProvider :vid="'name' + index" name="Chủ tài khoản" rules="required"
                                                v-slot="{ errors,classes }" tag="div"
                                                class="input-group">
                                <input style="width: 75%;border-radius: 0.42rem;" name="owner_name" type="text"
                                       class="form-control" placeholder="Chủ tài khoản"
                                       v-model="item.owner_name">
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0]  }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Chọn ngân hàng
                    <span class="text-danger"></span></label>
                <multiselect v-model="cacheBank" :options="banks"
                             placeholder="Chọn cở sở"
                             label="name"
                             track-by="name"
                             @input="updateStateBank(item, $event)">
                </multiselect>
                <ValidationProvider :vid="'bank' + index" name="Ngân hàng" rules="required" v-slot="{ errors,classes }">
                    <input name="ngan_hang" type="hidden" v-model="item.ngan_hang">
                    <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
            </div>
            <!--end::Form-->
        </div>
        <div class="col-md-4" v-if="item.enable_branch_bank">
            <div class="form-group">
                <label>Thành phố
                    <span class="text-danger"></span></label>
                <multiselect v-model="item.province" :options="provinces" :searchable="true"
                             :close-on-select="true"
                             :show-labels="true" placeholder="Thành phô"
                             @input="eventChangeProvince(item, $event)"
                ></multiselect>
                <ValidationProvider :vid="'province' + index" name="Thành phố" rules="required"
                                    v-slot="{ errors,classes }">
                    <input type="hidden" name="province" v-model="item.province">
                    <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
            <!--end::Form-->
        </div>
        <div class="col-md-4" v-if="item.enable_branch_bank">
            <div class="form-group">
                <label>Chi nhánh
                    <span class="text-danger"></span></label>
                <multiselect v-model="item.chi_nhanh" :options="item.branch_affter_select_province"
                             :searchable="true"
                             :close-on-select="true"
                             :show-labels="true" placeholder="Chi nhánh"
                ></multiselect>
                <ValidationProvider :vid="'chi_nhanh' + index" name="Chi nhánh" rules="required"
                                    v-slot="{ errors,classes }">
                    <input type="hidden" name="chi_nhanh" v-model="item.chi_nhanh">
                    <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
            <!--end::Form-->
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Số tài khoản
                    <span class="text-danger"></span></label>
                <ValidationProvider :vid="'stk'+index" name="Số tài khoản" rules="required"
                                    v-slot="{ errors,classes }">
                    <input name="stk" type="text" class="form-control" placeholder="Số tài khoản"
                           v-model="item.stk">
                    <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
        </div>
    </div>

</template>
<script>
    import {mapGetters} from "vuex";
    import Multiselect from 'vue-multiselect'

    export default {
        name: "BankAccount",
        props: {
            item: Object,
            index: null,
            banks: [],
            bank_account: [],
            provinces: [],
            branchs: [],
        },
        components: {
            Multiselect
        },
        data() {
            return {
                vmodel_index: '',
                cacheBank: [],
            };
        },
        created() {
            this.bank_account.map((value, index) => {
                if (index == this.index) {
                    if (value.is_default) {
                        this.vmodel_index = index
                    }
                }
            })
        },
        methods: {
            /*Xóa account*/
            minusAccount(index) {
                if (index > 0) {
                    this.$emit('minus_account', index)
                }
            },
            updateStateBank(obj, $event) {
                this.item.ngan_hang = $event.id;
                if ($event.id == 'Agribank') {
                    this.item.enable_branch_bank = true
                } else {
                    this.item.enable_branch_bank = false
                }
                this.item.branch_affter_select_province = []
            },
            eventChangeProvince(province, $event) {
                if ($event) {
                    let branchByProvince = this.branchs[$event];
                    this.item.branch_affter_select_province = branchByProvince
                }
            },
            updateRadio(key) {
                this.bank_account.map((value, index) => {
                    if (key == index) {
                        value.is_default = true
                    } else {
                        value.is_default = false
                    }
                })
            },
        },
        mounted() {

        },
        computed: {
            ...mapGetters(["currentUserAccountInfo", 'roles'])
        }
    };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .fa-minus-circle:hover {
        color: #F64E60;
    }
</style>

