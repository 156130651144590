<template>
    <b-modal @hide="onHide" :centered="true" ref="modal-course-level" hide-footer :title="title"  class="modal-course-level">
        <div class="d-block text-center">
            <ValidationObserver ref="form">
                <form class="form" @submit.prevent="saveCourseLevel">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Loại khóa học: <span class="text-danger">*</span></label>
                                <ValidationProvider name="Loại khóa học" rules="required" v-slot="{ errors,classes }" vid="course_type_id">
                                    <multiselect v-model="courseType" :options="courseTypes"
                                                 placeholder="Chọn loại khóa học"
                                                 label="name"
                                                 track-by="name"
                                                 :class="classes"
                                                 @input="handleChangeCourseType"
                                                 :disabled="check_edit || isDisable"
                                    ></multiselect>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="show_center" :hidden="check_general">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Trung tâm: <span class="text-danger">*</span></label>
                                <ValidationProvider name="Trung tâm" rules="required" v-slot="{ errors,classes }" vid="center_id">
                                    <multiselect v-model="center" :options="centers"
                                                 placeholder="Chọn trung tâm"
                                                 label="name"
                                                 track-by="name"
                                                 :class="classes"
                                                 @input="handleChangeCenter"
                                                 :disabled="check_edit"
                                    ></multiselect>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Tên trình độ: <span class="text-danger">*</span></label>
                                <ValidationProvider name="Tên trình độ" rules="required" v-slot="{ errors,classes }" vid="name">
                                    <input type="text" ref="name" name="name" class="form-control" :class="classes" placeholder="Tên trình độ" v-model="course_level.name" :disabled="check_general">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Trình độ: <span class="text-danger">*</span></label>
                                <ValidationProvider name="Trình độ" rules="required" v-slot="{ errors,classes }" vid="order">
                                    <input type="text" ref="order" name="order" class="form-control" pattern="\d+" v-on:input="validateInput" :class="classes" placeholder="Trình độ" v-model="course_level.order" :disabled="check_general">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Hiển thị: <span class="text-danger"></span></label>
                                    <div class="checkbox-inline">
                                        <label class="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" v-model="course_level.status" :disabled="check_general">
                                            <span></span></label>
                                    </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </div>
        <footer class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideModal">Hủy bỏ</button>
            <button v-if="!check_edit" type="button" class="btn btn-success" @click="saveCourseLevel">Xác nhận</button>
            <button v-if="check_edit" type="button" class="btn btn-success" @click="updateCourseLevel" :hidden="check_general">Xác nhận</button>
        </footer>
    </b-modal>
</template>
<script>
    import Multiselect from 'vue-multiselect'
    import '@/assets/sass/custom/custom_course_type.scss';
    import {CREATE_COURSE_LEVELS, UPDATE_COURSE_LEVELS} from "../../../core/services/store/course/courseLevel.module";
    import {GET_CENTERS} from "@/core/services/store/center/centers.module";
    const _ = require('lodash');

    export default {
        components: {
            Multiselect,
        },
        props: {
            showModal: { default: false },
            check_edit: { default: false },
            courseLevel: { default: null },
            allCourseType: { default: [] },
            course_type_id: { default: '' },
            centerId: { default: '' },
        },
        name: "Course-Level-Form",
        data() {
            return {
                course_level: {
                    course_type_id: '',
                    center_id: '',
                    name: "",
                    order: "",
                    status: true,
                },
                title: '',
                courseTypes: [],
                courseType: [],
                show_center: false,
                center: [],
                check_general: false,
                courseTypeId: '',
                centers: [],
                isDisable: false,
            }
        },
        mounted() {
            this.clearForm();
            this.getCenters();
        },
        methods: {
            getCenters() {
                this.$store.dispatch(GET_CENTERS, {
                    limit: 10000,
                    status: 1
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data;
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            validateInput() {
              this.course_level.order = this.course_level.order.replace(/[^0-9]+/g, '');
            },
            handleChangeCourseType(value) {
                if (value.id === 1 || value.id === 2 || value.id === 3) {
                    this.show_center = true;
                } else {
                    this.show_center = false;
                }
                this.course_level.course_type_id = value.id;
            },
            handleChangeCenter(value) {
                this.course_level.center_id = value.id;
            },
            onHide(evt) {
                this.$emit("clicked", true);
            },
            clearForm() {
                this.title = '';
                this.course_level.name = '';
                this.course_level.center_id = '';
                this.course_level.order = '';
                this.course_level.status = true;
                this.center = [];
                this.courseType = [];
            },
            hideModal() {
                this.$refs['modal-course-level'].hide();
            },
            toggleModal() {
                // when the modal has hidden
                this.$refs['modal-course-level'].toggle('#toggle-btn');
            },
            saveCourseLevel() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        return false;
                    } else {
                        // this.course_level.center_id = this.course_level.center_id.id;
                        this.course_level.center_id = this.centerId ? this.centerId : this.$route.query.center_id;
                        this.$store.dispatch(CREATE_COURSE_LEVELS, this.course_level).then((res) => {
                            if (!res.error) {
                                this.hideModal();
                                this.$bvToast.toast(res.message, {
                                    title: 'Tạo trình độ',
                                    variant: 'success',
                                    solid: true
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Tạo trình độ',
                                    variant: 'danger',
                                    solid: true
                                });
                            }}).catch((response) => {
                            if (response?.data?.data?.message_validate_form) {
                                this.$refs.form.setErrors(response.data.data.message_validate_form);
                                return;
                            }
                            this.$bvToast.toast('Có lỗi xảy ra', {
                                title: 'Tạo trình độ',
                                variant: 'danger',
                                solid: true
                            });
                        });
                    }
                });
            },
            updateCourseLevel() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        return false;
                    } else {
                        this.course_level.center_id = this.center.id;
                        this.$store.dispatch(UPDATE_COURSE_LEVELS, this.course_level).then((res) => {
                            if (!res.error) {
                                this.hideModal();
                                this.$bvToast.toast(res.message, {
                                    title: 'Cập nhật trình độ',
                                    variant: 'success',
                                    solid: true
                                });
                                this.courseType = this.course_level;
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Cập nhật trình độ',
                                    variant: 'danger',
                                    solid: true
                                });
                            }}).catch((response) => {
                            if (response?.data?.data?.message_validate_form) {
                                this.$refs.form.setErrors(response.data.data.message_validate_form);
                                return;
                            }
                            this.$bvToast.toast('Có lỗi xảy ra', {
                                title: 'Cập nhật trình độ',
                                variant: 'danger',
                                solid: true
                            });
                        });
                    }
                });
            },

        },
        watch: {
            showModal() {
                if (this.showModal) {
                    this.clearForm();
                    this.title = "Thêm trình độ";
                    this.check_general = false;
                    this.courseTypes = this.allCourseType;
                    if (this.course_type_id){
                      this.isDisable = true;
                      this.courseTypeId = this.course_type_id;
                      this.courseType = this.courseTypes.find((courseType)=> {
                        return courseType.id === parseInt(this.courseTypeId);
                      });
                      this.course_level.course_type_id = this.course_type_id;
                    }
                    this.$refs['modal-course-level'].show();
                    if (this.check_edit) {
                        this.title = "Sửa trình độ";
                        if (this.courseLevel) {
                            this.course_level = this.courseLevel;
                            this.courseType = this.courseLevel.course_type;
                            if (this.courseLevel.course_type_id === 1 || this.courseLevel.course_type_id === 2 || this.courseLevel.course_type_id === 3) {
                                this.show_center = true;
                            } else {
                                this.show_center = false;
                            }
                            this.center = this.courseLevel.center;
                            if (this.courseLevel.center_id == null || this.courseLevel.center_id === 0) {
                                this.check_general = true;
                            }
                        }
                    }
                }
            }
        }

    }
</script>
