<template>
  <div>
    <button
        class="btn btn-warning font-weight-bold btn-sm"
        v-b-modal="`swap-task-modal`"
    >
      <i class="fas fa-sync"></i>
      Hoán đổi
    </button>
    <b-modal
        centered
        id="swap-task-modal"
        size="lg"
        title="Hoán đổi tác vụ của 2 admin"
        hide-footer
        ref="swap-task-modal"
        @hidden="resetData"
    >
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" autocomplete="off">
          <div>
            <p>Thao tác hoán đổi người phụ trách của 2 tác vụ chỉ áp dụng với tác vụ test speaking.</p>
            <p>Để hoán đổi thành công, 2 admin phải có hình thức và cơ sở trực (nếu có) phù hợp với tác vụ mới.</p>
            <div class="">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="d-block">Admin 1<span class="text-danger">*</span></label>
                    <ValidationProvider name="Admin 1" rules="required" v-slot="{ errors,classes }"
                                        vid="Admin_1">
                      <el-select
                          v-model="admin1"
                          filterable
                          remote
                          reserve-keyword
                          placeholder="Vui lòng nhập Admin cần đổi"
                          class="w-100"
                          :remote-method="findTester"
                          :loading="loading"
                          @change="getListTask"
                      >
                        <el-option
                            v-for="item in listTester1"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="d-block">Admin 2<span class="text-danger">*</span></label>
                    <ValidationProvider name="Admin 2" rules="required" v-slot="{ errors,classes }"
                                        vid="Admin_2">
                      <el-select
                          v-model="admin2"
                          filterable
                          remote
                          reserve-keyword
                          placeholder="Vui lòng nhập Admin cần đổi"
                          class="w-100"
                          suffix-icon="el-icon-search"
                          :remote-method="findTester2"
                          @change="getListTask2"
                          :loading="loading"
                      >
                        <el-option
                            v-for="item in listTester2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="d-block">Tác vụ cần hoán đổi 1<span class="text-danger">*</span></label>
                    <ValidationProvider name="Tác vụ 1" rules="required" v-slot="{ errors,classes }"
                                        vid="task_1">
                      <el-select
                          v-model="task1"
                          filterable
                          remote
                          reserve-keyword
                          placeholder="Nhập tác vụ"
                          class="w-100"
                          :disabled="admin1 == null"
                      >
                        <el-option
                            v-for="item in listTask1"
                            :key="item.id"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="d-block">Tác vụ cần hoán đổi 2<span class="text-danger">*</span></label>
                    <ValidationProvider name="Tác vụ 2" rules="required" v-slot="{ errors,classes }"
                                        vid="task_2">
                      <el-select
                          v-model="task2"
                          filterable
                          remote
                          reserve-keyword
                          placeholder="Nhập tác vụ"
                          class="w-100"
                          :disabled="admin2 == null"
                      >
                        <el-option
                            v-for="item in listTask2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-secondary mr-2" @click="closeModal">Hủy bỏ</button>
                <button type="button" class="btn btn-primary font-weight-bold btn-sm" @click="handleSubmit(confirmSwap)" :disabled="loadingConfirm">
                  <i v-if="loadingConfirm" class="el-icon-loading"></i>Xác nhận
                </button>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>
<script>
import ModalHandleLoading from "@/view/pages/test-input/components/ModalHandleLoading.vue";
import {GET_LIST_TASK_TESTER, GET_TESTER_BY_KEYWORD, SWAP_TASK} from "@/core/services/store/test/testInput.module";
import {TYPE_TASK_ITEM_DEFINE} from "@/core/option/testInputOption";
import {formatDateTime, formatDateTimeVietnam} from "@/core/filters";

export default {
  name: "SwapTaskModal",
  components: {ModalHandleLoading},
  data() {
    return {
      listTester1: [],
      listTask1: [],
      listTester2: [],
      listTask2: [],
      loading: false,
      admin1: null,
      admin2: null,
      task1: null,
      task2: null,
      loadingConfirm: false
    }
  },
  methods: {
    findTester(query) {
      this.loading = true
      this.$store.dispatch(GET_TESTER_BY_KEYWORD, {keyword: query}).then((res) => {
        this.listTester1 = res.data.map((e) => {
          return {
            value: e?.tester_id,
            label: `${+e.user_id +100000} - ${e.user_name} - ${e.phone}`
          }
        })
      }).finally(() => {
        this.loading = false
      })
    },
    getListTask() {
      this.task1 = null
      this.$store.dispatch(GET_LIST_TASK_TESTER, {tester_id: this.admin1}).then((res) => {
        this.listTask1 = res.data.map((e) => {
          return {
            value: e?.id,
            label: `${e.id} - ${TYPE_TASK_ITEM_DEFINE[e?.type_item_id]} (${formatDateTimeVietnam(e?.schedule)})`
          }
        })
      })
    },
    findTester2(query) {
      this.loading = true
      this.$store.dispatch(GET_TESTER_BY_KEYWORD, {keyword: query}).then((res) => {
        this.listTester2 = res.data.map((e) => {
          return {
            value: e?.tester_id,
            label: `${e.user_id} - ${e.user_name} - ${e.phone}`
          }
        })
      }).finally(() => {
        this.loading = false
      })
    },
    getListTask2() {
      this.task2 = null
      this.$store.dispatch(GET_LIST_TASK_TESTER, {tester_id: this.admin2}).then((res) => {
        console.log(res)
        this.listTask2 = res.data.map((e) => {
          return {
            value: e?.id,
            label: `${e.id} - ${TYPE_TASK_ITEM_DEFINE[e?.type_item_id]} (${formatDateTimeVietnam(e?.schedule)})`
          }
        })
      })
    },
    resetData() {
      this.listTester1 = []
      this.listTask1 = []
      this.listTester2 = []
      this.listTask2 = []
      this.loading = false
      this.admin1 = null
      this.admin2 = null
      this.task1 = null
      this.task2 = null
    },
    closeModal(){
      this.$bvModal.hide('swap-task-modal')
    },
    confirmSwap(){
      this.loadingConfirm = true
      this.$store.dispatch(SWAP_TASK,{from_id:this.task1, to_id:this.task2}).then(()=>{
        this.$emit('create-success');
        this.$message({
          showClose: true,
          message: 'Hoán đổi thành công',
          type: 'success'
        });
        this.closeModal()
      }).catch((res)=>{
        this.$message({
          showClose: true,
          message: `${res?.response?.data?.message}`,
          type: 'error'
        });
      }).finally(()=>{
        this.loadingConfirm = false
      })
    }
  }
}
</script>

<style scoped>

</style>