<template>
  <div>
    <span>Bạn có chắc chắn muốn xóa KRA của nhân sự không?</span>
    <div style="margin-top: 20px; display: flex; justify-content: end; gap: 6px">
      <el-button @click="handleClose">Hủy bỏ</el-button>
      <el-button type="primary" @click="handleDeleteKRA">Xác nhận</el-button>
    </div>
  </div>
</template>

<script>
import {DELETE_KRA} from "@/core/services/store/backoffice/mangementKpi";

export default {
  data() {
    return {

    }
  },
  props: {
    idKRA: {
      type: Number || String,
      default: null,
      required: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog')
    },
    handleDeleteKRA() {
      this.$store.dispatch(DELETE_KRA, this.idKRA).then(() => {
        this.$message.success("Xoá KRA thành công")
        this.$emit('getListKraByUser')
        this.handleClose();
      }).catch(e => {
        console.log(e, 'Error delete kra');
      })
    }
  }
}
</script>

<style lang="css" scoped>
  .el-dialog__header {
    padding: 0 !important;
  }
</style>