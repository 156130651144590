<template>
  <div class="row" >
      <div class="col-6 mt-3">
        <label for="">Mã tác vụ</label>
        <el-input disabled class="w-100" :value="taskInfo?.task_id"></el-input>
      </div>
      <div class="col-6 mt-3">
        <label for="">Loại tác vụ</label>
        <el-input disabled class="w-100" :value="taskInfo?.type"></el-input>
      </div>
      <div class="col-6 mt-3">
        <label for="">Chi nhánh học</label>
        <el-input disabled class="w-100" :value="taskInfo?.branch_name"></el-input>
      </div>
      <div class="col-6 mt-3">
        <label for="">Lớp học</label>
        <el-input disabled class="w-100" :value="taskInfo?.classRoom"></el-input>
      </div>
      <div class="col-6 mt-3">
        <label for="">Thời gian khai giảng</label>
        <el-input disabled class="w-100" :value="taskInfo?.start_date"></el-input>
      </div>
      <div class="col-6 mt-3">
        <label for="">Người phụ trách lớp học</label>
        <el-input disabled class="w-100" :value="taskInfo?.classroom_person_in_charge"></el-input>
      </div>
    <div class="row col-12">
      <label class="col-6 mt-4" for="">Lịch học</label>
      <div class="bg-white w-100 p-4 mx-4" style="border-radius: 2px; border: 1px solid #DCDFE6">
        <div v-for="schedule in taskInfo?.classroom_schedule" class="row col-12 mb-3" style="align-items: center">
          <label class="col-2" style="font-weight: 700; font-size: 13px" for="">{{ schedule?.day }}</label>
          <div class="col-5">
            <label for="">Thời gian bắt đầu</label>
            <el-input disabled class="w-100" :value="schedule?.start_date"></el-input>
          </div>
          <div class="col-5">
            <label for="">Thời gian kết thúc</label>
            <el-input disabled class="w-100" :value="schedule?.end_date"></el-input>
          </div>
        </div>
      </div>
    </div>
      <div class="col-xl-6 mt-3">
        <label for="">Địa chỉ /Link lớp học</label>
        <el-input disabled class="w-100" :value="valueAddressZoom()"></el-input>
      </div>
      <div class="col-6 mt-3">
        <label for="">Người phụ trách tác vụ</label>
        <el-input disabled class="w-100" :value="taskInfo?.responsible"></el-input>
      </div>
      <div class="col-6 mt-3">
        <label for="">Thời gian tạo</label>
        <el-input disabled class="w-100" :value="taskInfo?.created_at"></el-input>
      </div>
    <div class="row col-12">
      <div class="col-6 mt-3">
        <label for="">Họ tên khách hàng</label>
        <div disabled class="d-flex justify-content-between w-100 rounded input-detail">
          <span>{{ taskInfo?.user_name }}</span>
          <span class="show_detail" @click="navigateDetailCustomer(taskInfo)">Xem chi tiết</span>
        </div>
      </div>
      <div class="col-6 mt-3">
        <label for="">Số điện thoại đăng ký</label>
        <el-input disabled class="w-100" :value="taskInfo?.Phone"></el-input>
      </div>
      <div class="col-6 mt-3">
        <label for="">Số điện thoại liên hệ</label>
        <el-input disabled class="w-100" :value="taskInfo?.user_phone_other"></el-input>
      </div>
      <div class="col-6 mt-3">
        <label for="">Nhà mạng</label>
        <el-input disabled class="w-100" :value="taskInfo?.network"></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import {TYPE_TASK_DIFINE} from '@/core/option/taskArrangePersonal';

export default {
  name: "infoLayering",
  props: {
    taskInfo: {
      type: {},
      default: () => {
        return {}
      }
    },
  },
  components: {},
  data() {
    return {
      typeTaskDifine: TYPE_TASK_DIFINE,
    }
  },
  mounted() {

  },

  methods: {
    navigateDetailCustomer(taskInfo) {
      window.open(this.$router.resolve({
            name: 'customer-crm-detail',
            params: {id: taskInfo.user_id},
            query: {best_customer_id: taskInfo.best_customer_id}
          }
      ).href, `_blank`)
    },
    valueAddressZoom() {
      return this.taskInfo?.classroom_address ? this.taskInfo?.classroom_address : this.taskInfo.zoom_id ? `Zoom ID: ${this.taskInfo.zoom_id} | Zoom Password: ${this.taskInfo.zoom_password}` : ''
    }

  },
};
</script>

<style scoped>
.show_detail {
  color: rgba(0, 102, 255, 1);
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s;
}

.show_detail:hover {
  color: rgb(0, 0, 255);
}
</style>