<template>
	<div class="text-center">
		<div class="spinner-pending">
			<img class="img" src="../../../../../../public/media/test-input/pending.png" alt="hihi">
		</div>
		<h1 style="font-size: 13px" class="mt-3"><p>Hệ thống đang trong quá trình xử lý.</p> <p>Vui lòng chờ trong giây lát.</p></h1>
		<h1 class="mt-3" style="color:#5E00B6;
">{{ seconds }}s</h1>
	</div>
</template>

<script>
export default {
	name: "LoadingCreateTestInput",
	props: {
		seconds: {
			type: String,
			default: () => {
				return "";
			}
		}
	},
}
</script>

<style scoped>
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.spinner-pending {
	display: flex;
	justify-content: center;
	align-items: center;
}

.img {
	height: 30px;
	animation: spin 2.5s linear infinite;
}
</style>