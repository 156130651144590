
export const types = [
    {
        id: 1,
        name: 'Trung tâm',
    },
    {
        id: 2,
        name: 'Tỉnh',
    }
]

export const typeFormatText = {
    1 : 'Trung tâm',
    2 : 'Tỉnh'
}

export const MT01 = 9;
export const MT02 = 13;






