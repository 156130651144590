<template>
  <div class="example-preview table-responsive">
    <table class="table table-vertical-center table-hover">
      <thead>
      <tr>
        <th colspan="2">Ca trực</th>
        <th v-for="(item, index) in days" :key="index">{{ item.dayOfWeek }}<br>({{ item.day }})</th>
      </tr>
      </thead>
      <tbody v-for="(task, index) in tasks" :key="index">
      <tr>
        <td rowspan="7">{{ index }}</td>
      </tr>
      <tr>
        <td>{{task}}</td>
        <td>
          <span class="badge badge-success">1046 - Gia sư</span>
        </td>
      </tr>
      <tr>
        <td>08:30</td>
        <td>
          <span class="badge badge-success">1046 - Gia sư</span>
        </td>
      </tr>
      <tr>
        <td>08:30</td>
      </tr>
      <tr>
        <td>08:30</td>
      </tr>
      <tr>
        <td>08:30</td>
      </tr>
      <tr>
        <td>08:30</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TaskManagerCalendar",
  props: {
    tasks: {
      type: Array,
      default: () => {
        return [];
      }
    },
    days: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    handleCalendar() {
      console.log(this.tasks)
    }
  }
}
</script>

<style scoped>

</style>