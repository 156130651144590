<template>
    <div>
        <div v-if="isShowButton">
            <button
                v-if="Math.abs(item.total_amount_childes) === 0 || Math.abs(item.total_amount_childes) < Math.abs(item.amount)"
                v-b-modal="`modal-transfer-${index}`"
                class="btn btn-light-danger btn-xs p-2">
                <i class="la la-share fa-xs"></i>
                Chuyển
            </button>
            <button v-else class="btn btn-success btn btn-light-primary  font-weight-bold">
                <i class="la la-angle-down"></i>Hoàn thành
            </button>
        </div>
        <b-modal :centered="true" ref="modal-amount" hide-footer :id="'modal-transfer-' + index"
                 title="Chuyển tiền cho trung tâm">
            <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                <form class="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Số tiền
                                    <span
                                        class="text-danger">* (Tối đa
                    {{ formatPrice(Math.abs(item.amount - item.total_amount_childes), ' đ') }})</span></label>
                                <ValidationProvider vid="amount" name="Số tiền chuyển"
                                                    rules="excluded:0|min_value:1"
                                                    mode="lazy"
                                                    v-slot="{ errors,classes }">
                                    <money v-model="price" v-bind="money" class="form-control" :class="classes"
                                    ></money>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="amount" data-validator="notEmpty" class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="float-right">
                        <button type="button" class="btn btn-secondary mr-2" @click="hideModal()"> Hủy bỏ</button>
                        <button type="submit" class="btn btn-success">Xác nhận</button>
                    </div>
                </form>
            </ValidationObserver>
        </b-modal>
    </div>
</template>
<script>
import {mapGetters,} from "vuex";
import {Money} from 'v-money'
import {CREATE_STUDENT_AMOUNT} from "../../../core/services/store/contract/studentAmount.module";

export default {
    components: {
        Money,
        mapGetters
    },
    props: {
        item: Object,
        index: '',
        isShowButton: {
            type: Boolean,
            default() {
                return true
            }
        }
    },
    data() {
        return {
            price: '',
            money: {
                decimal: ',',
                thousands: ',',
                prefix: '',
                suffix: ' đ',
                precision: 0,
                masked: false,
            }
        }
    },
    name: "ModalTransferMoney",

    methods: {
        onSubmit() {
            this.$store
                .dispatch(CREATE_STUDENT_AMOUNT, {
                    parent_log_id: this.item.id,
                    amount: this.price
                })
                .then((res) => {
                    if (!res.error) {
                        this.$refs['modal-amount'].hide();
                        this.$emit('move-success')
                        this.$bvToast.toast(res.message, {
                            title: 'Đóng tiền',
                            variant: 'success',
                            solid: true
                        });
                        this.price = ''
                    } else {
                        this.$bvToast.toast(res.message, {
                            title: 'Đóng tiền',
                            variant: 'danger',
                            solid: true
                        });
                    }
                }).catch((e) => {
                if (e.data.error && e.status !== 404) {
                    this.$bvToast.toast(e.data.message, {
                        title: 'Lỗi',
                        variant: 'danger',
                        solid: true
                    });
                }
                if (e.data.data.message_validate_form) {
                    this.$refs.form.setErrors(e.data.data.message_validate_form);
                }
            });
        },
        hideModal() {
            this.$refs['modal-amount'].hide();
        }
    }
}
</script>

<style scoped>
</style>
