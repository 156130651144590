<template>
  <div style="display: flex; align-items: center; justify-content: space-between; padding: 10px 20px">
    <h4 style="margin: 0">Nguồn lực học viên mở lớp</h4>
    <el-button
        @click="exportExcelProductionManagement"
        type="primary"
        style="display: flex; align-items: center; gap: 6px; color: white">
      <i class="el-icon-download text-white"></i>
      <span>Export</span>
    </el-button>
  </div>
</template>

<script>

import {exportExcel} from "@/core/filters";
import {EXPORT_LIST_PRODUCTION} from "@/core/services/store/course/classes.module";
import {ProductionModel} from "@/view/pages/production-management/model/productionModel";

export default {
  name: 'HeaderProductionManagement',
  props: {
    query: {
      type: Object,
      required: true,
      default: null
    }
  },
  methods: {
    exportExcelProductionManagement() {
      this.is_export_excel = true
      const HEADER_ROW_EXPORT_PRODUCT_MANAGEMENT = [
        {
          key: 'code',
          value: 'Mã học viên',
        },
        {
          key: 'name',
          value: 'Họ tên khách hàng',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'branch',
          value: 'Chi nhánh học',
        },
        {
          key: 'course',
          value: 'Khóa học',
        },
        {
          key: 'class_name',
          value: 'Lớp học khóa trước',
        },
        {
          key: 'status',
          value: 'Trạng thái học viên',
        },
        {
          key: 'timeStudy',
          value: 'Thời gian có thể đi học',
        },
        {
          key: 'schedule',
          value: 'Lịch học mong muốn',
        },
      ]
      const payload = {
        keyword: this.query?.keyword,
        center_id: this.query?.center_id,
        branch_id: this.query?.branch_id,
        course_id: this.query?.course_id,
        status_student: this.query?.status_student,
        time_available: this.query?.time_available,
        desired_schedule: this.query?.desired_schedule,
      }
      this.$store.dispatch(EXPORT_LIST_PRODUCTION, payload).then((res) => {
        if (!res.error) {
          this.is_export_excel = false
          const data = res?.data?.map(item => {
            return new ProductionModel(item)
          })
          exportExcel(HEADER_ROW_EXPORT_PRODUCT_MANAGEMENT, data, "Danh sách quản lý nguồn lực mở lớp.xlsx", false)
        }
      });
    },
  }
}
</script>
