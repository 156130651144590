<template>
  <div class="card card-custom gutter-b h-100">
    <!--    header-->
    <div class="card-header">
      <div class="card-title">
        Danh sách giải đấu
      </div>
      <div class="card-title">
        <router-link class="font-weight-bold font-size-3 btn btn-success" to="/tournament/add-tournament">Thêm giải
          đấu
        </router-link>
      </div>
    </div>
    <!--    body-->
    <div class="card-body">
      <div class="row">
        <div class="col-md-3 col-sm-12 mb-3">
          <h4 style="font-size: 13px">Tên sự kiện</h4>
          <el-input placeholder="Nhập tên sự kiện" v-model="nameFind" @input="handleFindTournament()"></el-input>
        </div>
      </div>
      <!--      table-->
      <div class="mt-10 table-responsive">
        <table class="table table-bordered text-center table-vertical-center ">
          <thead>
          <tr style="background-color: #F8F8F8 ; font-size: 13px">
            <th class="text-center">STT</th>
            <th>Tên giải đấu</th>
            <th>Thời gian bắt đầu</th>
            <th>Thời gian kết thúc</th>
            <th>Trạng thái</th>
            <th>Hành động</th>
          </tr>
          </thead>
          <tbody v-if="tournamentList.length == 0">
          <tr>
            <td colspan="6">
              Không có dữ liệu.
            </td>
          </tr>
          </tbody>
          <tbody>
          <tr v-for="(tournament,index) in tournamentList">
            <td class="text-center">{{ index + 1 }}</td>
            <td>{{ tournament.nameTournament }}</td>
            <td>{{ tournament.timeStart }}</td>
            <td>{{ tournament.timeEnd }}</td>
            <td>
              <p :class="tournament?.color" class="mb-0">
                {{ tournament.statusName }}
              </p>
            </td>
            <td class="text-left">
              <div >
                <button class="btn btn-xs btn-icon mr-2 btn-outline-primary"
                        title="Chi tiết giải đấu"
                        @click="detailTournament(tournament.id)">
                  <i class="fa fa-eye"></i>
                </button>
                <button class="btn btn-xs btn-icon mr-2 btn-outline-primary"
                        title="Tổ chức giải đấu"
                        @click="organizeTournament(tournament.id)">
                  <i class="flaticon2-file-2"></i>
                </button>
                <button v-if="tournament?.status == CHUA_BAT_DAU" class="btn btn-xs btn-icon mr-2 btn-outline-danger"
                        title="xóa"
                        @click="deleteTournament(tournament.id)">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mb-0 d-flex flex-row-reverse mr-10">
      <p class="mb-0">Tổng số bản ghi: <span class="text-primary ">{{total}}</span></p>
    </div>
    <div class="edu-paginate mx-auto text-center mt-10 ">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import {TournamentList, TournamentListModel} from "@/view/pages/Tournaments/Models/TournamentListModel";
import {CHUA_BAT_DAU} from "@/core/option/tournamentOption";
import router from "@/router";
import {DELETE_TOURNAMENT, GET_TOURNAMENT} from "@/core/services/store/tournament/tournament.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "TournamentList",
  data() {
    return {
      tournamentList: [],
      CHUA_BAT_DAU: CHUA_BAT_DAU,
      nameFind: '',
      page: 1,
      last_page: 0,
      total:0
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý dạy học", route: "" },
      { title: "Danh sách giải đấu" },
    ]);
    this.getTournamentList()
  },
  methods: {
    getTournamentList() {
      this.$store.dispatch(GET_TOURNAMENT, {page: this.page}).then((data) => {
        this.tournamentList = data.data.map((tournament) => new TournamentListModel(tournament.id, tournament.name, tournament.start_date, tournament.end_date, tournament.status))
        this.total = data.pagination.total;
        this.page = data.pagination.page;
        this.last_page = data.pagination.last_page;
      })
    },
    findTournaments() {
      this.$store.dispatch(GET_TOURNAMENT, {name: this.nameFind, page: this.page}).then((data) => {
        this.tournamentList = data.data.map((tournament) => new TournamentListModel(tournament.id, tournament.name, tournament.start_date, tournament.end_date, tournament.status))
        this.total = data.pagination.total;
        this.page = data.pagination.page;
        this.last_page = data.pagination.last_page;
      })
    },
    detailTournament(id) {
      router.push({path: `/tournament/tournament-detail/${id}`})
    },
    organizeTournament(id) {
      router.push({path: `/tournament/organize-tournament/${id}`})
    },
    deleteTournament(id) {
      this.$confirm("Bạn có chắc chắn muốn xóa tin <b>Kết quả vòng bảng</b> này không?", 'Xóa giải đấu', {
        confirmButtonText: 'Xác nhận',
        dangerouslyUseHTMLString:true,
        confirmButtonClass:"bg-success border-white",
        cancelButtonText: 'Hủy',
        type: 'Xóa giải đấu',
      }).then(() => {
        this.$store.dispatch(DELETE_TOURNAMENT, id).then(() => {
          this.getTournamentList()
          this.$notify({
            title: 'Thành công',
            message: 'Xóa giải đấu thành công',
            type: 'success'
          });
        })
      }).catch((data) => {
        
      });
    },
    clickCallback(obj) {
      this.page = obj;
      this.getTournamentList();
    },
    handleFindTournament() {
      this.findTournaments()
    },

  }
}
</script>

<style scoped>

</style>