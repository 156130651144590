<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(handleCreateTask)">
        <div class="flex-column">
          <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px; align-items: start">
            <ValidationProvider vid="type" name="Loại tác vụ" rules="required" v-slot="{ errors,classes }">
              <span class="label-custom">Loại tác vụ</span>
              <el-select v-model="formData.typeTask" filterable clearable style="width: 100%"
                         placeholder="Chọn loại tác vụ" >
                <el-option
                    v-for="item in typeManagementOption"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
            <ValidationProvider vid="customer" name="Khách hàng" rules="required|max:50" v-slot="{ errors,classes }" v-if="formData.typeTask === XEP_LOP">
              <span class="label-custom">Khách hàng</span>
              <el-autocomplete
                  style="width: 100%"
                  :clearable="true"
                  v-model="queryString"
                  :fetch-suggestions="querySearch"
                  placeholder="Nhập mã học viên hoặc số điện thoại"
                  :debounce="300"
                  @select="handleSelect"
              ></el-autocomplete>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
            <ValidationProvider vid="center" name="Trung tâm" rules="required" v-slot="{ errors,classes }" v-if="formData.typeTask === KHAI_GIANG">
              <span class="label-custom">Trung tâm</span>
              <el-select v-model="formData.center" filterable style="width: 100%"
                         placeholder="Chọn khóa học cần xếp lớp" @change="getClassList()"  >
                <el-option
                    v-for="item in centers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px; align-items: start" v-if="formData.typeTask === XEP_LOP">
            <ValidationProvider vid="course" name="Khóa học cần xếp lớp" rules="required" v-slot="{ errors,classes }">
              <span class="label-custom">Khóa học cần xếp lớp</span>
              <el-select v-model="formData.course" filterable style="width: 100%" clearable
                         placeholder="Chọn khóa học cần xếp lớp">
                <el-option
                    v-for="item in listCourseOfStudent"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
            <ValidationProvider vid="calendarCall" name="Lịch gọi" rules="required" v-slot="{ errors,classes }" >
              <span class="label-custom">Lịch gọi</span>
              <date-picker
                  v-model="formData.callCalendar"
                  type="datetime"
                  placeholder="Thời gian gọi lại"
                  format="HH:mm DD-MM-YYYY"
                  valueType="YYYY-MM-DD HH:mm"
                  class="w-100"
                  :disabled-date="disabledDate"
                  :disabled-time="disableTime"
              >
              </date-picker>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <ValidationProvider vid="class" name="Lớp học" rules="required" v-slot="{ errors,classes }" v-if="formData.typeTask === KHAI_GIANG" >
          <span class="label-custom">Lớp học</span>
          <el-select v-model="formData.class" filterable style="width: 49%" clearable
                     placeholder="Chọn khóa học cần xếp lớp" :disabled="formData.center === null" :loading="loading">
            <el-option
                v-for="item in classList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
          </div>
        </ValidationProvider>
        <div style="margin-top: 10px;">
          <ValidationProvider vid="reason" name="Lý do thêm mới tác vụ" rules="required|max:50"
                              v-slot="{ errors,classes }">
            <span class="label-custom">Lý do thêm mới tác vụ</span>
            <el-input v-model="formData.reasonCreateTask" type="textarea" placeholder="Nhập lý do thêm mới tác vụ"/>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>
        </div>
        <div class="action">
          <button class="btn-custom" style="background: #dddbdb; color: black" @click="closeDialog">Đóng</button>
          <button class="btn-custom" style="background: #5E00B6; color: white" type="submit">Thêm mới</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>

import {CustomerModel} from "@/view/pages/task-management/model/TaskManagementModel";
import {
  CREATE_TASK_MANAGEMENT, GET_CLASS_LIST,
  GET_LIST_COURSE_OF_STUDENT, GET_LIST_CUSTOMER,
} from "@/core/services/store/task-management/task-management.module";
import moment from "moment-timezone";
import {KHAI_GIANG, TYPE_TASK, TYPE_TASK_ADDED, XEP_LOP} from "@/core/option/taskArrangePersonal";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'DialogAddTaskManagement',
  components: {DatePicker},
  data() {
    return {
      queryString: '',
      formData: {
        typeTask: 1,
        course: null,
        callCalendar: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        reasonCreateTask: null,
        center: null,
        class: null
      },
      defaultValue: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      typeManagementOption: TYPE_TASK_ADDED,
      listCourseOfStudent: [],
      userInfo: null,
      KHAI_GIANG: KHAI_GIANG,
      XEP_LOP: XEP_LOP,
      loading: false,
      classList: []
    }
  },
  props:{
    centers:{
      type:Array,
      default(){
        return []
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    handleCreateTask() {
      const payload = {
        student_register_course_id: this.formData.course,
        call_time: this.formData.callCalendar,
        user_id: this.userInfo,
        reason: this.formData.reasonCreateTask,
        center_id: this.formData.center,
        class_id: this.formData.class,
        type_task: this.formData.typeTask
      }
      this.$store.dispatch(CREATE_TASK_MANAGEMENT, payload).then(res => {
        if (!res.error) {
          this.closeDialog()
          this.$emit('getListTaskManagement')
          this.$message.success('Thêm mới tác vụ thành công')
        } else {
          this.$message.error(res.message)
        }
      }).catch(e => {
        console.log(e, 'Error create task!')
      })
    },
    handleSelect(item) {
      this.userInfo = +item?.id + 100000
      this.getListCourses()
    },
    querySearch(queryString, callback) {
      if (!queryString) {
        return callback([]);
      }
      const payload = {
        keyword: this.queryString,
      }
      this.$store.dispatch(GET_LIST_CUSTOMER, payload).then(res => {
        if (!res.error) {
          const listCustomer = res?.data?.map(item => {
            return new CustomerModel(item?.id, item?.name)
          })
          callback(listCustomer)
        }
      }).catch(e => {
        callback([]);
        console.log(e, 'get customer error')
      })
    },
    getListCourses() {
      const payload = {
        user_id: this.userInfo,
      }
      this.$store.dispatch(GET_LIST_COURSE_OF_STUDENT, payload).then(res => {
        if (!res.error) {
          this.listCourseOfStudent = res?.data ?? []
        }
      })
    },
    disabledDate(time) {
      return moment(time).isSameOrBefore(moment().subtract(1, 'days'));
    },
    disableTime(time) {
      return moment(time).isSameOrBefore()
    },
    getClassList(){
      this.loading = true;
      this.$store.dispatch(GET_CLASS_LIST,{center_id: this.formData.center}).then((data)=>{
        this.classList = data.data
      }).catch(()=>{
        this.classList = []
      }).finally(()=>{
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>
.action {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
}

.btn-custom {
  padding: 10px 15px;
  border-radius: 5px;
}

.label-custom {
  display: block;
  margin-bottom: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
</style>