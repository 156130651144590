<template>
  <div>
    <h3 class="question-description">
      <div v-html="formatBrTagText(question.description)"></div>
    </h3>
    <h4 class="question-script" v-if="question.script">
      <div v-html="formatBrTagText(question.script)"></div>
    </h4>
    <div class="question-script" v-if="question.image">
      <el-image
          :src="question.image"
          fit="fill"
      ></el-image>
    </div>
    <div v-if="question.audio" class="mb-3">
      <audio ref="audio" controls preload="metadata">
        <source :src="question.audio" type="audio/ogg">
      </audio>
  </div>
    <div v-if="question.video" class="mb-3">
      <video class="rounded" width="320" height="240" preload="auto" controls>
        <source :src="question.video" type="video/mp4">
      </video>
    </div>
  </div>
</template>
<script>
export default {
  name: "homework-container",
  props: {
    question: {
      type: Object,
      default: () => { return {} }
    }
  },
  data(){
    return {}
  },
  watch: {
    question: {
      handler() {
        if (this.question.audio){
          this.$refs['audio']?.load()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    formatBrTagText(value) {
      return value ? value.replace(/(\r\n|\n\r|\r|\n)/g, '<br>') : '';
    },
  }
}
</script>

<style scoped>

</style>