<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(handleUpdateHonor)">
        <div style="width: 100%; height: 1px; background: gray; margin-top: -20px; margin-bottom: 20px"></div>
        <div style="display: grid; grid-template-columns: 6fr 6fr; gap: 40px">
          <ValidationProvider name="Tên chương trình" rules="required|max:50" v-slot="{ errors,classes }" vid="name">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Chương trình <span style="color: red">*</span></span>
              <el-input size="small" v-model="cloneHonor.name" placeholder="Nhập tên chương trình"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider name="Thời gian vinh danh" rules="required" v-slot="{ errors,classes }" vid="date">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Thời gian vinh danh <span style="color: red">*</span></span>
              <el-date-picker
                  v-model="cloneHonor.date"
                  size="small"
                  placeholder="Chọn khoảng thời gian"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <div class="fv-plugins-message-container">
                <div data-field="date" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div style="display: flex; flex-direction: column; gap: 10px; margin-top: 30px">
          <span class="page-filter__title">Mô tả</span>
          <el-input size="small" type="textarea" v-model="cloneHonor.description" placeholder="Nhập mô tả"/>
        </div>
        <div style="display: flex; justify-content: end; margin-top: 30px; gap: 10px">
          <el-button style="background: #CACACA" @click="closeDialog()">Hủy bỏ</el-button>
          <button
              :disabled="loading"
              style="background: #5D04B0; padding: 0 10px; border-radius: 5px; color: white; position:relative;"
          >
            <i v-if="loading" class="el-icon-loading text-white float-righ"></i>
            Xác nhận
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {UPDATE_HONOR} from "@/core/services/store/honors/honor.module";

export default {
  props: {
    honor: {
      type: Object,
      default: {
        id: null,
        name: '',
        date: '',
        description: ''
      }
    },
    isEdit: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
      cloneHonor: {}
    }
  },
  mounted() {
    this.cloneHonor = {...this.honor}
  },
  methods: {
    handleUpdateHonor() {
      this.loading = true
      const payload = {
        program_name: this.cloneHonor.name,
        start_time: this.cloneHonor.date,
        program_desc: this.cloneHonor.description,
        honor_status: this.cloneHonor.status,
        id: this.cloneHonor.id,
      }
      this.$store.dispatch(UPDATE_HONOR, payload).then(() => {
        this.$message.success('Sửa chương trình thành công')
        this.closeDialog();
        this.$emit('getListHonor')
      })
    },
    closeDialog() {
      this.loading = false;
      this.$emit('closeDialog')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
