<template>
    <div>
        <button class="btn btn-light-info font-weight-bold" @click="openModal">
            <i class="la la-money-bill"></i>Hóa đơn điện tử
        </button>
        <el-dialog title="Hóa đơn điện tử" class="responsive" :visible.sync="dialogFormVisible">
            <el-row :gutter="5" class="pr-2 pl-2">
                <p>Chọn mẫu hóa đơn: <span class="text-danger">*</span></p>
                <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                    <form class="form" @submit.prevent="handleSubmit(onSubmit)">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <ValidationProvider vid="type-bill" name="Mẫu hóa đơn" rules="required" v-slot="{ errors,classes }">
                                        <el-radio-group v-model="typeBill">
                                            <el-radio label="1" border>Hóa đơn Edutalk</el-radio>
                                            <el-radio label="2" border>Hóa đơn Keypass</el-radio>
                                            <el-radio label="3" border>Hóa đơn IELTS Mentor</el-radio>
                                            <el-radio label="4" border>Hóa đơn Ecoach</el-radio>
                                        </el-radio-group>
                                        <div class="fv-plugins-message-container">
                                            <div data-field="type-bill" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <el-row class="mt-10">
                            <button class="btn btn-success float-right mt-3 ml-3" @click="onSubmit">Xác nhận</button>
                            <button type="button" class="btn btn-secondary float-right mt-3 ml-3" @click="dialogFormVisible = false">Hủy bỏ</button>
                        </el-row>
                    </form>
                </ValidationObserver>
            </el-row>

        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Modal-Bill",
        props: {
            item: null
        },
        data() {
            return {
                dialogFormVisible: false,
                typeBill: ''
            }
        },
        methods: {
            openModal() {
                this.dialogFormVisible = true
            },
            onSubmit() {
                let url = window.location.origin + '/bill?id=' + this.item.id + '&type=' + this.typeBill
                window.open(url, '_blank');
            }
        }
    }
</script>

<style scoped>

</style>
