<template>
  <div class="container-fluid">
    <!--Title-->
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Tiêu đề
            <span class="text-danger"></span></label>
          <el-input
              placeholder="Tiêu đề"
              v-model="input"
              clearable>
          </el-input>
        </div>
      </div>
    </div>
    <!--End-->

    <!--Mô tả-->
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Mô tả
            <span class="text-danger"></span></label>
          <textarea class="form-control" rows="3"
                    v-model="input"
                    placeholder="Mô tả"></textarea>
        </div>
      </div>
    </div>
    <!--End-->

    <!-- Ảnh   -->
<!--    <div class="row">-->
<!--      <div class="col-md-6">-->
<!--        <div class="form-group">-->
<!--          <label>Ảnh-->
<!--            <span class="text-danger"></span></label>-->
<!--          <input-->
<!--              type="file"-->
<!--              name="profile_avatar"-->
<!--              accept=".png, .jpg, .jpeg"-->
<!--              @change="onFileChange($event)"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-md-6">-->
<!--      </div>-->
<!--    </div>-->
    <!--End-->

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TempTitle",
  data() {
    return {
      input: '',
      current_photo: null,
      image_name: null,
      image_type: null,
    }
  },
  computed: {
    photo() {
      return this.current_photo == null
          ? this.default_photo
          : this.current_photo;
    }
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
        this.image_name = file.name;
        this.image_type = file.type;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

  }
}
</script>

<style scoped>

</style>