<template>
  <div>
    <div>
      <p>Hệ thống phát hiện có lỗi trong quá trình import dữ liệu:</p>
    </div>
    <div class="example-preview b-table-sticky-header">
        <table class="table table-vertical-center b-table">
            <thead class="thead-light">
                <tr>
                    <th>Tên khóa học</th>
                    <th>Loại voucher</th>
                    <th>Nội dung lỗi</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in dataPreview" :key="index">
                    <td> {{item.course_name}}</td>
                    <td> {{item.id_voucher == 1  ? 'Giảm giá theo khoá học' : (item.id_voucher == 0 ? 'Giảm giá theo hợp đồng' : '')}}</td>
                    <td v-if="!item.success || invalidateDataImport" class="text-success">
                        Đạt 
                    </td>
                    <td v-else>
                      <p v-for="(item_err, index_err) in item?.error[0]?.filter(d => d != null)" :key="index_err">
                            <span v-if="item_err" class="text-red">{{item_err}}</span>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="mt-3 d-flex justify-content-end">
        <el-button @click="closeModal" class="btn">Quay lại</el-button>
        <el-button v-if="invalidateDataImport" :disabled="is_disable" type="success" class="btn btn-success" @click="submitUpload">Xác nhận</el-button>
    </div>
  </div>
</template>

<script>
import {
    IMPORT_CLASS
} from "@/core/services/store/rewards-programs/rewardsPrograms.module";

export default {
    name: "ModalImportClass",
    data() {
        return {
            fileList: [],
            dataImport: {
                spin_reward_program_id: '',
                data: []
            },
            is_disable: false
        }
    },
    props: {
        dataPreview : {
            type : Array,
            default()  {
                return [];
            }
        },
    },
    mounted() {

    },

    methods: {
        submitUpload() {
            this.dataImport.spin_reward_program_id = this.$route.params.id;
            this.dataImport.data = this.dataPreview;
            this.is_disable = true;

            this.$store.dispatch(IMPORT_CLASS, this.dataImport).then((data) => {
                this.$notify({
                    title: 'Thành công',
                    message: data.message,
                    type: 'success'
                });
                this.closeModalDone();
                this.is_disable = false;
            })
        },
        closeModal() {
            this.$emit("closeModalReview", false);
        },
        closeModalDone() {
            this.$emit("doneImport", false);
        },
    },
    computed: {
        invalidateDataImport() {
            if (this.dataPreview.find(d => d.error[0].find(c => c != null))) {
                return false;
            }
            return true;
        }
    },

}

</script>

<style>
.text-success {
    color: aquamarine;
}
.text-red {
    color: red;
}
</style>