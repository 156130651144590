<template id="listAdmissions">
        <div class="col-xl-12">
          <div class="mb-5 mt-5">
            <h5 style="margin-bottom: 0;">Danh sách lớp đang tuyển sinh</h5>
          </div>
        <div class="border border-1 rounded" style="background-color: rgba(248, 248, 248, 1);">
                <div class="d-flex">
                    <div class="col-md-4 mt-4">
                        <label for="">Chi nhánh</label>
                        <template>
                          <el-select class="w-100" v-model="query.branch" filterable placeholder="Tìm theo chi nhánh">
                            <el-option v-for="item in branches" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                          </el-select>
                        </template>
                        <!-- <el-input size="medium" placeholder="Tìm theo chi nhánh" el-input class="w-100" v-model="query.branch"></el-input> -->
                    </div>

                    <div class="col-md-4 mt-4">
                        <label for="">Lịch học</label>
                        <el-input
                            size="medium"
                            placeholder="Tìm lịch học"
                            prefix-icon="el-icon-search"
                            v-model="query.schedule">
                        </el-input>
                    </div>
                </div>

                <div class="mt-5 col-md-12" style="overflow: scroll; height: 30vh;" >
                    <div class="example-preview table-responsive" style="scroll-behavior: smooth;">
                      <table style="background-color: #fff;" class="table table-bordered table-vertical-center">
                        <thead>
                          <tr>
                            <th scope="col">Mã lớp</th>
                            <th scope="col" class="min-w-80px">Lịch học</th>
                            <th scope="col" class="min-w-200px">Lịch khai giảng dự kiến</th>
                            <th scope="col" class="min-w-100px">KG chính thức</th>
                            <th scope="col" class="min-w-50px">Sĩ số</th>
                          </tr>
                        </thead>
                        <tbody v-if="listClass?.length > 0">
                        <tr v-for="(item, index) in listClass">
                          <td>{{ item.id }}</td>
                          <td>{{ item.schedule }}</td>
                          <td>{{ item.time_opening }}</td>
                          <td v-if="date_opening">{{ item?.date_opening ?? '' }}</td>
                          <td v-else><button class="rounded-pill badge bg-secondary">Chưa chốt</button></td>
                          <td>{{ item.number_of_students }}</td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
        </div>
 </div>
</template>

<script>

export default {
 name: "ListAdmissions",
 components: {},
 props: {
    branches: {
      type: Object,
        default: () => {
          return null;
        }
    },

    listClass: {
      type: Array,
        default: () => {
          return [];
        }
    },
  },
 data() {
   return {
     query: {
      branch: '',
      schedule: ''
     }
   }
 },
 mounted() {

 },

 methods: {

 }
 
}
</script>

<style>
#listAdmissions table th,
table td {
  padding: 0.8rem;
  text-align: start;
}
</style>