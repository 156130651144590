<template>
  <div class="  ">
    <h4>Tổng hợp điểm</h4>
    <div class="card mb-5" style="background-color: #F6F6F6" v-for="skill in resultTests">
      <div class="d-flex justify-content-between align-items-center p-3" style="text-align: left">
        <p class="mb-0" style="font-size: 13px">{{ skill.nameType }}</p>
        <p class="mb-0" style="font-size: 13px">Số điểm: {{ skill.totalPoint ?? skill.totalPointAuto }}
          /{{ testScores }}</p>
      </div>
    </div>
    <h4>Khóa học gợi ý</h4>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent>
        <ValidationProvider name="Khóa học gợi ý" rules="required" v-slot="{ errors,classes }"
                            vid="time_end">
          <el-input v-model="courseSuggest" placeholder="Nhập khóa học gợi ý cho học viên"/>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
          </div>
          <div class="d-flex flex-row-reverse mt-4">
            <button type="submit" class="btn btn-primary" @click="handleSubmit(saveCourseSuggest)">Xác nhận</button>
          </div>
        </ValidationProvider>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {GET_RESULT_EXAM, UPDATE_COURSE_SUGGEST} from "@/core/services/store/test/testInput.module";
import router from "@/router";
import {ResultTest} from "@/view/pages/test-input/models/result_test";
import {TYPE_TEST_BY_KEY} from "@/core/option/testInputOption";

export default {
  name: "SuggestedCourse",
  data() {
    return {
      courseSuggest: null,
      testIELTS: false,
      resultTests: []
    }
  },
  props: {
    idSchedule: {
      type: Number,
      default() {
        return null
      }
    }
  },
  mounted() {
    this.getResultTests()
  },
  methods: {
    saveCourseSuggest() {
      let payload = {
        id: this.idSchedule,
        data: {
          course_suggest: this.courseSuggest
        }
      }
      this.$store.dispatch(UPDATE_COURSE_SUGGEST, payload).then((data) => {
        this.$message.success("Đã chấm điểm thành công");
        router.push({name: "task-details", params: {id: test_input_schedule_id}})
      })
    },
    async getResultTests() {
      let test_input_schedule_id = this.$route.params.id
      let data = await this.$store.dispatch(GET_RESULT_EXAM, test_input_schedule_id ?? 0);
      if (!data.error) {
        let keys = ["speaking", "writing", "listening", "vocabulary", "reading"];
        if (data.data?.vocabulary?.right_answers > 1) {
          keys = ["speaking", "writing", "listening", "reading"];
          this.testIELTS = true
        } else {
          keys = ["speaking", "vocabulary"];
        }
        let count = 0
        let res = keys.map((obj) => {
          if (!data?.data?.hasOwnProperty(obj) || data.data[obj] == null) {
            return null;
          }
          count++
          return new ResultTest(TYPE_TEST_BY_KEY.get(obj), data.data[obj].right_answers ?? null, data.data[obj].total_correct ?? null, data.data[obj].avg_point ?? null, data.data[obj]);
        }).filter(d => d != null);
        this.resultTests = res;
        console.log(this.resultTests)

      }
    }
  },
  computed: {
    testScores() {
      return this.testIELTS ? '9.0' : '10'
    }
  }
}
</script>

<style scoped>

</style>