import ApiService from "@/core/services/api.service";
import {CREATE_CATEGORY, GET_CATEGORY, UPDATE_CATEGORY} from "./category.module";

const _ = require('lodash');

export const GET_NOTIFICATION = "support/notification/list";
export const CREATE_NOTIFICATION = "support/notification/store";
export const SHOW_NOTIFICATION = "support/notification/show";
export const UPDATE_NOTIFICATION = "support/notification/update";
export const DELETE_NOTIFICATION = "support/notification/delete";

const state = {};
const getters = {};
const actions = {
  // Get category
  [GET_NOTIFICATION](context, payload) {
    return new Promise(resolve => {
      ApiService.query(`${GET_NOTIFICATION}`, payload).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response)
      });
    })
  },
  [SHOW_NOTIFICATION](context, id) {
    return new Promise(resolve => {
      ApiService.get(`${SHOW_NOTIFICATION}`, id).then(({data}) => {
        resolve(data);
      }).catch((response) => {
        console.log(response)
      });
    })
  },
  [CREATE_NOTIFICATION](context, payload) {
    return new Promise(resolve => {
      ApiService.post(`${CREATE_NOTIFICATION}`, payload).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        reject(response)
      });
    })
  },
  [UPDATE_NOTIFICATION](context, payload) {
    return new Promise(resolve => {
      ApiService.post(`${UPDATE_NOTIFICATION}`, payload).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        reject(response)
      });
    })
  },
  [DELETE_NOTIFICATION](context, id) {
    return new Promise(resolve => {
      ApiService.delete(`${DELETE_NOTIFICATION}`, id).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        reject(response)
      });
    })
  },

  // [GET_COURSE_BY_ID](context, id) {
  //     return new Promise(resolve => {
  //         ApiService.get('courses', id).then(({data}) => {
  //             resolve(data);
  //         }).catch((response) => {
  //             console.log(response)
  //         });
  //     })
  // },
  // [CREATE_COURSE](context, payload) {
  //     return new Promise((resolve, reject) => {
  //         ApiService.post('courses', payload).then(({data}) => {
  //             resolve(data);
  //         }).catch(({response}) => {
  //             reject(response)
  //         });
  //     })
  // },
  // [UPDATE_COURSE](context, payload) {
  //     return new Promise((resolve, reject) => {
  //         ApiService.update('courses', payload.id, payload).then(({data}) => {
  //             resolve(data);
  //         }).catch(({response}) => {
  //             reject(response)
  //         });
  //     })
  // },
  // [DELETE_COURSE](context, id) {
  //     return new Promise(resolve => {
  //         ApiService.delete('courses', id).then(({data}) => {
  //             resolve(data);
  //         }).catch((response) => {
  //             console.log(response)
  //         });
  //     })
  // },
};
const mutations = {};
export default {
  state,
  actions,
  mutations,
  getters
};