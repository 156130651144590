<template>
  <div>
    <button v-if="isEdit" type="button" class="btn   mr-2 btn-outline-primary" style="font-size: 10px"
            v-b-modal="`Modal_Receive_Request`"><i class="flaticon2-pen" style="font-size: 10px"></i> Chỉnh sửa
    </button>
    <button v-else type="button" class="btn" style="background-color: #00B432;color: white"
            v-b-modal="`Modal_Receive_Request`">Tiếp nhận yêu cầu
    </button>
    <b-modal id="Modal_Receive_Request" :title="isEdit?'Cập nhật thông tin tiếp nhận xử lý yêu cầu':'Tiếp nhận xử lý yêu cầu'" centered hide-footer ref="Modal_Receive_Request"
             size="lg" @show="getDetailTicket()" @hidden="resetData()">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" @submit.prevent autocomplete="off">
          <div>
            <p>Phương án xử lý <span class="text-danger">*</span></p>
            <ValidationProvider name="Phương án xử lý" :rules="{required :true,max: 300}" v-slot="{ errors,classes }"
                                vid="title">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 5, maxRows: 100}"
                  placeholder="Nhập phương án xử lý"
                  v-model="problemSolution"
                  maxlength = "300"
              >
              </el-input>
              <div v-if="problemSolution">
                <p class="text-right mb-0" >{{problemSolution.length}}/300</p>
              </div>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div>
            <p>Thời gian dự kiến hoàn thành <span class="text-danger">*</span></p>
            <ValidationProvider name="Thời gian dự kiến" rules="required" v-slot="{ errors,classes }"
                                vid="time">
              <date-picker
                  type="date"
                  placeholder="Thời gian"
                  format="DD-MM-YYYY"
                  v-model="day"
                  :disabled-date="disabledBeforeStartDate"
              >
              </date-picker>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="d-flex d-flex flex-row-reverse mt-4">
            <button :disabled="loading" type="button" class="btn btn-success ml-4 position-relative" @click="handleSubmit(confirmRequest)">
             {{isEdit?'Cập nhật':'Xác nhận'}}
              <i v-if="loading" style="position:absolute; left: 50%; translate: -50%" class="el-icon-loading"></i>
            </button>
            <button type="button" class="btn btn-secondary" @click="cancel()">Hủy</button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {RECEIVE_PROCESS} from "@/core/services/store/csvc/csvc.module";
import moment from "moment-timezone";

export default {
  name: "ReceiveRequestModal",
  components: {DatePicker},
  data() {
    return {
      day: null,
      problemSolution: null,
      loading: false,
    }
  },
  props: {
    isEdit: {
      type: Boolean,
      default() {
        return false
      }
    },
    detailTicket: {
      type: Object,
      default() {
        return null
      }
    }
  },
  methods: {
    confirmRequest() {
      this.loading = true
      let payload = {
        id: this.$route.params.id,
        body: {
          solution: this.problemSolution,
          date_time: moment(this.day).format("YYYY-MM-DD HH:mm:ss")
        }
      }
      this.$store.dispatch(RECEIVE_PROCESS, payload).then((data) => {
        this.$refs['Modal_Receive_Request'].hide()
        this.loading = false
        this.$notify({
          title: 'Thành công',
          message: 'Tiếp nhận xử lý thành công',
          type: 'success'
        });
        this.$emit('Done_Receive_Request')
      }).catch(() => {

      })
    },
    cancel() {
      this.$refs['Modal_Receive_Request'].hide()
    },
    getDetailTicket() {
      let parts = this.detailTicket?.date_time.split('-');
      let mydate = new Date(parts[2], parts[1] - 1, parts[0]);
      this.problemSolution = this.detailTicket?.solution;
      this.day = new Date(mydate);
    },
    disabledBeforeStartDate(date) {
      const today = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      return moment(date) < today;
    },
    resetData() {
      this.problemSolution = null
      this.day = null

    }
  }
}
</script>

<style scoped>

</style>