<template>
    <!--begin::Card-->
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" @submit.prevent="handleSubmit(onSubmit)" id="dataForm" autocomplete="off">
            <div class="card card-custom">
                <!--begin::Header-->
                <div class="card-header">
                    <div class="card-title">
                        <h3 class="card-label">
                            Tạo Học viên
                        </h3>
                    </div>
                    <div class="card-toolbar">
                        <router-link
                                class="font-weight-bold font-size-3  btn btn-secondary"
                                :to="{ name: 'user-list' }"
                        ><i class="fas fa-angle-double-left"></i> Quay lại
                        </router-link>
                    </div>
                </div>
                <!--end::Header-->
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Họ tên
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Họ tên" rules="required|min:6" v-slot="{ errors,classes }">
                                    <input type="text" ref="name" name="name" class="form-control" :class="classes"
                                           placeholder="Họ tên"
                                           v-model=user.name>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Email
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Email" vid="email" rules="required|email" v-slot="{ errors,classes }">
                                    <input type="email" name="email" class="form-control" placeholder="Email"
                                           :class="classes"
                                           v-model=user.email>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Số điện thoại
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Số điện thoại" vid="phone" :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }" v-slot="{ errors,classes }">
                                    <input type="number" name="phone" class="form-control" placeholder="Số điện thoại"
                                           :class="classes"
                                           v-model=user.phone>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Chọn giới tính
                                    <span class="text-danger"></span></label>
                                <multiselect v-model="user.profile.gender" :options="gender"
                                             :searchable="true"
                                             :close-on-select="true"
                                             :show-labels="true" placeholder="Pick a value"
                                ></multiselect>
                            </div>
                            <!--end::Form-->
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Ngày tháng năm sinh
                                    <span class="text-danger"></span></label>
                                <date-picker v-model="user.profile.birthday" placeholder="Chọn ngày tháng"  format="DD-MM-YYYY"  valueType="YYYY-MM-DD"></date-picker>
                            </div>
                            <!--end::Form-->
                        </div>
                        <div class="col-md-4 ">
                            <div class="form-group mb-1">
                                <label>Địa chỉ
                                    <span class="text-danger"></span></label>
                                <textarea class="form-control" rows="3" v-model="user.profile.address"></textarea>
                            </div>
                            <!--end::Form-->
                        </div>
                    </div>
                    <!--Thông tin cấp trên-->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Mật khẩu
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Mật khẩu" rules="required|min:6" v-slot="{ errors,classes }">
                                    <input name="password" type="password" class="form-control" placeholder="Mật khẩu"
                                           v-model="user.password" :class="classes" autocomplete="new-password">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>
                                    <span class="text-danger"></span></label>
                                <div class="col-9 col-form-label">
                                    <div class="checkbox-inline">
                                        <label class="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5" v-model="user.status">
                                            <span></span>Kích hoạt</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <multiselect v-model="cacheAccountType" :options="accountType"
                                     placeholder="Loại tài khoản"
                                     label="name"
                                     track-by="name"
                                     hidden
                        ></multiselect>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Nhập lại mật khẩu
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Xác nhận mật khẩu" rules="required|min:6|confirmed:Mật khẩu"
                                                    v-slot="{ errors,classes }">
                                    <input name="password_confirmation" type="password" class="form-control"
                                           placeholder="Xác thực mật khẩu" v-model="user.confirm_password"
                                           :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Thông tin tài khoản ngân hàng-->
                <div class="separator separator-dashed my-5"></div>
                <div class="card-header">
                    <div class="card-title align-items-start flex-column">
                        <button type="submit" class="btn btn-success mr-2">
                            Tạo mới
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
    <!--end::Card-->
</template>
<script>
    import {CREATE_CUSTOMER} from "../../../../../src/core/services/store/user/users.module";
    import Multiselect from 'vue-multiselect'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";
    import {mapGetters} from "vuex";
    import {GET_CUSTOMER_ACCOUNT_TYPE} from "../../../../core/services/store/user/account-type.module";

    export default {
        name: "Create-User",
        components: {
            Multiselect,
            DatePicker,
        },
        watch: {
            user(bf, at) {
                console.log(bf, at);
            }
        },
        data() {
            return {
                user: {
                    uuid: '',
                    name: '',
                    phone: "",
                    email: "",
                    email_verified_at: "",
                    status: true,
                    parent_id: "",
                    remember_token: "",
                    password: "",
                    confirm_password: "",
                    positions: [],
                    profile: {
                        uuid: "",
                        ma_gioi_thieu: "",
                        gioithieu_id: "",
                        gender: 'Nam',
                        address: "",
                        birthday: "",
                        start_to_work: "",
                        loai_tai_khoan: "",
                        chi_nhanh_id: "",
                        trung_tam_id: "",
                        xac_thuc_sdt: "",
                        thoi_gian_nghi_viec: "",
                        account_type_id: '',
                        khuvuc_id: ""
                    },
                },

                /*Data default*/
                isLoadingBoss: false,
                gender: [
                    'Nam', 'Nữ', 'Khác'
                ],
                branchs: {},
                accountType: [],
                cacheAccountType: '',
                /*Cache */
            };
        },
        computed: {
            ...mapGetters(['getAccountType',])
        },
        beforeMount() {
            this.$store.dispatch(GET_CUSTOMER_ACCOUNT_TYPE).then((res) => {
                this.cacheAccountType = res.data;
            });
        },
      mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý nhân sự"},
                {title: "Tạo học viên"}
            ]);
        },

        methods: {
            onSubmit() {
                this.user.profile.account_type_id = this.cacheAccountType.id;
                this.$store.dispatch(CREATE_CUSTOMER, this.user)
                    .then((res) => {
                        if (!res.error) {
                            this.$router.push({name: "user-list"}).then(() => {
                                this.$bvToast.toast(res.message, {
                                    title: 'Tạo khách hàng',
                                    variant: 'success',
                                    solid: true
                                });
                            })
                        } else {
                            this.$bvToast.toast(res.message, {
                                title: 'Tạo khách hàng',
                                variant: 'danger',
                                solid: true
                            });
                        }
                    }).catch((e) => {
                      if (e.data.data.message_validate_form){
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                      }
                });
            },
        },
    };
</script>
<style>
    .mx-datepicker {
        width: 100% !important;
    }
    .mx-input {
      height: 39px !important;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

