import ApiService from "@/core/services/api.service";
import {commonExport} from "@/core/common/commonHelper";
import {GET_CUSTOMERS_BY_USER_ID} from "@/core/services/store/customer/customer.module";

const _ = require('lodash');
//action types
export const GET_CONTRACT_BY_ID = "get_contract_by_id";
export const GET_CONTRACT_BY_CUSTOMER_ID = "get_contract_by_customer_id";
export const GET_CONTRACTS = "list-contract";
export const CREATE_CONTRACT = "create_contract";
export const UPDATE_CONTRACT = "update_contract";
export const UPDATE_CONTRACT_BEFORE_DEPOSIT = "update_contract_before_deposit";
export const DELETE_CONTRACT = "delete_contract";
export const DEPOSIT_MONEY = "deposit_money_for_contract";
export const WITHDRAW_MONEY = "withdraw_money";
export const EXPORT_EXCEL_CONTRACTS = "export_excel_contracts";
export const EXPORT_EXCEL_CONTRACTS_V2 = "export_excel_contracts_v2";
export const EXPORT_EXCEL_197_CONTRACTS = "export_excel_197_contracts";
export const EXPORT_CONTRACT_GOOGLE_SHEET = "export_contract_google_sheet";
export const GET_TOTAL_DEBT = "get-total-debt";
export const LIST_COURSE_REFUND = "contract/list-course-refund";
export const TIEU_SO_DU = "tieu_so_du";
export const SEND_OTP = "contract/send-otp";
export const CREATE_NEW_USER_WHEN_CREATE_CONTRACT = "contract/create-user";
export const GET_BRANCH_CONTRACT = "contract/list-branch-can-update";
export const GET_CONTRACT_BY_USER_ID = "contracts/get-by-user";
export const UPDATE_CONTRACT_BY_USER_ID = "contracts/updated-contracts-by-id";
export const GET_HISTORY_UPDATE_CONTRACT = "contracts/history-update-by-contract-id";
export const GET_LIST_BRANCH = "contract/list-branch";
//mutations types
export const SET_CONTRACTS = "setContracts";
export const ADD_CONTRACTS = "addContracts";
export const SET_CONTRACT_BY_ID = "getContractById";
export const SET_CONTRACT_PAGINATE = "set_contract_paginate_value";
export const UPDATE_LIST_CONTRACT_WHEN_DELETE = "set_contracts_when_delete_contract";
export const UPDATE_LIST_CONTRACT_WHEN_DEPOSIT = "set_contracts_when_deposit_money";
export const GET_POLICT_CONTRACT = "contract/get_policy_contract";
export const GET_REASON_WITHDRAWAL = "contract/get-all-reason-withdrawal";

export const NOT_DONE = 'Chưa đạt';
export const DONE = 'Đạt';
export const UNDUPLICATED = 'Không trùng';

const state = {
    errors: null,
    contracts: [],
    contract: '',
    contractPaginatedData: [],
};

const getters = {
    getAllContracts(state) {
        return state.contracts;
    },
    getContractById(state) {
        return state.contract;
    },
    getContractPaginate(state) {
        return state.contractPaginatedData;
    },
};

const actions = {
    [GET_BRANCH_CONTRACT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_BRANCH_CONTRACT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_LIST_BRANCH](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LIST_BRANCH}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [CREATE_CONTRACT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('contracts', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [UPDATE_CONTRACT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('contracts/' + payload.id + '?_method=PUT', payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [UPDATE_CONTRACT_BEFORE_DEPOSIT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('update-contracts-before-deposit/' + payload.id, payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [UPDATE_CONTRACT_BY_USER_ID](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(UPDATE_CONTRACT_BY_USER_ID , payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [DEPOSIT_MONEY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('deposit-money/' + payload.id, payload.params).then(({data}) => {
                let contract = data.data;
                context.commit(UPDATE_LIST_CONTRACT_WHEN_DEPOSIT, contract);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [WITHDRAW_MONEY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('subcontracts', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [GET_CONTRACTS](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("contracts", payload)
                .then(({data}) => {
                    context.commit(SET_CONTRACTS, data.data.data);
                    const contractPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when contracts clicks on some page number).
                        total_pages: data.data.total_pages // total pages in record
                    }
                    context.commit(SET_CONTRACT_PAGINATE, contractPagination)
                    resolve(data);
                })
                .catch((errors) => {
                });
        });
    },

    [GET_TOTAL_DEBT](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("contracts/sum-totalDebt", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((errors) => {
                    console.log(errors)
                });
        });
    },

    [GET_CONTRACT_BY_ID](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get('contracts', id).then(({data}) => {
                context.commit(SET_CONTRACT_BY_ID, data.data);
                resolve(data);
            }).catch(({response}) => {
                console.log(response);
                reject(response);
            });
        })
    },

    [GET_HISTORY_UPDATE_CONTRACT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_HISTORY_UPDATE_CONTRACT, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_CONTRACT_BY_CUSTOMER_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('contracts', id).then(({data}) => {
                context.commit(SET_CONTRACT_BY_ID, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_CONTRACT_BY_USER_ID](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_CONTRACT_BY_USER_ID, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [DELETE_CONTRACT](context, id) {
        return new Promise(resolve => {
            ApiService.delete('contracts', id).then(({data}) => {
                context.commit(UPDATE_LIST_CONTRACT_WHEN_DELETE, id);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [EXPORT_EXCEL_CONTRACTS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('contracts/export', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_EXCEL_CONTRACTS_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('contracts/export-v2', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_EXCEL_197_CONTRACTS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('contracts/export-197-data', payload).then(({data}) => {
                let filename = "danh_sach_hop_dong_197.xlsx";
                commonExport(filename, data);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [EXPORT_CONTRACT_GOOGLE_SHEET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('contracts/export-google-sheet', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    /**
     * api danh sách khóa học đã rút
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [LIST_COURSE_REFUND](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${LIST_COURSE_REFUND}`, payload.id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [TIEU_SO_DU](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('contracts/tieu-so-du', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    /**
     * api gửi otp xác thực sđt
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [SEND_OTP](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${SEND_OTP}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    /**
     * api gửi otp xác thực otp
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [CREATE_NEW_USER_WHEN_CREATE_CONTRACT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${CREATE_NEW_USER_WHEN_CREATE_CONTRACT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [GET_POLICT_CONTRACT](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(GET_POLICT_CONTRACT, id).then(({data}) => {
                context.commit(SET_CONTRACT_BY_ID, data.data);
                resolve(data);
            }).catch(({response}) => {
                console.log(response);
                reject(response);
            });
        })
    },
    [GET_REASON_WITHDRAWAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_REASON_WITHDRAWAL, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                console.log(response);
                reject(response);
            });
        })
    },
};


const mutations = {
    [SET_CONTRACTS](state, data) {
        state.contracts = data;
        state.errors = {};
    },
    [ADD_CONTRACTS](state, data) {
        state.contracts.push(data);
        state.errors = {};
    },
    [SET_CONTRACT_BY_ID](state, data) {
        state.contract = data;
    },
    [SET_CONTRACT_PAGINATE](state, data) {
        state.contractPaginatedData = data;
    },

    [UPDATE_LIST_CONTRACT_WHEN_DELETE](state, id) {
        let contracts = state.contracts;
        let indexContract = _.findIndex(contracts, function (object) {
            return object.id === id;
        });
        contracts.splice(indexContract, 1);
    },
    [UPDATE_LIST_CONTRACT_WHEN_DEPOSIT](state, contract) {
        let contracts = state.contracts;
        if (contract !== '') {
            let indexContract = _.findIndex(contracts, function (object) {
                return object.id === contract.id;
            });
            state.contracts[indexContract] = contract;
        }
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
