<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Thu nhập giảng viên</h3>
        </div>
        <div class="card-title">
          <div class="">
            <div class="mt-1 mx-auto">
              <div class="dropdown dropdown-inline">
                <button type="button" @click="exportDemoSalary" class="btn btn-light-primary font-weight-bolder" :disabled="loading">
												<span class="svg-icon svg-icon-md">
													<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
														<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
															<rect x="0" y="0" width="24" height="24"></rect>
															<path d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z" fill="#000000" opacity="0.3"></path>
															<path d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z" fill="#000000"></path>
														</g>
													</svg>
                          <!--end::Svg Icon-->
												</span>Download file mẫu</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="example mb-10">
          <div class="input-group row mb-5">
            <div class="col-md-12">
              <ValidationObserver v-slot="{ handleSubmit }" >
                <form class="form-inline" @submit.prevent="handleSubmit(onSubmit)">
                  <ValidationProvider ref="validate_file" rules="required" name="File" v-slot="{ validate, errors }" class="mr-2 mt-1">
                    <div class="upload">
                      <div v-for="file in files" :key="file.id">
                        <i class="fas fa-minus-circle mr-2" style="cursor: pointer;" @click="removeFile"></i>
                        <span>{{file.name}}</span> -
                        <span>{{file.size}}</span> -
                        <span v-if="file.error">{{file.error}}</span>
                        <span v-else-if="file.success">success</span>
                        <span v-else-if="file.active">active</span>
                        <span v-else-if="!!file.error">{{file.error}}</span>
                        <span v-else></span>
                      </div>
                      <div class="example-btn d-flex justify-content">
                        <file-upload
                            class="btn btn-text-dark-50 btn-icon-primary font-weight-bold btn-hover-bg-light mr-3"
                            post-action=""
                            extensions="xlsx,xls"
                            accept=".xlsx,.xls"
                            :size="1024 * 1024 * 10"
                            v-model="files"
                            @input-filter="inputFilter"
                            @input-file="inputFile"
                            ref="upload">
                          <i class="flaticon-upload"></i>
                          Chọn file
                        </file-upload>
                        <button type="button" @click="onSubmit" class="btn btn-success ml-2" :disabled="isAble" :class="loadingClass">
                          <i class="fa fa-arrow-up" aria-hidden="true"></i>
                          Upload thu nhập
                        </button>
                      </div>
                    </div>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </form>
              </ValidationObserver>
            </div>
            <div class="col-md-6" v-if="show_process_loading">
              <div class="progress">
                <div class="progress-bar" role="progressbar" :style="{width : percentLoadingImportSalaryTeacher + '%'}"
                     :aria-valuenow="`${percentLoadingImportSalaryTeacher}`" aria-valuemin="0"
                     aria-valuemax="100">{{ percentLoadingImportSalaryTeacher }}%
                </div>
              </div>
            </div>
          </div>
          <div class="row ml-5" v-if="show_process_loading">
            <div class="col-md-6 mt-2">
              <label class="mr-2">Import phiếu lương</label>
              <span v-if="percentLoadingImportSalaryTeacher === 0" class="badge badge-warning">Chờ xử lý</span>
              <span v-if="percentLoadingImportSalaryTeacher < 99 && percentLoadingImportSalaryTeacher > 0"
                    class="badge badge-primary">Đang xử lý </span>
              <span v-if="percentLoadingImportSalaryTeacher === 100" class="badge badge-success">Hoàn tất</span>

            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {EXPORT_DEMO_TEACHER_SALARY, PUSH_PERCENT_LOADING_IMPORT_SALARY_TEACHER} from "@/core/services/store/user/log_human_resource.module";
import Multiselect from 'vue-multiselect';
import {
  GET_USERS,
  IMPORT_TEACHER_SALARY
} from "@/core/services/store/user/users.module";
import FileUpload from 'vue-upload-component';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Swal from "sweetalert2";
const _ = require('lodash');

export default {
  name: "PaySlipTeacherIndex",
  data() {
    return {
      upload: null,
      files: [],
      isAble : true,
      loading : false,
      show_process_loading: false,
      loadingClass: ''
    }
  },
  components : {
    Multiselect,FileUpload,DatePicker
  },
  computed : {
    ...mapGetters(['percentLoadingImportSalaryTeacher']),

  },
  mounted() {
     this.setBreadcrumb();
     this.excitedBroadcasting();
  },
  watch: {
    percentLoadingImportSalaryTeacher: function (value) {
      if (value === 100) {
        Swal.fire(
            'Đã xong!',
            '',
            'success'
        )
      }
    },
  },
  methods : {
    inputFilter: function(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile: function(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.dataImport = newFile.file;
        this.isAble = false;
      }
      if (newFile && oldFile) {
        // update
      }
      if (!newFile && oldFile) {
        // remove
      }
    },
    removeFile : function (){
      this.files.pop();
      this.isAble = true;
    },
    onFileChange (event) {
      const { valid } = this.$refs.validate_file.validate(event);
      this.dataImport = event.target.files[0];
    },
    onSubmit: function () {
      let formData = new FormData();
      this.isAble = true;
      this.loadingClass = 'spinner spinner-darker-success spinner-left mr-3';
      this.show_process_loading = true;
      formData.append('dataImport', this.dataImport);
      this.$store.dispatch(IMPORT_TEACHER_SALARY,formData).then( (res) => {
        this.removeFile();
        this.$store.dispatch(GET_USERS).then(() => {
          this.$bvToast.toast(res.message, {
            title: 'Import',
            variant: 'success',
            solid: true
          });
        })
        this.loadingClass = '';
      }).catch(() => {
        this.$bvToast.toast('Có lỗi xảy ra', {
          title: 'Import',
          variant: 'danger',
          solid: true
        });
        this.loadingClass = '';
        this.show_process_loading = false;
        setPercent(0);
      });
    },
    setBreadcrumb: function (){
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Quản lý nhân sự"},
        { title: "Thu nhập nhân sự" }
      ]);
    },
    exportDemoSalary: function (){
      this.loading = true;
      this.$store.dispatch(EXPORT_DEMO_TEACHER_SALARY).then((res) => {
        if(!res.error){
          this.loading = false;
          this.$bvToast.toast(res.message, {
            title : 'Xuất file',
            variant : 'success',
            solid : true
          });
        }else {
          this.loading = false;
          this.$bvToast.toast(res.message, {
            title : 'Xuất file',
            variant : 'danger',
            solid : true
          });
        }
      }).catch((e) => {
        this.loading = false;
        this.$bvToast.toast(e.data.message, {
          title : 'Xuất file',
          variant : 'danger',
          solid : true
        });
      });
    },
    excitedBroadcasting  () {
      this.$echo.channel('loading_import_salary_teacher').listen('LoadingImportSalaryTeacherEvent', (payload) => {
        this.$store.dispatch(PUSH_PERCENT_LOADING_IMPORT_SALARY_TEACHER, payload.data.percent).then(() => {
        });
      });
    },
    setPercent(percent) {
      let _this = this;
      setTimeout(function (){
        _this.$store.dispatch(PUSH_PERCENT_LOADING_IMPORT_SALARY_TEACHER, percent);
      }, 1000)
    }
  }
}
</script>

<style>
.fa-minus-circle:hover {
  color: red !important;

}
.input-group i {
  font-size : 0.9rem;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
