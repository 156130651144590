<template>
  <b-modal
      id="modal-detail-team"
      ref="modal-detail-team"
      :title="`Xem chi tiết team ${teamName}`"
      centered
      hide-footer
  >
    <table class="table table-bordered">
      <thead>
      <tr align-items class="table-active">
        <th scope="col">STT</th>
        <th scope="col">Danh sách thành viên</th>
        <th scope="col">Tên nhân vật</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,index) in members">
        <td>{{ index + 1 }}</td>
        <td>{{ item.name }} - {{ item.phone }} <span v-if="item.is_captain" style="color: red; font-weight: bold">(C)</span></td>
        <td>{{ item.name_in_game }}</td>
      </tr>
      </tbody>
    </table>
  </b-modal>
</template>

<script>
export default {
  name: "ModalDetailTeam",
  props: {
    members: {
      type: Array,
      default() {
        return []
      }
    },
    teamName: {
      type: String,
      default(){
        return ''
      }
    }
  }
}
</script>

<style scoped>

</style>