<template>
  <div>
    <div class="mb-4">
      <label for="">Bước 1: Tải file mẫu</label>
      <div>
        <button class="btn btn-primary" @click="exportFileMau">
          <i class="el-icon-download"></i>
          Tải file
        </button>
      </div>
    </div>
    <div>
      <label for="">Bước 2: Upload file excel lên hệ thống</label>
      <el-upload
          ref="upload"
          accept=".xlsx"
          :limit="1"
          list-type="text"
          action=""
          :file-list="fileList"
          :on-change="handleChange"
          :on-remove="handleRemove"
          :auto-upload="false"
          :multiple="false"
      >
        <el-button size="small" type="warning">
          <i class="el-icon-upload2"></i>
          Tải lên
        </el-button>
      </el-upload>
    </div>
    <div class="mt-3 d-flex justify-content-end">
      <el-button class="btn">Hủy</el-button>
      <el-button :disabled="disable" :loading="loading" size="small" type="success" class="btn btn-success" @click="submitUpload">Xác nhận</el-button>
    </div>
  </div>
</template>

<script>
import {
  EXPORT_FILE_DEFAULT_HOA_DON,
  UPLOAD_DANH_SACH_DONG_TIEN,
} from "@/core/services/store/contract/studentAmount.module";
import { read, utils } from "xlsx";

export default {
  name: "ModalImport",
  data() {
    return {
      fileList: [],
      disable: false,
      loading: false
    }
  },
  methods: {
    submitUpload() {
      if (this.fileList.length <= 0){
        this.$message.warning('Vui lòng chọn file');
        return;
      }
      this.fileToJson(this.fileList).then(dataToImport => {
        this.loading = true;
        this.$store.dispatch(UPLOAD_DANH_SACH_DONG_TIEN, dataToImport).then((res) => {
          this.dialogUpload = false;
          this.$emit('importSuccess')
          this.$notify({
            title: 'Thành công',
            message: res.message,
            type: 'success'
          })
        }).catch((error) => {
          this.$notify({
            title: 'Thất bại',
            message: error.data.message,
            type: 'error'
          })
        }).finally(() => {
          this.loading = false
        })
      })
    },
    handleRemove() {
      this.fileList = []
    },
    handleChange(file, fileList){
      this.fileList = fileList
    },
    exportFileMau() {
      this.$store.dispatch(EXPORT_FILE_DEFAULT_HOA_DON).then((res) => {
        this.$notify({
          title: 'Thành công',
          message: res.message,
          type: 'success'
        })
      }).catch((error) => {
        this.$notify({
          title: 'Thất bại',
          message: error.data.message,
          type: 'error'
        })
      })
    },
    fileToJson(file) {
      return new Promise((res, rej) => {
          /* Boilerplate to set up FileReader */
          const reader = new FileReader()
          reader.onload = (e) => {
              /* Parse data */
              const bstr = e.target.result
              const wb = read(bstr, {type: 'binary'})
              /* Get first worksheet */
              const wsname = wb.SheetNames[0]
              const ws = wb.Sheets[wsname]
              /* Convert array of arrays */
              const data = utils.sheet_to_json(ws)

              res(data);
          }
          reader.onerror = (e) => {
              rej(e);
          };
          reader.readAsBinaryString(file[0].raw)
      });
    },
  }
}
</script>

<style scoped>

</style>