<template>
  <div>
    <div v-if="!confirm" class="row">
      <div class="col-md-3">
        <label>Tên lớp học</label>
        <div class="form-group">
          <el-input @input="getListClassSchedule" v-model="query.class_name" placeholder="Nhập tên lớp học" clearable></el-input>
        </div>
      </div>
      <div class="col-md-3">
        <label>Trung tâm</label>
        <div class="form-group">
          <el-select @change="getListBranch" v-model="query.center_id" placeholder="Chọn trung tâm" clearable>
            <el-option v-for="center in centers"
                       :key="center.id"
                       :label="center.name"
                       :value="center.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-md-3">
        <label>Chi nhánh</label>
        <div class="form-group">
          <el-select @change="getListClassSchedule" v-model="query.branch_id" placeholder="Chọn chi nhánh" clearable>
            <el-option v-for="branch in branches"
                       :key="branch.id"
                       :label="branch.name"
                       :value="branch.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="col-md-3">
        <label>Trạng thái</label>
        <div class="form-group">
          <el-select @change="getListClassSchedule" v-model="query.status" placeholder="Chọn trạng thái" clearable>
            <el-option label="Sắp khai giảng" :value="1"></el-option>
            <el-option label="Đang học" :value="2"></el-option>
            <el-option label="Kêt thúc" :value="3"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12 pb-2">
        <span class="btn btn-warning btn-sm mr-3 cursor-text">Đã chọn <span>{{ count_class }}</span></span>
        <button class="btn btn-secondary btn-sm" @click="toggleSelection"><i class="el-icon-circle-close"></i>Hủy chọn</button>
      </div>
    </div>
    <el-row :loading="loading_table">
      <div class="col-md-12 pb-2">
        Chọn các lớp cần nghỉ:
      </div>
      <div class="col-md-12" style="max-height: 600px;overflow-y: scroll">
        <classroom-table :loading="loading_table" @changeListClass="changeListClass" :toggle="toggle" :tableData="tableData"></classroom-table>
      </div>
    </el-row>
  </div>
</template>

<script>
import { GET_LIST_CENTERS } from '../../../core/services/store/center/centers.module';
import { GET_LIST_BRANCHES } from '../../../core/services/store/center/branch.module';
import classroomTable from './classroomTable';
import {LIST_CLASS_SCHEDULE} from "../../../core/services/store/course/classes.module";
export default {
  name: "dialog_off_lesson",
  props: {
    confirm: false,
    listDates: [],
    someProp: 0
  },
  data() {
    return {
      count_class : 0
      }
    },
  watch: {
    listClass(newValue) {
      if (newValue.length > 0) {
        this.confirm = true
      } else {
        this.confirm = false;
      }
    },
    async someProp(newValue) {
      await this.getListClassSchedule();
    }
  },
  components: {
    classroomTable,
  },
  data() {
    return {
      listClass: [],
      centers: [],
      branches: [],
      toggle: 0,
      query: {
        class_name: '',
        center_id: '',
        branch_id: '',
        status: '',
      },
      tableData: [],
      loading_table: false
    }
  },
  async created() {
    this.$store.dispatch(GET_LIST_CENTERS, {}).then(data => {
      this.centers = data.data;
    })
    this.getListBranch();
    // this.getListClassSchedule();
  },
  methods: {
    async getListClassSchedule() {
      this.loading_table = true;
      this.tableData = [];
      this.$store.dispatch(LIST_CLASS_SCHEDULE, {
        dates: this.listDates,
        class_name: this.query.class_name,
        center_id: this.query.center_id,
        branch_id: this.query.branch_id,
        status: this.query.status,
      }).then(res => {
        this.tableData = res.data;
      }).finally(() => {
        this.loading_table = false;
      })
    },
    toggleSelection() {
      this.toggle = this.toggle + 1;
    },
    changeListClass(data,count_class) {
      let result = [];
      let count = 0;
      for (const val of data) {
        for (const sub_val of val.lesson_off) {
          count++;
          result.push({
            'stt': count,
            'class_id': val.id,
            'lesson': sub_val.lesson,
            'name': val.name,
            'old_date': sub_val.old_date,
            'new_date': sub_val.new_date,
            'start_time': sub_val.start_time,
            'end_time': sub_val.end_time,
            'date_of_week' : sub_val.date_of_week
          })
        }
      }
      this.listClass = result;
      this.count_class = count_class;
      this.$emit('changeListClass', this.listClass,count_class);
    },
    async getListBranch() {
      this.branches = [];
      this.query.branch_id = '';
      this.getListClassSchedule();
      this.$store.dispatch(GET_LIST_BRANCHES, {center_id: this.query.center_id}).then(data => {
        this.branches = data.data;
      })
    },
  }
}
</script>

<style scoped>
  .cursor-text:hover {
    cursor: text;
  }
</style>