<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label"></h3>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">

            <el-date-picker
                v-model="query.date_week"
                clearable
                type="week"
                :format="dateRange"
                placeholder="Chọn ngày">
            </el-date-picker>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label></label>
            <el-select
                v-model="query.tester_id"
                filterable
                clearable
                remote
                reserve-keyword
                placeholder="Nhập số điện thoại,MNV"
                :remote-method="remoteMethod"
                :loading="search_loading">
              <el-option
                  v-for="item in testers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-content-between">
            <button type="button"
                    class="btn btn-primary font-weight-bold mr-2"
                    :disabled="is_disable_search"
                    :class="{'spinner spinner-white spinner-left' : is_disable_search}"
                    @click="search"
            >
              Tìm kiếm
            </button>
          </div>
        </div>
      </div>
      <div style="background: #F4E8FF" class="p-3 mb-2  d-flex justify-content-between">
        <div class="mt-5 ml-4">
          <button type="button" class="btn btn-primary btn-sm" @click="backWeek"
          >
            <i class="el-icon-arrow-left"></i>
            Tuần trước
          </button>
        </div>
        <div>
          <p class="text-center">Lịch làm việc </p>
          <p v-if="query.date_week.length && query.date_week[0]">(từ ngày {{ query.date_week[0] | formatDate }} - đến
            {{ query.date_week[1] | formatDate }})</p>
        </div>
        <div class="mt-5 mr-4">
          <button type="button" class="btn btn-primary btn-sm" @click="nextWeek">Tuần sau<i
              class="el-icon-arrow-right"></i></button>
        </div>
      </div>
      <div class="calendar-item mt-10" v-for="(item, index) in data" :key="index">
        <CalendarItem
            :item_prop="item"
            @saveCalendarSuccess="getCalendar"
        ></CalendarItem>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import CalendarItem from "@/view/pages/test-input/components/CalendarItem";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';
import {TEST_INPUT_GET_CALENDAR} from "@/core/services/store/test/testInput.module";
import {GET_TESTERS} from "@/core/services/store/test/test.module";

export default {
  name: "CalendarIndex",
  components: {CalendarItem, DatePicker},
  data() {
    return {
      last_page: 1,
      page: 1,
      is_disable_search: false,
      search_loading: false,
      checked: false,
      query: {
        date_week: moment().startOf('week'),
        center_id: '',
        branch_id: '',
        tester_id: '',
      },
      centers: [],
      calendar: [],
      testers: [],
      data: []
    }
  },
  computed: {
    dateRange() {
      let startWeek = moment(this.query.date_week);
      let endWeek = startWeek.clone().endOf('week');
      return startWeek.format('DD/MM/YYYY') + ' - ' + endWeek.format('DD/MM/YYYY')
    }
  },
  mounted() {
    this.getCalendar();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý test đầu vào", route: 'attendance-index'},
      {title: "Lịch làm việc"}
    ]);
  },
  methods: {
    getCalendar() {
      let startWeek = this.query.date_week.startOf('week').format('YYYY-MM-DD');
      let endWeek = this.query.date_week.endOf('week').format('YYYY-MM-DD');
      this.$store.dispatch(TEST_INPUT_GET_CALENDAR, {
        date: [startWeek, endWeek],
        tester_id: this.query.tester_id
      }).then((data) => {
        this.data = data.data;
      }).catch((e) => {
        this.$message.error(e.data.message);
      }).finally(() => {
        this.is_disable_search = false;
      })
    },
    search() {
      this.is_disable_search = true;
      this.getCalendar();
    },
    remoteMethod(query) {
      this.search_loading = true;
      this.$store.dispatch(GET_TESTERS, {
        keyword: query,
        status : 1
      }).then((data) => {
        this.search_loading = false;
        this.testers = data.data;
      })
      if (query !== '') {
        this.search_loading = true;
        setTimeout(() => {
          this.search_loading = false;
          this.options = this.list.filter(item => {
            return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    clickCallback(obj) {
      this.page = obj;
      this.pushParamsUrl();
    },
    nextWeek() {
    },
    backWeek() {
      let start = this.query.date_week[0];
      if (start) {
        let current = moment(start, "YYYY-MM-DD").tz('Asia/Ho_Chi_Minh');
      } else {
        let current = moment().tz('Asia/Ho_Chi_Minh').subtract(1, 'week');
      }
    },
  }
}
</script>

<style scoped>
.el-select {
  width: 100%;
}

.el-input {
  width: 100% !important;
}
</style>