<template>
    <div>
        <div class="d-flex align-items-center">
            <button v-if="!item.activities_count && currentUser.user.permission.includes(permission.SUA_NGAY)"
             v-b-modal="`modal-edit-created-at-${index}`"
                    class="btn btn-sm btn-outline-primary font-weight-bold mr-2">
                <i class="far fa-clock icon-sm"></i>Sửa ngày
            </button>
<!--            <button v-if="!item.activities_count && currentUser.user.permission.includes(permission.SUA_HE_SO)"-->
<!--             v-b-modal="`modal-edit-coefficient-business-${index}`"-->
<!--                    class="btn btn-sm btn-outline-primary font-weight-bold mr-2">-->
<!--                <i class="fas fa-sort-amount-down"></i>Sửa hệ số-->
<!--            </button>-->
            <button
                    v-if="!item.activities_count && (currentUser.user.permission.includes(permission.KHACH_HANG_DONG_TIEN)
                      ||currentUser.user.permission.includes(permission.DS_KH_DONG_TIEN))"
                    v-b-modal="`modal-show-log-change-dated-paid-${index}`"
                    class="btn btn-sm btn-outline-primary font-weight-bold mr-2">
                <i class="flaticon-layers icon-sm"></i>Lịch sử
            </button>
        </div>
        <div class="btn-group d-flex align-items-center" role="group" aria-label="Basic example">
            <button v-b-modal="`modal-edit-created-at-${index}`"
                    v-if="item.activities_count && currentUser.user.permission.includes(permission.SUA_NGAY)"
                    class="btn btn-sm btn-primary font-weight-bold mr-2">
                <i class="flaticon-time icon-sm"></i>Sửa ngày
            </button>
<!--            <button v-b-modal="`modal-edit-coefficient-business-${index}`"-->
<!--                    v-if="item.activities_count && currentUser.user.permission.includes(permission.SUA_HE_SO)"-->
<!--                    class="btn btn-sm btn-primary font-weight-bold mr-2">-->
<!--                <i class="fas fa-sort-amount-down"></i>Sửa hệ số-->
<!--            </button>-->
            <button
                    v-if="item.activities_count && (currentUser.user.permission.includes(permission.KHACH_HANG_DONG_TIEN)
                      ||currentUser.user.permission.includes(permission.DS_KH_DONG_TIEN))"
                    v-b-modal="`modal-show-log-change-dated-paid-${index}`"
                    class="btn btn-sm btn-primary font-weight-bold mr-2">
                <i class="flaticon-layers icon-sm"></i>Lịch sử
            </button>
        </div>
        <b-modal :centered="true" :scrollable="true" size="md" :ref="'modal-edit-created-at'" hide-footer
                 :id="'modal-edit-created-at-' + index"
                 title="Sửa thời gian nạp">
            <div class="example-preview table-responsive">
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                    <form class="form" @submit.prevent="handleSubmit(onSubmitTime)">
                        <div class="form-group">
                            <date-picker
                                    v-model="date"
                                    type="datetime"
                                    :default-value="new Date().setHours(12, 0, 0, 0)"
                                    value-type="format"
                                    format="DD-MM-YYYY HH:mm:ss"
                                    @input="selectDate($event)"
                            ></date-picker>
                        </div>
                        <div class="float-right">
                            <button type="submit" class="btn btn-primary mr-2">Save</button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </b-modal>
        <b-modal :centered="true" :scrollable="true" size="md" :ref="'modal-edit-coefficient-business'" hide-footer
                 :id="'modal-edit-coefficient-business-' + index"
                 title="Sửa hệ số kinh doanh">
            <div class="example-preview table-responsive">
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                    <form class="form" @submit.prevent="handleSubmit(onSubmitCoefficient)">
                        <div class="form-group">
                            <div>
                                <ValidationProvider vid="business_coefficient" name="Hệ số kinh doanh"
                                                    rules="required|excluded:0|min_value:0.1|max_value:5"
                                                    v-slot="{ errors,classes }">
                                    <input id="business_coefficient" class="form-control" :class="classes" type="number"
                                           step=".01" v-model="item.business_coefficient"
                                           placeholder="Hệ số kinh doanh">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="business_coefficient" data-validator="notEmpty"
                                             class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="float-right">
                            <button type="submit" class="btn btn-primary mr-2">Save</button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </b-modal>
        <b-modal :centered="true" :scrollable="true" size="xl" :ref="'modal-show-log-change-dated-paid-' + index"
                 hide-footer
                 :id="'modal-show-log-change-dated-paid-' + index"
                 title="Lịch sử đổi ngày nạp tiền">
            <div class="example mb-10">
                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col" class="min-w-100px">STT</th>
                            <th scope="col" class="min-w-200px">Người sửa</th>
                            <th scope="col" class="min-w-200px">Mã nhân viên</th>
                            <th scope="col" class="min-w-100px">Ngày nạp trước khi sửa</th>
                            <th scope="col" class="min-w-100px">Hệ số trước khi sửa</th>
                            <th scope="col" class="min-w-100px">Thời gian sửa</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(value, index) in log_change_date_paid" :key="index">
                            <td>{{index + 1}}</td>
                            <td>{{value.user ? value.user.name : ''}}</td>
                            <td>{{value.user ? value.user.id + 100000 : ''}}</td>
                            <td>{{value.old_data | formatDateTimeAsia}}</td>
                            <td>{{ value.old_business_coefficient }}</td>
                            <td>{{value.created_at | formatDateTimeAsia}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import {
        GET_LOG_CHANGE_DATED_PAID,
        UPDATE_STUDENT_AMOUNT
    } from "../../../core/services/store/contract/studentAmount.module";
    // Datetime picker
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import moment from 'moment-timezone';
    import {mapGetters} from "vuex";

    export default {
        name: "ModalEditCreatedAt",
        components: {
            DatePicker
        },
        props: {
            item: Object,
            index: ''
        },
        data() {
            return {
                date: this.formatDate(),
                list_detail: [],
                log_change_date_paid: []
            }
        },
        computed: {
            ...mapGetters(["currentUser"]),
        },
        mounted() {
            this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
                let modal_edit_date = 'modal-show-log-change-dated-paid-' + this.index;
                if (modalId == modal_edit_date) {
                    this.getLogChangeDatePaid();
                }
            })
        },
        methods: {
            getLogChangeDatePaid() {
                this.$store.dispatch(GET_LOG_CHANGE_DATED_PAID, this.item.id).then((data) => {
                    if (!data.error) {
                        this.log_change_date_paid = data.data;
                    }
                });
            },
            selectDate($event) {
                // Không cho phép để rỗng
                if ($event == null) {
                    this.$bvToast.toast('Ngày không cho phép để trống', {
                        title: 'Chọn ngày',
                        variant: 'danger',
                        solid: true
                    });
                    this.date = this.formatDate()
                }
            },
            formatDate() {
                return moment(String(this.item.dated_paid)).format('DD-MM-YYYY HH:mm:ss')
            },
            onSubmitTime() {
                this.$store.dispatch(UPDATE_STUDENT_AMOUNT, {
                    dated_paid: this.date,
                    id: this.item.id,
                    is_update_children: true,
                }).then((res) => {
                    if (!res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Sửa ngày đóng tiền',
                            variant: 'success',
                            solid: true
                        });
                        this.$emit('update_success');
                    } else {
                        this.$bvToast.toast(res.message, {
                            title: 'Sửa ngày đóng tiền',
                            variant: 'danger',
                            solid: true
                        });
                    }
                });
                this.$refs['modal-edit-created-at'].hide();
            },
            onSubmitCoefficient() {
                this.$store.dispatch(UPDATE_STUDENT_AMOUNT, {
                    id: this.item.id,
                    is_update_children: true,
                    business_coefficient: this.item.business_coefficient,
                }).then((res) => {
                    if (!res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Sửa hệ số kinh doanh',
                            variant: 'success',
                            solid: true
                        });
                        this.$emit('update_success');
                    } else {
                        this.$bvToast.toast(res.message, {
                            title: 'Sửa hệ số kinh doanh',
                            variant: 'danger',
                            solid: true
                        });
                    }
                });
                this.$refs['modal-edit-coefficient-business'].hide();
            },
        }
    }
</script>

<style scoped>
</style>
