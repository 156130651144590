<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">Thông tin Citime</h3>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-4">
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Trung tâm</label>
            <el-select v-model="query.center_id"
                       placeholder="Chọn trung tâm"
                       @change="getCombos"
                       clearable
            >
              <el-option
                  v-for="item in centers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="example mb-10">
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <form class="form" id="combo_create">
            <div class="example-preview">
              <table class="table">
                <thead>
                <tr>
                  <th scope="col">STT</th>
                  <th scope="col">Tên combo</th>
                  <th scope="col">Giá trị</th>
                  <th scope="col">Thời gian trả góp</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(combo , index) in combos" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <ValidationProvider :vid="'combos.'+index + '.name'" name="Tên combo" rules="required"
                                        v-slot="{ errors,classes }">
                      <input v-model="combo.name" placeholder="Nhập tên" class="form-control" :class="classes"
                             type="text"/>
                      <div class="fv-plugins-message-container">
                        <div data-field="courses" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </td>
                  <td>
                    <ValidationProvider :vid="'combos.'+index + '.amount'" name="Tên combo" rules="required"
                                        v-slot="{ errors,classes }">
                      <money v-model="combo.amount" v-bind="money" :class="classes" class="form-control"></money>
                      <div class="fv-plugins-message-container">
                        <div data-field="courses" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>

                  </td>
                  <td>
                    <ValidationProvider :vid="'combos.'+index + '.months'" name="Tên combo" rules="required"
                                        v-slot="{ errors,classes }">
                      <el-select v-model="combo.months"  placeholder="Chọn tháng trả góp" size="large" multiple>
                        <el-option
                            v-for="item in moneyOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                      </el-select>
                    </ValidationProvider>
                  </td>
                  <td>
                    <i @click="deleteCombo(combo.id)" v-if="combo.id"
                       class="text-danger  cursor-pointer fas fa-trash mt-3"></i>
                    <i @click="minusCombo(index)" v-else
                       class="text-danger cursor-pointer fas fa-minus-circle mt-3"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-between mt-4">
              <button type="button" class="btn btn-success  btn-sm" @click="addCombo"><i class="fas fa-plus"></i>Thêm
                mới
              </button>
              <button type="button" class="btn btn-success  btn-xl" @click="saveCombo">Lưu
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import {GET_LIST_CENTERS} from "@/core/services/store/center/centers.module";
import {Cambridge_Mentor_ID, ECOACH_ID, IELTS_MENTOR_ID} from "@/core/option/typeCenterBill";
import ComboItem from "@/view/pages/citime/ComboItem";
import {Money} from 'v-money';
import {CITIME_COMBO_CREATE, CITIME_COMBO_DELETE, CITIME_COMBO_INDEX} from "@/core/services/store/citime/citime.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "CitimeIndex",
  components: {ComboItem, Money},

  data() {
    return {
      centers: [],
      query: {
        center_id: IELTS_MENTOR_ID
      },
      combos: [
        {
          id: null,
          name: '',
          amount: '',
          months : null
        }
      ],
      money: {
        decimal: ',',
        thousands: ',',
        prefix: '',
        suffix: ' đ',
        precision: 0,
        masked: false,
      },
      moneyOption: [
        {
          value: "6",
          label: "6 tháng"
        },
        {
          value: "9",
          label: "9 tháng"
        },
        {
          value: "12",
          label: "12 tháng"
        },
      ]
    }
  },
  mounted() {
    this.getCenter();
    this.getCombos();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý tài chính", route: ''},
      {title: "Thông tin Citime"}
    ]);
  },
  computed: {},
  methods: {
    addCombo() {
      this.combos.push({
        id: null,
        name: '',
        amount: '',
        months : null
      });
    },
    getCombos() {
      this.$store.dispatch(CITIME_COMBO_INDEX, this.query).then((data) => {
        this.combos = data.data;
      }).catch((error) => {

      })
    },
    saveCombo() {
      this.$confirm('Bạn chắc chắn muốn lưu combo không?', 'Lưu combo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch(CITIME_COMBO_CREATE, {
          center_id: this.query.center_id,
          combos: this.combos
        }).then((data) => {
          this.getCombos();
          this.$message.success(data.message);
        }).catch((e) => {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        })
      })
    },
    deleteCombo(id) {
      this.$confirm('Bạn chắc chắn muốn xóa combo không?', 'Xóa combo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch(CITIME_COMBO_DELETE, id).then((data) => {
          this.getCombos();
          this.$message.success(data.message);
        }).catch((error) => {
          this.$message.error(error.data.message);
        })
      })
    },
    minusCombo(index) {
      this.combos.splice(index, 1);
    },
    getCenter() {
      this.$store.dispatch(GET_LIST_CENTERS, {
        ids: [ECOACH_ID, IELTS_MENTOR_ID, Cambridge_Mentor_ID]
      }).then((data) => {
        this.centers = data.data;
      })
    }
  }
}
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>