<template>
  <div>
    <b-modal
        id="event-history"
        title="Xem lịch sử"
        centered
        hide-footer
    >
      <table class="table" v-if="histories.length">
        <thead>
        <tr>
          <th scope="col">STT</th>
          <th scope="col">Thời gian check-in</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item ,index) in histories" :key="index">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ item.time_checkin }}</td>
        </tr>
        </tbody>
      </table>
      <p v-else class="text-danger">Không có lịch sử nào</p>
    </b-modal>
  </div>
</template>
<script>
import {
  COMPANY_EVENT_USER_GET_HISTORIES
} from "@/core/services/store/company-event/company-event.module";

export default {
  name: "Histories",
  props: {
    user_id: {
      type: Number,
      default: () => {
        return 0;
      },
    }
  },
  watch: {
    user_id() {
      this.getHistories();
    }
  },
  data() {
    return {
      histories: []
    }
  },
  methods: {
    getHistories() {
      this.$store.dispatch(COMPANY_EVENT_USER_GET_HISTORIES, {
        user_id: this.user_id
      }).then((res) => {
        if (!res.error) {
          this.histories = res.data;
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    }
  }
}
</script>

<style scoped>

</style>