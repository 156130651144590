<template>
 <div>
   <video class="rounded" width="150" height="100" controls>
     <source :src="urlVideo" type="video/mp4">
   </video>
 </div>
</template>

<script>
export default {
  name: "VideoModal",
  props:{
    urlVideo:{
      type:String,
      default(){
        return ''
      }
    }
  }
}
</script>

<style scoped>

</style>