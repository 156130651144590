<template>
  <div>
    <div v-if="typeQuestion === 'write_paragraph'" class="border border-dark rounded-lg mb-5 p-8 bg-white">
      <p class="font-weight-bold text-break m-0 p-2">
        {{ groupQuestions[indexQuestion]?.group_question?.description }}
      </p>
      <p class="m-0" style="white-space: pre-line">
        {{ groupQuestions[indexQuestion]?.group_question?.script }}
      </p>
      <img :src="groupQuestions[indexQuestion].group_question.image" alt="">
    </div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="form" @submit.prevent autocomplete="off">
        <div class="p-5 rounded-lg border  bg-white">
          <div style="display: flex; justify-content: end">
            <div style="display: flex; align-items: center; gap: 4px;" class="mb-3">
              <el-button class="p-2" v-if="indexQuestion > 0" style="background-color: #bab9bc; color: #000;font-size: 13px; "  @click="prevQuestion">Câu trước</el-button>
              <el-button class="p-2" style="background-color: #554B7E; color: #f9f7fb;font-size: 13px" @click="handleSubmit(nextQuestion)">{{groupQuestions.length !== indexQuestion + 1 ? "Câu sau" : "Lưu"}}</el-button>
            </div>
          </div>
          <div
              v-if="typeQuestion === 'write_paragraph'"
              style="width: 100%; display: flex; align-items: center; justify-content: space-between; padding:0 14px 20px 14px;">
            <div class="d-flex align-self-center">
              <span style="font-weight: 600;">Chấm điểm</span>
            </div>
          </div>
          <div class="d-flex">
            <div
                class="d-flex flex-column"
                :class="typeQuestion === 'write_paragraph' ? 'col-8' : 'col-6'"
                style="gap: 10px"
            >
              <div v-if="typeQuestion === 'record_video'" class=" rounded-sm p-4 text-white" style="background-color: #554b7e">
                Record_video
              </div>
              <div v-if="typeQuestion === 'record_audio'" class=" rounded-sm p-4 text-white" style="background-color: #554b7e">
                Record_audio
              </div>
              <div v-if="typeQuestion === 'record_video' || typeQuestion === 'record_audio'" class="rounded-lg p-5 font-weight-bolder border border-dark" style="background: #f9f7fb">
                <p class="font-weight-bold text-break m-0">
                  {{ groupQuestions[indexQuestion]?.group_question?.description }}
                </p>
                <p class="m-0" style="white-space: pre-line">
                  {{ groupQuestions[indexQuestion]?.group_question?.script }}
                </p>
                <el-image
                    :src="groupQuestions[indexQuestion]?.group_question?.image"
                    fit="fill"
                    v-if="groupQuestions[indexQuestion]?.group_question?.image"
                ></el-image>
                <div
                    v-if="groupQuestions[indexQuestion]?.group_question?.video !== ''"
                    class="rounded-lg overflow-hidden w-full"
                >
                  <video class="rounded" width="320" height="220" controls preload="auto">
                    <source :src="groupQuestions[indexQuestion]?.group_question?.video" type="video/mp4" />
                  </video>
                </div>
                <div
                    v-if="groupQuestions[indexQuestion]?.group_question?.audio !== ''"
                    class="rounded-lg overflow-hidden w-full"
                >
                  <audio class="rounded" controls preload="auto">
                    <source :src="groupQuestions[indexQuestion]?.group_question?.audio" type="audio/ogg" />
                  </audio>
                </div>
              </div>
              <div class="p-5 rounded-lg border border-dark d-flex flex-column" style="background-color: #f9f7fb;">
                <span class="font-weight-bolder flex-fill">Bài làm</span>
                <div
                    v-if="typeQuestion === 'write_paragraph'"
                    class="bg-white p-4 my-5 border border-dark rounded-lg"
                    style="position: relative; display: flex; align-items: center; overflow-y: auto"
                    :style="{'height': `${answerHeight}px`}"
                >
                  <div
                      v-for="(comment, index) in highlightComment"
                      :style="{ 'height': `${answerHeight}px`, 'z-index': index }"
                      style="position: absolute; top: 0; left: 0; padding: 4px 14px;" :key="`span-${index}`">
                                <span v-for="(item, indexItem) in comment" ref="answerRef" :key="`${indexItem}-${index}`">
                                    <span v-if="item.type === 1" class="bg-warning" style="white-space: pre-wrap">{{ item?.text }}</span>
                                    <span v-if="item.type === 0" style="white-space: pre-wrap">{{ item.text }}</span>
                                    <span v-if="item.type === 2" @mouseup="getSelectionText(groupQuestions[indexQuestion]?.student_answer)" style="white-space: pre-wrap">{{ item.text
                                      }}</span>
                                </span>
                  </div>
                </div>
                <div v-if="typeQuestion === 'record_video'">
                  <video class="rounded" width="320" height="220" controls preload="auto">
                    <source :src="groupQuestions[indexQuestion]?.student_answer" type="video/mp4" />
                  </video>
                </div>
                <div v-if="typeQuestion === 'record_audio'">
                  <audio class="rounded" controls :key="indexQuestion">
                    <source :src="groupQuestions[indexQuestion]?.student_answer" type="audio/ogg" />
                  </audio>
                </div>
                <div v-if="typeMark !== 10 && currentQuestion?.typeAnswer === WRITE_PARAGRAPH" class="d-flex justify-content-between mt-3" style="gap: 20px; flex-wrap: wrap;">
                  <div class="d-flex flex-column">
                    <label>Task Achievement</label>
                    <ValidationProvider
                        name="Task Achievement"
                        rules="required"
                        v-slot="{ errors  }"
                        class="w-100 "
                    >
                      <el-input-number :step-strictly="true" :step="0.5" v-model="currentQuestion.achievement" size="small" :min="0" :max="9"
                                       controls-position="right"></el-input-number>
                      <div class="fv-plugins-message-container">
                        <div
                            data-field="name"
                            data-validator="notEmpty"
                            class="fv-help-block"
                        >
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="d-flex flex-column">
                    <label>Lexical Resource</label>
                    <ValidationProvider
                        name="Lexical"
                        rules="required"
                        v-slot="{ errors  }"
                        class="w-100 "
                    >
                      <el-input-number :step-strictly="true" :step="0.5" v-model="currentQuestion.lexical" size="small" :min="0" :max="9"
                                       controls-position="right"></el-input-number>
                      <div class="fv-plugins-message-container">
                        <div
                            data-field="name"
                            data-validator="notEmpty"
                            class="fv-help-block"
                        >
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="d-flex flex-column">
                    <label>Coherence and Cohesion</label>
                    <ValidationProvider
                        name="Coherence"
                        rules="required"
                        v-slot="{ errors  }"
                        class="w-100 "
                    >
                      <el-input-number :step-strictly="true" :step="0.5" v-model="currentQuestion.coherence" size="small" :min="0" :max="9"
                                       controls-position="right"></el-input-number>
                      <div class="fv-plugins-message-container">
                        <div
                            data-field="name"
                            data-validator="notEmpty"
                            class="fv-help-block"
                        >
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="d-flex flex-column">
                    <label>Grammatical Range and Accuracy</label>
                    <ValidationProvider
                        name="Grammatical"
                        rules="required"
                        v-slot="{ errors  }"
                        class="w-100 "
                    >
                      <el-input-number :step="0.5" :step-strictly="true" v-model="currentQuestion.grammatical" size="small" :min="0" :max="9"
                                       controls-position="right"></el-input-number>
                      <div class="fv-plugins-message-container">
                        <div
                            data-field="name"
                            data-validator="notEmpty"
                            class="fv-help-block"
                        >
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div>
                    <label>Overall</label>
                    <el-input :value="calculatorOverall" size="small" disabled />
                  </div>
                </div>
                <div
                    class="d-flex flex-column"
                    v-if="typeMark === 10 && typeQuestion === 'write_paragraph'"
                    style="gap: 8px"
                >
                  <label class="font-weight-boldest ">Điểm toàn bài</label>
                  <ValidationProvider
                      name="Điểm toàn bài"
                      rules="required"
                      v-slot="{ errors  }"
                      class="w-100 "
                  >
                    <el-select
                        v-model="groupQuestions[indexQuestion].totalScore"
                        placeholder="Chọn điểm"
                        size="small"
                        style="width: 150px"
                    >
                      <el-option
                          v-for="item in selectScore"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div
                          data-field="name"
                          data-validator="notEmpty"
                          class="fv-help-block"
                      >
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div
                v-if="typeQuestion === 'write_paragraph'"
                class="col-4 border border-dark rounded-lg p-4"
                :class="typeQuestion === 'write_paragraph' ? 'col-4' : 'col-6'"
            >
              <div class="d-flex mb-5 overflow-hidden" style="align-items: center; justify-content: space-between; font-weight: 700">
                <span>Danh sách nhận xét</span>
                <span
                    :class="commentToAnswer?.length >= lengthComment  ? 'text-success' : 'text-danger'">
                            {{commentToAnswer?.length }}/{{ lengthComment }}
                        </span>
              </div>
              <div v-if="commentToAnswer?.length > 0" class="rounded-lg  " style="gap: 20px;overflow: auto;height: 300px">
                <div
                    v-for="(comment, index) in commentToAnswer??[]" @click="changeComment(comment, index)"
                    class="rounded-lg overflow-hidden border border-dark mt-3" :key="comment.index"
                >
                  <div class="d-flex justify-content-between align-content-center bg-warning">
                    <label class="d-flex align-self-center font-weight-boldest w-100 px-4 text-black mb-0">
                      {{ index + 1 }}. {{ `${comment?.text.substring(0,60)}` }}{{`${comment?.text.length > 60 ?'...':''}`}}
                    </label>
                    <div @click="removeComment(index)" class="p-2">
                      <i class="el-icon-delete text-black "></i>
                    </div>
                  </div>
                  <div class="py-2 p-3">
                    <p style="word-break: break-all;white-space: pre-wrap">{{comment?.feedback}}</p>
                  </div>
                </div>
              </div>
              <div v-else class="text-center">Chưa có nhận xét</div>
            </div>
            <div v-if="typeQuestion === 'record_video' || typeQuestion === 'record_audio'" class="card col-6 p-8" style="gap: 20px; background-color: #f6f4f8">
              <SelectOption
                  class-name="align-items-center "
                  label="Fluency & Coherence"
                  placeholder="Chọn thang điểm"
                  :markValue="groupQuestions[indexQuestion].Fluency_Coherence"
                  :typeMark="typeMark"
                  :key="`Fluency & Coherence${indexQuestion}`"
              />
              <SelectOption
                  class-name="align-items-center"
                  label="Lexical Resource"
                  placeholder="Chọn thang điểm"
                  :markValue="groupQuestions[indexQuestion].Lexical_Resource"
                  :typeMark="typeMark"
                  :key="`Lexical Resource${indexQuestion}`"
              />
              <SelectOption
                  class-name="align-items-center"
                  label="Grammatical range & Accuracy"
                  placeholder="Chọn thang điểm"
                  :markValue="groupQuestions[indexQuestion].Grammatical_Range_Accuracy"
                  :typeMark="typeMark"
                  :key="`Grammatical range & Accuracy${indexQuestion}`"
              />
              <SelectOption
                  class-name="align-items-center"
                  label="Pronunciation"
                  placeholder="Chọn thang điểm"
                  :markValue="groupQuestions[indexQuestion].pronunciation"
                  :typeMark="typeMark"
                  :key="`Pronunciation${indexQuestion}`"
              />
              <div class="d-flex " >
                <div style="line-height: 2">
                  <p class="font-weight-boldest" style="font-size: 16px">Điểm toàn bài</p>
                </div>
                <div class="ml-5" :key="indexQuestion" v-if="typeMark === 10">
                  <ValidationProvider
                      name="Điểm toàn bài"
                      rules="required"
                      v-slot="{ errors,  }"
                      class="w-100 px-4"
                  >
                    <el-select v-model="groupQuestions[indexQuestion].totalScore" placeholder="Chọn điểm"  style="width: 100%" size="small">
                      <el-option
                          v-for="item in selectScore"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div
                          data-field="name"
                          data-validator="notEmpty"
                          class="fv-help-block"
                      >
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div v-else class="ml-5" :key="indexQuestion">
                  <el-input :value="calculatorOverallSpeaking" size="small" disabled />
                </div>
              </div>

            </div>
          </div>
          <div class="d-flex justify-content-end" v-if="typeQuestion === 'write_paragraph'">
            <el-button @click="confirmMarkHomework = true" class="border-0 p-0 mt-2 text-primary">Làm thế nào để thêm nhận xét?</el-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <!--        Modal add comment-->
    <el-dialog :visible.sync="addComment" :show-close="false" :close-on-click-modal="false" :destroy-on-close="true" :lock-scroll="false">
      <p class="mb-3 ">Nhận xét: {{textComment}}</p>
      <el-input type="textarea"  placeholder="Nhập nhận xét" rows="10" v-model="feedback"></el-input>
      <span slot="footer" class="dialog-footer">
                <el-button @click="closeModalAddComment">Đóng</el-button>
                <el-button :disabled="disableAddComment && feedback === ''" type="primary" @click="confirmAddComment" >Xác nhận</el-button>
            </span>
    </el-dialog>
    <el-dialog title="Lưu ý:" :visible.sync="confirmMarkHomework" width="30%">
      <span style="white-space: no-wrap">Bạn vui lòng chọn (bôi đen) nội dung bài làm của học viên để thêm nhận xét.</span>
      <span slot="footer" class="dialog-footer w-100" style="display: flex; justify-content: center;">
            <el-button type="info" @click="confirmMarkHomework = false" style="background-color: #5D04B0; color: white;">Đã hiểu</el-button>
          </span>
    </el-dialog>
  </div>
</template>

<script>

import { ADMIN_POST_MARK_HOMEWORK } from "@/core/services/store/course/classes.module";
import {selectScore, selectScore9, WRITE_PARAGRAPH} from "@/core/option/selectScore";
import SelectOption from "@/view/pages/classes/components/SelectOption.vue";
import router from "@/router";
export default {
  data() {
    return {
      indexQuestion: 0,
      feedback: "",
      editComment: false,
      indexCommentEdit: null,
      loading: false,
      textComment: "",
      indexTextComment: null,
      disableAddComment: false,
      disableSubmit: false,
      commentToAnswer: [],
      result: [],
      confirmMarkHomework: false,
      addComment: false,
      enableHighlight: false,
      totalScore: null,
      answerHeight: 0,
      achievement: 0,
      lexical: 0,
      coherence: 0,
      grammatical: 0,
      overall: 0,
      fluency: {
        valueOption: null,
        comment: '',
      },
      coherenceSpeaking: {
        valueOption: null,
        comment: '',
      },
      grammaticalRange: {
        valueOption: null,
        comment: '',
      },
      grammaticalAccuracy: {
        valueOption: null,
        comment: '',
      },
      pronunciation: {
        valueOption: null,
        comment: '',
      },
      vocabularyRange: {
        valueOption: null,
        comment: '',
      },
      vocabularyAccuracy: {
        valueOption: null,
        comment: '',
      },
      WRITE_PARAGRAPH : WRITE_PARAGRAPH
    }
  },
  components: {SelectOption},
  props: {
    groupQuestions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    typeMark: {
      type: Number,
      default: 0
    },
    course_student_id: {
      type: Number,
      default: null,
    }
  },
  mounted() {
    let data = this.localStorageCommentAnswer('get');
    if(data){
      this.groupQuestions = data?.answerOfStudent;
      this.indexQuestion = data?.indexQuestion;
    }
    this.commentToAnswer = this.currentQuestion?.commentToAnswer
    this.matchHeight()
  },
  computed: {
    lengthComment(){
      let numberOfCharacters = this.groupQuestions[this.indexQuestion]?.student_answer.length;
      return numberOfCharacters < 100 ? 1 : this.typeMark === 10 ? 1 : 3;
    },
    selectScore(){
      return this.typeMark === 10 ? selectScore : selectScore9
    },
    highlightComment() {
      let answers = this.groupQuestions[this.indexQuestion]?.student_answer;
      if (this.commentToAnswer?.length === 0) {
        return [[{ text: answers, type: 2 }]];
      }
      return [...this.commentToAnswer.map(comment => [
        { text: answers.slice(0, comment.index), type: 0 },
        { text: comment.text, type: 1 },
        {
          text: answers.slice(comment.index + comment.text.length),
          type: 0
        },
      ]),
        [{ text: answers, type: 2 }]]
    },
    typeQuestion() {
      return this.groupQuestions[this.indexQuestion]?.group_question?.type
    },
    // clickHighlight() {
    //     this.enableHighlight = true
    // },
    calculatorOverall: function () {
      if([this.currentQuestion.achievement,this.currentQuestion.lexical,this.currentQuestion.coherence,this.currentQuestion.grammatical].includes(undefined)){
        return  0
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.currentQuestion.totalScore = this.customRound((parseInt(this.currentQuestion.achievement) + parseInt(this.currentQuestion.lexical) + parseInt(this.currentQuestion.coherence) + parseInt(this.currentQuestion.grammatical)) / 4)
      return this.customRound((parseInt(this.currentQuestion.achievement) + parseInt(this.currentQuestion.lexical) + parseInt(this.currentQuestion.coherence) + parseInt(this.currentQuestion.grammatical)) / 4)
    },
    calculatorOverallSpeaking() {
      if([this.currentQuestion.pronunciation.valueOption,this.currentQuestion.Grammatical_Range_Accuracy.valueOption,this.currentQuestion.Lexical_Resource.valueOption,this.currentQuestion.Fluency_Coherence.valueOption].includes(null) || this.typeMark === 10 ){
        return  0
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.currentQuestion.totalScore = this.customRound((parseInt(this.currentQuestion.pronunciation.valueOption) + parseInt(this.currentQuestion.Grammatical_Range_Accuracy.valueOption) + parseInt(this.currentQuestion.Lexical_Resource.valueOption) + parseInt(this.currentQuestion.Fluency_Coherence.valueOption)) / 4)
      return this.customRound((parseInt(this.currentQuestion.pronunciation.valueOption) + parseInt(this.currentQuestion.Grammatical_Range_Accuracy.valueOption) + parseInt(this.currentQuestion.Lexical_Resource.valueOption) + parseInt(this.currentQuestion.Fluency_Coherence.valueOption)) / 4)
    },
    currentQuestion(){
      return this.groupQuestions[this.indexQuestion]
    }
  },
  methods: {
    customRound(num) {
      const integerPart = Math.floor(num);
      const decimalPart = num % 1;

      if (decimalPart <= 0.25) {
        return integerPart;
      } else if (decimalPart <= 0.75) {
        return integerPart + 0.5;
      } else {
        return integerPart + 1;
      }
    },
    removeComment(comment) {
      this.commentToAnswer.sort((a, b) => a.index > b.index ? 1 : -1).splice(comment, 1)
    },
    matchHeight() {
      this.answerHeight = 200;
    },
    closeModalAddComment(comment) {
      this.textComment = ''
      this.addComment = false
      if(!this.editComment){

      }
      this.editComment = false
      this.feedback = ''

    },
    prevQuestion() {
      this.indexQuestion -= 1
      this.commentToAnswer = this.currentQuestion.commentToAnswer
    },
    nextQuestion() {
      if(this.commentToAnswer?.length < this.lengthComment && this.typeQuestion === 'write_paragraph'){
        this.$message({
          type: "error",
          message: `Bạn cần nhập đủ ${this.lengthComment} nhận xét để lưu.`,
          showClose: true,
        });
        return
      }
      this.localStorageCommentAnswer('set');
      const commentSpeaking = [
        {
          "fluency": this.fluency.comment,
        },
        {
          "coherence": this.coherenceSpeaking.comment,
        },
        {
          "grammaticalRange": this.grammaticalRange.comment,
        },
        {
          "grammaticalAccuracy": this.grammaticalAccuracy.comment,
        },
        {
          "pronunciation": this.pronunciation.comment,
        },
        {
          "vocabularyRange": this.vocabularyRange.comment,
        },
        {
          "vocabularyAccuracy": this.vocabularyAccuracy.comment,
        }
      ]
      const scoreSpeaking = {
        "fluency": this.fluency?.valueOption,
        "coherence": this.coherence?.valueOption,
        "grammaticalRange": this.grammaticalRange?.valueOption,
        "grammaticalAccuracy": this.grammaticalAccuracy?.valueOption,
        "pronunciation": this.pronunciation?.valueOption,
        "vocabularyRange": this.vocabularyRange?.valueOption,
        "vocabularyAccuracy": this.vocabularyAccuracy?.valueOption,
      }
      const scoreWriting = {
        "task-achievement": this.achievement,
        "lexical-resource": this.lexical,
        "coherence-and-cohesion": this.coherence,
        "grammatical-range-and-accuracy": this.grammatical,
      }
      this.result.push(
          {
            "score": this.typeMark === 10 ? this.totalScore : this.overall,
            "outcomes_id": this.groupQuestions[this.indexQuestion]?.outcomes_id,
            "comment": this.groupQuestions[this.indexQuestion]?.comment,
            "comments": this.typeQuestion === 'record_video' ? commentSpeaking : this.commentToAnswer,
            "score_detail": this.typeQuestion === 'record_video' ? scoreSpeaking : scoreWriting
          }
      )
      if(this.indexQuestion + 1 !== this.groupQuestions.length) {
        this.indexQuestion += 1
        this.commentToAnswer = this.currentQuestion?.commentToAnswer
        // this.resetNextPage()
      } else {
        this.summit()
        this.disableSubmit = true
      }
    },
    changeComment(comment, index) {
      this.editComment = true
      this.addComment = true
      this.indexCommentEdit = index
      this.indexTextComment = comment.index
      this.textComment = comment.text
      this.feedback = comment.feedback
    },
    confirmAddComment() {
      if (this.editComment === true) {
        this.commentToAnswer?.splice(this.indexCommentEdit, 1, {
          text: this.textComment,
          feedback: this.feedback,
          index: this.indexTextComment
        })
        this.editComment = false;
        this.addComment = false
        this.feedback = ""
        return
      }
      if(this.feedback.trim() !== "") {
        this.commentToAnswer?.push({
          text: this.textComment,
          feedback: this.feedback,
          index: this.indexTextComment
        })
        console.log(this.indexTextComment);
        this.commentToAnswer?.sort((a, b) => a.index > b.index ? 1 : -1)
        this.addComment = false
        this.feedback = ""
      }else {
        this.$message({
          type: "error",
          message: "Bạn chưa nhập nhận xét!",
          showClose: true,
        });
        this.disableAddComment = true
      }
    },
    studentLength (value){
      if(value < 300) {
        return 1
      }
      return 15
    },
    getSelectionText(answer) {
      let word = "";
      if (window.getSelection) {
        word = window.getSelection();
      }
      if (document.selection && document.selection.type !== "Control") {
        word = document.selection.createRange().word;
      }
      let answers = this.groupQuestions[this.indexQuestion]?.student_answer
      if (answers.indexOf(word.toString().trim()) !== -1 && word.toString().trim()!== "" && this.commentToAnswer?.find(item=> (item?.start === word.anchorOffset && item?.text.length === word.toString().length) || item?.feedback === "") == null) {
        this.addComment = true
        this.textComment = word.toString();
        this.indexTextComment = word.anchorOffset < word.focusOffset ? word.anchorOffset : word.focusOffset
      }
      else {

      }
    },
    summit() {
      this.loading = true
      // {
      //   "score": this.typeMark === 10 ? this.totalScore : this.overall,
      //     "outcomes_id": this.groupQuestions[this.indexQuestion]?.outcomes_id,
      //     "comment": this.groupQuestions[this.indexQuestion]?.comment,
      //     "comments": this.typeQuestion === 'record_video' ? commentSpeaking : this.commentToAnswer,
      //     "score_detail": this.typeQuestion === 'record_video' ? scoreSpeaking : scoreWriting
      // }
      let results = this.groupQuestions.map((question)=>{
        const commentSpeaking = [
          {
            "fluency": question.Fluency_Coherence.comment,
          },
          {
            "vocabularyRange": question.Lexical_Resource.comment,
          },
          {
            "grammaticalRange": question.Grammatical_Range_Accuracy.comment,
          },
          {
            "pronunciation": question.pronunciation.comment,
          },
        ]
        const scoreSpeaking = {
          fluency: question?.Fluency_Coherence?.valueOption,
          vocabularyRange: question?.Lexical_Resource?.valueOption,
          grammaticalRange: question?.Grammatical_Range_Accuracy?.valueOption,
          pronunciation: question?.pronunciation?.valueOption,
        }
        const scoreWriting = {
          "task-achievement": question?.achievement??null,
          "lexical-resource": question?.lexical??null,
          "coherence-and-cohesion": question?.coherence??null,
          "grammatical-range-and-accuracy": question?.grammatical??null,
        }
        return {
          score: question.totalScore,
          outcomes_id: question?.outcomes_id,
          comment: question?.comment,
          comments: question.typeAnswer === WRITE_PARAGRAPH ? question.commentToAnswer : commentSpeaking,
          score_detail: question.typeAnswer === WRITE_PARAGRAPH ? scoreWriting : scoreSpeaking,
          comment_phase : "nhận xét theo giai đoạn"
        }
      })
      this.$store
          .dispatch(ADMIN_POST_MARK_HOMEWORK, {
            classroom_id: this.$route.query.classroom_id,
            course_student_id: this.course_student_id,
            overall_result_id: this.$route.query.overall_id,
            type: "homework",
            results: results,
          })
          .then(() => {
            this.$message({
              type: "success",
              message: "Chấm điểm thành công!",
              showClose: true,
            });
            this.localStorageCommentAnswer('remove');
            this.$router.push({ name: "list_mark_homework",params: {id : this.$route.params.id},
              query: { classroom_id: this.$route.query.classroom_id, lesson: this.$route.query.lesson , learning_schedule_id: this.$route.query.learning_schedule_id}});
          })
          .catch((res) => {
            this.$message({
              type: "error",
              message: "Sever đang bận! " + res.data.message,
              showClose: true,
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    resetNextPage(){
      this.fluency = {
        valueOption: null,
        comment: '',
      }
      this.coherenceSpeaking={
        valueOption: null,
        comment: '',
      }
      this.grammaticalRange= {
        valueOption: null,
        comment: '',
      }
      this.grammaticalAccuracy= {
        valueOption: null,
        comment: '',
      }
      this.pronunciation= {
        valueOption: null,
        comment: '',
      }
      this.vocabularyRange= {
        valueOption: null,
        comment: '',
      }
      this.vocabularyAccuracy= {
        valueOption: null,
        comment: '',
      }
    },
    localStorageCommentAnswer(type){
      let studentAnswer = {
        indexQuestion : this.indexQuestion,
        answerOfStudent : this.groupQuestions
      }
      let studentId = this.$route.query.student_id
      let classRoomId = this.$route.query.classroom_id
      let lesson = this.$route.query.lesson
      let key = `studentAnswer${studentId}${classRoomId}${lesson}`
      switch(type) {
        case 'get':
          return  JSON.parse(localStorage.getItem(key));
        case 'set':
          localStorage.setItem(key,JSON.stringify(studentAnswer))
          break;
        case 'remove':
          localStorage.removeItem(key)
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
