export const SALE_LEADER = 1;
export const SALE_EXPERT = 2;
export const SALE_MEMBER = 3;
export const SALE_INTERN = 4;
export const GDVP = 5;
export const GDKV = 6;
export const GDV = 27;
export const TEACHER = 7;
export const SUPPORT = 8;
export const STUDENT = 9;
export const CENTER = 10;
export const BRANCH = 11;
export const QLHV = 12;
export const CENTER_ASSIGN = 13;
export const STAFF = 14;
export const BOD = 15;
export const TEAMLEADER = 16;
export const HANH_CHINH_KHU_VUC = 18;
export const HEAD_OF_DEPARTMENT = 32;

export const DOANH_SO_VI_TRI_MOI_SI = 24000000;
export const DOANH_SO_VI_TRI_MOI_S = 99000000;
export const DOANH_SO_VI_TRI_MOI_SE = 300000000;

export const DOANH_SO_CA_NHAN = 24000000;

export const DOANH_SO_DOI_SE = 66000000;
export const DOANH_SO_DOI_SL = 200000000;

export const accountTypeOption = [
    { name: QLHV , value: "Quản lý học viên" },
    { name: SALE_INTERN, value: "Sale Intern" },
    { name: SALE_MEMBER, value: "Sale" },
    { name: SALE_EXPERT, value: "Sale Expert" },
    { name: SALE_LEADER, value: "Sale Leader" },
    { name: GDVP, value: "Giám đốc văn phòng" },
];

export const kiemNghiemOption = [
    {id: 'TPOnline', name: 'TP Online'},
    {id: 'TVV', name: 'Tư vấn viên'},
    {id: 'SLX', name: 'SLX'},
    {id: 'TPTT', name: 'TP Truyền thông'},
    {id: 'TPNS', name: 'TP Nhân sự'},
]

export const TAI_KHOAN_CAP_TREN = [
    { id: 'sale-member', value: "Sale" },
    { id: 'sale-expert', value: "Sale Expert" },
    { id: 'sale-leader', value: "Sale Leader" },
];
export const TPOL = ['TPOL', 'TPOnline'];
export const TPNS = ['TPNS'];
export const TVV = ['TVV'];

export const GDVPorGDKV = [GDVP, GDKV];

export const WATCH_AS_TP = [
    {
        id: 0,
        value: 'TPhòng/Giám đốc',
        label: 'TP/GĐ'
    },
    {
        id: 1,
        value: 'Đội kinh doanh',
        label: 'Đội'
    },
    {
        id: 2,
        value: 'Cá nhân',
        label: 'Cá nhân'
    }
];

export const WATCH_AS_SALE = [
    {
        id: 1,
        value: 'Đội kinh doanh',
        label: 'Đội'
    },
    {
        id: 2,
        value: 'Cá nhân',
        label: 'Cá nhân'
    }
];



