<template>
  <div id="listPromotion">
    <div class="page-header" style="display: flex; justify-content: space-between; padding: 18px 0; align-items: center;">
      <h3 class="page-title" style="font-size: 16px; font-weight: 400">Danh sách khuyến mại</h3>
      <button class="p-2" style="background: #1BC5BD; border-radius: 5px; width: 150px; color: white" @click="addPromotion = true">
        <i class="el-icon-plus text-white mr-1"></i>
        Thêm khuyến mại
      </button>
    </div>
    <div class="table-responsive" style="overflow: auto; height: 58vh">
      <table class="table table-vertical-center table-bordered">
        <thead style="position: sticky; top: 0;">
        <tr style="border: 1px solid #EBEDF3">
          <th scope="col" style="background: #F8F8F8; width: 40px">STT</th>
          <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: start">Tên khuyến mại</th>
          <th scope="col" class="min-w-100px" style="background: #F8F8F8; text-align: start">Giá trị giảm</th>
          <th scope="col" class="w-160px text-center" style="background: #F8F8F8">Hành động</th>
        </tr>
        </thead>
        <tbody v-if="loading">
        <tr class="col-12">
          <td class="text-center"><i class="el-icon-loading" style="position: absolute; left: 50%; margin: 15px 0"></i>
          </td>
        </tr>
        </tbody>
        <tbody v-else-if="dataPromotion?.length > 0">
        <tr v-for="(item, index) of dataPromotion" :key="item?.id" style="border: 1px solid #EBEDF3; border-top: 0">
          <td>{{ index + 1 }}</td>
          <td style="text-align: start">{{ truncatedText(item?.name) }}</td>
          <td style="text-align: start; color: rgba(0, 102, 255, 1);">{{ item?.value  }}%</td>
          <td>
            <div style="display: flex; justify-content: center; align-items: center; gap: 10px">
              <button class="btn-action" style="border-color: #FF0000" @click="handleDelete(item)">
                <i class="el-icon-delete" style="color: #FF0000"></i>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr style="word-break: break-word; white-space: nowrap; position: relative">
          <label style="position: absolute; left: 50%; translate: -50%; top: 10px">chưa có khuyến mại</label>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-8" style="display: flex; justify-content: end;" v-if="dataPromotion?.length > 0">
      <span>Tổng số bản ghi: <span style="color: #0066FF">{{dataPromotion?.length ?? 0}}</span></span>
    </div>
    <el-dialog title="Thêm khuyến mại"
               :show-close="false" :visible.sync="addPromotion" width="50%"
               :destroy-on-close="true">
      <AddPromotion @closeDialog="addPromotion=false; close(true)"/>
    </el-dialog>

    <el-dialog id="" top="25vh" width="40%" title="Xoá khuyến mại" :visible.sync="dialogDelete">
      <hr>
      <span class="text-break">Bạn có chắc chắn muốn xoá Khuyến mại " {{ name_Promotion_delete }} "</span>
      <div slot="footer" class="dialog-footer d-flex justify-content-end mt-2 pb-4">
          <el-button size="mini" style="background: rgba(202, 202, 202, 1);" class="text-black" @click="close(false)">Hủy bỏ</el-button>
          <el-button size="mini" style="background: rgba(93, 4, 176, 1);" class="text-white" @click="deletePromotion">
              <i v-if="loadingDelete" class="el-icon-loading text-white float-righ"></i>
              Xác nhận</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AddPromotion from "../Components/AddPromotion.vue";
import {DELETE_PROMOTION, GET_LIST_PROMOTION_GENZ} from '../../../../core/services/store/rewards-programs/rewardsPrograms.module'
import moment from "moment-timezone";

export default {
  components: { AddPromotion },
  props: {
    start_time: {
      type: Date,
      default: '',
    },

    end_time: {
      type: Date,
      default: '',
    },
  },
  data() {
    return{
      loading: false,
      addPromotion: false,
      dialogDelete: false,
      loadingDelete: false,
      dataPromotion: [],
      id_Promotion_delete : '',
      name_Promotion_delete : '',
      start_time: '',
      end_time: ''
    }
  },

  watch: {
    start_time: 'handleTimeChange',
    end_time: 'handleTimeChange',
  },


  computed: {},

  mounted() {
    this.getPromotion();
  },
  
  methods: {
    handleTimeChange(key, newVal) {
      this[key] = moment(newVal).format('YYYY-MM-DD HH:mm:ss');
      if (this.start_time && this.end_time) {
        this.handleTime();
      }
    },
    
    getPromotion() {
      let start_time = this.start_time ? moment(this.start_time).format('YYYY-MM-DD HH:mm:ss') : '';
      let end_time = this.end_time ? moment(this.end_time).format('YYYY-MM-DD HH:mm:ss') : '';
      if((!start_time || !end_time)){
        start_time = '';
        end_time = '';
      };

      if(moment(start_time).isAfter(moment(end_time)) && start_time && end_time) {
        this.$notify({
        title: 'Dư liệu sai!',
        message: 'Thời gian bắt đầu không được lớn hơn Thời gian kết thúc',
        type: 'warning'
      });
      this.start_time = '';
      this.end_time = '';
      return;
      }
      this.$store.dispatch(GET_LIST_PROMOTION_GENZ, {
        program_id : this.$route.query.promotion_id,
        start_time, end_time
      }).then(data => {
          this.dataPromotion = data.data;
      })
    },

    handleTime() {
      let start_time = this.start_time;
      let end_time = this.end_time;
      if(start_time || end_time && start_time > end_time) {
        this.getPromotion();
      }
    },

    truncatedText(item) {
      let wordLimit = 35;
      let suffix = '...';
      if (item.length > wordLimit) {
          return item.slice(0, wordLimit) + suffix;
    }
    return item;
    },

    handleDelete(item) {
      this.id_Promotion_delete = item.id;
      this.name_Promotion_delete = item.name;
      this.dialogDelete = true;
    },

    async deletePromotion() {
      this.loadingDelete=true
      let id = this.id_Promotion_delete;
     await this.$store.dispatch(DELETE_PROMOTION, id).then((res) => {
          if (!res.data.error) {
              this.$notify({
                  title: 'Thành công!',
                  message: 'Xoá khuyến mại thành công',
                  type: 'success'
            });
            this.close(true)
            this.loadingDelete = false;
          }
          }).catch((e) => {
          this.$notify.error({
            title: 'Lỗi',
            message: 'Xoá khuyến mại thất bại',
            type: 'error'
          });
          this.loadingDelete = false;
        });
      },

    close() {
      this.addPromotion = false;
      this.dialogDelete = false;
      this.getPromotion();
    }
  }
}
</script>

<style lang="css">
.btn-action {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid;
}

#listPromotion .el-dialog__body{
  padding: 0 20px;
}
</style>