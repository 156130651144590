<template>
    <div class="container">
        <div class="customer-study">
            <div class="customer-list-courses">
                <div class="title">
                    <h4>Thông tin tổng quan</h4>
                </div>
                <div class="table-responsive overflow-auto">
                    <table class="table table-vertical-center table-hover">
                        <thead>
                        <tr>
                            <th>Khóa học</th>
                            <th>Lớp học</th>
                            <th>Lộ trình học</th>
                            <th>Số buổi đi học</th>
                            <th>Số buổi làm BT</th>
                            <th>Kết quả đầu ra</th>
                            <th>Thời gian bắt đầu học</th>
                            <th>Hành động</th>
                        </tr>
                        </thead>
                        <tbody v-if="studentRegisterCourses.length > 0">
                        <tr v-for="(item, key) in studentRegisterCourses">
                            <td>{{ item.course && item.course.name }}</td>
                            <td>{{ item.class ? item.class.name : '(Chưa có thông tin)' }}</td>
                            <td>{{ item.schedule }}</td>
                            <td>{{ item.schedule_number }}</td>
                            <td>{{ item.homework }}</td>
                            <td>
                                <span :class="renderOutput(item.output).classText">{{
                                        renderOutput(item.output).name
                                    }}</span>
                            </td>
                            <td >
                              <span v-if="item.class">{{item.timeStart}} - {{ item.class.start_date }}</span>
                            </td>
                            <td>
                                <a href="#" title="Xem chi tiết"
                                   class="btn btn-xs btn-icon btn-outline-success ml-2"
                                   @click="showModalDetail(item)"><i class="far fa-eye"></i>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr>
                            <td colspan="8" class="event-title no-data text-center">Chưa có dữ liệu</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <el-dialog title="Tình hình học tập" :visible.sync="showModal">
          <chi-tiet-hoc-tap
              :loading="loading"
              :student-register-course="studentRegisterCourse"
              :classroom="classroomShow"
              :schedules="schedules"
          ></chi-tiet-hoc-tap>
        </el-dialog>
    </div>
</template>

<script>
import ChiTietHocTap from "./ChiTietHocTap";
import {CUSTOMER_SCHEDULE_LIST} from "@/core/services/store/customer/customer-crm.module";
import {StudyDetail} from "@/view/pages/customers/Model/studyDetail";

export default {
    name: "Hoc-Tap",
    components: {ChiTietHocTap},
    props: {
        studentRegisterCourses: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            showModal: false,
            studentRegisterCourse: {},
            classroomShow: {},
            schedules: [],
            loading: true,
        }
    },
    watch: {
      showModal() {
        if (this.showModal === false) {
          this.schedules = []
          this.loading = true
        }
      }
    },
    methods: {
        showModalDetail(studentRegisterCourse) {
            this.studentRegisterCourse = studentRegisterCourse;
            this.classroomShow = studentRegisterCourse.class;
            this.showModal = true;
            this.getSchedule();
        },
        getSchedule() {
          try {
            this.$store.dispatch(CUSTOMER_SCHEDULE_LIST, this.studentRegisterCourse.id).then((res) => {
              if (res?.data === null) {
                this.schedules = []
                this.loading = false
                return
              }
              const result = res?.data?.result
              this.schedules = result.map(item => new StudyDetail(
                  item?.lesson,
                  item?.day,
                  item?.is_scheduled,
                  item?.rating,
                  item?.homework_status,
                  item?.teacher_comment,
                  item?.comment_phase,
                  item?.type,
                  result?.length,
                  item?.student_feedback,
                  item?.comment,
                  item?.attendance_reason
              ));
              this.loading = false
            }).catch(e => {
              console.log(e)
            })
          } catch (error) {
            console.log(error)
          }
        },
        renderOutput(output) {
            let text;
            let classText;
            switch (output) {
                case 1:
                    text = 'Pass';
                    classText = 'badge badge-success';
                    break;
                case 2:
                    text = 'Fail';
                    classText = 'badge badge-info';
                    break;
                default:
                    text = 'Chưa có';
            }
            return {
                name: text,
                classText: classText
            };
        },
    }
}
</script>

<style scoped>

</style>
