import {STATUS, TASK_NAME, TYPE_EXAMS, TYPE_TASK, TYPES} from "@/core/option/testInputOption";
import moment from "moment-timezone";

export function TaskInformationModel(token = "", taskCode, taskItem, taskType, testForm, typeOfTestQuestion, workday, startTime, endTime, center, branch, customerName, customerPhone, centerId, status, linkTestOnline, fullTimeStart, counselorsName, counselorsPhone, come_check_date, nameUserCreated, phoneUserCreated,testInputScheduleId) {
    this.taskCode = taskCode
    this.taskItem = taskItem
    this.taskType = taskTypeToString(taskType)
    this.testForm = taskFormToString(testForm)
    this.typeOfTestQuestion = typeOfTestQuestionToString(typeOfTestQuestion)
    this.workday = isValidDate(workday)?workday:''
    this.startTime =isValidDate(startTime)?startTime:''
    this.endTime = isValidDate(endTime)?endTime:''
    this.center = center
    this.branch = branch
    this.customerName = customerName
    this.customerPhone = customerPhone
    this.taskName = taskNameToString(taskItem)
    this.token = token
    this.centerID = centerId
    this.status = status
    this.linkTestOnline = linkTestOnline
    this.fullTimeStart = fullTimeStart
    this.testFormID = testForm
    this.testInputScheduleId = testInputScheduleId
    this.informationCounselors = nameUserCreated ? `${nameUserCreated} - ${phoneUserCreated}` : `${counselorsName} - ${counselorsPhone}`
        this.come_check_date = come_check_date

}

export function taskNameToString(taskItem) {
    return TASK_NAME.get(taskItem);
}

export function taskFormToString(taskForm) {
    return TYPES.find((d) => d.id === taskForm)?.name
}

export function typeOfTestQuestionToString(typeOfTestQuestion) {
    return TYPE_EXAMS.find((d) => d.id === typeOfTestQuestion)?.name
}

export function taskTypeToString(taskType) {
    return TYPE_TASK.find((d) => d.id === taskType)?.name
}

export function statusToString(taskType) {
    return STATUS.find((d) => d.id === taskType)?.name
}

export function toModelTest(data) {
    let startTime = moment(data.schedule);
    let endTime = moment(data.end_schedule);
    return new TaskInformationModel(
        data.token ?? "",
        data.id,
        data.type_item_id,
        data.type_task,
        data.type,
        data.type_exam_id,
        startTime.format("DD/MM/yyyy"),
        startTime.format("HH:mm:ss"),
        endTime.format("HH:mm:ss"),
        data.center_name,
        data.branch_name,
        data.customer_name,
        data.customer_phone,
        data.center_id,
        data.status,
        data.link_test_online,
        data.schedule,
        data.ten_nguoi_tu_van,
        data.std_nguoi_tu_van,
        data.come_check_date,
        data?.user_created?.name,
        data?.user_created?.phone,
        data?.test_input_schedule_id

    )
}
function isValidDate(dateString) {
    return !(dateString == 'Invalid date')
}

