<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        Thông báo
      </div>
      <div class="card-title">
        <button
          class="font-weight-bold font-size-3  btn btn-success"
          @click="addnew"
        >Thêm mới
        </button>
      </div>
    </div>

    <div class="card-body">
      <div class="example mb-10">

        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th>STT</th>
              <th>Name</th>
              <th>Thời gian tạo</th>
              <th style="min-width: 100px">Hành động</th>
            </tr>
            </thead>
            <tbody v-if="data.length > 0" >
            <tr v-for="(item, index) in data" :key="index">
              <td>{{ item.stt }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.created_at | formatDateTimeAsia }}</td>
              <td style="width: 15%">
                <a title="Chỉnh sửa" class="btn btn-icon mr-2 btn-outline-info" @click="edit(item.id)" href="javascript:">
                  <i class="fas fa-pen-nib"></i>
                </a>
                <a title="Xóa" @click="deleteNotification(item.id)" href="javascript:"
                   class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="3" class="event-title no-data">Chưa có dữ liệu</td>
            </tr>
            </tbody>
          </table>
          </div>
        <div style="display: flex;justify-content: center" class="edu-paginate mx-auto text-center">
          <paginate
            v-model="page"
            :page-count="last_page"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'Trước'"
            :next-text="'Sau'"
            :container-class="'pagination b-pagination'"
            :pageLinkClass="'page-link'"
            :page-class="'page-item'"
            :next-link-class="'next-link-item'"
            :prev-link-class="'prev-link-item'"
            :prev-class="'page-link'"
            :next-class="'page-link'"
          >
          </paginate>
        </div>
      </div>
    </div>

    <el-dialog :title="form_add ? 'Thêm mới thông báo' : 'Chỉnh sửa thông báo'" :visible.sync="checkVisibleAddEditForm">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" id="room_create" @submit.prevent="handleSubmit(form_add ? create : update )">
          <div class="card card-custom">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Tên thông báo
                      <span class="text-danger">*</span></label>
                    <ValidationProvider vid="name" name="Name" rules="required"
                                        v-slot="{ errors,classes }">
                      <input type="name" class="form-control" placeholder="Name"
                             v-model="form.name"
                             :class="classes">
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{
                            errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
                <button
                  type="submit"
                  class="btn btn-success mr-2"
                >
                  {{ form_add ? 'Tạo mới' : "Chỉnh sửa" }}
                </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div slot="footer" class="dialog-footer">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import {CREATE_NOTIFICATION, UPDATE_NOTIFICATION, DELETE_NOTIFICATION, GET_NOTIFICATION, SHOW_NOTIFICATION} from "../../../core/services/store/support/notification.module";
import Swal from "sweetalert2";

export default {
  name: "SupportNotification",
  data() {
    return {
      page: 1,
      last_page: 1,
      data: [
        {
          id: '',
          name: ''
        }
      ],
      checkVisibleAddEditForm: false,
      form: {
        id: '',
        name: ''
      },
      form_add: false,
      dialogUploadFile: false,
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Hỗ trợ"},
      {title: "Quản lý thông báo"}
    ]);
  },
  methods: {
    edit(id) {
      this.form_add = false;
      this.form.id = id;
      this.$store.dispatch(SHOW_NOTIFICATION, id).then((res) => {
        this.form.name = res.data.name;
        this.checkVisibleAddEditForm = true;
      });
    },
    create() {
        this.$store.dispatch(CREATE_NOTIFICATION, {name: this.form.name}).then((res) => {
          this.$bvToast.toast('Thêm mới thông báo thành công', {
            title: 'Thêm mới',
            variant: 'success',
            solid: true
          });
          this.fetchData();
          this.checkVisibleAddEditForm = false;
        });
    },

    update() {
        let payload = {id: this.form.id, name: this.form.name};
        this.$store.dispatch(UPDATE_NOTIFICATION, payload).then((res) => {
          this.$bvToast.toast('Sửa danh mục thành công', {
            title: 'Chỉnh sửa',
            variant: 'success',
            solid: true
          });
          this.fetchData();
          this.checkVisibleAddEditForm = false;
        });
    },
    clickCallback(obj) {
      this.page = obj;
      this.fetchData();
    },
    deleteNotification(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_NOTIFICATION, id).then((res) => {
            this.fetchData();
          }).catch(error => {
            this.$bvToast.toast(error.message, {
              title: 'Có lỗi xảy ra',
              variant: 'error',
              solid: true
            });
          });
        }
      });
    },
    addnew() {
      this.form = {}
      this.checkVisibleAddEditForm = true;
      this.form_add = true
    },
    fetchData() {
      this.$store.dispatch(GET_NOTIFICATION, {
            page: this.page,
            name : this.query_name,
            category_id:this.category_id,
        }).then((res) => {
            this.data = res.data.data;
            this.total_pages = res.data.last_page;
        });
        this.$store.dispatch(GET_NOTIFICATION, {page: this.page}).then((res) => {
          this.data = res.data.data;
          this.last_page = res.pagination.last_page;
        }).catch(error => {
          this.$bvToast.toast(error.message, {
            title: 'Có lỗi xảy ra',
            variant: 'error',
            solid: true
          });
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}

</script>
