<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="course_create" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Sửa chính sách cơ sở
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'mechanism-basis-index' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Tiêu đề/Danh mục
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Tiêu đề, danh mục" rules="required"
                                    v-slot="{ errors,classes }">
                  <input type="text" ref="name" name="name" class="form-control"
                         v-model="mechanism.category"
                         placeholder="Tiêu đề, danh mục" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Số thứ tự
                  <span class="text-danger"></span></label>
                <input type="number" ref="name" name="name" class="form-control"
                       v-model="mechanism.stt"
                       placeholder="Số thứ tự">

              </div>
            </div>

          </div>
          <div class="row mb-4">
            <div class="col-md-6">
              <div class="checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input type="checkbox" name="Checkboxes5" v-model="mechanism.is_all">
                  <span></span>Tất cả thành viên Edutalk có thể xem</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="!mechanism.is_all">
            <div class="col-md-4">
              <div class="form-group">
                <label>Phân quyền xem
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Phân quyền xem" rules="required" v-slot="{ errors,classes }">
                  <el-select v-model="cacheAccountType" @change="updateStateAccountType()" filterable multiple class="w-100" placeholder="Loại tài khoản" clearable>
                    <el-option
                        v-for="item in accountType"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Cấp bậc</label>
                <el-select v-model="levelSelect" filterable multiple class="w-100" placeholder="Cấp bậc" clearable>
                  <el-option
                      v-for="item in levels"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Khu vực</label>
                <el-select v-model="areaSelect" filterable multiple class="w-100" placeholder="Khu vực" clearable>
                  <el-option
                      v-for="item in areas"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-4" v-if="checkDepartment">
              <div class="form-group">
                <label>Phòng ban</label>
                <el-select v-model="departmentSelect" multiple filterable class="w-100" placeholder="Phòng ban" clearable>
                  <el-option
                      v-for="item in departments"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-4">
              <div class="checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input type="checkbox" name="Checkboxes5" v-model="mechanism.status">
                  <span></span>Hiển thị</label>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="form-group mb-1 col-md-12">
              <label>
                Kiểu thêm nội dung
                <span class="text-danger">*</span>
              </label>
              <div class="col-md-12 p-0">
                <ValidationProvider vid="typeContent" name="Kiểu thêm nội dung" rules="required"
                                    v-slot="{ errors,classes }">
                  <el-select v-model="mechanism.type_content" placeholder="Chọn kiểu thêm nội dung">
                    <el-option
                        v-for="(item, index) in listTypeContent"
                        :key="index"
                        :value="item.id"
                        :label="item.name">

                    </el-option>
                  </el-select>

                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group mb-1 col-md-12" v-if="mechanism.type_content == 0">
              <label>Nội dung
                <span class="text-danger">*</span></label>
              <ValidationProvider vid="content" name="Nội dung" rules="required"
                                  v-slot="{ errors,classes }">
                <ckeditor :editor="editor" v-model="mechanism.content"></ckeditor>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                      errors[0]
                    }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="form-group mb-1 col-md-12" v-if="mechanism.type_content == 1">
              <label>Liên kết
                <span class="text-danger">*</span>
              </label>
              <div class="col-md-12 p-0">
                <ValidationProvider vid="content" name="Liên kết" rules="required"
                                    v-slot="{ errors,classes }">
                  <textarea name="" id="" class="col-12 border" v-model="mechanism.content" placeholder="Dán liên kết tại đây">
                  </textarea>

                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>

              </div>
            </div>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button
                type="submit"
                class="btn btn-success mr-2"
            >
              Cập nhật
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>
import ApiService from "@/core/services/api.service";

import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_MECHANISM_BY_ID, UPDATE_MECHANISM} from "../../../core/services/store/system/mechanism.module";
import {GET_ACCOUNT_TYPE} from "../../../core/services/store/user/account-type.module";
import Multiselect from 'vue-multiselect'
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
//editor
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";
import {GET_LIST_AREAS} from "@/core/services/store/center/area.module";
import {GET_BUSINESS_LEVELS} from "@/core/services/store/system/information.module";
import { LIST_TYPE_CONTENT } from "../../../core/services/store/system/mechanism.module";
import {STAFF, BOD, TEAMLEADER, HEAD_OF_DEPARTMENT} from '../../../core/option/accountTypeOption';
import {ALL_DEPARTMENT} from '../../../core/services/store/department/department.module';

export default {
  components: {
    Multiselect,
    DatePicker
  },
  name: "MechanismEdit",
  data() {
    return {
      //Editor
        editor: CustomEditor,
        // Check show
        is_show_course_type: false,
        is_show_level: false,
        mechanism: {
            content: '',
            type: '',
            status: true,
            publish: '',
            created_user: '',
            updated_user: '',
            category: '',
            stt: 1,
            id_parent: '',
            slug: '',
            start_date: '',
            end_date: '',
            is_all: false,
            type_system_information: '',
            link: '',
            items: '',
            roles: [],
            mechanism_type: 2,
            type_content: '',
            department_id: ''
        },

        accountType: [],

        // Cache
        cacheAccountType: [],
        money: {
            decimal: ',',
            thousands: ',',
            prefix: '',
            suffix: ' VNĐ',
            precision: 0,
            masked: false,
        },
        levelSelect: [],
        levels: [],
        areaSelect: [],
        areas: [],
        listTypeContent: LIST_TYPE_CONTENT,
        typeContent: '',
        errorLink: '',
        checkDepartment: false,
        departments: {},
        departmentSelect: [],

    }
  },
  mounted() {
    this.getAccountType()
    this.getMechanismById()
    this.getListArea()
    this.getListDepartment()
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý chính sách cơ sở", route: 'profile-1'},
      {title: "Sửa chính sách cơ sở"}
    ]);
  },
  computed: {},
  methods: {
    getListArea() {
      this.$store.dispatch(GET_LIST_AREAS, {}).then(res => {
        if (!res.error) {
          this.areas = res.data;
        }
      })
    },
    getBusinessLevel() {
      this.$store.dispatch(GET_BUSINESS_LEVELS, {
        account_type_ids: this.cacheAccountType.length > 0 ? this.cacheAccountType : [0],
      }).then((res) => {
        if (!res.error) {
          this.levels = res.data
          var updateLevelSelect = []
          _.forEach(this.levelSelect, (value, key) => {
            let check = this.levels.find(x => x.id == value);
            if (check) {
              updateLevelSelect.push(value)
            }
          });
          this.levelSelect = updateLevelSelect
        }
      });
    },
    getMechanismById() {
      let id = this.$route.params.id;
      this.$store.dispatch(GET_MECHANISM_BY_ID, id).then((res) => {
        if (!res.error) {
            if (res.data.department_id) {
                this.checkDepartment = true;
                this.departmentSelect = Object.values(res.data.department_id).length && res.data.department_id[0] != 0 ? res.data.department_id : [];
            }
            this.mechanism = res.data
            this.setCacheAccountType(res.data)
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    setCacheAccountType(mechanism) {
      this.cacheAccountType = mechanism.roles
      this.levelSelect = mechanism.business_level_select
      this.areaSelect = mechanism.area_select
      this.getBusinessLevel()
    },
    updateStateAccountType() {
        let decentali = [STAFF, BOD, TEAMLEADER, HEAD_OF_DEPARTMENT];
        const check = this.cacheAccountType.some(r=> decentali.includes(r))
        this.checkDepartment = false;

        if (check) {
            this.checkDepartment = true;
        }
        this.getBusinessLevel()
    },
    getAccountType() {
      this.$store.dispatch(GET_ACCOUNT_TYPE, {
        limit: 1000,
      }).then((res) => {
        if (!res.error) {
          this.accountType = res.data.data
        }
      });
    },
    handleImageDraged: function (file, Editor, cursorLocation, oldContents) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      var formData = new FormData();
      formData.append("image", file);

      ApiService.post('upload-image', formData)
          .then(({data}) => {
            let url = data.data; // Get url from response
            Editor.setContents(oldContents);
            Editor.insertEmbed(cursorLocation, "image", url);
          })
          .catch(err => {
            console.log(err);
          });
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("image", file);

      ApiService.post('upload-image', formData)
          .then(({data}) => {
            let url = data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch(err => {
            console.log(err);
          });
    },
    onSubmit() {
      this.mechanism.areas = this.areaSelect
      this.mechanism.business_level = this.levelSelect
      this.mechanism.roles = this.cacheAccountType
      this.mechanism.department_id = this.departmentSelect

      this.$store.dispatch(UPDATE_MECHANISM, this.mechanism).then((res) => {
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Cập nhật',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$router.push({name: "mechanism-basis-index"}).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Cập nhật',
              variant: 'success',
              solid: true
            });
          })
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      });
    },
    getListDepartment() {
        this.$store.dispatch(ALL_DEPARTMENT).then((res) => {
            if (!res.error) {
                this.departments = res.data
            }
        }).catch((e) => {
            console.log(e, 'error')
        });
    },
  }
}
</script>
<style scoped>
.mx-datepicker {
  width: 100% !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
