<template>
  <div class="example-preview table-responsive">
    <table class="table table-vertical-center">
      <thead>
      <tr>
        <th style="min-width: 50px">STT</th>
        <th style="min-width: 150px">Số điện thoại</th>
        <th>Đối tượng gửi</th>
        <th style="min-width: 200px">Tên lỗi</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in listErrors" :key="item.index + item.phone">
        <td>{{ index + 1 }}</td>
        <td>{{ item.phone }}</td>
        <td>{{ item.target }}</td>
        <td>{{ item.errors }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    listErrors: {
      type: Array,
      required: true,
      default: []
    }
  }
}
</script>

<style lang="scss" scoped>

</style>