<template>
    <div class="col-md-6 p-0">
        <div :class="`${getTime() ? 'bg-lesson' : ''} day d-flex justify-content-between`">
            <div v-if="comingLesson" class="studying ">
                <p class="studying-title">
                    Lớp đang học tới
                </p>
            </div>
            <div class="day-left"> <b>Buổi {{ lesson.lesson }} <span
                        :class="`${lesson.type == 'homework' ? 'd-none' : (lesson.type == 'test' ? '' : 'd-none')} check-day`">Kiểm tra</span></b>

                <p >{{ lesson.date_only }} ({{ lesson.start_time }} - {{ lesson.end_time }})</p>
            </div>
            <div class="">
                <el-dropdown v-if="!(lesson.date > now_date && lesson.type == 'test') " @command="handleCommand">
                    <el-button :class="`${getTime() ? 'action-bg' : ''}`">
                        Hành động <i class="el-icon-arrow-down el-icon--right down-icon-action action"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-if="lesson.date <= now_date" command="attendance-lesson" class="hover-dropitem text-center dark">
                            <div class="w-100">
                                Điểm danh
                            </div>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="lesson.type !== '' && lesson.type !== 'test' && lesson.course_schedule_type != 1" command="content-homework" class="hover-dropitem text-center">
                            <div class="w-100">
                                Xem đề BTVN
                            </div>                
                        </el-dropdown-item>
                        <el-dropdown-item v-if="lesson.type !== 'first' && lesson.type !== 'end' && lesson.type !== 'test' && lesson.course_schedule_type != 1" command="give-gift" class="hover-dropitem text-center">
                            <div class="w-100">
                                Tặng quà
                            </div>                
                        </el-dropdown-item>
                        <el-dropdown-item v-if="(lesson.type !== 'first' && lesson.type !== 'end' && lesson.type !== 'test') || lesson.course_schedule_type == 1" command="comment" class="hover-dropitem text-center">                           
                            <div class="w-100">
                                Nhận xét
                            </div>                
                        </el-dropdown-item>
                        <el-dropdown-item v-if="lesson.type !== 'test' && lesson.course_schedule_type != 1" command="show-homework" class="hover-dropitem text-center">
                            <div>
                                Chấm BTVN
                            </div>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="lesson.type !== 'test'" command="content" class="hover-dropitem text-center">
                            <div>
                              Nội dung bài giảng
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    props: {
        lesson: Object,
        lessonList: Array
    },

    data() {
        return {
            isDisable: '',
            comingLesson: false,
            dateOnlyNextLesson: null,
            now_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            greaterComingLesson : false,
            indexDate: 0
        }
    },

    computed: {
    },
    mounted() {
        this.checkNextLesson();
    },

    watch: {
        lesson: {
            handler() {
                if (this.lesson.type !== 'tutoring') {
                    let matchLesson = this.lessonList.findLast(item => new Date(item.date) < new Date());
                    let matchIndex = this.lessonList.findLastIndex(item => new Date(item.date) < new Date());
                    this.greaterComingLesson = this.lesson.lesson > matchIndex ? true : false;
                    this.comingLesson = matchLesson && matchLesson.learning_schedule_id === this.lesson.learning_schedule_id && matchIndex !== this.lessonList.length - 1;
                }
            },
            immediate: true,
            deep: true
        },
    },

    methods: {
        checkNextLesson() {
            this.lessonList.map((value) => {
                if (value.lesson == this.lesson.lesson + 1) {
                    this.dateOnlyNextLesson = value.date;
                }
            })
            if (this.dateOnlyNextLesson) {
                this.dateOnlyNextLesson = moment(this.dateOnlyNextLesson).format("YYYY-MM-DD HH:mm:ss");
            }
        },

        getTime() {
            return new Date(this.lesson.date) < new Date();
        },
        handleCommand(command) {
            let lession_id = this.lesson.lesson;
            let starTimeDate = this.lesson.date_only;
            let starTime = this.lesson.start_time;
            if(command == 'show-homework') {
                this.$emit('mark-homeWork', lession_id, this.lesson.learning_schedule_id,this.lesson.center_id)
            }

            if(command == 'attendance-lesson') {
                this.$emit('attendance-show', this.lesson, this.greaterComingLesson)
            }
            
            if(command == 'content-homework') {
                const routeData = this.$router.resolve({ name: "content-homework",params: {id : this.lesson.lesson},
                 query: { classroom_id: this.$route.params.id, learning_schedule_id:this.lesson.learning_schedule_id  }});
                 window.open(routeData.href, '_blank');
            }
            if(command == 'give-gift') {
                this.$emit('give-gift', this.lesson)
            }

            if(command == 'comment') {
                this.$emit('comment', this.lesson)
            }
            if(command == 'content') {
                this.$emit('content', this.lesson)
            }
        },
        changeDates(dates, lesson) {
            let text = '';


            console.log(dates, lesson, 'dates')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/custom/custom-teacher-edu.scss";

.mx-datepicker {
    width: 100% !important;
}

el-dialog {
    margin-top: 3vh !important;
}

.el-popper[x-placement^=bottom] {
    margin-top: -11px;
    width: 182px;
    // height: 138px;
}

</style>