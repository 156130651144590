import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const GET_LIST_BOND_MANAGEMENT = "staff/bonds";
export const GET_BOND_DETAIL  = "staff/bonds/detail-by-milestone";
export const STAFF_PREVIEW_BOND = "staff/bonds/preview-bond";
export const STAFF_BOND_IMPORT_SOLD = "staff/bonds/import-sold";
export const STAFF_BOND_IMPORT = "staff/bonds/import-bond";
export const STAFF_PREVIEW_BOND_SOLD = "staff/bonds/preview-sold"
export const GET_BOND_HISTORY = "staff/bonds/histories"



const state = {};

const getters = {};
const actions = {
    [GET_LIST_BOND_MANAGEMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LIST_BOND_MANAGEMENT}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [GET_BOND_DETAIL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_BOND_DETAIL}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [GET_BOND_HISTORY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_BOND_HISTORY}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [STAFF_PREVIEW_BOND_SOLD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(STAFF_PREVIEW_BOND_SOLD, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [STAFF_BOND_IMPORT_SOLD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(STAFF_BOND_IMPORT_SOLD, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [STAFF_PREVIEW_BOND](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(STAFF_PREVIEW_BOND, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [STAFF_BOND_IMPORT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(STAFF_BOND_IMPORT, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

};


const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};