<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách loại khóa học</h3>
      </div>
      <div class="card-title">
        <b-button id="course_type_create" class="btn btn-success" @click="clickCourseTypeCreate">Thêm mới</b-button>
      </div>
    </div>
    <div class="card-body">
      <!--begin::Example-->
      <div class="example mb-10">
        <div class="row">
          <div class="form-group col-md-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text line-height-0 py-0">
                  <span class="svg-icon">
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Search.svg-->
                    <svg xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink"
                         width="24px" height="24px"
                         viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1"
                         fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24"
                              height="24"></rect>
                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000" fill-rule="nonzero"
                              opacity="0.3"></path>
                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000"
                              fill-rule="nonzero"></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <input type="text" class="form-control" placeholder="Tên loại khóa học" v-model="query.keyword" @input="searchByName" aria-describedby="basic-addon2">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <multiselect v-model="query.center" :options="centers"
                 placeholder="Tìm trung tâm"
                 label="name"
                 track-by="name"
                 @input="handleChangeCenter"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <multiselect v-model="cache_status" :options="statuses"
                           placeholder="Chọn trạng thái"
                           label="name"
                           track-by="name"
                           @input="handleChangeStatus"
              ></multiselect>
            </div>
          </div>
          </div>
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th scope="col" class="min-w-200px">ID</th>
              <th scope="col" class="min-w-200px">Tên loại khóa học</th>
              <th scope="col" class="min-w-200px">Thứ tự trong combo</th>
              <th scope="col" class="min-w-200px">Trung tâm</th>
              <th scope="col" class="min-w-200px">Trạng thái</th>
              <th scope="col" class="min-w-200px">Hành động</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in courseTypes.data" :key="index" v-if="item.status !== 4">
              <td>{{item.id}}</td>
              <td>{{item.name ? item.name : ''}}</td>
              <td>{{item.order}}</td>
              <td>{{ getCenter(item) }}</td>
              <td>
                <a href="javascript:" class="badge" v-bind:class="item.status === 1 ? classSpan.success : classSpan.danger">
                  {{item.status === 1 ? "Hiển thị" : "Ẩn"}}
                </a>
              </td>
              <td>
                <a title="Sửa" @click="editCourseType(item.id)" href="javascript:" class="btn btn-sm btn-icon mr-2 btn-outline-info" v-if="item.center_id"><i class="fas fa-pen-nib"></i></a>
                <a v-if="item.center_id" title="Xóa" @click="deleteCourseType(item.id)" href="javascript:" class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                <router-link title="Xem chi tiết trình độ" :to="{ name: 'course-level-list', query: { course_type_id: item.id, center_id : item.center_id || center_id_query }}" class="ml-2 badge badge-success">Chi tiết trình độ</router-link>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>
      <div class="edu-paginate mx-auto text-center">
        <paginate
                v-model="page"
                :page-count="last_page"
                :page-range="3"
                :margin-pages="2"
                :click-handler="clickPagination"
                :prev-text="'Trước'"
                :next-text="'Sau'"
                :container-class="'pagination b-pagination'"
                :pageLinkClass="'page-link'"
                :page-class="'page-item'"
                :next-link-class="'next-link-item'"
                :prev-link-class="'prev-link-item'"
                :prev-class="'page-link'"
                :next-class="'page-link'"
        >
        </paginate>
      </div>
      <course-type-create :showModal="showModalCourseType" :courseType="courseType" :check_edit="check_edit" @clicked="onclickCourseType"></course-type-create>
    </div>
<!--  </div>-->
</template>
<script>
    import Swal from "sweetalert2";
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {
        DELETE_COURSE_TYPES,
        GET_COURSE_TYPE_BY_ID,
        GET_COURSE_TYPES
    } from "../../../core/services/store/course/courseType.module";
    import Multiselect from 'vue-multiselect'
    import {mapGetters} from "vuex";
    import CourseTypeCreate from './Course-Type-Create.vue';
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";

    const _ = require('lodash');

    export default {
        components: {
            Multiselect,
            "course-type-create": CourseTypeCreate,
        },
        name: "Course-Type-List",
        data() {
            return {
                query: {
                    keyword: '',
                    center: '',
                    status: '',
                },
                courseTypes: [],
                keyword: '',
                // Paginate
                page: 1,
                last_page: 1,
                centers: [],
                statuses: [{name : 'Hiển thị', value: 1}, {name: 'Ẩn', value: 0}],
                classSpan: {
                    success: 'badge-success',
                    danger: 'badge-danger'
                },
                showModalCourseType: false,
                courseType: null,
                check_edit: false,
                center:[],
                cache_status : '',
                center_id_query :''
            }
        },
        computed: {
            ...mapGetters(["getAllCourseTypes","getCourseTypePaginate"]),
            total_pages() {
                return this.getCourseTypePaginate?.total_pages || 0
            },
        },
        mounted() {
            this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
            this.query.keyword = this.$route.query.keyword ? this.$route.query.keyword : '';
            this.query.status = this.$route.query.status ? this.$route.query.status : '';
            this.center_id_query = this.$route.query.center_id ? this.$route.query.center_id : '';
            this.cache_status = this.statuses.find((status) => {
              return status.value === parseInt(this.query.status);
            });
            this.fetchData();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý loại khóa học", route: 'course-type'},
                {title: "Danh sách loại khóa học"}
            ]);
        },
        methods: {
            getListCourseType() {
                this.$store.dispatch(GET_COURSE_TYPES, {
                    name: this.query.keyword,
                    page: this.page,
                    center_id: this.query.center.id,
                    status: this.query.status,
                }).then((res) => {
                    this.courseTypes = res.data;
                    this.last_page = res.data.last_page;
                }).catch((res)=>{
                    console.log(res);
                });
            },
            async fetchData() {
                await this.getCenters();
                await this.getListCourseType();
            },
            async getCenters() {
                await this.$store.dispatch(GET_CENTERS, {
                    limit: 10000,
                    status: 1
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data;
                        if (this.$route.query.center_id) {
                            let center_select = this.centers.filter((value) => {
                                if (parseInt(this.$route.query.center_id) === value.id) {
                                    return value;
                                }
                            });
                            this.query.center = center_select[0];
                        }
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            clickPagination(page) {
                this.page = page;
                this.$router.push({name: 'course-type-list', query: {keyword: this.query.keyword, center_id: this.query.center.id, status : this.query.status, page: this.page}});
                this.getListCourseType();
            },
            handleChangeCenter(center_select) {
                if (center_select) {
                  this.center_id_query = center_select.id;
                } else {
                    this.query.center = [];
                }
                this.$router.push({name: 'course-type-list', query: {keyword: this.query.keyword, center_id: this.query.center.id, status : this.query.status, page: this.page}});
                this.getListCourseType();
            },
          handleChangeStatus(event) {
                this.query.status = event != null ? event.value : '';
                this.$router.push({name: 'course-type-list', query: {keyword: this.query.keyword, center_id: this.query.center.id, status : this.query.status, page: this.page}});
                this.getListCourseType();
            },
            searchByName() {
                this.$router.push({name: 'course-type-list', query: {keyword: this.query.keyword, center_id: this.query.center.id, status : this.query.status, page: this.page}});
                this.getListCourseType();
            },
            getCenter(item) {
                if (item.center_id) {
                    let res = this.centers.filter((value) => {
                        if (value.id === item.center_id) {
                            return value;
                        }
                    });
                    return res[0]?.name || ''
                }
            },
            deleteCourseType(id) {
                Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(DELETE_COURSE_TYPES, id).then((res) => {
                            if (res.error) {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'danger',
                                    solid: true
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'success',
                                    solid: true
                                });
                                this.getListCourseType();
                            }
                        }).catch((res) => {
                            this.$bvToast.toast(res.data.message, {
                                title: 'Xóa',
                                variant: 'danger',
                                solid: true
                            });
                        });
                    }
                });
            },
            onclickCourseType() {
                this.showModalCourseType = false;
                this.check_edit = false;
            },
            clickCourseTypeCreate() {
                this.showModalCourseType = true;
            },
            editCourseType(id) {
                if (id) {
                    this.$store.dispatch(GET_COURSE_TYPE_BY_ID, id).then((res) => {
                        if (!res.error) {
                            this.courseType = res.data;
                            this.showModalCourseType = true;
                            this.check_edit = true;
                        }
                    });
                }
            }
        }
    }
</script>
<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
