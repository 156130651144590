<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Chấm công</h3>
      </div>
      <div class="card-title" v-if="currentUser.user.permission.includes(permission.BAO_CAO)">
        <div>
          <div class="my-3">
            <!-- Our triggering (target) element -->
            <b-button id="popover-reactive-1" variant="primary" ref="button" class="mr-2">
              <i class="la la-file-excel"></i> Xuất google sheet
            </b-button>
          </div>
          <!-- Output from the popover interaction -->
          <b-popover
              target="popover-reactive-1"
              triggers="click"
              :show.sync="popoverShow"
              placement="auto"
              container="my-container"
              ref="popover"
              @show="onShow"
              @hidden="onHidden"
          >
            <template #title>
              <b-button @click="onClose" class="close" aria-label="Close">
                <span class="d-inline-block" aria-hidden="true">&times;</span>
              </b-button>
              Dánh sách thư mục
            </template>

            <div>
              <b-form-group
                  label-for="popover-input-1"
                  label-cols="3"
                  :state="validateState"
                  class="mb-1"
                  invalid-feedback="Không được để trống"
                  size="lg"
              >
                <b-form-select
                    id="popover-input-1"
                    v-model="folderData"
                    :state="validateState"
                    :options="options"
                    size="sm"
                    class="mb-3"
                ></b-form-select>
              </b-form-group>
              <b-button @click="onClose" size="sm" variant="danger">Cancel</b-button>
              <b-button @click="onOk" size="sm" variant="primary" class="ml-2">Ok</b-button>
            </div>
          </b-popover>
          <button v-if="is_export_sheet" disabled
                  class="btn btn-primary font-weight-bold mr-2 spinner spinner-white spinner-left">
            Xuất google sheet
          </button>
        </div>
        <div>
          <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcel">
            <i class="la la-file-excel"></i> Xuất excel
          </button>
          <button v-else disabled
                  class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
            Xuất excel
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label>Chọn khoảng thời gian
                <span class="text-danger"></span></label>
              <date-range-picker
                  v-model="dateRange"
                  :auto-apply="false"
                  :locale-data="localData"
                  @update="selectDate"
              ></date-range-picker>
            </div>
            <!--end::Form-->
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Thông tin nhân sự
                <span class="text-danger"></span></label>
              <input type="text" v-model="query.keyword" class="form-control"
                     placeholder="Tên, SĐT, Mã nhân sự" v-on:input="searchByKeyWord">
            </div>
            <!--end::Form-->
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Chọn khu vực
                <span class="text-danger"></span></label>
              <multiselect v-model="cache_area" :options="areas"
                           placeholder="Chọn khu vực"
                           label="name"
                           track-by="name"
                           @input="selectArea($event)"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>Chọn chi nhánh
                <span class="text-danger"></span></label>
              <multiselect v-model="cache_branch" :options="branches"
                           placeholder="Chọn chi nhánh"
                           :custom-label="({ name_alias, name }) => `${name_alias ? name_alias : name}`"
                           track-by="name"
                           @input="selectBranch($event)"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-2 mt-8">
            <div class="d-flex justify-content-between">
              <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                      @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
              </button>
              <button v-else type="button" disabled
                      class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center table-hover">
            <thead>
            <tr>
              <th scope="col" class="min-w-100px">Mã nhân sự</th>
              <th scope="col" class="min-w-100px">Tên nhân sự</th>
              <th scope="col" class="min-w-100px">Chức vụ</th>
              <th scope="col" class="min-w-100px">Tên văn phòng</th>
              <th scope="col" class="min-w-100px">Số ca đăng kí đi làm</th>
              <th scope="col" class="min-w-120px">Tổng công</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in contracts.data" :key="index">
              <td>{{item.id + 100000 }}</td>
              <td>{{item.name}}</td>
              <td>{{item.profile.account_type ? item.profile.account_type.name : ''}}</td>
              <td>{{item.profile.branch ? item.profile.branch.name_alias : ''}}</td>
              <td>{{ item.regist }}</td>
              <td>{{ item.attendance }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="7"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>

    <div class="vld-parent">
      <loading :active.sync="isLoading"
               :can-cancel="true"
               :on-cancel="onCancel"
               :is-full-page="fullPage"></loading>
    </div>
  </div>
</template>
<script>
    import {mapGetters} from "vuex";
const _ = require('lodash');
import Multiselect from 'vue-multiselect';
// Datetime picker
import DateRangePicker from 'vue2-daterange-picker';
import Loading from 'vue-loading-overlay';
/* css */
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'vue-loading-overlay/dist/vue-loading.css';
import {
  EXPORT_SALES_TIME_KEEP,
  EXPORT_SALES_TIME_KEEP_GOOGLE_SHEET,
  GET_SALES_ON_WORK
} from "../../../core/services/store/report/report.module";
import {GET_FOLDERS_DRIVERS} from "../../../core/services/store/public/google.module";
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import {GET_AREAS} from "../../../core/services/store/center/area.module";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {exportExcel} from "../../../core/filters";

/*End*/

export default {
  components: {
    Multiselect,
    Loading,
    DateRangePicker
  },
  name: "TimeKeep",
  data() {
    return {
      /* date range picker */
      localData : {
        direction: 'ltr',
        format: 'dd-mm-yyyy',
        separator: ' ~ ',
        applyLabel: 'Đồng ý',
        cancelLabel: 'Hủy bỏ',
        weekLabel: 'Tuần',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
        monthNames: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
        firstDay: 0,
      },
      opens: 'center',
      dateRange: {},

      /*Loader */
      isLoading : false,
      fullPage : true,
      /* popover */
      path : '',
      showPop: false,
      folderData: '',
      validateState: null,
      options: [],
      popoverShow: false,
      /*Show modal edit*/
      cache_status: '',
      cache_current_account_type: '',
      cache_branch: '',
      cache_area: '',
      center_id: '',
      /*End*/
      /* options  */
      branches : [],
      branchesByCenter : [],
      areas : [],
      is_disable_search: false,
      is_export_excel: false,
      is_export_sheet: false,
      contracts: [],
      // Paginate
      page: 1,
      last_page: 1,
      query:{
        keyword: '',
        area_id: '',
        branch_id: '',
        startDate : '',
        endDate : '',
        account_type : ['sale-intern','sale-member']
      }
      ,
      payload: {
        limit: 1000000,
        status: 1,
        is_with : 0,
        area_id: ''
      },
    }
  },
  computed: {
      ...mapGetters(["currentUser"]),
  },
  watch: {
    folderData(val) {
      if (val) {
        this.validateState = true;
        this.path = val;
      }
    },
  },
  async mounted() {
    this.setDateRangeFromUrl();
    this.getFolders();
    await this.getList()
    await this.getAreas();
    await this.getBranches();
    await this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý nhân sự"},
      {title: "Chấm công"}
    ]);
  },
  methods: {
    onCancel :function () {
      console.log('User cancelled the loader.')
    },
    onClose() {
      this.popoverShow = false
    },
    onOk() {
      if (!this.folderData) {
        this.validateState = false
      }
      if (this.folderData) {
        this.onClose()
        // Return our popover form results
        this.exportGoogleSheet();
      }
    },
    onShow() {
      this.folderData = ''
      this.validateState = null
    },
    onHidden() {
      this.focusRef(this.$refs.button)
    },
    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          ;(ref.$el || ref).focus()
        })
      })
    },
    getFolders: function () {
      this.$store.dispatch(GET_FOLDERS_DRIVERS).then((res) => {
        this.options = _.reduce(res.data, function (result,item,key) {
          result.push({
            text : item.name,
            value : item.path
          });
          return result;
        },[])
        this.options.unshift({ text: '- Chọn thư mục -', value: '' });
      });

    },
    getBranches:  function () {
      this.$store.dispatch(GET_BRANCHES, this.payload).then((res) => {
        this.branches = res.data.data;
        this.branchesByCenter = res.data.data;
        let branch_id = this.$route.query.branch_id ? this.$route.query.branch_id : '';
        this.cache_branch = _.find(this.branches, function (branch) {
          return branch.id === parseInt(branch_id);
        });
      });
    },
    getAreas:  function () {
      this.$store.dispatch(GET_AREAS, this.payload).then((res) => {
        this.areas = res.data.data;
        let area_id = this.$route.query.area_id ? this.$route.query.area_id : '';
        this.cache_area = _.find(this.areas, function (area) {
          return area.id === parseInt(area_id);
        });
      });
    },
    selectBranch: function ($event) {
      this.query.branch_id = $event != null ? $event.id : '';
      this.pushParamsUrl();
    },
    setDateParams : function (d){
      return d.getFullYear()  + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +("0" + d.getDate()).slice(-2);
    },
    selectArea: function ($event) {
      this.payload.area_id = $event != null ? $event.id : '';
      this.query.area_id = $event != null ? $event.id : '';
      this.$store.dispatch(GET_BRANCHES, this.payload).then((res) => {
        this.branches = res.data.data;
        this.branchesByCenter = res.data.data;
      });
      this.cache_branch = '';
      this.query.branch_id = '';
      this.pushParamsUrl();
    },
    selectDate: function ($event) {
      this.query.startDate = this.setDateParams(this.dateRange.startDate);
      this.query.endDate = this.setDateParams(this.dateRange.endDate);
      this.pushParamsUrl();
    },
    searchByKeyWord : function () {
      this.pushParamsUrl();
    },
    setDateRangeFromUrl() {
      let date = new Date();
      this.query.startDate = this.$route.query.startDate;
      this.query.endDate = this.$route.query.endDate;
      let endDate = this.query.endDate ?  new Date(this.query.endDate) : new Date();
      let startDate = this.query.startDate?  new Date(this.query.startDate) : new Date(date.getFullYear(), date.getMonth(), 1);
      endDate.setDate(endDate.getDate());
      this.dateRange = {startDate , endDate};
    },
    /*End*/
    getList() {
      this.pullParamsUrl()
      this.callApiGetList()
    },
    pullParamsUrl() {
      if (typeof this.$route.query.page == "string") {
        this.page = parseInt(this.$route.query.page)
      }
      this.query.keyword = this.$route.query.keyword;
      this.query.area_id = this.$route.query.area_id;
      this.query.branch_id = this.$route.query.branch_id;
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'sale-time-keep',
        query: {
          page: this.page,
          keyword: this.query.keyword,
          area_id: this.query.area_id,
          branch_id : this.query.branch_id,
          startDate : this.query.startDate,
          endDate : this.query.endDate,
        }
      })
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({
        name: 'sale-time-keep',
        query: {
          page: this.page,
          keyword: this.query.keyword,
          area_id: this.query.area_id,
          branch_id : this.query.branch_id,
          startDate : this.query.startDate,
          endDate : this.query.endDate,
        }
      })
      this.callApiGetList()
    },
    callApiGetList() {
      this.$store.dispatch(GET_SALES_ON_WORK, {
        page: this.page,
        keyword: this.query.keyword,
        branch_id : this.query.branch_id,
        area_id : this.query.area_id,
        begin_date : this.query.startDate,
        end_date : this.query.endDate,
        account_type : ['sale-intern','sale-member']
      }).then((res) => {
        this.contracts = res.data
        this.is_disable_search = false;
        this.last_page = parseInt(res.data.last_page);
      });
    },

    exportExcel() {
      this.is_export_excel = true
      const HEADER_ROW_EXPORT_TIME_KEEP= [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'id',
          value: 'Mã nhân sự',
        },
        {
          key: 'name',
          value: 'Họ và tên ',
        },
        {
          key: 'branch',
          value: 'Văn phòng',
        },
        {
          key: 'cong_online',
          value: 'Công online',
        },
        {
          key: 'cong_truyen_thong',
          value: 'Công truyền thông',
        },
        {
          key: 'branch_id',
          value: 'Mã văn phòng',
        },
        {
          key: 'total',
          value: 'Tổng công',
        },
        {
          key: 'status',
          value: 'Trạng thái làm việc',
        },
      ]
      this.$store.dispatch(EXPORT_SALES_TIME_KEEP, {
        keyword: this.query.keyword,
        branch_id : this.query.branch_id,
        area_id : this.query.area_id,
        begin_date : this.query.startDate,
        end_date : this.query.endDate,
        account_type : ['sale-intern','sale-member']
      }).then((res) => {
        exportExcel(HEADER_ROW_EXPORT_TIME_KEEP, res.data, 'chấm công sale.xlsx', true)
        this.is_export_excel = false
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        }
      });
    },
    exportGoogleSheet() {
      this.isLoading = true
      this.$store.dispatch(EXPORT_SALES_TIME_KEEP_GOOGLE_SHEET, {
        keyword: this.query.keyword,
        branch_id : this.query.branch_id,
        area_id : this.query.area_id,
        begin_date : this.query.startDate,
        end_date : this.query.endDate,
        account_type : ['sale-intern','sale-member'],
        path : this.path,
      }).then((res) => {
        this.isLoading = false
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Export google sheet',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$bvToast.toast(res.message, {
            title: 'Export google sheet',
            variant: 'success',
            solid: true
          });
        }
      }).catch((e) => {
        if (e.data.error) {
          this.$bvToast.toast(e.data.message, {
            title: 'Export google sheet',
            variant: 'danger',
            solid: true
          });
        }
      });
    },
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsUrl()
      this.callApiGetList()
    },
  }
}
</script>
<style>
.calendars {
  flex-wrap: unset !important;
}
#btn-export-excel {
  position: relative;
}
.col-form-label{
  display: none;
}
.vue-daterange-picker {
  width: 100%;
}

.reportrange-text[data-v-79d7c92a]  {
  text-align: center;
  padding: 8px 10px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
