<template>
    <el-row>
        <div class="d-flex flex-column align-items-center">
            <h3>Bạn đã gửi phần thưởng hộp quà may mắn tới</h3>
            <h3 class="mt-2 mb-3"><b>{{ nameMember }}</b></h3>
            <div>
                <img :src="publicPath + '/media/gift.png'" alt="">
            </div>
        </div>

        <span class="dialog-footer mt-5 d-flex align-items-center justify-content-end">
            <button class="btn btn-outline-default ml-2" @click="close" >Đóng</button>
        </span>
    </el-row>
</template>

<script>
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

const _ = deepdash(lodash);
export default {
    name: "Gift",
    props: {
        // id của team
        idMember : {
            type: Number,
            default: 0
        },// id của team
        nameMember : {
            type: String,
            default: null
        },
    },
    data() {
        return {
            publicPath: process.env.VUE_APP_BASE_URL,
            loading: false,
            selectMember: []
        }
    },
    methods: {
        summit() {

        },
        close: function () {
            this.$emit('close');
        },
    },
}
</script>

<style scoped>

</style>