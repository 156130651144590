<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách trình độ</h3>
      </div>
      <div class="card-title">
        <b-button id="course_type_create" class="btn btn-success" @click="clickCourseLevelCreate">Thêm mới</b-button>
      </div>
    </div>
    <div class="card-body">
      <!--begin::Example-->
      <div class="example mb-10">
        <div class="row">
          <div class="form-group col-md-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text line-height-0 py-0">
                  <span class="svg-icon">
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Search.svg-->
                    <svg xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink"
                         width="24px" height="24px"
                         viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1"
                         fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24"
                              height="24"></rect>
                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000" fill-rule="nonzero"
                              opacity="0.3"></path>
                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000"
                              fill-rule="nonzero"></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <input type="text" class="form-control" placeholder="Tên trình độ" v-model="query.keyword" @input="searchByName" aria-describedby="basic-addon2">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <multiselect v-model="cache_center" :options="centers"
                 placeholder="Tìm trung tâm"
                 label="name"
                 track-by="name"
                 @input="handleChangeCenter"
              ></multiselect>
            </div>
          </div>
          </div>
        <div class="example-preview table-responsive overflow-auto">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th scope="col" class="min-w-200px">ID</th>
              <th scope="col" class="min-w-200px">Tên trình độ</th>
              <th scope="col" class="min-w-200px">Trình độ</th>
              <th scope="col" class="min-w-200px">Loại khóa học</th>
              <th scope="col" class="min-w-200px">Trung tâm</th>
              <th scope="col" class="min-w-200px">Trạng thái</th>
              <th scope="col" class="min-w-200px">Hành động</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in courseLevels.data" :key="index">
              <td>{{item.id}}</td>
              <td>{{item.name}}</td>
              <td>{{item.order}}</td>
              <td>{{ (item.course_type && item.course_type.name) ? item.course_type.name : '' }}</td>
              <td>{{ (item.center && item.center.name) ? item.center.name : '' }}</td>
              <td>
                <a href="javascript:" class="badge" v-bind:class="(item.status === 1  || item.status === true) ? classSpan.success : classSpan.danger">
                  {{(item.status === 1  || item.status === true) ? "Hiển thị" : "Ẩn"}}
                </a>
              </td>
              <td>
                <a title="Sửa" @click="editCourseLevel(item.id)" href="javascript:" class="btn btn-sm btn-icon mr-2 btn-outline-info"><i class="fas fa-pen-nib"></i></a>
                <a v-if="item.center_id" title="Xóa" @click="deleteCourseLevel(item.id)" href="javascript:" class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        </div>
      </div>
      <div class="edu-paginate mx-auto text-center">
        <paginate
                v-model="page"
                :page-count="last_page"
                :page-range="3"
                :margin-pages="2"
                :click-handler="clickPagination"
                :prev-text="'Trước'"
                :next-text="'Sau'"
                :container-class="'pagination b-pagination'"
                :pageLinkClass="'page-link'"
                :page-class="'page-item'"
                :next-link-class="'next-link-item'"
                :prev-link-class="'prev-link-item'"
                :prev-class="'page-link'"
                :next-class="'page-link'"
        >
        </paginate>
      </div>
      <course-level-form :centerId="center_id_query" :showModal="showModalCourseLevel" :courseLevel="courseLevel" :allCourseType="allCourseType" :course_type_id="query.course_type_id" :check_edit="check_edit" @clicked="onclickCourseLevel"></course-level-form>
    </div>
<!--  </div>-->
</template>
<script>
    import Swal from "sweetalert2";
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import { DELETE_COURSE_LEVELS, GET_COURSE_LEVEL_BY_ID, GET_COURSE_LEVELS } from "../../../core/services/store/course/courseLevel.module";
    import Multiselect from 'vue-multiselect'
    import {mapGetters} from "vuex";
    import CourseLevelForm from './Course-Level-Form.vue';
    import {GET_ALL_COURSE_TYPE} from "../../../core/services/store/course/courseType.module";
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";

    const _ = require('lodash');

    export default {
        components: {
            Multiselect,
            CourseLevelForm
        },
        name: "Course-Level-List",
        data() {
            return {
                query: {
                    keyword: '',
                    center_id: '',
                    course_type_id: '',
                },
                courseLevels: [],
                keyword: '',
                // Paginate
                page: 1,
                last_page: 1,
                centers: [],
                classSpan: {
                    success: 'badge-success',
                    danger: 'badge-danger'
                },
                showModalCourseLevel: false,
                courseLevel: null,
                check_edit: false,
                center: [],
                cache_center: '',
                allCourseType: [],
                center_id_query: '',
            }
        },
        mounted() {
            this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
            this.query.keyword = this.$route.query.keyword ? this.$route.query.keyword : '';
            this.query.course_type_id = this.$route.query.course_type_id ? this.$route.query.course_type_id : '';
            this.query.center_id = this.$route.query.center_id ? this.$route.query.center_id : '';
            this.getCenters();
            this.getAllCourseType();
            this.getListCourseLevel();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý trình độ", route: 'course-level'},
                {title: "Danh sách trình độ"}
            ]);
        },
        computed: {
          ...mapGetters(["getAllCourseLevels","getCourseLevelPaginate"]),
          total_pages() {
            return this.getCourseLevelPaginate?.total_pages || 0
          },
        },
        methods: {
            getListCourseLevel() {
                this.$store.dispatch(GET_COURSE_LEVELS, {
                    name: this.query.keyword,
                    page: this.page,
                    center_id: this.query.center_id,
                    course_type_id: this.query.course_type_id,
                }).then((res) => {
                    this.courseLevels = res.data;
                    this.last_page = res.data.last_page;
                }).catch((res)=>{
                    console.log(res);
                });
            },
            async getCenters() {
                await this.$store.dispatch(GET_CENTERS, {
                    limit: 10000,
                    status: 1
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data
                        if (this.$route.query.center_id) {
                          let center_id = this.$route.query.center_id
                          this.cache_center = this.centers.find((item) => {
                            return item.id === parseInt(center_id);
                          });
                        }
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            clickPagination(page) {
                this.page = page;
                this.$router.push({name: 'course-level-list', query: {name: this.query.keyword, center_id: this.query.center_id, page: this.page}})
                this.getListCourseLevel();
            },
            handleChangeCenter($event) {
                this.query.center_id = $event != null ? $event.id : '';
                this.$router.push({name: 'course-level-list', query: {keyword: this.query.keyword, center_id: this.query.center_id, page: this.page}});
                this.getListCourseLevel();
            },
            searchByName() {
                this.$router.push({name: 'course-level-list', query: {keyword: this.query.keyword, center_id: this.query.center_id, page: this.page}});
                this.getListCourseLevel();
            },
            getCenter(item) {
                if (item.profile != null && item.profile.chi_nhanh_id !== 0) {
                    let res = this.headquarters.filter((value) => {
                        if (value.id === item.profile.chi_nhanh_id) {
                            return value
                        }
                    })
                    return res[0].name
                }
            },
            getAllCourseType() {
                this.$store.dispatch(GET_ALL_COURSE_TYPE).then((res) => {
                    if (res.data) {
                        this.allCourseType = res.data;
                    }
                });
            },
            deleteCourseLevel(id) {
                Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                  cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(DELETE_COURSE_LEVELS, id).then((res) => {
                            if (res.error) {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'danger',
                                    solid: true
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'success',
                                    solid: true
                                });
                                this.getListCourseLevel();
                            }
                        }).catch((res) => {
                            this.$bvToast.toast(res.data.message, {
                                title: 'Xóa',
                                variant: 'danger',
                                solid: true
                            });
                        });
                    }
                })
            },
            onclickCourseLevel() {
                this.showModalCourseLevel = false;
                this.check_edit = false;
            },
            clickCourseLevelCreate() {
                this.showModalCourseLevel = true;
            },
            editCourseLevel(id) {
                if (id) {
                    this.$store.dispatch(GET_COURSE_LEVEL_BY_ID, id).then((res) => {
                        if (!res.error) {
                            this.courseLevel = res.data;
                            this.showModalCourseLevel = true;
                            this.check_edit = true;
                        }
                    });
                }
            }
        }
    }
</script>
<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
