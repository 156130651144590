<template>
    <div>
        <button @click="handleOk"
                class="btn btn-danger btn-sm"
                :title="ticket.feedback_ticket_star !== null ? 'Đã nhập đánh giá không được phép hủy chuyển' : ''"
                :class="{ 'spinner spinner-white spinner-left': loadingCancelTransfer}"
                :disabled="(ticket.category_chamsoc == 10 && ticket.category_chamsoc == 11 && ticket.feedback_ticket_star !== null) || loadingCancelTransfer"
        >Hủy chuyển
        </button>
        <b-modal id="cancel-department"
        title="Huỷ chuyển"
        size="xs"
        ok-title="Cập nhật"
        centered
        hide-footer
>
   <ValidationObserver v-slot="{ handleSubmit }" ref="form">
       <form>
           <div class="form-group">
               <ValidationProvider vid="feedback_ticket_comment" name="Nội dung" rules="required"
                                   v-slot="{ errors,classes }">
                   <div class="form-group">
                       <ValidationProvider vid="phone" name="Phòng ban xử lý"
                                           :rules="{ required: true }"
                                           v-slot="{ errors,classes }">
                          <span>Việc hủy chuyển ticket sẽ làm thay đổi Bên xử lý thành TTHT. Đồng thời trong danh sách ticket của phòng ban sẽ không còn ticket này. Bạn có chắc chắn muốn hủy chuyển ticket không?</span>
                           <div class="fv-plugins-message-container">
                               <div data-field="name" data-validator="notEmpty"
                                    class="fv-help-block">{{
                                   errors[0] }}
                               </div>
                           </div>
                       </ValidationProvider>
                   </div>
                   <div class="fv-plugins-message-container">
                       <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                           errors[0]
                           }}
                       </div>
                   </div>
               </ValidationProvider>
           </div>
       </form>
   </ValidationObserver>
   <b-button
           variant="danger"
           size="sm"
           class="float-right"
           :disabled="loadingCancelTransfer"
           :class="{'spinner spinner-white spinner-right' : loadingCancelTransfer}"
           @click="closeModal"
   >
       Đóng
   </b-button>
   <b-button
           variant="success"
           size="sm mr-1"
           class="float-right"
           :disabled="loadingCancelTransfer"
           :class="{'spinner spinner-white spinner-right' : loadingCancelTransfer}"
           @click="cancelDepartment"
   >
       Huỷ Chuyển
   </b-button>
</b-modal>
    </div>
</template>

<script>
    import {UPDATE_TICKET_FORM} from "../../../../../core/services/store/user/ticket.module";
    import Swal from "sweetalert2";

    export default {
        name: "CancelMoveDepartment",
        props: {
            ticket: {
                type: Object,
                default: () => {
                    return null;
                }
            },
            checkCancel: {
                type: Boolean,
                default: () => {
                  return true;
                }
            }
        },
        data() {
            return {
                loadingCancelTransfer: false
            }
        },
        methods: {
            handleOk() {
                if (this.ticket.status >= 2 && !this.checkCancel) {
                        this.$bvToast.toast('Không thể hủy do phòng ban đã nhập giải pháp', {
                          title: 'Hủy',
                          variant: 'danger',
                          solid: true
                        });
                        return;
                      }
                    this.$bvModal.show('cancel-department');                 
            },

            cancelDepartment() {
            this.closeModal()
            this.loadingCancelTransfer = true;
            this.$store.dispatch(UPDATE_TICKET_FORM, {
                    id: this.$route.params.id,
                    status: 1,
                    phong_ban_xu_ly: 1,
                    trung_tam_xu_ly: '',
                    chi_nhanh_xu_ly: '',
                    is_transfer: true,
                    checkbox_department_handle: '',
                    cancel_move_department: true
                }).then((res) => {

                    if (!res.error) {
                        this.$bvToast.toast('Hủy chuyển phòng ban thành công', {
                            title: 'Thành công',
                            variant: 'success',
                            solid: true
                        });
                        this.loadingCancelTransfer = false;
                        this.$emit('cancelMoveDepartment', true);
                        
                    }
                });
            },

            closeModal() {
                this.$bvModal.hide('cancel-department');
            }
        }
    }
</script>

<style scoped>

</style>