<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label fw-bold">Lịch sử cuộc gọi</h3>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Khách hàng</label>
                        <el-input placeholder="Nhập tên khách hàng" v-model="query.keyword"></el-input>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Từ số</label>
                        <el-input placeholder="Nhập số điện thoại " v-model="query.fromPhone"></el-input>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Đến số</label>
                        <el-input placeholder="Nhập số điện thoại " v-model="query.toPhone"></el-input>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Loại</label>
                        <el-select v-model="query.type" filterable class="w-100" placeholder="Chọn loại cuộc gọi" clearable
                                   collapse-tags>
                            <el-option v-for="item in listType" :key="item.id" :label="item.name" :value="item.id">
                                <span style="float: left">{{ item.name }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Trạng thái</label>
                        <el-select v-model="query.status" filterable class="w-100" placeholder="Chọn trạng thái"
                                   collapse-tags clearable>
                            <el-option v-for="item in status" :key="item.id" :label="item.name" :value="item.id">
                                <span style="float: left">{{ item.name }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Thời gian</label>
                        <date-picker placeholder="Chọn khoảng thời gian" range v-model="query.date_range" format="DD-MM-YYYY"
                                     valueType="YYYY-MM-DD"></date-picker>
                    </div>
                </div>
                <div class="col-md-2 mt-8">
                    <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2" style="height: 40px"
                            @click="search">
                        <i class="fa fa-search"></i> Tìm kiếm
                    </button>
                    <button v-else type="button" disabled
                            style="height: 40px"
                            class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2 h-0">
                        Tìm kiếm
                    </button>
                </div>
            </div>

            <div class="example mb-10">
                <div class="example-preview table-responsive ">
                    <table class="table table-vertical-center">
                        <thead class="table-secondary">
                        <tr>
                            <th class="min-w-50px" scope="col">Khách hàng</th>
                            <th scope="col" class="min-w-150px">Loại</th>
                            <th scope="col" class="min-w-100px">Brand name</th>
                            <th scope="col" class="min-w-100px">Từ số</th>
                            <th scope="col" class="min-w-120px">Tới số</th>
                            <th scope="col" class="min-w-100px">Thời gian</th>
                            <th scope="col" class="min-w-120px">Trạng thái</th>
                            <th scope="col" class="min-w-120px">Thời lượng</th>
                            <th scope="col" class="min-w-150px">Danh mục</th>
                            <th scope="col" class="min-w-150px">Lý do thất bại</th>
                            <th scope="col" class="min-w-80px">File ghi âm cuộc gọi</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="text-center" v-if="!dataHistory.length">
                            <td colspan="10">Không có dữ liệu</td>
                        </tr>
                        <tr v-for="(item, index) in dataHistory" :key="index" v-else>
                            <td>{{ item.user_customer ? item.user_customer.name : '( Không xác định )' }}</td>
                            <td>{{ item.category == 2 ? 'Gọi đến' : 'Gọi đi' }}</td>
                            <td>{{ checkNetwork(item.category == 1 ? item.from_phone : item.to_phone) }}</td>
                            <td>{{ item.from_phone  }}</td>
                            <td>{{ item.to_phone }}</td>
                            <td>{{ item.start_time_datetime |formatDateTimeVietnam}}</td>
                            <td>{{ item.status == 1 ? 'Thành công ' : 'Thất bại' }}</td>
                            <td>{{ item.answer_duration }}</td>
                            <td> {{ changeCategory(item.category_ticket) }} </td>
                            <td>{{ item.status == 2 ? (reasonError[item.reason] ?? '') : '' }}</td>
                            <td class="text-center">
                                <button @click="changeAudio(item.audio, item.id)" v-if="item.status == 1">
                                    <i class="bi bi-volume-up border p-2" style="font-size: 25px"></i>
                                </button>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{
                totalData }}</b></div>
            </div>
            <div class="edu-paginate mx-auto d-flex justify-content-center">
                <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
                          :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
                          :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
                          :next-class="'page-link'" :page-class="'page-item'">
                </paginate>
            </div>
            <el-dialog
                title="Chi tiết file ghi âm"
                :visible.sync="centerDialogVisible"
                width="30%"
                center>
                <div class="text-center">
                    <audio controls :key="audio">
                        <source :src="audio" type="audio/ogg">
                    </audio>
                </div>
            </el-dialog>
        </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  GET_CUSTOMER_CARE_PHONE,
  GET_HISTORY_STRINGEE, GET_TICKET_CATEGORIES,
  REASON_ERROR
} from "../../../core/services/store/user/ticket.module";
import { CATEGORY } from "../../../core/option/ticketOption";

export default {
    name: "TestStringee",
    components: {
        DatePicker
    },
    data() {
        return {
            centerDialogVisible: false,
            reasonError: REASON_ERROR,
            numbers: '',
            accessToken: 'YOUR_ACCESS_TOKEN',
            category: {
              1: 'Nghỉ học 2 buổi',
              2: 'Điểm kiểm tra thấp',
              3: 'Không làm bài',
              4: 'Chua đóng tiền học',
              5: 'Đã đóng tiền học',
              6: 'Feedback thấp',
              7: 'Nghỉ học 1 buổi',
              8: 'Vắng có phép',
              9: 'Hướng dẫn app cho phụ huynh',
              10: 'Kết quả BTVN không tốt',
              11: 'Kết quả BTVN không tốt'
            },
            config: {
                showMode: 'full',
                top: 45,
                right: 250,
                arrowLeft: 155,
                arrowDisplay: 'top',
                fromNumbers: [
                    {alias: 'Number-1', number: '+84899199586'},
                    {alias: 'Number-2', number: '+2222'}
                ],
                askCallTypeWhenMakeCall: false,
                appendToElement: null,
                makeAndReceiveCallInNewPopupWindow: false,
            },
            dataHistory: [],
            query: {
                keyword: '',
                fromPhone: '',
                toPhone: '',
                type: 0,
                status: 0,
                date_range: [],
                branch_name: ''
            },
            totalData: '',
            is_disable_search: false,
            status: [
                {
                    id: 0,
                    name: 'Tất cả'
                },
                {
                    id: 1,
                    name: 'Thành công'
                },
                {
                    id: 2,
                    name: 'Thất bại'
                }
            ],
            listType: [
                {
                    id: 0,
                    name: 'Tất cả'
                },
                {
                    id: 1,
                    name: 'Cuộc gọi đi'
                },
                {
                    id: 2,
                    name: 'Cuộc gọi đến'
                }
            ],
            centers: [],
            categories: [],
            page: 1,
            last_page: 1,
            fromNumber: '',
            toNumber: '',
            callType: '',
            audio: '',
            idFileAudio: '',
            branchName: []
        };
    },
    mounted() {
        this.getHistory();
        this.getCustomerCarePhone();
        this.getTicketCategories()
    },
    methods: {
        getHistory() {
            this.$store.dispatch(GET_HISTORY_STRINGEE, {
                page: this.page,
                keyword: this.query.keyword,
                fromPhone: this.query.fromPhone,
                toPhone: this.query.toPhone,
                type: this.query.type,
                status: this.query.status,
                date_range: this.query.date_range,
                branch_name: this.query.branch_name
            }).then((data) => {
                this.dataHistory = data.data.data;
                this.last_page = data.data.last_page
                this.totalData = data.data.total;
                console.log('this.last_page', data.data);
                this.is_disable_search = false
            }).catch((error) => {
                console.log(error);
            })
        },
        search() {
            this.is_disable_search = true
            this.page = 1;
            this.pushParamsUrl();
            this.getHistory();
        },
        clickCallback(obj) {
            this.page = obj;
            this.pushParamsUrl();
            this.getHistory();
        },
        pushParamsUrl() {
            this.$router.push({
                path: '', query: {
                    page: this.page,
                    ...this.query
                }
            })
        },
        changeAudio(audio, id) {
            this.audio = audio;
            this.idFileAudio = id;
            this.centerDialogVisible = true;
        },
        getCustomerCarePhone() {
            this.$store.dispatch(GET_CUSTOMER_CARE_PHONE, {}).then((data) => {
                this.branchName = data.data;
            }).catch((error) => {
                console.log(error);
            })
        },
        getTicketCategories() {
            this.$store.dispatch(GET_TICKET_CATEGORIES, {
              limit: 1000
            }).then((data) => {
              this.categories = data.data;
            })
        },
        changeCategory(category_ticket) {
            if (category_ticket) {
              return category_ticket.name;
            }
            return '';
        },
        checkNetwork(number) {
            var prefix = number.substring(0, 3);

            var vietnamobilePrefixes = ["092", "056", "058"];
            var mobifonePrefixes = ["089", "090", "093", "070", "079", "077", "076", "078"];
            var vinaphonePrefixes = ["088", "091", "094", "083", "084", "085"];
            var viettelPrefixes = ["086", "096", "097", "098", "032", "033", "034", "035", "036", "037", "038", "039", "190", "028"];
            var gmobilePrefixes = ["099", "059"];

            if (vietnamobilePrefixes.includes(prefix)) {
                return "Vietnamobile";
            } else if (mobifonePrefixes.includes(prefix)) {
                return "Mobifone";
            } else if (vinaphonePrefixes.includes(prefix)) {
                return "Vinaphone";
            } else if (viettelPrefixes.includes(prefix)) {
                return "Viettel";
            } else if (gmobilePrefixes.includes(prefix)) {
                return "Gmobile";
            } else {
                return "Không xác định"; // Trường hợp không thuộc bất kỳ nhà mạng nào
            }
        }

    }
}
</script>

<style scoped>
button {
    height: 25px;
    cursor: pointer;
}

textarea {
    height: 150px;
    width: 400px;
    resize: vertical;
}

#numbers {
    width: 400px;
    height: 30px;
}

.stringee_iframe_wrapper {
    left: 0;
    right: 45px !important;
}
</style>