<template>
    <tr class="group-color">
        <td></td>
        <td colspan="5">
            <b>{{clan.name}}</b>
            <span class="ml-2 text-white bg-violet p-2 rounded">Rank: {{clan.rank}}</span>
        </td>
        <td>
            <!-- Danh sách button cho nhóm đã được duyệt -->
            <button v-if="accept && clan.user_clan.length < 6" class="btn btn-info btn-sm mr-1 mb-1 mt-1" tabindex="0" @click="addMember"
                    aria-controls="dt-basic-example" type="button"><span><i
                class="el-icon-folder-add mr-1 "></i> Thêm thành viên</span>
            </button>
            <button v-if="accept" class="btn btn-danger btn-sm mr-1 mb-1 mt-1" tabindex="0" @click="deleteTeam"
                    aria-controls="dt-basic-example" type="button"><span><i
                class="el-icon-delete mr-1"></i> Xóa</span>
            </button>

            <!-- Danh sách button cho nhóm cần được duyệt -->
            <button v-if="!accept" class="btn btn-primary btn-sm mr-1 mb-1 mt-1" tabindex="0" @click="acceptTeam"
                    aria-controls="dt-basic-example" type="button"><span><i
                class="el-icon-success mr-1 "></i> Duyệt</span>
            </button>
            <button v-if="!accept" class="btn btn-danger btn-sm mr-1 mb-1 mt-1" tabindex="0" @click="deniedTeam"
                    aria-controls="dt-basic-example" type="button"><span><i
                class="el-icon-error mr-1"></i> Từ chối </span>
            </button>
        </td>
    </tr>
</template>

<script>
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import Swal from "sweetalert2";
import {APPROVE_TEAM, DELETE_TEAM} from "../../../../core/services/store/course/classes.module";
const _ = deepdash(lodash);

export default {
    name: "RowGroup",
    props: {
        accept: {
            type: Boolean,
            default: false // false là team chưa được duyệt và ngược lại
        },
        idTeam: {
            type: Number,
            default: 0
        },
        clan: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            dialogDenied: false,
            dialogAddMember: false,
            dialogGiftSuccess: false,
            dialogPunish: false,
            dialogDetailMember: false,
            dialog: {
                title: null,
                name: null,
            },
        }
    },
    methods: {
        closeDialog() {
            this.dialogDetailMember = false;
            this.dialogDenied = false;
            this.dialogAddMember = false;
            this.dialogGiftSuccess = false;
            this.dialogPunish = false;
        },
        acceptTeam () {
            const h = this.$createElement;
            this.$msgbox({
                title: 'Xét duyệt',
                message: h('p', null, [
                    h('span', null, 'Để xét duyệt, yêu cầu Team phải đảm bảo thông tin sau đây:'),
                    h('br', null, null),
                    h('span', null, '1. Tên team không vi phạm tiêu chuẩn giáo dục'),
                    h('br', null, null),
                    h('span', null, '2. Team được tạo bởi đúng '),
                    h('i', { style: 'color: teal' }, `chủ tướng`),
                    h('span', null, ` của lớp đang dạy`),
                ]),
                showCancelButton: true,
                showClose: false,
                confirmButtonText: 'Chọn xét duyệt',
                cancelButtonText: 'Hủy',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        this.apiAcceptTeam();
                    }
                    done();
                }
            })
        },

          apiAcceptTeam() {
            this.$store.dispatch(APPROVE_TEAM, {
                clan_id: this.clan.id,
                status: 1,
            }).then((res) => {
              this.$emit('reloadPage');
              this.$message({
                message: 'Duyệt team thành công!',
                type: 'success'
              });
            }).catch((e) => {
              console.log(e);
            });
        },
        async deleteTeam() {

          console.log(this.clan.id, 'clam');
            Swal.fire({
                title: 'Bạn chắc chắn muốn xóa team?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#7453a6',
                cancelButtonColor: '#E5E5E5',
                confirmButtonText: 'Tôi đồng ý',
                cancelButtonText: 'Đóng'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let res = await this.$store.dispatch(DELETE_TEAM, {clan_id: this.clan.id}).then(() => {
                        Swal.fire({
                            title: 'Xóa thành công',
                            text: "Team đã được xóa",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#7453a6',
                            confirmButtonText: 'Đóng',
                        })
                        this.$emit('reloadPage');
                    }).catch(() => {
                        Swal.fire({
                            title: 'Serve đang bận. Vui lòng thử lại sau!',
                            text: "Team đã được xóa",
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#7453a6',
                            confirmButtonText: 'Đóng',
                        });
                    })
                }
            })
        },
        deniedTeam() {
            this.$emit('denied', { id: this.clan.id  });
        },

        addMember() {
            this.$emit('addMember', { id: this.clan.id, name: this.clan.name });
        },

        openGift({id, name}) {
            this.dialog.title = `Thưởng`
            this.dialog.id = id;
            this.dialog.name = name;
            this.dialogGiftSuccess = true;
        },

        openPunish({id, name}) {
            this.dialog.title = `Phạt`
            this.dialog.id = id;
            this.dialog.name = name;
            this.dialogPunish = true;
        },

        openDetailMember({ id }) {
            this.dialog.title = 'Chi tiết học viên';
            this.dialog.id = 22;
            this.dialogDetailMember = true;
        },
    },
}
</script>

<style scoped>
    .bg-violet {
        background: #5E00B6;
    }
</style>