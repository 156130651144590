<template>
  <b-modal id="redo-general" ref="redo-general" centered hide-footer title="Xử lý khách hàng làm lại bài general"
           size="ms" @hidden="resetData()">
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent>
        <p>Vui lòng chọn phương án xử lý <span style="color: red">*</span></p>
        <ValidationProvider name="Chọn phương án xử lý" rules="required" v-slot="{ errors,classes }"
                            vid="Chọn phương án xử lý">
          <el-select v-model="problemSolution" placeholder="Chọn phương án xử lý" style="width: 100%" size="small" @change="handleChoose()">
            <el-option
                v-for="item in problemSolutions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="(item.value===1 && !isIelts)"
            />
          </el-select>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
          </div>
        </ValidationProvider>
        <div v-if="problemSolution === CHANGE_CODE">
          <p class="mt-5">Mã đề general<span style="color: red">*</span></p>
          <ValidationProvider name="Chọn phương án xử lý" rules="required" v-slot="{ errors,classes }"
                              vid="Chọn phương án xử lý">
            <el-select v-model="code" placeholder="Chọn phương án xử lý" style="width: 100%" size="small">
              <el-option
                  v-for="item in codeTests"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disable"
              />
            </el-select>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>
        </div>
        <div class="d-flex justify-content-end mt-6">
          <button type="button" class="btn btn-secondary" @click="cancel()">Hủy bỏ</button>
          <button type="button" class="btn btn-info ml-3" :disabled="loading" @click="handleSubmit(confirm)"><i class="el-icon-loading" v-if="loading"></i> Xác nhận</button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import {CHANGE_CODE, PROBLEM_SOLUTIONS} from "@/core/option/testInputOption";
import c from "@/assets/plugins/formvalidation/dist/es6/utils/classSet";
import {GET_CODE_TEST, REDO_GENERAL} from "@/core/services/store/test/testInput.module";

export default {
  name: "ModalRedoGeneral",
  data() {
    return {
      radio: null,
      problemSolution: null,
      problemSolutions: PROBLEM_SOLUTIONS,
      CHANGE_CODE: CHANGE_CODE,
      codeTests: [],
      code: null,
      loading: false
    }
  },
  props: {
    informationTask: {
      type: Object,
      default(){
        return {}
      }
    },
    isIelts:{
      type: Boolean,
      default(){
        return false
      }
    }
  },
  methods: {
    confirm() {
      this.loading = true;
      const payload = {
        id: this.informationTask?.testInputScheduleId,
        section_redo_type: this.problemSolution
      }
      if(this.problemSolution === this.CHANGE_CODE){
        payload.exam_code = this.code
      }
      this.$store.dispatch(REDO_GENERAL,payload).then((data)=>{
        this.$bvModal.hide('redo-general')
        this.$message({
          message: 'Xử lý thành công',
          type: 'success'
        });
      }).catch((e)=>{
        this.$message({
          message: 'Đã có lỗi xẩy ra',
          type: 'error'
        });
      }).finally(()=>{
        this.loading = false;
      })
    },
    getCodeTests() {
       this.$store.dispatch(GET_CODE_TEST,this.informationTask?.testInputScheduleId).then((data)=>{
        if(!data.error){
          this.codeTests = data?.data?.examCode.map((code)=>{
            return {
              value: code,
              disable: code === data?.data?.usingCode,
              label: `Mã đề 0${code}`
            }
          })
          console.log(this.codeTests)
        }
       })
    },
    handleChoose(){
      if(this.problemSolution === this.CHANGE_CODE){
        this.getCodeTests()
      }
    },
    cancel(){
      this.$bvModal.hide('redo-general')
    },
    resetData(){
      this.problemSolution= null;
      this.codeTests = [];
      this.code = null;
    }
  }
}
</script>

<style scoped>

</style>