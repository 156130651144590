import moment from "moment-timezone";

export class BotModel {
    constructor(id, code, apiKey, name, userName, description, status, roles, department) {
        this.id = id;
        this.code = code;
        this.apiKey = apiKey;
        this.name = name;
        this.userName = userName
        this.description = description;
        this.status = status;
        this.roles = roles;
        this.department = department;
        this.labelStatus = this.setStatusLabel(status)
    }
    setStatusLabel(status) {
        if (status === 1) {
            return 'Hoạt động'
        } else {
            return 'Ngừng hoạt động'
        }
    }
}

export class UserByBotModel {
    constructor(name, userId, department, telegramId, registerTime, status) {
        this.name = name;
        this.userId = userId;
        this.department = department;
        this.telegramId = telegramId;
        this.registerTime = moment(registerTime).format('DD-MM-YYYY HH:mm:ss');
        this.status = this.setStatus(status);
    }
    setStatus(status) {
        return status === 1;
    }
}

export const statusBot = [
    {
        label: 'Ngừng hoạt động',
        value: 0,
    },
    {
        label: 'Hoạt động',
        value: 1,
    }
]

export const roles = [
    {
        label: 'Nhân viên',
        value: 0,
    },
    {
        label: 'Trưởng bộ phận',
        value: 1,
    },
    {
        label: 'Trưởng phòng',
        value: 2,
    },
    {
        label: 'BOD',
        value: 3,
    }
]