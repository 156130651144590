<template>
  <div>
    <b-button v-b-modal="'checkbox-filter'" class="btn btn-danger ">
      <i class="fas fa-check-double"></i>
      Chọn bộ lọc
    </b-button>
    <b-modal id="checkbox-filter"
             title="Chọn bộ lọc"
             size="sx"
             ok-title="Cập nhật"
             ok-only
             hide-footer
             scrollable
    >
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Chọn tất cả
      </el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="checkedFilters" @change="handleCheckedFiltersChange">
        <div class="row">
          <div class="col-md-3">
            <el-checkbox v-for="filter in filters" :label="filter" :key="filter">{{ filter }}</el-checkbox>
          </div>
        </div>
      </el-checkbox-group>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalCheckboxFilter",
  props: {
    filterOptions: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  created() {
    this.filters = this.filterOptions;
  },
  data() {
    return {
      checkAll: false,
      checkedFilters: ['Mã ticket', 'Mã học viên,tên,SĐT,email', 'Người phản hồi', 'Trạng thái'],
      filters: [],
      isIndeterminate: true
    };
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedFilters = val ? this.filterOptions : [];
      this.isIndeterminate = false;
      this.emitCheckbox();
    },
    handleCheckedFiltersChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.filters.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.filters.length;
      this.emitCheckbox();
    },
    emitCheckbox() {
      this.$emit('checkboxSuccess', this.checkedFilters)
    }
  }
}
</script>

<style scoped>

</style>