<script>
import {RE_OPEN_SPEAKING} from "@/core/services/store/test/testInput.module";

export default {
  name: "ModalOpenSpeakingTest",
  data(){
    return {
     loading: false
    }
  },
  methods:{
    cancel(){
      this.$bvModal.hide('open-speaking-test')
    },
    confirm(){
      this.loading = true;
      let payload = {
        id : this.$route.params.id
      }
     this.$store.dispatch(RE_OPEN_SPEAKING,payload).then(()=>{
       this.$bvModal.hide('open-speaking-test')
       this.$message({
         message: 'Mở lại Speaking thành công',
         type: 'success'
       });
       this.$emit('open_re_speaking')
     }).catch((e)=>{
       console.log()
       this.$message.error(`${e.response.data.message}`);
     }).finally(()=>{
       this.loading = false;
     })
    }
  }
}
</script>

<template>
  <b-modal id="open-speaking-test" ref="open-speaking-test" centered hide-footer title="Mở lại ca test speaking">
    <p>Sau khi mở lại, khách hàng có thể vào lại link test và admin có thể chấm bài cho khách hàng.</p>
    <p>Tuy nhiên, ca test sẽ <span style="color: red">không tự động hủy</span> nếu khách hàng không vào test.</p>
    <p>Bạn có chắc chắn muốn mở lại ca test? </p>
    <div class="d-flex justify-content-end mt-2">
      <button type="button" class="btn btn-secondary" @click="cancel()" style="width: 100px">Hủy bỏ</button>
      <button type="button" class="btn btn-info ml-3" @click="confirm()" :disabled="loading"><i class="el-icon-loading" v-if="loading"></i>Xác nhận</button>
    </div>
  </b-modal>
</template>

<style scoped>

</style>