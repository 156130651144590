import ApiService from "@/core/services/api.service";

const _ = require('lodash');
//action types
export const GET_PROMOTION_BY_ID = "get_promotion_by_id";
export const GET_PROMOTIONS_BY_CONTRACT = "get_promotion_by_contract";
export const LIST_GIFT = "LIST_GIFT";
export const GET_VOUCHERS_BY_CONTRACT = "get_voucher_by_contract";
export const GET_PROMOTIONS = "list-promotion";
export const GET_PROMOTION_BY_NUMBER = "get_promotion_by_number";
export const CREATE_PROMOTION = "create_promotion";
export const UPDATE_PROMOTION = "update_promotion";
export const DELETE_PROMOTION = "delete_promotion";

//mutations types
export const SET_PROMOTIONS = "setPromotions";
export const ADD_PROMOTIONS = "addPromotions";
export const SET_PROMOTION_BY_ID = "getPromotionById";
export const SET_PROMOTION_PAGINATE = "set_promotion_paginate_value";
export const UPDATE_LIST_PROMOTION_WHEN_DELETE = "set_promotions_when_delete_promotion";

// genz


const state = {
    errors: null,
    promotions: [],
    promotion: '',
    promotionPaginatedData: [],
};

const getters = {
    getAllPromotions(state) {
        return state.promotions;
    },
    getPromotionById(state) {
        return state.promotion;
    },
    getPromotionPaginate(state) {
        return state.promotionPaginatedData;
    },
};

const actions = {
    [CREATE_PROMOTION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('promotions', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [UPDATE_PROMOTION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('promotions/update-by-number', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [GET_PROMOTIONS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('promotions', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_PROMOTION_BY_NUMBER](context, payload) {
        return new Promise(resolve => {
            ApiService.query('promotions/get-by-number', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_PROMOTIONS_BY_CONTRACT](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("list-promotions-courses", payload).then(({data}) => {
                resolve(data);
            }).catch((errors) => {
                console.log(errors)
            });
        });
    },
    [LIST_GIFT](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("list-gift", payload).then(({data}) => {
                resolve(data);
            }).catch((errors) => {
                console.log(errors)
            });
        });
    },
    [GET_VOUCHERS_BY_CONTRACT](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("list-vouchers-courses", payload).then(({data}) => {
                resolve(data);
            }).catch((errors) => {
                console.log(errors)
            });
        });
    },

    [GET_PROMOTION_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('promotions', id).then(({data}) => {
                context.commit(SET_PROMOTION_BY_ID, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [DELETE_PROMOTION](context, number) {
        return new Promise(resolve => {
            ApiService.post("promotions/delete-by-number", number).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};


const mutations = {
    [SET_PROMOTIONS](state, data) {
        state.promotions = data;
        state.errors = {};
    },
    [ADD_PROMOTIONS](state, data) {
        state.promotions.push(data);
        state.errors = {};
    },
    [SET_PROMOTION_BY_ID](state, data) {
        state.promotion = data;
    },
    [SET_PROMOTION_PAGINATE](state, data) {
        state.promotionPaginatedData = data;
    },

    [UPDATE_LIST_PROMOTION_WHEN_DELETE](state, id) {
        let promotions = state.promotions;
        let indexPromotion = _.findIndex(promotions, function (object) {
            return object.id === id;
        });
        promotions.splice(indexPromotion, 1);
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
