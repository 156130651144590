import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const LIST_COURSE_TRANSFER = "course-transfers";
export const CREATE_COURSE_TRANSFER = "create-course-transfers";
export const COURSE_TRANSFER_SEARCH_USERS = "course-transfers/search-user";
export const COURSE_TRANSFER_CHECK_BUSINESS = "course-transfers/check-business";
export const FROM_USER_INFORMATION = "course-transfers/from-user-information";
export const FROM_USER_COURSES = "from-user-courses";

const state = {};
const getters = {};
const actions = {
    /**
     * api danh sách chuyển nhượng
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [LIST_COURSE_TRANSFER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${LIST_COURSE_TRANSFER}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },

    /**
     * api tạo chuyển nhượng
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [CREATE_COURSE_TRANSFER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('course-transfers', payload).then(({data}) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },

    /**
     * api tìm kiếm học viên
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [COURSE_TRANSFER_SEARCH_USERS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${COURSE_TRANSFER_SEARCH_USERS}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },

    /**
     * api kiểm tra công nợ
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [COURSE_TRANSFER_CHECK_BUSINESS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${COURSE_TRANSFER_CHECK_BUSINESS}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },

    /**
     * api lấy thông tin người chuyển và các khóa học
     * @param context
     * @param user_id
     * @returns {Promise<unknown>}
     */
    [FROM_USER_INFORMATION](context, user_id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${FROM_USER_INFORMATION}`, user_id).then(({data}) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },

    /**
     * api lấy thông tin người chuyển và các khóa học
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [FROM_USER_COURSES](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${FROM_USER_INFORMATION}/user/${payload.user_id}/contract`, payload.contract_id).then(({data}) => {
                resolve(data);
            }).catch(({ response }) => {
                reject(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
