export const CATEGORY = {
    1: "Bảo hành",
    2: "Bảo lưu",
    3: "Khiếu nại",
    4: "Hỗ trợ học tập",
    5: "Rút tiền",
    6: "Đề xuất",
    7: "Khác",
    8: "Trung tâm hỗ trợ",
    9: "Chăm sóc học viên",
};
export const CATEGORY_CHAMSOC = {
    1: "Nghỉ 2,3 buổi trở lên",
    2: "Điểm kiểm tra thấp",
    3: "Không đi làm kiểm tra",
    4: "Chưa đóng tiền học",
    5: "Đã đóng tiền học",
};

export const STATUS = {
    1: "Chờ xử lý",
    2: "Đã nhập giải pháp",
    3: "Đã hoàn thành",
    0: "Đã Hủy",
}

export const VIDEO_OPTION = [
    '.mp4',
    '.avi',
    '.wmv',
    '.hevc',
    '.mov',
    '.f4v',
    '.mkv',
    '.ts',
    '.3gp',
    '.mpeg-2',
    '.webm',
    '.mp3',
];

export const IMAGE_OPTION = [
    '.jepg',
    '.jpg',
    '.png',
    '.gif',
    '.tiff',
    '.psd',
    '.pdf',
    '.eps',
    '.ai',
    '.svg',
    '.raw',
    '.heic',
];

export const TICKET_STATUS = [
    {label: 'Đã hủy', value: 0, bg_color: 'bg-red-error'},
    {label: 'Chờ xử lý', value: 1, bg_color: 'bg-yellow'},
    {label: 'Đã nhập giải pháp', value: 2, bg_color: 'bg-violet text-white'},
    {label: 'Hoàn thành', value: 3 , bg_color: 'bg-suc text-white'},
];

export const TICKET_FROM = [
    {label: 'QLHV', value: 2},
    {label: 'TTHT', value: 3},
];

export const TICKET_PROPERTY = [
    {label: 'Cao nhất', value: 1},
    {label: 'Cao', value: 2},
    {label: 'Trung bình', value: 3},
    {label: 'Thấp', value: 4},
];

export const TICKET_EVALUATE = [
    {label: 'Chưa nhập liệu', value: 'chưa nhập liệu'},
    {label: 'Không đánh giá', value: 0},
    {label: '1 sao', value: 1},
    {label: '2 sao', value: 2},
    {label: '3 sao', value: 3},
    {label: '4 sao', value: 4},
    {label: '5 sao', value: 5},
];

export const DOI_TUONG_PHAN_HOI = [
    {label: 'Học viên', value: 1},
    {label: 'Trợ giảng', value: 2},
    {label: 'Giảng viên', value: 3},
    {label: 'Nhân sự', value: 4},
];

export const FEEDBACK_TICKET_STAR = [
    {
        id: 'Chưa nhập liệu',
        name: null
    },
    {
        id: 0,
        name: "Không đánh giá"
    },
    {
        id: 1,
        name: "1 sao"
    },
    {
        id: 2,
        name: "2 sao"
    },
    {
        id: 3,
        name: "3 sao"
    },
    {
        id: 4,
        name: "4 sao"
    },
    {
        id: 5,
        name: "5 sao"
    }
];


