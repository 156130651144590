<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="form" @submit.prevent="handleSubmit(handleUpdateBot)"
          style="display: flex; flex-direction: column; gap: 20px">
      <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 25px">
        <div style="display: flex; flex-direction: column;">
          <label>Tên BOT <span style="color: red">*</span></label>
          <ValidationProvider name="Tên BOT" rules="required|max:50" v-slot="{ errors }" vid="nameBot">
            <el-input type="text" v-model="recordBot.name" placeholder="Nhập tên BOT"/>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>
        </div>
        <div style="display: flex; flex-direction: column;">
          <label>Bot User Name <span style="color: red">*</span></label>
          <ValidationProvider name="Bot User Name" rules="required|max:50" v-slot="{ errors }" vid="userName">
            <el-input type="text" v-model="recordBot.userName" placeholder="Nhập Bot User Name"/>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div style="display: flex; flex-direction: column;">
        <label>Bot Api Key <span style="color: red">*</span></label>
        <ValidationProvider name="Bot Api Key" rules="required|max:255" v-slot="{ errors }" vid="apiKey">
          <el-input type="text" v-model="recordBot.apiKey" placeholder="Nhập Bot Api Key"/>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
          </div>
        </ValidationProvider>
      </div>
      <div style="display: flex; flex-direction: column">
        <label>Mô tả</label>
        <ValidationProvider name="Bot Api Key" rules="max:200" v-slot="{ errors }" vid="apiKey">
          <el-input type="text" v-model="recordBot.description" placeholder="Nhập mô tả về BOT"/>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
          </div>
        </ValidationProvider>
      </div>
      <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 25px">
        <div>
          <label>Phân quyền xem <span style="color: red">*</span></label>
          <ValidationProvider name="Loại tài khoản" rules="required" v-slot="{ errors }" vid="role">
            <el-select v-model="recordBot.roles" clearable multiple placeholder="Chọn loại tài khoản" style="width: 100%">
              <el-option
                  v-for="item in rolesOption"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>
        </div>
        <div>
          <label>Phòng ban</label>
          <el-select v-model="recordBot.department" clearable multiple placeholder="Chọn phòng ban" style="width: 100%">
            <el-option
                v-for="item in listDepartment"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div style="display: flex; justify-content: end; gap: 10px">
        <el-button @click="closeDialog"
                   style="background: #E6E6E6 !important; color: #0a0a0a !important;">
          Hủy bỏ
        </el-button>
        <button v-loading="loading" type="submit"
                style="background: #4D44B5 !important; color: white !important; padding: 10px 20px; border-radius: 5px">
          Xác nhận
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>

import {GET_DEPARTMENT} from "@/core/services/store/ticket-category/category.module";
import {GET_LIST_ACCOUNT_TYPE, UPDATE_BOT} from "@/core/services/store/user/account-type.module";

export default {
  name: 'DialogUpdateBot',
  data() {
    return {
      roles: [],
      department: [],
      rolesOption: [],
      listDepartment: [],
      loading: false,
    }
  },
  props: {
    recordBot: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.getListDepartment();
    this.getListAccountType();
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    getListAccountType() {
      this.$store.dispatch(GET_LIST_ACCOUNT_TYPE).then(res => {
        if (!res.error) {
          this.rolesOption = res.data ?? [];
        }
      })
    },
    handleUpdateBot() {
      this.loading = true
      const payload = {
        id: this.recordBot.id,
        params: {
          name: this.recordBot.name,
          user_name: this.recordBot.userName,
          api_key: this.recordBot.apiKey,
          description: this.recordBot.description,
          account_type_ids: this.recordBot.roles,
          department_ids: this.recordBot.department,
        }
      }
      this.$store.dispatch(UPDATE_BOT, payload).then(response => {
        this.$message.success('Sửa BOT thành công')
        this.closeDialog();
        this.$emit('getListManagerBot')
      }).catch(e => {
        console.log(e, 'error')
      }).finally(() => {
        this.loading = false
      })
    },
    getListDepartment() {
      this.$store.dispatch(GET_DEPARTMENT).then((res) => {
        if (!res.error) {
          this.listDepartment = res.data ?? []
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
  }
}
</script>

<style lang="css" scoped>

</style>