import {
    COHERENCE_COHESION_WRITING,
    COMMENT_COHERENCE_COHESION_WRITING,
    COMMENT_FLUENCY_AND_COHERENCE,
    COMMENT_GRAMMATICAL_RANGE_ACCURACY,
    COMMENT_GRAMMATICAL_RANGE_ACCURACY_WRITING,
    COMMENT_LEXICAL_RESOURCE,
    COMMENT_LEXICAL_RESOURCE_WRITING,
    COMMENT_PRONUNCIATION,
    COMMENT_TASK_ACHIEVEMENT_WRITING, CRITERIAES_GET_COMMENT_AUTO,
    FLUENCY_AND_COHERENCE,
    GRAMMATICAL_RANGE_ACCURACY, GRAMMATICAL_RANGE_ACCURACY_WRITING,
    LEXICAL_RESOURCE,
    LEXICAL_RESOURCE_WRITING,
    PRONUNCIATION,
    TASK_ACHIEVEMENT_WRITING
} from "@/core/option/testInputOption";

export function CriteriaModel(id, name, eName = "", score = 10,commentAuto='',specificComments='',arrayDetail = []) {
    this.id = id;
    this.score = score;
    this.commentAuto = commentAuto;
    this.specificComments = specificComments;
    this.name = name ?? "";
    this.eName = eName;
    this.listdetailSkill = createListDetailSkill(CRITERIAES_GET_COMMENT_AUTO.get(id),arrayDetail)
};
CriteriaModel.prototype.calculationPointAndCommentAuto = function () {
    let count = 0
    this.listdetailSkill.map((skill) => {
        this.score = skill.scoreDetail < this.score ? skill.scoreDetail : this.score;
        this.commentAuto += `${skill.commentAuto}\n`
        if (skill.scoreDetail == 1 || skill.scoreDetail == 0) {
            count++
        }
        return
    })
    if (count == this.listdetailSkill.length) {
        if (this.id > 4) {
            this.commentAuto = ``;
            return
        };
        this.commentAuto = this.score == 1 ? 'Có thể nói được một chút, nhưng câu trả lời còn quá rời rạc và chưa thể tạo ra một ý nghĩa nhất định cho bài nói, từ đó chưa trả lời được câu hỏi':'Bạn gần như chưa thể nói được';
    }
};
CriteriaModel.prototype.setPointAndCommentAutoIs0Or1 = function (point) {
   this.listdetailSkill.forEach((detailSkill)=>{
       detailSkill.scoreDetail = point;
       if(this.id <5){
           detailSkill.commentAuto = detailSkill.listCommentAuto[point]
       }else {
           detailSkill.commentAuto = '';
       }
   })
};
CriteriaModel.prototype.clearPointAndCommentAutoIs0Or1 = function () {
    this.listdetailSkill.forEach((detailSkill)=>{
        if(detailSkill.scoreDetail < 2){
         detailSkill.scoreDetail = null;
         detailSkill.commentAuto = '';
        }
    })
};

export class DetailSkillModel {
    nameDetailSkill;
    scoreDetail = null;
    listCommentAuto;
    listMark;
    commentAuto;

    constructor(name, listComment, scoreDetail = null , commentAuto = null ) {
        this.nameDetailSkill = name;
        this.listCommentAuto = listComment;
        this.listMark = Object.keys(listComment)
        this.scoreDetail = scoreDetail;
        this.commentAuto = commentAuto;
    }

    setMark(point) {
        this.scoreDetail = point;
        this.commentAuto = this.listCommentAuto[point];
    }
}

function createListDetailSkill(arrSkill,arrayDetail = []) {
    let objectSkills = [];
    arrSkill.forEach((skill) => {
        let skillOld = arrayDetail.find((e)=>e.nameDetailSkill === skill.vName )
        if(skillOld){
            objectSkills = [...objectSkills, new DetailSkillModel(skill.vName, skill.pointLadder,skillOld.scoreDetail,skillOld.commentAuto)]
        }else {
            objectSkills = [...objectSkills, new DetailSkillModel(skill.vName, skill.pointLadder)]
        }
    })
    return objectSkills;
};
export const SPEAKING_CRITERIA = [
    new CriteriaModel(1, "ĐỘ TRÔI CHẢY VÀ MẠCH LẠC", "fluency_and_coherence"),
    new CriteriaModel(2, "VỐN TỪ", "lexical_resource"),
    new CriteriaModel(3, "ĐỘ ĐA DẠNG VÀ CHÍNH XÁC CỦA NGỮ PHÁP", "grammatical_range_accuracy"),
    new CriteriaModel(4, "PHÁT ÂM", "pronunciation"),
];
export const WRITING_CRITERIA = [
    new CriteriaModel(5, "ĐÁP ỨNG YÊU CẦU ĐỀ BÀI", "fluency_and_coherence"),
    new CriteriaModel(8, "ĐỘ MẠCH LẠC", "pronunciation"),
    new CriteriaModel(6, "TỪ VỰNG", "lexical_resource"),
    new CriteriaModel(7, "NGỮ PHÁP", "grammatical_range_accuracy"),
];
