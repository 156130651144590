<template>
  <b-modal @hide="onHide" size="lg" :centered="true" :scrollable="true" ref="modal-tester" hide-footer
           :title="isModalEdit?'Cập nhật nhân sự Admin':'Thêm mới nhân sự admin'" class="modal-tester"
           id="modal-tester">
    <ValidationObserver ref="form" v-slot="{ handleSubmit,validate }">
      <div class="d-block">
        <form class="form" @submit.prevent="handleSubmit(saveTester)">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Tìm nhân sự<span class="text-danger">(*)</span></label>
                <ValidationProvider vid="user_id" name="Nhân sự"
                                    :rules="{ required: true}"
                                    v-slot="{ errors,classes }">
                  <el-select
                      v-model="tester.user_id"
                      filterable
                      clearable
                      remote
                      reserve-keyword
                      placeholder="Nhập mã, SĐT, tên nhân sự"
                      :remote-method="remoteMethod"
                      :loading="search_loading">
                    <el-option
                        v-for="(item, index) in users"
                        :key="index"
                        :label="item.name + ' - ' + item.phone"
                        :value="item.id">
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Hình thức trực test<span class="text-danger">(*)</span></label>
                <ValidationProvider name="Hình thức trực test" :rules="{ required: true}"
                                    v-slot="{ errors,classes }"
                                    vid="type">
                  <el-select v-model="tester.type"
                             @input="handleType"
                             placeholder="Hình thức trực test"
                             clearable
                  >
                    <el-option
                        v-for="(item, index) in types"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6" v-if="tester.type === OFFLINE || tester.type === ALL">
              <div class="form-group">
                <label>Cơ sở trực test offline<span class="text-danger">(*)</span></label>
                <ValidationProvider name="Cơ sở trực" rules="required|max:30" v-slot="{ errors,classes }"
                                    vid="branch_ids">
                  <el-select v-model="tester.branch_ids"
                             placeholder="Cơ sở trực test offline"
                             clearable
                             multiple
                             collapse-tags
                             filterable
                  >
                    <el-option
                        v-for="(item, index) in branches"
                        :key="index"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6" v-if="tester.type === ONLINE || tester.type === ALL">
              <div class="form-group">
                <label>Link test online<span class="text-danger">(*)</span></label>
                <ValidationProvider name="Link test online" rules="required|max:100" v-slot="{ errors,classes }"
                                    vid="link_test_online">
                  <el-input v-model="tester.link_test_online" placeholder="Điền link zoom hoặc meet"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row mb-4" v-if="isChangeType && isModalEdit">
            <label class="col-3 col-form-label" style="padding-right: 0">Thời gian thay đổi hình thức <span
                style="color: red">*</span></label>
            <div class="col-5">
              <ValidationProvider name="Ngày thay đổi hình thức" rules="required" v-slot="{ errors,classes }"
                                  vid="Ngày thay đổi hình thức">
                <el-date-picker
                    v-model="dayChangeType.day"
                    type="date"
                    size="small"
                    placeholder="Chọn ngày"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-4">
              <ValidationProvider name="Buổi thay đổi hình thức" rules="required" v-slot="{ errors,classes,validate }"
                                  vid="formChange">
                <el-select v-model="dayChangeType.session" placeholder="Chọn buổi" size="small"
                           @change="changeDayType()">
                  <el-option
                      v-for="item in listSession"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-12 row" v-if="dayChangeType.error">
              <div class="col-3"></div>
              <div class="col-9" style="color: red">
                Lỗi: Tồn tại tác vụ test đầu vào không phù hợp với hình thức trực sau thời gian thay đổi.
              </div>
            </div>
          </div>
          <div class="row mb-4" v-if="isChangeBranch && isModalEdit">
            <label class="col-3 col-form-label">Ngày thay đổi cơ sở <span style="color: red">*</span></label>
            <div class="col-5">
              <ValidationProvider name="Ngày thay đổi cơ sở" rules="required" v-slot="{ errors,classes }"
                                  vid="Ngày thay đổi cơ sở">
                <el-date-picker
                    v-model="dayChangeBranch.day"
                    type="date"
                    size="small"
                    placeholder="Chọn ngày"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-4">
              <ValidationProvider name="Buổi thay đổi cơ sở" rules="required" v-slot="{ errors,classes }"
                                  vid="Buổi thay đổi cơ sở">
                <el-select v-model="dayChangeBranch.session" placeholder="Chọn buổi" size="small"
                           @change="changeDayBranch()">
                  <el-option
                      v-for="item in listSession"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-12 row" v-if="dayChangeBranch.error">
              <div class="col-3"></div>
              <div class="col-9" style="color: red">
                Lỗi: Tồn tại tác vụ test đầu vào không phù hợp với cơ sở trực sau thời gian thay đổi.
              </div>
            </div>
          </div>
          <div class="row">
            <label class="col-3 col-form-label">Ẩn/Hiển thị</label>
            <div class="col-5">
                <span class="switch switch-sm switch-icon">
                  <label>
                    <input v-model="tester.status" type="checkbox" checked="checked" name="select"/>
                    <span></span>
                  </label>
                </span>
            </div>
          </div>
          <div class="row mt-4 mb-4" v-if="!tester.status">
            <label class="col-3 col-form-label">Ngày làm việc cuối cùng</label>
            <div class="col-6">
              <el-date-picker
                  v-model="dateOff"
                  type="date"
                  size="small"
                  placeholder="Ngày làm việc cuối cùng"
                  format="dd-MM-yyyy"
                  value-format="yyyy-MM-dd"
                  @change="checkBeforeHide()"
              >
              </el-date-picker>
              <p style="color:red;" class="mt-2" v-if="errorDayOff">Nhân sự không được có tác vụ sau thời gian nghỉ
                việc</p>
            </div>
          </div>
          <div class="row">
            <label class="col-3 col-form-label">Lịch đi làm cố định</label>
            <div class="col-5">
                <span class="switch switch-sm switch-icon">
                  <label>
                    <input @change="handleCalendar" v-model="tester.is_fixed_schedule" type="checkbox" checked="checked"
                           name="select"/>
                    <span></span>
                  </label>
                </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" v-if="tester.is_fixed_schedule">
              <ValidationProvider name="Lịch làm việc" rules="" v-slot="{ errors,classes }"
                                  vid="register_calendar">
                <RegisterCalendar :data_prop="tester.register_calendar"
                                  @registerSuccess="registerSuccess">
                </RegisterCalendar>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div style="color: #5E00B6;cursor: pointer; font-weight: bold" @click="openHistory()" v-if="isModalEdit">
           <i class="el-icon-time" style="color: #5E00B6; font-weight: bold"></i> Lịch sử thay đổi
          </div>
          <footer class="modal-footer pt-5 pb-0">
            <button type="button" class="btn btn-secondary" @click="hideModal">Hủy bỏ</button>
            <button type="submit" :disabled="is_disable || (!tester.status && (errorDayOff||dateOff === null) || dayChangeType.error||dayChangeBranch.error)"
                    class="btn btn-success" v-if="isModalEdit"><i
                v-if="is_loading_save" class="el-icon-loading"></i>Cập nhật
            </button>
            <button type="submit" :disabled="is_disable" class="btn btn-success" v-else>Xác nhận</button>
          </footer>
        </form>
      </div>
    </ValidationObserver>
    <warning-update-admin @setIsWaringModal="isWaringModal=false" :isWaringModal="isWaringModal"
                          :dataWaringModal="dataWaringModal"
                          :tester="tester"
                          @updateSuccess="updateSuccess"
                          @updateTask="updateTester"
    ></warning-update-admin>
  <ModalHistoryChange :historyLog="historyLog" @resetHistoryLog="historyLog = []"></ModalHistoryChange>
  </b-modal>
</template>

<script>
import {
  CHECK_TASK_BY_DAY_LEAVE,
  CREATE_TESTER,
  SHOW_TESTER,
  UPDATE_TESTER
} from "@/core/services/store/test/test.module";
import {GET_USER_BY_PHONE} from "@/core/services/store/user/users.module";
import RegisterCalendar from "@/view/pages/test-input/components/RegisterCalendarDefault";
import {GET_BRANCHES} from "@/core/services/store/center/branch.module";
import {ALL, OFFLINE, ONLINE, TYPES} from "@/core/option/testInputOption";
import WarningUpdateAdmin from "@/view/pages/test/tester/WarningUpdateAdmin";
import {GET_CENTERS} from "@/core/services/store/center/centers.module";
import {
  CHECK_BEFORE_BRANCH,
  CHECK_BEFORE_HIDE,
  CHECK_BEFORE_TYPE,
  GET_HISTORY_LOG_TESTER
} from "@/core/services/store/test/testInput.module";
import {validate} from "vee-validate";
import ModalHistoryChange from "@/view/pages/test/tester/ModalHistoryChange.vue";

export default {
  name: "TesterModal",
  components: {ModalHistoryChange, WarningUpdateAdmin, RegisterCalendar},
  props: {
    showModal: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    testerObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isModalEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {

    })
    this.getAllCenterIsEdutalk();
  },
  data() {
    return {
      checked: '',
      tester: {},
      is_disable: false,
      is_update: false,
      search_loading: false,
      users: [],
      branches: [],
      types: TYPES,
      OFFLINE: OFFLINE,
      ALL: ALL,
      ONLINE: ONLINE,
      titleModal: '',
      isWaringModal: false,
      dataWaringModal: {},
      is_loading_save: false,
      dateOff: null,
      errorDayOff: false,
      dayChangeBranch: {
        day: null,
        session: null,
        error: false
      },
      dayChangeType: {
        day: null,
        session: null,
        error: false
      },
      listSession: [
        {value: 5, label: 'Sáng 1'},
        {value: 1, label: 'Sáng'},
        {value: 6, label: 'Chiều 1'},
        {value: 2, label: 'Chiều'},
        {value: 3, label: 'Tối'},
        {value: 4, label: 'Tối 2'},

      ],
      initialTester: {},
      historyLog: []
    }
  },
  watch: {
    showModal: function () {
      if (this.showModal) {
        this.$refs['modal-tester'].show();
      }
    },
    testerObj: {
      deep: true,
      immediate: true,
      handler() {
        if (this.testerObj.id) {
          this.getTesterById();
          this.getHistoryLogTester()
        } else {
          this.tester = this.resetDataDefault();
          this.errorDayOff = false;
          this.dateOff = null;
          this.dayChangeType = {
            day: null,
            session: null
          };
          this.dayChangeBranch = {
            day: null,
            session: null
          };
          this.initialTester = {};
        }
      }
    },
    isModalEdit() {
      this.is_update = this.isModalEdit
    }
  },
  methods: {
    validate,
    handleCalendar() {
      if (!this.tester.is_fixed_schedule) {
        this.tester.register_calendar = [];
      }
    },
   handleType() {
      this.tester.branch_ids = [];
    },
    getTesterById() {
      this.titleModal = 'Sửa nhân sự admin';
    return   this.$store.dispatch(SHOW_TESTER, this.testerObj.id).then((res) => {
        this.tester = res.data;
        this.initialTester = {...this.tester};
        if (this.tester.user) {
          this.users.push(this.tester.user);
        }
        if (this.tester.user_id === 0) {
          this.tester.user_id = ''
        }
         this.dateOff = res?.data?.day_off_work;

      }).finally(()=>{
        return null
    })
    },
    getAllCenterIsEdutalk() {
      this.$store.dispatch(GET_CENTERS, {
        is_edutalk: 1
      }).then((data) => {
        let ids = data.data.data.map((branch) => branch.id);
        this.getBranch(ids);
      })
    },
    getBranch(ids) {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 100000,
        center_ids: ids, status: 1
      }).then((data) => {
        this.branches = data.data.data;
      })
    },
    resetDataDefault() {
      this.titleModal = 'Thêm mới nhân sự admin';
      return {
        email: '',
        user_id: '',
        branch_ids: [],
        type: null,
        register_calendar: [],
        is_fixed_schedule: true,
        status: true
      }
    },
    registerSuccess(data) {
      this.tester.register_calendar = data;
    },
    remoteMethod(query) {
      this.search_loading = true;
      this.$store.dispatch(GET_USER_BY_PHONE, {
        phone: query
      }).then((data) => {
        this.search_loading = false;
        this.users = data.data;
      })
      if (query !== '') {
        this.search_loading = true;
        setTimeout(() => {
          this.search_loading = false;
          if (this.list) {
            this.options = this.list.filter(item => {
              return item.label.toLowerCase()
                  .indexOf(query.toLowerCase()) > -1;
            });
          }
        }, 200);
      } else {
        this.options = [];
      }
    },
    saveTester: function () {
      this.is_disable = true;
      if (this.isModalEdit) {
        this.updateTester()
      } else {
        this.createTester()
      }
    },
    updateTester() {
      this.is_loading_save = true;
      if (!this.tester.status) {
        this.tester.day_off_work = this.dateOff
      }
      this.$store.dispatch(CHECK_TASK_BY_DAY_LEAVE, this.tester).then((res) => {
        this.update();
        this.hideModalCheck();
        this.$emit('createSuccess', res.data)
      }).catch((error) => {
        if (error?.data?.data.message_validate_form) {
          this.$refs.form.setErrors(error.data.data.message_validate_form);
        }
        if (error?.data?.message) {

        }
        if (error?.data) {
          this.dataWaringModal = error?.data?.data
          this.isWaringModal = true
        }
      }).finally(() => {
        this.is_loading_save = false;
      })
    },

    update() {
      this.tester = {
        ...this.tester,
        shift_change_branch: this.dayChangeBranch.session,
        shift_change_type: this.dayChangeType.session,
        day_change_branch: this.dayChangeBranch.day,
        day_change_type: this.dayChangeType.day
      };
      this.$store.dispatch(UPDATE_TESTER, this.tester).then((res) => {
        this.$message.success(res.message);
        this.hideModal();
      }).catch((error) => {
        if (error?.data?.data.message_validate_form) {
          this.$refs.form.setErrors(error.data.data.message_validate_form);
        }
        if (error?.data?.message) {
          // this.$message.error(error.data.message);
        }
      }).finally(() => {
        this.is_disable = false;
        this.is_update = false;
        this.is_loading_save = false;
      })
    },
    createTester() {
      this.$store.dispatch(CREATE_TESTER, this.tester).then((res) => {
        this.$message.success(res.message);
        this.resetDataDefault();
        this.hideModal();
        this.$emit('createSuccess', res.data);
      }).catch((res) => {
        if (res?.data?.data.message_validate_form) {
          this.$refs.form.setErrors(res.data.data.message_validate_form);
        }
        if (res?.data?.message) {
          // this.$message.error(res.data.message);
        }
      }).finally(() => {
        this.is_disable = false;
        this.is_loading_save = false;
      })
    },
    hideModalCheck: function () {
      this.$bvModal.hide('waring-update-admin')
      this.$emit('createSuccess');
    },
    hideModal: function () {
      this.$refs['modal-tester'].hide();
      this.$emit('createSuccess');
    },
    onHide: function () {
      this.tester = this.resetDataDefault();
      this.$emit("clicked", true);
    },
    toggleModal() {
      // when the modal has hidden
      this.$refs['modal-tester'].toggle('#toggle-btn');
    },
    updateSuccess() {
      this.hideModal();
    },
    checkBeforeHide() {
      const payload = {day_off_work: this.dateOff, id: this.tester.id}
      this.$store.dispatch(CHECK_BEFORE_HIDE, payload).then((data) => {
        this.errorDayOff = !data?.data;
      }).catch(() => {
        this.errorDayOff = true;
      })
    },
    arraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
      return arr1.every((element, index) => arr2.includes(element) && arr1.includes(arr2[index]));
    },
    changeDayType() {
      const payload = {
        day_change_type: this.dayChangeType.day,
        shift_change_type: this.dayChangeType.session,
        id: this.tester.id
      }
      this.$store.dispatch(CHECK_BEFORE_TYPE, payload).then((data) => {
        this.dayChangeType.error = !data.data
      }).catch(() => {

      })
    },
    changeDayBranch() {
      const payload = {
        day_change_branch: this.dayChangeBranch.day,
        shift_change_branch: this.dayChangeBranch.session,
        id: this.tester.id
      }
      this.$store.dispatch(CHECK_BEFORE_BRANCH, payload).then((data) => {
        this.dayChangeBranch.error = !data.data
      }).catch(() => {

      })
    },
    openHistory(){
      this.getHistoryLogTester()
      this.$bvModal.show('modal_history_change')
    },
    getHistoryLogTester(){
      const payload = {
        id: this.testerObj.id
      }
      this.$store.dispatch(GET_HISTORY_LOG_TESTER,payload).then((res)=>{
        this.historyLog = res?.data??[]
      })
    }
  },
  computed: {
    isChangeType() {
      if (Object.keys(this.initialTester).length === 0) {
        return false
      }
      return this.initialTester?.type !== this.tester?.type
    },
    isChangeBranch() {
      if (Object.keys(this.initialTester).length === 0) {
        return false
      }
      return !this.arraysEqual(this.initialTester?.branch_ids, this.tester?.branch_ids)
    }
  }
}
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>
