import {formatVND} from "@/core/filters";

export class BookCostModel {
    constructor(id, courseId, name, cost, date, errors = []) {
        this.id = id;
        this.courseId = courseId;
        this.name = name;
        this.cost = cost ? formatVND(cost) : '';
        this.date = date;
        this.errors = errors.join(", ")
    }
}

export const rowTableBookError = [
    {courseId: 'ID khóa học'},
    {cost: 'Chi phí sách'},
    {date: 'Thời gian '},
    {errors: 'Lỗi'}
]
