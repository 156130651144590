<template>
  <div>
    <div class="my-3">
      <b-button id="popover-reactive-1"
                variant="primary" ref="button" class="mr-2"
                :class="disableButton ? 'spinner spinner-white spinner-left' : ''"
                :disabled="disableButton"
      >
        <i class="la la-file-excel"></i> Xuất google sheet
      </b-button>
    </div>
    <b-popover
        target="popover-reactive-1"
        triggers="click"
        :show.sync="popoverShow"
        placement="auto"
        container="my-container"
        ref="popover"
        @show="onShow"
        @hidden="onHidden"
    >
      <template #title>
        <b-button @click="onClose" class="close" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        Dánh sách thư mục
      </template>
      <div>
        <b-form-group
            label-for="popover-input-1"
            label-cols="3"
            :state="validateState"
            class="mb-1"
            invalid-feedback="Không được để trống"
            size="lg"
        >
          <b-form-select
              id="popover-input-1"
              v-model="folderData"
              :state="validateState"
              :options="options"
              size="sm"
              class="mb-3"
          ></b-form-select>
        </b-form-group>
        <b-button @click="onClose" size="sm" variant="danger">Cancel</b-button>
        <b-button @click="onOk" size="sm" variant="primary" class="ml-2">Ok</b-button>
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "ExportGoogleSheet",
  props: {
    options: {
      type: Array,
      default: () => { return [] }
    },
    disableButton: false
  },
  data(){
    return{
      path: '',
      showPop: false,
      folderData: '',
      validateState: null,
      popoverShow: false,
    }
  },
  methods: {
    onClose() {
      this.popoverShow = false
    },
    onOk() {
      if (!this.folderData) {
        this.validateState = false
      }
      if (this.folderData) {
        this.onClose()
        this.$emit('exportGoogleSheet');
      }
    },
    onShow() {
      this.folderData = ''
      this.validateState = null
    },
    onHidden() {
      this.focusRef(this.$refs.button)
    },
    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          ;(ref.$el || ref).focus()
        })
      })
    },
  }
}
</script>

<style scoped>

</style>