<template>
  <div style="">
    <div class="container header_detail d-flex justify-content-between py-4">
      <h4 style="margin-top: 10px; margin-left: 23px">Chấm điểm {{currentTest == 0 ?'Speaking':'Writing'}}: {{ this.$route.query.name ?? "" }} </h4>
      <b-button v-if="(currentTest !=2 && !isChildTest) " @click="backPageDetail()"><i class="fa fa-arrow-left"></i> Quay lại</b-button>
    </div>
    <hr style="margin-top: 0">
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "HeaderMarkTheExam",
  props:{
    currentTest:{
      default(){
        return null
      }
    },
    isChildTest:{
      default(){
        return false
      }
    }
  },
  methods:{
    backPageDetail(){
      let test_input_schedule_id = this.$route.params.id
      router.push({name: 'task-details', params: {id: test_input_schedule_id}})
    }
  }
}
</script>

<style scoped>

</style>