<template>

</template>

<script>
export default {
  name: "ComboItem"
}
</script>

<style scoped>

</style>