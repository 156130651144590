<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <el-input placeholder="Please input"></el-input>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-4">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Tất cả section
              </h3>
            </div>
          </div>
          <div class="card-body">
            <div class="col-md-12">
              <draggable
                  :list="list"
                  :disabled="!enabled"
                  class="list-group"
                  ghost-class="ghost"
                  :move="checkMove"
                  @start="dragging = true"
                  @end="dragging = false"
              >
                <div
                    class="list-group-item mt-2 cursor-pointer border-course-item"
                    v-for="(element, index) in list"
                    :key="element.name"
                >
                  {{ element.name }} <i class="fa fa-plus dot mr-4 float-right"></i>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Template Content
              </h3>
            </div>
          </div>
          <div class="card-body">
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item :title="item.name" :name="index" v-for="(item, index) in list" :key="index">
                <TempTitle v-if="item.id == 1"></TempTitle>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import TempTitle from "./sub-components/TempTitle";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";

export default {
  name: "TemplateIndex",
  components: {
    TempTitle,
    draggable
  },
  data() {
    return {
      enabled: true,
      list: [
        {name: "Form search", id: 0},
        {name: "Header", id: 1},
        {name: "Danh sách tin tức", id: 2},
        {name: "Danh sách khóa học", id: 3},
        {name: "Trung tâm", id: 4},
        {name: "Kiểm tra đầu vào", id: 5},
        {name: "Promotion", id: 6},
      ],
      dragging: false,
      activeNames: ['0']
    }
  },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Quản lý template", route: 'template-index'},
        // {title: "Quản lý template"}
      ]);
    },
  methods: {
    checkMove: function (e) {
      console.log('remove')
    },
    handleChange(val) {
      console.log(val);
    }
  }
}
</script>

<style>
i.dot {
  background: #4c0192;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
}

.list-group-item {
  border-top-width: thin;
  background: #ededed;
  font-weight: bold;
}

.el-collapse-item {
  margin-top: 10px !important;
  border: 1px solid #CACACA;
  border-radius: 2px;
  background: #ededed;
}

.el-collapse-item__header {
  background: none;
  margin-left: 10px;
  font-weight: bold;
}

.el-collapse-item__content {
  background: #ededed;
}

.el-collapse {
  border: none;
}

</style>