<template>
  <div class="w-100 detail-tai-chinh">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Danh sách hợp đồng</h4>

        <table class="table">
          <thead>
          <tr>
            <th scope="col">Mã HĐ</th>
            <th scope="col">Trung tâm</th>
            <th scope="col">Chi nhánh</th>
            <th scope="col">Giá trị hợp đồng</th>
            <th scope="col" class="min-w-200px">Người giới thiệu</th>
            <th scope="col" class="min-w-200px">Người tư vấn</th>
            <th scope="col" class="min-w-200px">Thời gian tạo hợp đồng</th>
            <th scope="col" class="min-w-150px">Hành động</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item , index) in contracts" :key="index">
            <td>
              <a href="javascript:void(0)" @click="viewContract(item.id)">{{ item.id }}</a>
            </td>
            <td>{{ item.center ? item.center.name : '' }}</td>
            <td>{{ item.branch ? item.branch.name : '' }}</td>
            <td>{{ item.total_after_voucher | formatPrice }}</td>
            <td>{{ item.get_ma_gioi_thieu ? (item.get_ma_gioi_thieu.id + 100000) : (item.customer.get_nguoi_gioi_thieu ? item.customer.get_nguoi_gioi_thieu.id + 100000 : '') + ' - ' }}
              {{ item.get_ma_gioi_thieu ? item.get_ma_gioi_thieu.name : (item.customer.get_nguoi_gioi_thieu ? item.customer.get_nguoi_gioi_thieu.name : '') }}
            </td>
            <td>{{item.consultants_user ? (parseInt(item.consultants_user.id) + 100000) : (item.customer.get_tvv ? item.customer.get_tvv.id + 100000 : '') + ' - '  }}
              {{ item.consultants_user ? item.consultants_user.name : (item.customer.get_tvv ? item.customer.get_tvv.name : '') }}
            </td>
            <td>{{ item.created_at  |formatDateTimeVietnam}}</td>
            <td class="action pt-1">
              <b-button class="" class="mr-2 p-2 btn btn-primary"
                        v-b-modal.modal-change-contract
                        @click="showModalChangeContract(item)"
                        title="Sửa"
              >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
              </b-button>
              <button class="btn btn-success p-2"
                      v-b-modal.modal-history-contract
                      @click="showModalHistoryContract(item.id)"
                      title="Lịch sử"
              >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                  </svg>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal-change-contract
        @updateContractSuccess="updateContractSuccess"
        :id_prop="showDetail.id"
        :created_user_prop="showDetail.created_user"
        :nguoi_tu_van_prop="showDetail.nguoi_tu_van"
    ></modal-change-contract>
    <modal-history-contract :data="history_update_contract">
    </modal-history-contract>
    <ContractDetail :showModal="showDetailContract" :contract="contract"
                    @clicked="onclickViewContractDetail"></ContractDetail>
  </div>
</template>

<script>


import {GET_CUSTOMERS_BY_USER_ID} from "@/core/services/store/customer/customer.module";
import {
  GET_CONTRACT_BY_ID,
  GET_CONTRACT_BY_USER_ID,
  GET_HISTORY_UPDATE_CONTRACT
} from "@/core/services/store/contract/contracts.module";
import ModalChangeContract from "@/view/pages/customers/Modal/ModalChangeContract";
import ModalHistoryContract from "@/view/pages/customers/Modal/ModalHistoryContract";
import ContractDetail from "@/view/pages/business/ContractDetail";

export default {
  name: "CrmKinhDoanh",
  data() {
    return {
      contracts: [],
      customer: [],
      history_update_contract: [],
      showDetail: {
        id: 0,
        created_user: 0,
        nguoi_tu_van: 0
      },
      showDetailContract: false,
      contract: {}
    }
  },

  components: {
    ModalChangeContract,
    ModalHistoryContract,
    ContractDetail
  },
  mounted() {
    this.getContract();
    this.getCustomer();
  },
  methods: {
    showModalChangeContract: function (contract) {
      this.showDetail.id = contract ? contract.id : 0;
      this.showDetail.created_user = contract ? Number(contract.ma_gioi_thieu) : 0;
      this.showDetail.nguoi_tu_van = contract ? Number(contract.nguoi_tu_van) : 0;
    },
    getContract() {
      let id = this.$route.params.id;
      this.$store.dispatch(GET_CONTRACT_BY_USER_ID, {
        id: id
      }).then(res => {
        if (!res.error) {
          this.contracts = res.data;
        }
      })
    },
    updateContractSuccess() {
      this.getContract();
      this.getCustomer();
    },
    showModalHistoryContract: function (contract_id) {
      this.$store.dispatch(GET_HISTORY_UPDATE_CONTRACT, {
        id: contract_id
      }).then(res => {
        if (!res.error) {
          this.history_update_contract = res.data;
        }
      })
    },

    getCustomer() {
      let id = this.$route.params.id
      this.$store.dispatch(GET_CUSTOMERS_BY_USER_ID, {
        id: id
      }).then(res => {
        if (!res.error) {
          this.customer = res.data;
        }
      })
    },
    viewContract: async function (contract_id) {
      await this.getContractDetail(contract_id);
      this.showDetailContract = true;
    },
    getContractDetail: async function (contract_id) {
      await this.$store.dispatch(GET_CONTRACT_BY_ID, contract_id).then((res) => {
        this.contract = res.data;
      });
    },
    onclickViewContractDetail() {
      this.showDetailContract = false;
    }
  }
}
</script>

<style scoped>
</style>