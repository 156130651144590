<template>
    <div class="container">
        <h2 class="item-title text-uppercase text-primary text-left mb-3">Thông tin học viên</h2>
        <section class="item row">
            <section class="item-name col-4">
                <p class="item-name-custom font-weight-bolder text-left">Họ và tên : <span
                        class="font-weight-normal">{{ testIncome.user_name }}</span></p>
                <p class="item-name-custom font-weight-bolder text-left">Số điện thoại : <span
                        class="item-name-number font-weight-normal">{{ testIncome.user_phone }}</span></p>
            </section>
            <section class="item-comment-total col-4" v-if="testIncome.type_test != 'daihoc'">
                <p class="font-weight-bolder text-left">Nhận xét tổng quan</p>
                <section class="item-result-box">
                    <section class="item-result-comment">
                        <p class="item-result-text text-left">{{ finalModel.comment }}</p>
                    </section>
                </section>
            </section>
            <section class="item-comment-total col-4" v-if="testIncome.type_test != 'daihoc'">
                <p class="font-weight-bolder text-left">Khóa học gợi ý</p>
                <section class="item-result-box">
                    <section class="item-result-comment">
                        <p class="item-result-text text-left">{{ finalModel.course }}</p>
                    </section>
                </section>
            </section>
            <section class="item-result col-12">
                <h2 class="text-left text-uppercase text-primary mb-3">Kết quả và nhận xét</h2>
                <section class="row" v-if="testIncome.type_test != 'daihoc'">
                    <section class="item-result-box col-3">
                        <p class="item-result-name text-left"><span class="font-weight-bolder">Speaking: </span><span
                                class="item-result-number">{{ finalModel.speaking_point }}</span></p>
                        <p class="item-result-txt font-weight-bolder text-left">Nhận xét của giảng viên:</p>
                        <section class="item-result-comment">
                            <p class="item-result-text text-left">{{ finalModel.speaking_comment }}</p>
                        </section>
                    </section>
                    <section class="item-result-box col-3" v-if="marks && marks['reading']">
                        <p class="item-result-name text-left"><span class="font-weight-bolder">Reading: </span><span
                                class="item-result-number">{{ marks['reading'].exactly + '/' + marks['reading'].total }}</span>
                        </p>
                        <p class="item-result-txt font-weight-bolder text-left">Nhận xét của giảng viên:</p>
                        <section class="item-result-comment"><p class="item-result-text text-left">{{
                            finalModel.reading_comment
                            }}</p>
                        </section>
                    </section>
                    <section class="item-result-box col-3" v-if="marks && marks['listening']">
                        <p class="item-result-name text-left"><span class="font-weight-bolder">Listening: </span><span
                                class="item-result-number">{{ marks['listening'].exactly + '/' + marks['listening'].total }}</span>
                        </p>
                        <p class="item-result-txt font-weight-bolder text-left">Nhận xét của giảng viên:</p>
                        <section class="item-result-comment">
                            <p class="item-result-text text-left">{{ finalModel.listening_comment }}</p>
                        </section>
                    </section>
                    <section class="item-result-box col-3" v-if="marks && marks['writing']">
                        <p class="item-result-name text-left"><span class="font-weight-bolder">Writing: </span><span
                                class="item-result-number">{{ marks['writing'].exchange }}</span>
                        </p>
                        <p class="item-result-txt font-weight-bolder text-left">Nhận xét của giảng viên:</p>
                        <section class="item-result-comment">
                            <p class="item-result-text text-left">{{ finalModel.writing_comment }}</p>
                        </section>
                    </section>
                    <section class="item-result-box col-3" v-if="marks && marks['general test']">

                        <p class="item-result-name text-left"><span
                                class="font-weight-bolder">General test: </span><span
                                class="item-result-number">{{
                marks['general test'].exactly + '/' + marks['general test'].total
              }}</span>
                        </p>
                        <p class="item-result-txt font-weight-bolder text-left">Nhận xét của giảng viên:</p>
                        <section class="item-result-comment">
                            <p class="item-result-text text-left">{{ finalModel.general_comment }}</p>
                        </section>
                    </section>
                </section>
                <section class="row" v-else>
                    <section class="item-result-box col-12" v-if="marks && marks['reading']">
                        <p class="item-result-name text-left"><span class="font-weight-bolder">Số câu đúng: </span><span
                                class="item-result-number">{{ marks['reading'].exactly + '/' + marks['reading'].total }}</span>
                        </p>
                        <p class="item-result-name text-left"><span class="font-weight-bolder">Số điểm: </span><span
                                class="item-result-number">{{ (marks['reading'].exactly * 0.2).toFixed(1) }}</span></p>
                        <p class="item-result-txt font-weight-bolder text-left">Nhận xét của giảng viên:</p>
                        <section class="item-result-comment text-left"><p class="item-result-text">{{ finalModel.comment
                            }}</p>
                        </section>
                        <p class="item-result-txt font-weight-bolder text-left">Khóa học gợi ý:</p>
                        <section class="item-result-comment text-left"><p class="item-result-text">{{ finalModel.course
                            }}</p>
                        </section>
                        <!-- <p class="item-result-name"><span class="font-weight-bolder">General: </span><span
                                class="item-result-number">{{marks['general test'].exchange + '/' + totalPoint}}</span>
                        </p> -->
                        <!-- <p class="item-result-txt font-weight-bolder">Nhận xét của giảng viên:</p>
                        <section class="item-result-comment"><p class="item-result-text">
                            {{finalModel.general_comment}}</p>

                        </section> -->
                    </section>
                </section>
            </section>
            <section class="w-50 mt-3 mb-3 text-left">
                <label for="copy_link">Link kết quả test gửi khách hàng</label>
                <b-input-group size="xm">
                    <b-form-input id="copy_link" v-model="educoachLink" placeholder="Nhập link kết quả"></b-form-input>
                    <b-input-group-append>
                        <b-button variant="success" id="copy" @click="copyLink">Copy link</b-button>
                    </b-input-group-append>
                </b-input-group>
            </section>
        </section>
    </div>
</template>

<script>
    export default {
        props: {
            user: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            finalModel: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            testIncome: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            marks: {
                type: Object,
                default: () => {
                    return {}
                }
            },
        },
        data() {
            return {
                model: {
                    firstName: '',
                    lastName: '',
                    email: ''
                },
                educoachLink: '',
                tooltipText: '',
                totalPoint: '',
                CENTER_ECOACH: 295,
                CENTER_IM: 516,
            }
        },
        mounted() {
            this.checkUrlResult();
        },
        watch: {
            testIncome: {
                handler() {
                    this.totalPoint = this.testIncome.type_test === 'giaotiep' ? 10 : 9;
                    this.finalModel.comment = this.testIncome.comment;
                    this.finalModel.course = this.testIncome.course_suggest;
                    this.finalModel.reading_comment = this.testIncome.reading_comment
                    this.finalModel.listening_comment = this.testIncome.listening_comment
                    this.finalModel.writing_comment = this.testIncome.writing_comment
                    this.finalModel.general_comment = this.testIncome.general_comment
                    this.finalModel.speaking_comment = this.testIncome.speaking_comment
                },
                deep: true,
                immediate: true,
            },
            marks: {
                handler() {
                    this.finalModel.speaking_point = this.marks?.speaking.exactly
                    // this.marks = this.marks;
                },
                deep: true,
                immediate: true,
            }
        },
        methods: {
            copyLink() {
                this.$message.success('Đã copy');
                navigator.clipboard.writeText(this.educoachLink);
            },
            checkUrlResult() {
                //Ecoach
                if (this.testIncome.center_id == this.CENTER_ECOACH) {
                    this.educoachLink = process.env.VUE_APP_URL_ECOACH + '/get-result?test_income_id=' + this.testIncome.id;
                }
                // Ielts Mentor
                if (this.testIncome.center_id == this.CENTER_IM) {
                    this.educoachLink = process.env.VUE_APP_URL_IM + '/get-result?test_income_id=' + this.testIncome.id;
                }
            }
        }

    }
</script>

<style>
    .item-result-number {
        color: red;
    }
</style>
