<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
          FAQ
      </div>
      <div class="card-title">
          <router-link
              class="font-weight-bold font-size-3  btn btn-success"
              :to="{ name: 'support-question-create' }"
            >Thêm mới
          </router-link>
      </div>
    </div>

    <div class="card-body">
      <div class="example mb-10">
          <div class="form-group">
                <div class="input-group row">
                      <div class="col-md-3">
                          <input type="text" class="form-control input-search"
                              placeholder="Nhập tên faq"
                              v-model="query_name"
                              aria-describedby="basic-addon2">
                      </div>
                      <div class="col-md-3">
                          <multiselect v-model="category" :options="categories"
                              placeholder="Chọn danh mục"
                              label="name"
                              track-by="name"
                              @input="updateCategory"
                              >
                          </multiselect>
                      </div>
                      <div class="col-md-3">
                          <multiselect v-model="type" :options="types"
                              placeholder="Chọn loại danh mục"
                              label="name"
                              track-by="name"
                              @input="updateType"
                              >
                          </multiselect>
                      </div>
                </div>
            </div>
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
              <tr>
                <th>STT</th>
                <th>Name</th>
                <th>Danh mục</th>
                <th>Loại danh mục</th>
                <th>Lượt truy cập</th>
                <th>Order</th>
                <th>Thời gian tạo</th>
                <th style="min-width: 100px">Hành động</th>
              </tr>
            </thead>
            <tbody v-if="data.length > 0" >
              <tr v-for="(item, index) in data" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.category.name }}</td>
                <td>{{ item.type.name }}</td>
                <td>{{ item.count_view }}</td>
                <td>{{ item.order}}</td>
                <td>{{ item.created_at | formatDateTimeAsia }}</td>
                <td>
                    <router-link title="Chỉnh sửa" class="btn btn-icon mr-2 btn-outline-info" :to="{ name: 'support-question-update', params: {id : item.id} }">
                                    <i class="fas fa-pen-nib"></i>
                                </router-link>
                    <a title="Xóa" @click="deleteCategory(item.id)" href="javascript:"
                        class="btn btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
                <paginate
                        v-model="page"
                        :page-count="total_pages"
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="getListQuestion"
                        :prev-text="'Trước'"
                        :next-text="'Sau'"
                        :container-class="'pagination b-pagination'"
                        :pageLinkClass="'page-link'"
                        :page-class="'page-item'"
                        :next-link-class="'next-link-item'"
                        :prev-link-class="'prev-link-item'"
                        :prev-class="'page-link'"
                        :next-class="'page-link'"
                >
                </paginate>
            </div>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
  import { GET_QUESTION,DELETE_QUESTION}
    from '../../../core/services/store/support/question.module';
  import {GET_ALL_CATEGORY,GET_TYPE_BY_CATEGORY}
    from '../../../core/services/store/support/type.module';
  import Swal from "sweetalert2";
  import Multiselect from 'vue-multiselect'


  export default {
    name: "SupportQuestionList",

    components: {
        Multiselect
    },
    data() {
      return {
        page: 1,
        total_pages:0,
        data: [],
        checkVisibleAddEditForm: false,
        form: {
          name: '',
        },
        category:null,
        type:null,
        form_add: false,
        categories: [],
        types : [],
        query_name:"",
        category_id : null,
        type_id: null
      }
    },

    mounted() {
        this.getListQuestion();
        this.getAllCategory();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Hỗ trợ"},
            {title: "Quản lý FAQ"}
        ]);
    },
    computed: {
    },
    watch: {
        query_name(after, before) {
            if (after !== before) {
                this.filterByName();
            }
        },
    },
    methods: {
      updateCategory ($event) {
        if($event) {
        this.category_id = $event.id;
        this.getTypeByCategory($event.id);
        this.getListQuestion();
        } else {
           this.category_id = null; 
           this.type = null;
           this.type_id = null;
           this.getListQuestion();
        }
      },
      updateType($event){
        if($event) {
          this.type_id = $event.id; 
          this.getListQuestion();
        } else {
           this.type_id = null; 
           this.getListQuestion();
        }
      },
      updateStateCategory($event){
      },
      changeName(){
        this.form.slug = this.removeAccents(this.form.name);
      },
      removeAccents(str) {
        str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return str.replaceAll(' ','-');
      },
      getListQuestion() {
           this.$store.dispatch(GET_QUESTION, {
            page: this.page,
            name : this.query_name,
            category_id:this.category_id,
            type_id:this.type_id,
        }).then((res) => {
            this.data = res.data.data;
            this.total_pages = res.data.last_page;
        });           
      },
      filterByName(){
          setTimeout(() => {
              this.$store.dispatch(GET_QUESTION, {
                  page: this.page,
                  name : this.query_name,
                  category_id:this.category_id,
                  type_id:this.type_id,
              }).then((res) => {
                  this.data = res.data.data;
                  this.total_pages = res.data.last_page;
        });   
      }, 700);
      },
      getAllCategory() {
        this.$store.dispatch(GET_ALL_CATEGORY).then((res) => {
            this.categories = res.data;
        });
      },
      getTypeByCategory(category_id) {
        this.$store.dispatch(GET_TYPE_BY_CATEGORY, {category_id:category_id}).then((res) => {
            this.types = res.data;
        });
      },
      deleteCategory(id){
            Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire("Xóa", "", "success");
                        this.$store.dispatch(DELETE_QUESTION, id).then((res) => {
                            if (res.error) {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'danger',
                                    solid: true
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'success',
                                    solid: true
                                });
                                this.getListQuestion();
                            }
                        });
                    }
                });
      },
    }
  }

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
