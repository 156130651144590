<template>
    <div>
        <button title="Chấm điểm" v-b-modal="`modal-mark-${item.id}`"
                :disabled="item.schedule_mark_test && (item.schedule_mark_test.status != 0 &&
                 item.schedule_mark_test.status != 3 &&
                item.schedule_mark_test.status != 4)"
                class="btn btn-xs btn-icon mr-2 btn-outline-info">
            <i class="fas far fa-edit"></i>
        </button>
        <b-modal :id="'modal-mark-' + item.id"
                 hide-footer
                 :centered="true"
                 :title="'Chấm điểm ' + item.user.name"
                 size="lg"
                 ok-title="Lưu"
                 cancel-title="Hủy bỏ"
                 scrollable
        >
            <div class="container mb-4">
                <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                    <form>
                        <div v-if="test.course_schedule && test.course_schedule.folder_name != 'from_zero'">
                          <div class="row font-weight-bold"><p>1. Speaking</p></div>
                          <div class="card">
                              <div class="card-body">
                                  <div class="form-group">
                                      <label>Chấm điểm:</label>
                                      <ValidationProvider vid="speaking_point" name="Điểm speaking" rules="required"
                                                          v-slot="{ errors,classes }">
                                          <el-select
                                                  v-model="marks.speaking_point"
                                                  placeholder="Điểm speaking"
                                                  @input="handlePointSpeaking"
                                                  clearable
                                          >
                                              <el-option
                                                      label="Enter your name"
                                                      v-for="item in speaking_point"
                                                      :key="item.value"
                                                      :label="item.label"
                                                      :value="item.value"
                                                      :class="classes"
                                              >
                                              </el-option>
                                          </el-select>
                                          <div class="fv-plugins-message-container">
                                              <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                                  errors[0] }}
                                              </div>
                                          </div>
                                      </ValidationProvider>
                                  </div>
                                  <div class="row mt-4">
                                      <div class="col-md-12">
                                          <div class="form-group">
                                              <label>Nhận xét:</label>
                                              <ValidationProvider vid="speaking_comment" name="Nhận xét"
                                                                  rules="required"
                                                                  v-slot="{ errors,classes }">
                                                  <b-form-textarea
                                                          id="textarea"
                                                          v-model="marks.speaking_comment"
                                                          placeholder="Nhận xét"
                                                          rows="3"
                                                          max-rows="6"
                                                          :class="classes"
                                                          @input="handleCommentSpeaking"
                                                  ></b-form-textarea>
                                                  <div class="fv-plugins-message-container">
                                                      <div data-field="name" data-validator="notEmpty"
                                                           class="fv-help-block">{{
                                                          errors[0] }}
                                                      </div>
                                                  </div>
                                              </ValidationProvider>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div>
                          <div v-if="test.course_schedule && test.course_schedule.folder_name != 'from_zero'" class="row font-weight-bold mt-4"><p>2. Listening - Reading - Use of English</p></div>
                          <div v-else class="row font-weight-bold mt-4"><p>1. Listening - Reading - Use of English</p></div>
                          <div class="card">
                              <div class="card-body">
                                  <div class="row d-flex justify-content-center" v-if="detail_mark.course_type">
                                      <div class="col-md-4 border border-secondary">
                                          <p class="font-weight-bold mt-3">Listening</p>
                                          <div class="row">
                                              <span class="col-md-6">Số câu đúng:</span>
                                              <span class="col-md-6">{{point_auto_listening.count_listening }}/{{ point_auto_listening.total_listening}}</span>
                                          </div>
                                          <div class="row mb-3">
                                              <span class="col-md-6">Số điểm:</span>
                                              <span class="col-md-6">{{point_auto_listening.avg_listening }}/{{point_ladder_default}}</span>
                                          </div>
                                      </div>
                                      <div class="col-md-4 border border-secondary">
                                          <p class="font-weight-bold mt-3">Reading</p>
                                          <div class="row">
                                              <span class="col-md-6">Số câu đúng:</span>
                                              <span class="col-md-6">{{point_auto_reading.count_reading }}/{{ point_auto_reading.total_reading}}</span>
                                          </div>
                                          <div class="row mb-3">
                                              <span class="col-md-6">Số điểm:</span>
                                              <span class="col-md-6">{{point_auto_reading.avg_reading }}/{{point_ladder_default}}</span>
                                          </div>
                                      </div>
                                      <div class="col-md-4 border border-secondary"
                                           v-if="point_auto_use_of_english.count_use_of_english">
                                          <p class="font-weight-bold mt-3">Use of English</p>
                                          <div class="row">
                                              <span class="col-md-6">Số câu đúng:</span>
                                              <span class="col-md-6">{{point_auto_use_of_english.count_use_of_english }}/{{ point_auto_use_of_english.total_use_of_english}}</span>
                                          </div>
                                          <div class="row mb-3">
                                              <span class="col-md-6">Số điểm:</span>
                                              <span class="col-md-6">{{point_auto_use_of_english.avg_use_of_english }}/{{point_ladder_default}}</span>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row mt-4">
                                      <div class="col-md-12">
                                          <div class="form-group">
                                              <label>Nhận xét:</label>
                                              <ValidationProvider vid="auto_mark_comment" name="Nhận xét"
                                                                  rules="required"
                                                                  v-slot="{ errors,classes }">
                                                  <b-form-textarea
                                                          id="textarea"
                                                          v-model="marks.auto_mark_comment"
                                                          placeholder="Nhận xét"
                                                          rows="3"
                                                          max-rows="6"
                                                          :class="classes"
                                                  ></b-form-textarea>
                                                  <div class="fv-plugins-message-container">
                                                      <div data-field="name" data-validator="notEmpty"
                                                           class="fv-help-block">{{
                                                          errors[0] }}
                                                      </div>
                                                  </div>
                                              </ValidationProvider>
                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>
                        <div v-if="test.course_schedule && test.course_schedule.folder_name != 'from_zero'">
                          <div class="row font-weight-bold mt-4"><p>3. Writing</p></div>
                          <div class="card">
                              <div class="card-body">
                                  <div class="row d-flex justify-content-center">
                                      <div class="col-md-4 border border-secondary">
                                          <p class="font-weight-bold mt-3">Writing</p>
                                          <div class="row">
                                              <span class="col-md-6">Số câu đúng:</span>
                                              <span class="col-md-6">{{point_auto_writing.count_writing }}/5</span>
                                          </div>
                                          <div class="row mb-3">
                                              <span class="col-md-6">Số điểm:</span>
                                              <span class="col-md-6">{{point_auto_writing.avg_writing }}/{{point_ladder_default}}</span>
                                          </div>
                                      </div>
                                  </div>
                                  <div v-for="(item, index) in marks.writing" class="mt-4 bg-light">
                                      <div class="col-md-12">
                                          <div class="form-group">
                                              <label class="mt-4">Bài làm của học viên:</label>
                                              <b-form-textarea
                                                      disabled
                                                      id="textarea"
                                                      v-model="item.student_answer"
                                                      placeholder="Bài làm của học viên"
                                                      rows="3"
                                                      max-rows="6"
                                              ></b-form-textarea>
                                          </div>
                                      </div>
                                      <div class="col-md-12">
                                          <div class="form-group">
                                              <label>Chấm điểm:</label>
                                              <ValidationProvider :vid="'writing.'+index + '.score'" name="Điểm writing"
                                                                  rules="required"
                                                                  v-slot="{ errors,classes }">
                                                  <el-select v-model="item.score" placeholder="Điểm writing" clearable
                                                             class="pb-6">
                                                      <el-option
                                                              label="Vui lòng chọn điểm"
                                                              v-for="item in writing_point"
                                                              :key="item.value"
                                                              :label="item.label"
                                                              :value="item.value"
                                                              :class="classes"
                                                      >
                                                      </el-option>
                                                  </el-select>
                                                  <div class="fv-plugins-message-container">
                                                      <div data-field="name" data-validator="notEmpty"
                                                           class="fv-help-block">{{
                                                          errors[0] }}
                                                      </div>
                                                  </div>
                                              </ValidationProvider>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-12">
                                      <div class="form-group">
                                          <label>Nhận xét:</label>
                                          <ValidationProvider vid="writing_comment" name="Nhận xét"
                                                              rules="required"
                                                              v-slot="{ errors,classes }">
                                              <b-form-textarea
                                                      id="textarea"
                                                      v-model="marks.writing_comment"
                                                      placeholder="Nhận xét"
                                                      rows="3"
                                                      max-rows="6"
                                                      :class="classes"
                                              ></b-form-textarea>
                                              <div class="fv-plugins-message-container">
                                                  <div data-field="name" data-validator="notEmpty"
                                                       class="fv-help-block">{{
                                                      errors[0] }}
                                                  </div>
                                              </div>
                                          </ValidationProvider>
                                      </div>
                                  </div>
                              </div>
                          </div>
                       </div>
                    </form>
                </ValidationObserver>
            </div>
            <b-button
                    :disabled="item.status ==3 || item.status == 0"
                    variant="success"
                    size="md"
                    class="float-right"
                    @click="submitMark"
            >
                Chấm điểm
            </b-button>
        </b-modal>
    </div>
</template>

<script>
    import {GET_DETAIL_MARK, SUBMIT_MARK} from "../../../../core/services/store/exam/exam.module";
    import {
        POINT,
        SPEAKING_POINT,
        PRE_IELTS_SPEAKING_POINT,
        PRE_IELTS_WRITING_POINT
    } from "../../../../core/option/testIncomeOption.js";
    import Swal from "sweetalert2";

    export default {
        name: "ModalMark",
        props: {
            item: {
                type: Object,
                default: () => {
                    return null;
                }
            },
            test: {
                type: Object,
                default: () => {
                    return null;
                }
            }
        },
        data() {
            return {
                speaking_point: [],
                writing_point: [],
                save_local_speaking: {
                    id: '',
                    point: '',
                    comment: ''
                },
                POINT,
                SPEAKING_POINT,
                PRE_IELTS_SPEAKING_POINT,
                PRE_IELTS_WRITING_POINT,
                detail_mark: {},
                value: '',
                point_ladder_default: 10,
                point_auto_listening: {
                    total_listening: '',
                    count_listening: '',
                    avg_listening: '',
                },
                point_auto_reading: {
                    total_reading: '',
                    count_reading: '',
                    avg_reading: '',
                },
                point_auto_writing: {
                    total_writing: '',
                    count_writing: '',
                    avg_writing: '',
                },
                point_auto_use_of_english: {
                    total_use_of_english: '',
                    count_use_of_english: '',
                    avg_use_of_english: '',
                },
                avg_speaking: '',

                marks: {
                    auto_mark_comment: '',
                    writing_comment: '',
                    speaking_comment: '',
                    speaking_point: '',
                    writing: [],
                }
            }
        },
        async mounted() {
            this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
                let modal_edit_date = 'modal-mark-' + this.item.id;
                if (modalId == modal_edit_date) {
                    this.getDetailMark();
                    this.checkPointDefault();
                }
            })

            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                let modal_edit_date = 'modal-mark-' + this.item.id;
                if (modalId == modal_edit_date) {
                }
            })
        },
        methods: {
            checkPointDefault() {
                let schedule_mark_test = this.item.schedule_mark_test;
                if (schedule_mark_test && schedule_mark_test.course_type) {
                    if (schedule_mark_test.course_type === 'pre_ielts') {
                        this.speaking_point = PRE_IELTS_SPEAKING_POINT;
                        this.writing_point = PRE_IELTS_WRITING_POINT;
                    } else if (schedule_mark_test.course_type === 'ielts') {
                        this.speaking_point = POINT;
                        this.writing_point = POINT;
                    } else {
                        this.speaking_point = SPEAKING_POINT;
                        this.writing_point = PRE_IELTS_WRITING_POINT;
                    }
                }
            },
            submitMark() {
                this.$store.dispatch(SUBMIT_MARK, {
                        id: this.detail_mark.id,
                        ...this.marks
                    }
                ).then((data) => {
                    this.getDetailMark();
                    this.$emit('mark-success');
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Chấm điểm thành công',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.hideModal();
                }).catch((e) => {
                    if (e.response.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.response.data.data.message_validate_form);
                    }
                })
            },
            hideModal() {
                this.$root.$emit('bv::hide::modal', 'modal-mark-' + this.item.id)
            },
            getDetailMark() {
                this.$store.dispatch(GET_DETAIL_MARK, {
                    course_student_id: this.item.id,
                    lesson: this.test.lesson
                }).then((data) => {
                    this.detail_mark = data.data;
                    this.handlePoint();
                    this.checkDetailLadderPoint();
                    this.setDefaultMark();
                })
            },
            markReset() {
                return {
                    auto_mark_comment: '',
                    writing_comment: '',
                    speaking_comment: '',
                    speaking_point: this.avg_speaking,
                    writing: [],
                }
            },
            setDefaultMark() {
                this.marks.auto_mark_comment = this.detail_mark.auto_mark_comment;
                this.marks.writing_comment = this.detail_mark.writing_comment;
                this.marks.speaking_comment = this.detail_mark.speaking_comment;
                this.marks.speaking_point = this.avg_speaking;
                this.detail_mark.writing.map((item) => {
                    this.marks.writing.push({
                        outcomes_id: item.outcomes_id,
                        score: item.score
                    })
                })
                this.getLocalStorageSpeaking();
            },
            handlePointSpeaking($event) {
                this.save_local_speaking.point = $event;
                this.save_local_speaking.id = this.item.id;
                this.setLocalStorageSpeaking();
            },
            handleCommentSpeaking($event) {
                this.save_local_speaking.comment = $event;
                this.save_local_speaking.id = this.item.id;
                this.setLocalStorageSpeaking();
            },
            setLocalStorageSpeaking() {
                localStorage.setItem('speaking_local', JSON.stringify(this.save_local_speaking));
            },
            getLocalStorageSpeaking() {
                let data = localStorage.getItem('speaking_local');
                data = JSON.parse(data);
                if (parseInt(data.id) == this.item.id) {
                    this.marks.speaking_point = data.point;
                    this.marks.speaking_comment = data.comment;
                }
            },
            checkDetailLadderPoint() {
                if (this.detail_mark.course_type == 'pre_ielts') {
                    this.point_ladder_default = 5;
                } else if (this.detail_mark.course_type == 'ielts') {
                    this.point_ladder_default = 9;
                } else {
                    this.point_ladder_default = 10; // giao-tiep
                }
            },
            handlePoint() {
                if (this.detail_mark.detail.length) {
                    let detail = this.detail_mark.detail;
                    detail.map((item) => {
                        // Listening
                        if (item.key == 'total_listening') {
                            this.point_auto_listening.total_listening = item.value;
                        }
                        if (item.key == 'count_listening') {
                            this.point_auto_listening.count_listening = item.value;
                        }
                        if (item.key == 'avg_listening') {
                            this.point_auto_listening.avg_listening = item.value;
                        }
                        //reading
                        if (item.key == 'total_reading') {
                            this.point_auto_reading.total_reading = item.value;
                        }
                        if (item.key == 'count_reading') {
                            this.point_auto_reading.count_reading = item.value;
                        }
                        if (item.key == 'avg_reading') {
                            this.point_auto_reading.avg_reading = item.value;
                        }
                        //writing
                        if (item.key == 'total_writing') {
                            this.point_auto_writing.total_writing = item.value;
                        }
                        if (item.key == 'count_writing') {
                            this.point_auto_writing.count_writing = item.value;
                        }
                        if (item.key == 'avg_writing') {
                            this.point_auto_writing.avg_writing = item.value;
                        }
                        //Use of english
                        if (item.key == 'total_use_of_english') {
                            this.point_auto_use_of_english.total_use_of_english = item.value;
                        }
                        if (item.key == 'count_use_of_english') {
                            this.point_auto_use_of_english.count_use_of_english = item.value;
                        }
                        if (item.key == 'avg_use_of_english') {
                            this.point_auto_use_of_english.avg_use_of_english = item.value;
                        }
                        if (item.key == 'avg_speaking') {
                            this.avg_speaking = item.value;
                        }
                    })
                }
            },
        }
    }
</script>

<style scoped>
    .font-weight-bold {
        font-weight: bold !important;
    }

    .el-select {
        display: block;
    }
</style>