<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Tạo mới quyền.
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Tạo mới quyền</span>
      </div>
      <div class="card-toolbar">

        <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'permission-list' }"
        ><i class="fas fa-angle-double-left"></i> Quay lại
        </router-link>
      </div>
    </div>
    <!--end::Header-->
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form  id="permission-add" ref="permission-add" class="form" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card-body">
          <div class="row">
            <div class="col-md-12">
                <div id="input-group-2" role="group" class="form-group">
                  <label id="input-group-2__BV_label_"
                         class="d-block">Tên quyền <span data-v-3736cd64="" class="text-danger">*</span></label>

                  <div>
                    <ValidationProvider name="Tên quyền" rules="required" vid="name" v-slot="{ errors,classes }">
                      <input type="text" ref="name" name="name" class="form-control" :class="classes"
                             placeholder="Tên quyền"
                             v-model=form.name>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group fv-plugins-icon-container">
                  <label>Chọn quyền cha</label>
                    <select2 :options="permissions" v-model="form.parent_id" placeholder="Chọn quyền cha"></select2>
                </div>
                <div id="checkboxes-4" role="group" tabindex="-1" class="bv-no-focus-ring">
                  <div v-for="guard in form.data"  class="custom-control custom-control-inline custom-checkbox b-custom-control-lg">
                    <input type="checkbox"
                           class="custom-control-input"
                           :id="'check'+guard.name"
                           v-model="guard.checked">
                    <label class="custom-control-label" :for="'check'+guard.name">Tạo cho {{guard.name}}</label>
                    <ValidationProvider name="nhóm quyền" rules="required" v-slot="{ errors,classes }">
                      <input type="checkbox"
                             :class="classes"
                             class="custom-control-input"
                             :id="'check'+guard.name"
                             v-model="guard.checked">
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
            </div>
          </div>
      </div>
      <div class="card-footer">
        <button type="submit" :disabled="isDisable" class="btn btn-success">Tạo mới</button>
      </div>
    </form>
    </ValidationObserver>
  </div>
  <!--end::Card-->
</template>

<script>
  import {CREATE_PERMISSION,GET_PERMISSION} from "../../../../core/services/store/user/permission.module";
  import KTUtil from "@/assets/js/components/util";
  import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";
  import Select2 from "@/view/content/Select2";
  const _ = require('lodash');

export default {
    components: {
        Select2
    },
  name: "Create-permission",
  data() {
    return {
        permissions: [],
      form: {
        name: "",
          parent_id : null,
        data: [
          {
            name : "backend",
            checked: true
          },
          {
            name : "noibo",
            checked: false
          },
          {
            name : "educenter",
            checked: false
          }
        ],
      },
      isDisable: false
    };
  },
  mounted: function () {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Tài khoản", route: 'account'},
      {title: "Tạo quyền"}
    ]);
      this.$store.cache.dispatch(GET_PERMISSION, {limit : 10000}).then((res)=>{
          this.permissions = _.map(res.data.data,(item) => {
              item = {id: item.id, text: item.name, area_id : item.area_id};
              return item;
          });
      });
  },
  computed : {
    error : state => state.errors
  },
  methods: {
    onReset()  {
      KTUtil.getById("permission-add").reset();
    },
    onSubmit() {
      this.isDisable = true;
      let name = this.form.name;
      let guard_name = _.reduce(this.form.data, (result, item, key) => {
        if (item.checked === true) {
          result.push(item.name);
        }
        return result;
      }, []);
      if (guard_name.length) {
        // send permission request
        this.$store.dispatch(CREATE_PERMISSION, {parent_id: this.form.parent_id,name, guard_name})
            .then((res) => {
              this.$store.cache.clear(GET_PERMISSION);
              this.$router.push({name: "permission-list"}).then(() => {
                this.$notify({
                  title: 'Thành công',
                  message: res.message,
                  type: 'success'
                });
              });
            }).catch((res) => {
              if (res.data.data.message_validate_form){
                this.$refs.form.setErrors(res.data.data.message_validate_form)
              }
              if (res.data.message){
                this.$notify({
                  title: 'Thất bại',
                  message: res.data.message,
                  type: 'error'
                });
              }
            }).finally(() => {
              this.isDisable = false;
            });
      } else {
        this.$notify({
          title: 'Xác thực dữ liệu',
          message: 'Vui lòng chọn trang cho quyền',
          type: "warning"
        });
        this.isDisable = false;
      }
    }
  },
};
</script>

<style scoped></style>
