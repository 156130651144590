<template>
  <b-modal @hide="onHide" ref="modal-course-type" hide-footer :title="title"  class="modal-course-type">
    <div class="d-block text-center">
      <ValidationObserver ref="form">
        <form class="form" @submit.prevent="saveCourseType">
          <div class="row" :hidden="check_general">
            <div class="col-md-12">
              <div class="form-group">
                <label>Trung tâm: <span class="text-danger">*</span></label>
                <ValidationProvider name="Trung tâm" rules="required" v-slot="{ errors,classes }" vid="center_id">
                  <multiselect v-model="center" :options="centers"
                     placeholder="Chọn trung tâm"
                     label="name"
                     track-by="name"
                     :class="classes"
                     :disabled="check_edit || check_general"

                  ></multiselect>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Tên loại khóa học: <span class="text-danger">*</span></label>
                <ValidationProvider name="Tên loại khóa học" rules="required" v-slot="{ errors,classes }" vid="name">
                  <input type="text" ref="course" name="course" class="form-control" :class="classes" placeholder="Tên loại khóa học" v-model="course_type.name" :disabled="check_general">
                  <div class="fv-plugins-message-container">
                    <div data-field="course" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Thứ tự trong combo: <span class="text-danger">*</span></label>
                <ValidationProvider name="Thứ tự combo" rules="required" v-slot="{ errors,classes }" vid="name">
                  <input type="number" ref="order" name="order" class="form-control" :class="classes" placeholder="Thứ tự trong combo" v-model="course_type.order" :disabled="check_general">
                  <div class="fv-plugins-message-container">
                    <div data-field="order" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Hiển thị: <span class="text-danger"></span></label>
                  <div class="checkbox-inline">
                    <label class="checkbox checkbox-success">
                      <input type="checkbox" name="Checkboxes5" v-model="course_type.status" :disabled="check_general">
                      <span></span></label>
                  </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <footer class="modal-footer">
      <button type="button" class="btn btn-secondary" @click="hideModal">Hủy bỏ</button>
      <button v-if="!check_edit" type="button" class="btn btn-success" @click="saveCourseType">Xác nhận</button>
      <button v-if="check_edit" type="button" class="btn btn-success" @click="updateCourseType" :hidden="check_general">Xác nhận</button>
    </footer>
  </b-modal>
</template>
<script>
    import {
        CREATE_COURSE_TYPES,
        UPDATE_COURSE_TYPES
    } from "../../../core/services/store/course/courseType.module";
    import Multiselect from 'vue-multiselect'
    import '@/assets/sass/custom/custom_course_type.scss';
    import {GET_CENTERS} from "@/core/services/store/center/centers.module";

    const _ = require('lodash');

    export default {
        components: {
            Multiselect,
        },
        props: {
            showModal: { default: false },
            check_edit: { default: false },
            courseType: { default: null }
        },
        name: "Course-Type-List",
        data() {
            return {
                centers: [],
                course_type: {
                    center_id: "",
                    name: "",
                    status: true,
                    order: '',
                },
                title: '',
                center: [],
                check_general: false,
            }
        },
        mounted() {
            this.clearForm();
            this.getCenters();
        },
        methods: {
             getCenters() {
                 this.$store.dispatch(GET_CENTERS, {
                    limit: 10000,
                     status: 1
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data;
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            onHide(evt) {
                this.$emit("clicked", true);
            },
            clearForm() {
                this.center = [];
                this.course_type.name = '';
                this.course_type.status = true;
                this.course_type.order = '';
            },
            hideModal() {
                this.$refs['modal-course-type'].hide();
            },
            toggleModal() {
                // when the modal has hidden
                this.$refs['modal-course-type'].toggle('#toggle-btn');
            },
            saveCourseType() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        return false;
                    } else {
                        this.course_type.center_id = this.center.id;
                        this.$store.dispatch(CREATE_COURSE_TYPES, this.course_type).then((res) => {
                            if (!res.error) {
                                this.hideModal();
                                this.$bvToast.toast(res.message, {
                                    title: 'Tạo loại khóa học',
                                    variant: 'success',
                                    solid: true
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Tạo loại khóa học',
                                    variant: 'danger',
                                    solid: true
                                });
                            }}).catch((response) => {
                            if (response?.data?.data?.message_validate_form) {
                                this.$refs.form.setErrors(response.data.data.message_validate_form);
                                return;
                            }
                            this.$bvToast.toast('Có lỗi xảy ra', {
                                title: 'Tạo loại khóa học',
                                variant: 'danger',
                                solid: true
                            });
                        });
                    }
                });
            },
            updateCourseType() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        return false;
                    } else {
                        this.course_type.center_id = this.center.id;
                        this.$store.dispatch(UPDATE_COURSE_TYPES, this.course_type).then((res) => {
                            if (!res.error) {
                                this.hideModal();
                                this.$bvToast.toast(res.message, {
                                    title: 'Cập nhật loại khóa học',
                                    variant: 'success',
                                    solid: true
                                });
                                this.courseType = this.course_type;
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Cập nhật loại khóa học',
                                    variant: 'danger',
                                    solid: true
                                });
                            }}).catch((response) => {
                            if (response?.data?.data?.message_validate_form) {
                                this.$refs.form.setErrors(response.data.data.message_validate_form);
                                return;
                            }
                            this.$bvToast.toast('Có lỗi xảy ra', {
                                title: 'Cập nhật loại khóa học',
                                variant: 'danger',
                                solid: true
                            });
                        });
                    }
                });
            }
        },
        watch: {
             showModal() {
                if (this.showModal) {
                    this.clearForm();
                    this.check_general = false;
                    this.title = "Thêm loại khóa học";
                    this.$refs['modal-course-type'].show();
                    if (this.check_edit) {
                        this.title = "Sửa loại khóa học";
                        if (this.courseType) {
                            this.course_type = this.courseType;
                            this.center = this.courseType.center;
                            if (this.courseType.center_id == null) {
                                this.check_general = true;

                            }
                        }
                    }
                }
            }
        }

    }
</script>
