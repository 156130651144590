<template>
  <div class="d-block text-center">
    <form class="form">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Tên : <span class="text-danger">*</span></label>
            <input type="text" name="name" class="form-control" placeholder="Họ và tên học viên"
                   :value="gift.name" disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Giá trị :
              <span class="text-danger">*</span></label>
            <input type="email" name="email" class="form-control" placeholder="Email"
                   :value="gift.value" disabled>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Ngày bắt đầu : <span class="text-danger">*</span></label>
            <input type="text" name="start_date" class="form-control" placeholder="Họ và tên học viên"
                   :value="gift.start_date" disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Ngày kết thúc :
              <span class="text-danger"></span></label>
            <input type="text" name="end_date" class="form-control" placeholder="Email"
                   :value="gift.end_date" disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Chi tiết quà tặng:
              <span class="text-danger"></span></label>
            <textarea class="form-control" placeholder="Chi tiết quà tặng"
                      :value="gift.description" disabled></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "GiftDetail",
  props: {
    gift: {
      type: [Array, Object],
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
