<template>
    <b-modal @hide="onHide" size="xl" :centered="true" :scrollable="true" ref="modal-benefit-detail"
             hide-footer :title="title" class="modal-benefit-detail">
        <div class="d-block text-center">
            <div class="row">
                <div class="col-md-12">
                    <vue-good-table
                            :columns="columns"
                            :rows="rows"
                            :search-options="{
                enabled: true
              }"
                            :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'bottom',
                dropdownAllowAll: true,
                setCurrentPage: 1,
                nextLabel: 'Sau',
                prevLabel: 'Trước',
                rowsPerPageLabel: 'Số bản ghi',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'Tất cả',
              }"
                    />
                </div>
            </div>
        </div>
        <footer class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideModal">Hủy bỏ</button>
        </footer>
    </b-modal>
</template>

<script>
    import 'vue-good-table/dist/vue-good-table.css'
    import {VueGoodTable} from 'vue-good-table';

    const _ = require('lodash');
    export default {
        name: "ContractDetailTvvTable",
        props: {
            showModal: {default: false},
            isBaoCaoChiNhanh: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            typeAction: {default: ''},
            contractData: {
                type: Array,
                default() {
                    return [];
                }
            },
        },
        data() {
            return {
                employeeRegisters: [],
                rows: [],
                title: '',
                query: {
                    stt: '',
                    user_id: '',
                    user_name: '',
                    morning: '',
                    afternoon: '',
                    evening: '',
                    center_name: ''
                },
                columns: [
                    {
                        label: 'STT',
                        field: 'stt',
                        tdClass: 'text-center',
                    },
                    {
                        label: 'Tên khách hàng',
                        field: 'user_name',
                        tdClass: 'text-center',
                        filterOptions: {
                            styleClass: 'class1', // class to be added to the parent th element
                            enabled: true, // enable filter for this column
                            placeholder: 'Tìm kiếm theo tên khách hàng', // placeholder for filter input
                            filterValue: '', // initial populated value for this filter
                            trigger: '', //only trigger on enter not on keyup
                        },
                    },
                    {
                        label: 'Số điện thoại',
                        field: 'phone',
                        html: true,
                        tdClass: 'text-center',
                        filterOptions: {
                            styleClass: 'class1', // class to be added to the parent th element
                            enabled: true, // enable filter for this column
                            placeholder: 'Tìm kiếm theo số điện thoại', // placeholder for filter input
                            filterValue: '', // initial populated value for this filter
                            trigger: '', //only trigger on enter not on keyup
                        },
                    },
                    {
                        label: 'Tư vấn viên',
                        field: 'nguoi_tu_van',
                        html: true,
                        tdClass: 'text-center',
                        filterOptions: {
                            styleClass: 'class1', // class to be added to the parent th element
                            enabled: true, // enable filter for this column
                            placeholder: 'Tìm kiếm theo tư vấn viên', // placeholder for filter input
                            filterValue: '', // initial populated value for this filter
                            trigger: '', //only trigger on enter not on keyup
                        },
                    },

                    {
                        label: 'Văn Phòng TVV',
                        field: 'branch_name',
                        tdClass: 'text-center',
                        filterOptions: {
                            styleClass: 'class1', // class to be added to the parent th element
                            enabled: true, // enable filter for this column
                            placeholder: 'Tìm kiếm văn phòng', // placeholder for filter input
                            filterValue: '', // initial populated value for this filter
                            trigger: '', //only trigger on enter not on keyup
                        },
                    },
                    {
                        label: 'Khu vực',
                        field: 'area_name',
                        tdClass: 'text-center',
                        filterOptions: {
                            styleClass: 'class1', // class to be added to the parent th element
                            enabled: true, // enable filter for this column
                            placeholder: 'Tìm kiếm theo khu vực', // placeholder for filter input
                            filterValue: '', // initial populated value for this filter
                            trigger: '', //only trigger on enter not on keyup
                        },
                    },
                    {
                        label: 'Số tiền',
                        field: 'amount_coefficient',
                        html: true,
                        tdClass: 'text-center',
                        filterOptions: {
                            styleClass: 'class1', // class to be added to the parent th element
                            enabled: true, // enable filter for this column
                            placeholder: 'Tìm kiếm theo số tiền', // placeholder for filter input
                            filterValue: '', // initial populated value for this filter
                            trigger: '', //only trigger on enter not on keyup
                        },
                    },
                ],
            }
        },
        components: {VueGoodTable},
        computed: {},
        methods: {
            hideModal: function () {
                this.$refs['modal-benefit-detail'].hide();
            },
            onHide: function (evt) {
                this.$emit("clicked", true);
            },
            toggleModal() {
                // when the modal has hidden
                this.$refs['modal-benefit-detail'].toggle('#toggle-btn');
            },
        },
        watch: {
            showModal() {
                if (this.showModal) {
                    this.$refs['modal-benefit-detail'].show();
                    // set các giá trị cho việc hiển thị
                    switch (this.typeAction) {
                        case  'quyen_loi' :
                            this.title = 'Danh sách quyền lợi';
                            break;
                        case  'hop_dong_no' :
                            this.title = 'Danh sách hợp đồng nợ';
                            break;
                        case  'hop_dong_moi' :
                            this.title = 'Danh sách hợp đồng mới';
                            break;
                        case  'doanh_so_moi' :
                            this.title = 'Doanh số Khách hàng mới';
                            break;
                        case  'hop_dong_noi_bo' :
                            this.title = 'Danh sách hợp đồng nội bộ';
                            break;
                        case  'hoan_tien' :
                            this.title = 'Danh sách hoàn tiền';
                            break;
                        case  'rut_tien' :
                            this.title = 'Danh sách rút tiền';
                            break;
                    }
                    this.rows = _.reduce(this.contractData, function (result, item, key) {
                        /* Nếu là báo cáo văn phòng thì tiền k nhân hệ số */
                        let amount = item.amount_coefficient ? item.amount_coefficient : item.amount;
                        let data = {
                            stt: ++key,
                            user_name: item.user.name,
                            phone: item.user.phone,
                            nguoi_tu_van: item.tvv.name,
                            branch_name: item.tvv && item.tvv.profile.branch ? item.tvv.profile.branch.name_alias : '',
                            area_name: item.tvv && item.tvv.profile.branch && item.tvv.profile.branch.area ? item.tvv.profile.branch.area.name : '',
                            amount_coefficient: (new Intl.NumberFormat('de-DE', {
                                style: 'currency',
                                currency: 'VND'
                            }).format(Math.abs(amount))),
                        }
                        result.push(data);
                        return result;
                    }, []);
                }
            },
        }
    }
</script>

<style scoped>

</style>
