<template>
  <b-modal @hide="onHide" :centered="true" :scrollable="true" size="xl" hide-footer ref="modal-ticket-detail" title="Chi tiết ticket">
    <div class="modal-body">
      <div class="row" v-loading="loading">
        <div class="col-md-6">
          <div class="container-content">
            <div class="form-group">
              <label>Tiêu đề:</label>
              <el-input type="text" :value="ticketObj.title" disabled />
            </div>
            <div class="row">
              <div class="col-6 form-group">
                <label>Phân loại:</label>
                <el-input type="text" :value="showClassify(ticketObj)" disabled />
              </div>
              <div class="col-6 form-group">
                <label>Danh mục:</label>
                <el-input type="text" :value="ticketObj.category_ticket && ticketObj.category_ticket.name" disabled />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label>Nội dung:</label>
                <textarea class="form-control w-100" rows="3" disabled>{{ ticketObj.content }}</textarea>
              </div>
            </div>
            <!-- Ảnh media -->
            <div class="row">
              <div class="form-group col-12" v-if="media.length > 0">
                <label>Ảnh/video:</label>
                <div class="ticket-media d-flex">
                  <div class="media image-ticket mr-2" v-for="image in tickets_images">
                    <img :src="image" class="media-image"
                         alt="Geeks Image" @click="showImageIdentity"/>
                  </div>
                  <div class="media video-ticket" v-for="video in tickets_videos">
                    <i class="fa fa-play-circle" style="color: blue" aria-hidden="true" @click="showModalVideo(video)"></i>
                  </div>
                </div>
                <b-modal :centered="true" :scrollable="true" content-class="your-class" size="lg" body-class="modal-video"  hide-footer hide-header ref="modal-video-ticket">
                  <video controls id="video1" style="width: 100%; height: 98%" ref="video">
                    <source :src="targetVideo" type="video/mp4">
                    Your browser doesn't support HTML5 video tag.
                  </video>
                </b-modal>
              </div>
              <div class="form-group col-12" v-else>
                <label>Ảnh/video:</label>
                <el-input type="text" value="Không có thông tin" disabled/>
              </div>
            </div>

            <div class="row">
              <div class="col-6 form-group">
                <label>Họ tên:</label>
                <el-input type="text" :value="ticketObj.user && ticketObj.user.name" disabled />
              </div>
              <div class="col-6 form-group">
                <label>Số điện thoại:</label>
                <el-input type="text" :value="ticketObj.user && ticketObj.user.phone" disabled/>
              </div>
              <div class="col-6 form-group">
                <label>Mã học viên:</label>
                <el-input type="text" :value="ticketObj.user_id + 100000" disabled/>
              </div>
              <div class="col-6 form-group">
                <label>Lớp học:</label>
                <el-input type="text" :value="ticketObj.classes && ticketObj.classes.name" disabled/>
              </div>
              <div class="col-6 form-group">
                <label>Trung tâm:</label>
                <el-input type="text" :value="ticketObj.center && ticketObj.center.name" disabled/>
              </div>
              <div class="col-6 form-group">
                <label>Chi nhánh:</label>
                <el-input type="text" :value="ticketObj.branch && ticketObj.branch.name" disabled/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="container-content">
            <div class="title d-flex">
              <p class="ticket-log-title">Trạng thái</p> <span class="badge badge-info">{{ STATUS[ticketObj.status] }}</span>
            </div>
            <div class="ticket-log mt-4" v-for="(log, key) in ticketObj.log">
              <div class="ticket-log-content">
                <p class="ticket-log-title">Thời gian:</p> <p>{{log.created_at | formatDateTime }}</p>
              </div>
              <div class="ticket-log-content">
                <p class="ticket-log-title">Người xử lý:</p>
                <p v-if="log.user">{{ log.user ? log.user.id + 100000 : ''}} - {{ log.user && log.user.name }}</p>
                <p v-else></p>
              </div>
              <div class="ticket-log-content">
                <p class="ticket-log-title">Hoạt động:</p> <p>{{ log.reason }}</p>
              </div>
              <div class="ticket-log-content">
                <p class="ticket-log-title">Nội dung:</p> <p>{{ log.content }}</p>
              </div>
              <div class="ticket-log-content">
                <p class="ticket-log-title">Ảnh/video:</p>
                <div class="row">
                  <div class="form-group col-12" v-if="log.attach_files.length > 0">
                    <div class="ticket-media d-flex">
                      <div class="media image-ticket mr-2" v-for="image in mapImages(log)">
                        <img :src="image" class="media-image"
                             alt="Geeks Image" @click="showImageIdentity"/>
                      </div>
                      <div class="media video-ticket" v-for="video in mapVideos(log)">
                        <i class="fa fa-play-circle" style="color: blue" aria-hidden="true" @click="showModalVideo(video)"></i>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-12" v-else>
                    Không có thông tin
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
          ref="modal-show-img"
          class="detail-img-modal"
          onclick="this.style.display='none'"
          style="display: none"
      >
        <div class="detail-img-modal-content detail-img-animate-zoom">
        <span
            class="
            detail-img-button
            detail-img-hover-red
            detail-img-xlarge
            detail-img-display-topright
          "
        >x</span
        >
          <img ref="img-detail" style="width: 100%" />
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {CUSTOMER_TICKET_DETAIL} from "@/core/services/store/customer/customer-crm.module";
import {CATEGORY, CATEGORY_CHAMSOC, IMAGE_OPTION, STATUS, VIDEO_OPTION} from "@/core/option/ticketOption";
export default {
  name: "CSKH-Detail",
  props: {
    ticket: {
      type: Object,
      default : () => { return {} }
    },
    showModal: false,
  },
  data() {
    return {
      ticketObj: {},
      CATEGORY,
      CATEGORY_CHAMSOC,
      STATUS,
      VIDEO_OPTION,
      IMAGE_OPTION,
      media: [],
      targetVideo: '',
      tickets_images: [],
      tickets_videos: [],
      loading: false,
    }
  },
  watch: {
    showModal() {
      if(this.showModal) {
        this.$refs['modal-ticket-detail'].show();
        this.tickets_images = [];
        this.tickets_videos = [];
        if (this.ticket.id) {
          this.loading = true
          this.$store.dispatch(CUSTOMER_TICKET_DETAIL, this.ticket.id).then((res) => {
            this.ticketObj = res.data;
            this.media = res.data.attach_files;
            if (this.media.length > 0){
              this.media.forEach((item) => {
                this.IMAGE_OPTION.forEach((value) => {
                  if (item.includes(value)) {
                    this.tickets_images.push(item);
                  }
                });
                this.VIDEO_OPTION.forEach((value) => {
                  if (item.includes(value)){
                    this.tickets_videos.push(item);
                  }
                });
              })
            }
          }).catch(() => {
            this.$notify({
              title: 'Thất bại',
              message: 'Có lỗi xảy ra',
              type: 'error'
            });
            this.hideModal();
          }).finally(() => { this.loading = false })
        }
      }
    }
  },
  methods: {
    onHide() {
      this.$emit("clicked", true);
    },
    hideModal() {
      this.$refs['modal-ticket-detail'].hide();
    },
    toggleModal() {
      this.$refs['modal-ticket-detail'].toggle('#toggle-btn');
    },
    showImageIdentity(element) {
      this.$refs["img-detail"].src = element.srcElement.src;
      this.$refs["modal-show-img"].style.display = "block";
    },
    showModalVideo(video) {
      let _this = this;
      this.targetVideo = video;
      this.$refs['modal-video-ticket'].show();
      setTimeout(function () {
        let modalContent = document.querySelector('.your-class');
        modalContent.style.background = 'none';
        modalContent.style.boxShadow = 'none';
        _this.$refs['video'].play();
      }, 200);
    },
    renderStatusLog(log) {
      if (log.type === 'change_status'){
        return  `Chuyển trạng thái từ <span class="font-weight-bold">${this.STATUS[log.old_content]}</span> sang <span class="font-weight-bold">${this.STATUS[log.new_content]}</span>`
      }
      return '';
    },
    showClassify(ticket) {
      let result = '';
      switch (ticket.is_auto){
        case 1:
          result = 'Chăm sóc';
          break;
        case 2:
        case 3:
          result = 'Hỗ trợ';
          break;
      }
      return result
    },
    mapImages(log) {
      const images = [];
      log.attach_files.forEach((item) => {
        this.IMAGE_OPTION.forEach((value) => {
          if (item.includes(value)) {
            images.push(item);
          }
        });
      })
      return images;
    },
    mapVideos(log) {
      const videos = [];
      log.attach_files.forEach((item) => {
        this.VIDEO_OPTION.forEach((value) => {
          if (item.includes(value)){
            videos.push(item);
          }
        });
      });
      return videos;
    }
  }
}
</script>

<style scoped>
.container-content{
  border: 1px solid #F3F6F9;
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.ticket-log {
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 5px;
}
.ticket-log-content{
  display: flex;
}
.ticket-log-title {
  min-width: 100px;
}

.detail-img-modal {
  z-index: 3;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.detail-img-xlarge {
  font-size: 24px;
}
.detail-img-display-topright {
  position: absolute;
  right: 0;
  top: 0;
}
.el-dialog__header { background-color: #67C23A }
.detail-img-button {
  border: none;
  display: inline-block;
  padding: 4px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}
.detail-img-animate-zoom {
  animation: animatezoom 0.6s;
}
.detail-img-modal-content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  width: 600px;
}
.image-ticket:hover {
  opacity: 0.5;
  cursor: pointer;
}

.media {
  width: 100px;
  height: 100px;
  background-color: #FFFFFF;
  border: 1px solid;
  border-radius: 5px;
}

.media-image{
  width: 50px;
  height: 50px;
  margin: auto;
}
.modal-body {
  padding: 0.75rem;
}

.fa-play-circle {
  margin: auto;
  font-size: 3.3rem;
}
.fa-play-circle:hover{
  cursor: pointer;
}

@media (max-width: 730px) {
  .second {
    margin-left: 20px;
  }
  .detail-img-modal-content {
    width: 200px;
  }
  .detail-img-xlarge {
    font-size: 12px;
  }
  .detail-img-button {
    padding: 0 8px;
  }
}
</style>