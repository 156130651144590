import ApiService from "@/core/services/api.service";
import {TaskInformationModel} from "@/view/pages/test-input/models/task_information_model";

const _ = require('lodash');

export const TEST_INPUT_GET_CALENDAR = "test_input_get_calendar";
export const TEST_INPUT_SAVE_CALENDAR = "test_input_save_calendar";
export const TEST_INPUT_GET_TEST_INPUT_SCHEDULE = "test-input/get-test-input-schedule";
export const TEST_INPUT_GET_TEST_INPUT_ONLY = "test-input/get-test-input-only";
export const TEST_INPUT_STORE_TASK = "test_input_store_task";
export const TEST_INPUT_STORE_TASK_PERSONAL = "test_input_store_task_personal";
export const TEST_INPUT_UPDATE_TASK_PERSONAL = "test_input_update_task_personal";

export const TEST_INPUT_UPDATE_TASK = "test_input_update_task";
export const TEST_INPUT_GET_TESTER_BY_USER_ID = "test_input_get_tester_by_user_id";
export const CHECK_ONLY_BRANCH_TESTER = "check_only_branch_tester";
export const TEST_INPUT_GET_LIST_TESTER = "test_input_get_list_tester";
export const TEST_INPUT_DELETE_TASK = "test_input_delete_task";
export const TEST_INPUT_DOWNLOAD_EXAMPLE = "test_input_download_example";
export const TEST_INPUT_PREVIEW_TASK = "test_input_preview_task";
// detail-task
export const TEST_INPUT_GET_INFORMATION_TASK = "test-input/task-detail";
export const TEST_INPUT_GET_DATA_TEST_USER = "test_input_preview_task2";

export const TEST_INPUT_UPLOAD_DATA_EXCEL = "test_input_upload_data_excel";
//
export const GET_LIST_TESTER_ON_CALL_SCHEDULE = "test-input/list-tester-on-call-schedule";
export const GET_LIST_SUGGEST_TESTER = "test-input/suggest-tester";
export const MAKE_EXAM_SPEAKING = "test-input/mark-hand"
export const GET_ANSWER_STUDENT = "test-input/get-answer"
export const GET_RESULT_EXAM = "test-input/get-point";
export const UPDATE_COURSE_SUGGEST = "test-input/update-course-suggest"
export const LIST_TESTER_FREE_TIME = "test-input/list-tester-free-time"
export const GET_TESTER_BY_USER_LOGIN = "test-input/get-tester-login"
export const GET_TESTER_FREE_TIME_PERSONAL = "test-input/get-tester-free-time-personal"
export const COME_CHECK_GENERAL = "test-input/tester-come-check"

export const GET_CALENDAR_WEEK_BY_TEST_ID = "test-input/calendar-week-by-test-id"
export const CHECK_SLOT_BY_DAY = "test-input/check-slot-by-day"
export const CHECK_TASK_OF_ME = 'test-input/check-task-is-mine'
export const CANCEL_TEST_INPUT = 'test-input/cancel-test-input-schedule-item'
export const GET_SPEAKING = 'test-input/get-speaking'
export const UPDATE_SPEAKING_FAILURE = 'test-input/update-failure'
export const CHECK_MY_TASK_PENDING = 'test-input/check-pending-my-task';
export const GET_NICK_NAME = 'test-input/get-before-update-nickname';
export const UPDATE_INFORMATION_TASK = 'test-input/update-nickname';
export const GET_LIST_REPORT = 'test-input/report'
export const GET_LIST_BRANCH_IS_EDUTALK= 'branches/get-list-is-edutalk'
export const EXPORT_REPORT_EXCEL = 'test-input/export-report'
export const REVIEW_WRITING = 'test-input/review-writing'
export const CHECK_TEST_IS_GRADED = 'test-input/check-scoring-is-allowed'
export const CHECK_TEST_COMPLETION = 'test-input/check-task-success'
export const CHECK_BEFORE_HIDE = 'testTester/check-before-hide'
export const GET_CODE_TEST = 'test-input/take-code-exam-ielts'
export const REDO_GENERAL = 'test-input/redo-general'
export const RE_OPEN_SPEAKING = 'test-input/re-open-speaking'
export const CHECK_BEFORE_BRANCH = 'testTester/check-before-branch'
export const CHECK_BEFORE_TYPE = 'testTester/check-before-type'
export const GET_HISTORY_LOG_TESTER = 'testTester/history-log-tester'
export const GET_TESTER_BY_KEYWORD = 'test-input/getTesterByKeyword'
export const GET_LIST_TASK_TESTER = 'test-input/take-task-by-tester'
export const SWAP_TASK = 'test-input/swap'
export const UPDATE_STATUS_DONE_TEST_INPUT_ITEM = 'test-input/update-status-done'

const state = {};

const getters = {};

const actions = {
    /**
     *
     * @param context
     * @param credentials
     * @returns {Promise<unknown>}
     */
    [TEST_INPUT_GET_CALENDAR](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('test-input/get-calendar', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    /**
     *
     * @param context
     * @param credentials
     * @returns {Promise<unknown>}
     */
    [TEST_INPUT_GET_LIST_TESTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('test-input/get-list-tester', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    /**
     *
     * @param context
     * @param credentials
     * @returns {Promise<unknown>}
     */
    [TEST_INPUT_SAVE_CALENDAR](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post('test-input/save-calendar', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [TEST_INPUT_STORE_TASK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("test-input/store", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                });
        });
    },
    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [TEST_INPUT_STORE_TASK_PERSONAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("test-input/store-personal", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                });
        });
    },
    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [TEST_INPUT_UPDATE_TASK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('test-input/update/' + payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        });
    },

    [COME_CHECK_GENERAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('test-input/tester-come-check/' + payload.id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        });
    },
    /**
     * Update test đầu vào, màn hình nhân viên xem
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [TEST_INPUT_UPDATE_TASK_PERSONAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('test-input/update-personal/' + payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        });
    },
    [TEST_INPUT_GET_TEST_INPUT_SCHEDULE](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${TEST_INPUT_GET_TEST_INPUT_SCHEDULE}`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [GET_TESTER_FREE_TIME_PERSONAL](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_TESTER_FREE_TIME_PERSONAL}`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [TEST_INPUT_GET_TEST_INPUT_ONLY](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${TEST_INPUT_GET_TEST_INPUT_ONLY}`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {

                    reject(res);
                });
        });
    },
    [TEST_INPUT_GET_TESTER_BY_USER_ID](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('test-input/get-tester-by-user-id', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [CHECK_ONLY_BRANCH_TESTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('test-input/check-only-branch-tester', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [TEST_INPUT_DELETE_TASK](context, id) {
        return new Promise(resolve => {
            ApiService.delete('test-input/delete', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [TEST_INPUT_DOWNLOAD_EXAMPLE](context, payload) {
        return new Promise(resolve => {
            ApiService.query('test-input/download-task-excel-example', payload).then(({data}) => {
                const filename = 'task-example' + ".csv";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [TEST_INPUT_PREVIEW_TASK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("test-input/preview-task-excel", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    // detail-task
    [TEST_INPUT_GET_INFORMATION_TASK](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${TEST_INPUT_GET_INFORMATION_TASK}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [TEST_INPUT_GET_DATA_TEST_USER]() {
        return {
            user: {
                name: "Tăng Phương Thảo", phone: "0971086077"
            },
            dataTests: [{typeTest: "speakingTest"}, {typeTest: "readingTest"}, {typeTest: "listeningTest"}, {typeTest: "writingTest"}]
        }
    },


    [TEST_INPUT_UPLOAD_DATA_EXCEL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("test-input/upload-data-task-excel", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [GET_LIST_TESTER_ON_CALL_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LIST_TESTER_ON_CALL_SCHEDULE}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [GET_LIST_SUGGEST_TESTER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LIST_SUGGEST_TESTER}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [MAKE_EXAM_SPEAKING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${MAKE_EXAM_SPEAKING}/${payload.id}`, payload.data)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [GET_ANSWER_STUDENT](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${GET_ANSWER_STUDENT}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [GET_RESULT_EXAM](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${GET_RESULT_EXAM}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [GET_CALENDAR_WEEK_BY_TEST_ID](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_CALENDAR_WEEK_BY_TEST_ID}`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject({response});
                });
        });
    },
    [UPDATE_COURSE_SUGGEST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_COURSE_SUGGEST}/${payload.id}`, payload.data)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [LIST_TESTER_FREE_TIME](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${LIST_TESTER_FREE_TIME}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [GET_TESTER_BY_USER_LOGIN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_TESTER_BY_USER_LOGIN}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [CHECK_SLOT_BY_DAY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${CHECK_SLOT_BY_DAY}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [CHECK_TASK_OF_ME](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("test-input/check-task-is-mine", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response)
                });
        });
    },
    [CANCEL_TEST_INPUT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('test-input/cancel-test-input-schedule-item/' + payload.id,payload.data).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        });
    },

    [GET_SPEAKING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('test-input/get-speaking/' + payload.id,payload.data).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        });
    },

    [UPDATE_SPEAKING_FAILURE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('test-input/update-failure/' + payload.id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        });
    },

    [CHECK_MY_TASK_PENDING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${CHECK_MY_TASK_PENDING}`).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        });
    },
    [GET_NICK_NAME](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_NICK_NAME}`, credentials) .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [GET_LIST_REPORT](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LIST_REPORT}`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [UPDATE_INFORMATION_TASK](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_INFORMATION_TASK}`, credentials) .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [GET_LIST_BRANCH_IS_EDUTALK](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('branches/get-list-is-edutalk', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [EXPORT_REPORT_EXCEL](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('test-input/export-report', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [REVIEW_WRITING](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${REVIEW_WRITING}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [CHECK_TEST_IS_GRADED](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${CHECK_TEST_IS_GRADED}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [CHECK_TEST_COMPLETION](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${CHECK_TEST_COMPLETION}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [CHECK_BEFORE_HIDE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${CHECK_BEFORE_HIDE}/${payload.id}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [GET_CODE_TEST](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${GET_CODE_TEST}`, id).then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [CHECK_BEFORE_TYPE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${CHECK_BEFORE_TYPE}/${payload.id}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [REDO_GENERAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${REDO_GENERAL}/${payload.id}`, payload).then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [CHECK_BEFORE_BRANCH](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${CHECK_BEFORE_BRANCH}/${payload.id}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [RE_OPEN_SPEAKING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${RE_OPEN_SPEAKING}/` + payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        });
    },
    [GET_HISTORY_LOG_TESTER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get('testTester/history-log-tester',payload.id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        });
    },
    [GET_TESTER_BY_KEYWORD](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${GET_TESTER_BY_KEYWORD}`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [GET_LIST_TASK_TESTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${GET_LIST_TASK_TESTER}`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    [SWAP_TASK](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${SWAP_TASK}`, credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    reject(res);
                });
             });
    },
    [UPDATE_STATUS_DONE_TEST_INPUT_ITEM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_STATUS_DONE_TEST_INPUT_ITEM}/` + payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        });
    },
}

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};
