<template>
  <div class="question row">
    <div class="col-6">
      <homework-container :question="question"></homework-container>
      <div class="row">
        <div class="col-md-6" v-for="(item, index) in question.questions" :key="index">
          <div class="">
            <div class="answer-title mb-1">
              <div v-html="formatBrTagText(item.title)"></div>
            </div>
            <div class="" v-if="typeView === 'view-result'">
              <div class="bold mb-2">Bài làm của học viên:</div>
              <audio controls preload="metadata" ref="video-log">
                <source :src="item.student_answer" type="audio/ogg">
                <source :src="item.student_answer" type="audio/mpeg">
              </audio>
            </div>
            <div class="d-flex align-items-center" v-else>
              <el-button type="success" icon="el-icon-microphone" >Record Now</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6" v-if="point">
      <!--      Bảng điểm các tiêu chí-->
      <TranscriptRecord :point="point"></TranscriptRecord>
    </div>
  </div>
</template>

<script>



import TranscriptRecord from "@/view/pages/classes/components/TranscriptRecord.vue";
import HomeworkContainer from "@/view/pages/classes/components/homework-type/homework-container.vue";

export default {
  name: "record-audio-kid",
  components: {HomeworkContainer, TranscriptRecord},
  props: {
    question: {
      type: Object,
      default: () => { return {} }
    },
    typeView: {
      type: String,
      default: ''
    },
  },
  mounted() {
    this.point = {
      totalPoint: this.question?.outComes?.score,
      skillPoints: this.question?.outComes?.comments
    }
  },
  data(){
    return{
      point:{}
    }
  },
  methods: {
    formatBrTagText(value) {
      return value ? value.replace(/(\r\n|\n\r|\r|\n)/g, '<br>') : '';
    },
  }
}
</script>

<style scoped>

</style>