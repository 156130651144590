<template>
  <div>
    <div v-for="(gift, index) in gifts" :key="index">
      <ListGiftItem
          :gift="gift"
          :id_promotion_disable="id_promotion_disable"
          :gift-select="giftSelect"
          :list-promotions="vouchersForContract"
          @showPromotionDetail="showPromotionDetail"
          @pickGift="pickGift"
          @pickPrice="setVoucherForContract"
          @cancelGift="cancelGift"
          class="mb-3 border "
          style="background-image: linear-gradient(#DFEAFF, #FFFFFF); border-color: #3254CB!important;"
      >
      </ListGiftItem>
    </div>
  </div>
</template>

<script>
import ListGiftItem from "@/view/pages/business/modal-contract/ListGiftItem";

export default {
  name: "ListGifts",
  components: {ListGiftItem},
  props: {
    gifts: {
      type: Array,
      default: () => {
        return []
      }
    },
    id_promotion_disable: {
      type: [String, Number, Array],
      default: () => {
        return []
      }
    },
    vouchersForContract: {
      type: Array,
      default: () => {
        return []
      }
    },
    giftSelect: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  methods: {
    pickGift(promotion) {
      this.$emit('pickGift', promotion);
    },
    showPromotionDetail(promotion) {
      this.$emit('showPromotionDetail', promotion);
    },
    setPromotionForContract(promotion) {
      this.$emit('setPromotionForContract', promotion);
    },
    setVoucherForContract(promotion) {
      this.$emit('setVoucherForContract', promotion);
    },
    cancelGift(promotion) {
      this.$emit('cancelGift', promotion);
    }
  }
}
</script>

<style scoped>

</style>
