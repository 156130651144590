// action types
import ApiService from "../api.service";
import {SET_ERROR} from "./auth.module";

export const USER_PROFILE = "userProfile";
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";

// mutation types
export const SET_USER_PROFILE = "setUserProfile";

const state = {
    errors: null,
    user_profile: {
        data: {
            avatar: "",
            profile: {
                account_type: {
                    name: ""
                }
            }
        }
    },
};

const getters = {
    userProfile(state) {
        return state.user_profile;
    },
};

const actions = {
    [USER_PROFILE](context, payload) {
        return new Promise(resolve => {
            ApiService.query("/get-user-data/" + payload.id)
                .then(({ data }) => {
                    context.commit(SET_USER_PROFILE, data);
                    resolve(data);
                })
        });
    },
    [UPDATE_PERSONAL_INFO](context, payload) {
        return new Promise(resolve => {
            ApiService.post('/update-user/' + JSON.parse(localStorage.getItem('user')).user.id, payload).then(({data}) => {
                context.commit(SET_USER_PROFILE, data);
                resolve(data);
            }).catch(({response}) => {
                if (response?.data?.data?.message_validate_form) {
                    context.commit(SET_ERROR, response.data.data.message_validate_form);
                } else {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                }
            });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_USER_PROFILE](state, user_profile) {
        state.user_profile = user_profile;
    },
    // [SET_PERSONAL_INFO](state, user_personal_info) {
    //     state.user_personal_info = user_personal_info;
    // },
};

export default {
    state,
    actions,
    mutations,
    getters
};
