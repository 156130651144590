import ApiService from "@/core/services/api.service";

const _ = require('lodash');
//action types
export const GET_DISTRICTS = "list-district";
export const GET_DISTRICTS_BY_PROVINCE = "get-districts-by-province";

//mutations types
export const SET_DISTRICTS = "setDistricts";

const state = {
    errors: null,
    districts: [],
};

const getters = {
    getAllDistricts(state) {
        return state.districts;
    },
};

const actions = {
    [GET_DISTRICTS](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/districts", credentials)
                .then(({data}) => {
                    context.commit(SET_DISTRICTS, data.data);
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },
    [GET_DISTRICTS_BY_PROVINCE](context, payload) {
        return new Promise((resolve) => {
            ApiService.post("/districts-by-province", payload)
                .then(({data}) => {
                    context.commit(SET_DISTRICTS, data.data);
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },

};

const mutations = {
    [SET_DISTRICTS](state, data) {
        state.districts = data;
        state.errors = {};
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
