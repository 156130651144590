import {getNameTitle, KEM, KHA, RAT_KEM, RAT_TOT, TOT} from "@/core/option/commentStudentType";

export class StudentComment {
    constructor(name, user_id, status, changeStudent, classroom_id, lesson, comment_phase, comments, isCompleted, attendance) {
        this.name = name;
        this.user_id = user_id;
        this.status = status;
        this.changeStudent = changeStudent;
        this.classroom_id = classroom_id;
        this.lesson = lesson;
        this.comment_phase = comment_phase && comment_phase!=="" ? comment_phase : { comment: ''}
        this.comments = comments;
        this.isCompleted = isCompleted;
        this.attendance = attendance;
    }
}

export class Comment {
    constructor(title, disabled, topic = null, feedback = '', review = null, recommendAuto = '', topics = []

    ) {
        this.title = title;
        this.disabled = disabled;
        this.topic = topic;
        this.review = review;
        this.recommendAuto = recommendAuto;
        this.feedback = feedback;
        this.nameTitle = getNameTitle.get(title);
        this.topics = topics;
        this.reviews = this.getReviews(topic);
    }

    setReviews(){
        this.reviews = this.getReviews(this.topic);
    }
    getReviews(topic){
        if(topic === 3 || topic === 4){
            return [
                {
                    label: "Tốt",
                    value: TOT,
                },
                {
                    label: "Khá",
                    value: KHA,
                },
                {
                    label: "Kém",
                    value: KEM,
                },
            ];
        }
        if (topic === '') {
            this.review = null
        }
        return [
            {
            label: "Rất Tốt",
            value: RAT_TOT,
            },
            {
                label: "Tốt",
                value: TOT,
            },
            {
                label: "Khá",
                value: KHA,
            },
            {
                label: "Kém",
                value: KEM,
            },
            {
                label: "Rất Kém",
                value: RAT_KEM,
            }
        ];
    }
}