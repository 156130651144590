<template>
  <tr>
    <td>{{ comment.user_name }}</td>
    <td>{{ comment.user_code }}</td>
    <td class="text-center">
      <div class="d-flex justify-center mb-3">
        <el-select :disabled="time_comment !== ''" v-model="classify" @change="changeClassify">
          <el-option v-for="(item, index) in optionClassify"
                     :key="index"
                     :label="item.value"
                     :value="item.id"
          >
            {{item.value}}
          </el-option>
        </el-select>
      </div>

      <textarea style="font-size: 14px;" :disabled="time_comment !== ''" rows="3" label="1" v-if="this.classify == 1 || this.classify == 2 || this.classify == 3" placeholder="Nhập những điểm cần cải thiện" class="checkbox-calendar form-control" v-model="comment_text"
                @input="changeComment"
                key="1"></textarea>
      <div class="d-flex justify-center text-red mt-2" v-if="(this.classify == 1 || this.classify == 2 || this.classify == 3) && this.comment_text.length < 150" >Cần nhập tối thiểu 150 kí tự</div>
      <div class="d-flex justify-center text-red" v-if="!classify">Không được để trống</div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "CommentItem",
  props: {
    comment: {
      type: Object,
      default: () => {
        return {};
      }
    },
    optionClassify: {
      type: Array,
      default: () => {
        return [];
      }
    },
    time_comment: {
      type: String,
      default: () => {
        return '';
      }
    },
  },
  watch: {
    comment(value) {
      this.comment_text = value.comment;
    },
    optionClassify(value) {
      this.classify = value.classify;
    },
  },
  mounted() {
    this.comment_text = this.comment.comment;
    this.classify = this.comment.classify;
  },
  data() {
    return {
      comment_text: '',
      classify: '',
      optionClass: {},
      error: '',
      listError: [],
      errorComment: ''
    }
  },
  methods: {
    getPlaceholder() {
      return 'Nhận xét học viên :' + this.comment.user_name;
    },
    changeComment() {
      this.$emit('changeComment', this.comment, this.comment_text);
    },
    changeClassify() {
      this.$emit('changeClassify', this.comment, this.classify);
    }
  }
}
</script>

<style scoped>
.text-red {
  color: red;
}
</style>