import ApiService from "@/core/services/api.service";
async function uploadToS3(file, progress, error, options) {
    const formData = new FormData();
    formData.append('file',file)
    const response = await ApiService.upload(options.uploadUrl, formData)
    this.rootEmit('file-upload-complete',  {
        url: `${response.data.data}`,
        name: file.name,
        id: this.id,
        parent: this.attributes.parent ? this.attributes.parent : this.attributes.name,

    })
    return {
        url: `${response.data.data}`,
        name: file.name,
        id: this.id,
        parent: this.attributes.parent,
        to: this.attributes.parent ? this.attributes.parent : this.attributes.name,
    };
}

export default function (instance) {
    instance.extend({
        uploader: uploadToS3
    });
}
