import ApiService from "@/core/services/api.service";

const _ = require('lodash');
//action types
export const CREATE_STAFF = "create_staff";
export const UPDATE_STAFF = "update_staff";
export const GET_USER_BY_ID = "get_user_by_id";
export const SEARCH_BY_MA_CAP_TREN = "search_by_ma_cap_tren";
export const GET_USERS = "list-user";
export const GET_USER_BY_PHONE = "get-by-phone";
export const CREATE_CUSTOMER = "create-customer";
export const DELETE_USER = "delete_user";
export const UPDATE_CUSTOMER = "update_customer";
export const GET_USER_LOG_HISTORIES = "get_user_log_histories";
export const GET_USER_LOG_TIME_WORK = "get_user_log_time_work";
export const GET_USER_LOG_ACTION_DATA = "get_user_log_action_data";
export const DISMISS_USER = "dismiss_user";
export const ACTIVE_USER = "active_user";
export const CHANGE_PASSWORD = "change_password";
export const EXPORT_CUSTOMERS = "export_customers";
export const EXPORT_CUSTOMERS_V2 = "export_customers_v2";
export const EXPORT_EMPLOYEE_EXAMPLE = "export_employee_example";
export const EXPORT_STAFF = "export_staff";
export const EXPORT_SALE_TREE = "export_sale_tree";
export const EXPORT_EXCEL_SALE_TREE_EXAMPLE = "export_excel_sale_tree_example";
export const IMPORT_SALARY = "import-user-salary";
export const IMPORT_TEACHER_SALARY = "import-teacher-salary";
export const IMPORT_LUCKY_DRAW = "import-lucky-draw";
export const IMPORT_SALE_TREE = "import_sale_tree";
export const IMPORT_EMPLOYEE = "import_employee";
export const IMPORT_SALE_TREE_UPDATE_PARENT = "import_sale_tree_update_parent";
export const GET_DATA_TREE_VIEW = "get_data_tree_view";
export const GET_DATA_SALE_LOG = "get_data_sale_log";
export const SEARCH_CUSTOMER_BY_OR_ID = "search-customer-by-phone-or-id";
export const GET_STUDENT_ALL = "get_student_all";
export const PUSH_PERCENT_LOADING_IMPORT_SALE_LOG = "push_percent_loading_import_sale_log";
export const GET_USER_BY_POSITION = "get_user_by_position";
export const SEARCH_TVV = "users/search-tvv";
export const GET_All_LIST_USER_REQUEST = "propose-management/get-all-data";
export const DELETE_REQUEST_APPLICATION = "propose-management";
export const SHOW_APPLITICATION = "propose-management/show-propose";
export const DECIDED_APPLITICATION = "propose-management/decided-propose";
export const GET_DEPARTMENT = "propose-management/get-department";
export const GET_USER_BY_USER_PHONE = "get-by-user-phone";
export const PREVIEW_FILE_EXCEL_KRA = "kra/preview";
export const IMPORT_FILE_EXCEL_KRA = "kra/import";

//mutations types
export const SET_USERS = "setUsers";
export const ADD_USERS = "addUsers";
export const SET_USERS_ERROR = "errorUsers";
export const SET_USER_BY_ID = "getUserById";
export const SET_INPUT_VALUE = "setuserinputvalue";
export const SET_USER_PAGINATE = "setuserpaginatevalue";
export const SET_USER_LOG_PAGINATE = "set_user_log_paginate_value";
export const SET_USER_LOG_HISTORIES = "set_user_log_histories";
export const SET_USER_LOG_DATA_CHART = "set_user_log_data_chart";
export const SET_USER_LOG_ACTION_DATA = "set_user_log_action_data";
export const UPDATE_LIST_USER = "set_users_when_active_user";
export const UPDATE_LIST_USER_WHEN_DELETE = "set_users_when_delete_user";
export const SET_PERCENT_LOADING_IMPORT_SALE_LOG = "set_percent_loading_import_sale_log";


const state = {
    errors: null,
    users: [],
    user: '',
    userPaginatedData: [],
    userLogPaginatedData: '',
    logHistories: '',
    logActionHistories: '',
    timeChartData: '',
    percent_loading: 0
};

const getters = {
    getAllUsers(state) {
        return state.users;
    },
    getUserById(state) {
        return state.user;
    },
    getUserPaginate(state) {
        return state.userPaginatedData;
    },
    getUserLogHistories(state) {
        return state.logHistories;
    },
    getUserLogHistoriesPaginate(state) {
        return state.userLogPaginatedData;
    },
    getUserLogChartData(state) {
        return state.timeChartData;
    },
    getUserActionLogData(state) {
        return state.logActionHistories;
    },
    percentLoadingImportSaleLog(state) {
        return state.percent_loading;
    }
};

const actions = {
    [GET_USERS](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/list-user", credentials)
                .then(({data}) => {
                    context.commit(SET_USERS, data.data);
                    const userPagination = {
                        total: data.total,  // total number of elements or items
                        per_page: data.per_page, // items per page
                        current_page: data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.total // total pages in record
                    }
                    context.commit(SET_USER_PAGINATE, userPagination)
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },

    [GET_STUDENT_ALL](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("users/get-student-all", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(() => {
                });
        });
    },

    /**
     * @param context
     * @param credentials
     * @returns {Promise<unknown>}
     */
    [GET_USER_BY_PHONE](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query("users/get-by-phone", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    },

    [GET_USER_BY_USER_PHONE](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query("users/get-by-user-phone", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    },

    [GET_All_LIST_USER_REQUEST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_All_LIST_USER_REQUEST}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [SHOW_APPLITICATION](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${SHOW_APPLITICATION}`, payload).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [DELETE_REQUEST_APPLICATION](context, payload) {
        return new Promise(resolve => {
            ApiService.delete('/propose-management/propose', payload.id).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [DECIDED_APPLITICATION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('propose-management/action-proposes', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [GET_USER_BY_POSITION](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query("users/get-user-by-position", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error)
                });
        });
    },
    [GET_USER_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('users', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_USER_LOG_ACTION_DATA](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("/user/log-action-history/" + payload.id, payload.params)
                .then(({data}) => {
                    context.commit(SET_USER_LOG_ACTION_DATA, data.data.data);
                    const userLogActionPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_USER_LOG_PAGINATE, userLogActionPagination)
                    resolve(data);
                })
                .catch(() => {
                });
        });
    },
    [GET_USER_LOG_HISTORIES](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("/user/log-history/" + payload.id, payload.params)
                .then(({data}) => {
                    context.commit(SET_USER_LOG_HISTORIES, data.data.data);
                    const userLogPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_USER_LOG_PAGINATE, userLogPagination)
                    resolve(data);
                })
                .catch(() => {
                });
        });
    },
    [GET_USER_LOG_TIME_WORK](context, id) {
        return new Promise((resolve) => {
            ApiService.get("/user/log-history-by-day", id)
                .then(({data}) => {
                    context.commit(SET_USER_LOG_DATA_CHART, data.data);
                    resolve(data);
                })
                .catch(() => {
                });
        });
    },
    [CREATE_STAFF](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('create-staff', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_STAFF](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('update-staff/' + payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [CREATE_CUSTOMER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('create-customer', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_CUSTOMER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('update-customer/' + payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [CHANGE_PASSWORD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('user/change-password/' + payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [SEARCH_BY_MA_CAP_TREN](context, payload) {
        return new Promise(resolve => {
            ApiService.query('users', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [DISMISS_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('user/dismiss/' + payload.id, payload.params).then(({data}) => {
                context.commit(UPDATE_LIST_USER, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [ACTIVE_USER](context, payload) {
        return new Promise(resolve => {
            ApiService.post('user/active/' + payload.id, payload.params).then(({data}) => {
                context.commit(UPDATE_LIST_USER, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [DELETE_USER](context, id) {
        return new Promise(resolve => {
            ApiService.delete('user', id).then(({data}) => {
                context.commit(UPDATE_LIST_USER_WHEN_DELETE, id);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [IMPORT_SALARY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('user/import-user-salary', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [IMPORT_TEACHER_SALARY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`user/${IMPORT_TEACHER_SALARY}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [IMPORT_LUCKY_DRAW](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`user/${IMPORT_LUCKY_DRAW}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [EXPORT_CUSTOMERS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('user/export-customers', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [EXPORT_CUSTOMERS_V2](context, payload) {
        return new Promise(resolve => {
            ApiService.query('user/export-customers-v2', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [EXPORT_EMPLOYEE_EXAMPLE](context, payload) {
        return new Promise(resolve => {
            ApiService.query('user/export-employee-example', payload).then(({data}) => {
                const filename = 'Employee Example' + ".xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_DEPARTMENT](context) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_DEPARTMENT}`)
                .then(({ data }) => {
                    resolve(data);
                })
        });
    },

    [EXPORT_STAFF](context, payload) {
        return new Promise(resolve => {
            ApiService.query('user/export-staff', payload).then(({data}) => {
                const filename = 'Danh sách nhân sự' + ".xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_DATA_TREE_VIEW](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/tree-view", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((e) => {
                    console.log(e, 'error')
                });
        });
    },

    [GET_DATA_SALE_LOG](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query("/sale-logs", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [SEARCH_CUSTOMER_BY_OR_ID](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("users", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((e) => {
                    console.log(e, 'error')
                });
        });
    },

    [EXPORT_SALE_TREE](context, payload) {
        return new Promise(resolve => {
            ApiService.query('sale/export-tree', payload).then(({data}) => {
                const filename = Date.now() + "phân_cấp_nhân_sự.xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [EXPORT_EXCEL_SALE_TREE_EXAMPLE](context, payload) {
        return new Promise(resolve => {
            ApiService.query('users/export-sale-tree-example', payload).then(({data}) => {
                const filename = "File mẫu phân cấp sale.xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [IMPORT_SALE_TREE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("users/import-sale-tree", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [IMPORT_EMPLOYEE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("users/import-employee", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [IMPORT_SALE_TREE_UPDATE_PARENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("users/import-update-parent", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [PREVIEW_FILE_EXCEL_KRA](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(PREVIEW_FILE_EXCEL_KRA, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },

    [IMPORT_FILE_EXCEL_KRA](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(IMPORT_FILE_EXCEL_KRA, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },

    /**
     * Tìm kiếm tư vấn viên
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [SEARCH_TVV](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${SEARCH_TVV}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },

    [PUSH_PERCENT_LOADING_IMPORT_SALE_LOG](context, payload) {
        context.commit(SET_PERCENT_LOADING_IMPORT_SALE_LOG, payload);
    },
};


const mutations = {
    [SET_PERCENT_LOADING_IMPORT_SALE_LOG](state, data) {
        state.percent_loading = data;
    },
    [SET_USERS](state, data) {
        state.users = data;
        state.errors = {};
    },
    [ADD_USERS](state, data) {
        state.users.push(data);
        state.errors = {};
    },
    [SET_USERS_ERROR](state, error) {
        state.errors = error;
    },
    [SET_USER_BY_ID](state, data) {
        state.user = data;
    },
    [SET_INPUT_VALUE](state, e) {
        let user = state.user;
        user[e.target.name] = e.target.value;
        state.user = user
    },
    [SET_USER_PAGINATE](state, data) {
        state.userPaginatedData = data;
    }
    , [SET_USER_LOG_PAGINATE](state, data) {
        state.userLogPaginatedData = data;
    },
    [SET_USER_LOG_HISTORIES](state, data) {
        state.logHistories = data;
    },
    [SET_USER_LOG_DATA_CHART](state, data) {
        state.timeChartData = data;
    },
    [SET_USER_LOG_ACTION_DATA](state, data) {
        state.logActionHistories = data;
    },
    [UPDATE_LIST_USER](state, user) {
        let users = state.users;
        let oldUser = _.find(users, function (object) {
            return object.id === user.id;
        });
        oldUser.status = user.status;
        oldUser.profile.thoi_gian_nghi_viec = user.profile.thoi_gian_nghi_viec;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
