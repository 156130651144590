<template>
  <div>
    <ValidationObserver v-if="!tableError" ref="form" v-slot="{ handleSubmit }">
      <form class="form" @submit.prevent="handleSubmit(handleCreateNotification)" style="display: flex; flex-direction: column; gap: 20px">
        <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 25px">
          <div style="display: flex; flex-direction: column;">
            <label>Loại <span style="color: red">*</span></label>
            <ValidationProvider name="Loại gửi" rules="required" v-slot="{ errors }" vid="type">
              <el-select v-model="type" clearable   placeholder="Chọn loại gửi"  style="width: 100%">
                <el-option
                    v-for="item in typeSendOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
              </div>
            </ValidationProvider>
          </div>
          <div v-if="checkTypeSend" style="display: flex; flex-direction: column">
            <label>Lớp <span style="color: red">*</span></label>
            <ValidationProvider name="Lớp" rules="required" v-slot="{ errors }" vid="classname">
              <el-select v-model="classId" filterable clearable placeholder="Chọn Lớp"  style="width: 100%">
                <el-option
                    v-for="item in classList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div v-if="checkTypeSend" style="display: grid; grid-template-columns: 1fr 1fr; gap: 25px">
          <div style="display: flex; flex-direction: column">
            <label>Học viên <span style="color: red">*</span></label>
            <ValidationProvider name="Học viên" rules="required" v-slot="{ errors }" vid="student">
              <el-select v-model="student" filterable multiple clearable placeholder="Chọn học viên" style="width: 100%">
                <el-option
                    v-for="item in studentList"
                    :key="item.id + item.name"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
              </div>
            </ValidationProvider>
          </div>
          <div style="display: flex; flex-direction: column">
            <label>Đối tượng gửi <span style="color: red">*</span></label>
            <ValidationProvider name="Đối tượng gửi" rules="required" v-slot="{ errors }" vid="target">
              <el-select v-model="target" clearable placeholder="Chọn đối tượng gửi" style="width: 100%">
                <el-option
                    v-for="item in targetList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div v-if="checkTypeSend">
          <label>Tiêu đề <span style="color: red">*</span></label>
          <ValidationProvider name="Tiêu đề" rules="required" v-slot="{ errors }" vid="title">
            <el-input v-model="title" placeholder="Nhập tiêu đề"/>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
            </div>
          </ValidationProvider>
        </div>
        <div v-if="checkTypeSend">
          <label>Nội dung <span style="color: red">*</span></label>
          <ValidationProvider name="Nội dung thông báo" rules="required" v-slot="{ errors }" vid="content">
            <el-input type="textarea" v-model="content" placeholder="Nhập nội dung thông báo"/>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
            </div>
          </ValidationProvider>
        </div>
        <div v-if="!checkTypeSend">
          <div v-if="fileImport" style="display: flex; align-items: center; gap: 8px">
            <span>{{ fileImport.name }}</span>
            <i class="el-icon-delete delete-file" @click="fileImport = null"></i>
          </div>
          <div v-else style="display: flex; flex-direction: column; gap: 20px">
            <div style="display: flex; flex-direction: column">
              <label>Bước 1: Tải xuống file mẫu</label>
              <button type="button" class="btn btn-primary" style="width: 130px" @click="downloadFileExcelExample">
                <i class="el-icon-bottom" style="color: white"></i>
                Tải xuống
              </button>
            </div>
            <div style="display: flex; flex-direction: column">
              <label>Bước 2: Tải file excel lên hệ thống</label>
              <label class="btn btn-warning" for="upload-file" style="width: 130px">
                <input type="file" style="display: none" id="upload-file" @change="handleFileUpload" accept=".xlsx, .xls" />
                <i class="el-icon-top" style="color: white"></i>
                Tải lên
              </label>
              <span v-if="mErrorImport" style="color: red">Vui lòng tải file</span>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: end; gap: 10px">
          <button type="button" class="btn btn-default" style="color: black" @click="closeDialog">Hủy bỏ</button>
          <button v-if="checkTypeSend" class="btn btn-primary">Xác nhận</button>
          <button type="button" v-else class="btn btn-primary" @click="handleImportFileExcel">Xác nhận</button>
        </div>
      </form>
    </ValidationObserver>
    <div v-else>
      <TableError :list-errors="listErrors"/>
      <div style="display: flex; justify-content: end; gap: 10px">
        <button type="button" class="btn btn-default" style="color: black" @click="closeDialog">Đóng</button>
        <button type="button" class="btn btn-primary" @click="backToImport">Quay lại</button>
      </div>
    </div>
  </div>
</template>

<script>
import {ErrorModel, optionTarget, typeSendOption} from "@/view/pages/system-information/models/notificationModel";
import * as XLSX from "xlsx";
import {
  CHECK_FILE_IMPORT_EXCEL,
  CREATE_NOTIFICATION_ERROR,
  GET_CLASS_LIST_ACTIVE,
  GET_STUDENTS_BY_CLASS,
  IMPORT_EXCEL_NOTIFICATION
} from "@/core/services/store/course/classes.module";
import TableError from "@/view/pages/system-information/component/TableError.vue";

export default {
  components: {TableError},
  data() {
    return {
      type: 1,
      student: [],
      target: '',
      title: '',
      content: '',
      classId: null,
      fileImport: null,
      dataImport: null,
      typeSendOption: typeSendOption,
      mErrorImport: false,
      listErrors: [],
      tableError: false,
      classList: [],
      studentList: [],
      targetList: optionTarget,
    }
  },
  mounted() {
    this.getListClassActive();
  },
  watch: {
    classId() {
      if (this.classId) {
        this.getListStudentByClass(this.classId)
      } else  {
        this.studentList = []
        this.student = []
        this.studentList = []
      }
    }
  },
  computed: {
    checkTypeSend() {
      return this.type === 1;
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', 'Gửi thông báo');
    },
    handleCreateNotification() {
      const payload = {
        classroom_id: this.classId,
        user_ids: this.student,
        app_type:this.target,
        title : this.title,
        content : this.content,
      }
      this.$store.dispatch(CREATE_NOTIFICATION_ERROR, payload).then(() => {
        this.$message.success('Gửi thông báo thành công')
        this.closeDialog()
      }).catch(e => {
        console.log(e)
        this.$message.error('Gửi thông báo thất bại')
      })
    },
    async handleImportFileExcel() {
      if (!this.fileImport) {
        this.mErrorImport = true
      }
      if (this.dataImport.length > 0) {
        await this.$store.dispatch(CHECK_FILE_IMPORT_EXCEL, this.dataImport).then(res => {
          const data = res.data
          if (data && data.isError === true) {
            this.$emit('setTitleError', 'Lỗi')
            this.tableError = true
            this.listErrors = data.data.map((item, index) => {
              return new ErrorModel(index +1, item.phone, item.app_type, item.errors)
            }).filter(item => item?.errors?.length > 0)
          } else {
            this.$store.dispatch(IMPORT_EXCEL_NOTIFICATION, this.dataImport).then(() => {
              this.$message.success('Gửi thông báo thành công')
              this.closeDialog()
            }).catch(e => {
              console.log(e)
              this.$message.error('Gửi thông báo thất bại')
            })
          }
        })
      }
    },
    downloadFileExcelExample() {
      const filePath = process.env.BASE_URL + 'excel/File mẫu danh sách gửi thông báo.xlsx';
      const a = document.createElement('a');
      a.href = filePath;
      a.download = 'File mẫu danh sách gửi thông báo.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileImport = file
        this.readExcelFile(file);
      }
    },
    backToImport() {
      this.tableError = false
      this.$emit('setTitleError', 'Gửi thông báo')
    },
    readExcelFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const columnMapping = { "Nội dung": 'content', "Số điện thoại": 'phone', "Tiêu đề": 'title', "Đối tượng gửi": 'app_type' }
        const headers = jsonData[0];
        const rows = jsonData.slice(1);
        const translatedHeaders = headers.map(header => columnMapping[header] || header);
        this.dataImport = rows.filter(item => item.length > 0).map(row => {
          const obj = {};
          translatedHeaders.forEach((header, index) => {
            obj[header] = row[index];
          });
          return obj;
        })
      };
      reader.readAsArrayBuffer(file);
    },
    getListClassActive() {
      this.$store.dispatch(GET_CLASS_LIST_ACTIVE).then(res => {
        if (res?.data?.length > 0) {
          this.classList = res.data
        }
      }).catch(e => {
        console.log(e)
      })
    },
    getListStudentByClass(id) {
      this.$store.dispatch(GET_STUDENTS_BY_CLASS, id).then(res => {
        if (res?.data?.length > 0) {
          this.studentList.push({
            id: -1,
            name: 'Tất cả'
          })
          this.studentList = res.data
        }})
    }
  },
}
</script>

<style lang="css" scoped>
  .delete-file {
    padding: 2px;
    border-radius: 9999px;
    cursor: pointer;
  }
  .delete-file:hover {
    color: red;
  }
</style>