<template>
  <!--begin::Card-->
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" autocomplete="off" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title ">
            <h3 class="card-label">
              Tạo trung tâm
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'center-list' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <!-- Ảnh đại diện -->
          <div class="form-group row">
            <div class="col-md-3">
              <div class="row mx-auto mt-8">
                <label class="col-xl-4 col-lg-3 col-form-label text-right">Ảnh đại diện</label>
                <div class="col-lg-9 col-xl-6">
                  <div class="image-input image-input-outline" id="kt_profile_avatar">
                    <div
                        class="image-input-wrapper"
                        :style="{ backgroundImage: `url(${photo})` }"
                    ></div>
                    <label
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Change avatar"
                    >
                      <i class="fa fa-pen icon-sm text-muted"></i>
                      <input
                          type="file"
                          name="profile_avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="onFileChange($event)"
                      />
                      <input type="hidden" name="profile_avatar_remove"/>
                    </label>
                    <span
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="cancel"
                        data-toggle="tooltip"
                        title="Cancel avatar"
                    >
                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                    <span
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title="Remove avatar"
                        @click="current_photo = null"
                    >
                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                  </div>
                  <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="center-name">Tên trung tâm
                      <span class="text-danger">*</span></label>
                    <ValidationProvider name="Tên trung tâm" vid="name" rules="required" v-slot="{ errors,classes }">
                      <el-input id="center-name" type="text" ref="name" name="name" :class="classes"
                             placeholder="Tên trung tâm"
                             v-model=center.name
                             @input="changeTitle"
                      />
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Đường dẫn</label>
                    <div class="el-input el-input-group el-input-group--prepend">
                      <el-input autocomplete="off" type="text" disabled v-model=center.slug />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="d-block">Loại trung tâm</label>
                <el-select v-model="center.type"
                           clearable filterable
                           placeholder="Chọn loại trung tâm"
                           class="w-100"
                >
                  <el-option v-for="item in type_center "
                             :key="item.id"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Số điện thoại
                  <span class="text-danger"></span></label>
                  <el-input name="phone" placeholder="Số điện thoại" v-model=center.phone />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Địa chỉ
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Địa chỉ" rules="required" v-slot="{ errors,classes }">
                  <el-input type="text" name="address" placeholder="Địa chỉ"
                         :class="classes"
                         v-model=center.address />
                  <div class="fv-plugins-message-container">
                    <div data-field="address" data-validator="notEmpty" class="fv-help-block">
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Thời gian làm việc</label>
                <el-input type="text" name="work_time"
                       placeholder="Thời gian làm việc (Ví dụ: 8h-17h)"
                       v-model=center.work_time />
              </div>
            </div>
          </div>
          <!-- Tỉnh, Huyện -->
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="center-province">Tỉnh <span class="text-danger"></span></label>
                <el-select id="center-province" v-model="center.province_id"
                           class="w-100"
                           placeholder="Chọn tỉnh thành"
                           clearable filterable
                           @change="updateDistricts"
                >
                  <el-option
                      v-for="item in provinces"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="center-district">Quận/Huyện</label>
                <el-select id="center-district" v-model="center.district_id"
                           class="w-100"
                           clearable filterable
                           placeholder="Chọn quận/huyện"
                >
                  <el-option
                      v-for="item in districts"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="row mt-6 mb-6">
            <div class="col-md-3 d-flex justify-content-center">
              <div class="col-form-label">
                <div class="checkbox-inline">
                  <label class="checkbox checkbox-success">
                    <input type="checkbox" name="Checkboxes5" v-model="center.status">
                    <span></span>Kích hoạt?</label>
                </div>
              </div>
            </div>
            <div class="col-md-3 d-flex justify-content-center">
              <div class="col-form-label">
                <div class="checkbox-inline">
                  <label class="checkbox checkbox-success">
                    <input type="checkbox" name="Checkboxes5" v-model="center.is_center">
                    <span></span>Là trung tâm nổi bật?</label>
                </div>
              </div>
            </div>
            <div class="col-md-3 d-flex justify-content-center">
              <div class="col-form-label">
                <div class="checkbox-inline">
                  <label class="checkbox checkbox-success">
                    <input type="checkbox" name="Checkboxes5" v-model="center.has_assistant">
                    <span></span>Có trợ giảng không?</label>
                </div>
              </div>
            </div>
            <div class="col-md-3 d-flex justify-content-center">
              <div class="col-form-label">
                <div class="checkbox-inline">
                  <label class="checkbox checkbox-success">
                    <input type="checkbox" name="Checkboxes5" v-model="center.is_edutalk">
                    <span></span>Thuộc Edutalk?</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group mb-1 col-md-12">
              <label class="mt-6">Công cụ bán hàng</label>
              <vue-editor id="editor_schedule" :editorOptions="editorSettings" useCustomImageHandler
                          @image-removed="removeImage"
                          @image-added="handleImageAdded" v-model=center.information.schedule></vue-editor>
            </div>
          </div>

          <!-- Thông tin trên bản đồ -->
          <div class="row mt-6">
            <div class="col-md-4">
              <div class="form-group">
                <label for="google-map">Google Map</label>
                <el-input id="google-map" type="text" name="work_time"
                       placeholder="Link google map"
                       v-model=center.google_map />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="kinh-do">Kinh độ</label>
                <el-input id="kinh-do" type="text" name="address" placeholder="Kinh độ"
                       v-model=center.lng />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="vi-do">Vĩ độ</label>
                <el-input id="vi-do" type="text" name="work_time"
                       placeholder="Vĩ độ"
                       v-model=center.lat />
              </div>
            </div>
          </div>
          <div class="row mt-6">
            <div class="form-group col-md-12">
              <label>Ảnh Gallery</label>
              <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
            </div>
          </div>
        </div>
        <div class="mx-auto py-3">
          <div class="">
            <el-button type="success" native-type="submit" :loading="is_disable" :disabled="is_disable" class="btn btn-success mr-2">
              Tạo mới
            </el-button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
  <!--end::Card-->
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {Quill, VueEditor} from 'vue2-editor'
import ImageResize from 'quill-image-resize-vue';
import {ImageDrop} from 'quill-image-drop-module';
import {GET_PROVINCES} from "@/core/services/store/public/provinces.module";
import {GET_DISTRICTS} from "@/core/services/store/public/districts.module";
import {CREATE_CENTER} from "@/core/services/store/center/centers.module";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import ApiService from "@/core/services/api.service";
import {ChangeToSlug} from "@/core/filters";

const _ = require('lodash');
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
  name: "Create-Center",
  components: {
    VueEditor,
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      type_center: [{label: 'Nhãn E', value: 1 }, {label: 'Đối tác', value: 0 }],
      center: {
        name: '',
        user_id: "",
        address: "",
        province_id: "",
        district_id: "",
        status: '',
        work_time: '',
        slug: "",
        fan_page: "",
        youtube_channel: "",
        website: '',
        long_description: '',
        short_description: '',
        is_popular: '',
        avg_rate: '',
        has_assistant: '',
        is_center: '',
        is_edutalk: '',
        lng: '',
        lat: '',
        google_map: '',
        information: {
          intro: '',
          special: '',
          course: '',
          schedule: '',
          teacher: '',
          sale: '',
          feedback: ''
        },
        area_id: '',
        phone: '',
        thumbnail: [],
        avatar: '',
        he_so_kinh_doanh: '',
        he_so_len_cap: '',
        type: ''
      },
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      provinces: [],
      districts: [],
      default_photo: "media/users/blank.png",
      current_photo: null,
      image_name: null,
      image_type: null,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        // thumbnailWidth: 200,
        maxFilesize: 0.5,
        thumbnailMethod: 'contain',
        addRemoveLinks: true,
        autoProcessQueue: false,
        headers: {"My-Awesome-Header": "header value"},
        init: function () {
          this.on("complete", function (file) {
            // this.files.push(file);
          });
        }
      },
      is_disable: false,

    };
  },
  computed: {
    photo() {
      return this.current_photo == null
          ? this.default_photo
          : this.current_photo;
    }
  },
  created() {
   this.queryProvinces();
   this.queryDistrict();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý trung tâm", route: 'center-list'},
      {title: "Thêm trung tâm"}
    ]);
  },
  methods: {
    changeTitle: function () {
        this.center.slug = ChangeToSlug(this.center.name);
    },
    removeImage: function (delta, oldContents) {
      ApiService.post('remove-image', {url: delta})
          .then(({data}) => {

          })
          .catch(err => {
            console.log(err);
          });
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      var formData = new FormData();
      formData.append("image", file);

      ApiService.post('upload-image', formData)
          .then(({data}) => {
            let url = data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch(err => {
            console.log(err);
          });
    },
    updateDistricts: function () {
      this.queryDistrict();
    },
    queryProvinces(){
      this.$store.dispatch(GET_PROVINCES).then((res) => {
        this.provinces = res.data;
      })
    },
    queryDistrict(){
      this.$store.dispatch(GET_DISTRICTS, {province_id: this.center.province_id}).then((res) => {
        this.districts = res.data;
      })
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
        this.image_name = file.name;
        this.image_type = file.type;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    async onSubmit() {
      this.is_disable = true;
      let has_assistant = this.center.has_assistant == true ? 1 : 0;
      let is_center = this.center.is_center == true ? 1 : 0;
      let status = this.center.status == true ? 1 : 0;
      let is_edutalk = this.center.is_edutalk == true ? 1 : 0;
      let formData = new FormData();
      formData.append('name', this.center.name);
      formData.append('address', this.center.address);
      formData.append('province_id', this.center.province_id);
      formData.append('district_id', this.center.district_id);
      formData.append('status', status);
      formData.append('slug', this.center.slug);
      formData.append('long_description', this.center.long_description);
      formData.append('short_description', this.center.short_description);
      formData.append('has_assistant', has_assistant);
      formData.append('is_center', is_center);
      formData.append('is_edutalk', is_edutalk);
      formData.append('lng', this.center.lng);
      formData.append('lat', this.center.lat);
      formData.append('google_map', this.center.google_map);
      formData.append('phone', this.center.phone);
      formData.append('work_time', this.center.work_time);
      formData.append('he_so_kinh_doanh', this.center.he_so_kinh_doanh);
      formData.append('he_so_len_cap', this.center.he_so_len_cap);
      formData.append('intro', this.center.information.intro);
      formData.append('special', this.center.information.special);
      formData.append('course', this.center.information.course);
      formData.append('schedule', this.center.information.schedule);
      formData.append('teacher', this.center.information.teacher);
      formData.append('sale', this.center.information.sale);
      formData.append('feedback', this.center.information.feedback);
      formData.append('is_edutalk', this.center.type);
      let avatar = this.photo;

      function urltoFile(url, filename, mimeType) {
        return (fetch(url)
                .then(function (res) {
                  return res.arrayBuffer();
                })
                .then(function (buf) {
                  return new File([buf], filename, {type: mimeType});
                }).catch((error) => {
                  return 1;
                })
        );
      }

      if (avatar.search('https') === -1) {
        let file = await urltoFile(avatar, this.image_name, this.image_type);
        if (file !== 1 && file.name !== 'null') {
          formData.append('avatar', file);
        }
      }
      let ins = this.$refs.myVueDropzone.dropzone.files.length;
      for (var x = 0; x < ins; x++) {
        formData.append("thumbnail[]", this.$refs.myVueDropzone.dropzone.files[x]);
      }
      this.$store.dispatch(CREATE_CENTER, formData).then((res) => {
          this.$router.push({name: "center-list"}).then(() => {
            this.$notify({
              title: 'Thành công',
              message: 'Thêm mới trung tâm thành công',
              type: 'success'
            });
          })
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }else {
          this.$notify({
            title: 'Thất bại',
            message: e.data.message,
            type: 'success'
          });
        }
      }).finally(() => {
        this.is_disable = false;
      });
    },
  },
};
</script>
<style>
.dz-progress {
  /* progress bar covers file name */
  display: none !important;
}
</style>

