import ApiService from "@/core/services/api.service";

const _ = require('lodash');
//action types
export const GET_STUDENTAMOUNTS = "list-studentAmount";
export const EXPORT_EXCEL_STUDENTAMOUNTS = "export_excel_studentAmounts";
export const EXPORT_STUDENTAMOUNT_GOOGLE_SHEET = "export_studentAmount_google_sheet";
export const EXPORT_SALES_TIME_KEEP = "export-sales-time-keep";
export const EXPORT_SALES_TIME_KEEP_GOOGLE_SHEET = "export-sales-time-keep-google-sheet";
export const GET_BRANCH_SALES = "get_sales_by_branch";
export const GET_BRANCH_SALES_V2 = "get_sales_by_branch_v2";
export const DSKH_DOI = "dskh_doi";
export const DSKH_DOI_V2 = "dskh_doi-v2";
export const REPORT_DIGITAL = "report_digital";
export const SHOW_DETAIL_BY_DATE = "get-detail-data-by-date";
export const SHOW_DETAIL_BY_DATE_V2 = "get-detail-data-by-date-v2";
export const SHOW_DETAIL_BY_DATE_TVV = "get-detail-data-by-date_tvv";
export const DSKH_BRANCH = "dskh_branch";
export const REPORT_TVV = "report_tvv";
export const GET_SALES_ON_WORK = "get-sales-on-work";
export const GET_EMPLOYEE = "get_employee";

//mutations types
export const SET_STUDENTAMOUNTS = "setStudentAmounts";
export const SET_SALES_TIME_KEEP = "set-sales-time-keep";
export const SET_STUDENTAMOUNT_PAGINATE = "set_studentAmount_paginate_value";
export const SET_SALES_TIME_KEEP_PAGINATE = "set-sales-time-keep-paginate";


const state = {
    errors: null,
    studentAmounts: [],
    studentAmountPaginatedData: [],
    salesTimeKeepPaginate: [],
    salesTimeKeep: [],
};

const getters = {
    getAllStudentAmounts(state) {
        return state.studentAmounts;
    },
    getStudentAmountPaginate(state) {
        return state.studentAmountPaginatedData;
    },
    getSalesTimeKeep(state) {
        return state.salesTimeKeep;
    },
    getSalesTimeKeepPaginate(state) {
        return state.salesTimeKeepPaginate;
    },
};

const actions = {
    [GET_STUDENTAMOUNTS](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("list-of-settlements", payload)
                .then(({data}) => {
                    console.log(data.data)
                    context.commit(SET_STUDENTAMOUNTS, data.data.logs.data);
                    const studentAmountPagination = {
                        total: data.data.logs.total,  // total number of elements or items
                        per_page: data.data.logs.data.per_page, // items per page
                        current_page: data.data.logs.current_page, // current page (it will be automatically updated when studentAmounts clicks on some page number).
                        total_pages: data.data.logs.last_page // total pages in record
                    }
                    context.commit(SET_STUDENTAMOUNT_PAGINATE, studentAmountPagination)
                    resolve(data);
                })
                .catch((errors) => {
                    console.log(errors)
                });
        });
    },

    [EXPORT_EXCEL_STUDENTAMOUNTS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('edutalk-center-payment/export', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [EXPORT_STUDENTAMOUNT_GOOGLE_SHEET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('edutalk-center-payment/export-google-sheet', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_SALES_TIME_KEEP](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('reports/export-slk', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_SALES_TIME_KEEP_GOOGLE_SHEET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('reports/export-slk-google-drive', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },


    [GET_BRANCH_SALES](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('reports/get-branch-sales', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_BRANCH_SALES_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('reports/get-branch-sales-v2', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [GET_EMPLOYEE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('reports/employees', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [DSKH_DOI](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('reports/dskh-doi', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DSKH_DOI_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('reports/dskh-doi-v2', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [REPORT_DIGITAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('reports/digital', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [DSKH_BRANCH](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('reports/dskh-branch', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [REPORT_TVV](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('reports/tvv', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [SHOW_DETAIL_BY_DATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('reports/all-action-click-detail', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [SHOW_DETAIL_BY_DATE_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('reports/all-action-click-detail-v2', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [SHOW_DETAIL_BY_DATE_TVV](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('reports/all-action-click-detail-tvv', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },

    [GET_SALES_ON_WORK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('reports/sales-time-keep', payload).then(({data}) => {
                console.log(data)
                // context.commit(SET_SALES_TIME_KEEP)
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },


};

const mutations = {
    [SET_STUDENTAMOUNTS](state, data) {
        state.studentAmounts = data;
        state.errors = {};
    },
    [SET_STUDENTAMOUNT_PAGINATE](state, data) {
        state.studentAmountPaginatedData = data;
    },
    [SET_SALES_TIME_KEEP](state, data) {
        state.salesTimeKeep = data;
    },
    [SET_SALES_TIME_KEEP_PAGINATE](state, data) {
        state.salesTimeKeepPaginate = data;
    },
};
export default {
    state,
    actions,
    mutations,
    getters
};
