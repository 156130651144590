<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Danh sách phòng ban</h3>
        </div>
        <div class="card-title">
          <button
              class="font-weight-bold font-size-3  btn btn-success"
              @click="createDepartment()"
          >Thêm mới
          </button>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <div class="form-group">
            <div class="input-group">
              <div class="col-md-2">
                <div class="form-group">
                  <label>Tên phòng ban</label>
                  <input type="text" class="form-control"
                         v-model="query.keyword"
                         @input="search"
                         placeholder="Nhập tên phòng ban">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Trạng thái</label>
                  <el-select filterable class="w-100" placeholder="Trạng thái"
                             clearable
                             v-model="query.status"
                             @input="search"
                  >
                    <el-option
                        v-for="(item, index) in list_status"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    >
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Thời gian tạo</label>
                  <date-picker
                      placeholder="Chọn thời gian"
                      format="DD-MM-YYYY"
                      range
                      valueType="YYYY-MM-DD" v-model="query.date_range"
                      @clear="query.date_range = ''"
                      @input="search"
                  >
                  </date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="example-preview table-responsive">
            <table class="table table-hover text-center table-bordered table-vertical-center">
              <thead>
              <tr>
                <th scope="col">STT</th>
                <th scope="col">Tên phòng ban</th>
                <th scope="col">Thời gian tạo</th>
                <th scope="col">Trạng thái</th>
                <th scope="col">Hành động</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in department" :key="index" class="">
<!--                <td>{{ index + 1 }}</td>-->
                <td>{{ (query.page - 1) * per_page + index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.created_at |formatDateTimeVietnam}}</td>
                <td v-if="item.status" align="center"> <span class="bg-success w-25 rounded-pill p-2">Hiển thị</span></td>
                <td v-else align="center"><span class="bg-secondary w-25 rounded-pill p-2">Ẩn</span></td>
                <td>
                  <button @click="editDepartment(item.id)" title="Sửa"
                          class="btn btn-sm btn-icon mr-2 btn-outline-info"><i
                      class="fas fa-pen-nib"></i>
                  </button>
                  <button title="Xóa" @click="deleteDepartment(item.id,item.name)" href="javascript:"
                          class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="edu-paginate mx-auto text-center">
                <paginate
                    v-model="query.page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :page-class="'page-item'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                >
                </paginate>
      </div>
    </div>
    <el-dialog @close="resetModal" :title="title" class="responsive" :visible.sync="dialogFormVisible">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form" @submit.prevent="handleSubmit(updateOrCreateDepartment)">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Tên phòng ban
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Tên phòng ban" rules="required"
                                    v-slot="{ errors,classes }">
                  <input type="text" ref="name" name="name" class="form-control" v-model="form.name"
                         placeholder="Tên phòng ban" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                  <div class="col-md-12 p-0 pt-2">
                    <label class="col-md-12 p-0">Trạng thái
                      <span class="text-danger">*</span></label>
                    <el-switch
                        v-model="form.status"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                    </el-switch>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <button type="submit" class="btn btn-primary mr-2">{{ is_edit ? 'Sửa' : 'Thêm mới' }}</button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </el-dialog>
  </div>
</template>

<script>
import {GET_PERMISSION} from "@/core/services/store/user/permission.module";
import {SHOW_DEPARTMENTS, UPDATE_DEPARTMENTS} from "@/core/services/store/ticket/ticket.module";
import {mapGetters} from "vuex";
import {DELETE_DEPARTMENT, GET_DEPARTMENT} from "@/core/services/store/department/department.module";
import Swal from "sweetalert2";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import errorMessage from "@/view/pages/common/ErrorMessage";

export default {
  name: "ManageDepartmentList",
  components: {
    DatePicker
  },
  data() {
    return {
      query: {
        search: "",
        page: 1,
        status: '',
        keyword: '',
        date_range: [],
      },
      department: [],
      is_edit: false,
      dialogFormVisible: false,
      form: {
        name: '',
        status: true,
      },
      status: true,
      id: '',
      total_pages: 1,
      last_page: 1,
      per_page: 20,
      list_status: [
        {id: 0, name: 'Ẩn'},
        {id: 1, name: 'Hiển thị'},
      ],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    title() {
      return this.is_edit ? 'Sửa phòng ban' : 'Tạo phòng ban';
    }
  },
  mounted() {
    this.query.search = this.$route.query.name ? this.$route.query.name : '';
    this.query.page = parseInt(this.$route.query.page ? this.$route.query.page : 1);
    this.$store.dispatch(GET_PERMISSION, {
      name: this.$route.query.name,
      page: this.query.page,
      show_progress: 1
    });
    this.getList();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý tên phòng ban"},
      {title: "Danh sách phòng ban"}
    ]);
  },
  methods: {
    getList() {
      this.pullParamsUrl()
      this.listDepartment()
    },
    pullParamsUrl() {
      this.query.page = this.$route.query.page ? Number(this.$route.query.page) : 1
      this.query.keyword = this.$route.query.keyword
      this.query.status = this.$route.query.status ? Number(this.$route.query.status) : ''
      this.query.date_range = this.$route.query.date_range ? this.$route.query.date_range : [];
    },
    pushParamsToUrl() {
      let params = {
        keyword: this.query.keyword,
        status: this.query.status,
        page: this.query.page,
        date_range : this.query.date_range,
        ...this.query
      };
      this.pushParamsUrl(params);
    },
    listDepartment(){
      let page = this.query.page;
      let keyword = this.query.keyword ? this.query.keyword.trim() : "";
      let status = this.query.status ?? '';
      let date_range = this.query.date_range ?? [];
      this.$store.dispatch(GET_DEPARTMENT, {
        all: true ,
        keyword: keyword,
        status: status,
        date_range: date_range,
        page: page,
      }).then((res) => {
        this.query.page = res.pagination.current_page;
        this.last_page = res.pagination.last_page;
        this.department = res.data;
        this.per_page = res.pagination.per_page;
      });
    },
    search() {
      this.pushParamsToUrl()
      this.listDepartment()
    },
    editDepartment(id) {
      this.is_edit = true;
      this.id = id;
      this.dialogFormVisible = true;
      this.$store.dispatch(SHOW_DEPARTMENTS, {id: id}).then(res => {
        this.form.name = res.data.name;
        this.form.status = res.data.status == true ? true : false;
        this.dialogFormVisible = true;
        this.getList();
      })
    },
    deleteDepartment(id , name) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa phòng ban " + name,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((res) => {
        if (res.isConfirmed) {
          this.$store.dispatch(DELETE_DEPARTMENT, id).then(res => {
            console.log(res)
            if (res) {
              this.$notify({
                title: 'Không thành công',
                message: res.message,
                type: 'error'
              });
            }else {
              this.$notify({
                title: 'Thành công',
                message: 'Xóa dữ liệu thành công',
                type: 'success'
              });
            }
            this.listDepartment();
          })
        }
      });
    },
    createDepartment() {
      this.is_edit = false;
      this.dialogFormVisible = true;
    },
    updateOrCreateDepartment() {
      this.$store.dispatch(UPDATE_DEPARTMENTS, {id: this.id , name: this.form.name , status: this.form.status}).then(res => {
        if (res) {
          this.dialogFormVisible = false;
          if (this.id){
            this.$notify({
              title: 'Thành công',
              message: 'Cập nhập thành công',
              type: 'success'
            });
          }else {
            this.$notify({
              title: 'Thành công',
              message: 'Thêm mới thành công',
              type: 'success'
            });
          }
          this.getList();
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }else {
          this.$notify({
            title: 'Thất bại',
            message: e.data.message,
            type: 'success'
          });
        }
      })
    },
    resetModal() {
      this.dialogFormVisible = false;
      this.form.name = '';
      this.form.status = true;
      this.id = '';
    },
    clickCallback(obj) {
      this.query.page = obj
      let params = {
        keyword: this.query.keyword,
        status: this.query.status,
        page: this.query.page,
        date_range : this.query.date_range,
        ...this.query
      };
      this.pushParamsUrl(params);
      this.listDepartment()
    },
  }
}
</script>

<style scoped>

</style>