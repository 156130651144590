import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "../auth.module";
import {EXPORT_EMPLOYEE_EXAMPLE, IMPORT_EMPLOYEE} from "../user/users.module";

const _ = require('lodash');

export const COMPANY_EVENT_GET_LIST     = "company-events-list";
export const COMPANY_EVENT_GET_BY_ID    = "company-events-by-id";
export const COMPANY_EVENT_CREATE       = "company-events-create";
export const COMPANY_EVENT_UPDATE       = "company-events-update";
export const COMPANY_EVENT_DELETE       = "company-events-delete";

export const COMPANY_EVENT_USER_GET_LIST = "company-event-users-list";
export const COMPANY_EVENT_USER_CREATE = "company-event-users-create";
export const COMPANY_EVENT_USER_UPDATE = "company-event-users-update";
export const COMPANY_EVENT_USER_DELETE = "company-event-users-delete";
export const COMPANY_EVENT_USER_QR_CODE = "company-event-users-qrcode";
export const COMPANY_EVENT_USER_GET_HISTORIES = "company_event_user_get_histories";

export const COMPANY_EVENT_USER_EXPORT_EXAMPLE = "company-event-users-export-example";
export const COMPANY_EVENT_USER_EXPORT = "company-event-users-export";
export const COMPANY_EVENT_USER_IMPORT = "company-event-users-import";

//mutations types
export const COMPANY_EVENT_SET = "setCompanyEvent";

const state = {
    errors: null,
    company_events: [],
    eventCompanyPaginatedData: [],
};

const getters = {
    getCompanyEventPaginate(state) {
        return state.eventCompanyPaginatedData;
    },
};

const actions = {
    [COMPANY_EVENT_GET_LIST](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query('company-events', credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                });
        });
    },
    [COMPANY_EVENT_GET_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('company-events', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [COMPANY_EVENT_CREATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('company-events', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [COMPANY_EVENT_UPDATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('company-events/'+ payload.id + '?_method=PUT', payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [COMPANY_EVENT_DELETE](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('company-events', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    [COMPANY_EVENT_USER_GET_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query('company-event-users', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [COMPANY_EVENT_USER_CREATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('company-event-users', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [COMPANY_EVENT_USER_UPDATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('company-event-users', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [COMPANY_EVENT_USER_DELETE](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('company-event-users', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [COMPANY_EVENT_USER_QR_CODE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('qrcode', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    [COMPANY_EVENT_USER_EXPORT_EXAMPLE](context, payload) {
        return new Promise(resolve => {
            ApiService.query('export-event-user-example', payload).then(({data}) => {
                const filename = 'Data Participants Example' + ".xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [COMPANY_EVENT_USER_EXPORT](context, payload) {
        return new Promise(resolve => {
            ApiService.query('export-event-user', payload).then(({data}) => {
                const filename = 'Data event user' + ".xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [COMPANY_EVENT_USER_IMPORT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("company_event_user_import", payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [COMPANY_EVENT_USER_GET_HISTORIES](context, payload) {
        return new Promise(resolve => {
            ApiService.query('get-history-by-user', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
}

const mutations = {
    [COMPANY_EVENT_SET](state, data) {
        state.events = data;
        state.errors = {};
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
