import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const GET_BRANCHES_NOT_AREA = "get_branches_not_area";
export const CREATE_AREA = "create_area";
export const UPDATE_AREA = "update_area";
export const GET_AREAS = "GET_AREAS";
export const GET_LIST_AREAS = "GET_LIST_AREAS";
export const DELETE_AREA = "delete_area";
export const GET_AREA_BY_ID = "get_area_by_id";
export const GET_AREA_BY_TYPE = "areas/get_area_by_list_type";

const state = {};
const getters = {};
const actions = {
    // Get provinces

    [GET_BRANCHES_NOT_AREA](context, payload) {
        return new Promise(resolve => {
            ApiService.query('branches/branch-not-area', {}).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_AREAS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('areas', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_LIST_AREAS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('areas/get-list', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_AREA_BY_ID](context, payload) {
        return new Promise(resolve => {
            ApiService.get('areas', payload.id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_AREA](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('areas', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [UPDATE_AREA](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('areas', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [DELETE_AREA](context, id) {
        return new Promise(resolve => {
            ApiService.delete('areas', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_AREA_BY_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_AREA_BY_TYPE, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
