<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Danh sách tin tuyển dụng</h3>
        </div>
        <div class="card-title">
          <router-link
              class="font-weight-bold font-size-3  btn btn-success"
              :to="{ name: 'jobs-create' }"
          >Tạo tin tuyển dụng mới
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <div class="example mb-10">
          <div class="input-group row">
            <div class="col-md-3 form-group">
              <label for="keyword">Tiêu đề
                <span class="text-danger"></span></label>
              <el-input id="keyword" type="text" v-model="query.keyword"
                     placeholder="Tên công việc" />
            </div>
            <div class="col-md-3 form-group">
              <label for="type">Loại tin</label>
              <el-select id="type" class="w-100" v-model="query.is_special" placeholder="Loại tin" filterable clearable>
                <el-option
                    v-for="item in TYPE_RECRUITMENT_JOB"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="col-md-3">
              <label for="status">Trạng thái công việc</label>
              <el-select id="status" class="w-100" v-model="query.status" placeholder="Trạng thái công việc" filterable clearable>
                <el-option
                    v-for="item in STATUS_RECRUITMENT_JOB"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="col-md-3">
              <label for="status">Trạng thái CV gửi về</label>
              <el-select id="status" class="w-100" v-model="query.cv_status" placeholder="Trạng thái CV gửi về" filterable clearable>
                <el-option
                    v-for="item in RECRUITMENT_JOB_CV_STATUS"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <el-button class="btn btn-primary" @click="searchData" :loading="isLoading">Tìm kiếm</el-button>
              </div>
            </div>
          </div>
          <div class="example-preview table-responsive overflow-auto">
            <table class="table table-vertical-center table-bordered table-hover table-vertical-center b-table">
              <thead class="thead-light">
                <tr>
                  <th scope="col" class="text-center">STT</th>
                  <th scope="col">Tên công việc</th>
                  <th scope="col">Loại tin</th>
                  <th scope="col">Trạng thái</th>
                  <th scope="col">Người đăng</th>
                  <th scope="col">Thời gian đăng</th>
                  <th scope="col">Thời gian cập nhật</th>
                  <th scope="col">Cv gửi về</th>
                  <th scope="col">Hành động</th>
                </tr>
              </thead>
              <tbody v-if="isLoading">
                <tr role="row" class="b-table-busy-slot" >
                  <td colspan="8" role="cell" class="text-center">
                    <div class="text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
              <tr v-for="(job,index) in getAllJobs" :key="index">
                <td class="text-center">{{  (Number(query.page) - 1) * getJobPaginate.per_page + index + 1}}</td>
                <td class="">{{job.title}}</td>
                <td class="">{{renderType(job.is_special)}}</td>
                <td class="">
                  <span class="badge" :class="job.status ? 'badge-success' : 'badge-info'" style="min-width: 54px">
                    {{ job.status ? 'Hiển thị' : 'Ẩn' }}
                  </span>
                </td>
                <td class="">{{job.user.name + ' - ' +  (job.user.id + 100000)}}</td>
                <td class="">{{job.created_at}}</td>
                <td class="">{{job.updated_at}}</td>
                <td class="">
                  <router-link :to="{ name : 'curriculum-vitae-list-by-job' , params: { jobId: job.id } }" v-if="job.cvs.length > 0"
                               class="badge badge-success cvs-router" style="min-width: 70px">Xem cv <span class="cvs-number">{{renderNumberCvsUnCheck(job.cvs)}}</span>
                  </router-link>
                  <span v-else class="badge badge-warning">Chưa có cv</span>
                </td>
                <td class="">
                  <router-link :to="{ name: 'jobs-update', params: {id : job.id} }" title="Sửa"
                               class="btn btn-sm btn-icon mr-2 btn-outline-info"><i
                      class="fas fa-pen-nib"></i>
                  </router-link>
                  <a title="Xóa" @click="deleteJob(job.id)" href="javascript:"
                     class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="edu-paginate mx-auto text-center" v-if="getAllJobs.length > 0">
        <paginate
            v-model="query.page"
            :page-count="total_pages"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'Trước'"
            :next-text="'Sau'"
            :container-class="'pagination b-pagination'"
            :pageLinkClass="'page-link'"
            :page-class="'page-item'"
            :next-link-class="'next-link-item'"
            :prev-link-class="'prev-link-item'"
            :prev-class="'page-link'"
            :next-class="'page-link'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import {
  TYPE_RECRUITMENT_JOB,
  STATUS_RECRUITMENT_JOB,
  RECRUITMENT_JOB_CV_STATUS
} from '@/core/option/recruimentJobOption';
import {DELETE_RECRUITMENT_JOB, GET_RECRUITMENT_JOBS} from "@/core/services/store/recruitment/recruiment.module";

const _ = require('lodash');

export default {
  name: "RecruitmentJobsList",
  data() {
    return {
      query : {
        page: 1,
        keyword: '',
        is_special: '',
        status: '',
        cv_status: '',
      },
      isLoading : false,
      cvsUnchecked : 0,
      TYPE_RECRUITMENT_JOB,
      STATUS_RECRUITMENT_JOB,
      RECRUITMENT_JOB_CV_STATUS
    };
  },
  components: {},
  computed: {
    ...mapGetters(["getAllJobs","getJobPaginate"]),
    total_pages() {
      return Number(this.getJobPaginate?.total_pages) || 1;
    },
  },
  created() {
    this.setParams();
    this.isLoading = true;
    this.$store.dispatch(GET_RECRUITMENT_JOBS, this.query).then(() => {
      this.isLoading = false;
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý công việc"},
      { title: "Công việc" }
    ]);
  },
  methods: {
    searchByTitle: function (){
      this.pushParams();
    },
    deleteJob(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_RECRUITMENT_JOB, id).then(() => {
          });
        }
      });
    },
    setParams: async function (){
      this.pullParamsFromUrl(this.query)
    },
    searchData: function() {
      this.isLoading = true;
      this.pushParamsUrl(this.query)
      this.getQuery();
    },
    renderType: function (is_special){
      let result = _.find(this.TYPE_RECRUITMENT_JOB,function (item){
        return item.value === is_special;
      })
      return result ? result.label : '';
    },
    clickCallback(pageNum) {
      this.query.page = pageNum;
      this.pushParams();
      this.isLoading = true;
      this.$store.dispatch(GET_RECRUITMENT_JOBS,this.query).then((res)=>{
        this.isLoading = false;
      });
    },
    pushParams: function (){
      this.pushParamsUrl(this.query);
    },
    getQuery: function (){
      this.$store.dispatch(GET_RECRUITMENT_JOBS, this.query).then((res) => {
        this.query.page = 1;
        this.isLoading = false;
      });
    },
    renderNumberCvsUnCheck: function (cvs){
      let num = 0;
      cvs.forEach(function (cv){
        if (cv.type === 1){
          num++;
        }
      });
      return num;
    }
  },
};
</script>
<style>

.reportrange-text[data-v-79d7c92a]  {
  text-align: center;
  padding: 12px 10px;
}
.calendars {
  flex-wrap: unset !important;
}
.vue-daterange-picker {
  width: 100%;
}
.cvs-router {
  position: relative;
}
.cvs-number {
  position: absolute;
  top: -9px;
  right: -9px;
  background: red;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  padding-top: 2px;
}
</style>