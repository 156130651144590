<template>
  <!--begin::Card-->
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Cập nhật công việc</h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'jobs-list' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group row">
            <div class="col-md-6">
              <div class="row mx-auto mt-8">
                <div class="col-xl-4 col-lg-3 d-flex justify-content-center">
                  <input-avatar :photo="photo"
                                :current_photo="current_photo"
                                sub-text="Ảnh đại diện"
                                @onFileChange="onFileChange"
                                @removeAvatar="current_photo = null">

                  </input-avatar>
                </div>
                <div class="col-lg-9 col-xl-6 d-flex justify-content-center">
                  <input-avatar :photo="cover_photo"
                                :current_photo="current_cover_photo"
                                sub-text="Ảnh bìa"
                                @onFileChange="onFileCoverChange"
                                @removeAvatar="current_cover_photo = null">
                  </input-avatar>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Tên công việc
                      <span class="text-danger">*</span></label>
                    <ValidationProvider name="Tiêu đề công việc" vid="title" rules="required|max:100" v-slot="{ errors,classes }">
                      <el-input type="text" ref="title" name="title" :class="classes"
                                placeholder="Tiêu đề công việc"
                                v-model="job.title" v-on:input="enterTitle" />
                      <error-message field="title" :errors="errors"></error-message>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-12">
                  <label>Đường dẫn <span class="text-danger"> *</span></label>
                  <div class="form-group">
                    <ValidationProvider name="Đường dẫn" vid="slug" rules="required" v-slot="{ errors,classes }">
                      <el-input autocomplete="off" type="text" v-model="job.slug" aria-describedby="basic-addon1" placeholder="Đường dẫn" disabled/>
                      <error-message field="slug" :errors="errors"></error-message>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-6">
            <div class="col-md-4">
              <label for="status">Trạng thái</label>
              <div class="form-group d-flex align-items-center mt-3">
                <el-switch v-model="job.status"></el-switch>
              </div>
            </div>
            <div class="col-md-4">
              <label for="is_special">Loại tin<span class="text-danger"> *</span></label>
              <div class="form-group">
                <ValidationProvider name="Loại công việc" vid="is_special" rules="required" v-slot="{ errors,classes }">
                  <el-select id="is_special" class="w-100" :class="classes" v-model="job.is_special" placeholder="Loại tin" filterable clearable>
                    <el-option
                        v-for="item in TYPE_RECRUITMENT_JOB"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                  <error-message field="is_special" :errors="errors"></error-message>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <label for="excerpt">Mức lương<span class="text-danger"> *</span></label>
              <div class="form-group">
                <ValidationProvider name="Loại công việc" vid="type" rules="required" v-slot="{ errors,classes }">
                  <el-input id="excerpt" type="text" ref="title" name="title" :class="classes"
                            placeholder="Tiêu đề công việc"
                            v-model="job.excerpt" />
                  <error-message field="type" :errors="errors"></error-message>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-4">
              <label for="number">Số lượng cần tuyển<span class="text-danger"> *</span></label>
              <div class="form-group">
                <ValidationProvider name="Số lượng cần tuyển" vid="number" :rules="{ required: true, regex: /^([0-9]+)$/ }" v-slot="{ errors,classes }">
                  <el-input type="number" :class="classes" id="number" class="w-100" v-model="job.number" placeholder="Số lượng cần tuyển" />
                  <error-message field="number" :errors="errors"></error-message>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <label for="type">Hình thức làm việc<span class="text-danger"> *</span></label>
              <div class="form-group">
                <ValidationProvider name="Hình thức làm việc" vid="type_work" rules="required" v-slot="{ errors,classes }">
                  <el-select id="type" class="w-100" v-model="job.type_work" placeholder="Hình thức làm việc"
                             filterable clearable multiple>
                    <el-option
                        v-for="item in TYPE_WORK_TIME_RECRUITMENT_JOB"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                  <error-message field="type_work" :errors="errors"></error-message>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <label>Phòng ban<span class="text-danger"> *</span></label>
              <div class="form-group">
                <ValidationProvider name="Phòng ban" vid="office" rules="required" v-slot="{ errors,classes }">
                  <el-input type="text" ref="office" name="office" :class="classes"
                            placeholder="Phòng ban"
                            v-model="job.office" />
                  <error-message field="office" :errors="errors"></error-message>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-4">
              <label for="gender">Giới tính<span class="text-danger"> *</span></label>
              <div class="form-group">
                <ValidationProvider name="Giới tính" vid="experience" rules="required" v-slot="{ errors,classes }">
                  <el-select id="gender" class="w-100" v-model="job.gender" placeholder="Giới tính" filterable clearable>
                    <el-option
                        v-for="item in GENDER"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                  <error-message field="gender" :errors="errors"></error-message>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <label for="experience">Kinh nghiệm<span class="text-danger"> *</span></label>
              <div class="form-group">
                <ValidationProvider name="Kinh nghiệm" vid="experience" rules="required" v-slot="{ errors,classes }">
                  <el-input id="experience" type="text" ref="experience" name="experience" :class="classes"
                            placeholder="Kinh nghiệm"
                            v-model="job.experience" />
                  <error-message field="experience" :errors="errors"></error-message>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <label for="area">Khu vực làm việc<span class="text-danger"> *</span></label>
              <div class="form-group">
                <ValidationProvider name="Khu vực làm việc" vid="area" rules="required" v-slot="{ errors,classes }">
                  <el-input id="area" type="text" ref="area" name="area" :class="classes"
                            placeholder="Khu vực làm việc"
                            v-model="job.area" />
                  <error-message field="area" :errors="errors"></error-message>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-4">
              <label for="address">Địa chỉ làm việc<span class="text-danger"> *</span></label>
              <div class="form-group">
                <ValidationProvider name="Địa chỉ làm việc" vid="address" rules="required|max:100" v-slot="{ errors,classes }">
                  <el-input id="address" class="w-100" v-model="job.address" placeholder="Địa chỉ làm việc" />
                  <error-message field="address" :errors="errors"></error-message>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <label for="expiration_date">Hạn ứng tuyển<span class="text-danger"> *</span></label>
              <div class="form-group">
                <ValidationProvider name="Hạn ứng tuyển" vid="expiration_date" rules="required" v-slot="{ errors,classes }">
                  <el-date-picker
                      v-model="job.expiration_date"
                      type="date"
                      class="w-100"
                      format="dd-MM-yyyy"
                      value-format="dd-MM-yyyy"
                      id="expiration_date"
                      placeholder="Chọn ngày hạn ứng tuyển">
                  </el-date-picker>
                  <error-message field="expiration_date" :errors="errors"></error-message>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label>Mô tả công việc
                <span class="text-danger">*</span></label>
              <ValidationProvider name="Mô tả công việc" vid="content" rules="required|max:6000" v-slot="{ errors,classes }">
                <vue-editor id="editor-content" :editorOptions="editorSettings" useCustomImageHandler
                            v-model="job.content" :class="classes"
                            @image-added="handleImageAdded"
                            @image-draged="handleImageDraged"
                ></vue-editor>
                <error-message field="content" :errors="errors"></error-message>
              </ValidationProvider>
            </div>
          </div>
          <div class="card-toolbar">
            <el-button native-type="submit" class="btn btn-success mr-2" :loading="loading">Cập nhật</el-button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {Quill, VueEditor} from 'vue2-editor'
import ImageResize from 'quill-image-resize-vue';
import {ImageDrop} from 'quill-image-drop-module';
import ApiService from "@/core/services/api.service";
import {
  GET_RECRUITMENT_JOB_BY_ID, UPDATE_RECRUITMENT_JOB
} from "@/core/services/store/recruitment/recruiment.module";
import {ChangeToSlug} from "@/core/filters";
import {
  GENDER,
  STATUS_RECRUITMENT_JOB,
  TYPE_RECRUITMENT_JOB,
  TYPE_WORK_TIME_RECRUITMENT_JOB
} from "@/core/option/recruimentJobOption";

const _ = require('lodash');
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
import ErrorMessage from "@/view/pages/common/ErrorMessage";
import InputAvatar from "@/view/pages/common/InputAvatar";

export default {
  name: "RecruitmentJobUpdate",
  components: {
    ErrorMessage,
    DatePicker,
    VueEditor,
    InputAvatar
  },
  data() {
    return {
      id : "",
      job: {
        title: '',
        type: '',
        slug: '',
        content: '',
        excerpt: '',
        status: true,
        is_special: '',
        number: '',
        office: '',
        type_work: [],
        gender: '',
        experience: '',
        area: '',
        address: '',
        expiration_date: '',
      },
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      /* image data */
      default_photo: "media/users/blank.png",
      current_photo: null,
      default_cover_photo: "media/users/blank.png",
      current_cover_photo: null,
      image_avatar_name: null,
      image_avatar_type: null,
      background_avatar_name: null,
      background_avatar_type: null,
      TYPE_RECRUITMENT_JOB,
      STATUS_RECRUITMENT_JOB,
      TYPE_WORK_TIME_RECRUITMENT_JOB,
      GENDER,
      loading: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.$store.dispatch(GET_RECRUITMENT_JOB_BY_ID, this.id).then((res)=> {
      for (const jobKey in this.job) {
        switch (jobKey){
          case 'type_work' :
            this.job.type_work = res.data.type_work.split(', ');
            break;
          default :
            this.job[jobKey] = res.data[jobKey];
        }
      }
      this.job.status = res.data.status > 0;
      this.current_photo = res.data.avatar;
      this.current_cover_photo = res.data.background_avatar;
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý công việc", route: 'jobs-list'},
      {title: "Cập nhật công việc"}
    ]);
  },
  computed: {
    photo() {
      return this.current_photo == null
          ? this.default_photo
          : this.current_photo;
    },
    cover_photo(){
      return this.current_cover_photo == null
          ? this.default_cover_photo
          : this.current_cover_photo;
    }
  },
  methods: {
    handleImageDraged: function (delta, oldContents) {
      ApiService.post('remove-image', {url: delta})
          .then(({data}) => {
          })
          .catch(err => {
            console.log(err);
          });
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      var formData = new FormData();
      formData.append("image", file);

      ApiService.post('upload-image', formData)
          .then(({data}) => {
            let url = data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch(err => {
            console.log(err);
          });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
        this.image_avatar_name = file.name;
        this.image_avatar_type = file.type;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onFileCoverChange(e) {
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.current_cover_photo = event.target.result;
        };
        reader.readAsDataURL(file);
        this.background_avatar_name = file.name;
        this.background_avatar_type = file.type;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    enterTitle: function (){
      this.job.slug = ChangeToSlug(this.job.title);
    },
    async onSubmit() {
      if (!this.current_cover_photo || !this.current_photo) {
        this.$message.error('Ảnh đại diện và ảnh bìa không được để trống');
        return;
      }
      this.loading = true;
      let formData = new FormData();
      for (const key in this.job) {
        switch (key) {
          case 'status':
            let status = this.job[key] ? 1 : 0;
              formData.append(key, status);
              break;
          case 'type_work' :
            formData.append(key, this.job.type_work.join(', '));
            break;
          default :
            formData.append(key, this.job[key]);
        }
      }
      let avatar = this.photo;
      function urltoFile(url, filename, mimeType) {
        return (fetch(url)
                .then(function (res) {
                  return res.arrayBuffer();
                })
                .then(function (buf) {
                  return new File([buf], filename, {type: mimeType});
                }).catch((error) => {
                  return 1;
                })
        );
      }
      if (avatar.search('https') === -1) {
        let file = await urltoFile(avatar, this.image_name, this.image_type);
        if (file !== 1 && file.name !== 'null') {
          formData.append('avatar', file);
        }
      } else {
        formData.append('avatar', avatar);
      }

      let backgroundAvatar = this.cover_photo;
      if (backgroundAvatar.search('https') === -1) {
        let file = await urltoFile(backgroundAvatar, this.background_avatar_name, this.background_avatar_type);
        if (file !== 1 && file.name !== 'null') {
          formData.append('background_avatar', file);
        }
      } else {
        formData.append('background_avatar', backgroundAvatar);
      }
      this.$store.dispatch(UPDATE_RECRUITMENT_JOB,{
        id: this.id,
        params: formData,
      }).then((res) => {
        this.$router.push({name: "jobs-list"}).then(() => {
          this.noticeMessage('success', 'Thành công', res.message);
        })
      }).catch((e) => {
        if (e.data.data?.message_validate_form) {
          if (e.data.data?.message_validate_form['avatar'] || e.data.data?.message_validate_form['background_avatar']){
            let message = e.data.data?.message_validate_form['avatar'] ? 'Ảnh đại diện' : 'Ảnh bìa';
            this.$message.error(`${message} quá dung lượng vui lòng thử lại.`);
          }
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        } else {
          this.noticeMessage('error', 'Thất bại', e.data?.message);
        }
      }).finally(() => this.loading = false);
    },
  },
};
</script>
<style>
#editor-content {
  height: 720px;
}
</style>

