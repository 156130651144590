<script>
export default {
  name: "ModalBarem",
  props: {
    id: {
      type: String,
      default() {
        return ''
      }
    },
    detailSkill: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods:{
    setMark(point){
      this.detailSkill.setMark(point);
      this.$emit('handleComment');
      this.$bvModal.hide(`ModalBarem${this.id}`)
    }
  }
}
</script>

<template>
  <b-modal :id="'ModalBarem'+id" :title="'Barem chấm điểm '+detailSkill?.nameDetailSkill" centered hide-footer
           ref="ModalBarem"
           size="xl">
    <div class="mt-5 table-responsive" style="height: auto ; max-height: 500px">
      <table class="table table-bordered  table-vertical-center ">
        <thead>
        <tr class="" style="background-color: #F8F8F8 ; font-size: 13px">
          <th style="width: 80%">Lời nhận xét</th>
          <th style="width: 20%" class="text-center">Hành động</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in detailSkill?.listCommentAuto" :key="index">
          <td :class="{'text-primary' : detailSkill?.scoreDetail === index}">{{ item }}</td>
          <td class="text-center">
            <button type="button" class="btn btn-primary" @click="setMark(index)">
              Chọn
            </button>
          </td>
        </tr>

        </tbody>
      </table>
    </div>
  </b-modal>
</template>

<style scoped>

</style>