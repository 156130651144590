<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Danh sách giảng viên</h3>
        </div>
        <div class="card-title">
          <button class="font-weight-bold font-size-3 btn btn-primary mr-4" @click="exportExcel">
            <i class="la la-file-excel"></i> Xuất excel
          </button>
          <button class="font-weight-bold font-size-3 btn btn-primary mr-4" @click="exportExcelV2">
            <i class="la la-file-excel"></i> Xuất excel v2
          </button>
          <router-link
              class="font-weight-bold font-size-3  btn btn-success"
              :to="{ name: 'teacher-create' }"
          >Thêm mới
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <div class="form-group">
            <div class="input-group">
              <div class="col-md-3">
                <input type="text" class="form-control" placeholder="Nhập mã, tên, số điện thoại hoặc email "
                       v-on:input="searchTeachers"
                       v-model="query.keyword"
                       aria-describedby="basic-addon2">
              </div>
              <div class="col-md-3">
                <multiselect v-model="cache_account_position" :options="accountTypes"
                             label="name"
                             :searchable="true"
                             :close-on-select="true"
                             :show-labels="true" placeholder="Chọn chức vụ"
                             @input="selectAccountType"
                ></multiselect>
              </div>
              <div class="col-md-3">
                <el-select filterable class="w-100" placeholder="Chọn trung tâm áp dụng"
                           clearable
                           multiple
                           v-model="query.center_id"
                           @input="searchCenter"
                >
                  <el-option
                      v-for="item in listCenter"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-3">
                <el-select filterable class="w-100" placeholder="Trạng thái"
                           clearable
                           v-model="query.status"
                           @input="searchStatus"
                >
                  <el-option
                      v-for="(item, index) in this.status()"
                      :key="index"
                      :label="item"
                      :value="index"
                  >
                    <span style="float: left">{{ item }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="example-preview table-responsive">
            <table class="table table-vertical-center">
              <thead>
              <tr>
                <th scope="col">STT</th>
                <th scope="col">Mã</th>
                <th scope="col">Họ và tên</th>
                <th scope="col">Email</th>
                <th scope="col">Số điện thoại</th>
                <th scope="col">Địa chỉ</th>
                <th scope="col">Vị trí</th>
                <th scope="col">Trạng thái</th>
                <th scope="col" class="min-w-100px">Hành động</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in teachers" :key="index">
                <th scope="row">{{query.page == 1 ? index + 1 : (query.page - 1) * 20 + index + 1}}</th>
                <td class="">{{parseInt(item.id) + 100000}}</td>
                <td class="">{{item.name}}</td>
                <td class="">{{item.email}}</td>
                <td class="">{{item.phone}}</td>
                <td class="">{{item.profile ? item.profile.address : ''}}</td>
                <td class="">{{item.profile && item.profile.account_type ? item.profile.account_type.name : ''}}</td>
                <td class=""><span class="badge"
                                   v-bind:class="item.status === 1 ? classSpan.success : classSpan.danger">
                                    {{item.status === 1 ? "Hiển thị" : 'Ẩn'}}
                                </span></td>
                <td class="">
                  <router-link :to="{ name: 'teacher-update', params: {id : item.id} }" title="Sửa"
                               class="btn btn-sm btn-icon mr-2 btn-outline-info"><i class="fas fa-pen-nib"></i>
                  </router-link>
                  <a title="Xóa" @click="deleteTeacher(item.id)" href="javascript:"
                     class="btn btn-sm btn-icon btn-outline-danger mr-2"><i class="fas fa-trash"></i></a>
                  <router-link :to="{ name: 'teacher-detail', params: {id : item.id} }" title="Xem chi tiết"
                               class="btn btn-sm btn-icon mr-2 btn-outline-success"><i class="far fa-eye"></i>
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="edu-paginate mx-auto text-center">
        <paginate
            v-model="query.page"
            :page-count="total_pages"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'Trước'"
            :next-text="'Sau'"
            :container-class="'pagination b-pagination'"
            :pageLinkClass="'page-link'"
            :page-class="'page-item'"
            :next-link-class="'next-link-item'"
            :prev-link-class="'prev-link-item'"
            :prev-class="'page-link'"
            :next-class="'page-link'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Multiselect from 'vue-multiselect';
import {
  DELETE_TEACHER, EXPORT_EXCEL_TEACHER, EXPORT_EXCEL_TEACHER_V2,
  GET_TEACHER_ACCOUNT_TYPES,
  GET_TEACHERS,
} from "../../../core/services/store/teacher/teacher.module";
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {LIST_CENTER} from "@/core/services/store/contract/contract-program.module";
import {GET_CENTERS, GET_LIST_CENTERS} from "@/core/services/store/center/centers.module";
import {exportExcel} from "../../../core/filters";

const _ = require('lodash');

export default {
  name: "List-Teacher",
  data() {
    return {
      query : {
        keyword: "",
        page: 1,
        account_position: '',
        is_staff : 2,
        type : 'teacher',
        center_ids : [],
        status: null,
      },
      accountTypes : [],
      cache_account_position : '',
      classSpan : {
        success : 'badge-success',
        danger : 'badge-danger'
      },
      teachers: [],
      listCenter: [],
      is_export_excel: false,

    };
  },
  components : {
    Multiselect,
  },
  computed: {
    ...mapGetters(["getAllTeachers","getTeacherPaginate","getTeacherAccountTypes"]),
    total_pages() {
      return this.getTeacherPaginate?.total_pages || 0;
    },
  },
  beforeMount() {
    this.accountTypes = this.getTeacherAccountTypes;
  },
   async mounted() {
    this.getCenter();
    let account_type = await this.$store.dispatch(GET_TEACHER_ACCOUNT_TYPES);
    this.query.keyword = this.$route.query.keyword ? this.$route.query.keyword.trim() : '';
    this.query.page = parseInt(this.$route.query.page ? this.$route.query.page : 1);
    let account_type_id = this.$route.query.account_position ? this.$route.query.account_position : '';
    this.query.account_position = account_type_id;
     if (this.query.account_position){
       this.cache_account_position = _.find(account_type.data,function (obj){
         return obj.id === parseInt(account_type_id);
       });
     }
     await this.$store.dispatch(GET_TEACHERS, this.query);
     await this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý giảng viên"},
      { title: "Danh sách giảng viên" }
    ]);
  },
  watch : {
    getTeacherAccountTypes (after, before){
      if (after !== before){
        this.accountTypes = after;
      }
    },
    getAllTeachers(after, before){
      if (after !== before){
        this.teachers = after;
      }
    }
  },
  methods: {
    deleteTeacher(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_TEACHER, id).then(() => {
          });
        }
      });
    },
    searchTeachers : _.debounce(function() {
      this.getQuery();
    }, 300),
    selectAccountType : function (event){
      this.query.account_position = event != null ? event.id : '';
      this.getQuery();
    },
    clickCallback(pageNum) {
      this.$router.push({ path : '' , query : { page : pageNum, keyword : this.query.keyword.trim(), account_position : this.query.account_position}});
      this.$store.dispatch(GET_TEACHERS,{
        page : pageNum,
        keyword : this.query.keyword.trim(),
        account_position : this.query.account_position,
        is_staff : 2,
        type : 'teacher'
      });
    },
    getQuery : function (){
      this.$router.push({ path : '' , query : { keyword : this.query.keyword.trim(), account_position : this.query.account_position , center_ids : this.query.center_ids , status_user : this.query.status}});
      this.$store.dispatch(GET_TEACHERS,{
        keyword : this.query.keyword.trim(),
        account_position : this.query.account_position,
        is_staff : 2,
        type : 'teacher',
        center_ids : this.query.center_ids,
        status_user : this.query.status
      }).then(() => {
        this.query.page = 1;
      });
    },
    getCenter() {
      this.$store.dispatch(GET_LIST_CENTERS,{
      }).then((res) => {
        this.listCenter = res.data;
      });
    },
    status() {
      return ['Ẩn', 'Hiển thị'];
    },
    exportExcelV2() {
      let params = {
        keyword : this.query.keyword.trim(),
        account_position : this.query.account_position,
        is_staff : 2,
        type : 'teacher',
        center_ids : this.query.center_ids,
        status_user : this.query.status
      }
      const HEADER_ROW_EXPORT_TEACHER_LIST = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'id',
          value: 'Mã nhân sự',
        },
        {
          key: 'name',
          value: 'Họ tên GV',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'email',
          value: 'Email',
        },
        {
          key: 'account_type',
          value: 'Vị trí',
        },
        {
          key: 'teacher_center_name',
          value: 'Trung tâm',
        },
        {
          key: 'teacher_level',
          value: 'Trình độ',
        },
        {
          key: 'address',
          value: 'Địa chỉ',
        },
        {
          key: 'owner_name',
          value: 'Chủ tài khoản',
        },
        {
          key: 'bank_name',
          value: 'Ngân hàng',
        },
        {
          key: 'province',
          value: 'Tỉnh thành ngân hàng',
        },
        {
          key: 'chi_nhanh_ngan_hang',
          value: 'Chi nhánh ngân hàng',
        },
        {
          key: 'stk',
          value: 'Số tài khoản',
        },
      ]
      this.$store.dispatch(EXPORT_EXCEL_TEACHER_V2, params).then((res) => {
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        }
        exportExcel(HEADER_ROW_EXPORT_TEACHER_LIST, res.data, "Danh sách giảng viên.xlsx", true)
      });
    },
    exportExcel() {
      let params = {
        keyword : this.query.keyword.trim(),
        account_position : this.query.account_position,
        is_staff : 2,
        type : 'teacher',
        center_ids : this.query.center_ids,
        status_user : this.query.status
      }
      const HEADER_ROW_EXPORT_TEACHER_LIST = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'id',
          value: 'Mã nhân sự',
        },
        {
          key: 'name',
          value: 'Họ tên GV',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'email',
          value: 'Email',
        },
        {
          key: 'account_type',
          value: 'Vị trí',
        },
        {
          key: 'center_name',
          value: 'Trung tâm',
        },
        {
          key: 'level',
          value: 'Trình độ',
        },
        {
          key: 'address',
          value: 'Địa chỉ',
        },
        {
          key: 'owner_name',
          value: 'Chủ tài khoản',
        },
        {
          key: 'bank_name',
          value: 'Ngân hàng',
        },
        {
          key: 'province',
          value: 'Tỉnh thành ngân hàng',
        },
        {
          key: 'chi_nhanh_ngan_hang',
          value: 'Chi nhánh ngân hàng',
        },
        {
          key: 'stk',
          value: 'Số tài khoản',
        },
      ]
      this.$store.dispatch(EXPORT_EXCEL_TEACHER, params).then((res) => {
        if (res.data.error) {
          this.$bvToast.toast(res.data.message, {
            title: 'Export excel',
            variant: 'danger',
            solid: true
          });
        }
        exportExcel(HEADER_ROW_EXPORT_TEACHER_LIST, res.data, "Danh sách giảng viên.xlsx", true)
      });
    },
    searchCenter: function (event){
      this.query.center_ids = event;
      this.getQuery();
    },
    searchStatus: function (event){
      this.query.status = event;
      this.getQuery();
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.input-group i:after, .input-group i:before {
  line-height: 1.5;
}
</style>