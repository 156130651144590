<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between row">
      <div class="col-md-6">
        <div class="card-title">
          Danh sách nhân sự chấm công
        </div>
      </div>
      <div class="col-md-6 d-flex justify-content-end">
        <div class="example-btn mt-2">
          <div v-for="file in files" :key="file.id">
            <i class="fas fa-minus-circle mr-2" style="cursor: pointer;"
               @click="removeFile"></i>
            <span>{{ file.name }}</span> -
            <span>{{ file.size }}</span> -
            <span v-if="file.error">{{ file.error }}</span>
            <span v-else-if="file.success">success</span>
            <span v-else-if="file.active">active</span>
            <span v-else-if="!!file.error">{{ file.error }}</span>
            <span v-else></span>
          </div>
          <file-upload
              class="btn btn-text-dark-50 btn-icon-primary font-weight-bold btn-hover-bg-light mr-3"
              post-action=""
              extensions="xlsx,xls"
              accept=".xlsx,.xls"
              :size="1024 * 1024 * 10"
              v-model="files"
              @input-filter="inputFilter"
              @input-file="inputFile"
              ref="upload">
            <i class="flaticon-upload"></i>
            Chọn file
          </file-upload>
          <button type="button"
                  @click="onSubmit"
                  class="btn btn-success mr-3"
                  :class="{'spinner spinner-darker-success spinner-left' : is_loading_import}"
          >
            Upload
          </button>
        </div>
      </div>
      <div class="card-title">
        <!--        <router-link class="font-weight-bold font-size-3 btn btn-success" :to="{name: 'event-create'}">Thêm mới-->
        <!--        </router-link>-->
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row mb-3">
          <div class="col-md-3">
            <input type="text" class="form-control" placeholder="Họ và tên, Id máy chấm công, Mã nhân viên, Telegram Id"
                   v-model="formSearch.title"
                   aria-describedby="basic-addon2">
          </div>
          <div class="col-md-3">
            <button class="btn btn-primary" @click="handleFormSearch($event)">Tìm kiếm</button>
          </div>
        </div>
        <div class="example-preview table-responsive">
          <table class="table table-bordered m-0 table-vertical-center">
            <thead>
            <tr>
              <th>STT</th>
              <th>Họ và tên</th>
              <th>Id máy chấm công</th>
              <th class="min-w-100px">Mã nhân viên</th>
              <th class="min-w-100px">Telegram Id</th>
              <th class="min-w-300px">Device Id</th>
              <th>Trạng thái</th>
              <th>Hình thức làm việc</th>
              <th>Thời gian định mức</th>
              <th>Thời gian tính muộn</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in users" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.fingerprint_machine_id }}</td>
              <td>
                <div v-if="item.status">
                  <span v-if="item.user_id && item.has_user_id">{{ item.user_id + 100000 }}</span>
                  <span v-else>
                  <button v-if="!item.change_user_id" @click="openInputChangeUserId(item)"
                          class="btn btn-icon btn-success btn-xs"><i
                      class="ki ki-solid-plus icon-sm"></i>
                  </button>
                  <span v-else class="d-flex justify-content-between">
                    <el-select
                        class="mr-2"
                        @focus="activeItem(item)"
                        v-model="item.user_id"
                        filterable
                        clearable
                        remote
                        reserve-keyword
                        placeholder="Mã nhân sự, SĐT"
                        :remote-method="remoteMethod"
                        :loading="search_loading">
                      <el-option
                          v-for="item in item.user_list"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <button class="btn btn-icon btn-primary btn-xs" @click="mappingUserId(item)">
                      <i class="ki ki-check icon-sm"></i>
                    </button>
                  </span>
                </span>
                </div>
              </td>
              <td>
                <div v-if="item.status && item.user_id">
                  <span v-if="item.telegram_id && item.has_telegram_id">{{ item.telegram_id }}</span>
                  <span v-else>
                  <button v-if="!item.change_tele_id" @click="openInputChangeTelegramId(item)"
                          class="btn btn-icon btn-success btn-xs"><i
                      class="ki ki-solid-plus icon-sm"></i>
                  </button>
                  <span v-else class="d-flex justify-content-between">
                    <el-input placeholder="Nhập telegram Id" v-model="item.telegram_id" class="mr-2"></el-input>
                    <button class="btn btn-icon btn-primary btn-xs" @click="mappingTelegramId(item)">
                      <i class="ki ki-check icon-sm"></i>
                    </button>
                  </span>
                </span>
                </div>
              </td>
              <td>
                <AddDevice
                    :item="item"
                    @deviceSuccess="getList"
                ></AddDevice>
              </td>
              <td>
                <div class="col-3">
                  <AttendanceStatus :item="item"></AttendanceStatus>
                </div>
              </td>
              <td>
                <el-switch
                    style="display: block"
                    v-model="item.type"
                    @change="changeType(item)"
                    active-color="#13ce66"
                    inactive-color="#409eff"
                    active-text="Full time"
                    inactive-text="Part time">
                </el-switch>
              </td>
              <td>
                <span class="d-flex justify-content-between">
                  <el-input class="mr-2" placeholder="Thời gian" v-model="item.timework"></el-input>
                  <button class="btn btn-icon btn-primary btn-xs" @click="changeTimework(item)">
                    <i class="ki ki-check icon-sm"></i>
                  </button>
                </span>
              </td>
              <td>
                <span class="d-flex justify-content-between">
                  <input type="time" v-model="item.time_late"
                         min="00:00" max="23:59" required>
                  <button class="btn btn-icon btn-primary btn-xs" @click="changeTimeLate(item)">
                    <i class="ki ki-check icon-sm"></i>
                  </button>
                </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Multiselect from 'vue-multiselect';
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import '@/assets/sass/custom/custom_event.scss';
import {DELETE_EVENT} from "../../../core/services/store/event/event.module";
import {
  ATTENDANCE_GET_USER_LIST,
  ATTENDANCE_MAPPING_TELEGRAM,
  ATTENDANCE_MAPPING_USER,
  ATTENDANCE_MAPPING_TYPE,
  ATTENDANCE_MAPPING_TIMEWORK,
  ATTENDANCE_MAPPING_TIMELATE, ATTENDANCE_IMPORT_EXCEL
} from "@/core/services/store/attendance/attendance.module";
import {
  GET_USER_BY_PHONE,
  IMPORT_SALE_TREE,
  PUSH_PERCENT_LOADING_IMPORT_SALE_LOG
} from "@/core/services/store/user/users.module";
import AttendanceStatus from "@/view/pages/attendances/components/AttendanceStatus";
import AddDevice from "@/view/pages/attendances/components/AddDevice";
import FileUpload from 'vue-upload-component';

export default {
  components: {
    AddDevice,
    AttendanceStatus,
    DatePicker,
    Multiselect,
    FileUpload
  },
  name: "AttendanceIndex",
  data() {
    return {
      item_active: null,
      search_loading: false,
      is_loading_import: false,
      areas: [],
      centers: [],
      files: [],
      formSearch: {
        title: '',
        area: '',
        center: '',
        startDate: '',
        endDate: ''
      },
      users: [],
      page: 1,
      last_page: 1,
      define_status_css: {
        0: 'badge badge-danger',
        1: 'badge badge-primary',
      }, define_status: {
        0: 'Nghỉ',
        1: 'Đang hoạt động',
      }
    }
  },
  mounted() {
    this.getList();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý danh sách nhân sự chấm công", route: 'attendance-index'},
      {title: "Danh sách nhân sự chấm công"}
    ]);
  },
  methods: {
    changeTimeLate(item) {
      this.$store.dispatch(ATTENDANCE_MAPPING_TIMELATE, {
        time_late: item.time_late,
        fingerprint_id: item.id,
      }).then((data) => {
        this.$bvToast.toast('Cập nhật user thành công', {
          title: 'Cập nhật thời gian thành công',
          variant: 'success',
          solid: true
        });
      })
    },
    changeTimework(item) {
      this.$store.dispatch(ATTENDANCE_MAPPING_TIMEWORK, {
        timework: item.timework,
        fingerprint_id: item.id,
      }).then((data) => {
        this.$bvToast.toast('Cập nhật user thành công', {
          title: 'Cập nhật thời gian thành công',
          variant: 'success',
          solid: true
        });
      })
    },
    changeType(item) {
      this.$store.dispatch(ATTENDANCE_MAPPING_TYPE, {
        type: item.type,
        fingerprint_id: item.id,
      }).then((data) => {
        if (item.type) {
          item.timework = 8;
        }
        this.$bvToast.toast('Cập nhật user thành công', {
          title: 'Cập nhật type thành công',
          variant: 'success',
          solid: true
        });
      })
    },
    activeItem(item) {
      this.item_active = item;
    },
    handleFormSearch() {
      this.page = 1
      this.getList()
    },
    mappingUserId(item) {
      this.$store.dispatch(ATTENDANCE_MAPPING_USER, {
        user_id: item.user_id,
        fingerprint_id: item.fingerprint_machine_id,
      }).then((data) => {
        this.$bvToast.toast('Cập nhật user thành công', {
          title: 'Cập nhật user thành công',
          variant: 'success',
          solid: true
        });
        this.users = this.users.map((value) => {
          if (value.id === item.id) {
            value.has_user_id = true;
          }
          return value;
        });
      });
    },
    mappingTelegramId(item) {
      this.$store.dispatch(ATTENDANCE_MAPPING_TELEGRAM, {
        telegram_id: item.telegram_id,
        fingerprint_id: item.fingerprint_machine_id,
      }).then((data) => {
        this.$bvToast.toast('Cập telegram thành công', {
          title: 'Cập nhật telegram',
          variant: 'success',
          solid: true
        });
        this.users = this.users.map((value) => {
          if (value.id === item.id) {
            value.has_telegram_id = true;
          }
          return value;
        });
      }).catch((err) => {
        this.$bvToast.toast('Cập nhật telegram thất bại', {
          title: 'Cập nhật telegram',
          variant: 'danger',
          solid: true
        });
      });
    },
    getList() {
      this.$store.dispatch(ATTENDANCE_GET_USER_LIST, {
        page: this.page,
        keyword: this.formSearch.title
      }).then((data) => {
        this.users = data.data.data;
        this.users = this.users.map((item, key) => {
          if (item.devices.length) {
            item.device_id = item.devices[0].device_id;
          } else {
            item.device_id = '';
          }
          return item;
        })
        this.last_page = data.pagination.last_page

        this.users.map((value) => {
          value.type = value.type === 0;
          value.change_user_id = false
          value.change_tele_id = false
          value.search_user_id = ''
          value.user_list = []

          if (value.user_id) {
            value.has_user_id = true
          } else {
            value.has_user_id = false
          }

          if (value.telegram_id) {
            value.has_telegram_id = true
          } else {
            value.has_telegram_id = false
          }
        })
      });
    },
    clickCallback(obj) {
      this.page = obj
      this.getList()
    },
    openInputChangeUserId(item) {
      this.users = this.users.map((value) => {
        if (value.id === item.id) {
          value.change_user_id = true;
        }
        return value;
      })
    },
    openInputChangeTelegramId(item) {
      this.users = this.users.map((value) => {
        if (value.id === item.id) {
          value.change_tele_id = true;
        }
        return value;
      })
    },
    deleteEvent(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_EVENT, id).then((res) => {
            if (res.error) {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'danger',
                solid: true
              });
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'success',
                solid: true
              });
              this.getList();
            }
          }).catch((res) => {
            this.$bvToast.toast(res.data.message, {
              title: 'Xóa',
              variant: 'danger',
              solid: true
            });
          });
        }
      });
    },
    removeFile: function () {
      this.files.pop();
      this.isAble = true;
    },
    onSubmit() {
      let formData = new FormData();
      formData.append('dataImport', this.dataImport);
      this.is_loading_import = true;
      this.show_process_loading = true;
      this.$store.dispatch(ATTENDANCE_IMPORT_EXCEL, formData).then((res) => {
        this.is_loading_import = false;
        this.$message.success(res.data);
        this.getList();
      }).catch((error) => {
        this.$message.error(error.data.message);
      }).finally(() => {
        this.is_loading_import = false
      });
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.dataImport = newFile.file;
        this.isAble = false;
      }
      if (newFile && oldFile) {
        // update
      }
      if (!newFile && oldFile) {
        // remove
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    }, remoteMethod: _.debounce(function (query) {
      this.search_loading = true;
      this.$store.dispatch(GET_USER_BY_PHONE, {
        phone: query
      }).then((data) => {
        this.search_loading = false;
        let users = data.data;
        this.users = this.users.map((value) => {
          if (value.id === this.item_active.id) {
            value.user_list = users;
          }
          return value;
        })
      })
      // if (query !== '') {
      //   setTimeout(() => {
      //     this.search_loading = false;
      //     this.options = this.list.filter(item => {
      //       return item.label.toLowerCase()
      //           .indexOf(query.toLowerCase()) > -1;
      //     });
      //   }, 200);
      // } else {
      //   this.options = [];
      // }
    }, 500),
  }
}
</script>

<style scoped>
.el-input {
  width: 100%;
}

.el-select {
  width: 100%;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
