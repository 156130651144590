<template>
  <div class="question">
    <homework-container :question="question"></homework-container>
    <div class="el-row">
        <div class="answer w-100">
          <div class="answer-title">
            <div class="d-flex justify-content-center">
              <el-carousel :autoplay="false" trigger="click" @change="changeItem" style="width: 400px;">
                <el-carousel-item v-for="(item, k) in question.questions" :key="k">
                  <el-image
                      style="width: 100%; height: 100%"
                      :src="item.image"
                      fit="fill">
                  </el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <!--   Xem kết quả học viên   -->
          <div v-if="typeView === 'view-result'">
            <div class="answer-detail d-flex flex-wrap justify-content-around mt-6">
              <div class="d-flex justify-content-around overflow-auto">
                <el-button class="text-break btn-break"
                           :type="questions[activeBtn].student_answer === questions[activeBtn].correct_answer ? 'success' : 'danger'"
                           :icon="questions[activeBtn].student_answer === questions[activeBtn].correct_answer ? 'el-icon-success' : 'el-icon-error'"
                >
                  {{ questions[activeBtn].student_answer }}
                </el-button>
              </div>
            </div>
            <div class="answer-detail d-flex flex-wrap justify-content-around mt-6">
              <div class="d-flex justify-content-around overflow-auto">
                <el-button class="text-break btn-break"
                           v-for="(answer, index) in answers"
                           :type="activeBtn === index ? 'success' : ''" :key="index">
                  {{ answer }}
                </el-button>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="answer-detail d-flex flex-wrap justify-content-around mt-6">
              <div class="d-flex justify-content-around overflow-auto">
                <el-button class="text-break btn-break"
                           v-for="(answer, index) in answers" :key="index">
                  {{ answer }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import homeworkContainer from "./homework-container.vue";

export default {
  name: "match-text-image",
  components: {homeworkContainer},
  props: {
    question: {
      type: Object,
      default: () => { return {} }
    },
    typeView: {
      type: String,
      default: ''
    },
  },
  data(){
    return{
      activeBtn: 0
    }
  },
  computed: {
    images: function (){
      return this.question.questions.map(item => item.image)
    },
    answers: function (){
      let results =this.question.questions.map(item => item.correct_answer)
      return results
    },
    questions: function (){
      return this.question.questions
    },
  },
  methods: {
    changeItem(index) {
      this.activeBtn = index;
    }
  }
}
</script>

<style scoped>

</style>