<template>
    <!--begin::Card-->
    <div>
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
            <form class="form" @submit.prevent="handleSubmit(onSubmit)">
                <div class="card card-custom">
                    <!--begin::Header-->
                    <div class="card-header">
                        <div class="card-title ">
                            <h3 class="card-label">
                                Sửa trung tâm
                            </h3>
                        </div>
                        <div class="card-toolbar">
                            <button type="button" class="font-weight-bold font-size-3  btn btn-danger mr-2" @click="useDataOld" v-if="type === 'web-content'">
                                <i class="fas fa-times"></i> Sử dụng thông tin cũ
                            </button>
                            <router-link
                                    class="font-weight-bold font-size-3  btn btn-secondary"
                                    :to="{ name: 'center-list' }"
                            ><i class="fas fa-angle-double-left"></i> Quay lại
                            </router-link>
                        </div>
                    </div>
                    <!--end::Header-->
                    <div class="card-body">
                        <!-- Ảnh đại diện -->
                        <div class="form-group row">
                            <div class="col-md-3">
                                <div class="row mx-auto mt-8">
                                    <label class="col-xl-4 col-lg-3 col-form-label text-right">Ảnh đại diện</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <div class="image-input image-input-outline" id="kt_profile_avatar">
                                            <div
                                                    class="image-input-wrapper"
                                                    :style="{ backgroundImage: `url(${photo})` }"
                                            ></div>
                                            <label
                                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="change"
                                                    data-toggle="tooltip"
                                                    title=""
                                                    data-original-title="Change avatar"
                                            >
                                                <i class="fa fa-pen icon-sm text-muted"></i>
                                                <input
                                                        type="file"
                                                        name="profile_avatar"
                                                        accept=".png, .jpg, .jpeg"
                                                        @change="onFileChange($event)"
                                                />
                                                <input type="hidden" name="profile_avatar_remove" />
                                            </label>
                                            <span
                                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="cancel"
                                                    data-toggle="tooltip"
                                                    title="Cancel avatar"
                                            >
                                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                                    </span>
                                            <span
                                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="remove"
                                                    data-toggle="tooltip"
                                                    title="Remove avatar"
                                                    @click="current_photo = null"
                                            >
                                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                                    </span>
                                        </div>
                                        <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Tên trung tâm<span class="text-danger">*</span></label>
                                            <u class="float-right" style="color: #00b300; cursor: pointer;" v-if="type === 'web-content'" @click="handleUseCenter()">Sử dụng thông tin của trung tâm</u>
                                            <ValidationProvider name="Tên trung tâm" vid="name" rules="required" v-slot="{ errors,classes }">
                                                <el-input type="text" ref="name" name="name"  :class="classes"
                                                       placeholder="Tên trung tâm"
                                                       v-model=center.name_mkt
                                                       @input="changeTitle"
                                                />
                                                <div class="fv-plugins-message-container">
                                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                        {{ errors[0] }}
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Đường dẫn</label>
                                                <div class="el-input el-input-group el-input-group--prepend">
                                                    <el-input autocomplete="off" type="text" disabled v-model=center.slug />
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Số điện thoại
                                        <span class="text-danger"></span></label>
                                        <el-input name="phone" placeholder="Số điện thoại" v-model=center.phone_mkt />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Địa chỉ
                                        <span class="text-danger">*</span></label>
                                    <ValidationProvider name="Địa chỉ" rules="required" v-slot="{ errors,classes }">
                                        <el-input type="text" name="address" placeholder="Địa chỉ"
                                               :class="classes"
                                               v-model=center.address_mkt />
                                        <div class="fv-plugins-message-container">
                                            <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Thời gian làm việc</label>
                                    <el-input type="text" name="work_time"
                                           placeholder="Thời gian làm việc (Ví dụ: 8h-17h)"
                                           :value="showWorktime(center.work_time)" />
                                </div>
                            </div>
                        </div>
                        <!-- Tỉnh huyện -->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Tỉnh
                                        <span class="text-danger"></span></label>
                                    <multiselect v-model="cache_province_id" :options="provinces"
                                                 placeholder="Chọn tỉnh thành"
                                                 label="name"
                                                 track-by="name"
                                                 @input="selectProvince"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Quận/Huyện</label>
                                        <multiselect v-model="cache_district_id" :options="districts"
                                                     placeholder="Chọn quận/huyện"
                                                     label="name"
                                                     track-by="name"
                                                     @input="selectDistrict"
                                        ></multiselect>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-6 mb-6">
                        <div class="col-md-3 d-flex justify-content-center">
                          <div class="col-form-label">
                            <div class="checkbox-inline">
                              <label class="checkbox checkbox-success">
                                <input type="checkbox" name="Checkboxes5" v-model="center.status">
                                <span></span>Kích hoạt?</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 d-flex justify-content-center">
                          <div class="col-form-label">
                            <div class="checkbox-inline">
                              <label class="checkbox checkbox-success">
                                <input type="checkbox" name="Checkboxes5" v-model="center.is_center">
                                <span></span>Là trung tâm nổi bật?</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 d-flex justify-content-center">
                          <div class="col-form-label">
                            <div class="checkbox-inline">
                              <label class="checkbox checkbox-success">
                                <input type="checkbox" name="Checkboxes5" v-model="center.has_assistant">
                                <span></span>Có trợ giảng không?</label>
                            </div>
                          </div>
                        </div>
                          <div class="col-md-3 d-flex justify-content-center">
                            <div class="col-form-label">
                              <div class="checkbox-inline">
                                <label class="checkbox checkbox-success">
                                  <input type="checkbox" name="Checkboxes5" v-model="center.is_edutalk">
                                  <span></span>Thuộc Edutalk?</label>
                              </div>
                            </div>
                          </div>
                      </div>

                        <div class="row">
                            <div class="form-group mb-1 col-md-12">
                                <label class="mt-6">Công cụ bán hàng</label>
                                <vue-editor id="editor_schedule" :editorOptions="editorSettings" useCustomImageHandler
                                            @image-removed="removeImage"
                                            @image-added="handleImageAdded" v-model=center.information_mkt.schedule></vue-editor>
                            </div>
                        </div>

                        <div class="row mt-6">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Google map</label>
                                    <el-input type="text" name="google_map" placeholder="Google map"
                                           v-model="center.google_map" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Kinh độ</label>
                                    <el-input type="text" name="lng" placeholder="Kinh độ"
                                           v-model="center.lng" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Vĩ độ</label>
                                    <el-input type="text" name="lat"
                                           placeholder="Vĩ độ"
                                           v-model="center.lat" />
                                </div>
                            </div>
                        </div>
                        <div class="row mt-6">
                            <div class="form-group col-md-12">
                                <label>Ảnh Gallery</label>
                                <vue-dropzone @vdropzone-removed-file="removeFile" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mx-auto card-header py-3">
                    <div class="card-toolbar">
                        <button type="submit" class="btn btn-success mr-2">
                            Cập nhật
                        </button>
                    </div>
                </div>
            </form>
        </ValidationObserver>
        <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogFormVisible">
            <el-row :gutter="10" class="pr-2 pl-2">
                <center-educenter v-if="dialogFormVisible" @completed="completedUseInfoCenter" :center="center"></center-educenter>
            </el-row>
        </el-dialog>
    </div>
    <!--end::Card-->
</template>
<script>
    import {mapGetters} from "vuex";
    import Multiselect from 'vue-multiselect';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {Quill, VueEditor} from 'vue2-editor'
    import ImageResize from 'quill-image-resize-vue';
    import {ImageDrop} from 'quill-image-drop-module';
    import {GET_PROVINCES} from "../../../core/services/store/public/provinces.module";
    import {GET_DISTRICTS, GET_DISTRICTS_BY_PROVINCE} from "../../../core/services/store/public/districts.module";
    import { GET_CENTER_BY_ID, UPDATE_CENTER} from "../../../core/services/store/center/centers.module";
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import ApiService from "@/core/services/api.service";
    import {ChangeToSlug} from "../../../core/filters";
    import CenterEducenter from "./Center-Educenter.vue";
    import GoogleMap from "../google-map/GoogleMap";
    import Swal from "sweetalert2";
    import {TYPE_CENTER} from "@/core/option/centerOption";

    const _ = require('lodash');
    Quill.register("modules/imageDrop", ImageDrop);
    Quill.register("modules/imageResize", ImageResize);

    export default {
        name: "Edit-Center",
        components: {
            Multiselect,
            DatePicker,
            VueEditor,
            vueDropzone: vue2Dropzone,
            CenterEducenter,
            GoogleMap
        },
        data() {
            return {
                removeFiles: [],
                TYPE_CENTER,
                center: {
                    name_mkt: '',
                    avatar_mkt: '',
                    phone_mkt: '',
                    address_mkt: '',
                    long_description_mkt: '',
                    short_description_mkt: '',
                    thumbnail_mkt: '',
                    information_mkt: {
                        intro: '',
                        special: '',
                        course: '',
                        schedule: '',
                        teacher: '',
                        sale: '',
                        feedback: ''
                    },
                    name: '',
                    user_id: "",
                    address: "",
                    province_id: "",
                    district_id: "",
                    status: '',
                    work_time: '',
                    slug: "",
                    fan_page: "",
                    youtube_channel: "",
                    website: '',
                    long_description: '',
                    short_description: '',
                    is_popular: '',
                    avg_rate: '',
                    has_assistant: '',
                    is_center: '',
                    lng: '',
                    lat: '',
                    google_map: '',
                    information: {
                        intro: '',
                        special: '',
                        course: '',
                        schedule: '',
                        teacher: '',
                        sale: '',
                        feedback: ''
                    },
                    area_id: '',
                    phone: '',
                    thumbnail: [],
                    avatar: '',
                    is_edutalk: ''
                },
                editorSettings: {
                    modules: {
                        imageDrop: true,
                        imageResize: {}
                    }
                },
                id: '',
                type: '',
                provinces: [],
                districts: [],
                districtsByProvince : [],
                cache_province_id : '',
                cache_district_id : '',
                default_photo: "media/users/blank.png",
                current_photo: null,
                image_name: null,
                image_type: null,
                dropzoneOptions: {
                    url: 'https://httpbin.org/post',
                    // thumbnailWidth: 200,
                    maxFilesize: 0.5,
                    thumbnailMethod: 'contain',
                    addRemoveLinks: true,
                    autoProcessQueue: false,
                    headers: { "My-Awesome-Header": "header value" },
                    init: function() {
                        this.on("complete", function(file) {
                            // this.files.push(file);
                        });
                    }
                },
                dialogFormVisible: false,
                dialog: {
                    title: ''
                },
                center_const: {}

            };
        },
        computed: {
            ...mapGetters(['getCenterById',"getAllProvinces", 'getAllDistricts',]),
            photo() {
                return this.current_photo ? this.current_photo : this.default_photo;
            }
        },
      created() {
          this.type = this.$route.query.type
      },
      beforeMount() {
            this.id = this.$route.params.id;
            this.$store.dispatch(GET_CENTER_BY_ID, this.id).then((res) => {
                this.center = res.data;
                this.center_const = res.data;
                let arrUrl = this.center.thumbnail_mkt;
                this.cache_province_id = this.center.province;
                this.cache_district_id = this.center.district;
                this.center.province_id = this.center.province?.id | '';
                this.center.district_id = this.center.district?.id | '';
                // this.center.is_edutalk = this.center.is_edutalk;
                this.current_photo = this.center.avatar_mkt;
                for (let i = 0; i< arrUrl.length; i++ ){
                    var file = new File(["foo"], arrUrl[i].file_name, {
                        type: arrUrl[i].mine_type
                    });
                    this.$refs.myVueDropzone.manuallyAddFile(file, arrUrl[i].url);
                }
            });
        },
        async mounted() {
            await this.$store.dispatch(GET_PROVINCES).then((res) => {
              this.provinces = res.data;
            });
            await this.$store.dispatch(GET_DISTRICTS).then((res) => {
              this.districts = res.data;
              this.districtsByProvince = res.data;
            });
            await this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý trung tâm"},
                {title: "Sửa trung tâm"}
            ]);
        },
        methods: {
            changeTitle: function () {
                this.center.slug = ChangeToSlug(this.center.name_mkt);
            },
            removeFile(file, error, xhr){
                this.removeFiles.push(file);
            },

            removeImage: function(delta, oldContents){
              ApiService.post('remove-image', {url:delta})
                  .then(({data}) => {
                  })
                  .catch(err => {
                    console.log(err);
                  });
            },

            handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
                // An example of using FormData
                // NOTE: Your key could be different such as:
                // formData.append('file', file)
                var formData = new FormData();
                formData.append("image", file);
                ApiService.post('upload-image',formData)
                    .then(({data}) => {
                        let url = data.data; // Get url from response
                        Editor.insertEmbed(cursorLocation, "image", url);
                        resetUploader();
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },
            selectProvince : function (event) {
              let province_id = event != null ? event.id : '';
              if (province_id){
                this.$store.dispatch(GET_DISTRICTS_BY_PROVINCE, {id: province_id}).then((res)=>{
                  this.districts = res.data;
                });
              }else{
                this.districts = this.districtsByProvince;
              }
              this.center.province_id = province_id;
              this.cache_district_id = '';
            },
            selectDistrict : function(event){
                this.center.district_id = event != null ? event.id : '';
            },

            onFileChange(e) {
                const file = e.target.files[0];
                if (typeof FileReader === "function") {
                    const reader = new FileReader();
                    reader.onload = event => {
                        this.current_photo = event.target.result;
                    };
                    reader.readAsDataURL(file);
                    this.image_name = file.name;
                    this.image_type = file.type;
                } else {
                    alert("Sorry, FileReader API not supported");
                }
            },
            onSubmit: async function () {
                let has_assistant = this.center.has_assistant == true ? 1 : 0;
                let is_center = this.center.is_center == true ? 1 : 0;
                let status = this.center.status == true ? 1 : 0;
                let province_id = this.center.province_id == null ? '' : this.center.province_id;
                let district_id = this.center.district_id == null ? '' : this.center.district_id;
                let formData = new FormData();
                formData.append('name', this.center.name_mkt);
                formData.append('slug', this.center.slug);
                formData.append('work_time', this.center.work_time);
                formData.append('address', this.center.address_mkt);
                formData.append('province_id', province_id);
                formData.append('district_id', district_id);
                formData.append('status', status);
                // formData.append('fan_page', this.center.fan_page);
                // formData.append('youtube_channel', this.center.youtube_channel);
                // formData.append('website', this.center.website);
                formData.append('long_description', this.center.long_description_mkt);
                formData.append('short_description', this.center.short_description_mkt);
                formData.append('has_assistant', has_assistant);
                formData.append('is_center', is_center);
                formData.append('lng', this.center.lng);
                formData.append('lat', this.center.lat);
                formData.append('google_map', this.center.google_map);
                formData.append('phone', this.center.phone_mkt);
                formData.append('he_so_kinh_doanh', this.center.he_so_kinh_doanh);
                formData.append('he_so_len_cap', this.center.he_so_len_cap);
                formData.append('intro', this.center.information_mkt.intro);
                formData.append('special', this.center.information_mkt.special);
                formData.append('course', this.center.information_mkt.course);
                formData.append('schedule', this.center.information_mkt.schedule);
                formData.append('teacher', this.center.information_mkt.teacher);
                formData.append('sale', this.center.information_mkt.sale);
                formData.append('feedback', this.center.information_mkt.feedback);
                // formData.append('is_edutalk', this.center.is_edutalk);

                for (let i = 0; i < this.removeFiles.length; i++) {
                    formData.append('removeFiles[]', this.removeFiles[i]);
                }
                let avatar = this.photo;

                function urltoFile(url, filename, mimeType) {
                    return (fetch(url)
                            .then(function (res) {
                                return res.arrayBuffer();
                            })
                            .then(function (buf) {
                                return new File([buf], filename, {type: mimeType});
                            }).catch((error) => {
                                return 1;
                            })
                    );
                }

                if (avatar.search('https') === -1) {
                    let file = await urltoFile(avatar, this.image_name, this.image_type);
                    if (file !== 1 && file.name !== 'null') {
                        formData.append('avatar', file);
                    }
                } else {
                    formData.append('avatar', avatar);
                }
                let thumbnail = _.reduce(this.$refs.myVueDropzone.dropzone.files, function (result, value, key) {
                    result.push(value);
                    return result;
                }, []);
                for (let x = 0; x < thumbnail.length; x++) {
                    formData.append("thumbnail[]", thumbnail[x]);
                }
                this.$store.dispatch(UPDATE_CENTER, {
                    id: this.id,
                    params: formData,
                }).then((res) => {
                      this.$router.push({name: "center-list"}).then(() => {
                        this.$notify({
                          title: 'Thành công',
                          message: 'Cập nhật trung tâm thành công',
                          type: 'success'
                        });
                      })
                }).catch((e) => {
                  if (e.data.data.message_validate_form) {
                    this.$refs.form.setErrors(e.data.data.message_validate_form);
                  }
                  if (e.data?.message){
                    this.$notify({
                      title: 'Thất bại',
                      message: e.data.message,
                      type: 'error'
                    });
                  }
                });
            },
            completedUseInfoCenter(item) {
                if (item.name) {
                    this.center.name_mkt = this.center.name
                    this.changeTitle()
                }
                if (item.avatar) {
                    this.current_photo = this.center.avatar
                }
                if (item.phone) {
                    this.center.phone_mkt = this.center.phone
                }
                if (item.address) {
                    this.center.address_mkt = this.center.address
                }
                if (item.short_description) {
                    this.center.short_description_mkt = this.center.short_description
                }
                if (item.long_description) {
                    this.center.long_description_mkt = this.center.long_description
                }
                if (item.intro) {
                    this.center.information_mkt.intro = this.center.information.intro
                }
                if (item.special) {
                    this.center.information_mkt.special = this.center.information.special
                }
                if (item.course) {
                    this.center.information_mkt.course = this.center.information.course
                }
                if (item.schedule) {
                    this.center.information_mkt.schedule = this.center.information.schedule
                }
                if (item.teacher) {
                    this.center.information_mkt.teacher = this.center.information.teacher
                }
                if (item.sale) {
                    this.center.information_mkt.sale = this.center.information.sale
                }
                if (item.feedback) {
                    this.center.information_mkt.feedback = this.center.information.feedback
                }
                if (item.gallery) {
                    this.$refs.myVueDropzone.removeAllFiles(true);
                    let arrUrl = this.center.thumbnail
                    if (arrUrl) {
                        for ( let i = 0; i < arrUrl.length; i++ ) {
                            var file = new File(["foo"], arrUrl[i].file_name + '.png', {
                                type: 'image/png'
                            });
                            this.$refs.myVueDropzone.manuallyAddFile(file, arrUrl[i].url);
                        }

                    }
                }
                this.dialogFormVisible = false
            },
            getInfoCenter() {
              this.id = this.$route.params.id;
                this.$store.dispatch(GET_CENTER_BY_ID, this.id).then((res) => {
                    this.center = res.data;
                    this.center_const = res.data;
                    let arrUrl = this.center.thumbnail_mkt;
                    this.cache_province_id = this.center.province;
                    this.cache_district_id = this.center.district;
                    this.center.province_id = this.center.province?.id | '';
                    this.center.district_id = this.center.district?.id | '';
                    this.current_photo = this.center.avatar_mkt;
                    this.$refs.myVueDropzone.removeAllFiles(true);
                    for (let i = 0; i< arrUrl.length; i++ ){
                        var file = new File(["foo"], arrUrl[i].file_name, {
                            type: arrUrl[i].mine_type
                        });
                        this.$refs.myVueDropzone.manuallyAddFile(file, arrUrl[i].url);
                    }
                });
            },
            useDataOld() {
                Swal.fire({
                    title: "Bạn chắc chắn muốn xóa thông tin?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.getInfoCenter()
                    }
                });
            },
            handleUseCenter(item) {
                this.dialog.title = 'Chọn chi tiết trung tâm muốn sử dụng lại';
                this.dialogFormVisible = true;
            },
            showWorktime(workTime) {
              if (workTime){
                let workTimeStr = eval('(' + workTime + ')');
                let workTimeObj = JSON.parse(JSON.stringify(workTimeStr)) || {}
                return workTimeObj?.start ? workTimeObj.start + '' + ' - ' + workTimeObj.end + '' : '';
              }
              return '';
            },
        },
    };
</script>
<style>
    .mx-datepicker {
        width: 100% !important;
    }
    .dz-progress {
        /* progress bar covers file name */
        display: none !important;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

