import ApiService from "@/core/services/api.service";
export const GET_BUSINESS_LEVEL = "get_business_level";
export const GET_BUSINESS_FOR_CANDIDATE = "business-level-for-candidate";
export const CREATE_MANAGE_PROGRAM_COURSE = "create-manage-program-course";
export const GET_LIST_MANAGE_COURSE = "manage-course";
export const GET_PROGRAM_COURSE = "get-manage-course";


const state = {};
const getters = {};
const actions = {
    // Get provinces
    [GET_BUSINESS_LEVEL](context, payload) {
        return new Promise(resolve => {
            ApiService.query('business-level', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_BUSINESS_FOR_CANDIDATE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_BUSINESS_FOR_CANDIDATE, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_MANAGE_PROGRAM_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(CREATE_MANAGE_PROGRAM_COURSE, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_LIST_MANAGE_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_LIST_MANAGE_COURSE, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_PROGRAM_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_PROGRAM_COURSE, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
