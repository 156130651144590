export const PROCESS_TEST_INCOME = 1;
export const PROCESS_TEACHING = 2;
export const PROCESS_TYPE_SELECT_TEST = 1;
export const PROCESS_TYPE_MARK = 2;
export const PROCESS_TYPE_ATTENDANCE = 1;
export const PROCESS_TYPE_REVIEW = 2;
export const PROCESS_TYPE_GRADE_HOMEWORK = 3;
export const PROCESS_TYPE_OPEN_SLIDE = 4;
export const PROCESS_TEST_INCOME_DETAIL = [
    {value: 6, label: "Vào test online", slug: "error_test_input_type_online"},
    {value: 7, label: "Chấm speaking", slug: "error_test_input_mark_speaking"},
    // {value: 9, label: "Chấm general", slug: "error_test_input_mark_general"},
];
export const PROCESS_TEACHING_DETAIL = [
    {value: 1, label: "Điểm danh", slug: "error_teaching_attendance"},
    {value: 2, label: "Nhận xét", slug: "error_teaching_review"},
    {value: 3, label: "Chấm BTVN", slug: "error_teaching_grade"},
    // {value: 4, label: "Mở slide", slug: "error_teaching_open_slide"},
    {value: 5, label: "Tặng quà", slug: "error_teaching_give_gift"},
    {value: 10, label: "Upload tư liệu", slug: "error_teaching_upload_document"},
    {value: 12, label: "Duyệt team", slug: "error_teaching_accept_team"},
    {value: 14, label: "Nhập điểm kiểm tra", slug: "error_teaching_post_exam"},
];
export const TICKET_SUPPORT_CENTER_BACKEND = 3;
export const TICKET_STUDENT_MANAGEMENT_EDUCENTER = 4;
export const TICKET_INPUT_SOLUTION = 1;
export const TICKET_NO_CHANGE_DONE = 2;
export const TICKET_NO_REVIEW = 3;
export const TICKET_SUPPORT_CENTER_PROCESS_DETAIL = [
    {value: 1, label: "Nhập giải pháp", slug: "error_support_center_input_solution"},
    {value: 2, label: "Hoàn thành", slug: "error_support_center_no_change_done"},
    {value: 3, label: "Đánh giá", slug: "error_support_center_no_review"},
];
export const TICKET_STUDENT_MANAGEMENT_PROCESS_DETAIL = [
    {value: 1, label: "Nhập giải pháp", slug: "error_student_management_input_solution"},
    {value: 2, label: "Hoàn thành", slug: "error_student_management_no_change_done"},
    {value: 3, label: "Đánh giá", slug: "error_student_management_no_review"},
];

export const TYPE_ERROR_TEST_ICOME = [
   {id: 6, name: "Vào test online"},
   {id: 7, name: "Chấm điểm speaking"},
   {id: 9, name: "Chấm điểm general"},
  
];

export const TYPE_ERROR_DEFINE = {
        0: "Thanh tra",
        1: "Điểm danh",
        2: "Nhận xét",
        3: "Chấm BTVN",
        5: "Tặng quà",
        6: "Vào test online",
        7: "Chấm điểm speaking",
        9: "Chấm điểm general",
        10: "Tư liệu",
        12: "Duyệt team",
        14: "Nhập điểm",
};

export const TYPE_ERROR_TEACHING = [
   {id:0, name: "Thanh tra"},
   {id:1, name: "Điểm danh"},
   {id:2, name: "Nhận xét"},
   {id:3, name: "Chấm BTVN"},
   {id:5, name: "Tặng quà"},
   {id:10, name: "Tư liệu"},
   {id:12, name: "Duyệt team"},
   {id:14, name: "Nhập điểm"},
]

export const LIST_ERROR = [
    {id:0,  name: "Thanh tra"},
    {id:1,  name: "Điểm danh"},
    {id:2,  name: "Nhận xét"},
    {id:3,  name: "Chấm BTVN"},
    {id:5,  name: "Tặng quà"},
    {id:6,  name: " Vào test Online"},
    {id:7,  name: "Chấm điểm speaking"},
    {id:9,  name: "Chấm điểm general"},
    {id:10, name: "Tư liệu"},
    {id:12, name: "Duyệt team"},
    {id:14, name: "Nhập điểm"},
 ]
 
 export const STATUS_ERROR_DEFINE = {
    1: 'Chưa sửa',
    2: 'Đã sửa',
    3: 'Huỷ lỗi',
 }

 export const STATUS_ERROR_CSS = {
    1: 'badge bg-danger text-white',
    2: 'badge bg-success text-white',
    3: 'badge bg-secondari',
 }
;

