import Swal from "sweetalert2";
<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        Danh sách Workshop & Club
      </div>
      <div class="card-title">
        <router-link class="font-weight-bold font-size-3 btn btn-success" :to="{name: 'workshop-club-create'}">Thêm mới</router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="form-group col-md-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text line-height-0 py-0">
                  <span class="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000" fill-rule="nonzero"
                              opacity="0.3"></path>
                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000"
                              fill-rule="nonzero"></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <input type="text" class="form-control" placeholder="Tên workshop, club" v-model="formSearch.name" aria-describedby="basic-addon2" @keyup="handleFormSearch">
            </div>
          </div>
          <div class="form-group col-md-3">
            <div class="form-group">
              <el-select v-model="formSearch.type" filterable clearable class="w-100" placeholder="Chọn loại" @change="handleFormSearch">
                <el-option
                        v-for="item in types"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id"
                >
                  <span style="float: left">{{ item.value }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div class="form-group">
              <date-picker v-model="formSearch.event_time" valueType="format" @input="handleFormSearch" placeholder="Ngày diễn ra"></date-picker>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
              <tr>
                <th>Mã</th>
                <th>Phân loại</th>
                <th>Tên</th>
                <th>Thời gian diễn ra</th>
                <th>Hình thức</th>
                <th>Phòng zoom</th>
                <th>Đối tượng áp dụng</th>
                <th>Ngày tạo</th>
                <th style="min-width: 100px">Hành động</th>
              </tr>
            </thead>
            <tbody>
            <tr v-if="data.length > 0" v-for="item in data">
              <td>{{ item.id }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.event_time }}</td>
              <td>{{ item.hinh_thuc }}</td>
              <td>
                <p v-if="item.hinh_thuc == 'Online'">ID: {{ item.zoom_id }}</p>
                <p v-if="item.hinh_thuc == 'Online'">Password: {{ item.zoom_password }}</p>
              </td>
              <td><span v-if="item.classrooms.length > 0" v-for="(classroom, key) in item.classrooms" class="badge badge-success badge-custom">{{ item.classrooms[key] }}</span></td>
              <td>{{ item.created_at }}</td>
              <td>
                <router-link :to="{ name: 'workshop-club-edit', params: {id : item.id, type: item.type} }" title="Sửa" class="btn btn-sm btn-icon mr-2 btn-outline-info">
                  <i class="fas fa-pen-nib"></i>
                </router-link>
                <a title="Xóa" @click="deleteWorkshopClub(item.id)" href="javascript:"
                   class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            <tr v-if="data.length == 0">
              <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
              v-model="page"
              :page-count="last_page"
              :page-range="3"
              :margin-pages="1"
              :click-handler="clickCallback"
              :prev-text="'Trước'"
              :next-text="'Sau'"
              :container-class="'pagination b-pagination'"
              :pageLinkClass="'page-link'"
              :next-link-class="'next-link-item'"
              :prev-link-class="'prev-link-item'"
              :prev-class="'page-link'"
              :next-class="'page-link'"
              :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import {mapGetters} from "vuex";
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import DatePicker from "vue2-datepicker";
    import 'vue2-datepicker/index.css';
    import '@/assets/sass/custom/custom_event.scss';
    import {
        DELETE_WORKSHOP_AND_CLUB,
        GET_WORKSHOP_AND_CLUB
    } from "../../../core/services/store/workshopAndClub/workshopAndClub.module";
    import Swal from "sweetalert2";

    export default {
        components: {
            DatePicker,
            Multiselect
        },
        name: "Workshop-Club-List",
        data() {
            return {
                types: [
                    { id: 0, value: 'Workshop'},
                    { id: 1, value: 'Club'}
                ],
                areas: [],
                centers: [],
                formSearch: {
                    name: '',
                    type: '',
                    time: '',
                },
                data: [],
                page: 1,
                last_page: 1,
            }
        },
        mounted() {
            this.getListWorkshopAndClub()
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý Workshop & Club", route: 'workshop-club-list'},
                {title: "Danh sách Workshop & Club"}
            ]);
        },
        methods : {
            handleFormSearch() {
                this.page = 1
                this.getListWorkshopAndClub()
            },
            deleteWorkshopClub(id) {
                Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                  cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(DELETE_WORKSHOP_AND_CLUB, id).then((res) => {
                            if (res.error) {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'danger',
                                    solid: true
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'success',
                                    solid: true
                                });
                                this.getListWorkshopAndClub();
                            }
                        }).catch((res) => {
                            this.$bvToast.toast(res.data.message, {
                                title: 'Xóa',
                                variant: 'danger',
                                solid: true
                            });
                        });
                    }
                });
            },
            getListWorkshopAndClub() {
                this.$store.dispatch(GET_WORKSHOP_AND_CLUB, {
                    page: this.page,
                    name: this.formSearch.name,
                    type: this.formSearch.type,
                    event_time: this.formSearch.event_time
                }).then((res) => {
                    this.data = res.data
                    this.last_page = res.pagination.last_page
                });
            },
            clickCallback(obj) {
                this.page = obj
                this.getListWorkshopAndClub()
            },

        }
    }
</script>

<style scoped>

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
