<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <div>
      <div v-if="checkIncoming" class="position-absolute shadow-lg p-3 mb-5 bg-white rounded" style="right: 520px; top: 60px;  z-index: 1000">
        <div class="">
          <div v-if="!dialogAssignTicket" class="d-flex">
            <modal-create-ticket @create-done="createSussess" :sources="sources" :departments="departments" :objectFeedback="objectFeedback"></modal-create-ticket>
            <button class="btn btn-success font-weight-bold btn-sm ml-4" @click="dialogAssignTicket = true">
              <i class="fas fa-ticket-alt"></i>
              Gán ticket
            </button>
          </div>
          <div v-if="dialogAssignTicket">
            <h6>Gán cuộc gọi vào ticket</h6>
            <div class="p-4">
              <div>
                <label>Mã ticket</label>
              </div>
              <el-select
                v-model="formTicket.ticket_id"
                filterable
                clearable
                remote
                reserve-keyword
                placeholder="Nhập số mã ticket"
                :remote-method="remoteMethod"
                :loading="search_loading">
                <el-option
                    v-for="item in dataFindTicket"

                    :key="item.id"
                  :label="changeTitle(item)"
                  :value="item.id">
                  <span>{{ changeTitle(item) }}</span>
                </el-option>
              </el-select>
            </div>
            <div class="mt-4 d-flex justify-content-end">
              <el-button class=""@click="dialogAssignTicket = false">Huỷ bỏ</el-button>
              <el-button class="bg-violet text-white" :disabled="loadingAssignee" v-loading="loadingAssignee" @click="assigneeTicket">Xác nhận</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 pb-1 hide" id="buttonCall">
        <div v-if="!checkChangeIcon">
          <el-button class="border border-dark p-3 rounded" @click="changeIcon">
            <i class="el-icon-phone-outline"></i>
          </el-button>
        </div>
      </div>
    </div>
    <!--begin: Search -->
    <b-dropdown
      size="sm"
      id="kt_quick_search_toggle"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg :src="publicPath+'media/svg/icons/General/Search.svg'" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTSearchDefault></KTSearchDefault>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Search -->

    <!--begin: Notifications -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
        >
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg :src="publicPath+'media/svg/icons/Code/Compiling.svg'" />
          </span>
          <span class="pulse-ring"></span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->




    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import i18nService from "@/core/services/i18n.service.js";
import {
    CHANGE_LOG_STRINGEE,
    CONNECT_STRINGEE, HANDLE_HISTORY_STRINGEE, REASON_ERROR,
    RELOAD_CONNECT_STRINGEE,
    UPDATE_HISTORY_STRINGEE, GET_SOURCE,
    GET_OBJECT_FEEDBACK, GET_ASSIGNEE_TICKET_STRINGEE
} from "../../../core/services/store/user/ticket.module";
import {GET_USER_BY_USER_PHONE} from "../../../core/services/store/user/users.module";
import ModalCreateTicket from "../../pages/crm/tickets/components/ModalCreateTicket";
import { GET_DEPARTMENT } from "../../../core/services/store/user/department.module";

export default {
  name: "KTTopbar",
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      languageFlag: "",
      languages: i18nService.languages,
      //
      config: {
        showMode: 'disable',
        top: 60,
        right: 200,
        arrowLeft: 155,
        arrowDisplay: 'top',
        fromNumbers: [
          { alias: 'Number-1', number: '+84899199586' },
          { alias: 'Number-2', number: '+2222' }
        ],
        askCallTypeWhenMakeCall: false,
        appendToElement: null,
        makeAndReceiveCallInNewPopupWindow: false,
      },
      accessToken: '',
      numbers: '',
      fromNumber: '',
      toNumber: '',
      callType: '',
      checkIncoming: false,
      messageError: '',
      reasonError: REASON_ERROR,
      userByPhone: {},
      dialogCreateTicket: false,
      dialogAssignTicket: false,
      sources: [],
      departments: [],
      formTicket: {
        ticket_id: '',
      },
      access_token: '',
      loadingAssignee: false,
      checkChangeIcon: false,
      objectFeedback: [],
      dataFindTicket: {},
      search_loading: false,
      category_chamsoc_define: {
        1: 'Nghỉ học 2 buổi',
        2: 'Điểm kiểm tra thấp',
        3: 'Không làm bài',
        4: 'Chua đóng tiền học',
        5: 'Đã đóng tiền học',
        6: 'Feedback thấp',
        7: 'Nghỉ học 1 buổi',
        8: 'Nghỉ học 1 buổi',
        9: 'Hướng dẫn app cho phụ huynh',
        10: 'Kết quả BTVN không tốt',
        11: 'Kết quả BTVN không tốt'
      },

    };
  },
  components: {
    KTSearchDefault,
    KTDropdownNotification,
    KTDropdownQuickAction,
    KTDropdownMyCart,
    KTDropdownLanguage,
    KTQuickUser,
    KTQuickPanel,
    ModalCreateTicket
  },
  mounted() {
    this.connectStringeeSoftPhone();
    this.getSource();
    this.getObjectFeedback();
    this.getDepartments();
    this.initStringeeSoftPhone();
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  },
  methods: {
    beforeUnloadHandler(event) {
      if (!this.isLeaving) {
        // event.preventDefault();
        // event.returnValue = 'Reload page '; // Hiển thị hộp thoại xác nhận mặc định của trình duyệt
        StringeeSoftPhone.hangupCall();
        return '';
      }
    },
    getDepartments() {
      this.$store.dispatch(GET_DEPARTMENT, {
        all: true,
        status: 1
      }).then((data) => {
        this.departments = data.data;
      })
    },
    getObjectFeedback() {
      this.objectFeedback = [];
      this.$store.dispatch(GET_OBJECT_FEEDBACK).then((data) => {
        for (const key in data.data) {
          this.objectFeedback.push({
            id: key,
            name: data.data[key]
          })
        }
      })
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    initStringeeSoftPhone() {
      StringeeSoftPhone.init(this.config);

      StringeeSoftPhone.on('displayModeChange', event => {
        console.log('displayModeChange', event);
        if (event === 'min') {
          StringeeSoftPhone.config({ arrowLeft: 75 });
        } else if (event === 'full') {
          StringeeSoftPhone.config({ arrowLeft: 155 });
        } else if (event === 'none') {
          this.checkChangeIcon = false;
          if ($("div").hasClass('hide')) {
            document.getElementById("buttonCall").classList.add('show');
            document.getElementById("buttonCall").classList.remove('hide');
          }
        }
      });

      StringeeSoftPhone.on('requestNewToken', () => {
        console.log('requestNewToken+++++++');
        StringeeSoftPhone.connect(this.access_token);
      });

      StringeeSoftPhone.on('authen', res => {
        if (res.r != 0) {
          this.reloadStringeeSoftPhone();
        }
        console.log('authen: ', res);
      });

      StringeeSoftPhone.on('disconnect', () => {
        // console.log('disconnected');
      });

      StringeeSoftPhone.on('signalingstate', state => {
        console.log(state.reason, 'alooo')
        if (state.reason == "Ended") {
          this.updateLogCall();
        if ((this.$route.name == 'ticket-show' || this.$route.name == 'crm-ticket-show') && !this.checkIncoming && this.callType == 'callAway') {
            this.formTicket.ticket_id = this.$route.params.id;
            this.dataFindTicket = [1];
            this.assigneeTicket()
        }
        this.checkIncoming = false;

        }
        this.changeLogs(state);
      });

      StringeeSoftPhone.on('beforeMakeCall', (call, callType) => {
        var self = this;
        setTimeout(function() {
          self.handleDataCall(call.fromNumber, call.toNumber, callType, call.callId);
          self.callStringeeId = call.callId
          self.fromNumber = call.fromNumber
          self.toNumber = call.toNumber
        }, 2000);
        return true;
      });

      StringeeSoftPhone.on('answerIncomingCallBtnClick', () => {
        this.checkIncoming = true;
        console.log('answerIncomingCallBtnClick');
      });

      StringeeSoftPhone.on('makeOutgoingCallResult', (res) => {
        console.log(res, 'makeOutgoingCallResult');
        this.getUserByPhone(res.toNumber)

        this.messageError = res.message;
      });

      StringeeSoftPhone.on('makeOutgoingCallBtnClick', (fromNumber, toNumber, callType) => {
        this.fromNumber = fromNumber;
        this.toNumber = toNumber;
        this.callType = callType;
        console.log('makeOutgoingCallBtnClick: fromNumber=' + fromNumber + ', toNumber=' + toNumber + ',callType=' + callType);
      });

      StringeeSoftPhone.on('incomingCall', incomingcall => {
        StringeeSoftPhone._iframe.contentWindow.stringeePhone
          .setLabelHtml('#page-incomming-call .wrap-avatar-round',
            "<div class='mt-20' style='color: white'>" + ' Hàng đợi : ' + incomingcall.toNumber + "</div>")
          this.fromNumber = incomingcall.fromNumber;
          this.toNumber = incomingcall.toAlias;
        console.log('incomingCall', incomingcall)
        this.handleDataCall(incomingcall.fromNumber, incomingcall.toAlias, 'incomingCall', incomingcall.callId)
        this.getUserByPhone(incomingcall.fromNumber)

      });

      StringeeSoftPhone.on('endCallBtnClick', () => {
        // this.handleDataCall(this.fromNumber, this.toNumber, this.callType,'')
        this.updateLogCall();
          if ((this.$route.name == 'ticket-show' || this.$route.name == 'crm-ticket-show') && !this.checkIncoming && this.callType == 'callAway') {
              this.formTicket.ticket_id = this.$route.params.id;
              this.dataFindTicket = [1];
              this.assigneeTicket()
          }
          this.checkIncoming = false;

          console.log('endCallBtnClick');
      });

      StringeeSoftPhone.on('callingScreenHide', () => {
        console.log('callingScreenHide');
      });

      StringeeSoftPhone.on('declineIncomingCallBtnClick', () => {
        console.log('declineIncomingCallBtnClick');
      });

      StringeeSoftPhone.on('incomingScreenHide', () => {

        console.log('incomingScreenHide');
      });

      StringeeSoftPhone.on('customMessage', data => {
        console.log('customMessage', data);
      });
    },
    getUserByPhone(phone) {
      let convertedString = '0' + phone.toString().slice(2);

      this.$store.dispatch(GET_USER_BY_USER_PHONE, {phone: convertedString}).then((res) => {
        this.userByPhone = res.data
        if (this.userByPhone) {
          this.checkChangeIcon = false;
          StringeeSoftPhone.config({ showMode: 'full' });
        }
        console.log(this.userByPhone.name, 'nameeeee')
        StringeeSoftPhone._iframe.contentWindow.stringeePhone.setLabelHtml('#page-calling .info-name', "<b>" + this.userByPhone.name + "</b>")
        StringeeSoftPhone._iframe.contentWindow.stringeePhone.setLabelHtml('#page-incomming-call .info-name', "<b>" + this.userByPhone.name + "</b>")
      })
    },
    connectStringeeSoftPhone() {
      this.$store.dispatch(CONNECT_STRINGEE, {}).then((data) => {
        this.accessToken = data.data.access_token;
        this.numbers = data.data.phone_stringee;
        if (!this.numbers) {
          return;
        }
        var fromNumbers = [];

        var numbers = this.numbers.split(",");

        for (var i = 0; i < numbers.length; i++) {
          var nn = { alias: numbers[i], number: numbers[i] };
          fromNumbers.push(nn);
        }
        StringeeSoftPhone.config({ fromNumbers: fromNumbers });
        StringeeSoftPhone.connect(this.accessToken);
      })
    },
    reloadStringeeSoftPhone() {
      this.$store.dispatch(RELOAD_CONNECT_STRINGEE, {}).then((res) => {
        this.accessToken = res.data;
        var fromNumbers = [];

        var numbers = this.numbers.split(",");

        for (var i = 0; i < numbers.length; i++) {
          var nn = { alias: numbers[i], number: numbers[i] };
          fromNumbers.push(nn);
        }
        StringeeSoftPhone.config({ fromNumbers: fromNumbers });
        StringeeSoftPhone.connect(this.accessToken);
      })
    },
    handleDataCall(fromNumber, toNumber, callType, callId) {
      let error = this.reasonError[this.messageError];
      if (error) {
        this.$bvToast.toast('Gọi không thành công', {
          title: error,
          variant: 'danger',
          solid: true
        });
      }

      this.callStringeeId = callId;
      this.$store.dispatch(HANDLE_HISTORY_STRINGEE, {
        fromNumber: fromNumber,
        toNumber: toNumber,
        callType: callType,
        callId: callId,
        message: this.messageError
      }).then((res) => {

      })
    },
    updateLogCall() {
      this.$store.dispatch(UPDATE_HISTORY_STRINGEE, {
        callId: this.callStringeeId
      }).then((res) => {

      })
    },
    changeIcon() {
      this.checkChangeIcon = true;
      StringeeSoftPhone.config({ showMode: 'full' });
    },
    createSussess(data) {
      this.formTicket.ticket_id = data.id;
      this.dataFindTicket = [data];
      this.assigneeTicket();
    },
    assigneeTicket() {
      if (!this.dataFindTicket.length) {
        this.$bvToast.toast('Không tìm thấy ticket để gán', {
          title: 'Không tìm thấy ticket để gán',
          variant: 'danger',
          solid: true
        });
        return;
      }
      this.loadingAssignee = true;
      let payload = {};
      payload.ticket_id = this.formTicket.ticket_id;
      payload.fromNumber = this.fromNumber;
      payload.toNumber = this.toNumber;
      payload.callType = this.callType;
      payload.callId = this.callStringeeId;
      this.$store.dispatch(CHANGE_LOG_STRINGEE, payload).then((res) => {
        this.dialogAssignTicket = false;
        this.$bvToast.toast('Gán ticket thành công', {
          title: 'Gán ticket thành công',
          variant: 'success',
          solid: true
        });
        this.loadingAssignee = false;
        const checkTicket = this.dataFindTicket.find(item => item.is_auto == 3);
        if (checkTicket) {
          this.$router.push({name: 'crm-ticket-show', params: {id: this.formTicket.ticket_id}})
        } else {
          this.$router.push({name: 'ticket-show', params: {id: this.formTicket.ticket_id}})
        }
      })
    },
    remoteMethod(query) {
      this.search_loading = true;
      this.$store.dispatch(GET_ASSIGNEE_TICKET_STRINGEE, {ticket_id: query}).then((data) => {
          this.dataFindTicket = data.data;
            this.search_loading = false;
      })

    },
    changeLogs(request) {
      // let payload = request;

      // payload.ticket_id = this.$route.params.id;
      // this.$store.dispatch(CHANGE_LOG_STRINGEE, request).then((res) => {
      // })
    },
    changeTitle(ticket) {
        let category = '';
        if (ticket.category_ticket) {
            category = ticket.category_ticket.name;
        } else {
            category = this.category_chamsoc_define[ticket.category_chamsoc];
        }
        return category ? ticket.id + ' - ' + category : 'Không có bản ghi';
      // return ticket.category_chamsoc ? ticket.id + '-' + this.category_chamsoc_define[ticket.category_chamsoc] : (ticket.id ? ticket.id + ' - ' + (ticket.category_ticket ?? 'Không có bản ghi');
    },
    getSource() {
      this.sources = [];
      this.$store.dispatch(GET_SOURCE).then((data) => {
        for (const key in data.data) {
          this.sources.push({
            id: key,
            name: data.data[key]
          })
        }
      })
    },
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  }
};
</script>
<style>
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  .bg-violet {
    background: #5E00B6;
  }
</style>
