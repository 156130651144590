<template xmlns="http://www.w3.org/1999/html">
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        Danh sách ứng viên
      </div>
      <div class="card-title">
        <b-button variant="success" size="md" class="float-right ml-2"
                  :disabled="loading_export" :class="{'spinner spinner-white spinner-right' : loading_export}"
                  @click="exportExcel">Xuất excel</b-button>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row filter_form">
          <div class="col-xxl-3 col-md-4 ">
            <label for=""><b>Ứng viên</b></label>
            <div class="form-group w-100">
              <el-input class="shadow-sm" type="text" v-model="query.keyword" clearable
                placeholder="Tìm tên, mã, sđt, email"></el-input>
            </div>
          </div>
          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Khu vực</b></label>
            <div class="form-group w-100">
              <el-select class=" w-100 shadow-sm" v-model="query.area" collapse-tags placeholder="Chọn khu vực" @change="getAllOffice" filterable multiple clearable>
            <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
          </el-select>
            </div>
          </div>
          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Văn phòng</b></label>
            <div class="form-group w-100">
              <el-select class=" w-100 shadow-sm" placeholder="Chọn văn phòng" v-model="query.branch" multiple filterable collapse-tags clearable>
                <el-option v-for="item in branchs" :key="item.id" :label="item.name_alias ?? item.name" :value="item.id">
                  <span style="float: left">{{ item.name_alias ?? item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Loại ứng viên</b></label>
            <div class="form-group w-100">
              <el-select v-model="query.type" class="w-100 shadow-sm" placeholder="Chọn trạng thái" collapse-tags clearable>
                <el-option v-for="item in type_candidate" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Thời gian tạo ứng viên</b></label>
            <div class="form-group w-100 shadow-sm">
              <date-picker placeholder="Thời gian tạo" range v-model="query.date_range" format="DD-MM-YYYY"
                valueType="YYYY-MM-DD"></date-picker>
            </div>
          </div>

          <div class="col-xxl-3 col-md-4">
            <label for=""><b>Trạng thái ứng viên</b></label>
            <div class="form-group w-100">
              <el-select v-model="query.status" class="w-100 shadow-sm" placeholder="Chọn trạng thái" collapse-tags multiple clearable>
                <el-option label="Chưa đào tạo" :value="0"> </el-option>
                <el-option label="Đang đào tạo" :value="1"> </el-option>
                <el-option label="Bị loại" :value="2"> </el-option>
                <el-option label="Hoàn thành đào tạo" :value="3"> </el-option>
              </el-select>
            </div>
          </div>

          <div class="col-xxl-3 col-md-4">
            <label for="">&ensp;</label>
            <div class="form-group" @click="srearchCandidate">
              <button class="btn btn-primary font-weight-bold mr-2">
                <i class="fa fa-search"></i>Tìm kiếm</button>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center table-bordered" v-loading="loading" element-loading-text="Loading...">
            <thead>
              <tr style="background-color: #F8F8F8;">
                <th>Mã ứng viên</th>
                <th>Họ và tên</th>
                <th>Số điện thoại</th>
                <th>Email</th>
                <th>Khu vực</th>
                <th>Văn phòng</th>
                <th>Trạng thái ứng viên</th>
                <th>Thời gian tạo</th>
              </tr>
            </thead>
            <tbody v-if="listCandidate?.length > 0">
              <tr v-for="(item, index) in listCandidate">
                <td>{{ item.id ? item.id + 100000 : '' }}</td>
                <td>{{ item ? item.name : '' }}</td>
                <td>{{ item ? item.phone : '' }}</td>
                <td>{{ item ? item.email : '' }}</td>
                <td>{{ item ? item?.profile?.area?.name : '' }}</td>
                <td>{{ item ? item?.profile?.branch?.name_alias : '' }}</td>
                <td >
                  <div class="text-white rounded p-1 text-center " :class="statusCandidateCss[item?.candidates?.status]"> {{ statusCandidate[item?.candidates?.status] }}</div>
                </td>
                <td>{{ item.created_at | formatDateTimeAsia }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{
          totalData }}</b></div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
        :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
        :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
        :next-class="'page-link'" :page-class="'page-item'">
      </paginate>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { GET_ALL_CANDIDATE } from '../../../core/services/store/course/classes.module';
import { EXPORT_LIST_CANDIDATE } from '../../../core/services/store/export/export.module';
import { GET_AREA, GET_OFFICE } from "../../../core/services/store/center/branch.module";
export default {
  name: "user-candidate",
  components: {
    DatePicker
  },
  data() {
    return {
      page: 1,
      last_page: 1,
      loading_btn_accept: false,
      loading_btn_lesson: false,
      query: {
        keyword: '',
        area: [],
        branch: [],
        status: [],
        type: '',
        date_range: ''
      },
      listCandidate: [],
      branchs: [],
      item: {},
      areas: [],
      loading: false,
      totalData: '',
      loading_export: false,
      is_export_candidate: false,
      type_candidate : [
        {id:0, name:"Sinh viên"},
        {id:1, name:"Học sinh"},
        {id:4, name:"Full time"},
        {id:5, name:"Part time"},
        {id:6, name:"Cộng tác viên"}
      ],
      statusCandidateCss: {
        0: "bg-secondary text-dark",
        1: "bg-primary",
        2: "bg-danger",
        3: "bg-success",
      },

      statusCandidate: {
        0: 'Chưa đào tạo',
        1: 'Đang đào tạo',
        2: 'Bị loại',
        3: 'Hoàn thành đào tạo'
      }
    }
  },

  mounted() {
 
    this.getAllCandidate();
    this.getAllArea();
    this.getAllOffice();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý ứng viên", route: 'user-candidate' },
      { title: "Khối cơ sở" },
      { title: "Quản lý" },
      { title: "Ứng viên" }
    ]);
  },
  methods: {
    exportExcel() {
      this.loading_export = true;
      this.$store.dispatch(EXPORT_LIST_CANDIDATE, {
        page: this.page,
        keyword: this.query.keyword,
        area: this.query.area,
        status: this.query.status,
        branch: this.query.branch,
        type: this.query.type,
        date_range: this.query.date_range,
        is_export_candidate: 1
      }).then((res) => {
        this.loading_export = false;
        this.$notify({
          title: 'Success',
          message: 'Export danh sách ứng viên thành công',
          type: 'success'
        });
      })
    },

    srearchCandidate() {
      this.page = 1;
      this.getAllCandidate();
    },

    getAllCandidate() {
      let check_date = this.query.date_range;
       if(check_date[0] == null || check_date[1] == null) {
        this.query.date_range = '';
       }
      this.loading = true,
        this.$store.dispatch(GET_ALL_CANDIDATE, {
          page: this.page,
          keyword: this.query.keyword,
          area: this.query.area,
          status: this.query.status,
          branch: this.query.branch,
          type: this.query.type,
          date_range: this.query.date_range,
          is_export_candidate: 0
        }).then((res) => {
          this.listCandidate = res.data.data;
          this.totalData = res.data.total;
          this.last_page = res.data.last_page
          this.loading = false
        }).catch(() => {
          this.loading = false
        });
    },

    getAllOffice() {
      this.$store.dispatch(GET_OFFICE, {
        area_id: this.query.area
      }).then((res) => {
        if (!res.error) {
          this.branchs = res.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },

    getAllArea() {
      this.$store.dispatch(GET_AREA, {
      }).then((res) => {
        if (!res.error) {
          this.areas = res.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },



    searchData() {
      this.getAllCandidate()
    },

    clickCallback(obj) {
      this.page = obj
      this.getAllCandidate()
    },
  }
}
</script>

<style scoped>

</style>


