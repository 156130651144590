export const POINT = [
    {value: 2.5, label: 2.5},
    {value: 3, label: 3},
    {value: 3.5, label: 3.5},
    {value: 4, label: 4},
    {value: 4.5, label: 4.5},
    {value: 5, label: 5},
    {value: 5.5, label: 5.5},
    {value: 6, label: 6},
    {value: 6.5, label: 6.5},
    {value: 7, label: 7},
    {value: 7.5, label: 7.5},
    {value: 8, label: 8},
    {value: 8.5, label: 8.5},
    {value: 9, label: 9},
];

export const SPEAKING_POINT = [
    {value: 1, label: 1},
    {value: 2, label: 2},
    {value: 3, label: 3},
    {value: 4, label: 4},
    {value: 5, label: 5},
    {value: 6, label: 6},
    {value: 7, label: 7},
    {value: 8, label: 8},
    {value: 9, label: 9},
    {value: 10, label: 10},
];

export const PRE_IELTS_SPEAKING_POINT = [
    {value: 2.5, label: 2.5},
    {value: 3, label: 3},
    {value: 3.5, label: 3.5},
    {value: 4, label: 4},
    {value: 4.5, label: 4.5},
    {value: 5, label: 5},
];

export const PRE_IELTS_WRITING_POINT = [
    {value: 1, label: 1},
    {value: 2, label: 2},
    {value: 3, label: 3},
    {value: 4, label: 4},
    {value: 5, label: 5},
];

export const TEST = [
    {value: 'giaotiep', label: 'Giao tiếp'},
    {value: 'ielts', label: 'Ielts'},
    {value: 'mini-ielts', label: 'Mini Ielts'},
    {value: 'daihoc', label: 'Đại học'},
];
