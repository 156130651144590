export const CHUA_TIEP_NHAN = 0;
export const DANG_XU_LY = 1;
export const HOAN_THANH = 2;
export const TEXT_STATUS = new Map([[CHUA_TIEP_NHAN, "Chưa tiếp nhận"],[DANG_XU_LY,"Đang xử lý"],[HOAN_THANH,"Hoàn thành"]]);
export const COLOR_STATUS = new Map([[CHUA_TIEP_NHAN, "btn btn-secondary"],[DANG_XU_LY,"btn btn-primary"],[HOAN_THANH,"btn btn-success"]]);
export const LIST_STATUS = [{
    label : 'Chưa tiếp nhận',
    value : CHUA_TIEP_NHAN },
    {
        label : 'Đang xử lý',
        value : DANG_XU_LY },
    {
        label : 'Hoàn thành',
        value : HOAN_THANH },

]
export const LIST_RATE = [
    {
        label : 'Chưa đánh giá',
        value : 0 },
    {
        label : 'Chưa hài lòng',
        value : 1 },
    {
        label : 'Hài lòng',
        value : 2 },
    {
        label : 'Rất hài lòng',
        value : 3 },
]