import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "../auth.module";
import {UPDATE_WHEN_UPDATE_COURSE_TYPE} from "./courseType.module";
const _ = require('lodash');

export const GET_COURSE_LEVELS = "course-level-list";
export const DELETE_COURSE_LEVELS = "delete-course-level";
export const CREATE_COURSE_LEVELS = "create-course-level";
export const GET_COURSE_LEVEL_BY_ID = "get-course-level-by-id";
export const UPDATE_COURSE_LEVELS = "update-course-level";

//mutations types
export const SET_COURSE_LEVELS = "setCourseLevels";
export const SET_COURSE_LEVEL_PAGINATE = "setCourseLevelPaginate";
export const UPDATE_LIST_COURSE_LEVEL = "update_list_course_level";
export const UPDATE_DELETE_COURSE_LEVEL= "update_delete_course_level";
export const UPDATE_WHEN_UPDATE_COURSE_LEVEL = "update_when_update_course_level";

const state = {
    errors: null,
    courseLevels: [],
    courseLevelPaginateData: [],
};

const getters = {
    getAllCourseLevels(state) {
        return state.courseLevels;
    },
    // getCourseLevelById(state) {
    //     return state.courseLevel;
    // },
    getCourseLevelPaginate(state) {
        return state.courseLevelPaginateData;
    },
};

const actions = {
    [GET_COURSE_LEVELS](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/course-level", credentials)
                .then(({data}) => {
                    context.commit(SET_COURSE_LEVELS, data.data.data);
                    const courseLevelPagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_COURSE_LEVEL_PAGINATE, courseLevelPagination);
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },
    [GET_COURSE_LEVEL_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('course-level', id).then(({data}) => {
                // context.commit(SET_USER_BY_ID, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [UPDATE_COURSE_LEVELS](context, payload) {
        return new Promise(resolve => {
            ApiService.update('course-level', payload.id, payload).then(({data}) => {
                context.commit(UPDATE_WHEN_UPDATE_COURSE_LEVEL, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_COURSE_LEVELS](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.post('course-level', payload).then(({data}) => {
                context.commit(UPDATE_LIST_COURSE_LEVEL, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [DELETE_COURSE_LEVELS](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('course-level', id).then(({data}) => {
                context.commit(UPDATE_DELETE_COURSE_LEVEL, id);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
}

const mutations = {
    [SET_COURSE_LEVELS](state, data) {
        state.courseLevels = data;
        state.errors = {};
    },
    [SET_COURSE_LEVEL_PAGINATE](state, data) {
        state.courseLevelPaginateData = data;
    },
    [UPDATE_DELETE_COURSE_LEVEL](state, id) {
        let listCourseLevels = state.courseLevels;
        let index = _.findIndex(listCourseLevels, function (courseLevel) {
            return courseLevel.id === id;
        });
        listCourseLevels.splice(index, 1);
    },
    [UPDATE_LIST_COURSE_LEVEL](state, courseLevel) {
        let listCourseLevels = state.courseLevels;
        listCourseLevels.unshift(courseLevel);
    },
    [UPDATE_WHEN_UPDATE_COURSE_LEVEL](state, courseLevelUpdate) {
        let listCourseLevels = state.courseLevels;
        let index = _.findIndex(listCourseLevels, function (courseLevel) {
            return courseLevel.id === courseLevelUpdate.id
        });
        listCourseLevels.splice(index, 1);
        listCourseLevels.splice(index, 0, courseLevelUpdate);
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
