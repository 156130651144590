<template>
    <el-select v-model="modelSearch"
               clearable
               filterable
               remote
               reserve-keyword
               placeholder="Nhập mã nhân sự/ số điện thoại"
               class="w-100"
               :remote-method="remoteMethod"
               :loading="loading"
               @change="selectSearch"
    >
      <template slot="empty" class="align-items-center justify-content-center">
        <div class="align-items-center justify-content-center el-select-dropdown__item hover cursor-pointer">
          <div>Không tìm thấy nhân sự</div>
        </div>
      </template>
      <el-option v-for="user in userSearch"
                 :key="user.id"
                 :label="user.id + 100000 +  ' - ' + user.name + ' - ' + user.phone"
                 :value="user.id">
      </el-option>
    </el-select>
  </template>
  
  <script>
  import {SEARCH_CUSTOMER_BY_OR_ID} from "@/core/services/store/user/users.module";
  const _ = require('lodash');
  export default {
    name: "SelectSearch",
    props: {
      type : String
    },
    data() {
      return {
        loading: false,
        userSearch: [],
        modelSearch: ''
      }
    },
    methods: {
      remoteMethod(query) {
        if (query !== '' && query.length >= 6 && query.length <= 10) {
          this.new_phone = query;
          const params = {
            search_customer_by_phoneOrId: query,
            limit: 10000,
            status: 1,
            is_staff: 0,
            type : this.type
          }
          this.loading = true;
          let vm = this;
          setTimeout(function () {
            vm.getStudents(params);
          }, 300)
        }
      },
      getStudents(params) {
        this.$store.dispatch(SEARCH_CUSTOMER_BY_OR_ID, params).then((res) => {
          this.userSearch = res.data.data;
          this.loading = false;
        })
      },
      selectSearch(e) {
        let user = this.userSearch.find((d) => d.id == this.modelSearch);
        this.$emit('selectChange', this.modelSearch, user?.profile?.chi_nhanh_id);
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
