<template>
  <b-modal
      ref="modal_history_change"
      id="modal_history_change"
      size="xl"
      title="Lịch sử thay đổi"
      centered
      hide-footer
      @hidden="resetData()"
  >
    <div class="example-preview b-table-sticky-header">
      <table class="table table-vertical-center b-table  table-bordered">
        <thead class="thead-light">
        <tr>
          <th scope="col">Người thực hiện</th>
          <th scope="col">Nội dung thay đổi</th>
          <th scope="col">Thông tin chi tiết</th>
          <th scope="col">Thời gian cập nhật</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(log,index) in historyLog">
          <td>{{ `${log?.user.id} - ${log?.user?.name}` }}</td>
          <td>{{ TYPE_HISTORY_LOG.get(log.type) }}</td>
          <td style="white-space: pre-wrap">{{ log.content }}</td>
          <td>{{ formatDay(log.created_at) }}</td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center" style="margin: 10px 0" v-if="historyLog.length === 0">Không có bản
        ghi
      </div>
    </div>
    <div class="d-flex flex-row-reverse">
        <button type="button" class="btn btn-secondary" @click="closeModal()">Đóng</button>
    </div>
  </b-modal>

</template>
<script>
import {TYPE_HISTORY_LOG} from "@/core/option/testInputOption";
import moment from 'moment-timezone';

export default {
  name: "ModalHistoryChange",
  props: {
    historyLog: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      loading: false,
      TYPE_HISTORY_LOG: TYPE_HISTORY_LOG
    }
  },
  methods: {
    formatDay(data) {
     return  moment(data).format('HH:mm:ss DD-MM-YYYY')
    },
    closeModal(){
      this.$bvModal.hide('modal_history_change');
    },
    resetData(){
      this.$emit('resetHistoryLog')
    }
  }
}
</script>

<style scoped>

</style>