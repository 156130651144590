<template>
  <!--begin::Card-->
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" @submit.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title ">
            <h3 class="card-label">
              Tạo tài khoản
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'account' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-4 ">
              <div class="form-group">
                <label>Họ tên
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Họ tên" rules="required" v-slot="{ errors,classes }">
                  <input type="text" ref="name" name="name" class="form-control" :class="classes"
                         placeholder="Họ tên"
                         v-model=user.name>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4 ">
              <div class="form-group">
                <label>Email
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="email" name="Email" rules="required|email"
                                    v-slot="{ errors,classes }">
                  <input type="email" class="form-control" placeholder="Email"
                         :class="classes"
                         v-model=user.email>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4 ">
              <div class="form-group">
                <label>Số điện thoại
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="phone" name="Số điện thoại"
                                    :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                    v-slot="{ errors,classes }">

                  <input type="number" v-model="user.phone" :class="classes" class="form-control">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Loại tài khoản
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Loại tài khoản" rules="required" v-slot="{ errors,classes }">
                  <multiselect v-model="cacheAccountType" :options="accountType"
                               placeholder="Loại tài khoản"
                               label="name"
                               track-by="name"
                               @input="updateStateAccountType"
                               :class="classes"
                               :hideSelected="true"
                  ></multiselect>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
                <input type="hidden" name="account_type_id" :value="user.profile.account_type_id">
              </div>
            </div>
            <div class="col-md-4" v-if="showBusinessLevel">
              <div class="form-group">
                <label>Cấp bậc<span class="text-danger">*</span></label>
                <ValidationProvider vid="account_type_id" name="Cấp bậc"
                                    :rules="{ required: true }"
                                    v-slot="{ errors,classes }">
                  <el-select filterable class="w-100" placeholder="Cấp bậc"
                             v-model="user.profile.business_level_id"
                             clearable
                  >
                    <el-option
                        v-for="item in businessLevels"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Chọn nhóm quyền (Backend)
                  <span class="text-danger"></span></label>
                <el-select v-model="user.backend_role_id" clearable filterable
                           class="w-100" placeholder="Chọn quyền"
                           ref="backend_role_id">
                  <el-option
                      v-for="(item, key) in backend_roles"
                      :label="item.name"
                      :value="item.id"
                      :key="key"
                  >
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="col-md-4" v-if="showKhuvuc">
              <div class="form-group">
                <label>Khu vực
                  <span class="text-danger"></span></label>
                <ValidationProvider name="Khu vực" rules="required" v-slot="{ errors,classes }">
                  <multiselect v-model="cacheKhuvuc" :options="areas"
                               placeholder="Khu vực"
                               label="name"
                               track-by="name"
                               @input="updateStateKhuvuc"
                               :class="classes"
                  ></multiselect>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
                <input type="hidden" name="account_type_id" :value="user.profile.account_type_id">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Trung tâm<span class="text-danger"></span></label>
                <ValidationProvider vid="trung_tam_id" name="Chọn trung tâm"
                                    :rules="{ required: false }"
                                    v-slot="{ errors,classes }">
                  <el-select filterable class="w-100" placeholder="Chọn trung tâm"
                             v-model="user.profile.trung_tam_id"
                             clearable
                             @input="updateTrungTam"
                  >
                    <el-option
                        v-for="item in centers"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Chọn chi nhánh<span class="text-danger"></span></label>
                <ValidationProvider vid="chi_nhanh_id" name="Chọn chi nhánh"
                                    :rules="{ required: false }"
                                    v-slot="{ errors,classes }">
                  <el-select filterable class="w-100" placeholder="Chọn chi nhánh"
                             v-model="user.profile.chi_nhanh_id"
                             clearable
                  >
                    <el-option
                        v-for="item in branches"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                      <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4" v-if="showSelectBoss">
              <div class="form-group">
                <label>Chọn cấp trên
                  <span class="text-danger"></span></label>
                <multiselect
                    v-model="cacheMaCapTren"
                    placeholder="Nhập mã cấp trên..."
                    id="ajax"
                    label="name"
                    :options="maCapTren"
                    :loading="isLoadingBoss"
                    :internal-search="false"
                    @search-change="searchUserByBossCode"
                    @input="updateStateMacaptren"
                    :limit-text="3"
                    :limit="3"
                >
                </multiselect>
              </div>
            </div>

            <div class="col-md-4 ">
              <div class="form-group">
                <label>Chức vụ kiêm nhiệm
                  <span class="text-danger"></span></label>
                <multiselect v-model="cachePosition" :options="positions"
                             placeholder="Chọn chức vụ"
                             label="position"
                             track-by="position"
                             :multiple="true"
                             @input="updateStatePositions"
                ></multiselect>
              </div>
              <!--end::Form-->
            </div>
            <div class="col-md-4 ">
              <div class="form-group">
                <label>Chọn giới tính
                  <span class="text-danger"></span></label>
                <multiselect v-model="user.profile.gender" :options="gender"
                             :searchable="true"
                             :close-on-select="true"
                             :show-labels="true" placeholder="Pick a value"
                ></multiselect>
              </div>
              <!--end::Form-->
            </div>
            <div class="col-md-4 ">
              <div class="form-group mb-1">
                <label>Địa chỉ
                  <span class="text-danger"></span></label>
                <textarea class="form-control" rows="3" v-model="user.profile.address"></textarea>
              </div>
              <!--end::Form-->
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Ngày tháng năm sinh
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="profile.birthday" name="Ngày sinh" rules="required"
                                    v-slot="{ errors,classes }">
                  <date-picker v-model="user.profile.birthday" format="DD-MM-YYYY"
                               valueType="YYYY-MM-DD" :class="classes"></date-picker>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <!--end::Form-->
            </div>
            <div class="col-md-4 ">
              <div class="form-group">
                <label>Ngày bắt đâu làm việc
                  <span class="text-danger"></span></label>
                <ValidationProvider vid="profile.start_to_work" name="Ngày sinh" rules=""
                                    v-slot="{ errors,classes }">
                  <date-picker v-model="user.profile.start_to_work" format="DD-MM-YYYY"
                               valueType="YYYY-MM-DD" :class="classes"></date-picker>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <!--end::Form-->
            </div>
            <!--Thông tin cấp trên-->
            <div class="col-md-6">
              <div class="form-group">
                <label>Mật khẩu
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Mật khẩu" rules="required|min:6" v-slot="{ errors,classes }">
                  <input type="password" class="form-control" placeholder="Mật khẩu"
                         v-model="user.password" :class="classes" autocomplete="new-password">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>
                  <span class="text-danger"></span></label>
                <div class="col-9 col-form-label">
                  <div class="checkbox-inline">
                    <label class="checkbox checkbox-success">
                      <input type="checkbox" name="Checkboxes5" v-model="user.status">
                      <span></span>Kích hoạt</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Nhập lại mật khẩu
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Xác nhận mật khẩu" rules="required|min:6|confirmed:Mật khẩu"
                                    v-slot="{ errors,classes }">
                  <input name="password_confirmation" type="password" class="form-control"
                         placeholder="Xác thực mật khẩu" v-model="user.confirm_password"
                         :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <!--Thông tin tài khoản ngân hàng-->
<!--        <div class="card card-custom">-->
<!--          <div class="card-header">-->
<!--            <div class="card-title">-->
<!--              <h3 class="card-label">-->
<!--                Thông tin tài khoản ngân hàng-->
<!--              </h3>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="d-flex flex-grow-1 align-items-center bg-hover-light p-4 rounded add-bank-account"-->
<!--               @click="addAccount()">-->
<!--            <div class="mr-4 flex-shrink-0 text-center" style="width: 40px;">-->
<!--              <i class="icon-xl fas fa-plus"></i>-->
<!--            </div>-->
<!--            <div class="text-muted">Thêm tài khoản</div>-->
<!--          </div>-->
<!--          <div class="card-body">-->
<!--            <BankAccount v-for="(item, index) in user.bank_accounts" :key="index"-->
<!--                         :item="item"-->
<!--                         :index="index"-->
<!--                         :banks="banks"-->
<!--                         :bank_account="user.bank_accounts"-->
<!--                         :provinces="provinces"-->
<!--                         :branchs="branchs"-->
<!--                         @minus_account="minusAccount"-->
<!--            >-->
<!--            </BankAccount>-->
<!--          </div>-->
<!--        </div>-->
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <button type="submit" class="btn btn-success mr-2" :disabled="is_disable">
              Tạo mới
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
  <!--end::Card-->
</template>
<script>
import {mapGetters} from "vuex";
import {provinces} from "../../../assets/js/components/provinces";
import {banks} from "../../../assets/js/components/banks";
import {branchs} from "../../../assets/js/components/branchs";
import {CREATE_STAFF, SEARCH_BY_MA_CAP_TREN} from "../../../../src/core/services/store/user/users.module";
import Multiselect from 'vue-multiselect'
import BankAccount from "./BankAccount";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {GET_LIST_PERMISSION} from "../../../core/services/ajaxUrl";
import Swal from "sweetalert2";
import {GET_ACCOUNT_TYPE, GET_POSITIONS, GET_ROLES} from "../../../core/services/store/user/account-type.module";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_AREAS} from "../../../core/services/store/center/area.module";
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
import {GDKV, GDVP, HANH_CHINH_KHU_VUC, SALE_LEADER, SALE_MEMBER} from "@/core/option/accountTypeOption";
import {GET_BUSINESS_LEVEL} from "@/core/services/store/business/level.module";


export default {
  name: "UserCreate",
  components: {
    BankAccount,
    Multiselect,
    DatePicker,
  },
  watch: {
    user(bf, at) {
      console.log(bf, at);
    }
  },
  data() {
    return {
      user: {
        uuid: '',
        name: '',
        phone: "",
        email: "",
        email_verified_at: "",
        status: true,
        parent_id: "",
        remember_token: "",
        password: "",
        confirm_password: "",
        positions: [],
        profile: {
          uuid: "",
          ma_gioi_thieu: "",
          gioithieu_id: "",
          gender: 'Nam',
          address: "",
          birthday: "",
          start_to_work: "",
          loai_tai_khoan: "",
          chi_nhanh_id: "",
          trung_tam_id: "",
          van_phong_id: "",
          xac_thuc_sdt: "",
          thoi_gian_nghi_viec: "",
          account_type_id: '',
          khuvuc_id: ""
        },
        bank_accounts: [
          {
            owner_name: "",
            ngan_hang: "",
            province: "",
            chi_nhanh: "",
            stk: "",
            enable_branch_bank: false,
            branch_affter_select_province: [],
            is_default: true
          },
        ],
        backend_role_id: ''
      },
      /*Data default*/
      isLoadingBoss: false,
      keyword_search_boss_code: '',
      status: {
        0: 'Không hoạt động',
        1: 'Đang hoạt động',
        2: "Nghỉ việc"
      },
      gender: [
        'Nam', 'Nữ', 'Khác'
      ],
      boss_type_account: {
        1: 'Sale Leader',
        2: 'Sale Member'
      },
      positions: [],
      provinces: [],
      banks: [],
      maCapTren: [],
      branchs: {}, // Branch này của tài khoản ngân hàng
      accountType: [],
      backend_roles: [],
      areas: [],
      /*Cache */
      cacheAccountType: [],
      cacheKhuvuc: [],
      cachePosition: [],
      cacheBank: [],
      cacheMaCapTren: [],
      components: [
        'Autocompletes', 'Comboboxes', 'Forms', 'Inputs', 'Overflow Buttons', 'Selects', 'Selection Controls', 'Sliders', 'Textareas', 'Text Fields',
      ],
      // Check show
      showKhuvuc: false,
      showSelectBoss: false,
      is_show_branch: false,
      showTrungTam: false,
      is_disable: false,
      centers: [],
      branches: [],
      showBusinessLevel: false,
      businessLevels: []
    };
  },

  created() {
    this.getRoles();
    this.getBranches();
    this.getCenters();
    this.getPositions();
    this.getAccountType();
    this.getAreas();
  },

  mounted() {
    this.provinces = provinces
    this.banks = banks
    this.branchs = branchs
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý tài khoản", route: 'profile-1'},
      {title: "Tạo tài khoản"}
    ]);
  },

  computed: {
    getPermissionUrl: function () {
      return GET_LIST_PERMISSION
    },
    ...mapGetters(["currentUserAccountInfo", 'roles'])
  },

  methods: {
    getRoles() {
      this.$store.dispatch(GET_ROLES, {
        limit: 1000
      }).then((res) => {
        this.backend_roles = res.data.data.filter(value => value.guard_name === 'backend');
      });
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        status: 1,
        center_id: this.user.profile.trung_tam_id,
      }).then((res) => {
        if (!res.error) {
          this.branches = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getAreas() {
      this.$store.dispatch(GET_AREAS, {
        limit: 10000,
        status: 1,
      }).then((res) => {
        if (!res.error) {
          this.areas = res.data.data
        }
      });
    },
    getCenters() {
      this.$store.dispatch(GET_CENTERS, {
        limit: 10000,
        status: 1,
      }).then((res) => {
        if (!res.error) {
          this.centers = res.data.data
        }
      });
    },
    setStateBranch($event) {
      this.user.profile.chi_nhanh_id = $event.id
    },
    /*Thêm account*/
    addAccount() {
      this.user.bank_accounts.push({
        enable_branch_bank: false,
        owner_name: "",
        ngan_hang: "",
        chi_nhanh: "",
        stk: "",
        is_default: false,
      })
    },
    /*Xóa account*/
    minusAccount(index) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          if (index > 0) {
            this.user.bank_accounts.splice(index, 1)
          }
        }
      });
    },
    /*Nếu là ngân hàng agribank thì cho chọn thành phố vào chi nhánh*/
    updateStateCenter(obj) {
      this.user.profile.trung_tam_id = obj.id
    },
    updateStateAccountType(obj) {
      this.updateShowSelectBoss(obj)
      this.checkShowCenterAndBranch(obj)
      let id = obj.id;
      if (id === GDKV || id === HANH_CHINH_KHU_VUC) {
        this.showKhuvuc = true;
      } else {
        this.showKhuvuc = false;
      }
      this.user.profile.account_type_id = id;
      this.checkShowBusinessLevel(id);
    },
    updateShowSelectBoss(obj) {
      if (obj.id === 1 || obj.id === 2 || obj.id === 3 || obj.id === 4) {
        this.showSelectBoss = true
      } else {
        this.showSelectBoss = false
      }
    },
    checkShowCenterAndBranch(obj) {
      let slug = obj.slug;
      if (slug === 'sale-leader' || slug === 'sale-expert' || slug === 'sale-member' || slug === 'sale-intern' || slug === 'gdvp' || slug === 'quan-ly-hoc-vien') {
        this.is_show_branch = true
      } else {
        this.is_show_branch = false
      }

      if (slug === 'tai-khoan-uy-quyen' || slug === 'chi-nhanh') {
        this.showTrungTam = true;
      } else {
        this.showTrungTam = false;
      }
    },
    updateStateKhuvuc(obj) {
      this.user.profile.khuvuc_id = obj.id
    },
    updateTrungTam(obj) {
      this.user.profile.chi_nhanh_id = '';
      this.branches = [];
      this.getBranches();
    },
    updateStatePositions($event) {
      this.user.positions = []
      $event.map((value) => {
        this.user.positions.push(value.id)
      })
    },
    updateStateMacaptren(obj) {
      this.user.parent_id = obj.id
    },
    updateStateBank(obj) {
      this.user.bank_accounts.ngan_hang = obj.id
    },
    getAccountType() {
      this.$store.dispatch(GET_ACCOUNT_TYPE, {
        limit: 1000,
      }).then((res) => {
        if (!res.error) {
          this.accountType = res.data.data
        }
      });
    },
    getPositions() {
      this.$store.dispatch(GET_POSITIONS, {}).then((res) => {
        if (!res.error) {
          this.positions = res.data
        }
      });
    },
    searchUserByBossCode(query) {
      this.isLoadingBoss = true;
      if (query.length > 0) {
        this.$store.dispatch(SEARCH_BY_MA_CAP_TREN, {
          search_boss: query,
          is_staff: 1,
          limit: 100,
        }).then((res) => {
          if (!res.error) {
            this.maCapTren = res.data.data
            this.isLoadingBoss = false;
          }
        }).catch(() => {
          this.isLoadingBoss = false;
        });
      } else {
        this.isLoadingBoss = false;
      }
    },
    checkShowBusinessLevel(id) {
      this.showBusinessLevel = false;
      if ([GDVP, GDKV, SALE_LEADER, SALE_MEMBER].includes(id)) {
        this.getBusinessLevels(id);
        this.showBusinessLevel = true;
        this.user.profile.business_level_id = this.user.profile.business_level_id > 0 ? this.user.profile.business_level_id : ''
      }
    },
    getBusinessLevels() {
      this.$store.dispatch(GET_BUSINESS_LEVEL, {
        limit: 10000,
        status: 1,
        account_type_id: this.user.profile.account_type_id
      }).then((res) => {
        if (!res.error) {
          this.businessLevels = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    onSubmit() {
      this.is_disable = true;
      this.$store
          .dispatch(CREATE_STAFF, this.user)
          .then((res) => {
            this.$router.push({name: "account"}).then(() => {
              this.$bvToast.toast('Tạo tài khoản thành công', {
                title: 'Tạo tài khoản',
                variant: 'success',
                solid: true
              });
            })
          }).catch((e) => {
        if (e.data?.data?.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
          _.each(e.data.data.message_validate_form, (value, key) => {
            this.$bvToast.toast(value[0], {
              title: 'Tạo tài khoản',
              variant: 'danger',
              solid: true
            });
          })
        }
        if (e.data?.message) {
          this.$bvToast.toast(e.data.message, {
            title: 'Tạo tài khoản',
            variant: 'danger',
            solid: true
          });
        }
      }).finally(() => {
        this.is_disable = false;
      });
    },
  },
};
</script>
<style scoped>
.mx-datepicker {
  width: 100% !important;
}

.add-bank-account:hover i, .add-bank-account:hover .text-muted {
  color: #1BC5BD !important;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

