<template>
 <span class="switch switch-sm switch-icon">
                      <label>
                       <input @input="change" type="checkbox" v-model="item.status"/>
                       <span></span>
                      </label>
                     </span>
</template>

<script>
import {ATTENDANCE_UPDATE_STATUS} from "@/core/services/store/attendance/attendance.module";

export default {
  name: "AttendanceStatus",
  props: {
    item: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  methods: {
    change() {
      this.$store.dispatch(ATTENDANCE_UPDATE_STATUS, {
        status: !this.item.status,
        fingerprint_id: this.item.fingerprint_machine_id,
      }).then((data) => {
        this.$bvToast.toast(data.message, {
          title: 'Cập nhật trạng thái',
          variant: 'success',
          solid: true
        });
        this.users = this.users.map((value) => {
          if (value.id === item.id) {
            value.has_user_id = true;
          }
          return value;
        });
      }).catch((err) => {
        this.$bvToast.toast(err.data.data.message, {
          title: 'Cập nhật trạng thái',
          variant: 'warning',
          solid: true
        });
      });
    }
  }
}
</script>

<style scoped>

</style>