<template>
  <div>
    <div class="mb-4">
      <label for="">Bước 1: Tải file mẫu</label>
      <div>
        <button class="btn btn-primary" @click="exportFileMau">
          <i class="el-icon-download"></i>
          Tải file
        </button>
      </div>
    </div>
    <div>
      <label for="">Bước 2: Upload file excel lên hệ thống</label>
      <el-upload
          ref="upload"
          accept=".xlsx"
          :limit="1"
          list-type="text"
          action=""
          :file-list="fileList"
          :on-change="handleChange"
          :on-remove="handleRemove"
          :auto-upload="false"
          :multiple="false"
      >
        <el-button size="small" type="warning">
          <i class="el-icon-upload2"></i>
          Tải lên
        </el-button>
      </el-upload>
    </div>
    <div class="mt-3 d-flex justify-content-end">
      <el-button @click="closeModalImport" class="btn">Hủy</el-button>
      <el-button :loading="loading" size="small" type="success" class="btn btn-success" @click="submitUpload">Xác nhận</el-button>
    </div>
  </div>
</template>

<script>
import {
  EXPORT_EXAM_CLASS,
  PREVIEW_IMPORT,
  GET_DATA_IMPORT, EXPORT_EXAM_CLASS_VOUCHER, GET_DATA_IMPORT_CLASS
} from "@/core/services/store/rewards-programs/rewardsPrograms.module";
import { read, utils } from "xlsx";

export default {
  name: "ModalImportClassVoucher",
  props: {
    loadingPreview : {
      type : Boolean,
      default() {
        return true;
      }
    },
  },
  data() {
    return {
      fileList: [],
      disable: false,
      loading: false,
      listDataImport: []
    }
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    async submitUpload() {
      await this.fileToJson(this.fileList).then(dataToImport => {
        this.loading = true;
        this.listDataImport = dataToImport;
        this.validateData(dataToImport);
      }).catch(e => {
        this.$message({
          type: 'error',
          message: 'Có lỗi xảy ra',
          showClose: true
        });
      });
    },
    validateData(dataToImport) {
      if (!this.listDataImport.length) {
        this.listDataImport = {
          error: [
            data?.ma_qua_tang ? null : "Mã qùa tặng không được để trống.",
            data?.ten_lop ? null : "Tên lớp không được để trống.",
          ],
          success: 1
        }
        this.$emit("importSuccess", this.listDataImport);
        return;
      }

      this.listDataImport = this.listDataImport.map((data) => {
        return {
          ...data,
          error: [
            data?.ma_qua_tang ? null : "Mã qùa tặng không được để trống.",
            data?.ten_lop ? null : "Tên lớp không được để trống.",
          ],
          success: 1
        }
      });
      // console.log(this.listDataImport.find(d => d.error.find(c => c != null)),'alo');
      // if (this.listDataImport.find(d => d.error.find(c => c != null))) {
      //   this.$emit("importSuccess", this.listDataImport);
      //   return;
      // }

      this.$store.dispatch(GET_DATA_IMPORT_CLASS, {id: this.$route.params.id, data: dataToImport}).then((response) => {
        let errorImport = response;
        this.listDataImport = this.listDataImport.map((data) => {
          var errorData = errorImport.find(d => d.ten_lop == data.ten_lop);
          return {
            ...data,
            error: [
              ...data.error,
              errorData?.ma_qua_tang ? errorData?.gift_error : null,
              errorData?.ten_lop ? errorData?.class_error : null,
            ],
            success: (errorData.gift_error && errorData?.gift_error != null) || (errorData.class_error && errorData?.class_error != null) > 0
          }
        });
        this.$emit("importSuccess", this.listDataImport);
        this.loading = false;
      }).catch((res) => {
        console.log(res, "err GET_DATA_IMPORT");
      });
    },
    handleRemove() {
      this.fileList = []
    },
    handleChange(file, fileList) {
      this.fileList = fileList
    },
    exportFileMau() {
      this.$store.dispatch(EXPORT_EXAM_CLASS_VOUCHER).then((res) => {
        this.$notify({
          title: 'Thành công',
          message: res.message,
          type: 'success'
        })
      }).catch((error) => {
        this.$notify({
          title: 'Thất bại',
          message: error.data.message,
          type: 'error'
        })
      })
    },
    fileToJson(file) {
      return new Promise((res, rej) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader()
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result
          const wb = read(bstr, {type: 'binary'})
          /* Get first worksheet */
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          /* Convert array of arrays */
          const data = utils.sheet_to_json(ws)

          res(data);
        }
        reader.onerror = (e) => {
          rej(e);
        };
        reader.readAsBinaryString(file[0].raw)
      });
    },
    closeModalImport() {
      this.fileList = [];
      this.$emit("closeModalImport", false);
    }
  }
}
</script>

<style scoped>

</style>