<template>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form class="form" @submit.prevent autocomplete="off">
        <div v-if="isVoucher" class="row">
          <div class="col-lg-12 col-xs-12 mb-3">
            <p>Tên lớp học <span class="text-danger">*</span></p>
            <ValidationProvider name="Lớp học" rules="required" v-slot="{ errors }" vid="combo_id" >
              <el-input v-model="itemClass.className" placeholder="Nhập tên lớp học"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-lg-12 col-xs-12 mb-3">
            <p>Quà tặng <span class="text-danger">*</span></p>
            <ValidationProvider name="voucher" rules="required" v-slot="{ errors }" vid="id_voucher">
              <el-select v-model="itemClass.voucherId" clearable placeholder="Chọn voucher" class="w-100">
                <el-option
                    v-for="item in listGift"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-lg-12 col-xs-12 mb-3">
            <p>Số lượng khóa <span class="text-danger">*</span></p>
            <ValidationProvider name="Số lượng khóa" rules="required" v-slot="{ errors,classes }"
                                vid="title">
              <el-select class=" d-block" placeholder="Tên khóa học/combo" size="large" v-model="itemClass.quantity"
                         @change="changeCombo">
                <el-option label="1" value="1"/>
                <el-option label="2" value="2"/>
                <el-option label="3" value="3"/>
                <el-option label="4" value="4"/>
                <el-option label="5" value="5"/>
              </el-select>
<!--              <el-input type="number" placeholder="Số lượng khóa" v-model="dataClass.quantity" @input="changeCombo"/>-->
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-lg-12 col-xs-12 mb-3">
            <p>Tên khóa học/combo <span class="text-danger">*</span></p>
            <ValidationProvider name="Tên khóa học/combo" rules="required" v-slot="{ errors,classes }"
                                vid="title" >
              <el-select class=" d-block" placeholder="Tên khóa học/combo" size="large" v-model="itemClass.comboId">
                <el-option
                  v-for="item in quantity"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"

                />
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-lg-12 col-xs-12">
            <p>Loại voucher <span class="text-danger">*</span></p>
            <ValidationProvider name="Loại voucher" rules="required" v-slot="{ errors,classes }"
                                vid="title" >
              <el-select class=" d-block" placeholder="Chọn loại voucher" size="large" v-model="itemClass.type">
                <el-option
                  v-for="item in typeVoucher"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"

                />
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-lg-12 col-xs-12">
            <p>Voucher <span class="text-danger">*</span></p>
            <ValidationProvider name="Quà tặng" rules="required" v-slot="{ errors,classes }"
                                vid="title">
              <el-select class=" d-block" placeholder="Chọn quà tặng" size="large" v-model="itemClass.voucherIds" multiple>
                <el-option
                    v-for="item in listGift"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"

                />
              </el-select>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="d-flex d-flex justify-content-end mt-4">
          <button
              type="button"
              class="btn btn-secondary mr-4 "
              @click="handleCancel()"
          >
            Hủy
          </button>
          <button
              type="button"
              class="btn btn-success "
              @click="handleSubmit(editClass)"
          >
            Xác nhận
          </button>
        </div>
      </form>
    </ValidationObserver>
</template>

<script>
import {EDIT_CLASS, GET_LIST_GIFT, GET_COMBO_BY_QUANTITY} from "@/core/services/store/rewards-programs/rewardsPrograms.module";
import {GiftModel} from "@/view/pages/rewards-program/detail-program/model/InformationProgramModel";

export default {
  name: "ModalEditClass",
  data() {
    return {
      idProgram: '',
      listGift: [],
      sameNameError : false,
      quantity: {},
      typeVoucher: [
        {
          id: 0,
          name: 'Giảm giá theo hợp đồng'
        },
        {
          id: 1,
          name: 'Giảm giá theo khóa học'
        }
      ],
      dataFilter: []
    }
  },
  async mounted() {
    this.idProgram = this.idDetailProgram = this.$route.params.id;
      console.log('1');
      await this.getListGift()
    this.filterGift()
  },
  props: {
    itemClass: {
      type: Object,
      default() {
        return {}
      }
    },
    isVoucher: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  methods: {
    async getListGift() {
      await this.$store.dispatch(GET_LIST_GIFT, { id: this.idProgram,  all: 1 }).then((data) => {
        if(!data.error){
          this.listGift = data?.map(item => {
            return new GiftModel(
                item?.id,
                item?.name,
                item?.file_name,
                item?.url,
                item?.program_type
            )
          })
        }
      })
    },
    filterGift() {

    },
    handleCancel() {
        console.log("closeEdit");
        this.$emit('closeEdit');
    },
    editClass() {
      if(this.itemClass.name === ''){
        return
      }
      let payload = {
        combo_id : this.itemClass.comboId,
        type_voucher : this.itemClass.type,
        // spin_gift_id: this.itemClass.gift,
        is_of_slot: false,
        spin_reward_program_id: this.idProgram,
        id : this.itemClass.id,
        program_type: this.isVoucher ? 1 : 2,
        name: this.itemClass.className,
        spin_gift_id: this.itemClass.voucherId,
      }
      if (!this.isVoucher) {
        payload.spin_gift_id = this.itemClass.voucherIds
      }
      this.$store.dispatch(EDIT_CLASS, payload).then(() => {
        this.$notify({
          title: 'Thành công',
          message: 'Cập nhật lớp học thành công ',
          type: 'success'
        });
        this.$emit('closeEdit')
      }).catch((error) => {
        if(error?.data?.data?.message_validate_form?.name[0]??'' == 'Tên đã tồn tại trong hệ thống.'){
          this.sameNameError = true
        }
      })
    },
    clearData() {
      this.itemClass = {
        name: null,
        gift: null,
      }
      this.sameNameError = false
    },
    getCombo() {
      this.$store.dispatch(GET_COMBO_BY_QUANTITY, {quantity: this.itemClass.quantity}).then((data) => {
        this.quantity = data.data;
      })
    },
    changeCombo() {
      this.$store.dispatch(GET_COMBO_BY_QUANTITY, {quantity: this.itemClass.quantity}).then((data) => {
        this.itemClass.combo_id = '';
        this.quantity = data.data;
      })
    }
  }
}
</script>

<style scoped>

</style>
