<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Lịch sử khách hàng</h3>
            </div>
            <div class="card-title">
            </div>
        </div>
        <div class="card-body">
            <!--begin::Example-->
            <div class="example mb-10">
                <div class="row">
                    <div class="col-md-2">
                        <div class="form-group">
                            <multiselect v-model="cacheLogType" :options="log_type"
                                         placeholder="Lọc"
                                         label="name"
                                         track-by="name"
                                         @input="handleLogType($event)"
                                         :hideSelected="true"
                            ></multiselect>
                        </div>
                    </div>
                </div>
                <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                    <form class="form" @submit.prevent="handleSubmit(onSubmit)">
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <ValidationProvider vid="type_cskh" name="Tag" rules="" v-slot="{ errors,classes }">
                                        <multiselect v-model="cacheTypeCskh" :options="type_cskh"
                                                     placeholder="CSKH"
                                                     label="name"
                                                     track-by="name"
                                                     @input="handleTypeCskh($event)"
                                                     :hideSelected="true"
                                                     :class="classes"
                                        ></multiselect>
                                        <div class="fv-plugins-message-container">
                                            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                                errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="form-group col-md-7">
                                <div class="input-group">
                                    <ValidationProvider vid="content" name="Nội dung" rules=""
                                                        v-slot="{ errors,classes }">
                                        <input type="text" class="form-control" placeholder="Nhập nội dung"
                                               v-model="history.content"
                                               aria-describedby="basic-addon2"
                                               :class="classes"
                                        >
                                        <div class="fv-plugins-message-container">
                                            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                                errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>

                                </div>
                            </div>
                            <div class="col-md-3 mb-7">
                                <div class="d-flex justify-content-between">
                                    <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2">Gửi
                                    </button>
                                    <button v-else type="button" disabled
                                            class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                                        Tìm kiếm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col" class="min-w-30px">STT</th>
                            <th scope="col" class="min-w-100px">Ngày</th>
                            <th scope="col" class="min-w-100px">Hành động</th>
                            <th scope="col" class="min-w-100px">Status</th>
                            <th scope="col" class="min-w-100px">Trạng thái/Nội dung</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in customer_log.data" :key="index">
                            <td>{{index + 1}}</td>
                            <td>{{item.created_at | formatDateTimeAsia}}</td>
                            <td>{{item.action}}</td>
                            <td>{{item.status_cskh}}</td>
                            <td>
                                {{item.content}}
                                <ModalCSKH #ModalCskh
                                           :item="item"
                                           :index="index"
                                           @updateSuccess="updateSuccess"
                                           v-if="item.status_cskh ==='Đang xử lý'"
                                >
                                </ModalCSKH>
                                <ModalCSKHRep #ModalCskhRep
                                              :item="item"
                                              :index="index"
                                              @updateSuccess="updateSuccess"
                                              v-if="checkShowModalRepCskh(item.status_cskh)"
                                >
                                </ModalCSKHRep>
                                <span v-if="item.content_reply_cskh" class="font-weight-light text-success">
                                <i class="flaticon2-send"></i> Câu trả lời:
                                {{item.content_reply_cskh}}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        <div class="edu-paginate mx-auto text-center">
            <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :page-class="'page-item'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
            >
            </paginate>
        </div>

    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";

    import Multiselect from 'vue-multiselect'
    import FileUpload from 'vue-upload-component';
    // Date picker
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    //
    import {CREATE_CUSTOMER_LOG, GET_CUSTOMER_LOG} from "../../../core/services/store/customer/customer-log.module";
    import {GET_CUSTOMER_BY_ID} from "../../../core/services/store/customer/customer.module";
    import ModalCSKH from './Modal/ModalCSKH';
    import ModalCSKHRep from './Modal/ModalCSKHRep';

    const _ = require('lodash');

    export default {
        components: {
            Multiselect, FileUpload, DatePicker, Loading, ModalCSKH, ModalCSKHRep
        },
        name: "CustomerHistory",
        data() {
            return {
                /*Export googlesheet*/
                is_disable_search: false,
                /*End*/
                query: {
                    log_type: '',
                },
                history: {
                    customer_id: '',
                    type: '',
                    old_level: '',
                    new_level: '',
                    content: '',
                    log_type: '',
                    type_cskh: '',
                    content_cskh: '',
                    content_reply_cskh: '',
                    status_cskh: '',
                    action: '',
                    user_cskh: '',
                    course_student_id: '',
                    lesson: '',
                    help_id: '',
                    user_id: '',
                    data: '',
                    reason: '',
                    center_user_id: '',
                    ngay_hen: '',
                },
                log_type: [
                    {
                        value: '',
                        name: "--Chọn--"
                    },
                    {
                        value: 'kinh_doanh',
                        name: 'Kinh doanh',
                    },
                    {
                        value: 'tai_chinh',
                        name: 'Tài chính',
                    },
                    {
                        value: 'hoc_tap',
                        name: 'Học tập',
                    },
                    {
                        value: 'cskh',
                        name: 'Chăm sóc khách hàng',
                    },
                ],
                type_cskh: [
                    // {
                    //     value: '',
                    //     name: "--Chọn--"
                    // },
                    {
                        value: 'khieu_nai',
                        name: 'Khiếu nại',
                    },
                    {
                        value: 'cham_soc',
                        name: 'Tele chăm sóc',
                    },
                ],
                customer: {},
                customer_log: [],
                keyword: '',
                // Paginate
                page: 1,
                last_page: 1,
                // Cache
                cacheLogType: [],
                cacheTypeCskh: [],
            }
        },
        mounted() {
            this.getList()
            this.getCustomer()
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý khách hàng", route: 'profile-1'},
                {title: "Lịch sử khách hàng"}
            ]);
        },
        methods: {
            checkShowModalRepCskh(status) {
                return status == 'Tiếp nhận' || status == 'Đã xử lý xong' || status == 'Hài lòng'
            },
            onSubmit() {
                this.$store.dispatch(CREATE_CUSTOMER_LOG, this.history).then((res) => {
                    if (!res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Tạo mới',
                            variant: 'success',
                            solid: true
                        });
                        this.history.content = ''
                        this.history.type_cskh = ''
                        this.cacheTypeCskh = []
                        this.getList()
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                });
            },
            getList() {
                this.page = parseInt(this.$route.query.page)
                this.pullParamsUrl()
                this.callApiGetList()
            },
            getCustomer() {
                let id = this.$route.params.id;
                this.$store.dispatch(GET_CUSTOMER_BY_ID, id).then((res) => {
                    if (!res.error) {
                        this.customer = res.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            sendCSKH() {
                this.is_disable_search = true
                this.page = 1
                this.pushParamsUrl()
                this.callApiGetList()
            },

            pullParamsUrl() {
            },
            setCacheTheLoad() {

            },
            clickCallback(obj) {
                this.page = obj
                this.$router.push({
                    name: 'customer-user-history', query: {
                        keyword: this.query.keyword,
                        page: this.page,
                    }
                })
                this.callApiGetList()
            },
            callApiGetList() {
                let customer_id = this.$route.params.id;
                this.$store.dispatch(GET_CUSTOMER_LOG, {
                    customer_id: customer_id,
                    log_type: this.query.log_type,
                    page: this.page,
                }).then((res) => {
                    this.customer_log = res.data
                    this.last_page = res.data.last_page
                    this.is_disable_search = false
                });
            },
            pushParamsUrl() {
                this.$router.push({
                    name: 'customer-user-history',
                    query: {
                        page: this.page,
                        type_log: this.query.type_log
                    }
                })
            },

            handleLogType($event) {
                this.query.log_type = $event.value
                this.getList()
            },
            handleTypeCskh($event) {
                this.history.customer_id = this.customer.id
                this.history.type_cskh = $event.value
                this.history.log_type = 'cskh'
                this.history.old_level = this.customer.level
                this.history.new_level = this.customer.level
                this.history.status_cskh = 'Đang xử lý'
                if ($event.value == 'cham_soc') {
                    this.history.action = 'Tele chăm sóc'
                } else {
                    this.history.action = 'Khiếu nại'
                }
                this.history.user_cskh = 0
            },
            addFirstSelectExample(obj, text) {
                obj.unshift({
                    name: text
                })
            },
            fillUser(item) {
                if (!item) {
                    return '';
                } else {
                    let code = item.id + 100000
                    let name = item.name
                    return code + ' - ' + name;
                }

            },
            updateSuccess() {
                this.getList()
            }
        }
    }
</script>
<style>
    .fa-minus-circle:hover {
        color: red !important;

    }

    .input-group i {
        font-size: 0.9rem;
    }

    /*Css multiple select*/
    .mx-datepicker {
        width: 100% !important;
    }

    .multiselect__tags {
        padding: 3px 40px 0 9px !important;
        min-height: 38px !important;
    }

    .multiselect {
        min-height: 38px !important;
    }

    .mx-input {
        min-height: 38px !important;
    }

    .multiselect__single {
        margin-top: 5px !important;
    }

    .input-group span {
        width: 100% !important;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
