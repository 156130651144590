<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Phòng ban xử lý</h3>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="ticket_id">Mã ticket
                <span class="text-danger"></span></label>
              <el-input id="ticket_id" type="number" v-model="query.ticket_id"
                        placeholder="Mã ticket..." clearable></el-input>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="user">Khách hàng
                <span class="text-danger"></span></label>
              <el-input id="user" type="text" v-model="query.keyword"
                        placeholder="Tên, SĐT, Mã học viên..." clearable></el-input>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="user_feedback">Nội dung Ticket
                <span class="text-danger"></span></label>
              <el-input id="user_feedback" type="text" v-model="query.content"
                        placeholder="Nội dung Ticket"></el-input>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block" for="office">Phòng ban xử lý</label>
              <el-select id="office" v-model="query.phong_ban_xu_ly"
                         clearable filterable multiple
                         placeholder="Chọn Phòng ban xử lý..."
                         class="w-100"
                         @change="changePhongBanXuLy"
              >
                <el-option v-for="item in departments"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block" for="status">Trạng thái</label>
              <el-select id="status" v-model="query.status" clearable filterable multiple placeholder="Trạng thái" class="w-100">
                <el-option v-for="status in TICKET_STATUS"
                           :key="status.value"
                           :label="status.label"
                           :value="status.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="create_date">Thời gian chuyển phòng ban
                <span class="text-danger"></span></label>
              <date-picker
                  placeholder="Chọn thời gian"
                  format="DD-MM-YYYY"
                  id="create_date"
                  range
                  valueType="YYYY-MM-DD" v-model="query.date_range_update">
              </date-picker>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="create_date">Danh mục
                <span class="text-danger"></span></label>
                <el-select id="status" v-model="query.category_status"
                           clearable
                           filterable
                           multiple
                           reserve-keyword
                           collapse-tags
                           placeholder="Danh mục"
                           class="w-100">
                  <el-option v-for="(item, index) in ticket_categories"
                             :key="index"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Độ ưu tiên</label>
              <el-select v-model="query.priorities" filterable class="w-100" placeholder="Độ ưu tiên" clearable>
                <el-option label="Tiêu chuẩn" :value="1"> </el-option>
                <el-option label="Ưu tiên" :value="2"> </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="d-flex justify-content-between">
              <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                      @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
              </button>
              <button v-else type="button" disabled
                      class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                Tìm kiếm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="example-preview table-responsive b-table-sticky-header">
        <table :aria-busy="isBusy" class="table table-hover table-bordered table-hover b-table">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="min-w-80px">Mã ticket</th>
              <th scope="col" class="min-w-80px">Phân loại</th>
              <th scope="col" class="min-w-130px">Bên gửi phản hồi</th>
              <th scope="col" class="min-w-120px">Danh mục</th>
              <th scope="col" class="min-w-110px">Độ ưu tiên</th>
              <th scope="col" class="min-w-130px">Phòng ban xử lý</th>
              <th scope="col" class="min-w-150px">Trạng thái</th>
              <th scope="col" class="min-w-170px">Thời gian chuyển phòng ban</th>
              <th scope="col" class="min-w-140px">Đánh giá</th>
              <th scope="col" class="min-w-120px">Thao tác</th>
            </tr>
          </thead>
          <tbody v-if="isBusy">
          <tr role="row" class="b-table-busy-slot" >
            <td colspan="16" role="cell" class="text-center">
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else-if="tickets && tickets.length > 0" >
            <tr v-for="(item, index) in tickets" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.is_auto == 1 ? 'Chăm sóc học viên' : 'Yêu cầu hỗ trợ' }}</td>
              <td>{{ (item.is_auto === 2 || item.is_auto === 1) && (item.category_chamsoc != 1 && item.category_chamsoc != 2 && item.category_chamsoc != 6 && item.category_chamsoc != 10 && item.category_chamsoc != 11)  ? 'QLHV' : 'TTHT'}}</td>
              <td>{{ item.category_ticket ? item.category_ticket && item.category_ticket.name : category_chamsoc_define[item.category_chamsoc] }} </td>
              <td> <p :style="renderPriority(item).styleColor"> {{ renderPriority(item).label }} </p> </td>
              <td>{{ item.phong_ban_xu_ly_rel && item.phong_ban_xu_ly_rel.name }}</td>
              <td>
                <span class="p-2 rounded" :class="TICKET_STATUS.find((v) => v.value === Number(item.status))
                 && TICKET_STATUS.find((v) => v.value === Number(item.status)).bg_color " >
                  {{ TICKET_STATUS.find((v) => v.value === Number(item.status)) && TICKET_STATUS.find((v) => v.value === Number(item.status)).label }}
                </span>
              </td>
              <td>{{ item.department_processing_move_at | formatDateTime }}</td>
              <td>{{ renderRate(item) }}</td>

              <td class="sticky-action">
                <router-link :to="{name: 'show-feedback', params: {id: item.id}}"
                    title="Chi tiết ticket" class="btn sm btn-icon btn-outline-success">
                  <i class="far fa-eye"></i>
                </router-link>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="17" class="text-center">Chưa có dữ liệu</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-end mt-6 bold">Tổng số bản ghi : {{ total }}</div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';
import '@/assets/sass/table/tableCommon.scss'
import {GET_DEPARTMENTS, GET_TICKET_CATEGORIES, GET_TICKETS} from "@/core/services/store/ticket/ticket.module";
import {TICKET_STATUS, TICKET_PROPERTY, TICKET_EVALUATE, TICKET_FROM, FEEDBACK_TICKET_STAR} from "@/core/option/ticketOption";
import {CATEGORY_LIST} from "@/core/services/store/user/ticket.module";
const _ = require('lodash');

export default {
  components: {
    DatePicker,
  },
  name: "FeedbackIndex",
  data() {
    return {
      isBusy: false,
      is_disable_search: false,
      branches: [],
      TICKET_STATUS,
      TICKET_PROPERTY,
      TICKET_EVALUATE,
      TICKET_FROM,
      FEEDBACK_TICKET_STAR,
      centers: [],
      sources: [],
      tickets: [],
      // Paginate
      page: 1,
      last_page: 1,
      query: {
        is_auto: [],
        ticket_id: '',
        response: '',
        property: [],
        evaluate: [],
        date_range_update: [],
        keyword: '',
        status: [],
        phong_ban_xu_ly: [],
        content: '',
        category_status: '',
        priorities: ''
      },
      date_filter_default: [],
      districtsByProvince: [],
      is_auto_default: [],
      //Phòng ban xử lý
      departments: [],
      ticket_categories: [],
      currentStudentAmount: {},
      category_chamsoc_define: {
        1: 'Nghỉ học 2 buổi',
        2: 'Điểm kiểm tra thấp',
        3: 'Không làm bài',
        4: 'ChƯa đóng tiền học',
        5: 'Đã đóng tiền học',
        6: 'Feedback thấp',
        7: 'Nghỉ học 1 buổi',
        8: 'Nghỉ học 1 buổi',
        9: 'Hướng dẫn app cho phụ huynh',
        10: 'Kết quả BTVN không tốt',
        11: 'Kết quả BTVN không tốt',
        13: 'Nhắc nhở bảo hành',
        14: 'Nghỉ 1 buổi không phép',
        15: 'Nghỉ buổi khai giảng',
        16: 'Chăm sóc học viên giữa khóa'
      },
      total: 0,
      listCategory: []
    }
  },
  created() {
    this.getDateFilterDefault();
    this.getListDepartments();
    this.getListTicketCategories();
    this.getList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Phản hồi", route: '#'},
      {title: "Xử lý phản hồi"}
    ]);
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {},
  methods: {
    getDateFilterDefault() {
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
      this.date_filter_default.push(startOfMonth);
      this.date_filter_default.push(endOfMonth);
    },
    getList() {
      this.pullParamsUrl();
      this.callApiGetList();
    },
    getListDepartments(){
      this.$store.dispatch(GET_DEPARTMENTS, { status: 1, all: true }).then((res) => {
        this.departments = res.data;
      })
    },
    getListTicketCategories(){
      this.$store.dispatch(GET_TICKET_CATEGORIES,{
        departmentIds: this.query.phong_ban_xu_ly,
        status: 1, limit: 100000,
        join: true
      }).then((res) => {
        this.ticket_categories = res.data;
        Array.prototype.push.apply(this.ticket_categories, CATEGORY_LIST);
        this.ticket_categories.map(function (item) {
          item.value_prop = item.id + ',' + item.status;
          return item;
        })
      })
    },
    pullParamsUrl() {
      this.page = this.$route.query.page ? Number(this.$route.query.page) : 1
      this.pullParamsFromUrl(this.query);
      this.query.is_auto =this.$route.query.is_auto ? this.$route.query.is_auto.split(',').map(Number) : this.is_auto_default;
      this.query.evaluate = this.$route.query.evaluate ? this.$route.query.evaluate.split(',').map(item => isNaN(item) ? item : Number(item)) : [];
      this.query.date_range_update = this.$route.query.date_range_update ? this.$route.query.date_range_update : [];
    },
    pushParamsToUrl() {
      this.pushParamsUrl({
        page: this.page,
        ...this.query,
        is_auto: this.query.is_auto.length === 0 ? this.is_auto_default : this.query.is_auto,
        date_range_update: this.query.date_range_update ? this.query.date_range_update : this.date_filter_default,
      });
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({
        path: '',
        query: {
          page: this.page,
          ...this.query,
          date_range_update: this.query.date_range_update ? this.query.date_range_update : this.date_filter_default,
          type_filter: 'Phòng ban xử lý',
        }
      })
      this.callApiGetList();
    },
    callApiGetList() {
      this.is_auto_default = this.TICKET_FROM.map((item) => item.value);

      this.isBusy = true;
      this.$store.dispatch(GET_TICKETS, {
        page: this.page,
        ...this.query,
        is_auto: this.query.is_auto.length === 0 ? this.is_auto_default : this.query.is_auto,
        date_range_update: this.query.date_range_update ? this.query.date_range_update : this.date_filter_default,
        not_show: true,
        search: true,
        type_filter: 'Phòng ban xử lý',
      }).then((res) => {
        if (!res.error) {
          this.isBusy = false;
          this.is_disable_search = false;
          this.tickets = res.data.data;
          this.last_page = res.data.last_page;
          this.total = res.data.total;
        }
      }).finally(() => {
      });
    },
    searchData() {
      this.page = 1
      this.is_disable_search = true
      this.pushParamsToUrl();
      this.callApiGetList();
    },
    renderPriority(ticket) {
      const priority = ticket.priority;
      let priorityNumber = '';
      if (priority){
        priorityNumber = this.TICKET_PROPERTY.find( item => priority === item.value).value;
      }
      let result = {label: '', styleColor: ''}
      switch (priorityNumber){
        case 1 :
          result.label = 'Tiêu chuẩn';
          result.styleColor = 'color: #000000';
          break;
        case 2 :
          result.label = 'Ưu tiên';
          result.styleColor = 'color: #FF0B0B';
          break;
      }
      return result;
    },
    changePhongBanXuLy(){
      this.getListTicketCategories();
    },
    renderRate(ticket) {
      let result = this.FEEDBACK_TICKET_STAR.find(item => item.id === ticket.feedback_ticket_star);
      return result ? result.name : 'Chưa nhập liệu';
    }
  }
}
</script>
<style>
.bg-yellow {
  background-color: #FFC107;
}
.bg-red-error {
  background-color: red;
}
.bg-violet {
  background-color: #8950FC;
}
.bg-suc {
  background-color: #1BC5BD;
}
td {
  text-align: left !important;
}
</style>
