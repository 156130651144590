<template>
    <div>
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label fw-bold">Báo cáo cuộc gọi</h3>
            </div>
            <div>
                <el-button class="btn-success bg-success" v-loading="loadingExcel" @click="exportCallLogs">Export</el-button>
            </div>
        </div>
        <div class="card-body bg-white">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Thời gian </label>
                        <div>

                            <date-picker
                                @change="search()"
                                v-model="query.date_range"
                                type="date"
                                format="DD-MM-YYYY"
                                value-type="YYYY-MM-DD" range
                                placeholder="Chọn ngày tạo"
                                :default="new Date()"
                            ></date-picker>
                        </div>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="border-solid p-4 rounded">
                    <h4>Thống kê gọi đi</h4>
                    <div class="">
                        <div class="col-md-12 d-flex justify-content-center">
                            <div class="col-md-2 text-center ">
                                <div class=" bg-secondary px-1 rounded">
                                    <div class="pt-4">
                                        <img src="../../../../public/media/stringee/Group_green.png" class="h-125" />
                                    </div>
                                    <p class="pt-4">
                                        Cuộc gọi đi
                                    </p>
                                    <p class="pb-4">
                                        {{ callAway.total }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-2 text-center ">
                                <div class=" bg-secondary px-1 rounded">
                                    <div class="pt-4">
                                        <img src="../../../../public/media/stringee/Group_orange.png" class="h-125" />
                                    </div>
                                    <p class="pt-4">
                                      Tổng số khách hàng
                                    </p>
                                    <p class="pb-4">
                                      {{ callAway.totalCustomer }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-2 text-center ">
                                <div class=" bg-secondary px-1 rounded">
                                    <div class="pt-4">
                                        <img src="../../../../public/media/stringee/Group_blue.png" class="h-125" />
                                    </div>
                                    <p class="pt-4">
                                        Cuộc gọi thành công
                                    </p>
                                    <p class="pb-4">
                                        {{ callAway.callDone }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-2 text-center ">
                                <div class=" bg-secondary px-1 rounded">
                                    <div class="pt-4">
                                        <img src="../../../../public/media/stringee/Group_red.png" class="h-125" />
                                    </div>
                                    <p class="pt-4">
                                        Cuộc gọi thất bại
                                    </p>
                                    <p class="pb-4">
                                        {{ callAway.callFail }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-2 text-center ">
                                <div class=" bg-secondary px-1 rounded">
                                    <div class="pt-4">
                                        <img src="../../../../public/media/stringee/Group_blue_1.png" class="h-125" />
                                    </div>
                                    <p class="pt-4">
                                        Tốc độ trả lời trung bình
                                    </p>
                                    <p class="pb-4">
                                        {{ callAway.avgReplySpeed }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-2 text-center ">
                                <div class=" bg-secondary px-1 rounded">
                                    <div class="pt-4">
                                        <img src="../../../../public/media/stringee/Group_2446.png" class="h-125" />
                                    </div>
                                    <p class="pt-4">
                                        Thời gian xử lý trung bình
                                    </p>
                                    <p class="pb-4">
                                        {{ callAway.avgAnswerDuration }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="border-solid p-4 rounded mt-6">
                    <h4>Thống kê gọi đến</h4>
                    <div class="">
                        <div class="col-md-12 d-flex justify-content-center">
                            <div class="col-md-2 text-center ">
                                <div class=" bg-secondary px-1 rounded">
                                    <div class="pt-4">
                                        <img src="../../../../public/media/stringee/Group_green.png" class="h-125" />
                                    </div>
                                    <p class="pt-4">
                                        Cuộc gọi đến
                                    </p>
                                    <p class="pb-4">
                                        {{ incomingcall.total }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-2 text-center ">
                                <div class=" bg-secondary px-1 rounded">
                                    <div class="pt-4">
                                        <img src="../../../../public/media/stringee/Group_blue.png" class="h-125" />
                                    </div>
                                    <p class="pt-4">
                                        Cuộc gọi thành công
                                    </p>
                                    <p class="pb-4">
                                        {{ incomingcall.callDone }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-2 text-center ">
                                <div class=" bg-secondary px-1 rounded">
                                    <div class="pt-4">
                                        <img src="../../../../public/media/stringee/Group_red.png" class="h-125" />
                                    </div>
                                    <p class="pt-4">
                                        Cuộc gọi nhỡ
                                    </p>
                                    <p class="pb-4">
                                        {{ incomingcall.callFail }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-2 text-center ">
                                <div class=" bg-secondary px-1 rounded">
                                    <div class="pt-4">
                                        <img src="../../../../public/media/stringee/Group_blue_1.png" class="h-125" />
                                    </div>
                                    <p class="pt-4">
                                        Tốc độ trả lời trung bình
                                    </p>
                                    <p class="pb-4">
                                        {{ incomingcall.avgReplySpeed }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-2 text-center ">
                                <div class=" bg-secondary px-1 rounded">
                                    <div class="pt-4">
                                        <img src="../../../../public/media/stringee/Group_2446.png" class="h-125" />
                                    </div>
                                    <p class="pt-4">
                                        Thời gian xử lý trung bình
                                    </p>
                                    <p class="pb-4">
                                        {{ incomingcall.avgAnswerDuration }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {GET_CALL_STATISTICS, EXPORT_CALL_STATISTICS, GET_CUSTOMER_CARE_PHONE} from "../../../core/services/store/user/ticket.module";
import moment from "moment-timezone";
import DatePicker from 'vue2-datepicker';

export default {
    components: {
        DatePicker
    },
    name: "CallStatistics",
    data() {
        return {
            query: {
                date_range: [],
                branch_id: ''
            },
            callAway: [],
            incomingcall: [],
            userCustomerCarePhone: [],
            loadingExcel: false
        }
    },
    mounted() {
        this.query.date_range = [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')];
        this.getData();
        this.getCustomerCarePhone();
    },
    methods: {
        getData() {
            this.$store.dispatch(GET_CALL_STATISTICS, this.query).then((data) => {
                this.callAway = data.data.callAway;
                this.incomingcall = data.data.incomingcall;
            }).catch((error) => {
                console.log(error);
            })
        },
        getCustomerCarePhone() {
            this.$store.dispatch(GET_CUSTOMER_CARE_PHONE, {}).then((data) => {
                this.userCustomerCarePhone = data.data;
                console.log(this.userCustomerCarePhone, 'aaaa');
            }).catch((error) => {
                console.log(error);
            })
        },
        exportCallLogs() {
            let data_export = {
                sheet_one : [],
            };
            this.loadingExcel = true;
            this.$store.dispatch(EXPORT_CALL_STATISTICS, {
                ...this.query,
            }).then((res) => {
                data_export.sheet_one = res.data;
                this.loadingExcel = false;
            }).finally(() => {
                this.loadingExcel = false;
            });

        },
        pushParamsUrl() {
            this.$router.push({
                path: '', query: {
                page: this.page,
                ...this.query
                }
            })
        },
        search() {
            this.pushParamsUrl();
            this.getData();
        },
        disabledBeforeStartDate(date) {
            const today = moment().set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            });
            return moment(date) < today;
        },
    }
}
</script>

<style scoped>
    .border-solid {
        border: 1px solid;
    }
</style>