import ApiService from "@/core/services/api.service";

const _ = require('lodash');

export const GET_ALL_CATEGORY = "support/category/getAll";
export const GET_TYPE_BY_CATEGORY = "support/type/get-type-by-category";
export const GET_TYPE = "support/type/list";
export const CREATE_TYPE = "support/type/create";
export const UPDATE_TYPE = "support/type/update";
export const DELETE_TYPE = "support/type/delete";


const state = {};
const getters = {};
const actions = {
    [GET_ALL_CATEGORY](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${GET_ALL_CATEGORY}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_TYPE_BY_CATEGORY](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${GET_TYPE_BY_CATEGORY}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    // Get type
    [GET_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${GET_TYPE}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${CREATE_TYPE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${UPDATE_TYPE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_TYPE](context, id) {
        return new Promise(resolve => {
            ApiService.delete(DELETE_TYPE, id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
