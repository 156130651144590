<template>
  <el-checkbox v-model="checked"  @change="handleCheckSlot()"></el-checkbox>
</template>

<script>
import {TICK_OUT_OF_SLOT} from "@/core/services/store/rewards-programs/rewardsPrograms.module";

export default {
  name: "CheckBoxOutOfSlots",
  data(){
    return {
      checked : false
    }
  },
  props:{
    is_of_slot : {
      type: Boolean,
      default(){
        return false
      }
    },
    idClass : {
      type: Number,
      default(){
        return 1
      }
    }
  },
  mounted() {
    this.checked = this.is_of_slot
  },
  methods:{
    handleCheckSlot(){
      let payload = {
        is_of_slot : this.checked,
        spin_reward_program_classroom_id : this.idClass
      }
       this.$store.dispatch(TICK_OUT_OF_SLOT,payload).then(()=>{
         this.$emit('doneTickSlot')
       }).catch(()=>{

       })
    }
  }
}
</script>

<style scoped>

</style>