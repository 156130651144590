import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ErrorService from "@/core/services/ErrorService";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import Paginate from 'vuejs-paginate'
import { ValidationObserver, ValidationProvider, extend, localize,configure  } from 'vee-validate';
import vi from 'vee-validate/dist/locale/vi.json';
import * as rules from 'vee-validate/dist/rules'
import mixin from '../src/assets/js/components/common.js'
import * as filters from '@/core/filters'; // global filters
import VueQRCodeComponent from 'vue-qrcode-component'

// import MockService from "@/core/mock/mock.service";
import FormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG,SET_OLD_PATH } from "@/core/services/store/config.module";
import { Plugin } from 'vue-fragment';
import "@/core/plugins/element.io";
import "@/core/plugins/zingcharts";
import * as VueGoogleMaps from "vue2-google-maps";
import VTooltip from 'v-tooltip';
import CKEditor from '@ckeditor/ckeditor5-vue2';

extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined, []].indexOf(value) === -1
    };
  },
  computesRequired: true
});

Vue.use( CKEditor );
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCuuOaGlzi1Yt_auSBXBuGDlBjvZ8hcJQA",
    // key: "AIzaSyD81a1AzM4FAU7HUUxyy1mvOZDxMrmjKDU",
    libraries: "places"
  }
});
Vue.use(Plugin)
Vue.component('qr-code', VueQRCodeComponent)
// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
    dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
    // ...
  }
})

localize('vi', vi);

Vue.use(FormWizard);
Vue.use(VTooltip);
// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.config.productionTip = false;
// Handle all Vue errors
Vue.config.errorHandler = (error) => ErrorService.onError(error);
Vue.component('paginate', Paginate);



// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import VueEcho from 'vue-echo-laravel';

if (process.env.VUE_APP_SOCKET_URL) {
  // window.io = require("socket.io-client");
  // Vue.use(VueEcho, {
  //   broadcaster: 'socket.io',
  //   client: require('socket.io-client'),
  //   host: process.env.VUE_APP_SOCKET_URL, // host:window.location.hostname +':6001'
  // });
}
// // API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();
import VueFormulate from '@braid/vue-formulate'
import uploadPlugin from "./core/plugins/uploadToS3";
import FormulateImage from "./view/content/FormulateImage";
Vue.component('FormulateImage', FormulateImage);

Vue.use(VueFormulate, {
  plugins: [uploadPlugin],
  uploadUrl: `edutalk-option/upload`,
  library: {
    eduImage: {
      classification: 'file',
      component: 'FormulateImage',
      slotProps: {
        component: ['pos']
      }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('uuid');
  if (myParam) {
    localStorage.setItem("uuid-candidate-teacher", myParam)
  }
  // Ensure we checked auth before each page load.
  const { authorize } = to.meta;
  let currentUser = store.getters.currentUser;
  if(to.name ==='login' && currentUser.hasOwnProperty('user')){
    return next({ path: '/dashboard' });
  }
  if(to.name==='maintain') return next();
  if(authorize){
    if (!currentUser.token) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/login', query: { returnUrl: to.path } });
    }
    // check if route is restricted by role
    if (authorize !== 'ALL' && authorize.length && !currentUser.user.permission.includes(authorize)) {
      // role not authorised so redirect to home page
      return next({ path: '/dashboard' });
    }
  }else{
    if(to.name!=='logout' && to.name!=='login'){
      return next({ path: '/dashboard' });
    }
  }
  if(to.name !=='login' ){
      store.dispatch(SET_OLD_PATH,to.path);
  }

  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
Vue.mixin(mixin);
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

