<template>
  <div>
    <b-modal
        @ok="handleOk"
        centered id="tester-calendar-modal"
        size="xl"
        title="Lịch đi làm"
        ok-title="Sửa lịch"
        cancel-title="Hủy"
        :ok-disabled="okDisable"
        ok-only
        @hidden="resetModal"
    >
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" autocomplete="off">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Nhân sự Admin<span class="text-danger">*</span></label>
                  <el-input disabled :value="`${tester.name} - ${tester.phone}`"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group ">
                  <label class="d-block">Tuần làm việc<span class="text-danger">*</span></label>
                  <el-date-picker
                      v-model="query.date_week"
                      type="week"
                      placeholder="Chọn tuần"
                      :format="dateRange"
                      :picker-options="{firstDayOfWeek : 1}"
                      clearable
                      @input="getCalendar"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <p class="text-info font-weight-light font-italic">Cập nhật lần cuối lúc
                {{ formatTime(Date.parse(updated_at_last)) }}</p>
            </div>
            <div class="row">
              <calendar-item-only :item_prop="calendar" :days="days()"></calendar-item-only>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  TEST_INPUT_GET_CALENDAR,
  TEST_INPUT_GET_TESTER_BY_USER_ID, TEST_INPUT_SAVE_CALENDAR,
  TEST_INPUT_UPDATE_TASK
} from "@/core/services/store/test/testInput.module";
import {TIME_PICKER_OPTIONS, TYPE_TASK} from "@/core/option/testInputOption";
import moment from "moment-timezone";
import CalendarItem from "@/view/pages/test-input/components/CalendarItem";
import CalendarItemOnly from "@/view/pages/test-input/components/CalendarItemOnly";
import {daysOfWeek} from "@/core/option/dayOption";

export default {
  name: "TesterCalendarModal",
  components: {CalendarItemOnly, CalendarItem, DatePicker},
  props: {
    tester: {
      type: Object,
      default: () => {
        return null;
      }
    },
    isOpenTesterCalendar: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  data() {
    return {
      //setup
      updated_at_last: null,
      calendar: null,
      showTimePanel: false,
      showTimeRangePanel: false,
      okDisable: false,
      search_loading: false,
      fileList: [],
      type_task: TYPE_TASK,
      testers: [],
      schedule: {
        type_task: '',
        tester_id: '',
        schedule: '',
        end_schedule: '',
      },
      query: {
        date_week: moment().startOf('isoWeek'),
      },
      time_picker_options: TIME_PICKER_OPTIONS,
      daysOfWeek: daysOfWeek,
    }
  },
  watch: {
    isOpenTesterCalendar: {
      handler() {
        this.query.date_week = moment().startOf('isoWeek')
        this.getCalendar();
      },
      deep: true
    }
  },
  mounted() {
  },
  methods: {
    days() {
      let result = [];
      if (this.query.date_week == null) {
        this.query.date_week = moment().startOf('isoWeek');
      }
      let startWeek = moment(this.query.date_week).startOf('isoWeek');
      for (let i = 0; i < 7; i++) {
        let tomorrow = startWeek.clone().add(i, 'days');

        result.push({
          day: tomorrow.clone().format('DD-MM-YYYY'),
          dayOfWeek: this.daysOfWeek[tomorrow.clone().day()]
        })
      }
      return result;
    },
    resetModal() {
      this.query.date_week = moment().startOf('isoWeek')
      this.$emit("closeTesterCalendar")
    },
    formatTime(time) {
      const format = "HH:mm DD-MM-YYYY"
      return moment(time).format(format)
    },
    getCalendar() {
      let startWeek = moment(this.query.date_week).startOf('isoWeek');
      let endWeek = moment(this.query.date_week).endOf('isoWeek');
      startWeek = startWeek.format('YYYY-MM-DD');
      endWeek = endWeek.format('YYYY-MM-DD');
      this.$store.dispatch(TEST_INPUT_GET_CALENDAR, {
        date: [startWeek, endWeek],
        tester_id: this.tester.id
      }).then((data) => {
        this.calendar = data?.data? data.data : null;
        this.updated_at_last = data.data.updated_at_last;
      }).catch((e) => {
        this.$message.error(e.data.message);
      }).finally(() => {
        this.is_disable_search = false;
      })
    },
    saveCalendar() {
      this.$store.dispatch(TEST_INPUT_SAVE_CALENDAR, {
        calendars: this.calendar.data,
        tester_id: this.tester.id
      }).then((data) => {
        this.$message.success(data.message);
        this.is_show = true;
        this.closeModal();
      }).catch((error) => {
        console.log(error.data)
				if (error && error.data) {
					this.$message.error(error.data.message);
				}
        this.getCalendar()
      }).finally(()=>{

      });
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.saveCalendar();
    },
    callApi() {
      this.okDisable = true;
      this.$store.dispatch(TEST_INPUT_UPDATE_TASK, this.schedule).then((data) => {
        this.okDisable = false;
        this.$message.success(data.message);
        this.$emit('create-success');
        this.closeModal();
      }).catch((e) => {
        this.okDisable = false;
        if (e.response.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.response.data.data.message_validate_form);
        }
      });
    },
    // Set phòng ban xử lý
    closeModal() {
      this.$bvModal.hide('tester-calendar-modal');
    },
    remoteMethod(query) {
      this.search_loading = true;
      this.$store.dispatch(TEST_INPUT_GET_TESTER_BY_USER_ID, {
        keyword: query
      }).then((data) => {
        this.search_loading = false;
        this.testers = data.data;
      })
      if (query !== '') {
        this.search_loading = true;
        setTimeout(() => {
          this.search_loading = false;
          this.options = this.list.filter(item => {
            return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
  },
  computed: {
    dateRange() {
      let startWeek = moment(this.query.date_week).startOf('isoWeek');
      let endWeek = moment(this.query.date_week).endOf('isoWeek');
      return startWeek.format('DD/MM/YYYY') + ' - ' + endWeek.format('DD/MM/YYYY')
    },
  }
}
</script>

<style scoped>
.el-select {
  width: 100%;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}
</style>