<template>
  <div class="container">
    <el-form :model="formData" ref="rateForm" :rules="rules" label-position="top" label-width="100px">
      <el-form-item label="Thay đổi trạng thái trả góp:" prop="installment_method_id">
        <el-select v-model="formData.installment_method_id" placeholder="Chọn trạng thái trả góp" class="mt-2">
          <el-option
              v-for="item in tuitionPayments"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="showDisable(item)"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Nội dung:" prop="reason">
        <el-input type="textarea"
                  maxlength="3000"
                  show-word-limit
                  placeholder="Nhập nội dung tại đây..."
                  class="mt-2"
                  :rows="5"
                  v-model="formData.content"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Ảnh/video:">
        <upload-file @emitUpdateFile="uploadFileSuccess"></upload-file>
      </el-form-item>
      <div class="d-flex justify-content-center">
        <el-button :loading="loading" class="btn btn-primary" @click="onSubmit">Cập nhật</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import UploadFile from "@/view/pages/feedback/UploadFile";
import ErrorMessage from "@/view/pages/common/ErrorMessage";
import {CREATE_INSTALLMENT_INFORMATION_LOG} from "@/core/services/store/installment/installment.module";
export default {
  name: "ChangeInstallmentMethod",
  components: {ErrorMessage, UploadFile},
  props: {
    installment: {
      type: Object,
      default: () => { return {} }
    },
    tuitionPayments: {
      type: Array,
      default: () => { return [] }
    },
  },
  data(){
    return{
      formData: {
        reason: '',
        files: [],
        installment_method_id: ''
      },
      rules: {
        reason: [
          { max: 3000, message: 'Nội dung không quá 3000 kí tự', trigger: 'blur' }
        ],
        installment_method_id: [
          { required: true, message: 'Trạng thái trả góp không được để trống', trigger: 'blur' }
        ],
      },
      loading: false,
    }
  },
  watch: {
    installment: {
      handler() {
        this.formData.installment_method_id = this.installment.installment_method_id ? Number(this.installment.installment_method_id) : '';
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onSubmit(){
      this.$refs.rateForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let formData = new FormData();
          for (let formDataKey in this.formData) {
            if (formDataKey === 'files'){
              this.formData.files.forEach((item) => {
                formData.append('attach_files[]', item.raw);
              })
            }
            formData.append(formDataKey, this.formData[formDataKey]);
          }
          let payload = {
            id: this.installment.id,
            contract_id: this.installment.contract_id,
            params: formData
          };
          this.$store.dispatch(CREATE_INSTALLMENT_INFORMATION_LOG, payload).then((res) => {
            this.$emit('successUpdate', res.data);
            this.noticeMessage('success', 'Thành công', 'Nhập giải pháp thành công');
          }).catch((e) => {
            this.noticeMessage('error', 'Thất bại', e?.data?.message);
          }).finally(() => {
            this.loading = false;
          })
        } else {
          return false;
        }
      });
    },
    uploadFileSuccess(fileList) {
      this.formData.files = fileList;
    },
    showDisable(item) {
      return [1, 2, 8].includes(item.id);
    }
  }
}
</script>

<style scoped>

</style>