import ApiService from "@/core/services/api.service";

const _ = require('lodash');

export const GET_CATEGORY = "support/category/list";
export const CREATE_CATEGORY = "support/category/create";
export const UPDATE_CATEGORY = "support/category/update";
export const DELETE_CATEGORY = "support/category/delete";

const state = {};
const getters = {};
const actions = {
    // Get category
    [GET_CATEGORY](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${GET_CATEGORY}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_CATEGORY](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${CREATE_CATEGORY}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_CATEGORY](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${UPDATE_CATEGORY}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_CATEGORY](context, id) {
        return new Promise(resolve => {
            ApiService.delete(DELETE_CATEGORY, id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
