<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Quản lý đào tạo</h3>
            </div>
            <div class="card-title">

            </div>
        </div>
        <div class="card-body">
            <div>
                <div class="">
                    <div class="col-md-4 mb-4 p-0">
                        <label>Tên chương trình</label>
                        <input type="text" v-model="query.name" class="form-control col-md-6" @input="search" placeholder="Nhập chương trình">
                    </div>
                </div>
            </div>
            <div class="example mb-10">
                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col" class="min-w-100px">Tên chương trình</th>
                            <th scope="col" class="min-w-100px">Khóa học</th>
                            <th scope="col" class="min-w-50px max-w-40px">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in listCourse" :key="index">
                            <td>{{ item.id }}</td>
                            <td>{{ item.title }}</td>
                            <td>
                                <p v-for="(item_prop, index_prop) in item.courses" :key="index_prop">
                                    {{ item_prop.title }}
                                </p>
                            </td>
                            <td>
                                <button class="btn-primary btn d-flex justify-content-center"
                                        @click="updateMangeCourse(item)">
                                    <i class="el-icon-s-tools"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex justify-content-end">
                    <span>Tổng số bản ghi : {{total}}</span>
                </div>
            </div>
            <div>
                <paginate
                    v-model="query.page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :page-class="'page-item'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                >

                </paginate>
            </div>
        </div>
        <el-dialog title="Phân quyền chương trình học" :visible.sync="editVisible">
            <el-dialog
                title="Inner Dialog"
                :visible.sync="innerVisible"
                append-to-body>
            </el-dialog>
            <ValidationObserver v-slot="{ handleSubmit }" ref="form" >
                <form class="form" autocomplete="off" @submit.prevent="handleSubmit(handleOk)">
                    <div>
                        <div>
                            <label for="">Tên chương trình</label>
                            <input type="button" disabled value="" class="form-control text-left" v-model="form.name">
                        </div>
                        <div>
                            <label for="">Vị trí</label>
                            <el-select class="w-100" v-model="form.account_type"
                                       @change="getBusiness(form.account_type)"
                                       multiple
                            >
                                <el-option v-for="(item, index) in listAccountType"
                                           :key="index"
                                           :label="item.name"
                                           :value="item.id"
                                >
                                    <span>{{ item.name }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <div>
                            <label for="">Vị trí kiêm nghiệm</label>
                            <el-select class="w-100" v-model="form.position" multiple @change="changePosition">
                                <el-option v-for="(item, index) in positions"
                                            :key="index"
                                            :label="item"
                                            :value="item"
                                >
                                    <span>{{ item }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <div v-if="checkShowLocation || (form.businessLevel && form.businessLevel.length)">
                            <ValidationProvider name="Cấp bậc" rules="required" v-slot="{ errors,classes }" vid="withdraw_course">
                            <label for="">Cấp bậc </label>
                            <el-select class="w-100" v-model="form.businessLevel" multiple @change="changePosition">
                                <el-option v-for="(item, index) in businessLevel"
                                           :key="index"
                                           :label="item.account_type ? item.account_type.name + ' ' + item.name : ''"
                                           :value="item.id"
                                >
                                    <span>{{ item.account_type ? item.account_type.name + ' ' + item.name : '' }}</span>
                                </el-option>
                            </el-select>
                            <div class="fv-plugins-message-container">
                                <div data-field="withdraw_course" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                            </div>
                            </ValidationProvider>
                        </div>
                        <div v-if="checkAreaType">
                            <ValidationProvider name="Loại khu vực" rules="required" v-slot="{ errors,classes }" vid="withdraw_course">
                            <label for="">Loại khu vực</label>
                            <el-select class="w-100" v-model="form.areaType" @change="getAreaByType(form.areaType, 'changeEvent')"
                                       >
                                <el-option v-for="(item, index) in areaType"
                                           :key="index"
                                           :label="item.name"
                                           :value="item.id"
                                >
                                    <span>{{ item.name }}</span>
                                </el-option>
                            </el-select>
                            <div class="fv-plugins-message-container">
                                <div data-field="withdraw_course" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                            </div>
                            </ValidationProvider>
                        </div>
                        <div v-if="checkArea || (form.area && form.area.length)">
                            <ValidationProvider name="Khu vực" rules="required" v-slot="{ errors,classes }" vid="withdraw_course">
                            <label for="">Khu vực</label>
                            <el-select class="w-100" v-model="form.area" multiple @change="changeArea">
                                <el-option v-for="(item, index) in listArea"
                                           :key="index"
                                           :label="item.name"
                                           :value="item.id"
                                >
                                    <span>{{ item.name }}</span>
                                </el-option>
                            </el-select>
                            <div class="fv-plugins-message-container">
                                <div data-field="withdraw_course" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                            </div>
                            </ValidationProvider>
                        </div>
                        <div v-if="checkCandidate || (form.typeCandidate && form.typeCandidate.length)">
                            <ValidationProvider name="Loại ứng viên" rules="required" v-slot="{ errors,classes }" vid="withdraw_course">
                            <label for="">Loại ứng viên</label>
                            <el-select class="w-100" v-model="form.typeCandidate" >
                                <el-option v-for="(item, index) in listTypeCandidate"
                                           :key="index"
                                           :label="item.name"
                                           :value="item.id"
                                >
                                    <span>{{ item.name }}</span>
                                </el-option>
                            </el-select>
                            <div class="fv-plugins-message-container">
                                <div data-field="withdraw_course" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                            </div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div slot="footer" class="dialog-footer d-flex justify-content-end mt-2">
                        <button v-loading="loading" type="submit" class="violet text-white p-2 rounded">Cập nhật
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </el-dialog>
    </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
    GDKV, GDVP,
    GET_ACCOUNT_TYPE_FOR_PROGRAM,
    SALE_LEAD,
    SALE_MEMBER,
    UNG_VIEN,
} from "@/core/services/store/user/account-type.module";
import {
    CREATE_MANAGE_PROGRAM_COURSE,
    GET_BUSINESS_LEVEL,
    GET_LIST_MANAGE_COURSE, GET_PROGRAM_COURSE
} from "@/core/services/store/business/level.module";
import {GET_AREA_BY_TYPE} from "@/core/services/store/center/area.module";
import {GET_AREAS} from "@/core/services/store/center/area.module";

export default {
    name: "ManageCourse",
    data() {
        return {
            editVisible: false,
            innerVisible: false,
            form: {
                program_id: '',
                name: '',
                account_type: '',
                position: '',
                areaType: '',
                area: '',
                typeCandidate: '',
                businessLevel: ''
            },
            listAccountType: [],
            listCourse: [],
            businessLevel: [],
            areaType: [
                {
                    id: 10,
                    name: 'Chọn tất cả'
                },
                {
                    id: 1,
                    name: 'Trung tâm'
                },
                {
                    id: 2,
                    name: 'Tỉnh'
                }
            ],
            listArea: [],
            listTypeCandidate: [
                {
                    id: 10,
                    name: 'Chọn tất cả'
                },
                {
                    id: 0,
                    name: 'Sinh viên/Người đi làm'
                },
                {
                    id: 1,
                    name: 'Học sinh'
                },
            ],
            positions: ['TPOnline', 'TVV', 'Trainer'],
            checkShowLocation: false,
            checkTypeArea: [],
            query: {
                name: '',
                page: 1,
            },
            last_page: 1,
            checkCandidate: false,
            checkAreaType: false,
            checkArea: false,
            total: '',
            all: {
                id: -1,
                account_type: {
                    name: "Chọn tất cả"
                },
                name: ''
            },
            pickAll: {
                id: -1,
                name: "Chọn tất cả"
            },
            loading: false
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý đào tạo", route: 'policy-manage'},
            {title: "Quản lý phân quyền chương trình học"}
        ]);
        this.getAccountType();
        this.getCourse();
    },
    methods: {
        getCourse() {
            this.$store.dispatch(GET_LIST_MANAGE_COURSE, this.query).then((res) => {
                this.listCourse = res.data.data;
                this.last_page = parseInt(res.data.last_page);
                this.total = res.data.total;
            }).catch((e) => {

            });
        },
        clickCallback(obj) {
            this.page = obj
            this.$router.push({
                name: 'policy-manage',
                query: {
                    keyword: this.query.name,
                    page: this.query.page,
                }
            })
            this.getCourse()
        },
        getAccountType() {
            this.$store.dispatch(GET_ACCOUNT_TYPE_FOR_PROGRAM, {}).then((res) => {
                this.listAccountType = res.data;
            }).catch((e) => {

            });
        },
        getBusiness(account_type_id) {
            this.checkShowLocation = false;
            this.checkCandidate = false;
            this.checkAreaType = false;
            this.checkArea = false;
            if (!Object.values(account_type_id).length) {
                this.checkShowLocation = false;
                this.checkCandidate = false;
                this.checkAreaType = false;
                this.businessLevel = [];
                this.form.businessLevel = [];
                this.form.area = [];
                return;
            }

            if (account_type_id.indexOf(SALE_LEAD) >= 0 || account_type_id.indexOf(SALE_MEMBER) >= 0 || account_type_id.indexOf(GDKV) >= 0 || account_type_id.indexOf(GDVP) >= 0 ) {
                this.checkAreaType = true;
                this.checkShowLocation = true;
                this.checkArea = true;
                this.form.businessLevel = this.form.businessLevel.includes(-1) ? [-1] : this.form.businessLevel;
                this.form.areaType = this.form.areaType ? this.form.areaType : 10;
                this.getAreaByType(this.form.areaType);
                this.form.area = this.form.area.includes(-1) ? [-1] : this.form.area;
            } else {
                this.checkAreaType = false;
                this.checkShowLocation = false;
            }
            if (account_type_id.indexOf(UNG_VIEN) >= 0  && Object.values(account_type_id).length) {
                this.checkCandidate = true;
                this.checkAreaType = true;
                this.checkArea = false;
                this.form.area = this.form.area && Object.values(this.form.area).length ? this.form.area : [];
                this.form.areaType = this.form.areaType ? this.form.areaType : 10;
                this.form.typeCandidate = this.form.typeCandidate ?? 10;
            } else {
                this.checkCandidate = false;
                this.form.typeCandidate = '';
            }
            this.$store.dispatch(GET_BUSINESS_LEVEL, {
                account_type_ids: account_type_id,
                all: true
            }).then((res) => {
                this.businessLevel = res.data;
            }).catch((e) => {

            });
        },
        getAreaByType(type, handleEvent=null) {
            if (handleEvent == 'changeEvent') {
                this.form.area = []
            }
            if (!type) {
                this.checkArea = false;
                return;
            }
            this.checkArea = true;
            this.listArea = [];

            this.$store.dispatch(GET_AREA_BY_TYPE, {type: type, all:true}).then((res) => {
                this.listArea = res;
            }).catch((e) => {
            });
        },
        changePosition() {
            this.checkShow = true;
            if ((this.form.businessLevel && this.form.businessLevel[this.form.businessLevel.length -1] == -1)) {
                this.form.businessLevel = [-1];
            } 
            if(this.form.businessLevel && this.form.businessLevel[0] == -1 && this.form.businessLevel.length > 1) {
                var index = this.form.businessLevel.indexOf(-1);
                if (index !== -1) {
                    this.form.businessLevel.splice(index, 1);
                }
            }
        },
        handleOk() {
            this.loading = true;
            let dataForm = this.form;
            let business = this.businessLevel;
            let listArea = this.listArea;

            let business_id = [];
            let area_id = [];

            if (dataForm.businessLevel && dataForm.businessLevel.indexOf(-1) >= 0) {
                business_id = this.pluckId(business);
                dataForm.businessLevel = business_id;
            }

            if (dataForm.area && dataForm.area.indexOf(-1) >= 0) {
                area_id = this.pluckId(listArea);
                let a = this.removeData(area_id)
                dataForm.area = a;
            }

            this.$store.dispatch(CREATE_MANAGE_PROGRAM_COURSE, dataForm).then((res) => {
                this.editVisible = false;
                this.loading = false;
              this.noticeMessage( 'success','Thành công', 'Cập nhật thành công');
            }).catch((e) => {

            });
        },
        pluckId(data) {
            return data.map(item => { return item.id });
        },
        removeData(data) {
            const index = data.indexOf(-1);
            if (index > -1) {
                data.splice(index, 1);
                return data;
            }
        },
        updateMangeCourse(item) {
            this.$store.dispatch(GET_PROGRAM_COURSE, {program_id: item.id}).then((res) => {
                if (!Object.values(res.data).length) {
                    this.checkShowLocation = false;
                    this.checkAreaType = false;
                }
                this.form.name = res.data.name ?? item.title;
                this.form.account_type = res.data?.account_type_id;
                this.form.position = res.data?.position_name;
                this.form.businessLevel = res.data?.business_level_id;
                this.form.area = res.data?.area_id;
                this.form.typeCandidate = res.data?.type_candidate;
                this.form.areaType = res.data?.area_type;
                this.changePosition();
                this.getBusiness(this.form.account_type);
                this.getAllArea();
          
            }).catch((e) => {
            });

            this.form.program_id = item.id;
            this.editVisible = true;
        },
        getAllArea() {
            let arr = [];
            this.$store.dispatch(GET_AREAS, {}).then((res) => {
                this.listArea = res.data.data;
                this.listArea.push(this.pickAll);
            }).catch((e) => {

            });
        },
        search() {
            this.getCourse();
        },
        changeArea() {
            if ((this.form.area && this.form.area[this.form.area.length -1] == -1)) {
                this.form.area = [-1];
            } 
            if(this.form.area && this.form.area[0] == -1 && this.form.area.length > 1) {
                var index = this.form.area.indexOf(-1);
                if (index !== -1) {
                    this.form.area.splice(index, 1);
                }
            }
        }
    }
}
</script>

<style scoped>
.violet {
    background-color: #5e35b1;
}
</style>