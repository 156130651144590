<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
          Danh mục
      </div>
      <div class="card-title">
          <button
              class="font-weight-bold font-size-3  btn btn-success"
              @click="addnew"
            >Thêm mới
          </button>
      </div>
    </div>

    <div class="card-body">
      <div class="example mb-10">
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
              <tr>
                <th>STT</th>
                <th>Name</th>
                <th>Order</th>
                <th>Thời gian tạo</th>
                <th style="min-width: 100px">Hành động</th>
              </tr>
            </thead>
            <tbody v-if="data.length > 0" >
              <tr v-for="(item, index) in data" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.order }}</td>
                <td>{{ item.created_at | formatDateTimeAsia }}</td>
                <td>
                    <a title="Chỉnh sửa" class="btn btn-icon mr-2 btn-outline-info" @click="edit(index, item)" href="javascript:">
                                    <i class="fas fa-pen-nib"></i>
                                </a>
                    <a title="Xóa" @click="deleteCategory(item.id)" href="javascript:"
                        class="btn btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
                <paginate
                        v-model="page"
                        :page-count="total_pages"
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="getListCategory"
                        :prev-text="'Trước'"
                        :next-text="'Sau'"
                        :container-class="'pagination b-pagination'"
                        :pageLinkClass="'page-link'"
                        :page-class="'page-item'"
                        :next-link-class="'next-link-item'"
                        :prev-link-class="'prev-link-item'"
                        :prev-class="'page-link'"
                        :next-class="'page-link'"
                >
                </paginate>
            </div>
    
    <el-dialog :title="form_add ? 'Thêm mới danh mục' : 'Chỉnh sửa danh mục'" :visible.sync="checkVisibleAddEditForm">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" id="room_create" @submit.prevent="handleSubmit(form_add ? create : update )">
            <div class="card card-custom">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Tên danh mục
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider vid="name" name="Tên" rules="required"
                                                    v-slot="{ errors,classes }">
                                    <input type="text" ref="name" name="name" class="form-control"
                                           v-on:input="changeName"
                                           v-model="form.name"
                                           placeholder="Nhập tên danh mục" :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <div>Icon
                                    <span class="text-danger">*</span></div>
                        <div class="image-input image-input-outline" id="kt_profile_avatar">
                                            <div
                                                    class="image-input-wrapper"
                                                    :style="{ backgroundImage: `url(${photo})` }"
                                            ></div>
                                            <label
                                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="change"
                                                    data-toggle="tooltip"
                                                    title=""
                                                    data-original-title="Change avatar"
                                            >
                                                <i class="fa fa-pen icon-sm text-muted"></i>
                                                <input
                                                        type="file"
                                                        name="profile_avatar"
                                                        accept=".png, .jpg, .jpeg"
                                                        v-on:input="onFileChange($event)"
                                                />
                                                <input type="hidden" name="profile_avatar_remove" />
                                            </label>
                                            <span
                                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="cancel"
                                                    data-toggle="tooltip"
                                                    title="Cancel avatar"
                                            >
                                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                                    </span>
                                            <span
                                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="remove"
                                                    data-toggle="tooltip"
                                                    title="Remove avatar"
                                                    @click="current_photo = null"
                                            >
                                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                                    </span>
                                        </div>
                                        <div v-if="!current_photo"  class="fv-plugins-message-container">
                                          <div class="fv-help-block">Icon là bắt buộc</div>
                                        </div>
                            </div>
                        </div>
                                        <span class="d-flex align-items-center form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Order
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider vid="folder_name" name="Order" rules="required"
                                                    v-slot="{ errors,classes }">
                                    <input type="number" step="any" ref="Order" name="folder_name" class="form-control" min="0"
                                           v-model="form.order"
                                           placeholder="Nhập order" :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="folder_name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-header">
                    <div class="card-title align-items-start flex-column  float-right">
                        <button
                                type="submit"
                                class="btn btn-success mr-2 float-right"
                        >
                            {{ form_add ? 'Tạo mới' : "Chỉnh sửa" }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
      <div slot="footer" class="dialog-footer">
      </div>
  </el-dialog>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
  import { GET_CATEGORY, UPDATE_CATEGORY , CREATE_CATEGORY,DELETE_CATEGORY}
    from '../../../core/services/store/support/category.module';
  import Swal from "sweetalert2";


  export default {
    name: "SupportCategory",

    components: {
    },
    data() {
      return {
        page: 1,
        total_pages:0,
        data: [],
        files: [],
        checkVisibleAddEditForm: false,
        form: {
          name: ''
        },
        form_add: false,
        default_photo: "media/users/blank.png",
        current_photo: null,
        default_photo_row: null,
      }
    },

    mounted() {
        this.getListCategory();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Hỗ trợ"},
            {title: "Quản lý danh mục"}
        ]);
    },
    computed: {
            photo() {
                return this.current_photo ? this.current_photo : this.default_photo;
            }
    },
    methods: {
      changeName(){
        this.form.slug = this.removeAccents(this.form.name);
      },
      removeAccents(str) {
        str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return str.replaceAll(' ','-');
      },
      onFileChange(e) {
                const file = e.target.files[0];
                if (typeof FileReader === "function") {
                    const reader = new FileReader();
                    reader.onload = event => {
                        this.current_photo = event.target.result;
                    };
                    reader.readAsDataURL(file);
                    this.image_name = file.name;
                    this.image_type = file.type;
                } else {
                    alert("Sorry, FileReader API not supported");
                }
      },
      getListCategory() {
        this.$store.dispatch(GET_CATEGORY, {
            page: this.page
        }).then((res) => {
            this.data = res.data.data;
            this.total_pages = res.data.last_page;
        });
      },

      edit(index, item) {
          this.default_photo_row = item.gallery_files;
          this.current_photo = item.gallery_files
          this.form_add = false;
          this.form = item
          this.checkVisibleAddEditForm = true;
          this.getListCategory();
      },

      addnew() {
        this.current_photo = '';
        this.form = {}
        this.checkVisibleAddEditForm = true;
        this.form_add = true
      },
      deleteCategory(id){
            Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire("Xóa", "", "success");
                        this.$store.dispatch(DELETE_CATEGORY, id).then((res) => {
                            if (res.error) {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'danger',
                                    solid: true
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'success',
                                    solid: true
                                });
                                this.getListCategory();
                            }
                        });
                    }
                });
      },
      async create() {
        if(this.current_photo) {
            let formData = new FormData();
            let avatar = this.photo;

                function urltoFile(url, filename, mimeType) {
                    return (fetch(url)
                            .then(function (res) {
                                return res.arrayBuffer();
                            })
                            .then(function (buf) {
                                return new File([buf], filename, {type: mimeType});
                            }).catch((error) => {
                                return 1;
                            })
                    );
                }

                if (avatar.search('https') === -1) {
                    let file = await urltoFile(avatar, this.image_name, this.image_type);
                    if (file !== 1 && file.name !== 'null') {
                        formData.append('icon', file);
                    }
                }
                formData.append('name', this.form.name);
                formData.append('slug', this.form.slug);
                formData.append('order', this.form.order);

          this.$store.dispatch(CREATE_CATEGORY, formData).then((res) => {
            this.$notify({
              title: 'Thành công',
              message: 'Thêm mới danh mục thành công',
              type: 'success'
            });
            this.getListCategory();
            this.checkVisibleAddEditForm = false;
          }).catch((e) => {
            this.$notify({
            title: 'Thất bại',
            message: e.data.message,
            type: 'success'
          });
          });
        }
      },

      async update() {
        if(this.current_photo) {
        let formData = new FormData();
            if(this.photo !== this.default_photo_row ) {
              let avatar = this.photo;
                function urltoFile(url, filename, mimeType) {
                    return (fetch(url)
                            .then(function (res) {
                                return res.arrayBuffer();
                            })
                            .then(function (buf) {
                                return new File([buf], filename, {type: mimeType});
                            }).catch((error) => {
                                return 1;
                            })
                    );
                }

                if (avatar.search('https') === -1) {
                    let file = await urltoFile(avatar, this.image_name, this.image_type);
                    if (file !== 1 && file.name !== 'null') {
                        formData.append('icon', file);
                    }
                }
            }
              formData.append('id', this.form.id);
                formData.append('name', this.form.name);
                formData.append('slug', this.form.slug);
                formData.append('order', this.form.order);
        try {
          this.$store.dispatch(UPDATE_CATEGORY, formData).then((res) => {
            this.$bvToast.toast('Sửa danh mục thành công', {
                title: 'Chỉnh sửa',
                variant: 'success',
                solid: true
            });
            this.getListCategory();
            this.checkVisibleAddEditForm = false;
          });
        } catch (error) {
          console.log("error", error)
        }
      }
      }
    }
  }

</script>
