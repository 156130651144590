<script>
export default {
  name: "ResultTestDetailChild",
  props:{
    result:{
      type:Object,
    }
  }

}
</script>

<template>
<div>
  <header class="d-lg-flex justify-content-between d-md-block">
    <div class="d-flex">
      <div class="circle" style="position: relative">
       <img  alt=""  src="../../../../../assets/img/imgChild/Vocabulary.png" style="position: absolute;top: 4px;left: 5px">
      </div>
      <p style="font-size: 13px;font-weight: bold;color: #545454;margin-left: 6px">
        {{result.name}}
      </p>
    </div>
    <div class="d-flex">
      <span>Evaluation (Đánh giá):</span>
      <el-rate :value="result.start" :disabled="true"></el-rate>
    </div>
  </header>
  <div class="card"></div>
  <div class="mt-3">
    <p style="color: #545454;font-weight: bold;">Nhận xét:</p>
    <p style="white-space: pre-line">{{result.comment}}</p>
  </div>
</div>
</template>

<style scoped>
.circle {
  width: 20px;
  height: 20px;
  background-color: #013878;
  border-radius: 50%; /* Điều này làm cho div trở thành hình tròn */
}
</style>