import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "../auth.module";

const _ = require('lodash');

export const GET_INCOMES = "get-incomes";
export const GET_TESTERS = "get-testers";
export const DELETE_INCOME = "delete-testIncome";
export const DELETE_TESTER = "delete-tester";
export const CANCEL_INCOME = "cancel-testIncome";
export const CREATE_INCOME = "create-testIncome";
export const CREATE_TESTER = "create-tester";
export const UPDATE_TESTER = "update-tester";
export const SHOW_TESTER = "show-tester";
export const GET_INCOME_BY_ID = "get-testIncome-by-id";
export const UPDATE_INCOME = "update-testIncome";
export const GET_INCOME_EXAM_BY_ID = "get_income_exam_by_id";
export const FINISH_MARK_POINT = "finish_mark_point";
export const CHOOSE_TEST = "choose_test";
export const STORE_MARK = "store_mark";
export const UPDATE_COMMENT_COURSE_SUGGEST = "update_comment_course_suggest";


export const CHECK_TASK_BY_DAY_LEAVE = "test-input/check-task-by-day-leave"
export const ASSIGN_TASK = "test-input/assign-task"
export const AUTO_ASSIGN_TEST_INPUT = "test-input/auto-assign-test-input"
//mutations types
export const SET_INCOME = "setIncome";
export const SET_TESTER = "setTester";
export const UPDATE_TESTER_LIST = "update_tester_list";
//


const state = {
    errors: null,
    testIncomes: [],
    testers: [],
    testIncomePaginateData: [],
};

const getters = {
    getIncomesPaginate(state) {
        return state.userPaginatedData;
    },
    getIncomes(state) {
        return state.testIncomes;
    },
    getTesters(state) {
        return state.testers;
    },
};

const actions = {
    /**
     * api lấy danh sách test đầu vào
     * @param context
     * @param credentials
     * @returns {Promise<unknown>}
     */
    [GET_INCOMES](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('testIncome', credentials)
                .then(({data}) => {
                    context.commit(SET_INCOME, data.data)
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
    /**
     * api lấy danh sách tester
     * @param context
     * @param credentials
     * @returns {Promise<unknown>}
     */
    [GET_TESTERS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query('testTester', credentials)
                .then(({data}) => {
                    context.commit(SET_TESTER, data.data)
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },

    [GET_INCOME_BY_ID](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get('testIncome', id).then(({data}) => {
                resolve(data);
            }).catch(({error}) => {
                reject(error);
            });
        })
    },

    [GET_INCOME_EXAM_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('testIncomeExam', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    /**
     * api cập nhật test đầu vào
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [FINISH_MARK_POINT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('finish-mark-point', payload).then(({data}) => {
                // let w = window.open()
                // w.document.write(data);
                // w.document.close()
                // w.setTimeout(function () {
                //     w.print()
                // }, 1000)
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    /**
     * api sửa test đầu vào
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [UPDATE_INCOME](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('testIncome', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    /**
     * api tạo test đầu vào
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [CREATE_INCOME](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('testIncome', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    /**
     * api tạo tester
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [CREATE_TESTER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('testTester', payload).then(({data}) => {
                resolve(data);
                context.commit(UPDATE_TESTER_LIST, data.data)
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    /**
     * api show tester
     * @param context
     * @param id
     * @returns {Promise<unknown>}
     */
    [SHOW_TESTER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get('testTester', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    /**
     * api cập nhật tester
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [UPDATE_TESTER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('testTester', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    /**
     *
     * @param context
     * @param id
     * @returns {Promise<unknown>}
     */
    [DELETE_INCOME](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('testIncome', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    /**
     * api xóa tester
     * @param context
     * @param id
     * @returns {Promise<unknown>}
     */
    [DELETE_TESTER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('testTester', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    [CANCEL_INCOME](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get('testIncome/cancel', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [CHOOSE_TEST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('testIncome/choose-test/' + payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [STORE_MARK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('testIncome/store-mark/' + payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [UPDATE_COMMENT_COURSE_SUGGEST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('testIncome/final-update/' + payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [CHECK_TASK_BY_DAY_LEAVE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${CHECK_TASK_BY_DAY_LEAVE}/` + payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [ASSIGN_TASK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${ASSIGN_TASK}/` + payload.id, payload.tester).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [AUTO_ASSIGN_TEST_INPUT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${AUTO_ASSIGN_TEST_INPUT}` , payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
}

const mutations = {
    [SET_INCOME](state, data) {
        state.testIncomes = data;
        state.errors = {};
    },

    [SET_TESTER](state, data) {
        state.testers = data;
        state.errors = {};
    },

    [UPDATE_TESTER_LIST](state, tester) {
        let testers = state.testers;
        testers.unshift(tester);
    },

    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
