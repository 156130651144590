<template>
  <transition name="fade-in-up">
      <router-view></router-view>
  </transition>
</template>

<script>
export default {
  name: "Permission",
};
</script>

<style scoped></style>
