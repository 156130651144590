import {
    COHERENCE_IN_CONTENT,
    COMMENT_COHERENCE_COHESION_WRITING,
    COMMENT_FLUENCY_AND_COHERENCE,
    COMMENT_GRAMMATICAL_RANGE_ACCURACY,
    COMMENT_GRAMMATICAL_RANGE_ACCURACY_WRITING,
    COMMENT_LEXICAL_RESOURCE,
    COMMENT_LEXICAL_RESOURCE_WRITING,
    COMMENT_PRONUNCIATION,
    COMMENT_TASK_ACHIEVEMENT_WRITING,
    CONTENT_COMMUNICATION_CIRCUIT,
    EXPAND_AND_DEVELOPIDEAS,
    GRAMMAR_DIVERSITY,
    GRAMMAR_DIVERSITY_WRITING,
    NAME_CRITERIA, PLAGIARISM_RATE,
    PRONUNCIATION_ABILITY,
    TYPE_TEST,
    VOCABULARY_DIVERSITY,
    WORD_DIVERSITY, WRITING
} from "@/core/option/testInputOption";

export function ResultTest(type, correctQuestions = null, totalQuestions = null, totalPointAuto = null, dataComments = [], data = null) {
    this.type = type
    this.correctQuestions = correctQuestions
    this.totalQuestions = totalQuestions
    this.nameType = typeTestToString(type)
    this.totalPointAuto = toRoundingMarkSkill(totalPointAuto)
    if (dataComments.length) {
        this.dataComments = toDataComment(dataComments)
        if (type === WRITING) {
            this.totalPoint = toTotalPoint(this.dataComments, data?.type_of_plagiarism)
        } else {
            this.totalPoint = toTotalPoint(this.dataComments)
        }

    }
    if (type === WRITING) {
        this.typePlagiarism = data?.type_of_plagiarism;
        this.descriptionPlagiarism = data?.description_of_plagiarism
    }
}

function toDataComment(dataComments) {
    return dataComments?.map((dataComment) => {
        return new DataComment(dataComment?.key, dataComment?.point, dataComment?.comment, dataComment.comment_auto)
    })
}

function toTotalPoint(dataComments, typePlagiarism = null) {
    let totalPoint = dataComments?.map((dataComment) => parseFloat(dataComment.point) ?? 0).reduce((a, b) => a + b) / 4
    if (typePlagiarism) {
        const minusPoints = PLAGIARISM_RATE.find((e) => e.value === +typePlagiarism)
        totalPoint = toRoundingMarkSkill((totalPoint * (1-minusPoints.minusPoints)))
    }else {
        totalPoint = toRoundingMarkSkill(totalPoint);
    }
    return totalPoint == 0 ? 0 : totalPoint.toFixed(1)
}

export function toRoundingMark(number) {
    let numberInt = Math.floor(number);
    let remainder = number - numberInt;
    return numberInt + Math.round(remainder * 2) / 2;
}

export function toRoundingMarkSkill(number) {
    let numberInt = Math.floor(number);
    let remainder = number - numberInt;
    return numberInt + Math.floor(remainder * 2) / 2;
}


function typeTestToString(type) {
    return TYPE_TEST.get(type)
}

export function DataComment(eName, point, comment, comment_auto) {
    this.nameCriteria = eNameToVName(eName)
    this.autoComment = comment_auto ?? makeAutoComment(eName, point)
    this.comment = comment
    this.point = point
}

function eNameToVName(eName) {
    return NAME_CRITERIA.get(eName)
}

function makeAutoComment(eNameCriteria, pointCriteria) {
    if (eNameCriteria == null || eNameCriteria == undefined) {
        return "";
    }
    switch (eNameCriteria) {
        case "fluency_and_coherence":
            return EXPAND_AND_DEVELOPIDEAS.pointLadder[pointCriteria];
            break;
        case "lexical_resource":
            return WORD_DIVERSITY.pointLadder[pointCriteria];
            break;
        case "grammatical_range_accuracy":
            return GRAMMAR_DIVERSITY.pointLadder[pointCriteria];
            break;
        case "pronunciation":
            return PRONUNCIATION_ABILITY.pointLadder[pointCriteria];
            break;
        case "task_achievement_writing":
            return CONTENT_COMMUNICATION_CIRCUIT.pointLadder[pointCriteria];
            break;
        case "lexical_resource_writing":
            return VOCABULARY_DIVERSITY.pointLadder[pointCriteria];
            break;
        case "grammatical_range_accuracy_writing":
            return GRAMMAR_DIVERSITY_WRITING.pointLadder[pointCriteria];
            break;
        case "coherence_cohesion_writing":
            return COHERENCE_IN_CONTENT.pointLadder[pointCriteria];
            break;

    }
}


