import ApiService from "@/core/services/api.service";

const _ = require('lodash');
export const DOWNLOAD_COST_PRICE_DEMO = "cost-price/download-example"
export const IMPORT_COST_PRICE = "cost-price/import-cost-price";
export const LIST_COST_PRICE = "cost-price";
const state = {};

const getters = {};
const actions = {
    [DOWNLOAD_COST_PRICE_DEMO](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(DOWNLOAD_COST_PRICE_DEMO).then(({data}) => {
                const filename = "File mẫu giá vốn.xlsx";
                const anchor = document.createElement("a");
                anchor.setAttribute("download", filename);
                anchor.setAttribute("href", data.data);
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [IMPORT_COST_PRICE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(IMPORT_COST_PRICE, payload.data).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [LIST_COST_PRICE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(LIST_COST_PRICE, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },
};


const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};