<template>
    <!--begin::Card-->
    <div class="card card-custom gutter-b">
        <div class="card-header">
            <div class="card-title">
                <h3 class="card-label">Release note</h3>
            </div>
        </div>
        <div class="card-body">
            <!--begin::Example-->
            <div class="example example-basic">
                <div class="example-preview">
                    <div class="timeline timeline-justified timeline-4">
                        <div class="timeline-bar"></div>
                        <div class="timeline-items" v-for="item in releases">
                            <div class="timeline-item">
                                <div class="timeline-badge">
                                    <div class="bg-danger"></div>
                                </div>
                                <div class="timeline-label">
                                    <span class="font-size-h3 font-weight-bold">Version {{ item.version}}</span>
                                    <span class="text-primary font-weight-bold"> {{ item.time }}</span>
                                </div>
                                <div class="timeline-content">
                                    <div class="d-flex align-items-center justify-content-between mb-3">
                                        <div class="mr-2">
                                            <span class="label label-light-success font-weight-bolder label-inline ml-2">FEATURE</span>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mt-3" v-for="feature in item.feature">
                                        <!--begin::Bullet-->
                                        <span class="bullet bullet-bar bg-success align-self-stretch"></span>
                                        <!--end::Bullet-->
                                        <!--begin::Checkbox-->
                                        <label class="checkbox checkbox-lg checkbox-light-info checkbox-inline flex-shrink-0 m-0 mx-4">
                                        </label>
                                        <!--end::Checkbox-->
                                        <!--begin::Text-->
                                        <div class="d-flex flex-column flex-grow-1">
                                            <a href="#" class="text-dark-75 text-hover-primary font-weight-bold mb-1">{{ feature}}</a>
                                        </div>
                                        <!--end::Text-->
                                    </div>

                                    <!--begin::UPDATE-->
                                    <template v-for="update in item.update">
                                        <div class="d-flex align-items-center justify-content-between mb-3 mt-5" >
                                            <div class="mr-2">
                                                <span class="label label-light-warning font-weight-bolder label-inline ml-2">UPDATE</span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mt-3">
                                            <!--begin::Bullet-->
                                            <span class="bullet bullet-bar bg-warning align-self-stretch"></span>
                                            <!--end::Bullet-->
                                            <!--begin::Checkbox-->
                                            <label class="checkbox checkbox-lg checkbox-light-info checkbox-inline flex-shrink-0 m-0 mx-4">
                                            </label>
                                            <!--end::Checkbox-->
                                            <!--begin::Text-->
                                            <div class="d-flex flex-column flex-grow-1">
                                                <a href="#" class="text-dark-75 text-hover-primary font-weight-bold mb-1">{{ update}}</a>
                                            </div>
                                            <!--end::Text-->
                                        </div>
                                    </template>
                                    <!--end::UPDATE-->


                                    <!--begin::BUG-->
                                    <template v v-for="bug in item.bug">
                                        <div class="d-flex align-items-center justify-content-between mb-3 mt-5">
                                            <div class="mr-2">
                                                <span class="label label-light-danger font-weight-bolder label-inline ml-2">BUG</span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mt-3">
                                            <!--begin::Bullet-->
                                            <span class="bullet bullet-bar bg-danger align-self-stretch"></span>
                                            <!--end::Bullet-->
                                            <!--begin::Checkbox-->
                                            <label class="checkbox checkbox-lg checkbox-light-info checkbox-inline flex-shrink-0 m-0 mx-4">
                                            </label>
                                            <!--end::Checkbox-->
                                            <!--begin::Text-->
                                            <div class="d-flex flex-column flex-grow-1">
                                                <a href="#" class="text-dark-75 text-hover-primary font-weight-bold mb-1">{{ bug }}</a>
                                            </div>
                                            <!--end::Text-->
                                        </div>
                                    </template>
                                    <!--end::BUG-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Example-->
        </div>
    </div>
    <!--end::Card-->
</template>
<script>
    import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        data() {
            return {
                releases: []
            };
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Release note" }
            ]);
            ApiService.query('version').then(({data})=>{
                this.releases = data.data;
            })
        }
    }
</script>