<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Lịch sử cập nhật chính sách</h3>
      </div>
      <div class="card-title">
      </div>
    </div>
    <div class="card-body">
      <!--Filter-->
      <div class="example mb-10">
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th scope="col" class="min-w-100px">STT</th>
              <th scope="col" class="min-w-100px">Tên chính sách</th>
              <th scope="col" class="min-w-100px">Nội dung thay đổi</th>
              <th scope="col" class="min-w-100px">Thời gian update</th>
              <th scope="col" class="min-w-100px">Người update</th>
              <th scope="col" class="min-w-100px">Hành động</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in termPolicy" :key="index">
              <td>{{ page == 1 ? index + 1 : (page - 1) * 20 + index + 1 }}</td>
              <td>{{ item.center ? item.center.name : '' }}</td>
              <td>{{ checkTypePolicy(item.type) }} <span class="text-violet">{{ item.name }}</span></td>
              <td>{{ item.updated_at | formatDateTimeVietnam}}</td>
              <td>{{ item.policy_catalog && item.policy_catalog.user ? (item.policy_catalog.updated_id + 100000) + ' - ' + item.policy_catalog.user.name : '' }}</td>
              <td>
                <a title="Xem chi tiết"
                   v-b-modal.modal-create-history
                   class="btn btn-sm btn-icon mr-2 btn-outline-success" @click="viewPolicy(item)"><i class="far fa-eye"></i>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
    <modal-preview-history :dataHistory="dataHistory"></modal-preview-history>
  </div>
</template>

<script>
import {GET_HISTORY_POLICY, GET_LOG_POLICY} from "@/core/services/store/system/termpolicy.module";
import ModalPreviewHistory from "@/view/pages/term-policy/components/ModalPreviewHistory";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
export default {
  name: "HistoryUpdate",

  components: {
    ModalPreviewHistory
  },
  data() {
    return {
      page: 1,
      last_page: 1,
      termPolicy: {},
      dataHistory: {}
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý điều khoản & chính sách ", route: 'term-policy'},
      {title: "Lịch sử cập nhật chính sách"}

    ]);
    this.getLogPolicy();
  },
  methods: {
    clickCallback(obj) {
      this.page = obj
      this.$router.push({path: '', query: {page: this.page}})
      this.getTermPolicyByCenterId()
    },
    viewPolicy(item) {
      this.$store.dispatch(GET_HISTORY_POLICY,{id: item.id}).then((res) => {
        this.dataHistory = res.data;
      });
    },
    getLogPolicy() {
      let id = this.$route.params.id;
      this.$store.dispatch(GET_LOG_POLICY,{center_id: id, page: this.page}).then((res) => {
        this.termPolicy = res.data.data;
        this.last_page = res.data.last_page;
      });
    },
    checkTypePolicy(type) {
      let text;
      switch (type) {
        case 0:
          text = 'Thêm mới danh mục ';
          break;
        case 1:
          text = 'Cập nhập danh mục ';
          break;
        case 2:
          text = 'Xóa danh mục ';
          break;
      }
      return text
    }
  }
}
</script>

<style scoped>
  .text-violet {
    color: blueviolet;
  }
  .ck-balloon-rotator__content {
    z-index: 1050 !important;
  }
  .ck.ck-balloon-panel.ck-balloon-panel_arrow_n.ck-balloon-panel_visible.ck-balloon-panel_with-arrow {
    z-index: 10000 !important;
  }
  .ck.ck-balloon-panel.ck-balloon-panel_visible {
    z-index: 10000 !important;
  }
  .ck.ck-balloon-panel {
    z-index: 10000 !important;
  }
  .ck.ck-reset_all {
    z-index: 10000 !important;
  }
</style>