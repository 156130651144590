<template>
  <div>
    <div v-if="dataJson.length==0">
      <input
          type="file"
          name="file-input-exam"
          :id="type"
          class="file-input__input"
          @change="inputFile"
      />
      <label class="file-input__label bg-light" :for="type"
             :class="{'disable-import':!disableImport, 'on-disable-import':disableImport}">
        <span style="color: black"><i class="flaticon-upload mr-2" style="color: black"></i>Upload file</span></label
      >
    </div>
    <div style="height: 100%;width: 100%" class="py-1" v-else>
      <i class="fas fa-minus-circle mr-2" style="cursor: pointer;"
         @click="removeFile"></i>
      <span>{{ nameFile }}</span>
    </div>
  </div>
</template>
<script>
import FileUpload from 'vue-upload-component';
import {utils, read} from "xlsx";
import {LIST_SHEET} from "@/core/option/LogResourceOption";
import moment from "moment-timezone";

export default {
  name: "UploadFilePaycheck",
  data() {
    return {
      files: [],
      dataJson: [],
      disableImport: false,
      nameFile: "",
      LIST_SHEET: LIST_SHEET,
    }
  },
  mounted() {

  },
  components: {
    FileUpload
  },
  props: {
    type: {
      type: String,
      default() {
        return ""
      }
    },
    flagDoneUpload:{
      type: Boolean,
      default(){
        return false
      }
    }
  },
  watch:{
    flagDoneUpload(){
      this.dataJson = []
    }
  },
  methods: {
    removeFile() {
      this.dataJson = []
      this.$emit('deleteFile', LIST_SHEET.get(this.type))
    },
    async inputFile(event) {
      if (!event.target.files.length) {
        return
      }
      let dataImport = event.target.files[0];
      this.dataJson = await this.fileToJson(dataImport)
      this.dataJson.forEach((data) => {
        Object.keys(data).forEach((key) => {
          data[this.convertKey(key)] = data[key]
          delete data[key];
        })
      })
      if(this.type == "Văn phòng Tổng"){
        this.dataJson.forEach((data) => {
          data.thoi_diem_trai_phieu_co_hieu_luc = data?.thoi_diem_trai_phieu_co_hieu_luc?.split("/")?.reverse().join("/")??moment(this.ExcelDateToJSDate(data.thoi_diem_trai_phieu_co_hieu_luc)).format("YYYY/MM/DD")
          data.phan_tram_kpi_hoan_thanh_cong_viec =  (+data.phan_tram_kpi_hoan_thanh_cong_viec?.replace('%','').replace(',','.')/100 )??''
          data.phan_tram_kpi_thoi_gian = +data?.phan_tram_kpi_thoi_gian?.replace('%','').replace(',','.')/100
        })
      }
      let dataFile = {
        type: LIST_SHEET.get(this.type),
        data: this.dataJson
      }
      this.nameFile = event.target.files[0].name
      this.$emit("uploadFile", dataFile)
    },
    ExcelDateToJSDate(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);

      var fractional_day = serial - Math.floor(serial) + 0.0000001;

      var total_seconds = Math.floor(86400 * fractional_day);

      var seconds = total_seconds % 60;

      total_seconds -= seconds;

      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;

      return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate());
    },
    removeVietnameseTones(str) {
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
      str = str.replace(/Đ/g, "D");
      // Some system encode vietnamese combining accent as individual utf-8 characters
      // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
      // Remove extra spaces
      // Bỏ các khoảng trắng liền nhau
      str = str.replace(/ + /g, " ");
      str = str.trim();
      // Remove punctuations
      // Bỏ dấu câu, kí tự đặc biệt
      str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
      return str;
    },
    convertKey(key) {
      let myString = this.removeVietnameseTones(key);
      let trimmedString = myString.trim();
      let formattedString = trimmedString.replace(/\s+/g, '_');
      return formattedString.toLowerCase()
    },
    fileToJson(file) {
      return new Promise((res, rej) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader()
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result
          const wb = read(bstr, {type: 'binary'})
          /* Get first worksheet */
          const dataName = wb.SheetNames[0]
          const dataSheet = wb.Sheets[dataName]
          /* Convert array of arrays */
          const data = utils.sheet_to_json(dataSheet,{raw : false})
          /* Update state */
          res(data);
          //const header = data.shift()
        }
        reader.onerror = (e) => {
          rej(e);
        };
        reader.readAsText(file, 'utf-8')
      });
    },

  }
}
</script>

<style scoped>
.file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  height: 28px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 600;
  color: white;
  padding: 2px 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);

}

.disable-import {

}

.on-disable-import {

}
</style>