<template>
  <div>
    <div class="page-header" style="display: flex; justify-content: space-between; padding: 18px 0; align-items: center;">
      <h3 class="page-title" style="font-size: 16px; font-weight: 400">Danh sách hạng mục</h3>
      <button style="background: #1BC5BD; border-radius: 3px; padding: 5px 6px; color: white" @click="addCategory = true">
        Thêm hạng mục
      </button>
    </div>
    <div class="table-responsive" style="overflow: auto; height: 58vh">
      <table class="table" style="text-align: center;">
        <thead style="position: sticky; top: 0;">
        <tr style="border: 1px solid #EBEDF3">
          <th scope="col" style="background: #F8F8F8; width: 40px">STT</th>
          <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: start">Tên hạng mục</th>
          <th scope="col" class="min-w-100px" style="background: #F8F8F8; text-align: start">Loại hạng mục</th>
          <th scope="col" class="w-160px" style="background: #F8F8F8">Hành động</th>
        </tr>
        </thead>
        <tbody v-if="loading">
        <tr class="col-12">
          <td class="text-center"><i class="el-icon-loading" style="position: absolute; left: 50%; margin: 15px 0"></i>
          </td>
        </tr>
        </tbody>
        <tbody v-else-if="honorCategoryList?.length > 0">
        <tr v-for="(item, index) of honorCategoryList" :key="item?.id" style="border: 1px solid #EBEDF3; border-top: 0">
          <td>{{ index + 1 }}</td>
          <td style="text-align: start">{{ item?.category }}</td>
          <td style="text-align: start">{{ item?.type_category_name }}</td>
          <td>
            <div style="display: flex; justify-content: center; align-items: center; gap: 10px">
              <button class="btn-action" style="border-color: #0066FF" @click="openEdit(item)">
                <i class="el-icon-edit" style="color: #0066FF"></i>
              </button>
              <button class="btn-action" style="border-color: #FF0000" @click="openDelete(item)">
                <i class="el-icon-delete" style="color: #FF0000"></i>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr style="word-break: break-word; white-space: nowrap; position: relative">
          <label style="position: absolute; left: 50%; translate: -50%; top: 10px">Chưa có danh mục</label>
        </tr>
        </tbody>
      </table>
    </div>
    <div style="display: flex; justify-content: end;" v-if="honorCategoryList?.length > 0">
      <span>Tổng số bản ghi: <span style="color: #0066FF">{{honorCategoryList?.length}}</span></span>
    </div>
    <el-dialog title="Thêm mới hạng mục"
               :show-close="false" :visible.sync="addCategory" width="30%"
               :destroy-on-close="true" :center="true"
    >
      <DialogAddCategory
          :add-category="addCategory"
          @closeDialog="addCategory = false"
          :parent-id="this.parentId"
          @getListCategory="getListCategory"
      />
    </el-dialog>
    <el-dialog title="Sửa hạng mục"
               :show-close="false" :visible.sync="isEdit" width="30%"
               :destroy-on-close="true" :center="true"
    >
      <DialogEditCategory
          v-if="isEdit"
          :is-edit="isEdit"
          :category="category"
          @closeEditCategory="isEdit = false"
          :parent-id="this.parentId"
          @getListCategory="getListCategory"
      />
    </el-dialog>
    <el-dialog title="Xoá hạng mục"
               :show-close="false" :visible.sync="isDelete" width="30%"
               :destroy-on-close="true" :center="true"
    >
      <div style="width: 100%; height: 1px; background: gray; margin-top: -20px; margin-bottom: 20px"></div>
      <span style="word-break: break-word">Bạn có chắc chắn muốn xóa chương trình “{{category?.category}}” không?</span>
      <div style="display: flex; justify-content: end; margin-top: 30px">
        <el-button style="background: #CACACA" @click="isDelete = false">Hủy bỏ</el-button>
        <button
            class="ml-3"
            :disabled="loading"
            style="background: #5D04B0; padding: 0 10px; border-radius: 5px; color: white; position:relative;"
            @click="handleDeleteCategory"
        >
          <i v-if="loading" class="el-icon-loading text-white float-righ"></i>
          Xác nhận
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {HonorCategoryModel} from "@/view/pages/honors/Model/honorModel";
import DialogAddCategory from "@/view/pages/honors/component/DialogAddCategory.vue";
import DialogEditCategory from "@/view/pages/honors/component/DialogEditCategory.vue";
import {DELETE_HONOR_CATEGORY, GET_LIST_HONOR_CATEGORY} from "@/core/services/store/honors/honor.module";

export default {
  components: {DialogEditCategory, DialogAddCategory},
  props: {
    parentId: {
      type: Number,
      required: true,
      default: null,
    }
  },
  mounted() {
    this.getListCategory();
  },
  data() {
    return{
      honorCategoryList: [],
      category: {},
      loading: false,
      isEdit: false,
      isDelete: false,
      addCategory: false,
    }
  },
  methods: {
    async getListCategory() {
      this.honorCategoryList = await this.$store.dispatch(GET_LIST_HONOR_CATEGORY, {
        program_id : this.$route.params.id
      }).then(data => {
        if (data.data.length > 0) {
          return data.data.map(item => {
            return new HonorCategoryModel(
                item?.id,
                item?.category_name,
                item?.honor_category_type,
                item?.category_desc,
            )
          })
        }
        return []
      })
    },
    openEdit(category) {
      this.category = category
      setTimeout(() => {
        this.isEdit = true
      },500)
    },
    openDelete(category) {
      this.category = category
      this.isDelete = true
    },
    handleDeleteCategory() {
      this.loading = true;
      this.$store.dispatch(DELETE_HONOR_CATEGORY, this.category.id).then((res) => {
        this.$message.success('Xóa hạng mục thành công')
        this.getListCategory();
        this.isDelete = false;
        this.loading = false;
      }).catch((e) => {
        this.loading = false;
      })
    },
  }
}
</script>

<style lang="css" scoped>
.btn-action {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid;
}
</style>
