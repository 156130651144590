<template>
  <form
    class="form center-educenter"
    @submit.prevent="summit"
    autocomplete="off"
  >
    <hr class="header-title-dialog" />
    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="checkbox-inline">
            <label
              class="checkbox checkbox-success text-dark-100 font-weight-bolder"
              style="font-size: 14px !important"
            >
              <input
                type="checkbox"
                name="check"
                v-model="check_all"
                class="mr-4"
                @change="handleAllChange()"
              />
              <span></span>Chọn tất cả
            </label>
          </div>
          <span></span>
        </div>
      </div>
      <div v-if="course.name" class="col-md-12">
        <div class="form-group">
          <div class="checkbox-inline">
            <label class="checkbox checkbox-success title-center">
              <input
                type="checkbox"
                name="name"
                v-model="checkBox.name"
                class="mr-4"
                @change="checkRemoveAll"
              />
              <span></span>Tên trung tâm
            </label>
          </div>
          <p class="text-dark-50 ml-8">{{ course.name }}</p>
        </div>
      </div>
      <div v-if="course.avatar" class="col-md-12">
        <div class="form-group">
          <div class="checkbox-inline">
            <label class="checkbox checkbox-success title-center">
              <input
                type="checkbox"
                name="avatar"
                v-model="checkBox.avatar"
                class="mr-4"
                @change="checkRemoveAll"
              />
              <span></span>Ảnh trung tâm
            </label>
          </div>
          <p class="ml-8" v-if="course.avatar">
            <img class="img-center" :src="course.avatar" />
          </p>
          <p v-else class="ml-8 font-italic">Chưa có</p>
        </div>
      </div>
      <div v-if="course.fee" class="col-md-12">
        <div class="form-group">
          <div class="checkbox-inline">
            <label class="checkbox checkbox-success title-center">
              <input
                type="checkbox"
                name="name"
                v-model="checkBox.fee"
                class="mr-4"
                @change="checkRemoveAll"
              />
              <span></span>Học phí
            </label>
          </div>
          <money
            v-model="course.fee"
            v-bind="money"
            class="text-dark-50 ml-8">
          ></money>
        </div>
      </div>
      <div v-if="course.sale" class="col-md-12">
        <div class="form-group">
          <div class="checkbox-inline">
            <label class="checkbox checkbox-success title-center">
              <input
                type="checkbox"
                name="name"
                v-model="checkBox.sale"
                class="mr-4"
                @change="checkRemoveAll"
              />
              <span></span>Giảm giá (%)
            </label>
          </div>
          <p class="text-dark-50 ml-8">{{ course.sale }}</p>
        </div>
      </div>
      <div v-if="course.time" class="col-md-12">
        <div class="form-group">
          <div class="checkbox-inline">
            <label class="checkbox checkbox-success title-center">
              <input
                type="checkbox"
                name="name"
                v-model="checkBox.time"
                class="mr-4"
                @change="checkRemoveAll"
              />
              <span></span>Thời lượng học
            </label>
          </div>
          <p class="text-dark-50 ml-8">{{ course.time }}</p>
        </div>
      </div>
      <div v-if="course.video" class="col-md-12">
        <div class="form-group">
          <div class="checkbox-inline">
            <label class="checkbox checkbox-success title-center">
              <input
                type="checkbox"
                name="name"
                v-model="checkBox.video"
                class="mr-4"
                @change="checkRemoveAll"
              />
              <span></span>Video giới thiệu
            </label>
          </div>
          <div class="card card-body">
            <p class="ml-8" v-html="course.video"></p>
          </div>
        </div>
      </div>
      <div v-if="course.content" class="col-md-12">
        <div class="form-group">
          <div class="checkbox-inline">
            <label class="checkbox checkbox-success title-center">
              <input
                type="checkbox"
                name="name"
                v-model="checkBox.content"
                class="mr-4"
                @change="checkRemoveAll"
              />
              <span></span>Nội dung bài học
            </label>
          </div>
          <div class="card card-body">
            <p class="ml-8" v-html="course.content"></p>
          </div>
        </div>
      </div>
      <div v-if="course.excerpt" class="col-md-12">
        <div class="form-group">
          <div class="checkbox-inline">
            <label class="checkbox checkbox-success title-center">
              <input
                type="checkbox"
                name="name"
                v-model="checkBox.excerpt"
                class="mr-4"
                @change="checkRemoveAll"
              />
              <span></span>Mô tả ngắn
            </label>
          </div>
          <div class="card card-body">
            <p class="ml-8" v-html="course.excerpt"></p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <div class="checkbox-inline">
            <label class="checkbox checkbox-success title-center">
              <input
                type="checkbox"
                name="name"
                v-model="checkBox.description"
                class="mr-4"
                @change="checkRemoveAll"
              />
              <span></span>Mô tả
            </label>
          </div>
          <div class="card card-body">
            <p class="ml-8" v-html="course.description"></p>
          </div>
        </div>
      </div>

      <div class="col-md-12 mb-3">
        <button
          :disabled="loading.save"
          @click="setAlarm = true"
          class="btn btn-success float-right mt-3 ml-3 dat-lich-tvv"
        >
          <i v-if="loading.save" class="el-icon-loading"></i>
          Sử dụng
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import "@/assets/sass/custom/custom_event.scss";
import { Money } from "v-money";
export default {
  components: {
    Money,
  },
  props: {
    course: {
      default: null,
    },
    data: Object,
  },
  data() {
    return {
      checkBox: {
        name: false,
        avatar: false,
        fee: false,
        sale: false,
        time:false,
        video: false,
        excerpt: false,
        description: false,
        content: false,
      },
      check_all : false,
      check_show: false,
      loading: {
        save: false,
        loadingSelect: false,
      },
      item: {},
      money: {
        decimal: ",",
        thousands: ",",
        prefix: "",
        suffix: " VNĐ",
        precision: 0,
        masked: false,
      },
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    summit() {
      this.$emit("completed", this.checkBox);
    },
    checkRemoveAll() {
      let check_length = Object.keys(this.checkBox).length;
      let total_check = 0;
      for (var key in this.checkBox) {
        if (this.checkBox[key] != true) {
          this.check_all = false;
        }
        if (!this.course[key]) {
         check_length = check_length - 1;
        }
        if(this.checkBox[key] == true) {
          total_check = total_check + 1;
        }
      }
      if (total_check == check_length) {
        this.check_all = true;
      }
    },
    handleAllChange() {
      if (this.check_all) {
        for (var key in this.checkBox) {
            this.checkBox[key] = true;         
        }
      } else {
        for (var key in this.checkBox) {
            this.checkBox[key] = false;
        }
      }
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
