<template>
  <div class="bg-white p-1" style="overflow: auto">
    <div class="page-header"
         style="display: flex; justify-content: space-between; padding: 18px 24px; align-items: center;">
      <h3 class="page-title" style="font-size: 18px;">Danh sách vinh danh</h3>
      <button style="background: #1BC5BD; border-radius: 3px; padding: 5px 6px; color: white" @click="addHonor = true">
        Thêm chương trình
      </button>
    </div>
    <div style="width: 100%; height: 1px; background: #D6D5D5"></div>
    <div style="padding: 18px 24px">
      <div class="page-filter" style="display: flex; align-items: end; gap: 40px;">
        <div>
          <label>Chương trình</label>
          <el-input v-model="programName" placeholder="Nhập tên chương trình, mô tả"/>
        </div>
        <div>
          <label>Thời gian vinh danh</label>
          <date-picker placeholder="Thời gian tạo" range v-model="date_range" format="DD-MM-YYYY"
                       valueType="YYYY-MM-DD"></date-picker>
        </div>
        <div>
          <label>Trạng thái</label><br>
          <el-select v-model="status" clearable placeholder="Chọn trạng thái">
            <el-option
                v-for="item in honorOption"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <el-button style="background: #0066FF; color: white" @click="getListHonor"><i v-bind:class="[search ? 'el-icon-loading text-white mr-2' : '']"></i>Tìm kiếm</el-button>
      </div>
    </div>
    <div class="table-responsive" style="overflow: hidden; padding: 24px">
      <table class="table" style="text-align: center;">
        <thead>
        <tr style="border: 1px solid #EBEDF3">
          <th scope="col" style="background: #F8F8F8; width: 40px">STT</th>
          <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: start">Tên chương trình</th>
          <th scope="col" class="min-w-150px" style="background: #F8F8F8; text-align: start">Thời gian vinh danh</th>
          <th scope="col" class="min-w-120px" style="background: #F8F8F8; text-align: start">Mô tả</th>
          <th scope="col" class="min-w-80px" style="background: #F8F8F8; text-align: center;">Trạng thái</th>
          <th scope="col" class="w-160px" style="background: #F8F8F8">Hành động</th>
        </tr>
        </thead>
        <tbody v-if="loading">
        <tr class="col-12">
          <td class="text-center"><i class="el-icon-loading" style="position: absolute; left: 50%; margin: 15px 0"></i>
          </td>
        </tr>
        </tbody>
        <tbody v-else-if="honorList?.length > 0">
        <tr v-for="(item, index) of honorList" :key="item?.id" style="border: 1px solid #EBEDF3; border-top: 0">
          <td>{{ index + 1 }}</td>
          <td style="text-align: start">
            <span class="line-clamp-1">{{ item?.name }}</span>
          </td>
          <td style="text-align: start">{{ formatDate(item?.date) }}</td>
          <td>
            <p class="line-clamp-1" style="white-space: pre-wrap; text-align: start" v-html="item.description"></p>
          </td>
          <td style="text-align: center">
            <el-switch v-model="item.status" active-color="#13ce66" inactive-color="#888888"
                       @change="handleChangeStatus(item)"></el-switch>
          </td>
          <td>
            <div style="display: flex; justify-content: center; align-items: center; gap: 10px">
              <button class="btn-action" style="border-color: #038E00" @click="viewDetailHonor(item)">
                <i class="el-icon-menu" style="color: #038E00"></i></button>
              <button class="btn-action" style="border-color: #0066FF" @click="handleOpenEditHonor(item)">
                <i class="el-icon-edit" style="color: #0066FF"></i></button>
              <button class="btn-action" style="border-color: #FF0000" @click="handleOpenRemoveHonor(item)">
                <i class="el-icon-delete" style="color: #FF0000"></i></button>
            </div>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr style="word-break: break-word; white-space: nowrap; position: relative; height: 40px">
          <div style="position: absolute; left: 50%; translate: -50%; top: 10px;">
            <div style="line-height: 25px !important;">Chưa có chương trình</div>
          </div>
        </tr>
        </tbody>
      </table>
      <div v-if="honorList?.length > 0">
        <div class="edu-paginate mx-auto text-center mt-10 mb-5"
             style="display: flex; justify-content: center; align-items: center; height: 50px">
          <paginate
              v-model="page"
              :page-count="last_page"
              :page-range="3"
              :margin-pages="1"
              :click-handler="clickCallback"
              :prev-text="'Trước'"
              :next-text="'Sau'"
              :container-class="'pagination b-pagination'"
              :pageLinkClass="'page-link'"
              :next-link-class="'next-link-item'"
              :prev-link-class="'prev-link-item'"
              :prev-class="'page-link'"
              :next-class="'page-link'"
              :page-class="'page-item'"
          >
          </paginate>
        </div>
        <div class="w-100" style="display: flex; justify-content: end; margin-top: -50px">
          <!-- Tổng số bản ghi: <span style="color: #0066FF; font-weight: 600; margin-left: 5px;">{{ this.total }}</span> -->
          Tổng số bản ghi: <span style="color: #0066FF; font-weight: 600; margin-left: 5px;">{{ this.total }}</span>

        </div>
      </div>
    </div>
    <el-dialog title="Thêm chương trình vinh danh"
               :show-close="false" :visible.sync="addHonor" width="40%"
               :destroy-on-close="true" :center="true"
    >
      <DialogAddHonor :add-honor="addHonor" @closeDialog="addHonor = false" @getListHonor="getListHonor"/>
    </el-dialog>
    <el-dialog title="Sửa chương trình vinh danh"
               :show-close="false" :visible.sync="editHonor" width="40%"
               :destroy-on-close="true" :center="true"
    >
      <DialogEditHonor v-if="editHonor" :is-edit="editHonor" :honor="honor" @closeDialog="editHonor = false"
                       @getListHonor="getListHonor"/>
    </el-dialog>
    <el-dialog title="Xoá chương trình vinh danh"
               :show-close="false" :visible.sync="removeHonor" width="30%"
               :destroy-on-close="true" :center="true"
    >
      <div style="width: 100%; height: 1px; background: gray; margin-top: -20px; margin-bottom: 20px"></div>
      <span style="word-break: break-word">Bạn có chắc chắn muốn xóa chương trình “{{ honor?.name }}” không?</span>
      <div style="display: flex; justify-content: end; margin-top: 30px">
        <el-button style="background: #CACACA" @click="removeHonor = false">Hủy bỏ</el-button>

        <button
            class="ml-4"
            :disabled="loading"
            style="background: #5D04B0; padding: 0 10px; border-radius: 5px; color: white; position:relative;"
            @click="handleDeleteHonor"
        >
          <i v-if="loading" class="el-icon-loading text-white float-righ"></i>
          Xác nhận
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {HonorModel, honorStatus} from "@/view/pages/honors/Model/honorModel";
import moment from "moment-timezone";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import DialogAddHonor from "@/view/pages/honors/component/DialogAddHonor.vue";
import DialogEditHonor from "@/view/pages/honors/component/DialogEditHonor.vue";
import {
  DELETE_HONOR,
  GET_LIST_HONOR,
  UPDATE_STATUS_HONOR
} from "@/core/services/store/honors/honor.module";

export default {
  name: 'honorsList',
  components: {DialogEditHonor, DialogAddHonor, DatePicker},
  data() {
    return {
      page: 1,
      honor: {},
      last_page: 1,
      total: '',
      programName: '',
      date_range: '',
      status: null,
      description: '',
      honorOption: honorStatus,
      loading: true,
      honorList: [],
      addHonor: false,
      editHonor: false,
      removeHonor: false,
      search: false,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách vinh danh"}
    ]);
    this.getListHonor();
  },
  methods: {
    clickCallback(obj) {
      this.page = obj
      this.getListHonor()
    },
    formatDate(date) {
      if (date) {
        return moment(date).format('YYYY-MM-DD');
      }
    },
    async getListHonor() {
      this.search = true;
      const payload = {
        page: this.page,
        keyword: this.programName,
        status: this.status,
        date_range: this.date_range,
      }
      this.honorList = await this.$store.dispatch(GET_LIST_HONOR, payload).then((res) => {
        const data = res.data;
        this.loading = false;
        this.total = data.total;
        this.page = data.current_page;
        this.last_page = data.last_page;
        this.filterByCondition()
        this.search = false;
        if (data.data.length > 0) {
          return data.data.map(item => {
            return new HonorModel(
                item?.id,
                item?.program_name,
                item?.start_time,
                item?.program_desc,
                item?.honor_status
            )
          })
        } else {
          return [];
        }
      }).catch((error) => {
        console.log(error)
        this.search = false;
      });
    },
    handleOpenRemoveHonor(honor) {
      this.removeHonor = true
      this.honor = honor
    },
    handleDeleteHonor() {
      this.loading = true;
      this.$store.dispatch(DELETE_HONOR, this.honor.id).then(() => {
        this.removeHonor = false
        this.$message.success('Xóa chương trình thành công')
        this.removeHonor = false
        this.getListHonor();
      })
    },
    handleChangeStatus(honor) {
      this.$store.dispatch(UPDATE_STATUS_HONOR, honor.id)
          .then(() => {
            this.getListHonor();
            this.$message.success('Thay đổi trạng thái chương trình thành công!')
          })
          .catch((error) => {
            console.log(error)
          })
    },
    handleOpenEditHonor(honor) {
      this.honor = honor
      this.editHonor = true
    },
    viewDetailHonor(honor) {
      const id = honor.id
      this.$router.push({name: 'honor-details', params: {id}, query: {honorName: honor.name}})
    },
    filterByCondition() {
      this.$router.push({
        name: 'honorsList',
        query: {
          'keyword': this.programName,
          'honorDate': this.formatDate(this.date_range),
          'status': this.status,
        }
      })
    }
  },
}
</script>
<style lang="css" scoped>
.page-filter__item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 220px;
}

.page-filter__title {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.btn-action {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid;
}
</style>
