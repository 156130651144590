<template>
    <!--begin::Card-->
    <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">
                    Sửa quyền.
                </h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1">Sửa quyền</span>
            </div>
            <div class="card-toolbar">

                <router-link
                        class="font-weight-bold font-size-3  btn btn-secondary"
                        :to="{ name: 'permission-list' }"
                ><i class="fas fa-angle-double-left"></i> Quay lại
                </router-link>
            </div>
        </div>
        <!--end::Header-->
        <div class="row">
            <div class="card-body">
                <div class="form-group">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                    <form class="" id="permission-edit" @submit.prevent="handleSubmit(onSubmit)">
                        <div id="input-group-2" role="group" class="form-group">
                            <label id="input-group-2__BV_label_"
                                   class="d-block">Tên quyền:</label>
                            <div>
                              <ValidationProvider name="Tên quyền" rules="required" vid="name" v-slot="{ errors,classes }">
                                <input type="text" ref="name" name="name" class="form-control" :class="classes"
                                       placeholder="Tên quyền"
                                       v-model=form.name>
                                <div class="fv-plugins-message-container">
                                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                  </div>
                                </div>
                              </ValidationProvider>
                            </div>
                        </div>
                        <div class="form-group fv-plugins-icon-container">
                            <label>Quyền cha</label>
                            <select2 :options="permissions" v-model="form.parent_id" placeholder="Quyền cha"></select2>
                        </div>
                        <button type="submit" class="btn btn-success">Cập nhật</button>
                    </form>
                  </ValidationObserver>
                </div>
            </div>
        </div>

    </div>
    <!--end::Card-->
</template>

<script>
    import {
        GET_PERMISSION,
        GET_PERMISSION_BY_ID,
        UPDATE_PERMISSION
    } from "../../../../core/services/store/user/permission.module";
    import { mapGetters } from "vuex";
    import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";
    import Select2 from "@/view/content/Select2";
    const _ = require('lodash');

    export default {
        components: {
            Select2
        },
        name: "Edit-permission",
        data() {
            return {
                permissions: [],
                id: '',
              form : {
                  name : '',
                  guard_name : '',
                  parent_id: '',
              },
              isDisable: false
            };
        },


         created() {
            this.id = this.$route.params.id;
            this.$store.cache.dispatch(GET_PERMISSION, {limit: 100000}).then((res)=>{
                this.permissions = _.map(res.data.data,(item) => {
                    item = {id: item.id, text: item.name, area_id : item.area_id};
                    return item;
                });
            });
            this.$store.dispatch(GET_PERMISSION_BY_ID, this.id).then((res) => {
              this.form.name = res.data.name;
              this.form.guard_name = res.data.guard_name;
              this.form.parent_id =  res.data.parent_id;
            });
        },
        computed: {
            ...mapGetters(['getPermissionById']),
            error: state => state.errors,
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Tài khoản", route: 'account'},
                {title: "Sửa quyền"}
            ]);
        },
        methods: {
            onSubmit() {
              this.isDisable = true;
                this.$store.dispatch(UPDATE_PERMISSION,{
                    id : this.id,
                    params : this.form
                }).then( (res) => {
                    this.$store.cache.clear(GET_PERMISSION);
                    this.$router.push({ name: "permission-list" }).then(() => {
                      this.$notify({
                        title: 'Thành công',
                        message: res.message,
                        type: 'success'
                      });
                    });
                }).catch((e) => {
                  if (e.data.data.message_validate_form){
                    this.$refs.form.setErrors(e.data.data.message_validate_form)
                  }
                  if (e.data.message){
                    this.$notify({
                      title: 'Thất bại',
                      message: e.data.message,
                      type: 'error'
                    });
                  }
                }).finally(() => {
                  this.isDisable = false;
                })
            },
        },
    }
</script>

<style scoped></style>
