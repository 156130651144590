/**
 * You can use form generate at https://vueformulate.com/guide/forms/generating-forms/#schemas
 * @type {string}
 */
let prefix = 'home';
export const ecosystemPage = [
    {
        "type": "eduImage",
        "name": `ecosystem_banner_image`,
        "parent": `ecosystem_banner_items`,
        "label": "Ảnh banner",
        "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
        "help": "Select a png, jpg or gif to upload",
        "@file-upload-complete": "file-upload-complete"
    },
    {
        "type": "group",
        "name": `ecosystem_banner_items`,
        "repeatable": true,
        "add-label": "+ Thêm box banner",
        "value": [{}],
        "children": [
            {
                "label": "Nội dung box",
                "name": "content_box",
                "validation": "required"
            },
            {
                "label": "Tiêu đề box",
                "name": "title_box",
                "validation": "required"
            }
        ]
    },
    // block 1
    {
        "type": "group",
        "name": `ecosystem_img_items_01`,
        "id": 'ecosystem_feed_01',
        "repeatable": true,
        "add-label": "+ Thêm block",
        "children": [
            {
                "label": "Tiêu đề page 01",
                "name": "title_page",
                "validation": "required"
            },

            // Tiêu đề Mô tả
            {
                "label": "Tiêu đề mô tả ",
                "name": "title_description",
                "validation": "required"
            },
            {
                "type": "eduImage",
                "name": `ecosystem_image_block`,
                "label": "Ảnh ",
                "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
                "help": "Select a png, jpg or gif to upload",
                "@file-upload-complete": "file-upload-complete"
            },
            // Mô tả
            // {
            //     "label": "Nội dung mô tả",
            //     "name": "description",
            //     "validation": "required"
            // }
        ]
    },

    // block 3
    {
        "type": "group",
        "name": `ecosystem_img_items_02`,
        "id": 'ecosystem_feed_02',
        "repeatable": true,
        "add-label": "+ Thêm block",
        "children": [
            {
                "label": "Tiêu đề page 02",
                "name": "title_page",
                "validation": "required"
            },
            {
                "type": "group",
                "name": `ecosystem_img_items_g`,
                "repeatable": true,
                "id": 'feed',
                "add-label": "+ Thêm ý",
                "children": [
                    {
                        "label": "Nội dung mô tả",
                        "name": "description",
                        "validation": "required"
                    },
                ]
            },
        ]
    },
    // block 4
    {
        "type": "group",
        "name": `ecosystem_img_items_03`,
        "id": 'ecosystem_feed_03',
        "repeatable": true,
        "add-label": "+ Thêm block",
        "children": [
            {
                "label": "Tiêu đề page 03",
                "name": "title_page",
                "validation": "required"
            },
            {
                "type": "eduImage",
                "name": `ecosystem_image_block_03`,
                "label": "Ảnh",
                // "parent": 'ecosystem_img_items_04',
                "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
                "help": "Select a png, jpg or gif to upload",
                "@file-upload-complete": "file-upload-complete"
            },
            // Mô tả
            {
                "label": "Nội dung mô tả",
                "name": "description",
                "validation": "required"
            }
        ]
    },
    // block ảnh 02
    {
        "type": "group",
        "name": `ecosystem_img_items`,
        "id": 'ecosystem_feed',
        "repeatable": true,
        "add-label": "+ Thêm block",
        "children": [
            {
                "label": "Tiêu đề 04",
                "name": "title_page",
                "validation": "required"
            },
            {
                "type": "group",
                "name": `ecosystem_img_items_g`,
                "repeatable": true,
                "id": 'feed',
                "add-label": "+ Thêm ảnh",
                "children": [
                    // Tiêu đề Mô tả
                    {
                        "label": "Tiêu đề mô tả 01",
                        "name": "title_description",
                        "validation": "required"
                    },
                    {
                        "label": "Tiêu đề mô tả 02",
                        "name": "title_description_eng",
                        "validation": "required"
                    },
                    // Mô tả
                    {
                        "label": "Nội dung mô tả chính sách trọng tâm",
                        "name": "description",
                        "validation": "required"
                    },
                    {
                        "type": "eduImage",
                        "name": `ecosystem_image`,
                        "label": "Ảnh chính sách trọng tâm",
                        "parent": 'ecosystem_img_items',
                        "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
                        "help": "Select a png, jpg or gif to upload",
                        "@file-upload-complete": "file-upload-complete"
                    }
                ]
            },
        ]
    },   
    {
        "label": "Câu kết",
        "name": "slogan_end",
        "validation": "required"
    },
    {
        "type": "submit",
        ":disabled": "isLoading",
        "label": "Lưu thông tin trang Hệ sinh thái"
    }
];
export const homePage = [
    // {
    //     "type": "eduImage",
    //     "name": `home_banner_image`,
    //     "label": "Ảnh home banner",
    //     "parent": "home_banner_items",
    //     "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
    //     "help": "Select a png, jpg or gif to upload",
    //     "@file-upload-complete": "file-upload-complete"
    // },
    // {
    //     "type": "text",
    //     "name": `${prefix}_history_title`,
    //     "label": "Lịch sử của Edutalk",
    //     "validation": "required"
    // },
    // {
    //     "type": "group",
    //     "name": `${prefix}_map_items`,
    //     "repeatable": true,
    //     "label": "Lịch sử hình thành",
    //     "add-label": "+ Thêm lịch sử",
    //     "children": [
    //         {
    //             "type": "text",
    //             "name": `${prefix}_map`,
    //             "label": "Năm",
    //         },
    //         {
    //             "type": "text",
    //             "name": `${prefix}_desc_map`,
    //             "label": "Mô tả",
    //         }
    //     ]
    // },
    {
        "type": "eduImage",
        "name": `image_history_desktop`,
        "label": "Ảnh lịch sử hoàn thành desktop",
        "parent": "home_banner_items",
        "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
        "help": "Select a png, jpg or gif to upload",
        "@file-upload-complete": "file-upload-complete"
    },
    {
        "type": "eduImage",
        "name": `image_history_mobile`,
        "label": "Ảnh lịch sử hoàn thành mobile",
        "parent": "home_banner_items",
        "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
        "help": "Select a png, jpg or gif to upload",
        "@file-upload-complete": "file-upload-complete"
    },
    {
        "type": "text",
        "name": `${prefix}_partner_title`,
        "label": "Title chuỗi của Edutalk",
        "validation": "required"
    },
    // block 1
    {
        "type": "group",
        "name": `home_img_items_01`,
        "id": 'home_feed_01',
        "label": "Ảnh chuỗi hệ thống",
        "repeatable": true,
        "add-label": "+ Thêm block",
        "children": [
            {
                "type": "eduImage",
                "name": `home_image_block_01`,
                "label": "Ảnh logo cty trực thuộc edutalk",

                "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
                "help": "Select a png, jpg or gif to upload",
                "@file-upload-complete": "file-upload-complete"
            }
        ]
    },
    // block 2
    {
        "type": "group",
        "name": `home_img_items_02`,
        "id": 'home_feed_02',
        "repeatable": false,
        "add-label": "+ Thêm block",
        "children": [
            {
                "label": "Tiêu đề tự hào là người edu",
                "name": "title_page",
                "validation": "required"
            },

            // Tiêu đề Mô tả
            {
                "type": "eduImage",
                "name": `home_image_block_02`,
                "label": "Ảnh banner Content",
                "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
                "help": "Select a png, jpg or gif to upload",
                "@file-upload-complete": "file-upload-complete"
            },
            // Mô tả
            {
                "label": "Nội dung mô tả (phần tự hào)",
                "name": "description",
                "validation": "required"
            },
        ]
    },
// block 3
    {
        "type": "group",
        "name": `home_img_items_03`,
        "id": 'home_feed_03',
        "repeatable": true,
        "add-label": "+ Thêm block",
        "children": [
            // Tiêu đề Mô tả
            {
                "label": "Tiêu đề mô tả (trong size đối tác chiến lược)",
                "name": "title_description",
                "validation": "required"
            },
            {
                "type": "eduImage",
                "name": `home_image_block_03`,
                "label": "Ảnh banner Content",
                "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
                "help": "Select a png, jpg or gif to upload",
                "@file-upload-complete": "file-upload-complete"
            },
            {
                "type": "eduImage",
                "name": `home_image_logo_block_03`,
                "label": "Ảnh logo",
                "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
                "help": "Select a png, jpg or gif to upload",
                "@file-upload-complete": "file-upload-complete"
            },
            // Mô tả
            {
                "label": "Nội dung mô tả ( trong đối tác chiến lược)",
                "name": "description",
                "validation": "required"
            },
        ]
    },

    {
        "type": "submit",
        ":disabled": "isLoading",
        "label": "Lưu thông tin trang Home Edutalk"
    }
];
export const peopleInEdutalkPage = [
    //banner
    // {
    //     "label": "Title banner",
    //     "name": "level",
    //     "validation": "required"
    // },
    {
        "type": "eduImage",
        "name": `banner_items`,
        "label": "Ảnh banner",
        "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
        "help": "Select a png, jpg or gif to upload",
        "@file-upload-complete": "file-upload-complete"
    },
    //block 01
    {
        "type": "group",
        "name": `leader_edutalk_items`,
        "repeatable": true,
        "add-label": "+ Thêm leader",
        "value": [{}],
        "children": [
            {
                "label": "Họ và tên",
                "name": "name_leader",
                "validation": "required"
            },
            {
                "label": "Vị trí",
                "name": "level",
                "validation": "required"
            },
            {
                "label": "Mô tả",
                "name": "description",
                "validation": "required"
            },
            {
                "type": "eduImage",
                "name": `avata_leader`,
                "label": "Ảnh leader",
                "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
                "help": "Select a png, jpg or gif to upload",
                "@file-upload-complete": "file-upload-complete"
            },
        ]
    },
    //block 02
    {
        "type": "group",
        "name": `personnel_edutalk_items`,
        "repeatable": true,
        "add-label": "+ Thêm personnel",
        "value": [{}],
        "children": [
            {
                "label": "Họ và tên",
                "name": "name_personnel",
                "validation": "required"
            },
            {
                "label": "Vị trí",
                "name": "level",
                "validation": "required"
            },
            {
                "label": "Mô tả",
                "name": "description",
                "validation": "required"
            },
            {
                "type": "eduImage",
                "name": `avata_personnel`,
                "label": "Ảnh member",
                "validation": "mime:image/jpeg,image/jpg,image/png,image/svg+xml",
                "help": "Select a png, jpg or gif to upload",
                "@file-upload-complete": "file-upload-complete"
            },
        ]
    },
    // //block 03
    {
        "label": "Url video",
        "name": "url_video",
        "validation": "required"
    },
    {
        "label": "Tiêu đề mô tả video",
        "name": "title_video",
        "validation": "required"
    },
    {
        "label": "Mô tả video",
        "name": "des_video",
        "validation": "required"
    },
    {
        "type": "submit",
        ":disabled": "isLoading",
        "label": "Lưu thông tin trang Hệ sinh thái"
    }
]

export const contactPage = [
    {
        "label": "Url video",
        "name": "url_video",
        "validation": "required"
    },
    {
        "type": "submit",
        ":disabled": "isLoading",
        "label": "Lưu thông tin trang Hệ sinh thái"
    }
]