<script>
import {CANCEL_TEST_INPUT} from "@/core/services/store/test/testInput.module";

export default {
  name: "ModalCancelSchedule",
  data(){
    return {
     reason:'',
      loading:false
    }
  },
  methods:{
    cancelTask(){
      this.loading = true;
        this.$store.dispatch(CANCEL_TEST_INPUT,{id:this.$route.params.id,data:{reason_cancel:this.reason}}).then((data)=>{
          this.$notify({
            title: 'Thành công',
            message: 'Hủy lịch thành công',
            type: 'success'
          });
          this.$refs['cancel-schedule'].hide()
          this.$emit('cancelSchedule')
        }).catch((e)=>{
          this.$notify.error({
            title: 'Thất bại ',
            message: `${e?.response?.data?.message}`
          });
        }).finally(()=>{
          this.loading = false;
        })
    },
    closeModal(){
      this.$refs['cancel-schedule'].hide()
      this.reason = ''
    }
  },
  props:{
    customerName: {
      type: String,
      default() {
        return " "

      }
    },
  }
}
</script>

<template>
<div>
  <b-modal id="cancel-schedule" ref="cancel-schedule"  centered  hide-footer  title="Hủy lịch kiểm tra đầu vào" >
    <p >Vui lòng nhập lý do để xác nhận hủy lịch test đầu vào của học viên {{this.customerName}}.</p>
    <p >Lý do hủy lịch <span style="color: red">*</span></p>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent>
    <ValidationProvider name="Lý do" rules="required" v-slot="{ errors,classes }"
                        vid="tester_id">
    <el-input
        type="textarea"
        :rows="2"
        placeholder="Nhập lý do hủy lịch"
        v-model="reason">
    </el-input>
      <div class="fv-plugins-message-container">
        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
      </div>
    </ValidationProvider>
    <div class="d-flex justify-content-end mt-2">
      <button type="button" class="btn btn-secondary" style="width: 100px" @click="closeModal()">Hủy bỏ</button>
      <button type="button" class="btn btn-info ml-3" @click="handleSubmit(cancelTask)" style="width: 115px" :disabled="loading"> <i v-if="loading" class="el-icon-loading"></i>Xác nhận</button>
    </div>
      </form>
    </ValidationObserver>
  </b-modal>
</div>
</template>

<style scoped>
/deep/ .my-class {
  color: red;
  font-size: 16px;
  font-weight: bold;
}
</style>