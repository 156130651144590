<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(addPromotion)">
        <div style="width: 100%; height: 1px; background: gray; margin-top: 5px; margin-bottom: 20px"></div>
        <div style="display: grid; grid-template-columns: 6fr 6fr; gap: 17px">
          <ValidationProvider name="Tên khuyến mại" rules="required|max:100" v-slot="{ errors }" vid="name">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Tên khuyến mại <span style="color: red">*</span></span>
              <el-input size="small" maxlength="100" show-word-limit v-model="query.name" placeholder="Nhập tên khuyến mại" clearable/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider name="Giá trị giảm" rules="required" v-slot="{ errors }" vid="value_reduce">
              <div style="display: flex; flex-direction: column; gap: 10px">
                <span class="page-filter__title">
                  Giá trị giảm <span style="color: red">*</span>
                </span>
                <el-select
                  clearable
                  size="small"
                  v-model="query.value_reduce"
                  placeholder="Chọn giá trị giảm"
                >
                  <el-option
                    v-for="value in valueOptions"
                    :key="value"
                    :label="`${value}%`"
                    :value="value"
                  />
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="value_reduce" data-validator="notEmpty" class="fv-help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </ValidationProvider>
         
          <ValidationProvider name="Giá còn lại" rules="required|integer|min_value:0" v-slot="{ errors }" vid="name">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Giá còn lại<span style="color: red">*</span></span>
              <el-input type="number" size="small" v-model="query.value_remaining" clearable placeholder="Nhập giá còn lại"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider name="Mô tả" rules="" v-slot="{ errors }" vid="name">
            <div style="display: flex; flex-direction: column; gap: 10px">
              <span class="page-filter__title">Mô tả thêm<span style="color: red"></span></span>
              <el-input size="small" v-model="query.description" placeholder="Nhập mô tả thêm"/>
              <div class="fv-plugins-message-container">
                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
        </div>

        <div style="display: flex; justify-content: end; margin-top: 20px; padding-bottom: 20px; gap: 10px">
          <el-button size="mini" style="background: rgba(202, 202, 202, 1); border-radius: 5px;" @click="closeDialog(false)">Hủy bỏ</el-button>
          <el-button
              @click="addPromotion"
              size="mini"
              :disabled="loading"
              style="background: #5D04B0; border-radius: 5px; color: white; position:relative;">
            <i v-if="loading" class="el-icon-loading text-white float-righ"></i>
            Xác nhận
          </el-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {CREATE_PROMOTION_GENZ}  from '../../../../core/services/store/rewards-programs/rewardsPrograms.module'

export default {
  props: {
   
  },
  data() {
    return {
     query: {
      name: '',
      value_reduce: '',
      value_remaining: '',
      description: ''
     },
     valueOptions: Array.from({ length: 101 }, (_, i) => i),
      loading: false,
    }
  },

  mounted() {
   
  },

  methods: {
    addPromotion() {
      let name = this.query.name;
      let value = this.query.value_reduce;
      let value_remaining = this.query.value_remaining;
      if(!name.trim() || !value | !value_remaining) {
        this.$notify({
        title: 'Thiếu dữ liệu',
        message: 'Vui lòng nhập đủ dữ liệu',
        type: 'warning'
      });
      return;
      }
      this.loading = true;
      const payload = {
        name,
        value,
        value_remaining,
        description : this.query.description,
        spin_reward_program_id: this.$route.query.promotion_id,
        program_type: 3,
      };

      this.$store.dispatch(CREATE_PROMOTION_GENZ, payload).then((res) => {
        if (!res.data.error) {
          this.$notify({
          title: 'Success',
          message: 'Thêm khuyến mại thành công',
          type: 'success'
        });
       
        this.closeDialog(true);
        }
      }).catch((e) => {
        this.$notify.error({
          title: 'Error',
          message: 'Thêm khuyến mại thất bại'
        });
        this.loading = false;
      });
    },

    closeDialog(is_reload) {
      this.loading = false;
      this.$emit('closeDialog', is_reload)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>