<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Danh sách lớp học của giảng viên</h3>
        </div>
        <div class="card-title">
          <router-link
              class="font-weight-bold font-size-3  btn btn-secondary"
              :to="{name: 'teacher-list'}"
          ><i class="fas fa-angle-double-left"></i> Quay lại
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <div class="form-group">
            <div class="input-group">
              <div class="col-md-3">
                <input type="text" class="form-control" placeholder="Nhập tên lớp học"
                       v-on:input="searchClasses"
                       v-model="query.name"
                       aria-describedby="basic-addon2">
              </div>
            </div>
          </div>
          <div class="example-preview table-responsive">
            <table class="table table-vertical-center">
              <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Tên lớp</th>
                <th scope="col">Tên khóa</th>
                <th scope="col">Tên chi nhánh</th>
                <th scope="col">Lịch học</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in getClassesByTeacher" :key="index">
                <th scope="row">{{++index}}</th>
                <td class="">{{item.name}}</td>
                <td class="">{{item.course.name}}</td>
                <td class="">{{item.branch.name}}</td>
                <td class=""><span v-for="(result,k) in item.schedules" :key="k">
                  {{result.day}} : {{result.start_date}} - {{result.end_date}} <br>
                </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="edu-paginate mx-auto text-center">
        <paginate
            v-model="query.page"
            :page-count="total_pages"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'Trước'"
            :next-text="'Sau'"
            :container-class="'pagination b-pagination'"
            :pageLinkClass="'page-link'"
            :page-class="'page-item'"
            :next-link-class="'next-link-item'"
            :prev-link-class="'prev-link-item'"
            :prev-class="'page-link'"
            :next-class="'page-link'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import {GET_CLASS_BY_TEACHER} from "../../../core/services/store/teacher/teacher.module";

export default {
  name: "View-Teacher",
  data()  {
    return {
      query : {
        name: "",
        page: 1,
        account_position: '',
        is_staff : 2
      },
      id : '',
    }
  },
  computed : {
    ...mapGetters(['getClassesByTeacher','getClassesPaginate']),
    total_pages() {
      return this.getClassesPaginate?.total_pages || 0;
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.query.page = this.$route.query.page;
    this.query.name = this.$route.query.name;
    this.$store.dispatch(GET_CLASS_BY_TEACHER,{
      id: this.id,
      params : {
        page : this.query.page,
        name: this.query.name
      }
    });
  },
  mounted() {
     this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý nhân sự"},
      { title: "Danh sách lớp học" }
    ]);
  },
  methods : {
    clickCallback : function (pageNum){
      this.query.page = pageNum;
      this.getQuery();
    },
    searchClasses : _.debounce(function (){
      this.getQuery();
    },300),

    getQuery : function (){
      this.$router.push({path : '',query : {page : this.query.page, name : this.query.name}});
      this.$store.dispatch(GET_CLASS_BY_TEACHER,{
        id: this.id,
        params : {
          page : this.query.page,
          name: this.query.name
        }
      })
    },
  },
}
</script>

<style scoped>

</style>