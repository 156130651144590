<template>
    <div>
        <div class="card card-custom gutter-b">
            <div class="card-header d-flex justify-content-between">
                <div class="card-title">
                    <h3 class="card-label">Báo cáo DSKH chi nhánh</h3>
                </div>
            </div>
            <div class="card-body">
                <!--begin::Example-->
                <div class="example mb-10">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group" data-app>
                                <label>Chọn tháng
                                    <span class="text-danger"></span></label>
                                <b-form>
                                    <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :return-value.sync="dates"
                                            transition="scale-transition"
                                            offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <b-form-input
                                                    prepend-icon="event"
                                                    id="input-1"
                                                    v-model="show_date"
                                                    type="text"
                                                    placeholder="Chọn tháng"
                                                    v-bind="attrs"
                                                    v-on="on"
                                            ></b-form-input>
                                        </template>
                                        <v-date-picker
                                                v-model="dates"
                                                no-title
                                                scrollable
                                                range
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="saveRange">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </b-form>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Chọn khu vực
                                    <span class="text-danger"></span></label>
                                <multiselect v-model="cache_area" :options="areas"
                                             placeholder="Chọn khu vực"
                                             label="name"
                                             track-by="name"
                                             @input="selectArea($event)"
                                ></multiselect>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Chọn chi nhánh
                                    <span class="text-danger"></span></label>
                                <multiselect v-model="cache_branch" :options="branches"
                                             placeholder="Chọn chi nhánh"
                                             :custom-label="({ name_alias, name }) => `${name_alias ? name_alias : name}`"
                                             track-by="name"
                                             @input="selectBranch($event)"
                                ></multiselect>
                            </div>
                        </div>
                        <div class="col-md-2 mt-9">
                            <button class="btn btn-primary" @click="searchDataDSKH">Tìm kiếm</button>
                        </div>
                    </div>
                    <div class="b-table-sticky-column table-responsive">
                        <table class="table table-hover table-bordered b-table">
                            <tr role="row" class="b-table-busy-slot" v-show="isBusy">
                                <td colspan="3" role="cell" class="">
                                    <div class="text-center text-danger my-2">
                                        <span aria-hidden="true" class="align-middle spinner-border"></span>
                                        <strong>Loading...</strong>
                                    </div>
                                </td>
                            </tr>
                            <thead>
                            <tr>
                                <th class="table-primary b-table-sticky-column" style="border-bottom: none">Ngày trong
                                    tháng
                                </th>
                                <th class="font-size-10" style="min-width: 100px; vertical-align:middle">KQ tháng</th>
                                <th class="font-size-10" v-for="(day,index) in days" :key="index">{{ day }}</th>
                            </tr>
                            </thead>
                            <tbody v-show="!isBusy">
                            <tr>
                                <td class="table-primary b-table-sticky-column">Số lịch hẹn</td>
                                <td class="font-size-10"><b>{{ totalScheduledAppointment }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day,'lich_hen')">{{
                                    renderSchedule(day) }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-primary b-table-sticky-column">Số lịch hẹn bị hủy</td>
                                <td class="font-size-10"><b>{{ totalScheduledCancel }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day,'lich_hen_huy')">{{
                                    renderScheduleCancel(day) }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-primary b-table-sticky-column">Số lượng KH lên test</td>
                                <td class="font-size-10"><b>{{totalCustomerOnTest}}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day,'kh_test')">{{renderCustomerOnTest(day)}}</a>
                                </td>
                            </tr>
                            <tr>
                                <td title="" class="table-primary b-table-sticky-column">Số quyền lợi <a
                                        href="javascript:"
                                        id="so-quyen-loi"><i
                                        class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="so-quyen-loi" title="Lần đầu đóng dưới 1200k!"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ totalBenefit }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day ,'quyen_loi')">{{renderBenefit(day,'so_quyen_loi')}}</a>
                                </td>
                            </tr>
                            <tr>
                                <td title="" class="table-primary b-table-sticky-column">Quyền lợi <a href="javascript:"
                                                                                                      id="quyen-loi"><i
                                        class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="quyen-loi" title="Doanh số lần đầu đóng dưới 1200k!"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ totalBenefitAmount | formatVND }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day ,'quyen_loi')">{{
                                    renderBenefit(day,'quyen_loi') | formatVND }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td title="" class="table-primary b-table-sticky-column">Số lượng hợp đồng nợ <a
                                        href="javascript:" id="hop-dong-no"><i class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="hop-dong-no" title="Hợp đồng công nợ"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ totalDebtContract }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day ,'hop_dong_no')">{{renderDebtContract(day,'so_hop_dong_no')}}</a>
                                </td>
                            </tr>
                            <tr>
                                <td title="" class="table-primary b-table-sticky-column">Doanh số nợ <a
                                        href="javascript:"
                                        id="doanh-so-hop-dong-no"><i
                                        class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="doanh-so-hop-dong-no" title="Doanh số hợp đồng công nợ"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ totalDebtContractAmount | formatVND }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index">
                                    {{renderDebtContract(day,'doanh_so_no') | formatVND}}
                                </td>
                            </tr>
                            <tr>
                                <td title="" class="table-primary b-table-sticky-column">Số lượng hợp đồng mới <a
                                        href="javascript:" id="hop-dong-moi"><i class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="hop-dong-moi" title="Lần đầu đóng từ 1200k trở lên"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ totalNewContract }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day ,'hop_dong_moi')">{{renderNewContract(day,'so_hop_dong_moi')}}</a>
                                </td>
                            </tr>
                            <tr>
                                <td title="" class="table-primary b-table-sticky-column">Doanh số Khách hàng mới <a
                                        href="javascript:" id="doanh-so-KH-moi"><i class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="doanh-so-KH-moi"
                                               title="Doanh số khách hàng mới đóng lần thứ 2 trở đi trong tháng"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ totalNewContractAmount | formatVND}}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day ,'doanh_so_moi')">{{renderNewContract(day,'ds_so_hop_dong_moi')
                                    | formatVND}}</a>
                                </td>
                            </tr>
                            <!--                            <tr>-->
                            <!--                                <td title="" class="table-primary b-table-sticky-column">Tỷ lệ chốt <a-->
                            <!--                                        href="javascript:"-->
                            <!--                                        id="ty-le-chot"><i-->
                            <!--                                        class="fas fa-info-circle"></i></a>-->
                            <!--                                    <b-tooltip target="ty-le-chot" title="Số HĐ mới/ Số KH lên test"-->
                            <!--                                               variant="primary"></b-tooltip>-->
                            <!--                                </td>-->
                            <!--                                <td class="font-size-10"><b>{{ Math.round(contractClosing) }}%</b></td>-->
                            <!--                                <td class="font-size-10" v-for="(day,index) in days" :key="index">-->
                            <!--                                    {{renderContractClosing(day)}}%-->
                            <!--                                </td>-->
                            <!--                            </tr>-->
                            <tr>
                                <td title="" class="table-primary b-table-sticky-column">Số lượng hợp đồng nội bộ <a
                                        href="javascript:" id="hd-noi-bo"><i class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="hd-noi-bo" title="Số lượng HĐ có [Mã giới thiệu] = [Mã nhân sự]"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ totalContractInternal }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day ,'hop_dong_noi_bo')">{{
                                    renderContractInternal(day,'so_hop_dong_noi_bo') }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td title="" class="table-primary b-table-sticky-column">Doanh số nội bộ <a
                                        href="javascript:"
                                        id="ds-noi-bo"><i
                                        class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="ds-noi-bo"
                                               title="Doanh số hợp đồng có [Mã giới thiệu] = [Mã nhân sự]"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ totalContractInternalAmount | formatVND }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{
                                    renderContractInternal(day,'ds_so_hop_dong_noi_bo') | formatVND}}
                                </td>
                            </tr>
                            <tr>
                                <td title="" class="table-primary b-table-sticky-column">Tổng doanh số <a
                                        href="javascript:"
                                        id="tong-ds"><i
                                        class="fas fa-info-circle"></i></a>
                                    <b-tooltip target="tong-ds"
                                               title="Quyền lợi + Doanh số KH mới + Doanh số nợ + Doanh số nội bộ - Rút tiền - Hoàn tiền"
                                               variant="primary"></b-tooltip>
                                </td>
                                <td class="font-size-10"><b>{{ tongDoanhSo | formatVND }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index">{{ renderDoanhSo(day)
                                    | formatVND }}
                                </td>
                            </tr>
                            <tr>
                                <td class="table-primary b-table-sticky-column">Số tiền rút</td>
                                <td class="font-size-10"><b>{{ Math.abs(totalWithDraw) | formatVND }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day ,'rut_tien')">{{
                                    Math.abs(renderRefundMoreThan(day,'withdraw')) | formatVND}}</a>
                                </td>
                            </tr>
                            <tr>
                                <td class="table-primary b-table-sticky-column">Số tiền hoàn</td>
                                <td class="font-size-10"><b>{{ Math.abs(totalRefund) | formatVND }}</b></td>
                                <td class="font-size-10" v-for="(day,index) in days" :key="index"><a href="javascript:"
                                                                                                     @click="showDetailByDate(day ,'hoan_tien')">{{
                                    Math.abs( renderRefundMoreThan(day,'refund') ) | formatVND}}</a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <register-on-staff :showModal="showOnStaff" :dataDetail="dataDetail" :type-on-staff="typeOnStaff"
                               @clicked="clickOnStaff"></register-on-staff>
            <schedule-by-date :showModal="showScheduleDetail" :scheduleData="dataDetail" :type-action="typeAction"
                              @clicked="clickOnSchedule"></schedule-by-date>
            <contract-detail-table :show-modal="showContractDetail" :contract-data="dataDetail"
                                   :type-action="typeAction" @clicked="clickOnContractDetail" v-bind:is-bao-cao-chi-nhanh="true"></contract-detail-table>
        </div>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="true"
                     :on-cancel="onCancel"
                     :is-full-page="fullPage">
            </loading>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Multiselect from 'vue-multiselect';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
    import {GET_AREAS} from "../../../core/services/store/center/area.module";
    import {
        DSKH_BRANCH,
        SHOW_DETAIL_BY_DATE
    } from "../../../core/services/store/report/report.module";
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';
    import '@/assets/sass/custom/dskh-doi/dskh_doi.scss';

    import RegisterOnStaff from "./RegisterOnStaff";
    import ScheduleByDate from "./ScheduleByDate";
    import ContractDetailTable from "./ContractDetailTable";

    const _ = require('lodash');
    export default {
        name: "DSKHBranch",
        components: {
            Multiselect, DatePicker, Loading, RegisterOnStaff, ScheduleByDate, ContractDetailTable
        },
        data() {
            return {
                isBusy: false,
                /* query */
                query: {
                    start_date: '',
                    branch_id: '',
                    area_id: '',
                },
                /* date-picker */
                dates: [],
                menu: false,
                show_date: '',
                /* options */
                branchesByCenter: [],
                branches: [],
                areas: [],
                days: [],
                positions: [{name: 'Sale Expert', value: 5}, {name: 'Sale Member', value: 6}, {
                    name: 'Sale Intern',
                    value: 7
                }],
                /*Cache */
                cache_status: '',
                cache_current_account_type: '',
                cache_branch: '',
                cache_area: '',
                /*Loader */
                isLoading: false,
                fullPage: true,
                /*payload */
                payload: {
                    limit: 1000000,
                    status: 1,
                    area_id: '',
                    is_with : 1,
                },
                /* data response*/
                eventRegisters: [],
                dataDetail: [],
                leadersOnWork: [],
                scheduledAppointments: [],
                scheduledCancels: [],
                customersOnTest: [],
                benefit: [],
                debtContract: [],
                newContract: [],
                contractInternal: [],
                refundMoreThan: [],
                idCreated: [],
                /* is show variable */
                showOnStaff: false,
                typeOnStaff: false,
                showScheduleDetail: false,
                showContractDetail: false,
                typeAction: '',
                /* total variable */
                totalScheduledAppointment: 0,
                totalScheduledCancel: 0,
                totalCustomerOnTest: 0,
                totalBenefit: 0,
                totalBenefitAmount: 0,
                totalDebtContract: 0,
                totalDebtContractAmount: 0,
                totalNewContract: 0,
                totalNewContractAmount: 0,
                totalContractInternal: 0,
                totalContractInternalAmount: 0,
                totalWithDraw: 0,
                totalRefund: 0,
                contractClosing: 0,
                tongDoanhSo: 0,
            };
        },
        computed: {},
        async created() {
            this.isBusy = true;
            await this.getBranches();
            await this.getAreas();
            await this.setParams();
            await this.getQuery();
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý tài chính"},
                {title: "Báo cáo DSKH chi nhánh"}
            ]);
        },
        methods: {
            /* all get data function */
            getBranches: async function () {
                await this.$store.dispatch(GET_BRANCHES, this.payload).then((res) => {
                    this.branches = res.data.data;
                    this.branchesByCenter = res.data.data;
                    this.addFirstSelectExample(this.branches, '__Chọn__')
                });
            },
            getAreas: async function () {
                await this.$store.dispatch(GET_AREAS, this.payload).then((res) => {
                    this.areas = res.data.data;
                    this.addFirstSelectExample(this.areas, '__Chọn__')
                });
            },
            addFirstSelectExample(obj, text) {
                obj.unshift({
                    name: text
                })
            },
            searchDataDSKH: function () {
                this.isBusy = true;
                this.getQuery();
            },
            getQuery: async function () {
                this.query.idCreated = [];
                await this.$store.dispatch(DSKH_BRANCH, this.query).then((res) => {
                    this.days = res.data.dataDays;
                    this.idCreated = res.data.idCreated;
                    this.newContract = res.data.newContract;
                    this.eventRegisters = res.data.eventRegister;
                    this.leadersOnWork = res.data.leaderRegister;
                    this.scheduledAppointments = res.data.scheduledAppointment;
                    this.customersOnTest = res.data.customersOnTest;
                    this.scheduledCancels = res.data.scheduledCancel;
                    this.benefit = res.data.benefit;
                    this.debtContract = res.data.debtContract;
                    this.contractInternal = res.data.contractInternal;
                    this.refundMoreThan = res.data.refund;
                    this.isBusy = false;
                    this.countTotalStaffOnWork();
                });
            },
            /* all select function */
            selectBranch: function ($event) {
                this.query.branch_id = $event != null ? $event.id : '';
                this.pushParams();
            },
            selectArea: function ($event) {
                this.payload.area_id = $event != null ? $event.id : '';
                this.query.area_id = $event != null ? $event.id : '';
                this.cache_branch = '';
                this.getBranches()
                this.pushParams();
            },
            saveRange: function () {
                this.$refs.menu.save(this.dates);
                let range = this.dates.sort(function (left, right) {
                    return moment(left) - moment(right);
                }).map(item => moment(item).format('DD/MM/YYYY'));
                this.show_date = range.join(' - ');
                this.query.start_date = this.show_date;
                this.pushParams();
            },
            setParams: async function () {
                let branch_id = this.query.branch_id = this.$route.query.branch_id ? this.$route.query.branch_id : '';
                this.cache_branch = _.find(this.branches, function (branch) {
                    return branch.id === parseInt(branch_id);
                });
                let area_id = this.query.area_id = this.$route.query.area_id ? this.$route.query.area_id : '';
                this.cache_area = _.find(this.areas, function (area) {
                    return area.id === parseInt(area_id);
                });
                this.query.sale = this.$route.query.sale ? this.$route.query.sale : '';
                this.show_date = this.query.start_date = this.$route.query.start_date ? this.$route.query.start_date : '';
                this.query.nguon = this.$route.query.nguon ? this.$route.query.nguon : '';
            },
            pushParams: function () {
                this.$router.push({path: '/reports/dskh-branch', query: this.query});
            },
            onCancel: function () {
                console.log('User cancelled the loader.')
            },
            normalizer: function (node) {
                return {
                    id: node.id,
                    label: node.name,
                }
            },
            getFullDate: function (date) {
                let d = new Date();
                let year = d.getFullYear();
                date += '/' + year;
                return date;
            },
            /* all click function */
            showDetailByDate: async function (date, action) {
                this.query.date = this.getFullDate(date);
                this.query.idCreated = this.idCreated;
                this.query.action = action;
                this.query.report_type = 'Kinh doanh văn phòng';
              await this.$store.dispatch(SHOW_DETAIL_BY_DATE, this.query).then((res) => {
                    this.dataDetail = res.data;
                });
                switch (action) {
                    case 'lich_hen' :
                        this.typeAction = 'lich_hen';
                        this.showScheduleDetail = true;
                        break;
                    case 'lich_hen_huy' :
                        this.typeAction = 'lich_hen_huy';
                        this.showScheduleDetail = true;
                        break;
                    case 'kh_test' :
                        this.typeAction = 'kh_test';
                        this.showScheduleDetail = true;
                        break;
                    case 'quyen_loi' :
                        this.typeAction = 'quyen_loi';
                        this.showContractDetail = true;
                        break;
                    case 'hop_dong_no' :
                        this.typeAction = 'hop_dong_no';
                        this.showContractDetail = true;
                        break;
                    case 'hop_dong_moi' :
                        this.typeAction = 'hop_dong_moi';
                        this.showContractDetail = true;
                        break;
                    case 'doanh_so_moi' :
                        this.typeAction = 'doanh_so_moi';
                        this.showContractDetail = true;
                        break;
                    case 'hop_dong_noi_bo' :
                        this.typeAction = 'hop_dong_noi_bo';
                        this.showContractDetail = true;
                        break;
                    case 'hoan_tien' :
                        this.typeAction = 'hoan_tien';
                        this.showContractDetail = true;
                        break;
                    case 'rut_tien' :
                        this.typeAction = 'rut_tien';
                        this.showContractDetail = true;
                        break;
                }
            },
            clickOnStaff: function () {
                this.showOnStaff = false;
            },
            clickOnSchedule: function () {
                this.showScheduleDetail = false;
            },
            clickOnContractDetail: function () {
                this.showContractDetail = false;
            },
            /* all render function */
            renderData: function (day, data, type = '') {
                let value = _.find(data, function (item) {
                    return item.day === day;
                });
                if (type === 'so_quyen_loi' || type === 'so_hop_dong_no' || type === 'so_hop_dong_moi' || type === 'so_hop_dong_noi_bo') {
                    return value ? value.total : 0;
                } else if (type === 'quyen_loi' || type === 'doanh_so_no' || type === 'ds_so_hop_dong_moi' || type === 'ds_so_hop_dong_noi_bo') {
                    return value ? value.amount : 0;
                } else if (type === 'withdraw') {
                    let value = _.find(data, function (item, key) {
                        return key === day;
                    });
                    return value && value.withdraw ? value.withdraw : 0;
                } else if (type === 'refund') {
                    return value && value.refund ? value.refund : 0;
                } else {
                    return value ? value.number : 0;
                }
            },
            renderSchedule: function (day) {
                return this.renderData(day, this.scheduledAppointments);
            },
            renderScheduleCancel: function (day) {
                return this.renderData(day, this.scheduledCancels);
            },
            renderCustomerOnTest: function (day) {
                return this.renderData(day, this.customersOnTest);
            },
            renderBenefit: function (day, type) {
                return this.renderData(day, this.benefit, type);
            },
            renderDebtContract: function (day, type) {
                return this.renderData(day, this.debtContract, type);
            },
            renderNewContract: function (day, type) {
                return this.renderData(day, this.newContract, type);
            },
            renderContractInternal: function (day, type) {
                return this.renderData(day, this.contractInternal, type);
            },
            renderContractClosing: function (day) {
                return this.renderCustomerOnTest(day) !== 0 ? Math.round(this.renderNewContract(day, 'so_hop_dong_moi') / this.renderCustomerOnTest(day) * 100) : 0;
            },
            renderRefundMoreThan: function (day, type) {
                return this.renderData(day, this.refundMoreThan, type);
            },
            renderDoanhSo: function (day) {
                // Quyền lợi + Doanh số KH mới + Doanh số nợ + Doanh số nội bộ - Rút tiền - Hoàn tiền
                return this.renderBenefit(day, 'quyen_loi') + this.renderNewContract(day, 'ds_so_hop_dong_moi')
                    + this.renderDebtContract(day, 'doanh_so_no') + this.renderContractInternal(day, 'ds_so_hop_dong_noi_bo')
                    + this.renderRefundMoreThan(day, 'withdraw') + this.renderRefundMoreThan(day, 'refund');
            },
            /* all sum total function */
            countTotalStaffOnWork: function () {
                this.totalScheduledAppointment = _.reduce(this.scheduledAppointments, function (result, item, key) {
                    result += item.number;
                    return result;
                }, 0);
                this.totalScheduledCancel = _.reduce(this.scheduledCancels, function (result, item, key) {
                    result += item.number;
                    return result;
                }, 0);
                this.totalCustomerOnTest = _.reduce(this.customersOnTest, function (result, item, key) {
                    result += item.number;
                    return result;
                }, 0);
                this.totalBenefit = _.reduce(this.benefit, function (result, item, key) {
                    result += item.total;
                    return result;
                }, 0);
                this.totalBenefitAmount = _.reduce(this.benefit, function (result, item, key) {
                    result += item.amount;
                    return result;
                }, 0);
                this.totalNewContract = _.reduce(this.newContract, function (result, item, key) {
                    result += item.total;
                    return result;
                }, 0);
                this.totalNewContractAmount = _.reduce(this.newContract, function (result, item, key) {
                    result += item.amount;
                    return result;
                }, 0);
                this.totalDebtContract = _.reduce(this.debtContract, function (result, item, key) {
                    result += item.total;
                    return result;
                }, 0);
                this.totalDebtContractAmount = _.reduce(this.debtContract, function (result, item, key) {
                    result += item.amount;
                    return result;
                }, 0);
                this.totalContractInternal = _.reduce(this.contractInternal, function (result, item, key) {
                    result += item.total;
                    return result;
                }, 0);
                this.totalContractInternalAmount = _.reduce(this.contractInternal, function (result, item, key) {
                    result += item.amount;
                    return result;
                }, 0);
                this.totalRefund = _.reduce(this.refundMoreThan, function (result, item, key) {
                    if (item.refund) {
                        result += item.refund;
                    }
                    return result;
                }, 0);
                this.totalWithDraw = _.reduce(this.refundMoreThan, function (result, item, key) {
                    if (item.withdraw) {
                        result += item.withdraw;
                    }
                  return result;
                }, 0);
                this.contractClosing = this.totalCustomerOnTest > 0 ? this.totalNewContract / this.totalCustomerOnTest * 100 : 0;
                // Quyền lợi + Doanh số KH mới + Doanh số nợ + Doanh số nội bộ - Rút tiền - Hoàn tiền
                this.tongDoanhSo = this.totalBenefitAmount + this.totalNewContractAmount + this.totalDebtContractAmount + this.totalContractInternalAmount + this.totalWithDraw + this.totalRefund;
            }
        },
    };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
