<template>
    <div class="card card-custom gutter-b">
        <div class="card-header d-flex justify-content-between">
            <div class="card-title">
                <h3 class="card-label">Danh sách sự kiện</h3>
            </div>
            <div class="card-title">
                <router-link
                        class="font-weight-bold font-size-3  btn btn-success"
                        :to="{ name: 'event-web-create' }"
                >Thêm mới
                </router-link>
            </div>
        </div>
        <div class="card-body">
            <!--begin::Example-->
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <el-input placeholder="Tiêu đề" v-model="query.keyword"></el-input>
                    </div>
                    <!--end::Form-->
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <el-select v-model="query.website" filterable class="w-100" placeholder="Website" clearable>
                            <el-option
                                    v-for="item in websites"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                <span style="float: left">{{ item.label }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <el-select filterable clearable class="w-100" placeholder="Loại bài viết" v-model="query.type"
                        >
                            <el-option
                                    v-for="item in types"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                <span style="float: left">{{ item.label }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <el-select v-model="query.status" filterable class="w-100" placeholder="Trạng thái" clearable>
                            <el-option
                                    v-for="item in status"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                <span style="float: left">{{ item.label }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <date-picker placeholder="Ngày đăng" range v-model="query.created_at" format="DD-MM-YYYY"
                                     valueType="YYYY-MM-DD"></date-picker>
                    </div>
                    <!--end::Form-->
                </div>
                <div class="col-md-2">
                    <div class="d-flex justify-content-between">
                        <button class="btn btn-primary font-weight-bold mr-2"
                                :class="{'spinner spinner-white spinner-left': is_disable_search}"
                                @click="searchData">
                            <i class="fa fa-search"></i> Tìm kiếm
                        </button>
                    </div>
                </div>
            </div>

            <div class="example mb-10">
                <div class="example-preview table-responsive">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">Website</th>
                            <th scope="col">Loại bài viết</th>
                            <th scope="col">Tiêu đề</th>
                            <th scope="col">Mô tả ngắn</th>
                            <th scope="col" class="min-w-150px">Trạng thái</th>
                            <th scope="col" class="min-w-150px">Ngày đăng</th>
                            <th scope="col">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="(item, index) in events" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.website }}</td>
                            <td>{{ item.type }}</td>
                            <td>{{ item.title }}</td>
                            <td>{{ item.short_description }}</td>
                            <td>
                                <button v-if="item.status" href="#" class="btn btn-success button-active">
                                    <i class="fa fa-check-circle"></i> Hoạt động
                                </button>
                                <button v-else href="#" class="btn btn-success button-not-active">
                                    <i class="fa fa-minus-circle"></i> Ẩn
                                </button>
                            </td>
                            <td>{{ item.created_at | formatDateTime }}</td>
                            <td>
                                <router-link :to="{ name: 'event-web-edit', params: {id : item.id} }" title="Sửa"
                                             class="btn btn-sm btn-icon mr-2 btn-outline-info"><i
                                        class="fas fa-pen-nib"></i>
                                </router-link>
                                <a title="Xóa" @click="deleteEvent(item.id)" href="javascript:"
                                   class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="edu-paginate mx-auto text-center">
            <paginate
                    v-model="page"
                    :page-count="last_page"
                    :page-range="3"
                    :margin-pages="1"
                    :click-handler="clickCallback"
                    :prev-text="'Trước'"
                    :next-text="'Sau'"
                    :container-class="'pagination b-pagination'"
                    :pageLinkClass="'page-link'"
                    :next-link-class="'next-link-item'"
                    :prev-link-class="'prev-link-item'"
                    :prev-class="'page-link'"
                    :next-class="'page-link'"
                    :page-class="'page-item'"
            >
            </paginate>
        </div>
    </div>
</template>
<script>
    import Swal from "sweetalert2";
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import Multiselect from 'vue-multiselect'
    import {GET_EVENT_WEB, DELETE_EVENT_WEB} from "../../../core/services/store/event-web/event-web.module";
    // Date picker
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    const _ = require('lodash');

    export default {
        components: {Multiselect, DatePicker},
        name: "EventWebsiteIndex",
        data() {
            return {
                is_disable_search: false,
                events: [],
                websites: [
                    {
                        value: 1,
                        label: 'Tất cả',
                    },
                    {
                        value: 2,
                        label: 'Edutalk',
                    },
                    {
                        value: 3,
                        label: 'STVT',
                    },
                    {
                        value: 4,
                        label: 'Educoach',
                    },
                    {
                        value: 5,
                        label: 'Ielts Mentor',
                    },
                ],
                types: [
                    {
                        value: 'normal',
                        label: 'Thông thường',
                    },
                    {
                        value: 'highlights',
                        label: 'Nổi bật',
                    },
                ],
                status: [
                    {
                        value: 0,
                        label: 'Ẩn',
                    },
                    {
                        value: 1,
                        label: 'Hiển thị',
                    },
                ],
                // Paginate
                page: 1,
                last_page: 1,
                query: {
                    keyword: '',
                    website: '',
                    type: '',
                    status: '',
                    created_at: '',
                },
            }
        },
        mounted() {
            this.getList();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý sự kiện", route: 'profile-1'},
                {title: "Danh sách sự kiện"}
            ]);
        },
        methods: {
            searchData() {
                this.page = 1
                this.is_disable_search = true
                this.pushParamsUrl()
                this.callApiGetList()
            },
            pushParamsUrl() {
                this.$router.push({
                    name: 'event-web-index',
                    query: {
                        page: this.page,
                        keyword: this.query.keyword,
                        website: this.query.website,
                        type: this.query.type,
                        status: this.query.status,
                        created_at: this.query.created_at,
                    }
                })
            },
            handleQueryCenter($event) {
                let id = $event.id
                this.query.center_id = id
            },
            getList() {
                this.pullParamsUrl()
                this.callApiGetList()
            },
            pullParamsUrl() {
                this.page = parseInt(this.$route.query.page);
                this.query.keyword = this.$route.query.keyword;
                this.query.website = this.$route.query.website != undefined && this.$route.query.website != '' ? parseInt(this.$route.query.website) : 1;
                this.query.type = this.$route.query.type;
                this.query.status = this.$route.query.status != undefined && this.$route.query.status != '' ? parseInt(this.$route.query.status) : '';
                this.query.created_at = this.$route.query.created_at;
            },
            clickCallback(obj) {
                this.page = obj
                this.$router.push({path: '', query: {page: this.page}})
                this.callApiGetList()
            },
            callApiGetList() {
                this.$store.dispatch(GET_EVENT_WEB, {
                    page: this.page,
                    keyword: this.query.keyword,
                    website: this.query.website,
                    type: this.query.type,
                    status: this.query.status,
                    created_at: this.query.created_at,
                }).then((res) => {
                    if (!res.data.error) {
                        this.is_disable_search = false;
                        this.events = res.data;
                        this.last_page = res.pagination.last_page;
                    }
                });
            },
            deleteEvent(id) {
                Swal.fire({
                    title: "Bạn chắc chắn muốn xóa?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                    cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire("Xóa", "", "success");
                        this.$store.dispatch(DELETE_EVENT_WEB, id).then((res) => {
                            if (res.error) {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'danger',
                                    solid: true
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Xóa',
                                    variant: 'success',
                                    solid: true
                                });
                                this.getList()
                            }
                        });
                    }
                });
            },
        }
    }
</script>
<style>
    .mx-datepicker {
        width: 100% !important;
    }

    .multiselect__tags {
        padding: 3px 40px 0 9px !important;
        min-height: 38px !important;
    }

    .multiselect {
        min-height: 38px !important;
    }

    .mx-input {
        min-height: 38px !important;
    }

    .button-active {
        position: static;
        background: #49d746 !important;
        border: #49d746 !important;
        border-radius: 5px !important;
    }

    .button-not-active {
        position: static;
        background: #F64E60 !important;
        border: #F64E60 !important;
        border-radius: 5px !important;
    }

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
