<template>
    <div class="card card-custom">
        <div class="card-header">
            <h3 class="card-title">
                Danh sách khóa học không có hợp đồng
            </h3>
            <div class="card-toolbar">
                <div class="example-tools justify-content-center">
                    <span class="example-toggle" data-toggle="tooltip" title="View code"></span>
                    <span class="example-copy" data-toggle="tooltip" title="Copy code"></span>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card-body">
              <div class="form-group">
              <label class="d-flex justify-content-center">Tìm học viên<span></span></label>
              <div class="d-flex justify-content-center">
                <SelectCustomer minimumInputLength="6" placeholder="Nhập mã học viên hoặc số điện thoại"
                                method="GET"
                                :url="getUserUrl"
                                @input="selectCustomer($event)"
                >
                </SelectCustomer>
              </div>
            </div>
            </div>
          </div>
          <div class="col-md-12" v-if="show_detail">
            <div class="card-body">
              <span class="label label-xl label-light-info label-inline my-2 font-weight-bolder">Số dư tài khoản: {{ formatPrice(user.cash,' VNĐ') }}</span>
              <div class="example mb-10">
                <div class="example-preview table-responsive overflow-auto">
                  <div class="example-preview">
                    <table class="table table-vertical-center b-table">
                      <thead class="thead-light">
                      <tr>
                        <th scope="col" class="min-w-50px">STT</th>
                        <th scope="col" class="min-w-100px">Mã khóa học</th>
                        <th scope="col" class="min-w-100px">Tên lớp</th>
                        <th scope="col" class="min-w-100px">Khóa học</th>
                        <th scope="col" class="min-w-100px">Giá khóa</th>
                        <th scope="col" class="min-w-150px">Đã trả</th>
                        <th scope="col" class="min-w-100px">Trung tâm</th>
                        <th scope="col" class="min-w-150px">Ngày tạo</th>
                        <th scope="col" class="min-w-90px">Hành động</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in register_courses" :key="index" v-if="item.contract_id == 0">
                        <td>{{ index + 1 }}</td>
                        <td>HV-{{ item.id }}</td>
                        <td>{{ item.classes ? item.classes.name : 'Chưa có lớp' }}</td>
                        <td>{{ item.course ? item.course.name : '' }}</td>
                        <td>{{ formatPrice(item.fee - item.promotion_fee) }}</td>
                        <td>{{ formatPrice(item.paid) }}</td>
                        <td>{{ item.center ? item.center.name : (item.course && item.course.center ? item.course.center.name : '') }}</td>
                        <td>{{ item.created_at | formatDateTimeAsia }}</td>
                        <td>
                          <a v-if="item.paid < item.fee" title="Trả nợ khóa" @click="spendCourse(item)" href="javascript:" class="btn btn-sm btn-icon btn-outline-info">
                            <i class="fas fa-money-bill"></i>
                          </a>
                          <a title="Hủy" @click="deleteCourse(item.id)" href="javascript:" class="btn btn-sm btn-icon btn-outline-danger ml-2 mb-1">
                            <i class="fas fa-trash"></i>
                          </a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import Multiselect from 'vue-multiselect';
    import SelectCustomer from "@/view/content/SelectCustomer";
    import {GET_USER} from '@/core/services/ajaxUrl';
    import {GET_USER_BY_ID} from "../../../core/services/store/user/users.module";
    import Swal from 'sweetalert2';
    import {
        DESTROY_STUDENT_REGISTER_COURSE,
        GET_REGISTER_COURSES_BY_USER, SPEND_STUDENT_REGISTER_COURSE
    } from "../../../core/services/store/contract/student-register-course.module";
    import {formatPrice} from "../../../core/filters";

    export default {
        name: "TieuKhoaHoc",
        data() {
            return {
                price: 0,
                user: {
                  id: 0,
                  cash: 0
                },
                register_courses: [],
                show_detail: false,
                isDebt: false,
                contract_id: '',
                money: {
                    decimal: ',',
                    thousands: ',',
                    prefix: '',
                    suffix: ' VNĐ',
                    precision: 0,
                    masked: false,
                }
            };
        },
        components: {
            Multiselect, SelectCustomer
        },
        computed: {
            getUserUrl: function () {
                return GET_USER
            },
            total_pages() {
                return 1;
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: 'Quản lý tài chính'},
                {title: 'Hủy, tiêu khóa học không có hợp đồng'},
            ])
        },
        methods: {
          moveSuccess(data){
            this.user.cash = data.cash;
          },
            selectCustomer: function ($event) {
                this.getUserById($event)
            },
            getUserById(id) {
              if(id){
                this.$store.dispatch(GET_USER_BY_ID, id).then((res) => {
                  if (!res.error) {
                    this.user = res.data
                    this.show_detail = true
                  }
                });
                this.$store.dispatch(GET_REGISTER_COURSES_BY_USER, {
                    user_id: id
                }).then((res) => {
                    if (!res.error) {
                        this.register_courses = res.data
                    }
                });
              } else {
                this.show_detail = false
              }
            },
            deleteCourse(id) {
                Swal.fire({
                    title: "Bạn chắc chắn muốn hủy?",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                  cancelButtonText: "Không",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(DESTROY_STUDENT_REGISTER_COURSE, {
                            course_student_id: id
                        }).then((res) => {
                            if (!res.error) {
                                this.getUserById(this.user.id)
                                Swal.fire("Hủy khóa học thành công", "", "success")
                            } else {
                                console.log(res.error)
                            }
                        });
                    }
                });
            },
            spendCourse(item) {
                let needPay = formatPrice(item.fee - item.paid);
                let cashLeft = this.user.cash > needPay ? this.user.cash - needPay : 0;
                cashLeft = formatPrice(cashLeft)
                let course_name = item.course ? item.course.name : '';
                Swal.fire({
                    title: "<strong>Bạn muốn thanh toán tiền cho</strong>",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Đồng ý",
                  cancelButtonText: "Không",
                    html: '<p>Khóa học: <b>'+ course_name +'</b></p>' +
                          '<p>Số tiền cần trả: <b>' + needPay + '</b></p>' +
                          '<p>Số dư còn lại: <b>' + cashLeft + '</b></p>'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(SPEND_STUDENT_REGISTER_COURSE, {
                            course_student_id: item.id
                        }).then((res) => {
                            if (!res.error) {
                                this.getUserById(this.user.id)
                                Swal.fire("Tiêu khóa học thành công", "", "success");
                            } else {
                                Swal.fire(res.message, "", "error");
                            }
                        });
                    }
                });
            }
        },
    }
</script>
<style>
    #select-customer{
      width: 50%;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
