import ApiService from "@/core/services/api.service";
//action types

export const GET_STUDENT_ALL_CENTER = "get_student_all_center";


const state = {
    errors: null,
    roles: [],
    accountTypes: [],
    accountType: '',
};

const getters = {};
// Get list student in center
const actions = {
    [GET_STUDENT_ALL_CENTER](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("center-user", credentials)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(() => {
                });
        });
    },
};

const mutations = {};

export default {
    state,
    actions,
    mutations,
    getters
};
