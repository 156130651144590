<template>
  <div class="card card-custom gutter-b">
    <div class="card-header py-4 d-flex align-items-center" v-if="schedule_detail">
      <div>
        <h1 style="font-size: 18px" class="mb-0">Quản lí dữ liệu BTVN lộ trình {{ schedule_detail.name }} - Buổi
          {{ lesson }}</h1>
      </div>
      <div>
        <b-button variant="success" @click="createGroupQuestion()">Thêm nhóm câu hỏi</b-button>
      </div>
    </div>
    <div v-else>
      <h4 class="text-center m-4">Lộ trình không tồn tại</h4>
    </div>
    <div class="card-body" v-if="schedule_detail">
      <div class="example mb-10">
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th>Group ID</th>
              <th>title</th>
              <th>Description</th>
              <th>Script</th>
              <th>Audio</th>
              <th>Image</th>
              <th>Video</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody v-if="lesson_detail?.data?.length > 0">
            <template v-for="(item, index) in lesson_detail.data">
              <template>
                <tr v-bind:key="item.id + 'a'">
                  <th>{{ item.id }}</th>
                  <th>{{ item.title }}</th>
                  <th>{{ item.description }}</th>
                  <th>{{ item.script }}</th>
                  <th>{{ item.audio }}</th>
                  <th>{{ item.image }}</th>
                  <th>{{ item.video }}</th>
                  <th>{{ item.type }}</th>
                  <th>
                    <button class="font-weight-bold font-size-3 btn btn-info float-left mx-4"
                            @click="editGroupQuestion( item.id, index, item)"
                    ><i class="el-icon-edit"></i></button>
                  </th>
                  <th>
                    <button class="font-weight-bold font-size-3 btn btn-danger float-left mx-4"
                            @click="deleteGroupQuestion( item.id, index, item, open())"
                    ><i class="fas fa-trash"></i></button>
                  </th>
                </tr>
              </template>
              <template>
                <tr v-bind:key="item.id + 'b'">
                  <td>Question ID</td>
                  <td>title</td>
                  <td>correct answer</td>
                  <td></td>
                  <td>audio</td>
                  <td>image</td>
                  <td>video</td>
                  <td>score</td>
                  <td colspan="2">action</td>
                </tr>
              </template>
              <template v-for="(item1, index1) in item.questions">
                <tr v-bind:key="item1.id + 'c'">
                  <td>{{ item1.id }}</td>
                  <td>{{ item1.title }}</td>
                  <td>{{ item1.correct_answer }}</td>
                  <td>{{ item1.script }}</td>
                  <td>{{ item1.audio }}</td>
                  <td>{{ item1.image }}</td>
                  <td>{{ item1.video }}</td>
                  <td>{{ item1.score }}</td>
                  <td>
                    <button class="font-weight-bold font-size-3 btn btn-success float-left mx-4"
                            @click="editQuestion( item1.id, index1, item1)"
                    ><i class="el-icon-edit"></i></button>
                  </td>
                  <th>
                    <button class="font-weight-bold font-size-3 btn btn-danger float-left mx-4"
                    type="text" @click="deleteQuestion(item1.id, index1, item1)"
                    ><i class="fas fa-trash"></i></button>
                  </th>
                </tr>
              </template>
              <template>
                <tr v-bind:key="item.id + 'd'">
                  <td></td>
                </tr>
              </template>
              <tr>
                <td colspan="9">
                  <b-button variant="success" @click="createQuestion( item)">Thêm câu hỏi</b-button>
                </td>
              </tr>
            </template>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="9" class="event-title no-data">{{ lesson_detail.message }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <el-dialog :title="isCreatGroupQuestion?'Thêm mới nhóm câu hỏi':'Chỉnh sửa nhóm câu hỏi'" :visible.sync="formEditGroup">
      <el-form :model="form">
        <el-form-item label="Title">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="Title"
              v-model="form_groupquestion.title"
              autosize>
          </el-input>
        </el-form-item>
        <el-form-item label="Description">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="Description"
              v-model="form_groupquestion.description"
              autosize>
          </el-input>
        </el-form-item>
        <el-form-item label="Script">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="Script"
              v-model="form_groupquestion.script"
              autosize>
          </el-input>
        </el-form-item>
        <el-form-item label="Audio">
          <el-input v-model="form_groupquestion.audio" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Image">
          <el-input v-model="form_groupquestion.image" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Video">
          <el-input v-model="form_groupquestion.video" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Type">
          <el-input v-model="form_groupquestion.type" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button @click="formEditGroup = false">Hủy</el-button>
            <el-button style="background-color:#8950fc; color: white" @click="submitGroupQuestion" :disabled="isDisable">Xác nhận</el-button>
        </span>
    </el-dialog>
    <el-dialog :title="isCreatQuestion?'Thêm mới câu hỏi':'Chỉnh sửa câu hỏi'"
              :visible.sync="formEditQuestion"
    >
      <el-form :model="form">
        <el-form-item label="Title">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="title"
              v-model="form_question.title"
              autosize>
          </el-input>
        </el-form-item>
        <el-form-item label="Đáp án đúng">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="Description"
              v-model="form_question.correct_answer"
              autosize>
          </el-input>
        </el-form-item>
        <el-form-item label="Audio">
          <el-input v-model="form_question.audio" autocomplete="off" placeholder="Audio"></el-input>
        </el-form-item>
        <el-form-item label="Image">
          <el-input v-model="form_question.image" autocomplete="off" placeholder="Image"></el-input>
        </el-form-item>
        <el-form-item label="Video">
          <el-input v-model="form_question.video" autocomplete="off" placeholder="Video"></el-input>
        </el-form-item>
        <el-form-item label="Score">
          <el-input v-model="form_question.score" autocomplete="off" placeholder="Score"></el-input>
        </el-form-item>
        <div class="m-4">
          <p class="float-left"
          > Answer </p>
          <!-- <button class="font-weight-bold font-size-3 btn btn-success float-right mx-4"
              @click="addAnswer"
          >Add answer</button> -->
          <el-button @click="addAnswer" class="font-weight-bold font-size-3 btn btn-success float-right mx-4">Add
            answer
          </el-button>
        </div>
        <el-table
            :data="form_question.answers"
            style="width: 100%">
          <el-table-column prop="id" label="ID" v-if="!isCreatQuestion"></el-table-column>
          <el-table-column prop="content" label="Content">
            <template slot-scope="scope">
              <el-input v-model="scope.row.content" autocomplete="off" placeholder="Score"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="image" label="Image">
            <template slot-scope="scope">
              <el-input v-model="scope.row.image" autocomplete="off" placeholder="Image"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="audio" label="Audio">
            <template slot-scope="scope">
              <el-input v-model="scope.row.audio" autocomplete="off" placeholder="Audio"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="position" label="Position">
            <template slot-scope="scope">
              <el-input v-model="scope.row.position" autocomplete="off" placeholder="Position"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Action">
            <template slot-scope="scope">
              <el-button @click="deleteAnswer(scope.$index, scope.row.id)" class="btn btn-danger"><i
                  class="el-icon-delete-solid"></i></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button @click="formEditQuestion = false">Hủy</el-button>
            <el-button style="background-color:#8950fc; color: white" @click="submitQuestion" :disabled="isDisable">Xác nhận</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import Multiselect from 'vue-multiselect';
import {
  SHOW_SCHEDULE_COURSE,
  EDIT_GROUP_QUESTION,
  EDIT_QUESTION, ADD_GROUP_QUESTION, ADD_QUESTION,
  DELETE_GROUP_QUESTION,
  DELETE_QUESTION
} from '../../../core/services/store/course/classes.module';

import {clone} from 'lodash';

export default {
  name: "Off-Lesson",

  components: {
    Multiselect
  },
  data() {
    return {
      page: 1,
      data: [],
      schedule_detail: [],
      lesson_detail: {},
      lesson: this.$route.query.lesson ?? 0,
      schedule_id: this.$route.params.id,
      checkVisibleAddEditForm: false,
      form: {
        name: ''
      },
      form_add: false,
      form_groupquestion: {},
      formEditGroup: false,
      form_question: {},
      formEditQuestion: false,
      isCreatGroupQuestion:null,
      isCreatQuestion : null,
      idTest : null,
      isDisable : false
    }
  },

  mounted() {
    this.getListScheduleCourse();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý lộ trình", route: 'schedule-course-list'},
      {title: "Chỉnh sửa chi tiết lộ trình"}
    ]);
  },

  methods: {
    getListScheduleCourse() {
      this.$store.dispatch(SHOW_SCHEDULE_COURSE, {
        id: this.$route.params.id,
        lesson: this.lesson
      }).then((res) => {
        this.schedule_detail = res.data.schedule_course;
            this.lesson_detail = res.data.lesson
        this.idTest = this.$route.query.test_id
        console.log(this.idTest)
      });
    },
    lessonDetail() {
      this.getListScheduleCourse()
      this.$router.push({
        path: `/schedule-course/edit/${this.$route.params.id}`, query: {
          lesson: this.lesson
        }
      });
    },

    deleteGroupQuestion(id){
      this.$confirm('This will permanently delete. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let payload = {
          group_question_id : id
        }
        this.$store.dispatch(DELETE_GROUP_QUESTION, payload).then((data) => {
          this.formEditQuestion = false;
          this.getListScheduleCourse()
          this.$message({
            type: 'success',
            message: 'Delete completed'
          });
        });
      }).catch(() => {

      })

    },
    deleteQuestion(id){
      this.$confirm('This will permanently delete. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let payload = {
          question_id : id
        }
        this.$store.dispatch(DELETE_QUESTION, payload).then((data) => {
          this.formEditQuestion = false;
          this.getListScheduleCourse()
          this.$message({
            type: 'success',
            message: 'Delete completed'
          });
        });
      }).catch(() => {

      })
    },

    editGroupQuestion(id, index, item) {
      this.isCreatGroupQuestion = false
      this.form_groupquestion = clone(item);
      this.formEditGroup = true;
    },
    createGroupQuestion(){
      this.isCreatGroupQuestion = true;
      this.formEditGroup = true;
    },
    async submitGroupQuestion() {
      if(this.isCreatGroupQuestion){
        if(this.isDisable == true){
          return;
        }
        this.isDisable = await true
        this.form_groupquestion.section = "section";
        this.form_groupquestion.score = 1;
        this.form_groupquestion.is_shuffle =1;
        this.form_groupquestion.skill = "skill";
        this.form_groupquestion.test_id = this.idTest;
        this.$store.dispatch(ADD_GROUP_QUESTION, this.form_groupquestion)
        .then((data) => {
          this.formEditGroup = false;
          this.getListScheduleCourse()
          this.form_groupquestion = {}
        }).catch((errors) => {
          console.log(errors);
        })
        .finally(()=>{
          this.isDisable = false
        });
        return
      }
      this.$store.dispatch(EDIT_GROUP_QUESTION, this.form_groupquestion).then((data) => {
        this.formEditGroup = false;
        this.getListScheduleCourse()
      });
    },
    editQuestion(id, index, item) {
      this.isCreatQuestion = false
      this.form_question = clone(item);
      this.formEditQuestion = true;
      this.form_question.id_remove = []
    },
    createQuestion( item){
      this.isCreatQuestion = true
      this.form_question = clone(item);
      this.formEditQuestion = true;
      this.form_question.answers = []
    },
    deleteAnswer(index, id) {
      if(this.isCreatQuestion){
        this.form_question.answers.splice(index,1)
        return
      }
      this.form_question.answers = this.form_question.answers.filter((item, index1) => index1 !== index)
      id != 0 ? this.form_question.id_remove.push(id) : '';
    },
    addAnswer() {
      this.form_question.answers.push({id: 0, image: '', audio: '', position: '', content: ''})
    },
    submitQuestion() {
      if(this.isCreatQuestion){
        if(this.isDisable == true){
          return;
        }
        this.isDisable = true
        let payload = {
          group_question_id: this.form_question.id,
          title: this.form_question.title,
          correct_answer: this.form_question.correct_answer,
          image: this.form_question.image,
          audio: this.form_question.audio,
          video: this.form_question.video,
          is_shuffle: this.form_question.is_shuffle,
          type: this.form_question.is_shuffle.type,
          score: this.form_question.score,
          order: 1,
          vocab: "vocab",
          is_new: 1,
          answers : this.form_question.answers

        }
        this.$store.dispatch(ADD_QUESTION, payload).then((data) => {
          this.formEditQuestion = false;
          this.getListScheduleCourse()
          this.form_question = {}
        }).
        catch((errors) => {
          console.log(errors);
        })
        .finally(()=>{
          this.isDisable = false
        });;
        return
      }
      this.$store.dispatch(EDIT_QUESTION, this.form_question).then((data) => {
        this.formEditQuestion = false;
        this.getListScheduleCourse()
      });
    },

     open() {
        this.$confirm('This will permanently delete. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: 'Delete completed'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled'
          });          
        });
      }
  }
}
</script>
