<template>
  <div style="display: flex; align-items: center; justify-content: space-between; padding:20px;">
    <h4 style="margin: 0">Quản lý trái phiếu</h4>
    <div>
      <el-button
          type="success" style="background: #1BC5BD; border: #1BC5BD; color: white" @click="isImportSold = true">
        Import TP bán</el-button>
      <el-button
          type="primary" style="background: #0066FF; color: white; border: #0066FF" @click="isImport = true">
        Import trái phiếu</el-button>
    </div>
    <el-dialog
        v-if="isImport"
        :title="titleDialogImportBond"
        :visible.sync="isImport"
        width="70%"
        :before-close="handleCloseBondDialog"
        :show-close="false"
        top="8vh"
    >
      <hr style="margin-top: -20px"/>
      <DialogImportBond @setTitleError="setTitleBond" @closeDialog="isImport = false" @getListBondManagement="getListBondManagement"/>
    </el-dialog>
    <el-dialog
        v-if="isImportSold"
        :title="titleDialogImportSold"
        :visible.sync="isImportSold"
        width="50%"
        :before-close="handleCloseBondSoldDialog"
        :show-close="false"
        top="8vh"
    >
      <hr style="margin-top: -20px"/>
      <DialogImportBondSold @setTitleError="setTitleBondSold" @closeDialog="isImportSold = false" @getListBondManagement="getListBondManagement"/>
    </el-dialog>
  </div>
</template>

<script>
import DialogImportBond from "@/view/pages/baseoffice/bonds-management/component/DialogImportBond.vue";
import DialogImportBondSold from "@/view/pages/baseoffice/bonds-management/component/DialogImportBondSold.vue";

export default {
  name: 'HeaderBondManagement',
  components: {DialogImportBondSold, DialogImportBond},
  data() {
    return {
      isImport: false,
      isImportSold: false,
      titleDialogImportBond: 'Import trái phiếu',
      titleDialogImportSold: 'Import trái phiếu bán/chuyển nhượng',
    }
  },
  methods: {
    setTitleBond(newTitle) {
      this.titleDialogImportBond = newTitle
    },
    setTitleBondSold(newTitle) {
      this.titleDialogImportSold = newTitle
    },
    handleCloseBondDialog() {
      this.isImport = false
    },
    handleCloseBondSoldDialog() {
      this.isImportSold = false
    },
    getListBondManagement() {
      this.$emit('getListBondManagement')
    },
  }
}
</script>

<style lang="css" scoped>

</style>