<template>
  <div class="card card-custom gutter-b" id="report-training">
    <div class="card-header">
      <div class="card-title">
        Báo cáo đào tạo
      </div>
      <div class="card-title d-flex">
        <div v-if="query.program">
          <el-button :disabled="loading_export" @click="exportExcel" style="width: 121px"
            class="rounded text-white mr-3" size="mini" type="primary">
            <i v-bind:class="[loading_export ? 'el-icon-loading' : '']"></i>
            Xuất excel
          </el-button>
        </div>
        <div>
          <b-button @click="openAttendanceModal" class="btn btn-success btn-sm">Điểm danh
          </b-button>
          <FormCandidate :centers="centers" :teacher="infoTeacher" :trainers="trainers"
            @reload="getAllSaleLevelUpTraining">
          </FormCandidate>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-xxl-2 col-md-4">
            <label for=""><b>Học viên</b></label>
            <div class="form-group w-100">
              <el-input class="shadow-sm" type="text" v-model="query.keyword" clearable
                placeholder="Nhập tên, số điện thoại, email"></el-input>
            </div>
          </div>
          <div class="col-xxl-2 col-md-4">
            <label for=""><b>Chương trình đào tạo</b></label>
            <div class="form-group w-100">
              <el-select class="w-100 shadow-sm" @change="changeProgram" v-model="query.program" placeholder="Chọn chương trình đào tạo"
                collapse-tags>
                <el-option v-for="item in programs" :key="item.quantumleap_id" :label="item.name"
                  :value="item.quantumleap_id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-xxl-2 col-md-4">
            <label for=""><b>Khu vực làm việc</b></label>
            <div class="form-group w-100">
              <el-select class=" w-100 shadow-sm" @change="changeArea()" v-model="query.area" collapse-tags
                filterable placeholder="Chọn khu vực làm việc" clearable>
                <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-xxl-2 col-md-4">
            <label for=""><b>Văn phòng làm việc</b></label>
            <div class="form-group w-100">
              <el-select class="w-100 shadow-sm" placeholder="Chọn văn phòng làm việc" v-model="query.branch"
                collapse-tags clearable filterable>
                <el-option v-for="item in branchs" :key="item.id" :label="item.name_alias ? item.name_alias : item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-xxl-2 col-md-4">
            <label for=""><b>Trạng thái học viên</b></label>
            <div class="form-group w-100">
              <el-select multiple class="w-100 shadow-sm" placeholder="Chọn trạng thái học viên" v-model="query.status"
                collapse-tags clearable filterable>
                <el-option v-for="item in statusSale" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-2 col-md-4">
            <label for=""><b>Thời gian tạo</b></label>
            <div class="form-group w-100 shadow-sm">
              <date-picker placeholder="Thời gian tạo" range v-model="query.date_range" format="DD-MM-YYYY"
                valueType="YYYY-MM-DD"></date-picker>
            </div>
          </div>
          <div class="col-xxl-2 col-md-4">
            <label for="">&ensp;</label>
            <div class="form-group">
              <button @click="searchCandidate" class="btn btn-primary font-weight-bold mr-2">
                <i v-bind:class="[loading ? 'el-icon-loading' : 'el-icon-search']"></i>Tìm kiếm</button>
            </div>
          </div>
        </div>
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center table-bordered" v-loading="loading"
            element-loading-text="Loading...">
            <thead>
              <tr style="background-color: #F8F8F8;">
                <th>Họ và tên</th>
                <th>Số điện thoại</th>
                <th>Văn phòng</th>
                <th>Khu vực</th>
                <th>Trạng thái học viên</th>
                <th v-for="(course, index) in courses" :key="index">
                  Trạng thái {{ course.title }}
                </th>
                <th>Thời gian hoàn thành đào tạo</th>
              </tr>
            </thead>
            <tbody v-if="listSaleLevelUpTraining?.length > 0">
              <tr v-for="(item) in listSaleLevelUpTraining" :key="item.id">
                <td>
                  <div class="name-teacher">{{ item?.name }}</div>
                </td>
                <td>{{ item?.phone }}</td>
                <td>{{ item?.branch_name }}</td>
                <td>
                  <div class="area-teacher">{{ item.area }}</div>
                </td>
                <td>
                  <span class="p-2" style="font-size: 12px;font-weight: 500; padding: 4px 2px; width: 93%;"
                    :class="statusDefineCss[item.status]">{{
                    statusDefine[item.status] }}</span>
                </td>
                <td v-for="(course, index) in item?.program_detail?.courses" :key="index">
                  <div class="status-schedule">
                    <div class="d-inline-flex mr-2 rounded-circle" style="width: 10px; height: 10px;"
                      :class="status_course_css[course.status]"></div>
                    <span>{{ status_course[course.status] }}</span>
                  </div>
                </td>
                <td>{{ item.completedDate }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9" class="event-title no-data text-center">Không có bản ghi</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b
            style="color: rgb(54, 153, 255);">{{
          totalData }}</b></div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
        :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'"
        :pageLinkClass="'page-link'" :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'"
        :prev-class="'page-link'" :next-class="'page-link'" :page-class="'page-item'">
      </paginate>
    </div>

    <!-- Điểm danh học đào tạo -->
    <el-dialog title="Điểm danh học đào tạo" :visible.sync="is_attendance" width="75%">
      <div>
        <div class="col-12 row">
          <div class="col-xxl-3 col-md-4">
            <div>
              <label for="">Học viên</label>
            </div>
            <el-input class="shadow-sm" type="text" v-model="query_attend.keyword" clearable
                placeholder="Nhập tên, số điện thoại, email"></el-input>
          </div>
          <div class="col-xxl-3 col-md-4">
            <div>
              <label for="">Khóa học</label>
            </div>
            <el-select class="w-100 shadow-sm" placeholder="Chọn khóa học" v-model="query_attend.course"
              @change="changeCourseAttend" collapse-tags filterable>
              <el-option v-for="item in course_attendes" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-xxl-3 col-md-4">
            <div>
              <label for="">Khu vực làm việc</label>
            </div>
            <el-select @change="changeArea('attend')" class="w-100 shadow-sm" placeholder="Chọn khu vực làm việc"
              v-model="query_attend.area" collapse-tags clearable filterable>
              <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-xxl-3 col-md-4">
            <div>
              <label for="">Chọn văn phòng</label>
            </div>
            <el-select class="w-100 shadow-sm" placeholder="Chọn văn phòng" v-model="query_attend.branch" collapse-tags
              clearable filterable>
              <el-option v-for="item in branch_attendances" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="col-xxl-3 col-md-4">
            <label for="">&ensp;</label>
            <div class="w-100">
              <button @click="searchCandidateAttend" class="btn btn-primary font-weight-bold">
                <i v-bind:class="[loadingAttend ? 'el-icon-loading' : 'el-icon-search']"></i>Tìm kiếm</button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="example-preview table-responsive overflow-auto mt-3">
            <table class="table text-left table-hover table-bordered table-vertical-center b-table">
              <thead>
                <th scope="col">STT</th>
                <th scope="col">Ứng viên</th>
                <th scope="col">Văn phòng</th>
                <th scope="col">Điểm danh</th>
              </thead>
              <tbody>
                <tr v-for="(item, index) in candidateAttendances" :key="index">
                  <td>{{ ++index }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.branch_name }}</td>
                  <td><input type="checkbox" :value="item.id" v-model="checkboxAttendance"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-center">
            <paginate v-model="page_attend" :page-count="last_page_attend" :page-range="3" :margin-pages="1" :click-handler="clickCallbackAttend"
              :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'"
              :pageLinkClass="'page-link'" :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'"
              :prev-class="'page-link'" :next-class="'page-link'" :page-class="'page-item'">
            </paginate>
          </div>
          <div class="d-flex dialog-footer justify-content-end mb-3">
            <button v-if="checkboxAttendance.length > 0" @click="attendance"
              class="btn btn-primary font-weight-bold">Điểm danh</button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { SALE_LEVEL_UP_TRAINING, SALE_LEVEL_UP_PROGRAMS, SALE_LEVEL_UP_COURSES, ATTEND_SALE } from '@/core/services/store/sale-level-up/training.module';
import { GET_LIST_AREAS } from "@/core/services/store/center/area.module";
import { GET_LIST_BRANCHES } from '@/core/services/store/center/branch.module';
import { FormCandidate } from "./candidate-component/index";
import { exportDontNeedHeader } from "@/core/filters";
import moment from 'moment-timezone';
export default {
  name: "manage-candidate-teacher",
  components: {
    DatePicker, FormCandidate
  },
  data() {
    return {
      page: 1,
      page_attend : 1,
      last_page: 1,
      last_page_attend: 1,
      query: {
        keyword: '',
        program: '',
        area: '',
        branch: '',
        status: [],
        date_range: '',
      },
      query_attend: {
        course: '',
        area: '',
        branch: ''
      },
      listSaleLevelUpTraining: [],
      centers: [],
      trainers: [],
      loading: false,
      totalData: '',
      loading_export: false,
      statusDefineCss: {
        0: "badge bg-secondary",
        1: "badge bg-warning text-white",
        2: "badge bg-danger text-white",
        3: "badge bg-successs text-white",
      },
      infoTeacher: {},
      infoDetail: {},
      statusDefine: {
        0: "Chưa đào tạo",
        1: "Đang đào tạo",
        2: "Bị loại",
        3: "Hoàn thành đào tạo",
      },

      statusSale: [
        { id: 0, name: "Chưa đào tạo" },
        { id: 1, name: "Đang đào tạo" },
        { id: 3, name: "Hoàn thành đào tạo" },
      ],

      status_course: {
        0: "Chưa học",
        1: "Đang học",
        2: "Đã học",
        3: "Hoàn thành",
      },

      status_course_css: {
        0: "bg-secondary",
        1: "bg-warning",
        2: "bg-primary",
        3: "bg-successs",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      is_attendance: false,
      listCourse: [],
      messageerrorAttendance: '',
      messageRequireBranch: '',
      messageRequire: '',
      programs: [],
      areas: [],
      branchs: [],
      branch_attendances: [],
      courses: [],
      course_attendes: [],
      loadingAttend: false,
      candidateAttendances: [],
      checkboxAttendance: []
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Báo cáo đào tạo" }
    ]);

    this.getAllPrograms();
    this.getListArea();
    this.getBranchs();
    this.getAllCourses();
    this.queryDateRangeNow();
  },
  methods: {
    queryDateRangeNow() {
      const firstDayOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const lastDayOfMonth = moment().endOf('month').format('YYYY-MM-DD');
      this.query.date_range = [firstDayOfMonth,lastDayOfMonth]
    },
    openAttendanceModal(){
      this.is_attendance = true;
      this.query_attend = {
        course: '',
        area: '',
        branch: ''
      }
      this.page_attend = 1;
      this.last_page_attend = 1;
      this.candidateAttendances = [];
    },
    searchCandidateAttend() {
      if(this.query_attend.course) {
        this.page_attend = 1;
        this.last_page_attend = 1;
        this.getAllSaleLevelUpAttend();
      }
    },
    changeCourseAttend() {
      this.programs.forEach(program => {
        program.courses.forEach(course => {
          if (this.query_attend.course == course.id) {
            this.query_attend.route_id = program.id;
            this.query_attend.program = program.quantumleap_id;
          }
        })
      })
    },
    changeArea(type) {
      if (type) {
        this.getBranchAttendances(this.query_attend.area);
      } else {
        this.getBranchs(this.query.area);
      }
    },
    changeProgram() {
      this.courses = this.programs.find(program => program.quantumleap_id == this.query.program).courses;
      this.query.route_id = this.programs.find(program => program.quantumleap_id == this.query.program).id;
    },

    attendance() {
      this.$store.dispatch(ATTEND_SALE, {
        course_id: this.query_attend.course,
        user_ids: this.checkboxAttendance
      })
        .then((res) => {
          if (!res.error) {
            this.getAllSaleLevelUpAttend()
            this.getAllSaleLevelUpTraining()
            this.is_attendance = false;
            this.$message({
              type: 'success',
              message: 'Điểm danh thành công!',
              showClose: true
            });
          } else {
            this.$message({
              type: 'error',
              message: 'Điểm danh thất bại!',
              showClose: true
            })
          }
        }).catch(error => {
          this.$message({
            type: 'error',
            message: error,
            showClose: true
          })
        })
    },

    exportExcel() {
      this.loading_export = true;
      this.$store.dispatch(SALE_LEVEL_UP_TRAINING, {
        page: this.page,
        keyword: this.query.keyword,
        area: this.query.area,
        status: this.query.status,
        branch: this.query.branch,
        date_range: this.query.date_range,
        program: this.query.program,
        route_id : this.query.route_id,
        is_export: 1
      }).then((res) => {
        exportDontNeedHeader(res.data, "danh_sach_ung_vien_sale_level_up" + new Date().getTime() / 1000 + ".xlsx")
        this.loading_export = false;

      }).catch(() => {
        this.loading_export = false;

      });
    },

    searchCandidate() {
      if (this.query.program) {
        this.page = 1;
        this.last_page = 1;
        this.changeProgram();
        this.getAllSaleLevelUpTraining();
      }
    },
    getAllSaleLevelUpAttend() {
      this.checkboxAttendance = []
      this.loadingAttend = true,
        this.$store.dispatch(SALE_LEVEL_UP_TRAINING, {
          page: this.page_attend,
          keyword: this.query_attend.keyword,
          area: this.query_attend.area,
          branch: this.query_attend.branch,
          route_id: this.query_attend.route_id,
          course_id: this.query_attend.course,
          program: this.query_attend.program
        }).then((res) => {
          this.candidateAttendances = res.data;
          this.totalData = res.pagination.total;
          this.last_page_attend = res.pagination.last_page;
          this.loadingAttend = false;
        }).catch(() => {
          this.loadingAttend = false
        });
    },
    getAllSaleLevelUpTraining() {
      let check_date = this.query.date_range;
      if (check_date[0] == null || check_date[1] == null) {
        this.query.date_range = '';
      }
      this.loading = true,
        this.$store.dispatch(SALE_LEVEL_UP_TRAINING, {
          page: this.page,
          keyword: this.query.keyword,
          area: this.query.area,
          status: this.query.status,
          branch: this.query.branch,
          date_range: this.query.date_range,
          route_id: this.query.route_id,
          program: this.query.program
        }).then((res) => {
          this.listSaleLevelUpTraining = res.data;
          this.totalData = res.pagination.total;
          this.last_page = res.pagination.last_page;
          this.loading = false;
        }).catch(() => {
          this.loading = false
        });
    },
    getBranchs(area_id) {
      let payload;
      if (area_id) {
        payload = {
          area_id: area_id
        }
      }
      this.$store.dispatch(GET_LIST_BRANCHES, payload).then((data) => {
        if (!data.error) {
          this.branchs = data.data;
          this.branch_attendances = data.data;
        }
      })
    },
    getBranchAttendances(area_id) {
      let payload;
      if (area_id) {
        payload = {
          area_id: area_id
        }
      }
      this.$store.dispatch(GET_LIST_BRANCHES, payload).then((data) => {
        if (!data.error) {
          this.branch_attendances = data.data;
        }
      })
    },
    getAllCourses() {
      this.$store.dispatch(SALE_LEVEL_UP_COURSES).then((data) => {
        if (!data.error) {
          this.course_attendes = data.data;
        }
      })
    },
    getAllPrograms() {
      this.$store.dispatch(SALE_LEVEL_UP_PROGRAMS).then((data) => {
        if (!data.error) {
          this.programs = data.data;
        }
      })
    },
    getListArea() {
      this.$store.dispatch(GET_LIST_AREAS, {}).then(data => {
        if (!data.error) {
          this.areas = data.data;
        }
      })
    },
    searchData() {
      this.getAllSaleLevelUpTraining()
    },
    clickCallback(obj) {
      this.page = obj
      this.getAllSaleLevelUpTraining()
    },
    clickCallbackAttend(obj) {
      this.page_attend = obj
      this.getAllSaleLevelUpAttend();
    }
  }
}
</script>

<style>
#report-training .bg-successs {
  background-color: rgba(0, 128, 13, 1);
}

#report-training table th,
table td {
  padding: 0.8rem;
  text-align: start;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 1441px) {

  #report-training .status-schedule,
  #report-training .area-teacher,
  #report-training .name-teacher {
    width: 100px;
  }

  #report-training .deadline,
  #report-training .name-teacher,
  #report-training .center-teacher {
    width: 200px;
  }
}
</style>
