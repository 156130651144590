<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Quyết toán trung tâm</h3>
      </div>
      <div class="card-title" v-if="currentUser.user.permission.includes(permission.QUYET_TOAN_TRUNG_TAM)">
        <div>
          <div class="my-3">
            <!-- Our triggering (target) element -->
            <b-button id="popover-reactive-1" variant="primary" ref="button" class="mr-2">
              <i class="la la-file-excel"></i> Xuất google sheet
            </b-button>
          </div>
          <!-- Output from the popover interaction -->
          <b-popover
              target="popover-reactive-1"
              triggers="click"
              :show.sync="popoverShow"
              placement="auto"
              container="my-container"
              ref="popover"
              @show="onShow"
              @hidden="onHidden"
          >
            <template #title>
              <b-button @click="onClose" class="close" aria-label="Close">
                <span class="d-inline-block" aria-hidden="true">&times;</span>
              </b-button>
              Dánh sách thư mục
            </template>

            <div>
              <b-form-group
                  label-for="popover-input-1"
                  label-cols="3"
                  :state="validateState"
                  class="mb-1"
                  invalid-feedback="Không được để trống"
                  size="lg"
              >
                <b-form-select
                    id="popover-input-1"
                    v-model="folderData"
                    :state="validateState"
                    :options="options"
                    size="sm"
                    class="mb-3"
                ></b-form-select>
              </b-form-group>
              <b-button @click="onClose" size="sm" variant="danger">Cancel</b-button>
              <b-button @click="onOk" size="sm" variant="primary" class="ml-2">Ok</b-button>
            </div>
          </b-popover>
        </div>
        <div>
          <button v-if="!is_export_excel" class="btn btn-success font-weight-bold mr-2" @click="exportExcel">
            <i class="la la-file-excel"></i> Xuất excel
          </button>
          <button v-else disabled
                  class="btn btn-success font-weight-bold mr-2 spinner spinner-white spinner-left">
            Xuất excel
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <input type="text" v-model="query.keyword" class="form-control"
                     placeholder="Tên, SĐT, Mã học viên" v-on:input="searchByKeyword">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <input type="text" v-model="query.bill_code" class="form-control"
                     placeholder="Mã hóa đơn" v-on:input="searchByKeyword">
            </div>
            <!--end::Form-->
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <multiselect v-model="cache_center" :options="centers"
                           placeholder="Chọn trung tâm"
                           label="name"
                           track-by="name"
                           @input="selectCenter($event)"
              ></multiselect>
            <!--end::Form-->
          </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <multiselect v-model="cache_branch" :options="branches"
                           placeholder="Chọn chi nhánh"
                           label="name"
                           track-by="name"
                           @input="selectBranch($event)"
              ></multiselect>
            </div>
            <!--end::Form-->
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <date-picker v-model="query.month" placeholder="Chọn tháng" :full-month-name="true" type="month" format="MM-YYYY" :clear-button="true"  valueType="YYYY-MM" class="w-100 edutalk-date-picker" @input="selectDate"></date-picker>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <multiselect v-model="cache_type"
                           :options="types"
                           :close-on-select="false"
                           placeholder="Loại quyết toán"
                           label="name"
                           track-by="name"
                           multiple
                           @input="selectType($event)"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <multiselect v-model="cache_hinh_thuc"
                           :options="hinh_thuc_types"
                           :close-on-select="false"
                           placeholder="Hình thức thanh toán"
                           label="name"
                           track-by="name"
                           multiple
                           @input="selectHinhThuc($event)"
              ></multiselect>
            </div>
          </div>
          <div class="col-md-3">
            <div class="d-flex justify-content-between">
              <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                      @click="searchData">
                <i class="fa fa-search"></i> Tìm kiếm
              </button>
              <button v-else type="button" disabled
                      class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
                Tìm kiếm
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            Tổng thanh toán :
            <span class="badge badge-danger">{{formatPrice(this.total_amount, 'đ')}}</span>
          </div>
        </div>
        <div class="example-preview table-responsive">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <th scope="col" class="min-w-100px">Mã hóa đơn</th>
              <th scope="col" class="min-w-100px">Mã học viên</th>
              <th scope="col" class="min-w-100px">Họ và tên</th>
              <th scope="col" class="min-w-100px">Số điện thoại</th>
              <th scope="col" class="min-w-120px">Tên chi nhánh</th>
              <th scope="col" class="min-w-120px">Số tiền</th>
              <th scope="col" class="min-w-120px">Ngày nạp-hoàn</th>
              <th scope="col" class="min-w-100px">Loại quyết toán</th>
              <th scope="col" class="min-w-100px">Hình thức thanh toán</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in getAllStudentAmounts" :key="index">
              <td>{{item.id}}</td>
              <td>{{parseInt(item.user.id) + 100000}}</td>
              <td>{{item.user.name}}</td>
              <td>{{item.user.phone}}</td>
              <td>{{item.chi_nhanh_hoc? item.chi_nhanh_hoc.name : ''}}</td>
              <td>{{formatPrice(item.amount, 'đ')}}</td>
              <td>{{item.dated_paid | formatDateTime}}</td>
              <td>{{item.loaiQuyetToan}}</td>
              <td>{{item.hinh_thuc_thanh_toan }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="query.page"
          :page-count="total_pages"
          :page-range="3"
          :margin-pages="2"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :page-class="'page-item'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
      >
      </paginate>
    </div>

    <div class="vld-parent">
      <loading :active.sync="isLoading"
               :can-cancel="true"
               :on-cancel="onCancel"
               :is-full-page="fullPage"></loading>
    </div>
  </div>
</template>
<script>
import {
  EXPORT_EXCEL_STUDENTAMOUNTS,
  EXPORT_STUDENTAMOUNT_GOOGLE_SHEET, GET_STUDENTAMOUNTS
} from "../../../core/services/store/report/report.module";

const _ = require('lodash');
// import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import Multiselect from 'vue-multiselect'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// Datetime picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {mapGetters} from "vuex";
import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import {GET_USER_PROMOTION} from "../../../core/services/store/user/user_promotion_degradation.module";
import {GET_FOLDERS_DRIVERS} from "../../../core/services/store/public/google.module";
import {exportExcel} from "../../../core/filters";
/*End*/

export default {
  components: {
    Multiselect,
    DatePicker,
    Loading
  },
  name: "Student-Amount-Payment",
  data() {
    return {
      /*Loader */
      isLoading : false,
      fullPage : true,
      /* popover */
      path : '',
      showPop: false,
      folderData: '',
      validateState: null,
      options: [],
      popoverShow: false,
      /*Show modal edit*/
      showModalStudentAmount: false,
      showDetailStudentAmount: false,
      student_amounts: [],
      center_id : '',
      /*End*/
      is_disable_search: false,
      is_export_excel: false,
      is_export_sheet: false,
      /* cache data */
      cache_center : '',
      cache_branch : '',
      cache_type : [],
      /* options */
      centers: [],
      branches: [],
      branchesByCenter: [],
      cache_hinh_thuc: [],
      types : [{name : 'Trung tâm thu tiền', value : 1}, {name : 'Edutalk thu tiền', value: 2}, {name : 'Hoàn quyền lợi', value: 3}, {name : 'Quyền lợi', value: 4}],
      // Paginate
      query:
          {
            page: 1,
            keyword: '',
            center_id : '',
            branch_id : '',
            bill_code : '',
            month : '',
            type : [],
            cache_hinh_thuc : [],
          }
      ,
      total_amount : '',
      payload : {
        limit : 1000000,
        is_with : 1,
      },
      hinh_thuc_types: [
        {name : 'Tiền mặt', value : 'Tiền mặt'},
        {name : 'Chuyển khoản', value : 'Chuyển khoản'}
      ],
    }
  },
  watch: {
    folderData(val) {
      if (val) {
        this.validateState = true;
        this.path = val;
      }
    },
  },
  computed : {
    ...mapGetters(['getAllStudentAmounts', 'getStudentAmountPaginate','currentUser']),
    total_pages() {
      return this.getStudentAmountPaginate?.total_pages || 1;
    },
  },
  async created() {
     this.getFolders();
    await this.getCenters();
    await this.getBranches();
    await this.setParams();
    await this.$store.dispatch(GET_STUDENTAMOUNTS,this.query).then((res)=>{
      this.is_disable_search = false
      this.total_amount = res.data.total_amount;
    });

  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý báo cáo"},
      {title: "Quyết toán trung tâm"}
    ]);
  },
  methods: {
    onCancel :function () {
      console.log('User cancelled the loader.')
    },
    onClose() {
      this.popoverShow = false
    },
    onOk() {
      if (!this.folderData) {
        this.validateState = false
      }
      if (this.folderData) {
        this.onClose()
        // Return our popover form results
        this.exportGoogleSheet();
      }
    },
    onShow() {
      this.folderData = ''
      this.validateState = null
    },
    onHidden() {
      this.focusRef(this.$refs.button)
    },
    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          ;(ref.$el || ref).focus()
        })
      })
    },
    getFolders:  function () {
       this.$store.dispatch(GET_FOLDERS_DRIVERS).then((res) => {
        this.options = _.reduce(res.data, function (result,item,key) {
          result.push({
            text : item.name,
            value : item.path
          });
          return result;
        },[]);
         this.options.unshift({ text: '- Chọn thư mục -', value: '' });
      });

    },
    getCenters : async function (){
      this.$store.dispatch(GET_CENTERS,this.payload).then((res) => {
        this.centers = res.data.data;
      });
    },
    getBranches: async function() {
      await this.$store.dispatch(GET_BRANCHES,this.payload).then((res)=>{
        this.branches = res.data.data;
        this.branchesByCenter = res.data.data;
      });
    },
    selectCenter : function ($event){
      let center_id = $event != null ? $event.id : '';
      this.is_show = $event != null;
      this.query.center_id = center_id;
      if (center_id !== ''){
        this.branches = _.reduce(this.branchesByCenter, function (result,value,key){
          if (center_id === value.center_id){
            result.push(value);
          }
          return result;
        },[]);
      }else{
        this.branches = this.branchesByCenter;
      }
      this.cache_branch = '';
      this.pushParams();
    },
    selectBranch : function ($event){
      this.query.branch_id = $event != null ? $event.id : '';
      this.pushParams();
    },
    selectType : function ($event){
        this.query.type = _.map($event,(type)=> type.value);
        this.pushParams();
    },
    selectHinhThuc : function ($event) {
      this.query.cache_hinh_thuc = _.map($event,(cache_hinh_thuc)=> cache_hinh_thuc.value);
      this.pushParams();
    },
    selectDate : function (){
      this.pushParams();
    },
    searchByKeyword : function (){
      this.pushParams();
    },
    pushParams : function (){
      this.$router.push({path : '/reports/student-amounts' , query : this.query})
    },
    setParams : async function (){
      this.query.page = parseInt(this.$route.query.page ? this.$route.query.page : 1);
      let branch_id = this.query.branch_id = this.$route.query.branch_id ? this.$route.query.branch_id : '';
      let center_id = this.query.center_id = this.$route.query.center_id ? this.$route.query.center_id : '';
      this.cache_branch = _.find(this.branches, function (branch){
        return branch.id === parseInt(branch_id);
      });
      this.cache_center = _.find(this.centers, function (center){
        return center.id === parseInt(center_id);
      });
      let type = this.$route.query.type ? this.$route.query.type : '';
      type = _.map(type,item => parseInt(item))
      this.cache_type  = _.reduce(this.types, function (result,value,ley){
        if (type.includes(value.value)){
          result.push(value);
        }
        return result
      },[]);
      let cache_hinh_thuc = this.$route.query.cache_hinh_thuc ? this.$route.query.cache_hinh_thuc : '';
      cache_hinh_thuc = _.map(cache_hinh_thuc,item => parseInt(item))
      this.cache_hinh_thuc  = _.reduce(this.hinh_thuc_types, function (result,value,ley){
        if (cache_hinh_thuc.includes(value.value)){
          result.push(value);
        }
        return result
      },[]);
      this.query.branch_id = branch_id;
      this.query.center_id = center_id;
      this.query.type = type;
      let date = new Date();
      let currentMothValue = date.getFullYear() + '-' + (date.getMonth() + 1);
      this.query.month = this.$route.query.month ? this.$route.query.month : currentMothValue;
      this.query.keyword = this.$route.query.keyword ? this.$route.query.keyword : '';
      this.query.bill_code = this.$route.query.bill_code ? this.$route.query.bill_code : '';
    },
    exportExcel : function () {
      this.is_export_excel = true;
      const HEADER_ROW_EXPORT_QUYET_TOAN_TRUNG_TAM = [
        {
          key: 'id',
          value: 'Mã hóa đơn',
        },
        {
          key: 'user_id',
          value: 'Mã học viên',
        },
        {
          key: 'user_name',
          value: 'Họ và tên',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'chi_nhanh_hoc',
          value: 'Tên chi nhánh',
        },
        {
          key: 'amount',
          value: 'Số tiền',
        },
        {
          key: 'ngay_nap_hoan',
          value: 'Ngày nạp-hoàn',
        },
        {
          key: 'loai_quyet_toan',
          value: 'Loại quyết toán',
        },
        {
          key: 'hinh_thuc_thanh_toan',
          value: 'Hình thức thanh toán',
        },
      ]
      this.$store.dispatch(EXPORT_EXCEL_STUDENTAMOUNTS, this.query).then((res) => {
        this.is_export_excel = false
        exportExcel(HEADER_ROW_EXPORT_QUYET_TOAN_TRUNG_TAM, res.data, "Quyết toán trung tâm.xlsx", false)
        this.$bvToast.toast(res.message, {
          title: 'Export excel',
          variant: 'success',
          solid: true
        });
      });
    },
    exportGoogleSheet : function () {
      this.isLoading = true
      this.query.path = this.path;
      this.$store.dispatch(EXPORT_STUDENTAMOUNT_GOOGLE_SHEET, this.query).then((res) => {
        this.isLoading = false
        if (!res.error) {
          this.query.path = '';
          this.$bvToast.toast(res.message, {
            title: 'Export google sheet',
            variant: 'success',
            solid: true
          });
        }
      });
    },
    searchData : function () {
      this.is_disable_search = true
      this.getQuery()
    },
    clickCallback(pageNum) {
      this.query.page = pageNum;
      this.pushParams();
      this.$store.dispatch(GET_STUDENTAMOUNTS,this.query);
    },
    getQuery : async function (){
      this.$store.dispatch(GET_STUDENTAMOUNTS,this.query).then((res)=>{
        this.is_disable_search = false
        this.total_amount = res.data.total_amount;
        this.query.page = 1;
      })
    },
  }
}
</script>
<style>
.col-form-label{
  display: none;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
