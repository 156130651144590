<template>
  <b-modal @hide="onHide" size="xl" :centered="true" :scrollable="true" ref="modal-mark-point" hide-footer title="Chấm điểm đầu vào"  class="modal-mark-point">
    <div id="app">
      <form-wizard @on-complete="onComplete"
                 validate-on-back
                 ref="wizard"
                 :start-index.sync="activeTabIndex"
                 shape="circle" color="#20a0ff" error-color="#ff4949"
                 title="Hoàn thành các bước test đầu vào"
                 subtitle=""
                 :validateOnBack="false"
                 :finishButtonText="disable ? 'Đóng' : 'Lưu'"
                 backButtonText="Quay lại"
                 nextButtonText="Tiếp tục"
                 @on-change ="changeTab"
      >
      <tab-content title="Chọn bài test" icon="fas fa-microphone"
                   :before-change="() => validate('firstStep')">
        <first-step ref="firstStep" @on-validate="onStepValidate" :exam="exam" :test-income="testIncome" :is-disable="isDisable"></first-step>
      </tab-content>
      <tab-content title="Chấm điểm" icon="fas fa-signature"
                   :before-change="() => validate('secondStep')">
        <second-step ref="secondStep" 
        :answers="answers" :marks="marks" :is-disable="disable" :test-income="testIncome" :writingQuestion="writingQuestion" @on-validate="onStepValidate"></second-step>
      </tab-content>
      <tab-content :title="income.type_test == 'daihoc' ? 'Nhận xét': 'Nhận xét tổng quan'" icon="fas fa-comments"
                   :before-change="() => validate('thirdStep')">
        <third-step ref="thirdStep" @on-validate="onStepValidate" :is-disable="disable" :test-income="testIncome"></third-step>
      </tab-content>
      <tab-content title="Kiểm tra" icon="fas fa-print">
        <fourth-step :user="user" :finalModel="finalModel" :marks="marks"  :test-income="testIncome"></fourth-step>
      </tab-content>
    </form-wizard>
    </div>
  </b-modal>
</template>

<script>
import FirstStep from "./wizad/FirstStep.vue";
import SecondStep from "./wizad/SecondStep.vue";
import ThirdStep from "./wizad/ThirdStep.vue";
import FourthStep from "./wizad/FourthStep.vue";
import prettyJSON from "../../../core/plugins/prettyJson";
import {
  CHOOSE_TEST,
  FINISH_MARK_POINT,
  GET_INCOME_BY_ID,
  STORE_MARK, UPDATE_COMMENT_COURSE_SUGGEST
} from "../../../core/services/store/test/test.module";
import Swal from "sweetalert2";

export default {
  name: "app",
  props: {
    showModal: {
      type: Boolean,
      default: () => { return false}
    },
    income: {
      type: Object,
      default: () => { return {} }
    },

  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
  },
  data() {
    return {
      finalModel: {},
      activeTabIndex: 0,
      marks: {},
      exam: {},
      answers: [],
      speakingQuestion: [],
      writingQuestion: [],
      user: {},
      testIncome: {},
      isDisable: false,
      disable: false,
      firstStepCheck : true
    };
  },
  computed: {
    prettyJSON() {
      return prettyJSON(this.finalModel);
    }
  },
  watch: {
    showModal: function(){
      if (this.showModal){
        if (this.income){
          this.activeTabIndex = 0;
          this.$store.dispatch(GET_INCOME_BY_ID, this.income.id).then((res) => {
            this.marks = res.data.marks;
            this.exam = res.data.exam;
            this.answers = res.data.answers;
            this.speakingQuestion = res.data.speakingQuestion;
            this.writingQuestion = res.data.writingQuestion;
            this.user = res.data.user;
            this.testIncome = res.data.test_income;
            this.$refs['modal-mark-point'].show();
            this.disable = this.testIncome.status === 2;
            if(res.data?.exam?.exam_type) {
              this.activeTabIndex = 1;
              this.finalModel.test = res.data?.exam?.exam_type
            } 
            if(res.data?.marks?.speaking) {
              this.activeTabIndex = 2;
            }
            if(res.data?.test_income?.comment && res.data?.test_income?.course_suggest) {
              this.activeTabIndex = 3;
            }
          }).catch(() => {
            this.$bvToast.toast('Học viên chưa làm bài test reading và listening', {
              title: 'Chấm điểm',
              variant: 'warning',
              solid: true
            });
            this.$emit('errorGetDataExam')
          })
        }
      }
    }
  },
  methods: {
    onComplete() {
      if (this.testIncome.status !== 2){
        this.finalModel.testIncomeId = this.income.id;
        if (!this.marks?.reading && !this.marks?.listenning){
          Swal.fire({
            title: "Học viên chưa làm bài test. Vui lòng đợi học viên làm bài test và hoàn thành nốt các bước chấm điểm.",
            showDenyButton: true,
            confirmButtonText: "Đồng ý",
          });
          return;
        }
        this.$store.dispatch(FINISH_MARK_POINT, this.finalModel).then( (res) => {
          this.$bvToast.toast('Chấm điểm thành công', {
            title: 'Chấm điểm',
            variant: 'success',
            solid: true
          });
          this.testIncome.status = 2;
          this.$emit('finishMarkPoint');
          this.$refs['modal-mark-point'].hide();
        }).catch((res) => {
          this.$bvToast.toast(res.data.message, {
            title: 'Có lỗi',
            variant: 'error',
            solid: true
          });
        })
      } else {
        this.$refs['modal-mark-point'].hide();
      }

    },
    forceClearError() {
      this.$refs.wizard.tabs[this.activeTabIndex].validationError = null;
    },
    validate(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidate(validated, model) {
      if (validated) {
        this.finalModel = { ...this.finalModel, ...model };
      }
    },
    hideModal: function (){
      this.$refs['modal-mark-point'].hide();
    },
    onHide: function (){
      this.$emit("clicked", true);
    },
    toggleModal() {
      this.$refs['modal-mark-point'].toggle('#toggle-btn');
    },
    changeTab(preIndex, nextIndex) {
      if (nextIndex === 1 && this.firstStepCheck && this.testIncome.status !== 2){
        let params = {
          id: this.income.id,
          test: this.finalModel.test,
        }
        this.$store.dispatch(CHOOSE_TEST, params).then((res) => {
          this.testIncome = res.data.test_income;
          this.marks = res.data.marks;
          this.firstStepCheck = false;
        }).catch((error) => {
          this.$bvToast.toast(error.data.message, {
            title: 'Chọn bài test thất bại',
            variant: 'success',
            solid: true
          });
          this.isDisable = false;
        })
      }

      if (nextIndex === 2 && this.testIncome.status !== 2){
        let arrWriting = [];
        for (const property in this.finalModel.writing) {
            arrWriting.push(this.finalModel.writing[property]);
          }
        let params = {
          id: this.income.id,
          writing_comment: this.finalModel.writing_comment,
          reading_comment: this.finalModel.reading_comment,
          listening_comment: this.finalModel.listening_comment,
          speaking_point: this.finalModel.speaking_point,
          speaking_comment: this.finalModel.speaking_comment,
          general_comment: this.finalModel.general_comment,
          writing : arrWriting,
        }
        this.$store.dispatch(STORE_MARK, params).then((res) => {
          this.testIncome = res.data.test_income;
          this.marks = res.data.marks;
        }).catch((error) => {
          console.log(error)
        })
      }

      if(nextIndex === 3 && this.testIncome.status !== 2){
        let params = {
          id: this.income.id,
          comment: this.finalModel.comment,
          course: this.finalModel.course,
        }
        this.$store.dispatch(UPDATE_COMMENT_COURSE_SUGGEST, params).then((res) => {
          this.testIncome = res.data.test_income;
          this.marks = res.data.marks;
        })
      }
    }
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.modal-mark-point {
  font-family: Menlo, Monaco, "Courier New", monospace;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}

pre {
  overflow: auto;
}
pre .string {
  color: #885800;
}
pre .number {
  color: blue;
}
pre .boolean {
  color: magenta;
}
pre .null {
  color: red;
}
pre .key {
  color: green;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-color: #ddd;
}

.panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;

  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-body {
  padding: 15px;
}
</style>
