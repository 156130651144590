import {RECORD_AUDIO, RECORD_VIDEO, WRITE_PARAGRAPH} from "@/core/option/selectScore";

export const CRITERIA_OF_RECORD_AUDIO = [
    {eName:'accuracy',vName:'Độ chính xác'},{eName:'stress',vName:'Trọng âm'},{eName:'final_sound',vName:'Âm cuối'},{eName:'intonation',vName:'Ngữ điệu'}
];
export const CRITERIA_OF_RECORD_VIDEO = [
    {eName:'energy',vName:'Năng lượng'},{eName:'style_expression',vName:'Phong thái, biểu cảm'},{eName:'accuracy',vName:'Độ chính xác'},{eName:'stress',vName:'Trọng âm'},{eName:'final_sound',vName:'Âm cuối'},{eName:'intonation',vName:'Ngữ điệu'}
];
export const CRITERIA_OF_WRITING = [
    {eName:'accuracy',vName:'Độ chính xác'},{eName:'creative',vName:'Sáng tạo'},{eName:'grammar',vName:'Ngữ Pháp'},
];
export const KIND_OF_CRITERIA = new Map([
    [RECORD_AUDIO,CRITERIA_OF_RECORD_AUDIO],
    [RECORD_VIDEO,CRITERIA_OF_RECORD_VIDEO],
    [WRITE_PARAGRAPH,CRITERIA_OF_WRITING],
]);
export const E_NAME_TO_V_NAME = new Map([
    ['accuracy','Độ chính xác'],
    ['stress','Trọng âm'],
    ['final_sound','Âm cuối'],
    ['intonation','Ngữ điệu'],
    ['energy','Năng lượng'],
    ['style_expression','Phong thái, biểu cảm'],
    ['creative','Sáng tạo'],
    ['grammar','Ngữ Pháp']
])