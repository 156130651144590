<template>
  <div>
    <div class="row">
      <div class="col-md-2">
        Task việc:
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-9">
        Danh sách lỗi:
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div v-for="item in processTypes" class="row mb-12">
            <div class="process-total" @click="getError(item.value)">
              <span class="process-type"
                    :class="{'process-type-error-selected': item.amount > 0 && item.disable == true,
                              'process-type-error': item.amount > 0 && item.disable == false,
                              'process-type-success-selected': (item.amount == 0 | item.amount == null) && item.disable == true,
                              'process-type-success': (item.amount == 0 | item.amount == null) && item.disable == false
              }">
                {{ item.label }}</span>
            </div>
            <span class="amount-error">{{ item.amount > 0 ? item.amount : 0 }}</span>
        </div>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-9">
      <div class="example-preview b-table-sticky-header">
        <table class="table table-hover border mt-5 table-vertical-center b-table" style="word-break: break-word">
          <thead class="thead-light">
          <tr>
            <th scope="col" class="min-w-50px">STT</th>
            <th scope="col">Nội dung</th>
            <th scope="col" style="min-width: 170px">Thời gian phát sinh lỗi</th>
          </tr>
          </thead>
          <tbody>
            <tr v-if="errors.length > 0" v-for="(error, key) in errors">
              <th scope="row">{{ key + 1 }}</th>
              <td>{{ error.message }}</td>
              <td>{{ error.date_appear | formatDateTimeAsia }}</td>
            </tr>
            <tr v-if="errors.length == 0">
              <td colspan="3">Hiện tại không có lỗi nào phát sinh</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
    import {
        TICKET_SUPPORT_CENTER_BACKEND,
        TICKET_INPUT_SOLUTION, TICKET_STUDENT_MANAGEMENT_PROCESS_DETAIL,
        TICKET_SUPPORT_CENTER_PROCESS_DETAIL, TICKET_STUDENT_MANAGEMENT_EDUCENTER
    } from "../../../core/option/warningError";
    import {GET_ERROR_LIST} from "../../../core/services/store/report-error/reportError.module";
    const _ = require('lodash');
    export default {
        name: "DetailError",
        props: {
            process: {
                default: null
            },
            query: {
              default: null
            },
            totalError: {
                default: Object
            }
        },
        data() {
            return {
                loading: {
                    save: false,
                    loadingSelect: false
                },
                processTypes: [],
                TICKET_SUPPORT_CENTER_PROCESS_DETAIL,
                TICKET_STUDENT_MANAGEMENT_PROCESS_DETAIL,
                errors: [],
            }
        },
        methods: {
            close() {
                this.$emit('close')
            },
            summit() {
                this.$emit('completed', this.checkBox);
            },
            getError(processType) {
                this.$store.dispatch(GET_ERROR_LIST, {
                    operating: this.query.operating,
                    type: this.query.type,
                    date_range: this.query.date_range,
                    is_feedback_error: this.query.is_feedback_error,
                    process: this.process,
                    process_type: processType
                }).then(res => {
                    this.errors = res.data
                }).catch(res => {
                    console.log(res)
                })
                this.selectDisable(processType);
            },
            selectDisable(processType) {
                _.forEach(this.processTypes, (value) => {
                    if (value.value == processType) {
                        value.disable = true
                    } else {
                        value.disable = false
                    }
                });
            }
        },
        mounted() {
            if (this.process == TICKET_SUPPORT_CENTER_BACKEND) {
                this.processTypes = TICKET_SUPPORT_CENTER_PROCESS_DETAIL
                this.getError(TICKET_INPUT_SOLUTION);
            }
            if (this.process == TICKET_STUDENT_MANAGEMENT_EDUCENTER) {
                this.processTypes = TICKET_STUDENT_MANAGEMENT_PROCESS_DETAIL
                this.getError(TICKET_INPUT_SOLUTION);
            }
            _.forEach(this.processTypes, (value) => {
                value.amount = this.totalError[value.slug]
                if (value.value == 1) {
                    value.disable = true
                } else {
                    value.disable = false
                }
            });
        },
    }
</script>

<style>
  .process-type-error {
    background-color: #d52323;
  }
  .process-type-success {
    background-color: rgb(0, 179, 0);
  }
  .process-type-error-selected {
    background-color: #ad3737;
  }
  .process-type-success-selected {
    background-color: rgb(22 102 22);
  }
  .process-type {
    color: white;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    display: inline-block;
    min-width: 132px;
  }
  .process-total {
    margin: 20px 0px 0px 10px;
    position: absolute;
    z-index: 9;
    cursor: pointer;
    word-break: break-word;
  }
  .amount-error {
    border: 1px solid black;
    background-color: white;
    border-radius: 25px;
    height: fit-content;
    width: fit-content;
    padding: 1px 3px 0px 2px;
  }
  .amount-error {
    position: relative;
    z-index: 10;
    margin-left: 129px;
    margin-top:10px;
    min-width: 40px;
    text-align: center;
  }
  @media only screen and (max-width: 1440px){
    .amount-error {
      min-width: max-content!important;
    }
  }
</style>
