<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="course_create" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Sửa combo
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'promotion-index' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Tiêu đề
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="title" name="Tiêu đề" rules="required"
                                    v-slot="{ errors,classes }">
                  <el-input placeholder="Tiêu đề" :class="classes" v-model="promotion.name"></el-input>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Trung tâm
                  <span class="text-danger w-100"></span></label>
                <ValidationProvider vid="title" name="Trung tâm" rules="required"
                                    v-slot="{ errors,classes }">
                  <el-select disabled v-model="promotion.center_id" placeholder="Trung tâm" class="w-100" @change="changeCenter">
                    <el-option
                        v-for="item in centers"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Chi nhánh áp dụng
                  <span class="text-danger w-100">*</span></label>
                <ValidationProvider vid="title" name="Chi nhánh" rules="required"
                                    v-slot="{ errors,classes }">
                  <el-select filterable v-model="promotion.branch_id" multiple placeholder="Chi nhánh" class="w-100"
                             @change="changeBranch">
                    <el-option
                        v-for="item in branches"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label>Giá trị giảm
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="title" name="Giá trị giảm" rules="required"
                                    v-slot="{ errors,classes }">
                  <money v-model="promotion.value" v-bind="money" class="form-control" :class="classes"
                  ></money>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Ngày bắt đầu<span class="text-danger">*</span></label>
                    <ValidationProvider vid="time_publish" name="Ngày bắt đầu" rules="required"
                                        v-slot="{ errors,classes }">
                      <date-picker
                          v-model="promotion.start_date"
                          type="date"
                          placeholder="Thời gian xuất bản"
                          format="DD-MM-YYYY"
                          valueType="YYYY-MM-DD"
                          :default-value="new Date()"
                      ></date-picker>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Ngày kết thúc<span class="text-danger"></span></label>
                    <ValidationProvider vid="end_date" name="Ngày kết thúc" rules=""
                                        v-slot="{ errors,classes }">
                      <date-picker
                          v-model="promotion.end_date"
                          type="date"
                          placeholder="Ngày kết thúc"
                          format="DD-MM-YYYY"
                          valueType="YYYY-MM-DD"
                          :default-value="new Date()"
                      ></date-picker>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <p>Danh sách khóa học</p>
              <div class="card card-custom">
                <div class="card-body list-courses">
                  <div class="row">
                    <div class="col-md-12">
                      <el-select
                          v-model="list_course"
                          multiple
                          filterable
                          placeholder="Vui lòng chọn khóa học"
                          @input="selectCourse"
                      >
                        <el-option
                            v-for="item in courses"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-12">
                      <draggable
                          v-if="array_course_select.length"
                          :list="array_course_select"
                          :disabled="!enabled"
                          class="list-group"
                          ghost-class="ghost"
                          :move="checkMove"
                          @start="dragging = true"
                          @end="dragging = false"
                      >
                        <div
                            class="list-group-item mt-2 cursor-pointer border-course-item"
                            v-for="(element, index) in array_course_select"
                            :key="element.name"
                        >

                          <span class="dot mr-4">{{ index + 1 }}</span>{{ element.name }}
                          <i class="fa fa-align-justify handle float-right"></i>
                        </div>
                      </draggable>
                      <p v-else>Chưa có lớp học nào được chọn</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button
                type="submit"
                class="btn btn-success mr-2"
            >
              Cập nhật
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
// Date picker
import Swal from "sweetalert2";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import draggable from 'vuedraggable'
import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
import {GET_COURSES} from "../../../core/services/store/course/course.module";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
import {Money} from 'v-money'
import {
  GET_PROMOTION_BY_NUMBER,
  GET_PROMOTIONS,
  UPDATE_PROMOTION
} from "../../../core/services/store/contract/promotions.module";

export default {
  name: "PromotionEdit",
  components: {
    DatePicker,
    draggable,
    Money
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: ',',
        prefix: '',
        suffix: ' VNĐ',
        precision: 0,
        masked: false,
      },
      loading_search: false,
      enabled: true,
      dragging: false,
      list_course: [],
      promotion: {
        name: '',
        course_ids: [],
        amount_course: '',
        value: '',
        unit: 1,
        type: 1,
        start_date: '',
        end_date: '',
        user_created: '',
        user_updated: '',
        user_delete: '',
        gift: '',
        amount: '',
        center_id: '',
        branch_id: [],
        is_active: '',
        is_center: '',
        description: '',
        number: '',
        level_in: '',
        level_out: '',
      },
      branches: [],
      courses: [],
      centers: [],
      allCombo: [],
      array_course_select: [],
    }
  },
  watch: {},
  async mounted() {
    await this.getByNumber();
    await this.searchCourses();
    await this.getCenters();
    await this.getBranches();
    this.getAllCombo();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý combo", route: 'promotion-index'},
      {title: "Sửa combo"}
    ]);
  },
  methods: {
    async getByNumber() {
      await this.$store.dispatch(GET_PROMOTION_BY_NUMBER, {
        number: this.$route.params.id,
      }).then((res) => {
        if (!res.error) {
          this.promotion = res.data;
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getCenters() {
      this.$store.dispatch(GET_CENTERS, {
        limit: 10000,
      }).then((res) => {
        if (!res.error) {
          this.centers = res.data.data;
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 2000,
        center_id: this.promotion.center_id
      }).then((res) => {
        if (!res.error) {
          this.branches = res.data.data;
          if (this.branches.length) {
            this.branches.unshift({
              id: 0,
              name: 'Tất cả chi nhánh'
            })
          }
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    changeCenter() {
      this.promotion.branch_id = [];
      this.array_course_select = [];
      this.list_course = [];
      this.getBranches();
      this.searchCourses();
    },
    changeBranch($event) {
      if ($event.includes(0)) {
        let arrIds = [];
        this.branches.map((value => {
          if (value.id != 0) {
            arrIds.push(value.id);
          }
        }))
        this.promotion.branch_id = arrIds;
      }
      this.checkExistCombo();
    },
    checkExistCombo() {
      this.allCombo.map((combo => {
        this.promotion.branch_id.map((branch_id) => {
          if (branch_id == combo.branch_id) {
            let course_in_combo = combo.course_ids;
            this.handleIdsCourse();
            let course_select_combo = this.promotion.course_ids;
            if (course_in_combo == course_select_combo.join(",")) {
              this.resetSelectCourse(branch_id);
            }
          }
        })
      }))
    },
    resetSelectCourse(branch_id) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Combo này đã tồn tại trong chi nhánh:' + this.getNameBranch(branch_id),
        // footer: '<a href="">Why do I have this issue?</a>'
      })
      this.promotion.course_ids = [];
      this.array_course_select = [];
      this.list_course = [];
    },
    getNameBranch(id) {
      for (let i = 0; i < this.branches.length; i++) {
        if (id == this.branches[i].id) {
          return this.branches[i].name;
        }
      }
      return '';
    },
    async searchCourses() {
      await this.callApiGetCourse({
        limit: 100,
        center_id: this.promotion.center_id
      }).then(() => {
        this.list_course = this.promotion.course_ids.map((item) => parseInt(item));
        this.courses.map((course => {
          this.list_course.map((id) => {
            if (id == course.id) {
              this.array_course_select.push(course);
            }
          })
        }));
      })
    },
    getAllCombo() {
      this.$store.dispatch(GET_PROMOTIONS, {
        limit: 10000,
        end_date: new (Date),
      }).then((res) => {
        if (!res.data.error) {
          this.allCombo = res.data.data;
        }
      });
    },
    async callApiGetCourse(payload) {
      await this.$store.dispatch(GET_COURSES, payload).then((res) => {
        if (!res.error) {
          this.courses = res.data.data;
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },

    selectCourse(ids) {
      this.array_course_select = [];
      ids.map((id) => {
        this.courses.map((course) => {
          if (id == course.id) {
            this.array_course_select.push(course);
          }
        })
      })
      this.checkExistCombo();
    },
    onSubmit() {
      this.handleIdsCourse();
      this.$store.dispatch(UPDATE_PROMOTION, this.promotion).then((res) => {
        if (!res.error) {
          this.$router.push({name: "promotion-index"}).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Cập nhật',
              variant: 'success',
              solid: true
            });
          })
        } else {
          this.$bvToast.toast(res.message, {
            title: 'Cập nhật',
            variant: 'danger',
            solid: true
          });
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      });
    },
    handleIdsCourse() {
      this.promotion.course_ids = [];
      this.array_course_select.map((value => {
        this.promotion.course_ids.push(value.id);
      }))
    },
    checkMove: function (e) {
      console.log('remove')
    }
  },
}
</script>

<style>
.el-select {
  margin-left: 0;
}

.list-courses {
  background: #FFFFFF;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.09);
}

span.dot {
  background: #4c0192;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
}

.course-select {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  margin: 1rem 1rem 1rem 0;
  position: relative;
}

.list-group-item + .list-group-item {
  border-top-width: thin;
}

.border-course-item {
  border-color: #DCDFE6;
}

.el-select {
  width: 100%;
}

/*.course-select-item {*/
/*  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);*/
/*}*/

</style>