export const WITHDRAW_TYPE = [
    {value: 1, name: 'Hủy khóa'},
    {value: 2, name: 'Không hủy khóa'},
]

export const STUDENT_AMOUNT_TYPE = [
    {
        id: 2,
        name: 'Tất cả'
    },
    {
        id: 0,
        name: 'Rút tiền'
    },
    {
        id: 1,
        name: 'Nạp tiền'
    },
]

export const STUDENT_AMOUNT_STATUS = [
    {
        value: 0,
        label: 'Tất cả'
    },
    {
        value: 1,
        label: 'Tồn tại'
    },
    {
        value: 4,
        label: 'Đã hủy'
    },
]

export const TYPE_NAP_RUT = [
    {
        id: 0,
        name: 'Tất cả'
    },
    {
        id: 1,
        name: 'Rút tiền'
    },
    {
        id: 2,
        name: 'Nạp tiền'
    },
    {
        id: 3,
        name: 'Hoàn tiền'
    },
];
export const BEN_THU_TIEN = [
    {
        id: 0,
        name: 'Trung tâm'
    },
    {
        id: 1,
        name: 'Edutalk'
    }
];

export const TYPE_NAP_RUT_KHDT = [
    {
        id: 0,
        name: 'Tất cả'
    },
    {
        id: 1,
        name: 'Rút tiền'
    },
    {
        id: 2,
        name: 'Nạp tiền'
    },
    {
        id: 3,
        name: 'Hoàn tiền'
    },

    {
        id: 6,
        name: 'Chuyển nhượng'
    },
];
