<template>

    <div class="card card-custom gutter-b">
        <router-view></router-view>
        <div class="card-header">
            <div class="card-title">
                Lộ trình học
            </div>

        </div>
        <div class="container mt-3">
            <h1 style="font-size: 20px">Lộ trình học {{ this.$route.query.course }} :</h1>
        </div>

        <div class="card-body">
            <div class="example mb-10">
                <div class="example-preview table-responsive">
                    <table class="table table-bordered  ">
                        <thead class="thead-light">
                        <tr class="">
                            <th style="width: 20%">Buổi</th>
                            <th style="width: 40%">Nội dung chính</th>
                            <th style="width: 20%">Ngày học</th>
                            <th style="width: 20%" class="text-center">Hành động</th>
                        </tr>
                        </thead>
                        <tbody v-if="scheduleLearning.length > 0">
                        <tr v-for="(item, index) in scheduleLearning" :key="index">
                            <td class="text-left">{{ 'Buổi ' + ++index }}</td>
                            <td class="text-left">{{ item.name }}</td>
                            <td>
                                <span v-for="(item_d, index_p) in item.date_of_weeks" :key="index_p">{{ item_d.date }}{{ index_p !== item.date_of_weeks.length - 1 ? ', ' : '' }}</span>
                            </td>
                            <td class="text-center d-flex justify-content-center">
                                <button
                                    title="Xem chi tiết"
                                    class="btn btn-sm btn-icon btn-outline-primary mx-1"
                                    @click="showDetail(item,index)"
                                >
                                    <i class="far fa-eye"></i>
                                </button>
                                <router-link
                                    title="Quản lí dữ liệu BTVN"
                                    href="javascript:"
                                    class="btn btn-sm btn-icon btn-outline-warning mx-1"
                                    :to="{ name: 'schedule-course-edit', params: {id: schedule_id}, query: {lesson: index,test_id: item?.self_study == 0?item?.exam:item?.self_study} }"
                                >
                                    <i class="fas fa-edit"></i>
                                </router-link>
                                <router-link
                                    title="Quản lý slide"
                                    href="javascript:"
                                    class="btn btn-sm btn-icon btn-outline-success mx-1"
                                    :to="{ name: 'schedule-course-slide', params: {id: schedule_id}, query: {index: item.id } }"
                                >
                                    <i class="far fa-list-alt"></i>
                                </router-link>
                                <router-link
                                    title="Quản lý giáo trình"
                                    href="javascript:"
                                    class="btn btn-sm btn-icon btn-warning mx-1"
                                    :to="{ name: 'curriculum', params: {id: schedule_id}, query: {index: item.id } }"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-book" viewBox="0 0 16 16">
                                        <path
                                            d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                                    </svg>
                                </router-link>
                            </td>

                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr>
                            <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <modal-detail :item="detailSession"></modal-detail>
        <el-dialog title="Upload Slide" :visible.sync="dialogUpload" @close="closeModal">
            <div class="container">
                <el-upload
                    ref="upload"
                    accept=".pptx"
                    :limit="1"
                    list-type="text"
                    action=""
                    drag
                    :file-list="fileList"
                    :on-change="handleChange"
                    :on-remove="handleRemove"
                    :auto-upload="false"
                    :multiple="false"
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    <div class="el-upload__tip" slot="tip">Powerpoint files</div>
                </el-upload>
                <div class="mt-3">
                    <el-button :disabled="disable" :loading="loading" size="small" type="success"
                               class="btn btn-success" @click="submitUpload">Upload
                    </el-button>
                </div>
            </div>
            <div slot="footer" class="example-btn text-right">
                <el-button @click="dialogUpload = false" type="info" class="btn m-4">Cancel</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
    DETAIL_SCHEDULE_COURSE,
    REMOVE_UPLOAD_SLIDE_SCHEDULE,
    UPLOAD_SLIDE_SCHEDULE
} from '@/core/services/store/course/classes.module';
import {mapGetters} from "vuex";
import Swal from "sweetalert2";
import ModalDetail from "@/view/pages/schedule-course/component/modalDetail.vue";

export default {
    name: "Off-Lesson",
    components: {
        ModalDetail

    },
    data() {
        return {
            page: 1,
            data: [],
            fileList: [],
            schedule_id: this.$route.params.id,
            folder_name: this.$route.query.folder_name,
            current_schedule_id: '',
            dialogUpload: false,
            form: {
                name: ''
            },
            disable: false,
            loading: false,
            detailSession: {},
            indexSession: null
        }
    },
    mounted() {
        this.getListScheduleCourse();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý lộ trình", route: 'schedule-course-list'},
            {title: "Chi tiết lộ trình"}
        ]);
    },
    computed: {
        ...mapGetters(['scheduleLearning'])
    },
    methods: {
        async showDetail(item, index) {
            this.detailSession = await item;
            this.$bvModal.show("Modal_Edit_News")
        },
        getListScheduleCourse() {
            this.$store.dispatch(DETAIL_SCHEDULE_COURSE, this.$route.params.id).then((res) => {
                this.data = res.data
            });
        },
        openDialogUpload(id) {
            this.dialogUpload = true;
            this.current_schedule_id = id;
        },
        closeModal() {
            this.$refs.upload.clearFiles();
            this.fileList = [];
        },
        handleRemove() {
            this.fileList = []
        },
        closeFile() {
            this.file_name = '';
        },
        handleChange(file, fileList) {
            this.fileList = fileList
        },
        submitUpload() {
            if (this.fileList.length > 0) {
                this.loading = true;
                let formData = new FormData();
                formData.append('file', this.fileList[0].raw);
                let payload = {
                    id: this.current_schedule_id,
                    params: formData,
                }
                this.$store.dispatch(UPLOAD_SLIDE_SCHEDULE, payload).then((res) => {
                    this.dialogUpload = false;
                    this.$notify({
                        title: 'Thành công',
                        message: res.message,
                        type: 'success'
                    })
                }).catch((error) => {
                    this.$notify({
                        title: 'Thất bại',
                        message: error.data.message,
                        type: 'error'
                    })
                }).finally(() => {
                    this.loading = false
                })
            } else {
                this.$message.warning('Vui lòng chọn file');
            }
        },
        removeSlide(id) {
            Swal.fire({
                title: "Bạn chắc chắn muốn gỡ slide?",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Đồng ý",
                cancelButtonText: "Không",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch(REMOVE_UPLOAD_SLIDE_SCHEDULE, id).then((res) => {
                        this.$notify({
                            title: 'Thành công',
                            message: res.message,
                            type: 'success'
                        })
                    }).catch((error) => {
                        this.$notify({
                            title: 'Thất bại',
                            message: error.data.message,
                            type: 'error'
                        })
                    })
                }
            });
        },
        changeDate(data) {
            let text = '';
            forea
            console.log(data, 'data');
        }
    }
}

</script>
