<template>
  <div id="modal-marketing-input-amount" class="d-inline">
    <button class="btn btn-primary btn-sm"
            v-b-modal="`modal-marketing-input-amount`">
      <i class="fas fa-plus-circle"></i>Nhập liệu thông tin
    </button>
    <b-modal
        centered
        id="modal-marketing-input-amount"
        size="xl"
        title="Nhập liệu thông tin"
        ok-title="Xác nhận"
        cancel-title="Hủy bỏ"
        :ok-disabled="okDisable"
        @ok=handleOk
    >
      <div class="card">
        <div class="card-header">
          <ValidationObserver v-slot="{ handleSubmit }" ref="form">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Chọn tháng</label>
                  <el-date-picker
                      format="MM-yyyy"
                      value-format="yyyy-MM"
                      type="month"
                      v-model="query.month"
                      placeholder="Chọn tháng"
                      @input="getMarketing"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Nguồn khách hàng</label>
                  <ValidationProvider vid="source_id" name="Nguồn"
                                      :rules="{}"
                                      v-slot="{ errors,classes }">
                    <el-select v-model="query.source_id"
                               placeholder="Chọn nguồn"
                               clearable
                               filterable
                               :class="classes"
                               @change="getMarketing"
                    >
                      <el-option
                          v-for="item in sources"
                          :key="item.id"
                          :label="item.label"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Trung tâm</label>
                  <ValidationProvider vid="center_id" name="Trung tâm"
                                      :rules="{  }"
                                      v-slot="{ errors,classes }">
                    <el-select v-model="query.center_id"
                               placeholder="Chọn trung tâm"
                               clearable
                               filterable
                               :class="classes"
                               @change="getMarketing"
                    >
                      <el-option
                          v-for="item in centers"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
        <div class="card-body">
          <div class="example mb-10" v-if="this.query.center_id && this.query.source_id && this.query.month">
            <h5 class="font-weight-bold">Chi phí quảng cáo</h5>
            <div class="example-preview table-responsive">
              <table class="table table-vertical-center">
                <thead>
                <tr>
                  <th class="min-w-160px" v-for="(item, index) in inputs" :key="index">{{ index }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td v-for="(item, index) in inputs" :key="index">
                    <InputAmountItem
                        :date="index"
                        :amount="item"
                        :centers="centers"
                        :sources="sources"
                        @changeInputAmount="changeInputAmount"
                    >
                    </InputAmountItem>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>
            <p class="text-danger">Cần phải chọn nguồn và trung tâm</p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {MARKETING_INPUT_AMOUNT, MARKETING_INPUT_AMOUNT_STORE} from "@/core/services/store/bizfly/reportDigital.module";
import InputAmountItem from "@/view/pages/report/components/InputAmountItem";
import moment from 'moment-timezone';

export default {
  name: "ModalMarketingInputAmount",
  components: {InputAmountItem},
  props: {
    sources: {
      type: Array,
      default: () => {
        return [];
      }
    },
    centers: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      okDisable: false,
      inputs: [],
      query: {
        month: null,
        source_id: '',
        center_id: '',
      }
    }
  },
  watch: {},
  mounted() {
    this.handleDefault();
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      this.getMarketing();
    })
  },
  methods: {
    changeInputAmount(amountEmit, dateEmit) {
      for (let key in this.inputs) {
        if (dateEmit == key) {
          this.inputs[key] = amountEmit;
        }
      }
    },
    getMarketing() {
      this.inputs = [];
      this.$store.dispatch(MARKETING_INPUT_AMOUNT, this.query).then((data) => {
        this.inputs = data.data;
      })
    },
    handleOk(bvModalEvent) {
      this.store();
      bvModalEvent.preventDefault();
    },
    store() {
      let payload = {
        inputs: this.inputs,
        ...this.query
      }
      this.$store.dispatch(MARKETING_INPUT_AMOUNT_STORE, payload).then(() => {
        this.$message.success('Nhập liệu thành công');
        this.$emit('inputSuccess');
        this.hideModal();
      }).catch((e) => {
        if (e.response.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.response.data.data.message_validate_form);
        }
      });
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-marketing-input-amount')
    },
    handleDefault() {
      this.query.month = moment().format('yyyy-MM');
    },
  }
}
</script>

<style scoped>

</style>