import moment from "moment-timezone";

export const UNSENT = 0
export const SENT_ZALO = 1;
export const SENT_EMAIL = 2;
export const SENT_SMS = 3;
export const COLOR_SENT = new Map([
    [SENT_ZALO, "badge badge-primary"],
    [SENT_EMAIL, "badge badge-warning"],
    [SENT_SMS, "badge badge-success"],
]);
export const NAME_SENT = new Map([
    [SENT_ZALO, "Zalo"],
    [SENT_EMAIL, "Email"],
    [SENT_SMS, "SMS"],
]);

export function formatMoneyToVND(value){
    value = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
    return value
}
export function formatDate(value){
   return  moment(value).format('HH:mm DD-MM-YYYY')
}
export const SUCCESS = 1;
export const FAIL = 0;

