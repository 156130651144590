<template>
    <div>
        <div class="card card-custom gutter-b">
            <div class="card-header d-flex justify-content-between">
                <div class="card-title">
                    <h3 class="card-label">Lịch sử đăng nhập</h3>
                </div>
            </div>
            <div class="card-body">
                <div class="form-group mb-8">
                    <div class="alert alert-custom alert-default" role="alert">
                        <div class="alert-icon">
                            <span class="svg-icon svg-icon-primary svg-icon-xl">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="24px" height="24px" viewBox="0 0 24 24"
                                     version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none"
                                       fill-rule="evenodd">
                                        <rect x="0" y="0" width="24"
                                              height="24"></rect>
                                        <path d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z"
                                              fill="#000000" opacity="0.3"></path>
                                        <path d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z"
                                              fill="#000000"
                                              fill-rule="nonzero"></path>
                                    </g>
                                </svg>
                                <!--end::Svg Icon-->
                            </span>
                        </div>
                        <div class="alert-text">
                            <span>Chú thích:</span>
                            <span class="label label-inline label-light-primary font-weight-bold ml-1">Backend</span>
                            <span class="label label-inline label-light-success font-weight-bold ml-1">Nội bộ</span>
                            <span class="label label-inline label-light-danger font-weight-bold ml-1">Center</span>
                        </div>
                    </div>
                </div>
                <!--begin::Example-->
                <div class="example mb-10">
                    <div class="form-group">
                        <form class="form-inline">
                            <div role="group" class="input-group">
                                <multiselect v-model="form.guard" :options="guards"
                                             :searchable="true"
                                             :close-on-select="true"
                                             :show-labels="true" placeholder="Chọn trang"
                                             @input="selectGuard"
                                ></multiselect>
                            </div>
                            <div role="group" class="input-group ml-4">
                                <date-picker v-model="form.choose_date" valueType="format" placeholder="Chọn ngày tháng"
                                             @input="selectDate"></date-picker>
                            </div>
                        </form>
                    </div>
                    <div class="example-preview table-responsive">
                        <table class="table table-vertical-center">
                            <thead>
                            <tr>
                                <th scope="col">No</th>
                                <th scope="col">Ip</th>
                                <th scope="col">Trình duyệt</th>
                                <th scope="col">Trang</th>
                                <th scope="col">Đăng nhập lúc</th>
                                <th scope="col">Đăng xuất lúc</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in getUserLogHistories" :key="index">
                                <th scope="row">{{++index}}</th>
                                <td class="">{{item.ip_address}}</td>
                                <td class="">{{item.user_agent}}</td>

                                <td class="">
                <span class="label label-inline label-light-primary font-weight-bold full-width"
                      v-if="item.guard === 'backend'">{{item.guard}}</span>
                                    <span class="label label-inline label-light-success font-weight-bold full-width"
                                          v-if="item.guard === 'noibo'">{{item.guard}}</span>
                                    <span class="label label-inline label-light-danger font-weight-bold full-width full-width"
                                          v-if="item.guard === 'educenter'">{{item.guard}}</span>
                                </td>
                                <td class="">{{item.login_at}}</td>
                                <td class="">{{item.logout_at}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="edu-paginate mx-auto text-center">
                <paginate
                        v-model="query.page"
                        :page-count="total_pages"
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="clickCallback"
                        :prev-text="'Trước'"
                        :next-text="'Sau'"
                        :container-class="'pagination b-pagination'"
                        :pageLinkClass="'page-link'"
                        :page-class="'page-item'"
                        :next-link-class="'next-link-item'"
                        :prev-link-class="'prev-link-item'"
                        :prev-class="'page-link'"
                        :next-class="'page-link'"
                >
                </paginate>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import LineChart from './LineChart.vue';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import Multiselect from 'vue-multiselect';
    import Select2Ajax from "../../../content/Select2Ajax";
    import Select2 from "../../../content/Select2";
    import {
        GET_USER_LOG_HISTORIES,
        GET_USER_LOG_TIME_WORK
    } from "../../../../core/services/store/user/users.module";
    import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";
    import VueJsonPretty from "vue-json-pretty";
    import numeral from 'numeral';

    const datasets = {
        2018: {
            borderColor: 'rgba(50, 115, 220, 0.5)',
            backgroundColor: 'rgba(50, 115, 220, 0.1)',
            data: [300, 700, 450, 750, 450]
        },
    };

    const options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback: value => numeral(value).format('$0,0')
                }
            }]
        },
        tooltips: {
            mode: 'index',
            callbacks: {
                label(tooltipItem, data) {
                    console.log(tooltipItem, data);
                    const label = data.datasets[tooltipItem.datasetIndex].label;
                    const value = numeral(tooltipItem.yLabel).format('$0,0');
                    return `${label}: ${value}`;
                }
            }
        }
    };
    const _ = require('lodash');

    export default {
        datasets,
        options,
        name: "Log-Login-History",
        components: {LineChart, VueJsonPretty, DatePicker, Multiselect, Select2Ajax, Select2},
        data() {
            return {
                id: '',
                query: {
                    page: 1,
                    search: "",
                },
                selectedYears: [2018],
                loaded: false,
                form: {
                    guard: '',
                    choose_date: '',
                },
                guards: ['backend', 'noibo', 'educenter'],
            }
        },
        computed: {
            ...mapGetters(['getUserLogHistories', 'getUserLogHistoriesPaginate', 'getUserLogChartData']),
            total_pages() {
                return this.getUserLogHistoriesPaginate?.total_pages || 0;
            },
            displayedDatasets() {
                return this.selectedYears.map(year => datasets[year]);
            }
        },
        mounted() {
            this.loaded = false
            this.id = this.$route.params.id;
            this.query.page = parseInt(this.$route.query.page ? this.$route.query.page : 1);
            this.form.guard = this.$route.query.guard ? this.$route.query.guard : '';
            this.form.choose_date = this.$route.query.choose_date ? this.$route.query.choose_date : '';
            this.$store.dispatch(GET_USER_LOG_TIME_WORK, this.id).then(() => {
                this.loaded = true;
            });
            this.$store.dispatch(GET_USER_LOG_HISTORIES, {
                id: this.id,
                params: {
                    page: this.query.page,
                    guard: this.form.guard,
                    choose_date: this.form.choose_date,
                }
            });
            console.log(this.getUserLogChartData);
            // _.reduce(this.getUserLogChartData,function (result,value,key) {
            //
            //   return result;
            // },[]);
            // this.chartdata = this.getUserLogHistories;
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý nhân sự", route: 'profile-1'},
                {title: "Lịch sử đăng nhập"}
            ]);

        },
        methods: {
            clickCallback(pageNum) {
                this.$router.push({
                    path: '',
                    query: {page: pageNum, guard: this.form.guard, choose_date: this.form.choose_date,}
                });
                this.$store.dispatch(GET_USER_LOG_HISTORIES, {
                    id: this.id,
                    params: {
                        page: pageNum,
                        guard: this.form.guard,
                        choose_date: this.form.choose_date,
                    }
                });
            },
            getQuery: function () {
                this.$store.dispatch(GET_USER_LOG_HISTORIES, {
                    id: this.id,
                    params: this.form
                }).then(() => {
                    this.query.page = 1;
                });
            },
            selectGuard: function () {
                this.$router.push({path: '', query: this.form});
                this.getQuery();
            },
            selectDate: function () {
                this.$router.push({path: '', query: this.form});
                this.getQuery();
            },
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .multiselect {
        width: 214px;
    }

    .mx-input {
        height: 40px;
    }
</style>