<template>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" id="room_create" @submit.prevent="handleSubmit(onSubmit)">
            <div class="card card-custom">
                <!--begin::Header-->
                <div class="card-header">
                    <div class="card-title">
                        <h3 class="card-label">
                            Sửa phòng học
                        </h3>
                    </div>
                    <div class="card-toolbar">
                        <router-link
                                class="font-weight-bold font-size-3  btn btn-secondary"
                                :to="{ name: 'room-index' }"
                        ><i class="fas fa-angle-double-left"></i> Quay lại
                        </router-link>
                    </div>
                </div>
                <!--end::Header-->
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tên phòng
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider vid="name" name="Tên phòng" rules="required"
                                                    v-slot="{ errors,classes }">
                                    <input type="text" ref="name" name="name" class="form-control"
                                           v-model="room.name"
                                           placeholder="Tên phòng" :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Sĩ số
                                    <span class="text-danger"></span></label>
                                <ValidationProvider vid="number_student" name="Sĩ số" rules="min_value:0"
                                                    v-slot="{ errors,classes }">
                                    <input type="number" ref="name" name="name" class="form-control"
                                           v-model="room.number_student"
                                           placeholder="Sĩ số" :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Chọn Trung tâm
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Trung tâm" rules="required" v-slot="{ errors,classes }">
                                    <el-select v-model="room.center_id" placeholder="Chọn Trung tâm"
                                               @input="handleCenter" clearable>
                                        <el-option
                                                v-for="item in centers"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Chọn Chi nhánh
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Chi nhánh" rules="required" v-slot="{ errors,classes }">
                                    <el-select v-model="room.branch_id" placeholder="Chọn chi nhánh" clearable>
                                        <el-option
                                                v-for="item in branches"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Trang thiết bị phòng
                                    <span class="text-danger"></span></label>
                                <textarea class="form-control" v-model="room.equipment" rows="3"
                                          placeholder="Trang thiết bị phòng"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Thông tin cấp trên-->
                <div class="separator separator-dashed my-5"></div>
                <div class="card-header">
                    <div class="card-title align-items-start flex-column">
                        <button
                                type="submit"
                                class="btn btn-success mr-2"
                        >
                            Cập nhật
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>

</template>
<script>
    import Multiselect from 'vue-multiselect'
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {Money} from 'v-money'

    import ImageResize from 'quill-image-resize-vue';
    import {ImageDrop} from 'quill-image-drop-module';
    import {GET_BRANCHES} from "../../../core/services/store/center/branch.module";
    import {GET_CENTERS} from "../../../core/services/store/center/centers.module";
    import {UPDATE_ROOM, GET_ROOM_BY_ID} from "../../../core/services/store/course/classes.module";

    const _ = require('lodash');

    Quill.register("modules/imageDrop", ImageDrop);
    Quill.register("modules/imageResize", ImageResize);
    export default {
        components: {
            Multiselect,
            Money,
        },
        name: "RoomEdit",
        data() {
            return {
                editorSettings: {
                    modules: {
                        imageDrop: true,
                        imageResize: {}
                    }
                },
                room: {
                    name: '',
                    number_student: '',
                    center_id: '',
                    branch_id: '',
                    equipment: '',
                },
                branches: [],
                branches_dependent: [],
                centers: [],

                // Cache
                cacheBranch: [],
                cacheCenter: [],
            }
        },
        mounted() {
            this.getCenters()
            this.getBranches()
            this.getById()
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý phòng học", route: 'profile-1'},
                {title: "Sửa phòng học"}
            ]);
        },
        computed: {},
        methods: {
            getById() {
                let id = this.$route.params.id;
                this.$store.dispatch(GET_ROOM_BY_ID, id).then((res) => {
                    if (!res.error) {
                        this.room = res.data
                        this.updateCacheState(res.data)
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            updateCacheState(room) {
                if (room.center != null) {
                    let center = room.center
                    this.cacheCenter = center
                    this.updateBranchByCenter(center.id)
                }
                if (room.branch != null) {
                    this.cacheBranch = room.branch
                }
            },
            updateBranchByCenter(id) {
                if (id == undefined) return
                this.cacheBranch = []
                this.branches_dependent = this.branches.filter((value) => {
                    return value.center_id == id
                })
            },
            getCenters() {
                this.$store.dispatch(GET_CENTERS, {
                    limit: 10000,
                    status: 1
                }).then((res) => {
                    if (!res.error) {
                        this.centers = res.data.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getBranches() {
                this.$store.dispatch(GET_BRANCHES, {
                    limit: 10000,
                    status: 1,
                    center_id: this.room.center_id
                }).then((res) => {
                    if (!res.error) {
                        this.branches = res.data.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            /*Set state*/
            handleCenter() {
                this.room.branch_id = '';
                this.getBranches();
            },
            onSubmit() {
                this.$store.dispatch(UPDATE_ROOM, this.room).then((res) => {
                    if (res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Cập nhật',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.$router.push({name: "room-index"}).then(() => {
                            this.$bvToast.toast(res.message, {
                                title: 'Cập nhật',
                                variant: 'success',
                                solid: true
                            });
                        })
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                });
            },
        }
    }
</script>
<style scoped>
    .mx-datepicker {
        width: 100% !important;
    }
    .el-select {
        display: unset;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
