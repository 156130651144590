<template>
    <div>
        <button
                @click="redoingTest"
                title="Làm lại bài kiểm tra"
                class="btn btn-xs btn-icon mr-2 btn-outline-danger">
            <i class="fa far fas fa-redo"></i>
        </button>
    </div>
</template>

<script>
    import Swal from "sweetalert2";
    import {REDOING_TEST} from "../../../../core/services/store/exam/exam.module";

    export default {
        name: "ButtonRedoingTest",
        props: {
            item: {
                type: Object,
                default: () => {
                    return null;
                }
            },
            test: {
                type: Object,
                default: () => {
                    return null;
                }
            }
        },
        methods: {
            redoingTest() {
                Swal.fire({
                    title: 'Bạn có muốn cho phép học viên làm lại bài không?',
                    icon: 'warning',
                    showDenyButton: false,
                    showCancelButton: true,
                    confirmButtonText: `Đồng ý`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.callApi();
                    }
                })
            },
            callApi() {
                this.$store.dispatch(REDOING_TEST, {
                    course_student_id: this.item.id,
                    schedule_test_list_id: this.test.id,
                    student_id: this.item.user.id,
                }).then((data) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Thông báo',
                        text: 'Cho học viên làm bài thành công. Học viên có thể bắt đầu làm bài ngay.',
                        timer: 5000
                    })
                    this.$emit('approve-success');
                });
            }
        }
    }
</script>

<style scoped>

</style>