<template>
  <div class="table-responsive" style="overflow: hidden; padding-bottom: 30px" v-loading="loading">
    <table class="table" style="text-align: center;">
      <thead>
      <tr style="border: 1px solid #EBEDF3">
        <th scope="col" class="w-180px" style="background: #F8F8F8; text-align: start">Danh mục bị thay đổi</th>
        <th scope="col" class="w-100px" style="background: #F8F8F8; text-align: start">Từ giá trị</th>
        <th scope="col" class="w-110px" style="background: #F8F8F8; text-align: start">Sang giá trị</th>
        <th scope="col" class="w-130px" style="background: #F8F8F8; text-align: start">Thời gian cập nhật</th>
        <th scope="col" class="w-150px" style="background: #F8F8F8; text-align: start;">Người cập nhật</th>
        <th scope="col" class="w-60px" style="background: #F8F8F8; text-align: start;">Năm</th>
      </tr>
      </thead>
      <tbody v-if="histories.length > 0">
        <tr v-for="item of histories" :key="item?.index" style="border: 1px solid #EBEDF3; border-top: 0">
          <td style="text-align: start" :title="item?.category">{{ item?.category ?? '' }}</td>
          <td style="text-align: start">{{ item?.fromValue ?? '' }}</td>
          <td style="text-align: start">{{ item?.toValue ?? '' }}</td>
          <td style="text-align: start">{{ item?.updatedAt ?? '' }}</td>
          <td style="text-align: start" :title="item?.personalUpdate">{{ item?.personalUpdate ?? '' }}</td>
          <td style="text-align: start" :title="item?.year">{{ item?.year ?? '' }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr style="word-break: break-word; white-space: nowrap; position: relative">
          <label style="position: absolute; left: 50%; translate: -50%; top: 10px">Chưa có dữ liệu</label>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import {GET_BOND_HISTORY} from "@/core/services/store/baseoffice/bondManagement";
import {BondHistoryModel} from "@/view/pages/baseoffice/bonds-management/model/bondModel";

export default {
  name: "BondHistoryModal",
  data() {
    return {
      histories: [],
      loading: true,
    }
  },
  props: {
    record: {
      type: Object,
      required: true,
      default: null,
    }
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    getHistory() {
      const payload = {
        user_id: this.record.userId,
        milestone: this.record.milestone
      }
      this.$store.dispatch(GET_BOND_HISTORY, payload).then(response => {
        if (!response.error) {
          this.loading = false
          this.histories = response.data?.map(item => {
            return new BondHistoryModel(item)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>