<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Tạo mới nhóm quyền.
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Tạo mới nhóm quyền</span>
      </div>
      <div class="card-toolbar">
        <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'role-list' }"
        ><i class="fas fa-angle-double-left"></i> Quay lại
        </router-link>
      </div>
    </div>
    <!--end::Header-->
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form id="role-add" ref="role-add" class="form" @submit.prevent="handleSubmit(onSubmit)">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
                <div id="input-group-2" role="group" class="form-group">
                  <label id="input-group-2__BV_label_">Tên nhóm quyền <span data-v-3736cd64="" class="text-danger">*</span></label>
                  <div>
                    <ValidationProvider name="Tên nhóm quyền" rules="required" vid="name" v-slot="{ errors,classes }">
                      <input type="text" ref="name" name="name" class="form-control" :class="classes"
                             placeholder="Tên nhóm quyền"
                             v-model=form.name>
                      <div class="fv-plugins-message-container">
                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              <b-tabs pills content-class="mt-3">
                <b-tab v-for="(data,guard) in getPermissionsByGuard" :key="guard" :title="'Trang '+guard">
                  <div class="accordion" role="tablist" v-if="guard!=='backend'">
                    <b-card no-body class="mb-1" :key="key" v-for="(item, key) in data">
                      <b-card-header header-tag="header" class="p-3" role="tab">
                        <b-form-checkbox
                                class="font-weight-bold"
                                v-b-toggle="'accordion'+item.slug"
                                v-model="form.guard_name[guard][item.id]"
                                size="lg"
                                @input="checkAll(guard,item)"
                        >
                          {{item.name}}
                        </b-form-checkbox>
                      </b-card-header>
                      <b-collapse :id="'accordion'+item.slug" visible role="tabpanel">
                        <b-card-body>
                          <div class="checkbox-inline">
                            <b-form-checkbox name="guard_name" class="col-md-3" v-for="(child, key) in item.children" :key="key" v-model="form.guard_name[guard][child.id]" size="lg">{{child.name}}</b-form-checkbox>
                          </div>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                  <div v-else>
                    <el-tree
                        :props="defaultProps"
                        :data="data"
                        default-expand-all
                        node-key="id"
                        show-checkbox
                        @check-change="handleCheckChange"
                        >
                    </el-tree>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" :disabled="isDisable" class="btn btn-success" id="sm_create_role">Tạo mới</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
  <!--end::Card-->
</template>

<script>
import {CREATE_ROLE, GET_PERMISSIONS_BY_GUARD} from "../../../../core/services/store/user/role.module";
import Multiselect from 'vue-multiselect';
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
const _ = deepdash(lodash);


  export default {
    name: "Create-role",
    components : {
      Multiselect
    },
    data() {
      return {
        form: {
          name: "",
          guard_name : {}
        },
        defaultProps: {
          label: 'name'
        },
        isDisable: false,
      };
    },

    beforeMount() {
      let dataMap = _.reduce(this.getPermissionsByGuard, function(result,value, key) {
        result[key] = value.reduce((res,val, k)=>{
          res[val.id] = false;
          return res;
        },{});
        return result;
      },{});
      this.form.guard_name = dataMap;
    },
    mounted: function () {
      this.$store.dispatch(GET_PERMISSIONS_BY_GUARD);
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Tài khoản", route: 'account'},
        {title: "Tạo nhóm quyền"}
      ]);
    },
    watch : {
      getPermissionsByGuard(newValue,oldValue){
        if(newValue!==oldValue){
          let dataMap = _.reduce(newValue, function(result,value, key) {
            result[key] = value.reduce((res,val, k)=>{
              res[val.id] = false;
              val.children.map((re)=>{
                  res[re.id] = false;
              });
              return res;
            },{});
            return result;
          },{});
          this.form.guard_name = dataMap;
        }
      }
    },
    computed : {
      ...mapGetters(['getPermissionsByGuard']),
      error : state => state.errors
    },
    methods: {
      handleCheckChange(data, checked, indeterminate) {
        this.form.guard_name['backend'][data.id] = checked
      },
        checkAll(guard,item){
          item.children.map((child)=>{
              this.form.guard_name[guard][child.id] = this.form.guard_name[guard][item.id];
          })
        },
      onSubmit() {
        this.isDisable = true;
        let condition = _.findValueDeep( this.form.guard_name, (value, key, parent) => {
          if(value != false) return true;
        });
        if(condition){
          // send role request
          this.$store.dispatch(CREATE_ROLE, this.form)
              .then((res) => {
                this.$router.push({ name: "role-list" }).then(() => {
                  this.$bvToast.toast(res.message,{
                    title: 'Thành công',
                    variant: 'success',
                    solid: true
                  });
                });
              }).catch((e) => {
                if (e.data.data.message_validate_form){
                  this.$refs.form.setErrors(e.data.data.message_validate_form);
                }
             }).finally(() => {
               this.isDisable = false
             });
        }else{
          this.$refs.form.setErrors({guard_name : ['Trang không được để trống']});
        }
      }
    },
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .accordion .card .card-header:focus,.accordion .card .card-header .card-title:focus{
    outline: none;
  }
  .accordion .card .card-header .card-title{
    color: #3F4254;
  }
</style>

