<template xmlns:white-space="http://www.w3.org/1999/xhtml">
  <div>
    <div class="class-title ml-7 d-flex">
      <p style="min-width: 75px">Lớp học:</p> <span>{{ classroom && classroom.name }}</span>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-3 form-group">
          <label>Lộ trình học:</label>
          <input type="text" class="form-control" :value="studentRegisterCourse && studentRegisterCourse.schedule" disabled>
        </div>
        <div class="col-md-3 form-group">
          <label>Số buổi đi học:</label>
          <input type="text" class="form-control" :value="studentRegisterCourse && studentRegisterCourse.schedule_number" disabled>
        </div>
        <div class="col-md-3 form-group">
          <label>Số buổi làm bài tập:</label>
          <input type="text" class="form-control" :value="studentRegisterCourse && studentRegisterCourse.homework" disabled>
        </div>
        <div class="col-md-3 form-group">
          <label>Kết quả đầu ra:</label>
          <input type="text" class="form-control" :value="renderOutput(studentRegisterCourse && studentRegisterCourse.is_pass)" disabled>
        </div>
      </div>
      <div class="table-responsive overflow-auto">
        <table class="table table-vertical-center table-hover">
          <thead>
          <tr>
            <th>Buổi học</th>
            <th>Thời gian học</th>
            <th>Điểm danh</th>
            <th v-if="studentRegisterCourse?.class?.center_id !== 530">HV đánh giá</th>
            <th>Bài tập về nhà</th>
            <th>GV nhận xét</th>
          </tr>
          </thead>
          <tbody v-if="loading">
          <tr>
            <td colspan="6" class="text-center">
              <div class="d-flex justify-content-center w-100">
                <i class="el-icon-loading"></i>
              </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else-if="schedules.length > 0">
          <tr v-for="(item, key) in schedules" :key="key">
            <td>{{ checkLesson(item.lesson, item.type, schedules.length) }}</td>

            <td>{{item.time}}</td>
            <td>
                <button  v-if="item.attendance !== ''"
                         class="px-2 py-1 label-rounded"
                    type="button" :class="item.attendance === 'Vắng mặt' ? 'bg-red text-white' :
                    item.attendance === 'Có mặt' ? 'bg-green text-white' :
                    item.attendance === 'Có phép' ? 'bg-yellow text-black' :
                    item.attendance === 'Ngừng học' ? 'bg-gray1 text-white' : 'bg-gray2'"
                        data-toggle="tooltip" data-placement="top" :title="item.attendance_reason">
                    {{item.attendance}}
                </button>
<!--              <label-->
<!--                  :title="item.attendance_reason"-->
<!--                  class="px-2 py-1 label-rounded"-->
<!--                  -->
<!--              ></label>-->
            </td>
            <td v-if="studentRegisterCourse?.center_id !== 530">
              <el-tooltip v-if="item.student_feeback" :content="item.student_feeback" placement="top">
                  <span
                      class="px-2 py-1 label-rounded"
                      :class="item.rating > 2 ? 'bg-green text-white' : item.rating <= 2 ? 'bg-red text-white' : 'bg-gray2'"
                  >{{ item.rating + ' sao' }}</span>
              </el-tooltip>
              <span
                  v-else-if="item.rating > 0"
                  class="px-2 py-1 label-rounded"
                  :class="item.rating > 2 ? 'bg-green text-white' : item.rating <= 2 ? 'bg-red text-white' : 'bg-gray2'"
              >{{ item.rating + ' sao' }}</span>
              <span
                  v-else-if="item.attendance"
                  class="px-2 py-1 label-rounded bg-gray2"
              >Không đánh giá</span>
            </td>
            <td>
                <span
                    v-if="item.homework_status"
                    class="px-2 py-1 label-rounded"
                    :class="item.homework_status === 'Hoàn thành' ? 'bg-green text-white'
                    : item.homework_status === 'Chưa làm' ? 'bg-gray1 text-white'
                    : item.homework_status === 'Nộp muộn' ? 'bg-red text-white'
                    : item.homework_status === 'Không có bài tập' ? 'bg-gray2'
                    : ''"
                >{{item.homework_status}}</span>

            </td>
            <td>
              <el-tooltip v-if="!item.comments && !item.comment_phase && !item.autoComment" content="Giảng viên chưa nhận xét" placement="top">
                <el-button
                    :disabled="item.lesson === 'Buổi khai giảng'
                      || item.lesson === 'Final Test'
                      || item.lesson === 'Buổi tổng kết'"
                    type="text" class="btn btn-xs btn-icon"
                    @click="showModalCommentDetail(item)">
                  <i class="far fa-eye"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip v-else content="Xem chi tiết" placement="top">
                <el-button
                    type="text" class="btn btn-xs btn-icon btn-outline-success" @click="showModalCommentDetail(item)">
                  <i class="far fa-eye"></i>
                </el-button>
              </el-tooltip>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="7" class="event-title no-data text-center">Chưa có dữ liệu</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <el-dialog
        :title="`Nhận xét của giảng viên về buổi ${lesson}`"
        :visible.sync="showCommentDetail"
        append-to-body
    >
      <div v-if="commentDetail || comment_phase || autoComment">
        <p v-if="autoComment" style="color: black; word-break: break-word">
          {{autoComment}}
        </p>
        <div v-if="commentDetail">
          <h5 class="font-weight-boldest mb-2">Chi tiết buổi học</h5>
          <div v-for="item of commentDetail" style="margin-bottom: 15px" :key="item.title">
            <div v-if="item.recommendAuto !== '' || item.feedback !== ''">
              <label class="label-detail">
                {{item.title === 1 ? 'Tính chủ động'
                  : item.title === 2 ? 'Độ tập trung' : 'Độ tiếp thu' }}
              </label>
              <p style="color: black; word-break: break-word; text-align: start" v-if="item.recommendAuto">
                {{ item?.recommendAuto }}
              </p>
              <div style="display: flex; align-content: center" v-if="item?.feedback">
                <div class="feedback"></div>
                <p style="margin: auto; word-break: break-word; text-align: start; width: 100%">{{ item?.feedback }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="comment_phase">
          <span style="color: black; font-weight: 700">Nhận xét tổng quan giai đoạn {{formatDate(comment_phase.start)}} - {{formatDate(comment_phase.end)}}</span>
          <p style="color: black; word-break: break-word">{{ comment_phase?.comment }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "ChiTietHocTap",
  props:{
    studentRegisterCourse: {
      type: Object,
      default: () => { return {} }
    },
    classroom: {
      type: [Object, String],
      default: () => { return {} }
    },
    loading: {
      type: Boolean,
      default: true,
    },
    schedules: {
      type: Array,
      required: true,
      default: []
    }
  },
  data(){
    return {
      lesson: null,
      showCommentDetail: false,
      commentDetail: [],
      comment_phase: {},
      autoComment: '',
    }
  },
  methods: {
    showModalCommentDetail (item) {
      if (item.comments || item.autoComment || item.comment_phase) {
        this.showCommentDetail = true
      }
      this.lesson = item.lesson
      this.commentDetail = item.comments
      this.comment_phase = item.comment_phase
      this.autoComment = item.autoComment
    },
    formatDate(date) {
      if (date) {
        return moment(String(date)).format('DD/MM')
      }
      return null
    },
    checkLesson(item, type, totalLesson) {
      if(item === 1) {
        return 'Buổi khai giảng'
      }
      if(type === 'test') {
        if(item === totalLesson - 1) {
          return 'Final Test'
        } else {
          return 'Mid Term Test'
        }
      }
      if(item === totalLesson - 1) {
        return 'Final Test'
      }
      if (item === totalLesson) {
        return 'Buổi tổng kết'
      }
      return `Buổi ${item}`
    },
    renderOutput(output) {
      let text;
      switch (output) {
        case 1:
          text = 'Pass';
          break;
        case 2:
          text = 'Fail';
          break;
        default:
          text = 'Chưa có';
      }
      return text;
    },
  }
}
</script>

<style scoped>
  .view-feedback {
    border: none;
    margin-left: 20px;
    padding: 6px 10px;
  }
  .view-feedback:hover {
    color: #0BB7AF;
  }
  .label-detail {
    background: #FEC803;
    padding: 4px 6px;
    border-radius: 4px;
    color: black;
  }
  .footer-modal__details {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .label-rounded {
    border-radius: 5px;
  }
  .bg-red {
    background: #BF0000;
  }
  .bg-green {
    background: #128300;
  }
  .bg-gray1 {
    background: #3D3D3D
  }
  .bg-yellow {
    background: #FFC700;
  }
  .bg-gray2 {
    background: #DEDEDE
  }
  .feedback {
    height: 45px;
    width: 1px;
    margin:0 10px;
    background: #858585;
  }
</style>
