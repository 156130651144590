<template id="tableResultSchedule">
    <el-table class="w-100" :data="listResult">
        <el-table-column property="name" label="Name" width="350px"></el-table-column>
        <el-table-column property="result" label="Result" width="280px"></el-table-column>
      </el-table>
</template>

<script>

export default {
name: "tableResultSchedule",
components: {},
props: {
  listResult: {
  type: Array,
    default: () => {
      return [];
    }
},
},
data() {
return {
 
}
},
mounted() {

},

methods: {

}

}
</script>

<style>

</style>