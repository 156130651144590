import ApiService from "@/core/services/api.service";
import {SET_ERROR} from "../auth.module";
const _ = require('lodash');

export const GET_COURSE_TYPES = "course-type-list";
export const DELETE_COURSE_TYPES = "delete-course-type";
export const CREATE_COURSE_TYPES = "create-course-type";
export const GET_COURSE_TYPE_BY_ID = "get-course-type-by-id";
export const UPDATE_COURSE_TYPES = "update-course-type";
export const GET_ALL_COURSE_TYPE = "get-all-course-type";

//mutations types
export const SET_COURSE_TYPES = "setCourseTypes";
export const SET_COURSE_TYPE_PAGINATE = "setCourseTypePaginate";
export const UPDATE_LIST_COURSE_TYPE = "update_list_course_type";
export const UPDATE_DELETE_COURSE_TYPE = "update_delete_course_type";
export const UPDATE_WHEN_UPDATE_COURSE_TYPE = "update_when_update_course_type";

const state = {
    errors: null,
    courseTypes: [],
    courseTypePaginateData: [],
};

const getters = {
    getAllCourseTypes(state) {
        return state.courseTypes;
    },
    // getCourseTypeById(state) {
    //     return state.user;
    // },
    getCourseTypePaginate(state) {
        return state.userPaginatedData;
    },
};

const actions = {
    [GET_COURSE_TYPES](context, credentials) {
        return new Promise((resolve) => {
            ApiService.query("/course-type", credentials)
                .then(({data}) => {
                    context.commit(SET_COURSE_TYPES, data.data.data);
                    const courseTypePagination = {
                        total: data.data.total,  // total number of elements or items
                        per_page: data.data.per_page, // items per page
                        current_page: data.data.current_page, // current page (it will be automatically updated when users clicks on some page number).
                        total_pages: data.data.last_page // total pages in record
                    }
                    context.commit(SET_COURSE_TYPE_PAGINATE, courseTypePagination);
                    resolve(data);
                })
                .catch((res) => {
                    console.log(res);
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },
    [GET_ALL_COURSE_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.query('course-type/all').then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_COURSE_TYPE_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('course-type', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [UPDATE_COURSE_TYPES](context, payload) {
        return new Promise(resolve => {
            ApiService.update('course-type', payload.id, payload).then(({data}) => {
                context.commit(UPDATE_WHEN_UPDATE_COURSE_TYPE, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_COURSE_TYPES](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.post('course-type', payload).then(({data}) => {
                context.commit(UPDATE_LIST_COURSE_TYPE, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [DELETE_COURSE_TYPES](context, id) {
        return new Promise((resolve,reject) => {
            ApiService.delete('course-type', id).then(({data}) => {
                context.commit(UPDATE_DELETE_COURSE_TYPE, id);
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
}

const mutations = {
    [SET_COURSE_TYPES](state, data) {
        state.courseTypes = data;
        state.errors = {};
    },
    [SET_COURSE_TYPE_PAGINATE](state, data) {
        state.courseTypePaginateData = data;
    },
    [UPDATE_DELETE_COURSE_TYPE](state, id) {
        let listCourseTypes = state.courseTypes;
        let index = _.findIndex(listCourseTypes, function (courseType) {
            return courseType.id === id;
        });
        listCourseTypes.splice(index, 1);
    },
    [UPDATE_LIST_COURSE_TYPE](state, courseType) {
        let listCourseTypes = state.courseTypes;
        listCourseTypes.unshift(courseType);
    },
    [UPDATE_WHEN_UPDATE_COURSE_TYPE](state, courseTypeUpdate) {
        let listCourseTypes = state.courseTypes;
        let index = _.findIndex(listCourseTypes, function (courseType) {
            return courseType.id === courseTypeUpdate.id
        });
        listCourseTypes.splice(index, 1);
        listCourseTypes.splice(index, 0, courseTypeUpdate);
    },
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
