<template>
  <div class="d-flex">
    <div
        class=""
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
        style="width: 100px; height: 100px;background-size: 100px 100px;"
        :style="{'background-image': 'url(' + urlProps + ')'}">
      <div
          v-if="hovered"
          class="w-100 h-100 d-flex justify-content-center align-items-center"
          style="background-color: rgba(0, 0, 0, 0.5)"
      >
        <i
            class="flaticon2-magnifier-tool mr-3"
            style="cursor: pointer"
            @click="openPhoto = true"
        ></i>
        <i v-if="haveDeleteImage"
            class="flaticon-delete"
            style="cursor: pointer"
           @click="deleteImage()"
        ></i>
      </div>
      <el-dialog
          :visible.sync="openPhoto"
          width="30%"
          center
          z-index="1"
      >
        <img
            :src="urlProps"
            style="width: 100%; height: auto"
        />
        <span slot="footer" class="dialog-footer">
                              <el-button @click="openPhoto = false"
                              >Đóng</el-button
                              ></span
        >
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageModal",
  data(){
    return{
      hovered : false,
      url: this.urlProps,
      openPhoto : false
    }
  },
  props:{
    urlProps : {
      type:String,
      default(){
        return ''
      }
    },
    haveDeleteImage:{
      type:Boolean,
      default(){
        return false
      }
    },
    indexImage:{
      type:Number,
      default(){
        return 0
      }
    }
  },
  methods:{
    deleteImage(){
      this.$emit('deleteImage',this.indexImage)
    }
  }
}
</script>

<style scoped>

</style>