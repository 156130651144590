import ApiService from "@/core/services/api.service";

const _ = require('lodash');

export const BIZFLY_GET_SALE_DIGITAL = "bizfly_get_sale_digital";
export const MARKETING_INPUT_AMOUNT = "marketing_input_amount";
export const MARKETING_INPUT_AMOUNT_STORE = "marketing_input_amount_store";

const state = {};

const getters = {};

const actions = {
    [BIZFLY_GET_SALE_DIGITAL](context, payload) {
        return new Promise(resolve => {
            ApiService.query('bizfly/sale/digital', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [MARKETING_INPUT_AMOUNT](context, payload) {
        return new Promise(resolve => {
            ApiService.query('reports/digital/marketing-input', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [MARKETING_INPUT_AMOUNT_STORE](context, payload) {
        return new Promise(resolve => {
            ApiService.post('digital/marketing-input/store', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
