<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách phòng ban</h3>
      </div>
      <div class="card-title">
        <button
          class="font-weight-bold font-size-3  btn btn-success"
          @click="createDepartment()"
        >Thêm mới
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="example mb-10">
        <div class="row">
          <div class="col-md-2 col-sm-12">
            <div class="form-group">
              <el-input placeholder="Tên phòng ban" v-model="query.name"></el-input>
            </div>
          </div>
          <div class="col-md-2 col-sm-12">
            <div class="form-group">
              <el-select v-model="query.status" filterable class="w-100" placeholder="Trạng thái"
                         clearable>
                <el-option
                  v-for="item in list_status"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-2 col-sm-12">
            <button v-if="!is_disable_search" class=" btn btn-primary font-weight-bold mr-2"
                    @click="searchData">
              <i class="fa fa-search"></i> Tìm kiếm
            </button>
            <button v-else type="button" disabled
                    class="btn btn-primary spinner spinner-white spinner-left font-weight-bold mr-2">
              Tìm kiếm
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="example-preview table-responsive">
              <table class="table table-vertical-center">
                <thead>
                <tr>
                  <th scope="col" style="width: 15%">STT</th>
                  <th scope="col">Mã phòng ban</th>
                  <th scope="col">Tên phòng ban</th>
                  <th scope="col" style="width: 15%">Trạng thái</th>
                  <th scope="col" style="width: 15%">Hành động</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in data" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.code }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    <department-status :item="item"></department-status>
                  </td>
                  <td>
                    <button @click="editDepartment(item.id)" title="Sửa"
                            class="btn btn-sm btn-icon mr-2 btn-outline-info"><i
                      class="fas fa-pen-nib"></i>
                    </button>
                    <button v-if="item.ticket_categories_count" disabled title="Không thể xóa phòng ban do đã được gán dữ liệu" @click="deleteDepartment(item.id)" href="javascript:"
                            class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></button>
                    <button v-else title="Xóa" @click="deleteDepartment(item.id)" href="javascript:"
                            class="btn btn-sm btn-icon btn-outline-danger"><i class="fas fa-trash"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="edu-paginate mx-auto text-center"  style="display: flex;justify-content: center">
              <paginate
                :page-count="last_page"
                :page-range="3"
                :margin-pages="1"
                :click-handler="clickCallback"
                :prev-text="'Trước'"
                :next-text="'Sau'"
                :container-class="'pagination b-pagination'"
                :pageLinkClass="'page-link'"
                :next-link-class="'next-link-item'"
                :prev-link-class="'prev-link-item'"
                :prev-class="'page-link'"
                :next-class="'page-link'"
                :page-class="'page-item'"
              >
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog @close="resetModal" :title="title" class="responsive" :visible.sync="dialogFormVisible">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form" @submit.prevent="handleSubmit(updateOrCreateDepartment)">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Tên phòng ban
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Tên phòng ban" rules="required"
                                    v-slot="{ errors,classes }">
                  <input type="text" ref="name" name="name" class="form-control" v-model="form.name"
                         placeholder="Tên phòng ban" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <button type="submit" class="btn btn-primary mr-2">{{ is_edit ? 'Sửa' : 'Thêm mới' }}</button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </el-dialog>
  </div>
</template>

<script>
import {
  DELETE_DEPARTMENT,
  GET_DEPARTMENT,
  SHOW_DEPARTMENT,
  STORE_DEPARTMENT,
  UPDATE_DEPARTMENT
} from "../../../core/services/store/department/department.module";
import Swal from "sweetalert2";
import {mapGetters} from "vuex";
import DepartmentStatus from "./DepartmentStatus";

export default {
  name: "DepartmentList",
  components: {
    DepartmentStatus
  },
  data() {
    return {
      data: [],
      last_page: 1,
      query: {
        name: '',
        status: '',
      },
      list_status: [
        {id: 0, name: 'Ẩn'},
        {id: 1, name: 'Hiển thị'},
      ],
      form: {
        name: '',
        status: '',
      },
      is_disable_search: false,
      page: 1,
      dialogFormVisible: false,
      is_edit: false,
      id: '',
    }
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["currentUser"]),
    title() {
      return this.is_edit ? 'Sửa phòng ban' : 'Tạo phòng ban';
    }
  },
  methods: {
    resetModal() {
      this.dialogFormVisible = false;
      this.form.name = '';
      this.form.status = '';
      this.id = '';
    },
    updateOrCreateDepartment() {
      if (this.is_edit) {
        this.$store.dispatch(UPDATE_DEPARTMENT, {id: this.id, name: this.form.name, status: (this.form.status == true ? 1 : 0)}).then(() => {
          this.$notify({
            title: 'Thành công',
            message: 'Sửa dữ liệu thành công',
            type: 'success'
          });
          this.fetchData();
          this.dialogFormVisible = false;
        }).catch((e) => {
          if (e.data.data.message_validate_form) {
            this.$refs.form.setErrors(e.data.data.message_validate_form);
          }else {
            this.$notify({
              title: 'Thất bại',
              message: e.data.message,
              type: 'success'
            });
          }
        })

        return;
      }

      this.$store.dispatch(STORE_DEPARTMENT, {name: this.form.name}).then(res => {
        this.$notify({
          title: 'Thành công',
          message: 'Sửa dữ liệu thành công',
          type: 'success'
        });
        this.fetchData();
        this.dialogFormVisible = false;
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }else {
          this.$notify({
            title: 'Thất bại',
            message: e.data.message,
            type: 'success'
          });
        }
      })
    },
    createDepartment() {
      this.is_edit = false;
      this.dialogFormVisible = true;
    },
    editDepartment(id) {
      this.is_edit = true;
      this.id = id;
      this.$store.dispatch(SHOW_DEPARTMENT, id).then(res => {
        this.form.name = res.data.name;
        this.form.status = res.data.status == 1 ? true : false;
        this.dialogFormVisible = true;
      })
    },
    deleteDepartment(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa thông tin?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_DEPARTMENT, id).then(() => {
            this.fetchData();
            this.$notify({
              title: 'Thành công',
              message: 'Xóa dữ liệu thành công',
              type: 'success'
            });
          })
        }
      });
    },
    searchData() {
      this.page = 1;
      this.fetchData();
    },
    fetchData() {
      this.is_disable_search = true;
      let payload = {
        name: this.query.name, page: this.page
      }
      if (this.query.status !== '') {
        payload.status = this.query.status;
      }
      this.$store.dispatch(GET_DEPARTMENT, payload)
        .then(res => {
          this.data = res.data;
        }).finally(() => {
        this.is_disable_search = false;
      })
    },
    clickCallback(obj) {
      this.page = obj;
      this.fetchData();
    },
  }
}
</script>

<style>
.checkbox-slide, .checkbox-switch {
  border-radius: 20px !important;
}
</style>