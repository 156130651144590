import ApiService from "@/core/services/api.service";
export const CREATE_COEFFICIENT = "create_coefficient";
export const UPDATE_COEFFICIENT = "update_coefficient";
export const GET_COEFFICIENTS = "get_coefficients";
export const DELETE_COEFFICIENT = "delete_coefficient";
export const GET_COEFFICIENT_BY_ID = "get_coefficient_by_id";
export const GET_DISTINCT_COEFFICIENTS = "coefficients/get-coefficients-distinct";
export const CHANGE_STATUS_BUSINESS_COEFFICIENT = "coefficients/change-status";

const state = {};
const getters = {};
const actions = {
    // Get provinces
    [GET_COEFFICIENTS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('coefficients', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [GET_DISTINCT_COEFFICIENTS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_DISTINCT_COEFFICIENTS}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_COEFFICIENT_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('coefficients', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_COEFFICIENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('coefficients', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_COEFFICIENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('coefficients', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_COEFFICIENT](context, id) {
        return new Promise(resolve => {
            ApiService.delete('coefficients', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    /**
     * Thay đổi trạng thái
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [CHANGE_STATUS_BUSINESS_COEFFICIENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update(`${CHANGE_STATUS_BUSINESS_COEFFICIENT}`, payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },


};
const mutations = {};
export default {
    state,
    actions,
    mutations,
    getters
};
