<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">Thêm mới giải đấu</div>
    </div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="form" @submit.prevent autocomplete="off">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class=" ">
                <div>
                  <div>
                    <div class="pb-6 row">
                      <div class="col-md-6 col-sm-12 mb-3">
                        <label
                        >Tên giải đấu
                          <span class="text-danger" style="font-size: 20px"
                          >*</span
                          ></label
                        >
                        <ValidationProvider
                            name="Tên giải đấu"
                            rules="required"
                            v-slot="{ errors, classes }"
                            vid=""
                        >
                          <el-input
                              placeholder="Nhập tên giải đấu"
                              v-model="dataAddTournament.nameTournament"
                          />
                          <div class="fv-plugins-message-container">
                            <div
                                data-field="name"
                                data-validator="notEmpty"
                                class="fv-help-block"
                            >
                              {{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6 col-sm-12 mb-3">
                        <label
                        >Trung tâm áp dụng
                          <span class="text-danger" style="font-size: 20px"
                          >*</span
                          ></label
                        >
                        <ValidationProvider
                            name="Trung tâm áp dụng"
                            rules="required"
                            v-slot="{ errors, classes }"
                            vid=""
                        >
                          <el-select
                              class="d-block"
                              placeholder="Nhập trung tâm áp dụng"
                              size="large"
                              v-model="dataAddTournament.centerApply"
                              filterable
                              @change="handleSelectCenter()"
                              multiple
                          >
                            <el-option
                                v-for="item in listCenter"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                          </el-select>
                          <div class="fv-plugins-message-container">
                            <div
                                data-field="name"
                                data-validator="notEmpty"
                                class="fv-help-block"
                            >
                              {{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6 col-sm-12 mb-3">
                        <label>Khu vực áp dụng </label>
                        <el-select
                            class="d-block"
                            placeholder="Nhập khu vực áp dụng"
                            size="large"
                            v-model="dataAddTournament.provincesApply"
                            filterable
                            multiple
                            @change="handleSelectProvinces"
                        >
                          <el-option
                              v-for="item in provinces"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                          />
                        </el-select>
                      </div>
                      <div class="col-md-6 col-sm-12 mb-3">
                        <label>Khóa học áp dụng </label>
                        <el-select
                            class="d-block"
                            placeholder="Nhập khóa học áp dụng"
                            size="large"
                            v-model="dataAddTournament.courseApply"
                            filterable
                            :disabled="isDisableCourse"
                            multiple
                            filterable
                            @change="handleSelectCourse()"
                        >
                          <el-option
                              v-for="item in listCourse"
                              :key="item.id"
                              :label="`${item.name} ${item.center_name?`( ${item.center_name} )`:''}`"
                              :value="item.id"
                          />
                        </el-select>
                      </div>
                      <div class="col-md-6 col-sm-12 mb-3">
                        <label
                        >Số lượng cặp đấu trong một trận
                          <span class="text-danger" style="font-size: 20px"
                          >*</span
                          ></label
                        >
                        <ValidationProvider
                            name="Số lượng cặp đấu trong trận"
                            rules="required"
                            v-slot="{ errors, classes }"
                            vid=""
                        >
                          <el-input-number
                              :min="1"
                              :max="10"
                              class="d-block w-100"
                              placeholder="Nhập số lượng cặp đấu"
                              v-model="dataAddTournament.totalCompetition"
                              controls-position="right"
                          />
                          <div class="fv-plugins-message-container">
                            <div
                                data-field="name"
                                data-validator="notEmpty"
                                class="fv-help-block"
                            >
                              {{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6 col-sm-12 mb-3">
                        <label class="d-block"
                        >Thời gian bắt đầu
                          <span class="text-danger" style="font-size: 20px"
                          >*</span
                          ></label
                        >
                        <ValidationProvider
                            name="Thời gian bắt đầu"
                            rules="required"
                            v-slot="{ errors, classes }"
                            vid="start_schedule"
                        >
                          <date-picker
                              type="datetime"
                              placeholder="Thời gian bắt đầu"
                              v-model="dataAddTournament.timeStart"
                              format="DD-MM-YYYY HH:mm"
                              :disabled-date="disabledBeforeStartDate"
                              @change="handleSelectTimeStart()"
                              :disabled-time="disableHourStart"
                              :default-value="new Date()"
                          >
                          </date-picker>
                          <div class="fv-plugins-message-container">
                            <div
                                data-field="name"
                                data-validator="notEmpty"
                                class="fv-help-block"
                            >
                              {{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6 col-sm-12 mb-3">
                        <label
                        >Thời gian kết thúc<span
                            class="text-danger"
                            style="font-size: 20px"
                        >*</span
                        ></label
                        >
                        <ValidationProvider
                            name="Thời gian kết thúc"
                            rules="required"
                            v-slot="{ errors, classes }"
                            vid="end_schedule"
                        >
                          <date-picker
                              type="datetime"
                              placeholder="Thời gian kết thúc"
                              v-model="dataAddTournament.timeEnd"
                              format="DD-MM-YYYY HH:mm"
                              :disabled-date="disabledBeforeEndDate"
                              :disabled="isDisableTimeEnd"
                              :disabled-time="disableHourEnd"
                              :default-value="dataAddTournament.timeStart"
                          >
                          </date-picker>
                          <div class="fv-plugins-message-container">
                            <div
                                data-field="name"
                                data-validator="notEmpty"
                                class="fv-help-block"
                            >
                              {{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <label>Nội dung truyền thông<span
                            class="text-danger"
                            style="font-size: 20px"
                        >*</span></label>
                        <ValidationProvider
                            name="Nội dung truyền thông"
                            rules="required"
                            v-slot="{ errors, classes }"
                            vid="content_media"
                        >
                          <ckeditor  :editor="editor" v-model="dataAddTournament.content_media"></ckeditor>
                          <div class="fv-plugins-message-container">
                            <div
                                data-field="name"
                                data-validator="notEmpty"
                                class="fv-help-block"
                            >
                              {{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-sm-12" style="width: fit-content">
                      <h1 style="font-size: 13px">
                        Ảnh banner giải đấu<span
                          class="text-danger"
                          style="font-size: 20px"
                      >*</span
                      >
                      </h1>
                      <input
                          class="file-input__input"
                          id="file-input"
                          type="file"
                          name="myImage"
                          accept="image/png, image/gif, image/jpeg"
                          @change="addPhotoBanner"
                      />
                      <div v-if="dataPhotoBanner == null">
                        <label class="file-input__label" for="file-input">
                          <span
                          ><i
                              class="flaticon-upload mr-2"
                              style="color: white"
                          ></i
                          >Tải lên</span
                          ></label
                        >
                        <p class="text-dark">
                          Kích thước ảnh tiêu chuẩn là 300 x 140 pixels.
                        </p>
                      </div>
                      <div class="d-flex" v-else>
                        <div
                            class=""
                            @mouseover="upHereBanner = true"
                            @mouseleave="upHereBanner = false"
                            style="
                            width: 100px;
                            height: 100px;
                            background-size: 100px 100px;
                          "
                            :style="{
                            'background-image': 'url(' + dataPhotoBanner + ')',
                          }"
                        >
                          <div
                              v-if="upHereBanner"
                              class="w-100 h-100 d-flex justify-content-center align-items-center"
                              style="background-color: rgba(0, 0, 0, 0.5)"
                          >
                            <i
                                class="flaticon2-magnifier-tool mr-3"
                                style="cursor: pointer"
                                @click="openPhotoBanner = true"
                            ></i>
                            <i
                                class="flaticon-delete"
                                @click="removePhotoBanner()"
                                style="cursor: pointer"
                            ></i>
                          </div>
                          <el-dialog
                              :visible.sync="openPhotoBanner"
                              width="30%"
                              center
                          >
                            <img
                                :src="dataPhotoBanner"
                                style="width: 100%; height: auto"
                            />
                            <span slot="footer" class="dialog-footer">
                              <el-button @click="openPhotoBanner = false"
                              >Đóng</el-button
                              ></span
                            >
                          </el-dialog>
                        </div>
                      </div>
                      <div
                          class="fv-plugins-message-container"
                          v-if="dataPhotoBanner == null && errorBanner"
                      >
                        <div>
                          <p class="text-danger">Chưa có file nào được chọn</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <h1 style="font-size: 13px">
                        Ảnh thông tin giải đấu<span
                          class="text-danger"
                          style="font-size: 20px"
                      >*</span
                      >
                      </h1>
                      <input
                          class="file-input__input"
                          id="file-input-1"
                          type="file"
                          @change="addPhotoNews"
                          accept="image/png, image/gif, image/jpeg"
                      />
                      <div v-if="dataPhotoNews == null">
                        <label class="file-input__label" for="file-input-1">
                          <span
                          ><i
                              class="flaticon-upload mr-2"
                              style="color: white"
                          ></i
                          >Tải lên</span
                          ></label
                        >
                        <p class="text-dark">
                          Kích thước ảnh tiêu chuẩn là 340 x n pixels. Với n là chiều cao tùy theo mong muốn.
                        </p>
                      </div>
                      <div class="d-flex" v-else>
                        <div
                            class=""
                            @mouseover="upHereNews = true"
                            @mouseleave="upHereNews = false"
                            style="
                            width: 100px;
                            height: 100px;
                            background-size: 100px 100px;
                          "
                            :style="{
                            'background-image': 'url(' + dataPhotoNews + ')',
                          }"
                        >
                          <div
                              v-if="upHereNews"
                              class="w-100 h-100 d-flex justify-content-center align-items-center"
                              style="background-color: rgba(0, 0, 0, 0.5)"
                          >
                            <i
                                class="flaticon2-magnifier-tool mr-3"
                                style="cursor: pointer"
                                @click="openPhotoNews = true"
                            ></i>
                            <i
                                class="flaticon-delete"
                                @click="removePhotoNews()"
                                style="cursor: pointer"
                            ></i>
                          </div>
                          <el-dialog
                              :visible.sync="openPhotoNews"
                              width="30%"
                              center
                          >
                            <img
                                :src="dataPhotoNews"
                                style="width: 100%; height: auto"
                            />
                            <span slot="footer" class="dialog-footer">
                              <el-button @click="openPhotoNews = false"
                              >Đóng</el-button
                              ></span
                            >
                          </el-dialog>
                        </div>
                      </div>
                      <div
                          class="fv-plugins-message-container"
                          v-if="dataPhotoNews == null && errorNews"
                      >
                        <div>
                          <p class="text-danger">Chưa có file nào được chọn</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="container">
          <div class="">
            <hr/>
            <button
                type="button"
                class="btn btn-primary mb-5 button_confirm"
                style="background-color: #1bc5bd; border: #1bc5bd"
                @click="handleSubmit(handleAdd)"
            >
              <div
                  v-if="loading"
                  class="spinner-border text-light"
                  role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <div v-else>Xác nhận</div>
            </button>
          </div>
        </footer>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {GET_LIST_CENTERS} from "@/core/services/store/center/centers.module";
import {GET_PROVINCES} from "@/core/services/store/public/provinces.module";
import {GET_COURSES_LIST} from "@/core/services/store/course/course.module";
import moment from "moment-timezone";
import {POST_TOURNAMENT} from "@/core/services/store/tournament/tournament.module";
import router from "@/router";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {ECOACH_ID, IELTS_MENTOR_ID} from "@/core/option/typeCenterBill";
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";

export default {
  name: "AddNewTournament",
  components: {DatePicker},
  data() {
    return {
      dataPhotoBanner: null,
      dataPhotoNews: null,
      openPhotoBanner: false,
      upHereBanner: false,
      openPhotoNews: false,
      upHereNews: false,
      dataAddTournament: {
        nameTournament: null,
        centerApply: [0],
        provincesApply: [0],
        courseApply: [0],
        timeStart: null,
        timeEnd: null,
        imageBanner: null,
        imageNews: null,
        totalCompetition: 5,
        content_media: null
      },
      listCenter: [],
      provinces: [],
      listCourse: [],
      errorBanner: false,
      errorNews: false,
      loading: false,
      editor: CustomEditor
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách giải đấu", route: "tournamentList"},
      {title: "Thêm mới giải đấu"},
    ]);
    this.getCenters().then(()=>{
      this.getCourse();
    });

  },
  methods: {
    getCenters(flag = true) {
       let ids = this.dataAddTournament.centerApply[0] == 0 ? [ECOACH_ID, IELTS_MENTOR_ID] : this.dataAddTournament.centerApply
      return this.$store
          .dispatch(GET_LIST_CENTERS, {
            status: 1,
            ids: ids,
          })
          .then((data) => {
            if (!data.error) {
              let ids = data.data.flatMap((center) => center.branches.map((branch) => branch.province.id))
              this.getProvinces(ids)
              if (flag) {
                this.listCenter = data.data;
                this.listCenter.unshift({name: "Tất cả", id: 0});
              }
            }
          });
    },
    getProvinces(ids) {
      this.$store.dispatch(GET_PROVINCES, {ids: ids}).then((res) => {
        if (!res.error) {
          this.provinces = res.data;
          this.provinces.unshift({name: "Tất cả", id: 0});
        }
      });
    },
    getCourse() {
      this.$store.dispatch(GET_COURSES_LIST, {
        center_ids: this.dataAddTournament.centerApply[0] == 0 ? [ECOACH_ID, IELTS_MENTOR_ID] : this.dataAddTournament.centerApply,
        status: 1
      })
          .then((res) => {
            this.listCourse = res.data.map((course) => {
              return {...course, center_name: this.listCenter.find((center) => center.id == course.center_id)?.name};
            });
            this.listCourse.unshift({name: "Tất cả", id: 0});
          });
    },
    addTournament() {
      this.loading = true;
      let formData = new FormData();
      let timeStart =
          moment(this.dataAddTournament.timeStart).format("YYYY-MM-DD HH:mm") ??
          null;
      let timeEnd =
          moment(this.dataAddTournament.timeEnd).format("YYYY-MM-DD HH:mm") ??
          null;
      formData.append("name", this.dataAddTournament.nameTournament);
      if (this.dataAddTournament.centerApply[0] !== 0) {
        this.dataAddTournament.centerApply.forEach((center) => {
          formData.append("center_ids[]", center);
        })
      }
      if (this.dataAddTournament.provincesApply[0] !== 0) {
        this.dataAddTournament.provincesApply.forEach((provinces) => {
          formData.append(
              "province_ids[]",
              provinces
          );
        })
      }
      if (this.dataAddTournament.courseApply[0] !== 0) {
        this.dataAddTournament.courseApply.forEach((course) => {
          formData.append("course_ids[]", course);
        })

      }
      formData.append("start_date", timeStart);
      formData.append("end_date", timeEnd);
      formData.append("img_banner", this.dataAddTournament.imageBanner);
      formData.append("img_info", this.dataAddTournament.imageNews);
      formData.append("content_media", this.dataAddTournament.content_media);
      formData.append(
          "total_competition",
          this.dataAddTournament.totalCompetition
      );
      this.$store
          .dispatch(POST_TOURNAMENT, formData)
          .then(() => {
            this.$notify({
              title: "Thành công",
              message: "Thêm giải đấu thành công",
              type: "success",
            });
            router.push({path: "/tournament"});
          })
          .catch(() => {
            this.$notify.error({
              title: "Lỗi",
              message: "Đã có lỗi thêm giải đấu",
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    handleSelectCenter() {
      if (this.dataAddTournament.centerApply.length > 1) {
        let index = this.dataAddTournament.centerApply.findIndex(
            (center) => center == 0
        );
        if (
            this.dataAddTournament.centerApply[
            this.dataAddTournament.centerApply.length - 1
                ] == 0
        ) {
          this.dataAddTournament.centerApply = [0];
          this.getCourse();
          this.getCenters()
          return;
        }
        if (index != -1) {
          this.dataAddTournament.centerApply.splice(index, 1);
        }
      }
      if (this.dataAddTournament.centerApply.length == 0) {
        this.dataAddTournament.centerApply = [0];
      }
      this.getCourse();
      this.getCenters(false)
      this.dataAddTournament.courseApply = [0];
      this.dataAddTournament.provincesApply = [0]
    },
    handleSelectProvinces() {
      if (this.dataAddTournament.provincesApply.length > 1) {
        let index = this.dataAddTournament.provincesApply.findIndex(
            (center) => center == 0
        );
        if (
            this.dataAddTournament.provincesApply[
            this.dataAddTournament.provincesApply.length - 1
                ] == 0
        ) {
          this.dataAddTournament.provincesApply = [0];
          return;
        }
        if (index != -1) {
          this.dataAddTournament.provincesApply.splice(index, 1);
        }
      }
      if (this.dataAddTournament.provincesApply.length == 0) {
        this.dataAddTournament.provincesApply = [0];
      }
    },
    handleSelectCourse() {
      if (this.dataAddTournament.courseApply.length > 1) {
        let index = this.dataAddTournament.courseApply.findIndex(
            (center) => center == 0
        );
        if (
            this.dataAddTournament.courseApply[
            this.dataAddTournament.courseApply.length - 1
                ] == 0
        ) {
          this.dataAddTournament.courseApply = [0];
          return;
        }
        if (index != -1) {
          this.dataAddTournament.courseApply.splice(index, 1);
        }
      }
      if (this.dataAddTournament.courseApply.length == 0) {
        this.dataAddTournament.courseApply = [0];
      }
    },
    handleSelectTimeStart() {
      this.dataAddTournament.timeEnd = null;
    },
    disabledBeforeStartDate(date) {
      const today = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      return moment(date) < today;
    },
    disabledBeforeEndDate(date) {
      return (
          moment(date).endOf("days") <= moment(this.dataAddTournament.timeStart)
      );
    },
    disableHourStart(hour) {
      let today = new Date();
      return today >= hour;
    },
    disableHourEnd(hour) {
      return this.dataAddTournament.timeStart >= hour;
    },
    addPhotoBanner(e) {
      if (e.target.files.length) {
        this.dataPhotoBanner = URL.createObjectURL(e.target.files[0]);
        this.dataAddTournament.imageBanner = e.target.files[0];
      }
    },
    addPhotoNews(e) {
      if (e.target.files.length) {
        this.dataPhotoNews = URL.createObjectURL(e.target.files[0]);
        this.dataAddTournament.imageNews = e.target.files[0];
      }
    },
    removePhotoBanner() {
      this.$confirm("Bạn có chắc chắn muốn xóa ảnh này không?", "Xóa ảnh", {
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        type: "Delete",
      })
          .then(() => {
            this.dataPhotoBanner = null;
            this.dataAddTournament.imageBanner = null;
            this.$notify({
              title: "Thành công",
              message: "Xóa ảnh thành công",
              type: "success",
            });
          })
          .catch(() => {
          });
    },
    removePhotoNews() {
      this.$confirm("Bạn có chắc chắn muốn xóa ảnh này không?", "Xóa ảnh", {
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        type: "Delete",
      })
          .then(() => {
            this.dataPhotoNews = null;
            this.dataAddTournament.imageNews = null;
            this.$notify({
              title: "Thành công",
              message: "Xóa ảnh thành công",
              type: "success",
            });
          })
          .catch(() => {
          });
    },
    handleAdd() {
      if (this.dataPhotoNews == null || this.dataPhotoBanner == null) {
        this.errorNews = true;
        this.errorBanner = true;
        return;
      }
      this.addTournament();
    },
  },
  computed: {
    isDisableCourse() {
      return (
          this.dataAddTournament.centerApply == null ||
          this.dataAddTournament.centerApply.length == 0
      );
    },
    isDisableTimeEnd() {
      return this.dataAddTournament.timeStart == null;
    },
  },
};
</script>

<style scoped>
.file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  height: 34px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 7px 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  background-color: #667ca4;
}

@media only screen and (max-width: 768px) {
  .button_confirm {
    margin-left: 15px;
  }
}
</style>