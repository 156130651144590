<template>
  <div class="question row">
    <div class="col-6">
      <homework-container :question="question"></homework-container>
      <div class="row">
        <div class="col-md-12" v-for="(item, index) in question.questions" :key="index">
          <div class="">
            <div class="answer-title mb-1">
              <div v-html="formatBrTagText(item.title)"></div>
            </div>
            <div class="" v-if="typeView === 'view-result'">
              <div v-if="item.student_answer  " class="bold mb-2">Bài làm của học viên:</div>
              <video v-if="item.student_answer  " ref="video" controls preload="auto" class="rounded" width="320" height="240" :poster="item.student_answer">
                <source :src="item.student_answer" type="video/mp4">
              </video>
            </div>
            <div class="d-flex align-items-center" v-else>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6" v-if="point">
      <!--      Bảng điểm các tiêu chí-->
      <TranscriptRecord :point="point"></TranscriptRecord>
    </div>

  </div>
</template>

<script>
import homeworkContainer from "./homework-container.vue";
import TranscriptRecord from "@/view/pages/classes/components/TranscriptRecord.vue";
import {NameSkill, NameSkillOld} from "@/core/option/selectScore";
export default {
  name: "record-video",
  components: {TranscriptRecord, homeworkContainer},
  props: {
    question: {
      type: Object,
      default: () => { return {} }
    },
    typeView: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      point:{}
    }
  },
  mounted() {
    let outComes = this.question?.outComes;
    let skillPoints = outComes?.comments?.map((comment)=>{
      let key = Object.keys(comment)?.join('')
      let quantityComments = outComes?.comments?.length
      return {
        nameSkill: quantityComments === 4 ?NameSkill?.get(key):NameSkillOld.get(key),
        pointSkill:outComes?.score_detail[key],
        commentSkill:comment[key]
      }
    })
    this.point = {
      totalPoint : outComes?.score,
      skillPoints:skillPoints
    }
    },
  methods: {
    formatBrTagText(value) {
      return value ? value.replace(/(\r\n|\n\r|\r|\n)/g, '<br>') : '';
    },
  }
}
</script>

<style scoped>
.comment-essay {
  width: 100%;
  height: auto;
  background: #FBF5FF;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 14px;
}
</style>