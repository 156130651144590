<template>
  <div class="row user-inf">
    <div class="col-md-6">
      <div class="form-group">
        <label>Họ và tên</label>
        <ValidationProvider name="Họ và tên" rules="required" v-slot="{ errors,classes }" vid="user.name">
          <input type="text" name="name" class="form-control" placeholder="Họ và tên học viên"
                 :class="classes"
                 :value="customer ? customer?.name : user?.name"
                 disabled
          >
          <div class="fv-plugins-message-container">
            <div data-field="user.name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
          </div>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <label>Số điện thoại</label>
        <ValidationProvider vid="customer.phone" name="Số điện thoại"
                            :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                            v-slot="{ errors,classes }">
          <input type="number" :value="customer ? customer?.phone : user?.phone" :class="classes" class="form-control" disabled>
          <div class="fv-plugins-message-container">
            <div data-field="customer.phone" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
            </div>
          </div>
        </ValidationProvider>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Email<span v-if="!is_edutalk" class="text-danger"> *</span></label>
        <ValidationProvider name="Email" vid="user.email" rules="required|email" v-slot="{ errors,classes }">
          <input type="email" name="email"
                 class="form-control"
                 placeholder="Email"
                 :class="classes"
                 :disabled="!is_disable_mail"
                 :value="customer ? customer?.email : user?.email">
          <div class="fv-plugins-message-container">
            <div data-field="user.email" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
            </div>
          </div>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <label>Ngày tháng năm sinh
          <span class="text-danger"></span></label>
        <el-date-picker id="birthDay"
                        format="dd-MM-yyyy"
                        ref="birthday"
                        name="birthday"
                        value-format="dd-MM-yyyy" type="date" placeholder="Ngày sinh"
                        :value="customer ? customer?.ngay_sinh : user?.profile?.birthday"
                        :picker-options="pickerOptions"
                        default-value="2000-10-10"
                        disabled
                        style="width: 100%;"
        ></el-date-picker>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Trung tâm
          <span class="text-danger"></span></label>
        <input type="text" disabled="disabled" v-model="center.name" placeholder="Tên trung tâm" class="form-control">
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Chi nhánh
          <span class="text-danger"></span></label>
          <el-select
              @change="changeEditBranch"
              class="w-100"
              v-model="branch_update"
              :disabled="editBranch"
              placeholder="Tên chi nhánh">
            <el-option
                 v-for="item in branch_list"
                 :key="item.id"
                 :label="item.name"
                 :value="item.id">
            </el-option>
          </el-select>
      </div>
    </div>
    <div class="col-md-6" v-if="!is_disable_phone">
      <div class="form-group">
        <label>Trạng thái
          <span class="text-danger"></span></label>
        <el-select
            @change="changeEditStatus"
            class="w-100"
            v-model="status"
            :disabled="total_paid > 0"
            placeholder="Tên chi nhánh">
          <el-option
              v-for="item in listStatus"
              :key="item.id"
              :label="item.value"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_LIST_BRANCH
} from "@/core/services/store/contract/contracts.module";
import alert from "@/view/pages/vue-bootstrap/Alert";

export default {
  name: "UserDetail",
  props: {
    user: {
      type: Object,
      default: () => { return {} }
    },
    center: {
      type: Object,
      default: () => { return {} }
    },
    branch: {
      type: Object,
      default: () => { return {} }
    },
    is_edit_branch: {
      default: false
    },
    is_disable_phone: {
      default: false
    },
    is_disable_mail: {
      default: false
    },
    total_paid: {
      default: () => { return 0 }
    },
    confirm: {
      default: () => { return 0 }
    },
    detail: {},
    editBranch: { default: true },
    editName: { default: false },
    editEmail: { default: true },
    editPhone: { default: true },
    editBirthDate: { default: false },
    customer: {
      type: Object,
      default: () => { return {} }
    },
    is_edutalk: { default: false },
  },
  data() {
    return {
      branch_update: null,
      branch_list: [],
      /*date picker*/
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      status: this.confirm,
      listStatus: [
        {
          id: 0,
          value: 'Chờ xác thực',
        },
        {
          id: 1,
          value: 'Đã xác thực',
        }
      ],
      checkTotalPaid: false,
      tuitionPayment: ''
    }
  },
  mounted() {
    this.retrieveBranch();
  },
  methods: {
    changeEditBranch() {
      this.$emit('changeEditBranch', this.branch_update);
    },
    changeEditStatus() {
      this.$emit('changeEditStatus', this.status);
    },
    retrieveBranch() {
      let pay_load = { branch_id: this.branch ? this.branch.id : 0, center_id: this.center.id || 0, type: !this.editBranch ? [2, 3] : []  };
      this.$store.dispatch(GET_LIST_BRANCH, pay_load).then((res) => {
        if (!res.error) {
          this.branch_update = pay_load.branch_id;
          this.branch_list = res.data;
        }
      }).catch((response) => {
        this.$bvToast.toast(response.data.message, {
          title: 'Có lỗi',
          variant: 'danger',
          solid: true
        });
      })
    },
  }
}
</script>

<style scoped>

</style>
