<template>
    <div class="card card-custom gutter-b">
      <div class="m-4"> 
      <div id="panel-1" class="panel mt-2">
        <div id="list-propse-application" class="panel-container show">
            <div class="panel-content">
                <div class="d-flex header">
                   <div class="d-flex justify-content-end w-100 m-0">
                        <div>
                            <button @click="clickExprotExcel" style="color: black;" class="btn btn-warning fw-bolder"><i style="color: black;" class="el-icon-s-marketing pr-1"></i>Xuất Excel</button>
                        </div>
                   </div>
                </div>
                        <div id="search" class="row d-flex ml-1">
                                <div class="col-xl-3 col-lg-4 mt-2">
                                    <label for=""><b>Tên/Mã nhân sự/Lý do</b></label>
                                    <el-input placeholder="Tìm theo tên, sđt nhân sự" v-model="query.keyword" clearable></el-input>
                                </div>
                                <div class="col-xl-3 col-lg-4 mt-2">
                                    <label for=""><b>Thời gian</b></label><br>
                                    <el-date-picker
                                        class="w-100"
                                        v-model="query.month"
                                        value-format="dd-MM-yyyy"
                                        type="month"
                                        placeholder="Chọn tháng">
                                    </el-date-picker>
                                </div>
                                <div class="col-xl-3 col-lg-4 mt-2">
                                    <label for=""><b>Phòng ban</b></label><br>
                                    <el-select  class="w-100" clearable v-model="query.department" placeholder="Chọn phòng ban">
                                        <el-option
                                        v-for="item in allDepartment"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="col-xl-3 col-lg-4 mt-2 w-100" >
                                    <label for=""><b>Danh mục</b></label><br>
                                    <el-select class="w-100" v-model="query.category" placeholder="Danh mục" collapse-tags clearable>
                                        <el-option label="Nghỉ phép" :value="0"> </el-option>
                                        <el-option label="Tăng ca" :value="1"> </el-option>
                                        <el-option label="Xin đổi ca làm việc" :value="2"> </el-option>
                                        <el-option label="Bổ sung giờ làm ngoài văn phòng" :value="3"> </el-option>
                                    </el-select>
                                </div>
                                <div class="col-xl-3 col-lg-4 mt-2">
                                    <label for=""><b>Trạng thái</b></label><br>
                                    <div class="form-group w-100">
                                        <el-select class="w-100" v-model="query.status" placeholder="Chọn trạng thái" collapse-tags clearable>
                                            <el-option label="Đang chờ duyệt" :value="0"> </el-option>
                                            <el-option label="Đã duyệt" :value="1"> </el-option>
                                            <el-option label="Đã từ chối" :value="2"> </el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="btn-search mt-2">
                                    <button @click="getListUserRequest" class="btn btn-primary ml-4 mt-7"><i class="el-icon-search"></i>Tìm kiếm</button>
                                </div>
                        </div>
               <div class="example-preview table-responsive style-color">
          <table class="table table-vertical-center table-bordered ml-4 style-color" v-loading="loadingTable" element-loading-text="Loading...">
            <thead>
              <tr style="background-color: #F8F8F8;">
                <th>STT</th>
                <th>Người đề xuất</th>
                <th>Danh mục</th>
                <th>Lý do</th>
                <th>Phòng ban</th>
                <th>Trạng thái</th>
                <th>Hành động</th>
              </tr>
            </thead>
            <tbody v-if="dataUserRequest?.length > 0">
              <tr v-for="(item, index) in dataUserRequest">
                <td>{{ index + 1 }}</td>
                <td>{{ (item?.user?.id + 100000) + ' - ' + item?.user?.name }}</td>
                <td>
                    <div v-if="item.category == 0">
                        <div>Nghỉ phép</div> 
                    </div>
                    <div v-if="item.category == 1">
                        <div>Tăng ca</div> 
                    </div>
                    <div v-if="item.category == 2">
                        <div>Xin đổi ca làm việc</div> 
                    </div>
                    <div v-if="item.category == 3">
                        <div>Bổ sung giờ làm việc ngoài văn phòng</div> 
                    </div>
                </td>
                <td>{{ checkLength(item.reason, 30) }}</td>
                <td>{{ item.parent_department }}</td>
                <td>
                    <div v-if="item.status == 0">
                        <div class="circle_status d-inline-flex mr-2" style="background-color: #C0C0C0"></div>
                        <span>Đang chờ duyệt</span>
                    </div>
                    <div v-if="item.status == 1">
                        <div class="circle_status d-inline-flex mr-2" style="background-color: green;"></div>
                            <span>Đã duyệt</span>
                    </div>
                    <div v-if="item.status == 2">
                        <div class="circle_status d-inline-flex mr-2" style="background-color: red"></div>
                        <span>Đã từ chối</span>
                    </div>
                </td>
                <td>
                    <div class="d-flex">
                        <div class="d-flex justify-content-center w-100">
                            <button title="Xem chi tiết" @click="showDetail(item)" class=""><i style="font-size: 20px;" class="el-icon-view style-color"></i></button>
                        </div>
                    </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9" class="event-title no-data">Chưa có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{ totoalData }}</b></div>
                    <div class="edu-paginate mx-auto d-flex justify-content-center">
                            <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
                                :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
                                :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
                                :next-class="'page-link'" :page-class="'page-item'">
                            </paginate>
                    </div>
            </div>

                <!-- dialog chấp nhận yêu cầu nghỉ phép -->
                <el-dialog id="dialog-request-applications" :title="`Duyệt đơn xin: ${ titleDetail }`" :visible.sync="dialogConfirmRequest">
                <div style="word-break: break-word;" class="border-top border-dark pt-4">
                    <div  v-if="infoDetail?.category == 0" >
                        <p>Teamlead-BOD xét duyệt đơn xin nghỉ phép của nhân sự vui lòng đảm bảo công việc của nhân sự nghỉ phép hoặc sắp xếp nhân sự đảm nhiệm công việc đúng tiến độ!</p>
                        <p>Bạn có chắc chắn muốn duyệt đơn xin nghỉ phép ngày {{ infoDetail.start_date | formatDate}} không?</p>
                    </div>
                    <div v-else>
                        <span>
                           <b> Bạn có chắc chắn muốn duyệt đơn xin {{ titleDetail }} này không?</b>
                        </span>
                    </div>
                </div>
                <div class="box-bottom-decide  d-flex justify-content-end mt-4">
                    <div><button @click="dialogConfirmRequest = false" class="rounded btn btn-info mr-2">Huỷ bỏ</button></div>
                    <div><button @click="handleRequest(infoDetail?.id, true)" class=" rounded btn btn-success">Xác nhận</button></div>
                </div>
            </el-dialog>

            <!-- dialog từ chối yêu cầu nghỉ phép -->
            <el-dialog width="40" id="dialog-request-applications" title="Nhập lý do từ chối" :visible.sync="dialogRefusetRequest">
                <div>
                    <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="Vui lòng nhập lý do từ chối"
                    v-model="query.reason_for_refusal">
                    </el-input>
                </div>
                <div class="box-bottom-decide d-flex justify-content-end mt-4">
                    <div><button @click="dialogRefusetRequest = false" class="btn btn-info rounded mr-3">Huỷ bỏ</button></div>
                    <div><button :disabled="!query.reason_for_refusal" @click="handleRequest(infoDetail?.id, false)" class="btn btn-success rounded mr-3">Xác nhận</button></div>
                </div>
            </el-dialog>

             <!-- dialog Show chi tiết yêu cầu -->
             <el-dialog id="dialogShowDetail"  :title="`Xem chi tiết đơn đề xuất: ${ titleDetail }`" :visible.sync="dialogShowDetail">
                <div class="container style-color">
                    <div class="info-user pt-3 border-top">
                            <div class="row">
                                <div class="col-xl-6 mt-2">
                                    <label class="w-100" for=""><b>Mã nhân sự</b></label>
                                    <el-input class="info-request w-100" disabled :value="infoDetail?.user?.id + 100000"></el-input>
                                </div>
                                <div class="col-xl-6 mt-2">
                                    <label class="w-100" for=""><b>Họ và tên</b></label>
                                    <el-input class="info-request w-100" disabled :value="infoDetail?.user?.name"></el-input>
                                </div>
                                <div class="col-xl-6 mt-2">
                                    <label class="w-100" for=""><b>Vị trí</b></label>
                                    <el-input class="info-request w-100" disabled :value="infoDetail?.user?.profile?.account_type?.name"></el-input>
                                </div>
                                <div class="col-xl-6 mt-2">
                                    <label class="w-100" for=""><b>Phòng ban</b></label>
                                    <el-input class="info-request w-100" disabled :value="infoUser?.department_name"></el-input>
                                </div>
                            </div>
                    </div>
                    <!-- nếu là danh mục nghỉ phép -->
                    <div v-if="infoDetail?.category == 0" class="mt-3">
                        <div class="row d-flex line-cao">
                            <div class="col-xl-3 col-md-6">
                                <label for=""><b>Tổng số công nghỉ phép</b></label>
                                <el-input class="w-100" disabled :value="infoDetail?.general_leave + ' Công'"></el-input>
                            </div>
                            <div class="col-xl-3 col-md-6">
                                <label for=""><b>Ngày bắt đầu nghỉ phép</b></label>
                                <el-input class="w-100" disabled :value="infoDetail?.start_date | formatDate"></el-input>
                            </div>
                            <div class="col-xl-3 col-md-6" v-if="infoDetail?.start_shift_off">
                                <label for=""><b>Buổi bắt đầu nghỉ phép</b></label>
                                <el-input class="info-request w-100" v-if="infoDetail?.start_shift_off == 1" disabled value="Ca sáng"></el-input>
                                <el-input class="info-request w-100" v-if="infoDetail?.start_shift_off == 2" disabled value="Ca chiều"></el-input>
                                <el-input class="info-request w-100" v-if="infoDetail?.start_shift_off == 3" disabled value="Ca tối"></el-input>
                            </div>
                            <div class="col-xl-3 col-md-6">
                                <label for=""><b>Ngày kết thúc nghỉ phép</b></label>
                                <el-input class="info-request w-100" disabled :value="infoDetail?.end_date | formatDate"></el-input>
                            </div>
                        </div>
                        <div class="mt-3">
                                <div>
                                    <div><b>Lý do Nghỉ phép</b><span class="text-danger">*</span></div>
                                    <el-input
                                    class=" mt-1 info-request w-100"
                                    type="textarea"
                                    style="width: 100%;"
                                    :maxlength="500"
                                    :value="infoDetail?.reason"
                                    :autosize="{ minRows: 2, maxRows: 4}"
                                    placeholder="Nhập lý do nghỉ phép">
                                    </el-input>
                                </div>
                        </div>
                    </div>
                     <!-- nếu là danh mục tăng ca -->
                    <div v-if="infoDetail?.category == 1" class="mt-3">
                        <div class="row d-flex line-cao">
                           <div class="col-xl-4 col-md-6">
                                <div><b>Ngày Tăng ca</b></div>
                                <el-input class="info-request w-100"  disabled :value="infoDetail?.start_date | formatDate"></el-input>
                            </div>
                           <div class="col-xl-4 col-md-6">
                                <div><span><b>Giờ bắt đầu</b></span></div>
                                <el-input class="info-request w-100"  disabled :value="infoDetail?.time_checkin"></el-input>
                            </div>
                           <div class="col-xl-4 col-md-6">
                                <div><span><b>Giờ kết thúc</b></span></div>
                                <el-input class="info-request w-100" disabled :value="infoDetail?.time_checkout"></el-input>
                            </div>
                        </div>

                        <div class=" row d-flex line-cao">
                            <div class="col-xl-4 col-md-6">
                                <div><b>Giờ chấm công thực tế</b></div>
                                <el-input class="info-request w-100" disabled :value="fingerprint.fingerprint_start"></el-input>
                            </div>
                            <div class="col-xl-4 col-md-6">
                                <div><b>Giờ kết thúc thực tế</b></div>
                                <el-input class="info-request w-100" disabled :value="fingerprint.fingerprint_end"></el-input>
                            </div>
                        </div>

                        <div class="mr-4 line-cao mt-3">
                            <div>
                                <div><b>Lý do tăng ca</b></div>
                                <el-input
                                :maxlength="500"
                                class="mt-1 rounded-3 info-request"
                                style="width: 100%"
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 4}"
                                placeholder="Nhập lý do tăng ca"
                                :value="infoDetail?.reason">
                                </el-input>
                            </div>
                        </div>
                        <div class="mr-4 line-cao mt-3">
                            <div>
                                <div><b>Kết quả tăng ca</b></div>
                                <el-input
                                    :maxlength="500"
                                    class="mt-1 rounded-3 info-request"
                                    style="width: 100%"
                                    type="textarea"
                                    :autosize="{ minRows: 2, maxRows: 4}"
                                    placeholder="Nhập lý do tăng ca"
                                    :value="infoDetail?.overtime_results">
                                </el-input>
                            </div>
                        </div>
                        <div class="mr-4 line-cao mt-3">
                            <div>
                                <div><b>Loại tăng ca</b></div>
                                <div>
                                    <el-radio :value="infoDetail?.type_arise" disabled :label="0">Phát sinh giờ làm</el-radio>
                                    <el-radio :value="infoDetail?.type_arise" disabled :label="1">Phát sinh công việc</el-radio>
                                </div>
                            </div>
                        </div>

                    </div>
                     <!-- nếu là danh mục Bổ sung giờ làm việc ngoài văn phòng -->
                    <div v-if="infoDetail?.category == 3" class="mt-3">
                        <div class="row d-flex line-cao res-input">
                            <div class="col-xl-4">
                                <div><b>Ngày cần bổ sung</b></div>
                                <el-input class="info-request w-100" disabled :value="infoDetail?.start_date | formatDate"></el-input>
                            </div>
                            <div class="col-xl-4">
                                <div><span><b>Thời gian bắt đầu</b></span></div>
                                <el-input class="info-request w-100" disabled :value="infoDetail?.time_checkin"></el-input>
                            </div>
                            <div class="col-xl-4">
                                <div><span><b>Giờ giờ kết thúc</b></span></div>
                                <el-input class="info-request w-100" disabled :value="infoDetail?.time_checkout"></el-input>
                            </div>
                        </div>
                        <div class="line-cao mt-3">
                            <div>
                                <div><b>Lý do bổ sung</b></div>
                                <el-input
                                class="mt-1 rounded-3 info-request"
                                type="textarea"
                                style="width: 100%;"
                                :maxlength="500"
                                :autosize="{ minRows: 2, maxRows: 4}"
                                placeholder="Nhập lý do nghỉ phép"
                                :value="infoDetail?.reason">
                                </el-input>
                            </div>
                        </div>
                    </div>
                     <!-- nếu là danh mục xin đổi ca -->
                    <div v-if="infoDetail?.category == 2" class="info-request mt-3 w-100"> 
                        <div class="row d-flex res-input">
                            <div class="col-xl-4 mt-3">
                                <div><b>Ngày đổi ca</b></div>
                                <el-input class="info-request w-100" disabled :value="infoDetail?.start_date | formatDate"></el-input>
                            </div>
                            <div class="col-xl-4 mt-3">
                                <div><b>Ca hiện tại</b></div>
                                <el-input  class="info-request w-100" disabled :value="changeCurrentWorking(infoDetail?.current_working_hours)"></el-input>
                            </div>
                            <div class="col-xl-4 mt-3">
                                <div><b>Ca thay đổi</b></div>
                                <el-input class="info-request w-100" disabled :value="changeCurrentWorking(infoDetail?.current_working_change)"></el-input>
                            </div>
                        </div> 
                        <div class="line-cao mt-3">
                            <div>
                                <div><b>Lý do đổi ca</b></div>
                                <el-input
                                class="mt-1 rounded-3 info-request"
                                type="textarea"
                                style="width: 100%;"
                                :maxlength="500"
                                :autosize="{ minRows: 2, maxRows: 4}"
                                placeholder="Nhập lý do đổi ca"
                                :value="infoDetail?.reason">
                                </el-input>
                            </div>
                        </div>
                    </div>
                    <!-- box show detail bottom -->
                    <div class="col-xl-12 line-cao d-flex p-0 mt-2" v-if="infoDetail?.category == 0">
                        <div class="col-md-2 p-0">
                            <div><b>Loại nghỉ phép</b></div>
                        </div>
                        <div>
                            <el-radio :value="infoDetail?.type_leave" :label="0" disabled>Nghỉ dùng phép</el-radio>
                            <el-radio :value="infoDetail?.type_leave" :label="1" disabled>Nghỉ không lương</el-radio>
                        </div>
                    </div>
                    <div class="box-info-handler row mt-3">
                        <div v-if="infoDetail?.category == 0" class="col-xl-6 line-cao">
                            <div><b>Số điện thoại liên hệ khi cần</b></div>
                            <el-input class="w-100 info-request" disabled :value="infoDetail?.phone ? infoDetail?.phone : infoUser?.user?.phone"></el-input>
                        </div>
                        <div class="col-xl-6 line-cao">
                                <div>
                                    <div><b>Trạng thái đơn</b></div>
                                    <el-input class="w-100" v-if="infoDetail?.status == 0 " disabled value="Đang chờ duyệt"></el-input>
                                    <el-input class="w-100" v-if="infoDetail?.status == 1 " disabled value="Đã duyệt"></el-input>
                                    <el-input class="w-100" v-if="infoDetail?.status == 2 " disabled value="Đã từ chối"></el-input>
                                </div>
                        </div>

                        <div class="col-xl-6 line-cao" v-if="infoDetail?.user_handler && infoDetail?.status == 1">
                            <div>
                                <div><b>Người duyệt</b></div>
                                <el-input class="w-100" disabled :value="infoDetail?.user_handler?.name"></el-input>
                            </div>
                        </div>
    
                        <div class="col-xl-6 line-cao"  v-if="infoDetail?.user_handler && infoDetail?.status == 2">
                                    <!-- NGƯỜI TỪ CHỐI -->
                                    <div >
                                        <div>
                                            <div><b>Người từ chối</b></div>
                                            <el-input class="w-100" disabled :value="infoDetail?.user_handler?.name"></el-input>
                                        </div>
                                    </div>
    
                                </div>
                            </div>
                            <div v-if="infoDetail?.user_handler && infoDetail?.status == 2">
                                <!-- LÝ DO TỪ CHỐI -->
                                <div class="line-cao mt-3">
                                    <div>
                                        <div><b>Lý do từ chối</b></div>
                                        <el-input
                                        class=" mt-1"
                                        type="textarea"
                                        style="width: 100%;"
                                        :maxlength="500"
                                        :value="infoDetail?.reason_for_refusal"
                                        :autosize="{ minRows: 2, maxRows: 4}"
                                        placeholder="Nhập lý do từ chối">
                                        </el-input>
                                    </div>
                                </div>
                            </div>
                    <div class="d-flex justify-content-end mt-4">
                        <div v-if="infoDetail?.status == 1">
                            <button @click="changedilogRefuse('Refuse')" class="btn btn-danger rounded mr-3">Từ chối</button>
                        </div>
<!--                        <div v-if="infoDetail?.status == 0">-->
<!--                            <button @click="changedilogRefuse('Accept')" class="btn btn-success rounded">Duyệt</button>-->
<!--                        </div>-->
                    </div>
                    
                </div>
            </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_All_LIST_USER_REQUEST, DELETE_REQUEST_APPLICATION, SHOW_APPLITICATION, DECIDED_APPLITICATION, GET_DEPARTMENT} from "../../../core/services/store/user/users.module";
import { EXPORT_EXCEL_REQUEST_APPLICATION, EXPORT_APPLITICATION_CATEGORY} from '../../../core/services/store/export/export.module';
import {exportExcelMultipleSheet, HEADER_ROW_EXPORT_CATEGORY_0, HEADER_ROW_EXPORT_CATEGORY_1, HEADER_ROW_EXPORT_CATEGORY_2, HEADER_ROW_EXPORT_CATEGORY_3 } from "../../../core/filters";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';
export default {
    name: "list-propose-application",
    components: {
    DatePicker
  },
    data() {
        return {
            page: 1,
            last_page: 1,
            query: 
            {
                keyword: '', 
                category: '',
                department: '',
                month: '',
                status: '', 
            },
            infoDetail: [],
            dataUserRequest:[],
            infoUser: [],
            allDepartment: [],
            titleDetail: '',   
            error: 
            {
                timecheckRest: false,
            },
            dialogShowDetail: false,
            dialogRequestApplications: false,
            dialogRefusetRequest: false,
            dialogConfirmRequest: false,
            phong_ban_xu_ly_define: {
                    1: 'TTHT',
                    2: 'Tài chính',
                    3: 'Nhân sự',
                    4: 'SPNN',
                    5: 'CNTT',
                    6: 'Đối tác',
                    7: 'STVT',
                    8: 'Đào tạo',
                    9: 'Brand',
                    10: 'Digital',
                    11: 'Marketing',
                    12: 'R&D',
                    13: 'Pháp lý',
                    14: 'QLHV',
                    15: 'CSVC',
                },
            typeApplications: 
            [
                {id: 0, type: 'Nghỉ phép'},
                {id: 1, type: 'Tăng ca'},
                {id: 2, type: 'Xin thay đổi ca làm việc'},
                {id: 3, type: 'Bổ sung giờ làm việc ngoài văn phòng'},
            ],
            phong_ban_xu_lys: [
                {id:1, name: 'TTHT'},
                {id:2, name: 'Tài chính'},
                {id:3, name: 'Nhân sự'},
                {id:4, name: 'SPNN'},
                {id:5, name: 'CNTT'},
                {id:6, name: 'Đối tác'},
                {id:7, name: 'STVT'},
                {id:8, name: 'Đào tạo'},
                {id:9, name: 'Brand'},
                {id:10, name: 'Digital'},
                {id:11, name: 'Marketing'},
                {id:12, name: 'R&D'},
                {id:13, name: 'Pháp lý'},
                {id:14, name: 'QLHV'},
                {id:15, name: 'CSVC'},
            ],
            loadingTable: false,
            totoalData:  '',
            fingerprint: 
            {
                fingerprint_start : '',
            }
        }
    },

    computed: {
    },
    mounted() {
        this.getListUserRequest();
        this.getDepartments();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Danh sách", icon: 'far fa-info-square'},
            {title: "Danh sách đề xuất duyệt đơn", icon: 'fas fa-list'}
        ]);
    },

    watch: { },

    methods: {
        showDetail(item) {
            this.dialogShowDetail = true;
            this.infoDetail = item;
            this.getInfoUser(item);
            switch (item.category) {
                case 0:
                    var result = 'Nghỉ phép';
                break;
                case 1:
                    var result = 'Tăng ca';
                break;
                case 2:
                    var result = 'Xin đổi ca làm việc';
                break;
                case 3:
                    var result = 'Bổ sung giờ làm việc ngoài văn phòng';
                break;
            }
            return this.titleDetail = result;
        },

        formatTimeReal(time) {
            return moment(time).format('HH:mm:ss');
        },

       

        deleteRequest(id_delete) {
            this.$store.dispatch(DELETE_REQUEST_APPLICATION, {
                id: id_delete
            }).then((res) => {
                    this.$notify({
                        title: 'Thành công',
                        message: 'Xoá yêu cầu thành công',
                        type: 'success'
                    });
                }).catch(() => {
                this.loading = false
            });
        },

        clickExprotExcel() {
            let is_muiltiple_sheet = this.query.category;
            switch (is_muiltiple_sheet) {
                case '':
                this.exportClassError();
                break;
                case 0:
                this.exportExcel();
                break;
                case 1:
                this.exportExcel();
                break;
                case 2:
                this.exportExcel();
                break;
                case 3:
                this.exportExcel();
                break;
                default:
                    break;
            }
        },
        
        exportExcel() {
            this.$store.dispatch(EXPORT_EXCEL_REQUEST_APPLICATION, {
                keyword: this.query.keyword,
                category: this.query.category,
                status: this.query.status,
                department: this.query.department,
                date_range: this.query.date_range,
                month: this.query.month
            }).then((res) => {
                this.getListUserRequest();
                }).catch(() => {
                this.loading = false
            });
        },

        async exportClassError() {
            let data_export = {
            sheet_one : [],
            sheet_two : [],
            sheet_three : [],
            sheet_four : [],
            };
            await this.$store.dispatch(EXPORT_APPLITICATION_CATEGORY, {
            ...this.query,
            }).then((res) => {
                data_export.sheet_one = res.data[[0]];
                data_export.sheet_two = res.data[[1]];
                data_export.sheet_three = res.data[[2]];
                data_export.sheet_four = res.data[[3]];
            }).finally(() => {
            });

            let export_sheet = [         
            {
            'header' : HEADER_ROW_EXPORT_CATEGORY_0,
            'data' : data_export.sheet_one,
            'sheet' : 'Nghỉ phép'
            },
            {
            'header' : HEADER_ROW_EXPORT_CATEGORY_1,
            'data' : data_export.sheet_two,
            'sheet' : 'Tăng ca'
            },
            {
            'header' : HEADER_ROW_EXPORT_CATEGORY_2,
            'data' : data_export.sheet_three,
            'sheet' : 'Xin đổi ca'
            },
            {
            'header' : HEADER_ROW_EXPORT_CATEGORY_3,
            'data' : data_export.sheet_four,
            'sheet' : 'Bổ sung giờ làm ngoài văn phòng'
            }
            ]
            let fileName = "danh-sach-de-xuat" + new Date().getTime() / 1000 + ".xlsx";
            exportExcelMultipleSheet(export_sheet, fileName, false);
        },

        handleRequest(id, decided) {
            this.loadingTable = true;
            this.$store.dispatch(DECIDED_APPLITICATION, {
               id,
               status: decided,
               reason_for_refusal: this.query.reason_for_refusal
            }).then((res) => {
                if(decided){
                    this.$notify.success({
                    title: 'Thành công',
                    message: 'Duyệt yêu cầu thành công',
                    offset: 100
                    });
                }else {
                    this.$notify.success({
                    title: 'Thành công',
                    message: 'Từ chối yêu cầu thành công',
                    offset: 100
                    });
                }
                this.getListUserRequest();
                this.dialogConfirmRequest = false;
                this.dialogRefusetRequest = false;
                this.query.reason_for_refusal= '';
            }).catch((e) => {
                if(decided){
                    this.$notify.error({
                    title: 'Thất bại',
                    message: 'Duyệt yêu cầu thất bại',
                    offset: 100
                    });
                }else {
                    this.$notify.error({
                    title: 'Thất bại',
                    message: 'Từ chối yêu cầu thất bại',
                    offset: 100
                    });
                }
            }).finally(() => {
                this.loadingTable = false;
            });
           
        },

        checkLength(item, max_length) {
            if (item.length > max_length) {
                return item.substring(0, max_length) + '...';
            } else {
                return item;
            }
        },

        getInfoUser(item) {
                this.$store.dispatch(SHOW_APPLITICATION, {
                    id: item.id
            }).then((res) => {
                this.infoUser = res.data;
                this.fingerprint.fingerprint_start = res.data.fingerprint_attendance_start;
                this.fingerprint.fingerprint_end = res.data.fingerprint_attendance_end;
                // this.getListUserRequest();
                }).catch(() => {
            });
        },

        getDepartments() {
            this.$store.dispatch(GET_DEPARTMENT, {
            }).then((res) => {
              this.allDepartment = res.data;
            }).catch(() => {
               
            })
        },

        getListUserRequest() {
            this.loadingTable = true;
            this.$store.dispatch(GET_All_LIST_USER_REQUEST, {
                page: this.page,
                keyword: this.query.keyword,
                category: this.query.category,
                status: this.query.status,
                department: this.query.department,
                date_range: this.query.date_range,
                month: this.query.month
            }).then((res) => {
                this.dataUserRequest = res.data
                this.totoalData =  res.pagination.total;
                this.last_page = res.pagination.last_page;
                }).catch(() => {
                this.loading = false
            }).finally(()=> {
                this.loadingTable = false;
            });
        },

        changedilogRefuse(is_dialog) {
            this.dialogShowDetail = false;
            if(is_dialog == 'Refuse') {
                this.dialogRefusetRequest = true;
            }
            if(is_dialog == 'Accept') {
                this.dialogConfirmRequest = true;
            }

        },

        clickCallback() {
            this.getListUserRequest();
        },
        changeCurrentWorking(type) {
            let text = '';
            switch (type) {
                case 1:
                    text = 'Sáng - chiều';
                    break;
                case 2:
                    text = 'Chiều - tối';
                    break;
                case 3:
                    text = 'Sáng - tối';
                    break;
            }
            return text;
        }
      }
    }
</script>

<style>
    * {
        font-size: 12px;
      }


    .circle_status{
        color: rgb(5, 5, 5);
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
    .selcet-type-applications{
        border-color: #B87EE7;
    }

    #dialog-request-applications .el-dialog__body {
        padding: 16px 20px;
    }

    /* #search .el-input__inner {
        width: 267px;
    } */

    

    .btn-rest-day {
        font-size: 8px;
        padding: 5px;
        margin-top: 5px;
        opacity: 0.5;
        color: black;
    }
    .line-cao{
        line-height: 34px;
    }

    @media only screen and (max-width: 1441px){
        #dialogShowDetail .el-dialog {
            width: 57% !important;
        }

        #list-propse-application .el-input__inner {
            width: 100% !important;
        }

        .info-request {
            width: 151px 
        }
    }

    @media only screen and (max-width: 1025px){
        #list-propse-application .header .el-input__inner{
            display: block !important;
        }
        #list-propse-application .res-input {
            display: block !important;
        }

        #list-propse-application #search .el-input__inner {
            width: 100%;
        }
        .info-request {
            width: 100% 
        }
    }

    @media only screen and (max-width: 769px){
        #list-propse-application #search .el-input__inner {
            display: block;
            width: 100%!important;
        }

        #list-propse-application #search .btn-search {
            margin-top: -25px;
            margin-bottom: 20px;
        }

        #dialog-request-applications .el-dialog {
            width: 62% !important;
        }
        
        #dialogShowDetail .box-info-handler .el-input__inner {
            width: 200px;
        }
    }

    @media only screen and (max-width: 426px){
        #dialogShowDetail .el-dialog {
            width: 88% !important;
        }
           

        #dialog-request-applications .el-dialog {
            width: 90% !important;
        }

        #list-propse-application .box-info-handler .el-input__inner {
            width: 200px;   
        }

        #list-propse-application .type-applications {
            display: block !important;
        }

        #list-propse-application #search .el-input__inner {
            display: block;
        }
  
    }

    @media only screen and (max-width: 431px){
        #list-propse-application #search .el-input__inner {
            display: block;
        }
    }

    @media only screen and (max-width: 391px){
        #list-propse-application #search .el-input__inner {
            display: block;
        }
    }

    @media only screen and (max-width: 376px){
        #list-propse-application #search .el-input__inner {
            display: block;
        }

        #dialogShowDetail .el-dialog {
            width: 90% !important;
        }
    }


    .style-color{
        color: #454674 !important;
    }

    .btn-css {
        background-color: #F4F5FF;
        color: #472F92;
        border-color: #B87EE7;
    }

    .btn-css:hover {
        background-color: #d0d3f4;
        color: black;
    }

    table th,
        table td {
        padding: 0.5rem;
        text-align: center;
        min-width: 150px;
        }
</style>