<template>
    <div>
        <b-modal
            @ok="handleOk"
            centered id="task-edit-modal"
            size="lg"
            title="Sửa tác vụ"
            ok-title="Cập nhật"
            cancel-title="Hủy"
            :ok-disabled="okDisable"
            @hidden="resetModal"
            :hide-footer="isShowLoading"
            @show="showModalEvent()"
        >
            <ValidationObserver v-show="!isShowLoading" v-slot="{ handleSubmit }" ref="form">
                <form class="form" autocomplete="off">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Loại tác vụ <span class="text-danger">*</span></label>
                                    <ValidationProvider name="Loại tác vụ" rules="required|max:30"
                                                        v-slot="{ errors,classes }"
                                                        vid="type_task">
                                        <el-select v-model="schedule.type_task"
                                                   :disabled="isDisableByTypeTask"
                                                   placeholder="Loại tác vụ"
                                                   clearable
                                                   @change="handleTypeTask"
                                        >
                                            <el-option
                                                v-for="item in type_task"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                                :disabled="item.id === testInput"
                                            >
                                            </el-option>
                                        </el-select>
                                        <div class="fv-plugins-message-container">
                                            <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Thời gian bắt đầu<span class="text-danger">*</span></label>
                                    <ValidationProvider name="Thời gian bắt đầu" rules="required"
                                                        v-slot="{ errors,classes }"
                                                        vid="schedule">
                                        <date-picker
                                            :disabled="isDisableTimeStart"
                                            v-model="schedule.schedule"
                                            type="datetime"
                                            format="DD-MM-YYYY HH:mm"
                                            valueType="YYYY-MM-DD HH:mm"
                                            placeholder="Thời gian bắt đầu"
                                            :time-picker-options="time_picker_options"
                                            :disabled-time="disableOptionTimeStart"
                                            :disabled-date="disabledBeforeStartDate"
                                            @change="onStartDateChanged"
                                        >
                                        </date-picker>
                                        <div class="fv-plugins-message-container">
                                            <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Thời gian kết thúc<span class="text-danger">*</span></label>
                                    <ValidationProvider name="Thời gian kết thúc" rules="required"
                                                        v-slot="{ errors,classes }"
                                                        vid="end_schedule">
                                        <date-picker
                                            :disabled="isDisableTimeEnd"
                                            v-model="schedule.end_schedule"
                                            type="datetime"
                                            format="DD-MM-YYYY HH:mm"
                                            valueType="YYYY-MM-DD HH:mm"
                                            placeholder="Thời gian kết thúc"
                                            :time-picker-options="time_picker_options_end"
                                            :disabled-date="disabledBeforeEndDate"
                                            :disabled-time="disableOptionTimeEnd"
                                            @change="onEndDateChanged"
                                        >
                                        </date-picker>
                                        <div class="fv-plugins-message-container">
                                            <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Người phụ trách<span class="text-danger">*</span></label>
                                    <ValidationProvider name="Tester" rules="required" v-slot="{ errors,classes }">
                                        <el-select
                                            filterable
                                            v-model="schedule.tester_id"
                                            placeholder="Người phụ trách"
                                            clearable
                                            :disabled=" isDisableTester"
                                            @change="changeTester()"
                                        >
                                            <el-option
                                                v-for="item in testers"
                                                :key="item.id"
                                                :label="`${item.name} - ${item.phone}`"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                        <div class="fv-plugins-message-container">
                                            <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                          <div class="col-md-6" v-if="schedule.type_task !== TEST_DAU_VAO">
                            <div class="form-group">
                              <label>Đối tượng xử lý<span class="text-danger">*</span></label>
                              <ValidationProvider name="Đối tượng xử lý" rules="required|max:30" v-slot="{ errors,classes }"
                                                  vid="processing_object">
                                <el-input placeholder="Nhập đối tượng xử lý" v-model="schedule.processing_object"></el-input>
                                <div class="fv-plugins-message-container">
                                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
<!--                          <div class="col-md-6" v-if="schedule.type_task === TEST_DAU_VAO">-->
<!--                            <div class="form-group">-->
<!--                              <label>Hình thức test<span class="text-danger">*</span></label>-->
<!--                              <ValidationProvider name="Hình thức test" rules="required" v-slot="{ errors,classes }">-->
<!--                                <el-select-->
<!--                                    filterable-->
<!--                                    v-model="schedule.type"-->
<!--                                    placeholder="Hình thức test"-->
<!--                                    clearable-->
<!--                                >-->
<!--                                  <el-option-->
<!--                                      v-for="item in listTypeTask"-->
<!--                                      :key="item.id"-->
<!--                                      :label="item.name"-->
<!--                                      :value="item.id">-->
<!--                                  </el-option>-->
<!--                                </el-select>-->
<!--                                <div class="fv-plugins-message-container">-->
<!--                                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">-->
<!--                                    {{ errors[0] }}-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </ValidationProvider>-->
<!--                            </div>-->
<!--                          </div>-->
                        </div>
                    </div>
                </form>
            </ValidationObserver>
            <modal-handle-loading
                v-show="isShowLoading"
                :status-prop="statusSpeaking"
                :id="idSpeaking"
                :prop-seconds="seconds"
                :isShowLoading="isShowLoading"
                :action="'update'"
                @close-modal="closeModal"
                @loading-success="loadingSuccess"
            ></modal-handle-loading>
        </b-modal>
    </div>
</template>

<script>
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
    LIST_TESTER_FREE_TIME,
    TEST_INPUT_UPDATE_TASK
} from "@/core/services/store/test/testInput.module";
import {
    NOT_HAVE_ADMIN,
    TEST_DAU_VAO,
    TIME_PICKER_OPTIONS, TIME_PICKER_OPTIONS_END,
    TYPE_TASK,
    TYPE_TASK_TEST_INPUT
} from "@/core/option/testInputOption";
import {GET_TESTERS} from "@/core/services/store/test/test.module";
import moment from "moment-timezone";
import ModalHandleLoading from "./ModalHandleLoading";


export default {
    name: "TaskEditModal",
    components: {DatePicker, ModalHandleLoading},
    props: {
        schedule_prop: {
            type: Object,
            default: () => {
                return null;
            }
        },
    },
    data() {
        return {
            //setup
            showTimePanel: false,
            showTimeRangePanel: false,
            okDisable: false,
            search_loading: false,
            fileList: [],
            type_task: TYPE_TASK,
            testers: [],
            schedule: {
                type_task: '',
                tester_id: '',
                schedule: '',
                end_schedule: '',
                // type: ''
            },
            time_picker_options: TIME_PICKER_OPTIONS,
            time_picker_options_end: TIME_PICKER_OPTIONS_END,
            isDisableTimeStart: false,
            timeTasks: new Map([
                [TYPE_TASK_TEST_INPUT, 30]
            ]),
            allTester: [],
            testInput: 1,
            //Loading
            isShowLoading: false,
            statusSpeaking: 0,
            idSpeaking: 0,
            seconds: 60,
            listTypeTask: [],
            TEST_DAU_VAO: TEST_DAU_VAO,
            loading: false
            //End Loading
        }
    },
    watch: {
        schedule_prop: {
            handler() {
              console.log(this.schedule_prop);
                this.schedule.id = this.schedule_prop.id;
                this.schedule.type_task = this.schedule_prop.type_task;
                this.schedule.tester_id = this.schedule_prop.tester_id;
                this.schedule.schedule = this.schedule_prop.schedule;
                this.schedule.end_schedule = this.schedule_prop.end_schedule;
                this.schedule.processing_object = this.schedule_prop.processing_object;
                // this.schedule.type = this.schedule_prop.type
                if (this.schedule.type_task == TEST_DAU_VAO) {
                    this.isDisableTimeStart = true
                }
            },
            deep: true
        },
        statusSpeaking() {
            this.loadingSuccess();
        },
        isDisableTester() {
        }
    },
    computed: {
        isDisableByTypeTask() {
            return this.schedule.type_task === TEST_DAU_VAO && this.isDisableTimeStart;
        },
        isDisableTimeEnd() {
            return [TYPE_TASK_TEST_INPUT].includes(this.schedule.type_task)
        },
        isDisableTester() {
            if (!this.schedule.end_schedule || !this.schedule.schedule) {
                return false;
            }
            return ([this.schedule_prop.end_schedule, null].includes(this.schedule.end_schedule) || [this.schedule_prop.schedule, null].includes(this.schedule.schedule)) && this.schedule.type_task != TEST_DAU_VAO
        }
    },
    mounted() {
        
    },
    methods: {
        eventShowModal() {
            this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
                this.getTestersFrees()
            })
        },
				showModalEvent() {
					this.isShowLoading = false;
					this.getTestersFrees();
				},
        resetModal() {
            this.schedule.id = this.schedule_prop.id;
            this.schedule.type_task = this.schedule_prop.type_task;
            this.schedule.tester_id = this.schedule_prop.tester_id;
            this.schedule.schedule = this.schedule_prop.schedule;
            this.schedule.end_schedule = this.schedule_prop.end_schedule;
            this.isDisableTimeStart = this.schedule.type_task == TEST_DAU_VAO;
            this.testers = [];
            // this.schedule.type = '';
            this.listTypeTask = []
        },
        onStartDateChanged(date, type) {
            this.schedule.tester_id = ''
            this.getTestersFrees()
            this.handleDate(date, type, "dateStart");
        },
        onEndDateChanged(date, type) {
            this.schedule.tester_id = ''
            this.getTestersFrees()
            this.handleDate(date, type, "dateEnd");
        },
        disabledBeforeStartDate(date) {
            const today = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
            return moment(date) < today;
        },
        disabledBeforeEndDate(date) {
            return moment(date).endOf('days') <= moment(this.schedule.schedule);
        },
        disableOptionTimeStart(time) {
            let currentTime = moment(time).format("HH:mm");
            return ("11:30" < currentTime && currentTime <= "14:15") || ("17:30" <= currentTime && currentTime < "18:00")
        },
        disableOptionTimeEnd(time) {
            let currentTime = moment(time).format("HH:mm");
            return time <= new Date(Date.parse(this.schedule.schedule)) || ("12:00" < currentTime && currentTime < "14:15");
        },
        handleDate(date, type, typeTime) {
            if (type !== 'date') {
                this.pick = true;
                this.showTimePanel = null
                this.showTimePicker = null
                if (typeTime == "dateStart") {
                    // this.schedule.end_schedule = this.calculateEndTime(Date.parse(date))
                    this.schedule.end_schedule = '';
                }
                return;
            }
            this.showTimePanel = this.pick || moment(date).isSame(moment(date).startOf('day')) //
            this.showTimePicker = this.showTimePanel ? true : null
            this.pick = false;
        },
        calculateEndTime(date) {
            if (this.timeTasks.has(this.schedule.type_task)) {
                let minutes = this.timeTasks.get(this.schedule.type_task);
                return moment(new Date(date + minutes * 60000)).format("YYYY-MM-DD HH:mm");
            }
            return null;
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.update();
        },
        afterUpdateSuccess(speaking) {
            if (!speaking) {
                return;
            }
            this.isShowLoading = true;
            this.statusSpeaking = speaking.status;
            this.idSpeaking = speaking.id;
            this.seconds = 60;
        },
        loadingSuccess() {
            this.$emit('create-success');
        },
        update() {
            if (this.okDisable) {
                return;
            }
            this.okDisable = true;
            this.$store.dispatch(TEST_INPUT_UPDATE_TASK, this.schedule).then((data) => {
                this.okDisable = false;
                this.$emit('create-success');
                this.afterUpdateSuccess(data.data);
            }).catch((e) => {
                this.okDisable = false;
                if (+e.response.data.error_code === NOT_HAVE_ADMIN) {
                    this.$message.error(`${e.response.data.message}`);
                    this.schedule.tester_id = null;
                    this.getTestersFrees()
                }
                if (e.response.data.message) {
                  this.$message.error(e.response.data.message);
                }
            }).finally(() => {
                setTimeout(() => {
                    this.okDisable = false;
                }, 1000);
            });
        },
        // Set phòng ban xử lý
        closeModal() {
            this.isShowLoading = false;
            this.idSpeaking = 0;
            this.statusSpeaking = 0;
            this.$bvModal.hide('task-edit-modal');
        },
        handleTypeTask() {
            this.isDisableTimeStart = false
            this.schedule.schedule = '';
            this.schedule.end_schedule = '';
        },
        getTesters() {
            this.search_loading = true;
            this.$store.dispatch(GET_TESTERS, {
                status: 1
            }).then((data) => {
                this.search_loading = false;
                this.testers = data.data;
            })
        },
        getTestersFrees() {
						if (!this.schedule.schedule || !this.schedule.end_schedule) {
							return;
						}
            let payload = {
                start_date: moment(this.schedule.schedule).format("YYYY-MM-DD HH:mm:ss"),
                end_date: moment(this.schedule.end_schedule).format("YYYY-MM-DD HH:mm:ss"),
                tester_id: this.schedule.tester_id,
                test_input_schedule_items_id: this.schedule.id
            }
            this.search_loading = true;
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
            this.$store.dispatch(LIST_TESTER_FREE_TIME, payload).then((data) => {
                this.search_loading = false;
                this.testers = data.data;
                this.checkTypeTestOfTester()

            }).finally(()=>{
              loading.close();
            })
        },
        checkTypeTestOfTester(){
          let currentTester = this.testers.find((tester)=>tester.id === this.schedule.tester_id )
          const ALL = 0
          const ONLINE = 1
          const OFFLINE = 2
          if(ALL === currentTester.type){
            this.listTypeTask = [
              {
                id: 1,
                name: "ONLINE"
              },
              {
                id: 2,
                name: "OFFLINE"
              },
            ]
          }
          if(ONLINE === currentTester.type){
            this.listTypeTask = [
              {
                id: 1,
                name: "ONLINE"
              }
            ]
          }
          if(OFFLINE === currentTester.type){
            this.listTypeTask = [
              {
                id: 2,
                name: "OFFLINE"
              },
            ]
          }

      },
      changeTester(){
          // this.schedule.type = ''
          this.checkTypeTestOfTester()
      }
    }
}
</script>

<style scoped>
.el-select {
    width: 100%;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
}
</style>