import ApiService from "@/core/services/api.service";

const _ = require('lodash');
//action types
export const GET_TEACHER_BY_ID = "get_teacher_by_id";
export const GET_TEACHERS = "list-teacher";
export const GET_TEACHER_ACCOUNT_TYPES = "teacher_account_type";
export const CREATE_TEACHER = "create_teacher";
export const UPDATE_TEACHER = "update_teacher";
export const DELETE_TEACHER = "delete_teacher";
export const GET_CLASS_BY_TEACHER = "get_classes_by_teacher_or_tutor";

//mutations types
export const SET_TEACHERS = "setTeachers";
export const SET_TEACHER_ACCOUNT_TYPES = "set_teacher_account_types";
export const SET_TEACHERS_ERROR = "errorTeachers";
export const SET_TEACHER_BY_ID = "set_Teacher_By_Id";
export const SET_TEACHER_PAGINATE = "set_teacher_paginate_value";
export const UPDATE_LIST_TEACHER_WHEN_DELETE = "set_teachers_when_delete_teacher";
export const SET_CLASSES_BY_TEACHER = "set_classes_by_teacher";
export const SET_CLASSES_PAGINATE = "set_classes_paginate_by_teacher";
export const EXPORT_EXCEL_TEACHER = "export_excel_teacher";
export const EXPORT_EXCEL_TEACHER_V2 = "export_excel_teacher_v2";


const state = {
    errors: null,
    teachers: [{
        profile: {
            address: '',
            account_type: {
                name: ''
            }
        }
    }],
    teacher : '',
    classesByTeacher : [],
    classesPaginate : [],
    teacher_account_types : [],
    teacherPaginatedData : [],
};

const getters = {
    getAllTeachers(state) {
        return state.teachers;
    },
    getTeacherById(state) {
        return state.teacher;
    },
    getTeacherPaginate(state) {
        return state.teacherPaginatedData;
    },
    getTeacherAccountTypes(state) {
        return state.teacher_account_types;
    },
    getClassesByTeacher(state) {
        return state.classesByTeacher;
    },
    getClassesPaginate(state) {
        return state.classesPaginate;
    },
};
const actions = {
    [CREATE_TEACHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('create-teacher', payload).then(({data}) => {
                    resolve(data);
                }).catch(({response}) => {
                    reject(response);
                });
        });
    },
    [GET_TEACHER_ACCOUNT_TYPES](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("teacher-account-types", payload)
                .then(({data}) => {
                    context.commit(SET_TEACHER_ACCOUNT_TYPES,data.data);
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },
    [UPDATE_TEACHER](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.post("update-teacher/" + payload.id, payload.params)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({res}) => {
                    reject(res);
                });
        });
    },

    [GET_TEACHERS](context, payload) {
        return new Promise((resolve) => {
            ApiService.query("list-user", payload)
                .then(({data}) => {
                    context.commit(SET_TEACHERS, data.data.data);
                    const teacherPagination = {
                        total: data.data.meta.pagination.total,  // total number of elements or items
                        per_page: data.data.meta.pagination.per_page, // items per page
                        current_page: data.data.meta.pagination.current_page, // current page (it will be automatically updated when teachers clicks on some page number).
                        total_pages: data.data.meta.pagination.total_pages // total pages in record
                    }
                    context.commit(SET_TEACHER_PAGINATE, teacherPagination)
                    resolve(data);
                })
                .catch(() => {
                    //context.commit(SET_ERROR, response.data.message);
                });
        });
    },

    [GET_TEACHER_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('users', id).then(({data}) => {
                context.commit(SET_TEACHER_BY_ID, data.data);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [DELETE_TEACHER](context, id) {
        return new Promise(resolve => {
            ApiService.delete('user', id).then(({data}) => {
                context.commit(UPDATE_LIST_TEACHER_WHEN_DELETE, id);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_CLASS_BY_TEACHER](context, payload) {
        return new Promise(resolve => {
            ApiService.get('classes-by-teacher', payload.id, payload.params).then(({data}) => {
                const classPagination = {
                    total: data.data.total,  // total number of elements or items
                    per_page: data.data.per_page, // items per page
                    current_page: data.data.current_page, // current page (it will be automatically updated when teachers clicks on some page number).
                    total_pages: data.data.last_page // total pages in record
                }
                context.commit(SET_CLASSES_BY_TEACHER, data.data.data);
                context.commit(SET_CLASSES_PAGINATE, classPagination);
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [EXPORT_EXCEL_TEACHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('teacher/export', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_EXCEL_TEACHER_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('teacher/export-v2', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
};


const mutations = {
    [SET_TEACHERS](state, data) {
        state.teachers = data;
        state.errors = {};
    },
    [SET_TEACHER_ACCOUNT_TYPES](state, data) {
        state.teacher_account_types = data;
        state.errors = {};
    },
    [SET_TEACHERS_ERROR](state, error) {
        state.errors = error;
    },
    [SET_TEACHER_PAGINATE](state, data) {
        state.teacherPaginatedData = data;
    },
    [UPDATE_LIST_TEACHER_WHEN_DELETE](state, id){
        let teachers = state.teachers;
        let indexTeacher = _.findIndex(teachers,function (object) {
            return object.id === id;
        });
        teachers.splice(indexTeacher,1);
    },
    [SET_TEACHER_BY_ID](state, data){
        state.teacher = data;
    },

    [SET_CLASSES_BY_TEACHER](state, data){
        state.classesByTeacher = data;
    },
    [SET_CLASSES_PAGINATE](state, data){
        state.classesPaginate = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
