import {CSS_STATUS_TOURNAMENT, STATUS_TOURNAMENT} from "@/core/option/tournamentOption";
import moment from "moment-timezone";
export function TournamentListModel(idTournament,nameTournament='',timeStart='',timeEnd='',status=0,display=0){
    this.id = idTournament
    this.nameTournament = nameTournament
    this.timeStart = moment(timeStart).format("DD/MM/YYYY HH:mm")
    this.timeEnd = moment(timeEnd).format("DD/MM/YYYY HH:mm")
    this.statusName = statusToString(status)
    this.display = display
    this.color = colorStatus(status)
    this.status = status
}

export const TournamentList = [
   new TournamentListModel("IELTS Mentor Miền Trung mở rộng lần I năm 2023","21:10:00 14/02/2023","21:10:00 14/02/2023",0,0),
   new TournamentListModel("ECoach Thanh Hóa mở rộng lần I năm 2023","21:10:00 14/02/2023","21:10:00 14/02/2023",1,1)
]

function statusToString(status){
    return STATUS_TOURNAMENT.get(status)
}
function colorStatus(status){
    return CSS_STATUS_TOURNAMENT.get(status)
}