<template>
    <div class="card card-custom gutter-b border-success">
        <div class="card-header bg-success">
            <div class="card-title">
                <h3 class="card-label text-white">Danh sách hỗ trợ học viên
                </h3>
            </div>
        </div>
        <div class="card-body">
            <div class="example-preview table-responsive">
                <table class="table table-vertical-center">
                    <thead>
                    <tr>
                        <th scope="col" class="min-w-30px">Mã học viên</th>
                        <th scope="col" class="min-w-100px">Lớp</th>
                        <th scope="col" class="min-w-100px">Nội dung</th>
                        <th scope="col" class="min-w-100px">Chi nhánh</th>
                        <th scope="col" class="min-w-100px">Danh mục</th>
                        <th scope="col" class="min-w-100px">Trạng thái</th>
                    </tr>
                    </thead>
                    <tbody v-if="customer.user && customer.user.tickets.length > 0">
                    <tr v-for="(item, index) in customer.user.tickets" :key="index">
                        <td>{{item.user_id + 100000}}</td>
                        <td>{{item.classes ? item.classes.name : ''}}</td>
                        <td>{{item.content}}</td>
                        <td>{{item.branch ? item.branch.name : ''}}</td>
                        <td></td>
                        <td>{{getTextStatus(item.status)}}
                        </td>
                    </tr>
                    </tbody>
                    <tbody v-else>
                    <tr>
                      <td colspan="6" class="text-center">Chưa có dữ liệu</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ClassSupport",
        data() {
            return {
                status: [
                    {
                        value: 0,
                        text: 'Hủy',
                    },
                    {
                        value: 1,
                        text: 'Chờ xử lý',
                    },
                    {
                        value: 2,
                        text: 'Đang xử lý',
                    },
                    {
                        value: 3,
                        text: 'Hoàn thành',
                    },
                ]
            }
        },
        props: {
            customer: {}
        },
        methods: {
            getTextStatus(status) {
                let text = '';
                 this.status.filter((item) => {
                    if (item.value == status) {
                        text = item.text
                    }
                })
                return text
            }
        }
    }
</script>

<style scoped>

</style>
