<template>
  <div class="question row">
    <div class="col-6">
      <homework-container :question="question"></homework-container>
      <div class="row">
        <div class="col-md-12" v-for="(item, index) in question.questions" :key="index">
          <div class="">
            <div class="answer-title mb-1">
              <div v-html="formatBrTagText(item.title)"></div>
            </div>
            <div class="" v-if="typeView === 'view-result'">
              <div v-if="item.student_answer" class="bold mb-2">Bài làm của học viên:</div>
              <audio v-if="item.student_answer" controls preload="metadata" ref="video-log">
                <source :src="getAudioByField(item.student_answer)" type="audio/ogg">
              </audio>
            </div>
            <div class="d-flex align-items-center" v-else>
              <el-button type="success" icon="el-icon-microphone" >Record Now</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6" v-if="point">
      <!--      Bảng điểm các tiêu chí-->
      <TranscriptRecord :point="point"></TranscriptRecord>
    </div>

  </div>
</template>

<script>
import homeworkContainer from "./homework-container.vue";
import {NameSkill, NameSkillOld} from "@/core/option/selectScore";
import TranscriptRecord from "@/view/pages/classes/components/TranscriptRecord.vue";
export default {
  name: "record-audio",
  components: {TranscriptRecord, homeworkContainer},
  props: {
    question: {
      type: Object,
      default: () => { return {} }
    },
    typeView: {
      type: String,
      default: ''
    },
  },
  mounted() {
    let outComes = this.question?.outComes;
    let skillPoints = outComes?.comments?.map((comment)=>{
      let key = Object.keys(comment)?.join('')
      let quantityComments = outComes?.comments?.length
      return {
        nameSkill: quantityComments === 4 ?NameSkill?.get(key):NameSkillOld.get(key),
        pointSkill:outComes?.score_detail[key],
        commentSkill:comment[key]
      }
    })
    this.point = {
      totalPoint : outComes?.score ,
      skillPoints:skillPoints
    }
  },
  data(){
    return{
      point:{
      }
    }
  },
  methods: {
    formatBrTagText(value) {
      return value ? value.replace(/(\r\n|\n\r|\r|\n)/g, '<br>') : '';
    },
    getAudioByField(string) {
      let url = string.split("://");
      if (url.length > 1) {
        return string;
      }
      return "https://" + string
    },
    changeDataOld(valueOld){
      let newValue = ['Fluency & Coherence','Lexical Resource','Grammatical range & Accuracy','Pronunciation'].map((name)=>{
        return {
          nameSkill:name,
          pointSkill:0,
          commentSkill: ''
        }
      });
      valueOld.forEach((value)=>{
        switch (value.nameSkill) {
          case 'Fluency':
          case 'Coherence' :
            let FluencyCoherence = newValue[0]
            newValue[0] = {...FluencyCoherence,pointSkill: FluencyCoherence.pointSkill + value.pointSkill/2,commentSkill: FluencyCoherence.commentSkill + value.commentSkill}
        break;
          case 'Vocabulary Range':
          case 'Vocabulary Accuracy' :
            let LexicalResource = newValue[1]
            newValue[1] = {...LexicalResource,pointSkill: LexicalResource.pointSkill + value.pointSkill/2,commentSkill: LexicalResource.commentSkill + value.commentSkill}
            break;
          case 'Grammatical Range':
          case 'Grammatical Accuracy' :
            let GrammaticalRangeAccuracy = newValue[2]
            newValue[2] = {...GrammaticalRangeAccuracy,pointSkill: GrammaticalRangeAccuracy.pointSkill/2 + value.pointSkill,commentSkill: GrammaticalRangeAccuracy.commentSkill + value.commentSkill}
            break;
          case 'Pronunciation':
            let Pronunciation = newValue[3]
            newValue[3] = {...Pronunciation,pointSkill: Pronunciation.pointSkill + value.pointSkill/2,commentSkill: Pronunciation.commentSkill + value.commentSkill}
            break;
        }
      })
      return newValue

    }

  }
}
</script>

<style scoped>
.comment-essay {
    width: 100%;
    height: auto;
    background: #FBF5FF;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 20px;
    font-size: 14px;
}
    
</style>