<template>
  <div class="card card-custom gutter-b" id="classError">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Bảng báo lỗi</h3>
      </div>
      <div class="card-title">
        <button class="btn btn-success font-weight-bold mr-2" 
                :class="{'spinner spinner-white spinner-left' : is_export_excel}"
                :disabled="is_export_excel"
                @click="exportExcel">
          <i class="la la-file-excel" v-if="!is_export_excel"></i> Xuất excel
        </button>
        <button class="btn btn-success font-weight-bold mr-2"
                :class="{'spinner spinner-white spinner-left' : is_export_excel}"
                :disabled="is_export_excel"
                @click="exportExcelV2">
          <i class="la la-file-excel" v-if="!is_export_excel"></i> Xuất excel v2
        </button>
        <button class="btn btn-primary font-weight-bold mr-2"
                @click="showModalAddError">
          Thêm lỗi
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-2">
          <date-picker
              placeholder="Chọn thời gian"
              format="DD-MM-YYYY"
              range
              valueType="YYYY-MM-DD" v-model="query.date_range">
          </date-picker>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <el-select @change="handleCenter()" v-model="query.center_id" filterable class="w-100" placeholder="Trung tâm" clearable>
              <el-option
                  v-for="item in centers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <el-select v-model="query.branch_id" filterable class="w-100" placeholder="Chi nhánh" clearable multiple collapse-tags>
              <el-option
                  v-for="item in branches"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                <span style="float: left">{{ item.name }}</span>
              </el-option>
          </el-select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <el-select v-model="query.process" filterable class="w-100" placeholder="Khâu"  @change="getListErrorByPhase()" clearable collapse-tags>
              <el-option
                v-for="item in phaseList"
                :key="item.process"
                :label="item.name"
                :value="item.process">
              </el-option>
          </el-select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <el-select v-model="query.process_type" filterable class="w-100" placeholder="Phân loại" clearable collapse-tags>
              <el-option
                v-for="item in listError"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
          </el-select>
          </div>
        </div>
        <div  v-if="query.process == 2" class="col-md-2">
          <div class="form-group">
            <el-select v-model="query.class_id" class="w-100" placeholder="Mã lớp" clearable filterable collapse-tags>
              <el-option
                v-for="item in listClass"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                <span style="float: left">{{ item.name }}</span>
              </el-option>
          </el-select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <el-input v-model="query.title" placeholder="Nội dung"></el-input>
          </div>
        </div>
        <div class="col-md-2">
          <div class="d-flex justify-content-between">
            <button class="btn btn-primary font-weight-bold" @click="getTotalError(); srearchListError()"
                    :class="{'spinner spinner-white spinner-left' : is_disable_search}"
                    :disabled="is_disable_search"
            >
              <i class="fa fa-search" v-if="!is_disable_search"></i> Tìm kiếm
            </button>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <h4 class="ml-5">
          Tổng số lỗi hiện tại:
          <span class="total-error">{{ (Number(totalError.total_error_test_income) + Number(totalError.total_teaching)) }}</span>
        </h4>
      </div>
      <div>
      <div class="process mt-10">
        <div class="mt-5 ml-8 mb-10">
          <p style="font-size: 15px">Danh sách quy trình</p>
          <div class="row">
            <div class="card text-center process-select"
                 style="max-width: 300px; min-width: 260px; height: 180px;"
                 :class="{ 'process-error': totalError.total_error_test_income > 0, 'process-no-error': totalError.total_error_test_income == 0 | totalError.total_error_test_income == null }"
                 @click="viewDetail(PROCESS_TEST_INCOME)"
            >
              <div class="card-header text-white"
                   :class="{'process-error-header': totalError.total_error_test_income > 0, 'process-no-error-header': totalError.total_error_test_income == 0 | totalError.total_error_test_income == null}"
              >
                <p style="font-size: 15px">Test đầu vào</p>
              </div>
              <div class="card-body">
                <h1>{{ totalError.total_error_test_income > 0 ? totalError.total_error_test_income : 0  }}</h1>
              </div>
            </div>
            <div class="col-md-1 text-center">
              <i class="fa fa-arrow-right fa-3x mt-16"></i>
            </div>
            <div class="card text-center process-select" style="max-width: 300px; min-width: 260px; height: 180px;"
                 :class="{ 'process-error': totalError.total_teaching > 0, 'process-no-error': totalError.total_teaching == 0 | totalError.total_teaching == null}"
                 @click="viewDetail(PROCESS_TEACHING)"
            >
              <div class="card-header text-white"
                   :class="{'process-error-header': totalError.total_teaching > 0, 'process-no-error-header': totalError.total_teaching == 0 | totalError.total_teaching == null}">
                <p style="font-size: 15px">Dạy học</p>
              </div>
              <div class="card-body">
                <h1>{{ totalError.total_teaching > 0 ? totalError.total_teaching : 0 }}</h1>
              </div>
            </div>
          </div>
          <div class="row mt-10">
            <p class="text-danger">Lưu ý: Chỉ số lỗi Test đầu vào chỉ hiển thị theo bộ lọc trung tâm.</p>
          </div>
        </div>
      </div>
      </div>
    </div>

    <tableWarningError
      :dataWarning="listWarning"
      :dataDeFileErro="dataDeFileErro"
      :last_page="last_page"
      :page="page"
      :loading="loading_table"
      :total="totalData"
      @emit="emitTable">
    </tableWarningError>
  
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogFormVisible" width="60%">
      <el-row :gutter="10" class="pr-2 pl-2">
        <detail-error v-if="dialogFormVisible" @completed="completedViewDetail"
                      :process_type="query.process_type"
                      :process="process"
                      :totalError="totalError"
                      :center_id="query.center_id"
                      :branch_id="query.branch_id"
                      @click-detail="getTotalError"
                      :date_range="query.date_range">
        </detail-error>
      </el-row>
    </el-dialog>
    <FormErrorTeaching
    @reloadTableError="getListError">
    </FormErrorTeaching>
  </div>
</template>
<script>
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {
      GET_TOTAL_ERROR,
      EXPORT_ERROR_LIST,
      EXPORT_ERROR_LIST_V2,
      GET_LIST_ERROR
    } from "../../../core/services/store/report-error/reportError.module";
    import {PROCESS_TEST_INCOME, PROCESS_TEACHING,} from "../../../core/option/warningError";
    import DetailError from "./DetailError";
    import {GET_CENTERS} from "@/core/services/store/center/centers.module";
    import {GET_BRANCHES} from "@/core/services/store/center/branch.module";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {exportExcel} from "../../../core/filters";
    import FormErrorTeaching from "./component/FormErrorTeaching.vue"
    import tableWarningError from "./component/tableWarningError.vue";
    import { GET_ALL_CLASS } from "../../../core/services/store/course/classes.module";
    import { 
             TYPE_ERROR_TEST_ICOME,
             TYPE_ERROR_TEACHING,
             LIST_ERROR,
             TYPE_ERROR_DEFINE
      } from "../../../core/option/warningError";

    const _ = require('lodash');

    export default {
        components: {
            DetailError,
            DatePicker,
            FormErrorTeaching,
            tableWarningError
        },
        name: "Error-List",
        data() {
            return {
                page: 1,
                last_page: 1,
                totalData: '',
                totalError: [],
                PROCESS_TEST_INCOME,
                PROCESS_TEACHING,
                dialog: {
                    title: ''
                },
                dialogFormVisible: false,
                process: '',
                process_type: '',
                centers: [],
                branches: [],
                listError: '',
                dataDeFileErro:TYPE_ERROR_DEFINE,
                listClass: {},
                dataDefile: {},
                query:{
                  center_id: '',
                  branch_id: '',
                  date_range: '',
                  process: '',
                  process_type: '',
                  class_id: '',
                  title: ''
                },
                phaseList: [
                  {process: 1, name:'Test đầu vào'},
                  {process: 2, name:'Dạy học'},
              ],
              is_disable_search: false,
              is_export_excel: false,
              loading_table: false
            }
        },
        mounted() {
            this.getListErrorByPhase();
            this.getCenters();
            this.getListError();
            this.getTotalError();
            this.getAllClass();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý lỗi", route: 'report-error-index'},
                {title: "Bảng báo lỗi"}
            ]);
        }, 
        methods: {
            showModalAddError() {
              this.$bvModal.show(`add-error-teaching`);
            },
             getCenters() {
                  this.$store.dispatch(GET_CENTERS, {
                      limit: 10000,
                      status: 1,
                      is_warning_error: 1
                  }).then((res) => {
                      if (!res.error) {
                          this.centers = res.data.data
                      }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            getBranches() {
                this.$store.dispatch(GET_BRANCHES, {
                    limit: 10000,
                    status: 1,
                    center_id: this.query.center_id,
                }).then((res) => {
                    if (!res.error) {
                      this.branches = res.data.data
                    }
                }).catch((e) => {
                    console.log(e, 'error')
                });
            },
            handleCenter() {
              this.query.branch_id = []
                if (this.query.center_id) {
                    this.getBranches()
                } else {
                    this.branches = []
                }
            },
            viewDetail(process) {
                this.process = process;
                if (process == PROCESS_TEST_INCOME) {
                    this.dialog.title = "Lỗi khâu Test đầu vào"
                } else {
                    this.dialog.title = "Lỗi khâu Dạy học"
                }
                this.dialogFormVisible = true
            },
            completedViewDetail() {
                this.dialogFormVisible = false
            },

            srearchListError() {
              this.page = 1;
              this.getListError()
            },
            getListError(){
              this.loading_table = true;
              let payload = {
                    page: this.page,
                    center_id: this.query.center_id,
                    branch_id: this.query.branch_id,
                    date_range: this.query.date_range,
                    process: this.query.process,
                    process_type: this.query.process_type,
                    class_id: this.query.class_id,
                    title: this.query.title
              }
              this.$store.dispatch(GET_LIST_ERROR, payload).then(res => {
                    if (!res.error) {
                        this.listWarning = res;
                        this.is_disable_search = false
                        this.totalData = res.pagination.total;
                        this.last_page = res.pagination.last_page;
                        this.loading_table = false;
                    }
                }).catch(res => {
                   this.loading_table = false;
                    console.log(res);
                });
            },
            getTotalError() {
               this.is_disable_search = true
                this.$store.dispatch(GET_TOTAL_ERROR, {
                    center_id: this.query.center_id,
                    branch_id: this.query.branch_id,
                    date_range: this.query.date_range,
                    process: this.query.process,
                    process_type: this.query.process_type,
                    class_id: this.query.class_id,
                    title: this.query.title
                }).then(res => {
                    if (!res.error) {
                        this.totalError = res.data
                        this.is_disable_search = false
                    }
                }).catch(res => {
                    console.log(res);
                });
            },
            getListErrorByPhase() {
              this.query.process_type= '';
             switch (this.query.process) {
              case 1:
                  this.listError = TYPE_ERROR_TEST_ICOME;
                break;
              case 2:
                  this.listError = TYPE_ERROR_TEACHING;
              break;
              default:
                  this.listError = LIST_ERROR;
                break;
             }
            },
            getAllClass() {
              this.$store.dispatch(GET_ALL_CLASS, {
                }).then(res => {
                    if (!res.error) {
                        this.listClass = res.data;
                    }
                }).catch(res => {
                    console.log(res);
                });
            },
            emitTable(obj) {
              this.page = obj;
              this.getListError();
            },
            exportExcel() {
                this.is_export_excel = true
                const HEADER_ROW_EXPORT_ERROR_LIST = [
                    {
                        key: 'stt',
                        value: 'STT',
                    },
                    {
                        key: 'process',
                        value: 'Khâu',
                    },
                    {
                        key: 'task',
                        value: 'Task việc',
                    },
                    {
                        key: 'center_name',
                        value: 'Trung tâm',
                    },
                    {
                        key: 'branch_name',
                        value: 'Chi nhánh',
                    },
                    {
                        key: 'type_error',
                        value: 'Trạng thái',
                    },
                    {
                        key: 'message',
                        value: 'Nội dung',
                    },
                    {
                        key: 'date_appear',
                        value: 'Thời gian báo lỗi',
                    },
                    {
                        key: 'date_fixed',
                        value: 'Thời gian sửa lỗi',
                    },
                ]
                this.$store.dispatch(EXPORT_ERROR_LIST, {
                  center_id: this.query.center_id,
                  branch_id: this.query.branch_id,
                  date_range: this.query.date_range,
                  process: this.query.process,
                  process_type: this.query.process_type,
                  class_id: this.query.class_id,
                  title: this.query.title,
                  is_bang_bao_loi_spnn: 1,
                  is_export: 1,
                }).then((res) => {
                    this.is_export_excel = false
                    if (res.data.error) {
                        this.$bvToast.toast(res.data.message, {
                          title: 'Export excel',
                          variant: 'danger',
                          solid: true
                        });
                    } else {
                      exportExcel(HEADER_ROW_EXPORT_ERROR_LIST, res.data, "Danh sách lỗi.xlsx", true)
                    }
                });
            },
            exportExcelV2() {
                this.is_export_excel = true
                const HEADER_ROW_EXPORT_ERROR_LIST = [
                    {
                        key: 'stt',
                        value: 'STT',
                    },
                    {
                        key: 'process',
                        value: 'Khâu',
                    },
                    {
                        key: 'task',
                        value: 'Task việc',
                    },
                    {
                        key: 'center_name',
                        value: 'Trung tâm',
                    },
                    {
                        key: 'branch_name',
                        value: 'Chi nhánh',
                    },
                    {
                        key: 'status',
                        value: 'Trạng thái',
                    },
                    {
                        key: 'message',
                        value: 'Nội dung',
                    },
                    {
                        key: 'date_appear',
                        value: 'Thời gian báo lỗi',
                    },
                    {
                        key: 'updated_at',
                        value: 'Thời gian sửa lỗi',
                    },
                ]
                this.$store.dispatch(EXPORT_ERROR_LIST_V2, {
                    center_id: this.query.center_id,
                    branch_id: this.query.branch_id,
                    date_range: this.query.date_range,
                    is_bang_bao_loi_spnn: 1,
                    is_export: 1,
                }).then((res) => {
                    this.is_export_excel = false
                    if (res.data.error) {
                        this.$bvToast.toast(res.data.message, {
                            title: 'Export excel',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        exportExcel(HEADER_ROW_EXPORT_ERROR_LIST, res.data, "Danh sách lỗi.xlsx", true)
                    }
                });
            },
        }
    }
</script>
<style>
  .process {
    border: 1px solid #9f9dad;
    border-radius: 8px;
  }
  .process-error {
    border: 1px solid #d52323!important;
  }
  .process-error-header {
    background-color: #d52323!important;
  }
  .process-no-error {
    border: 1px solid #14ad14!important;
  }
  .process-no-error-header {
    background-color: #14ad14!important;
  }
  .total-error {
    border: 1px solid;
    border-radius: 5px;
    margin-top: 20px;
    padding: 2px 10px 2px 10px;
  }
  .el-dialog__header {
    border: 1px solid #EBEDF3 !important;
    text-align: center;
  }
  .process-select {
    cursor: pointer;
  }

 li.el-select-dropdown__item  {
    max-width: 225px;
  }
</style>
