<template>
  <b-modal @hide="onHide" size="xl" :centered="true" :scrollable="true" ref="modal-account-type-avatar" hide-footer title="Chỉnh sửa ảnh đại diện"  class="modal-account-type-avatar">
    <div class="form-group text-center row">
      <label class="col-xl-3 col-lg-3 col-form-label text-right">Ảnh đại diện</label>
      <div class="col-lg-9 col-xl-6">
        <div class="image-input image-input-outline" id="kt_profile_avatar">
          <div
              class="image-input-wrapper"
              :style="{ backgroundImage: `url(${photo})` }"
          ></div>
          <label
              class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              data-action="change"
              data-toggle="tooltip"
              title=""
              data-original-title="Change avatar"
              @click="toggleShow"
          >
            <i class="fa fa-pen icon-sm text-muted"></i>
            <!--                <input-->
            <!--                  type="file"-->
            <!--                  name="profile_avatar"-->
            <!--                  accept=".png, .jpg, .jpeg"-->
            <!--                  @change="onFileChange($event)"-->
            <!--                />-->
            <input type="hidden" name="profile_avatar_remove" />
          </label>
          <my-upload field="img"
                     @crop-success="cropSuccess"
                     @crop-upload-success="cropUploadSuccess"
                     @crop-upload-fail="cropUploadFail"
                     @src-file-set="srcFileSet"
                     v-model="show"
                     :width="300"
                     :height="300"
                     langType="en"
                     :params="params"
                     :headers="headers"
                     img-format="png"></my-upload>
          <span
              class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              data-action="cancel"
              data-toggle="tooltip"
              title="Cancel avatar"
          >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
          <!--              <span-->
          <!--                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"-->
          <!--                data-action="remove"-->
          <!--                data-toggle="tooltip"-->
          <!--                title="Remove avatar"-->
          <!--                @click="current_photo = null"-->
          <!--              >-->
          <!--                <i class="ki ki-bold-close icon-xs text-muted"></i>-->
          <!--              </span>-->
        </div>
        <!--            <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>-->
      </div>
    </div>
    <footer class="modal-footer">
      <button type="button" class="btn btn-secondary" @click="hideModal">Hủy bỏ</button>
      <button type="button" class="btn btn-success" @click="updateAvatar">Cập nhật</button>
    </footer>
  </b-modal>
</template>

<script>
import myUpload from "vue-image-crop-upload/upload-2.vue";
import {UPDATE_ACCOUNT_TYPE_AVATAR} from "../../../core/services/store/user/account-type.module";
export default {
  name: "Avatar-Change",
  props : {
    showModal: { default: false },
    accountType : {
      type : Object,
      default()  {
        return {};
      }
    },
  },
  data() {
    return {
      show: false,
      params: {
        token: '123456798',
        name: 'avatar'
      },
      headers: {
        smail: '*_~'
      },
      imgDataUrl: '',
      default_photo: "media/users/blank.png",
      current_photo: null,
      image_name: null,
      image_type: null,
      check_spinner: false,
      AccountTypeDetail : '',
    };
  },
  components : {
    'my-upload': myUpload
  },
  computed : {
    photo() {
      return this.current_photo == null
          ? this.default_photo
          : this.current_photo;
    }
  },
  methods : {
    onHide(evt) {
      this.$emit("clicked", true);
    },
    hideModal() {
      this.$refs['modal-account-type-avatar'].hide();
    },
    toggleShow() {
      this.show = !this.show;
    },
    srcFileSet(fileName, fileType, fileSize){
      this.image_name = fileName;
      this.image_type = fileType;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field){
      this.current_photo = imgDataUrl;
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field){
      console.log('-------- upload success --------');
      console.log(jsonData);
      console.log('field: ' + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field){
      console.log('-------- upload fail --------');
      console.log(status);
      console.log('field: ' + field);
    },
    async updateAvatar() {
          var avatar = this.photo;
          this.check_spinner = true;
          function urltoFile(url, filename, mimeType) {
            return (fetch(url)
                    .then(function (res) {
                      return res.arrayBuffer();
                    })
                    .then(function (buf) {
                      return new File([buf], filename, {type: mimeType});
                    }).catch((error) => {
                      return 1;
                    })
            );
          }
          let formData = new FormData();
          if (avatar.search('https') == -1) {
            console.log(avatar);
            let file = await urltoFile(avatar, this.image_name, this.image_type);
            if (file != 1) {
              formData.append('avatar', file);
            }
          }
          await this.$store.dispatch(UPDATE_ACCOUNT_TYPE_AVATAR, {id : this.AccountTypeDetail.id, params : formData}).then((rp) => {
            this.check_spinner = false;
            this.$refs['modal-account-type-avatar'].hide();
            this.$bvToast.toast(rp.data.data.message,{
              title: 'Thành công',
              variant: 'success',
              solid: true
            });
          }).catch((rp) => {
            this.check_spinner = false;
          });

    },
    cancel() {
      this.user.name = this.userProfile.data.name;
      this.user.phone = this.userProfile.data.phone;
      this.user.email = this.userProfile.data.email;
      this.user.address = this.userProfile.data.profile.address;
      this.user.birthday = this.userProfile.data.profile.birthday;
      this.current_photo = this.userProfile.data.avatar;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
        this.image_name = file.name;
        this.image_type = file.type;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    }
  },
  watch : {
    showModal() {
      if (this.showModal){
        this.$refs['modal-account-type-avatar'].show();
        if (this.accountType){
          this.AccountTypeDetail = this.accountType;
          this.current_photo = this.AccountTypeDetail.avatar;
        }
      }

    }
  }
}
</script>

<style scoped>

</style>