<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <div class="  row ">
      <div class="col-12 d-flex mb-4">
        <div>
          <p class="mr-5" style="cursor: pointer;font-size: 12px" @click="isTabCheckPlagiarism = true" :class="{'text-primary border_bottom_item': isTabCheckPlagiarism}"
           >KIỂM TRA ĐẠO VĂN</p>
        </div>
        <div v-for="(item, index) in writingCriteria">
          <p class="mr-5" style="cursor: pointer;font-size: 12px" :class="{'text-primary border_bottom_item': index === tabCurrent && !isTabCheckPlagiarism}"
             @click="handleSubmit(changeTab(index))" >{{ item.name }}</p>
        </div>
      </div>

      <div class="col-4 " >
        <div class="  row rounded  py-5 " style="background-color: #F8F8F8;" v-if="!isTabCheckPlagiarism">
          <div class=" col-12 mt-2 content_test">
            <div  v-for="(item, index) in listDetailSkill"
                 :key="tabCurrent.toString() + index">
              <ReviewSkill :criteria="item" :id="tabCurrent.toString() + index" :skillCurrent="skillCurrent"
                           :allCriteria="writingCriteria"></ReviewSkill>
            </div>
          </div>
          <div style="width: 100%" >
            <InputSpecificComments :skillCurrent="skillCurrent"></InputSpecificComments>
          </div>
          <div class=" col-12 d-flex flex-row-reverse">
            <button type="button" class="btn btn-primary" @click="handleSubmit(confirm)" :disabled="isDisable" style="height: 30px;padding: 5px;width: 100px;border-radius:3px">
              {{ checkFillAllSkill() ? 'Hoàn Thành' : 'Tiếp theo' }}
            </button>
          </div>
        </div>
        <div v-else  class=" col-12  row rounded  py-5 " style="background-color: #F8F8F8;">
           <div style="background-color: #F8F8F8" class="rounded px-4 pb-4 col-12">
              <p style="font-size: 13px; font-weight: 400;color: #4F4F4F">Bài viết có dấu hiệu đạo văn không?</p>
             <el-radio v-model="isPlagiarism" :label="false" @change="resetDataPlagiarism()">Không</el-radio>
             <br>
             <el-radio v-model="isPlagiarism" :label="true">Có</el-radio>
             <div v-if="isPlagiarism">
               <hr>
               <div class="d-flex align-items-center">
                 <p style="font-size: 13px; font-weight: 400;color: #4F4F4F" class="my-0 mr-3">Tỉ lệ đạo văn của bài viết <span style="color: red">*</span></p>
                 <ValidationProvider name="Tỉ lệ đạo văn" rules="required" v-slot="{ errors,classes }"
                                     :vid="'Tỉ lệ đạo văn'">
                 <el-select
                     v-model="plagiarismRate"
                     placeholder="Chọn"
                     size="small"
                     style="width: 150px"
                 >
                   <el-option
                       v-for="item in PLAGIARISM_RATE"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"
                   />
                 </el-select>
                   <div class="fv-plugins-message-container">
                     <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                   </div>
                 </ValidationProvider>
               </div>
               <div class="mt-6">
                 <p style="font-size: 13px; font-weight: 400;color: #4F4F4F" >Nhận xét cụ thể, dẫn chứng  <span style="color: red">*</span></p>
                 <ValidationProvider name="Nhận xét đạo văn" :rules="{required :true,min:50}" v-slot="{ errors,classes }"
                                     :vid="'Nhận xét đạo văn'">
                 <el-input
                     v-model="plagiarismComment"
                     :autosize="{ minRows: 2, maxRows: 4}"
                     type="textarea"
                     placeholder="Nhập nhận xét"
                 />
                   <div class="fv-plugins-message-container">
                     <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                   </div>
                 </ValidationProvider>
               </div>
             </div>
             <div class=" col-12 d-flex flex-row-reverse pr-0 mt-3">
               <button type="button" class="btn btn-primary"  style="height: 30px;padding: 5px;width: 100px;border-radius:3px" @click="handleSubmit(next)">
                 Tiếp theo
               </button>
             </div>
           </div>
        </div>
      </div>
      <div class="col-8 ">
        <div class="pl-8">
          <div style="background-color: #F8F8F8;border-radius: 3px" class="p-4">
            <h4 style="font-weight: bold;font-size: 14px" >Đề bài</h4>
            <div style="background-color: white;color: #4F4F4F" class="px-3 py-4 mt-4">
              <p style="font-weight: 400;" v-html="topic"></p>
            </div>
          </div>
          <div style="background-color: #F8F8F8;border-radius: 3px" class="p-4 mt-5">
            <h4 style="font-weight: bold;font-size: 14px" >Bài làm của học viên</h4>
            <div style="background-color: white;color: #4F4F4F" class="px-3 py-4 mt-4 content_test">
              <p style="font-weight: 400;white-space:pre-line">{{dataTests[0]?.answerOfUser}}</p>
            </div>
            <p style="font-weight: normal;font-size: 13px" class="mb-0 mt-3">Số từ: <span>{{countWords}}</span></p>
          </div>
        </div>
      </div>
    </div>
    <modal-confirmed-low-scoring :allCriteria="writingCriteria" @submit="makePoint"></modal-confirmed-low-scoring>
    <ModalConfirmedPlagiarism :allCriteria="writingCriteria" @submit="makePoint"></ModalConfirmedPlagiarism>
    <el-dialog
        title="Thông báo"
        :visible.sync="centerDialogVisible"
        width="20%"
        :close-on-click-modal="false"
        :show-close="false"
        top="30vh"
    >
      <span>Tác vụ đã được admin {{current_scorer_name}} hoàn thành.</span>
      <div class="d-flex justify-content-center mt-8">
        <button type="button" class="btn btn-secondary" @click="comeBack()">Quay về</button>
      </div>
    </el-dialog>
  </ValidationObserver>
</template>

<script>
import ReviewSkill from "@/view/pages/test-input/components/ComponentMarkTheExam/ReviewSkill";
import {TestModel} from "@/view/pages/test-input/models/test_model";
import {
  CHECK_TEST_COMPLETION,
  GET_ANSWER_STUDENT,
  MAKE_EXAM_SPEAKING
} from "@/core/services/store/test/testInput.module";
import router from "@/router";
import InputSpecificComments from "@/view/pages/test-input/components/ComponentMarkTheExam/InputSpecificComments";
import {CriteriaModel} from "@/view/pages/test-input/models/criteria_model"
import ModalConfirmedLowScoring
  from "@/view/pages/test-input/components/ComponentMarkTheExam/ModalConfirmedLowScoring.vue";
import {PLAGIARISM_RATE} from "@/core/option/testInputOption";
import ModalConfirmedPlagiarism
  from "@/view/pages/test-input/components/ComponentMarkTheExam/ModalConfirmedPlagiarism.vue";

export default {
  name: "TestWriting",
  components: {ModalConfirmedPlagiarism, ModalConfirmedLowScoring, InputSpecificComments, ReviewSkill},
  data() {
    return {
      writingCriteria: [
        new CriteriaModel(5, "ĐÁP ỨNG YÊU CẦU ĐỀ BÀI", "task_achievement_writing"),
        new CriteriaModel(8, "ĐỘ MẠCH LẠC", "coherence_cohesion_writing"),
        new CriteriaModel(6, "TỪ VỰNG", "lexical_resource_writing"),
        new CriteriaModel(7, "NGỮ PHÁP", "grammatical_range_accuracy_writing"),
      ],
      dataTests: [],
      tabCurrent: 0,
      listTabDone: [0],
      isDisable: false,
      centerDialogVisible: false,
      current_scorer_name: '',
      topic:'',
      isPlagiarism:false,
      PLAGIARISM_RATE:PLAGIARISM_RATE,
      plagiarismRate: null,
      plagiarismComment:'',
      isTabCheckPlagiarism: true

    }
  },
  props: {
    doneTest: {
      type: Boolean
    }
  },
  mounted() {
    this.getAnswerStudent();
    let dataLocal = JSON.parse(localStorage.getItem('data_mark_writing' + this.$route.params.id));
    if(dataLocal){
      let dataWriting = dataLocal?.dataWriting
      this.writingCriteria = dataWriting.map((data) => {
        return new CriteriaModel(data.id,data.name,data.eName,data.score,data.commentAuto,data.specificComments,data.listdetailSkill)
      });
      this.plagiarismRate = dataLocal.plagiarismRate;
      this.isPlagiarism = dataLocal.isPlagiarism;
      this.plagiarismComment = dataLocal.plagiarismComment
    }
  },
  watch: {
    writingCriteria: {
      handler(newVal, oldVal) {
        this.setLocalDataMark()
      },
      deep: true
    }
  },
  methods: {
    async confirm() {
      if (this.checkLowScore()) {
        const testIsCompleted = await this.checkTestCompletion()
        if (testIsCompleted) {
          this.centerDialogVisible = true ;
          return
        }
        this.$bvModal.show("Modal-Confirmed-Low-Scoring")
        return
      }
      if (!this.checkNextSkill()) {
        this.$message.error('Bạn chưa hoàn thành skill này');
        return
      }
      if (this.checkFillAllSkill()) {
        const testIsCompleted = await this.checkTestCompletion()
        if (testIsCompleted) {
            this.centerDialogVisible = true ;
          return
        }
        await this.makePoint();
        return
      }
      this.nextSkill()
    },
    backPage() {
      this.$emit("backPage")
    },
    getAnswerStudent() {
      let test_input_schedule_id = this.$route.params.id
      this.$store.dispatch(GET_ANSWER_STUDENT, test_input_schedule_id).then((data) => {
        if (!data.error) {
          this.topic = data?.data?.topic
          this.dataTests = data?.data?.userAnswers.flat().filter(item => item.answers.length > 0).map((element) => {
            return new TestModel(element.description, element.answers.reduce((a, b) => a + b))
          })
        }
      })
    },
    async makePoint() {
      this.isDisable = true
      for (const criteria of this.writingCriteria) {
        await criteria.calculationPointAndCommentAuto();
      }
      let data = {
        type: "writing",
        criterias: this.criteria,
        plagiarism:{
          type_of_plagiarism:this.plagiarismRate??0,
          description_of_plagiarism: this.plagiarismComment
        }
      }
      let test_input_schedule_id = this.$route.params.id
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      await this.$store.dispatch(MAKE_EXAM_SPEAKING, {id: test_input_schedule_id, data: data}).then(()=>{
        this.$message.success('Chấm Writing thành công');
        this.resetLocalDataMark()
        router.push({name: 'task-details', params: {id: test_input_schedule_id}})
      }).finally(() => {
        loading.close();
        this.isDisable = false;
      })
    },
    nextSkill() {
      if (this.tabCurrent == this.writingCriteria.length - 1) {
        this.isTabCheckPlagiarism = true;
        this.tabCurrent = 0
        return
      }
      if(!this.isTabCheckPlagiarism){
        this.tabCurrent++;
        this.listTabDone = [...this.listTabDone, this.tabCurrent];
      }else {
        this.isTabCheckPlagiarism = false;
      }

    },
    changeTab(index) {
      if (this.checkLowScore() && !this.listTabDone.includes(index)) {
        this.$bvModal.show("Modal-Confirmed-Low-Scoring")
        return
      }
      if (!this.checkNextSkill(this.tabCurrent) && !this.isTabCheckPlagiarism) {
        this.$message.error('Bạn chưa hoàn thành skill này');
        return
      }
      this.isTabCheckPlagiarism = false;
      this.tabCurrent = index;
      this.listTabDone = [...this.listTabDone, this.tabCurrent]
    },
    checkNextSkill(index = -1) {
      // && (this.skillCurrent.specificComments != '')
      return ((this.listDetailSkill?.find((detailSkill) => detailSkill?.scoreDetail === null) == null)  )
    },
    checkFillAllSkill() {
      return this.writingCriteria.find((skill) => {
            return ((skill?.listdetailSkill?.find((detailSkill) => detailSkill?.scoreDetail == null)) != null) || skill.specificComments == ''
          }
      ) == null;
    },
    checkLowScore() {
      return !!this.skillCurrent?.listdetailSkill?.find((detailSkill) => detailSkill?.scoreDetail < 2 && detailSkill.scoreDetail !== null )
    },
    checkTestCompletion() {
      return this.$store.dispatch(CHECK_TEST_COMPLETION, this.$route.params.id).then((res) => {
        return false
      }).catch((e) => {
        console.log(e)
        this.current_scorer_name = e?.data?.data?.current_scorer_name
        return true
      })
    },
    comeBack(){
      router.go(-1)
    },
    async next(){
      if(this.plagiarismRate === 4){
        this.writingCriteria.forEach((criteria)=>{
          criteria.setPointAndCommentAutoIs0Or1(0)
        })
        const testIsCompleted = await this.checkTestCompletion()
        if (testIsCompleted) {
          this.centerDialogVisible = true ;
          return
        }
        this.$bvModal.show("Modal-Confirmed-Plagiarism")
        return
      }
      this.nextSkill()
    },
    setLocalDataMark() {
      const saveData = {
        dataWriting : this.writingCriteria,
        plagiarismComment : this.plagiarismComment,
        plagiarismRate: this.plagiarismRate,
        isPlagiarism: this.isPlagiarism
      }
      localStorage.setItem('data_mark_writing' + this.$route.params.id, JSON.stringify(saveData))
    },
    resetLocalDataMark(){
      localStorage.removeItem('data_mark_writing' + this.$route.params.id)
    },
    resetDataPlagiarism(){
      this.plagiarismRate = null;
      this.plagiarismComment = '';
    }

  },
  computed: {
    criteria() {
      return this.writingCriteria.map((criteria) => {
        return {
          key: criteria.eName,
          point: criteria.score,
          comment: criteria.specificComments,
          comment_auto: criteria.commentAuto,
        }
      })
    },
    listDetailSkill() {
      return this.writingCriteria[this.tabCurrent]?.listdetailSkill
    },
    skillCurrent() {
      return this.writingCriteria[this.tabCurrent]
    },
    countWords() {
      const words = this.dataTests[0]?.answerOfUser?.trim().split(/\s+/);
      if(words){
        return words.length;
      }
     return 0
    }
  }
}
</script>

<style scoped>
h5 {
  font-size: 16px;
  color: #000000;
  font-weight: bold;
}

.content_test_writing {
  height: 500px;
  font-size: 16px;
  overflow-y: auto;
}

.content_test {
  max-height: 680px;
  overflow-y: auto;
}

.border_bottom {
  border-bottom: 6px solid #D6D5D5;
  border-width: 1px;
}

.border_bottom_item {
  border-bottom: 6px solid #0066FF;
  border-width: 1px;
}

p {
  font-weight: bold;
  font-size: 15px;
}
</style>
