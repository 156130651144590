<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Bảng báo lỗi Xử lý phản hồi</h3>
      </div>
      <div class="card-title">
        <button :disabled="is_export_excel"  @click="exportExcel" class="btn btn-success font-weight-bold mr-2" type="succsess">
         <i v-bind:class="[is_export_excel ? 'el-icon-loading' : 'la la-file-excel']"></i>
            Xuất excel
        </button>
    </div>
    </div>
    <div class="card-body">
      <div class="fillter-errors">
        <div class="row">
          <div class="col-md-2">
            <date-picker
                placeholder="Chọn thời gian"
                format="DD-MM-YYYY"
                range
                valueType="YYYY-MM-DD" v-model="query.date_range">
            </date-picker>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <el-select @change="handleCenter()" v-model="query.operating" filterable class="w-100" placeholder="Mảng" clearable>
                <el-option
                    v-for="item in operating"
                    :key="item.id"
                    :label="item.name"
                    :value="item.value">
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <el-select @change="handleCenter()" v-model="query.type" filterable class="w-100" placeholder="Phân loại" clearable  collapse-tags>
                <el-option
                    v-for="item in type"
                    :key="item.id"
                    :label="item.name"
                    :value="item.value">
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-2 sreach-error-feedback">
            <div v-loading="is_disable_search" class="d-flex justify-content-between">
              <button  class="btn btn-primary font-weight-bold" @click="getTotalError">
                <i class="fa fa-search" ></i> Tìm kiếm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <h4 class="ml-5">
          Tổng số lỗi hiện tại:
          <span class="total-error">{{ totalError.total > 0 ? totalError.total : 0 }}</span>
        </h4>
      </div>
      <div>
      <div class="process mt-10">
        <div class="mt-5 ml-8 mb-10">
          <p style="font-size: 15px">Bảng báo lỗi vận hành xử lý phản hồi</p>
          <div class="row">
            <div class="card text-center process-select"
                 style="max-width: 400px; min-width: 360px;"
                 :class="{ 'process-error': totalError.total_error_ticket_support_center > 0, 'process-no-error': totalError.total_error_ticket_support_center == 0 | totalError.total_error_ticket_support_center == null }"
                 @click="viewDetail(TICKET_SUPPORT_CENTER_BACKEND, query)"
            >
              <div class="card-header text-white"
                   :class="{'process-error-header': totalError.total_error_ticket_support_center > 0, 'process-no-error-header': totalError.total_error_ticket_support_center == 0 | totalError.total_error_ticket_support_center == null}"
              >
                <p style="font-size: 15px">Trung tâm hỗ trợ</p>
              </div>
              <div class="card-body">
                <h1>{{ totalError.total_error_ticket_support_center > 0 ? totalError.total_error_ticket_support_center : 0  }}</h1>
              </div>
            </div>
            <div class="col-md-1 text-center">
<!--              <i class="fa fa-arrow-right fa-3x mt-16"></i>-->
            </div>
            <div class="card text-center process-select" style="max-width: 400px; min-width: 360px;"
                 :class="{ 'process-error': totalError.total_error_ticket_student_management > 0, 'process-no-error': totalError.total_error_ticket_student_management == 0 | totalError.total_error_ticket_student_management == null}"
                 @click="viewDetail(TICKET_STUDENT_MANAGEMENT_EDUCENTER, query)"
            >
              <div class="card-header text-white"
                   :class="{'process-error-header': totalError.total_error_ticket_student_management > 0, 'process-no-error-header': totalError.total_error_ticket_student_management == 0 | totalError.total_error_ticket_student_management == null}">
                <p style="font-size: 15px">Quản lý học viên</p>
              </div>
              <div class="card-body">
                <h1>{{ totalError.total_error_ticket_student_management > 0 ? totalError.total_error_ticket_student_management : 0 }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogFormVisible" >
      <el-row :gutter="10" class="pr-2 pl-2">
        <detail-error v-if="dialogFormVisible" @completed="completedViewDetail" :query="query" :process="process" :totalError="totalError"></detail-error>
      </el-row>
    </el-dialog>

    

  </div>
</template>
<script>
import {
  EXPORT_ERROR_LIST_FEEDBACK,
  EXPORT_ERROR_LIST_FEEDBACK_V2
} from "../../../core/services/store/report-error/reportError.module";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
    import {GET_TOTAL_ERROR_RESPONSE_HANDLING} from "../../../core/services/store/report-error/reportError.module";
    import {TICKET_SUPPORT_CENTER_BACKEND, TICKET_STUDENT_MANAGEMENT_EDUCENTER} from "../../../core/option/warningError";
    import DetailError from "./DetailError";
    import {exportExcel} from "../../../core/filters";
    const _ = require('lodash');

    export default {
        components: {
            DetailError,
            DatePicker
        },
        name: "Error-List",
        data() {
            return {
                totalError: [],
                TICKET_SUPPORT_CENTER_BACKEND,
                TICKET_STUDENT_MANAGEMENT_EDUCENTER,
                dialog: {
                    title: ''
                },
                dialogFormVisible: false,
                process: '',
                query:{
                  operating: '',
                  type: '',
                  date_range: '',
                  is_feedback_error: 1
                },
                
                operating: 
                [
                  {id:1 , name: "Trung tâm hỗ trợ", value: 3},
                  {id:2 , name: "Quản lý học viên", value: 4},
                ],
                type: [
                  {id:1,  name: "Xử lý phản hồi", value: 1},
                  {id:2,  name: "Chăm sóc học viên", value: 2},
                ],
                is_disable_search: false,
                is_export_excel: false

            }
        },
        mounted() {
            this.getTotalErrorResponseHandLing();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý lỗi", route: 'report-error-index'},
                {title: "Bảng báo lỗi Xử lý phản hồi"}
            ]);
        },
        methods: {
            getTotalError() {
              this.getTotalErrorResponseHandLing();
            },
            handleCenter() {
            },
            exportExcel() {
                this.is_export_excel = true
                const HEADER_ROW_EXPORT_TICKETS_CSHV = [
                    {
                        key: 'stt',
                        value: 'STT',
                    },
                    {
                        key: 'process',
                        value: 'Mảng',
                    },
                    {
                        key: 'task',
                        value: 'Task việc',
                    },
                    {
                        key: 'type',
                        value: 'Phân loại',
                    },
                    {
                        key: 'status',
                        value: 'Trạng thái',
                    },
                    {
                        key: 'message',
                        value: 'Nội dung',
                    },
                    {
                        key: 'date_appear',
                        value: 'Thời gian báo lỗi',
                    },
                    {
                        key: 'time_start_deadline',
                        value: 'Thời gian bắt đầu tính deadline',
                    },
                    {
                        key: 'date_fixed',
                        value: 'Thời gian sửa lỗi',
                    },
                    {
                        key: 'department',
                        value: 'Phòng ban xử lý',
                    },
                ]
            this.$store.dispatch(EXPORT_ERROR_LIST_FEEDBACK, {
                  operating: this.query.operating,
                  type: this.query.type,
                  date_range: this.query.date_range,
                  is_feedback_error: this.query.is_feedback_error,
                  is_export: 1
                }).then((res) => {
                    this.is_export_excel = false
                    if (res.data.error) {
                        this.$bvToast.toast(res.data.message, {
                          title: 'Export  ',
                          variant: 'danger',
                          solid: true
                        });
                    } else {
                        exportExcel(HEADER_ROW_EXPORT_TICKETS_CSHV, res.data, "Danh sách lỗi phản hồi.xlsx", true)
                    }
                    this.loading_export = false;
                });
            },
            exportExcelV2() {
                // Danh sách lỗi phản hồi
                const HEADER_ROW_EXPORT_TICKETS_CSHV = [
                    {
                        key: 'stt',
                        value: 'STT',
                    },
                    {
                        key: 'process',
                        value: 'Mảng',
                    },
                    {
                        key: 'task',
                        value: 'Task việc',
                    },
                    {
                        key: 'type',
                        value: 'Phân loại',
                    },
                    {
                        key: 'status',
                        value: 'Trạng thái',
                    },
                    {
                        key: 'message',
                        value: 'Nội dung',
                    },
                    {
                        key: 'date_appear',
                        value: 'Thời gian báo lỗi',
                    },
                    {
                        key: 'date_fixed',
                        value: 'Thời gian sửa lỗi',
                    },
                ]
                this.$store.dispatch(EXPORT_ERROR_LIST_FEEDBACK_V2, {
                    operating: this.query.operating,
                    type: this.query.type,
                    date_range: this.query.date_range,
                    is_feedback_error: this.query.is_feedback_error,
                    is_export: 1
                }).then((res) => {
                    this.is_export_excel = false
                    if (res.data.error) {
                      this.$bvToast.toast(res.data.message, {
                        title: 'Export  ',
                        variant: 'danger',
                        solid: true
                      });
                    } else {
                      exportExcel(HEADER_ROW_EXPORT_TICKETS_CSHV, res.data, "Danh sách lỗi phản hồi.xlsx", true)
                    }
                });
            },
            viewDetail(process, query) {
                this.query = query;
                this.process = process
                if (process == TICKET_SUPPORT_CENTER_BACKEND) {
                    this.dialog.title = "Lỗi trung tâm hỗ trợ"
                } else {
                    this.dialog.title = "Lỗi quản lý học viên"
                }
                this.dialogFormVisible = true
            },
            completedViewDetail() {
                this.dialogFormVisible = false
            },
            getTotalErrorResponseHandLing() {
                this.is_disable_search = true;
                    this.$store.dispatch(GET_TOTAL_ERROR_RESPONSE_HANDLING,{
                        operating: this.query.operating,
                        type: this.query.type,
                        date_range: this.query.date_range,
                        is_feedback_error: this.query.is_feedback_error
                    }).then(res => {
                        if (!res.error) {
                            this.totalError = res.data
                        }
                        this.is_disable_search = false;
                    }).catch(res => {
                        console.log(res);
                    });
            },
        }
    }
</script>
<style>
  .process {
    border: 1px solid #9f9dad;
    border-radius: 8px;
  }
  .process-error {
    border: 1px solid #d52323!important;
  }
  .process-error-header {
    background-color: #d52323!important;
  }
  .process-no-error {
    border: 1px solid #14ad14!important;
  }
  .process-no-error-header {
    background-color: #14ad14!important;
  }
  .total-error {
    border: 1px solid;
    border-radius: 5px;
    margin-top: 20px;
    padding: 2px 10px 2px 10px;
  }
  .el-dialog__header {
    border: 1px solid #EBEDF3 !important;
    text-align: center;
  }
  .process-select {
    cursor: pointer;
  }

  .sreach-error-feedback .el-loading-spinner{
      width: 47%;
  }

  .sreach-error-feedback 
  .el-loading-spinner 
  .circular {
    height: 22px;
    margin-top: 10px;
  }


</style>
